import React from "react";
import { _formatSecondsToMinutes, _formatDateToCallCardDateFormat, _formatTalkListenRatio } from "../../../utils/helpers";
import OpenCallScreen from "app/styles/assets/images/icons/dealView/OpenCallScreen.svg";

import { getMeetingIcon } from "../../call/MeetingIcon";

const colorByCallType = {
    ZOOM: "Blue",
    GOOGLE_MEET: "Green",
    HUBSPOT: "Orange",
    HUBSPOT_LIVE: "Orange"
}

export const CallPreviewHeader = ({ errorMessage, openCallPage, callTime, callType, meetingTitle }) => {

    return (
        <div className={"CallPreview__header CallPreview__header" + (colorByCallType[callType] || "Purple")}>
            <div className="CallPreview__subjectContainer">
                <img src={getMeetingIcon(callType)} className="CallPreview__callIcon" />
                <span className="CallPreview__subject">{meetingTitle}</span>
            </div>
            <div className="CallPreview__headerRight">
                <span className="CallPreview__date">{_formatDateToCallCardDateFormat(callTime)}</span>
                {!errorMessage &&
                    <div className="NewButton" onClick={openCallPage}>
                        <img className="NewButton__startAdornment" src={OpenCallScreen} />
                        <span className="NewButton__label">View Call</span>
                    </div>
                }
            </div>
        </div>

    );
}

CallPreviewHeader.propTypes = {}
CallPreviewHeader.defaultProps = {}




