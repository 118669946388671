import React, { useState, useEffect } from "react";
import { getFilteredCalls, exportCallLogToExcel } from "app/utils/network";
import { withErrorHandling } from "app/components/error/errorHandlingScreen";
import { setCallLogFilter, setFilteredCalls } from "app/actions/callLog";
import { connect } from "react-redux";
import { metrics } from "app/utils/metrics";
import { isEqual, has, clone } from "lodash";
import { CallTablePageRender } from "app/containers/CallTablePageRender";
import { emptyFilterParams, removeFilterValue, getInitialFilters } from "app/utils/filter";
import withExplainer from "./withExplainer";
import useSnackbar from "../utils/hooks/useSnackbar";
import NewDataTooltip from "../components/common/NewDataTooltip";
import { anyDiffElementsInArray } from "../utils/dataUtils";
import { usePrevious } from "../utils/hooks/usePrevious";
import { usePageTimeTracker } from "../utils/hooks/usePageTimeTracker";

const CallTablePage = props => {
  const [filterParams, setFilterParams] = useState(props.callLog.filterParams);
  const prevFilterParams = usePrevious(filterParams);
  const [loading, setLoading] = useState(false);
  const [showNewDataTooltip, setShowNewDataTooltip] = useState(false);
  const [stagedResults, setStagedResults] = useState(null);
  const { setSnackbar } = useSnackbar();
  usePageTimeTracker(["TeamCalls"]);

  const totalHits = props.callLog?.calls?.totalHits ?? 0;
  let startIndex = filterParams?.from ?? 0;
  let endIndex = filterParams?.from + filterParams?.size ?? 50;
  if (endIndex > totalHits) {
    endIndex = totalHits;
  }
  if (startIndex === 0 && endIndex !== 0) {
    startIndex = 1;
  }

  useEffect(() => {
    return (() => {
      if (!props.auth.isAdminLogin)
        metrics.logEndOfPage("teamCallLog");
    })
  }, [])

  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    if (prevFilterParams && !isEqual(props.callLog.filterParams, prevFilterParams)) {
      filtersChanged();
    }
  }, [props.callLog.filterParams]);

  async function componentDidMount() {
    const calls = props?.callLog?.calls?.calls ?? [];
    if (!(calls.length > 0)) {
      setLoading(true);
    }
    setFilterParams(props.callLog.filterParams);
    const searchResults = await getFilteredCalls(props.callLog.filterParams);
    trySettingResults(searchResults);
    setLoading(false);
  }

  async function filtersChanged() {
    setLoading(true);
    setShowNewDataTooltip(false);
    setFilterParams(props.callLog.filterParams);
    const searchResults = await getFilteredCalls(props.callLog.filterParams);
    setResults(searchResults);
    setLoading(false);
  }


  function trySettingResults(searchResults) {
    const calls = props?.callLog?.calls?.calls ?? [];
    if (calls.length > 0 && anyDiffElementsInArray(searchResults.calls, props.callLog.calls.calls, "callId")) {
      setShowNewDataTooltip(true);
      setTimeout(() => setShowNewDataTooltip(false), 20000);
      setStagedResults(searchResults);
      return;
    }

    setResults(searchResults);
  }

  function handleShowNewData() {
    setLoading(true);
    if (stagedResults)
      setResults(stagedResults);
    setTimeout(() => setLoading(false), 500);
    setShowNewDataTooltip(false);
  }

  function setResults(searchResults) {
    if (searchResults.calls) {
      props.setFilteredCalls(searchResults);
    } else {
      props.setFilteredCalls({ calls: [] });
    }
  }

  async function exportToExcel() {
    setLoading(true);
    const response = await exportCallLogToExcel(filterParams);
    try {
      downloadFile(response);
    } catch (error) {
      setSnackbar("Oops. We weren't able to download the excel. Contact support@trywingman.com for help.", "ERROR");
      setLoading(false);
    }
    setLoading(false);
    return response;
  }

  function downloadFile(contents) {
    const binaryString = window.atob(contents);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.href = window.URL.createObjectURL(blob);
    const fileName = "StringsCallLogExport.xlsx";
    link.download = fileName;
    link.click();
    document.body.removeChild(link);
  }

  async function sort(sortField, sortDirection) {
    const updatedFilterParams = clone(filterParams);
    updatedFilterParams.sortField = sortField;
    updatedFilterParams.sortDirection = sortDirection;
    await onApplyFilter(updatedFilterParams);
  }

  async function onApplyFilter(filterParamsLocal = filterParams) {
    const updatedFilterParams = clone(filterParamsLocal);
    //whenever a new filter is applied start from page 1.
    updatedFilterParams.from = 0;
    saveCallLogFilterParams(updatedFilterParams);
  }

  async function handleRemoveFilterValue(fieldName) {
    const updatedFilterParams = removeFilterValue(filterParams, fieldName);
    onApplyFilter(updatedFilterParams);
  }

  async function resetFilter() {
    const updatedFilterParams = clone(emptyFilterParams);
    setLoading(true);
    saveCallLogFilterParams(updatedFilterParams);
    setLoading(false);
  }

  function saveCallLogFilterParams(updatedFilterParams) {
    const { userId } = props.auth.data.user;
    props.setCallLogFilter(updatedFilterParams, userId);
  }

  async function goToPreviousPage() {
    const updatedFilterParams = clone(filterParams);
    setLoading(true);
    const previousStart = updatedFilterParams.from - updatedFilterParams.size;
    if (previousStart >= 0) {
      updatedFilterParams.from = previousStart;
      saveCallLogFilterParams(updatedFilterParams);
    }
    document.getElementById("InnerContainer").scrollTo(0, 0);
    setLoading(false);
  }

  async function goToNextPage() {
    const updatedFilterParams = clone(filterParams);
    setLoading(true);
    const nextStart = updatedFilterParams.from + updatedFilterParams.size;
    if (nextStart < props.callLog.calls.totalHits) {
      updatedFilterParams.from = nextStart;
      saveCallLogFilterParams(updatedFilterParams);
    }
    document.getElementById("InnerContainer").scrollTo(0, 0);
    setLoading(false);
  }

  return (
    <>
      {showNewDataTooltip && <NewDataTooltip onClick={handleShowNewData} />}
      <CallTablePageRender
        calls={props.callLog.calls ? props.callLog.calls.calls : []}
        totalHits={totalHits}
        onApplyFilter={onApplyFilter}
        filterParams={filterParams}
        sort={sort}
        startIndex={startIndex}
        endIndex={endIndex}
        classes={props.classes}
        goToPreviousPage={goToPreviousPage}
        goToNextPage={goToNextPage}
        resetFilter={resetFilter}
        userNames={props.screen.metaData.userNames}
        deletedUserNames={props.screen.metaData.deletedUserNames}
        managers={props.screen.metaData.managers}
        categories={props.screen.metaData.categories}
        competitors={props.screen.metaData.competitors}
        loading={loading}
        status={props.screen.metaData.status}
        types={props.screen.metaData.types}
        exportToExcel={exportToExcel}
        removeFilterValue={handleRemoveFilterValue}
        scoringTemplates={props.screen.metaData.scoringTemplates}
      />
    </>
  );
}

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { setCallLogFilter, setFilteredCalls },
)(withExplainer(withErrorHandling((CallTablePage))));

export const fillWithDefaults = paramsWithoutDefaults => {
  const paramsWithDefaults = clone(paramsWithoutDefaults, true);
  Object.keys(emptyFilterParams).map(key => {
    if (!has(paramsWithoutDefaults, key)) {
      paramsWithDefaults[key] = emptyFilterParams[key];
    }
  });
  return paramsWithDefaults;
};

export const removeDefaults = paramsWithDefaults => {
  const paramsWithoutDefaults = clone(paramsWithDefaults, true);
  Object.keys(emptyFilterParams).map(key => {
    if (isEqual(emptyFilterParams[key], paramsWithoutDefaults[key])) {
      delete paramsWithoutDefaults[key];
    }
  });
  return paramsWithoutDefaults;
};

const hashPrefix = "filterQuery=";

const getFilterParamsFromHash = hash => {
  const hashLocal = hash.replace(`#${hashPrefix}`, "");
  const paramsWithoutDefaults = JSON.parse(decodeURIComponent(hashLocal));
  return fillWithDefaults(paramsWithoutDefaults);
};

export const getHashFromFilterParams = filterParams => {
  const paramsWithoutDefaults = removeDefaults(filterParams);
  return hashPrefix + JSON.stringify(paramsWithoutDefaults);
};
