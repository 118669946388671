import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ScorecardSkeleton() {
    return (
        <div className='p-5'>
            <Skeleton count={2} height={40} />

            <Skeleton height={2} className='mt-5' />

            {new Array(4).fill("dummy").map((e, index) => {
                return <div className='mb-8' key={"score-card-loader-question" + index}>
                    <Skeleton height={20} width={"70%"} />
                    <Skeleton height={20} width={"15%"} className='ml-14' />
                    <div>
                        <Skeleton height={20} width={"50%"} />
                    </div>
                </div>
            }
            )

            }
            <Skeleton height={40} width={"45%"} className='mr-8 mt-14' />
            <Skeleton height={40} width={"45%"} />

        </div>
    )
}
