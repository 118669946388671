import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, withStyles } from "@material-ui/core";

const styles = {
  selectRoot: {
    width: "100%",
    position: "relative",
    marginTop: 3,
  },
  disabled: {
    color: 'grey'
  }
};

const CustomSelect = props => {
  const { classes } = props
  return (
    <Select
      fullWidth
      className={classes.selectRoot}
      onChange={props.onChange}
      displayEmpty
      value={props.value}
      disabled={props.disabled}
    >
      {props.placeholder != "" &&
        <MenuItem value="" disabled>{props.placeholder}</MenuItem>
      }
      {props.menuItems.map((menuItem, i) => {
        return (<MenuItem key={i} value={menuItem.value}>{menuItem.label}</MenuItem>)
      })}
    </Select>

  );
};

CustomSelect.propTypes = {
  menuItems: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string
};

CustomSelect.defaultProps = {
  menuItems: [],
  value: null,
  placeholder: ""
};

export default withStyles(styles)(CustomSelect);
