import React from 'react';
import FolderAccordianItem from "./FolderAccordianItem";
import { MAX_LEVEL_OF_FOLDERS } from 'app/containers/Folder'

const PREFIX = "ACCORDIAN__"

const ROOT_FOLDER = {
    id: 'ROOT',
    name: 'Home',
    parentFolder: null,
    hasChildren: true,
}

export default function FolderAccordian({ folderData, setSelectedFolder, selectedFolder, parentId = 'ROOT', level = 0, currentFolderId, type }) {
    const folders = folderData
        ?.filter((item) => item.parentFolder === parentId)
        ?.sort((a, b) => (a.name > b.name ? 1 : -1));

    let disabled = false;

    if (level === 0) {
        return (
            <FolderAccordianItem key={PREFIX + "ROOT"} id={"ROOT"} folder={ROOT_FOLDER} level={level} selectedFolder={selectedFolder} onFolderClick={setSelectedFolder} disabled={disabled}>
                <FolderAccordian folderData={folderData} parentId={"ROOT"} level={level + 1} setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} currentFolderId={currentFolderId} type={type} />
            </FolderAccordianItem>
        )
    }

    // NOTE: Don't show folders after 2nd level
    if (!folders.length || level == MAX_LEVEL_OF_FOLDERS + 1) return null;

    return (
        <>
            {folders.map((folder) => {
                if (type === 'Folder') {
                    // Disable moving a folder to itself.
                    disabled = currentFolderId === folder.id;

                    // NOTE: This is a hack to disable moving a folder 2 levels deep.
                    if (level === MAX_LEVEL_OF_FOLDERS) {
                        disabled = true;
                    }
                }

                return (
                    <FolderAccordianItem key={PREFIX + folder.id} id={folder.id} folder={folder} level={level} selectedFolder={selectedFolder} onFolderClick={setSelectedFolder} disabled={disabled}>
                        <FolderAccordian folderData={folderData} parentId={folder.id} level={level + 1} setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} currentFolderId={currentFolderId} type={type} />
                    </FolderAccordianItem>
                )
            })}
        </>
    );
}