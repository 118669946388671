const mentionInputStyle = {
	control: {
		backgroundColor: '#fff',
		height: '50px',
		fontSize: 12,
		fontColor: '#808080',
		fontWeight: 'normal',
	},

	highlighter: {
		overflow: 'hidden',
	},

	input: {
		margin: 0,
	},

	'&singleLine': {
		control: {
			display: 'inline-block',
			width: 130,
		},

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},

		input: {
			padding: 1,

		},
	},

	'&multiLine': {
		control: {

		},

		highlighter: {
			padding: 0,
			color: '#2F86E6'
		},

		input: {
			padding: 0,
			minHeight: 40,
			outline: 0,
			border: 0,
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 10,
			overflowY: 'auto',
			maxHeight: '80px'
		},

		item: {
			padding: '5px 15px',
			fontSize: '12',
			backgroundColor: 'white',
			color: '#2F86E6',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#2F86E6',
				color: 'white'
			},
		},
	},
}

const mentionInputStyleDisabled = {
	control: {
		pointerEvents: "none",
		fontSize: 13,
		fontWeight: 'normal',
		color: 'black',
		width: '100%'
	},

	highlighter: {
		overflow: 'hidden',

		substring: {
			visibility: 'visible'
		}
	},

	input: {
		margin: 0,
		pointerEvents: "none",
	},

	'&singleLine': {
		control: {
			display: 'inline-block',
			width: 130,
		},

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},

		input: {
			padding: 1,
		},
	},

	'&multiLine': {
		control: {

		},

		highlighter: {
			padding: 0,
			color: 'darkblue',
			zIndex: 1,
			top: "-3px"
		},

		input: {
			pointerEvents: "none",
			padding: 0,
			minHeight: 30,
			outline: 0,
			border: 0,
			color: "#696969",
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 10,
			overflowY: 'auto',
			maxHeight: '80px'
		},

		item: {
			padding: '5px 15px',
			fontSize: '12',
			color: '#2F86E6',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#2F86E6',
				color: 'white'
			},
		},
	},
}

const mentionInputStyleDisabledReply = {
	control: {
		pointerEvents: "none",
		fontSize: 12,

		fontWeight: 'normal',
		fontColor: '#808080',
		width: '100%'
	},

	highlighter: {
		overflow: 'hidden',
	},

	input: {
		margin: 0,
		pointerEvents: "none",
	},

	'&singleLine': {
		control: {
			display: 'inline-block',
			width: 130,
		},

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},

		input: {
			padding: 1,
		},
	},

	'&multiLine': {
		control: {

		},

		highlighter: {
			padding: 0,
			color: '#2F86E6',

		},

		input: {
			pointerEvents: "none",
			padding: 0,
			minHeight: 30,
			outline: 0,
			border: 0,
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 10,
			overflowY: 'auto',
			maxHeight: '80px'
		},

		item: {
			padding: '5px 15px',
			fontSize: '12',
			color: '#2F86E6',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#2F86E6',
				color: 'white'
			},
		},
	},
}

const mentionInputStyleReply = {
	control: {
		backgroundColor: '#fff',
		fontSize: 12,

		fontColor: '#808080',
		fontWeight: 'normal',
		border: "0.4px solid #808080",
		margin: '7px',
	},

	highlighter: {
		overflow: 'hidden',
	},

	input: {
		margin: "5px",
	},

	'&singleLine': {
		control: {
			display: 'inline-block',
			width: 130,
		},

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},

		input: {
			padding: 1,
		},
	},

	'&multiLine': {
		control: {

			width: "auto"
		},

		highlighter: {
			padding: 0,
			color: '#2F86E6'
		},

		input: {
			padding: 0,
			minHeight: 30,
			outline: 0,
			border: 0,
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 10,
			overflowY: 'auto',
			maxHeight: '80px'
		},

		item: {
			padding: '5px 15px',
			fontSize: '12',
			backgroundColor: 'white',
			color: '#2F86E6',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#2F86E6',
				color: 'white'
			},
		},
	},
}

const mentionStyleReply = {
	color: '#2F86E6 !important'
}

const mentionStyle = {
	backgroundColor: 'white',
	color: '#2F86E6 !important'
}

const mentionInputStylesforEmailEditor = {
	control: {
		backgroundColor: '#fff',
		height: '100%',
		fontSize: 14,
		fontColor: '#808080',
		fontWeight: 'normal',
	},

	highlighter: {
		overflow: 'hidden',
	},

	input: {
		margin: 0,
	},

	'&singleLine': {
		control: {
			display: 'inline-block',
			width: 130,
		},

		highlighter: {
			padding: 1,
			border: '2px inset transparent',
		},

		input: {
			padding: 1,

		},
	},

	'&multiLine': {
		control: {

		},

		highlighter: {
			padding: 0,
			color: '#2F86E6'
		},

		input: {
			padding: 0,
			minHeight: 40,
			outline: 0,
			border: 0,
		},
	},

	suggestions: {
		list: {
			backgroundColor: 'white',
			borderRadius: "6px",
			border: '1px solid rgba(0,0,0,0.15)',
			fontSize: 12,
			overflowY: 'auto',
			maxHeight: '120px'
		},

		item: {
			padding: '6px 20px',
			fontSize: '14',
			backgroundColor: 'white',
			color: '#374151',
			borderBottom: '1px solid rgba(0,0,0,0.15)',
			'&focused': {
				backgroundColor: '#E5F0FD'
			},
		},
	},
}

export {
	mentionStyle,
	mentionInputStyle,
	mentionInputStyleDisabled,
	mentionInputStyleReply,
	mentionStyleReply,
	mentionInputStyleDisabledReply,
	mentionInputStylesforEmailEditor
}