import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { Button, TextLarge, TextBase, TextSM } from 'app/designSystem';
import { CloseIcon, BellRingingIcon, ThreeDotMenuIcon, ArrowDownIcon } from 'app/designSystem/icons';
import { updateFolderMetadata, addSharedUsers, removeSharedUsers } from 'app/utils/network/gametapes';
import SelectWithSearchNew from "app/components/filter/SelectWithSearchNew";
import ProfileImage from 'app/components/common/ProfileImage';
import useDataHelper from "app/utils/hooks/useDataHelper";
import useSnackbar from "app/utils/hooks/useSnackbar"
import { DeleteUserMenu, FolderAccessMenu } from "app/components/gameTapesLatest/menu";
import { setGameTapesFolderData } from 'app/actions/gametapes'


const UserRow = ({ userId, subscribed, handleSubscribe, handleRemove }) => {
    const deleteMenuRef = useRef(null);
    const [isDeleteMenuOpen, setDeleteMenuOpen] = useState(false);
    const { getUserName } = useDataHelper();
    const [subscribedState, setSubscribedState] = useState(subscribed);

    return (
        <div className="flex items-center justify-between mb-2 h-10">
            <div className="flex items-center">
                <ProfileImage userId={userId} size={30} disableTooltip={true} />
                <TextBase className="text-gray-500 ml-2">{getUserName(userId)}</TextBase>
            </div>
            <div className="flex items-center">
                <Button variant="tertiary" role="icon-btn" className={classnames("hover:bg-purple-50 !p-1 mr-2 rounded", subscribedState ? "!bg-purple-50 !text-purple-600" : "!text-gray-500")} onClick={() => { handleSubscribe(!subscribedState, userId); setSubscribedState(!subscribedState) }}>
                    <BellRingingIcon />
                </Button>

                <ThreeDotMenuIcon onClick={() => setDeleteMenuOpen(!isDeleteMenuOpen)} ref={deleteMenuRef} />
            </div>
            <DeleteUserMenu isOpen={isDeleteMenuOpen} anchorEl={deleteMenuRef.current} handleClose={() => setDeleteMenuOpen(false)} handleRemove={() => { handleRemove(userId); setDeleteMenuOpen(false) }} />
        </div>
    )
}

export default function ShareFolderModal({ open, onClose, handleSubscribe, folderId }) {
    const formRef = useRef();
    const accessMenuRef = useRef(null);
    const dispatch = useDispatch();

    const { setSnackbar } = useSnackbar()

    const currentUserId = useSelector(store => store.auth.userId);
    const customer = useSelector(store => store.auth.stringsCustomer);
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const cachedFoldersData = useSelector(state => state.gametapes.cachedFoldersData);

    const [isPrivate, setIsPrivate] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isAccessMenuOpen, setIsAccessMenuOpen] = useState(false);

    useEffect(() => {
        if (cachedFoldersData[folderId]) {
            setIsPrivate(cachedFoldersData[folderId].isPrivate);
        }
    }, [cachedFoldersData])

    const handleSubmit = async () => {
        const newSharedUsers = [...selectedUsers, ...cachedFoldersData[folderId]?.sharedUsers]
        addSharedUsers({ folderId, sharedUsers: selectedUsers })
        setSelectedUsers([]);
        dispatch(setGameTapesFolderData(folderId, {
            ...cachedFoldersData[folderId],
            sharedUsers: newSharedUsers
        }))
    };

    const handleRemove = async (userId) => {
        const newSharedUsers = cachedFoldersData[folderId]?.sharedUsers?.filter(user => user !== userId);
        const newSubscribedUsers = cachedFoldersData[folderId]?.subscribedUsers?.filter(user => user !== userId);
        removeSharedUsers({ folderId, userId });
        setSelectedUsers([]);
        dispatch(setGameTapesFolderData(folderId, {
            ...cachedFoldersData[folderId],
            sharedUsers: newSharedUsers,
            subscribedUsers: newSubscribedUsers
        }))
    };

    const handleAccessChange = useCallback((isPrivate) => {
        setIsPrivate(isPrivate);
        updateFolderMetadata({ ...cachedFoldersData[folderId], folderId, isPrivate });
    }, [cachedFoldersData, folderId])

    const handleShare = () => {
        navigator.clipboard.writeText(window.location.href)
        setSnackbar("Link copied to clipboard", "SUCCESS");
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className="flex justify-center items-center"
        >
            <div className="flex flex-col p-0 bg-white rounded-lg w-[42rem] outline-none" style={{
                maxHeight: "calc(100vh - 8.25rem)",
            }}>
                <div className="px-8 py-4">
                    <div className="flex justify-between w-full">
                        <TextLarge className="text-gray-900 font-medium">Share Folder</TextLarge>
                        <CloseIcon className="cursor-pointer" onClick={onClose} />
                    </div>
                </div>
                <hr />
                <div ref={formRef} className="px-8 py-4">
                    <div className="flex items-center">
                        <div className="w-[30rem]">
                            <SelectWithSearchNew
                                key="agentNames"
                                fieldName="agentNames"
                                value={selectedUsers}
                                data={userNames.filter(user => !cachedFoldersData[folderId]?.sharedUsers?.includes(user.value) || user.value === currentUserId)}
                                label="Select Users to share the folder with"
                                placeholder="Type users name"
                                handleChange={userIds => setSelectedUsers(userIds?.length > 0 ? userIds.map(rep => rep?.value) : [])}
                                dontShowCloseIcon={true}
                                isInCallUploadForm={true}
                                popoverContainerRef={formRef}
                                joinPhrase=", "
                            />
                        </div>
                        <Button className="ml-2 w-48" onClick={handleSubmit}>Invite to folder </Button>
                    </div>
                </div>

                <div className="px-8 py-4">
                    <div className="flex justify-between items-center bg-wingman-white rounded py-2 px-4">
                        <div className="flex">
                            {customer.consentPageConfig.companyLogoUrl ? <div className="w-12 h-12 flex justify-center items-center mr-1">
                                <img src={customer.consentPageConfig.companyLogoUrl} alt="share folder icon" className="w-full h-auto" />
                            </div> : null}
                            <div className="flex flex-col justify-between bg-wingman-white p-1.5">
                                <TextBase>{customer?.name}</TextBase>
                                <TextBase className="text-gray-500">{userNames.length} members</TextBase>
                            </div>
                        </div>
                        <div className="flex" ref={accessMenuRef} onClick={() => setIsAccessMenuOpen(!isAccessMenuOpen)}>
                            <TextBase>{isPrivate ? "Private" : "Public"}</TextBase>
                            <ArrowDownIcon />
                        </div>
                    </div>
                </div>

                <div className="px-8 py-4 overflow-y-auto">
                    <TextBase className="text-gray-900 font-medium mb-4">Members</TextBase>
                    {
                        cachedFoldersData[folderId].sharedUsers && cachedFoldersData[folderId].sharedUsers.length > 0 && cachedFoldersData[folderId].sharedUsers.map(userId => (
                            <UserRow key={userId} handleSubscribe={handleSubscribe} userId={userId} handleRemove={handleRemove} subscribed={cachedFoldersData[folderId]?.subscribedUsers?.includes(userId)} />
                        ))
                    }
                </div>

                <hr />

                <div className="px-8 py-4">
                    <div className="flex justify-end mt-2">
                        <Button className="w-36" onClick={handleShare}>Copy Link</Button>
                    </div>
                </div>

                <FolderAccessMenu isOpen={isAccessMenuOpen} anchorEl={accessMenuRef.current} folderIsPrivate={cachedFoldersData[folderId]?.isPrivate} handleClose={() => setIsAccessMenuOpen(false)} handleAccessChange={handleAccessChange} />
            </div>
        </Modal>
    )
}