import React, { useState } from "react";
import { connect } from "react-redux";
import IntegrationsIcon from "app/styles/assets/images/icons/settingsSidebar/IntegrationsSettingsIcon.svg";
import RealTimeIcon from "app/styles/assets/images/icons/settingsSidebar/RealTimeSettingsIcon.svg";
import AdminSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/AdminSettingsIcon.svg";
import UserSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/UserSettingsIcon.svg";
import NotificationSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/NotificationSettingsIcon.svg";
import RoleManagementIcon from "app/styles/assets/images/icons/settingsSidebar/RoleManagementIcon.svg";
import CrmSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/CrmSettingsIcon.svg";
import DownloadsIcon from "app/styles/assets/images/icons/settingsSidebar/DownloadsIcon.svg";
import ProfileSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/ProfileSettingsIcon.svg";
import BattleCardsIcon from "app/styles/assets/images/sidebar/Wingman.svg";
import PermissionsSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/PermissionsSettingsIcon.svg";
import KeywordsSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/KeywordsSettingsIcon.svg";
import RecorderSettingsIcon from "app/styles/assets/images/icons/settingsSidebar/RecorderSettingsIcon.svg";
import Dictionary from "app/styles/assets/images/icons/settingsSidebar/Dictionary.svg"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ScorecardsSettingsIcon } from "app/designSystem";


const SettingsSidebar = props => {


  return (
    <div className="Settings__sidebar">
      {props.activeSection == "USER" &&
        <div className="Settings__sidebar-section">
          <SidebarSectionHeader
            icon={UserSettingsIcon}
            label="Profile Settings"
          />
          <div>
            <Tab
              label="My Account"
              index="profile"
              icon={ProfileSettingsIcon}
              onTabSelect={props.onTabSelect}
              activeTab={props.activeSettingsTab}
            />
            {props.auth.isRecording && (
              <Tab
                label="Integrations"
                index="integrations"
                icon={IntegrationsIcon}
                onTabSelect={props.onTabSelect}
                activeTab={props.activeSettingsTab}
              />
            )}
            <Tab
              label="Notifications"
              index="notifyUser"
              icon={NotificationSettingsIcon}
              onTabSelect={props.onTabSelect}
              activeTab={props.activeSettingsTab}
            />
            {props.auth.isRecording && (<Tab
              label="Real Time Coaching"
              index="wingmanUser"
              icon={RealTimeIcon}
              onTabSelect={props.onTabSelect}
              activeTab={props.activeSettingsTab}
            />)}
            <Tab
              label="Downloads"
              index="downloads"
              icon={DownloadsIcon}
              onTabSelect={props.onTabSelect}
              activeTab={props.activeSettingsTab}
            />
          </div>
        </div>
      }
      {props.activeSection == "ADMIN" &&
        <>
          {(props.auth.isAdmin || props.auth.isManager) &&
            <div className="Settings__sidebar-section">
              <SidebarSectionHeader
                icon={AdminSettingsIcon}
                label="Workspace Settings"
              />
              <div>
                {(props.auth.data.user.stringsCustomer.featuresGated.ssoEnabled || props.auth.data.user.stringsCustomer.featuresGated.ssoDirectoryEnabled) && props.auth.isAdmin &&
                  <Tab
                    label="SSO and Provisioning"
                    index="sso"
                    icon={IntegrationsIcon}
                    onTabSelect={props.onTabSelect}
                    activeTab={props.activeSettingsTab}
                  />
                }
                {props.persisted.onboardingFlowStatus.customerLevelIntegrationsPresent && props.auth.isAdmin &&
                  <Tab
                    label="Integrations"
                    index="adminIntegrations"
                    icon={IntegrationsIcon}
                    onTabSelect={props.onTabSelect}
                    activeTab={props.activeSettingsTab}
                  />
                }

                <Tab
                  label="Notifications"
                  index="notifyAdmin"
                  icon={NotificationSettingsIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />


                <Tab
                  label="Real Time Coaching"
                  index="wingmanAdmin"
                  icon={RealTimeIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />

                <Tab
                  label="Recorder Settings"
                  index="recorder"
                  icon={RecorderSettingsIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />

                <Tab
                  label="Permission Settings"
                  index="permissions"
                  icon={PermissionsSettingsIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />
                {props.auth.data.user.stringsCustomer.featuresGated.crmCustomFields && <Tab
                  label="CRM Settings"
                  index="crm"
                  onTabSelect={props.onTabSelect}
                  icon={CrmSettingsIcon}
                  activeTab={props.activeSettingsTab}
                />}
                <Tab
                  label="Manage Users"
                  index="users"
                  icon={RoleManagementIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />

                <Tab
                  label="Autocorrect"
                  index="autocorrect"
                  icon={Dictionary}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />
                <Tab
                  label="Manage Topics"
                  index="keywords"
                  icon={KeywordsSettingsIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />
                {props.auth.data.user.stringsCustomer.featuresGated.scorecard &&
                  <Tab
                    label="Scorecard Settings"
                    index="scorecardSettings"
                    icon={ScorecardsSettingsIcon}
                    onTabSelect={props.onTabSelect}
                    activeTab={props.activeSettingsTab}
                  />
                }
                {props.auth.data.user.stringsCustomer.featuresGated.cueCards && <Tab
                  label="Manage Battle Cards"
                  index="battleCardsAdmin"
                  icon={BattleCardsIcon}
                  onTabSelect={props.onTabSelect}
                  activeTab={props.activeSettingsTab}
                />}
              </div>
            </div>}
        </>
      }

    </div>
  );
};

const SidebarSectionHeader = ({ icon, label, expanded, toggleExpansion }) => {
  return (
    <div onClick={toggleExpansion} className="Settings__sidebar-sectionHeader">
      <img className="Settings__sidebar-sectionHeaderIcon" src={icon} />
      <span className="Settings__sidebar-sectionHeading">{label}</span>
      {false && expanded && <ExpandLessIcon className="Settings__sidebar-sectionExpansionIcon" />}
      {false && !expanded && <ExpandMoreIcon className="Settings__sidebar-sectionExpansionIcon" />}
    </div>
  );
}

const Tab = ({
  activeTab, index, label, onTabSelect, icon
}) => {
  return (
    <div
      onClick={() => onTabSelect(index)}
      className={activeTab === index ? "Settings__sidebarButton Settings__sidebarButtonActive bg-brand-blue-4" : "Settings__sidebarButton"}
    >
      <img src={icon} className={activeTab === index ? "Settings__sidebarIcon Settings__sidebarIconActive" : "Settings__sidebarIcon"} />
      <span
        className={activeTab === index ? "Settings__sidebarText Settings__sidebarTextActive text-brand-blue-3" : "Settings__sidebarText"}

      >
        {label}
      </span>
    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  {},
)(SettingsSidebar);