import React from "react";
import ReactTooltip from "react-tooltip";
import { _formatSecondsToMinutes, _formatDateToCallCardDateFormat, _formatTalkListenRatio } from "../../../utils/helpers";

const colorByCallType = {
    ZOOM: "Blue",
    GOOGLE_MEET: "Green",
    HUBSPOT: "Orange",
    HUBSPOT_LIVE: "Orange"
}

export const CallPreviewCustomerSection = ({ clientTlRatios }) => {
    return (

        <div className="CallPreview__summaryContainer__rowItem">
            {clientTlRatios.length > 0 && <span className="CallPreview__summaryContainer__header">Customers</span>}
            {clientTlRatios.length > 0 && (
                <div className="CallPreview__summaryContainer__sectionList">
                    <div className="CallPreview__summaryContainer__sectionRow">
                        <div className=
                            "CallPreview__summaryContainer__clientTlRatioDiv">
                            <div className="CallPreview__summaryContainer__clientTlRatioContactDetails">
                                <div className="CallPreview__summaryContainer__clientTlRatioValue">
                                    <span className="CallPreview__summaryContainer__clientTlRatioText">{_formatTalkListenRatio(clientTlRatios[0].tlRatio)}</span>
                                    <div className="CallPreview__summaryContainer__clientTlRatioLineBackground">
                                        <div className="CallPreview__summaryContainer__clientTlRatioLine" style={{ width: ((clientTlRatios[0].tlRatio * 100) + "%") }} />
                                    </div>
                                </div>
                                <div className="CallPreview__summaryContainer__clientTlRatioContactContainer">
                                    <span className="CallPreview__summaryContainer__clientTlRatioContactName">{clientTlRatios[0].name}</span>
                                    <span className="CallPreview__summaryContainer__clientTlRatioJobTitle">{(clientTlRatios[0].jobTitle || "-")}</span>
                                </div>
                            </div>
                        </div>
                        {clientTlRatios.length > 1 &&
                            <div className="CallPreview__summaryContainer__clientTlRatioAdditionals">
                                <span data-tip data-for={"AdditionalCustomers"}><u>{"+" + (clientTlRatios.length - 1) + (clientTlRatios.length == 2 ? " Other" : " Others")}</u></span>
                                <ReactTooltip place="right" id={"AdditionalCustomers"} type="light" effect="solid" className="WhiteTooltip">
                                    {clientTlRatios.slice(1).map((clientTlRatio, i) => {
                                        return (
                                            <div>
                                                <div className="CallPreview__summaryContainer__clientTlRatioDiv">
                                                    <div className="CallPreview__summaryContainer__clientTlRatioContactDetails">
                                                        <div className="CallPreview__summaryContainer__clientTlRatioValue">
                                                            <span className="CallPreview__summaryContainer__clientTlRatioText">{_formatTalkListenRatio(clientTlRatio.tlRatio)}</span>
                                                            <div className="CallPreview__summaryContainer__clientTlRatioLineBackground">
                                                                <div className="CallPreview__summaryContainer__clientTlRatioLine" style={{ width: ((clientTlRatio.tlRatio * 100) + "%") }} />
                                                            </div>
                                                        </div>
                                                        <div className="CallPreview__summaryContainer__clientTlRatioContactContainer">
                                                            <span className="CallPreview__summaryContainer__clientTlRatioContactName">{clientTlRatio.name}</span>
                                                            <span className="CallPreview__summaryContainer__clientTlRatioJobTitle">{(clientTlRatio.jobTitle || "-")}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </ReactTooltip>
                            </div>

                        }
                    </div>
                </div>
            )}
        </div>
    );
}

CallPreviewCustomerSection.propTypes = {}
CallPreviewCustomerSection.defaultProps = {}




