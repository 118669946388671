import React from 'react';
import { TextBase, TextLarge } from 'app/designSystem'
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import useDataHelper from 'app/utils/hooks/useDataHelper';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

export default function TemplateCard({ className, template, ...props }) {

    const [createdBy, setCreatedBy] = useState("");
    const { getUserName } = useDataHelper();
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const [templateCreatorUserName, setTemplateCreatorUserName] = useState(null);

    useEffect(() => {
        let templateCreatedBy = "By ";
        if (template.popularTemplate) {
            if (template.author) {
                templateCreatedBy += template.author
            }
            else {
                templateCreatedBy += " Wingman"
            }
        }
        else if (template.createdBy) {
            let templateCreatorUserNameLocal = getUserName(template.createdBy)
            setTemplateCreatorUserName(templateCreatorUserNameLocal)
            if (templateCreatorUserNameLocal) templateCreatedBy += templateCreatorUserNameLocal
        }
        else {
            templateCreatedBy = ""
        }
        setCreatedBy(templateCreatedBy)
    }, [template, userNames])

    return (
        <div className={classNames(`flex flex-col justify-start pt-4 pb-3 px-4 m-4 ml-0 mb-8 bg-gray-50 rounded-lg cursor-pointer border border-coolGray-200 transition-all hover:bg-white hover:shadow-md  `, { "w-[20rem] h-30": props.forSettings }, { "w-[25rem] h-48": !props.forSettings }, className)} {...props} data-adoption-tracking="template-card" >
            <div className="mb-2 flex justify-between items-start h-[15%]">
                <TextLarge className="overflow-hidden h-full line-clamp-2">{template.name}</TextLarge>
            </div>
            {template.description &&
                <div className="mb-2 h-[40%]">
                    <TextBase className="text-gray-500 flex break-words max-w-full overflow-ellipsis line-clamp-3">{template.description}</TextBase>
                </div>
            }
            <div className={classNames("flex flex-row justify-between items-center", { "mt-5": template.description }, { "mt-2": !template.description })}>
                <div className="flex items-center ">
                    <TextBase textColor="text-gray-500" className="mr-6">Questions : {template.questions.length}</TextBase>
                </div>
            </div>
            <div className="flex flex-row justify-between items-center mt-2">
                <div className="flex items-center">
                    {(template.createdBy && !templateCreatorUserName) ?
                        <div className="">
                            <Skeleton width={120} height="text-base" />
                        </div> :
                        <TextBase textColor="text-gray-500" className="mr-6"> {createdBy}</TextBase>
                    }
                </div>
            </div>
        </div>
    )
}