import { Modal } from "@material-ui/core";
import { ModalType, ModalTypeSelector } from './ToastTypes';
import { getModalPosition } from './helper'
import PropTypes from 'prop-types';

const StickyToast = ({
    open,
    message,
    subMessage,
    onClose,
    onClick,
    onMouseEnter,
    onMouseLeave,
    position = 'top-right',
    progress,
    type,
    customStyle,
    children,
    ...props
}) => {

    const modalPositionStyle = getModalPosition(position)

    return (
        <Modal
            open={open}
            onClose={onClose}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            BackdropProps={{
                style: {
                    backgroundColor: "rgba(0,0,0,0)",
                    position: "initial"
                }
            }}
            style={{
                width: "26em",
                height: "6.5em",
                boxShadow: "0px 6px 14px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: "10px",
                cursor: "pointer",
                ...modalPositionStyle,
                ...customStyle
            }}
            disableBackdropClick
            disableEscapeKeyDown
            {...props}
        >
            <ModalTypeSelector type={type} message={message} subMessage={subMessage} progress={progress} children={children} />
        </Modal>
    );
}

export default StickyToast;

StickyToast.defaultProps = {
    open: false,
    message: "",
    subMessage: "",
    onClose: () => { },
    onClick: () => { },
    onMouseEnter: () => { },
    onMouseLeave: () => { },
    position: "top",
    progress: 0,
    type: "info",
    customStyle: {},
    children: null
}

StickyToast.propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    position: PropTypes.oneOf(["top", "bottom", "left", "right", "top-left", "top-right", "bottom-left", "bottom-right"]),
    progress: PropTypes.number,
    type: PropTypes.oneOf([
        ModalType.LOADING,
        ModalType.SUCCESS,
        ModalType.ERROR,
        ModalType.CUSTOM
    ]),
    customStyle: PropTypes.object,
    children: PropTypes.node
}