import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_FILTERED_CALLS_IF_EMPTY,
  SET_UPCOMING_CALLS,
  SET_DEFAULT_MY_CALL_TAB,
  SET_PAST_CALLS, SET_BATTLECARDS,
  SET_AGENTS_DATA, SET_TRACKERS_DATA,
  SET_MANAGER_DASHBOARD_SORT_DATA,
  SET_DEALS
} from "app/actions/actionTypes";
import {
  getManagerTrackersChartData,
  getAgentsTableData
} from "app/components/dashboards/helpers";
import {
  getBattlecards,
  getFilteredCalls,
  getOldTeamDashboardData,
  getCallCountData,
  getUpcomingCalls,
  getCompletedCalls,
  sendRequest
} from "app/utils/network";
import { GLOBAL } from "../../constants";
import { groupBattlecardsIntoCategories } from "../../components/BattleCard/helper";
import xss from 'xss';

const useDataFetcher = ({ screenName }) => {
  const dispatch = useDispatch();
  const userId = useSelector(store => store.auth.userId);
  const filterParams = useSelector(store => store.callLog.filterParams);
  const managerFilterParams = useSelector(store => store.screen.managerDashboardFilter);
  const showUnrecordedInMyCallsPage = useSelector(store => store.pageData.showUnrecordedInMyCallsPage);
  const dealsFilter = useSelector(store => store.screen.dealsFilter);
  let requestIdleCallback = func => { func(); };
  let cancelIdleCallback = () => { };
  if (window.requestIdleCallback) {
    requestIdleCallback = window.requestIdleCallback;
    cancelIdleCallback = window.cancelIdleCallback;
  }

  useEffect(() => {
    let handle = null;
    setTimeout(() => {
      handle = requestIdleCallback(fetchData);
    }, 5000);
    return (() => {
      if (handle)
        cancelIdleCallback(handle);
    });
  }, []);

  function fetchData() {
    fetchManagerDashboardData();
    // fetchWingmanData();
  }

  async function fetchCallLogData() {
    console.log("getting initial data for ", filterParams);
    const searchResults = await getFilteredCalls(filterParams);
    setFilteredCalls(searchResults);
  }

  async function fetchManagerDashboardData() {
    const [dashboardData, callCountData] = await Promise.all([
      getOldTeamDashboardData(managerFilterParams),
      getCallCountData(managerFilterParams)
    ]);
    setTrackersData(getManagerTrackersChartData(dashboardData));
    setAgentsData(getAgentsTableData(dashboardData, callCountData));
    setManagerDashboardSortData({ callCount: "desc" });
  }

  async function fetchMyCallsData() {
    const [upcomingCalls, pastCalls] = await Promise.all([getUpcomingCalls(userId, 1), getCompletedCalls(userId, 1, showUnrecordedInMyCallsPage)]);
    if (pastCalls === null || pastCalls.length === 0) {
      setDefaultMyCallTab("UPCOMING");
    }
    setUpcomingCalls({
      hasMore: !(upcomingCalls && upcomingCalls.length < 50),
      calls: upcomingCalls,
      page: 1,
    });
    setPastCalls({
      hasMore: !(pastCalls && pastCalls.calls && pastCalls.calls.length < 50),
      calls: pastCalls.calls,
      page: 1,
    });

  }

  async function fetchDealsData() {
    const dealsLocal = await sendRequest("/search/filter_opportunities", dealsFilter);
    if (dealsLocal.error) {
      return;
    }
    dispatch({ type: SET_DEALS, dealsData: dealsLocal });
  }

  async function fetchWingmanData() {
    const battlecards = await getBattlecards();
    const safeBattlecards = battlecards.map(card => {
      card.htmlContent = xss(card.htmlContent);
      return card;
    });
    const battlecardCategories = groupBattlecardsIntoCategories(safeBattlecards);
    setBattlecards(battlecardCategories);
  }

  function setDefaultMyCallTab(tab) {
    console.log('setting default my call tab', tab);
    dispatch({
      tab,
      type: SET_DEFAULT_MY_CALL_TAB,
    });
  }

  function setFilteredCalls(calls) {
    console.log('setting filteredCalls', calls);
    dispatch({
      calls,
      type: SET_FILTERED_CALLS_IF_EMPTY,
    });
  }

  function setUpcomingCalls(details) {
    dispatch({
      details,
      type: SET_UPCOMING_CALLS,
    });
  }

  function setPastCalls(details) {
    dispatch({
      details,
      type: SET_PAST_CALLS,
    });
  }

  function setBattlecards(battlecards) {
    dispatch({
      battlecards,
      type: SET_BATTLECARDS,
    });
  }

  function setAgentsData(agentsData) {
    dispatch({
      agentsData,
      type: SET_AGENTS_DATA,
    });
  }

  function setTrackersData(trackersData) {
    dispatch({
      trackersData,
      type: SET_TRACKERS_DATA,
    });
  }

  function setManagerDashboardSortData(managerSortData) {
    dispatch({
      managerSortData,
      type: SET_MANAGER_DASHBOARD_SORT_DATA,
    });
  }

  return null;
};

export default useDataFetcher;