import React, { useState } from "react";
import { useHoverListener } from "app/utils/hooks/useHoverListener";
import { _formatSecondsToPlayingTime } from "../../utils/helpers";

export const BehaviourCard = ({
  behaviourCard, leftMargin, onSkipPress, behaviourCardTime, type
}) => {
  const [showBehaviourCardDetails, setShowBehaviourCardDetails] = useState(false);
  useHoverListener(`behaviourCard__${behaviourCard.instanceId}__${behaviourCardTime}`, () => setShowBehaviourCardDetails(true), () => setShowBehaviourCardDetails(false));
  return (
    <div onMouseDown={() => onSkipPress(behaviourCardTime - 3 < 0 ? 0 : behaviourCardTime - 3)} className="Timeline__progress__CardsShown__cardIconDiv" style={{ left: `${leftMargin}%` }}>
      {showBehaviourCardDetails && <div className="cardDetails">
		<b>{type} Card:</b>
		<br />
		<b>Time shown at: </b>
        {`${_formatSecondsToPlayingTime(behaviourCardTime)} s`}
        <br />
      </div>
	  }
	  <span className="behaviourCardIcon" id={`behaviourCard__${behaviourCard.instanceId}__${behaviourCardTime}`}>B</span>
    </div>);
};
