import { useSelector } from "react-redux";

const useDataHelper = () => {
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const stringsCustomer = useSelector(store => store.auth.stringsCustomer);
    const activeDealboard = useSelector(store => store.screen?.activeDealboard);
    const primaryRepId = useSelector(store => store?.call?.details?.callDataDocument?.dashboardMetrics?.dominantRep)

    function getUserName(userId, defaultValue = null) {
        const match = userNames?.find(o => o.value === userId);
        if (!match) return defaultValue;
        return match.label;
    }
    function getUserEmail(userId, defaultValue = null) {
        const match = userNames?.find(o => o.value === userId);
        if (!match) return defaultValue;
        return match.email;
    }

    function reduceWarnings(stage, warnings) {
        if (warnings === null) return [];
        if (stringsCustomer.opportunityLostStages && stringsCustomer.opportunityLostStages.includes(stage)) {
            return [];
        }
        if (stringsCustomer.opportunityWonStages && stringsCustomer.opportunityWonStages.includes(stage)) {
            return [];
        }

        return warnings;
    }

    return { getUserName, getUserEmail, reduceWarnings, primaryRepId, activeDealboard };
};

export default useDataHelper;
