import React, { Suspense, useState, useEffect, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { object } from "prop-types";
import { connect } from "react-redux";
import { Header, Sidebar } from "app/components/layout";
import { NotFound, Loading, ErrorBoundary } from "app/components/common";
import { STACKED, SALES } from "app/constants";
import classNames from "classnames";

import {
  Logout,
  Contact,
  Account
} from "app/containers";
import { AutomaticWingmanDownload } from "app/components/onboarding/WingmanDownload";
import Player from "../components/player/PlayerOld";
import { KeywordsTracking, RoleManagement, Settings } from "../components/settings";
import { fetchDataSafely } from "../utils/dataUtils";
import useMetaDataFetcher from "../utils/hooks/useMetaDataFetcher";
import useDataFetcher from "../utils/hooks/useDataFetcher";
import VoiceFingerprintWarning from "./VoiceFingerprintWarning";
import TrialEnd from "../components/common/TrialEnd";

const DealsViewPromise = import(/* webpackChunkName: 'DealsView' ,webpackPrefetch: true  */ "app/containers/DealsView");
const DealsView = lazy(() => DealsViewPromise);


import {
  TeamDashboard,
  CallsDashboard,
  Call,
  CallTablePage,
  Search,
  NotificationsPage,
  ActivityDashboard,
  AccountView,
  GameTapesLatest,
  FolderView,
  GameTapeView,
  DekstopLoginPage
} from "app/containers/OtherPages";
import { BattleCards } from "app/components/settings";
import CalendarSyncPrompt from "../components/onboarding/CalendarSyncPrompt";

const Dashboard = props => {
  const [customerType, setCustomerType] = useState(fetchDataSafely(props.auth, "data.user.customerType", "SALES"));
  const featuresGated = fetchDataSafely(props.auth, 'data.user.stringsCustomer.featuresGated') || {};
  const [showScreenName, setShowScreenName] = useState(true);

  useMetaDataFetcher();
  useDataFetcher("main");

  useEffect(() => {
    addMobileBrowserTag();
  }, []);

  const isMobileBrowser = () => {
    return /(android)/i.test(navigator.userAgent) || /(iphone|ipod|ipad)/i.test(navigator.userAgent);
  }

  useEffect(() => {
    if (props.screen.screenType === STACKED || window.location.href.indexOf("dealCentral") !== -1 || props.screen.screenName === "Deal Central")
      setShowScreenName(false);
    else
      setShowScreenName(true);
    addMobileBrowserTag();
  }, [props.screen.screenName, props.screen.screenType]);

  let style = "dashboard__content-inner";
  if (props.screen.screenType === STACKED) {
    if (window.location.href.includes("/call/") || window.location.href.includes("/callembed/"))
      style = "dashboard__content-inner-full";
    else style = "dashboard__content-inner-stacked";
  } else if (window.location.href.indexOf("dealCentral") !== -1) {
    style = "dashboard__content-inner-white";
  }
  else if (window.location.href.indexOf("myCalls") !== -1 || props?.screen?.screenName == 'My Calls') {
    style = "dashboard__content-inner-white !p-0";
  }

  function addMobileBrowserTag() {
    if (isMobileBrowser() && (
      window.location.href.includes("/call/")
      || window.location.href.includes("/teamCalls")
      || window.location.href.includes("/myCalls"))
    ) {
      if (!window.location.hash.includes("mobileSession=true"))
        window.location.hash = window.location.hash + "&mobileSession=true";
    }
  }

  function isGated(feature) {
    return featuresGated.hasOwnProperty(feature) && !featuresGated[feature];
  }
  return (
    <div className="dashboard">
      <Loading />
      <Sidebar customerType={customerType} />
      {/* // needed for scroll to work in  flex ,if flex elements layout is   like for example [Sidebar : Main section ] where Sidebar->Set width and Main section needs to be 100% and scrollable ,
      // scroll in css needs a width on parent for horizontal scroll or height for vertical scroll */}
      <div className={`dashboard__content w-0 ${props.screen.screenType === STACKED ? "dashboard__stacked" : ""}`}>
        <ErrorBoundary>
          <Header
            customerType={customerType}
            screenType={props.screen.screenType}
            url={window.location.href}
          />
          {props.auth.stringsCustomer.recordingDisabled && <TrialEnd />}

          <div id="InnerContainer" className={classNames(style, "bg-white")}>
            <Suspense fallback={<Loading loading></Loading>}>
              <Switch>
                <Route exact path={`${props.match.url}dekstop`} component={DekstopLoginPage} />
                {customerType === SALES && !props.auth.isRep && (
                  <Route exact path={props.match.url} component={CallTablePage} />
                )}
                {customerType === SALES && props.auth.isRep && (
                  <Route exact path={props.match.url} component={CallsDashboard} />
                )}
                <Route
                  exact
                  path={`${props.match.url}search`}
                  component={Search}
                />
                <Route
                  exact
                  path={[`${props.match.url}callsdashboard`, `${props.match.url}mycalls`]}
                  component={CallsDashboard}
                />
                <Route
                  exact
                  path={`${props.match.url}gametapes`}
                  component={GameTapesLatest}
                />
                <Route
                  exact
                  path={`${props.match.url}gametapes/folder/:folderId`}
                  render={localProps => <FolderView {...props} {...localProps} />}
                />
                <Route
                  exact
                  path={`${props.match.url}gametapes/video/:gametapeId`}
                  render={localProps => <GameTapeView {...props} {...localProps} />}
                />
                {!isGated('activityDashboard') && <Route
                  exact
                  path={`${props.match.url}activity`}
                  component={ActivityDashboard}
                />}
                <Route
                  exact
                  path={`${props.match.url}notifications`}
                  component={NotificationsPage}
                />
                <Route
                  exact
                  path={`${props.match.url}teamDashboard`}
                  component={TeamDashboard}
                />
                <Route
                  exact
                  path={`${props.match.url}dealsview`}
                  component={DealsView}
                />
                <Route
                  exact
                  path={`${props.match.url}dealcentral`}
                  component={DealsView}
                />
                <Route
                  path={`${props.match.url}dashboard`}
                  component={TeamDashboard}
                />
                <Route
                  exact
                  path={`${props.match.url}keywordsTracking`}
                  component={KeywordsTracking}
                />
                <Route exact path={[`${props.match.url}calllog`, `${props.match.url}teamCalls`]} component={CallTablePage} />
                {!isGated('battleCards') && <Route
                  exact path={`${props.match.url}(battlecards|cuecards)`}
                  render={localProps => <BattleCards {...props} {...localProps} />}
                />}
                <Route
                  exact
                  path={`${props.match.url}call/:callId`}
                  render={localProps => <Call {...props} {...localProps} />}
                />
                <Route
                  exact
                  path={`${props.match.url}callembed/:callId`}
                  render={localProps => <Call {...props} {...localProps} />}
                />
                <Route
                  exact
                  path={`${props.match.url}downloadWingman`}
                  component={AutomaticWingmanDownload}
                />
                <Route
                  exact
                  path={`${props.match.url}account/`}
                  component={AccountView}
                />
                <Route
                  exact
                  path={`${props.match.url}account/:accountId`}
                  component={AccountView}
                />
                <Route
                  exact
                  path={`${props.match.url}contact/:contactInfo`}
                  component={Contact}
                />
                <Route exact path={`${props.match.url}account/:accountId`} component={Account} />
                <Route exact path={`${props.match.url}search/`} component={Search} />
                {props.auth.isAdmin && (
                  <Route
                    exact
                    path={`${props.match.url}roleManagement`}
                    component={RoleManagement}
                  />
                )}
                <Route exact path={`${props.match.url}settings`} component={Settings} />
                <Route
                  exact
                  path={`${props.match.url}onboarding`}
                  render={() => <Redirect to="/onboarding" />}
                />
                <Route
                  exact
                  path={`${props.match.url}login`}
                  render={() => <Redirect to={props.screen.firstUrl} />}
                />
                <Route
                  exact
                  path={`${props.match.url}15cf7b40-f011-43ac-be0a-83639dd67e21`}
                  render={() => <Redirect to="/" />}
                />
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </div>
          <VoiceFingerprintWarning />
          <CalendarSyncPrompt promptResync promptSync={false} />
        </ErrorBoundary>
      </div>
    </div>
  );
}

const mapStateToProps = store => {
  return { ...store };
};

Dashboard.defaultProps = {
  location: {},
  match: {},
};

Dashboard.propTypes = {
  location: object,
  match: object,
};

export default connect(mapStateToProps)(Dashboard);
