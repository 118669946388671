import React, { useState } from "react";
import { sendRequest } from "../../utils/network";
import { useSelector, connect } from "react-redux";
import { fetchDataSafely } from "../../utils/dataUtils";
import { CustomSelect } from "../common";
import { refreshUserAction } from "app/actions/auth";
import useSnackbar from "../../utils/hooks/useSnackbar";

const PermissionSettings = ({ refreshUserAction }) => {
    const customer = useSelector(store => store.auth.stringsCustomer);
    const auth = useSelector(store => store.auth)
    const [callPrivacySetters, setCallPrivacySetters] = useState(fetchDataSafely(customer, "settings.authorizedCallAccessChangers") || "ADMINS");
    const [callDeleters, setCallDeleters] = useState(fetchDataSafely(customer, "settings.authorizedCallDeleters") || "ADMINS");
    const [callDownloaders, setCallDownloaders] = useState(fetchDataSafely(customer, "settings.authorizedCallDownloaders") || "ALL");
    const [callSharers, setCallSharers] = useState(fetchDataSafely(customer, "settings.authorizedCallSharers") || "ALL");
    const [callScorers, setCallScorers] = useState(fetchDataSafely(customer, "settings.authorizedCallScorers") || "MANAGERS_AND_ADMINS")
    const [scoreViewers, setScoreViewers] = useState(fetchDataSafely(customer, "settings.authorizedScoreViewers") || "MANAGERS_AND_SCORED_REP")
    const [signupSetters, setSignupSetters] = useState(fetchDataSafely(customer, "settings.authorizedSignupAccessChangers") || "ALL");
    const rolesForCallActions = [
        { value: "ALL", label: "Everyone" },
        { value: "ADMINS", label: "Admins" },
        { value: "ADMINS_AND_PARTICIPANTS", label: "Admins and Call Participants" },
        { value: "NO_ONE", label: "Nobody" },
    ]
    const whoCanScoreCalls = [
        { value: "ALL", label: "Everyone" },
        { value: "MANAGERS", label: "Managers" },
        { value: "MANAGERS_AND_ADMINS", label: "Admins and Managers" },
        { value: "NO_ONE", label: "Nobody" }
    ]

    const whoCanSeeScoresForCalls = [
        { value: "ALL", label: "Everyone" },
        { value: "MANAGERS_AND_SCORED_REP", label: "Managers and Scored Reps" },
        { value: "MANAGERS_AND_ADMINS_SCORED_REP", label: "Managers, Admins and Scored Reps" }
    ]

    const { setSnackbar } = useSnackbar();

    async function handleCallPrivacySettersChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_callaccess_changers", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setCallPrivacySetters(role);
    }

    async function handleCallDeletersChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_call_deleters", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setCallDeleters(role);
    }

    async function handleCallDownoaderschange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_call_downloaders", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setCallDownloaders(role);

    }

    async function handleCallSharersChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_call_sharers", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setCallSharers(role);

    }



    async function handleSignUpChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_signup_access_changers", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setSignupSetters(role);
    }

    async function handleCallScorersChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_call_scorers", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setCallScorers(role);
    }

    async function handleScoreViewersChange(role) {
        const params = new URLSearchParams();
        params.append("role", role);
        await sendRequest("/admin/set_authorized_score_viewers", params);
        refreshUserAction();
        setSnackbar("Changes Saved", "SUCCESS");
        setScoreViewers(role);
    }

    return (
        <div className="Settings__crmContainer" style={{ marginTop: "15px" }}>
            <span className="Settings__header">Permissions</span>
            {customer.featuresGated.callPrivacy &&
                <div className="row horCon">
                    <div className="col verCon" style={{ width: "50%" }}>
                        <span className="Settings__label">Call Privacy</span>
                        <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                            Who can change privacy on their calls?
                        </span>
                    </div>
                    <div className="col verCon" style={{ width: "20%" }}>
                        <CustomSelect
                            menuItems={[
                                { value: "ALL", label: "Everyone" },
                                { value: "ADMINS", label: "Admins" },
                                { value: "ADMINS_AND_MANAGERS", label: "Admins and Managers" },
                                { value: "NO_ONE", label: "Nobody" },
                            ]}
                            value={callPrivacySetters}
                            onChange={e => {
                                handleCallPrivacySettersChange(e.target.value);
                            }}
                            disabled={auth.isAdmin ? false : true}
                        />
                    </div>
                </div>

            }


            <div className="row horCon" style={{ marginTop: "15px" }}>
                <div className="col verCon" style={{ width: "50%" }}>
                    <span className="Settings__label">Call Deletion</span>
                    <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                        Who can delete calls?
                    </span>
                </div>
                <div className="col verCon" style={{ width: "20%" }}>
                    <CustomSelect
                        menuItems={[
                            { value: "ADMINS", label: "Admins" },
                            { value: "ADMINS_AND_PARTICIPANTS", label: "Admins and Call Participants" },
                            { value: "NO_ONE", label: "Nobody" },
                        ]}
                        value={callDeleters}
                        onChange={e => {
                            handleCallDeletersChange(e.target.value);
                        }}
                        disabled={auth.isAdmin ? false : true}
                    />
                </div>
            </div>

            <div className="row horCon" style={{ marginTop: "15px" }}>
                <div className="col verCon" style={{ width: "50%" }}>
                    <span className="Settings__label">Call Download</span>
                    <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                        Who can download calls?
                    </span>
                </div>
                <div className="col verCon" style={{ width: "20%" }}>
                    <CustomSelect
                        menuItems={rolesForCallActions}
                        value={callDownloaders}
                        onChange={e => {
                            handleCallDownoaderschange(e.target.value);
                        }}
                        disabled={auth.isAdmin ? false : true}
                    />
                </div>

            </div>


            <div className="row horCon" style={{ marginTop: "15px" }}>
                <div className="col verCon" style={{ width: "50%" }}>
                    <span className="Settings__label">Call Sharing</span>
                    <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                        Who can share calls?
                    </span>
                </div>
                <div className="col verCon" style={{ width: "20%" }}>
                    <CustomSelect
                        menuItems={rolesForCallActions}
                        value={callSharers}
                        onChange={e => {
                            handleCallSharersChange(e.target.value);
                        }}
                        disabled={auth.isAdmin ? false : true}
                    />
                </div>

            </div>

            <div className="row horCon" style={{ marginTop: "15px" }}>
                <div className="col verCon" style={{ width: "50%" }}>
                    <span className="Settings__label">Sign up</span>
                    <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                        Who can Signup?
                    </span>
                </div>
                <div className="col verCon" style={{ width: "20%" }}>
                    <CustomSelect
                        menuItems={[
                            { value: "ALL", label: "Everyone" },
                            { value: "ONLY_INVITED_USERS", label: "Invited Users Only" },
                        ]}
                        value={signupSetters}
                        onChange={e => {
                            handleSignUpChange(e.target.value);
                        }}
                        disabled={auth.isAdmin ? false : true}
                    />
                </div>
            </div>

            {auth.data.user.stringsCustomer.featuresGated.scorecard &&
                <>
                    <div className="row horCon" style={{ marginTop: "15px" }}>
                        <div className="col verCon" style={{ width: "50%" }}>
                            <span className="Settings__label">Call scorers</span>
                            <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                                Who can Score Calls?
                            </span>
                        </div>
                        <div className="col verCon" style={{ width: "20%" }}>
                            <CustomSelect
                                menuItems={whoCanScoreCalls}
                                value={callScorers}
                                onChange={e => {
                                    handleCallScorersChange(e.target.value);
                                }}
                                disabled={auth.isAdmin ? false : true}
                            />
                        </div>
                    </div>

                    <div className="row horCon" style={{ marginTop: "15px" }}>
                        <div className="col verCon" style={{ width: "50%" }}>
                            <span className="Settings__label">View Call Scores</span>
                            <span className="Settings__notifications-typeExplanation" style={{ marginTop: "-5px" }}>
                                Who can view Scores ?
                            </span>
                        </div>
                        <div className="col verCon" style={{ width: "20%" }}>
                            <CustomSelect
                                menuItems={whoCanSeeScoresForCalls}
                                value={scoreViewers}
                                onChange={e => {
                                    handleScoreViewersChange(e.target.value);
                                }}
                                disabled={auth.isAdmin ? false : true}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
PermissionSettings.propTypes = {}
PermissionSettings.defaultProps = {}
export default connect(
    s => s,
    { refreshUserAction },
)(PermissionSettings);