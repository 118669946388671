import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideSnackbar } from "app/actions/screen";
import SimpleSnackbar from "../common/Snackbar";

const ScreenSnackbar = props => {
  function setShowSnackbar(bool) {
    if (!bool) {
      props.hideSnackbar();
    }
  }

  return (
    <SimpleSnackbar
      autoHideDuration={props.screen.snackbarHideDuration || 3000}
      msg={props.screen.snackbarMessage}
      type={props.screen.snackbarType}
      open={props.screen.showSnackbar}
      setVisiblity={setShowSnackbar}
    />
  );
};

const mapStateToProps = store => {
  return {
    screen: store.screen,
  };
};

ScreenSnackbar.propTypes = {};

ScreenSnackbar.defaultProps = {};

export default connect(
  mapStateToProps,
  { hideSnackbar },
)(ScreenSnackbar);
