import React from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from "@material-ui/core";
import { WingmanLogoWithName } from "app/designSystem"
import { fetchDataSafely } from "app/utils/dataUtils";
import { useSelector } from 'react-redux';

const CompletingIntegration = props => {
    const user = useSelector(store => store.auth.data.user);
    return (
        <div style={{ flexDirection: "column", height: "50vh", width: "50vw", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center" }}>
            <WingmanLogoWithName className="h-15 mb-7" />
            <span style={{ margin: "7px 0px", fontSize: "30px", fontWeight: "600", color: "#304156" }}>Hi {fetchDataSafely(user, "displayName")},</span>
            <span style={{ marginBottom: "20px", fontSize: "20px", fontWeight: "500", color: "#304156" }}>Hold tight! We are completing your integration</span>
            {props.loading && <CircularProgress size={50} color="primary" />}
        </div>
    );
}
CompletingIntegration.propTypes = {}
CompletingIntegration.defaultProps = {}
export default CompletingIntegration;