import React from "react";
import durationIcon from "app/styles/assets/images/icons/alarm.png";
import { _formatSecondsToPlayingTime } from "app/utils/helpers";
import { SCHEDULED, PROCESSING, WAITING_IN_QUEUE } from "../../constants";

import { ClockIcon, TextBase } from "app/designSystem";

//Todo: move inside main JSX return once 'processing,failed,..' tags are redesigned in new look
const DurationWithIcon = ({ durationText }) => {

  return (
    <div className="flex flex-row items-center">

      <div className="w-6 h-6 mr-2">
        <ClockIcon className="w-full h-full" />
      </div>
      <TextBase textColor="text-coolGray-500">
        {durationText}
      </TextBase>
    </div>
  )
}
const DurationColumn = props => {
  let durationText = "";
  let textColor = "noColor";
  let showIcon = false;
  const status = props.callStatus.replace(/ /g, '_');
  if (!props.duration) {
    if (status === "UNKNOWN_CALL_STATUS") {
      durationText = "UNKNOWN ERROR";   //red
      textColor = "red";
    } else if (status === SCHEDULED) {
      durationText = SCHEDULED;         //gray
      textColor = "gray";
    } else if (status === "INITIATED") {
      durationText = "JOINING";       //orange
      textColor = "orange";
    } else if (status === 'INPROGRESS') {
      durationText = "ONGOING";       //green
      textColor = "green";
    } else if (['WAITING_IN_QUEUE', 'COMPLETED', 'PROCESSING', 'PROCESSED'].includes(status)) {
      durationText = "PROCESSING";    //orange
      textColor = "orange";
    } else if (['ERROR_IN_TRANSCRIBE', 'ERROR_IN_PROCESSING', 'ERROR_IN_RECORDING', 'FAILED_TO_EXTRACT_AUDIO_FROM_VIDEO'].includes(status)) {
      durationText = 'ERROR';       //red
      textColor = "red";
    } else if (status === 'CALL_DID_NOT_HAPPEN') {
      if (props.mediaRecorderStatus === 'BOT_DENIED') durationText = 'UNABLE TO RECORD';
      else durationText = 'NO SPEECH DETECTED';     //gray
      textColor = "gray";
    } else if (['UNABLE_TO_JOIN', 'BOTJOIN_DENIED', 'FAILED_TO_DOWNLOAD'].includes(status)) {
      durationText = 'UNABLE TO RECORD';        //gray
      textColor = "gray";
    } else if (['NO_DATA_INCALL', 'NOBODY_JOINED_CALL'].includes(status)) {
      durationText = 'NO SPEECH DETECTED';      //gray
      textColor = "gray";
    } else if (['IGNORED_BY_USER', 'BOTJOIN_DISABLED'].includes(status)) {
      durationText = 'NOT RECORDED';        //gray
      textColor = "gray";
    } else if (["POST_PROCESSING_DONE"].includes(status)) {
      durationText = "NO SPEECH DETECTED";      //gray
      textColor = "gray";
    } else {
      durationText = "PROCESSING";      //orange
      textColor = "orange";
    }
  } else {
    durationText = _formatSecondsToPlayingTime(props.duration);
    showIcon = true;
  }
  return (
    <>
      {showIcon && <DurationWithIcon durationText={durationText} />}

      {!showIcon && (
        <div className="CallTable__durationContainer">
          <span
            className={
              "CallTable__durationContainer_text " +
              "CallTable__durationContainer_" +
              textColor
            }
          >
            {durationText === "ONGOING" ? (
              <span>&#9679; {durationText}</span>
            ) : (
              durationText
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default DurationColumn;
