import React, { Component, useEffect, useState } from "react";
import { TextLarge } from 'app/designSystem'


const IncorrectWords = (props) => {

    const [visibleWords, setVisibleWords] = useState("");
    const [additionalInfo, setAdditionalInfo] = useState("");



    useEffect(() => {
        if (props.incorrectWords.length < 3) {
            setAdditionalInfo("")
        }
        const incorrectWords = props.incorrectWords
        let visibleWords = incorrectWords.slice(0, 3);
        let additionalInfo = incorrectWords.slice(3, incorrectWords.length);
        setVisibleWords((visibleWords.join()).replaceAll(",", ", "))
        if (additionalInfo.length > 0) {
            setAdditionalInfo("& " + additionalInfo.length + " others");
        }

    }, [props.incorrectWords])


    return (
        <div className="flex flex-col pl-8">
            <TextLarge textColor="text-coolGray-500">
                {visibleWords}
            </TextLarge>
            <TextLarge textColor="text-coolGray-500">
                {additionalInfo}
            </TextLarge>

        </div>
    )
}
export default IncorrectWords;