import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const useExitPrompt = (when, blockNavigation = false, message = 'Are you sure you want to quit without saving your changes?') => {
    const history = useHistory();
    const self = useRef(null);

    const onWindowOrTabClose = event => {
        if (!when) {
            return;
        }
        if (typeof event == 'undefined') {
            event = window.event;
        }
        if (event) {
            console.log('event', event);
            event.returnValue = message;
        }
        return message;
    };

    useEffect(() => {
        // This logic also blocks navigation to the page
        if (blockNavigation) {
            if (when) {
                self.current = history.block(message);
            } else {
                self.current = null;
            }
        }

        window.addEventListener('beforeunload', onWindowOrTabClose);
        return () => {
            if (self.current) {
                self.current();
                self.current = null;
            }
            window.removeEventListener('beforeunload', onWindowOrTabClose);
        }
    }, [message, when]);

};