import {
  LOADING,
  EXPAND_SIDEBAR,
  SCREEN_NAME,
  SCREEN_TYPE,
  STACKED_SCREEN_NAME,
  SET_FIRST_URL,
  SET_MANAGER_DASHBOARD_FILTER,
  SET_USER_DASHBOARD_FILTER,
  SET_AGENT_DASHBOARD_FILTER,
  SET_ACTIVE_FILTER,
  SET_META_DATA,
  SET_SETTINGS_TAB,
  SET_SEARCH_RESULTS,
  SET_SEARCH_TERM,
  SET_SNACKBAR,
  HIDE_SNACKBAR,
  SET_ADD_COMMENT_EXPANDED,
  SET_ADD_NOTE_EXPANDED,
  SET_FULLSCREEN_VIDEO,
  SET_NOTIFICATIONS,
  SET_MANAGER_DEAL_INSIGHTS_FILTER,
  SET_DEAL_BOARD,
  SET_ACTIVE_ROLLUP,
  SET_ACTIVE_DEALBOARD,
  SET_DEALS_FILTER,
  SET_SCORECARDS_DASHBOARD_FILTER
} from "./actionTypes";

export function setFirstUrl(firstUrl) {
  return {
    firstUrl,
    type: SET_FIRST_URL,
  };
}

export function setAddCommentExpanded({ show, time }) {
  return {
    show,
    time,
    type: SET_ADD_COMMENT_EXPANDED,
  };
}

export function setAddNoteExpanded(boolean) {
  return {
    boolean,
    type: SET_ADD_NOTE_EXPANDED,
  };
}

export function setLoading(loading) {
  return {
    loading,
    type: LOADING,
  };
}

export function setSidebarExpansion(expanded) {
  return {
    expanded,
    type: EXPAND_SIDEBAR,
  };
}

export function setScreenName(screenName) {
  return {
    screenName,
    type: SCREEN_NAME,
  };
}

export function setStackedScreenName(stackedScreenName) {
  return {
    stackedScreenName,
    type: STACKED_SCREEN_NAME,
  };
}

export function setScreenType(screenType) {
  return {
    screenType,
    type: SCREEN_TYPE,
  };
}

export function setManagerDashboardFilter(filter, userId) {
  localStorage.setItem(`managerDashboard${userId}`, JSON.stringify(filter));
  return {
    filter,
    type: SET_MANAGER_DASHBOARD_FILTER,
  };
}

export function setScorecardsDashboardFilter(filter, userId) {
  localStorage.setItem(`scorecardDashboard${userId}`, JSON.stringify(filter));
  return {
    filter,
    type: SET_SCORECARDS_DASHBOARD_FILTER,
  }
}

export function setManagerDealInsightsFilter(filter, userId) {
  localStorage.setItem(`dealInsights${userId}`, JSON.stringify(filter));
  return {
    filter,
    type: SET_MANAGER_DEAL_INSIGHTS_FILTER,
  };
}

export function setAgentDashboardFilter(filter, userId) {
  localStorage.setItem(`agentDashboard${userId}`, JSON.stringify(filter));
  return {
    filter,
    type: SET_AGENT_DASHBOARD_FILTER,
  };
}

export function setUserDashboardFilter(filter, userId) {
  localStorage.setItem(`userDashboard${userId}`, JSON.stringify(filter));
  return {
    filter,
    type: SET_USER_DASHBOARD_FILTER,
  };
}

export function setActiveFilter(filter) {
  return {
    filter,
    type: SET_ACTIVE_FILTER,
  };
}

export function setMetaData(metaData) {
  return {
    metaData,
    type: SET_META_DATA,
  };
}

export function setSettingsTab(tab) {
  return {
    tab,
    type: SET_SETTINGS_TAB,
  };
}

export function setSearchTerm(query) {
  return {
    query,
    type: SET_SEARCH_TERM
  };
}

export function setSearchResults(results) {
  return {
    results,
    type: SET_SEARCH_RESULTS
  };
}

export function setSnackbar(snackbarMessage, snackbarType, snackbarHideDuration = 3000) {
  return {
    snackbarMessage,
    snackbarType,
    snackbarHideDuration,
    type: SET_SNACKBAR
  };
}

export function hideSnackbar(data) {
  return {
    data,
    type: HIDE_SNACKBAR
  };
}

export function setFullScreenVideo(fullscreenVideo) {
  return {
    fullscreenVideo,
    type: SET_FULLSCREEN_VIDEO
  };
}

export function setNotifications(notifications) {
  return {
    notifications,
    type: SET_NOTIFICATIONS,
  }
}

export function setDealBoard(dealBoard) {
  return {
    dealBoard,
    type: SET_DEAL_BOARD
  }
}

export function setActiveRollup(activeRollup) {
  return {
    activeRollup,
    type: SET_ACTIVE_ROLLUP
  }
}

export function setActiveDealboard(activeDealboard) {
  return {
    activeDealboard,
    type: SET_ACTIVE_DEALBOARD
  }
}

export function setDealsFilter(dealsFilter) {
  return {
    dealsFilter,
    type: SET_DEALS_FILTER
  }
}