import { setUserData } from "app/actions/auth";
import { Input, Label } from "app/components/form";
import { GOOGLE_CONFIG } from "app/config";
import { googleLogin, login, loginByLink, requestResetPassword, requestLinkToLogin } from "app/utils/network";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { GoogleLogin } from "react-google-login";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setSnackbar } from "app/actions/screen";
import { Button, Text2XL, TextBase } from "app/designSystem";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.login = this.login.bind(this);
    this.googleLoginHandler = this.googleLoginHandler.bind(this);
    this.googleError = this.googleError.bind(this);
    this.submitOnEnter = this.submitOnEnter.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.requestResetPassword = this.requestResetPassword.bind(this);
    this.requestLinkToLogin = this.requestLinkToLogin.bind(this);
    this.state = {
      email: this.props?.email ?? "",
      password: "",
      loading: false,
      wrongPassword: false,
      googleLoginIssues: false,
      warnEmptyEmail: false
    };
  }

  setLoading(bool) {
    this.setState({ loading: bool });
  }

  submitOnEnter(event) {
    const login = this.login;
    event.preventDefault();
    if (event.keyCode === 13) {
      login();
    }
  }

  setEmail(event) {
    this.setState({ email: event.target.value, warnEmptyEmail: event.target.value.length === 0 });
  }

  setPassword(event) {
    this.setState({ password: event.target.value });
  }

  async googleLoginHandler(response) {
    this.setLoading(true);
    console.log("response profile obj is:", response?.profileObj);
    const data = await googleLogin(
      response.profileObj.name,
      response.profileObj.email,
      response.profileObj.imageUrl ?? null,
      response.tokenObj.id_token,
    );
    if (data?.error) {
      this.setLoading(false);
      this.props.setSnackbar(data.message, "ERROR");
      return;
    }
    this.props.setUserData(data);
    this.setLoading(false);
  }

  googleError(error) {
    console.log("error from google login is:", error, JSON.stringify(error));

    this.setState({ googleLoginIssues: true });
    if (error && error.error === "idpiframe_initialization_failed" && error.details && error.details.includes("Cookies")) {
      this.props.setSnackbar("Looks like you have disabled cookies for this site. Please enable them to login.", "ERROR");
    } else {
      this.props.setSnackbar("There was an error signing in via google", "ERROR");
    }
  }

  async login(e) {
    e?.preventDefault();
    if (!this.state.email) {
      this.setState({ warnEmptyEmail: true });
      return;
    }
    return await this.loginInternal(login({
      email: this.state.email,
      password: this.state.password,
    }));
  }

  async loginInternal(loginPromise) {
    this.setLoading(true);
    const response = await loginPromise;
    if (response.success) {
      const user = response;
      this.props.setUserData(user);
      const frontendUrl = `${window.location.origin}`;
    } else {
      console.log('got login response', response);
      let err = response.data.message;
      if (err) err = err.toLowerCase();
      if (err && err.indexOf('wrong') !== -1 && err.indexOf('password') !== -1) {
        this.setState({ wrongPassword: true });
        this.props.history.replace({ pathname: "/login" });
      }
      this.props.setSnackbar(response.data.message, "ERROR");
    }
    this.setLoading(false);
  }

  componentWillUnmount() {
    const loginForm = document.getElementById("loginForm");
    loginForm.removeEventListener("keyup", this.submitOnEnter);
  }

  async requestResetPassword() {
    if (!this.state.email) {
      this.props.setSnackbar("Fill email to request reset link", "INFO");
      this.setState({ warnEmptyEmail: true });
      return;
    }
    this.setLoading(true);
    const response = await requestResetPassword(this.state.email);
    this.setLoading(false);
    const success = response.data ? response.data.success : response.success;
    const message = response.data ? response.data.message : response.message;
    this.props.setSnackbar(message, success ? "INFO" : "ERROR");
  }

  async requestLinkToLogin() {
    if (!this.state.email) {
      this.props.setSnackbar('Fill email to request login link', "INFO");
      this.setState({ warnEmptyEmail: true });
      return;
    }
    this.setState({ wrongPassword: false });
    this.setLoading(true);
    const response = await requestLinkToLogin(this.state.email);
    this.setLoading(false);
    const success = response.data ? response.data.success : response.success;
    const message = response.data ? response.data.message : response.message;
    this.props.setSnackbar(message, success ? "INFO" : "ERROR");
  }

  render() {
    return (
      <div className="LoginForm" id="loginForm">
        <Text2XL textColor="text-coolGray-800">Welcome back.</Text2XL>
        <TextBase textColor="text-coolGray-500 mb-3">Please enter your details.</TextBase>
        <div className="buttonOptions googleLogin">
          <GoogleLogin
            clientId={GOOGLE_CONFIG.clientId}
            className="google"
            buttonText="Sign in with Google"
            onSuccess={this.googleLoginHandler}
            onFailure={this.googleError}
          />
        </div>
        <div className="specialDivider">OR</div>
        <form onSubmit={this.login}>
          <div className="flex flex-col justify-between mt-3 h-15">
            <Label>Email</Label>
            <Input
              placeholder="Enter your email"
              value={this.state.email}
              className="Login-Page__textField"
              onChange={this.setEmail}
              warning={this.state.warnEmptyEmail}
              warningMessage="Enter an email"
              name="email"
              autoFocus
            />
          </div>
          <div className="flex flex-col justify-between mt-3 h-15">
            <Label>Password</Label>
            <Input
              value={this.state.password}
              onChange={this.setPassword}
              placeholder="Enter your password"
              type="password"
              warning={this.state.wrongPassword}
              warningMessage="Wrong password"
              name="password"
            />
          </div>
          {
            (this.state.wrongPassword || this.state.googleLoginIssues) && <small style={{ padding: "10px" }}>
              Trouble logging in? <Link to className="text-wingman-brand" onClick={this.requestLinkToLogin}>Click here</Link> to get a link in your email to log you in.</small>
          }
          <Button
            loading={this.state.loading}
            id="login"
            className="mt-6 w-full"
            onClick={this.login}
            type="submit"
          >
            {"Sign In"}
          </Button>
        </form>
        <div className="flex flex-row justify-between whitespace-nowrap text-sm text-gray-700 mt-1">
          <div className="m-1">Don’t have an account?
            <Link to className="text-wingman-brand cursor-pointer font-medium" onClick={() => this.props.setLoginState("SignUp")}> Sign Up </Link>
          </div>
          <div className="text-wingman-brand cursor-pointer m-1 font-medium" onClick={this.requestResetPassword}>
            Forgot password
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

SignIn.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

SignIn.defaultProps = {};

export default connect(
  mapStateToProps,
  { setUserData, setSnackbar },
)(withRouter(SignIn));
