import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FoldersContainer } from 'app/components/gameTapesLatest'
import { folderIdMapping } from 'app/utils/network/gametapes'

const GameTapes = () => {
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            const result = await folderIdMapping();
            fetchCategories(result);
        }

        fetchData();
    }, []);

    const getHashAsUrlParams = () => {
        try {
            const hash = window.location.hash ? decodeURIComponent(window.location.hash).replace("#", "") : null;
            return new URLSearchParams(hash);
        } catch (error) {
            console.log("error converting hash to urlSearchParams", error);
            return new URLSearchParams();
        }
    }

    const fetchCategories = async (folderIdMappingData) => {
        const urlParams = getHashAsUrlParams();
        const categoryName = urlParams.get("category");
        const selectedGameTapeId = urlParams.get("selectedGameTapeId");

        //Redirect to gametape if selectedGameTapeId is present
        if (selectedGameTapeId) {
            return history.push(`/gametapes/video/${selectedGameTapeId}`);
        }

        //Redirect to gametape folder if category is present
        if (folderIdMappingData && folderIdMappingData[categoryName]) {
            return history.push(`/gametapes/folder/${folderIdMappingData[categoryName]}`);
        }
    }

    return (
        <div className="bg-white p-8" style={{
            minHeight: 'calc(100vh - 5rem)'
        }}>
            <FoldersContainer folderId='ROOT' />
        </div>
    )
}

export default GameTapes;