import React, { useState, useEffect, useRef } from 'react';
import { Modal } from '@material-ui/core';
import { TextSM, TextBase, Text2XL, Button } from 'app/designSystem'
import Input from 'app/designSystem/form/Input';
import TextArea from 'app/designSystem/form/TextArea';
import { CloseIcon } from 'app/designSystem/icons';
import { creatFolder, updateFolderMetadata } from 'app/utils/network/gametapes'
import metrics from 'app/utils/metrics';
import useSnackbar from "app/utils/hooks/useSnackbar";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

export default function CreateFolderModal({ folderId, onClose, onCreate, data }) {
    const { modal } = useSelector(state => state.gametapes);
    const [loading, setLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [isDefaultFolder, setIsDefaultFolder] = useState(false);
    const formRef = useRef(null);
    const { setSnackbar } = useSnackbar();

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        isPrivate: false,
        subscribedUsers: [],
    });

    useEffect(() => {
        if (data) {
            setFormData(data);
            setIsUpdating(true);

            if (data.userId) {
                setIsDefaultFolder(false);
            } else {
                setIsDefaultFolder(true);
            }
        } else {
            clearForm();
            setIsUpdating(false);
        }
    }, [data]);

    const onChangeHandler = (key, value) => {
        setErrorMessage(null);

        setFormData({
            ...formData,
            [key]: value
        });
    }

    const clearForm = () => {
        setFormData({
            name: "",
            description: "",
            isPrivate: false,
            subscribedUsers: [],
        });
    }

    const handleValidations = () => {
        if (!formData.name || formData.name.trim() === "") {
            setErrorMessage("Folder name is required");
            return false;
        }

        return true;
    }

    const handleSubmit = async (e) => {
        e?.preventDefault();

        if (!handleValidations()) {
            return;
        }

        setLoading(true);

        if (isUpdating) {
            await updateFolderMetadata({ ...formData, folderId: data.id });
            setSnackbar("Folder updated successfully", "SUCCESS");
        }
        else {
            metrics.logEventHeap('createFolder', { name: formData.name, type: formData.isPrivate ? 'Private' : 'Public' });
            await creatFolder({ ...formData, folderId });
            setSnackbar("Folder created successfully", "SUCCESS");
        }

        clearForm();
        setLoading(false);
        onCreate();
    }

    return (
        <Modal
            open={modal.isCreateFolderModalOpen}
            onClose={onClose}
            className="flex justify-center items-center"
        >
            <div className="flex flex-col bg-white rounded-lg w-[36rem] p-0 outline-none" style={{
                maxHeight: "calc(100vh - 6.25rem)"
            }}>

                <div className="px-8 py-4">
                    <div className="flex justify-between w-full items-center max-h-10">
                        <Text2XL className="text-gray-900 font-medium">{isUpdating ? "Update" : "Create"} Folder</Text2XL>
                        <CloseIcon className="cursor-pointer" onClick={onClose} />
                    </div>
                </div>
                <hr />
                <div className="overflow-y-auto">
                    <form className="px-8 py-4" ref={formRef} onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <Input
                                type="text"
                                label="Name"
                                placeholder="Folder Name"
                                value={formData.name}
                                onChange={(e) => {
                                    onChangeHandler("name", e.target.value)
                                }}
                                required={true}
                                maxLength={100}
                            />
                        </div>
                        <div className="mb-6">
                            <TextArea
                                label="Description"
                                placeholder="Folder Description"
                                value={formData.description}
                                onChange={(e) => {
                                    onChangeHandler("description", e.target.value)
                                }}
                                maxLength={500}
                            />
                        </div>
                        <div className="mb-6">
                            <TextBase className="mb-1 text-gray-500">Set visibility to</TextBase>
                            <label className="flex bg-gray-50 rounded p-1 mb-2 cursor-pointer">
                                <Input
                                    type="radio"
                                    name="visibility"
                                    value={!formData.isPrivate}
                                    onChange={() => {
                                        onChangeHandler("isPrivate", false)
                                    }}
                                    className="flex items-center w-6 mr-2"
                                    defaultChecked={!formData.isPrivate}
                                />
                                <div className="flex flex-col">
                                    <TextBase>Public</TextBase>
                                    <TextSM>Anyone in your organization can see this folder</TextSM>
                                </div>
                            </label>
                            <label className="flex bg-gray-50 rounded p-1 cursor-pointer" data-tip data-for="tooltip-private-folder">
                                <Input
                                    type="radio"
                                    name="visibility"
                                    value={formData.isPrivate}
                                    onChange={() => {
                                        onChangeHandler("isPrivate", true)
                                    }}
                                    className="flex items-center w-6 mr-2"
                                    defaultChecked={formData.isPrivate}
                                    disabled={isDefaultFolder}
                                />
                                <div className="flex flex-col">
                                    <TextBase>Private</TextBase>
                                    <TextSM>Only the owner can see this folder</TextSM>
                                </div>
                            </label>
                            <ReactTooltip place="bottom" id="tooltip-private-folder" disable={!isDefaultFolder}>
                                <span>
                                    You can't change the visibility of default folders
                                </span>
                            </ReactTooltip>
                        </div>
                    </form>
                </div>
                <div className="px-8 pb-4">
                    {errorMessage && <div className="flex justify-start items-center rounded mt-4 py-3 pl-5 pr-10 bg-red-50 cursor-pointer" onClick={() => { setErrorMessage("") }}>
                        <TextBase className="!text-red-600 font-normal">{errorMessage}</TextBase>
                    </div>}
                </div>
                <div className="px-8 py-4 flex justify-end bg-wingman-white border-t">
                    <div className="flex w-48 h-10">
                        <Button variant="secondary" className="py-2 w-1/3" onClick={onClose}>Cancel</Button>
                        <Button loading={loading} className="py-2 ml-4 w-2/3" onClick={handleSubmit}>{isUpdating ? "Update" : "Create"}</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}