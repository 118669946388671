import React from "react";
import { Component, useEffect, useState } from "react";
import { addPhrase, getAllPhrasesForCustomer, deleteCategory, addCategory } from "app/utils/network";
import { setStackedScreenName, setScreenType, setLoading } from "app/actions/screen";
import { STACKED, MAIN } from "app/constants";
import { connect, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Category, AddNewCategory } from ".";
import { TextBase, TextLarge, Text2XL, Button } from "app/designSystem"
import { ReactComponent as AddTopic } from "app/styles/assets/images/icons/addTopic.svg";
import AddTopicModal from "./AddTopicModal";
import TopicSearch from "./TopicSearch";


class KeywordsTracking extends React.Component {

  constructor(props) {
    super(props);
    this.addCategory = this.addCategory.bind(this);
    this.loadPhrases = this.loadPhrases.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.setSortedTopics = this.setSortedTopics.bind(this);
    this.scrollToCategory = this.scrollToCategory.bind(this);
    this.handleFilteredTopics = this.handleFilteredTopics.bind(this);
    this.state = {
      phrases: {},
      sortedTopics: [],
      showAddTopicModal: false,
      filteredTopics: []
    };
  }


  setAddTopicVisibility(bool) {
    this.setState({
      ...this.state,
      showAddTopicModal: bool
    })
  }

  async UNSAFE_componentWillMount() {
    this.props.setLoading(true);
    await this.loadPhrases();
    this.props.setLoading(false);
  }

  async loadPhrases() {
    const response = await getAllPhrasesForCustomer();
    const phrases = {};
    response.map((phrase, i) => {
      if (phrases[phrase.category] == null) {
        if (phrase.exactMatchRequired) {
          phrases[phrase.category] = { exact: [phrase], approx: [] };
        } else {
          phrases[phrase.category] = { exact: [], approx: [phrase] };
        }
      } else if (phrase.exactMatchRequired) {
        phrases[phrase.category].exact.push(phrase);

      } else {
        phrases[phrase.category].approx.push(phrase);

      }
    });
    this.setState({
      phrases,
    });

    let sortedTopics = this.setSortedTopics(Object.keys(phrases));
    this.setState({
      ...this.state,
      filteredTopics: sortedTopics
    })

  }

  handleFilteredTopics(filteredTopics) {
    this.setState({
      ...this.state,
      filteredTopics: filteredTopics
    })

  }

  setSortedTopics(keys) {
    const sortedTopics = keys.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
    this.setState({
      sortedTopics,
    })
    return sortedTopics;
  }

  scrollToCategory(category) {
    let el = document.getElementById(category + "inTopics");
    if (el != null) el.scrollIntoView();
  }

  async addCategory(category, exactMatches, similarMatches, appliesTo) {
    if (category.trim() === "") {
      return;
    }
    const { phrases } = this.state;
    this.props.setLoading(true);
    const result = await addCategory(
      category,
      [...exactMatches].flat(),
      [...similarMatches].flat(),
      appliesTo

    )

    if (result.error) {
      alert(result.message);
      return;
    }
    phrases[category] = { exact: [], approx: [], new: true };
    this.setState({
      phrases,
    });
    this.setSortedTopics(Object.keys(phrases));
    await this.loadPhrases();
    this.props.setLoading(false);
    this.scrollToCategory(category);
  }

  async deleteCategory(category) {
    this.props.setLoading(true);
    const { phrases } = this.state;
    await deleteCategory(category);
    delete phrases[category];
    let filteredTopics = this.state.filteredTopics;
    let deletedIndex = filteredTopics.indexOf(category);
    filteredTopics.splice(deletedIndex, 1);
    this.setState({
      ...this.state,
      filteredTopics: filteredTopics
    })
    this.setSortedTopics(Object.keys(phrases));
    this.setState({
      phrases,
    });
    this.props.setLoading(false);
  }

  render() {
    const categories = [];

    this.state.filteredTopics.map((key, i) => {
      const category = (
        <Category
          key={i + key.toString()}
          category={key}
          setLoading={this.props.setLoading}
          deleteCategory={this.deleteCategory}
          loadPhrases={this.loadPhrases}
          phrases={this.state.phrases[key]}
          disabled={this.props.auth.isAdmin ? false : true}
        />
      );
      categories.push(category);
    });
    return (
      <React.Fragment>

        <Text2XL className=" m-4 " textColor="text-coolGray-800">Topics</Text2XL>
        <div className=" w-full flex justify-between items-center  pt-2 pr-8 pb-5">
          <TopicSearch setCategories={this.handleFilteredTopics} defaultCategories={this.state.sortedTopics} />
          <Button
            variant="primary"
            startIcon={<AddTopic />}
            className="!py-1"
            onClick={() => this.setAddTopicVisibility(true)}
            disabled={!this.props.auth.isAdmin}
          >
            Add Topic
          </Button>

        </div>
        <AddTopicModal
          open={this.state.showAddTopicModal}
          hide={() => this.setAddTopicVisibility(false)}
          addTopic={this.addCategory}
          customerDocument={this.props.auth.stringsCustomer}
        >

        </AddTopicModal>


        {categories}
      </React.Fragment>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

KeywordsTracking.propTypes = {};

KeywordsTracking.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setLoading,
    setScreenType,
    setStackedScreenName,
  },
)(KeywordsTracking);
