import React, {
  useEffect
} from "react";
import {
  useDispatch,
  useSelector
} from "react-redux";
import {
  SET_META_DATA
} from "app/actions/actionTypes";
import {
  getAllUserNamesForFilters,
  getAllDeletedUserNamesForFilters,
  getAllManagersForFilters,
  getAllCategoriesForFilters,
  getAllCompetitorsForFilters,
  getAllCallStatus,
  getAllCallTypes,
  getAllBattlecardCategories,
  getProfileImages,
  sendRequest,
  getFrontendCrmFields,
  getRepJobTitles,
  getAllPipelines,
  getScoringTemplatesForFilter,
  getAllTagsForCustomer,
} from "app/utils/network";
import { fetchScoringSkills } from "../network/scorecards";
import { getLocalValue, setLocalValue, removeLocalValue } from "./usePersistedLocal";

function isArray(value) {
  return value && typeof value === "object" && value.constructor === Array;
}

export const defaultMetaData = {
  userNames: [],
  deletedUserNames: [],
  managers: [],
  types: [],
  status: [],
  categories: [],
  competitors: [],
  battlecardCategories: [],
  profileImages: {},
  savedFilters: [],
  jobTitles: {},
  pipelines: [],
  scoringTemplates: [],
  scoringSkills: [],
  callTagsByCustomer: []
};

//Set meta data for all the values which are truthy
export function getValuesFromLocal(...args) {
  let metadataValues = {};

  for (let i = 0; i < args.length; i++) {
    const key = args[i];
    const value = getLocalValue(key);

    // Assuming all metadata fields are array or an object, for other types of fields, do add extra checks
    if (isArray(value)) {
      metadataValues[key] = value;
    }
    else if (typeof value === "object" && Object.values(value).length > 0) {
      if (value.error) {
        metadataValues[key] = defaultMetaData[key];
        continue;
      }
      metadataValues[key] = value;
    }
    else {
      metadataValues[key] = defaultMetaData[key];
    }
  }

  return metadataValues;
}

export function setValuesToLocal(args) {
  for (let i = 0; i < args.length; i++) {
    const { key, value } = args[i];

    if (value) {
      if (isArray(value) && value.length > 0) {
        setLocalValue(key, value);
      } else if (typeof value === "object" && Object.values(value).length > 0) {
        if (value.error) {
          setLocalValue(key, defaultMetaData[key]);
          continue;
        }
        setLocalValue(key, value);
      }
    } else {
      setLocalValue(key, defaultMetaData[key]);
    }
  }
}

export function removeValuesFromLocal(args) {
  for (let i = 0; i < args.length; i++) {
    const key = args[i];
    removeLocalValue(key);
  }
}

export function resetValuesToDefaultInLocal(args) {
  for (let i = 0; i < args.length; i++) {
    const key = args[i];
    setLocalValue(key, defaultMetaData[key]);
  }
}

const useMetaDataFetcher = () => {
  const dispatch = useDispatch();
  const screenMetaData = useSelector(store => store.screen.metaData);

  useEffect(() => {
    let requestIdleCallback = func => { func(); };
    let cancelIdleCallback = () => { };
    if (window.requestIdleCallback) {
      requestIdleCallback = window.requestIdleCallback;
      cancelIdleCallback = window.cancelIdleCallback;
    }

    let handle = null;
    setTimeout(() => {
      handle = requestIdleCallback(fetchMetaData);
    }, 3000);
    return (() => {
      if (handle)
        cancelIdleCallback(handle);
    });

  }, []);

  async function getSavedFilters() {
    let allFilters = await sendRequest("/filter/getAllSavedFilters");
    allFilters = allFilters?.map(filter => {
      filter.filterParams.crmFields = getFrontendCrmFields(filter.filterParams.crmFields);
      return filter;
    });
    return allFilters;
  }

  async function fetchMetaData() {
    const valuesFromLocal = getValuesFromLocal(...Object.keys(defaultMetaData));
    // valuesFromLocal contains the values fetched from the local storage and is of the form: 
    // { userNames, deletedUserNames, managers, types, status, categories, competitors, battlecardCategories, profileImages, savedFilters, jobTitles, pipelines };

    try {
      setMetaData(valuesFromLocal);
    } catch (error) {
      console.log("file: useMetaDataFetcher.js ~ line 133 ~ fetchMetaData ~ error", error)
      console.log(valuesFromLocal);
    }

    const [userNames, deletedUserNames, managers, types, status, categories, competitors, battlecardCategories, profileImages, savedFilters, jobTitles, pipelines, scoringTemplates, scoringSkills, callTagsByCustomer] = await Promise.all([
      getAllUserNamesForFilters(),
      getAllDeletedUserNamesForFilters(),
      getAllManagersForFilters(),
      getAllCallTypes(),
      getAllCallStatus(),
      getAllCategoriesForFilters(),
      getAllCompetitorsForFilters(),
      getAllBattlecardCategories(),
      getProfileImages(),
      getSavedFilters(),
      getRepJobTitles(),
      getAllPipelines(),
      getScoringTemplatesForFilter(),
      fetchScoringSkills(),
      getAllTagsForCustomer()
    ]);

    // Update the values object with the fetched values
    const valuesFromBackend = { userNames, deletedUserNames, managers, types, status, categories, competitors, battlecardCategories, profileImages, savedFilters, jobTitles, pipelines, scoringTemplates, scoringSkills, callTagsByCustomer };

    // Set the new values to local storage
    setValuesToLocal(Object.keys(valuesFromBackend).map(key => ({ key, value: valuesFromBackend[key] })));

    try {
      setMetaData(valuesFromBackend);
    } catch (error) {
      console.log("file: useMetaDataFetcher.js ~ line 191 ~ fetchMetaData ~ error", error)
      console.log(valuesFromBackend);
    }
  }

  function setMetaData(metaData) {
    dispatch({
      type: SET_META_DATA,
      metaData,
    });
  }

  return null;
};

export default useMetaDataFetcher;