import React from 'react'
import classNames from "classnames";
import { TranscriptIcon, RecorderIcon } from "app/designSystem/icons";

const ToggleShowVideo = ({ setShowVideo, showVideo }) => {
    return (
        <div className="ToggleShowVideo">
            <div className="ToggleShowVideo__activeTabBackground" style={{ left: showVideo ? "0px" : "50%" }} />
            <div onClick={() => setShowVideo(true)} className={`ToggleShowVideo__tab ${showVideo ? "ToggleShowVideo__tabActive" : ""}`}>
                <RecorderIcon className={classNames(showVideo ? "text-wingman-brand" : "text-gray-500", "w-1/4")} />
                <span className={`ToggleShowVideo__tabLabel ${showVideo ? "ToggleShowVideo__tabActiveLabel" : ""}`}>Video</span>
            </div>
            <div onClick={() => setShowVideo(false)} className={`ToggleShowVideo__tab ${!showVideo ? "ToggleShowVideo__tabActive" : ""}`}>
                <TranscriptIcon className={classNames(!showVideo ? "text-wingman-brand" : "text-gray-500", "w-1/4")} />
                <span className={`ToggleShowVideo__tabLabel ${!showVideo ? "ToggleShowVideo__tabActiveLabel" : ""}`}>Transcript</span>
            </div>
        </div>
    );
}
ToggleShowVideo.propTypes = {}
ToggleShowVideo.defaultProps = {}
export default ToggleShowVideo;