import {
  SET_UPCOMING_CALLS,
  SET_PAST_CALLS,
  SET_BATTLECARDS,
  SET_AGENTS_DATA,
  SET_TRACKERS_DATA,
  SET_MANAGER_DASHBOARD_SORT_DATA,
  SET_TEAM_DASHBOARD_DATA,
  SET_TEAM_DASHBOARD_TRACKERS_DATA,
  UPDATE_RECORDING,
  SHOW_UNRECORDED_IN_MY_CALLS_PAGE,
  SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA,
  SET_SCORECARDS_DASHBOARD_DATA
} from "./actionTypes";

export function updateRecording(callId, recording) {
  return {
    callId,
    recording,
    type: UPDATE_RECORDING
  };
}

export function showUnrecordedInMyCallsPage(show) {
  return {
    show,
    type: SHOW_UNRECORDED_IN_MY_CALLS_PAGE
  };
}

export function setUpcomingCalls(details) {
  return {
    details,
    type: SET_UPCOMING_CALLS,
  };
}

export function setPastCalls(details) {
  return {
    details,
    type: SET_PAST_CALLS,
  };
}

export function setBattlecards(battlecards) {
  return {
    battlecards,
    type: SET_BATTLECARDS,
  };
}

export function setAgentsData(agentsData) {
  return {
    agentsData,
    type: SET_AGENTS_DATA,
  };
}

export function setTrackersData(trackersData) {
  return {
    trackersData,
    type: SET_TRACKERS_DATA,
  };
}

export function setManagerDashboardSortData(managerSortData) {
  return {
    managerSortData,
    type: SET_MANAGER_DASHBOARD_SORT_DATA,
  };
}

export function setTeamDashboardData(teamDashboardData) {
  return {
    teamDashboardData,
    type: SET_TEAM_DASHBOARD_DATA
  };
}

export function setScorecardsDashboardData(scorecardsDashboardData) {
  return {
    scorecardsDashboardData,
    type: SET_SCORECARDS_DASHBOARD_DATA
  };
}

export function setTeamDashboardDealInsightsData(teamDealInsightsData) {
  return {
    teamDealInsightsData,
    type: SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA
  };
}

export function setTeamDashboardTrackersData(trackersData) {
  return {
    trackersData,
    type: SET_TEAM_DASHBOARD_TRACKERS_DATA
  }
}