import React from "react";
import { TextBase } from 'app/designSystem/typography/Typography'
import { CloseIcon } from 'app/designSystem/icons';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function TagsContainer({ variant, tags, onTagRemove, disableCancel }) {
    return (
        tags?.map((tag, index) => (
            <div
                key={tag.value + index}
                className={classNames(
                    "p-2  mr-2 my-1 max-h-full h-8 w-auto flex items-center justify-between cursor-default",
                    { "text-purple-600 bg-purple-50 rounded-md": variant === "purple" },
                    { "text-coolGray-900 bg-coolGray-100 rounded": variant === "gray" }
                )}
            >
                <TextBase
                    className={classNames(
                        "ml-2 w-max",
                        { "text-purple-600": variant === "purple" },
                        { "text-coolGray-600": variant === "gray" }
                    )}>
                    {tag.label}
                </TextBase>
                {!disableCancel &&
                    <CloseIcon
                        className={classNames(
                            "ml-2 cursor-pointer",
                            { "text-purple-600": variant === "purple" },
                            { "text-coolGray-400": variant === "gray" }
                        )}
                        onClick={() =>
                            onTagRemove(tag)}
                    />}
            </div>
        ))
    )
}

TagsContainer.propTypes = {
    variant: PropTypes.string,
    tags: PropTypes.array,
    onTagRemove: PropTypes.func,
}

TagsContainer.defaultProps = {
    variant: "purple",
    tags: [],
    onTagRemove: () => { },
}