import React, { useState, useEffect } from "react";
import { TextXL } from "app/designSystem";
import { Button } from "app/designSystem";
import { fetchScoringTemplates } from "../../utils/network";
import { getSkills } from "app/actions/scorecards";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { useDispatch } from 'react-redux'
import ScorecardsSettingsSkeleton from "./ScorecardsSettingsSkeleton";
import ScorecardEmptyState from "app/scorecards/ScorecardEmptyState";
import TemplateCard from "app/scorecards/TemplateCard";
import CreateTemplateModal from "app/scorecards/CreateTemplateModal";
import useScorecardHelper from "app/scorecards/helper";
import { cloneDeep } from "lodash";
import classNames from "classnames";

const ScoreCardsSettings = () => {


    const [scoringTemplates, setScoringTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editTemplate, setEditTemplate] = useState(null);
    const dispatch = useDispatch();
    const { setSnackbar } = useSnackbar();
    const [createTemplateModalIsOpen, setCreateTemplateModalIsOpen] = useState(false);

    const { updateMetaDataForFilters } = useScorecardHelper()
    const handleTemplateCreated = (newTemplate, isEdit) => {
        if (isEdit) {
            let allTemplates = cloneDeep(scoringTemplates)
            let editedTemplateIndex = allTemplates.findIndex(template => template.id === newTemplate.id)
            allTemplates.splice(editedTemplateIndex, 1, newTemplate)
            setScoringTemplates(allTemplates)
        }
        else {
            setScoringTemplates([...scoringTemplates, newTemplate])
        }
        setEditTemplate(null);
        updateMetaDataForFilters()
        dispatch(getSkills())
        setCreateTemplateModalIsOpen(false)

    }

    useEffect(() => {
        if (!createTemplateModalIsOpen) {
            setEditTemplate(null);
        }
    }, [createTemplateModalIsOpen])


    useEffect(() => {
        dispatch(getSkills())
        setLoading(true)
        fetchScoringTemplates()
            .then(templates => { setScoringTemplates((templates || [])) })
            .catch((e) => {
                console.error(e)
                setSnackbar("Error loading templates", "ERROR");
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return (
        <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center px-16 mt-15">
                <TextXL textColor="text-coolGray-900" >Scorecards</TextXL>
                <Button
                    variant="primary"
                    className={classNames("!p-3", { "!invisible": scoringTemplates.length === 0 })}
                    disabled={loading}
                    onClick={() => setCreateTemplateModalIsOpen(true)}
                >
                    Create template
                </Button>
            </div>
            {(loading) &&
                <div className="px-16 flex flex-row flex-wrap">
                    <ScorecardsSettingsSkeleton />
                </div>
            }
            {!loading && scoringTemplates.length === 0 &&
                <div className="px-16 flex flex-row flex-wrap">
                    <ScorecardEmptyState className="mt-10" onCtaClick={() => setCreateTemplateModalIsOpen(true)} />
                </div>
            }
            <div className="px-16 flex flex-row flex-wrap">
                {!loading && scoringTemplates.length > 0 &&
                    scoringTemplates.map((template, index) => {
                        return (
                            <div key={template.name + index} onClick={() => { setEditTemplate(template); setCreateTemplateModalIsOpen(true); }} >
                                <TemplateCard template={template} forSettings={true} />
                            </div>
                        )
                    })
                }
            </div>
            {createTemplateModalIsOpen &&
                <>
                    <CreateTemplateModal template={editTemplate} open={createTemplateModalIsOpen} setOpen={() => setCreateTemplateModalIsOpen(false)} onSubmit={handleTemplateCreated} />
                </>
            }
        </div>
    )
};

export default ScoreCardsSettings;