import React from 'react'
import SelectWithSearchNew from 'app/components/filter/SelectWithSearchNew';
import PropTypes from 'prop-types';

const NotificationMediumSelector = ({
    selectedOption,
    setSelectedOption,
    popoverContainerRef
}) => {
    const medium = [
        {
            value: 'EMAIL',
            label: 'Email'
        },
        {
            value: 'SLACK',
            label: 'Slack'
        },
        {
            value: 'EMAIL_AND_SLACK',
            label: 'Email & Slack'
        }
    ]
    return (
        <SelectWithSearchNew
            key="channels"
            fieldName="channels"
            data={medium}
            value={selectedOption}
            label="Select Medium"
            handleChange={option => setSelectedOption(option.value)}
            dontShowCloseIcon={true}
            isMulti={false}
            autoClose={true}
            popoverContainerRef={popoverContainerRef}
        />
    )
}


export default NotificationMediumSelector;

NotificationMediumSelector.propTypes = {
    selectedOption: PropTypes.string.isRequired,
    setSelectedOption: PropTypes.func.isRequired
}

