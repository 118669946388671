import { ReactComponent as WarningIcon } from 'app/styles/assets/images/icons/Warning_Red.svg';
import { ReactComponent as SuccessIcon } from 'app/styles/assets/images/icons/Success_Green.svg';
import { TextBase, Text3XL } from 'app/designSystem/typography/Typography';

export const ModalType = {
    ERROR: 'error',
    SUCCESS: 'success',
    LOADING: 'loading',
    CUSTOM: 'custom'
};

const HorizontailProgressBar = ({ progress, color }) => {
    return (
        <div
            className={`w-full h-2 rounded-lg mt-2 bg-${color}`}
            style={{
                height: '2px',
                width: `${progress}%`
            }}
        />
    );
};

export const LoadingTypeContainer = ({ progress, message }) => {
    return (
        <div className="flex flex-col justify-center items-center border-none outline-none h-full w-full bg-white relative">
            <div className="bg-white h-full rounded-lg left-0 absolute"
                style={{
                    backgroundImage: `linear-gradient(90deg, #FFFFFF, #E5EFFF, #FFFFFF, #E5EFFF)`,
                    animation: 'load 2s infinite linear',
                    backgroundSize: '300% 100%',
                    width: `${progress}%`,
                }}></div>
            <div className="flex-col w-full absolute z-2">
                <div className="ml-4 mt-2">
                    <Text3XL className="text-brand-blue-1 font-bold mb-1">
                        {progress}%
                    </Text3XL>
                    <TextBase className="text-gray-500">
                        {message}
                    </TextBase>
                </div>
                <HorizontailProgressBar progress={progress} color="brand-blue-1" />
            </div>
        </div>
    );
};

export const ErrorTypeContainer = ({ progress, message, subMessage }) => {
    return (
        <div className="outline-none h-full bg-red-50 rounded-lg border-b-2 border-red-600">
            <div className="flex-col h-full">
                <div className="flex justify-start pl-4 items-center h-full">
                    <div className="font-bold mb-1 bg-red-100 rounded-full p-2.5 mr-2">
                        <WarningIcon />
                    </div>
                    <div className="flex-col items-center">
                        <TextBase className="text-red-600 text-s">
                            {message}
                        </TextBase>
                        {subMessage &&
                            <p className="text-gray-600">
                                {subMessage}
                            </p>}
                    </div>
                </div>
                {/* {progress ? (
                    <HorizontailProgressBar progress={progress} color="red-600" />
                ) : null} */}
            </div>
        </div>
    );
};

export const SuccessTypeContainer = ({ progress, message, subMessage }) => {
    return (
        <div className="outline-none h-full bg-green-50 rounded-lg border-b-2 border-green-600">
            <div className="flex-col h-full">
                <div className="flex justify-start pl-4 items-center h-full">
                    <div className="font-bold mb-1 bg-green-100 rounded-full p-1.5 mr-2">
                        <SuccessIcon />
                    </div>
                    <div className="flex-col items-center">
                        <TextBase className="text-green-600 text-s">
                            {message}
                        </TextBase>
                        {subMessage &&
                            <p className="text-gray-600">
                                {subMessage}
                            </p>}
                    </div>
                </div>
                {/* {progress ? (
                    <HorizontailProgressBar progress={progress} color="green-600" />
                ) : null} */}
            </div>
        </div>
    );
};

export const DefaultTypeContainer = ({ message, subMessage }) => {
    return (
        <div className="border-none outline-none h-full bg-white">
            <div className="flex-col h-full">
                <div className="flex justify-start pl-4 items-center h-full">
                    <div className="flex-col items-center">
                        <TextBase className="text-s">
                            {message}
                        </TextBase>
                        {subMessage &&
                            <p className="text-gray-600">
                                {subMessage}
                            </p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const CustomTypeContainer = ({ children }) => {
    return (
        { children }
    );
};

export const ModalTypeSelector = ({ type, progress, message, subMessage, children }) => {
    switch (type) {
        case ModalType.ERROR:
            return (
                <ErrorTypeContainer
                    progress={progress}
                    message={message}
                    subMessage={subMessage}
                />
            );
        case ModalType.SUCCESS:
            return (
                <SuccessTypeContainer
                    progress={progress}
                    message={message}
                    subMessage={subMessage}
                />
            );
        case ModalType.LOADING:
            return (
                <LoadingTypeContainer
                    progress={progress}
                    message={message}
                    subMessage={subMessage}
                />
            );
        case ModalType.CUSTOM:
            return (
                <CustomTypeContainer>
                    {children}
                </CustomTypeContainer>
            );
        default:
            return (
                <DefaultTypeContainer
                    progress={progress}
                    message={message}
                    subMessage={subMessage}
                />
            );
    }
};
