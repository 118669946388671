import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import SaveIcon from "@material-ui/icons/Save";
import { ProfileDropDownMenuItem } from "app/components/layout/ProfileDropdown";
import { useLastLocation } from "react-router-last-location";
import { CustomDropdown, ConfirmationDialog, Loading } from "../common";
import { setSnackbar } from "app/actions/screen";
import { setFilteredCalls } from "app/actions/callLog";
import { deleteCall, editMeetingTitle, getFilteredCalls, sendRequest } from "../../utils/network";
import IconButton from '@material-ui/core/IconButton';
import { Input } from "../form";
import moment from "moment";
import { useKeyListener } from "../../utils/hooks/useKeyListener";
import { doesUserHaveAccess, isAttendeeOfCall, _formatSecondsToMinutes } from "../../utils/helpers";
import { fetchDataSafely } from "../../utils/dataUtils";
import MeetingIcon from "./MeetingIcon";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { API_URL } from "app/config";
import { ShareCallButtonContainer } from "../player";
import ReactTooltip from "react-tooltip";
import AddToGameTapeButton from "../gameTapes/AddToGameTapeButton";
import { _formatSecondsToTime } from "app/utils/helpers";
import { Button, ScorecardIcon, TextSM, TextLarge } from "app/designSystem"
import { MusicIcon, RecorderIcon, TranscriptIcon, LockIcon, UnlockIcon, DownloadIcon, DeleteV2Icon, EditComment } from "app/designSystem/icons"
import ScoringPopover from "app/scorecards/ScoringPopover";
import useScorecardsPermissionHelper from "app/utils/hooks/useScorecardsPermissionHelper";
import { ViewType } from "app/constants";

const HeaderCall = props => {
  const [showDialog, setShowDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [callTitle, setCallTitle] = useState("");
  const { setSnackbar, hideSnackbar } = useSnackbar();
  const lastLocation = useLastLocation();
  const [privateCall, setPrivateCall] = useState(false);
  const [showScoringPopover, setShowScoringPopover] = useState(false)
  const rootRef = useRef()
  const showScorecardIcon = props?.auth?.stringsCustomer?.featuresGated?.scorecard
  const { showScoreCardButton } = useScorecardsPermissionHelper()
  const isCallEmbed = (props?.location?.pathname || "").includes("callembed")
  const [viewType, setViewType] = useState(ViewType.VIEW_SCORECARD_LIST)

  useKeyListener("CallTitleEdit", handleKeydown);


  function handleKeydown(e) {
    if (e.keyCode !== 13) {
      return;
    }
    handleTitleEdit();
  }

  useEffect(() => {
    if (
      props.call &&
      props.call.details &&
      props.call.details.callDataDocument
    ) {
      setCallTitle(props.call.details.callDataDocument.meetingInfo.meetingTitle);
      if (props.call.details.callDataDocument.access !== "PUBLIC") {
        setPrivateCall(true);
      }
    }
  }, [props.call]);

  if (
    !props.call ||
    !props.call.details ||
    !props.call.details.callDataDocument
  )
    return null;

  function goBack() {
    console.log("last location is:", lastLocation);
    if (lastLocation) {
      props.history.goBack()
    } else {
      props.history.push("/");
    }
  }

  async function handleTitleEdit() {
    await editMeetingTitle(props.call.details.callDataDocument.callId, callTitle);
    setEditingTitle(false);
  }

  async function handleDeleteCall() {
    setDeleting(true);
    const res = await deleteCall(props.call.details.callDataDocument.callId);
    if (res.success) {
      //Go back twice because opening a modal obstructs the first goback
      props.setSnackbar("Call deleted successfully", "SUCCESS");
      goBack();
      props.handleLoading(true)
      const searchResults = await getFilteredCalls(props.callLog.filterParams);
      if (searchResults.calls)
        props.setFilteredCalls(searchResults);
      else
        props.setFilteredCalls({ calls: [] });
      props.handleLoading(false)
    } else {
      setShowDialog(false);
      props.setSnackbar(res.message, "WARNING");
    }
    setDeleting(false);
  }

  async function toggleCallPrivacy() {
    const params = new URLSearchParams();
    params.append("callId", props.call.details.callDataDocument.callId);
    params.append("access", privateCall ? "PUBLIC" : "PRIVATE");
    const result = await sendRequest("/call/change_call_access", params);
    if (result.error) {
      setSnackbar(result.message, "ERROR");
    } else {
      setSnackbar("Call is now " + (privateCall ? "public" : "private") + "!", "SUCCESS");
      setPrivateCall(privateCall => !privateCall);
    }
  }

  async function handleDownloadCall(type) {
    const callId = props.call.details.callDataDocument.callId;
    if (props.call.videoAvailable && props.call.videoProcessed && type === "video") {
      window.open(props.call.details.signedVideoUrl);
    }
    if (type === "audio") {
      if (props.call.videoAvailable) { // for video calls, we need to get the mp3 file from the api
        window.open(API_URL + "/call/flac-to-mp3?callId=" + callId, "_blank");
      } else { // for audio calls, we can just fetch the mp3 file from the cdn
        window.open(props.call.details.signedAudioUrl);
      }
    }
    if (type === 'transcript') {
      try {
        let transcriptStr = ``
        const { conversationTurn, speakers, meetingInfo: { meetingTitle } } = props.player.playback


        let speakerMap = {}
        speakers.forEach((speaker, index) => {
          speakerMap[index] = speaker
        })

        conversationTurn.forEach((turn) => {
          transcriptStr += `>>${speakerMap[turn.speakerId - 1]}\t${_formatSecondsToTime(turn.startTime)}\n${turn.turnStr}\n\n`

        }
        )
        const blob = new Blob([transcriptStr], { type: "text/plain" })
        const transcriptDownloadLink = document.createElement('a')
        transcriptDownloadLink.href = URL.createObjectURL(blob)
        transcriptDownloadLink.download = meetingTitle + ".txt"
        transcriptDownloadLink.click()
        URL.revokeObjectURL(transcriptDownloadLink.href)
      }
      catch (e) {
        console.error("Error trying to generate and download transcript", e)
      }

    }
  }

  function hasDeletePermission() {
    const { user } = props.auth.data;
    const customer = props.auth.stringsCustomer;
    const call = props.call?.details?.callDataDocument;

    if (call?.callStatus !== "POST_PROCESSING_DONE") return false;

    if (customer.settings.authorizedCallDeleters) {
      return doesUserHaveAccess(customer.settings.authorizedCallDeleters, user, call);
    }

    return false;

  }

  function hasDownloadPermission() {
    const { user } = props.auth.data;
    const customer = props.auth.stringsCustomer;
    const call = props.call.details.callDataDocument;

    if (customer.settings.authorizedCallDownloaders) {
      return doesUserHaveAccess(customer.settings.authorizedCallDownloaders, user, call);
    }

    return false;

  }

  function hasSharingPermission() {
    const { user } = props.auth.data;
    const customer = props.auth.stringsCustomer;
    const call = props.call.details.callDataDocument;

    if (customer.settings.authorizedCallSharers) {
      return doesUserHaveAccess(customer.settings.authorizedCallSharers, user, call);
    }

    return false;

  }

  function getDisabledCopy() {
    const authorizedCallAccessChangers = props?.auth?.stringsCustomer?.settings?.authorizedCallAccessChangers ?? null;
    if (authorizedCallAccessChangers) {
      switch (authorizedCallAccessChangers) {
        case "ADMINS": return "Call privacy can be changed by participants of this call who are admins.";
        case "ADMINS_AND_MANAGERS": return "Call privacy can be changed by participants of this call who are admins or managers.";
        case "ALL": return "Call privacy can be changed by participants of this call.";
        case "NO_ONE": return "Call privacy can't be changed. Contact your Wingman admin to change this."
      }
      return "Call privacy can't be changed. Contact your Wingman admin to change this."
    }
  }

  function getDeleteDisabledCopy() {
    const call = props.call?.details?.callDataDocument;
    if (call?.callStatus !== "POST_PROCESSING_DONE") return "Only completed calls can be deleted.";
    return "You don't have the permissions required to delete this call.";
  }

  function hasPrivacySetterPermission() {
    const { user } = props.auth.data;
    const customer = props.auth.stringsCustomer;
    const call = props.call.details.callDataDocument;

    if (!isAttendeeOfCall(call, user)) {
      return false;
    }

    if (customer.settings.authorizedCallAccessChangers) {
      return doesUserHaveAccess(customer.settings.authorizedCallAccessChangers, user);
    }

    return false;
  }

  return (
    <div className="header headerCall" ref={rootRef}>
      <Loading />
      <div className="Call__ContextContainer">
        {!isCallEmbed && <div onClick={goBack} className="BackButton">
          <BackIcon color="primary" />
        </div>
        }
        <div className="Call__ContextContainer__TitleContainer">
          <div className="Player__iconContainer">
            <MeetingIcon callType={fetchDataSafely(props, "call.details.callDataDocument.callType")} />
          </div>
          <div className="headerCall__outerDiv">
            <div className="headerCall__titleDiv">
              {editingTitle &&
                <>
                  <span id="CallTitleEdit" className="headerCall__title" style={{ flexGrow: 1, maxWidth: "max(60%, 400px)" }}>
                    <Input value={callTitle} onChange={e => setCallTitle(e.target.value)} />
                  </span>
                  {!isCallEmbed && <IconButton size="small" onClick={handleTitleEdit}><SaveIcon color="primary" /></IconButton>}
                </>
              }
              {
                !editingTitle &&
                <>
                  <TextLarge id="CallTitleEdit" className="line-clamp-1 max-w-2xl">
                    {callTitle}
                  </TextLarge>
                  {!isCallEmbed && <IconButton size="small" onClick={() => { setEditingTitle(true) }}><EditComment /></IconButton>}
                </>
              }
            </div>
            <div>
              <span className="Player__text-time">
                {props.player.playback && props.player.playback.callDate ? moment(props.player.playback.callDate).format("MMMM DD, YYYY hh:mm A") : ""}
              </span>
              <span className="Player__text-time">
                {props.player.playback && props.player.playback.duration ? "  |  " + _formatSecondsToMinutes(props.player.playback.duration, " MINS") : ""}
              </span>
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div className="max-h-8 flex justify-center">
            {/* TODO Replace with a secondary button with active state after secondary btn states finalised */}
            {
              showScorecardIcon && showScoreCardButton(props.call.details.callDataDocument.scoredRep) &&
              <Button variant="secondary" startIcon={<ScorecardIcon />}
                onClick={() => setShowScoringPopover((showScoringPopover) => !showScoringPopover)}
                id="adoption-tracking-open-scorecard-panel" className="mx-1">
                Scorecards
                {(viewType !== ViewType.WRITE_SCORECARD) && (props.call?.details?.callDataDocument?.totalScorecards > 0) && (
                  <TextSM className="py-0.5 px-2 ml-1 bg-blue-100 rounded-sm" textColor="text-sky-800">{props.call.details.callDataDocument.totalScorecards}</TextSM>
                )}
                {(viewType === ViewType.WRITE_SCORECARD) &&
                  <div className=" px-1.5 py-1 ml-1 bg-blue-100 rounded-sm">
                    <EditComment />
                  </div>
                }
              </Button>
            }
            {!props.screen.fullscreenVideo && props.player.playback.callId && !isCallEmbed && (
              <AddToGameTapeButton />
            )}
            {!props.screen.fullscreenVideo && props.player.playback.callId && (
              <ShareCallButtonContainer disabled={!hasSharingPermission()} />
            )}
          </div>
          {props.auth.stringsCustomer.featuresGated.callPrivacy && !isCallEmbed &&
            <HeaderButton
              tooltipId={"PRIVATE_CALL_ICON"}
              label={privateCall ? "🔒 Private Call" : "📢 Public Call"}
              subCopy={privateCall ? "Accessible to call participants at " + props.auth.stringsCustomer.name + " and users tagged in comments." : "Accessible to everyone at " + props.auth.stringsCustomer.name + "."}
              disabledCopy={getDisabledCopy()}
              disabled={!hasPrivacySetterPermission()}
              onClick={() => toggleCallPrivacy()}
              icon={privateCall ? <LockIcon /> : <UnlockIcon />}
            />
          }
          {!isCallEmbed &&
            <CustomDropdown
              bubbleClass="ProfileDropdown"
              right={0}
              disabled={!hasDownloadPermission()}
              title={
                <HeaderButton
                  tooltipId={"DOWNLOAD_CALL_ICON"}
                  disabled={!hasDownloadPermission()}
                  disabledCopy={"You don't have the permissions required to download this call."}
                  label="Download"
                  icon={<DownloadIcon />}
                />}
              list={
                [
                  <ProfileDropDownMenuItem
                    onClick={() => handleDownloadCall("audio")}
                    label="Download Audio"
                    icon={<MusicIcon />}
                    className="ProfileDropdown__menuItem" />,
                  <ProfileDropDownMenuItem
                    onClick={() => handleDownloadCall("transcript")}
                    label="Download Transcript"
                    icon={<TranscriptIcon />}
                    className="ProfileDropdown__menuItem"
                  />,
                  ...((props.call.videoAvailable && props.call.videoProcessed) ?
                    [<ProfileDropDownMenuItem
                      onClick={() => handleDownloadCall("video")}
                      label="Download Video"
                      icon={<RecorderIcon />}
                      className="ProfileDropdown__menuItem"
                    />] : [])
                ]
              }
            />
          }
          {!isCallEmbed && <HeaderButton
            className="hover:text-red-600"
            tooltipId={"DELETE_CALL_ICON"}
            disabled={!hasDeletePermission()}
            onClick={() => setShowDialog(true)}
            label="Delete Call"
            disabledCopy={getDeleteDisabledCopy()}
            icon={<DeleteV2Icon />}
          />
          }
          <ConfirmationDialog
            title="Delete this Call?"
            message="Deletion is permanent and this call can't be restored once it is deleted."
            open={showDialog}
            yesLabel="Delete Call"
            onOk={handleDeleteCall}
            okLoading={deleting}
            onClose={() => setShowDialog(false)}
          />
        </div>
      </div>
      <ScoringPopover setShowScoringPopover={setShowScoringPopover} showScoringPopover={showScoringPopover} popoverAnchor={rootRef?.current} viewType={viewType} setViewType={setViewType} />
    </div>
  );
};

const HeaderButton = ({ icon, onClick, label, disabled, disabledCopy, tooltipId, subCopy, className }) => {

  const handleClick = () => {
    if (disabled) return;
    onClick();
  }

  return (
    <div data-tip data-for={tooltipId} onClick={handleClick} className={`headerProduct__button text-gray-500 ${className}`}>
      {icon && React.cloneElement(icon, { className: `headerProduct__buttonIcon ${disabled ? "headerProduct__buttonDisabled" : ''}` })}
      <ReactTooltip id={tooltipId} type="light" place="bottom" effect="solid" className="WhiteTooltip">
        <span className="WhiteTooltip__mainCopy">{label}</span>
        {!disabled && subCopy && <span className="WhiteTooltip__subCopy">{subCopy}</span>}
        {disabled && disabledCopy && <span className="WhiteTooltip__subCopy">{disabledCopy}</span>}
      </ReactTooltip>
    </div>
  );
}

const mapStateToProps = store => {
  return {
    ...store
  };
};

HeaderCall.propTypes = {
  call: PropTypes.object.isRequired
};

HeaderCall.defaultProps = {};

export default connect(
  mapStateToProps,
  { setSnackbar, setFilteredCalls }
)(withRouter(HeaderCall));

HeaderButton.defaultProps = {
  onClick: () => { }
}