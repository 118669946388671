export function conversatSearchEmailData(emailList, getUserEmail) {
    return emailList?.map((email) => {
        return {
            ...email,
            type: "EMAIL",
            date: email.messageDate,
            users: email?.users.map(userId => {
                return {
                    userEmail: getUserEmail(userId),
                    userId: userId
                };
            })
        };
    });
}