import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "../form";
import { logout } from "app/actions/auth";
import { setLoading } from "app/actions/screen";
import { connect } from "react-redux";
import ErrorIcon from "app/styles/assets/images/icons/error.svg";
import { sendRequest } from "../../utils/network";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.handleReload = this.handleReload.bind(this);
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((location, action) => {
      if (this.state.hasError) {
        console.log("setting has error to false", location, action)
        this.setState({
          hasError: false,
        });
      }
    });
  }



  handleReload(waitMs = 1000) {
    const userId = this.props.auth?.userId;
    if (userId) {
      //clean up all relevant saved filters before reloading
      if (window.location.pathname.toLowerCase().includes("teamcalls")) {
        localStorage.removeItem("callLogFilter" + userId);
        localStorage.removeItem("callLogFilterId" + userId);
      } else if (window.location.pathname.toLowerCase().includes("dashboard")) {
        localStorage.removeItem("managerDashboard" + userId);
        localStorage.removeItem("dashboardFilterId" + userId);
        localStorage.removeItem("dealInsights" + userId);
      } else if (window.location.pathname.toLowerCase().includes("dealcentral")) {
        localStorage.removeItem("rollup_" + userId);
        localStorage.removeItem("dealFilters" + userId);
      } else {
        localStorage.removeItem("callLogFilter" + userId);
        localStorage.removeItem("callLogFilterId" + userId);
      }
    }
    setTimeout(() => window.location.reload(), waitMs);
  }

  componentDidCatch(error, info) {
    this.props.setLoading(false);
    console.log("Error:", error.toString(), "info", info);
    if (process.env.REACT_APP_NODE_ENV === "production") {

      const lastErrorUrl = localStorage.getItem("lastErrorUrl");
      const lastErrorTime = localStorage.getItem("lastErrorTime");
      if (lastErrorTime && lastErrorUrl && window.location.href === lastErrorUrl && new Date().getTime() - lastErrorTime < 5000) {
        console.log("ignore error as I just sent an alert for the same thing.");
        this.setState({ hasError: true, error: error, info: info });
        return;
      }

      const didIReloadOnce = localStorage.getItem("didIReloadOnce");
      if (!didIReloadOnce || didIReloadOnce === 'false') {
        localStorage.setItem("didIReloadOnce", true);
        console.log("doing a auto reload  ,setting localstorage key didIReloadOnce to true...");

        const scope = new Sentry.Scope();
        scope.setExtras(info);
        scope.setTags({ "reloadFixedCrash": true, "breaksUI": true });
        Sentry.captureException(error, scope);

        /*Todo: Let's watch the sentry slack channel , If crashed logged by own API and that are equal ,remove this in a week  */
        const params = new URLSearchParams();
        params.append("url", window.location.href);
        params.append("firstTime", true);
        params.append("message", "Crashed once. Trying to autoreload: " + error.message + "\n\n\n" + JSON.stringify(info));
        sendRequest("/metrics/log_frontend_crash", params);
        /**********/


        //wait until sentry is done with the logging captured events in session or atmost 1 second
        Sentry.flush(1000).catch(() => {
          console.error("error flushing  sentry events before reload", e)
        }).finally(() => {
          console.log('flushing sentry events')
          this.handleReload(0);
        })

      } else {
        /*Todo: Let's watch the sentry slack channel , If crashed logged by own API and that are equal ,remove this in a week  */
        const params = new URLSearchParams();
        params.append("url", window.location.href);
        params.append("message", "autoreload didn't fix this: " + error.message + "\n\n\n" + JSON.stringify(info));
        sendRequest("/metrics/log_frontend_crash", params);

        /* *** */
        const scope = new Sentry.Scope();
        scope.setExtras(info);
        scope.setTags({ "reloadFixedCrash": false, "breaksUI": true });
        Sentry.captureException(error, scope);

        localStorage.setItem("lastErrorUrl", window.location.href);
        localStorage.setItem("lastErrorTime", new Date().getTime());
        localStorage.setItem("didIReloadOnce", false);
        console.log("setting didIReloadOnce to false");
        this.setState({ hasError: true, error: error, info: info });
      }
    } else {
      console.log("Inside error boundary error handler , env is not production,so skipped reload and logging to sentry");
      this.setState({ hasError: true, error: error, info: info });
      return;
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorMessage {...this.props} showReload={true} handleReload={this.handleReload} />
      );
    }
    return this.props.children;
  }
}

export const ErrorMessage = ({ type, handleReload, showReload, title, errorMessage, showSupportLink }) => {
  return (
    <div className="Error__container">
      <img src={ErrorIcon} className={"Error__" + type + "__icon"} />
      <span className={"Error__" + type + "__header"}>{title}</span>
      <span className={"Error__" + type + "__copy"}>
        {errorMessage}
        {showSupportLink && <a href="mailto:support@trywingman.com">support@trywingman.com</a>}
      </span>
      {showReload && <Button theme="dark" onClick={handleReload}>Reload</Button>}
    </div>
  );
};

ErrorMessage.defaultProps = {
  showReload: false,
  type: "page",
  title: "Oops. Something went wrong!",
  errorMessage: "An error occured in Wingman. Try to reload the page. If the problem persists, contact us on ",
  showSupportLink: true,
}

ErrorBoundary.defaultProps = {}

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { logout, setLoading },
)(withRouter(ErrorBoundary));