import React from "react";
import { PropTypes } from "prop-types";
import { Button, withStyles } from "@material-ui/core";

const styles = {
  root: {
    fontSize: "11px",
    fontWeight: "600",
  },
  mini: {
    fontSize: "10px",
    fontWeight: "600",
    padding: "2px",
    margin: "2px",
    minWidth: "0px",
  },
  
};

const CustomLink = props => {
  const { classes } = props;
  return (
    <Button className={`!text-brand-blue-1  ${props.type === "mini" ? `${classes.mini} ` : classes.root}`} {...props}>
      {props.children}
    </Button >
  );
};

CustomLink.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

CustomLink.defaultProps = {
  children: null,
  type: "default",
};

export default withStyles(styles)(CustomLink);
