import {
  MANAGER, REP,
} from "../constants";
import {
  getStartDate,
  getEndDate,
} from "../components/dashboards/dateUtils";
import {
  clone, cloneDeep
} from "lodash";


export const allFields = [
  "agentNames",
  "managers",
  "contacts",
  "domReps",
  "containsCategories",
  "notContainCategories",
  "competitorMentioned",
  "date",
  "duration",
  "talkListenRatio",
  "commentSearchTerm",
  "meetingTitle",
  "dealStageFrozen",
  "dealStageLive",
  "labels",
  "dispositions",
  "peopleTagged",
  "commentator",
  "status",
  "types",
  "query",
  "scoringTemplate",
  "scoredRep",
  "scoredBy",
  "score",
  "callTags"
]

export const allDealFields = [
  "agentNames",
  "date",
  "managers",
  "dealStage",
  "dealAmount",
  "dealNames",
  "competitors",
  "warnings",
]

export const emptyFilterParams = {
  agentNames: [],
  managers: [],
  contacts: [],
  domReps: [],
  containsCategories: [],
  notContainCategories: [],
  peopleTagged: [],
  commentator: [],
  competitorMentioned: [],
  startDate: null,
  endDate: null,
  startDuration: 1,
  endDuration: 10 * 60 * 60,
  startTalkListenRatio: 0,
  endTalkListenRatio: 1,
  commentSearchTerm: "",
  dealStageFrozen: [],
  dealStageLive: [],
  labels: [],
  dispositions: null,
  size: 50,
  from: 0,
  sortField: "time",
  sortDirection: "DESC",
  version: "2.5",
  status: [],
  types: [],
  crmFields: {},
  meetingTitle: "",
  query: "",
  scoringTemplate: [],
  scoredRep: [],
  scoredBy: [],
  score: {
    type: null,
    scoredSkill: [],
    startRange: 0,
    endRange: 5
  },
  callTags: []
};

export const initialFilterParams = {
  agentNames: [],
  managers: [],
  domReps: [],
  contacts: [],
  labels: [],
  containsCategories: [],
  notContainCategories: [],
  peopleTagged: [],
  commentator: [],
  competitorMentioned: [],
  dealStageFrozen: [],
  commentSearchTerm: "",
  meetingTitle: "",
  dealStageLive: [],
  startDate: null,
  types: [],
  status: [],
  dispositions: null,
  endDate: null,
  startDuration: 60,
  endDuration: 10 * 60 * 60,
  startTalkListenRatio: 0,
  endTalkListenRatio: 1,
  size: 50,
  from: 0,
  sortField: "time",
  sortDirection: "DESC",
  version: "2.5",
  crmFields: {},
  query: "",
  scoringTemplate: [],
  scoredRep: [],
  scoredBy: [],
  score: {
    type: null,
    scoredSkill: [],
    startRange: 0,
    endRange: 5
  },
  callTags: []
}

export const scorecardsGraphsDefaultInput = {
  startDate: null,
  endDate: null,
  users: [],
  filterParams: initialFilterParams
}

export const initialDealFilters = {
  agentNames: [],
  managers: [],
  competitors: [],
  warnings: [],
  startAmount: null,
  endAmount: null,
  startCloseDate: getStartDate("this_quarter"),
  endCloseDate: getEndDate("this_quarter"),
  stages: [],
  size: 10,
  sortField: "lastContacted",
  from: 0,
  sortDirection: "DESC",
  version: "2.0",
  crmFields: {},
}

export const emptyDealFilters = {
  agentNames: [],
  managers: [],
  competitors: [],
  warnings: [],
  startAmount: null,
  endAmount: null,
  startCloseDate: null,
  endCloseDate: null,
  stages: [],
  size: 10,
  sortField: "lastContacted",
  from: 0,
  sortDirection: "DESC",
  version: "2.0",
  crmFields: {},
}

export function getInitialDealFilters() {
  const initialDealParams = clone(initialDealFilters);
  const user = localStorage.getItem("user");

  if (user !== null && user !== undefined && user !== "undefined" && user !== "null") {
    const parsedUser = JSON.parse(user);
    if (parsedUser.roleInfo && parsedUser.roleInfo.role === MANAGER) {
      initialDealParams.managers.push(parsedUser.userId);
    }
  }
  return initialDealParams;
}

function getInitialParams(filterType) {
  const initialParams = clone(initialFilterParams);
  const user = localStorage.getItem("user");
  const managers = [];
  const agentNames = [];

  if (user !== null && user !== undefined && user !== "undefined" && user !== "null") {
    const parsedUser = JSON.parse(user);
    console.log("user is", parsedUser.emailId);
    agentNames.push(parsedUser.userId);
    if (parsedUser.roleInfo && parsedUser.roleInfo.role === MANAGER) {
      managers.push(parsedUser.userId);
    }
  }

  switch (filterType) {
    case "agentDashboard":
      initialParams.startDate = getStartDate("month");
      initialParams.endDate = getEndDate("month");
      break;
    case "userDashboard":
      initialParams.startDate = getStartDate("month");
      initialParams.endDate = getEndDate("month");
      initialParams.agentNames = agentNames;
      break;
    case "managerDashboard":
      initialParams.startDate = getStartDate("month");
      initialParams.endDate = getEndDate("month");
      break;
    case "callLogFilter":
      initialParams.managers = managers;
      break;
    case "scorecardsDashboard":
      break;
  }
  return initialParams;
}

export function getInitialFilters(filterType) {
  let filterParams = getInitialParams(filterType);
  return filterParams;
}

export function removeFilterValue(filterParams, fieldName) {
  let updatedFilterParams = clone(filterParams);
  switch (fieldName) {
    case "duration":
      updatedFilterParams.startDuration = emptyFilterParams.startDuration;
      updatedFilterParams.endDuration = emptyFilterParams.endDuration;
      break;
    case "talkListenRatio":
      updatedFilterParams.startTalkListenRatio = emptyFilterParams.startTalkListenRatio;
      updatedFilterParams.endTalkListenRatio = emptyFilterParams.endTalkListenRatio;
      break;
    case "date":
      updatedFilterParams.startDate = emptyFilterParams.startDate;
      updatedFilterParams.endDate = emptyFilterParams.endDate;
      break;
    case "score":
      const defaultScoreObject = cloneDeep(emptyFilterParams.score)
      updatedFilterParams.score = defaultScoreObject;
      break;
    default:
      if (fieldName.includes("_") && updatedFilterParams.crmFields) {
        let crmFields = clone(updatedFilterParams.crmFields);
        delete crmFields[fieldName];
        updatedFilterParams.crmFields = crmFields;
      } else {
        updatedFilterParams[fieldName] = emptyFilterParams[fieldName];
      }
  }
  return updatedFilterParams;
}

function removeCrmField(updatedFilterParams, fieldName) {
  if (!updatedFilterParams) return updatedFilterParams;
  delete updatedFilterParams[fieldName];
  return updatedFilterParams;
}

