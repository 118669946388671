import React, { useState, useEffect } from 'react'
import { ReactComponent as IncomingEmailIcon } from "app/styles/assets/images/icons/dealView/IncomingEmailIcon.svg";
import { ReactComponent as OutgoingEmailIcon } from "app/styles/assets/images/icons/dealView/OutgoingEmailIcon.svg";
import { getContactName, _formatDateToActivityDateFormat, checkIfExternal, _trimAndEllipsize } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import { fetchDataSafely } from "../../utils/dataUtils";
import { UserNameContext } from '../../containers/AccountView';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextBase, TextSM } from 'app/designSystem';


const EmailCard = ({ email, crmData, activeInteraction }) => {
    const [emailContent, setEmailContent] = useState(trimEmailContent());
    const [fromName, setFromName] = useState(email.fromName);
    const customerDomains = useSelector(store => store.auth.stringsCustomer.authorizedDomains);
    const { getRepName } = useContext(UserNameContext);

    function cleanContent(emailBody) {
        return emailBody.replace(/ *\<[^>]*\> */g, "");
    }

    function trimEmailContent() {
        return _trimAndEllipsize(email.textBody, 85);
    }

    useEffect(() => {
        let allAttendees = email.toAddresses;
        allAttendees = allAttendees.map(a => { return ({ email: a }) })
        const contactsLocal = [];
        const repsLocal = [];
        if (!crmData.contacts) crmData.contacts = [];
        let allContacts = [...crmData.contacts, ...fetchDataSafely(email, "crmData.contactDocumentList", [])]
        allAttendees.map(attendee => {
            if (checkIfExternal(attendee, customerDomains)) {
                let match = allContacts.find(c => {
                    if (c.emails) {
                        return c.emails.includes(attendee.email);
                    }
                    return false;
                });
                if (!match) {
                    match = { email: attendee.email };
                }
                contactsLocal.push(match);
            } else {
                let match = email.users.find(user => user.userEmail === attendee.email);
                if (!match) {
                    match = { userId: "", userEmail: attendee.email };
                }
                repsLocal.push(match);
            }
        })
        if (email.incoming) {
            let match = allContacts.find(c => {
                if (c.emails) {
                    return c.emails.includes(email.fromAddress)
                }
                return false;
            });
            setFromName(match ? getContactName(match) : email.fromName);
        } else {
            let match = email.users.find(user => user.userEmail === email.fromAddress);
            setFromName(match ? getRepName(match, email.fromAddress) : email.fromName);
            repsLocal.push(match ? match : { userId: "", userEmail: email.fromAddress })
        }
    }, [])

    return (
        <div className={`InteractionCard !py-4 ${activeInteraction.id === email.id ? "InteractionCardActive" : ""}`}>
            <div className="InteractionCard__iconColumn">
                {email.incoming ? <IncomingEmailIcon className="w-10 h-10" /> : <OutgoingEmailIcon />}
            </div>
            <div>
                <div className="flex flex-row w-full">
                    <TextSM className="line-clamp-1">{fromName}</TextSM>
                    <TextSM className="whitespace-nowrap ml-auto">{_formatDateToActivityDateFormat(email.messageDate)}</TextSM>
                </div>
                <TextBase className={"line-clamp-1 !font-semibold !text-coolGray-900 mt-1 mb-2"}>{_trimAndEllipsize(email.subject, 50)}</TextBase>
                <TextSM className="w-11/12">{cleanContent(emailContent)}</TextSM>
            </div>
        </div >
    );
}

export const EmailCardLoading = props => {
    return (
        <div className="InteractionCard">
            <div className="InteractionCard__iconColumn">
                <Skeleton height={40} width={40} circle={true} />
            </div>
            <div className="InteractionCard__contentColumn">
                <div className="InteractionCard__fromNameContainer">
                    <span className="InteractionCard__contactName">
                        <Skeleton height={10} />
                    </span>
                </div>
                <span className="InteractionCard__subject"><Skeleton height={25} /></span>
                <span className="InteractionCard__date"><Skeleton height={10} /></span>
                <span className="InteractionCard__bodyPreview"><Skeleton height={10} width={200} /></span>
                <div className="InteractionCard__namesContainer">
                    <div className="InteractionCard__fromNameContainer">
                        <span className="InteractionCard__contactName">
                            <Skeleton height={20} />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

EmailCard.propTypes = {}
EmailCard.defaultProps = {}
export default EmailCard;