import React from "react";
import Range from "rc-slider/lib/Range";
import "rc-slider/assets/index.css";
import { _roundUp } from "app/utils/helpers";

const RangeFilters = ({
  start, end, min, max, step, onStartChange, onEndChange, suffix,
}) => {
  function onChange([start, end]) {
    onStartChange(start);
    onEndChange(end);
  }
  return (
    <div className="Slider__container">
      <Range
        allowCross={false}
        min={min}
        step={step}
        max={max}
        value={[start, end]}
        onChange={onChange}
        trackStyle={[
          { backgroundColor: "#3150D2", borderColor: "#3150D2" },
          { backgroundColor: "#3150D2", borderColor: "#3150D2" },
        ]}
        handleStyle={[
          { backgroundColor: "#3150D2", borderColor: "#3150D2" },
          { backgroundColor: "#3150D2", borderColor: "#3150D2" },
        ]}
        railStyle={{ backgroundColor: "lightgrey" }}
      />
      <div className="Slider__labelContainer">
        <span>{start === null ? "" : `${_roundUp(start, 1)}${suffix}`}</span>
        <span>{end === null ? "" : `${_roundUp(end, 1)}${suffix}`}</span>
      </div>
    </div>
  );
};

RangeFilters.defaultProps = {
  suffix: "",
};

export default RangeFilters;
