import React from 'react'
import WarningsIcon from "app/styles/assets/images/icons/dealView/WarningsIcon.svg";
import { WARNINGS } from "app/constants"


const WarningsPopup = ({ warnings }) => {
    console.log(warnings)
    return (
        <div>
            {warnings.length > 0 && (
                <div className="DealCard__warningPopupContainer">
                    <div className="DealCard__warningPopupHeader">
                        <div className="DealView__InfoPanel__HeaderTitle">
                            <img src={WarningsIcon} className="DealView__InfoPanel__HeaderIcon" />
                            <span className="DealView__InfoPanel__HeaderText DealView__InfoPanel__HeaderTextWarning">Warnings</span>
                        </div>
                    </div>
                    {warnings.map(warning => {
                        return (
                            <div className="DealCard__warningPopupRow">
                                <div className="DealView__InfoPanel__SectionItem">
                                    <span className="DealView__InfoPanel__SectionTextWarning"></span>
                                    <span className="DealView__InfoPanel__SectionText">{WARNINGS.find(el => el.name === warning.name).label}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div >
    );
}
WarningsPopup.propTypes = {}
WarningsPopup.defaultProps = {}
export default WarningsPopup;

