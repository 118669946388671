import React, { useState, useEffect, useRef } from 'react';
import { PlayerControls, VideoPlayerWrapper, ProgressBar } from './player'
import { Player } from 'video-react';
import { addStats, updateProgress } from 'app/utils/network/gametapes';
import { useSelector, useDispatch } from 'react-redux';
import { setGameTapesGameTapesData } from 'app/actions/gametapes';
import { setUserPreferences } from "app/actions/persisted";
import { PlayNextDialog } from 'app/components/gameTapesLatest/helper';

const GameTapeVideo = ({ videoSource, gametapeId, handleVideoEnded }) => {
    const dispatch = useDispatch();

    const { playerRate } = useSelector(store => store.persisted.userPreferences);
    const { cachedGametapesChildrenData, cachedGametapesData } = useSelector(state => state.gametapes);
    const { gametape, stats } = cachedGametapesData[gametapeId];
    const videoPlayerRef = useRef(Player);
    const currentVideoProgressRef = useRef(0);

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [playbackResumed, setPlaybackResumed] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(playerRate || 1);
    const [showPlayNextDialog, setShowPlayNextDialog] = useState(false);
    const [nextGametapeTitle, setNextGametapeTitle] = useState("");

    const nextGametapeDialogThreshold = 10; // seconds

    useEffect(() => {
        addStats({ gametapeId: gametape.id });

        // Handle page reload and close events to update watch time
        window.addEventListener('beforeunload', () => {
            sendStatistics();
        });

        return () => {
            sendStatistics();
            setIsPlaying(false);

            window.removeEventListener('beforeunload', () => {
                sendStatistics();
            })
        }
    }, []);

    useEffect(() => {
        if (gametape && stats && !playbackResumed) {
            // Only seek the video player, if the video is watched less than 95% of the total time
            if (stats.progress < gametape.duration * 0.95) {
                videoPlayerRef.current.seek(stats.progress ?? 0);
            }

            setPlaybackResumed(true);
        }
    }, [gametape, stats])

    useEffect(() => {
        const duration = gametape.duration;
        const timeToShowDialog = duration - nextGametapeDialogThreshold;

        const folderId = gametape.parentFolder;
        const folderDocument = cachedGametapesChildrenData[folderId];

        if (folderDocument && folderDocument.length > 0) {
            const nextGameTapeIndex = folderDocument?.findIndex(gameTape => gameTape.id === gametapeId);

            if (nextGameTapeIndex !== -1 && nextGameTapeIndex < folderDocument?.length - 1) {
                const nextGameTapeName = folderDocument?.[nextGameTapeIndex + 1].gametape.name;
                setNextGametapeTitle(nextGameTapeName);
            }
        }

        if (currentTime >= timeToShowDialog) {
            setShowPlayNextDialog(true);
        } else {
            setShowPlayNextDialog(false);
        }
    }, [currentTime])

    useEffect(() => {
        if (isPlaying) {
            videoPlayerRef.current?.play();
        } else {
            videoPlayerRef.current?.pause();
        }

        sendStatistics();
    }, [isPlaying]);

    const handleSeek = ({ time, reverse = false, addToCurrent = true }) => {
        if (addToCurrent) {
            videoPlayerRef.current.seek(currentTime + time);

            if (reverse) {
                videoPlayerRef.current.seek(currentTime - time);
            }
        }
        else {
            videoPlayerRef.current.seek(time);
        }

        // Playback is resumed when the video is seeked to a new position
        videoPlayerRef.current.play();
        setPlaybackResumed(true);
    }

    useEffect(() => {
        videoPlayerRef.current?.subscribeToStateChange(({ currentTime, paused, ended }) => {
            setCurrentTime(currentTime);
            currentVideoProgressRef.current = currentTime;

            if (paused) {
                setIsPlaying(false);
            } else {
                setIsPlaying(true);
            }

            if (ended && parseInt(currentTime) === gametape.duration) {
                handleVideoEnded(gametape.id);
            }
        });

        videoPlayerRef.current.playbackRate = playbackSpeed;

        setTimeout(() => {
            videoPlayerRef.current.play();
        }, 500);
    }, [videoPlayerRef])

    const handleSpeedChange = (speed) => {
        setPlaybackSpeed(speed);
        videoPlayerRef.current.playbackRate = speed;
        dispatch(setUserPreferences({ playerRate: speed }));
    }

    const sendStatistics = () => {
        if (currentVideoProgressRef && currentVideoProgressRef.current > 0) {
            dispatch(setGameTapesGameTapesData(gametapeId, {
                ...cachedGametapesData[gametapeId],
                stats: {
                    ...stats,
                    progress: currentVideoProgressRef.current
                }
            }));

            updateProgress({
                gametapeId: gametape.id,
                progress: parseInt(currentVideoProgressRef.current),
                duration: gametape.duration
            });
        }
    }

    return (
        <div className="flex flex-col">
            <div className="relative flex flex-row justify-between" style={{
                height: '70vh',
                width: '100%'
            }}>
                <VideoPlayerWrapper
                    ref={videoPlayerRef}
                    src={videoSource}
                    poster={gametape.thumbnail}
                />
                <PlayNextDialog isOpen={showPlayNextDialog} callTitle={nextGametapeTitle} />
            </div>
            <div className="mx-10">
                <div className="mt-8">
                    <ProgressBar handleSeek={handleSeek} duration={gametape.duration} currentTime={currentTime} />
                </div>
                <div className="mt-8">
                    <PlayerControls handleSeek={handleSeek} isPlaying={isPlaying} setIsPlaying={setIsPlaying} isLiked={stats.liked} handleSpeedChange={handleSpeedChange} playbackSpeed={playbackSpeed} />
                </div>
            </div>
        </div>
    )
}

export default GameTapeVideo;