import React, { useState, useRef, useEffect } from 'react'
import { InputBase } from "@material-ui/core";
import { ReactComponent as Close } from "app/styles/assets/images/icons/moreFilters/Close.svg";
import { ReactComponent as TopicSearchSvg } from "app/styles/assets/images/icons/TopicSearch.svg";
import { debounce } from "lodash"
const TopicSearch = ({ setCategories, defaultCategories }) => {

    const [wordsSearched, setWordsSearched] = useState("");
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
            ref.current.autofocus = true;
        }
    })
    const deboucedSearchHandler = debounce(
        (words) => {
            var matchedCategories = findCategoriesAsPerSearch(words)
            setCategories(matchedCategories, false)
        },
        500
    )
    function findCategoriesAsPerSearch(value) {

        let currentFields = [...defaultCategories];
        const words = value.trim().split(" ");
        currentFields = currentFields.filter(field => {
            for (const word of words) {
                if (field.toLowerCase().includes(word.toLowerCase())) {
                    return true;
                }
            }

        })

        return currentFields;

    }


    function handleSearchChange(e) {
        let words = e.target.value;
        setWordsSearched(words)
        deboucedSearchHandler(words)

    }

    function clearQuery() {
        setWordsSearched("")
        setCategories(defaultCategories)
    }

    return (
        <div className="pl-2 m-1 w-6/12 rounded-xl  bg-coolGray-50  py-2">
            <div className="flex items-center px-0.5">
                <TopicSearchSvg className="mr-1" />
                <InputBase
                    id="SearchFilter"
                    className="flex-grow ml-1.5 !text-base !font-semibold !text-gray-600"
                    inputRef={ref}
                    value={wordsSearched}
                    onChange={(e) => handleSearchChange(e)}
                    placeholder="Search Topic"
                    autoComplete='off'
                />
                {wordsSearched?.length > 0 && <Close onClick={clearQuery} className="mr-1.5 cursor-pointer" />}
            </div>
        </div>
    )

}

export default TopicSearch;