import { CircularProgress, Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { TextLarge, Button, CloseIcon, EmptyCrmGroupIllustration, TextXL, TextBase } from 'app/designSystem'
import CRMGroupEditor from './CRMGroupEditor';
import PropTypes from "prop-types";
import { getDocFields } from 'app/utils/network';
import { getCrmGroups } from 'app/utils/network/crmGroup';
import useSnackbar from "app/utils/hooks/useSnackbar"
import { cloneDeep } from 'lodash';
import { v1 as uuid } from 'uuid';

const CRMGroupEditorModal = ({ open, closeModal }) => {
    const [groupList, setGroupList] = useState(null)
    const [crmFieldOptions, setCrmFieldOptions] = useState([])
    const { setSnackbar } = useSnackbar()
    const [autoFocus, setAutoFocus] = useState(false)

    useEffect(() => {
        fetchOpportunityFields()
        fetchCrmGroups()
    }, [])

    const transformCrmFieldsToOptions = (data) => {
        const docs = Object.values(data)
        return docs.map((doc) => ({ ...doc, label: doc.displayName, value: doc.id }))
    }

    const fetchOpportunityFields = async () => {
        const response = await getDocFields('OPPORTUNITY')
        if (response.error) return
        const optionsList = transformCrmFieldsToOptions(response)
        setCrmFieldOptions(optionsList)
    }

    const fetchCrmGroups = async () => {
        const response = await getCrmGroups();
        if (response.error)
            setSnackbar("Couldn't fetch CRM groups")
        else {
            const crmGroups = response.result
            if (!crmGroups) {
                setGroupList([])
                return;
            }
            const groupListLocal = crmGroups.map((crmGroup) => (
                {
                    ...crmGroup,
                    fields: crmGroup.fields.map(field =>
                        ({ ...field, label: field.displayName, value: field.id }))
                }))
            setGroupList(groupListLocal)
        }
    }

    const onCreateNewGroup = async () => {
        setAutoFocus(true)
        const newGroup = {
            id: uuid(),
            crmGroupName: "Untitled",
            fields: []
        }
        setGroupList([newGroup, ...groupList])
    }

    const handleCloseModal = () => {
        closeModal()
    }

    const handleGroupChange = (value, index) => {
        const groupListLocal = cloneDeep(groupList)
        groupListLocal[index] = value
        setGroupList(groupListLocal)
    }

    const handleGroupDelete = (id) => {
        setGroupList(groupList => groupList.filter(group => group.id !== id))
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={handleCloseModal} >
            <div className="flex flex-col bg-white rounded-lg w-7/12 ">
                <div id="crm-groups-header" className="flex justify-between items-center flex-shrink-0 border-gray-200 border-b py-4">
                    <TextLarge className="px-6"> CRM Groups </TextLarge>
                    <button className="px-6" onClick={handleCloseModal}><CloseIcon /></button>
                </div>
                {
                    (groupList && groupList.length > 0) &&
                    <div>
                        <div id="crm-groups-body" className="flex flex-col h-[50vh] overflow-y-scroll">
                            {groupList && groupList.map((group, index) => (
                                <CRMGroupEditor
                                    key={group.id}
                                    group={group}
                                    updateGroup={(value) => handleGroupChange(value, index)}
                                    handleGroupDelete={() => handleGroupDelete(group.id)}
                                    crmFieldOptions={crmFieldOptions}
                                    autoFocus={autoFocus}
                                />)
                            )}
                        </div>
                        <div className="bg-coolGray-50 py-2 flex items-center justify-end rounded-b-lg">
                            <Button
                                variant="primary"
                                className="h-8 w-40 mr-5"
                                onClick={onCreateNewGroup}>
                                Create a new group
                            </Button>
                        </div>
                    </div>}
                {
                    (groupList && groupList.length === 0) &&
                    <EmptyCrmGroupScreen
                        onCreateNewGroup={onCreateNewGroup}
                    />
                }
                {
                    !groupList &&
                    <div className="h-[50vh] w-full flex items-center justify-center">
                        <CircularProgress size={50} color="primary" />
                    </div>
                }


            </div>
        </Modal >
    );
}

export default CRMGroupEditorModal;

CRMGroupEditorModal.propTypes = {
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
}


const EmptyCrmGroupScreen = ({ onCreateNewGroup }) => {
    return (
        <div className=" w-full flex flex-col items-center justify-center">
            <EmptyCrmGroupIllustration
                className="mt-16"
            />
            <TextXL textColor="text-gray-900" className="mt-2">No CRM Groups found!</TextXL>
            <TextBase textColor="text-gray-500" className="my-1">You can add a group by clicking the button below</TextBase>
            <Button
                variant="primary"
                className="h-8 w-40 mr-5 mt-10 mb-12"
                onClick={onCreateNewGroup}>
                Create a new group
            </Button>
        </div>
    )
}

EmptyCrmGroupScreen.propTypes = {
    onCreateNewGroup: PropTypes.func,
}