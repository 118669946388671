import React from "react";
import { useDispatch } from "react-redux";
import { SET_SNACKBAR, HIDE_SNACKBAR } from "app/actions/actionTypes";

const useSnackbar = () => {
  const dispatch = useDispatch();

  function setSnackbar(snackbarMessage, snackbarType, snackbarHideDuration = 3000) {
    dispatch({ type: SET_SNACKBAR, snackbarMessage, snackbarType, snackbarHideDuration });
  }

  function hideSnackbar() {
    dispatch({ type: HIDE_SNACKBAR });
  }

  return { setSnackbar, hideSnackbar };
};

export default useSnackbar;
