import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import GmailLogo from "app/styles/assets/images/Gmail.svg";
import { NYLAS_CONFIG } from "app/config";
import { openWindowAndListenForMultipleURLParams, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import { GoogleSignInDummy } from "./SyncCalendars";
import { sendGetRequest } from "app/utils/network";

const SyncNylas = props => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector(store => store.auth.data);

  async function completeSync(code, state) {
    await crmOAuthCallback(code, 'NYLAS', null, state);
    setLoading(false);
    props.refreshUserAction();
  }

  async function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const emailSuggestion = (user && user.emailId) ? ("&login_hint=" + user.emailId) : "";
    const response = await sendGetRequest("/auth/getToken");
    if (response?.data?.error) {
      setLoading(false);
      return
    }
    const url = `https://api.nylas.com/oauth/authorize?response_type=code&client_id=${NYLAS_CONFIG.clientId}&scopes=email.read_only&redirect_uri=${location}${emailSuggestion}&state=${response.message}`;
    openWindowAndListenForMultipleURLParams(url, "Mail Integration", ["code", "state"], completeSync);
  }

  return (
    <IntegrationCard
      icon={GmailLogo}
      iconClass="email_icon"
      heading="Email"
      subHeading="Email Integration"
      bodyText="Sync your email to see relevant emails along with your meeting details."
      sync={sync}
      loading={loading}
      customButton={<GoogleSignInDummy />}
      customButtonEnabled={["googletester@stringsai.com", "tester@stringsai.com"].includes(user?.emailId)}
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncNylas.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

SyncNylas.defaultProps = {
  access: "user",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncNylas);
