import React, { useEffect, useState } from 'react';
import NotificationType from "../NotificationType";
import Select from "react-select";
import { setCallAlertPreferences } from 'app/utils/network/settings'
import { setEmailTemplateForPostCallAlert, getEmailTemplateForPostCallAlert } from 'app/utils/network/emailTemplate'
import useSnackbar from "app/utils/hooks/useSnackbar";
import { useSelector } from 'react-redux';
import { SelectWithSearchNew } from 'app/components/filter';
import { timeOptionsForSlack } from 'app/components/dashboards/dateUtils';
import EmailTemplateDropdown from '../emailTemplate/EmailTemplateDropdown';
import { Button } from 'app/designSystem'
import CRMGroupEditorModal from '../crmGroups/CRMGroupEditorModal';

const CallAlertSlackNotification = ({ pref, key }) => {
    const { setSnackbar } = useSnackbar();
    const notificationPref = useSelector(store => store.auth.data.user.slackNotificationPreferences);
    const [alertPreference, setAlertPreference] = useState();
    const [alertTime, setAlertTime] = useState(pref.id === 'postCallSlackAlertsEnabled' ? "PT18H" : "PT9H");
    const [showTime, setShowTime] = useState(false);
    const [showCrmGroup, setShowCrmGroup] = useState(false);
    const timezoneOffset = new Date().getTimezoneOffset();
    const timezoneOffsetString = (timezoneOffset < 0 ? '-PT' : 'PT') + Math.floor(Math.abs(timezoneOffset / 60)) + 'H' + Math.abs(timezoneOffset % 60) + 'M';

    useEffect(() => {
        switch (pref.id) {
            case "postCallSlackAlertsEnabled":
                setAlertPreference(pref.options.find(option => option.value === notificationPref.postCallAlertPreference))
                if (notificationPref.postCallAlertTime) setAlertTime(notificationPref.postCallAlertTime);
                break;
            case "preCallSlackAlertsEnabled":
                setAlertPreference(pref.options.find(option => option.value === notificationPref.preCallAlertPreference))
                if (notificationPref.preCallAlertTime) setAlertTime(notificationPref.preCallAlertTime)
                break;
        }
    }, [pref, notificationPref])

    useEffect(() => {
        console.log(alertTime);
    }, [alertTime])

    useEffect(() => {
        if (alertPreference && ["DAY_END", "DAY_START"].includes(alertPreference.value))
            setShowTime(true)
        else
            setShowTime(false)
    }, [alertPreference])


    const handleCallAlertPreferenceChange = async (option) => {
        setAlertPreference(option);
        const res = await setCallAlertPreferences(timezoneOffsetString, alertTime, pref.id, option.value);
        if (res.error) {
            setSnackbar("There was an error updating alert preference. Please try again.", "ERROR");
            setAlertPreference(null)
        }
        else
            setSnackbar("Successfully updated alert preference.", "SUCCESS");
    }

    const handleCallAlertTimeChange = async (option) => {
        setAlertTime(option.value)
        const res = await setCallAlertPreferences(timezoneOffsetString, option.value, pref.id, alertPreference.value);
        if (res.error) {
            setSnackbar("There was an error updating alert time. Please try again.", "ERROR");
            setAlertPreference(null)
        }
        else
            setSnackbar("Successfully updated alert time.", "SUCCESS");
    }


    const setDefaultEmailTemplateChange = async (option) => {
        const res = await setEmailTemplateForPostCallAlert(option.value);
        if (res.error) {
            setSnackbar("There was an error updating default email template. Please try again.", "ERROR");
            setAlertPreference(null)
        }
        else
            setSnackbar("Successfully updated email template preference for post call slack alert.", "SUCCESS");
    }

    const getDefaultEmailTemplateId = async () => {
        const res = await getEmailTemplateForPostCallAlert();
        if (!res.error) return res
        else return ""
    }

    return (
        <div key={key}>
            < NotificationType id={pref.id} type={pref.type} copy={pref.copy} slack />
            <div className="ml-10 mt-1 text-base flex flex-row items-center">
                <div>Send me updates</div>
                <Select
                    className="ml-4 w-48"
                    options={pref.options}
                    value={alertPreference}
                    placeholder="at"
                    onChange={handleCallAlertPreferenceChange} />
                {showTime &&
                    <div className="flex flex-row items-center">
                        <div className="mx-2 whitespace-nowrap">at</div>
                        <SelectWithSearchNew
                            key="callAlertTime"
                            fieldName="callAlertTime"
                            data={timeOptionsForSlack}
                            value={alertTime}
                            label="Select Time"
                            handleChange={handleCallAlertTimeChange}
                            dontShowCloseIcon={true}
                            position="right"
                            isMulti={false}
                            autoClose={true}
                            size="small"
                            toUpperCase
                        />
                    </div>
                }
            </div>
            {pref.id === 'postCallSlackAlertsEnabled' &&
                <>
                    <div className="ml-10 mt-1 text-base flex flex-row items-center">
                        <div>Set default email template</div>
                        <EmailTemplateDropdown
                            getDefaultEmailTemplateId={getDefaultEmailTemplateId}
                            setDefaultEmailTemplateChange={setDefaultEmailTemplateChange} />
                    </div>
                    <div className="ml-10 mt-1 text-base flex flex-row items-center">
                        <div>Setup CRM group templates</div>
                        <Button
                            variant="secondary"
                            className="ml-10 mt-1 !py-2 w-60"
                            onClick={() => setShowCrmGroup(true)}
                        >Crm Group
                        </Button>
                    </div>
                </>
            }
            <CRMGroupEditorModal
                open={showCrmGroup}
                closeModal={() => setShowCrmGroup(false)} />

        </div>
    )
}

export default CallAlertSlackNotification;
