import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { adminLogin } from "app/utils/network";
import { setUserData, setAdminLogin } from "app/actions/auth";
import { Button, Label, Input } from "app/components/form";
import { setLoading } from "app/actions/screen";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CONFIG } from "app/config";

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.setDesiredUser = this.setDesiredUser.bind(this);
    this.adminLogin = this.adminLogin.bind(this);
    this.submitOnEnter = this.submitOnEnter.bind(this);
    this.googleLoginHandler = this.googleLoginHandler.bind(this);
    this.googleError = this.googleError.bind(this);
    this.state = {
      email: "",
      password: "",
      desiredUser: "",
      error: "",
      emailError: false,
      passwordError: false,
    };
  }

  submitOnEnter(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.adminLogin();
    }
  }

  setEmail(event) {
    this.setState({ email: event.target.value, error: "" });
  }

  setPassword(event) {
    this.setState({ password: event.target.value, passwordError: false, error: "" });
  }

  setDesiredUser(event) {
    this.setState({ desiredUser: event.target.value, error: "" });
  }

  async adminLogin() {
    this.props.setLoading(true);
    try {
      const response = await adminLogin({
        email: this.state.email,
        password: this.state.password,
        desiredUser: this.state.desiredUser,
        googleVerified: false,
      });
      if (response.success) {
        const user = response;
        if (this.state.desiredUser !== this.state.email)
          await this.props.setAdminLogin(true);
        await this.props.setUserData(user);
      } else {
        this.setState({ error: response.data.message });
      }
    } catch (e) { }
    this.props.setLoading(false);
  }

  async googleLoginHandler(res) {
    if (this.state.desiredUser == "") {
      this.setState({
        error: "Please enter the Desired User.",
      });
      return;
    }
    this.props.setLoading(true);
    try {
      const response = await adminLogin({
        email: res.profileObj.email,
        password: null,
        desiredUser: this.state.desiredUser,
        googleVerified: true,
        idToken: res.tokenObj.id_token,
      });
      if (response.success) {
        const user = response;
        if (this.state.desiredUser !== res.profileObj.email)
          await this.props.setAdminLogin(true);
        await this.props.setUserData(user);
      } else {
        this.setState({ error: response.data.message });
      }
    } catch (e) { }
    this.props.setLoading(false);
  }

  googleError(error) {
    console.log(error);
    this.setState({ error: "There was an error signing in via google" });
  }

  componentDidMount() {
    const AdminLoginForm = document.getElementById("AdminLoginForm");
    AdminLoginForm.addEventListener("keyup", this.submitOnEnter);
  }

  componentWillUnmount() {
    const AdminLoginForm = document.getElementById("AdminLoginForm");
    AdminLoginForm.removeEventListener("keyup", this.submitOnEnter);
  }

  render() {
    return (
      <div className="LoginForm" id="AdminLoginForm">
        <div className="inputField">
          <Label>EMAIL</Label>
          <Input
            placeholder="Enter your email"
            value={this.state.email}
            className="Login-Page__textField"
            onChange={this.setEmail}
          />
        </div>
        <div className="inputField">
          <Label>PASSWORD</Label>
          <Input
            value={this.state.password}
            onChange={this.setPassword}
            placeholder="Enter your password"
            type="password"
          />
        </div>
        <div className="inputField">
          <Label>DESIRED USER</Label>
          <Input
            value={this.state.desiredUser}
            onChange={this.setDesiredUser}
            placeholder="Desired User"
            type="text"
          />
        </div>
        <div className="buttonOptions">
          <Button theme="dark" id="login" onClick={this.adminLogin}>
            {"Login"}
          </Button>
        </div>
        <div className="buttonOptions googleLogin">
          <GoogleLogin
            clientId={GOOGLE_CONFIG.clientId}
            className="google"
            onSuccess={this.googleLoginHandler}
            onFailure={this.googleError}
          />
        </div>
        <div className="error">
          {this.state.error && <span className="error">{this.state.error}</span>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

AdminLogin.propTypes = {
  setUserData: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
};

AdminLogin.defaultProps = {};

export default connect(
  mapStateToProps,
  { setUserData, setLoading, setAdminLogin },
)(AdminLogin);
