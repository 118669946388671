import React from "react";
import PropTypes from "prop-types";
import { Chip, withStyles } from "@material-ui/core";
import { ClosePlainCrossIcon } from "app/designSystem";
//Tailwind colors are not getting accepted here.So adding hex codes for this one component
const styles = () => ({
  root: {
    height: 30,
    margin: 2,
    fontSize: "14px",
    color: "#1E66BC",
    backgroundColor: "#E5f0fd",
    borderWidth: "0px"
  },
  mini: {
    height: 20,
    margin: 2,
    fontSize: "12px",
    backgroundColor: "#E5f0fd",
    borderWidth: "0px",
    color: "#1E66BC",
  },
  close: {
    width: 16,
    height: 16,
    color: "#ffffff",
    backgroundColor: "#E5f0fd",
    borderWidth: "0px",
    color: "#1E66BC",
  },
});

const CustomChip = props => {
  const { classes, chipProps, type } = props;
  return (
    <Chip
      color="primary"
      variant="outlined"
      size={type === "mini" ? "small" : ""}
      className={type === "mini" ? classes.mini : classes.root}
      deleteIcon={<ClosePlainCrossIcon />}
      {...chipProps}
    />
  );
};

CustomChip.propTypes = {
  classes: PropTypes.object.isRequired,
  chipProps: PropTypes.object.isRequired,
};

CustomChip.defaultProps = {
  type: "root",
};

export default withStyles(styles)(CustomChip);
