import React from 'react';
import cx from 'clsx';
import PropTypes from "prop-types";
import { useTabContext } from './TabsContext';

const TAB_LIST_KEY = "tablist__";

const TabList = (props) => {
    const {
        children,
        className,
        selectedClassName = "rounded-lg border-r border-l border-gray-300 !bg-white !text-black",
        ...attributes
    } = props;

    const tabContext = useTabContext();

    if (tabContext === null) {
        throw new Error('TabPanel must be used within a TabsProvider');
    }

    const activeTabId = tabContext.activeTabId;
    const onTabClick = tabContext.onTabClick;

    return (
        <ul
            {...attributes}
            className={cx("flex cursor-pointer rounded-lg border border-gray-300 bg-gray-100", className)}
            role="tablist"
        >
            {
                React.Children.map(children, (child, index) => {
                    const unique_id = child.props.id;

                    return React.cloneElement(child, {
                        key: TAB_LIST_KEY + unique_id,
                        isSelected: activeTabId === unique_id,
                        isLeftMost: index === 0,
                        isRightMost: index === React.Children.count(children) - 1,
                        handleClick: onTabClick.bind(null, unique_id),
                        selectedClassName
                    });
                })
            }
        </ul>
    )
}

TabList.tabsRole = 'TabList';

export default TabList;

TabList.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    selectedClassName: PropTypes.string,
};

TabList.defaultProps = {
    children: null,
    className: "",
    selectedClassName: "rounded-lg border-r border-l border-gray-300 !bg-white !text-black",
};
