import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { getSignedUrls } from "app/utils/network/call";
import { Button } from 'app/designSystem'
import { PlayIcon, PauseIcon, CloseIcon, ContinuePlayingIcon, ReplayIcon, FullScreenIcon } from 'app/designSystem/icons'
import { Modal } from "@material-ui/core";
import { VideoPlayer, ProgressBar } from 'app/components/gameTapesLatest/player'
import { TextSM, TextXL, TextBase } from "app/designSystem";
import { _formatSecondsToPlayingTime } from 'app/utils/helpers';
import PlaybackSpeedMenu from 'app/components/gameTapesLatest/player/PlaybackSpeedMenu'
import { useSelector, useDispatch } from 'react-redux';
import { setUserPreferences } from "app/actions/persisted";
import { exitFullscreen, requestFullscreen } from "app/utils/helpers";
import { Player, ControlBar, LoadingSpinner, BigPlayButton } from 'video-react';
import { metrics } from "app/utils/metrics";

const CallSnippetPreview = ({ isOpen, callTitle, callId, startTime, endTime, callDuration, hideSnippet }) => {
    const dispatch = useDispatch();
    const videoLoadedStatusCode = 4;
    const { playerRate } = useSelector(store => store.persisted.userPreferences);

    const videoPlayerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [videoUrl, setVideoUrl] = useState(null);
    const [currentTime, setCurrentTime] = useState(startTime);
    const [snippetEndTime, setSnippetEndTime] = useState(endTime);
    const [playbackSpeed, setPlaybackSpeed] = useState(playerRate || 1);
    const [isVideoBuffering, setIsVideoBuffering] = useState(true);
    const [videoEnded, setVideoEnded] = useState(false);
    const [isExtended, setIsExtended] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [poster, setPoster] = useState("https://image.freepik.com/free-vector/colourful-music-sound-waves-background_23-2148424538.jpg");

    useEffect(() => {
        return () => {
            if (videoPlayerRef.current !== null) {
                videoPlayerRef.current = null;
            }
        };
    }, [videoPlayerRef]);

    useEffect(() => {
        setPoster(videoUrl && videoUrl.endsWith("mp4") ? null : "https://image.freepik.com/free-vector/colourful-music-sound-waves-background_23-2148424538.jpg");
    }, [videoUrl]);

    useEffect(() => {
        if (snippetEndTime && currentTime >= snippetEndTime) {
            handleVideoEnded();
        }
    }, [currentTime, snippetEndTime]);

    useEffect(() => {
        if (isPlaying) {
            videoPlayerRef.current?.play();
            setVideoEnded(false);
        } else {
            videoPlayerRef.current?.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        fetchVideoUrl(callId);
    }, [callId]);

    const fetchVideoUrl = async (callId) => {
        const response = await getSignedUrls(callId);
        setVideoUrl(response.videoUrl ?? response.audioUrl);
    };

    const handleVideoStateChange = (props) => {
        const { currentTime, paused, readyState } = props;

        if (readyState === videoLoadedStatusCode) {
            setIsVideoBuffering(false);
        } else {
            setIsVideoBuffering(true);
        }

        if (currentTime) {
            setCurrentTime(currentTime);
        }

        if (paused) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    };

    const setVideoPlayerRef = (videoPlayer) => {
        if (videoPlayer) {
            videoPlayerRef.current = videoPlayer;
            videoPlayer.playbackRate = playbackSpeed;
            videoPlayer?.subscribeToStateChange(handleVideoStateChange);
        }
    };

    const handleSpeedChange = (speed) => {
        setPlaybackSpeed(speed);
        videoPlayerRef.current.playbackRate = speed;
        dispatch(setUserPreferences({ playerRate: speed }));
    }

    const handleVideoEnded = () => {
        setVideoEnded(true);
        setIsPlaying(false);
    }

    const handleVideoReplay = () => {
        setIsExtended(null);
        setVideoEnded(false);

        setTimeout(() => {
            videoPlayerRef.current.seek(startTime);
            setSnippetEndTime(endTime);
        }, 250);

        setTimeout(() => {
            setIsPlaying(true);
        }, 500);
    }

    const handleVideoExtended = () => {
        setVideoEnded(false);
        setIsExtended(true);
        setSnippetEndTime(callDuration); // set end time to call duration

        setTimeout(() => {
            setIsPlaying(true);
        }, 500);
    }

    const handleFullScreen = () => {
        if (isFullScreen) {
            exitFullscreen();
        } else {
            requestFullscreen(document.querySelector('#call-snippet-preview'));
        }

        setIsFullScreen(!isFullScreen);
    }

    const openCallPage = () => {
        metrics.logEventHeap("openCallPageAccountViewSearch");
        window.open("/call/" + callId + "#time=" + startTime);
    }

    return (
        <Modal
            open={isOpen}
            onClose={hideSnippet}
            className="flex justify-center items-center transition-all duration-300"
        >
            <div className={`flex flex-col bg-white h-[28rem] w-[44rem] ${isFullScreen ? "rounded-none" : "rounded-xl"}`} id="call-snippet-preview">
                <div className="flex items-center justify-between px-4 py-3">
                    <div className="flex items-center">
                        <TextXL className="text-center">{callTitle}</TextXL>
                        <Button variant="secondary" className="py-2 ml-4" onClick={openCallPage}>
                            <TextBase>View Call</TextBase>
                        </Button>
                    </div>
                    <Button variant="tertiary" className="p-1 rounded-full" onClick={hideSnippet}>
                        <CloseIcon />
                    </Button>
                </div>
                <div className="relative w-full h-full">
                    <Player
                        ref={videoPlayer => setVideoPlayerRef(videoPlayer)}
                        height="100%"
                        width="100%"
                        fluid={false}
                        src={videoUrl}
                        poster={poster}
                        startTime={startTime}
                        autoPlay={true}
                        preload="auto"
                    >
                        <source src={videoUrl} />
                        <ControlBar disableCompletely />
                        <LoadingSpinner />
                        <BigPlayButton className="hidden" position="center" />
                    </Player>
                    {
                        videoEnded && (
                            <div className="absolute top-0 w-full h-full flex justify-center items-center z-1000" style={{
                                background: "rgba(58, 58, 58, 0.4)",
                            }}>
                                <Button variant="secondary" className="py-2 px-4 mr-6" onClick={handleVideoReplay}>
                                    <ReplayIcon className="mr-2" />
                                    <TextBase>Replay</TextBase>
                                </Button>
                                {
                                    isExtended ? <Button className="py-2 px-4 text-white" onClick={openCallPage}>
                                        <ContinuePlayingIcon className="mr-2" />
                                        <TextBase textColor="text-white">View Call</TextBase>
                                    </Button> : <Button className="py-2 px-4 text-white" onClick={handleVideoExtended}>
                                        <ContinuePlayingIcon className="mr-2" />
                                        <TextBase textColor="text-white">Continue playing</TextBase>
                                    </Button>
                                }
                            </div>
                        )
                    }
                </div>
                <div className="flex items-center px-4 py-2">
                    <Button
                        id="tape_play_btn"
                        className="!rounded-full w-9 h-9"
                        onClick={() => setIsPlaying(!isPlaying)}
                    >
                        {(isVideoBuffering || isPlaying) ? <PauseIcon /> : <PlayIcon className="ml-0.5" />}
                    </Button>
                    <div className="ml-4 w-full my-auto flex items-center">
                        <div className="flex items-center">
                            <TextSM>{_formatSecondsToPlayingTime(currentTime)}/{_formatSecondsToPlayingTime(callDuration)}</TextSM>
                        </div>
                        <div className="w-full">
                            <ProgressBar duration={callDuration} currentTime={currentTime} showTimestamps={false} disableCursor={true} />
                        </div>
                        <div>
                            <PlaybackSpeedMenu currentSpeed={playbackSpeed} onChange={handleSpeedChange} />
                        </div>
                        <div onClick={handleFullScreen} className="ml-4 cursor-pointer">
                            <FullScreenIcon />
                        </div>
                    </div>
                </div>
            </div >
        </Modal >
    );
};

export default CallSnippetPreview;

CallSnippetPreview.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    callId: PropTypes.string.isRequired,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    hideSnippet: PropTypes.func.isRequired,
    callTitle: PropTypes.string,
    callDuration: PropTypes.number
};
