import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setStackedScreenName, setScreenType, setLoading } from "app/actions/screen";
import { getCallsForAccount, refreshUser } from "app/utils/network";
import { STACKED, MAIN } from "app/constants";
import { CallCardWithStats } from "app/components/opportunities";
import { NoData } from "app/components/common";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calls: [],
      okayTLRatioThreshold: null,
      goodTLRatioThreshold: null,
    };
  }
  async componentDidMount() {
    this.props.setStackedScreenName("Account Details");
    this.props.setScreenType(STACKED);
    const response = await refreshUser();
    this.setState({
      okayTLRatioThreshold: response.user.stringsCustomer.settings.okayTLRatioThreshold,
      goodTLRatioThreshold: response.user.stringsCustomer.settings.goodTLRatioThreshold,
    });
  }
  async UNSAFE_componentWillMount() {
    this.props.setLoading(true);
    const accountId = this.props.match.params.accountId || "";
    const calls = await getCallsForAccount(accountId);
    this.setState({
      calls,
    });
    this.props.setLoading(false);
  }
  componentWillUnmount() {
    this.props.setScreenType(MAIN);
  }
  render() {
    if (this.state.calls.length === 0) {
      return (
        <NoData
          title="No calls found"
          style={{ height: "calc(100vh - 60px)", margin: "-1em -2em" }}
        />
      );
    }
    return (
      <div>
        {this.state.calls.length > 0 && (
          <div style={{ marginTop: "30px" }} className="Manager-Dashboard__head-time">
            Past Meetings
          </div>
        )}
        {this.state.calls.map((call, i) => {
          return (
            <CallCardWithStats
              okayTLRatioThreshold={this.state.okayTLRatioThreshold}
              goodTLRatioThreshold={this.state.goodTLRatioThreshold}
              call={call}
              key={i}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

Account.propTypes = {
  classes: PropTypes.object,
  setStackedScreenName: PropTypes.func.isRequired,
  setScreenType: PropTypes.func.isRequired,
};

Account.defaultProps = {};

export default connect(
  mapStateToProps,
  { setScreenType, setStackedScreenName, setLoading },
)(Account);
