import React, { useState } from "react";
import { CustomTooltip, CustomChip } from "app/components/common";
import { CircularProgress, TextField } from "@material-ui/core";
import { useKeyListenerRef } from "../../utils/hooks/useKeyListener";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { useEffect } from "react";
import {
    Header, Input, Label
} from "app/components/form";

const TagFilledInputArea = (props) => {
    const [values, setValues] = useState([]);
    const [hideInputField, setHideInputField] = useState(props.disabled);
    const [names, setNames] = useState(props.incorrectWords ?? [])
    const [deletedIncorrectWords, setDeletedIncorrectWords] = useState([]);



    const [tagRef] = useKeyListenerRef(onKeyEnter);
    const { setSnackbar } = useSnackbar();



    function onChange(e) {
        props.forDictionary ? props.handleRecentlyAddedIncorrectWord(e.target.value) : null
        setValues(e.target.value.split("\n"));
    }

    async function addWordForDictionary(phrase) {
        const allIncorrectWords = props.allIncorrectWords;
        const incorrectWords = names.map((word) => word.toLowerCase())
        const word = phrase.trim();
        if (word != "") {
            if (allIncorrectWords[word.toLowerCase()] && !deletedIncorrectWords.includes(word)) {
                props.handleErrorMessagesForIncorrectWords("A correction has already been added for " + word)
                return;
            }

            else if (incorrectWords.includes(word.toLowerCase())) {
                props.handleErrorMessagesForIncorrectWords("This word is already added")
                return;
            }
            else if (props.correctWord.trim().toLowerCase() == word.toLowerCase()) {
                props.handleErrorMessagesForIncorrectWords("Correct and incorrect words cannot be the same")
                return;
            }
            else {
                props.handleErrorMessagesForIncorrectWords("")
                var allWords = [...names].flat();
                allWords.push(word)
                handleChanges(allWords);
                setNames(allWords);
                setValues([]);
            }
        }
    }

    async function addPhraseForTopics(phrase) {
        const phraseAdded = phrase.trim();
        if (phraseAdded != "") {
            const allPhrases = [...names].flat();
            allPhrases.push(phraseAdded)
            handleChanges(allPhrases);
            setNames(allPhrases);
            setValues([]);
        }
    }

    function onKeyEnter(event) {
        event.stopPropagation();
        event.preventDefault();

        if (event.keyCode === 13 && !event.shiftKey) {
            if (event.ctrlKey || event.altKey) {
                let tempValues = values;
                tempValues.push("");
                setValues(tempValues);
            }
            else values.map(value => {
                if (value) props.forDictionary ? addWordForDictionary(value) : addPhraseForTopics(value);
            });
        }
    }

    function handleClick(value) {
        let textToBeCopied = value;
        navigator.clipboard.writeText(textToBeCopied);
        setSnackbar("Phrase Copied!", "SUCCESS", 300);
    }

    function removePhrase(value) {
        let phraseToBeRemoved = value;
        let localNames = [...names].flat();
        let index = localNames.indexOf(phraseToBeRemoved);
        localNames.splice(index, 1)
        handleChanges(localNames)
        setNames(localNames);
        var deletedWords = [...deletedIncorrectWords];
        deletedWords.push(value);
        setDeletedIncorrectWords(deletedWords)
    }



    function handleChanges(changes) {
        props.handleChangesToMatches(changes);
    }



    return (
        <div style={{ overflow: "auto" }} ref={tagRef}>
            {!hideInputField && (
                <Input
                    value={values.join("\n")}
                    onChange={onChange}
                    className="KeywordsTracking__addPhrase"
                />
            )}

            <div className="m-1 items-center h-auto inline-block flex-wrap mt-2">
                {names && names.map((phrase, i) => {
                    return (

                        <CustomTooltip key={i} placement="top" enterDelay={100} title={!props.disabled ? "Click to copy" : null}>
                            <div style={{ display: "inline-block" }}>
                                <CustomChip
                                    className="KeywordsTracking__chip"
                                    chipProps={{
                                        label: phrase,
                                        onClick: () => handleClick(phrase),
                                        onDelete: () => {
                                            removePhrase(phrase);
                                        },
                                        disabled: props.disabled
                                    }}

                                />
                            </div>
                        </CustomTooltip>

                    )


                })}


            </div>


        </div>
    );
}

export default TagFilledInputArea;