import React, { useState, useEffect, Suspense } from 'react'
import { sendRequest } from '../utils/network';
import { setScreenType, setStackedScreenName } from "app/actions/screen";
import { useSelector, useDispatch } from "react-redux";
import { STACKED, MAIN } from '../constants';
import AccountViewHeader from '../components/dealView/AccountViewHeader';
import EmailPreview from '../components/dealView/EmailPreview';
import CallPreview, { PreviewLoading } from '../components/dealView/CallPreview';
import { PreviewAccountActivity } from '../components/dealView/AccountActivity';
import AccountInfoPanel from '../components/dealView/AccountInfoPanel';
import { fetchDataSafely } from '../utils/dataUtils';
import { AccountViewSearchContainer } from './AccountViewSearchContainer';
import DealStageBlue from "app/styles/assets/images/icons/dealView/DealStageBlue.svg";
import DealStageYellow from "app/styles/assets/images/icons/dealView/DealStageYellow.svg";
import DealStageViolet from "app/styles/assets/images/icons/dealView/DealStageViolet.svg";
import DealStageRed from "app/styles/assets/images/icons/dealView/DealStageRed.svg";
import DealStageGreen from "app/styles/assets/images/icons/dealView/DealStageGreen.svg";
import DealStagePurple from "app/styles/assets/images/icons/dealView/DealStagePurple.svg";
import { GetSortOrder } from '../components/dashboards/helpers';
import { useRef } from 'react';
import { findIndex } from 'lodash';
import { NoDataCustom } from '../components/common/NoData';
import NoInteractions from "app/styles/assets/images/icons/dealView/NoInteractions.svg"
import { _getDateAtMidnight } from '../utils/helpers';
import Interactions from '../components/dealView/Interactions';
import metrics from '../utils/metrics';
import { usePageTimeTracker } from '../utils/hooks/usePageTimeTracker';
import { getDealDataFromES } from 'app/utils/network/dealBoard';
import classNames from "classnames";
import { useLocation } from "react-router-dom";

const Activity = React.lazy(() => import('app/components/dealView/AccountActivity'));

export const dealStageColors = [
    { icon: DealStageGreen, color: "#07d79c", className: "DealView__interactionsContainerGreen" },
    { icon: DealStageRed, color: "#f6576e", className: "DealView__interactionsContainerRed" },
    { color: "#750fa5", icon: DealStageViolet, className: "DealView__interactionsContainerViolet" },
    { color: "#ffbc01", icon: DealStageYellow, className: "DealView__interactionsContainerYellow" },
    { icon: DealStageBlue, color: "#078cd7", className: "DealView__interactionsContainerBlue" },
    { icon: DealStagePurple, color: "#5925c7", className: "DealView__interactionsContainerPurple" }];

export const UserNameContext = React.createContext({});

const AccountView = props => {
    const dispatch = useDispatch();

    const search = useLocation().search;
    const crmId = new URLSearchParams(search).get('crmId');
    const [accountId, setAccountId] = useState(null);
    const [interactions, setInteractions] = useState([]);
    const [activeDeal, setActiveDeal] = useState([]);
    const [dealDataFromES, setDealDataFromES] = useState(null);
    const [allEvents, setAllEvents] = useState([]);
    const [activeInteraction, setActiveInteraction] = useState(null);
    const [crmData, setCrmData] = useState({});
    const cardRefs = useRef({});
    const interactionsRef = useRef(null);
    const [latestStage, setLatestStage] = useState(null);
    const [callIds, setCallIds] = useState([]);
    const [emailIds, setEmailIds] = useState([]);
    const [showSearchResult, setShowSearchResult] = useState(false);

    const [loading, setLoading] = useState(false);
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const auth = useSelector(s => s.auth);

    usePageTimeTracker(["DealCentral", "AccountView"]);


    useEffect(() => {
        setLatestStage(fetchDataSafely(crmData, "opportunity.stage", "null"));
        return (() => {
            if (!auth.isAdminLogin)
                metrics.logEndOfPage("accountView");
        })
    }, [crmData]);

    useEffect(() => {
        dispatch(setScreenType(STACKED));
        dispatch(setStackedScreenName("Account View"));
        checkIfAccountIdIsPresent();
        return () => {
            dispatch(setScreenType(MAIN));
        }
    }, []);

    useEffect(() => {
        getAllEvents();
    }, [interactions, crmData, activeDeal]);

    useEffect(() => {
        if (activeDeal?.id)
            getDealData(activeDeal?.id);
    }, [activeDeal])

    useEffect(() => {
        if (accountId) {
            fetchData();
        }
    }, [accountId])

    async function checkIfAccountIdIsPresent() {
        if (crmId) {
            setLoading(true);
            const params = new URLSearchParams();
            params.append("crmId", crmId);
            const dealAccountId = await sendRequest("/account/get_accountId_by_crmId", params);

            if (dealAccountId) {
                setAccountId(dealAccountId);
            }

            setLoading(false);
        } else {
            setAccountId(props.match.params.accountId);
        }
    }

    function getDealId() {
        if (window.location.hash !== "") {
            const searchTurn = window.location.hash.slice(1, window.location.hash.length);
            if (searchTurn.indexOf("deal=") !== -1) {
                return searchTurn.split("deal=")[1];
            }
        }
        return null;
    }

    function getRepName(userId, email) {
        const index = findIndex(userNames, function (o) { return o.value == userId; });
        if (index === -1) return email;
        const name = userNames[index].label;
        return name;
    }

    function addRef(ref, id) {
        cardRefs.current[id] = ref;
    }

    async function fetchData() {
        setLoading(true);
        const dealId = getDealId();
        await Promise.all([
            getAccountDoc(dealId),
            getInteractions(dealId),
            getAccountDocSlow(dealId)
        ])
        setLoading(false);
    }

    async function getDealData(dealId) {
        const accountLatestDeal = await getDealDataFromES(accountId, dealId) || null;
        setDealDataFromES(accountLatestDeal);
    }

    async function getInteractions(dealId) {
        const params = new URLSearchParams();
        if (dealId) params.append("dealId", dealId);
        params.append("accountId", accountId);
        let interactionsLocal = await sendRequest("/account/get_interactions_for_account", params);
        let interactionsEmail = [];
        let interactionsCall = [];
        if (interactionsLocal["emails"]) {
            let emailIdsLocal = [];
            interactionsLocal["emails"].map(email => emailIdsLocal.push(email.id));
            setEmailIds(emailIdsLocal);
            interactionsEmail = interactionsLocal["emails"].map(email => ({ date: email.messageDate, type: "EMAIL", ...email }));
        }

        if (interactionsLocal["calls"]) {
            let callIdsLocal = [];
            interactionsLocal["calls"].map(call => callIdsLocal.push(call.callId));
            setCallIds(callIdsLocal);
            interactionsCall = interactionsLocal["calls"]
                .filter(call => new Date(call.callTime).getTime() <= new Date().getTime())
                .map(call => ({ date: call.callTime, type: "CALL", ...call }));
        }

        interactionsLocal = [...interactionsEmail, ...interactionsCall];
        interactionsLocal.sort(GetSortOrder("date", "DESC"))
        setInteractions(interactionsLocal);
    }

    function isStageClosed(stage) {
        if (stage && (stage.toLowerCase().includes("won") || stage.toLowerCase().includes("lost")))
            return true;
        return false
    }

    function getAllEvents() {
        if (!interactions || interactions.length === 0) return;
        setActiveInteraction(interactions[0]);
        const allEventsLocal = [];

        if (crmData.opportunities) {
            crmData.opportunities.map(opp => {
                if (opp.id !== activeDeal?.id) return;
                let stageChangeHistory = fetchDataSafely(opp, "stageChangeHistory", []);
                stageChangeHistory.map(change => {
                    allEventsLocal.push({ date: change.dateChanged, type: "STAGE_CHANGED", id: opp.id + "STAGE_CHANGED" + change.stage, opportunity: opp, stage: change.stage });
                })
                allEventsLocal.push({ date: opp.createdDate, type: "OPPORTUNITY_CREATED", id: opp.id + "_CREATED", opportunity: opp });
                if (opp.closeDate && new Date(opp.closeDate).getTime() < new Date().getTime() && isStageClosed(opp.stage))
                    allEventsLocal.push({ date: opp.closeDate, type: "OPPORTUNITY_CLOSED", id: opp.id + "_CLOSED", opportunity: opp });
            })
        }

        interactions.map(interaction => {
            if (interaction.type === "EMAIL") {
                allEventsLocal.push({ date: interaction.messageDate, type: "EMAIL", email: interaction, id: interaction.id });
            } else {
                allEventsLocal.push({ date: interaction.callTime, type: "CALL", call: interaction, id: interaction.callId });
            }
        });

        allEventsLocal.sort(GetSortOrder("date", "DESC"));
        setAllEvents(allEventsLocal);
    }

    async function getAccountDoc(dealId) {
        const params = new URLSearchParams();
        params.append("accountId", accountId);
        if (dealId) params.append("dealId", dealId);
        const crmDataLocal = await sendRequest("/account/get_crmdata_by_account_id", params);
        setCrmData(crmDataLocal);
    }

    async function getAccountDocSlow(dealId) {
        const params = new URLSearchParams();
        params.append("accountId", accountId);
        if (dealId) params.append("dealId", dealId);
        const crmDataLocal = await sendRequest("/account/get_crmdata_by_account_id_slow", params);
        setCrmData(crmDataLocal);
    }

    function scrollToCard(id) {
        if (cardRefs.current[id]) {
            cardRefs.current[id].scrollIntoView({ block: "start", behavior: "smooth" })
        }
    }

    function scrollToDate(date) {
        let formattedDate = _getDateAtMidnight(new Date(date));
        for (let i in allEvents) {
            let event = allEvents[i];
            let eventDate = _getDateAtMidnight(new Date(event.date));
            if (eventDate.getTime() == formattedDate.getTime()) {
                scrollToCard(event.id);
                if (event.type === "CALL") setActiveInteraction(event.call);
                if (event.type === "EMAIL") setActiveInteraction(event.email);
                break;
            }
        }
    }

    function handleActiveInteractionChange(id) {
        const interaction = interactions.find(i => i.id === id || i.callId === id);
        if (interaction) {
            setActiveInteraction(interaction);
            scrollToCard(id);
        }
    }

    function setInteractionRef(ref) {
        interactionsRef.current = ref;
    }
    return (
        <UserNameContext.Provider value={{ getRepName }}>
            <div className="flex flex-col h-screen w-screen">
                <AccountViewHeader
                    crmData={crmData}
                    loading={loading}
                    interactions={interactions}
                    activeDeal={activeDeal}
                />
                {((interactions && interactions.length > 0) || loading) &&
                    <div className="flex flex-row">
                        <div className={"DealView__Interactions"}>
                            <Interactions
                                setInteractionRef={setInteractionRef}
                                loading={loading}
                                activeInteraction={activeInteraction}
                                allEvents={allEvents}
                                addRef={addRef}
                                interactions={interactions}
                                setActiveInteraction={setActiveInteraction}
                                crmData={crmData}
                                scrollToCard={scrollToCard}
                                activeDeal={activeDeal}
                                setActiveDeal={setActiveDeal}
                                disableInteractions={showSearchResult ? true : false}
                            />
                        </div>
                        <div className="DealView__MidSection">
                            <div className="DealView__AccountActivity">
                                <Suspense fallback={<PreviewAccountActivity />}>
                                    {loading && <PreviewAccountActivity />}
                                    {!loading && activeDeal && <Activity activity={dealDataFromES?.activity ?? []} scrollToDate={scrollToDate} />}
                                </Suspense>
                            </div>
                            <div className="DealView__BottomMidSection">
                                {loading && <div className={"DealView__InteractionView"}>
                                    <PreviewLoading />
                                </div>}
                                {!loading && <div className={classNames("DealView__InteractionView p-0")}>
                                    <AccountViewSearchContainer
                                        callIds={callIds}
                                        emailIds={emailIds}
                                        accountId={accountId}
                                        crmData={crmData}
                                        showSearchResult={showSearchResult}
                                        setShowSearchResult={setShowSearchResult}
                                    />
                                    {
                                        !showSearchResult && activeInteraction && <div className={"p-8 pt-0 h-full overflow-y-auto"}>
                                            {activeInteraction.type === "EMAIL" && <EmailPreview key={activeInteraction.id} crmData={crmData} email={activeInteraction} />}
                                            {activeInteraction.type === "CALL" && <CallPreview key={activeInteraction.callId} crmData={crmData} meetingTitle={activeInteraction?.meetingInfo?.meetingTitle} callTime={activeInteraction?.callTime} callId={activeInteraction?.callId} callType={activeInteraction.callType} />}
                                        </div>
                                    }
                                </div>
                                }
                                <div className={classNames("DealView__InfoPanel overflow-y-hidden", showSearchResult ? "blur-sm pointer-events-none" : "")}>
                                    <AccountInfoPanel deal={activeDeal} crmData={crmData} loading={loading} interactions={interactions} dealDataFromES={dealDataFromES} warnings={dealDataFromES?.warnings ?? []} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!loading && (!interactions || interactions.length === 0) &&
                    <NoDataCustom
                        icon={NoInteractions}
                        copy="No emails/calls found on Wingman for this deal"
                        subcopy="Please reach out to support@trywingman.com for help."
                    />
                }
            </div>
        </UserNameContext.Provider>
    );
}
AccountView.propTypes = {}
AccountView.defaultProps = {}

export default AccountView;
