import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HubSpotLogo from "app/styles/assets/images/integrations/hubspot.png";
import SalesforceLogo from "app/styles/assets/images/onboarding/salesforce.png";
import PipedriveLogo from "app/styles/assets/images/onboarding/PipedriveLogo.png";
import LeadsquaredLogo from "app/styles/assets/images/onboarding/leadsquaredLogo.png";
import FreshsalesLogo from "app/styles/assets/images/integrations/freshsales.png";
const CrmIcon = ({ height, width }) => {
    const defaultCrm = useSelector(store => store?.auth?.stringsCustomer?.defaultCrm);
    const [defaultCrmIcon, setDefaultCrmIcon] = useState(null);

    useEffect(() => { setCrmIcon() }, []);

    const setCrmIcon = () => {
        switch (defaultCrm) {
            case "SALESFORCE": setDefaultCrmIcon(SalesforceLogo); break;
            case "HUBSPOT": setDefaultCrmIcon(HubSpotLogo); break;
            case "FRESHSALES": setDefaultCrmIcon(FreshsalesLogo); break;
            case "PIPEDRIVE": setDefaultCrmIcon(PipedriveLogo); break;
            case "LEADSQUARED": setDefaultCrmIcon(LeadsquaredLogo); break;
        }
    }
    return (
        <img src={defaultCrmIcon} style={{ height, width }} />
    );
}
CrmIcon.propTypes = {}
CrmIcon.defaultProps = {
    height: "25px",
    width: "25px",
}
export default CrmIcon;