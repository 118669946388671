import React, { useEffect, useState } from "react";
import FilterCard from "./FilterCard";
import { TextXL, Button } from "app/designSystem";
import { sendRequest, getFrontendCrmFields } from "app/utils/network";
import { CustomDropdown, ConfirmationDialog } from "../common";
import SaveAsModal from "./SaveAsModal";
import downIcon from "app/styles/assets/images/icons/filter/Arrow.svg";
import DeleteIcon from "app/styles/assets/images/icons/filter/Delete.svg";
import DeleteHoverIcon from "app/styles/assets/images/icons/filter/DeleteHover.svg";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { emptyFilterParams } from "app/utils/filter";
import { isEqual, omit, clone } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import FilterIcon from "app/styles/assets/images/icons/filter/Filter.svg";
import { SET_CALL_LOG_FILTER_ID, SET_DASHBOARD_FILTER_ID, SET_SCORECARDS_DASHBOARD_FILTER_ID } from "../../actions/actionTypes";

import DateFiltersNew from "./DateFiltersNew";
import ReactTooltip from "react-tooltip";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import AlertButton from "app/components/common/AlertButton";
import { ButtonWithDropdown } from "app/designSystem/";

const omitFields = ["size", "from", "sortField", "sortDirection", "version", "startDate", "endDate"];

const Filter = props => {
  const defaultFilter = { id: "Default Filter", name: "All Calls", filterParams: emptyFilterParams };
  const allFilters = useSelector(store => store.screen.metaData.savedFilters);
  const dashboardFilterId = useSelector(store => store.screen.dashboardFilterId);
  const callLogFilterId = useSelector(store => store.screen.callLogFilterId);
  const scorecardsDashboardFilterId = useSelector(store => store.screen.scorecardsDashboardFilterId);
  const userId = useSelector(store => store.auth.userId);
  const [savedFilters, setSavedFilters] = useState(allFilters || []);
  const { setSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(getInitialFilter());
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [hasFilterChanged, setHasFilterChanged] = useState(isFilterNew());
  const [deleteHover, setDeleteHover] = useState(false);
  const [deleteIconRef] = useHoverListenerRef(() => setDeleteHover(true), () => setDeleteHover(false));
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isDefaultFilterSelected = selectedFilter?.id === "Default Filter";
  const dataOptions = {
    "EMPTY": {
      type: "",
      headerText: "",
      buttonText: ""
    },
    "ALERT": {
      type: "ALERT",
      headerText: "Update Alert Preference",
      buttonText: "Update"
    },
    "UPDATE": {
      type: "UPDATE",
      headerText: "Update Filter",
      buttonText: "Update"
    },
    "SAVE_AS": {
      type: "SAVE_AS",
      headerText: "Save new Filter",
      buttonText: "Save as"
    }
  }
  const [data, setData] = useState(dataOptions["EMPTY"]);

  const dropdownData = [
    { label: "Save as", onClick: () => handleOpenModal("SAVE_AS") }
  ]

  useEffect(() => {
    fetchData(true);
    setSelectedFilter(getInitialFilter());
  }, []);

  useEffect(() => {
    if (isFilterNew()) {
      console.log("not equal:", props.filterParams, selectedFilter.filterParams)
      setHasFilterChanged(true);
    } else {
      console.log("equal", props.filterParams, selectedFilter.filterParams)
      setHasFilterChanged(false);
    }
  }, [props.filterParams, selectedFilter]);

  function isFilterNew() {
    return !isEqual(omit(props.filterParams, omitFields), omit(selectedFilter.filterParams, omitFields));
  }

  function getCurrentFilterId() {
    let id = "";
    if (props.type === "MANAGER") {
      id = dashboardFilterId
    }
    else if (props.type === "SCORECARDS") {
      id = scorecardsDashboardFilterId
    }
    else {
      id = callLogFilterId
    }

    return id;

  }

  function getInitialFilter() {
    let initialFilter = clone(defaultFilter);
    let found = false;
    const currentFilterId = getCurrentFilterId();
    if (savedFilters && savedFilters.length > 0) {
      savedFilters.map(filter => {
        if (currentFilterId === filter.id) {
          initialFilter = filter;
          found = true;
        }
      })
    }
    if (!found && currentFilterId !== "Default Filter") {
      saveAndApplyFilter(initialFilter);
    }
    return initialFilter;
  }

  function saveAndApplyFilter(filter) {
    if (props.type === "MANAGER") {
      localStorage.setItem(`dashboardFilterId${userId}`, filter.id);
      dispatch({ type: SET_DASHBOARD_FILTER_ID, filterId: filter.id });
    } else if (props.type === "SCORECARDS") {
      localStorage.setItem(`scorecardsDashboardFilterId${userId}`, filter.id);
      dispatch({ type: SET_SCORECARDS_DASHBOARD_FILTER_ID, filterId: filter.id });
    }
    else {
      localStorage.setItem(`callLogFilterId${userId}`, filter.id);
      dispatch({ type: SET_CALL_LOG_FILTER_ID, filterId: filter.id });
    }
    props.onApplyFilter({ ...filter.filterParams, startDate: props.filterParams.startDate, endDate: props.filterParams.endDate, sortField: props.filterParams.sortField, sortDirection: props.filterParams.sortDirection, version: emptyFilterParams.version });
  }

  async function fetchData(setCurrentFilter = false) {
    let allFilters = await sendRequest("/filter/getAllSavedFilters");
    const currentFilterId = getCurrentFilterId()
    if (allFilters) {
      setSavedFilters(allFilters);
      if (!setCurrentFilter) return;
      allFilters = allFilters.map(filter => {
        filter.filterParams.crmFields = getFrontendCrmFields(filter.filterParams.crmFields);
        if (currentFilterId === filter.id) {
          handleFilterChange(filter, false);
        }
        return filter;
      });
    }
  }

  async function handleDelete() {
    const params = new URLSearchParams();
    params.append("id", selectedFilter.id);
    await sendRequest("/filter/delete_filter", params);
    setSnackbar("Filter is deleted.", "SUCCESS");
    handleFilterChange(defaultFilter);
    fetchData();
  }

  function handleFilterChange(filter, applyFilter = true, persistDates = true) {
    setSelectedFilter(filter);
    console.log("setting filter to:", filter, applyFilter);
    if (props.type === "MANAGER") {
      localStorage.setItem(`dashboardFilterId${userId}`, filter.id);
      dispatch({ type: SET_DASHBOARD_FILTER_ID, filterId: filter.id });
    } else if (props.type === "SCORECARDS") {
      localStorage.setItem(`scorecardsDashboardFilterId${userId}`, filter.id);
      dispatch({ type: SET_SCORECARDS_DASHBOARD_FILTER_ID, filterId: filter.id });
    }
    else {
      localStorage.setItem(`callLogFilterId${userId}`, filter.id);
      dispatch({ type: SET_CALL_LOG_FILTER_ID, filterId: filter.id });
    }
    if (applyFilter) {
      if (persistDates)
        props.onApplyFilter({ ...filter.filterParams, startDate: props.filterParams.startDate, endDate: props.filterParams.endDate, sortField: props.filterParams.sortField, sortDirection: props.filterParams.sortDirection, version: emptyFilterParams.version });
      else
        props.onApplyFilter({ ...filter.filterParams, sortField: props.filterParams.sortField, sortDirection: props.filterParams.sortDirection, version: emptyFilterParams.version });
    }
  }

  function onSaveFilter(selectedFilter) {
    fetchData();
    handleFilterChange(selectedFilter, true, true);
  }

  function onChange(fieldName, value) {
    const filterParamsUpdated = clone(props.filterParams);
    filterParamsUpdated[fieldName] = value;
    props.onApplyFilter(filterParamsUpdated);
  }

  function onMultipleChanges(fieldNames, values) {
    const filterParamsUpdated = clone(props.filterParams);
    fieldNames.map((fieldName, i) => {
      filterParamsUpdated[fieldName] = values[i];
    })
    props.onApplyFilter(filterParamsUpdated);
  }

  function resetFilter() {
    handleFilterChange(defaultFilter, true, false);
  }

  const handleOpenModal = (type) => {
    setData(dataOptions[type])
    setShowSaveModal(true);
  }

  const hideSaveAsModal = () => {
    setShowSaveModal(false)
    setData(dataOptions["EMPTY"])
  }

  console.log("🚀 ~ file: Filter.jsx ~ line 210 ~ savedFilters", savedFilters)

  return (
    <div className="w-full px-8">
      <div className="Filter__header flex flex-row items-center">
        <TextXL className="mr-2">Saved Filters:</TextXL>
        <CustomDropdown
          bubbleClass={"Filter__dropdown"}
          top={30}
          left={10}
          title={
            <div className="Filter__dropdown__trigger">
              <img src={FilterIcon} className={"Filter__dropdown__filterIcon"} />
              <span className="Filter__dropdown__triggerText">{selectedFilter.name}</span>
              <img className="Filter__dropdown__triggerIcon" src={downIcon} />
            </div>}
          list={[defaultFilter, ...savedFilters].map(v => <div key={v.id} onClick={() => handleFilterChange(v)} className="Filter__dropdown__item">{v.name}</div>)} />
        <div className="Filter__header__button flex flex-row items-center">
          {selectedFilter.id !== "Default Filter" &&
            <AlertButton
              isEnabled={selectedFilter.alertEnabled}
              onClick={() => handleOpenModal("ALERT")}
            />}
          {selectedFilter.id !== "Default Filter" && <div ref={deleteIconRef}>
            <ReactTooltip id="filter_delete" effect="solid">
              <span>Delete Filter</span>
            </ReactTooltip>
            <img data-tip data-for="filter_delete" onClick={() => setShowDeleteModal(true)} className="Filter__savedFilterButton" src={deleteHover ? DeleteHoverIcon : DeleteIcon} />
          </div>}
          <ButtonWithDropdown
            height="h-9"
            className="ml-2"
            dontShowDropdown={isDefaultFilterSelected}
            dropdownData={dropdownData}
            onClick={() => handleOpenModal(isDefaultFilterSelected ? "SAVE_AS" : "UPDATE")}
            disabled={!hasFilterChanged}
          >
            {isDefaultFilterSelected ? "Save as" : "Update"}
          </ButtonWithDropdown>

          <SaveAsModal
            open={showSaveModal}
            allFilters={[defaultFilter, ...savedFilters]}
            hideModal={hideSaveAsModal}
            filterParams={props.filterParams}
            selectedFilter={selectedFilter}
            onSaveFilter={onSaveFilter}
            data={data}
          />
          <ConfirmationDialog
            title="Delete saved filter"
            message="Are you sure you want to delete this filter?"
            open={showDeleteModal}
            yesLabel="Delete"
            noLabel="Cancel"
            onOk={() => {
              setShowDeleteModal(false);
              handleDelete();
            }}
            onClose={() => {
              setShowDeleteModal(false);
            }} />

          <div className="Filter__fieldContainer ml-10">
            <DateFiltersNew
              key="Date"
              showIcon
              onStartDateChange={options =>
                onChange("startDate", options)
              }
              onStartAndEndChange={values => onMultipleChanges(["startDate", "endDate"], values)}
              onEndDateChange={options => onChange("endDate", options)}
              startDate={props.filterParams.startDate}
              fieldName="date"
              enableRemove={false}
              removeFilterValue={() => props.removeFilterValue("date")}
              endDate={props.filterParams.endDate}
            />
          </div>
          <div className="Filter__fieldContainer" style={{ margin: "0px 10px" }}>
            <Button className="h-9 w-28" variant="secondary" onClick={() => resetFilter()}>Clear all</Button>
          </div>

        </div>
        <div className="Filter__header__rightContent">
          {props.headerRightContent}
        </div>
      </div>
      <FilterCard name={selectedFilter.name} resetFilter={resetFilter} {...props} />
    </div >
  );
}
export default Filter;

