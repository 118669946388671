import React, { useCallback, useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Modal } from "@material-ui/core";
import { sendRequest } from "app/utils/network";
import { useExitPrompt } from 'app/utils/hooks/useExitPrompt';
import Input from 'app/designSystem/form/Input';
import Button from 'app/designSystem/buttons/Button';
import FileUploadForm from 'app/designSystem/form/FileDropDown';
import { TabPanel, TabList, Tab, TabsProvider } from 'app/designSystem/tabs';
import { TextBase } from 'app/designSystem/typography/Typography';
import SelectWithSearchNew from "app/components/filter/SelectWithSearchNew";
import moment from 'moment';
import { cleanCommaSeperatedStrings, validateStringsOfEmails, urlRegexPattern, isWordMatchingThePattern } from '../../utils/helpers'
import { ReactComponent as MinimizeIcon } from 'app/styles/assets/images/icons/Minimize_Icon.svg'
import { ReactComponent as VideoUrlIcon } from "app/styles/assets/images/VideoUrl.svg"
import { ReactComponent as FileUploadIcon } from "app/styles/assets/images/FileUpload.svg"
import { ReactComponent as CancelIcon } from 'app/styles/assets/images/icons/Cancel_Icon.svg'
import { ReactComponent as WarningIcon } from 'app/styles/assets/images/icons/Warning_Red.svg';
import { ReactComponent as SuccessIcon } from 'app/styles/assets/images/icons/Success_Green.svg';
import { ReactComponent as CloseIcon } from 'app/styles/assets/images/icons/Close_Icon.svg';
import { isDoubleChannelAudio } from "app/utils/audioUtils";

// Define the source for axios requests
let source = axios.CancelToken.source();

const UploadCallRecordingForm = ({ open, hide, fileUploadProgress, setFileUploadProgress, uploadCallRecordingStatus, setFileUploadStatus, userNames }) => {
    const [formData, setFormData] = useState({
        meetingDate: moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"),
        meetingAttendees: "",
        meetingReps: "",
        recordingUrl: "",
        recordingFile: null,
        meetingTitle: "",
    });
    const [fieldsDisabled, disableFields] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const formRef = useRef();
    const errorMessageRef = useRef();
    const [usersWithoutVoiceFingerprint, setUsersWithoutVoiceFingerprint] = useState(true);
    const [repsList, setRepsList] = useState([]);
    const [isWarned, setIsWarned] = useState(false);
    const [isDualChannelAudio, setIsDualChannelAudio] = useState(false);

    // If a file is being uploaded, prevent the user from exiting the page by showing a prompt
    useExitPrompt(uploadCallRecordingStatus === "LOADING");

    const resetForm = useCallback(() => {
        setFormData({
            meetingDate: moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm"),
            meetingAttendees: "",
            meetingReps: "",
            recordingUrl: "",
            recordingFile: null,
            meetingTitle: "",
        });
    }, [setFormData]);

    useEffect(() => {
        setIsWarned(false);
        if (formData.recordingFile) isDoubleChannelAudio(formData.recordingFile).then(isDualChannel => {
            console.log(isDualChannel, isWarned);
            setIsDualChannelAudio(isDualChannel);
        })
    }, [formData.recordingFile, usersWithoutVoiceFingerprint]);

    useEffect(() => {
        onChangeHandler("meetingReps", repsList?.length > 0 ? repsList.map(rep => rep?.value) : []);
        if (repsList) setUsersWithoutVoiceFingerprint(repsList.filter(reps => !reps.hasVoiceFingerprint).map(reps => reps.label).join(' and '));
        else setUsersWithoutVoiceFingerprint('');
    }, [repsList]);

    const handleClose = () => {
        if (uploadCallRecordingStatus === "SUCCESS") {
            uploadAgain();
        }

        hide();

        if (uploadCallRecordingStatus === "SUCCESS" || uploadCallRecordingStatus === "ERROR") {
            setFileUploadStatus("IDLE");
        }
    }

    const cancelUpload = () => {
        source.cancel('UPLOAD CANCELLED BY USER');
        setFileUploadStatus("IDLE");
        setFileUploadProgress(0);
        setErrorMessage(null);
        disableFields(false);
    }

    const handleValidations = () => {
        if (formData.meetingTitle.length === 0) {
            setErrorMessage("Please enter a title for the recording.");
            setIsWarned(false);
            return false;
        }

        if (formData.meetingReps.length === 0) {
            setErrorMessage("Please select at least one rep for the recording.");
            setIsWarned(false);
            return false;
        }

        const filteredMeetingAttendees = cleanCommaSeperatedStrings(formData.meetingAttendees);
        onChangeHandler("meetingAttendees", filteredMeetingAttendees);

        // Validate emails
        if (!validateStringsOfEmails(filteredMeetingAttendees)) {
            setErrorMessage("Please enter valid email addresses");
            setIsWarned(false);
            return false;
        }

        // Validate date
        if (formData.meetingDate === "") {
            setErrorMessage("Please select a date for the recording.");
            setIsWarned(false);
            return false;
        }

        // Validate recording url
        if (formData.recordingUrl && !isWordMatchingThePattern(formData.recordingUrl, urlRegexPattern)) {
            setErrorMessage("Please enter a valid recording url");
            setIsWarned(false);
            return false;
        }

        if (!formData.recordingFile && formData.recordingUrl === "") {
            setErrorMessage("Please select a file or enter a recording URL");
            setIsWarned(false);
            return false;
        }

        //VFP and channel based warning
        if (!isDualChannelAudio && usersWithoutVoiceFingerprint.length > 0) {
            if (isWarned) return true;
            setErrorMessage('Voice fingerprint for ' + usersWithoutVoiceFingerprint + ' is missing. Wingman will not be able to identify the speakers in the call correctly. Submit again to continue!');
            setIsWarned(true);
            return false;
        }

        return true;
    }

    //add an option to cancel file upload
    const handleFileUpload = async () => {

        // Validate the form
        if (!handleValidations()) {
            setFileUploadStatus("ERROR");
            return;
        }

        const uploadRecordingFormData = new FormData();

        if (formData.recordingFile) {
            // Add the file to form data
            uploadRecordingFormData.append('file', formData.recordingFile);
        } else {
            uploadRecordingFormData.append('recordingUrl', formData.recordingUrl);
        }

        uploadRecordingFormData.append('meetingTitle', formData.meetingTitle);
        uploadRecordingFormData.append('externalAttendeeEmail', formData.meetingAttendees);
        uploadRecordingFormData.append('meetingReps', formData.meetingReps?.join(","));
        uploadRecordingFormData.append('meetingDate', new Date(formData.meetingDate).getTime());

        //UPdate the status to uploading
        setFileUploadStatus("LOADING")
        //Disable the form fields
        disableFields(true);
        // Hide the modal when the upload is started
        hide();

        try {
            source = axios.CancelToken.source();

            const response = await sendRequest(
                '/call/upload_recording',
                uploadRecordingFormData,
                null,
                {
                    'Content-Type': 'multipart/form-data'
                },
                false,
                {
                    onUploadProgress: progressEvent => {
                        const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))

                        if (progress < 95) {
                            setFileUploadProgress(progress);
                        }
                        else {
                            setFileUploadProgress(95);
                        }
                    },
                    cancelToken: source.token
                }
            )

            if (response.error) {
                throw response.message;
            }

            setFileUploadProgress(100);

            setTimeout(() => {
                setFileUploadStatus("SUCCESS");
                resetForm();
            }, 750);
        } catch (error) {
            console.log(error);
            setErrorMessage(error);
            setFileUploadStatus("ERROR")
            uploadAgain();
        }
    }

    const uploadAgain = () => {
        resetForm();
        disableFields(false);
        setFileUploadProgress(0);
    }

    //Resets the file/url when a different tab is selected
    const tabChangeHandler = (index) => {
        if (uploadCallRecordingStatus !== "LOADING") {
            if (index === 0) {
                onChangeHandler("recordingUrl", "");
            } else if (index === 1) {
                onChangeHandler("recordingFile", null);
            }
        }
    }

    // Handles the change selection
    const onChangeHandler = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });

        setErrorMessage(null);
        setFileUploadStatus("IDLE");
    }

    // When a errorMssage is set, scroll to the respective ref
    useEffect(() => {
        if (errorMessage && errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errorMessage]);

    return (
        <Modal open={open} onClose={handleClose} className="flex justify-center items-center">
            <div className="!bg-white rounded-lg" style={{
                width: "657px",
                maxHeight: "calc(100vh - 6.25rem)",
                padding: 0,
                outline: "none"
            }}>
                <div className="px-8 py-4">
                    <div className="flex justify-between w-full">
                        <p className="text-xl font-semibold text-gray-800"> Call Upload </p>
                        <button onClick={handleClose}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
                <hr />
                <form ref={formRef} className="flex-col justify-between" onSubmit={(e) => e.preventDefault()}>
                    <div className="px-8 py-4 overflow-y-scroll" style={{
                        maxHeight: "calc(100vh - 14rem)"
                    }}>
                        <Input
                            label="Call Title"
                            value={formData.meetingTitle}
                            onChange={(e) => onChangeHandler("meetingTitle", e.target.value)}
                            disabled={fieldsDisabled}
                            type="text"
                            placeholder="Demo Meeting"
                            className="mb-4"
                            aria-required="true"
                        />
                        <Input
                            label="External Attendees"
                            value={formData.meetingAttendees}
                            onChange={(e) => onChangeHandler("meetingAttendees", e.target.value)}
                            disabled={fieldsDisabled}
                            type="text"
                            placeholder="prospect@mydomain.com, prospect@micro.com"
                            className="mb-4"
                        />

                        <TextBase className="mb-2">Reps</TextBase>
                        <SelectWithSearchNew
                            key="agentNames"
                            fieldName="agentNames"
                            value={formData.meetingReps}
                            data={userNames}
                            label="Select Reps"
                            placeholder="Type rep name"
                            handleChange={userIds => setRepsList(userIds)}
                            disabled={fieldsDisabled}
                            dontShowCloseIcon={true}
                            isInCallUploadForm={true}
                            popoverContainerRef={formRef}
                            joinPhrase=" and "
                        />

                        <div className="mt-4 items-center">
                            <TextBase className="mb-2">Call Date</TextBase>
                            <div className="flex">
                                <input
                                    type="datetime-local"
                                    className="p-3 rounded border border-gray-300"
                                    value={formData.meetingDate}
                                    onChange={(e) => onChangeHandler("meetingDate", e.target.value)}
                                    max={moment().format("YYYY-MM-DD") + "T" + moment().format("HH:mm")}
                                    disabled={fieldsDisabled} />
                            </div>
                        </div>

                        <div className="border-b border-dotted border-gray-300 mt-6 mb-6" />
                        <TabsProvider onTabChangeHandler={tabChangeHandler}>
                            <TabList>
                                <Tab leftIcon={<FileUploadIcon color="gray" />} label="File Upload" id={0} />
                                <Tab leftIcon={<VideoUrlIcon />} label="Video/Audio URL" id={1} />
                            </TabList>
                            <div style={{
                                minHeight: '175px'
                            }}>
                                <TabPanel id={0}>
                                    <FileUploadForm
                                        onFileDrop={(files) => onChangeHandler("recordingFile", files?.[0])}
                                        accept={'video/mp4, audio/mpeg ,audio/mp3, audio/wav, audio/flac, audio/x-m4a'}
                                        multiple={false}
                                        placeholder={<><p className="font-medium">Drop your file here or Browse</p><p>Supported formats: mp4, mp3, wav, m4a, flac</p></>}
                                        disabled={fieldsDisabled}
                                        customStyles={{
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </TabPanel>
                                <TabPanel id={1}>
                                    <Input
                                        label="Video/Audio URL: (Supported formats: mp4, mp3, wav, m4a, flac)"
                                        value={formData.recordingUrl}
                                        onChange={(e) => onChangeHandler("recordingUrl", e.target.value)}
                                        disabled={fieldsDisabled}
                                        type="text"
                                        placeholder="http://www.mydomain.com/video.mp4"
                                    />
                                </TabPanel>
                            </div>
                        </TabsProvider>

                        <br />

                        {
                            (formData.recordingFile && uploadCallRecordingStatus !== "LOADING") && <div className="flex justify-between items-center rounded-lg py-5 px-5 border relative" style={{
                                background: `linear-gradient(to right, #F8FBFF ${fileUploadProgress}%, #fff ${fileUploadProgress}%)`
                            }}>
                                <div className="flex w-full">
                                    <p className="text-gray-900 text-l font-semibold">File to Upload: </p><p> {formData.recordingFile?.name}</p>
                                </div>
                            </div>
                        }

                        {
                            uploadCallRecordingStatus === "LOADING" && <div className="flex justify-between items-center rounded-lg py-5 px-5 border relative" style={{
                                background: `linear-gradient(to right, #F8FBFF ${fileUploadProgress}%, #fff ${fileUploadProgress}%)`
                            }}>
                                <div className="flex-col w-full">
                                    <p className="text-gray-900 text-l font-semibold">Uploading : {formData.recordingFile?.name}</p>
                                    <div className="text-gray-500 text-s">
                                        {fileUploadProgress}%
                                    </div>
                                    <div>
                                        {fileUploadProgress === 95 && (
                                            <div className="text-gray-500 text-xs">
                                                Performing checks on file.
                                                Do not refresh the page.
                                            </div>
                                        )}
                                    </div>
                                    <div className="w-full h-2 bg-brand-blue-1 rounded-lg" style={{
                                        height: "2px",
                                        width: `${fileUploadProgress}%`
                                    }}></div>
                                </div>
                                <div className=" absolute right-3 ">
                                    <button onClick={cancelUpload} disabled={fileUploadProgress === 100}>
                                        <CancelIcon />
                                    </button>
                                </div>
                            </div>
                        }

                        <br />

                        <div ref={errorMessageRef}>
                            {
                                ((uploadCallRecordingStatus === "IDLE" && errorMessage) || uploadCallRecordingStatus === "ERROR") && !isWarned &&
                                <div className="flex justify-start items-center rounded py-3 pl-5 pr-10 bg-red-50 cursor-pointer">
                                    <div className="mr-2 p-3 bg-red-100 rounded-full">
                                        <WarningIcon />
                                    </div>
                                    <div className="flex flex-col">
                                        <TextBase className="text-red-600 text-md">{uploadCallRecordingStatus === "ERROR" ? "Upload" : "Form"} Error</TextBase>
                                        <TextBase className="text-grey-500 font-normal">{errorMessage}</TextBase>
                                    </div>
                                </div>
                            }
                            {
                                errorMessage && isWarned &&
                                <div className="flex justify-start items-center rounded py-3 pl-5 pr-10 bg-orange-50 cursor-pointer">
                                    <div className="mr-2 p-3 bg-orange-100 rounded-full">
                                        <WarningIcon />
                                    </div>
                                    <div className="flex flex-col">
                                        <TextBase className="text-orange-600 text-md">Warning</TextBase>
                                        <TextBase className="text-orange-400 font-normal">{errorMessage}</TextBase>
                                    </div>
                                </div>
                            }
                        </div>

                        {
                            uploadCallRecordingStatus === "SUCCESS" && <div className="flex justify-start items-center rounded py-3 pl-5 pr-10 bg-green-50 cursor-pointer">
                                <div className="mr-2 p-2 bg-green-100 rounded-full">
                                    <SuccessIcon />
                                </div>
                                <div className="flex flex-col">
                                    <TextBase className="text-green-600 text-md">Upload Successful</TextBase>
                                    <TextBase className="text-grey-500 font-normal">This call will be available after processing. Please wait for a few minutes.</TextBase>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="px-8 py-5 flex items-center justify-end" style={{
                        backgroundColor: "#F8FBFF"
                    }}>
                        <Button onClick={handleFileUpload} className="w-44 rounded-md" disabled={fieldsDisabled} loading={uploadCallRecordingStatus === "LOADING"}>
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
        </Modal >
    )
}


export default UploadCallRecordingForm;
