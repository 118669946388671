import React, { Component, useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { createBattlecard, editBattlecard, deleteBattlecard, useNetworkRequest } from "app/utils/network";
import Footer from "app/components/BattleCard/form/Footer";
import CategoryDropDown from "app/components/BattleCard/form/CategoryDropDown";
import TriggerWordInput from "app/components/BattleCard/form/TriggerWordInput";
import { Header } from "app/components/form";
import { isEqual, clone } from "lodash";
import { ConfirmationDialog } from "../common";
import useSnackbar from "app/utils/hooks/useSnackbar";
import loadable from '@loadable/component'

const TalkingPointsBox = loadable(() => import(/* webpackChunkName: 'TalkingPointsBox', webpackPrefetch: true  */'app/components/BattleCard/form/TalkingPointsBox'))

const BattlecardFormModal = props => {
  const [state, setState] = useState(getState());
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
  const { setSnackbar } = useSnackbar();
  const [currentTrigger, setCurrentTrigger] = useState("");

  const { fetch } = useNetworkRequest();

  useEffect(() => {
    setState(getState());
  }, [props.battlecard, props.allCategories]);

  function getState() {
    let state;
    if (props.battlecard === null) {
      state = {
        listContent: [""],
        htmlContent: "<ul><li></li></ul>",
        triggers: [],
        talkingPoints: [""],
        category: "",
        edit: false,
        allCategories: props.allCategories || [],
        publishStatus: "DRAFT"
      };
    } else {
      const { battlecard } = props;
      state = {
        ...battlecard,
        edit: true,
        htmlContent: battlecard.htmlContent || getHtmlContentFromListContent(battlecard.listContent) || "",
        talkingPoints: JSON.parse(JSON.stringify(battlecard.listContent || [])),
        allCategories: props.allCategories || [],

      };
    }
    return state;
  }

  function getHtmlContentFromListContent(listContent) {
    return listContent === null ? [] : "<ul>" + listContent.map(content => ("<li>" + content + "</li>")).join("") + "</ul>";
  }

  function editHtmlContent(htmlContent) {
    console.log(htmlContent);
    setState({
      ...state,
      htmlContent,
    });
  }

  function editTalkingPoint(index, newTalkingPoint) {
    const { talkingPoints } = state;
    talkingPoints[index] = newTalkingPoint;
    setState({
      ...state,
      talkingPoints,
    });
  }

  function deleteTalkingPoint(index) {
    const { talkingPoints } = state;
    setState({
      ...state,
      talkingPoints,
    });
  }

  function onAddTrigger(trigger) {
    console.log("onAdd", trigger);
    const filterParams = { query: trigger, endDate: new Date(), startDate: new Date().setDate(new Date().getDate() - 100) }
    //const res = await fetch("/dashboard/wordOccurence", filterParams);
    //console.log("response on add trigger", res);
    const trigs = clone(state.triggers);
    trigs.push({ keyword: trigger });
    setState(state => ({
      ...state,
      triggers: trigs,
    }));
    return trigs;
  }

  function onRemoveTrigger(i, trigger) {
    const trigs = clone(state.triggers);
    trigs.splice(i, 1);
    setState({
      ...state,
      triggers: trigs,
    });
  }

  function changeCategory(category) {
    setState({
      ...state,
      category,
    });
  }

  function addCategory(category) {
    const { allCategories } = state;
    allCategories.push(category);
    setState({
      ...state,
      allCategories,
      category,
    });
  }

  async function handleClose(checkChanges = false) {
    if (checkChanges && hasAnythingChanged(props.battlecard)) {
      setShowUnsavedChangesDialog(true);
      return;
    }
    await setState(getState());
    props.hide();
  }

  function hasAnythingChanged(battlecard) {
    if (!battlecard) {
      if (isEqual(state, getState())) return false;
      return true;
    }
    if (battlecard.category === state.category
      && isEqual(battlecard.htmlContent, state.htmlContent)
      && isEqual(battlecard.triggers, state.triggers)) return false;
    return true;
  }

  async function handleDelete() {
    if (state.edit) {
      await deleteBattlecard(state.id);
      props.removeBattlecard(props.battlecard, state.edit);
    }
    handleClose();
  }

  async function submit(publishStatus) {
    publishStatus === "DRAFT" ? setSaving(true) : setPublishing(true);
    let battlecard;
    let triggers = state.triggers;
    let listContent = state.talkingPoints.filter(tp => tp !== "");
    if (currentTrigger !== "") {
      triggers = onAddTrigger(currentTrigger);
      setCurrentTrigger("");
    }

    if (!triggers || triggers.length === 0) {
      setSnackbar("No trigger words found", "ERROR");
      publishStatus === "DRAFT" ? setSaving(false) : setPublishing(false);
      return;
    }
    if (!listContent || listContent.length === 0) {
      listContent = [""];
    }
    if (state.edit) {
      battlecard = await editBattlecard({ ...state, triggers, listContent, publishStatus });
    } else {
      battlecard = await createBattlecard({ ...state, triggers, listContent, publishStatus });
    }
    publishStatus === "DRAFT" ? setSaving(false) : setPublishing(false);
    props.addOrUpdateBattlecard(battlecard, state.edit);
    handleClose();
  }

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => handleClose(true)}
        className="battlecardForm__outerContainer"
      >
        <div className="battlecardForm__container">
          <Header
            boldTitle=""
            title={(state.edit ? "Edit " : "New ") + "Battle Card"}
            onCancel={() => handleClose(true)}
          />
          <div className="battlecardForm__categoryAndTriggerContainer">
            <TriggerWordInput currentTrigger={currentTrigger} setCurrentTrigger={setCurrentTrigger} onAdd={onAddTrigger} onRemove={onRemoveTrigger} value={state.triggers} />
            <CategoryDropDown
              categories={state.allCategories || []}
              onChange={changeCategory}
              value={state.category}
              addCategory={addCategory}
            />
          </div>
          <span className="battlecardForm__label">Show Talking Points:</span>
          <TalkingPointsBox
            talkingPoints={state.htmlContent}
            onEdit={editHtmlContent}
          />
          <Footer
            saving={saving}
            publishing={publishing}
            onDelete={handleDelete}
            edit={state.edit}
            onSubmit={submit}
          />
        </div>
      </Modal>
      <ConfirmationDialog
        title="Discard unsaved changes"
        message="You have made some changes. Are you sure you want to discard them?"
        open={showUnsavedChangesDialog}
        yesLabel="Discard changes"
        noLabel="Save changes"
        onOk={() => {
          setShowUnsavedChangesDialog(false);
          handleClose();
        }}
        onClose={() => {
          setShowUnsavedChangesDialog(false);
          submit(state.publishStatus);
        }}
      />
    </div>
  );
}

export default BattlecardFormModal;
