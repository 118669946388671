import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MeetingIcon from "../call/MeetingIcon";

const PlaybackDetails = props => {
  if (!props.meetingInfo) return null;
  return (
    <div className="Player__group Player__info">
      <div className="Player__text">
        <div className="Player__iconContainer">
          <MeetingIcon callType={props.callType} />
        </div>
        <div className="Player__textContainer">
          <span className="Player__text-title">{props.meetingInfo.meetingTitle}</span>
          <span className="Player__text-time">
            {moment(props.callDate).format("MMMM DD, hh:mm A")}
          </span>
        </div>
        <span className="Player__text-opportunity">{props.opportunity}</span>
      </div>
    </div>
  );
};

PlaybackDetails.propTypes = {
  title: PropTypes.string,
  callDate: PropTypes.any,
  opportunity: PropTypes.string,
};
PlaybackDetails.defaultProps = {
  title: "",
  callDate: "",
  opportunity: "",
};

export default PlaybackDetails;
