import React, { useEffect, useState } from "react";
import { SearchV2Icon } from "app/designSystem/icons";
import { TextSM, TextBase } from "app/designSystem";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSearchOption } from "app/actions/call";
import { EmptySearchState } from "./notes/NoNotes";

const TopHighlights = () => {
    const dispatch = useDispatch();
    const [topHighlights, setTopHighlights] = useState([]);
    const categories = useSelector(state => state.call.details?.callDataDocument?.dashboardMetrics?.categories);

    useEffect(() => {
        if (categories) {
            const result = categories.sort((a, b) => b.occurences - a.occurences).map(e => ({ label: e.name, count: e.occurences }));
            setTopHighlights(result.slice(0, 5)); // Show only top 5 highlights
        }
    }, [categories])

    const handleHighlightClick = (label) => {
        dispatch(setSelectedSearchOption({ type: "Highlights", keyword: label }));
    }

    if (topHighlights.length === 0) {
        return <EmptySearchState />
    }

    return (
        <div>
            <hr />
            <TextBase className="my-4" textColor="text-gray-400">Top categories for this call</TextBase>
            <div className="flex flex-wrap">
                {
                    topHighlights.map((highlight, index) => {
                        return (
                            <Highlight key={index} label={highlight.label} count={highlight.count} onClickHandler={() => handleHighlightClick(highlight.label)} />
                        )
                    })
                }
            </div>
        </div>
    )
}

const Highlight = ({ label, count, onClickHandler }) => {
    return (
        <div className="flex items-center bg-purple-50 rounded mr-2 mb-2 p-2 h-8 cursor-pointer hover:bg-purple-100" onClick={onClickHandler}>
            <SearchV2Icon className="text-purple-300 h-3 w-3 mr-2" />
            <TextSM className="text-violet-800">{label}</TextSM>
            <span className="w-1 h-1 bg-purple-300 rounded-full mx-2"></span>
            <TextSM className="text-violet-500">{count} results</TextSM>
        </div>
    )
}

export default TopHighlights;