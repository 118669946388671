import React from 'react'
import {
  GetSortOrder
} from "app/components/dashboards/helpers";

export const withSortHistogramChart = HistogramChart => {
  class HistogramChartWithSort extends React.Component {
    constructor(props) {
      super(props)
      this.sort = this.sort.bind(this)
      this.state = {
        sortState: null,
        data: props.data
      }
    }
    UNSAFE_componentWillReceiveProps({
      data
    }) {
      if (!this.isSameData(data, this.state.data)) {
        this.setState({
          data
        })
      }
    }
    isSameData(data1, data2) {
      if (data1.length !== data2.length) return false;
      let map = {}
      for (var i = 0; i < data1.length; i++) {
        let obj1 = data1[i];
        map[obj1.name] = obj1;
      }
      for (var i = 0; i < data2.length; i++) {
        let obj2 = data2[i];
        let obj1 = map[obj2.name]
        if (!obj1) return false;
        if (obj1.total !== obj2.total ||
          obj1.count !== obj2.count ||
          obj1.percentage !== obj2.percentage
        )
          return false;
      }
      return true;
    }
    sort() {
      let {
        sortState,
        data
      } = this.state;
      sortState = sortState === "ASC" ? "DESC" : "ASC"
      data.sort(GetSortOrder(this.props.fxDataKey, sortState));
      this.setState({
        sortState,
        data
      });
    }
    render() {
      return <HistogramChart { ...this.props
      }
      data = {
        this.state.data
      }
      sortState = {
        this.state.sortState
      }
      sort = {
        this.sort
      }
      />
    }
  }
  return HistogramChartWithSort;
}