import React, { useEffect, useState } from 'react'
import SelectWithSearchNew from 'app/components/filter/SelectWithSearchNew';
import { getAllChannels } from 'app/utils/SlackUtils';
import useSnackbar from 'app/utils/hooks/useSnackbar';
import PropTypes from 'prop-types';

const SlackChannelSelector = ({
    selectedChannel,
    setSelectedChannel,
    popoverContainerRef
}) => {
    const dmObj = { value: "DM", label: "Direct Message" };
    const [slackChannels, setSlackChannels] = useState([dmObj]);
    const { setSnackbar } = useSnackbar();

    useEffect(() => {
        getAllSlackChannels();
    }, []);

    async function getAllSlackChannels() {
        const channelMap = await getAllChannels();
        if (channelMap)
            setSlackChannels([dmObj, ...channelMap]);
        else
            setSnackbar("Unable to fetch slack channels. Please try again later.", "ERROR");
    }

    return (
        <SelectWithSearchNew
            key="slackChannels"
            fieldName="slackChannels"
            data={slackChannels}
            value={selectedChannel}
            label="Select Channel"
            placeholder="Type channel name"
            handleChange={channels => setSelectedChannel(channels.value)}
            dontShowCloseIcon={true}
            isMulti={false}
            autoClose={true}
            popoverContainerRef={popoverContainerRef}
        />
    )
}


export default SlackChannelSelector;

SlackChannelSelector.propTypes = {
    selectedChannel: PropTypes.string.isRequired,
    setSelectedChannel: PropTypes.func.isRequired
}

