import React from "react";
import PropTypes from "prop-types";
import { withStyles, Tooltip } from "@material-ui/core";

const classes = {
  lightTooltip: {
    background: "white",
    color: "black",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)",
    borderRadius: "2px",
    fontSize: 12,
    margin: 0,
  },
  darkTooltip: {
    background: "black",
    color: "white",
    borderRadius: "2px",
    boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)",
    fontSize: 12,
    margin: 0,
  },
};

const CustomTooltip = props => {
  const { classes } = props;
  return (
    <Tooltip {...props} classes={{ tooltip: classes.lightTooltip }}>
      {props.children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.string,
  title: PropTypes.string,
};

CustomTooltip.defaultProps = {
  children: null,
  title: null,
  placement: "top",
};

export default withStyles(classes)(CustomTooltip);
