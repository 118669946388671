const PREFIX = '__local_storage_hook'

const getItem = key => {
    try {
        const value = window.localStorage.getItem(key)
        if (value) {
            return JSON.parse(value)
        }
    } catch (error) {
        console.error('error in useLocalStorageState: ', error)
    }

    return undefined
}

const setItem = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value))
}



export const getLocalValueInInt = (keyName) => {
    const hashKey = `${PREFIX}__hash__${keyName}`
    const count = getItem(hashKey);

    if (count) {
        return parseInt(count)
    }

    return 0;

}

export const setLocalValueInInt = (keyName, value) => {
    if (value && typeof value == "number") {
        const hashKey = `${PREFIX}__hash__${keyName}`
        setItem(hashKey, value.toString())
    }
}

export const incrementLocalValueInInt = (keyName, value) => {
    let count = getLocalValueInInt(keyName);
    count = count + value;
    setLocalValueInInt(keyName, count)

}

export const setLocalValue = (keyName, value) => {
    if (value) {
        const hashKey = `${PREFIX}__hash__${keyName}`
        setItem(hashKey, value)
    }
}

export const getLocalValue = (keyName, defaultValue) => {
    const hashKey = `${PREFIX}__hash__${keyName}`
    return getItem(hashKey) || defaultValue
}

export const removeLocalValue = (keyName) => {
    const hashKey = `${PREFIX}__hash__${keyName}`
    window.localStorage.removeItem(hashKey)
}

