import React, { useState, useEffect, useCallback } from 'react';
import TagsAutoComplete from './TagsAutoComplete'
import TagsContainer from './TagsContainer'
import PropTypes from 'prop-types'

export default function TagsInput({ selectedTags, tags, onChange }) {
    const [localSelectedTags, setLocalSelectedTags] = useState(selectedTags)

    useEffect(() => {
        onChange?.(localSelectedTags)
    }, [localSelectedTags])

    const onTagAdd = useCallback((tag) => {
        setLocalSelectedTags([...localSelectedTags, tag])
    }, [localSelectedTags])

    const onTagRemove = useCallback((tag) => {
        setLocalSelectedTags(localSelectedTags.filter(t => t !== tag.value))
    }, [localSelectedTags])

    const onBackSpace = useCallback(() => {
        if (localSelectedTags.length > 0) {
            setLocalSelectedTags(localSelectedTags.slice(0, localSelectedTags.length - 1))
        }
    }, [localSelectedTags])

    const transformToObject = (tags) => {
        return tags.map((tag) => ({ "label": tag, "value": tag }));
    }

    return (
        <div className="flex border-gray-300 rounded-md border p-2 overflow-y-auto flex-wrap">
            {(localSelectedTags && localSelectedTags.length > 0) &&
                <TagsContainer
                    tags={transformToObject(localSelectedTags)}
                    onTagRemove={onTagRemove}
                />}
            <TagsAutoComplete
                tags={tags}
                selectedTags={localSelectedTags}
                onEnter={onTagAdd}
                onClick={onTagAdd}
                onBackSpace={onBackSpace}
            />
        </div>
    )
}

TagsInput.propTypes = {
    selectedTags: PropTypes.array,
    tags: PropTypes.array,
    onChange: PropTypes.func
}

TagsInput.defaultProps = {
    selectedTags: [], // List of pre-selected tags to display on the input
    tags: [], // Total list of tags to display in the autocomplete
    onChange: () => { }
}