import React, { useState } from 'react'
import { Modal } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { sendRequest } from "app/utils/network";
import { refreshUserAction } from '../../actions/auth';
import { HexColorPicker, HexColorInput } from "react-colorful";
import OutsideClickHandler from 'react-outside-click-handler';
import { WingmanLogoWithName } from 'app/designSystem';
import { TextXL, Button, TextLarge, TextBase } from 'app/designSystem';
import { TextArea } from 'app/designSystem/form';
import { CloseIcon } from 'app/designSystem/icons';

const EditReminderEmailModal = ({ open, closeModal, refreshUserAction }) => {
    const auth = useSelector(store => store.auth);
    const { setSnackbar } = useSnackbar();
    const [saving, setSaving] = useState(false);
    const [color, setColor] = useState(auth?.stringsCustomer?.settings?.reminderEmailButtonColor ?? "#2680EB");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const defaultDisclaimer = "This meeting will be recorded for note-taking and training purposes. If you would like a copy of the recording or would prefer that this meeting not be recorded, please let me know!";
    const [disclaimer, setDisclaimer] = useState(auth?.stringsCustomer?.settings?.reminderEmailDisclaimer ?? defaultDisclaimer);

    async function saveDisclaimer() {
        setSaving(true);
        const params = new URLSearchParams();
        params.append("disclaimer", disclaimer);
        params.append("buttonColor", color);
        const res = await sendRequest("/admin/change_reminder_email", params);
        if (res.error) {
            setSnackbar("Error saving changes. Please retry.", "ERROR");
        } else {
            setSnackbar("Changes were successfully saved.", "SUCCESS");
            refreshUserAction();
            closeModal();
        }
        setSaving(false);
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={closeModal}
        >
            <div className="EditReminderEmailModal__container">
                <div className="flex flex-row m-3 px-3 pt-2" >
                    <TextXL>Edit reminder email</TextXL>
                    <button
                        className="ml-auto"
                        onClick={closeModal}>
                        <CloseIcon />
                    </button>
                </div>
                <hr />
                <div className="EditReminderEmailModal__body px-3">
                    <div className="EditReminderEmailModal__editSection">
                        <TextLarge className="my-1">Note:</TextLarge>
                        <TextArea
                            rows="5"
                            value={disclaimer}
                            onChange={e => setDisclaimer(e.target.value)}
                            placeholder="Add a disclaimer"
                        />
                        <TextLarge className="mb-1 mt-3">Button Color:</TextLarge>
                        <OutsideClickHandler onOutsideClick={() => setShowColorPicker(false)}>
                            <div onClick={() => setShowColorPicker(showColorPicker => !showColorPicker)} className="flex flex-row items-center border rounded p-2 cursor-pointer">
                                <span style={{ backgroundColor: color }} className="w-4 h-4 m-1"> </span>
                                <span>{color}</span>
                            </div>
                            {showColorPicker && <HexColorPicker color={color} onChange={setColor} />}
                            {showColorPicker && <HexColorInput color={color} onChange={setColor} />}
                        </OutsideClickHandler>
                    </div>
                    <div className="flex flex-col ml-5 w-[60%]">
                        <TextLarge className="my-1"> Subject:<i> Your meeting "Demo call with Prospect" is starting soon...</i></TextLarge>
                        <TextLarge className="my-1">Preview:</TextLarge>
                        <div className="flex flex-col p-4 bg-coolGray-50 overflow-y-auto h-[380px]">
                            <TextBase textColor="text-coolGray-600">Hi,</TextBase>
                            <br />
                            <TextBase textColor="text-coolGray-600">
                                Our meeting starts in a few minutes.
                            </TextBase>
                            <TextLarge className="my-2">
                                Demo Call with Prospect
                            </TextLarge>
                            <TextBase className="text-coolGray-600">Talk to you soon,</TextBase>
                            <TextBase className="text-coolGray-600">{auth?.data?.user?.displayName ?? "Your name"}</TextBase>
                            <Button className="w-1/2 h-10 mx-auto my-6" style={{ backgroundColor: color }} >Join meeting</Button>
                            <TextBase className="text-coolGray-600">Note: {disclaimer}</TextBase>
                            <br />
                            <div className="EditReminderEmailModal__emailPromo">
                                <TextBase className="text-coolGray-600 uppercase">This meeting is powered by</TextBase>
                                <a href="https://trywingman.com" className="my-2">
                                    <WingmanLogoWithName className="h-8" />
                                </a>
                                <TextBase className="my-3 text-coolGray-600 text-center">Wingman records, transcribes and analyzes sales meetings, so your salespeople can focus on the conversation and set your customers up for success.</TextBase>
                                <Button className="h-10" variant="secondary">Try Wingman!</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row ml-auto px-5 pt-1 pb-3">
                    <Button className="h-10" variant="secondary" loading={false} onClick={closeModal}>Cancel</Button>
                    <Button className="h-10 ml-3" loading={saving} onClick={saveDisclaimer}>Save Changes</Button>
                </div>
            </div>
        </Modal >
    );
}
EditReminderEmailModal.propTypes = {}
EditReminderEmailModal.defaultProps = {}
export default connect(props => props, { refreshUserAction })(EditReminderEmailModal);