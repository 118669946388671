import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #CCCCCC",
  },
  group: {
    color: "#2680EB",
    fontSize: "15px"
  },
  title: {
    color: "#2680EB",
    fontSize: "18px",
    fontWeight: "bold"
  },
  options: {
    margin: "5px",
    height: "30px"
  }
});

class RadioButtonsGroup extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    if (props.value != null) {
      this.state = {
        value: props.value.toString(),
      }
    } else {
      this.state = {
        value: ''
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value != this.state.value) {
      this.setState({
        value: nextProps.value === null ? null : nextProps.value.toString(),
      })
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value)
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <FormLabel>
            <span className={classes.title}>{this.props.title}</span>
          </FormLabel>
          <RadioGroup
            aria-label="duration"
            name="duration"
            className={classes.group}
            value={this.state.value}
            onChange={this.handleChange}
          >
            {this.props.options.map((option) => {
              return <FormControlLabel key={option.value} value={option.value} control={<Radio className={classes.options} />} label={option.label} />
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
};

RadioButtonsGroup.defaultProps = {
  title: "Checkbox",
  options: [{ "value": true, "label": "option" }]
};

export default withStyles(styles)(RadioButtonsGroup);
