import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const TabsContext = React.createContext(null);

export const useTabContext = () => React.useContext(TabsContext);

const TabsProvider = ({ tabId = 0, onTabChangeHandler, children }) => {
    const [activeTabId, setActiveTabId] = useState(tabId);

    const onTabClick = (id) => {
        setActiveTabId(id);
        onTabChangeHandler?.(id);
    }

    useEffect(() => {
        setActiveTabId(tabId);
    }, [tabId]);

    return (
        <TabsContext.Provider value={{
            activeTabId,
            onTabClick,
        }}>
            {children}
        </TabsContext.Provider>
    )
}

export default TabsProvider;

TabsProvider.propTypes = {
    tabId: PropTypes.number,
    children: PropTypes.node,
    onTabChangeHandler: PropTypes.func,
};

TabsProvider.defaultProps = {
    tabId: 0,
    children: null,
    onTabChangeHandler: null,
};