import Dashboard from "app/containers/Dashboard";
import AuthPage from "app/containers/AuthPage";
import Onboarding from "app/containers/Onboarding";
import AgentCalls from "app/containers/AgentCalls";
import VerifyEmail from "app/containers/VerifyEmail";
import Logout from "app/containers/Logout";
import ReviewAnnotation from "app/containers/ReviewAnnotation";
import ManualAnnotation from "app/containers/ManualAnnotation";
import Contact from "app/containers/Contact";
import GuestDashboard from "app/containers/GuestDashboard";
import Account from "app/containers/Account";
import Explainer from "app/containers/Explainer";
import EmailVerificationPending from "app/containers/EmailVerificationPending";
import ActivityDashboard from "app/containers/ActivityDashboard";
import TeamDashboard from "app/containers/TeamDashboard";
import CallsDashboard from "app/containers/CallsDashboard";
import Call from "app/containers/Call";
import CallTablePage from "app/containers/CallTablePage";
import Search from "app/containers/Search";
import NotificationsPage from "app/components/notifications/NotificationsPage";
import AccountView from "app/containers/AccountView";
import GameTapesLatest from "app/containers/GameTapesLatest";
import FolderView from "app/containers/Folder";
import GameTapeView from "app/containers/GameTapeView";
import DekstopLoginPage from "app/containers/DekstopLoginPage";

export {
  TeamDashboard,
  CallsDashboard,
  Call,
  CallTablePage,
  NotificationsPage,
  ActivityDashboard,
  AccountView,
  GameTapesLatest,
  FolderView,
  GameTapeView,
  Dashboard,
  AuthPage,
  Onboarding,
  VerifyEmail,
  Search,
  Logout,
  ReviewAnnotation,
  ManualAnnotation,
  Contact,
  AgentCalls,
  GuestDashboard,
  Account,
  Explainer,
  EmailVerificationPending,
  DekstopLoginPage,
};

export default {
  Dashboard,
  AuthPage,
  Onboarding,
  VerifyEmail,
  Search,
  Logout,
  ReviewAnnotation,
  ManualAnnotation,
  Contact,
  AgentCalls,
  GuestDashboard,
  Account,
  Explainer,
  EmailVerificationPending,
  ActivityDashboard,
  DealView: AccountView,
  DekstopLoginPage
};