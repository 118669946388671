import { Grid } from '@material-ui/core';
import React from 'react';
import Leaderboard from './Leaderboard';

const TeamDashboardTeamInsights = props => {
  const teamDashboardData = props.teamDashboardData;

  return (<Grid container spacing={3}>
    {teamDashboardData && teamDashboardData.leaderboards.map((leaderboard, i) =>
      <Grid key={i} item xs={3}>
        <Leaderboard data={leaderboard} />
      </Grid>)}
  </Grid>);
}

export default TeamDashboardTeamInsights;