import React from "react";
import classNames from "classnames";
import { _formatSecondsToPlayingTime } from 'app/utils/helpers';
import { TextSM } from 'app/designSystem'
import ThumbnailViewer from 'app/components/player/ThumbnailViewer';

const ProgressBar = ({ handleSeek, duration, currentTime = 0, showTimestamps = true, disableCursor = false }) => {
    const handleClick = (e) => {
        e.persist();
        const skippPointRate = (e.clientX - e.currentTarget.getBoundingClientRect().x) / e.currentTarget.clientWidth;
        handleSeek?.({ time: skippPointRate * duration, addToCurrent: false });
    }

    return (
        <div className="flex justify-between items-center">
            {showTimestamps && <TextSM className="w-10">{_formatSecondsToPlayingTime(currentTime)}</TextSM>}
            <div className={classNames("my-2 mx-4 w-full relative cursor-pointer", disableCursor && "cursor-not-allowed")} onClick={handleClick}>
                <ThumbnailViewer duration={duration} showPointerLine={!disableCursor} handleClick={handleClick} />
                <div className="relative cursor-pointer" onClick={handleClick}>
                    <div className="bg-gray-100 rounded-lg h-1.5 w-full" />
                    <div className="bg-wingman-brand rounded-lg h-1.5 w-full absolute top-0" style={{
                        width: `${Math.min((currentTime / duration) * 100, 100)}%`
                    }} />
                </div>
            </div>
            {showTimestamps && <TextSM className="w-10">{_formatSecondsToPlayingTime(duration)}</TextSM>}
        </div>
    );
}

export default ProgressBar;