import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";

function SPaper(props) {
  return <div className={`SPaper ${props.className}`}>{props.children}</div>;
}

SPaper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.number,
};

SPaper.defaultProps = {
  children: null,
  className: "",
  elevation: 2,
};

export default SPaper;
