import { SET_SCORING_SKILLS, SET_POPULAR_TEMPLATES } from "./actionTypes"
import { fetchScoringSkills, fetchPopularTemplates } from "app/utils/network/scorecards"



export function setSkills(data) {
    return {
        type: SET_SCORING_SKILLS,
        skills: data
    }
}

export function setPopularTemplates(data) {
    return {
        type: SET_POPULAR_TEMPLATES,
        popularTemplates: data
    }
}

export function getSkills() {
    return dispatch => {
        fetchScoringSkills().then((data) => {
            dispatch(setSkills(data || []))
        })
    };
}

export function getPopularTemplates() {
    return async (dispatch) => {
        const popularTemplates = await fetchPopularTemplates()
        dispatch(setPopularTemplates(popularTemplates) || [])

    }
}