import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GameTapeVideo, GameTapeInformationPanel, GameTapeExtraPanel } from "app/components/gameTapesLatest"
import { getGameTapes, getGameTapeInformation, getSignedUrls, updateLike } from "app/utils/network/gametapes";
import { HeaderSkeleton, GameTapeVideoSkeleton, GameTapeInformationPanelSkeleton } from "app/components/gameTapesLatest/skeleton";
import { Text2XL } from 'app/designSystem';
import { CloseIcon } from 'app/designSystem/icons';
import { MAIN, STACKED, SNIPPET } from "app/constants";
import { setScreenType } from "app/actions/screen";
import { setGameTapesGameTapesData, setGameTapesGameTapesChildrenData } from 'app/actions/gametapes'
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { debounce } from "lodash";
import metrics from 'app/utils/metrics';

const GameTapeView = (props) => {
    const dispatch = useDispatch();

    const { cachedGametapesChildrenData, cachedGametapesData } = useSelector(state => state.gametapes);

    const [loading, setLoading] = useState(true);
    const [gametapeId, setGameTapeId] = useState(null);
    const [videoSource, setVideoSource] = useState(null);
    const [folderId, setFolderId] = useState(null);

    const debouncedStatsEventHandler = useMemo(() => debounce(updateLike, 250), []);

    useEffect(() => {
        dispatch(setScreenType(STACKED));

        return () => {
            dispatch(setScreenType(MAIN));
        }
    }, []);

    useEffect(() => {
        setVideoSource(null);
        setGameTapeId(props.match.params.gametapeId);
    }, [props.match.params.gametapeId]);

    useEffect(() => {
        setLoading(true);
        if (gametapeId) {
            fetchGameTapeInformation(gametapeId);
        }
    }, [gametapeId]);

    const fetchGameTapeInformation = async (gametapeId) => {
        if (cachedGametapesData && cachedGametapesData[gametapeId]) {
            const gameTapeInformation = cachedGametapesData[gametapeId]

            if (gameTapeInformation?.videoUrl) { // If videoUrl is found in the cached gametape data
                setVideoSource(gameTapeInformation?.videoUrl);
            }

            fetchGameTapeVideoInformation(gameTapeInformation);
            setLoading(false);
        }
        else {
            const gameTapeInformation = await getGameTapeInformation({ gametapeId });

            fetchGameTapeVideoInformation(gameTapeInformation);
            dispatch(setGameTapesGameTapesData(gametapeId, gameTapeInformation));
        }
    }

    const handleVideoEnded = (gametapeId) => { // When video ends, go to the next gametape

        const folderId = cachedGametapesData[gametapeId]?.folder.id;
        const folderDocument = cachedGametapesChildrenData[folderId];

        if (folderDocument && folderDocument.length > 0) {
            const nextGameTapeIndex = folderDocument?.findIndex(gameTape => gameTape.id === gametapeId);

            if (nextGameTapeIndex !== -1 && nextGameTapeIndex < folderDocument?.length - 1) {
                const nextGameTapeId = folderDocument?.[nextGameTapeIndex + 1].id;

                if (nextGameTapeId !== gametapeId) {
                    props.history.push(`/gametapes/video/${folderDocument[nextGameTapeIndex + 1].id}`);
                }
            }
        }
    }

    const fetchGameTapeVideoInformation = (fetchedGameTapeData) => { // Fetch the video url and playlist information
        if (fetchedGameTapeData) {
            setFolderId(fetchedGameTapeData?.folder?.id);
            fetchVideoUrl(fetchedGameTapeData);
            fetchTapesPlaylistInfo(fetchedGameTapeData)
            setLoading(false);
        }
        else { //redirect page to not found
            props.history.push('/notFound');
        }
    }

    const fetchVideoUrl = async (currentGameTapeData) => {
        let data;

        if (currentGameTapeData?.gametape?.type === SNIPPET) {
            data = await getSignedUrls(currentGameTapeData?.gametape?.recordingId)
        }
        else
            data = await getSignedUrls(currentGameTapeData?.gametape?.callId)

        setVideoSource(data.videoUrl ?? data.audioUrl)

        dispatch(setGameTapesGameTapesData(gametapeId, {
            ...currentGameTapeData,
            videoUrl: data.videoUrl ?? data.audioUrl
        }));
    }

    const fetchTapesPlaylistInfo = async (fetchedGameTapeData) => {
        const folderId = fetchedGameTapeData.gametape.parentFolder;
        const gameTapeInformation = await getGameTapes({ folderId })
        dispatch(setGameTapesGameTapesChildrenData(folderId, gameTapeInformation));
    }

    const handleLike = (liked) => {
        debouncedStatsEventHandler({ gametapeId: gametapeId, liked });
        metrics.logEventHeap('likeTape', { type: liked ? 'Like' : 'Unlike' })
    }

    return (
        <div className="max-h-[95vh]">
            <div className="flex border-b items-center">
                {cachedGametapesData[gametapeId] ? <Link className="border-r py-5 px-6" to={`/gametapes/folder/${cachedGametapesData[gametapeId]?.gametape?.parentFolder}`}>
                    <CloseIcon />
                </Link> : <button className="border-r py-5 px-6"><CloseIcon /></button>}
                <div className="ml-5">
                    {(cachedGametapesData[gametapeId] && cachedGametapesData[gametapeId].folder) ? <Text2XL>{cachedGametapesData[gametapeId].gametape.name}</Text2XL> : <HeaderSkeleton />}
                </div>
            </div>
            <div className="flex overflow-hidden mx-8 my-6">
                <div className="w-3/4 overflow-auto">
                    {(videoSource && !loading && cachedGametapesData[gametapeId]?.gametape) ? <GameTapeVideo handleVideoEnded={handleVideoEnded} videoSource={videoSource} gametapeId={gametapeId} /> : <GameTapeVideoSkeleton />}
                </div>
                <div className="w-1/4 ml-8">
                    <div className="flex flex-col">
                        {(!loading && cachedGametapesData[gametapeId]) ? <GameTapeInformationPanel handleLike={handleLike} gametapeId={gametapeId} /> : <GameTapeInformationPanelSkeleton />}
                        {(folderId && cachedGametapesChildrenData[folderId] && cachedGametapesChildrenData[folderId].length > 0) && <GameTapeExtraPanel currentTapeId={cachedGametapesData[gametapeId]?.id} gametapesData={cachedGametapesChildrenData[folderId]} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(GameTapeView)

GameTapeView.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

GameTapeView.defaultProps = {
    match: {
        params: {
            gametapeId: null
        }
    },
    history: {
        push: () => { }
    }
}