import { Button, TextBase, TextLarge } from "app/designSystem";
import { Warning } from "app/designSystem/icons";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'

const CRMStatusPopup = ({ setShowCrmUnsyncedPopup }) => {
    return (
        <div className="flex flex-col w-80 px-5 py-6 shadow-lg rounded-lg absolute right-4 bottom-24 bg-white z-10 border">
            <div className="flex flex-row">
                <div>
                    <Warning className="h-6 w-6" />
                </div>
                <div className="ml-2 mb-4">
                    <TextLarge>CRM not synced</TextLarge>
                    <TextBase textColor="text-gray-500">Sync your CRM to get an accurate view from deal central</TextBase>
                </div>
            </div>
            <div className="flex flex-row justify-end">
                <Button
                    variant="tertiary"
                    className="mx-2"
                    onClick={() => setShowCrmUnsyncedPopup(false)}
                >
                    Remind Me Later</Button>
                <Link to="/settings#adminIntegrations">
                    <Button>Sync Now</Button>
                </Link>
            </div>
        </div>
    )
}

export default CRMStatusPopup;