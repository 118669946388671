import React from "react";
import PropTypes from "prop-types";

const Waveform = props => {
	return (
		<div className="Waveform">
			<div className="Waveform__progress">
				<div className="Waveform__progress-spectrum">
					{props.progressProps.waveformTurns.map(waveform => {
						if (waveform.type === "opportunity") {
							return (
								<div
									key={waveform.id}
									style={{
										width: `${waveform.width}%`,
										left: `${waveform.left}%`,
									}}
									className="Timeline__progress-spectrum-waveform Timeline__progress-spectrum-opportunity"
								/>
							);
						}
						return (
							<div
								key={waveform.id}
								style={{
									width: `${waveform.width}%`,
									left: `${waveform.left}%`,
								}}
								className="Timeline__progress-spectrum-waveform Timeline__progress-spectrum-agent"
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Waveform;
