import React, { useMemo, useState } from 'react';
import { Text2XL, TextXL, TextBase } from 'app/designSystem'
import { Input } from 'app/designSystem/form'
import { SearchIcon, EmptyFolderViewImage } from 'app/designSystem/icons'
import { SearchView } from 'app/components/gameTapesLatest'
import { FolderCards, CreateFolderBtn, getAccessOfAFolder } from 'app/components/gameTapesLatest/helper';

export default function RootFolderView({ folderOptions, foldersData, handleFolderActions }) {
    const [searchText, setSearchText] = useState('')

    const onSearchInputHandler = (e) => {
        const searchValue = e.target.value;
        setSearchText(searchValue);
    }

    return (
        <>
            <div className="rounded-lg p-4 mb-4 flex justify-between items-center">
                <Text2XL className="ml-4">
                    All Folders
                </Text2XL>
                <div className="flex justify-center items-center">
                    <Input placeholder="Search gametapes" inputClassname="h-10" startIcon={<SearchIcon className='text-medium-gray' />} onChange={onSearchInputHandler} />
                    <CreateFolderBtn />
                </div>
            </div>
            <div className="bg-white rounded-lg p-4">
                {
                    searchText.length > 0 ? <SearchView searchText={searchText} /> : <FolderWithSectionsView foldersData={foldersData} folderOptions={folderOptions} handleFolderActions={handleFolderActions} />
                }
            </div>
        </>
    )
}

const FolderWithSectionsView = ({ foldersData, folderOptions, handleFolderActions }) => {
    const seperateFoldersBasedOnAccess = (accumulator, folder) => {
        const access = getAccessOfAFolder(folder);
        const index = folderOptions.findIndex(option => option.label === access);
        accumulator[index].push(folder);
        return accumulator;
    }

    const foldersList = useMemo(() => foldersData?.reduce(seperateFoldersBasedOnAccess, [[], [], []])?.filter((folders) => folders.length > 0), [foldersData])
    /**
     * The above folderList will take a list of folders, and seperate them based on access
     * So you will get a multidimensional array of folders, 
     * like this: [[shared folders], [private folders], [public folders]]
     * and after that you can filter out the folders that are empty
     */

    if (foldersData?.length) {
        return (
            foldersList.map((folders, index) => {
                const sectionAccessLabel = getAccessOfAFolder(folders[0])

                return (
                    <div key={"FOLDERS_CONTAINER_" + index} className="flex flex-col">
                        <div className="flex flex-row justify-between px-4 py-2">
                            <TextXL className="text-gray-600">{sectionAccessLabel}</TextXL>
                        </div>
                        <div className="flex flex-row flex-wrap">
                            {
                                folders.map((folder) => <FolderCards key={folder.id} data={folder} handleFolderActions={handleFolderActions} />)
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    return (
        <div className="flex items-center justify-center w-full flex-col">
            <EmptyFolderViewImage className="my-5 h-full" />
            <TextXL>No folders found</TextXL>
            <TextBase textColor="text-gray-400">You can add a folder by clicking the Create Folder above.</TextBase>
        </div>
    )
}