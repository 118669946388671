import React from "react";
import { HeaderSales, HeaderStacked, HeaderGuest } from "app/components/layout";

import {
  LEAD_QUALIFICATION, STACKED, MAIN, GUEST,
} from "app/constants";

const excluded_headers_sites = [
  "/callembed",
  "/call/",
  "/account",
  "/gametapes/"
];

const Header = props => {
  if (props.screenType == STACKED && excluded_headers_sites.some(url => props.url.indexOf(url) !== -1)) {
    return null;
  }
  if (props.screenType == STACKED) {
    return <HeaderStacked />;
  }
  if (props.customerType == GUEST) {
    return <HeaderGuest />;
  }
  return <HeaderSales />;
};

export default Header;
