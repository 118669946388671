import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import ChatIcon from "app/styles/assets/images/icons/messageActions/chat.svg";
import ChatHoverIcon from "app/styles/assets/images/icons/messageActions/chatHover.svg";
import NoteIcon from "app/styles/assets/images/icons/messageActions/note.svg";
import NoteHoverIcon from "app/styles/assets/images/icons/messageActions/noteHover.svg";
import TimeShareIcon from "app/styles/assets/images/icons/messageActions/timeShare.svg";
import TimeShareHoverIcon from "app/styles/assets/images/icons/messageActions/timeShareHover.svg";
import UpDownIcon from "app/styles/assets/images/icons/callReview/upDown.svg";
import { _formatSecondsToTime, isElementInViewport } from "app/utils/helpers";
import CustomActions from "../common/CustomActions";
import { getShareableLink } from "../../utils/network";
import { getFutureEndDate } from "../dashboards/dateUtils";
import useSnackbar from "../../utils/hooks/useSnackbar";
import range from "lodash/range";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { PlayerContext } from "app/containers/Call";
import { debounce } from "lodash";
import { getShareableCallId } from "../../utils/network/callSharing";




const Message = props => {
  const { setSnackbar } = useSnackbar();
  const [sentences, setSentences] = useState([]);
  const [activeSentence, setActiveSentence] = useState();
  const [badges, setBadges] = useState([]);
  const [showAutoScrollTooltip, setShowAutoScrollTooltip] = useState(false);
  const sentencesRef = useRef({});
  const playerProps = useContext(PlayerContext);
  const messageRef = useRef();
  const prevActive = usePrevious(props.active);
  useEffect(() => {
    if (playerProps.playing && props.active) {
      highlightActiveSentenceDebounced();
    }
    if (prevActive && !props.active)
      setActiveSentence(null);
  }, [playerProps.playing, props.seek, props.active, sentences]);

  const highlightActiveSentenceDebounced = debounce(highlightActiveSentence, 300, {
    'leading': true,
    'trailing': false
  });

  function highlightActiveSentence() {
    if (playerProps.playing && props.active) {
      const seek = props.seek;
      if (sentences && sentences.length > 0) {
        sentences.find((s, i) => {
          const seekMs = seek * 1000;
          if (seekMs >= s.start && seekMs <= s.end) {
            if (activeSentence != i) {
              handleSetActiveSentence(i);
              return true;
            }
          }
        });
      }
    } else if (!props.active) {
      setActiveSentence(null);
    }
  }

  useEffect(() => {
    setBadges(props._addTranscriptLabels(props.turnId));
  }, [])

  function handleAutoScrollTooltipClick() {
    sentencesRef.current[activeSentence].scrollIntoView({ block: "center", behavior: "smooth" });
    props.setAutoScroll(true);
    handleAutoScrollTooltipVisiblity(false);
  }

  function handleAutoScrollTooltipVisiblity(bool) {
    if (bool) {
      if (showAutoScrollTooltip) return;
      if (props.lastDismissed === null || ((new Date() - props.lastDismissed) > 10000)) {
        setShowAutoScrollTooltip(bool);
      }
    } else {
      if (!showAutoScrollTooltip) return;
      props.setLastDismissed(new Date());
      setShowAutoScrollTooltip(bool);
    }
  }

  function handleSetActiveSentence(i) {
    setActiveSentence(i);
    const { isInViewPort, relativePosition, distanceFromEdge } = isElementInViewport(sentencesRef.current[i], "TranscriptContainer");
    if (isInViewPort) {
      if (relativePosition > 0.7 || distanceFromEdge < 40)
        sentencesRef.current[i].scrollIntoView({ block: "center", behavior: "smooth" });
      handleAutoScrollTooltipVisiblity(false);
      props.setAutoScroll(true);
      return;
    }
    if (props.autoScroll) {
      if (sentencesRef.current[i]) {
        sentencesRef.current[i].scrollIntoView({ block: "center", behavior: "smooth" });
        props.setAutoScroll(true);
      } else {
        console.log("here in no ref found to scroll to active sentence");
      }
    } else {
      handleAutoScrollTooltipVisiblity(true);
      setTimeout(() => handleAutoScrollTooltipVisiblity(false), 5000);
    }
  }

  const messageActions = [
    {
      msg: "Add a comment",
      onClick: handleExpandCommentArea,
      icon: ChatIcon,
      activeIcon: ChatHoverIcon
    },
    {
      msg: "Add a CRM note",
      onClick: handleSetActiveNote,
      icon: NoteIcon,
      activeIcon: NoteHoverIcon
    },
    {
      msg: "Share this moment",
      onClick: generateLink,
      icon: TimeShareIcon,
      activeIcon: TimeShareHoverIcon
    }
  ];

  async function generateLink(e) {
    e.stopPropagation();
    let response = await getShareableCallId(
      props.callId,
      getFutureEndDate("year"),
      null,
      null,
      "TURN_SHARE"
    );
    if (response?.error) setSnackbar("Couldn't generate the call link, please retry", "ERROR");
    else {
      let link = getShareableLink(response) + `#time=${props.time}`;
      copyStringToClipboard(link);
      setSnackbar("Link Copied", "SUCCESS");
    }
  }

  function handleAddRefTurn(ref) {
    messageRef.current = ref;
    props.addRefTurn(props.turnId, ref);
  }

  function handleSetActiveNote(e) {
    e.preventDefault();
    e.stopPropagation();
    props.setActiveNote({ startTime: props.time, comment: props.message });
  }

  function handleClick(sentence) {
    props.handleHighlightsClick(props.turnId, (sentence.start + 10) / 1000);
  }

  function handleExpandCommentArea(e) {
    e.preventDefault();
    props.expandCommentArea(true, props.time, e);
  }

  function copyStringToClipboard(str) {
    // Create new element
    const el = document.createElement("textarea");
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "");
    el.style = { position: "absolute", left: "-9999px" };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand("copy");
    // Remove temporary element
    document.body.removeChild(el);
  }

  useEffect(() => {
    setSentences(getSentences());
  }, [props.turn.words]);

  function getSentences() {
    let sentences = [];
    if (props.turn && props.turn.prediction && props.turn.words && props.turn.words.length > 0)
      sentences = props.turn.prediction.filter(pred => pred.type === "SEGMENT" && pred.metadata.predictionType === "sentence").map((sentence) => { return getSentence(sentence.startWordIdx, sentence.endWordIdx) });
    return sentences;
  }

  function getSentence(startWord, endWord) {
    if (props.turn && props.turn.words && props.turn.words.length > 0) {
      if (!startWord) startWord = 0;
      if (props.turn.words.length <= endWord) endWord = props.turn.words.length - 1;
      const content = joinWords(range(startWord, endWord + 1).map(i => props.turn.words[i].str));
      return { content, start: props.turn.words[startWord].startMs, end: props.turn.words[endWord].endMs };
    }
    return { content: props.turn.turnStr, start: props.turn.startTime, end: props.turn.endTime };
  }

  function joinWords(words) {
    const format = /^[a-zA-Z0-9](.*)/;
    let sentence = words.reduce(function (prevWord, nextWord) {
      let sep = prevWord.length && nextWord.length && format.test(nextWord) ? " " : "";
      return prevWord + sep + nextWord;
    }, '');
    return sentence;
  }

  function shouldAddFullStop(content) {
    const eosRegex = new RegExp("[\.\,\!\;\?]$");
    return !eosRegex.test(content);
  }

  function addSentenceRef(ref, i) {
    sentencesRef.current[i] = ref;
    props.addSentenceRef(i, props.turnId, ref);
  }

  function splitMessages(message, suffix = "") {
    return message.split("\n").map((m, i) => (
      <div onClick={() => props.handleHighlightsClick(props.turnId, props.time)} key={i}>
        {i > 0 && i < message.split("\n").length && <br />}
        <span className="Message__sentence">{m + suffix}</span>
      </div>
    ));
  }



  let uniqueBadges = badges;
  uniqueBadges = uniqueBadges.filter((x, i, a) => a.indexOf(x) === i);
  const Messages = splitMessages(props.message);

  return (
    <div
      style={props.style}
      className="Message"
      id={`Message_${props.turnId}`}
      key={`Message_${props.turnId}`}
      data-position={props.position}
      ref={handleAddRefTurn}
    >
      <span className="Message__time NotSelectable">
        {_formatSecondsToTime(props.time)}
      </span>
      <div className="Message__inner">
        <div className="Message__header NotSelectable">
          <span className="Message__title NotSelectable">{props.title}</span>
          {props.contact && props.contact.jobTitle && <span className="Message__jobTitle">{props.contact.jobTitle}</span>}
          <div className="Message__badgesContainer">
            {!isEmpty(uniqueBadges) &&
              uniqueBadges.slice(0, 3).map((badge, index) => (
                <div className="Message__badge" key={index} onClick={() => { props.handleTopicClick(badge) }}>
                  <span className="Message__badge-content hover:bg-violet-200 active:bg-violet-300">{badge}</span>
                </div>
              ))}
            {!isEmpty(uniqueBadges) && uniqueBadges.length > 3 &&
              <div title={uniqueBadges.slice(3).join(", ")} className="Message__badge" key={"extra"}>
                <span className="Message__badge-content">+{uniqueBadges.length - 3}</span>
              </div>
            }
          </div>
        </div>

        {sentences && sentences.length > 0 && (
          <div className="Message__content">
            <span>
              {sentences.map((sentence, i) =>
                <span onClick={() => handleClick(sentence)} key={i} ref={(ref) => addSentenceRef(ref, i)} className={`Message__sentence ${activeSentence === i ? "Message__active" : ""}`}>
                  {sentence.content.trim() === "." || sentence.content.trim() === "" ? "" : sentence.content + (shouldAddFullStop(sentence.content) ? ". " : " ")}
                </span>
              )}
            </span>
          </div>
        )}
        {!(sentences && sentences.length > 0) && (
          <div className="Message__contentOld">
            {Messages}
          </div>
        )}
      </div>
      {
        props.active && showAutoScrollTooltip &&
        <div className="Call__TranscriptContainer__autoScrollTooltip" onClick={handleAutoScrollTooltipClick}>
          <img src={UpDownIcon} className="Call__TranscriptContainer__autoScrollTooltipIcon" />
          <span className="Call__TranscriptContainer__autoScrollTooltipText">Scroll to current time</span>
          <img src={UpDownIcon} className="Call__TranscriptContainer__autoScrollTooltipIcon" />
        </div>
      }
    </div >
  );
};

export default Message;


Message.propTypes = {
  badges: PropTypes.arrayOf(PropTypes.string),
  messageRef: PropTypes.any,
  message: PropTypes.string,
  position: PropTypes.string,
  time: PropTypes.number,
  title: PropTypes.string,
};

Message.defaultProps = {
  messageRef: null,
  badges: [],
  message: "",
  position: "right",
  time: 0,
  title: "",
};
