import {
    sendRequest,
    sendCancellableRequest
} from "app/utils/network";

export async function searchCalls(query, from = 0, size = 25) {
    const params = new URLSearchParams();
    params.append("query", query);
    params.append("from", from);
    params.append("size", size);
    const response = await sendRequest("/search/for", params);
    return response;
}


export async function searchOpportunities(query, from = 0, size = 12) {
    const params = new URLSearchParams();
    params.append("query", query);
    params.append("from", from);
    params.append("size", size);
    const response = await sendRequest("/search/opportunity", params);
    return response;
}

export async function getSyncCountForCrm(crm) {
    const params = new URLSearchParams();
    params.append("crm", crm);
    const response = await sendRequest("/search/get_sync_count_for_crm", params);
    return response;
}

export async function getSyncCountForDialer(dialer) {
    const params = new URLSearchParams();
    params.append("dialer", dialer);
    const response = await sendRequest("/search/get_sync_count_for_dialer", params);
    return response;
}