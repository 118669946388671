import { Button } from "app/designSystem";
import React from "react";

const Footer = props => {
  return (
    <div className="battlecardForm__footerContainer">
      <div className="leftButton">
        <Button
          variant="secondary"
          loading={props.saving}
          onClick={() => props.onSubmit("DRAFT")}>
          Submit as Draft
        </Button>
      </div>
      <div className="rightButton">
        <Button
          variant="primary"
          loading={props.publishing}
          onClick={() => props.onSubmit("PUBLISHED")}
        >
          Publish Battle Card
        </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
