import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { TableCell, TableHead, TableRow, Table } from "@material-ui/core";
import { Card } from "app/components/common";
import TableHeader from "app/components/table/TableHeader";
import Skeleton from 'react-loading-skeleton';
import {
  AgentColumn,
  TimeColumn,
  TalkToListenRatioColumn,
  MeetingTitleColumn,
  DurationColumn,
  TranscriptColumn,
  ReasonColumn
} from "app/components/callTable";
import { TableColumn } from "app/components/table/TableColumn";
import { EmptyTableRow } from "app/components/table/EmptyTableRow";
import LongestMonologueColumn from "../callTable/LongestMonologueColumn";
import CommentIcon from "app/styles/assets/images/icons/tableComment.svg";
import LockedIcon from "app/styles/assets/images/icons/comments/locked.svg"
import { useSelector } from "react-redux";
import { NOT_CONNECTED_DISPOSITIONS } from "../../constants";
import CommentCount from "../call/comments/CommentCount";
import classNames from "classnames";

const CallTable = props => {

  const [agentMaps, setAgentMaps] = useState(getMap(props.userNames));
  const auth = useSelector(store => store.auth);
  const callLogFilter = useSelector(store => store.callLog.filterParams);
  const screenName = useSelector(store => store.screen.screenName);
  const fieldName = useSelector(store => store.screen.fieldName);
  const [hashSuffixQueryParams, setHashSuffixQueryParams] = useState(getHash());

  useEffect(() => {
    setHashSuffixQueryParams(getHash());
  }, [callLogFilter, screenName, fieldName])

  useEffect(() => {
    setAgentMaps(getMap(props.userNames));
  }, [props.userNames])

  function getMap(results) {
    const map = {};
    if (results != null) {
      for (let i = 0; i < results.length; i++) {
        map[results[i].value] = results[i].label;
      }
    }
    return map;
  }

  function openCallScreen(callId, hash) {
    props.history.push({
      pathname: `/call/${callId}${hash}`
    });
  }

  function showReasonColumn(call) {
    if ((call.callStatus === 'BOTJOIN_DISABLED' && call.botNotJoinReason && call.botNotJoinReason.length > 1) ||
      (["NOBODY_JOINED_CALL", "BOTJOIN_DENIED", "IGNORED_BY_USER"].includes(call.callStatus)))
      return true;
    return false;
  }

  function isRead(call) {
    if (call.readBy && call.readBy.includes(auth.userId)) {
      return true;
    }
    return false;
  }

  function getSearchType(query) {
    let type = "Transcript";
    if (query.startsWith("\"") && query.endsWith("\"")) {
      type = "TranscriptExact";
    }
    return type;
  }

  function getHash() {
    if (screenName && screenName.trim() === "Team Calls") {
      if (callLogFilter.query && callLogFilter.query.trim() !== "") {
        return "&query=" + encodeURIComponent(callLogFilter.query) + "&type=" + getSearchType(callLogFilter.query);
      }
      if (callLogFilter?.containsCategories?.length > 0) {
        return "&query=" + encodeURIComponent(callLogFilter.containsCategories.map((containsCategory) => containsCategory).join(",")) + "&type=Highlights";
      }
      if (callLogFilter?.competitorMentioned?.length === 1) {
        return "&query=" + encodeURIComponent(callLogFilter.competitorMentioned[0]) + "&type=TranscriptExact";
      }
    }
    if (props.query && fieldName === "turns.str") {
      return "&query=" + encodeURIComponent(props.query) + "&type=" + getSearchType(props.query);
    }

    return "";
  }

  const { filterParams, sort, classes, sortAvailable, showRep } = props;
  const sortData = {};
  sortData[filterParams.sortField] = filterParams.sortDirection;
  const disableSort = !sortAvailable;
  const headerProps = {
    sort,
    sortData,
    classes,
    disableSort
  };

  return (
    <div className="px-8">
      <div className="rounded-2xl  bg-white border-solid border border-coolGray-200 overflow-hidden">
        {props.tableTopbar}
        <div className="CallTable__table__container">
          <Table stickyHeader={props.stickyHeader} style={{ borderCollapse: "collapse" }}>
            <TableHead className="bg-coolGray-50">
              <TableRow>
                <TableCell className="!pl-8 !py-4">
                  <TableHeader className="whitespace-nowrap"
                    label="Date and Time"
                    field="time"
                    {...headerProps}
                  />
                </TableCell>
                {showRep &&
                  <TableCell className="!px-0 !py-4">
                    <TableHeader
                      label="Rep"
                      field="users.keyword"
                      {...headerProps}
                      disableSort
                    />
                  </TableCell>
                }
                <TableCell colSpan={3} className="!px-0 !py-4">
                  <TableHeader
                    label="Call Title"
                    field="title.keyword"
                    {...headerProps}
                    disableSort
                  />
                </TableCell>
                <TableCell colSpan={1} className="!px-0 !py-4">
                </TableCell>
                <TableCell className="!pl-0 !px-2 !py-4">
                  <TableHeader
                    className="whitespace-nowrap"
                    label="Call Duration"
                    field="duration"
                    {...headerProps}
                  />
                </TableCell>
                {!props.showLongestMonologue && <TableCell className="!px-4 !py-4">
                  <TableHeader
                    className="whitespace-nowrap"
                    label="Talk/Listen Ratio"
                    field="talkListenRatio"
                    {...headerProps}
                  />
                </TableCell>}
                {props.showLongestMonologue &&
                  <TableCell className="!px-0 !py-4">
                    <TableHeader
                      label="Longest Monologue Duration"
                      field="longestMonologueDuration"
                      {...headerProps}
                    />
                  </TableCell>
                }
              </TableRow>
            </TableHead>
            <tbody>
              {props.loading && <LoadingCallTable showRep={showRep} />}
              {!props.loading && props.calls?.map((call, i) => {
                let hash = "#";
                if (call.firstTurnId) {
                  hash = `#${call.firstTurnId}`;
                }
                hash = hash + hashSuffixQueryParams;
                const border = call.firstTurn ? "1px solid transparent" : "";
                const opacity = !call.duration ? 0.9 : 1;

                let callRowState = "UNREAD"; //add bg to distinguish read/unread rows
                if (isRead(call)) {
                  callRowState = "READ";
                }
                if (NOT_CONNECTED_DISPOSITIONS.includes(call.callDisposition)) {
                  callRowState = "NOT_CONNECTED";
                }
                return (
                  <React.Fragment key={call.callId}>
                    <TableRow
                      hover={false}
                      className={
                        classNames(
                          { "CallTable__searchRow": call.firstTurn }, "hover:bg-[#F8FBFF]")
                      }

                    >
                      <TableColumn path={`/call/${call.callId}${hash}`} span={1} contentStyles="!px-8">
                        <TimeColumn call={call} callRowState={callRowState} />
                      </TableColumn>
                      {showRep && <TableColumn path={`/call/${call.callId}${hash}`} span={1}>
                        <AgentColumn
                          call={call}
                          agentMaps={agentMaps}
                          size={40}
                        />
                      </TableColumn>
                      }
                      <TableColumn path={`/call/${call.callId}${hash}`} span={3}>
                        <MeetingTitleColumn showAccountDetails={props.showAccountDetails} call={call} callRowState={callRowState} />
                      </TableColumn>
                      <TableColumn path={`/call/${call.callId}${hash}`} className="!pr-1" >
                        <div className="flex items-center flex-shrink-0 w-max">
                          {call.access === "PRIVATE" &&
                            <img src={LockedIcon} className="w-7 h-7 mr-1.5" />
                          }
                          {call.numComments && call.numComments > 0 &&
                            <CommentCount count={call.numComments} />
                          }
                        </div>
                      </TableColumn>

                      {showReasonColumn(call) && <TableColumn path={`/call/${call.callId}${hash}`} key="reason" span={2}>
                        <ReasonColumn call={call} past />
                      </TableColumn>}
                      {!showReasonColumn(call) &&
                        <TableColumn path={`/call/${call.callId}${hash}`} key="duration" span={1}>
                          <DurationColumn
                            mediaRecorderStatus={call.mediaRecorderStatus}
                            callStatus={call.callStatus}
                            duration={call.duration}
                          />
                        </TableColumn>}
                      {!showReasonColumn(call) && props.showLongestMonologue &&
                        <TableColumn path={`/call/${call.callId}${hash}`} key="monologue" span={1}>
                          <LongestMonologueColumn
                            duration={call.longestMonologueDuration}
                          />
                        </TableColumn>}
                      {!showReasonColumn(call) && !props.showLongestMonologue &&
                        <TableColumn path={`/call/${call.callId}${hash}`} key="tlratio" span={1} contentStyles="!pl-4">
                          <TalkToListenRatioColumn
                            talkListenRatio={call.talkListenRatio}
                          />
                        </TableColumn>}
                    </TableRow>
                    {call.firstTurn && (
                      <TableRow >
                        <TableColumn
                          className="!border-t-0 CallTable__transcriptColumn !border-coolGray-300"
                          path={`/call/${call.callId}${hash}`}
                          span={8}
                          contentStyles="!px-8 !py-2"
                        >
                          <TranscriptColumn call={call} />
                        </TableColumn>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
              {props.calls?.length === 0 && !props.loading && <EmptyTableRow />}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

const LoadingCallTable = props => {
  return (
    <>
      {[...Array(5).keys()].map(i => (
        <TableRow className="CallTable__simpleRow appearDiv" key={"call-table-skeleton-row-" + i}>
          <TableCell colSpan={1}><Skeleton width={130} height={20} /></TableCell>
          {props.showRep &&
            <TableCell colSpan={1}>
              <div className="row-tcentered">
                <Skeleton circle width={30} height={30} />
                <div style={{ margin: "0px 2px" }} />
                <Skeleton circle width={30} height={30} />
              </div>
            </TableCell>
          }
          <TableCell colSpan={3}><Skeleton width={300} height={20} /></TableCell>
          <TableCell colSpan={2}>
            <div className="row-tcentered" style={{ marginLeft: "20px" }}>
              <Skeleton width={100} height={20} />
            </div>
          </TableCell>
          <TableCell colSpan={1}><Skeleton width={100} height={20} /></TableCell>
        </TableRow>
      ))}
    </>
  );
}

CallTable.propTypes = {
  calls: PropTypes.array.isRequired,
  sort: PropTypes.func,
  filterParams: PropTypes.object,
  contacts: PropTypes.array,
  userNames: PropTypes.array,
  classes: PropTypes.object,
  sortAvailable: PropTypes.bool,
  showRep: PropTypes.bool,
  showLongestMonologue: PropTypes.bool,
};

CallTable.defaultProps = {
  sort: () => { },
  filterParams: {},
  contacts: [],
  userNames: [],
  classes: {},
  sortAvailable: false,
  showRep: true,
  showLongestMonologue: false,
  showAccountDetails: false
};

export default withRouter(CallTable);
