import React from "react";
import { Button, TextXL, WingmanLogoWithName } from "app/designSystem";
import PropTypes from "prop-types";

export default function LoginMobile({ email, authKey }) {
    const loginUrl = `trywingman://login?authKey=${authKey}&email=${email}#Intent;scheme=trywingman;package=com.trywingman.mobile;end`;

    return (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center w-screen h-screen bg-white z-50 overflow-hidden">
            <div className="absolute top-4 left-4 z-10 sm:top-8 sm:-translate-x-1/2 sm:left-1/2">
                <WingmanLogoWithName className="w-44 sm:w-full" />
            </div>
            <div className="text-center mb-2 sm:scale-[2] sm:mb-16">
                <TextXL>Login as</TextXL>
                <TextXL className="!font-bold">{email}</TextXL>
            </div>
            <div className="sm:sm:scale-[2]">
                <a href={loginUrl}>
                    <Button className="w-96 !rounded-lg">Open the Wingman app</Button>
                </a>
            </div>
        </div>
    );
}

LoginMobile.propTypes = {
    email: PropTypes.string.isRequired,
    authKey: PropTypes.string.isRequired,
};

LoginMobile.defaultProps = {
    email: "",
    authKey: "",
};