import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TextBase } from 'app/designSystem'


function ScoringScale({ value, type, setValue, className, disabled = false }) {
    return (
        <div className={classNames(className, "flex justify-between")}>

            <div className="rounded inline-block overflow-hidden border-coolGray-200 border-l border-t border-b">
                {
                    type == "BINARY"
                    && (
                        <>
                            <button className={classNames("px-2 py-1 border-coolGray-200 border-r", { "bg-wingman-brand": value == 1 }, { "cursor-not-allowed": disabled })} onClick={() => { setValue(1) }} disabled={disabled}>
                                <TextBase textColor={value == 1 ? "text-white" : "text-coolGray-500"} >
                                    Yes
                                </TextBase>
                            </button>
                            <button className={classNames("px-2 py-1 border-coolGray-200 border-r", { "bg-wingman-brand": value == 0 }, { "cursor-not-allowed": disabled })} onClick={() => { setValue(0) }} disabled={disabled}>
                                <TextBase textColor={value == 0 ? "text-white" : "text-coolGray-500"}>
                                    No
                                </TextBase>
                            </button>
                        </>
                    )

                }
                {
                    type == "SCALED"
                    && (
                        <>
                            {
                                [1, 2, 3, 4, 5].map(
                                    scaledValue => {
                                        return <button className={classNames("px-2 py-1 border-coolGray-200 border-r", { "bg-wingman-brand": value == scaledValue }, { "cursor-not-allowed": disabled })} onClick={() => { setValue(scaledValue) }} key={scaledValue + "-score-value"} disabled={disabled}>
                                            <TextBase textColor={value == scaledValue ? 'text-white' : "text-coolGray-500"}>
                                                {scaledValue}
                                            </TextBase>
                                        </button>
                                    }
                                )
                            }

                            <button className={classNames("px-2 py-1 border-coolGray-200 border-r", { "bg-wingman-brand": (value == "NA" || value == - 99) }, { "cursor-not-allowed": disabled })} disabled={disabled} onClick={() => { setValue(-99) }}>
                                <TextBase textColor={(value == "NA" || value == - 99) ? 'text-white' : "text-coolGray-500"}>
                                    N/A
                                </TextBase>
                            </button>
                        </>

                    )

                }
            </div>


        </div>
    )
}

ScoringScale.propTypes = {

}

export default ScoringScale

