import React from "react";
import { PropTypes } from "prop-types";
import { _roundUp } from "app/utils/helpers";
import { Tabs, Tab } from "@material-ui/core";
import HistogramChart from "app/components/dashboards/histogram/HistogramChart";

/**
 * return color to draws charts
 * @param {string} color the color, e.g: purple, green, blue
 */
const getChartColor = color => {
  switch (color) {
    case "purple":
      return "#4A1058";
    case "blue":
      return "#00BECD";
    case "green":
      return "#54C282";
    default:
      return color;
  }
};

const tabs = ["day", "week", "month", "quarter", "year", "custom"];

class CustomBarChartWithTabs extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.state = {
      activeTab: tabs.indexOf(props.activeTab),
    };
  }
  handleTabChange(e, value) {
    this.setState({
      activeTab: value,
    });
    this.props.onTabChange(getDuration(value));
  }
  render() {
    return (
      <div className="Chart">
        <Tabs
          fullWidth
          onChange={this.handleTabChange}
          value={this.state.activeTab}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="1 day" />
          <Tab label="7 days" />
          <Tab label="1 month" />
          <Tab label="1 quarter" />
          <Tab label="1 year" />
          <Tab disabled label="Custom" />
        </Tabs>
        <HistogramChart
          color={getChartColor(this.props.color)}
          avg={10}
          changeRate={_roundUp(20.3433, 100)}
          xDataKey="name"
          fxDataKey="percentage"
          data={this.props.data}
          unit="%"
          leftHeader="Rep Name"
          rightHeader="Calls %"
        />
      </div>
    );
  }
}

const getDuration = index => {
  return ["day", "week", "month", "quarter", "year", "custom"][index];
};

CustomBarChartWithTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  color: PropTypes.string,
  avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeRate: PropTypes.number,
  verticalGrid: PropTypes.bool,
  horizontalGrid: PropTypes.bool,
  yTickCount: PropTypes.number,
  yDomain: PropTypes.array,
  xDataKey: PropTypes.string,
  fxDataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAllowDecimals: PropTypes.bool,
  avgLine: PropTypes.object,
  stroke: PropTypes.bool,
  yAxisLine: PropTypes.bool,
  height: PropTypes.number,
  yTickFormatter: PropTypes.func,
  xPadding: PropTypes.object,
  dotRadius: PropTypes.number,
  children: PropTypes.node,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
};

CustomBarChartWithTabs.defaultProps = {
  activeTab: "month",
  color: "purple",
  avg: 0,
  changeRate: 0,
  verticalGrid: false,
  horizontalGrid: false,
  yTickCount: 3,
  xDataKey: "x",
  fxDataKey: "fx",
  yDomain: [0, "auto"],
  yAllowDecimals: false,
  avgLine: null,
  stroke: true,
  yAxisLine: true,
  height: null,
  yTickFormatter: null,
  xPadding: {},
  dotRadius: 7,
  children: null,
  xLabel: "",
  yLabel: "",
};

export default CustomBarChartWithTabs;
