import React, { useState } from "react";
import { useHoverListener } from "app/utils/hooks/useHoverListener";
import { _formatSecondsToPlayingTime } from "../../utils/helpers";

export const Battlecard = ({
  battlecard, leftMargin, onSkipPress, battlecardTime,
}) => {
  const [showBattlecardDetails, setShowBattlecardDetails] = useState(false);
  useHoverListener(`battlecard__${battlecard.battlecardId}__${battlecardTime}`, () => setShowBattlecardDetails(true), () => setShowBattlecardDetails(false));
  return (
    <div onMouseDown={() => onSkipPress(battlecardTime - 3 < 0 ? 0 : battlecardTime - 3)} className="Timeline__progress__CardsShown__cardIconDiv" style={{ left: `${leftMargin}%` }}>
      {showBattlecardDetails
      && <div className="cardDetails">
        <b>Word: </b>
        {battlecard.triggerWord}
        <br />
        <b>Time shown at: </b>
        {`${_formatSecondsToPlayingTime(battlecardTime)} s`}
        <br />
        <b>Time shown for: </b>
        {`${(battlecard.timeDismissed - battlecard.timeShown) / 1000} s`}
         </div>
        }
      <span className="battlecardIcon" id={`battlecard__${battlecard.battlecardId}__${battlecardTime}`}>B</span>
    </div>);
};
