import React from 'react'
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import CompletingIntegration from '../components/onboarding/CompletingIntegration';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { crmOAuthCallback } from "app/utils/network";
import { useEffect } from 'react';
import { gup } from '../components/onboarding/helpers';
import { refreshUserAction } from "app/actions/auth";
import useSnackbar from '../utils/hooks/useSnackbar';
import { Button } from '../components/form';
import WarningIcon from "app/styles/assets/images/icons/warning.svg";
import tickAnimationData from "app/styles/assets/animations/tickAnimation.json";
import Lottie from "react-lottie";

const PipedriveInstallation = props => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const user = useSelector(store => store.auth.data.user);
    const { setSnackbar, hideSnackbar } = useSnackbar();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: tickAnimationData,
    };

    useEffect(() => {
        if (
            window.location.search.indexOf("code=") !== -1
            || window.location.hash.indexOf("code=") !== -1
        ) {
            if (window.document.URL.indexOf("code") !== -1) {
                const redirectUrl = window.document.URL;
                var code = gup(redirectUrl, "code");
                console.log("syncing pipedrive for code", code);
                completeSync(code);
            }
        }
    }, []);

    async function completeSync(code) {
        const userId = user.userId || {};
        const response = await crmOAuthCallback(code, 'PIPEDRIVE');
        if (response === "NOK") {
            setError(true);
            setLoading(false);
            setSnackbar("There was an error completing your Pipedrive integration", "ERROR");
            return;
        }
        setLoading(false);
        setSnackbar("Your pipedrive intergration is done. Redirecting you to Wingman.", "SUCCESS");
        props.refreshUserAction();
        setSuccess(true);
        setTimeout(() => redirectToIntegrations(), 2000);
    }

    function redirectToIntegrations() {
        window.open("/settings#integrations", "_self");
    }

    if (
        window.location.search.indexOf("code") !== -1
        || window.location.hash.indexOf("code") !== -1
    ) {
        return (
            <div style={{ flexDirection: "column", display: "flex", margin: "auto", alignItems: "center", justifyContent: "center" }}>
                <CompletingIntegration loading={loading} />
                {error && <img style={{ height: "100px", width: "100px" }} src={WarningIcon} />}
                {error && <span className="Typography__small">There was an error completing your Pipedrive integration. Try again or reach out to support@trywingman.com for help.</span>}
                {error && <Button theme="dark" onClick={redirectToIntegrations}>Go to Integrations</Button>}
                {success && <Lottie options={defaultOptions} height={75} width={75} />}
                {success && <span className="Typography__small">Your Pipedrive integration is done. Please wait for a few hours for the sync to complete. Redirecting you to Wingman...</span>}
            </div>
        );
    }
    return (
        <Redirect to="/settings#integrations" />
    );
}
PipedriveInstallation.propTypes = {}
PipedriveInstallation.defaultProps = {}

export default connect(
    store => store,
    { refreshUserAction },
)(PipedriveInstallation);