import React from "react";
import PropTypes from "prop-types";
import PrivateCallGraphic from "app/styles/assets/images/icons/callReview/privateCall.svg";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { Button } from "../form";
import { useHistory } from "react-router-dom";
const PrivateCallAccessDenied = ({ details }) => {
  const { getUserName } = useDataHelper();
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <div className="PrivateCall">
      <img src={PrivateCallGraphic} className="PrivateCall__Graphic" />
      <span className="PrivateCall__header">The call was marked as private by {getUserName(details.privacySetter)}</span>
      <span className="PrivateCall__subheader">
        Please contact <b>{getUserName(details.privacySetter)}</b> to get access to this call.
      </span>
      <Button theme="dark" onClick={goBack}>
        Go back
      </Button>
    </div>
  );
};
PrivateCallAccessDenied.propTypes = {};
PrivateCallAccessDenied.defaultProps = {};
export default PrivateCallAccessDenied;
