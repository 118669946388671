import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withStyles } from "@material-ui/core";
import { Filter } from "../filter";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "380px",
    width: "300px",
  },
  dateContainer: {
    marginTop: "20px",
    alignItems: "center",
    justifyContent: "center",
  },
  centered: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  label: {
    display: "flex",
    alignItems: "center",
    margin: "5px",
  },
};

const DashboardFilter = (props) => {

  const { userNames, deletedUserNames, managers, categories, competitors, status, types, scoringTemplates } = props.screen.metaData;
  return (
    <Filter
      type={props.type}
      onApplyFilter={props.onSubmit}
      filterParams={props.filterParams}
      userNames={userNames}
      deletedUserNames={deletedUserNames}
      managers={managers}
      categories={categories}
      competitors={competitors}
      removeFilterValue={props.removeFilterValue}
      status={status}
      types={types}
      scoringTemplates={scoringTemplates}
      defaultFilters={props.defaultFilters}
      prefillFilter={props.prefillFilter}

    />
  );
}

const mapStateToProps = store => {
  return { ...store };
};

DashboardFilter.propTypes = {
  logout: PropTypes.func,
};

DashboardFilter.defaultProps = {};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(DashboardFilter));
