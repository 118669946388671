import { useEffect, useState } from "react"
import { TextSM } from "app/designSystem";
import {
    lowerCaseRegexPattern,
    upperCaseRegexPattern,
    digitRegexPattern,
    isWordMatchingThePattern,
} from "../../utils/helpers";
import { ReactComponent as CheckGreen } from 'app/styles/assets/images/icons/general/CheckGreen.svg'
import { ReactComponent as CancelRed } from 'app/styles/assets/images/icons/general/CancelRed.svg'

const PasswordValidator = ({ password, setIsValidPassword }) => {
    const [lengthCheck, setLengthCheck] = useState(false)
    const [lowecaseCheck, setLowecaseCheck] = useState(false)
    const [uppercaseCheck, setUppercaseCheck] = useState(false)
    const [numberCheck, setNumberCheck] = useState(false)
    const [showPasswordValidator, setPasswordValidator] = useState(false)

    useEffect(() => {
        validatePassword()
        if (password.length > 0) setPasswordValidator(true)
    }, [password])

    useEffect(() => {
        if (lengthCheck && lowecaseCheck && uppercaseCheck && numberCheck)
            setIsValidPassword(true)
        else
            setIsValidPassword(false)
    }, [lengthCheck, lowecaseCheck, uppercaseCheck, numberCheck])

    function validatePassword() {
        if (password !== null && password != undefined) {
            (password.length >= 8 && password.length <= 20) ? setLengthCheck(true) : setLengthCheck(false);
            isWordMatchingThePattern(password, lowerCaseRegexPattern) ? setLowecaseCheck(true) : setLowecaseCheck(false);
            isWordMatchingThePattern(password, upperCaseRegexPattern) ? setUppercaseCheck(true) : setUppercaseCheck(false);
            isWordMatchingThePattern(password, digitRegexPattern) ? setNumberCheck(true) : setNumberCheck(false);
        }
    }

    return (
        <div className={"mt-1 mb-1 transition-all duration-300 overflow-auto " + (showPasswordValidator ? "h-8 " : "h-0 ")}>
            <div className="flex items-center">
                {lengthCheck ? <CheckGreen /> : <CancelRed />}
                <TextSM className="ml-1.5 mr-3">8-20 characters</TextSM>
                {lowecaseCheck ? <CheckGreen /> : <CancelRed />}
                <TextSM className="ml-1.5">Min 1 lowercase letter</TextSM>
            </div>
            <div className="flex items-center">
                {uppercaseCheck ? <CheckGreen /> : <CancelRed />}
                <TextSM className="ml-1.5 mr-3">Min 1 uppercase letter</TextSM>
                {numberCheck ? <CheckGreen /> : <CancelRed className="text-yellow-500 !text-base" />}
                <TextSM className="ml-1.5">Min 1 number</TextSM>
            </div>
        </div>
    )
}

export default PasswordValidator