import React from "react";
import ReactTooltip from "react-tooltip";
import { _formatSecondsToMinutes, _formatDateToCallCardDateFormat, _formatTalkListenRatio } from "../../../utils/helpers";
import ProfileImage from "../../common/ProfileImage";
import { ENGAGING_QUESTION_EXPLANATION, PRIMARY_REP_EXPLANATION, TLRATIO_EXPLANATION } from "../../../constants";
import useDataHelper from "../../../utils/hooks/useDataHelper";
import { CallDetailsMetric } from "app/components/call/CrmContext";
import InfoIcon from "app/styles/assets/images/icons/callReview/info.svg";


const colorByCallType = {
    ZOOM: "Blue",
    GOOGLE_MEET: "Green",
    HUBSPOT: "Orange",
    HUBSPOT_LIVE: "Orange"
}

export const CallPreviewRepsSection = ({ dominantRep, engagingQuestions, talkListenRatio }) => {
    const { getUserName } = useDataHelper();

    return (

        <div className="CallPreview__summaryContainer__sectionList">
            <div className="CallPreview__summaryContainer__sectionRow">
                <div className="CallPreview__summaryContainer__sectionStat">
                    <div className="ProfileImageAndName">
                        <ProfileImage className="ProfileImageAndName__icon" disableTooltip userId={dominantRep} size={25} />
                        <div className="CallPreview__summaryContainer__repDetails">
                            <div className="row-tcentered">
                                <span className="CallPreview__summaryContainer__repLabel">Primary Rep</span>
                                <img data-tip data-for={"PrimaryRep_metric"} src={InfoIcon} className="CrmContextContainer__callDetails__metricInfoIcon" />
                            </div>
                            <ReactTooltip place="right" id={"PrimaryRep_metric"} type="light" effect="solid" className="WhiteTooltip">
                                <span className="WhiteTooltip__mainCopy">Who is the primary rep?</span>
                                <span className="WhiteTooltip__subCopy">{PRIMARY_REP_EXPLANATION}</span>
                            </ReactTooltip>
                            <span className="CallPreview__summaryContainer__repName">{getUserName(dominantRep)}</span>
                        </div>
                    </div>
                </div>
                <CallDetailsMetric label="Talk/Listen Ratio" value={_formatTalkListenRatio(talkListenRatio, "")} explanation={TLRATIO_EXPLANATION} />
                <CallDetailsMetric label="Engaging Questions" value={engagingQuestions} explanation={ENGAGING_QUESTION_EXPLANATION} />
            </div>
        </div>
    );
}

CallPreviewRepsSection.propTypes = {}
CallPreviewRepsSection.defaultProps = {}




