import React, { useEffect, useState } from "react";
import { ReactComponent as Eye } from "app/styles/assets/images/icons/Eye.svg";
import { ReactComponent as Clock } from "app/styles/assets/images/icons/Clock.svg";
import { ReactComponent as Arrow } from "app/styles/assets/images/icons/Arrow.svg";
import { ReactComponent as LinkBroken } from "app/styles/assets/images/icons/LinkBroken.svg";
import { ReactComponent as Extend } from "app/styles/assets/images/icons/Extend.svg"
import { TextBase, TextLarge, Text2XL, Button, CheckBox } from "app/designSystem"
import ShareCallDetails from "./ShareCallDetails";
import { getShareableLink } from "app/utils/network";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { useSelector } from "react-redux";
import { sendRequest } from "../../utils/network";
import { updateNotificationReceiversForSnippet } from "app/utils/network"
const ShareCallManagement = ({ documents, fullCallDocument, meetTitle, backEnabled, backFunction }) => {

    const [snippetName, setSnippetName] = useState("");
    const [currentSnippetDocument, setCurrentSnippetDocument] = useState(null)
    const [currentUiMode, setCurrentUiMode] = useState("List")
    const [isFullCall, setIsFullCall] = useState(false)
    const { setSnackbar } = useSnackbar();
    const userId = useSelector(store => store.auth.userId);
    const [snippetDocs, setSnippetDocs] = useState(documents)
    const [fullCallDoc, setFullCallDoc] = useState(fullCallDocument)
    useEffect(() => {
        handleBack();
    }, [backEnabled]);

    function handleBack() {
        if (!backEnabled) {
            setCurrentSnippetDocument(null)
            setCurrentUiMode("List")
        }
    }
    function secondsToTimeConverter(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    }


    function handleSnippets(document, isFullCall) {
        backFunction();
        setCurrentUiMode("Details")
        setIsFullCall(isFullCall)
        setCurrentSnippetDocument(document)
    }

    function getLinkActivityStatus(document) {
        var linkExpired = false;
        if (document.expiresAt) {
            var expiry = new Date(document.expiresAt);
            var today = new Date();
            if (expiry < today) {
                linkExpired = true;
            }
        }
        return linkExpired;
    }

    async function copyLinkToClipBoard(event, document, isFullCallDocument, index) {
        var link = getShareableLink(document.shareId)
        event.stopPropagation();
        navigator.clipboard.writeText(link)
        setSnackbar("Link Copied to clipboard", "SUCCESS");
        let snippetDocuments = [...snippetDocs]
        const response = await updateNotificationReceiversForSnippet(document, userId)
        if (response) {
            if (isFullCallDocument) {
                setFullCallDoc(response)
            }
            else {
                snippetDocuments[index] = response;
                setSnippetDocs(snippetDocuments);
            }
        }
    }


    function handleLinkActivityStatus(document) {
        var linkExpired = getLinkActivityStatus(document)
        if (linkExpired) {
            return (
                <div className="flex flex-row items-center justify-start bg-red-100 px-3 py-2 rounded-xl">
                    <LinkBroken className="mr-2" />
                    <TextBase className="mr-1" textColor={"text-red-600"}>
                        Inactive
                    </TextBase>
                </div>
            )
        }
        else {
            return (
                <div className="flex flex-row items-center justify-start bg-green-100 py-2 px-3 rounded-xl">
                    <Extend className="mr-2" />
                    <TextBase className="mr-1" textColor={"text-green-600"}>
                        Active
                    </TextBase>
                </div>
            )
        }


    }

    return (
        <div className="flex flex-col items-center justify-start w-full pb-10">
            {currentUiMode == "List" &&
                <div className="w-full">
                    <div className=" w-full cursor-pointer" onClick={() => handleSnippets(fullCallDoc, true)}>
                        <div className="bg-coolGray-50 py-4 pl-8 flex flex-col items-center justify-start w-full">
                            <TextBase className="w-full" textColor={"text-coolGray-900"}>Entire Call</TextBase>
                        </div>
                        <div className="flex flex-row items-center justify-between px-8 w-full py-5">
                            <div className="flex flex-col items-center justify-start w-3/4">
                                <TextBase className="text-coolGray-800  w-full">{meetTitle}</TextBase>
                                <div className="flex flex-row items-center justify-start w-full mt-2">
                                    <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                                        <Eye className="mr-2" />
                                        <TextBase className=" mr-1" textColor={"text-coolGray-500"}>
                                            {fullCallDoc.views ? fullCallDoc.views.length : 0}
                                        </TextBase>
                                        <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                            {(fullCallDoc.views ? fullCallDoc.views.length : 0) == 1 ? "View" : "Views"}
                                        </TextBase>
                                    </div>
                                    <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                                        <Clock className="mr-2" />
                                        <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                            {secondsToTimeConverter(fullCallDoc.startTime) + " - "}
                                        </TextBase>
                                        <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                            {secondsToTimeConverter(fullCallDoc.endTime)}
                                        </TextBase>
                                    </div>
                                    <Button
                                        className="flex flex-row items-center justify-start !bg-green-100 py-2 px-3 rounded-xl ml-4"
                                        onClick={(e) => copyLinkToClipBoard(e, fullCallDoc, true)}
                                        id="copyFullCallLinkFromManagement"
                                    >
                                        <TextBase className="mr-1" textColor={"text-green-600"}>
                                            Copy Link
                                        </TextBase>
                                    </Button>
                                </div>
                            </div>
                            <Arrow className="cursor-pointer" />
                        </div>
                    </div>
                    <div className="flex  items-center  w-full pr-3 py-3 pl-8 mb-5 justify-between bg-coolGray-50">
                        <TextBase textColor={"text-coolGray-900"}>Trimmed snippets</TextBase>
                    </div>

                    {snippetDocs && (snippetDocs).map((document, index) => {
                        if (document.validatedSnippet) {
                            return (

                                <div className="flex flex-col items-center justify-between px-8 pt-0 pb-5 w-full cursor-pointer " onClick={() => handleSnippets(document, false)}>
                                    <div className="flex flex-row items-center justify-between w-full">
                                        <div className="flex flex-col items-center justify-start w-5/6 ">
                                            <TextBase className="w-full" textColor={"text-coolGray-900"}>
                                                {document.snippetName ? document.snippetName : meetTitle + " @ " + secondsToTimeConverter(document.startTime) + " - " + secondsToTimeConverter(document.endTime)}
                                            </TextBase>
                                            <div className="flex flex-row items-center justify-start w-full my-2">
                                                <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-4">
                                                    <Eye className="mr-2" />
                                                    <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                                        {document.views ? document.views.length : 0}
                                                    </TextBase>
                                                    <TextBase textColor={"text-coolGray-500"}>
                                                        {(document.views ? document.views.length : 0) == 1 ? " View" : " Views"}
                                                    </TextBase>
                                                </div>
                                                <div className="flex flex-row items-center justify-start bg-gray-50 p-2 rounded-xl mr-5">
                                                    <Clock className="mr-2" />
                                                    <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                                        {secondsToTimeConverter(document.startTime) + " -"}
                                                    </TextBase>
                                                    <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                                        {secondsToTimeConverter(document.endTime)}
                                                    </TextBase>
                                                </div>
                                                {true && handleLinkActivityStatus(document)}
                                                {!getLinkActivityStatus(document) &&

                                                    <Button
                                                        className="flex flex-row items-center justify-start !bg-green-100 py-2 px-3 rounded-xl ml-4"
                                                        onClick={(e) => copyLinkToClipBoard(e, document, false, index)}
                                                        id="copySnippetLinkFromManagement"
                                                    >
                                                        <TextBase className="mr-1" textColor={"text-green-600"}>
                                                            Copy Link
                                                        </TextBase>
                                                    </Button>

                                                }

                                            </div>
                                        </div>
                                        <Arrow className="cursor-pointer" />
                                    </div>
                                    {snippetName == "" && <div className="w-full border-solid border-coolGray-200 border-t"></div>}
                                </div>



                            )
                        }


                    })}
                    {documents.length == 0 &&
                        <TextBase textColor="text-coolGray-900" className="px-8 w-full ">
                            No snippets have been created from this call yet.
                        </TextBase>
                    }
                </div>}
            {currentUiMode == "Details" &&
                <div className="w-full">
                    <ShareCallDetails document={currentSnippetDocument} isFullCall={isFullCall} meetTitle={meetTitle} />
                </div>
            }



        </div>
    );
};

export default ShareCallManagement;
