import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ExpandedSection from "app/styles/assets/images/icons/dealView/expandedSection.svg";
import CollapsedSection from "app/styles/assets/images/icons/dealView/collapsedSection.svg"
import ContactsIconDark from "app/styles/assets/images/icons/ContactsIconDark.svg"
import ContactIcon from "app/styles/assets/images/icons/dealView/ContactIcon.svg";
import { checkIfContactInLinkedDomains, checkIfExternalFromEmail, getDomainFromEmail, _getActivityTypes } from "../../utils/helpers";
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

const AccountContactsRow = ({ index, style, data }) => {
    const { contacts, activityTypes } = data;
    const contact = contacts[index];

    return (
        <div className="DealView__InfoPanel__SectionRow" key={contact.email + "_" + contact.name} style={style}>
            <div className="DealView__InfoPanel__SectionItem">
                <img className="DealView__InfoPanel__SectionIcon" src={ContactIcon} />
                <div className="DealView__InfoPanel__SectionNameContainer">
                    <span className="DealView__InfoPanel__SectionName">{contact.name || "---"}</span>
                    <span className="DealView__InfoPanel__SectionJob">{contact.jobTitle || "---"}</span>
                </div>
            </div>
            <div className="DealView__InfoPanel__SectionActivityContainer">
                {
                    Object.keys(activityTypes).map((activityType, index) => (
                        <div className="DealView__InfoPanel__SectionActivityRow" key={activityType + "_" + index}>
                            <img className="DealView__InfoPanel__SectionActivityIcon" src={activityTypes[activityType].icon} />
                            <span className="DealView__InfoPanel__SectionActivityNumber">{contact[activityType]}</span>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

const AccountContact = ({ crmData, interactions }) => {
    const contactsInfoList = crmData.contacts;
    const [contacts, setContacts] = useState([]);
    const [showContacts, setShowContacts] = useState(true);
    const auth = useSelector(store => store.auth);
    const customerDomains = auth.stringsCustomer.authorizedDomains;
    const [linkedDomains, setLinkedDomains] = useState([]);
    const activityTypes = _getActivityTypes();

    useEffect(() => {
        if (interactions !== null && interactions.length > 0) setContacts(getContactsFromInteractions());
    }, [interactions, linkedDomains])

    useEffect(() => {
        if (contactsInfoList) setLinkedDomains(getLinkedDomainsFromContacts());
    }, [crmData])

    function getLinkedDomainsFromContacts() {
        return [... new Set(contactsInfoList.flatMap(contact => {
            let domain = getDomainFromEmail(contact.email);
            if (domain) return domain;
            return [];
        }))];
    }

    function getContactsFromInteractions() {
        let contactsList = [];

        interactions.map((interaction) => {
            let contactsFromInteraction = [];
            if (interaction.type === "EMAIL") contactsFromInteraction = getContactsFromMail(interaction);
            else contactsFromInteraction = getContactsFromCall(interaction);
            contactsFromInteraction.map((contact) => {
                let match = contactsList.find(c => {
                    if (c.email && c.email === contact.emailAddress) return c;
                    return false;
                });
                if (!match) {
                    contactsList.push(addNewToContactsList(contact));
                }
                else {
                    let index = contactsList.indexOf(match);
                    contactsList[index][contact.type] += 1;
                }
            })
        })

        return contactsList;
    }

    function addNewToContactsList(contact) {
        let contactDetails = { email: "", name: "", jobTitle: "", incomingEmails: 0, calls: 0, outgoingEmails: 0 };
        let match = contactsInfoList ? contactsInfoList.find(user => user.email === contact.emailAddress) : false;
        if (match) {
            contactDetails.name = match.fullName || match.email;
            contactDetails.jobTitle = match.jobTitle || "-";
        }
        else contactDetails.name = contact.emailAddress;

        contactDetails.email = contact.emailAddress;
        contactDetails[contact.type] = 1;
        return contactDetails;
    }

    function getContactsFromMail(interaction) {
        let mailContacts = [];
        let fromAddress = interaction.fromAddress;
        let toAddresses = interaction.toAddresses || [];

        if (fromAddress !== null && checkIfExternalFromEmail(fromAddress, customerDomains) && checkIfContactInLinkedDomains(fromAddress, linkedDomains)) {
            mailContacts.push({ emailAddress: fromAddress, type: "incomingEmails" });
        }
        toAddresses.map((toAddress) => {
            if (checkIfExternalFromEmail(toAddress, customerDomains) && checkIfContactInLinkedDomains(toAddress, linkedDomains)) {
                mailContacts.push({ emailAddress: toAddress, type: "outgoingEmails" });
            }
        })
        return mailContacts;
    }

    function getContactsFromCall(interaction) {
        let callContacts = [];
        let attendees = interaction.meetingInfo.meetingAttendees || [];
        attendees.map((attendee) => {
            if (checkIfExternalFromEmail(attendee.email, customerDomains) && checkIfContactInLinkedDomains(attendee.email, linkedDomains)) callContacts.push({ emailAddress: attendee.email, type: "calls" });
        })
        return callContacts;
    }

    return (
        <div>
            {
                contacts.length > 0 &&
                <div>
                    <div onClick={() => setShowContacts(showContacts => !showContacts)} className="DealView__InfoPanel__Header">
                        <div className="DealView__InfoPanel__HeaderTitle">
                            <img src={ContactsIconDark} className="DealView__InfoPanel__HeaderIcon" />
                            <span className="DealView__InfoPanel__HeaderText">Contacts ({contacts.length})</span>
                        </div>
                        <img src={showContacts ? ExpandedSection : CollapsedSection} className="DealView__InfoPanel__HeaderArrow" />
                    </div>
                    <div className="relative overflow-y-scroll pb-16" style={{
                        height: "calc(100vh - 12rem)"
                    }}>
                        <AutoSizer className={`${showContacts ? "" : "hidden"} DealView__InfoPanel__Section !h-full`}>
                            {({ height, width }) => (
                                <FixedSizeList height={height} width={width} itemSize={55} itemCount={contacts.length} itemData={{ contacts, activityTypes }}>
                                    {AccountContactsRow}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            }
        </div >
    );
};


export default AccountContact;
