import React from "react";
import PropTypes from "prop-types";

export const HistogramBar = props => (
  <HistogramBarContainer title={props.title}>
    <Labels left={props.leftLabel} right={props.rightLabel} />
    <OuterBar>
      <InnerBar color={props.color} size={props.size} label={props.barLabel} />
    </OuterBar>
  </HistogramBarContainer>
);

HistogramBar.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  barLabel: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

HistogramBar.defaultProps = {
  leftLabel: "",
  rightLabel: "",
  barLabel: "",
  color: "purple",
  size: 10,
};

const HistogramBarContainer = props => (
  <div className="HistogramBar__container" title={props.title}>
    {props.children}
  </div>
);

const OuterBar = props => <div className="HistogramBar__outerBar">{props.children}</div>;

const InnerBar = props => (
  <div
    className="HistogramBar__innerBar"
    style={{ backgroundColor: props.color, width: `${props.size}%` }}
  >
    {props.size > 10 && <span className="HistogramBar__innerBar__label">{props.label}</span>}
  </div>
);

const Labels = props => (
  <div className="HistogramBar__labels">
    <div className="HistogramBar__labels__left">{props.left}</div>
    <div className="HistogramBar__labels__right">{props.right}</div>
  </div>
);
