import React from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Paper, Badge } from '@material-ui/core';
import { connect } from "react-redux";
import { _formatTalkListenRatio, _formatSecondsToPlayingTime } from '../../utils/helpers';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const TeamDashboardCircleComparison = props => {

  const isDuration = props.correlation.format === 'duration';
  const maxValue = isDuration ? Math.max(props.correlation.won.value, props.correlation.lost.value) : 1;

  function format(v) {
    if (isDuration) return _formatSecondsToPlayingTime(v);
    return _formatTalkListenRatio(v);
  }

  return (<Paper style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
    <div style={{ fontSize: '18px', margin: '5px' }}>
      {props.correlation.name}
    </div>
    <div style={{ width: '80%', margin: '10px auto', maxWidth: '200px', maxHeight: '200px' }}>
      <CircularProgressbarWithChildren value={props.correlation.won.value} maxValue={maxValue} strokeWidth={5} styles={{
        path: {
          stroke: '#4CD964',
          strokeLinecap: 'butt',
          transformOrigin: 'center center',
        },
        trail: {
          stroke: '#F7F7F7',
          transformOrigin: 'center center'
        }
      }}>
        <div style={{ width: '70%', height: '70%', marginTop: "-5px" }}>
          <CircularProgressbarWithChildren value={props.correlation.lost.value} maxValue={maxValue} strokeWidth={7.143} styles={{
            path: {
              stroke: '#FD5181',
              strokeLinecap: 'butt',
              transformOrigin: 'center center',
            },
            trail: {
              stroke: '#F7F7F7',
              transformOrigin: 'center center',
            }
          }}>
          </CircularProgressbarWithChildren>
        </div>
      </CircularProgressbarWithChildren>
    </div>
    <div style={{ margin: '5px 0' }}>
      {getLabel('Deal Won', '#4CD964', props.correlation.won.count, format(props.correlation.won.value))}
      {getLabel('Deal Lost', '#FD5181', props.correlation.lost.count, format(props.correlation.lost.value))}
    </div>
  </Paper>);
};

const getLabel = (label, color, count, value) => {
  return <div style={{ display: 'flex', margin: '10px 0' }}>
    <span style={{ flex: 1 }}><span style={{ margin: 'auto 8px', borderRadius: '50%', width: '8px', height: '8px', backgroundColor: color, display: 'inline-block' }} />{label} ({count}):</span><span>{value}</span>
  </div>;
}

const TeamDashboardCorrelations = props => {
  return (<Paper style={{ padding: '20px', height: '100%' }}>
    <CarouselProvider
      naturalSlideWidth={280}
      naturalSlideHeight={334}
      totalSlides={props.correlations.length}>
      <Slider>
        {props.correlations.map((correlation, i) => <Slide>
          <TeamDashboardCircleComparison key={i} correlation={correlation} />
        </Slide>)}
      </Slider>
      <div style={{ display: 'flex' }}>
        <ButtonBack><ArrowBackIosIcon /></ButtonBack>
        <span style={{ flexGrow: '1', textAlign: 'center' }}>
          {props.correlations.map((c, i) => <Dot slide={i} className="TeamDashboard__CarouselDot">&#8226;</Dot>)}
        </span>
        <ButtonNext><ArrowForwardIosIcon /></ButtonNext>
      </div>
    </CarouselProvider>
  </Paper>)
};

export default connect(a => a, {})(TeamDashboardCircleComparison);