import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "app/actions/auth";
import { submitFreshsalesInfo } from "app/utils/network";
import { Grid, withStyles, TextField } from "@material-ui/core";
import { CustomLink } from "app/components/common";
import { Button } from "app/components/form";
import { setLoading } from "app/actions/screen";
import { SIGN_UP, FORGOT_PASSWORD } from "app/constants";
import freshsaleslogo from "app/styles/assets/images/onboarding/freshsalesLogo.svg";
import { Loading } from "app/components/common";
import { Card } from "app/components/common";
import { crmOAuthCallback } from "app/utils/network";
class FreshsalesForm extends Component {
  constructor(props) {
    super(props);
    this.setDomain = this.setDomain.bind(this);
    this.setApiKey = this.setApiKey.bind(this);
    this.submitFreshsalesInfo = this.submitFreshsalesInfo.bind(this);
    this.submitOnEnter = this.submitOnEnter.bind(this);
    this.state = {
      domain: "",
      apiKey: "",
      error: "",
      domainError: false,
      apiKeyError: false,
    };
  }

  componentDidMount() {
    const freshsalesForm = document.getElementById("freshsalesForm");
    freshsalesForm.addEventListener("keyup", this.submitOnEnter);
  }

  submitOnEnter(event) {
    const FreshsalesForm = this.FreshsalesForm;
    event.preventDefault();
    if (event.keyCode === 13) {
      this.submitFreshsalesInfo();
    }
  }

  setDomain(event) {
    this.setState({ domain: event.target.value, error: "" });
  }

  setApiKey(event) {
    this.setState({ apiKey: event.target.value, error: "" });
  }

  async submitFreshsalesInfo() {
    this.props.setLoading(true);
    if (this.setState.domain == "") {
      this.setState({ error: "Enter a domain name" });
    } else if (this.setState.apiKey == "") {
      this.setState({ error: "Enter an API key" });
    }
    const userId = this.props.match.params.userId || "";
    const access = this.props.match.params.access || "user";
    let domain = this.state.domain;
    if (!this.state.domain.includes("http")) {
      domain = `https://${this.state.domain}`;
    }
    //comment to get new build
    const response = await crmOAuthCallback(
      this.state.apiKey,
      "FRESHSALES",
      `${domain}.freshsales.io`
    );
    if (response.error) {
      this.setState({ error: response.data.message });
    } else {
      this.props.setLoading(false);
      window.close();
    }
    this.props.setLoading(false);
  }

  componentWillUnmount() {
    const freshsalesForm = document.getElementById("freshsalesForm");
    freshsalesForm.removeEventListener("keyup", this.submitOnEnter);
  }

  render() {
    return (
      <div className="Freshsales">
        <Loading />
        <div className="Freshsales__content">
          <Card className="Freshsales__content">
            <Grid>
              <div className="row-topcentered">
                <img className="header__logo" src={freshsaleslogo} alt="Logo" />
              </div>
            </Grid>
            <Grid>
              <div id="freshsalesForm">
                <table>
                  <tbody>
                    <tr className="row-centered">
                      <td style={{ display: "flex", alignItems: "baseline" }}>
                        <TextField
                          id="domain"
                          label="Enter freshsales CRM domain name"
                          error={this.state.domainError}
                          required
                          value={this.state.domain}
                          className="Freshsales__textFieldShort"
                          onChange={this.setDomain}
                          margin="normal"
                        />
                        <span className="Freshsales__textFieldBlack">.freshsales.io</span>
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td>
                        <TextField
                          id="apiKey"
                          label="Enter your freshsales account API key"
                          error={this.state.apiKeyError}
                          required
                          value={this.state.apiKey}
                          className="Freshsales__textField"
                          onChange={this.setApiKey}
                          margin="normal"
                          type="apiKey"
                        />
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td>
                        <Button theme="dark" id="syncFreshSales" onClick={this.submitFreshsalesInfo}>
                          Sync Freshsales
                        </Button>
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td className="Freshsales__infoDiv">
                        <span className="Freshsales__info">
                          You can find your freshsales API Key at:
                        </span>
                        <span className="Freshsales__infoLink">
                          <a
                            target="_blank"
                            href={`https://${this.state.domain === "" ? "<your_domain_name>" : this.state.domain
                              }.freshsales.io/personal-settings/api-settings`}
                          >
                            {`https://${this.state.domain === "" ? "<your_domain_name>" : this.state.domain
                              }.freshsales.io/personal-settings/api-settings`}
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td>
                        {this.state.error && <span className="error">{this.state.error}</span>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

FreshsalesForm.propTypes = {
  setUserData: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
};

FreshsalesForm.defaultProps = {};

export default connect(
  mapStateToProps,
  { setUserData, setLoading },
)(FreshsalesForm);
