import React from "react";
import NoCallsIcon from "app/styles/assets/images/noCalls.png";
import NoDataIcon from "app/styles/assets/images/icons/noData.svg";
import { Grid } from "@material-ui/core";
import { MAIN } from "app/constants";

const NoData = props => (
  <div
    className={props.screenType == MAIN ? "NoCalls" : "NoCallsWider"}
    style={{ ...props.style }}
  >
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          alignItems={"center"}
          direction={"column"}
          justify={"center"}
        >
          <Grid item>
            <img className="NoCalls__Icon" src={NoCallsIcon} alt="Logo" />
          </Grid>
          <Grid item>
            <h4>{props.title}</h4>
          </Grid>
          <Grid item>
            <span>{props.subheading}</span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default NoData;

export const NoDataCustom = props => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80%", width: "100%", flexDirection: "column" }}>
    <img style={{ width: "300px", margin: "20px 0px" }} src={props.icon} alt="Logo" />
    <span style={{ fontSize: "18px", fontWeight: "700" }}>{props.copy}</span>
    <span style={{ margin: "5px 0px", padding: "0px 10px", textAlign: "center", color: "#8798AD", fontSize: "12px", fontWeight: "400" }}>{props.subcopy}</span>
  </div>
);

NoDataCustom.defaultProps = {
  icon: NoDataIcon,
  copy: "No Data Found"
}
