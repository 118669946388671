import React, { useEffect, useState } from 'react'
import { _getActivitiesByDate } from 'app/utils/ActivityHelper';
import ActivityBar from './ActivityBarAccountView';
import { ActivityTooltip } from './DealActivity';
import LeftArrow from "app/styles/assets/images/icons/LeftArrowIcon.svg";
import RightArrow from "app/styles/assets/images/icons/RightArrowIcon.svg";
import ActivityDates from './ActivityDates';
import Skeleton from 'react-loading-skeleton';
import { VariableSizeList, FixedSizeList } from 'react-window';
import ReactTooltip from 'react-tooltip';

const ActivityBarColumn = ({ index, activityForOneDay, scrollToDate, setActiveActivityForOneDay }) => {
    return (
        <div data-tip data-for={"ActivityTip"} style={{ cursor: "pointer" }}
            onClick={() => scrollToDate(activityForOneDay.date)}
            onMouseEnter={() => setActiveActivityForOneDay(activityForOneDay)}
            onMouseLeave={() => setActiveActivityForOneDay(null)}
        >
            <ActivityBar propId={"ActivityBar" + index} activityForOneDay={activityForOneDay} barSize="Big" />
        </div>
    )
}

const Activity = ({ activity, scrollToDate }) => {
    const [enableHover, setEnableHover] = useState(false);
    const [activitiesByDate, setActivitiesByDate] = useState(null);
    const [isScrollableRight, setIsScrollableRight] = useState(false);
    const [isScrollableLeft, setIsScrollableLeft] = useState(false);

    const [activeActivityForOneDay, setActiveActivityForOneDay] = useState(null);

    const activityBarWidth = 32;

    useEffect(() => {
        setTimeout(() => setEnableHover(true), 500)
    }, []);

    useEffect(() => {
        let activityTimelineDiv = document.getElementById("ActivityTimelineDiv");
        let minNumOfDays = activityTimelineDiv !== null ? Math.ceil(activityTimelineDiv.clientWidth / activityBarWidth) : 50;
        let activitiesByDateLocal = _getActivitiesByDate(activity, minNumOfDays);
        setActivitiesByDate(activitiesByDateLocal);
    }, [activity])

    useEffect(() => {
        scrollToLatest();
    }, [activitiesByDate])

    function scrollActivity(direction) {
        let activityTimelineDiv = document.getElementById("ActivityTimelineDiv");
        sideScroll(activityTimelineDiv, direction);
    }

    function scrollToLatest() {
        let activityTimelineDiv = document.getElementById("ActivityTimelineDiv");
        sideScroll(activityTimelineDiv, 'latest');
    }

    function sideScroll(element, type, step = activityBarWidth * 14) {      //scrolling 2 weeks
        if (activitiesByDate) {
            let maxScroll = -(activitiesByDate.length * (activityBarWidth + 0.25) - element.clientWidth);
            if (type === 'latest') element.style.left = maxScroll + "px";
            else {
                if (type === 'left') {
                    element.style.left = Math.min(activityBarWidth * 0.25, (parseFloat(element.style.left) + step)) + "px";
                } else {
                    element.style.left = Math.max(maxScroll, (parseFloat(element.style.left) - step)) + "px";
                }
            }

            checkScrollable(element, maxScroll);
        }
    }

    function checkScrollable(el, maxScroll) {
        let leftPos = parseFloat(el.style.left);
        if (leftPos !== maxScroll) setIsScrollableRight(true);
        else setIsScrollableRight(false);

        if (leftPos !== activityBarWidth * 0.25) setIsScrollableLeft(true);
        else setIsScrollableLeft(false);
    }

    function getDateColumnSize(index) {
        const activityForOneDay = activitiesByDate[index];
        const currentDate = activityForOneDay.date;

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        if (new Date(currentDate).getTime() === today.getTime()) { // Date is today
            return 45;
        }

        return 32;
    }

    return (
        <div className="DealView__AccountActivity__Container">
            <img className="DealView__AccountActivity__ScrollArrows" id="DealViewTimelineLeftScroll"
                onClick={() => { scrollActivity('left') }}
                src={LeftArrow} style={isScrollableLeft ? {} : { filter: 'grayscale(1) opacity(0.4)' }} />
            <div className="DealView__AccountActivity__Timeline">
                <div id="ActivityTimelineDiv" style={{ position: "relative", transition: "left 400ms ease 0s" }}>
                    <div className="DealView__AccountActivity__Dates">
                        {
                            activitiesByDate?.map((activityForOneDay, index) => {
                                return (
                                    <div key={"DealView_Date__" + activityForOneDay.date + index}>
                                        <ActivityDates date={activityForOneDay.date} />
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="DealView__AccountActivity__Bars" onMouseLeave={() => setActiveActivityForOneDay(null)}>
                        {
                            activitiesByDate?.map((activityForOneDay, index) => (
                                <ActivityBarColumn key={"DealView_Bards__" + activityForOneDay.date + index} index={index} activityForOneDay={activityForOneDay} scrollToDate={scrollToDate} setActiveActivityForOneDay={setActiveActivityForOneDay} />
                            ))
                        }

                        {
                            activeActivityForOneDay && <ReactTooltip place="bottom" id={"ActivityTip"} type="light" effect="solid" className="WhiteTooltip WhiteTooltip__noPadding" disable={!enableHover}>
                                <ActivityTooltip activityForOneDay={activeActivityForOneDay} view="account" />
                            </ReactTooltip>
                        }
                    </div>
                </div>
            </div>
            <img
                className="DealView__AccountActivity__ScrollArrows"
                id="DealViewTimelineRightScroll"
                onClick={() => { scrollActivity('right') }}
                src={RightArrow} style={isScrollableRight ? {} : { filter: 'grayscale(1) opacity(0.4)' }} />
        </div>
    );
}


export const PreviewAccountActivity = () => {
    return (
        <div className="DealView__AccountActivity__Container">
            <div className="DealView__AccountActivity__Timeline" id="ActivityTimelineDiv">
                <div className="DealView__AccountActivity__Bars">
                    <Skeleton height={80} width={1200} style={{ marginTop: "20px" }} />
                </div>
            </div>
        </div>
    )
}

Activity.propTypes = {}
Activity.defaultProps = {}
export default Activity;