import moment from "moment";
export function getTimeHeading(duration, startDate, endDate) {
  if (duration == "day") {
    return moment(startDate).format("DD MMM");
  } else if (duration == "week") {
    return `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM")}`;
  } else if (duration == "month") {
    return `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM")}`;
  } else if (duration == "quarter") {
    return `${moment(startDate).format("DD MMM")} - ${moment(endDate).format("DD MMM")}`;
  } else if (duration == "year") {
    return `${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")}`;
  }
}
export function getStartDate(duration) {
  // eslint-disable-next-line linebreak-style
  let today_moment = moment(new Date()).set({
    hour: "0",
    minute: "0",
    second: "0",
  })
  let today = today_moment.toDate();
  switch (duration) {
    case "day":
      return today_moment
        .toDate();
    case "week":
      return today_moment
        .subtract(7, "days")
        .toDate();
    case "month":
      return today_moment
        .subtract(1, "months")
        .toDate();
    case "quarter":
      return today_moment
        .subtract(3, "months")
        .toDate();
    case "year":
      return today_moment
        .subtract(1, "years")
        .toDate();
    case "allTime":
      return null;
    case "this_week":
      var day = today.getDay(),
        diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      return new Date(today.setDate(diff));
    case "this_month":
      var month = Math.floor((today.getMonth()));
      return new Date(today.getFullYear(), month, 1);
    case "this_quarter":
      var quarter = Math.floor((today.getMonth() / 3));
      return new Date(today.getFullYear(), quarter * 3, 1);
    case "last_quarter":
      var quarter = Math.floor((today.getMonth() / 3));
      return new Date(today.getFullYear(), (quarter - 1) * 3, 1);
    case "this_year":
      return new Date(today.getFullYear(), 0, 1);

  }
}

export function isSameDate(date1, date2) {
  return date1.getYear() === date2.getYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function getActivePeriod(period, startDate, endDate) {
  return isSameDate(moment(startDate).toDate(), getStartDate(period)) && isSameDate(moment(endDate).toDate(), getEndDate(period));
}

export function getFutureEndDate(duration) {
  // eslint-disable-next-line linebreak-style
  let today = moment(new Date()).set({
    hour: "23",
    minute: "59",
    second: "59",
  });
  if (duration === "day") {
    return today.toDate();
  } else if (duration === "week") {
    return today
      .add(7, "days")
      .toDate();
  } else if (duration === "month") {
    return today
      .add(1, "months")
      .toDate();
  } else if (duration === "quarter") {
    return today
      .add(3, "months")
      .toDate();
  } else if (duration === "year") {
    return today
      .add(1, "years")
      .toDate();
  }
}
export function getEndDate(duration) {
  let today = moment(new Date()).set({
    hour: "23",
    minute: "59",
    second: "59",
  }).toDate();
  switch (duration) {
    case "this_week":
      var day = today.getDay(),
        diff = today.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
      var last = diff + 6;
      var end = today.setDate(last);
      return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    case "this_month":
      return new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
    case "this_quarter":
      var quarter = Math.floor((today.getMonth() / 3));
      var firstDate = new Date(today.getFullYear(), quarter * 3, 1, 23, 59, 59);
      return new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0, 23, 59, 59);
    case "last_quarter":
      var quarter = Math.floor((today.getMonth() / 3));
      var firstDate = new Date(today.getFullYear(), (quarter - 1) * 3, 1, 23, 59, 59);
      return new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0, 23, 59, 59);
    case "this_year":
      return new Date(today.getFullYear(), 11, 31, 23, 59, 59);
    case "allTime": return null;
    default: return today;
  }

}
export function getDuration(startDate, endDate) {
  if (startDate === null) {
    return "year";
  }
  const diff = moment(endDate).diff(moment(startDate), "days");
  if (diff < 2)
    return "day";
  else if (diff < 10)
    return "week";
  else if (diff < 31)
    return "month";
  else if (diff < 100)
    return "quarter";
  return "year";
}

const generateHours = () => {
  const hours = []
  for (let i = 0; i < 24; i++) {
    const hourValue = i % 12 || "12";
    const prefix = hourValue < 10 ? '0' : '';
    const AM_PM = (i < 12) ? "AM" : "PM"
    const hour = prefix + hourValue + ":00 " + AM_PM
    const date = new Date(0)
    date.setHours(prefix + i, '00', '00')
    hours.push({ label: hour, value: date.getTime() })
  }
  return hours
}

export const timeOptions = generateHours();

const generateHoursForSlack = () => {
  const hours = []
  for (let i = 0; i < 24; i++) {
    const hourValue = i % 12 || "12";
    const prefix = hourValue < 10 ? '0' : '';
    const AM_PM = (i < 12) ? "AM" : "PM"
    const hour = prefix + hourValue + ":00 " + AM_PM
    hours.push({ label: hour, value: 'PT' + i + 'H' })
  }
  return hours
}

export const timeOptionsForSlack = generateHoursForSlack();


export const getTimezoneOffsetString = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezoneOffsetString = (timezoneOffset < 0 ? '-PT' : 'PT') + Math.floor(Math.abs(timezoneOffset / 60)) + 'H' + Math.abs(timezoneOffset % 60) + 'M';
  return timezoneOffsetString;
}
