import React, { useState, useEffect, useRef } from 'react';
import ProfileImage from 'app/components/common/ProfileImage';
import { TextBase, Text2XL, Button } from 'app/designSystem'
import moment from 'moment';
import { BellRingingIcon, BellIcon, ShareIcon } from 'app/designSystem/icons'
import useDataHelper from "app/utils/hooks/useDataHelper";
import TextArea from 'app/designSystem/form/TextArea';
import { updateFolderMetadata } from 'app/utils/network/gametapes'
import { useKeyListener } from 'app/utils/hooks/useKeyListener';
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { useSelector } from 'react-redux';
import { WingmanLogo } from "app/designSystem"

const FolderInformationPanel = ({ folderId, handleSubscribe, handleShare }) => {
    const userId = useSelector(store => store.auth.userId);
    const { cachedFoldersData } = useSelector(state => state.gametapes);

    const { getUserName } = useDataHelper();
    const [subscribed, setSubscribed] = useState(cachedFoldersData[folderId].subscribedUsers?.includes(userId));
    const [editing, setEditing] = useState(false);
    const [description, setDescription] = useState(cachedFoldersData[folderId].description);
    const [isDefaultFolder, setIsDefaultFolder] = useState(true);
    const [descriptionId, setDescriptionId] = useState("");
    const editableInputRef = useRef(null);
    const { setSnackbar } = useSnackbar();

    useEffect(() => {
        if (cachedFoldersData[folderId]) {
            setSubscribed(cachedFoldersData[folderId].subscribedUsers?.includes(userId));
            setDescription(cachedFoldersData[folderId].description);

            if (cachedFoldersData[folderId].userId) {
                setIsDefaultFolder(false);
            } else {
                setIsDefaultFolder(true);
            }
        }
    }, [cachedFoldersData])

    useKeyListener(descriptionId, (e) => handleChange(e));

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [])

    useEffect(() => {
        if (editing && editableInputRef.current) {
            editableInputRef.current.focus();
            setDescriptionId("folder_id")
        }

    }, [editableInputRef, editing])

    const handleClickOutside = (event) => {
        if (editableInputRef.current && !editableInputRef.current.contains(event.target)) {
            setEditing(false);
        }
    }

    const handleChange = (event) => {
        if (event.target.value.length > 0) {
            setDescription(event.target.value);
        }

        if (event.key === "Escape") {
            setEditing(false);
            setDescription("");
            return;
        }

        if (event.key === "Enter" && event.shiftKey) {
            return;
        }

        if (event.key === "Enter" && !event.shiftKey) { // Update folder metadata on pressing enter
            updateDescription();
            return;
        }
    }

    const updateDescription = async () => {
        try {
            await updateFolderMetadata({
                folderId: cachedFoldersData[folderId]?.id,
                description: description.trim(),
                name: cachedFoldersData[folderId]?.name,
                isPrivate: cachedFoldersData[folderId]?.isPrivate,
                subscribedUsers: cachedFoldersData[folderId]?.subscribedUsers
            })

            setSnackbar("Folder Description updated", "SUCCESS");
        } catch (err) {
            setSnackbar("Something went wrong. Please try again later.", "ERROR");
        } finally {
            setEditing(false);
        }
    }

    return (
        <div className="flex flex-col w-full sticky bg-wingman-white rounded-xl p-4">
            <div className="mb-2">
                <Text2XL className="text-gray-900 font-medium break-words">{cachedFoldersData[folderId]?.name}</Text2XL>
            </div>
            <div className="mb-4">
                {editing && (
                    <div className="flex flex-col border rounded">
                        <TextArea id="folder_id" ref={editableInputRef} placeholder="Add a description" textAreaClassName="resize-none" />
                    </div>
                )}
                {!editing ? (description ? <TextBase className="text-gray-500 font-medium break-words">{description}</TextBase> : <TextBase className="!text-wingman-brand font-medium cursor-pointer" onClick={() => setEditing(true)}>Add description</TextBase>) : null}
            </div>
            {/* <div className="flex mb-4 flex-wrap">
                {
                    data.tags?.map((tag, index) => (
                        <div key={tag + index} className="border border-gray-200 rounded-4xl py-2 px-4 mr-2.5 mb-2.5 flex items-center">
                            <TextBase key={index} className="text-gray-500 font-light">{tag}</TextBase>
                        </div>
                    ))
                }
            </div> */}
            <div className="flex items-center mb-8">
                {isDefaultFolder ? <WingmanLogo className="w-9 h-auto rounded-full" /> : <ProfileImage userId={cachedFoldersData[folderId]?.userId} size={35} disableTooltip={true} />}
                <div className="ml-2 flex flex-col">
                    <TextBase className="text-gray-500">{
                        isDefaultFolder ? "Wingman" : getUserName(cachedFoldersData[folderId]?.userId)
                    }</TextBase>
                    <TextBase className="text-gray-500">Created on: {moment(cachedFoldersData[folderId]?.createdDate).format('MMM DD, YYYY')}</TextBase>
                </div>
            </div>
            <div className="flex">
                {
                    subscribed ?
                        <Button id="subscribe_folder_btn" startIcon={<BellRingingIcon stroke={"#ffffff"} />} className="py-2 w-36 h-10" onClick={() => { setSubscribed(false); handleSubscribe(false); }}>
                            Subscribed
                        </Button>
                        : <Button variant="secondary" startIcon={<BellIcon stroke={"#000000"} />} className="py-2 w-36 h-10" onClick={() => { setSubscribed(true); handleSubscribe(true); }}>
                            Subscribe
                        </Button>
                }
                <Button id="share_folder_btn" startIcon={<ShareIcon />} variant="secondary" className="py-2 ml-4 h-10" onClick={handleShare}>Share</Button>
            </div>
        </div>
    )
}

export default FolderInformationPanel;