import React from "react";
import PropTypes from "prop-types";
import { CustomChip } from "../common";

const TranscriptColumn = props => {
  let matchLabel = "Matches";
  if (props.call.turnsMatched === 1) {
    matchLabel = "Match";
  }
  return (
    <div className="flex flex-row items-center">
      <span className="CallTable__transcriptLabel">Matching transcript</span>
      <span className="CallTable__transcript__textContainer">
        {getTransformedTurnText(props.call.firstTurn)}
      </span>
      {props.call.turnsMatched > 1 && (
        <div className="CallTable__transcriptMatchChip">
          {`${props.call.turnsMatched - 1} More Results`}
        </div>
      )}
    </div>
  );
};

export function getTransformedTurnText(turnText) {
  let turnString = turnText;
  const turns = [];
  while (turnString.indexOf("<em>") !== -1) {
    const startPos = turnString.indexOf("<em>");
    const endPos = turnString.indexOf("</em>");
    turns.push({ type: "regular", text: turnString.slice(0, startPos) });
    turns.push({
      type: "bold",
      text: turnString.slice(startPos + 4, endPos),
    });
    turnString = turnString.slice(endPos + 5);
  }
  turns.push({ type: "regular", text: turnString });
  return (
    <>
      {turns.map((turn, i) => (
        <span
          key={i}
          className={
            turn.type == "bold" ? "CallTable__transcript_bold" : "CallTable__transcript_text"
          }
        >
          {turn.text}
        </span>
      ))}
    </>
  );
}

TranscriptColumn.propTypes = {};

export default TranscriptColumn;
