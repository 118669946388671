import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { InputBase } from "@material-ui/core";
import { Button } from "app/components/form";
import WarningIcon from "app/styles/assets/images/icons/warning.png";

class TalkingPointInput extends Component {
  async componentDidMount() {
    const talkingPointInput = document.getElementById("talkingPointInput");
    talkingPointInput.addEventListener("keyup", this.submitOnEnter);
  }

  componentWillUnmount() {
    const talkingPointInput = document.getElementById("talkingPointInput");
    talkingPointInput.removeEventListener("keyup", this.submitOnEnter);
  }

  submitOnEnter = event => {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.props.addTalkingPoint();
    }
  };

  render() {
    const {
      warning, value, onChange, addTalkingPoint,
    } = this.props;
    return (
      <div id="talkingPointInput" className="battlecardForm__tpContainer">
        <div className="battlecardForm__tpInputContainer">
          <span className="battlecardForm__label">Talking point</span>
          <div className="battlecardForm__tpContainer__inputRow">
            <div className="battlecardForm__tpContainer__input">
              {warning && (
                <div className="battlecardForm__tpContainer__input__warningContainer">
                  <span className="battlecardForm__warning">
                    <img className="battlecardForm__warning-icon" src={WarningIcon} alt="" />

                    {"Exceeded limit"}
                  </span>
                </div>
              )}
              <div
                className={
                  warning ? "battlecardForm__errorInputContainer" : "battlecardForm__inputContainer"
                }
              >
                <InputBase className="battlecardForm__input" value={value} onChange={onChange} />
              </div>
            </div>
            <Button  variant="outlined" onClick={addTalkingPoint}>
              {"Add"}
            </Button>
          </div>
          <span className="battlecardForm__helper">
            {"Talking points work better when they are as brief as possible."}
            <br />

            {"Tip: Limit a battle card to 5-7 talking points."}
            <br />

            {"Eg: ‘Salesforce integration available on enterprise plan’"}
          </span>
        </div>
      </div>
    );
  }
}

TalkingPointInput.propTypes = {};

export default TalkingPointInput;
