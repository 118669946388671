import React, { useEffect, useMemo, useState } from 'react'
import ReactTooltip from 'react-tooltip';
import { _getActivitiesByDate, _getActivityForDateDealView, _getActivityTypeScore, _getTooltipPlacement } from '../../utils/ActivityHelper';
import { _formatDateToCallCardDateFormat, _getActivityTypes } from '../../utils/helpers';
import ActivityBarDealView from './ActivityBarDealView';


const Activity = ({ deal }) => {
    const [enableHover, setEnableHover] = useState(false);
    const [activitiesByDate, setActivitiesByDate] = useState(_getActivitiesByDate(deal.activity, 14));

    useEffect(() => {
        setTimeout(() => setEnableHover(true), 500)
    }, []);

    useEffect(() => {
        let activitiesByDateLocal = _getActivitiesByDate(deal.activity, 14, 'deal');
        setActivitiesByDate(activitiesByDateLocal);
    }, [deal.activity])

    useMemo(() => {
        let activitiesByDateLocal = _getActivitiesByDate(deal.activity, 14);
        setActivitiesByDate(activitiesByDateLocal);
    }, [deal.activity])


    return (
        <div style={{ display: "flex", position: "relative" }}>
            <div className="DealCard__originLine"></div>
            {
                activitiesByDate.map((activityForOneDay, i) => (
                    <div key={"ActivityTip" + deal.id + i}>
                        <div data-tip data-for={"ActivityTip" + deal.id + i} >
                            <ActivityBarDealView activityForOneDay={_getActivityForDateDealView(activityForOneDay)} />
                        </div>
                        {enableHover && <ReactTooltip place="right" id={"ActivityTip" + deal.id + i} type="light" effect="solid" className="WhiteTooltip WhiteTooltip__noPadding">
                            <ActivityTooltip activityForOneDay={activityForOneDay} view="deal" />
                        </ReactTooltip>}
                    </div>
                ))
            }
        </div>
    );
}

export const ActivityTooltip = ({ activityForOneDay, view }) => {
    const activityTypes = _getActivityTypes();
    const dealViewActivityTypes = _getActivityTypes('dealView');

    let repAndCustomerActivity;
    if (view === "deal") repAndCustomerActivity = _getActivityForDateDealView(activityForOneDay);

    return (
        <div className="ActivityTooltip">
            <div className="ActivityTooltip__heading">
                <span className="ActivityTooltip__headingText">{_formatDateToCallCardDateFormat(activityForOneDay.date)}</span>
            </div>
            <div className="ActivityTooltip__body">
                {view === "deal" && (
                    <div className="ActivityTooltip__item">
                        {
                            Object.keys(dealViewActivityTypes).map((activityType, i) => (
                                <div key={dealViewActivityTypes[activityType].label + "__" + i} className="ActivityTooltip__activityColumn">
                                    <div className="ActivityTooltip__valueRow">
                                        <div className={"ActivityTooltip__valueCircle ActivityTooltip__value" + dealViewActivityTypes[activityType].label.split(" ")[0] + "Color"}></div>
                                        <span className="ActivityTooltip__label">{dealViewActivityTypes[activityType].label}</span>
                                    </div>
                                    <div className="ActivityTooltip__valueRow">
                                        <span className={"ActivityTooltip__score ActivityTooltip__score" + _getActivityTypeScore(repAndCustomerActivity[activityType])}>{_getActivityTypeScore(repAndCustomerActivity[activityType])}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )}
                <div className="ActivityTooltip__item">
                    {
                        Object.keys(activityTypes).map((activityType, i) => (
                            <div key={activityTypes[activityType].label + "__" + i} className="ActivityTooltip__activityColumn">
                                <div className="ActivityTooltip__valueRow">
                                    <img className={"ActivityTooltip__icon"} src={activityTypes[activityType].icon} />
                                    <span className="ActivityTooltip__label ActivityTooltip__labelSmall">{activityTypes[activityType].label}</span>
                                    <span className="ActivityTooltip__value ActivityTooltip__valueRight">{activityForOneDay[activityType].length}</span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>

        </div>
    );
}

Activity.propTypes = {}
Activity.defaultProps = {}

export default Activity;