import React, { useState } from 'react';
import webConfInfo from "app/styles/assets/images/explainers/web_conf_info.svg";
import dialerInfo from "app/styles/assets/images/explainers/dialer_info.svg";
import crmInfo from "app/styles/assets/images/explainers/crm_info.svg";
import { withRouter } from "react-router-dom";
import CompleteIntegrations from 'app/components/onboarding/CompleteIntegrations';
import { Button } from '../form';
import { useSelector } from "react-redux";

const EmptyStateTeams = ({ integrationsCompleted, history }) => {

	const screenName = useSelector(store => store.screen.screenName);
	const auth = useSelector(store => store.auth);

	function goToInviteUsers() {
		history.push({
			pathname: "/settings",
			hash: "users",
		})
	}

	return (
		<div className="EmptyState__container">
			<div className="EmptyState__noCallsContainer">
				<span className="mainLine">Your team has no calls.</span>
				{integrationsCompleted &&
					<span className="subLine">
						{screenName.indexOf("Dashboard") !== -1 ? "Relevant stats of your team's calls will automatically show up here." : "Relevant calls of your team will automatically show up here."}</span>
				}
				{auth.isAdmin && <Button onClick={goToInviteUsers} theme="dark">Invite Users</Button>}
				{auth.isAdmin && !integrationsCompleted && <CompleteIntegrations admin />}

			</div>

			<div className="EmptyState__explainerContainer SPaper">
				<span className="EmptyState__subHeader">How does Wingman record and analyse calls?</span>
				<div className="EmptyState__explainerContainer-imageContainer">
					<div className="singleContent">
						<img src={crmInfo} className="graphic" />
						<span className="mainLine">Automatic Crm Sync</span>
						<span className="subLine">Wingman will automatically link calls to your CRM. This allows managers to listen and analyse calls based on CRM fields like deal stage, deal amount and other CRM fields.
						</span>
					</div>
					<div className="singleContent">
						<img src={dialerInfo} className="graphic" />
						<span className="mainLine">Automatic Dialer Calls Recordings</span>
						<span className="subLine">Wingman will download and process call recordings from supported dialer systems if they have been integrated. Always make sure that the integrations are done to proceed.</span>
					</div>
					<div className="singleContent">
						<img src={webConfInfo} className="graphic" />
						<span className="mainLine">Automatic Video Conference Recordings</span>
						<span className="subLine">Wingman will join and record video conference meetings that support video conference link in the meeting description or location and have at-least one external participant (an email address not from your organization).</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(EmptyStateTeams);
