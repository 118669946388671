import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { signUp, googleSignup, verifyEmail } from "app/utils/network";
import { setUserData } from "app/actions/auth";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CONFIG } from "app/config";
import { Input, Label } from "app/components/form";
import { Button, Text2XL, TextBase } from "app/designSystem"
import { ReactComponent as GoogleIcon } from "app/styles/assets/images/icons/googleLoginButton.svg";
import { emailRegexPattern, isWordMatchingThePattern } from "../../utils/helpers";
import PasswordValidator from "./PasswordValidator";

const SignUp = (props) => {
  const dispatch = useDispatch()
  const { setSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  const [showSecondForm, setShowSecondForm] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: props?.email,
    password: "",
  });

  useEffect(() => {
    verifyJWT();
  }, [])

  const submitOnEnter = (event) => {
    setError(null)
    event.preventDefault();
    if (event.keyCode === 13)
      signupHandler();
  }

  const onChangeHandler = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  }

  const validateInput = () => {
    const { name, email, password } = formData;

    if (!email || email.trim() === "" || !isWordMatchingThePattern(email, emailRegexPattern)) {
      setError({ type: "email", message: "Please enter a valid email." })
      return false
    }
    if (!name || name.trim() === "") {
      setError({ type: "name", message: "Please enter your name." })
      return false
    }
    if (!password || password.trim() === "" || !isValidPassword) {
      setError({ type: "password", message: "Please enter a valid password." })
      return false
    }
    return true;
  }

  const signupHandler = async (e) => {
    e?.preventDefault();
    setLoading(true);
    if (validateInput()) {
      try {
        const { name, email, password } = formData;
        const response = await signUp(email, password, name);
        if (response.success) {
          const user = response;
          dispatch(setUserData(user))
        } else {
          setSnackbar(response.data.message, "ERROR", 5000);
        }
      } catch (e) {
        console.log(e);
      }
    }
    setLoading(false);
  }

  const googleLoginHandler = async (response) => {
    setLoading(true);
    const data = await googleSignup(
      response.profileObj.name,
      response.profileObj.email,
      response.profileObj.imageUrl,
      response.tokenObj.id_token,
    );
    if (data?.error) {
      setSnackbar(data?.message ?? "Error signing up. Please contact support.", "ERROR");
      setLoading(false);
      return;
    }
    dispatch(setUserData(data))
    setLoading(false);
  }

  const googleErrorHandler = (error) => {
    console.log("error from google signup is:", error, JSON.stringify(error));
    if (error && error.error === "idpiframe_initialization_failed" && error.details && error.details.includes("Cookies"))
      setSnackbar("Looks like you have disabled cookies for this site. Please enable them to login.", "ERROR");
    else
      setSnackbar("There was an error signing in via google", "ERROR");
  }

  const verifyJWT = () => {
    const param = new URLSearchParams(window.location.search);
    const jwt = param.get('jwt');
    console.log("jwt", jwt);
    if (jwt !== null) {
      console.log("sending verification mail");
      verifyEmail(jwt);
    }
  }

  return (
    <div onKeyUp={submitOnEnter} className="LoginForm" id="signUpForm">
      <Text2XL textColor="text-coolGray-800">Welcome to Wingman</Text2XL>
      <TextBase textColor="text-coolGray-500 mb-3">Let&#39;s get you started</TextBase>
      {
        showSecondForm ? <SignUpForm signupHandler={signupHandler} setIsValidPassword={setIsValidPassword} error={error} loading={loading} formData={formData} onChangeHandler={onChangeHandler} />
          : <SignUpWithGoogleForm googleLoginHandler={googleLoginHandler} googleErrorHandler={googleErrorHandler} loading={loading} setShowSecondForm={setShowSecondForm} />
      }

      <div className="m-1 text-gray-400 text-sm mx-auto">Already have an account?
        <Link className="text-wingman-brand cursor-pointer font-medium" onClick={() => props.setLoginState("SignIn")}> Sign in </Link>
      </div>
    </div>
  );
}

const SignUpFooter = () => {
  return (
    <span className="LoginForm__disclaimer text-sm">
      {"By signing up for a Wingman account you are agreeing to the "}
      <a className="text-wingman-brand" target="_blank" href="https://www.trywingman.com/privacy-policy" rel="noopener noreferrer">Privacy Policy</a>
      {" and "}
      <a className="text-wingman-brand" target="_blank" href="https://www.trywingman.com/terms-of-service" rel="noopener noreferrer">Terms of Service</a>.
    </span>
  )
}

const SignUpWithGoogle = ({ onSuccess, onFailure }) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_CONFIG.clientId}
      render={renderProps => (
        <Button
          className="w-full"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <div className="p-1 mr-3 bg-white rounded-sm">
            <GoogleIcon className="w-4 h-4" />
          </div>
          <span>
            Sign up with Google
          </span>
        </Button>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
    />
  )
}

const SignUpWithGoogleForm = ({ googleLoginHandler, googleErrorHandler, loading, setShowSecondForm }) => {
  return (
    <div>
      <div className="my-1">
        <SignUpWithGoogle onSuccess={googleLoginHandler} onFailure={googleErrorHandler} />
      </div>
      <div className="specialDivider">OR</div>
      <Button
        id="continue"
        variant="secondary"
        className="w-full"
        loading={loading}
        onClick={() => setShowSecondForm(true)}>
        {"Sign up with email"}
      </Button>
      <div className="my-7 flex justify-center">
        <SignUpFooter />
      </div>
    </div>
  )
}

const SignUpForm = ({ signupHandler, setIsValidPassword, error, loading, formData, onChangeHandler }) => {
  const { name, email, password } = formData;

  return (
    <form onSubmit={signupHandler}>
      <div className="inputField">
        <Label>Name</Label>
        <Input
          placeholder="Enter your name"
          value={name}
          className="p-0 h-full w-full"
          onChange={e => onChangeHandler("name", e.target.value)}
          warning={error?.type === "name"}
          warningMessage={error?.message}
          autoFocus
          name="name"
        />
      </div>
      <div className="inputField">
        <Label>Email</Label>
        <Input
          placeholder="Enter your email"
          value={email}
          className="p-0 h-full w-full"
          onChange={e => onChangeHandler("email", e.target.value)}
          warning={error?.type === "email"}
          warningMessage={error?.message}
          name="email"
        />
      </div>
      <div className="inputField">
        <Label>Password</Label>
        <Input
          placeholder="Enter your password"
          value={password}
          className="p-0 h-full w-full"
          onChange={e => onChangeHandler("password", e.target.value)}
          type="password"
          warning={error?.type === "password"}
          warningMessage={error?.message}
          autoComplete="current-password"
          name="password"
        />
      </div>
      <PasswordValidator
        password={password}
        setIsValidPassword={setIsValidPassword}
      />
      <Button
        id="signup"
        className="mt-6 w-full"
        loading={loading}
        onClick={signupHandler}>
        {"Sign up"}
      </Button>
      <div className="my-6 flex justify-center">
        <SignUpFooter />
      </div>
    </form>
  )
}

export default SignUp;
