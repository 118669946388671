import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { TextXL, TextBase, Button } from 'app/designSystem'
import { PlayAllIcon, EmptyGametapeViewImage } from 'app/designSystem/icons'
import { GameTapeCard } from 'app/components/gameTapesLatest'
import { GameTapeReorderModal, ClipboardActionsModal, CreateGameTapeForm } from 'app/components/gameTapesLatest/modal'
import { getGameTapes, updateOrder, removeGameTape } from 'app/utils/network/gametapes'
import { HeaderSkeleton, FolderContainerSkeleton } from 'app/components/gameTapesLatest/skeleton'
import useSnackbar from "app/utils/hooks/useSnackbar";
import { setGameTapesGameTapesChildrenData } from 'app/actions/gametapes'
import metrics from 'app/utils/metrics';
import PropTypes from 'prop-types';

const GameTapesContainer = ({ folderId }) => {
    const dispatch = useDispatch();

    const { setSnackbar } = useSnackbar();
    const { cachedGametapesChildrenData } = useSelector(state => state.gametapes);

    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [updateFormModalOpen, setUpdateFormModalOpen] = useState(false);
    const [copyGameTapeModalOpen, setCopyGameTapeModalOpen] = useState(false);
    const [moveGameTapeModalOpen, setMoveGameTapeModalOpen] = useState(false);
    const [selectedGametape, setSelectedGametape] = useState(null);

    useEffect(() => {
        if (folderId) {
            browseGameTape(folderId);
        }
    }, [folderId])

    const browseGameTape = useCallback(async (folderId) => {
        try {
            if (cachedGametapesChildrenData[folderId]) {
                setLoading(false);
            } else {
                setLoading(true);
            }

            const result = await getGameTapes({ folderId })
            if (result) {
                dispatch(setGameTapesGameTapesChildrenData(folderId, result));
            };
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }, [cachedGametapesChildrenData])

    const updateOrderOfTapes = useCallback(async ({ filesList }) => {
        setLoading(true);
        dispatch(setGameTapesGameTapesChildrenData(folderId, filesList));
        updateOrder({ folderId, orderList: filesList.map(e => e.id) });
        setSnackbar("Order updated successfully", "SUCCESS");
        setLoading(false);
    }, [folderId])

    const removeGameTapeHandler = useCallback(async (folderId, gametapeId) => {
        setLoading(true);

        try {
            await removeGameTape({ folderId, gametapeId });
            dispatch(setGameTapesGameTapesChildrenData(folderId, cachedGametapesChildrenData[folderId]?.filter(e => e.id !== gametapeId)));
            setSnackbar("Game tape removed successfully", "SUCCESS");
            browseGameTape(folderId);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }, [folderId, cachedGametapesChildrenData])

    const copyGameTapeHandler = useCallback(() => {
        browseGameTape(folderId);
        setSnackbar("Game tape copied successfully", "SUCCESS");
    }, [folderId])

    const moveGameTapeHandler = useCallback((gametapeId) => {
        dispatch(setGameTapesGameTapesChildrenData(folderId, cachedGametapesChildrenData[folderId]?.filter(e => e.id !== gametapeId)));
        browseGameTape(folderId);
        setSnackbar("Game tape moved successfully", "SUCCESS");
    }, [folderId])

    const handleModalClose = () => {
        setOpen(false);
        setUpdateFormModalOpen(false);
        setCopyGameTapeModalOpen(false);
        setMoveGameTapeModalOpen(false);
        setSelectedGametape(null);
    }

    const handleCreateAction = (action) => {
        handleModalClose();

        if (action === "create") {
            browseGameTape(folderId);
        } else if (action === "copy") {
            copyGameTapeHandler()
        } else if (action === "move") {
            moveGameTapeHandler(selectedGametape.id);
        }
    }

    if (loading) {
        return (
            <div className="flex flex-col">
                <HeaderSkeleton />
                <FolderContainerSkeleton />
            </div>
        )
    }

    if (cachedGametapesChildrenData[folderId]?.length > 0) {
        return (
            <div>
                <div className="flex flex-row justify-between px-4 py-2">
                    <TextXL className="text-gray-600">Gametapes</TextXL>
                    <div className="flex items-center">
                        <Button id="reorder_tapes_btn" variant="secondary" className="h-10" onClick={() => setOpen(true)}>Rearrange</Button>
                        <Link to={`/gametapes/video/${cachedGametapesChildrenData[folderId]?.[0]?.id}`} onClick={() => metrics.logEventHeap("playAllTapes")}>
                            <Button startIcon={<PlayAllIcon />} className="h-10 ml-2">Play All</Button>
                        </Link>
                    </div>
                </div>

                <div className="flex flex-row flex-wrap">
                    {cachedGametapesChildrenData[folderId]?.map((item) => {
                        return (
                            <GameTapeCard
                                key={item.id}
                                data={item.gametape}
                                stats={item.stats}
                                actions={{
                                    onEdit: () => {
                                        setUpdateFormModalOpen(true);
                                        setSelectedGametape(item.gametape);
                                    },
                                    onRemove: () => removeGameTapeHandler(folderId, item.id),
                                    onCopy: () => {
                                        setCopyGameTapeModalOpen(true);
                                        setSelectedGametape(item.gametape);
                                    },
                                    onMove: () => {
                                        setMoveGameTapeModalOpen(true);
                                        setSelectedGametape(item.gametape);
                                    },
                                }}
                            />
                        );
                    })}

                    <GameTapeReorderModal folderId={folderId} updateOrderOfTapes={updateOrderOfTapes} modalOpen={open} setOpen={setOpen} />
                    {updateFormModalOpen && <CreateGameTapeForm open={updateFormModalOpen} data={selectedGametape} closeModal={handleModalClose} onCreate={() => handleCreateAction("create")} />}
                    {copyGameTapeModalOpen && <ClipboardActionsModal action={"Copy"} type={"Gametape"} open={copyGameTapeModalOpen} gametape={selectedGametape} onClose={handleModalClose} onCreate={() => handleCreateAction("copy")} />}
                    {moveGameTapeModalOpen && <ClipboardActionsModal action={"Move"} type={"Gametape"} open={moveGameTapeModalOpen} gametape={selectedGametape} onClose={handleModalClose} onCreate={() => handleCreateAction("move")} />}
                </div>
            </div >
        )
    }

    return (
        <div>
            <div className="flex flex-row justify-between px-4 py-2">
                <TextXL className="text-gray-600">Gametapes</TextXL>
            </div>
            <div className="flex items-center justify-center w-full flex-col h-72">
                <EmptyGametapeViewImage className="my-5 h-full" />
                <TextXL>No gametapes found</TextXL>
                <TextBase textColor="text-gray-400">You can add a gametape by clicking the Create Gametape button on Call review page.</TextBase>
            </div>
        </div>
    )
}

export default GameTapesContainer;

GameTapesContainer.propTypes = {
    folderId: PropTypes.string,
}
