export const GET_CALL_DETAILS = "GET_CALL_DETAILS";
export const GET_CALLS = "GET_CALLS";
export const SEEK_PLAYBACK_POSITIONS = "SEEK_PLAYBACK_POSITIONS";
export const USER = "USER";
export const REFRESH_USER = "REFRESH_USER";
export const ERROR = "ERROR";
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_PUBLISHERS = "GET_PUBLISHERS";
export const GET_ONBOARDING_SKIPPED = "GET_ONBOARDING_SKIPPED";
export const NAVIGATE = "NAVIGATE";
export const LOADING = "LOADING";
export const EXPAND_SIDEBAR = "EXPAND_SIDEBAR";
export const SCREEN_NAME = "SCREEN_NAME";
export const SCREEN_TYPE = "SCREEN_TYPE";
export const STACKED_SCREEN_NAME = "STACKED_SCREEN_NAME";
export const SET_ONBOARDING_STATUS = "SET_ONBOARDING_STATUS";
export const SET_FIRST_URL = "SET_FIRST_URL";
export const SET_MANAGER_DASHBOARD_FILTER = "SET_MANAGER_DASHBOARD_FILTER";
export const SET_AGENT_DASHBOARD_FILTER = "SET_AGENT_DASHBOARD_FILTER";
export const SET_USER_DASHBOARD_FILTER = "SET_USER_DASHBOARD_FILTER";
export const SET_SCORECARDS_DASHBOARD_FILTER = "SET_SCORECARDS_DASHBOARD_FILTER"
export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const SET_USER_CUSTOM_DATES = "SET_USER_CUSTOM_DATES";
export const SET_MANAGER_CUSTOM_DATES = "SET_MANAGER_CUSTOM_DATES";
export const SET_MANAGER_FILTER_START_DATE = "SET_MANAGER_FILTER_START_DATE";
export const SET_MANAGER_FILTER_END_DATE = "SET_MANAGER_FILTER_END_DATE";
export const SET_USER_FILTER_START_DATE = "SET_USER_FILTER_START_DATE";
export const SET_USER_FILTER_END_DATE = "SET_USER_FILTER_END_DATE";
export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
export const SET_ACTIVE_TIME = "SET_ACTIVE_TIME";
export const SET_CALL_LOG_FILTER = "SET_CALL_LOG_FILTER";
export const SET_SEARCH_TERM = "SET_SEARCH_TERM";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SHOW_SEARCH_RESULTS = "SHOW_SEARCH_RESULTS";
export const SET_PLAYING = "SET_PLAYING";
export const SET_FILTERED_CALLS = "SET_FILTERED_CALLS";
export const SET_ANY_USER_CALLS = "SET_ANY_USER_CALLS";
export const SET_ANY_CUSTOMER_CALLS = "SET_ANY_CUSTOMER_CALLS";
export const SET_META_DATA = "SET_META_DATA";
export const SET_SETTINGS_TAB = "SET_SETTINGS_TAB";
export const SET_UPCOMING_CALLS = "SET_UPCOMING_CALLS";
export const SET_PAST_CALLS = "SET_PAST_CALLS";
export const SET_BATTLECARDS = "SET_BATTLECARDS";
export const SET_AGENTS_DATA = "SET_AGENTS_DATA";
export const SET_TRACKERS_DATA = "SET_TRACKERS_DATA";
export const SET_MANAGER_DASHBOARD_SORT_DATA = "SET_MANAGER_DASHBOARD_SORT_DATA";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SET_SNACKBAR = "SET_SNACKBAR";
export const SET_ADD_COMMENT_EXPANDED = "SET_ADD_COMMENT_EXPANDED";
export const SET_ADD_NOTE_EXPANDED = "SET_ADD_NOTE_EXPANDED";
export const SET_FILTERED_CALLS_IF_EMPTY = "SET_FILTERED_CALLS_IF_EMPTY";
export const SET_USER_PREFERENCES = "SET_USER_PREFERENCES";
export const SET_DEFAULT_MY_CALL_TAB = "SET_DEFAULT_MY_CALL_TAB";
export const SET_LAST_RELOAD = "SET_LAST_RELOAD";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_FULLSCREEN_VIDEO = "SET_FULLSCREEN_VIDEO";
export const SET_AUDIO_SOURCE = "SET_AUDIO_SOURCE";
export const SET_ONE_TIME_OPEN = "SET_ONE_TIME_OPEN";
export const UPDATE_RECORDING = "UPDATE_RECORDING";
export const SHOW_UNRECORDED_IN_MY_CALLS_PAGE = "SHOW_UNRECORDED_IN_MY_CALLS_PAGE";
export const SET_CALL_LOG_FILTER_ID = "SET_CALL_LOG_FILTER_ID";
export const SET_DASHBOARD_FILTER_ID = "SET_DASHBOARD_FILTER_ID";
export const SET_SCORECARDS_DASHBOARD_FILTER_ID = " SET_SCORECARDS_DASHBOARD_FILTER_ID"
export const SET_TEAM_DASHBOARD_DATA = "SET_TEAM_DASHBOARD_DATA";
export const SET_SCORECARDS_DASHBOARD_DATA = "SET_SCORECARDS_DASHBOARD_DATA";
export const SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA = "SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA";
export const SET_TEAM_DASHBOARD_TRACKERS_DATA = "SET_TEAM_DASHBOARD_TRACKERS_DATA";
export const SET_DEALS = "SET_DEALS";
export const SET_DEAL_CENTRAL_PAGE = "SET_DEAL_CENTRAL_PAGE";
export const SET_DEALS_FILTER = "SET_DEALS_FILTER";
export const HIDE_VP_WARNING = "HIDE_VP_WARNING";
export const HIDE_TRIAL_END_DIALOGUE = "HIDE_TRIAL_END_DIALOGUE";
export const SET_SELECTED_SPEAKERS = "SET_SELECTED_SPEAKERS";
export const SET_SHOW_SHARE_CALL_MODAL = "SET_SHOW_SHARE_CALL_MODAL";
export const SET_SHOW_ADD_TO_GAMETAPE_MODAL = "SET_SHOW_ADD_TO_GAMETAPE_MODAL";
export const SET_TRANSCRIPT_SELECTED = "SET_TRANSCRIPT_SELECTED";
export const SET_VIDEO_MINIMIZE = "SET_VIDEO_MINIMIZE";
export const SET_MATCHING_HIGHLIGHTS = "SET_MATCHING_HIGHLIGHTS";
export const SET_SELECTED_SEARCH_OPTION = "SET_SELECTED_SEARCH_OPTION";
export const SET_SHARE_DETAILS = "SET_SHARE_DETAILS";
export const SET_SELECTED_HIGHLIGHT = "SET_SELECTED_HIGHLIGHT";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const UPDATE_CALL = "UPDATE_CALL";
export const SET_MANAGER_DEAL_INSIGHTS_FILTER = "SET_MANAGER_DEAL_INSIGHTS_FILTER";
export const SET_CALL_LOG_FILTER_AND_REFRESH = "SET_CALL_LOG_FILTER_AND_REFRESH";
export const SET_DEAL_BOARD = "SET_DEAL_BOARD";
export const SET_GAMETAPES_FOLDER_DATA = "SET_GAMETAPES_FOLDER_DATA";
export const SET_GAMETAPES_GAMETAPES_DATA = "SET_GAMETAPES_GAMETAPES_DATA";
export const SET_GAMETAPES_ALL_FOLDER_DATA = "SET_GAMETAPES_ALL_FOLDER_DATA";
export const SET_GAMETAPES_ALL_GAMETAPES_DATA = "SET_GAMETAPES_ALL_GAMETAPES_DATA";
export const SET_GAMETAPES_FOLDER_CHILDREN_DATA = "SET_GAMETAPES_FOLDER_CHILDREN_DATA";
export const SET_GAMETAPES_GAMETAPES_CHILDREN_DATA = "SET_GAMETAPES_GAMETAPES_CHILDREN_DATA";
export const SET_GAMETAPES_TAGS_DATA = "SET_GAMETAPES_TAGS_DATA";
export const SET_ACTIVE_ROLLUP = "SET_ACTIVE_ROLLUP";
export const SET_ACTIVE_DEALBOARD = "SET_ACTIVE_DEALBOARD";
export const SET_SCORING_SKILLS = "SET_SCORING_SKILLS";
export const SET_POPULAR_TEMPLATES = "SET_POPULAR_TEMPLATES";
export const SET_CREATE_FOLDER_MODAL_OPEN = "SET_CREATE_FOLDER_MODAL_OPEN";
export const UPDATE_SCORECARD_COUNT = "UPDATE_SCORECARD_COUNT"

export default {
  GET_CALL_DETAILS,
  SEEK_PLAYBACK_POSITIONS,
  USER,
  GET_CALLS,
  ERROR,
  GET_CAMPAIGNS,
  GET_CLIENTS,
  GET_PUBLISHERS,
  GET_ONBOARDING_SKIPPED,
  NAVIGATE,
  LOADING,
  EXPAND_SIDEBAR,
  SCREEN_NAME,
  SCREEN_TYPE,
  STACKED_SCREEN_NAME,
  REFRESH_USER,
  SET_ONBOARDING_STATUS,
  SET_FIRST_URL,
  SET_USER_DASHBOARD_FILTER,
  SET_MANAGER_DASHBOARD_FILTER,
  ADMIN_LOGIN,
  SET_USER_CUSTOM_DATES,
  SET_MANAGER_CUSTOM_DATES,
  SET_MANAGER_FILTER_START_DATE,
  SET_MANAGER_FILTER_END_DATE,
  SET_USER_FILTER_START_DATE,
  SET_USER_FILTER_END_DATE,
  SET_ACTIVE_FILTER,
  SET_ACTIVE_TIME,
  SET_CALL_LOG_FILTER,
  SET_SEARCH_TERM,
  SET_SEARCH_RESULTS,
  SHOW_SEARCH_RESULTS,
  SET_PLAYING,
  SET_AGENT_DASHBOARD_FILTER,
  SET_FILTERED_CALLS,
  SET_FILTERED_CALLS_IF_EMPTY,
  SET_ANY_CUSTOMER_CALLS,
  SET_ANY_USER_CALLS,
  SET_META_DATA,
  SET_SETTINGS_TAB,
  SET_UPCOMING_CALLS,
  SET_PAST_CALLS,
  SET_BATTLECARDS,
  SET_AGENTS_DATA,
  SET_TRACKERS_DATA,
  SET_MANAGER_DASHBOARD_SORT_DATA,
  HIDE_SNACKBAR,
  SET_SNACKBAR,
  SET_ADD_NOTE_EXPANDED,
  SET_ADD_COMMENT_EXPANDED,
  SET_USER_PREFERENCES,
  SET_DEFAULT_MY_CALL_TAB,
  SET_LAST_RELOAD,
  SET_FULLSCREEN_VIDEO,
  SET_AUDIO_SOURCE,
  SET_ONE_TIME_OPEN,
  UPDATE_RECORDING,
  SHOW_UNRECORDED_IN_MY_CALLS_PAGE,
  SET_TRANSCRIPT_SELECTED,
  SET_CALL_LOG_FILTER_ID,
  SET_DASHBOARD_FILTER_ID,
  SET_DEALS,
  SET_DEAL_CENTRAL_PAGE,
  SET_DEALS_FILTER,
  HIDE_VP_WARNING,
  HIDE_TRIAL_END_DIALOGUE,
  SET_SELECTED_SPEAKERS,
  SET_VIDEO_MINIMIZE,
  SET_MATCHING_HIGHLIGHTS,
  SET_SELECTED_SEARCH_OPTION,
  SET_SHARE_DETAILS,
  SET_SELECTED_HIGHLIGHT,
  SET_ACTIVE_TAB,
  UPDATE_CALL,
  SET_SHOW_ADD_TO_GAMETAPE_MODAL,
  SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA,
  SET_MANAGER_DEAL_INSIGHTS_FILTER,
  SET_CALL_LOG_FILTER_AND_REFRESH,
  SET_DEAL_BOARD,
  SET_ACTIVE_ROLLUP,
  SET_ACTIVE_DEALBOARD,
  SET_GAMETAPES_FOLDER_DATA,
  SET_GAMETAPES_GAMETAPES_DATA,
  SET_GAMETAPES_ALL_FOLDER_DATA,
  SET_GAMETAPES_ALL_GAMETAPES_DATA,
  SET_GAMETAPES_FOLDER_CHILDREN_DATA,
  SET_GAMETAPES_GAMETAPES_CHILDREN_DATA,
  SET_SCORING_SKILLS,
  SET_POPULAR_TEMPLATES,
  SET_GAMETAPES_TAGS_DATA,
  SET_CREATE_FOLDER_MODAL_OPEN
};