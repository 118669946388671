import React, { useState } from "react";
import { Warning, Text2XL, TextBase, NotificationNudge } from "app/designSystem";
import { Input, Label } from "app/components/form";
import { Button } from "app/designSystem";
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { checkEmailId } from "app/utils/network";


const ImageReturn = () => {
    return (<Warning></Warning>);
}

const LoginCheck = (props) => {
    const { email, setEmail, loading, setLoading, ssoLoginHandler, setLoginState, setData } = props;
    const { setSnackbar } = useSnackbar();

    const [warning, setWarning] = useState(false);
    const [adminContactError, setAdminContactError] = useState(false);

    const handleResponse = (result) => {
        const { sso, signup, login } = result;

        if (sso && signup) {
            setLoginState("SignUpWithProvider");
            setData(result);
            return;
        }

        if (sso) {
            ssoLoginHandler(email);
        }
        else if (login) {
            setLoginState("SignIn");
        }
        else {
            setLoginState("SignUp");
        }
    }

    const checkCustomerDomain = async (e) => {
        e?.preventDefault();
        setLoading(true);
        setAdminContactError(false);

        if (email.trim().length === 0) {
            setWarning(true);
            return;
        }

        const response = await checkEmailId(email.trim());
        setLoading(false)

        if (response?.error) {
            if (response.status === 403) setAdminContactError(response?.message)
            else setSnackbar(response?.message, "ERROR");
        }
        else {
            const { result } = response;
            handleResponse(result);
        }
    }

    const handleChange = (e) => {
        setEmail(e.target.value);
        setWarning(false);
    }

    return (
        <div className="LoginForm" id="loginForm">
            <Text2XL textColor="text-coolGray-800">Welcome</Text2XL>
            <TextBase textColor="text-coolGray-500 mb-3">Please enter your details</TextBase>
            <form onSubmit={checkCustomerDomain}>
                <div className="flex flex-col justify-between mt-3 h-15">
                    <Label>Email Address</Label>
                    <Input
                        placeholder="Enter your email"
                        value={email}
                        className="p-0 h-full w-full"
                        onChange={(e) => handleChange(e)}
                        warning={warning}
                        warningMessage={"Enter an Email"}
                        name="email"
                        autoFocus
                    />
                </div>
                <Button
                    loading={loading}
                    id="login"
                    className="mt-6 w-full"
                    onClick={checkCustomerDomain}
                    type="submit"
                    disabled={email.trim().length === 0}
                >
                    {"Continue"}
                </Button>
            </form>

            {adminContactError &&
                <NotificationNudge Svg={ImageReturn}>
                    <p className="text-orange-700 whitespace-pre-wrap">
                        {adminContactError}
                    </p>
                </NotificationNudge>
            }
        </div>
    );
};

export default LoginCheck;
