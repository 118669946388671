import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import notification from "app/styles/assets/images/icons/notification.png";
import search from "app/styles/assets/images/icons/Search.png";
import { ProfileDropdown } from "app/components/layout";
import back from "app/styles/assets/images/icons/Back.png";
import { Link } from "react-router-dom";
import { withLastLocation } from "react-router-last-location";

const HeaderStacked = props => {
  const screenName = props.screen.stackedScreenName;
  function goBack() {
    if (props.lastLocation) {
      const value = props.history.goBack();
    } else {
      props.history.push("/");
    }
  }

  return (
    <div className="headerProduct">
      <div className="header__left">
        <div onClick={goBack} className="headerProduct__button">
          <img className="headerProduct__hamburger back-png" src={back} alt="back" />
        </div>
        <span className="headerProduct__title">{screenName}</span>
      </div>
      <div className="header__right">
        <div className="headerProduct__button">
          <ProfileDropdown />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

HeaderStacked.propTypes = {};

HeaderStacked.defaultProps = {};

export default connect(
  mapStateToProps,
  {},
)(withLastLocation(withRouter(HeaderStacked)));
