import React, { useState, useRef, useEffect } from 'react';
import { TextBase, TextLarge, TextXL, Button, RoundedPlusIcon, LinkToPageIcon, CloseIcon, ArrowLeftIcon } from 'app/designSystem'
import { Input } from 'app/designSystem/form';
import { sendRequest } from "app/utils/network";
import useSnackbar from '../utils/hooks/useSnackbar';
import { Modal } from "@material-ui/core";
import useScorecardHelper from './helper';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getPopularTemplates } from '../actions/scorecards';
import { metrics } from "app/utils/metrics";
import TemplateCard from './TemplateCard';
import { CardSkeleton } from 'app/components/common/skeleton';
import Skeleton from 'react-loading-skeleton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ScorecardQuestion from './ScorecardQuestion';


const CreateTemplateModal = ({ open, setOpen, onSubmit, template }) => {
    const dispatch = useDispatch();
    const { setSnackbar } = useSnackbar();
    const { getNewTemplateObject } = useScorecardHelper()
    const popularTemplates = useSelector(state => state.scorecards.popularTemplates);
    const [scoringTemplate, setScoringTemplate] = useState(null)//this is the template that is mutated in the UI and set in api finally
    const existingSkillsAcrossTemplates = useSelector(state => state?.scorecards?.skills)
    const [skillOptions, setSkillOptions] = useState([])
    const [submitLoading, setSubmitLoading] = useState(false)
    const modalContentRef = useRef()
    const [uiMode, setUiMode] = useState('LIST') //UI LIST view or inside TEMPLATE view showing questions /skills ( new or from popular or edit)
    const [questionsList, setQuestionsList] = useState([])

    useEffect(() => {

        if (scoringTemplate) {
            setQuestionsList(scoringTemplate?.questions || [])
        }

    }, [scoringTemplate])


    useEffect(() => {
        const skillsValueLabelFormat = existingSkillsAcrossTemplates.map(skill => ({ value: skill, label: skill }))
        setSkillOptions(skillsValueLabelFormat)
    }, [existingSkillsAcrossTemplates])

    const handleCreateTemplateFromScratch = () => {
        setUiMode('TEMPLATE')
        setScoringTemplate(getNewTemplateObject())
        addQuestion()
    }
    const handleCreateTemplateFromPopular = (popularTemplate) => {
        setUiMode('TEMPLATE')
        setScoringTemplate(cloneDeep(popularTemplate))

    }


    useEffect(() => {
        //dispatch action to fetch and set popular templates in redux only if empty, popular templates cached as they rarely change
        if (popularTemplates.length === 0) {
            dispatch(getPopularTemplates())
        }
        //template present -> EDIT flow 
        if (template) {
            setUiMode('TEMPLATE')
            setScoringTemplate(cloneDeep(template))
        }
    }, [])

    const questionChangeHandler = (question, index) => {
        let questions = cloneDeep(scoringTemplate.questions)
        questions[index] = question
        setScoringTemplate({ ...scoringTemplate, questions })

    }

    const deleteQuestion = (index) => {
        let questions = cloneDeep(scoringTemplate.questions)

        questions.splice(index, 1)
        //re order questions
        questions.forEach((question, index) => {
            question.order = index + 1
        })
        setScoringTemplate({ ...scoringTemplate, questions })

    }

    const handleSkillAdd = async (skill, index) => {
        if (!skillOptions.some(option => { return ((option.value === skill)); })) {
            setSkillOptions([...skillOptions, { value: skill, label: skill }])
        }
        questionChangeHandler({ ...scoringTemplate.questions[index], skill: skill }, index)
        metrics.logEventHeap('addNewSkill', { skill });


    }
    const validate = () => {
        let validatedState = cloneDeep(scoringTemplate)
        let isValid = true
        validatedState.invalidTitle = false

        if (!validatedState.name || validatedState.name.trim() == "") {
            validatedState.invalidTitle = true
            isValid = false
        }
        validatedState.questions.forEach(question => {
            question.invalidLabel = false
            question.invalidSkill = false
            if (!question.skill) {
                question.invalidSkill = true
                isValid = false
            }
            if (!question.label || question.label.trim() == "") {
                question.invalidLabel = true
                isValid = false
            }
        })
        setScoringTemplate(validatedState)
        return isValid
    }


    const handleSubmit = async () => {
        if (!validate()) {
            return
        }
        setSubmitLoading(true)
        let resp = null
        if (template)//editing 
        {
            resp = await sendRequest('scorecard/edit_scoring_template', scoringTemplate)
            metrics.logEventHeap('scoringTemplate', { type: "EDIT" });
        }
        else {
            resp = await sendRequest('scorecard/create_scoring_template', scoringTemplate)
            metrics.logEventHeap('scoringTemplate', { type: "CREATE" });
        }

        if (resp?.error) {
            setSnackbar("Error creating/editing template", "ERROR");
            setSubmitLoading(false)
        }
        else {
            setSnackbar("template saved", "SUCCESS");
            onSubmit(resp.result, template ? true : false)
        }

    }


    const addQuestion = () => {
        let question = {
            label: "",
            skill: null,
            order: (scoringTemplate?.questions?.length || 0) + 1
        }

        setScoringTemplate((scoringTemplateState) => {
            return {
                ...scoringTemplateState,
                questions: [...scoringTemplateState.questions, question]
            }
        })
    }

    const closeModal = () => {
        setOpen(false)
    }

    function handleReorderOfQuestions(result) {
        if (!result.destination) {
            return;
        }
        let questions = [...questionsList];
        const [reorderedQuestion] = questions.splice(result.source.index, 1);
        questions.splice(result.destination.index, 0, reorderedQuestion);
        setQuestionsList(questions)
        setScoringTemplate({ ...scoringTemplate, questions })

    }

    return (
        <Modal
            open={open}
            onClose={closeModal}
            className="flex justify-center items-center"
        >
            <div className="flex flex-col bg-white w-screen h-screen" ref={modalContentRef}>
                <div className="flex justify-between items-center flex-shrink-0 border-gray-200 border-b pr-12">

                    <div className='flex'>
                        {/* Show close button on List page and When Editing existing template */}
                        {(uiMode === "LIST" || template) && <button className="py-6 px-7" onClick={closeModal}><CloseIcon /></button>}
                        {/* Show Back button When Creating template after clicking popular or new template */}
                        {(uiMode === "TEMPLATE" && !template) && <button className="py-6 px-7" onClick={() => setUiMode("LIST")}><ArrowLeftIcon /></button>}
                        <div className='border-gray-200 border-r h-[4.5rem] mr-4'></div>
                        <TextLarge className="py-6">{template ? 'Edit ' : "Create "} scorecard template </TextLarge>
                    </div>

                    {uiMode === "TEMPLATE" && <Button variant="primary" className="px-12 " onClick={handleSubmit} loading={submitLoading} id="adoption-tracking-scorecard-template-submit">{(template || scoringTemplate?.description) ? "Save" : " Create"} template</Button>}

                </div>

                <div className='overflow-y-auto flex-grow pl-32 pr-15 py-8'>
                    {uiMode === "TEMPLATE" &&
                        <div className="flex">
                            <div className='flex-grow'>
                                <Input
                                    label="Template title"
                                    value={scoringTemplate?.name}
                                    onChange={(e) => setScoringTemplate({ ...scoringTemplate, name: e.target.value })}
                                    type="text"
                                    placeholder="e.g. Discovery call template"
                                    className="mb-6"
                                    error={scoringTemplate?.invalidTitle ?
                                        "Title is required" : null}

                                />
                                <DragDropContext onDragEnd={handleReorderOfQuestions}>
                                    <Droppable droppableId='reorderQuestions'   >

                                        {(provided) => (


                                            <div className='reorderQuestions'  {...provided.droppableProps} ref={provided.innerRef} tabIndex="-1">
                                                {
                                                    (questionsList).map((question, index) => (

                                                        <Draggable key={index} draggableId={'question-skill' + index + index} index={index}>

                                                            {(provided) => (
                                                                <div ref={provided.innerRef}   {...provided.draggableProps}  {...provided.dragHandleProps} tabIndex="-1" >

                                                                    <ScorecardQuestion
                                                                        question={question}
                                                                        index={index}
                                                                        skillOptions={skillOptions}
                                                                        addNewSkill={(skill) => handleSkillAdd(skill, index)}
                                                                        updateQuestion={(question) => { questionChangeHandler(question, index) }}
                                                                        deleteQuestion={() => { deleteQuestion(index) }}
                                                                        showDeleteQuestionIcon={scoringTemplate.questions.length > 1 ? true : false}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))
                                                }
                                                {provided.placeholder}
                                            </div>

                                        )}

                                    </Droppable>
                                </DragDropContext>
                                <Button variant="tertiary" className="!text-brand-blue-1" onClick={addQuestion} id="adoption-tracking-scorecard-template-add-question">Add question</Button>
                            </div>
                            {
                                //when scoringTemplate is created by copying a popular template, show whatever extra  details are present . 
                                (scoringTemplate?.description || scoringTemplate?.link) &&
                                <div className='flex-shrink-0 w-1/3 ml-12'>
                                    <div className='min-h-72 p-6 bg-coolGray-50 rounded-lg mt-4'>
                                        <TextXL>{scoringTemplate?.originalName}</TextXL>
                                        <TextBase className="mt-2" textColor='text-coolGray-500'>{scoringTemplate?.description}</TextBase>
                                        <TextBase className="mt-6" textColor='text-coolGray-500'>By : {scoringTemplate?.author || "Wingman"}</TextBase>
                                    </div>

                                    {scoringTemplate.link &&
                                        <div className='p-4 bg-coolGray-50 rounded-lg mt-4' >
                                            <div className='px-2 py-1 bg-violet-50 inline-block rounded-lg'>
                                                <TextBase textColor='text-violet-800'>Blog</TextBase>
                                            </div>
                                            <a className='flex items-center mt-2 block' href={scoringTemplate?.link} target="_blank" rel="noreferrer">
                                                <TextLarge className="mt-2 ml-1 w-3/4">{scoringTemplate?.blogTitle || "Check out our blog on getting started with  this template"}</TextLarge>
                                                <div className='w-1/4 flex items-center justify-end mr-4'>
                                                    <LinkToPageIcon className="text-coolGray-900" />
                                                </div>
                                            </a>


                                        </div>}


                                </div>
                            }
                        </div>

                    }
                    {
                        uiMode === "LIST" &&
                        <>
                            {
                                popularTemplates.length === 0 &&
                                <div>
                                    <Skeleton height={160} width={"100%"} className="ml-2" />
                                    <div className='flex'>
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                    </div>
                                    <div className='flex'>
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                        <CardSkeleton style={{ height: "12rem", width: "25rem" }} />
                                    </div>
                                </div>

                            }
                            {popularTemplates.length > 0 && <div>
                                <div className='flex flex-col items-center justify-center py-15 bg-white hover:bg-coolGray-50 border-2 border-dashed rounded-lg cursor-pointer' onClick={handleCreateTemplateFromScratch}>

                                    <div className='p-3 bg-indigo-50 rounded-full inline-block'>
                                        <RoundedPlusIcon className='text-brand-blue-1' />
                                    </div>
                                    <TextLarge className="mt-2">Create a new template</TextLarge>

                                </div>

                                <TextXL className="mt-8 ml-1">Presets</TextXL>
                                <div className='flex'>
                                    {popularTemplates.map((template, index) => {
                                        return <TemplateCard template={template} key={"popular-template-" + index} onClick={() => { handleCreateTemplateFromPopular(template) }} />
                                    })
                                    }

                                </div>
                            </div>
                            }
                        </>
                    }
                </div>
            </div>
        </Modal >
    )
}
export default CreateTemplateModal;