import React from "react";
import { Modal } from "@material-ui/core";
import { TextBase, TextLarge, TextSM, Button } from "app/designSystem"
import { ClosePlainCrossIcon } from "app/designSystem";
import TagFilledInputArea from "../settings/TagFilledInputAreaForModal";
import { useSelector } from "react-redux";
import { AiDetectedTopic } from "app/designSystem";


const ShowTopicsModal = (props) => {

    const customer = useSelector((store) => store.auth.stringsCustomer);
    const isAdmin = useSelector((store) => store.auth.isAdmin);
    return (
        <>
            <Modal open={props.open} onClose={() => props.close()} className="Form__outerContainer ">
                <div
                    className="bg-white rounded-lg overflow-auto w-[45rem] p-0 outline-none"
                    style={{
                        minHeight: "calc(55vh - 6.25rem)",
                        maxHeight: "calc(80vh - 6.25rem)"
                    }}
                >
                    <div className="px-8 py-5 flex justify-between items-center">
                        <div className="flex flex-col ">
                            <TextLarge className="mb-1">{props.category}</TextLarge>
                            {(props.exactMatches || props.approxMatches) &&
                                <TextSM>These are the words and phrases that determine when this topic is picked up in conversations.{isAdmin ? "You can edit these inside workprofile settings." : "Please reach to your team’s admins to change these."}</TextSM>
                            }
                        </div>
                        <button onClick={() => props.close()}>
                            <ClosePlainCrossIcon />
                        </button>
                    </div>

                    <hr />

                    {!props.approxMatches && !props.exactMatches &&
                        <div className="flex flex-col pl-8 py-5 items-center mt-5">
                            <AiDetectedTopic />
                            <TextBase className="mt-2 w-10/12 text-center" textColor="text-coolGray-500">This is an AI detected topic. Our AI models understand the context of the conversation and automagically tag relevant sections with this topic.</TextBase>
                        </div>
                    }


                    {customer.featuresGated.enableSemanticMatching && props.approxMatches && props.approxMatches.length > 0 &&
                        <div className="flex flex-col px-8 py-5">
                            <TextBase className="text-coolGray-900 mb-2"> Semantically similar matches</TextBase>
                            <div className="border rounded min-h-[7rem] min-w-[42rem] px-2 ">
                                <TagFilledInputArea incorrectWords={props.approxMatches} disabled={true} />
                            </div>
                        </div>
                    }
                    {props.exactMatches && props.exactMatches.length > 0 &&
                        <div className="flex flex-col px-8 py-5">
                            <TextBase className="text-coolGray-900 mb-2"> Exact matches</TextBase>
                            <div className="border rounded  min-w-[42rem] px-2 min-h-[7rem] ">
                                <TagFilledInputArea incorrectWords={props.exactMatches} forDashboards={true} disabled={true} />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </>
    )
}

export default ShowTopicsModal;