const AudioRecorder = () =>
  new Promise(async (resolve, reject) => {
    try {
      var RecordRTC = require('recordrtc');
      var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });
      const options = {
        type: "audio",
        recorderType: StereoAudioRecorder,
        mimeType: "audio/wav",
      };
      const recordRTC = RecordRTC(stream, options);
      const start = () => recordRTC.startRecording();


      const stop = () =>
        new Promise(async resolve => {
          recordRTC.stopRecording(audioUrl => {
            const audio = new Audio(audioUrl);
            const play = () => audio.play();
            const pause = () => audio.pause();
            const audioBlob = recordRTC.getBlob();
            stream.stop();
            recordRTC.getDataURL(dataUrl => {
              resolve({
                audioBlob,
                play,
                pause,
                dataUrl,
              });
            });
          });
        });

      resolve({
        start,
        stop
      });
    } catch (e) {
      reject(e);
    }
  });

export default AudioRecorder;