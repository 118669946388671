import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FROALA_KEY } from "app/config";

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/emoticons.min.js'
import "froala-editor/js/plugins/lists.min.js"
import "froala-editor/js/plugins/char_counter.min.js";
import "froala-editor/js/plugins/url.min.js";

import FroalaEditor from 'react-froala-wysiwyg';

const TalkingPointsBox = ({ talkingPoints, onEdit }) => {

  const handleEditorChanges = (editorStateNew) => {
    onEdit(editorStateNew);
  }

  return (
    <div className="battlecardForm__tpBox">
      <FroalaEditor
        config={{
          "key": FROALA_KEY,
          "attribution": false,
          "editorClass": 'RichTextEditor',
          "charCounterCount": true,
          "charCounterMax": 5000,
          "listAdvancedTypes": false,
          "height": 210,
          "toolbarButtons": {
            'moreText': {
              'buttons': ['bold', 'italic', 'underline', 'fontSize', "formatOL", "formatUL"],
              'buttonsVisible': 10,
              'size': 10,
            },
            'moreRich': {
              'buttons': ['insertLink', 'emoticons', 'insertHR']
            },
          }
        }}
        tag='textarea'
        model={talkingPoints}
        onModelChange={handleEditorChanges}
      >{talkingPoints}</FroalaEditor>

    </div>
  );
};

TalkingPointsBox.propTypes = {};

export default TalkingPointsBox;
