import React, { useState } from "react";
import "rc-slider/assets/index.css";
import { _roundUp } from "app/utils/helpers";
import { useEffect } from "react";
import { CustomDropdown } from "app/components/common";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";
import useSnackbar from "../../utils/hooks/useSnackbar";

const RangeFilterTemplate = props => {
    const [start, setStart] = useState(props.startRange);
    const [end, setEnd] = useState(props.endRange);
    const [showRemove, setShowRemove] = useState(false);
    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);


    const { setSnackbar } = useSnackbar();


    useEffect(() => {
        setStart(props.startRange);
    }, [props.startRange])

    useEffect(() => {
        setEnd(props.endRange);
    }, [props.endRange])

    useEffect(() => {
        validateAndFireDurationChangeEvents()
    }, [start, end])

    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        props.removeFilterValue(props.fieldName);
    }

    function validateAndFireDurationChangeEvents() {
        if (start !== props.startRange && end !== props.endRange) {
            if (start <= end)
                props.onStartAndEndChange([start, end]);
            else
                setSnackbar("Range entered is incorrent", "ERROR");
        } else if (start !== props.startRange) {
            if (start <= props.endRange)
                props.onStartChange(start);
            else
                setSnackbar("Range entered is incorrent", "ERROR");
        } else if (end !== props.endRange) {
            if (props.startRange <= end)
                props.onEndChange(end);
            else
                setSnackbar("Range entered is incorrent", "ERROR");
        }
    }

    return (

        <div className="FilterField__container">
            <div ref={ref} className="FilterField__valueContainer">
                {showRemove && !props.dontShowCloseIcon && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
                <div className="RangeInput">
                    <div className="RangeInput__row text-base">
                        <CustomDropdown
                            bubbleClass={"RangeInput__dropdown"}
                            top={30}
                            left={10}
                            title={<span className="RangeInput__dropdown__trigger">{_roundUp(start * props.roundOffFactor, 1)}{props.showPercentageAfterValue ? "%" : ""}</span>}
                            list={[...props.list].map(time => <div key={time} onClick={() => setStart(time)} className="RangeInput__dropdown__item">{_roundUp(time * props.roundOffFactor, 1)}{props.showPercentageAfterValue ? "%" : ""}</div>)} />
                        {" - "}
                        <CustomDropdown
                            bubbleClass={"RangeInput__dropdown"}
                            top={30}
                            left={10}
                            title={<span className="RangeInput__dropdown__trigger">{_roundUp(end * props.roundOffFactor, 1)}{props.showPercentageAfterValue ? "%" : ""}</span>}
                            list={[...props.list].flat().map(time => <div key={time} onClick={() => setEnd(time)} className="RangeInput__dropdown__item">{_roundUp(time * props.roundOffFactor, 1)}{props.showPercentageAfterValue ? "%" : ""}</div>)} />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default RangeFilterTemplate;