import React from "react";
import { withStyles } from "@material-ui/core";

const styles = {
  page: {
    transition: "500ms all",
    overflowX: "hidden",
    flexDirection: "row",
    display: "flex",
  },
};

class Paginator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      marginLeft: props.initialMargin,
      page: props.page,
    };
    this.goToPage = this.goToPage.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.page !== nextProps.page) {
      this.goToPage(nextProps.page);
    }
  }

  goToPage(page) {
    this.setState({
      page,
      marginLeft:
        this.props.initialMargin - (this.props.pageWidth - this.props.customOffset) * page,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.page}
        style={{
          marginLeft: this.state.marginLeft,
          width: (this.props.pageWidth - this.props.customOffset) * this.props.children.length,
        }}
      >
        {this.props.children.map((page, i) => {
          return (
            <div key={i} style={{ width: this.props.pageWidth - this.props.customOffset }}>
              {page}
            </div>
          );
        })}
      </div>
    );
  }
}

Paginator.propTypes = {};

Paginator.defaultProps = {
  pageWidth: window.innerWidth,
  customOffset: 0,
  children: [],
  initialMargin: 0,
  page: 0,
};

export default withStyles(styles)(Paginator);
