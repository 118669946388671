import request from "app/utils/request";
import {
  USER,
  ADMIN_LOGIN
} from "./actionTypes";
import {
  metrics
} from "app/utils/metrics";


export function setUserData(details) {
  console.log("user", details?.user);
  localStorage.setItem("user", JSON.stringify(details?.user));
  /*if (details.token) {
    localStorage.setItem("token", details.token);
  }*/
  const user = details?.user;
  return {
    user,
    type: USER
  };
}

export function setAdminLogin(isAdminLogin) {
  return {
    isAdminLogin,
    type: ADMIN_LOGIN
  };
}

export function refreshUserAction() {
  const params = new URLSearchParams();
  return dispatch => {
    return request()
      .post("/admin/refreshUser", params)
      .then(response => {
        const user = response.data;
        dispatch(setUserData(user));
      });
  };
}

export function logout() {
  const user = localStorage.getItem("user");
  console.log("loggging out user:", user);
  if (user !== "undefined" && user !== "null" && user !== "{}") {
    try {
      localStorage.setItem(`callLogFilter${user.userId}`, null);
    } catch (error) {
      console.log("no user found...");
    }
  }
  return dispatch => {
    const details = { token: "null", user: null };
    dispatch(setUserData(details));
    dispatch(setAdminLogin(false));
    return request()
      .post("/auth/logout")
  };
}