export function isFileAudio(filename) {
    let ext = filename.split('.').pop();
    if (ext.includes('mp3') || ext.includes('m4a') || ext.includes('wav') || ext.includes('flac')) return true;
    else return false;
}

export async function isDoubleChannelAudio(file) {
    if (file && !isFileAudio(file.name)) return false;
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioCtx = new AudioContext();
    const buffer = await blobToArrayBuffer(file);
    const data = await audioCtx.decodeAudioData(buffer);
    return data.numberOfChannels === 2 ? true : false
}

async function blobToArrayBuffer(blob) {
    if ('arrayBuffer' in blob) return await blob.arrayBuffer();

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject('error');
        reader.readAsArrayBuffer(blob);
    });
}