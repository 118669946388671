import React, { useState } from "react";
import { connect } from "react-redux";
import { Loading } from "app/components/common";
import { setLoading } from "app/actions/screen";
import { setUserData } from "app/actions/auth";
import { setRoleByUser, refreshUser } from "app/utils/network";
import { REP, MANAGER, OBSERVER } from "app/constants";
import RoleCard from "./RoleCard";
import RecordingSelection from "./RecordingSelection";

const RoleSelection = props => {
  const [showRecordingSelection, setShowRecordingSelection] = useState(false);

  async function handleRoleChange(role) {
    if (role === MANAGER) {
      setShowRecordingSelection(true);
      return;
    }
    const user = props.auth.data.user;
    if (user.roleInfo === null || user.roleInfo.role !== role) {
      props.setLoading(true);
      await setRoleByUser(role);
      const data = await refreshUser();
      props.setUserData(data);
      props.setLoading(false);
    }
  }

  async function setRecording(bool) {
    props.setLoading(true);
    await setRoleByUser(MANAGER, bool);
    const data = await refreshUser();
    props.setUserData(data);
    props.setLoading(false);
  }

  return (
    <React.Fragment>
      <Loading />
      <div className="Sync-Background verCon">
        <div className="Form__horCon">
          <RoleCard type={REP} onSelect={() => handleRoleChange(REP)} />
          <RoleCard
            type={MANAGER}
            selected={showRecordingSelection}
            onSelect={() => handleRoleChange(MANAGER)}
          />
          <RoleCard type={OBSERVER} onSelect={() => handleRoleChange(OBSERVER)} />
        </div>
        <div className="SyncFlowContents__textContainer" style={{ marginTop: "20px" }}>
          {!showRecordingSelection && (
            <React.Fragment>
              <span className="SyncFlowContents__heading">
                {"Define your role in the organisation"}
              </span>
              <span className="SyncFlowContents__subheading">Select a role to continue</span>
            </React.Fragment>
          )}
          {showRecordingSelection && <RecordingSelection setRecording={setRecording} />}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

RoleSelection.propTypes = {};

RoleSelection.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setLoading,
    setUserData,
  },
)(RoleSelection);
