
import React from "react";
import { connect } from "react-redux";
import { getReviewAnnotationQuestion, submitReviewAnnotationDecision, getReviewsCount } from "app/utils/network";
import { withStyles } from "@material-ui/core";

class ReviewAnnotation extends React.Component {
  constructor(props) {
    super(props);
    this.openCall = this.openCall.bind(this);
    this.state = {
      callId: "",
      turnId: 0,
      reviewFor: "",
      question: "",
      userId: this.props.auth.data.user.userId || "",
    };
  }
  async UNSAFE_componentWillMount() {
    await this.loadData();
    document.addEventListener("keydown", this.handleKeyDown.bind(this));
  }
  getRemainingMessage(reviewsCount) {
    return reviewsCount > this.state.quota ? "Keep up the good work!!" : (`Remaining: ${this.state.quota - reviewsCount}`);
  }
  async loadData() {
    const response = await getReviewAnnotationQuestion(this.state.userId);
    const reviews = await getReviewsCount(this.state.userId);
    this.setState({
      callId: response.callId,
      turnId: response.turnId,
      reviewFor: response.reviewFor,
      question: response.turn,
      reviewsCount: reviews.count,
      quota: reviews.quota,
    });
  }
  increaseReviewsCount() {
    this.setState({
      reviewsCount: this.state.reviewsCount + 1,
    });
  }
  handleKeyDown(e) {
    if (e.key === "y" || e.key === "Y") this.submit("YES");
    if (e.key === "n" || e.key === "N") this.submit("NO");
    if (e.key === "a" || e.key === "A") this.submit("NOT_APPLICABLE");
    if (e.key === "o" || e.key === "O") this.openCall();
  }
  startOfNextWeek() {
    const dt = new Date();
    const dy = dt.getDay();
    dt.setHours(0);
    dt.setMinutes(0);
    dt.setSeconds(0);
    dt.setDate(dt.getDate() - dy + (dy === 0 ? 1 : 8));
    return dt;
  }
  formatDuration(ms) {
    if (ms < 1000) return `${Math.floor(ms)} milliseconds`;
    const s = ms / 1000;
    if (s < 60) return `${Math.floor(s)} seconds`;
    const m = s / 60;
    if (m < 60) return `${Math.floor(m)} minutes`;
    const h = m / 60;
    if (h < 24) return `${Math.floor(h)} hours`;
    const d = h / 24;
    return `${Math.floor(d)} days`;
  }
  remainingTime() {
    return this.formatDuration(this.startOfNextWeek() - new Date());
  }
  openCall() {
    window.open(`/call/${this.state.callId}#${this.state.turnId}`).focus();
  }
  async submit(decision) {
    await submitReviewAnnotationDecision(this.state.callId, this.state.turnId, this.state.reviewFor, this.state.userId, decision);
    this.increaseReviewsCount();
    await this.loadData();
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ margin: "auto", textAlign: "center", width: "1000px" }}>
          <div style={{ display: "flex", fontSize: "20px", marginBottom: "50px" }}>
            <span style={{ flex: 1 }}>Reviews this week: {this.state.reviewsCount}</span>
            <span style={{ flex: 1 }}>{this.getRemainingMessage(this.state.reviewsCount)}</span>
            <span style={{ flex: 1 }}>Remaining time: {this.remainingTime()}</span>
          </div>
          <div style={{ fontSize: "40px" }}>Does this have <span>{this.state.reviewFor}</span>?</div>
          <div style={{
 fontSize: "30px", margin: "20px", minHeight: "170px", display: "flex", alignItems: "center", justifyContent: "center",
 }}>"{this.state.question}"
          </div>
          <button className={`${classes.button} ${classes.gray}`} onClick={this.openCall}>(O)pen call</button>
          <div>
            <button className={`${classes.button} ${classes.green}`} onClick={() => this.submit("YES")}>(Y)es</button>
            <button className={`${classes.button} ${classes.red}`} onClick={() => this.submit("NO")}>(N)o</button>
            <button className={`${classes.button} ${classes.blue}`} onClick={() => this.submit("NOT_APPLICABLE")}>N(A)</button>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  button: {
    border: "none",
    color: "white",
    fontSize: "20px",
    padding: "15px 32px",
    margin: "10px",
    borderRadius: "4px",
  },
  green: { backgroundColor: "#4CAF50" },
  red: { backgroundColor: "#F44336" },
  blue: { backgroundColor: "#008CBA" },
  gray: { backgroundColor: "#555555" },
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(mapStateToProps)(withStyles(styles)(ReviewAnnotation));
