import { useState, useEffect } from 'react'
import { debounce } from "lodash";

export const useScrollListener = (id) => {

	const [scroll, setScroll] = useState({
		x: 0,
		y: 0,
		diffY: 0,
		direction: ''
	});

	const listener = e => {
		setScroll(prev => ({
			x: document.getElementById(id).scrollLeft,
			y: -document.getElementById(id).scrollTop,
			diffY: Math.abs(prev.y - document.getElementById(id).scrollTop),
			direction: prev.y > -document.getElementById(id).scrollTop ? 'up' : 'down'
		}))
	}

	const listenerDebounced = debounce((e) => listener(e), 200);

	useEffect(() => {
		if (document.getElementById(id)) {
			document.getElementById(id).addEventListener('scroll', listenerDebounced)
		}
		return () => {
			if (document.getElementById(id)) {
				document.getElementById(id).removeEventListener('scroll', listenerDebounced);
			}
		}
	}, [])

	return scroll
}

export const useScrollListenerRef = () => {
	const ref = useRef(null);

	const [scroll, setScroll] = useState({
		x: 0,
		y: 0,
		diffY: 0,
		direction: ''
	});

	const listener = e => {
		setScroll(prev => ({
			x: ref.current.scrollLeft,
			y: -ref.current.scrollTop,
			diffY: Math.abs(prev.y - ref.current.scrollTop),
			direction: prev.y > -ref.current.scrollTop ? 'up' : 'down'
		}))
	}

	const setRef = useCallback(node => {
		if (ref.current) {
			ref.current.removeEventListener('scroll', listener);
		}

		if (node) {
			console.log("ref in scroll listener", node);
			node.addEventListener('scroll', listener)
		}

		// Save a reference to the node
		ref.current = node
	}, [])

	return [setRef, scroll];
}