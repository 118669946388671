import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../form";

const ViewDetails = props => {
  return (
    <div className="Player__group">
      <Link to={`/call/${props.playback.callId}`}>
        <Button  type="medium" variant="outlined">
          {"View"}
        </Button>
      </Link>
    </div>
  );
};

export default ViewDetails;
