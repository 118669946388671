import {
  SET_CALL_LOG_FILTER,
  USER,
  SET_FILTERED_CALLS,
  SET_FILTERED_CALLS_IF_EMPTY,
  SET_CALL_LOG_FILTER_AND_REFRESH,
} from "app/actions/actionTypes";
import {
  emptyFilterParams,
  initialFilterParams,
} from "app/utils/filter";

const initialState = {
  filterParams: getSavedFilterParams(),
  calls: {
    calls: [],
    totalHits: 0,
  },
};

function getSavedFilterParams() {
  try {
    const user = localStorage.getItem("user");
    if (user !== null && user !== undefined && user !== "null" && user !== "undefined") {
      let parsedUser = JSON.parse(user);
      const filterParams = localStorage.getItem(`callLogFilter${parsedUser.userId}`);
      if (filterParams && filterParams !== "undefined" && JSON.parse(filterParams) && JSON.parse(filterParams).version === initialFilterParams.version) {
        return JSON.parse(filterParams);
      }
    }
  } catch (error) {
    console.log(error)
  }
  return emptyFilterParams;

}


export default function (state = initialState, payload) {
  switch (payload.type) {
    case SET_CALL_LOG_FILTER:
      return {
        ...state,
        filterParams: payload.filterParams,
      };
    case SET_CALL_LOG_FILTER_AND_REFRESH:
      return {
        ...state,
        filterParams: payload.filterParams,
        calls: {
          calls: [],
          totalHits: 0,
        },
      };
    case USER:
      if (payload.user != null) {
        const filterParams = localStorage.getItem(`callLogFilter${payload.user.userId}`);
        if (filterParams && filterParams !== "undefined" && JSON.parse(filterParams) && JSON.parse(filterParams).version === initialFilterParams.version) {
          return {
            ...state,
            filterParams: JSON.parse(filterParams)
          };
        }
      }
    case SET_FILTERED_CALLS:
      return {
        ...state,
        calls: payload.results,
      };
    case SET_FILTERED_CALLS_IF_EMPTY:
      console.log("state is:", state);
      if (state.calls && state.calls.calls && state.calls.calls.length > 0) return { ...state };
      return {
        ...state,
        calls: payload.calls,
      };
    default:
      return state;
  }
}