import React from "react";
import { _formatTalkListenRatio } from "app/utils/helpers";
import { CircularProgressbar } from "react-circular-progressbar";
import { connect } from "react-redux";
import { getTLRatioColorTWClasses } from "../dashboards/helpers";
import { TextBase } from "../../designSystem";
const tailwindColors = require("tailwindcss/colors")

const TalkToListenRatioColumn = ({ talkListenRatios, talkListenRatio, auth }) => {
  const {
    okayTLRatioThreshold,
    goodTLRatioThreshold
  } = auth.stringsCustomer.settings;
  const { bgColor, textColor } = getTLRatioColorTWClasses(
    talkListenRatio,
    okayTLRatioThreshold,
    goodTLRatioThreshold
  );

  return (
    <div className="flex items-center ">
      <div className="w-6 h-6 ">
        <CircularProgressbar
          value={talkListenRatio * 100}
          strokeWidth={20}
          styles={{
            path: {
              stroke: bgColor,
              strokeLinecap: "butt"
            },
            trail: {
              stroke: tailwindColors.coolGray[200]
            }
          }}
        />
      </div>
      <TextBase textColor={textColor} className="ml-4">
        {_formatTalkListenRatio(talkListenRatio)}
      </TextBase>

    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  {}
)(TalkToListenRatioColumn);
