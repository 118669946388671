import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { FoldersContainer, GameTapesContainer, FolderInformationPanel, FolderExtraPanel, FolderPaths } from 'app/components/gameTapesLatest'
import { HeaderSkeleton, FolderInformationPanelSkeleton } from 'app/components/gameTapesLatest/skeleton'
import { ShareFolderModal } from 'app/components/gameTapesLatest/modal';
import { getFolderInformation, subscribeFolder, subscribeAnotherUser } from 'app/utils/network/gametapes'
import { MAIN, STACKED } from "app/constants";
import { setScreenType } from "app/actions/screen";
import { Text2XL } from 'app/designSystem';
import { ArrowLeftIcon } from 'app/designSystem/icons';
import { debounce } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { getGameTapesAllFolderData, getGameTapesAllGameTapesData, setGameTapesFolderData } from 'app/actions/gametapes'
import metrics from 'app/utils/metrics';

export const MAX_LEVEL_OF_FOLDERS = 2;

const FolderView = (props) => {
    const dispatch = useDispatch();

    const currentUserId = useSelector(store => store.auth.userId);
    const { foldersList, cachedFoldersData } = useSelector(state => state.gametapes);

    const [loading, setLoading] = useState(true);
    const [folderId, setFolderId] = useState(null);
    const [folderPath, setFolderPath] = useState([]);
    const [isShareFormOpen, setIsShareFormOpen] = useState(false);

    const debounceSubscriptionHandler = useMemo(() =>
        debounce((props) => {
            subscribeFolder(props);
            metrics.logEventHeap('subscribeFolder', { type: props.subscribe ? 'Subscribe' : 'Unsubscribe' });
        }, 500), [folderId]);

    const debounceAnotherUserSubscriptionHandler = useMemo(() =>
        debounce((props) => {
            subscribeAnotherUser(props);
            metrics.logEventHeap('subscribeFolder', { type: props.subscribe ? 'Subscribe' : 'Unsubscribe' });
        }, 500), [folderId]);

    useEffect(() => {
        dispatch(setScreenType(STACKED));
        dispatch(getGameTapesAllFolderData());
        dispatch(getGameTapesAllGameTapesData());

        return () => {
            dispatch(setScreenType(MAIN));
        }
    }, []);

    useEffect(() => {
        setFolderId(props.match.params.folderId);
        setFolderPath([]);
        fetchFolderInformation(props.match.params.folderId);
    }, [props.match.params.folderId]);

    const fetchFolderInformation = async (folderId) => {
        if (cachedFoldersData[folderId]) {
            setLoading(false);
        } else {
            setLoading(true);
        }
        const result = await getFolderInformation({ folderId });
        if (result && Object.keys(result).length > 0) {
            if (cachedFoldersData[folderId]) {
                dispatch(setGameTapesFolderData(folderId, {
                    ...cachedFoldersData[folderId],
                    ...result
                }));
            } else {
                dispatch(setGameTapesFolderData(folderId, result));
            }
            dispatch(getGameTapesAllFolderData());
        }
        else {
            //redirect page to not found
            props.history.push('/notFound');
        }
        setLoading(false);
    }

    useEffect(() => {
        if (folderId && foldersList?.length > 0) {
            let tempFoldersList = [];
            let currentFolder = foldersList?.filter(folder => folder.id === folderId)?.[0];

            while (currentFolder) {
                tempFoldersList.push({
                    name: currentFolder.name,
                    id: currentFolder.id
                })

                currentFolder = foldersList?.filter(folder => folder.id === currentFolder.parentFolder)?.[0];
            }

            tempFoldersList.push({
                name: 'Home',
                id: 'ROOT',
            });

            tempFoldersList.reverse();

            setFolderPath(tempFoldersList);
        }
    }, [foldersList, folderId]);

    const handleSubscribe = (subscribe, userId) => {
        if (userId) {
            if (subscribe) {
                const newSubscribedUsers = [...cachedFoldersData[folderId].subscribedUsers, userId];
                const newSharedUsers = Array.from(new Set([...cachedFoldersData[folderId].sharedUsers, ...newSubscribedUsers]));
                dispatch(setGameTapesFolderData(folderId, {
                    ...cachedFoldersData[folderId],
                    subscribedUsers: newSubscribedUsers,
                    sharedUsers: newSharedUsers
                }));
            } else {
                const newSubscribedUsers = cachedFoldersData[folderId].subscribedUsers.filter(userId => userId !== userId);
                dispatch(setGameTapesFolderData(folderId, {
                    ...cachedFoldersData[folderId],
                    subscribedUsers: newSubscribedUsers
                }));
            }

            debounceAnotherUserSubscriptionHandler({ folderId, subscribe, userId });
        } else {
            if (subscribe) {
                const newSubscribedUsers = [...cachedFoldersData[folderId].subscribedUsers, currentUserId];
                const newSharedUsers = Array.from(new Set([...cachedFoldersData[folderId].sharedUsers, ...newSubscribedUsers]));
                dispatch(setGameTapesFolderData(folderId, {
                    ...cachedFoldersData[folderId],
                    subscribedUsers: newSubscribedUsers,
                    sharedUsers: newSharedUsers
                }));
            } else {
                const newSubscribedUsers = cachedFoldersData[folderId].subscribedUsers.filter(userId => userId !== currentUserId);
                dispatch(setGameTapesFolderData(folderId, {
                    ...cachedFoldersData[folderId],
                    subscribedUsers: newSubscribedUsers
                }));
            }

            debounceSubscriptionHandler({ folderId, subscribe });
        }
    };

    return (
        <div className="overflow-hidden">
            <div className="flex border-b items-center p-2">
                {
                    cachedFoldersData[folderId] ? <Link className="py-5 px-6" to={cachedFoldersData[folderId].parentFolder === "ROOT" ? `/gametapes` : `/gametapes/folder/${cachedFoldersData[folderId].parentFolder}`}>
                        <ArrowLeftIcon />
                    </Link> : <button className="py-5 px-6">
                        <ArrowLeftIcon />
                    </button>
                }
                <div>
                    {(!loading && cachedFoldersData[folderId]) ? <Text2XL>{cachedFoldersData[folderId].name}</Text2XL> : <HeaderSkeleton />}
                </div>
            </div>
            <div className="flex">
                <div className="w-3/4 overflow-auto pt-5 py-20 pr-10 ml-8" style={{
                    maxHeight: 'calc(100vh - 5rem)'
                }}>
                    <div className="my-2">
                        <FolderPaths folderPath={folderPath} />
                    </div>
                    <div className="my-2">
                        {(folderPath.length === MAX_LEVEL_OF_FOLDERS) && <FoldersContainer folderId={folderId} />}
                    </div>
                    <div className="my-2">
                        <GameTapesContainer folderId={folderId} />
                    </div>
                </div>
                <div className="w-1/4 h-screen py-5 pb-5 px-4 flex flex-col" style={{
                    maxHeight: 'calc(100vh - 5rem)'
                }}>
                    {(!loading && cachedFoldersData[folderId]) ? <FolderInformationPanel folderId={folderId} handleSubscribe={handleSubscribe} handleShare={() => setIsShareFormOpen(true)} /> : <FolderInformationPanelSkeleton />}
                    {(!loading && cachedFoldersData[folderId]) && <FolderExtraPanel folderId={folderId} />}
                </div>
            </div>
            {cachedFoldersData[folderId] && <ShareFolderModal handleSubscribe={handleSubscribe} folderId={folderId} open={isShareFormOpen} onClose={() => setIsShareFormOpen(false)} />}
        </div>
    );
};

export default withRouter(FolderView);