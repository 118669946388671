import React, { useState } from "react";
import { Button, ArrowDownIcon } from "app/designSystem"
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import PropTypes from 'prop-types';

export default function ButtonWithDropdown({ className, height, children, loading, onClick, disabled, dropdownData, dontShowDropdown, ...props }) {
  const showDropdownButton = dropdownData?.length > 0 && !dontShowDropdown
  const [showDropdown, setShowDropdown] = useState(false)
  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="flex flex-row">
        <Button
          className={classNames(height, showDropdownButton ? "pl-4 rounded-r-none" : "")}
          variant="primary"
          loading={loading}
          onClick={onClick}
          disabled={disabled}
          {...props}
        >
          {children}
        </Button>
        {showDropdownButton &&
          <>
            <div className={classNames(height,
              "w-[2px] bg-brand-blue-1 flex flex-row items-center",
              disabled ? "opacity-30 cursor-not-allowed" : "")}>
              <div className="h-1/2 w-[1px] bg-white bg-opacity-30"></div>
            </div>
            <button
              className={classNames(height,
                "text-base  items-center justify-center flex disabled:opacity-30 disabled:cursor-not-allowed",
                "bg-brand-blue-1 hover:bg-brand-blue-2 active:bg-brand-blue-3 px-0.5 py-2.5 rounded-r flex flex-row items-center")}
              disabled={disabled}
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <ArrowDownIcon className="text-white" />
            </button>
          </>}
      </div >
      {
        showDropdown &&
        <div className="relative">
          <div className="w-full max-h-[10rem] top-0 right-0 z-10 absolute border overflow-y-auto rounded mt-1 bg-white p-1 shadow">
            <OutsideClickHandler
              onOutsideClick={() => setShowDropdown(false)}
            >
              <div onClick={() => setShowDropdown(false)}>
                {
                  dropdownData.map((data) =>
                    <DropdownItem
                      key={data.label}
                      label={data.label}
                      onClick={data.onClick}
                    />
                  )
                }
              </div>
            </OutsideClickHandler>
          </div>
        </div>
      }
    </div >
  )
}

ButtonWithDropdown.propTypes = {
  className: PropTypes.string,
  dropdownData: PropTypes.array.isRequired,
  height: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  dontShowDropdown: PropTypes.bool,
};

const DropdownItem = ({ label, onClick }) => {
  return (
    <div
      className=" text-base flex cursor-pointer w-full justify-center items-center hover:bg-brand-blue-4 p-1.5"
      onClick={onClick}>
      {label}
    </div>
  )
}

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

