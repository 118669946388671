import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
// import { Button } from "app/components/form";
import { Button } from "app/designSystem";

export default class ConfirmationDialog extends React.Component {
  constructor(props) {
    super();
  }

  handleCancel = e => {
    e.preventDefault();
    this.props.onClose();
  };

  handleOk = e => {
    e.preventDefault();
    this.props.onOk();
  };

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={this.handleCancel}>
            {this.props.noLabel}
          </Button>
          <Button variant="primary" className="min-w-[5rem]" loading={this.props.okLoading} onClick={this.handleOk}>
            {this.props.yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.defaultProps = {
  yesLabel: "Ok",
  noLabel: "Cancel",
  okLoading: false,
};

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  okLoading: PropTypes.bool,
};
