import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from "app/components/form";
import WarningIcon from "app/styles/assets/images/icons/warningWhite.svg";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { useSelector, useDispatch } from 'react-redux';
import { HIDE_VP_WARNING } from '../actions/actionTypes';
import { VoiceFingerprint } from '../components/settings';
import { sendRequest } from '../utils/network';
import useSnackbar from '../utils/hooks/useSnackbar';
import OutsideClickHandler from 'react-outside-click-handler';
import ProfileImage from '../components/common/ProfileImage';

const copy = {
    WARN: {
        heading: "Wingman is unable to analyze your dialer calls accurately.",
        subheading: "We need your voice fingerprint to accurately transcribe and analyze your calls. Record voice fingerprint now."
    },
    PAUSED: {
        heading: "Wingman is unable to analyze your dialer calls.",
        subheading: "We need your voice fingerprint to accurately transcribe and analyze your calls. Record voice fingerprint now.",
    },
    MANAGER: {
        heading: "Wingman is unable to analyze dialer calls for some of your reps.",
        subheading: "We need a voice fingerprint to accurately transcribe and analyze calls. Please remind your reps to record their voice fingerprint.",
    }
}

const VoiceFingerprintWarning = ({ }) => {

    const showVpWarning = useSelector(store => store.screen.showVpWarning);
    const auth = useSelector(store => store.auth);
    const dispatch = useDispatch();
    const trigger = <span className="FooterWarning__link">Record Voice Fingerprint 🎧</span>;
    const [warningType, setWarningType] = useState(null);
    const [repsWithVpMissing, setRepsWithVpMissing] = useState([]);
    const { setSnackbar } = useSnackbar();
    const [reminding, setReminding] = useState(false);

    useEffect(() => {
        updateWarningType();
    }, [auth.data.user.integrationStatus])

    async function sendReminder() {
        setReminding(true);
        var res = await sendRequest("/admin/remind_users_to_upload_vp");
        if (res.error) {
            setSnackbar("There was an error sending this reminder. Please try again or contact support.", "ERROR")
        } else {
            setSnackbar("Your reminder has been sent successfully.", "SUCCESS");
        }
        setReminding(false);
        hideWarning();
    }

    async function updateWarningType() {
        let warningLocal = await getWarningType();
        setWarningType(warningLocal);
    }

    async function getWarningType() {
        if (auth.data.user && auth.data.user.integrationStatus.dialerRecordingPaused) {
            return "PAUSED";
        }
        if (auth.data.user && auth.data.user.integrationStatus.dialerRecordingWarn) {
            return "WARN";
        }
        if (auth.isManager) {
            var repsWithVpMissing = await sendRequest("/admin/is_dialer_paused_for_reps");
            if (repsWithVpMissing && repsWithVpMissing.length > 0) {
                setRepsWithVpMissing(repsWithVpMissing);
                return "MANAGER";
            }
        }
    }

    if (!showVpWarning) return null;
    if (warningType == null) {
        return null;
    }

    function hideWarning() {
        dispatch({ type: HIDE_VP_WARNING });
    }

    return (
        <div className="FooterWarning__container">
            <div className="FooterWarning__textContainer">
                <div className="FooterWarning__headingContainer">
                    <img src={WarningIcon} className="FooterWarning__icon" />
                    <span className="FooterWarning__heading">{copy[warningType].heading}</span>
                </div>
                <span className="FooterWarning__subheading">{copy[warningType].subheading}</span>
            </div>
            <div className="FooterWarning__closeContainer">
                <CancelIcon
                    onClick={hideWarning}
                    style={{ fontSize: 25, color: "white" }}
                    color="primary"
                />
            </div>
            <div className="FooterWarning__buttonContainer">
                {(warningType === "WARN" || warningType === "PAUSED") && <VoiceFingerprint hideHasFingerprintComp trigger={trigger} />}
                {(warningType === "MANAGER") && <Button theme="transparent" loading={reminding} onClick={sendReminder}>Send Reminder 📧</Button>}
            </div>
        </div>
    );
}

VoiceFingerprintWarning.propTypes = {}
VoiceFingerprintWarning.defaultProps = {}
export default VoiceFingerprintWarning;