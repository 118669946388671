import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useHoverAndMoveListenerRef } from '../../utils/hooks/useHoverListener';
import { _formatSecondsToPlayingTime } from '../../utils/helpers';
import { fetchDataSafely } from '../../utils/dataUtils';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getMatchingThumbnailSprite, getMatchingThumbnailSpriteForSharedCall } from '../../reducers/call';

const exampleSprite = {
    spriteFile: "a44a6ba2-f6c7-44e4-a5f8-c2eb659339b5-sprite-thumb.jpg",
    images: [...Array(48).keys()].map(i => {
        return {
            x: (i % 6) * 220,
            y: Math.floor(i / 6) * 124,
            w: 220,
            h: 124
        };
    })
}

function ThumbnailViewer({ handleClick, duration, showPointerLine, isSharedCall, progressBarWithBookmark }) {
    const timeline = useRef(null);
    const thumbnail = useRef(null);
    const thumbnailDiv = useRef(null);
    const [showThumbnail, setShowThumbnail] = useState(false);
    const [thumbnailTime, setThumbnailTime] = useState(null);
    const [pointerLeft, setPointerLeft] = useState(0);
    const call = useSelector(store => store.call);
    const thumbnailSprite = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.thumbnailSprite", null);

    useHoverAndMoveListenerRef(timeline, handleTimelineHover, handleTimelineUnhover, handleTimelineMove, 100);

    function handleTimelineHover(e) {
        setThumbnailStyle(e);
        console.log("setting show thumbnail to true;");
        setShowThumbnail(true);
    }

    function handleTimelineMove(e) {
        setThumbnailStyle(e);
    }

    function setThumbnailStyle(e) {
        const left = (e.clientX - timeline.current.getBoundingClientRect().x - 110);
        setPointerLeft(e.clientX - timeline.current.getBoundingClientRect().x);
        if (thumbnail.current) {
            const skippPointRate = (e.clientX - timeline.current.getBoundingClientRect().x) / timeline.current.clientWidth;
            setThumbnailTime(duration * skippPointRate);
            let matchingSprite = null;
            if (isSharedCall) {
                matchingSprite = getMatchingThumbnailSpriteForSharedCall(thumbnailSprite.images, call.shareDetails, duration * skippPointRate);
            } else {
                matchingSprite = getMatchingThumbnailSprite(thumbnailSprite.images, duration, skippPointRate);
            }
            console.log("matching sprite found:", matchingSprite);
            if (!matchingSprite) return;
            const offsetX = -1 * matchingSprite.x;
            const offsetY = -1 * matchingSprite.y;
            thumbnail.current.style = "object-position: " + offsetX + "px " + offsetY + "px;" + "height:" + matchingSprite.h + "px; width:" + matchingSprite.w + "px;";
            thumbnailDiv.current.style = "left:" + (left + timeline.current.getBoundingClientRect().left) + "px;" + "height:" + matchingSprite.h + "px; width:" + matchingSprite.w + "px;" + "top:" + (timeline.current.getBoundingClientRect().top - 134) + "px";
        }
    }

    function handleTimelineUnhover(e) {
        console.log("setting show thumbnail to false");
        setShowThumbnail(false);
    }

    return (
        <>
            <div onMouseDown={handleClick} ref={timeline} className={`Timeline__thumbnailCatchmentArea ${progressBarWithBookmark && "Timeline__thumbnailCatchmentAreaBookmark"}`} >
                {showPointerLine && <div className={`Timeline__pointerLine bg-brand-blue-1 ${showThumbnail ? "" : "hidden"}`} style={{ left: pointerLeft }}></div>}
            </div>
            {
                thumbnailSprite && <div ref={thumbnailDiv} className={`${showThumbnail ? "" : "hidden"} Timeline__thumbnail`}>
                    <div className="Timeline__thumbnailInner">
                        <img ref={thumbnail} src={"https://strings-data.s3.amazonaws.com/video/" + thumbnailSprite.spriteFile} className={`Timeline__thumbnailIcon`} />
                        <span className="Timeline__thumbnailTime">{_formatSecondsToPlayingTime(thumbnailTime)}</span>
                    </div>
                </div>
            }
        </>
    );
}
ThumbnailViewer.propTypes = {}
ThumbnailViewer.defaultProps = {
    showPointerLine: true,
    isSharedCall: false,
    progressBarWithBookmark: false,
}
export default ThumbnailViewer;