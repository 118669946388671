import React from 'react';
import { Menu, MenuItem } from 'app/designSystem/menu'

export default function FolderMenu({ handleClose, isOpen, anchorEl, handleRemove }) {
    return (
        <Menu
            list={[
                <MenuItem label="Delete" onClick={handleRemove} />
            ]}
            open={isOpen}
            minWidth={125}
            onClose={() => handleClose()}
            menuPosition="right-top"
            anchorEl={anchorEl}
            onClick={(e) => {
                handleClose();
                e.stopPropagation();
            }}
        />
    )
}