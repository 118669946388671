import React from "react";
import PropTypes from "prop-types";
import { HistogramBar } from "app/components/dashboards/histogram/HistogramBar";
import sortIcon from "app/styles/assets/images/icons/sort.png";
import sortedIcon from "app/styles/assets/images/icons/sorted.png";
import descSortedIcon from "app/styles/assets/images/icons/descSorted.png";
import { withSortHistogramChart } from "app/components/dashboards/histogram/withSortHistogramChart";

const getChartColor = color => {
  switch (color) {
    case "purple":
      return "#2BAE22";
    case "blue":
      return "#2E5BFF";
    case "green":
      return "#2BAE22";
    default:
      return color;
  }
};

const HistogramChart = props => (
  <HistogramChartContainer>
    <ChartHeaders
      left={props.leftHeader}
      right={props.rightHeader}
      sort={props.sort}
      sortState={props.sortState}
    />
    {props.data.map((dataPoint, i) => (
      <HistogramBar
        key={i}
        color={getChartColor(props.color)}
        size={dataPoint[props.fxDataKey]}
        leftLabel={dataPoint[props.xDataKey]}
        rightLabel={dataPoint[props.fxDataKey] + props.unit}
        title={`Occurred in ${dataPoint.count} out of ${dataPoint.total} calls`}
      />
    ))}
    {props.data.length === 0 && <span>No Data Found.</span>}
  </HistogramChartContainer>
);

const HistogramChartContainer = props => (
  <div className="HistogramChart__container">{props.children}</div>
);

const ChartHeaders = props => (
  <div className="HistogramChart__chartHeaders">
    <div className="HistogramChart__chartHeaders__left">{props.left}</div>
    <div className="HistogramChart__chartHeaders__right">
      {props.right}
      <SortButton onClick={props.sort} sortState={props.sortState} />
    </div>
  </div>
);

const SortButton = props => (
  <div className="HistogramChart__sortButton" onClick={props.onClick}>
    <img
      src={
        props.sortState === null
          ? sortIcon
          : props.sortState === "ASC"
            ? sortedIcon
            : descSortedIcon
      }
      alt="sort"
      className="HistogramChart__sortIcon"
    />
  </div>
);

HistogramChart.propTypes = {
  leftHeader: PropTypes.string,
  rightHeader: PropTypes.string,
  data: PropTypes.array.isRequired,
  color: PropTypes.string,
  xDataKey: PropTypes.string.isRequired,
  fxDataKey: PropTypes.string.isRequired,
  unit: PropTypes.string,
  changeRate : PropTypes.number,
  avg : PropTypes.number
};

HistogramChart.defaultProps = {
  leftHeader: "",
  rightHeader: "",
  color: "lightgrey",
  unit: "nos",
  avg: 0,
  changeRate: 0,
};

export default withSortHistogramChart(HistogramChart);
