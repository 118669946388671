import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getContactName, _formatTalkListenRatio } from "../../utils/helpers";
import play from "app/styles/assets/images/icons/player/play.svg";
import pause from "app/styles/assets/images/icons/player/pause.svg";
import { useHoverListenerRef } from "../../utils/hooks/useHoverListener";
import { useDispatch, useSelector } from "react-redux";
import { SET_SELECTED_SPEAKERS } from "../../actions/actionTypes";
import ThumbnailViewer from "./ThumbnailViewer";

const PlayerSpeakerTimeline = props => {

    //Set customer name based on context, if call is a shared call then use the customer name from the shareDetailsDocument,or else pick customerName of loggedin user
    const selectCustomerName = () => {
        let customerName = null

        if (props.isSharedCall) {
            customerName = useSelector(store => store?.call?.shareDetails?.customerName || store?.calll?.shareDetails?.authorizedDomains?.[0])
        }
        else {
            customerName = useSelector(store => store?.auth?.stringsCustomer?.name || store?.auth?.stringsCustomer?.authorizedDomains[0]);
        }

        return customerName
    }

    const [selected, setSelected] = useState(true);
    const [specificallySelected, setSpecificallySelected] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(false);
    const [accountName, setAccountName] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [speakerTimelineRef] = useHoverListenerRef(() => setShowPlayIcon(true), () => setShowPlayIcon(false), 400);
    const dispatch = useDispatch();
    const [speaker, setSpeaker] = useState(null);
    const [jobTitle, setjobTitle] = useState(null);
    const customerName = selectCustomerName()



    useEffect(() => {
        let waveforms = props.progressProps?.waveformTurns;
        if (props.speaker && waveforms) {
            for (let i = 0; i < waveforms.length; i++) {
                if (waveforms[i].speaker == props.speaker) {
                    if (waveforms[i].type == 'opportunity') {
                        setSpeaker(props.contact ? getContactName(props.contact) : props.speaker);
                        setjobTitle(props.contact?.jobTitle);
                        setCompanyName(accountName);
                    }
                    else {
                        setSpeaker(props.speaker);
                        setjobTitle(props.jobTitle);
                        setCompanyName(customerName);
                    }
                    break;
                }
            }
        }
    }, [props.speaker, props.contact, accountName])

    useEffect(() => {
        try {
            if (props.accountName) setAccountName(props.accountName);
            else if (props.contact && props.contact.email) {
                setAccountName(props.contact.email.split("@")[1]);
            }
        }
        catch (e) {
            console.log("Couldn't set account name: ", e);
            setAccountName("");
        }
    }, [props.accountName]);

    useEffect(() => {
        setSelected(!props.selectedSpeakers || props.selectedSpeakers.includes(props.id));
        setSpecificallySelected((!props.selectedSpeakers || props.selectedSpeakers.includes(props.id)) && props.selectedSpeakers && props.selectedSpeakers.length === 1);
    }, [props.selectedSpeakers, props.id]);

    const timeline = useRef(null);

    function handleClick(e) {
        e.persist();
        const skippPointRate = (e.clientX - timeline.current.getBoundingClientRect().x) / timeline.current.clientWidth;
        props.progressProps.onSkipPress(skippPointRate);
    }

    function handlePlayerSelection() {
        dispatch({ type: SET_SELECTED_SPEAKERS, selectedSpeakers: [props.id] })
        props.howlerProps.setPlay();
    }

    function handlePlayerDeselection() {
        dispatch({ type: SET_SELECTED_SPEAKERS, selectedSpeakers: null })
        props.howlerProps.setPause();
    }

    return (
        <div ref={speakerTimelineRef} className={`Timeline ${!selected ? "Timeline__speakerRow__unselected" : ""}`}>
            <div className="Timeline__speakerRow">
                <div className="row-centered">
                    <span className="Timeline__speaker">{speaker}</span>
                    {jobTitle && <span className="Timeline__speakerTitle">{jobTitle}</span>}
                    {jobTitle && companyName && <div className="CallTable__dividerDot" />}
                    {companyName && <span className="Timeline__speakerTitle Timeline__speakerTitleAccount">{companyName}</span>}
                </div>
                {!specificallySelected && showPlayIcon && <img src={play} onClick={handlePlayerSelection} className="Timeline__speakerSelectIcon" />}
                {specificallySelected && <img src={pause} onClick={handlePlayerDeselection} className="Timeline__speakerSelectIcon" />}
                {props.tlRatio && <span className="Timeline__speakerPercentage">{_formatTalkListenRatio(props.tlRatio)}</span>}
            </div>
            <div className="Timeline__progress" ref={timeline} onMouseDown={handleClick}>
                <ThumbnailViewer
                    duration={props.howlerProps.duration}
                    handleClick={handleClick}
                    isSharedCall={props.progressProps.isSharedCall}
                />
                <div className="Timeline__progress-background" />
                <div className="Timeline__progress-spectrum">
                    {props.progressProps.waveformTurns.map(waveform => {
                        if (props.speaker && waveform.speaker !== props.speaker) {
                            return null;
                        }
                        if (waveform.type === "opportunity") {
                            return (
                                <div
                                    key={waveform.id}
                                    style={{
                                        width: `${waveform.width}%`,
                                        left: `${waveform.left}%`,
                                    }}
                                    className="Timeline__progress-spectrum-waveform Timeline__progress-spectrum-opportunity"
                                />
                            );
                        }
                        return (
                            <div
                                key={waveform.id}
                                style={{
                                    width: `${waveform.width}%`,
                                    left: `${waveform.left}%`,
                                }}
                                className="Timeline__progress-spectrum-waveform Timeline__progress-spectrum-agent"
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default PlayerSpeakerTimeline;
