import React from "react";
import aircallIcon from "app/styles/assets/images/integrations/aircall.png";
import zoomIcon from "app/styles/assets/images/integrations/zoom.png";
import freshcallerIcon from "app/styles/assets/images/integrations/freshcaller.png";
import outreachIcon from "app/styles/assets/images/integrations/outreach.png";
import googleMeetIcon from "app/styles/assets/images/integrations/googleMeets.png";
import goToMeetingIcon from "app/styles/assets/images/integrations/goToMeeting.png";
import hubspotIcon from "app/styles/assets/images/integrations/hubspot.png";
import phoneIcon from "app/styles/assets/images/integrations/phoneGeneric.png";
import recordingIcon from "app/styles/assets/images/integrations/recording.png";
import ringCentral from "app/styles/assets/images/integrations/ringCentral.png";
import blueJeansIcon from "app/styles/assets/images/integrations/blueJeans.png";
import salesloftIcon from "app/styles/assets/images/integrations/salesloft.webp";
import msTeamsIcon from "app/styles/assets/images/integrations/teams.png";
import ringcentralMeetings from "app/styles/assets/images/integrations/ringcentralMeetings.png";
import IncomingEmailIcon from "app/styles/assets/images/icons/dealView/IncomingEmailIcon.svg";
import OutgoingEmailIcon from "app/styles/assets/images/icons/dealView/OutgoingEmailIcon.svg";
import dialpadIcon from "app/styles/assets/images/integrations/dialpad.svg";
import frontspinIcon from "app/styles/assets/images/integrations/frontspin.png";
import closeIcon from "app/styles/assets/images/integrations/close-logo.png";
import classNames from "classnames";

const MeetingIconImage = ({ callType }) => {
  return <img alt="" src={getMeetingIcon(callType)} className="h-6 w-6 " />;
};

export function getInteractionicon(interactionType) {
  switch (interactionType) {
    case "incomingEmail":
      return IncomingEmailIcon;
    case "outgoingEmail":
      return OutgoingEmailIcon;
    default:
      return getMeetingIcon(interactionType);
  }
}

export function getMeetingIcon(callType) {
  switch (callType) {
    case "AIRCALL_RECORDING":
    case "AIRCALL RECORDING":
      return aircallIcon;
    case "FRESHCALLER":
    case "FRESHCALLER_LIVE":
    case "FRESHCALLER LIVE":
      return freshcallerIcon;
    case "GENERIC":
      return phoneIcon;
    case "GOOGLE_MEET":
    case "GOOGLE MEET":
      return googleMeetIcon;
    case "GOTO_MEETING":
    case "GOTO MEETING":
      return goToMeetingIcon;
    case "RINGCENTRAL_MEETING":
    case "RINGCENTRAL MEETING":
      return ringcentralMeetings;
    case "RINGCENTRAL":
      return ringCentral;
    case "OUTREACH":
      return outreachIcon;
    case "ZOOM":
    case "ZOOM_PHONE":
    case "ZOOM PHONE":
      return zoomIcon;
    case "HUBSPOT":
    case "HUBSPOT LIVE":
    case "HUBSPOT_LIVE":
      return hubspotIcon;
    case "ZOOM_CLOUD_RECORDING":
    case "ZOOM CLOUD RECORDING":
      return zoomIcon;
    case "BLUE_JEANS":
      return blueJeansIcon;
    case "BLUE JEANS":
      return blueJeansIcon;
    case "SALESLOFT":
      return salesloftIcon;
    case "MS_TEAMS":
    case "MS TEAMS":
      return msTeamsIcon;
    case "DIALPAD":
    case "DIALPAD_LIVE":
    case "DIALPAD LIVE":
      return dialpadIcon;
    case "RECORDING":
      return recordingIcon;
    case "FRONTSPIN":
      return frontspinIcon;
    case "CLOSEIO":
    case "CLOSE":
      return closeIcon;
    default:
      return phoneIcon;
  }
}

const MeetingIcon = ({ callType, className }) => (
  <div
    className={classNames(
      "h-10 w-10 flex items-center justify-center bg-coolGray-100 mx-1 rounded-full",
      className
    )}
  >
    <MeetingIconImage callType={callType} />
  </div>
);

export default MeetingIcon;
