import React from "react";
import { ProfileDropDownMenuItem } from "./ProfileDropdown";
import { HelpCenterIcon, SupportArticlesIcon, AcademyIcon } from "app/designSystem";
import { CustomDropdown } from "../common";
import helpCenterAnimation from "app/styles/assets/animations/helpCenterAnimation.json"
import Lottie from "react-lottie";
import classNames from "classnames";

const HelpCenterDropdown = ({ className }) => {


    const menuButtons = [
        <ProfileDropDownMenuItem
            to=""
            onClick={() => { window.open("https://academy.trywingman.com/?utm_source=Webapp&utm_medium=SupportButton&utm_campaign=Academy") }}
            label="Academy"
            icon={<AcademyIcon />}
            id="academyButton"
        />,

        < ProfileDropDownMenuItem
            to=""
            onClick={() => { window.open("https://support.trywingman.com/en?utm_source=Webapp&utm_medium=SupportButton&utm_campaign=Articles") }}
            label="Help center"
            icon={<SupportArticlesIcon />}
            id="supportArticlesButton"
        />
    ];


    return (
        <>
            <CustomDropdown bubbleClass="flex  flex-col items-center justify-between  p-2.5 border border-solid border-coolGray-200 bg-white  rounded-lg" className={classNames(className)} right={1} top={35} title={<HelpCenterIcon />} list={menuButtons} />
        </>
    );


}

export default HelpCenterDropdown