import React from "react";
import NoNotesIcon from "app/styles/assets/images/icons/noNotes.svg";
import NoHighlightsIcon from "app/styles/assets/images/icons/noHighlights.svg";
import NoSearchResultsIcon from "app/styles/assets/images/icons/noSearchResultsInCall.svg";

export const NoNotes = () => {
  return (
    <div className="NoCommentsContainer">
      <img className="icon" src={NoNotesIcon} alt="No Notes" />
      <span className="title">No Notes</span>
      <span className="copy">You have not added any notes for this call.</span>
      <br />
      <span className="copy">
        {
          "Use this section to take notes after a call and publish them to your CRM and improve your CRM hygiene"
        }
      </span>
    </div>
  );
};


export const NoHighlights = ({ copy }) => {
  if (!copy) copy = "There were no highlights found for this section.";
  return (
    <div className="NoCommentsContainer">
      <img className="icon" src={NoHighlightsIcon} alt="No Notes" />
      <span className="title">No highlights</span>
      <span className="copy">{copy}</span>
    </div>
  );
};

export const EmptySearchState = ({ copy }) => {
  if (!copy) copy = "Search for a keyword to find highlights or transcripts in this call.";
  return (
    <div className="NoCommentsContainer">
      <img className="icon" src={NoSearchResultsIcon} alt="No Results" />
      <span className="title">What you're looking for?</span>
      <span className="copy">{copy}</span>
    </div>
  );
};
