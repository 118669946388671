import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Grid, Checkbox, Table, IconButton } from "@material-ui/core";
import CustomTooltip from "app/components/common/CustomTooltip";
import {
  CustomSelect,
  ConfirmationDialog,
  Card,
} from "app/components/common";
import {
  getAllUsersByCustomerId,
  setAdmin,
  setRoleByAdmin,
  setRecordingUser,
  assignManager,
  refreshUser,
  deleteUser,
  useNetworkRequest,
  sendRequest,
} from "app/utils/network";
import { setStackedScreenName, setScreenType } from "app/actions/screen";
import { ReactComponent as ExcelIcon } from "app/styles/assets/images/icons/excel.svg";
import { refreshUserAction } from "app/actions/auth";
import { MANAGER, REP, OBSERVER } from "app/constants";
import AddUserIcon from "@material-ui/icons/PersonAddRounded";
import { TableHeaders } from "../table/TableHeader";
import InviteUserFormModal from "../roleManagement/InviteUserModal";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { downloadFile } from "../../utils/dataUtils";
import { TableSkeleton } from "app/components/common/skeleton/TableSkeleton";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import GroupIcon from '@material-ui/icons/Group';
import { TextBase, DeleteV2Icon, Button } from "app/designSystem";

const RoleManagement = props => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [managers, setManagers] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [mapManagerIdToName, setMapManagerIdToName] = useState({});
  const [userToDelete, setUserToDelete] = useState();
  const { setSnackbar, hideSnackbar } = useSnackbar();
  const [userTree, setUserTree] = useState(null);
  const [usersMap, setUsersMap] = useState(null);
  const { fetchFromFunc } = useNetworkRequest();
  const [deletingUserEmail, setDeletingUserEmail] = useState("");
  const isSsoDirectoryEnabled = useSelector(store => store.auth?.data?.user?.stringsCustomer?.featuresGated?.ssoDirectoryEnabled);


  useEffect(() => {
    refreshContent();
  }, []);


  function setInviteUserVisiblity(bool) {
    setShowInviteUserModal(bool);
  }

  function openDeleteMessage(userId) {
    setUserToDelete(userId);
    setShowDeleteDialog(true);
  }

  function setDeleteDialog(bool) {
    setShowDeleteDialog(bool);
  }

  function countNodes(userId, tree, usersMap) {
    if (usersMap[userId].nodeCount) return usersMap[userId].nodeCount;
    if (!tree[userId]) return usersMap[userId].nodeCount = 1;
    return usersMap[userId].nodeCount = tree[userId].reduce((p, ci) => p + countNodes(ci, tree, usersMap), 1);
  }

  function fillResult(userId, usersMap, result, visited, tree, level) {
    if (visited[userId]) return;
    visited[userId] = true;
    if (userId) {
      var user = usersMap[userId];
      user.level = level;
      result.push(user);
    }
    if (tree[userId]) {
      const childNodeCounts = {};
      tree[userId].forEach(cid => childNodeCounts[cid] = usersMap[cid].nodeCount);
      const userByReportCount = Object.entries(childNodeCounts).sort((nca, ncb) => {
        const d = nca[1] - ncb[1];
        if (d !== 0) return -d / Math.abs(d);
        if (usersMap[nca[0]].displayName === usersMap[ncb[0]].displayName) return 0;
        return usersMap[nca[0]].displayName < usersMap[ncb[0]].displayName ? -1 : 1;
      }).map(a => a[0]);
      userByReportCount.map(cid => fillResult(cid, usersMap, result, visited, tree, level + 1));
    }
  }

  function constructUserTree(users) {
    const localUsersMap = {};
    const localUserTree = {};
    users.forEach(u => localUsersMap[u.userId] = u);
    users.forEach(u => {
      const userId = u.userId;
      var managerId = u.roleInfo && u.roleInfo.managerId;
      if (managerId === userId) {
        console.log('Ignoring manager of ' + userId + ' as it is themself');
        managerId = null;
      }
      if (!localUsersMap.hasOwnProperty(managerId)) managerId = null;
      if (!localUserTree[managerId]) localUserTree[managerId] = [];
      localUserTree[managerId].push(userId);
    });
    setUsersMap(localUsersMap);
    setUserTree(localUserTree);
    users.forEach(u => countNodes(u.userId, localUserTree, localUsersMap));
    const visited = {};
    const result = [];
    fillResult(null, localUsersMap, result, visited, localUserTree, 0);
    return result;
  }

  async function refreshContent() {
    setLoading(true)
    try {
      const res = await refreshUser();
      const userData = res.user;
      if (userData.stringsCustomer) {
        const { customerId } = userData.stringsCustomer;
        const tempUsers = await getAllUsersByCustomerId(customerId);
        const tempManagers = [];
        tempUsers.map(user => {
          if (user.roleInfo && user.roleInfo.role === MANAGER) {
            tempManagers.push({ label: user.displayName || user.emailId, value: user.userId, imageUrl: (user.imageUrl ? user.imageUrl : null) });
          }
        });
        const tempMapManagerIdToName = {};
        tempManagers.map(manager => tempMapManagerIdToName[manager.id] = manager.displayName || manager.emailId);
        setUsers(constructUserTree(tempUsers));
        tempManagers.push({ value: "", label: "None" });
        tempMapManagerIdToName["None"] = "None";
        setManagers(tempManagers);
        setMapManagerIdToName(tempMapManagerIdToName);
      }
    } catch (error) {
      console.log("Error refreshing content:", error);
    }
    setLoading(false);
  }

  function userAdded() {
    refreshContent();
    setInviteUserVisiblity(false);
    setSnackbar("Invited User.", "SUCCESS");
  }

  async function handleRoleChange(user, role) {
    const { userId } = props.auth.data.user;
    if (user.roleInfo == null || user.roleInfo.role !== role) {
      setLoading(true);
      await setRoleByAdmin(user.userId, role);
      await refreshContent();
      if (userId === user.userId) {
        props.refreshUserAction();
      }
      setLoading(false);
    }
  }

  async function handleAdminChange(user, value) {
    if (
      user.roleInfo == null
      || (user.roleInfo.admin && value === false)
      || (!user.roleInfo.admin && value === true)
    ) {
      setLoading(true);
      await fetchFromFunc(() => setAdmin(user.userId, value));
      await refreshContent();
      setLoading(false);
    }
  }

  async function handleRecordingChange(user, value) {
    if (
      user.roleInfo == null
      || (user.roleInfo.recording && value === false)
      || (!user.roleInfo.recording && value === true)
    ) {
      setLoading(true);
      await setRecordingUser(user.userId, value);
      await refreshContent();
      setLoading(false);
    }
  }

  async function handleManagerChange(user, managerId) {
    if (user.roleInfo == null || user.roleInfo.managerId != managerId) {
      setLoading(true);
      await assignManager(
        user.userId,
        managerId,
        mapManagerIdToName[managerId],
      );
      await refreshContent();
      setLoading(false);
    }
  }

  async function handleIntegrationStatusDownload() {
    setSnackbar("Downloading report", "LOADING");
    const response = await sendRequest("/admin/get_integration_stats");
    downloadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "IntegrationStatus.xlsx");
    hideSnackbar();
  }

  async function handleDeleteUser() {
    setLoading(true);
    console.log(userToDelete);
    setDeleteDialog(false);
    await deleteUser(userToDelete);
    setSnackbar("Deleted User.", "SUCCESS");
    refreshContent();
  }

  function hideChildren(userId, hide) {
    if (!userTree[userId]) return;
    for (var i = 0; i < userTree[userId].length; i++) {
      const childId = userTree[userId][i];
      const child = usersMap[childId];
      child.hidden = hide;
      child.childrenHidden = hide;
      hideChildren(childId, hide);
    }
  }

  function toggleCollapse(userId, hide) {
    hideChildren(userId, hide);
    usersMap[userId].childrenHidden = hide;
    setUsers([...users]);
  }

  function toggleButton(user) {
    if (user.childrenHidden) return <ArrowRightIcon
      viewBox="5 5 14 14"
      className="RoleManagement__table__collapseIcon"
      onClick={() => toggleCollapse(user.userId, false)} />
    return <ArrowDropDownIcon
      viewBox="5 5 14 14"
      className="RoleManagement__table__collapseIcon"
      onClick={() => toggleCollapse(user.userId, true)} />
  }

  function getManagerId(userId) {
    const user = usersMap[userId];
    return user && user.roleInfo && user.roleInfo.managerId ? user.roleInfo.managerId : null;
  }

  function isReportingTo(managerId, userId, depth) {
    if (userId === null || managerId === null || depth > 100) return false;
    if (managerId === userId) return true;
    return isReportingTo(getManagerId(managerId), userId, depth + 1);
  }

  function getManagersForUser(user) {
    return managers.filter(m => !isReportingTo(m.value, user.userId, 0));
  }

  async function sendInviteAgain(user) {
    const params = new URLSearchParams();
    params.append("email", user.emailId.trim());
    await sendRequest("/admin/inviteUserAgain", params);
    setSnackbar("Invited User Again.", "SUCCESS");
  }
  const headerLabels = [
    { label: "Active Users", value: "users", sort: false },
    { label: "Email Address", value: "email", sort: false },
    { label: "Role", value: "role", sort: false },
    { label: "Manager", value: "manager", sort: false },
    { label: "Recording User", value: "recording", sort: false },
    { label: "Admin", value: "admin", sort: false },
    { label: "", value: "", sort: false },
  ];

  const headerProps = { disableSort: true };

  function confirmDeleteUserModal(user) {
    openDeleteMessage(user.userId)
    setDeletingUserEmail(user.emailId);
  }

  return (
    <div style={{ overflowX: "scroll" }}>
      <Card
        className="RoleManagement__card"
        title="Current Roles"
        headActions={
          <div className="flex flex-row">
            <Button
              className="mr-3 h-9"
              variant="secondary"
              onClick={handleIntegrationStatusDownload}
              disabled={!props.auth.isAdmin}
            >
              <ExcelIcon className="h-5" />
              <span>Onboarding Status Report</span>
            </Button>
            <Button
              className="h-9"
              variant="primary"
              onClick={() => setInviteUserVisiblity(true)}
              disabled={!props.auth.isAdmin}
            >
              <AddUserIcon fontSize="small" />
              <span className="ml-1">Invite User</span>
            </Button>
          </div>
        }
      >
        <Grid container spacing={4} className="Manager-Dashboard">
          <InviteUserFormModal
            hide={() => setInviteUserVisiblity(false)}
            open={showInviteUserModal}
            managers={managers}
            userAdded={userAdded}
          />
          <Grid item xs={12} className="Manager-Dashboard__agents-performance !px-6">
            <Table>
              <TableHeaders
                headerLabels={headerLabels}
                headerProps={headerProps}
              />
              {loading ?
                <TableSkeleton rowSize={10} />
                :
                <tbody>
                  {users.map((user, i) => {
                    const admin = user.roleInfo && user.roleInfo.admin;
                    const role = user.roleInfo && user.roleInfo.role;
                    const manager = role === MANAGER;
                    const isRecording = user.roleInfo && user.roleInfo.recording;
                    const hasManager = user.roleInfo && user.roleInfo.managerId;
                    const workosId = user.workosUserId;

                    return (
                      <tr key={i} className={`RoleManagement__table__row ${user.hidden ? "hidden-row" : ""}`}>
                        <td>
                          <div className={`RoleManagement__table__userName RoleManagement__table__level${user.level}`}>
                            {user.nodeCount > 1 && toggleButton(user)}
                            {user.displayName || user.emailId}
                            {!user.emailVerified &&
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <span className="CallTable__detailsText"><i>Invited</i></span>
                                <div className="CallTable__dividerDot" />
                                <a onClick={() => sendInviteAgain(user)} > <span style={{ textDecoration: "underline", color: "#5C7AEA", display: "flex" }} className="CallTable__detailsText">Resend Invite</span></a>
                              </div>
                            }
                            {user.nodeCount > 1 && <span className="RoleManagement__table__teamSize"><GroupIcon className="RoleManagement__table__teamIcon" />{user.nodeCount - 1}</span>}
                          </div>
                        </td>
                        <td>
                          <div className="RoleManagement__table__email">
                            {user.emailId}
                          </div>
                        </td>

                        <CustomTooltip
                          title={"You can't change your role here because SSO is enabled"}
                          disableHoverListener={!workosId}>
                          <td>
                            <CustomSelect
                              menuItems={[
                                { value: REP, label: "Rep" },
                                { value: MANAGER, label: "Manager" },
                                { value: OBSERVER, label: "Observer" },
                              ]}
                              value={role}
                              onChange={e => handleRoleChange(user, e.target.value)}
                              disabled={!props.auth.isAdmin || workosId}
                            />
                          </td>
                        </CustomTooltip>

                        <td>
                          {role !== OBSERVER && (
                            <CustomTooltip
                              title={"You can't change your manager here because SSO is enabled"}
                              disableHoverListener={!workosId}>
                              <div>
                                <CustomSelect
                                  menuItems={getManagersForUser(user)}
                                  placeholder="None"
                                  value={hasManager ? user.roleInfo.managerId : ""}
                                  onChange={e => handleManagerChange(user, e.target.value)}
                                  disabled={!props.auth.isAdmin || !!workosId}
                                />
                              </div>
                            </CustomTooltip>
                          )}

                          {role === OBSERVER && ""}
                        </td>

                        <CustomTooltip
                          title={"You can't change your recording permission here because SSO is enabled"}
                          disableHoverListener={!workosId}>
                          <td>
                            <Checkbox
                              checked={isRecording}
                              onChange={e => handleRecordingChange(user, e.target.checked)}
                              value="recording"
                              disabled={!manager || !props.auth.isAdmin || !!workosId}
                              className="!text-brand-blue-1"
                            />
                          </td>
                        </CustomTooltip>
                        <CustomTooltip
                          title={"You can't change admin here because SSO is enabled"}
                          disableHoverListener={!workosId}>
                          <td>
                            <Checkbox
                              checked={admin}
                              onChange={e => handleAdminChange(user, e.target.checked)}
                              value="admin"
                              disabled={!props.auth.isAdmin || !!workosId}
                              className="!text-brand-blue-1"
                            />
                          </td>
                        </CustomTooltip>
                        <CustomTooltip
                          title={"You can't delete user because SSO is enabled"}
                          disableHoverListener={!workosId}>
                          <td>
                            <IconButton disabled={!props.auth.isAdmin || !!workosId} onClick={() => confirmDeleteUserModal(user)} className="hover:text-red-600">
                              <DeleteV2Icon />
                            </IconButton>
                          </td>
                        </CustomTooltip>
                      </tr>
                    );
                  })}
                </tbody>
              }
            </Table>
          </Grid>
        </Grid>
        <ConfirmationDialog
          title="Delete User"
          message={<DeleteUserMessage deletingUserEmail={deletingUserEmail} />}
          open={showDeleteDialog}
          onOk={handleDeleteUser}
          yesLabel="Yes, Delete user"
          noLabel="No, Keep user"
          onClose={() => setDeleteDialog(false)}
        />
      </Card >
    </div >
  );
}

const DeleteUserMessage = props => (
  <div>
    <TextBase textColor="text-red-900">Deleting user {props.deletingUserEmail}</TextBase>

    <br />
    <TextBase>Warning: This cannot be undone.</TextBase>
    <TextBase>Deleting a user will disable their integrations and prevent them from logging in.</TextBase>
    <br />
    <TextBase>
      <br />
      {' '}
      The user's calls will still be available.
      <br />
      {' '}
      Please email
      {" "}
      <a href="mailto: support@trywingman.com">support@trywingman.com</a>
      {' '}
      if you would like their calls deleted too.
    </TextBase>
  </div>
);

const mapStateToProps = store => {
  return { ...store };
};

RoleManagement.propTypes = {};

RoleManagement.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setScreenType,
    setStackedScreenName,
    refreshUserAction,
  },
)(RoleManagement);
