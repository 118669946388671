import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setFolderCreateModal, getGameTapesAllFolderData } from 'app/actions/gametapes'
import { FolderAccordian, getFolderData } from 'app/components/gameTapesLatest/helper'
import { CreateFolderModal } from 'app/components/gameTapesLatest/modal'
import { copyGameTape, moveGameTape, moveFolder } from 'app/utils/network/gametapes'
import { TextBase, Text2XL, Button } from 'app/designSystem'
import { CloseIcon } from 'app/designSystem/icons';
import { CircularProgress } from "@material-ui/core";
import { MAX_LEVEL_OF_FOLDERS } from 'app/containers/Folder';


const ClipboardActionsModal = ({ open, onClose, onCreate, gametape, folderId, action, type, ...props }) => {
    const dispatch = useDispatch();

    const foldersList = useSelector(state => getFolderData(state.gametapes.foldersList));
    const subFoldersCount = useMemo(() => foldersList?.filter(folder => folder.parentFolder === folderId).length, [foldersList]);

    const [loading, setLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState({ id: "ROOT", level: 0 });
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        dispatch(getGameTapesAllFolderData());
    }, [])

    const handleValidations = () => {
        if (type === 'Gametape') {
            if (selectedFolder.id === "ROOT") {
                setErrorMessage("GameTape can't be shifted to the main folder")
                return false;
            }

            if (selectedFolder.id === gametape.parentFolder && action === 'Move') {
                setErrorMessage("Gametape can't be moved to the same folder")
                return false;
            }
        }
        else {
            if (selectedFolder.id === folderId && action === 'Move') {
                setErrorMessage("Folder can't be moved to the same folder")
                return false;
            }

            if (subFoldersCount > 0 && selectedFolder.id !== "ROOT" && action === 'Move') {
                setErrorMessage("Folder with children can't be moved to subfolder")
                return false;
            }
        }

        setErrorMessage(null);
        return true;
    }

    const handleSubmit = async () => {
        // Validate the form
        if (!handleValidations()) {
            return;
        }

        setLoading(true);

        const parentFolderId = selectedFolder.id;

        if (action === 'Copy') {
            await copyGameTape({ gametapeId: gametape.id, parentFolderId });
        } else if (action === 'Move') {
            if (type === 'Folder') {
                await moveFolder({ folderId, parentFolderId });
            }
            else if (type === 'Gametape') {
                await moveGameTape({ gametapeId: gametape.id, parentFolderId });
            }
        }

        setLoading(false);
        onCreate?.();
    }

    const handleFolderCreationModalOpen = () => {
        dispatch(setFolderCreateModal(true));
    }

    const handleFolderCreationModalClose = () => {
        dispatch(setFolderCreateModal(false));
    }

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className="flex justify-center items-center"
            >
                <div className="flex flex-col p-4 bg-white rounded-lg" style={{
                    width: "30rem",
                    maxHeight: "calc(100vh - 6.25rem)",
                    padding: 0,
                    outline: "none"
                }}>
                    <div className="px-8 py-4">
                        <div className="flex justify-between w-full">
                            <Text2XL className="text-gray-900 font-medium">{action} {type}</Text2XL>
                            <CloseIcon className="cursor-pointer" onClick={onClose} />
                        </div>
                    </div>
                    <hr />
                    <div className="px-8 py-4">
                        <div className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border focus:outline-none overflow-y-auto h-72 p-2">
                            {foldersList && foldersList.length > 0 ? (
                                <FolderAccordian folderData={foldersList} setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} currentFolderId={folderId} type={type} />
                            ) : <CircularProgress size={50} color="primary" />}
                        </div>
                    </div>
                    <div className="px-8">
                        {errorMessage && <div className="flex justify-start items-center rounded mt-4 py-3 pl-5 pr-10 bg-red-50 cursor-pointer" onClick={() => { setErrorMessage("") }}>
                            <TextBase textColor="text-red-600" className="font-normal">{errorMessage}</TextBase>
                        </div>}
                    </div>
                    <div className="px-8 py-5 flex items-center justify-between">
                        <div>
                            {selectedFolder?.level <= MAX_LEVEL_OF_FOLDERS - 1 && <Button variant="secondary" onClick={handleFolderCreationModalOpen} className="mr-4">New Folder</Button>}
                        </div>
                        <div className="w-44 flex items-center">
                            <Button className="ml-2 flex-1" variant="secondary" onClick={onClose}>Cancel</Button>
                            <Button className="ml-2 flex-1" loading={loading} onClick={handleSubmit}>{action}</Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <CreateFolderModal
                folderId={selectedFolder.id}
                onClose={handleFolderCreationModalClose}
                onCreate={() => { handleFolderCreationModalClose(); dispatch(getGameTapesAllFolderData()); }}
            />
        </>
    )
}

ClipboardActionsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func,
    gametape: PropTypes.object,
    folderId: PropTypes.string,
    action: PropTypes.oneOf(["Copy", "Move"]),
    type: PropTypes.oneOf(["Folder", "Gametape"])
}

export default ClipboardActionsModal;