import React from 'react';
import { TextXL, TextBase } from 'app/designSystem'
import { EmptyFolderViewImage } from 'app/designSystem/icons'
import { FolderCards, CreateFolderBtn } from 'app/components/gameTapesLatest/helper';

export default function SubFolderView({ foldersData, handleFolderActions }) {

    if (foldersData?.length > 0) {
        return (
            <>
                <div className="flex flex-row justify-between px-4 py-2">
                    <TextXL className="text-gray-600">Folders</TextXL>
                    <div className="flex items-center">
                        <CreateFolderBtn />
                    </div>
                </div>
                <div className="flex flex-row flex-wrap">
                    {
                        foldersData?.map((folder) => <FolderCards key={folder.id} data={folder} handleFolderActions={handleFolderActions} />)
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <div className="flex flex-row justify-between px-4 py-2">
                <TextXL className="text-gray-600">Folders</TextXL>
                <div className="flex items-center">
                    <CreateFolderBtn />
                </div>
            </div>
            <div className="flex items-center justify-center w-full flex-col h-64">
                <EmptyFolderViewImage className="my-5 h-full" />
                <TextXL>No folders found</TextXL>
                <TextBase textColor="text-gray-400">You can add a folder by clicking the Create Folder above.</TextBase>
            </div>
        </>
    )
}