import React from 'react';
import { TextBase, TextSM } from 'app/designSystem';
import { Link } from "react-router-dom";
import { _formatSecondsToMinutes } from 'app/utils/helpers';
import { PlayIcon } from 'app/designSystem/icons';

const GameTapeExtraPanel = ({ gametapesData, currentTapeId }) => {
    const [currentGameTapeIndex, setCurrentGameTapeIndex] = React.useState(gametapesData.findIndex(gameTape => gameTape.id === currentTapeId));

    React.useEffect(() => {
        setCurrentGameTapeIndex(gametapesData.findIndex(gameTape => gameTape.id === currentTapeId));

        // Scroll to the current tape in the list
        const gameTapeList = document.getElementById('gameTapeList');
        if (gameTapeList) {
            gameTapeList?.children?.[currentGameTapeIndex]?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentTapeId]);

    return (
        <div className="flex flex-col border rounded-lg mt-4" style={{
            height: '375px',
        }}>
            <TextSM className="text-gray-500 my-4 mx-3"> Playlist - {currentGameTapeIndex + 1}/{gametapesData.length}</TextSM>
            <div className="overflow-auto" id="gameTapeList">
                {
                    gametapesData && gametapesData.map(({ gametape }, index) => {

                        const isPlaying = currentGameTapeIndex === index;

                        return (
                            <Link to={`/gametapes/video/${gametape.id}`} key={gametape.id} className={`flex items-center justify-start px-3 pb-2 ${isPlaying && 'bg-gray-100'}`} replace onClick={() => setCurrentGameTapeIndex(index)}>
                                <div className="w-4 mr-2">
                                    {
                                        isPlaying ? <PlayIcon className="max-w-full" style={{ color: "#2680EB" }} /> : <TextBase className="text-gray-500"> {index + 1} </TextBase>
                                    }
                                </div>
                                <img src={gametape.thumbnail} alt="" className="object-cover rounded" style={{
                                    height: '100%',
                                    width: '120px'
                                }} />
                                <div className="flex flex-col ml-4 my-1 w-full">
                                    <div className="h-10 overflow-hidden">
                                        <TextBase className="text-gray-900"> {gametape.name} </TextBase>
                                    </div>
                                    <div className="rounded mr-2 flex" style={{
                                        width: 'fit-content'
                                    }}>
                                        <TextBase className="text-gray-500"> {_formatSecondsToMinutes(gametape.duration, " mins")} </TextBase>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GameTapeExtraPanel;