import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import { TextBase, TextSM } from 'app/designSystem'
import { getTopViews } from "app/utils/network/gametapes";
import useDataHelper from "app/utils/hooks/useDataHelper";
import ProfileImage from 'app/components/common/ProfileImage';
import { setGameTapesFolderData } from 'app/actions/gametapes'
import { EmptyTopStatsViewImage, InfoIcon } from 'app/designSystem/icons'

const FolderExtraPanel = ({ folderId }) => {
    const dispatch = useDispatch();

    const { foldersList, cachedFoldersData } = useSelector(state => state.gametapes);

    const { getUserName } = useDataHelper();

    useEffect(() => {
        if (cachedFoldersData[folderId].order) {
            fetchTopViews();
        }
    }, [folderId])

    const fetchTopViews = async () => {
        const gametapesInCurrentFolder = cachedFoldersData[folderId].order;
        const subFolders = foldersList?.filter(folder => folder.parentFolder === folderId);
        const subFolderGametapesList = subFolders?.map(subFolder => subFolder?.order) ?? [];

        const data = await getTopViews([...gametapesInCurrentFolder, ...subFolderGametapesList]);

        if (data && data.result) {
            dispatch(setGameTapesFolderData(folderId, {
                ...cachedFoldersData[folderId],
                topViewers: data.result
            }))
        }
    }

    if (!cachedFoldersData[folderId]?.topViewers || cachedFoldersData[folderId]?.topViewers?.length === 0) {
        return <div className="h-full">
            <TextBase className="mt-4 bg-wingman-white rounded-t-xl text-gray-500 p-4 pb-0">Top Viewers</TextBase>
            <div className="overflow-y-auto bg-wingman-white rounded-b-xl p-4 pt-2">
                <hr className="mb-4" />
                <div className="flex flex-col items-center py-10">
                    <EmptyTopStatsViewImage className="w-28 h-28" />
                    <TextBase className="mt-4 text-center">No viewers currently!</TextBase>
                </div>
            </div>
        </div>;
    }

    return (
        <>
            <div className="flex items-end justify-start w-3/5">
                <TextBase className="mt-4 bg-wingman-white rounded-t-xl text-gray-500 p-4 pb-0 pr-1">Top Viewers</TextBase>
                <InfoIcon className="mr-auto h-[1.25rem] w-[1.25rem]" data-tip data-for={"TopViews_Info"} />
            </div>
            <ReactTooltip id={"TopViews_Info"} className="bg-gray-900 p-2 px-4 rounded">
                <TextSM textColor="text-gray-50">Top views also include <br /> views from subfolders</TextSM>
            </ReactTooltip>
            <div className="overflow-y-auto bg-wingman-white rounded-b-xl p-4 pt-2">
                <hr className="mb-4" />

                {
                    cachedFoldersData[folderId]?.topViewers?.map(({ user_id, viewed }, index) => {
                        return (
                            <div key={user_id + "__views__" + index} className="flex items-center justify-between mb-4">
                                <div className="flex items-center">
                                    <ProfileImage userId={user_id} size={26} disableTooltip={true} />
                                    <TextBase className="text-gray-500 ml-2">{getUserName(user_id)}</TextBase>
                                </div>
                                <div>
                                    <TextBase className="text-gray-500">{viewed} {viewed > 1 ? "videos" : "video"}</TextBase>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default FolderExtraPanel;