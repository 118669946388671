import React from 'react';
import { AccountViewSearchEmptyStateImage } from "app/designSystem/icons"
import { TextXL, TextBase } from "app/designSystem";

export default function AccountViewSearchEmptyState() {
    return (
        <div className="flex items-center justify-center flex-col min-h-full">
            <AccountViewSearchEmptyStateImage className="mb-6 h-36" />
            <TextXL>Sorry, we couldn’t find any matches for that</TextXL>
            <TextBase textColor="text-gray-400">Please try searching for another term</TextBase>
        </div>
    )
}