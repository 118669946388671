import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { _formatTalkListenRatio } from '../../utils/helpers';
import { getChartColorLines } from './helpers';
import LinearChart from './LinearChart';
import { InfoIcon } from 'app/designSystem';
import ShowTopicsModal from './showTopicsModal';
import ReactTooltip from 'react-tooltip';
const TeamDashboardMultiLineComparison = ({ maxVal, data, header, seed, avg, index, color, goToTeamCallLog, category, exactMatches, approxMatches }) => {
  if (!seed) seed = Math.random();
  function getColor(d, seed) {
    return (Math.floor((d + seed) * 360) % 360);
  }

  const [showTopicsModalIsOpen, setShowTopicsModalIsOpen] = useState(false);

  return (
    <div style={{ padding: '15px' }}>
      <div style={{ position: "relative" }}>
        <div className="TeamDashboard__TrackersHeading" style={{ marginBottom: "10px" }}>
          <div className=' flex items-center'>
            {category}
            <InfoIcon className='ml-1 cursor-pointer' onClick={() => { setShowTopicsModalIsOpen(true) }} />
            <ShowTopicsModal
              open={showTopicsModalIsOpen}
              category={category}
              close={() => setShowTopicsModalIsOpen(false)}
              exactMatches={exactMatches}
              approxMatches={approxMatches}
            />

          </div>
          <span
            style={{ display: "flex", marginLeft: "auto" }} className="LinkWithoutLine"
            onClick={() => goToTeamCallLog(category)}>
            View Calls
          </span>
        </div>
        <div style={{ overflowY: 'auto', maxHeight: '350px' }}>
          {data.map((item, i) =>
            <ComparisonItem
              key={item.name}
              name={item.name}
              value={item.value / item.maxVal}
              color={item.color ? color : getChartColorLines(index)} />)}
        </div>
        {avg != null && <span style={{ left: _formatTalkListenRatio(avg) }} className="TeamDashboard__TrackersAvgLine"></span>}
        {avg != null && <span style={{ left: "calc( -15px + " + _formatTalkListenRatio(avg) }} className="TeamDashboard__TrackersAvgLineValue">
          Avg: {_formatTalkListenRatio(avg)}
        </span>
        }
      </div>
    </div>
  );
}

const ComparisonItem = ({ name, value, color }) => {
  const colorLight = "#f5f6f7";
  return (<div style={{ padding: '5px 0px' }}>
    <div style={{ display: 'flex', flexDirection: 'row', position: "relative", zIndex: "3" }}>
      <span style={{ flexGrow: '1' }}>{name.charAt(0).toUpperCase() + name.slice(1)}</span>
      <span style={{ color }}>{Math.floor(value * 100)}%</span>
    </div>
    <div>
      <LinearChart items={[{ value, color }, { value: 1 - value, color: colorLight }]} itemStyle={{ borderRadius: "4px", height: "6px", opacity: "0.6" }} style={{ width: '100%', marginRight: '0px' }} />
    </div>
  </div>)
}

export default TeamDashboardMultiLineComparison;