import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Lottie from "react-lottie";
import disconnected from "app/styles/assets/animations/disconnectedAnimation.json";
import CancelIcon from "@material-ui/icons/Cancel";
import { useDispatch, useSelector } from 'react-redux';
import { HIDE_TRIAL_END_DIALOGUE } from '../../actions/actionTypes';

const TrialEnd = ({ }) => {
    const dispatch = useDispatch();
    const [showTrialEnd, setShowTrialEnd] = useState(false);
    const showTrialEndRedux = useSelector(s => s.screen.showTrialEndDialogue);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: disconnected,
    };

    useEffect(() => {
        if (showTrialEndRedux) {
            const timeLastHid = localStorage.getItem("hidTrialEndDialogue");
            if (!timeLastHid) setShowTrialEnd(true);
            else if (timeLastHid && new Date().getTime() - timeLastHid > (24 * 3600 * 1000)) {
                setShowTrialEnd(true);
            } else {
                console.log("not showing again as I just showed this 2 days back.");
                setShowTrialEnd(false);
            }
        } else {
            setShowTrialEnd(false);
        }
    }, [showTrialEndRedux])

    function onHide() {
        dispatch({ type: HIDE_TRIAL_END_DIALOGUE })
        localStorage.setItem("hidTrialEndDialogue", new Date().getTime());
    }



    if (!showTrialEnd) return null;

    return (
        <div className="TrialEnd">
            <div className="TrialEnd__hideDiv">
                <CancelIcon
                    onClick={onHide}
                    style={{ fontSize: 25, color: "#fff" }}
                />
            </div>
            <span className="TrialEnd__header">🙁Your trial has come to an end.</span>
            <Lottie options={defaultOptions} height={250} width={250} />
            <span className="TrialEnd__subheader">Wingman is no longer recording your team’s calls. Please ask your admins to reach out to their sales reps or email us at <b><u>sales@trywingman.com</u></b> to get going again.</span>
        </div>
    );
}
TrialEnd.propTypes = {}
TrialEnd.defaultProps = {}
export default TrialEnd;