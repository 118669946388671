import { connect } from "react-redux";
import React from 'react';
import { Paper } from "@material-ui/core";
import { _formatTalkListenRatio, _formatSecondsToPlayingTime } from '../../utils/helpers';
import LinearChart from './LinearChart';

const TeamDashboardLineComparison = props => {
  const maxValue = props.correlation.maxValue || Math.max(props.correlation.won.value, props.correlation.lost.value);

  function format(v) {
    if (v === "Infinity") return "Infinity";
    if (props.correlation.format === 'duration') return _formatSecondsToPlayingTime(v);
    if (props.correlation.format === 'number') return v.toFixed(1);
    return _formatTalkListenRatio(v);
  }

  function getLabel(label, color, count, valueText, value) {
    return <div style={{ display: 'flex', width: "100%", margin: '10px 0', flexDirection: "row", alignItems: "center" }}>
      <span style={{ margin: '0px 8px', borderRadius: '50%', width: '8px', height: '8px', backgroundColor: color }} />
      <span style={{ width: "100px", marginRight: "5px" }}>{label} ({count}):</span>
      <LinearChart items={[{ value, color }, { value: maxValue - value, color: '#F7F7F7' }]} style={{ display: "flex", width: 'calc(100% - 180px)' }} />
      <span style={{ display: "flex", width: "20px", marginLeft: "10px" }}>
        {valueText}
      </span>
    </div>;
  }

  return (<Paper style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
    <div style={{ fontSize: '18px', margin: '5px', textTransform: "capitalize" }}>
      {props.correlation.name.toLowerCase()}
    </div>
    <div style={{ margin: '5px 0' }}>
      {getLabel('Deals Won', '#4CD964', props.correlation.won.count, format(props.correlation.won.value), props.correlation.won.value)}
      {getLabel('Deals Lost', '#FD5181', props.correlation.lost.count, format(props.correlation.lost.value), props.correlation.lost.value)}
    </div>
  </Paper>);
}



export default connect(a => a, {})(TeamDashboardLineComparison);