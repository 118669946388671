import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import {
    Header, Input, Label
} from "app/components/form";
import { TextBase, TextLarge, Text2XL, CheckBox, TextSM, Toggle, Button } from "app/designSystem"
import { ReactComponent as CancelIcon } from "app/styles/assets/images/icons/closeCircle.svg";
import { ReactComponent as InfoIcon } from "app/styles/assets/images/icons/infoIcon.svg"
import { InformationDialog } from "app/components/common";
import Select from "react-select";
import TagFilledInputArea from "./TagFilledInputAreaForModal";
import {
    isWordMatchingThePattern,
    alphaNumericRegexPattern,
} from "app/utils/helpers";

export const options = {
    "ALL": { value: "ALL", label: "All" },
    "AGENT": { value: "AGENT", label: "Rep" },
    "CUSTOMER": { value: "CUSTOMER", label: "Customer" }
};
export const SemanticallySimilarMessage = props => {
    return (
        <div>
            <p>
                We will employ our state of the art NLP systems to find all phrases that
                mean the same thing.
            </p>
            <p>
                For example, “I don’t know this” can also be said as “I am not sure
                about this”.
            </p>
            <p>This is typically effective for longer phrases (4 or more words)</p>
        </div>
    );
};


const AddTopicModal = (props) => {

    const [topicName, setTopicName] = useState("");
    const [optionsLocal, setOptionsLocal] = useState(Object.values(options));
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [similarMatches, setSimilarMatches] = useState([]);
    const [exactMatches, setExactMatches] = useState([]);
    const [errorMessageForTopicName, setErrorMessageForTopicName] = useState(null);
    const [appliesTo, setAppliesTo] = useState({ value: "ALL", label: "All" });


    const handleTopicNameChange = (topic) => {
        setTopicName(topic)
        setErrorMessageForTopicName(null)
    }

    const handleClose = () => {
        handleClearFields();
        props.hide();
    }

    const handleChangesToExactMatches = (changes) => {
        setExactMatches(changes)
    }

    const handleChangesToSimilarMatches = (changes) => {
        setSimilarMatches(changes)
    }

    const validateTopicName = () => {
        if (topicName.length == 0) {
            setErrorMessageForTopicName("Topic name shouldn't be empty")
            return false
        }
        if (!isWordMatchingThePattern(topicName, alphaNumericRegexPattern)) {
            setErrorMessageForTopicName("Topic name shouldn't have special characters")
            return false
        }
        return true;

    }

    const handleAddTopic = () => {
        if (validateTopicName()) {
            props.addTopic(topicName, exactMatches, similarMatches, appliesTo.value)
            handleClose();
        }
    }

    const handleAppliesToChanges = (type) => {
        setAppliesTo(type)
    }

    const handleClearFields = () => {
        setTopicName("");
        setOptionsLocal(Object.values(options));
        setShowInfoDialog(false);
        setSimilarMatches([]);
        setExactMatches([]);
        setAppliesTo({ value: "ALL", label: "All" });
        setErrorMessageForTopicName(null)
    }

    return (
        <div>
            <Modal open={props.open} onClose={handleClose} className="Form__outerContainer">
                <div
                    className="!bg-white rounded-lg overflow-scroll"
                    style={{
                        width: "700px",
                        maxHeight: "calc(100vh - 6.25rem)",
                        padding: 0,
                        outline: "none"

                    }}
                >
                    <div className="px-8 py-5 flex justify-between items-center">
                        <TextLarge>Add Topic</TextLarge>
                        <button onClick={handleClose}>
                            <CancelIcon />
                        </button>
                    </div>

                    <hr />

                    <div className=" w-full flex flex-col px-8 mt-4">
                        <div className="flex justify-between items-center">
                            <TextBase textColor="text-coolGray-500 my-1">Topic Name</TextBase>
                            {errorMessageForTopicName &&
                                <TextBase textColor="text-rose-500 my-1">{errorMessageForTopicName}</TextBase>
                            }
                        </div>
                        <Input
                            value={topicName}
                            onChange={e => handleTopicNameChange(e.target.value)}
                            placeHolder={"Topic Name"}
                        />
                    </div>

                    <div className="w-full px-8 my-4">
                        <TextBase textColor="text-coolGray-500 my-1">Applies To</TextBase>
                        <Select options={optionsLocal} value={appliesTo} placeholder="Applies To" onChange={handleAppliesToChanges} />
                    </div>
                    {props.customerDocument.featuresGated.enableSemanticMatching &&
                        <>
                            <div className=" w-full flex flex-col px-8 mt-4">
                                <div className="flex justify-between items-center">
                                    <div className="flex items-center">
                                        <TextBase textColor="text-coolGray-500 my-1 mr-1">Semantically similar matches</TextBase>
                                        <InfoIcon
                                            onClick={() => setShowInfoDialog(true)}
                                            className="cursor-pointer"
                                        />
                                    </div>
                                    <TextSM textColor="text-coolGray-400 my-1">Please Enter To add</TextSM>
                                </div>
                                <TagFilledInputArea handleChangesToMatches={handleChangesToSimilarMatches}></TagFilledInputArea>
                            </div>

                            <InformationDialog
                                title="What is a Semantically Similar Match?"
                                message={<SemanticallySimilarMessage />}
                                open={showInfoDialog}
                                onClose={() => setShowInfoDialog(false)}
                            />
                        </>
                    }
                    <div className=" w-full flex flex-col px-8 mt-4">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">

                                <TextBase textColor="text-coolGray-500">
                                    Exact Matches
                                </TextBase>


                            </div>
                            <TextSM textColor="text-coolGray-400 my-1">Please Enter To add</TextSM>
                        </div>
                        <TagFilledInputArea handleChangesToMatches={handleChangesToExactMatches}></TagFilledInputArea>
                    </div>

                    <div className="flex items-center px-8 mt-5 pt-3 justify-end pb-3 bg-coolGray-100">

                        <Button
                            theme="dark"
                            onClick={handleAddTopic}
                            variant={"primary"}
                            className={"!px-2 !py-2 "}
                        >
                            <TextBase textColor="text-coolGray-50">
                                Submit
                            </TextBase>
                        </Button>

                    </div>
                </div>
            </Modal >
        </div >
    );
}

export default AddTopicModal;