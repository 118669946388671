import React from "react";
import PropTypes from "prop-types";
import { TextBase } from "app/designSystem/typography/Typography";
import { ArrowRightIcon, ArrowLeftIcon } from "app/designSystem/icons";
import ReactTooltip from "react-tooltip";
import clx from "classnames";

const MenuItem = ({ icon, label, showArrow, reverse, className, onClick, onHover, disabled, tooltipId, disabledMessage, ...props }) => (
    <button className={clx("py-2 px-2 hover:bg-gray-50 rounded-lg w-full", disabled && "!text-gray-200", className)} disabled={disabled} onClick={onClick} onMouseOver={!disabled && onHover} {...props}>
        <div className="flex items-center justify-between">
            {(showArrow && reverse) && <ArrowLeftIcon className="mr-2 h-2.5" />}

            <div className={`flex items-center ${reverse ? 'flex-row-reverse flex-grow' : ''}`}>
                {icon && React.cloneElement(icon, {
                    className: "w-5 h-5 mr-2 grayscale",
                    style: {
                        filter: disabled && "invert(47%) sepia(4%) saturate(1269%) hue-rotate(182deg) brightness(92%) contrast(84%)"
                    }
                })}
                <TextBase className={clx(reverse ? 'mr-2' : 'ml-2', disabled ? 'text-gray-300' : 'text-gray-600')}>{label}</TextBase>
                <ReactTooltip id={tooltipId} type="light" place="bottom" effect="solid">
                    {disabledMessage && <span>{disabledMessage}</span>}
                </ReactTooltip>
            </div>

            {(showArrow && !reverse && !disabled) && <button><ArrowRightIcon className="ml-2" /></button>}
        </div>

    </button>
);

MenuItem.propTypes = {
    icon: PropTypes.element,
    label: PropTypes.string,
    showArrow: PropTypes.bool,
    reverse: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    disabled: PropTypes.bool,
    tooltipId: PropTypes.string,
    disabledMessage: PropTypes.string
};

MenuItem.defaultProps = {
    icon: null,
    label: "",
    showArrow: false,
    reverse: false,
    className: "",
    onClick: () => { },
    onHover: () => { },
    disabled: false,
    tooltipId: "",
    disabledMessage: ""
};

export default MenuItem;