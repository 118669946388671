import request from "app/utils/request";
import { GET_CALL_DETAILS, GET_CALLS, SET_ACTIVE_TIME, SET_SELECTED_SEARCH_OPTION, SET_ACTIVE_TAB, SET_SELECTED_HIGHLIGHT, UPDATE_SCORECARD_COUNT } from "./actionTypes";
import { fetchDataSafely } from "../utils/dataUtils";

export function setCallDetails(callId, details) {
  return { callId, details, type: GET_CALL_DETAILS };
}

export function setCalls(details) {
  return { details, type: GET_CALLS };
}

export function setSelectedSearchOption(selectedSearchOption) {
  return { selectedSearchOption, type: SET_SELECTED_SEARCH_OPTION };
}

export function setSelectedHighlight(highlightsSection) {
  return { highlightsSection, type: SET_SELECTED_HIGHLIGHT };
}

export function setSelectedTab(activeTabName) {
  return { activeTabName, type: SET_ACTIVE_TAB };
}

export function setScorecardCount(count) {
  return { count, type: UPDATE_SCORECARD_COUNT };
}
export function getCallDetails(callId, dontTrackViews = false) {
  return (dispatch) => {
    return request()
      .get("/call/callDetails", { params: { callId, isAdminLogin: dontTrackViews, isFrontend: true, withWords: true } })
      .then((response) => {
        handleCallDetails(response, dispatch, callId);
      })
      .catch((e) => {
        if (e.status === 403)
          dispatch(setCallDetails(callId, { error: true, errorMessage: "PRIVATE_CALL", privacySetter: e.data.message }));
        if (e.status === 404)
          dispatch(setCallDetails(callId, { error: true, errorMessage: "NO_SUCH_CALL_FOUND" }));
      });
  };
}

export function getTrimmedCallDetails(callId) {
  return (dispatch) => {
    return request()
      .get("/call/callDetails", { params: { callId, isAdminLogin: true, isFrontend: true, withWords: false } })
      .then((response) => {
        handleCallDetails(response, dispatch, callId);
      })
      .catch((e) => {
        if (e.status === 403)
          dispatch(setCallDetails(callId, { error: true, errorMessage: "PRIVATE_CALL", privacySetter: e.data.message }));
        if (e.status === 404)
          dispatch(setCallDetails(callId, { error: true, errorMessage: "NO_SUCH_CALL_FOUND" }));
      });
  };
}

function handleCallDetails(response, dispatch, callId) {
  console.log("response for getCallDetails", response);
  if (fetchDataSafely(response, "data.body.error")) {
    dispatch(setCallDetails(callId, { error: true, errorMessage: fetchDataSafely(response, "data.body.message") }));
  } else {
    dispatch(setCallDetails(callId, response.data));
  }
}

export function getCallsByPublisher(calls) {
  return (dispatch) => {
    dispatch(setCalls({ data: calls }));
  };
}

export function setActiveTime(time) {
  return { time, type: SET_ACTIVE_TIME };
}

export default {
  getCallDetails,
  getCallsByPublisher
};
