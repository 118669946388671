import React from "react";
import sortIcon from "app/styles/assets/images/icons/sort.png";
import sortedIcon from "app/styles/assets/images/icons/sorted.png";
import descSortedIcon from "app/styles/assets/images/icons/descSorted.png";
import { CustomIconButton } from "app/components/common";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import { TextBase } from "app/designSystem";
import TableHeaderSort from "./TableHeaderSort";
import classNames from "classnames";



const TableHeader = ({
  className, sort, sortData, field, label, disableSort, icon,
}) => {
  return (
    <div className={classNames("flex flex-row items-center", className)}>
      <TextBase textColor="text-coolGray-600">
        {label}
      </TextBase>
      {!disableSort && (
        <TableHeaderSort
          sortType={
            sortData[field] ? sortData[field] : "NONE"
          }
          onClick={() => sort(field, sortData[field] === "DESC" ? "ASC" : "DESC")}
        />
      )}
    </div>
  );
};

export const TableHeaders = ({ headerLabels, headerProps }) => {
  return (
    <TableHead>
      <TableRow>
        {headerLabels.map((field, i) => {
          return (
            <TableCell key={i} >
              <TableHeader label={field.label} field={field.value} disableSort={!field.sort} {...headerProps} />
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
