import React from 'react'
import PropTypes from 'prop-types'

import { SyncIcon, TextBase } from 'app/designSystem';
const NewDataTooltip = ({ onClick }) => {
    return (
        <button className="absolute top-15 left-[40%] z-20 NewDataTooltip bg-brand-blue-3 rounded-l-full rounded-r-full px-4 py-1.5 flex items-center" onClick={onClick}>

            <SyncIcon /> <TextBase className="ml-3" textColor='text-white'>Click to refresh</TextBase>
        </button>
    );
}
NewDataTooltip.propTypes = {}
NewDataTooltip.defaultProps = {}
export default NewDataTooltip;