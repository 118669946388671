import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { DeleteV2Icon, DropdownWithSearch, RoundedPlusIcon } from 'app/designSystem'
import TagsContainer from 'app/designSystem/form/TagsInput/TagsContainer'
import { Input } from 'app/designSystem/form';
import { updateCrmGroup, deleteCrmGroup } from 'app/utils/network/crmGroup';

const CRMGroupEditor = ({
    group,
    crmFieldOptions,
    updateGroup,
    handleGroupDelete,
    autoFocus,
}) => {

    const [showSearchDropdown, setShowSearchDropdown] = useState(false)

    useEffect(() => {
        if (!group) return;
        updateCrmGroup(group)
    }, [group])

    const handleGroupNameChange = (e) => {
        updateGroup({ ...group, crmGroupName: e.target.value })
    }

    const onTagRemove = (tag) => {
        updateGroup({ ...group, fields: group.fields.filter(field => field.value !== tag.value) })
    }

    const handleDropDownOptionClick = (option) => {
        updateGroup({ ...group, fields: [...group.fields, option] })
    }

    const onDelete = () => {
        deleteCrmGroup(group)
        handleGroupDelete()
    }

    return (
        <div className="mx-6 py-2">
            <div>
                <Input
                    autoFocus={autoFocus}
                    value={group.crmGroupName}
                    onChange={handleGroupNameChange}
                    className="w-32 !borderl-red-400 rounded"
                    inputClassname="!py-2 !px-2 hover:bg-gray-100 !border-white focus:!border-brand-blue-1 !text-large font-medium"
                />
            </div>
            <div className="flex flex-row border-b items-center">
                <div className="flex flex-row mb-2 mt-0.5 flex-wrap">
                    <TagsContainer
                        variant="gray"
                        tags={group.fields}
                        onTagRemove={onTagRemove}
                    />
                    <div className="relative flex items-center justify-center">
                        <RoundedPlusIcon
                            className="cursor-pointer mr-2 text-coolGray-500"
                            onClick={() => setShowSearchDropdown(true)}
                        />
                        {showSearchDropdown &&
                            <DropdownWithSearch
                                className="bg-red-400"
                                selectedOptions={group.fields}
                                dropdownOptions={crmFieldOptions}
                                onOptionClick={handleDropDownOptionClick}
                                onOutsideClick={() => setShowSearchDropdown(false)}
                            />}
                    </div>
                </div>
                <DeleteV2Icon
                    className="ml-auto min-w-[2rem] cursor-pointer self-center content-center hover:text-red-700"
                    onClick={onDelete}
                />

            </div>
        </div >
    )
}

export default CRMGroupEditor

CRMGroupEditor.propTypes = {
    group: PropTypes.object.isRequired,
    crmFieldOptions: PropTypes.array,
    updateGroup: PropTypes.func.isRequired,
    handleGroupDelete: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
}