import React, { useState } from 'react'
import { AlertBellIcon, AlertBellActiveIcon, AlertBellHoverIcon } from 'app/designSystem';
import PropTypes from 'prop-types';

const AlertButton = ({ isEnabled, onClick }) => {
    const [hoverAlerts, setHoverAlerts] = useState(false);
    return (
        <button
            name="filterAlerts"
            className="ml-1"
            onClick={onClick}
            onMouseEnter={() => setHoverAlerts(true)}
            onMouseLeave={() => setHoverAlerts(false)}
        >
            {hoverAlerts ? <AlertBellHoverIcon /> :
                isEnabled ? <AlertBellActiveIcon /> : <AlertBellIcon />}
        </button>
    )
}

export default AlertButton

AlertButton.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
}