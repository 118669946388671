import React, { useState } from "react";
import { Button, withStyles, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import Lottie from "react-lottie";
import tickAnimationData from "app/styles/assets/animations/tickAnimation.json";
import { PropTypes } from "prop-types";
import { NoEncryption } from "@material-ui/icons";

const styles = theme => ({
  light: {
    backgroundColor: "white",
    color: "rgb(107,114,128)",
    border: "1px solid rgb(107,114,128)",
    "&:hover": {
      boxShadow: "none",
      color: "#2680EB",
      backgroundColor: "white",
      border: "1px solid #2680EB",
    }
  },
  transparent: {
    color: "#fff",
    backgroundColor: "transparent",
    border: "2px solid #fff",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none"
    },
  },
  lightIcon: {
    color: "#2680EB",
  },
  darkIcon: {
    color: "white",
  },
  dark: {
    backgroundColor: "#2680EB",
    color: "white",
    borderColor: "white",
    "&:hover": {
      backgroundColor: "#0280FF",
      boxShadow: "none"
    },
  },
  big: {
    minWidth: "170px",
    height: "36px",
    margin: "3px",
    fontSize: "16px",
    fontWeight: "500",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    padding: "0px 15px",
    boxShadow: "0px 0px white",
    whiteSpace: "nowrap",
  },
  medium: {
    minWidth: "100px",
    height: "32px",
    margin: "3px",
    fontSize: "14px",
    fontWeight: "400",
    display: "flex",
    textTransform: "capitalize",
    alignItems: "center",
    padding: "0px 15px",
    boxShadow: "0px 0px white",
    whiteSpace: "nowrap",
  },
  loadingIcon: {
    color: "white",
  },
  disabled: {
    opacity: "0.5",
  },
});

const CustomButton = props => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { disabled, classes } = props;
  let theme = classes.dark;
  if (props.theme === "light") {
    theme = classes.light;
  } else if (props.theme === "transparent") {
    theme = classes.transparent;
  }
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: tickAnimationData,
  };

  function showTickAnimation() {
    if (!props.showTickAnimation) return;
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 2000);
  }

  async function handleClick(e) {
    const res = await props.onClick(e);
    if (res && res.error) {
      // handle error somehow
    } else {
      showTickAnimation();
    }
  }

  return (
    <div className={props.className} title={props.title}>
      <Button
        disabled={disabled}
        variant={props.variant}
        onClick={handleClick}
        style={{
          backgroundColor: props.bgColor,
          color: props.textColor,
          borderColor: props.textColor,
          width: props.width,
          height: props.height
        }}
        className={
          classNames(
            disabled ? classes.disabled : "",
            props.type === "big" ? classes.big : classes.medium,
            theme,
          )
        }
      >
        {(showSuccess || props.forceShowSuccess) && (
          <Lottie options={defaultOptions} height={25} width={25} />
        )}
        {!(showSuccess || props.forceShowSuccess) && props.loading && (
          <CircularProgress
            size={20}
            className={props.theme === "light" ? classes.lightIcon : classes.darkIcon}
          />
        )}
        {!props.loading && !(showSuccess || props.forceShowSuccess) && props.children}
      </Button>
    </div>
  );
};

CustomButton.defaultProps = {
  variant: "contained",
  color: "primary",
  type: "big",
  title: "",
  theme: "light",
  width: "auto",
  loading: false,
  disabled: false,
  showTickAnimation: false,
  onClick: () => { },
  forceShowSuccess: false,
  height: null
};

CustomButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  showTickAnimation: PropTypes.bool,
  onClick: PropTypes.func,
  forceShowSuccess: PropTypes.bool,
};

export default withStyles(styles)(CustomButton);
