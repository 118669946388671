import { sendRequest } from '../network'

export async function createDealBoard(dealBoard) {
    const response = await sendRequest("/deal_settings/create_deal_board", dealBoard);
    if (response.error)
        throw "Couldn't create the board"
    else
        return response
}

export async function fetchDealBoards() {
    const response = await sendRequest("/deal_settings/get_deal_boards");
    if (response.error)
        return undefined
    else
        return response
}

export async function deleteDealBoard(boardId) {
    const params = new URLSearchParams();
    params.append("boardId", boardId);
    const response = await sendRequest("/deal_settings/delete_deal_board", params);
    if (response.error)
        return undefined
    else
        return response
}

export async function editDealSettingsGeneral(boardId, boardTitle, admins, viewers) {
    const params = new URLSearchParams();
    params.append("boardId", boardId);
    params.append("boardTitle", boardTitle);
    params.append("admins", admins);
    params.append("viewers", viewers);
    const response = await sendRequest("/deal_settings/edit_general", params);
    if (response.error)
        throw "Couldn't edit Deal settings General"
    else
        return true
}

export async function editDealSettingsColumns(boardId, payload) {
    const response = await sendRequest("/deal_settings/edit_columns?boardId=" + boardId, payload);
    if (response.error)
        throw "Couldn't edit Deal settings Columns"
    else
        return true
}

export async function editDealSettingsWarnings(boardId, payload) {
    const response = await sendRequest("/deal_settings/edit_warnings?boardId=" + boardId, payload);
    if (response.error)
        throw "Couldn't edit Deal settings Warnings"
    else
        return true
}

export async function editDealAlerts(boardId, payload) {
    const response = await sendRequest("/deal_settings/edit_alerts?boardId=" + boardId, payload);
    if (response.error)
        return undefined
    else
        return response
}

export async function fetchDealsData(dealsFilter) {
    const response = await sendRequest("/search/filter_opportunities", dealsFilter);
    if (response.error)
        return undefined
    else
        return response
}

export async function fetchDealsOverview(filterParams) {
    const response = await sendRequest("/search/deals_view_overview", filterParams);
    if (response.error)
        return undefined
    else
        return response
}

export async function fetchDealNames() {
    const params = new URLSearchParams();
    params.append("fieldName", "name");
    const response = await sendRequest("/filter/get_unique_field_values_for_opps", params);
    if (response.error)
        return []
    else
        return response
}

export async function fetchDealStages() {
    const params = new URLSearchParams();
    params.append("fieldName", "stage");
    const response = await sendRequest("/filter/get_unique_field_values_for_opps", params);
    if (response.error)
        return []
    else
        return response
}

export async function fetchWarnings() {
    const params = new URLSearchParams();
    params.append("fieldName", "warnings");
    let response = await sendRequest("/filter/get_unique_field_values_for_opps", params);
    if (response.error)
        return []
    else {
        response = response.map(option => {
            option.label = option.label.replaceAll("_", " ");
            return option;
        })
        return response
    }
}

export async function fetchAccessList() {
    const response = await sendRequest("/opportunity/get_edit_access_list");
    if (response.error)
        return undefined
    else
        return response
}

export async function saveRollup(boardId, rollupJSON) {
    const response = await sendRequest("/deal_settings/save_rollup?boardId=" + boardId, rollupJSON);
    if (response.error)
        return undefined
    else
        return response
}

export async function deleteRollup(boardId, rollupId) {
    const params = new URLSearchParams();
    params.append("boardId", boardId);
    params.append("rollupId", rollupId);
    const response = await sendRequest("/deal_settings/delete_rollup", params);
    if (response.error)
        return undefined
    else
        return response
}

export async function getDealDataFromES(accountId, dealId) {
    const params = new URLSearchParams();
    if (dealId) params.append("dealId", dealId);
    params.append("accountId", accountId);
    const response = await sendRequest("/account/account_latest_deal", params);
    if (response.error)
        return undefined
    else
        return response
}

export async function getDealWarningSettings(boardName) {
    const params = new URLSearchParams();
    params.append("boardName", boardName);
    const response = await sendRequest("/deal_settings/get_opp_warning_settings", params);
    if (response.error)
        return undefined
    else
        return response
}

