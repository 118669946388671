import React, { useState, useEffect } from "react";
import {
  KeywordsTracking,
  RoleManagement,
  ProfileSettings,
  IntegrationSettings,
  AppKeySettings,
  SettingsSidebar,
  CrmSettings,
  NotificationSettings,
  WingmanSettings,
  BattleCards,
  SingleSignOn
} from "app/components/settings";
import {
  setScreenName,
  setSettingsTab
} from "app/actions/screen";
import { refreshUserAction } from "app/actions/auth";
import { connect, useSelector } from "react-redux";
import RecorderSettings from "./RecorderSettings";
import Downloads from "./Downloads";
import { debounce } from "lodash";
import { fetchDataSafely } from "app/utils/dataUtils";
import PermissionSettings from "./PermissionSettings";
import TranscriptCorrection from "./TranscriptCorrection";
import ScoreCardsSettings from "./ScoreCardsSettings";

const Settings = props => {
  const profilePage = ["profile", "integrations", "wingmanUser", "notifyUser", "battleCards", "downloads"];
  const adminPage = ["sso", "adminIntegrations", "notifyAdmin", "wingmanAdmin", "recorder", "permissions"];
  const auth = useSelector(store => store.auth);
  const showSingleSignOn = auth.data.user.stringsCustomer.featuresGated.ssoEnabled || auth.data.user.stringsCustomer.featuresGated.ssoDirectoryEnabled;
  const [activeSection, setActiveSection] = useState(getActiveSection());

  const [activeSettingsTab, setActiveSettingsTab] = useState(getActiveSettingsTab());
  const featuresGated = fetchDataSafely(props.auth, 'data.user.stringsCustomer.featuresGated') || {};

  function isGated(feature) {
    return featuresGated.hasOwnProperty(feature) && !featuresGated[feature];
  }

  function getActiveSection() {
    if (window.location.hash) {
      if (profilePage.includes(window.location.hash.slice(1, window.location.hash.length))) {
        return "USER";
      }
      else
        return "ADMIN";
    }
    return "USER";
  }

  useEffect(() => {
    setActiveSection(getActiveSection())
    setActiveSettingsTab(getActiveSettingsTab());
    props.setScreenName("");
  }, [window.location.hash])

  function getActiveSettingsTab() {
    return window.location.hash
      ? window.location.hash.slice(1, window.location.hash.length)
      : props.screen.settingsTab
  }

  useEffect(() => {
    if (document.getElementById(activeSettingsTab))
      document.getElementById(activeSettingsTab).scrollIntoView({ block: "start" });
  }, []);

  useEffect(() => {
    document.getElementById("InnerContainer").addEventListener("scroll", handleScrollDebounced);
    return () => {
      document.getElementById("InnerContainer").removeEventListener("scroll", handleScrollDebounced);
    }
  }, [activeSection])

  const handleScrollDebounced = debounce(e => {
    const activePage = activeSection === "USER" ? profilePage : adminPage;
    const active = spyScroll(document.getElementById("InnerContainer"), activePage);
    if (active)
      handleTabSelect(activePage[active], false);
  }, 100);

  useEffect(() => {
    props.setScreenName("");
    window.history.replaceState({}, undefined, "#" + activeSettingsTab);
    props.refreshUserAction();
  }, []);

  function handleTabSelect(tab, scroll = true) {
    setActiveSettingsTab(tab);
    setActiveSection(profilePage.includes(tab) ? "USER" : "ADMIN");
    window.history.replaceState({}, undefined, "#" + tab);
    if (scroll && document.getElementById(tab)) {
      scrollToTab(tab);
      return;
    }
    setTimeout(() => {
      if (scroll && document.getElementById(tab)) {
        scrollToTab(tab);
      }
    }, 300);
  }

  function scrollToTab(tab) {
    document.getElementById(tab).scrollIntoView({ behavior: "smooth", block: "start" });
    window.history.replaceState({}, undefined, "#" + tab);
    props.setSettingsTab(tab);
  }

  return (
    <div className="Settings__outerContainer">
      <SettingsSidebar
        activeSettingsTab={activeSettingsTab}
        onTabSelect={handleTabSelect}
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      />
      <div className="Settings__innerContainer" >
        {profilePage.includes(activeSettingsTab) &&
          <div>
            <section id="profile"><ProfileSettings /></section>
            {auth.isRecording && <section id="integrations"><IntegrationSettings user /></section>}
            <section id="notifyUser"><NotificationSettings user /></section>
            {auth.isRecording && <section id="wingmanUser"> <WingmanSettings user /></section>}
            <section id="downloads"> <Downloads /></section>
            <div style={{ height: "calc(100vh - 320px)" }}></div>
          </div>
        }
        {(auth.isAdmin || auth.isManager) && adminPage.includes(activeSettingsTab) &&
          <div>
            {(auth.isAdmin && showSingleSignOn) && <section id="sso">
              <div className="Settings__integrationsContainer">
                <span className="Settings__header">SSO and Provisioning</span>
                <div className="m-2">
                  <SingleSignOn featuresGated={auth.stringsCustomer.featuresGated} />
                </div >
              </div>
            </section>}
            {auth.isAdmin && <section id="adminIntegrations"><IntegrationSettings admin /></section>}
            <section id="notifyAdmin"><NotificationSettings admin /></section>
            <section id="wingmanAdmin"> <WingmanSettings admin /></section>
            <section id="recorder"> <RecorderSettings /></section>
            <section id="permissions"> <PermissionSettings /></section>
            <div style={{ height: "calc(100vh - 320px)" }}></div>
          </div>
        }

        {(auth.isAdmin || auth.isManager) && activeSettingsTab === "crm" && !isGated('crmCustomFields') && <CrmSettings />}
        {(auth.isAdmin || auth.isManager) && activeSettingsTab === "keywords" && <KeywordsTracking />}
        {(auth.isAdmin || auth.isManager) && activeSettingsTab === "users" && <RoleManagement />}
        {(auth.isAdmin || auth.isManager) && activeSettingsTab === "autocorrect" && <TranscriptCorrection />}
        {(auth.isAdmin || auth.isManager) && props.auth.data.user.stringsCustomer.featuresGated.scorecard && activeSettingsTab === "scorecardSettings" && <ScoreCardsSettings />}
        {(auth.isAdmin || auth.isManager) && props.auth.data.user.stringsCustomer.featuresGated.cueCards && activeSettingsTab === "battleCardsAdmin" && <BattleCards />}

      </div>
    </div>
  );
};

const spyScroll = (scrollParent, targetElementIds) => {
  if (!scrollParent) return false;

  const targetElements = targetElementIds.map(id => document.getElementById(id));

  let bestMatch = {};
  for (const sectionName in targetElements) {
    if (Object.prototype.hasOwnProperty.call(targetElements, sectionName)) {
      const domElm = targetElements[sectionName];
      if (!domElm) continue;
      const delta = Math.abs(scrollParent.scrollTop - domElm.offsetTop); // check distance from top, takig scroll into account
      if (!bestMatch.sectionName)
        bestMatch = { sectionName, delta };

      if (delta <= bestMatch.delta) {
        bestMatch = { sectionName, delta };
      }
    }
  }

  return bestMatch.sectionName;
};


const mapStateToProps = store => {
  return { ...store };
};

Settings.propTypes = {};

Settings.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setScreenName,
    setSettingsTab,
    refreshUserAction,
  }
)(Settings);
