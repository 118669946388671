import React, { useState, useEffect } from "react";
import AccountViewSearch from "app/components/accountView/AccountViewSearch";
import { CallPreviewSearchResult } from "../components/dealView/CallPreviewSearchResult";
import { EmailPreviewSearchResult } from "../components/dealView/EmailPreviewSearchResult";
import { fetchAccountSearchResults } from "../utils/network";
import useDataHelper from "../utils/hooks/useDataHelper";
import { conversatSearchEmailData } from "../utils/AccountViewUtils";
import { ACCOUNT_VIEW_SEARCH_FIELDS } from "../constants";
import { Button } from "../designSystem";
import AccountViewSearchEmptyState from "app/components/accountView/AccountViewSearchEmptyState"
import { getCallHighlights } from "app/utils/network/call";
import { metrics } from "app/utils/metrics";

export const AccountViewSearchContainer = ({
    callIds,
    emailIds,
    accountId,
    crmData,
    showSearchResult,
    setShowSearchResult,
}) => {
    const [queryText, setQueryText] = useState("");
    const [from, setFrom] = useState(5);
    const [searchResults, setSearchResult] = useState({
        phrase: "",
        result: [],
        numberOfResultsFetched: 0,
        callHighlightPhrase: {}
    });
    const [searchResultLoading, setSearchResultLoading] = useState(false);
    const [totalNumberSearchResult, setTotalNumberSearchResult] = useState(0);
    const { getUserEmail } = useDataHelper();

    const fetchMoreResults = async () => {
        metrics.logEventHeap("fetchMoreResultsAccountViewSearch");
        setSearchResultLoading(true);
        const response = await fetchAccountSearchResults(ACCOUNT_VIEW_SEARCH_FIELDS[searchResults?.type]?.searchField, queryText, callIds, emailIds, null, from);
        setFrom(from + 5);
        const matchedHighlightWordsEachCall = await getTopicMatchingForCalls(queryText, response?.calls.map(({ callId }) => callId));
        setSearchResult((prevState) => ({
            ...prevState,
            result: [...searchResults.result, ...response?.calls, ...conversatSearchEmailData(response?.emails, getUserEmail)],
            numberOfResultsFetched: searchResults?.numberOfResultsFetched + response?.calls?.length + response?.emails?.length,
            callHighlightPhrase: matchedHighlightWordsEachCall
        }));
        setSearchResultLoading(false);
    }

    const getTopicMatchingForCalls = async (searchQuery, callIds) => {
        // const callHighlightsList = [];
        let matchedWordsOfEachCall = {};
        await Promise.all(callIds.map(async callId => {
            const highlightsResult = await getCallHighlights(callId);

            if (highlightsResult.length > 0) {
                const highlightedWordsFromCall = getHighlightsFromCallsResult(highlightsResult, searchQuery);
                // callHighlightsList.push(...highlightedWordsFromCall);
                matchedWordsOfEachCall = { ...matchedWordsOfEachCall, [callId]: highlightedWordsFromCall };
            }
            else {
                matchedWordsOfEachCall = { ...matchedWordsOfEachCall, [callId]: [`${searchQuery}`] }
            }
        }));
        return matchedWordsOfEachCall;
    }

    const getHighlightsFromCallsResult = (callData, filterGroupName) => {
        return Array.from(new Set(callData?.flat()?.filter(({ groupName }) => groupName.toLowerCase().includes(filterGroupName.toLowerCase()))?.map(({ highlights }) => highlights)?.flat()?.map(({ words }) => words)?.flat()?.map(({ str, bold }) => Boolean(bold) ? str : null)?.filter(Boolean) ?? []));
    }

    return (
        <>
            <AccountViewSearch
                loading={searchResultLoading}
                callIds={callIds}
                emailIds={emailIds}
                queryText={queryText}
                setSearchResult={setSearchResult}
                setQueryText={setQueryText}
                setLoading={setSearchResultLoading}
                getTopicMatchingForCalls={getTopicMatchingForCalls}
                setShowSearchResult={setShowSearchResult}
                accountId={accountId}
                setTotalNumberSearchResult={setTotalNumberSearchResult}
            />
            <div className="pt-9 px-9 overflow-y-auto">
                {showSearchResult && searchResults.result.length === 0 && <AccountViewSearchEmptyState />}
                {showSearchResult && searchResults.result.length > 0 && (
                    <>
                        <div className="text-xs mb-2 text-gray-500 ">{totalNumberSearchResult} Results</div>
                        {searchResults.result.map((searchResult, index) =>
                            searchResult.type === "EMAIL" ? (
                                <EmailPreviewSearchResult
                                    key={"Email_Preview_Search_Result" + index}
                                    query={searchResults.phrase}
                                    email={searchResult}
                                    crmData={crmData}
                                />
                            ) : (
                                <CallPreviewSearchResult
                                    key={"Call_Preview_Search_Result" + index}
                                    matchedWords={(searchResults.type === "TRANSCRIPT") ? searchResults?.phrase
                                        : (searchResults?.callHighlightPhrase[searchResult?.callId])}
                                    searchType={searchResults.type}
                                    crmData={crmData}
                                    callResult={searchResult}
                                    searchedQuery={queryText}
                                />
                            )
                        )}
                        {
                            totalNumberSearchResult > searchResults?.numberOfResultsFetched &&
                            <Button className="DealView__LoadMoreButton" loading={searchResultLoading} onClick={fetchMoreResults}> View More Results</Button>
                        }
                    </>
                )}
            </div>
        </>
    );
};
AccountViewSearchContainer.propTypes = {};
AccountViewSearchContainer.defaultProps = {};
