import React from "react";
import { PropTypes } from "prop-types";
import sortIcon from "app/styles/assets/images/icons/sort.png";
import sortedIcon from "app/styles/assets/images/icons/sorted.png";
import descSortedIcon from "app/styles/assets/images/icons/descSorted.png";
import { withRouter } from "react-router-dom";
import {
  _formatSecondsToPlayingTime,
  _formatTalkListenRatio,
  _formatDateToCallCardDateTimeFormat,
} from "app/utils/helpers";
import { TableCell, TableHead, TableRow, Table, TableBody } from "@material-ui/core";
import { Card, CustomIconButton, Loading } from "app/components/common";
import { Link } from "react-router-dom";
import { Button } from "app/components/form";

class UserCallTable extends React.Component {
  constructor(props) {
    super(props);
    this.openCallScreen = this.openCallScreen.bind(this);
  }
  openCallScreen(callId) {
    this.props.history.push({
      pathname: `/call/${callId}`,
    });
  }
  render() {
    const headerProps = {
      sort: this.props.sort,
      sortData: this.props.sortData,
    };
    if (this.props.loading) {
      return <LoadingTable />;
    }
    return (
      <Card
        title="Recent Calls"
        headActions={
          <Link
            to={{
              pathname: `/agentCalls/${this.props.userId}`,
              state: { name: this.props.name },
            }}
          >
            <Button>View All Calls</Button>
          </Link>
        }
        overflow="hidden"
        maxHeight="none"
      >
        <Table style={{ overflowY: "hidden" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeader label="Contact" disableSort field="contact" {...headerProps} />
              </TableCell>
              <TableCell colSpan={3}>
                <TableHeader label="Call Title" disableSort field="title" {...headerProps} />
              </TableCell>
              <TableCell>
                <TableHeader label="Call Source" disableSort field="callType" {...headerProps} />
              </TableCell>
              <TableCell>
                <TableHeader label="Date and Time" field="time" {...headerProps} />
              </TableCell>
              <TableCell>
                <TableHeader label="Call Duration" field="duration" {...headerProps} />
              </TableCell>
              <TableCell>
                <TableHeader label="Talk/Listen Ratio" field="talkListenRatio" {...headerProps} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.calls.map((call, i) => {
              return (
                <TableRow
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.openCallScreen(call.callId)}
                >
                  <TableColumn path={`/call/${call.callId}`} span={1}>
                    {call.contacts && call.contacts.length
                      ? call.contacts.map(contact => contact.replace("null", "")).join(", ")
                      : ""}
                  </TableColumn>
                  <TableColumn path={`/call/${call.callId}`} span={3}>
                    {call.title}
                  </TableColumn>
                  <TableColumn path={`/call/${call.callId}`} span={1}>
                    {call.callType}
                  </TableColumn>
                  <TableColumn path={`/call/${call.callId}`} span={1}>
                    {_formatDateToCallCardDateTimeFormat(call.time)}
                  </TableColumn>
                  <TableColumn path={`/call/${call.callId}`} span={1}>
                    {call.callStatus === "WAITING IN QUEUE" || call.callStatus === "PROCESSING" ?
                        "PROCESSING" : _formatSecondsToPlayingTime(call.duration)}
                  </TableColumn>
                  <TableColumn path={`/call/${call.callId}`} span={1}>
                    {_formatTalkListenRatio(call.talkListenRatio)}
                  </TableColumn>
                </TableRow>
              );
            })}
            {this.props.calls.length == 0 && <EmptyTableRow />}
          </TableBody>
        </Table>
      </Card>
    );
  }
}

const LoadingTable = () => <Loading loading />;

const TableColumn = class TD extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log("error:", error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <TableCell colSpan={this.props.span}>NA</TableCell>;
    }
    return (
      <TableCell colSpan={this.props.span}>
        <Link to={this.props.path}>
          <span style={{ color: "#333" }}>{this.props.children}</span>
        </Link>
      </TableCell>
    );
  }
};

const TableHeader = ({
  classes, sort, sortData, field, label, disableSort,
}) => {
  return (
    <div className="Manager-Dashboard__agents-performance-header-column">
      {label}
      {!disableSort && (
        <CustomIconButton
          classes={classes}
          icon={
            sortData[field] != null
              ? sortData[field] == "ASC"
                ? sortedIcon
                : descSortedIcon
              : sortIcon
          }
          onClick={() => sort(field, sortData[field] == "DESC" ? "ASC" : "DESC")}
        />
      )}
    </div>
  );
};

const EmptyTableRow = () => (
  <tr>
    <td>No Calls found.</td>
    <td />
    <td />
    <td />
    <td />
    <td />
    <td />
  </tr>
);

UserCallTable.propTypes = {
  calls: PropTypes.array.isRequired,
  sort: PropTypes.func,
  filterParams: PropTypes.object,
  contacts: PropTypes.array,
  userNames: PropTypes.array,
};

UserCallTable.defaultProps = {
  sort: () => {},
  filterParams: {},
  contacts: [],
  userNames: [],
};

export default withRouter(UserCallTable);
