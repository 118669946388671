import React from "react";
import { SortAscIcon, SortDescIcon, SortNoneIcon } from "app/designSystem";
import classNames from "classnames";
export default function TableHeaderSort({ sortType, className, onClick }) {
    let SortIcon = SortNoneIcon;
    if (sortType == "DESC") {
        SortIcon = SortDescIcon;
    }
    else if (sortType == "ASC") {

        SortIcon = SortAscIcon;

    }
    return (
        <button
            className={classNames(
                "flex hover:bg-coolGray-100 active:bg-coolGray-200  p-1 rounded-full",
                className
            )}
            onClick={onClick}
        >
            <div className="flex justify-center items-center h-4 w-4">
                <SortIcon className="w-full h-full" />
            </div>
        </button>
    );
}
