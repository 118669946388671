import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import CloseLogo from "app/styles/assets/images/onboarding/close-logo.png";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import { sendRequest } from "../../utils/network";

const SyncClose = props => {
    const [loading, setLoading] = useState(false);

    async function sync() {
        setLoading(true);
        const userId = props.auth.data.user.userId || {};
        let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
        if (window.location.port) {
            frontendUrl = `${frontendUrl}:${window.location.port}`;
        }
        const win = window.open(
            `${frontendUrl}/closeIntegration`,
            "Close Integration",
            "width=600, height=400",
        );
        const { refreshUserAction } = props;
        const pollTimer = setInterval(async () => {
            try {
                if (win.closed) {
                    clearInterval(pollTimer);
                    setLoading(false);
                    sendRequest("/close/sync");
                    refreshUserAction();
                }
            } catch (e) { }
        }, 100);
    }

    return (
        <IntegrationCard
            icon={CloseLogo}
            heading="CloseIO"
            subHeading="CRM Integrations"
            bodyText="Sync close to link all CRM info."
            loading={loading}
            sync={sync}
            {...props}
        />
    );
};

const mapStateToProps = store => {
    return { ...store };
};

SyncClose.defaultProps = {
    access: "user",
};

SyncClose.propTypes = {
    refreshUserAction: PropTypes.func.isRequired,
    access: PropTypes.string,
};

export default connect(
    mapStateToProps,
    { refreshUserAction },
)(SyncClose);
