import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import { Grid, TextField } from "@material-ui/core";
import { Button } from "app/components/form";
import { setLoading } from "app/actions/screen";
import AircallLogo from "app/styles/assets/images/onboarding/aircallFull.svg";
import { Loading } from "app/components/common";
import { Card } from "app/components/common";
import { useKeyListenerRef } from 'app/utils/hooks/useKeyListener';
import { useRef } from "react";


const AircallForm = props => {
	const { loading } = props?.screen;
	const [apiId, setApiId] = useState("");
	const [apiToken, setApiToken] = useState("");
	const [error, setError] = useState("");
	const [newApiKeyInput] = useKeyListenerRef(submitOnEnter);
	const [newApiIdInput] = useKeyListenerRef(submitOnEnter);


	function submitOnEnter(event) {
		event.preventDefault();
		if (event.keyCode === 13) {
			submitAircallInfo();
		}
	}

	function handleApiIdChange(event) {
		setError("");
		setApiId(event.target.value);
	}

	function handleApiTokenChange(event) {
		setError("");
		setApiToken(event.target.value);
	}

	async function submitAircallInfo() {
		props.setLoading(true);
		if (apiId == "") {
			setError("Enter a API Id");
		} else if (apiToken == "") {
			setError("Enter a API Token");
		}
		else {
			const response = await crmOAuthCallback(apiId, 'AIRCALL', apiToken);
			if (response.error) {
				console.log("setting error to", response.data.message);
				setError(response.data.message);
			} else {
				props.setLoading(false);
				window.close();
			}
		}
		props.setLoading(false);
	}


	return (
		<div className="Freshsales">
			<Loading />
			<div className="Freshsales__content">
				<Card className="Freshsales__content">
					<Grid>
						<div className="row-topcentered">
							<img className="header__logo" src={AircallLogo} alt="Logo" />
						</div>
					</Grid>
					<Grid>
						<div id="AircallForm">
							<table>
								<tbody>
									<tr className="row-centered">
										<td>
											<TextField
												id="apiId"
												label="Enter API id"
												required
												value={apiId}
												className="Freshsales__textField"
												onChange={handleApiIdChange}
												inputRef={newApiIdInput}
											/>
											<span className="Freshsales__textFieldBlack"></span>
										</td>
									</tr>
									<tr className="row-centered">
										<td>
											<TextField
												id="apiToken"
												label="Enter API token"
												required
												value={apiToken}
												className="Freshsales__textField"
												onChange={handleApiTokenChange}
												margin="normal"
												type="apiToken"
												inputRef={newApiKeyInput}
											/>
										</td>
									</tr>
									<tr className="row-centered">
										<td>
											<Button theme="dark" id="syncFreshSales" onClick={submitAircallInfo}>
												Sync Aircall
											</Button>
										</td>
									</tr>

									<tr className="row-centered">
										<td>
											{error && <span className="error">{error}</span>}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Grid>
				</Card>
			</div>
		</div>
	);
}

const mapStateToProps = store => {
	return { ...store };
};

AircallForm.propTypes = {
	setUserData: PropTypes.func.isRequired,
	setLoading: PropTypes.func,
};

AircallForm.defaultProps = {};

export default connect(
	mapStateToProps,
	{ setUserData, setLoading },
)(AircallForm);
