import React, { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { USER } from '../../actions/actionTypes';
import { refreshUser } from '../network';

const useReduxUpdater = () => {
    const dispatch = useDispatch();
    const refreshUserAction = async () => {
        const response = await refreshUser();
        console.log("response from refresh user action in useReduxUpdater", response);
        localStorage.setItem("user", JSON.stringify(response?.user));
        dispatch({ type: USER, user: response?.user })
    }
    return { refreshUserAction };
}

export default useReduxUpdater;