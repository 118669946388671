import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import AccountWarnings from "./AccountWarnings";
import AccountContacts from "./AccountContacts";
import NextCallInfo from "./NextCallInfo";

const AccountInfoPanel = ({ crmData, loading, interactions, warnings, deal, dealDataFromES }) => {
    const auth = useSelector(store => store.auth);

    if (loading) {
        return (
            <EmptyInfoPanel />
        );
    }

    return (
        <div className="DealView__InfoPanel__fullContainer">
            <AccountWarnings deal={deal} warnings={warnings} />
            <NextCallInfo dealDataFromES={dealDataFromES} />
            <AccountContacts crmData={crmData} interactions={interactions} />
        </div >
    );
};

export const EmptyInfoPanel = props => (
    <div className="InfoPanel__fullContainer">
        <div className="DealView__InfoPanel__SectionRow">
            <Skeleton height={100} width={300} />
        </div>

    </div>
);

export default AccountInfoPanel;
