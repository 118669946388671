import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import TeamDashboardMultiLineComparison from './TeamDashboardMultiLineComparison';
import { _formatTalkListenRatio } from '../../utils/helpers';
import { withRouter } from "react-router-dom";
import { clone } from "lodash";
import { emptyFilterParams } from '../../utils/filter';
import { useDispatch, useSelector } from "react-redux";
import { SET_CALL_LOG_FILTER_AND_REFRESH } from '../../actions/actionTypes';
import { NoDataCustom } from '../common/NoData';



const TeamDashboardTrackers = props => {
  const trackersData = props.trackersData;
  const dispatch = useDispatch();
  const existingFilterParams = useSelector(store => store.screen.managerDashboardFilter);
  const phrases = props.topicsForDashboards;

  async function goToTeamCallLog(category) {
    const filterParamsUpdated = clone(existingFilterParams);
    filterParamsUpdated.containsCategories = [category];
    dispatch({ type: SET_CALL_LOG_FILTER_AND_REFRESH, filterParams: filterParamsUpdated });
    props.history.push({
      pathname: `/calllog`,
    });
  }

  return (<Grid container spacing={3}>
    {Object.keys(trackersData).map((category, i) => {
      if (trackersData[category].length > 0) {
        const avg = trackersData[category].reduce((c, a) => c + (a.total > 0 ? a.count / a.total : 0), 0) / trackersData[category].length;
        return (
          <Grid
            key={i}
            item
            xs={12}
            sm={4}
            className="Manager-Dashboard__chart"
          >
            <Paper>
              <TeamDashboardMultiLineComparison
                index={i}
                avg={avg}
                data={trackersData[category].map(d => ({
                  name: d.name,
                  value: d.count,
                  maxVal: d.total
                }))}
                category={category}
                goToTeamCallLog={goToTeamCallLog}
                phrases={phrases}
                exactMatches={phrases[category.toLocaleLowerCase()] ? phrases[category.toLocaleLowerCase()].exact : null}
                approxMatches={phrases[category.toLocaleLowerCase()] ? phrases[category.toLocaleLowerCase()].approx : null}
              />
            </Paper>
          </Grid>
        );
      }
    })}
    {Object.keys(trackersData).length === 0 && <NoDataCustom />}
  </Grid>);
}

export default withRouter(TeamDashboardTrackers);