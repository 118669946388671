import {
  SEEK_PLAYBACK_POSITIONS,
  SET_PLAYING,
  SET_SELECTED_SPEAKERS,
} from "./actionTypes";

export function setSeekPlaybackPositions(position) {
  return {
    position,
    type: SEEK_PLAYBACK_POSITIONS,
  };
}

export function setSelectedSpeakers(selectedSpeakers) {
  return {
    selectedSpeakers,
    type: SET_SELECTED_SPEAKERS,
  };
}

export function setPlaying(playing) {
  return {
    playing,
    type: SET_PLAYING,
  };
}

export function doSeekPlaybackPositions(position) {
  return dispatch => {
    return dispatch(setSeekPlaybackPositions(position));
  };
}

export default {
  doSeekPlaybackPositions,
  setPlaying,
  setSelectedSpeakers
};