import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, Modal } from "@material-ui/core";
import { RecordingContent } from "../onboarding/VoiceFingerprinting";
import { Button } from "../form";
import { getVoiceFingerprintUrl, submitVoiceFingerPrint } from "../../utils/network";
import { refreshUserAction } from "../../actions/auth";
import { setLoading } from "../../actions/screen";

const VoiceFingerprint = ({
  auth, refreshUserAction, setLoading, trigger, hideHasFingerprintComp
}) => {
  const [hasFingerprint, setHasFingerprint] = useState(
    auth.data.user.integrationStatus.voiceFingerPrintingFlow,
  );
  const [showRecordingContent, setShowRecordingContent] = useState(false);

  useEffect(() => {
    setHasFingerprint(auth.data.user.integrationStatus.voiceFingerPrintingFlow);
  }, [auth]);

  async function onSubmit(audio) {
    const userId = auth.data.user.userId || {};
    setLoading(true);
    const response = await submitVoiceFingerPrint(userId, audio);
    setHasFingerprint(false);
    if (response.success) {
      await refreshUserAction();
      setHasFingerprint(true);
    }
    setLoading(false);
    setShowRecordingContent(false);
  }

  return (
    <div className="Settings__voiceContainer">
      {(hasFingerprint === false || hideHasFingerprintComp) && (
        <div onClick={() => setShowRecordingContent(true)}>
          {trigger}
        </div>
      )}
      {!hideHasFingerprintComp && hasFingerprint === true && <PlayFingerprint hasFingerprint={hasFingerprint} />}
      {!hideHasFingerprintComp && hasFingerprint === true && (
        <div className="button">
          <Button bgColor="white" textColor="#686868" onClick={() => setShowRecordingContent(true)}>
            {"Record Again"}
          </Button>
        </div>
      )}
      <Modal
        open={showRecordingContent}
        onClose={() => {
          setShowRecordingContent(false);
        }}
        className="Form__outerContainer"
      >
        <RecordingContent onSubmit={onSubmit} />
      </Modal>
    </div>
  );
};

const PlayFingerprint = ({ hasFingerprint }) => {
  async function getFingerprintUrl() {
    const response = await getVoiceFingerprintUrl();
    if (document.getElementById("fingerprint") !== null) {
      document.getElementById("fingerprint").setAttribute("src", response);
    }
  }

  useEffect(() => {
    getFingerprintUrl();
  }, [hasFingerprint]);

  return (
    <audio id="fingerprint" controls>
      <source src="" type="audio/wav" />
    </audio>
  );
};

VoiceFingerprint.propTypes = {};

VoiceFingerprint.defaultProps = {
  hideHasFingerprintComp: false,
}

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { refreshUserAction, setLoading },
)(VoiceFingerprint);
