import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { CustomLink, Loading, ConfirmationDialog } from "app/components/common";
import { refreshUserAction } from "app/actions/auth";
import { setLoading } from "app/actions/screen";
import voice from "app/styles/assets/images/onboarding/mic.svg";
import { submitVoiceFingerPrint } from "app/utils/network";
import { Recording } from "app/components/onboarding/Recording";
import SyncFlowContents from "app/components/onboarding/SyncFlowContents";

const VoiceFingerprinting = props => {

  const [next, setNext] = useState(false);
  const [showWarning, setShowWarning] = useState(false);


  async function onSubmit(audio) {
    const userId = props.auth.data.user.userId || {};
    const response = await submitVoiceFingerPrint(userId, audio);
    if (response.success) {
      props.skipHandler();
    }
    return response;
  }

  function goNext() {
    setNext(true);
  }

  function handleSkip() {
    setShowWarning(true);
  }


  if (next) {
    return (
      <div>
        <RecordingPage
          skipHandler={handleSkip}
          onSubmit={onSubmit}
        />
        <ConfirmationDialog
          title="Do you want to skip recording your Voice Fingerprint?"
          message="Recording a voice fingerprint is important to enable Wingman to distinguish between you and other speakers in the call. Skipping this step can lead to incorrect analysis of your calls."
          open={showWarning}
          yesLabel="Remind me later"
          noLabel="Cancel"
          onOk={() => {
            setShowWarning(false);
            props.skipHandler();
          }}
          onClose={() => {
            setShowWarning(false);
          }}
        />
      </div>
    );
  }
  return (
    <div className="Sync-Background verCon">
      <SyncFlowContents
        transparent
        icon={voice}
        iconClass={"VoiceFingerprint__copyIcon"}
        heading="Record Your Voice Fingerprint"
        subheading="We need a voice sample to recognize your voice in calls."
        skipHandler={handleSkip}
        clickHandler={goNext}
        buttonLabel="Next"
      />
      <CustomLink onClick={handleSkip}>Skip</CustomLink>
      <ConfirmationDialog
        title="Do you want to skip recording your Voice Fingerprint?"
        message="Recording a voice fingerprint is important to enable Wingman to distinguish between you and other speakers in the call. Skipping this step can lead to incorrect analysis of your calls."
        open={showWarning}
        yesLabel="Remind me later"
        noLabel="Cancel"
        onOk={() => {
          setShowWarning(false);
          props.skipHandler();
        }}
        onClose={() => {
          setShowWarning(false);
        }}
      />
    </div>
  );
}

export const RecordingPage = props => (
  <div className="Sync-Background">
    <Loading />
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          direction="column"
          justify="center"
        >
          <RecordingContent {...props} />
          <Grid item>
            <CustomLink onClick={props.skipHandler}>Skip</CustomLink>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export const RecordingContent = props => {
  const user = useSelector(store => store.auth.data.user);
  return (
    <div className="VoiceFingerprint__card">
      <Grid item>
        <span className="VoiceFingerprint__copy">
          Please speak the following statement
      </span>
      </Grid>
      <Grid item>
        <div className="VoiceFingerprint__passage">
          <p>Hi, this is {user.displayName} from {user.stringsCustomer.name}. Happy that we could connect today. I know you've just moved homes, so I really appreciate you taking out time for me!</p>
          <p>Oh, and in case you're wondering, the person named Wingman that's joined us is a bot that I've invited.</p>
          <p>What is Wingman?</p>
          <p>Wingman is really my co-pilot on sales calls. Helps me by taking notes... bookmark any important things that you might say... and even gives me useful prompts like "{(user?.displayName ?? "Heyy").split(" ")[0]}, stoppp! You've been going on forever!"</p>
          <p>And then later, I can zoom through the call real quick, see like next steps and questions. Makes follow-ups really easy. That's just some of it.</p>
          <p>So, let's get started?</p>
        </div>
      </Grid>
      <Grid item>
        <Recording onSubmit={props.onSubmit} />
      </Grid>
    </div>
  );
}

const mapStateToProps = store => {
  return { ...store };
};

VoiceFingerprinting.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  skipHandler: PropTypes.func.isRequired
};

VoiceFingerprinting.defaultProps = {
  setLoading: () => { }
};

export default connect(
  mapStateToProps,
  { refreshUserAction, setLoading }
)(VoiceFingerprinting);
