import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { isEmpty } from "lodash";
import { flattenHighlights, _formatSecondsToTime } from "app/utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { isCustomerTurn } from "../../reducers/call";
import { useEffect } from "react";
import { GetSortOrder } from "../dashboards/helpers";
import { setSelectedHighlight } from "../../actions/call";
import { NoHighlights } from "./notes/NoNotes";
import { TextSM } from "app/designSystem"
import { PlayIcon } from "app/designSystem/icons";
import TopHighlights from "./TopHighlights";

const HighlightsSection = ({
	highlightsData,
	bookmarksData,
	handleHighlightsClick,
	customerType
}) => {
	const dispatch = useDispatch();
	const [currentTab, setCurrentTab] = useState(hasBookmarksData() ? 0 : 1);
	let speakers = ["Speaker 1", "Speaker 2"];
	const summaryData = useSelector(store => store.call.details.summary);
	const turns = useSelector(store => store.call.details.callData.conversationTurn);
	const callProcessed = useSelector(store => store.call.details.callDataDocument.callStatus) === "POST_PROCESSING_DONE";
	const channel = useSelector(store => store.call.details.callData.channel);
	if (summaryData && summaryData.speakerNames) {
		speakers = summaryData.speakerNames;
	}

	useEffect(() => {
		if (currentTab === 0) {
			dispatch(setSelectedHighlight("Bookmarks"));
		} else if (currentTab === 1) {
			dispatch(setSelectedHighlight("Questions"));
		} else if (currentTab === 2) {
			dispatch(setSelectedHighlight("Next Steps"));
		} else {
			dispatch(setSelectedHighlight("Others"));
		}
	}, [currentTab])

	function hasBookmarksData() {
		if (
			customerType !== "GUEST" &&
			bookmarksData &&
			!isEmpty(bookmarksData.highlightGroups) &&
			bookmarksData.highlightGroups.length > 0
		)
			return true;
		return false;
	}

	return (
		<div className="Call__HighlightContainer">
			<div className="Call__HighlightContainer__tabs">
				<span onClick={() => setCurrentTab(0)} className={`Call__HighlightContainer__tab ${currentTab === 0 ? "Call__HighlightContainer__tabActive" : ""}`}>Bookmarks</span>
				<span onClick={() => setCurrentTab(1)} className={`Call__HighlightContainer__tab ${currentTab === 1 ? "Call__HighlightContainer__tabActive" : ""}`}>Questions</span>
				<span onClick={() => setCurrentTab(2)} className={`Call__HighlightContainer__tab ${currentTab === 2 ? "Call__HighlightContainer__tabActive" : ""}`}>Next Steps</span>
				<span onClick={() => setCurrentTab(3)} className={`Call__HighlightContainer__tab ${currentTab === 3 ? "Call__HighlightContainer__tabActive" : ""}`}>Others</span>
			</div>

			{currentTab === 0 &&
				<div className="Call__HighlightContainer__listContainer">
					<Highlights
						highlightsData={bookmarksData}
						keyword={null}
						excludeKeywords={[]}
						handleHighlightsClick={handleHighlightsClick}
						speakers={speakers}
						channel={channel}
						turns={turns}
					/>
				</div>
			}

			{currentTab === 1 &&
				<div className="Call__HighlightContainer__listContainer">
					<Highlights
						highlightsData={highlightsData}
						keyword={"question"}
						excludeKeywords={[]}
						handleHighlightsClick={handleHighlightsClick}
						speakers={speakers}
						channel={channel}
						turns={turns}
						processingPending={!callProcessed}
					/>
				</div>
			}
			{currentTab === 2 &&
				<div className="Call__HighlightContainer__listContainer">
					<Highlights
						highlightsData={highlightsData}
						keyword={"next step"}
						excludeKeywords={[]}
						handleHighlightsClick={handleHighlightsClick}
						speakers={speakers}
						channel={channel}
						turns={turns}
						processingPending={!callProcessed}
					/>
				</div>
			}
			{currentTab === 3 &&
				<div className="Call__HighlightContainer__listContainer">
					<Highlights
						highlightsData={highlightsData}
						keyword={null}
						excludeKeywords={["next step", "question"]}
						handleHighlightsClick={handleHighlightsClick}
						speakers={speakers}
						showCategory={true}
						channel={channel}
						turns={turns}
						processingPending={!callProcessed}
						isHighlightsOthers
					/>
				</div>
			}
		</div>
	);
};

export const Highlights = ({
	highlightsData,
	keyword,
	speakers,
	channel,
	excludeKeywords,
	turns,
	handleHighlightsClick,
	showCategory,
	processingPending,
	type,
	isHighlightsOthers
}) => {

	const [highlights, setHighlights] = useState([]);

	function getMergedSentence(first, second) {
		let i = 0, j = 0;
		let mergedSentence = []

		while (i < first.length && j < second.length && first[i].str != second[j].str) {
			mergedSentence.push(first[i++])
		}
		while (i < first.length && j < second.length && first[i].str == second[j].str) {
			if (first[i].bold != second[j].bold)
				first[i].bold = true;
			mergedSentence.push(first[i++])
			j++;
		}
		while (i < first.length) {
			mergedSentence.push(first[i++])
		}
		while (j < second.length) {
			mergedSentence.push(second[j++])
		}
		return mergedSentence;
	}

	function mergeHighlights(arr, keys) {
		try {
			const group = arr.reduce((acc, o) => {
				const copy = { ...o }
				// merging the sentence on startTime and turnId
				copy["startTime"] = Math.round(copy["startTime"] / 3)
				const unique = keys.map(k => copy[k]).join('|');
				if (acc[unique]) {
					if (!acc[unique].groupName.includes(o.groupName))
						acc[unique].groupName = [...acc[unique].groupName, ...[o.groupName]]
					acc[unique].words = acc[unique].startTime < o.startTime
						? getMergedSentence(acc[unique].words, o.words)
						: getMergedSentence(o.words, acc[unique].words)
				}
				else
					acc[unique] = { ...o, groupName: [o.groupName] };
				return acc;
			}, {})
			return Object.values(group)
		}
		catch {
			return arr
		}
	}

	useEffect(() => {
		if (isHighlightsOthers) {
			const keys = ["highlightStr", "startTime", "turnId"]
			const flattenHighlightsData = flattenHighlights(highlightsData, excludeKeywords, keyword)
			const mergedHighlightsData = mergeHighlights(flattenHighlightsData, keys)
			setHighlights(mergedHighlightsData);
		}
		else
			setHighlights(flattenHighlights(highlightsData, excludeKeywords, keyword));
	}, [highlightsData]);
	if (processingPending) return <NoHighlights copy="Wingman is currently analyzing this call and this will show up shortly." />;

	if (highlights) {
		return <>
			{
				highlights.map((h, index) => {
					const turn = turns && h && turns[h.turnId - 1] ? turns[h.turnId - 1] : null;
					return (
						<Highlight
							turn={turn}
							key={index}
							h={h}
							index={index}
							handleHighlightsClick={handleHighlightsClick}
							speakers={speakers}
							channel={channel}
							showCategory={showCategory}
							isHighlightsOthers={isHighlightsOthers}
						/>
					);
				})}
			{highlights.length === 0 && type === "SEARCH" &&
				<TopHighlights />
			}
			{highlights.length === 0 && type !== "SEARCH" &&
				<NoHighlights />
			}
		</>
	}
	return null;
}

const Highlight = ({ h, turn, speakers, channel, handleHighlightsClick, index, showCategory, isHighlightsOthers }) => {
	const renderWords = (words) => words.map((w, i) => w.bold ? <b key={i}>{w.str} </b> : <span key={i}>{w.str} </span>);
	return (
		<div
			className="Highlight"
			key={`${index} ${h.turnId}`}
			onClick={() =>
				handleHighlightsClick(
					h.turnId,
					h.startTime,
					h.groupName
				)
			}
			role="presentation"
		>
			<div className="flex">
				<div className="flex items-center rounded mr-2 bg-blueGray-50 p-1 text-gray-400 hover:text-wingman-brand w-[3.5rem] justify-center cursor-pointer">
					<TextSM className="text-current">
						{_formatSecondsToTime(h.startTime)}
					</TextSM>
					<div>
						<PlayIcon className="h-3 w-3 ml-1" />
					</div>
				</div>
				<div className="Highlight__header">
					<span className={`Highlight__speaker ${isCustomerTurn(turn, speakers, channel) ? "Highlight__speakerCustomer" : ""}`}>{turn ? speakers[turn.speakerId - 1] : "Speaker"}</span>
					{showCategory && isHighlightsOthers && h.groupName.map((name, index) => {
						return (
							<span className="Highlight__category" key={name + index}>{name}</span>
						)
					})}
					{showCategory && !isHighlightsOthers && <span className="Highlight__category">{h.groupName}</span>}
				</div>
			</div>
			<div className="ml-16">
				<span className="Highlight__text">
					{h.highlightStr && h.highlightStr.split(" ").slice(0, 30).join(" ")}
					{!h.highlightStr && h.words && h.words.length && renderWords(h.words)}
				</span>
			</div>
		</div>

	);
}

Highlights.defaultProps = {
	type: "Highlights",
}

Highlight.defaultProps = {
	showCategory: false,
}

HighlightsSection.propTypes = {};

export default HighlightsSection;
