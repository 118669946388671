import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, TextBase, TextLarge, TextSM } from "app/designSystem";
import { ReactComponent as InfoCyan } from 'app/designSystem/icons/infoCyan.svg'
import ReactTooltip from 'react-tooltip';
import { _formatDateToMinsBefore } from 'app/utils/helpers'
import { getSyncCountForCrm, getSyncCountForDialer } from "app/utils/network/search";
import { CircularProgress } from "@material-ui/core";
import { ConfirmActionModal } from "../gameTapesLatest/modal";

const IntegrationCard = ({
  synced,
  heading,
  subHeading,
  bodyText,
  icon,
  sync,
  unsync,
  syncButtonLabel,
  unsyncButtonLabel,
  customButton,
  customButtonEnabled,
  loading,
  lastSyncDate,
  crm,
  dialer
}) => {
  const lastSyncInfo = _formatDateToMinsBefore(lastSyncDate)
  const [syncCountData, setSyncCountData] = useState(null)
  const [showSyncData, setShowSyncData] = useState(false)
  const [showDisconnectConfirmationModal, setShowDisconnectConfirmationModal] = useState(false)

  useEffect(() => {
    crm && getCrmSyncData()
    dialer && getDialerSyncData()
  }, [])

  useEffect(() => {
    setShowSyncData(syncCountData && hasNonZeroData())
  }, [syncCountData])

  const getCrmSyncData = async () => {
    const data = await getSyncCountForCrm(crm);
    setSyncCountData(data)
  }

  const getDialerSyncData = async () => {
    const data = await getSyncCountForDialer(dialer);
    setSyncCountData(data)
  }

  const hasNonZeroData = () => !Object.values(syncCountData).every(count => count === 0)

  return (
    <div className="IntegrationCard rounded-lg p-2 border border-gray-200 mb-4">
      <div className="flex flex-row items-center">
        <div className="p-1 bg-gray-50 rounded flex items-center h-9">
          <img src={icon} alt="icon" className="h-8 w-8" />
        </div>
        <div className="ml-2">
          <TextLarge textColor="text-gray-900" className="font-semibold">{heading}</TextLarge>
          <TextBase textColor="text-gray-500" className="font-medium">{subHeading}</TextBase>
        </div>
        <div className="footer ml-auto">
          {!synced && !(customButton && customButtonEnabled) && (
            <Button
              className="!p-2"
              variant="secondary"
              loading={loading}
              onClick={sync}>
              {syncButtonLabel}
            </Button>
          )}
          {!synced && customButton && customButtonEnabled && (
            <div onClick={sync}>
              {customButton}
            </div>
          )}
          {synced &&
            <button
              className="p-2 text-sm text-red-500 flex items-center justify-center rounded border border-medium-gray font-semibold hover:bg-red-50 "
              onClick={() => setShowDisconnectConfirmationModal(true)}
            >
              {loading && <CircularProgress color="white" size={15} className="mr-5" />}
              {unsyncButtonLabel}
            </button>
          }
        </div>
      </div>
      <div className="IntegrationCard__body">
        <div className="titleDiv">
          <TextBase textColor="text-gray-500" className="font-normal">{bodyText}</TextBase>
        </div>
      </div>
      <div className="flex flex-wrap">
        {synced && lastSyncDate &&
          <div className="rounded text-sm m-1 p-2 font-medium bg-cyan-50 !text-cyan-600 flex flex-nowrap" >
            {/* Use this when the data is correct */}
            {/* {"Last synced: " + lastSyncInfo}  */}
            {"Active"}
            {showSyncData && < InfoCyan data-tip data-for={"sync_data" + heading} className="mx-1 cursor-pointer" />}
          </div>}
        {showSyncData &&
          < ReactTooltip place={"bottom"} id={"sync_data" + heading} type="light" effect="solid" className="WhiteTooltipx !shadow-md !px-3 !py-4 !opacity-100">
            <TextSM className="mb-2">Synced so far</TextSM>
            <div className="grid grid-cols-2">
              {(syncCountData?.calls !== 0) && <SyncInfo title="Calls" count={syncCountData.calls} />}
              {(syncCountData?.contacts !== 0) && <SyncInfo title="Contacts" count={syncCountData.contacts} />}
              {(syncCountData?.emails !== 0) && <SyncInfo title="Emails" count={syncCountData.emails} />}
              {(syncCountData?.leads !== 0) && <SyncInfo title="Leads" count={syncCountData.leads} />}
              {(syncCountData?.deals !== 0) && <SyncInfo title="Deals" count={syncCountData.deals} />}
            </div>
          </ReactTooltip>}
      </div>
      {showDisconnectConfirmationModal &&
        <ConfirmActionModal
          open={showDisconnectConfirmationModal}
          onConfirm={() => { unsync(); setShowDisconnectConfirmationModal(false) }}
          onClose={() => { setShowDisconnectConfirmationModal(false) }}
          title={"Disconnect"}
          message={"Are you sure you want to disconnect " + heading + " " + "from your integrations?"}
          integrationName={heading}
        />
      }
    </div >
  );
};

IntegrationCard.propTypes = {
  synced: PropTypes.bool.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  bodyText: PropTypes.string.isRequired,
  sync: PropTypes.func.isRequired,
  unsync: PropTypes.func.isRequired,
  syncButtonLabel: PropTypes.string,
  unsyncButtonLabel: PropTypes.string,
  loading: PropTypes.bool,
};

IntegrationCard.defaultProps = {
  syncButtonLabel: "Connect",
  unsyncButtonLabel: "Disconnect",
  loading: false,
  customButton: null,
  customButtonEnabled: false
};

export default IntegrationCard;


const SyncInfo = ({ title, count }) => (
  <div className="flex flex-col m-1">
    <TextSM>{title}</TextSM>
    <TextBase>{count}</TextBase>
  </div>
)