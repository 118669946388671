import React, { Component } from "react";
import { Modal } from "@material-ui/core";
import { inviteUser } from "../../utils/network";
import { Input } from "app/components/form";
import { TextBase, TextLarge, TextSM, Button } from "app/designSystem"
import { ReactComponent as CancelIcon } from "app/styles/assets/images/icons/plainClose.svg";
import Select from "react-select";
import { formatOptionLabelForUserDropdowns } from "../../utils/helpers";
import { validateStringsOfEmails } from '../../utils/helpers'


export const options = [
  { value: "REP", label: "Rep", explanationText: "Enables customer-facing roles to record, transcribe and analyze calls " },
  { value: "MANAGER-RECORDING", label: "Manager - Recording", explanationText: "Let managers record calls, review team calls, monitor deal activity and onboard new reps" },
  { value: "MANAGER - NON RECORDING", label: "Manager - Non Recording", explanationText: "Let managers review team calls, monitor deal activity and onboard new reps" },
  { value: "OBSERVER", label: "Observer", explanationText: "Give observers visibility into sales conversations, opportunities and team dashboards" }
]


export const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: '50px',
    padding: '0 4px'
  }),
};

export default class InviteUserFormModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      role: options[3],
      optionsLocal: Object.values(options),
      manager: "",
      emailWarning: false,
      loading: false,
      errorMessage: ""
    };
    this.handleClose = this.handleClose.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);
    this.handleManagerChange = this.handleManagerChange.bind(this);
    this.handleInviteUser = this.handleInviteUser.bind(this)
  }

  handleManagerChange(manager) {
    this.setState({
      ...this.state,
      manager: manager
    })
  }

  clearFields() {
    this.setState({
      email: "",
      role: options[3],
      optionsLocal: Object.values(options),
      manager: "",
      emailWarning: false,
      loading: false,
      errorMessage: ""
    })
  }

  async handleInviteUser() {
    let recordingUser = true;
    let role = this.state.role.value
    if (validateStringsOfEmails(this.state.email)) {
      this.setState({
        ...this.state,
        errorMessage: ""
      })
      if ((role == "OBSERVER" || role == "MANAGER - NON RECORDING")) recordingUser = false;
      this.setState({ loading: true });
      const response = await inviteUser(this.state, recordingUser);
      if (response.success) {
        this.props.userAdded();
        this.clearFields();
      } else {
        this.setState({
          ...this.state,
          errorMessage: response.data.message,
          emailWarning: true
        })
      }
      this.setState({ loading: false });
      return response;
    } else {
      this.setState({
        ...this.state,
        errorMessage: "Please enter valid email",
        emailWarning: true
      })
    }


  }

  handleClose() {
    this.props.hide();
  }

  formatOptionsForRoles = ({ label, explanationText }) => (
    <div className="flex  p-1 flex-col">
      <TextBase textColor="text-coolGray-900 ">{label}</TextBase>
      <TextSM textColor="text-coolGray-500" className="w-[300]">{explanationText}</TextSM>
    </div>

  )

  handleRoleChange(changedRole) {
    this.setState({
      ...this.state,
      role: changedRole
    })
  }

  render() {
    return (
      <div>
        <Modal open={this.props.open} onClose={this.handleClose} className="Form__outerContainer">
          <div
            className="!bg-white rounded-lg"
            style={{
              width: "640px",
              maxHeight: "calc(100vh - 6.25rem)",
              padding: 0,
              outline: "none"
            }}
          >


            <div className="px-8 py-5 flex justify-between items-center">
              <TextLarge>Invite User</TextLarge>
              <button onClick={this.handleClose}>
                <CancelIcon />
              </button>
            </div>

            <hr />


            <div className=" w-full flex flex-col px-8 mt-4">
              <div className="flex items-center justify-between">
                <TextBase textColor="text-coolGray-500 my-1">Email</TextBase>
                <TextBase textColor="text-rose-500 my-1">{this.state.errorMessage}</TextBase>
              </div>
              <Input
                value={this.state.email}
                warning={this.state.emailWarning}
                onChange={e => {
                  this.setState({
                    ...this.state,
                    email: e.target.value
                  })
                }}
                autoFocus={true}
                placeHolder={"you@yourcompany.com"}
                className={"!p1 !w-full"}
              />
            </div>

            <div className="flex flex-col px-8 mt-4 ">
              <TextBase textColor="text-coolGray-500 my-1"> Role</TextBase>
              <Select options={this.state.optionsLocal} value={this.state.role} placeholder="Applies To" onChange={this.handleRoleChange} formatOptionLabel={this.formatOptionsForRoles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#f1f5f9',
                  },
                })}
                styles={customStyles}
              />

            </div>

            <div className="flex flex-col px-8 mt-4 ">
              <TextBase textColor="text-coolGray-500 my-1"> Manager</TextBase>
              <Select options={this.props.managers} value={this.state.manager} placeholder="None" formatOptionLabel={formatOptionLabelForUserDropdowns} onChange={this.handleManagerChange} styles={customStyles}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#f1f5f9',
                  },
                })}
              />

            </div>




            <div className=" ml-8 mr-8 items-center justify-around bg-purple-50 rounded-md py-3 mb-2 mt-8">
              <TextBase textColor="text-purple-600" className="justify-around text-center">
                {(this.state.role.value == "OBSERVER" || this.state.role.value == "MANAGER - NON RECORDING") ? "Non recording users are not billed" : "Recording users will be billed"}
              </TextBase>
            </div>



            <div className="flex items-center px-8 mt-8 justify-end pb-5">
              <Button variant="secondary" onClick={this.handleClose} className={"mr-2 !px-4 !py-2"}>
                Cancel
              </Button>
              <Button
                loading={this.state.loading}
                onClick={this.handleInviteUser}
                variant="primary"
                className="!px-4 !py-2"
              >
                Invite user
              </Button>

            </div>





          </div>
        </Modal>
      </div>
    );
  }
}