import React, { lazy, Suspense } from "react";
import {
  Route, BrowserRouter as Router, Switch, Redirect, withRouter,
} from "react-router-dom";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { setFirstUrl, setNotifications } from "app/actions/screen";
import { setUserData } from "app/actions/auth";
import { setLastReload } from "app/actions/persisted";
import { LastLocationProvider } from "react-router-last-location";
import { Loading } from "app/components/common";
import * as Sentry from "@sentry/react";

// Component Imports
import {
  AuthPage,
  Onboarding,
  VerifyEmail,
  ReviewAnnotation,
  ManualAnnotation,
  GuestDashboard,
  EmailVerificationPending,
  Logout
} from "app/containers";
import { FreshsalesForm, LeadsquaredForm, AircallForm, FrontspinForm, CloseForm } from "app/components/onboarding";
import { refreshUser, sendRequest, sendGetRequest } from "app/utils/network";
import ScreenSnackbar from "app/components/screen/ScreenSnackbar";
import { isOnboardingDone } from "../reducers/persisted";
import { fetchDataSafely } from "../utils/dataUtils";
import { FRONTEND_VERSION } from "app/config";
import AddHubspotScopes from "../components/onboarding/AddHubspotScopes";
import PipedriveInstallation from "./PipedriveInstallation";
import ScrollToTop from "../components/common/ScrollToTop";
import { _formatDate } from "../utils/helpers";
import { Integrations } from "@sentry/tracing";
import { loadScript } from "../utils/scriptLoader";

const DashboardPromise = import(/* webpackChunkName: 'Dashboard' ,webpackPrefetch: true  */ "app/containers/Dashboard");
const Dashboard = lazy(() => DashboardPromise);
const initialPaths = ["/logout", "/login", "/signUp", "/onboarding", "/onBoarding", "/verifyEmail", "/verifyemail"];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.identifyUser = this.identifyUser.bind(this);
    this.addIntercom = this.addIntercom.bind(this);
    this.addHeap = this.addHeap.bind(this);
    this.addSegment = this.addSegment.bind(this);
    this.bootHeap = this.bootHeap.bind(this);
    this.addNpsSurvey = this.addNpsSurvey.bind(this);
    this.bootSegment = this.bootSegment.bind(this);
    this.bootIntercom = this.bootIntercom.bind(this);
    this.identifyUserForSentry = this.identifyUserForSentry.bind(this);
    this.loadNotifications = this.loadNotifications.bind(this);
    this.loadScript = this.loadScript.bind(this);
    this.bootNpsSurvey = this.bootNpsSurvey.bind(this);
    this.state = {
      pathname: window.location.pathname,
    };
    this.shouldIReload();
  }

  UNSAFE_componentWillMount() {
    this.loadScrollbarCss();
    const path = window.location.pathname;
    console.log("path:", path, initialPaths.indexOf(path));
    let matchesAny = false;
    initialPaths.map(initialPath => {
      if (path.indexOf(initialPath) !== -1) {
        matchesAny = true;
      }
    });
    if (!matchesAny) {
      this.props.setFirstUrl(path);
    }

    this.isCallEmbed = (path || "").includes("callembed")
    console.log('setting callembed to', this.isCallEmbed)
  }

  loadScrollbarCss() {
    const os = window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";
    if (os === "Windows") {
      var css = document.createElement('link');
      css.rel = "stylesheet";
      css.type = 'text/css'; /* IF is windowsXP */
      css.href = '/scrollbar.css';
      document.getElementsByTagName("head")[0].appendChild(css);
    }
  }

  async shouldIReload() {
    const params = new URLSearchParams();
    params.append("version", FRONTEND_VERSION);
    const isFrontendVersionTooOld = await sendRequest("/auth/is_frontend_version_too_old", params);
    console.log("did i reload for this version?", sessionStorage.getItem("last_frontend_version_reloaded"), FRONTEND_VERSION);
    if (isFrontendVersionTooOld === true && sessionStorage.getItem("last_frontend_version_reloaded") !== FRONTEND_VERSION) {
      sessionStorage.setItem("last_frontend_version_reloaded", FRONTEND_VERSION);
      console.log("reloading frontend");
      window.location.reload(true);
    }
  }

  async componentDidMount() {
    console.log("Running in  environment ", process.env.REACT_APP_NODE_ENV || "local")
    this.loadNotifications();
    this.addFullStory();
    if (!this.isCallEmbed) {
      this.addIntercom();
    }
    this.addNpsSurvey();
    this.addHeap();
    this.addSentry();
    //this.addSegment();
    if (this.isLoggedIn() && this.isEmailVerified()) {
      const data = await refreshUser();
      this.props.setUserData(data);
      this.identifyUserForSentry(data?.user)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.pathname !== window.location.pathname) {
      this.addHeap();
      this.addFullStory();
      this.addNpsSurvey();
      this.shouldIReload();
      this.loadNotifications();
      if (window.Intercom && !this.isCallEmbed) {
        window.Intercom("update");
      }
      this.setState({
        pathname: window.location.pathname,
      });
    }

  }

  componentWillUnmount() {
    const script = document.querySelector("#fullstory");
    if (script) {
      script.removeEventListener("load", this.identifyUser);
    }
    const intercomScript = document.querySelector("#intercom");
    if (intercomScript) {
      intercomScript.removeEventListener("load", this.bootIntercom);
    }

    const heapScript = document.querySelector("#heap");
    if (heapScript) {
      heapScript.removeEventListener("load", this.bootHeap);
    }

    const segmentScript = document.querySelector("#segment");
    if (segmentScript) {
      segmentScript.removeEventListener("load", this.bootSegment);
    }
  }

  async loadNotifications() {
    if (this.isLoggedIn() && this.isEmailVerified()) {
      const localNotifications = await sendRequest("/notifications/get_all_notifications");
      this.props.setNotifications(localNotifications);
    }
  }

  addSegment() {
    console.log("adding segment");
    const myAwesomeScript = document.createElement("script");
    if (process.env.REACT_APP_NODE_ENV === "production")
      myAwesomeScript.setAttribute("src", "/segment.js");
    else
      myAwesomeScript.setAttribute("src", "/segmentDev.js");
    myAwesomeScript.setAttribute("id", "segment");
    document.head.appendChild(myAwesomeScript);
    const script = document.querySelector("#segment");
    script.addEventListener("load", this.bootSegment);
  }

  loadScript(scriptId, jsFileName, onLoadCallback) {
    const myAwesomeScript = document.createElement("script");
    myAwesomeScript.setAttribute("src", "/" + jsFileName + ".js");
    myAwesomeScript.setAttribute("id", scriptId);
    document.head.appendChild(myAwesomeScript);
    const script = document.querySelector("#" + scriptId);
    script.addEventListener("load", onLoadCallback);
  }

  addNpsSurvey() {
    if (this.isLoggedIn()
      && this.props.auth?.data?.user?.npsSurveyEnabled
      && window.location.hostname.indexOf("trywingman.com") !== -1
      && !this.props.auth.isAdminLogin
    ) {
      console.log("adding npsSurvey");
      const myAwesomeScript = document.createElement("script");
      myAwesomeScript.setAttribute("src", "/npsSurvey.js");
      myAwesomeScript.setAttribute("id", "npsSurvey");
      document.head.appendChild(myAwesomeScript);
      const script = document.querySelector("#npsSurvey");
      script.addEventListener("load", this.bootNpsSurvey);
    }
  }

  bootNpsSurvey() {
    setTimeout(() => {
      console.log("here in nps boot");
      const data = this.props.auth.data;
      if (window.SparrowLauncher && window.SparrowLauncher instanceof Function) {
        console.log("calling npsSurvey with", data);
        window.SparrowLauncher('popup', {
          email: data?.user?.emailId,
          variables: {
            userid: data?.user?.userId,
            name: data?.user?.displayName,
          }
        });
      }
    }, 5000);
  }

  addIntercom() {
    console.log("adding intercom");
    const myAwesomeScript = document.createElement("script");
    myAwesomeScript.setAttribute("src", "/intercom.js");
    myAwesomeScript.setAttribute("id", "intercom");
    document.head.appendChild(myAwesomeScript);
    const script = document.querySelector("#intercom");
    script.addEventListener("load", this.bootIntercom);
  }

  addHeap() {
    if (!window.heap && this.isLoggedIn() && window.location.hostname.indexOf("app.trywingman.com") !== -1 && !this.props.auth.isAdminLogin) {
      console.log("adding heap");
      const myAwesomeScript = document.createElement("script");
      myAwesomeScript.setAttribute("src", "/heap.js");
      myAwesomeScript.setAttribute("id", "heap");
      document.head.appendChild(myAwesomeScript);
      const script = document.querySelector("#heap");
      script.addEventListener("load", this.bootHeap);
    }
  }

  addSentry() {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      console.log("adding sentry");
      Sentry.init({
        dsn: "https://5289384acbff41dc817585c752e6975c@o486257.ingest.sentry.io/5542811",
        autoSessionTracking: true,
        environment: process.env.REACT_APP_NODE_ENV,
        release: process.env.REACT_APP_COMMIT_REF,//using commit ID of build from netlify.same is sent to sentry as release id when deploy finishes. Source maps are sent to sentry with the same Identifier, so use the same here to map correct sourcemaps for each deploy
        integrations: [
          new Integrations.BrowserTracing({
            beforeNavigate: context => {
              let pathname = location.pathname;
              if (location.pathname.includes("/call/")) {
                pathname = "/call/:callId";
              } else if (location.pathname.includes("account/")) {
                pathname = "/account/:accountId";
              } else if (location.pathname.includes("sharedCall/")) {
                pathname = "/sharedCall/:shareId";
              }
              return {
                ...context,
                // You could use your UI's routing library to find the matching
                // route template here. We don't have one right now, so do some basic
                // parameter replacements.
                name: pathname
              };
            },
          }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      });
    }
  }

  async identifyUserForSentry(user) {
    if (process.env.REACT_APP_NODE_ENV === "production" && !isEmpty(user)) {
      try {
        //let's log admin login(super admin where you can login as anyone, not to be confused with user role admin) crashes too, but tag  them differently
        console.log("identifying user for sentry", user);
        Sentry.setUser({
          email: user.emailId,
          id: user.userId,
          username: user.displayName
        });

        Sentry.setTags(
          {
            "strings_customer_name": user?.stringsCustomer?.name,
            "strings_customer_id": user?.stringsCustomer?.customerId,
            "isAdminLogin": (this.props?.auth?.isAdminLogin || false)
          }
        )

      } catch (error) {
        console.log("error in identifying user for sentry for user " + user, error);
      }
    }
  }

  async bootSegment() {
    if (this.isLoggedIn() && !this.props.auth.isAdminLogin) {
      console.log("booting segment");
      const { user } = this.props.auth.data;
      if (window.analytics.identify) {
        try {
          window.analytics.identify(user.emailId,
            {
              email: user.emailId,
              name: user.displayName,
              role: fetchDataSafely(user, "roleInfo.role", "NA"),
              customerId: fetchDataSafely(user, "stringsCustomer.customerId"),
              customerName: fetchDataSafely(user, "stringsCustomer.name", "NA"),
            });
        } catch (error) {
          console.log("error in identifying heap", error);
        }
      }
    }
  }

  async bootHeap() {
    if (this.isLoggedIn() && window.location.hostname.indexOf("app.trywingman.com") !== -1 && !this.props.auth.isAdminLogin) {
      console.log("booting heap");
      await new Promise(res => { setTimeout(res, 2000) });
      const { user } = this.props.auth.data;
      if (window.heap.identify) {
        try {
          window.heap.identify(user.userId);
          window.heap.addUserProperties({
            id: user.userId,
            email: user.emailId,
            name: user.displayName,
            dealCentralEnabled: user?.stringsCustomer?.featuresGated?.dealView,
            defaultCrm: user?.stringsCustomer?.defaultCrm,
            recordingDisabled: user?.stringsCustomer?.recordingDisabled,
            role: fetchDataSafely(user, "roleInfo.role"),
            customerId: fetchDataSafely(user, "stringsCustomer.customerId"),
            customerName: fetchDataSafely(user, "stringsCustomer.name"),
          });
        } catch (error) {
          console.log("error in identifying heap", error);
        }
      }
    }
  }

  async bootIntercom() {
    console.log("booting intercom");
    window.Intercom("boot", {
      app_id: "u6fbser8",
    });
    if (this.isLoggedIn() && !this.props.auth.isAdminLogin) {
      const { user } = this.props.auth.data;
      const userHash = await sendRequest("/user/get_intercom_hash");
      window.intercomSettings = {
        app_id: "u6fbser8",
        name: user.displayName, // Full name
        email: user.emailId, // Email address
        userId: user.userId,
        user_id: user.userId,
        user_hash: userHash,
        customerId: fetchDataSafely(user, "stringsCustomer.customerId"),
        role: fetchDataSafely(user, "roleInfo.role", "NULL").toString(),
        isAdmin: fetchDataSafely(user, "roleInfo.admin"),
        company: {
          id: fetchDataSafely(user, "stringsCustomer.customerId", "NA"),
          name: fetchDataSafely(user, "stringsCustomer.name", "NA"),
          created_at: Math.round(new Date(fetchDataSafely(user, "stringsCustomer.createdDate", "")).getTime() / 1000),
        }
      };
      console.log(window.intercomSettings);
    }
  }

  addFullStory() {
    console.log(
      "testing fullstory",
      "adding fullstory?",
      window.location.hostname.indexOf("app.trywingman.com") !== -1,
      this.isLoggedIn(),
      !this.props.auth.isAdminLogin,
      !document.querySelector("#fullstory")
    );
    if (
      !document.querySelector("#fullstory") &&
      window.location.hostname.indexOf("app.trywingman.com") !== -1
      && this.isLoggedIn()
      && !this.props.auth.isAdminLogin
    ) {
      console.log("adding fullstory to browser");
      const myAwesomeScript = document.createElement("script");
      myAwesomeScript.setAttribute("src", "/fullstory.js");
      myAwesomeScript.setAttribute("id", "fullstory");
      document.head.appendChild(myAwesomeScript);
      const script = document.querySelector("#fullstory");
      script.addEventListener("load", this.identifyUser);
    }
  }

  identifyUser() {
    if (this.isLoggedIn()) {
      const { user } = this.props.auth.data;
      window.FS.identify(user.userId, {
        displayName: user.displayName,
        email: user.emailId,
      });
      window.FS.setUserVars({ "role": fetchDataSafely(user, "roleInfo.role") });
    }
  }

  isLoggedIn(props = this.props) {
    const userData = props.auth.data.user || {};
    if (isEmpty(userData)) {
      return false;
    }
    return true;
  }

  isStringsEmployee() {
    const userData = this.props.auth.data.user || {};
    if (!isEmpty(userData)) {
      const emailId = userData.emailId || "";
      return emailId.endsWith("strings.ai") || emailId.endsWith("trywingman.com");
    }
    return false;
  }

  isEmailVerified() {
    const userData = this.props.auth.data.user || {};
    if (userData) {
      return userData.emailVerified;
    }
    return false;
  }

  isOnboardingSkipped() {
    const { onboardingSkipped } = this.props.persisted;
    return onboardingSkipped;
  }

  render() {
    //console.log("in app.jsx render. logged in:", this.isLoggedIn(), " recording:", this.props.auth.isRecording, "role selection:", this.props.auth.isRoleSelectionDone, "onboarding skupped:", this.isOnboardingSkipped(), "onboarding done:", isOnboardingDone(this.props.auth.data.user, this.props.persisted.onboardingFlowStatus));
    //console.log("redirection to onboarding?", this.isLoggedIn() && (this.props.auth.isRecording || !this.props.auth.isRoleSelectionDone) && !this.isOnboardingSkipped() && !isOnboardingDone(this.props.auth.data.user, this.props.persisted.onboardingFlowStatus));
    return (
      <Router>
        <ScrollToTop />
        <LastLocationProvider>
          <ScreenSnackbar />
          <Suspense fallback={<Loading loading />}>
            <Switch>
              <Route path="/verifyEmail/:token" component={VerifyEmail} />
              <Route path="/guest" component={GuestDashboard} />
              {this.isStringsEmployee() && (
                <Route path="/reviewAnnotation/" component={ReviewAnnotation} />
              )}
              {this.isStringsEmployee() && (
                <Route path="/manualAnnotation/" component={ManualAnnotation} />
              )}
              <Route exact path="/logout" component={Logout} />
              {this.isLoggedIn() && !this.isEmailVerified() && (
                <Route path="/*" component={EmailVerificationPending} />
              )}
              {this.isLoggedIn() && (
                <Route path="/freshsalesIntegration/:userId/:access" component={FreshsalesForm} />
              )}
              {this.isLoggedIn() && (
                <Route path="/frontspinIntegration" component={FrontspinForm} />
              )}
              {this.isLoggedIn() && (
                <Route path="/closeIntegration" component={CloseForm} />
              )}
              {this.isLoggedIn() && (
                <Route path="/aircallIntegration/:userId/:access" component={AircallForm} />
              )}
              {this.isLoggedIn() && (
                <Route path="/leadsquaredIntegration/:userId/:access" component={LeadsquaredForm} />
              )}
              {this.isLoggedIn() && (
                <Route path="/updateHubspotSync" component={AddHubspotScopes} />
              )}
              {this.isLoggedIn() &&
                <Route
                  exact
                  path={`/pipedriveInstallation`}
                  component={PipedriveInstallation}
                />}

              <Route path="/onboarding" component={Onboarding} />
              {this.isLoggedIn() && this.isEmailVerified() && (isOnboardingDone(this.props.auth.data.user, this.props.persisted.onboardingFlowStatus) || this.isOnboardingSkipped() || this.isCallEmbed) && (
                <Route path="/" component={Dashboard} />
              )}
              {!this.isLoggedIn() && <Route path="/" component={AuthPage} />}

              {this.isLoggedIn() && (this.props.auth.isRecording || !this.props.auth.isRoleSelectionDone) && !this.isOnboardingSkipped() && !isOnboardingDone(this.props.auth.data.user, this.props.persisted.onboardingFlowStatus) && !this.isCallEmbed && (
                <Redirect to="/onboarding" />
              )}

              {this.isLoggedIn() && !this.props.auth.isRecording && this.props.auth.isRoleSelectionDone && (
                <Redirect to="/" />
              )}
            </Switch>
          </Suspense>
        </LastLocationProvider>
      </Router>
    );
  }
}

/*const Migration = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: migrationData,
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", width: "100vw" }}>
      <img src={logo} style={{ height: "50px", marginBottom: "30px" }} />
      <Lottie options={defaultOptions} height={200} width={300} />
      <span style={{ margin: "10px 0px", fontSize: "30px", color: "#301456", fontWeight: "700" }}>Wingman is currently under maintenance.</span>
      <span style={{ margin: "0px 0px", fontSize: "15px", color: "#301456", fontWeight: "500" }}> The web application and call recorder systems may experience disruptions until {_formatDate(new Date(1601728200000))}.</span>
    </div>
  );
}*/

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { setFirstUrl, setUserData, setLastReload, setNotifications },
)(withRouter(App));
