import React, { useState } from 'react';
import { Button, Input, Label } from "app/components/form";
import { setSnackbar } from '../../actions/screen';
import { resetPasswordWithKey } from '../../utils/network';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setFirstUrl } from "app/actions/screen";
import PasswordValidator from './PasswordValidator';

const ResetPassword = props => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false)

  function submitIfEnter(event) {
    setError(null)
    event.preventDefault();
    if (event.keyCode === 13) {
      submitPassword();
    }
  }

  function validateInput() {
    if (!password || password.trim() === "" || !isValidPassword) {
      setError({ type: "password", message: "Please enter a valid password." })
      return false
    }
    if (!confirmPassword || confirmPassword.trim() === "" || confirmPassword !== password) {
      setError({ type: "confirmPassword", message: "Passwords do not match." })
      return false
    }
    return true;
  }

  const submitPassword = async () => {
    if (!validateInput()) return;
    const p = new URLSearchParams(window.location.search);
    const email = p.get('email');
    const authKey = p.get('authKey');
    if (!email || !authKey) {
      props.setSnackbar("Missing reset params, check if you've copied entire link", "ERROR");
      return;
    }
    setLoading(true);
    const response = await resetPasswordWithKey(email, password, authKey);
    if (response.success) {
      props.setSnackbar('Password was successfully reset.', 'INFO');
      props.setFirstUrl('/');
      props.history.push("/login");
    } else {
      props.setSnackbar(response.data.message, "ERROR");
    }
    setLoading(false);
  }

  return (<div className="LoginForm" id="loginForm">
    <span className="CopyLarge">Reset Password</span>
    <div className="inputField">
      <Label>New password</Label>
      <Input
        placeholder="New password"
        type="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyUp={submitIfEnter}
        warning={error?.type === "password"}
        warningMessage={error?.message}
      />
    </div>
    <div className="inputField">
      <Label>Confirm password</Label>
      <Input
        placeholder="Confirm password"
        type="password"
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
        onKeyUp={submitIfEnter}
        warning={error?.type === "confirmPassword"}
        warningMessage={error?.message}
      />
    </div>
    <PasswordValidator
      password={password}
      setIsValidPassword={setIsValidPassword}
    />
    <div className="buttonOptions">
      <Button
        type="medium"
        loading={loading}
        theme="dark"
        id="login"
        onClick={submitPassword}>
        Update password
      </Button>
    </div>
  </div>);
}

export default connect(
  a => { },
  { setSnackbar, setFirstUrl })(withRouter(ResetPassword));