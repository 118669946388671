import {
  SET_UPCOMING_CALLS,
  SET_PAST_CALLS,
  SET_BATTLECARDS,
  SET_AGENTS_DATA,
  SET_TRACKERS_DATA,
  SET_MANAGER_DASHBOARD_SORT_DATA,
  UPDATE_RECORDING,
  SHOW_UNRECORDED_IN_MY_CALLS_PAGE,
  SET_TEAM_DASHBOARD_DATA,
  SET_SCORECARDS_DASHBOARD_DATA,
  SET_TEAM_DASHBOARD_TRACKERS_DATA,
  SET_DEALS,
  SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA,
  SET_DEAL_CENTRAL_PAGE,
} from "app/actions/actionTypes";

const initialState = {
  upcomingCalls: {
    calls: [],
    hasMore: false,
    page: 1,
    lastUpdated: null,
  },
  pastCalls: {
    calls: [],
    hasMore: false,
    page: 1,
    lastUpdated: null,
  },
  dealsData: {
    opportunities: [],
    totalHits: 0,
    page: 1
  },
  battlecards: {
    //cards: { "Pricing": [], "Competitors": [], "Product Features": [], "Objections": [] },
    cards: {},
    lastUpdated: null,
  },
  agentsData: {
    data: [],
    lastUpdated: null,
  },
  trackersData: {
    data: [],
    lastUpdated: null,
  },
  managerSortData: {
    callCount: "desc",
  },
  teamDashboardData: {},
  teamDealInsightsData: {},
  scorecardsDashboardData: {},
  showUnrecordedInMyCallsPage: false,
};

function updateUpcomingCalls(upcomingCalls, payload) {
  if (!upcomingCalls || !upcomingCalls.calls) return upcomingCalls;
  return {
    ...upcomingCalls,
    calls: upcomingCalls.calls.map(call => {
      if (call.callId === payload.callId)
        call.botNotJoinReason = payload.recording ? '' : 'IGNORED_BY_USER';
      return call;
    })
  };
}

export default function (state = initialState, payload) {
  switch (payload.type) {
    case UPDATE_RECORDING:
      return {
        ...state,
        upcomingCalls: updateUpcomingCalls(state.upcomingCalls, payload)
      };
    case SHOW_UNRECORDED_IN_MY_CALLS_PAGE:
      return {
        ...state,
        showUnrecordedInMyCallsPage: payload.show
      };
    case SET_UPCOMING_CALLS:
      return {
        ...state,
        upcomingCalls: {
          ...payload.details,
          lastUpdated: new Date()
        },
      };
    case SET_PAST_CALLS:
      return {
        ...state,
        pastCalls: {
          ...payload.details,
          lastUpdated: new Date(),
        },
      };
    case SET_BATTLECARDS:
      return {
        ...state,
        battlecards: {
          cards: payload.battlecards,
          lastUpdated: new Date(),
        },
      };
    case SET_AGENTS_DATA:
      return {
        ...state,
        agentsData: {
          data: payload.agentsData,
          lastUpdated: new Date(),
        },
      };
    case SET_TRACKERS_DATA:
      return {
        ...state,
        trackersData: {
          data: payload.trackersData,
          lastUpdated: new Date()
        },
      };
    case SET_MANAGER_DASHBOARD_SORT_DATA:
      return {
        ...state,
        managerSortData: payload.managerSortData,
      };
    case SET_TEAM_DASHBOARD_DATA:
      return {
        ...state,
        teamDashboardData: {
          data: payload.teamDashboardData,
          lastUpdated: new Date()
        }
      }
    case SET_SCORECARDS_DASHBOARD_DATA:
      return {
        ...state,
        scorecardsDashboardData: {
          data: payload.scorecardsDashboardData,
          lastUpdated: new Date()
        }
      }
    case SET_TEAM_DASHBOARD_DEAL_INSIGHTS_DATA:
      return {
        ...state,
        teamDealInsightsData: {
          data: payload.teamDealInsightsData,
          lastUpdated: new Date()
        }
      }
    case SET_TEAM_DASHBOARD_TRACKERS_DATA:
      return {
        ...state,
        teamDashboardTrackersData: {
          data: payload.trackersData,
          lastUpdated: new Date()
        }
      }
    case SET_DEALS:
      return {
        ...state,
        dealsData: {
          ...state.dealsData,
          opportunities: payload.dealsData.opportunities,
          totalHits: payload.dealsData.totalHits,
        }
      };
    case SET_DEAL_CENTRAL_PAGE:
      return {
        ...state,
        dealsData: {
          ...state.dealsData,
          page: payload.page
        }
      };
    default:
      return state;
  }
}