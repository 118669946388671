import React from "react";
import { MeetingTitleColumn } from "../callTable";
import CalendarIcon from "app/styles/assets/images/icons/filter/Calendar.svg";

const NextCallInfo = ({ dealDataFromES }) => {

    function openCallReviewPage() {
        window.open("/call/" + dealDataFromES.nextCallId, "_blank");
    }

    return (
        <div>
            {dealDataFromES?.nextCallDate && dealDataFromES?.nextCallId && dealDataFromES?.nextCallTitle && (
                <div className="DealView__InfoPanel__SectionContainer">
                    <div className="DealView__InfoPanel__Header">
                        <div className="DealView__InfoPanel__HeaderTitle">
                            <img src={CalendarIcon} className="DealView__InfoPanel__HeaderIcon" />
                            <span className="DealView__InfoPanel__HeaderText">Upcoming Call</span>
                        </div>
                    </div>
                    <div onClick={openCallReviewPage} className="DealView__InfoPanel__SectionRow" style={{ cursor: "pointer" }}>
                        <div className="DealView__InfoPanel__SectionItem">
                            <MeetingTitleColumn hoverUnderline showAccountDetails showDate call={{ title: dealDataFromES.nextCallTitle, callType: dealDataFromES.nextCallType, time: dealDataFromES.nextCallDate }} />
                        </div>
                    </div>
                </div>
            )
            }
        </div >
    );
};

NextCallInfo.defaultProps = {}
export default NextCallInfo;
