import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import DialpadLogo from "app/styles/assets/images/integrations/dialpad.svg";
import { DIALPAD_CLIENT_ID, DIALPAD_INSTANCE_BASE } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { ERROR } from "../../actions/actionTypes";

const SyncDialpad = props => {
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useSnackbar();

  async function completeSync(code) {
    const userId = props.auth.data.user.userId || {};
    const res = await crmOAuthCallback(code, 'DIALPAD');
    if (res === "NOK") {
      console.log("Code is:", code);
      setSnackbar("Error syncing. Please try again, check your permission levels or contact support@trywingman.com for help", ERROR);
      return;
    }
    setLoading(false);
    props.refreshUserAction();
  }

  async function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const url = `${DIALPAD_INSTANCE_BASE}oauth2/authorize?client_id=${DIALPAD_CLIENT_ID}&redirect_uri=${location}`;
    openWindowAndListenForParam(url, "Dialpad Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={DialpadLogo}
      heading="Dialpad"
      subHeading="Dialer Integrations"
      bodyText="Sync dialpad to link calls to wingman."
      loading={loading}
      sync={sync}
      dialer="DIALPAD"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncDialpad.defaultProps = {
  access: "user",
};

SyncDialpad.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  access: PropTypes.string,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncDialpad);
