import React from 'react';
import { Grid } from '@material-ui/core';
import TeamDashboardCircleComparison from "./TeamDashboardCircleComparison";
import TeamDashboardLineComparison from './TeamDashboardLineComparison';
import LabelWithExplanation from '../common/LabelWithExplanation';
import { DEAL_INSIGHTS_EXPLANATION } from "app/constants";
import { NoDataCustom } from '../common/NoData';

const TeamDashboardDealInsights = props => {
  if ((!props.categoryCorrelation || props.categoryCorrelation.length === 0) && (!props.statsCorrelation || props.statsCorrelation.length === 0))
    return <NoDataCustom />
  return (<Grid container spacing={3}>
    <Grid item xs={12}>
      <div className="TeamDashboard__sectionHeader">
        <LabelWithExplanation labelClass="TeamDashboard__sectionHeader__text" label="Deal Insights" explanation={DEAL_INSIGHTS_EXPLANATION} />
      </div>
      <Grid container spacing={3}>
        {props.statsCorrelation.map((correlation, i) => <Grid key={i} item xs={4}>
          <TeamDashboardLineComparison correlation={correlation} />
        </Grid>)}
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {props.categoryCorrelation.map((correlation, i) => <Grid key={i} item xs={3}>
          <TeamDashboardLineComparison correlation={correlation} />
        </Grid>)}
      </Grid>
    </Grid>
  </Grid>);
};

export default TeamDashboardDealInsights;