import { sendRequest } from 'app/utils/network';

export const getSignedUrls = async (callId) => {
    const params = new URLSearchParams();
    params.append("callId", callId);

    const response = await sendRequest("/call/get_signed_urls", params);
    return response;
}

export const getCallHighlights = async (callId) => {
    const searchPamas = new URLSearchParams();
    searchPamas.append("callId", callId);
    const callsAndEmailsSearchResult = await sendRequest("/call/account_call_highlights", searchPamas, null, null, false);
    return callsAndEmailsSearchResult;
}

export const assignTags = async (callId, tags) => {
    const searchParams = new URLSearchParams();
    searchParams.append("callId", callId);
    searchParams.append('tagList', tags);
    const assignTagsResult = await sendRequest("/call/assignCallTags", searchParams);
    return assignTagsResult;
}

export const removeTag = async (callId, tag) => {
    const searchParams = new URLSearchParams();
    searchParams.append("callId", callId);
    searchParams.append("tag", tag.label.toLowerCase());
    const removeTagResult = await sendRequest("/call/removeCallTag", searchParams, null, null, false);
    return removeTagResult;
}

export const getSignedUrlsForSnippet = async (shareId) => {
    const params = new URLSearchParams();
    params.append("shareId", shareId)
    let response = await sendRequest("/call/get_shared_call_details", params);
    return response
}