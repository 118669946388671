export const LEAD_QUALIFICATION = "LEAD_QUALIFICATION"
export const SALES = "SALES"
export const GUEST = "GUEST"
export const GOOGLE_GRANTED = "GOOGLE_GRANTED"
export const LOGIN = "LOGIN"
export const SIGN_UP = "SIGN_UP"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const CALL_CARD_DATE_FORMAT = "MMMM DD, YYYY"
export const DEAL_ACTIVITY_DATE_FORMAT = "MMM DD, YY"
export const ACTIVITY_DATE_FORMAT = "MMM DD YYYY"
export const DD_MMM_DATE_FORMAT = "DD MMM, YYYY"
export const DATE_MONTH_FORMAT = "DD MMM"
export const CALL_CARD_DATETIME_FORMAT = "MMMM DD, YYYY HH:mm"
export const TIME_DATE_FORMAT = "MMMM DD, YYYY hh:mm A"
export const STACKED = "STACKED"
export const MAIN = "MAIN";
export const CONTACT_COLOR = "#3AAC77";
export const ACCOUNT_COLOR = "#FF5964";
export const OPPORTUNITY_COLOR = "#645E9D";
export const TLRATIO_GOOD_COLOR = "#2A9D8F";
export const TLRATIO_BAD_COLOR = "#FF5964";
export const TLRATIO_OKAY_COLOR = "#E9A46A";
export const WAITING_IN_QUEUE = "WAITING IN QUEUE";
export const SCHEDULED = "SCHEDULED";
export const PROCESSING = "PROCESSING";
export const MANAGER = "MANAGER";
export const REP = "REP";
export const OBSERVER = "OBSERVER";
export const PERSONAL = "PERSONAL";
export const TEAM = "TEAM";
export const GLOBAL = "GLOBAL";
export const SNIPPET = "SNIPPET";
export const NOT_CONNECTED_DISPOSITIONS = ["CALL_DID_NOT_CONNECT_WITH_PROSPECT", "CALL_NOBODY_JOINED", "CALL_BOTJOIN_DENIED"];
export const SHOW_CRM_WARNING_FOR_CALL_STATUS = ["POST_PROCESSING_DONE"];
export const PRIMARY_REP_EXPLANATION = "The rep who spoke the most in this call.";
export const TLRATIO_EXPLANATION = "The ratio of the time spent by the primary rep talking versus listening.";
export const LONGEST_MONOLOGUE_EXPLANATION = "The longest duration for which the primary rep spoke without pausing.";
export const ENGAGING_QUESTION_EXPLANATION = "The number of questions asked by a rep that fetched a 30 second or longer reply from a client.";
export const ENGAGING_QUESTION_EXPLANATION_DASHBOARD = "The avg number of questions asked by a rep that fetched a 30 second or longer reply from a client.";
export const INTERACTIVITY_EXPLANATION = "It is a measure of how interactive the rep was on a scale of 1-10. 10 being the most interactive.";
export const DEAL_INSIGHTS_EXPLANATION = "Deal insights surfaces correlations that our AI detects for factors that show a significant impact on deal outcomes";
export const CONSENT_PAGES_EXPLANATION = "Takes attendees to a pre-meeting page that allows you to capture consent for call recordings."
export const DEEP_LINK_PROTOCOL_URL = "trywingman://";
export const WARNINGS = [
  {
    name: "LOW_ACTIVITY",
    label: "Low activity in this deal",
    alertSettings: {
      joinPhrase: " with ",
      text: "low activity"
    },
    settings: {
      textBefore: "Activity on this deal has been low for the last",
      textAfter: "",
      threshold: 7,
      acceptableValues: [3, 7, 14, 28],
      enabled: true
    }
  },
  {
    name: "CLOSE_DATE_CHANGED_MANY_TIMES",
    label: "Close date has been changed many times",
    alertSettings: {
      joinPhrase: " where ",
      text: "close date has been changed many times"
    },
    settings: {
      textBefore: "Close date has changed more than",
      textAfter: "times",
      threshold: 1,
      acceptableValues: [1, 2, 3, 4, 5],
      enabled: true
    }
  },
  {
    name: "DEAL_STUCK_IN_STAGE",
    label: "Deal is stuck in a stage",
    alertSettings: {
      joinPhrase: " where ",
      text: "stage hasn't changed for some time."
    },
    settings: {
      textBefore: "Deal is stuck in a stage since the last",
      textAfter: "",
      threshold: 28,
      acceptableValues: [3, 7, 14, 28],
      enabled: true
    }
  },
  {
    name: "SINGLE_THREADED",
    label: "Deal is single threaded",
    alertSettings: {
      joinPhrase: " which are",
      text: " single threaded"
    },
    settings: {
      textBefore: "There is only one contact engaged in this deal.",
      textAfter: "",
      threshold: null,
      acceptableValues: [],
      enabled: true
    }
  },
  {
    name: "PRICING_NOT_DISCUSSED",
    label: "Pricing has not been discussed yet",
    alertSettings: {
      joinPhrase: " where ",
      text: "pricing has not been discussed yet"
    },
    settings: {
      textBefore: "Pricing has not yet been discussed and the deal is set to close in",
      textAfter: "",
      threshold: 14,
      acceptableValues: [3, 7, 14, 28],
      enabled: true
    }
  },
  // NO_NEXT_STEPS: {
  //   label: "Next steps have not been discussed yet",
  //   settings: null
  // },
  {
    name: "DECISION_MAKER_NOT_INVOLVED",
    label: "Decision maker is not involved",
    alertSettings: {
      joinPhrase: " where ",
      text: "decision maker is not yet involved in this deal"
    },
    settings: {
      textBefore: "Decision maker at or above",
      textAfter: "is not yet involved in this deal.",
      threshold: null,
      acceptableValues: ["C-level", "VP-level", "Director-level", "Manager-level"],
      decisionMakerTitleRequired: "Director-level",
      enabled: false
    }
  },
  {
    name: "CLOSE_DATE_IN_PAST",
    label: "Close date has passed",
    alertSettings: {
      joinPhrase: " where ",
      text: "close date has passed"
    },
    settings: {
      textBefore: "Close date for this deal has passed.",
      textAfter: "",
      threshold: null,
      acceptableValues: [],
      enabled: true
    }
  },
  {
    name: "PROSPECT_IS_SILENT",
    label: "No activity from the prospect in a while",
    alertSettings: {
      joinPhrase: " with ",
      text: "no activity from the prospect in a while"
    },
    settings: {
      textBefore: "There has been no activity from the prospect in the last",
      textAfter: "",
      threshold: 7,
      acceptableValues: [2, 3, 7, 14, 28],
      enabled: true
    }
  },
  {
    name: "REP_IS_SILENT",
    label: "No activity from the rep in a while",
    alertSettings: {
      joinPhrase: " with ",
      text: "no activity from the rep in a while"
    },
    settings: {
      textBefore: "There has been no activity from the rep in the last",
      textAfter: "",
      threshold: 7,
      acceptableValues: [2, 3, 7, 14, 28],
      enabled: true
    }
  },
  {
    name: "COMPETITOR_MENTIONED_TOO_MANY_TIMES",
    label: "Competitor mentioned too many times by the customer",
    alertSettings: {
      joinPhrase: " where ",
      text: "a competitor was mentioned by the customer too many times"
    },
    settings: {
      textBefore: "A competitor was mentioned by the customer more than ",
      textAfter: " times",
      threshold: 2,
      acceptableValues: [1, 2, 3, 4],
      enabled: true
    }
  }
]

export const stageOptions = [{
  value: "OPEN",
  label: "All Open Deals"
}, {
  value: "LOST",
  label: "All Lost Deals"
}, {
  value: "WON",
  label: "All Won Deals"
}
]

export const noCompetitorMentioned = [{
  value: "NONE",
  label: "No competitor mentioned"
}]

export const warningsOptions = [{
  value: "HEALTHY",
  label: "All Healthy Deals"
}, {
  value: "ATRISK",
  label: "All At-Risk Deals"
}]

export const DEAL_COLUMNS = [
  {
    id: "account_name",
    position: 0,
    label: "Account/Deal",
    disableSort: null,
    columnClass: "DealCard__nameHeader",
  },
  {
    id: "twoWeekActivityScore",
    position: 1,
    label: "Activity",
    disableSort: null,
    columnClass: "DealCard__activityHeader"
  },
  {
    id: "competitorsMentioned",
    position: 2,
    label: "Competitors Mentioned",
    disableSort: true,
    columnClass: null,
    type: "STRING"
  }, {
    id: "amount_num",
    position: 2,
    label: "Amount",
    disableSort: null,
    columnClass: null,
    type: "NUMERIC"
  },
  {
    id: "nextCallDate",
    position: 3,
    label: "Next Call",
    disableSort: null,
    columnClass: null,
    type: "DATE"
  },
  {
    id: "closeDate",
    position: 4,
    label: "Close Date",
    disableSort: null,
    columnClass: null,
    type: "DATE"
  },
  {
    id: "stage",
    position: 5,
    label: "Stage",
    disableSort: true,
    columnClass: null,
    type: "STRING"
  },
  {
    id: "lastContacted",
    position: 6,
    label: "Last Contacted",
    disableSort: null,
    columnClass: null,
    type: "DATE"
  },
  {
    id: "owner",
    position: 7,
    label: "Owner",
    disableSort: true,
    columnClass: null,
    type: "STRING"
  }
];

export const ACCOUNT_VIEW_SEARCH_FIELDS = {
  "TRANSCRIPT": { searchField: "turns.str", type: "TranscriptExact" },
  "CATEGORY": { searchField: "turns.categories", type: "Highlights" }
}

export default {
  LEAD_QUALIFICATION,
  GOOGLE_GRANTED,
  LOGIN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CALL_CARD_DATE_FORMAT,
  CALL_CARD_DATETIME_FORMAT,
  STACKED,
  MAIN,
  SALES,
  GUEST,
  CONTACT_COLOR,
  ACCOUNT_COLOR,
  OPPORTUNITY_COLOR,
  TLRATIO_BAD_COLOR,
  TLRATIO_GOOD_COLOR,
  TLRATIO_OKAY_COLOR,
  WAITING_IN_QUEUE,
  SCHEDULED,
  PROCESSING,
  MANAGER,
  REP,
  OBSERVER,
  PERSONAL,
  GLOBAL,
  TEAM,
  NOT_CONNECTED_DISPOSITIONS,
  TIME_DATE_FORMAT,
  WARNINGS,
  DEAL_COLUMNS
};

export const ViewType = {
  VIEW_SCORECARD: 'VIEW_SCORECARD',//viewing a single scorecard
  WRITE_SCORECARD: 'WRITE_SCORECARD',//new creating and editing 
  VIEW_SCORECARD_LIST: 'VIEW_SCORECARD_LIST'//entire list of scorecards
}