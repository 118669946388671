import {
  useCallback,
  useEffect, useRef,
} from "react";

export function useKeyListener(id, callback) {
  useEffect(() => {
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener("keyup", callback);
    }
    return () => {
      if (document.getElementById(id)) {
        document.getElementById(id).removeEventListener("keyup", callback);
      }
    };
  }, [id, callback]);
}

export function useKeyListenerRef(callback) {
  const ref = useRef(null);

  const setRef = useCallback(node => {
    if (ref.current) {
      ref.current.removeEventListener("keyup", callback);
    }

    if (node) {
      node.addEventListener("keyup", callback);
    }

    // Save a reference to the node
    ref.current = node
  }, [callback])

  return [setRef];
}