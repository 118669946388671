import React from 'react';
import { Modal } from "@material-ui/core";
import PropTypes from "prop-types";
import { Text2XL, TextBase, Button } from 'app/designSystem';
import { CloseIcon } from 'app/designSystem/icons';

export default function ConfirmActionModal({ open, onClose, onConfirm, title = "Delete", message = "Are you sure you want to delete ?", confirmText = "Confirm", cancelText = "Cancel", integrationName }) {
    return (
        <Modal
            open={open}
            onClose={onClose}
            className="flex justify-center items-center"
        >
            <div className="flex flex-col bg-white rounded-lg w-[24rem] p-0" style={{
                maxHeight: "calc(100vh - 6.25rem)"
            }}>
                <div className="px-4 py-4">
                    <div className="flex justify-between w-full">
                        <Text2XL className="!text-coolGray-900 font-medium">{title + " "} {integrationName}</Text2XL>
                        <CloseIcon className="cursor-pointer" onClick={onClose} />
                    </div>
                </div>
                <hr />
                <div className="px-4 py-4">
                    <TextBase>{message}</TextBase>
                </div>
                <div className="p-4 flex justify-end">
                    <Button variant="secondary" className="!px-4 !py-2" onClick={onClose}>{cancelText}</Button>
                    <Button className="ml-4 !px-4 !py-2 !bg-red-800" onClick={onConfirm}>{confirmText}</Button>
                </div>
            </div>
        </Modal>
    )
}

ConfirmActionModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    confirmText: PropTypes.string,
    cancelText: PropTypes.string,
}

ConfirmActionModal.defaultProps = {
    title: "Delete",
    message: "Are you sure you want to delete ?",
    confirmText: "Confirm",
    cancelText: "Cancel",
}