import React, { useEffect } from "react";
import { CardShort, CustomLink, CallTable, Loading } from "app/components/common";
import {
	setScreenName,
	setScreenType,
	setLoading,
	setSearchTerm,
	setSearchResults,
	setMetaData
} from "app/actions/screen";
import { connect } from "react-redux";
import { Input, Grid, InputAdornment } from "@material-ui/core";
import searchIcon from "app/styles/assets/images/icons/Search.png";
import { STACKED, MAIN } from "app/constants";
import TranscriptIcon from "app/styles/assets/images/icons/dealView/TranscriptIcon.svg";
import AttendeeIcon from "app/styles/assets/images/icons/dealView/AttendeeIcon.svg";
import {
	searchCalls,
	getAllUserNamesForFilters,
	refreshUser,
	sendRequest
} from "app/utils/network";
import { Button } from "app/components/form";
import ResultsAction from "../components/callTable/ResultsAction";
import { withRouter } from "react-router-dom";
import { TextLarge } from "app/designSystem";

const Search = (props) => {

	useEffect(() => {
		const previousScreenName = props.screen.screenName;
		props.setScreenName("");
		fetchData();
		return () => { props.setScreenName(previousScreenName); }
	}, []);

	useEffect(() => {
		if (!props.screen.searchTerm || props.screen.searchTerm.trim() === "") {
			props.history.goBack();
		}
	}, [props.screen.searchTerm])

	async function fetchData() {
		await refreshUser();
		const userNames = await getAllUserNamesForFilters();
		props.setMetaData({
			userNames
		});
	}

	function getBothResults() {
		getSearchCalls(false);
	}

	async function getSearchResults(concat = false, page = props.screen.searchResults.callPage) {
		await getSearchCalls(concat, page);
	}

	async function getSearchCalls(concat = false, page = 0) {
		console.log("getSearchCalls", concat, page);
		props.setLoading(true);
		let query = props.screen.searchTerm;
		if (query !== null && query !== "") {
			if (query.split('"').length - (1 % 2) === 1) {
				query = query.replace(/"([^"]*)$/, "$1");
			}

			const params = new URLSearchParams();
			params.append("query", query);
			params.append("fieldName", props.screen.fieldName);
			params.append("from", page * 25);
			const callResults = await sendRequest("/search/search_field", params);
			props.setLoading(true);
			if (callResults.calls === undefined || callResults.calls === null) {
				alert("Error occured during search. Please retry later.");
				props.setLoading(false);
				return;
			}
			setCalls(callResults, concat, page);
		}
		props.setLoading(false);
	}

	function setCalls(response, concat, page) {
		const calls = response.calls;

		if (calls.length == 0) {
			props.setSearchResults({
				calls: [],
				totalCallHits: 0,
				hasMoreCalls: false,
				callPage: 0
			});
		} else {
			let allCalls = [];
			let initial = 0;
			if (concat) {
				allCalls = props.screen.searchResults.calls;
				initial = allCalls.length;
			}
			props.setSearchResults({
				calls: allCalls.concat(calls),
				totalCallHits: response.totalHits,
				hasMoreCalls: initial + calls.length < response.totalHits,
				callPage: page
			});
		}
	}

	async function nextPage(pageName) {
		props.setLoading(true);
		const { searchResults } = props.screen;
		await props.setSearchResults({
			...searchResults,
			callPage: searchResults.callPage + 1
		});
		getSearchResults(false, searchResults.callPage + 1);

	}

	async function prevPage(pageName) {
		props.setLoading(true);
		const { searchResults } = props.screen;
		await props.setSearchResults({
			...searchResults,
			callPage: searchResults.callPage - 1
		});
		getSearchResults(false, searchResults.callPage - 1);
	}

	const { searchResults } = props.screen;
	const authorizedDomain =
		props.auth.data.user.stringsCustomer.authorizedDomains || "";
	return (
		<div className="pb-15">
			<Loading />
			<CallResults
				calls={searchResults.calls}
				authorizedDomain={authorizedDomain}
				totalHits={searchResults.totalCallHits}
				hasMore={searchResults.hasMoreCalls}
				userNames={props.screen.metaData.userNames}
				page={searchResults.callPage}
				query={props.screen.searchTerm}
				nextPage={() => nextPage("calls")}
				fieldName={props.screen.fieldName}
				prevPage={() => prevPage("calls")}
			/>

		</div>
	);
}

function isQueryPhrase(queryParam) {
	if (!queryParam) return false;
	try {
		return queryParam.split(" ").length > 1;
	} catch (error) {
		return false;
	}
}

function getSearchCriteria(fieldName, query) {
	const isPhrase = isQueryPhrase(query);
	switch (fieldName) {
		case "attendees": return "Attendee name/email contains ";
		case "title": return "Title contains ";
		case "turns.str": return query.startsWith("\"") && query.endsWith("\"") ? "Transcript contains the exact " + (isPhrase ? "phrase " : "word ") : "Transcript contains variations of ";
	}
}

const CallResults = props => (
	<div>
		<div className="flex flex-row items-center  mb-3">
			<TextLarge className="pl-4"> Search Results for:</TextLarge>
			<div className="SearchSuggestions__resultContainer SearchSuggestions__resultContainerHighlighted" key={"title"}>
				<img src={props.fieldName === "attendees" ? AttendeeIcon : TranscriptIcon} className="SearchSuggestions__resultIcon" />
				<div className="SearchSuggestions__resultNameDiv">
					<span className="SearchSuggestions__resultTitle">
						{getSearchCriteria(props.fieldName, props.query)} <span className="SearchSuggestions__resultTitleBold">{props.query}</span>
					</span>
				</div>
			</div>
			<div className="Search__resultsRight">
				<ResultsAction
					startIndex={props.page * 25 + 1}
					endIndex={(props.page + 1) * 25}
					totalHits={props.totalHits}
					page={props.page}
					goToNextPage={props.nextPage}
					goToPreviousPage={props.prevPage}
				/>
			</div>
		</div>

		<div>
			<CallTable {...props} showAccountDetails />
		</div>
		<div style={{ margin: "10px 0px", display: "flex" }}>
			<div className="Search__resultsRight">
				<ResultsAction
					startIndex={props.page * 25 + 1}
					endIndex={(props.page + 1) * 25}
					totalHits={props.totalHits}
					page={props.page}
					goToNextPage={props.nextPage}
					goToPreviousPage={props.prevPage}
				/>
			</div>
		</div>
	</div>
);

const NoResultsFound = () => (
	<div
		style={{ marginTop: "30px", marginBottom: "10px" }}
		className="Manager-Dashboard__head-time"
	>
		No Results Found
	</div>
);

const ShowMoreButton = props => (
	<div
		style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: 10
		}}
	>
		<Button theme="dark" onClick={props.nextPage}>
			Show More
		</Button>
	</div>
);

const mapStateToProps = store => {
	return { ...store };
};

Search.propTypes = {};

Search.defaultProps = {};

export default connect(
	mapStateToProps,
	{
		setLoading,
		setScreenType,
		setScreenName,
		setSearchTerm,
		setSearchResults,
		setMetaData
	}
)(withRouter(Search));
