import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import xss from 'xss';

import {
  _formatSecondsToTime,
  _formatDateToDaysRelative,
} from "app/utils/helpers";
import Linkify from "react-linkify";
import CommentArea from "./CommentArea";
import FooterButton from "./FooterButton";
import EditComment from "app/styles/assets/images/icons/comments/EditComment.svg";
import EditCommentHover from "app/styles/assets/images/icons/comments/EditCommentHover.svg";
import DeleteComment from "app/styles/assets/images/icons/comments/DeleteComment.svg";
import DeleteCommentHover from "app/styles/assets/images/icons/comments/DeleteCommentHover.svg";
import ReplyComment from "app/styles/assets/images/icons/comments/ReplyComment.svg";
import ReplyCommentHover from "app/styles/assets/images/icons/comments/ReplyCommentHover.svg";
import { ConfirmationDialog } from "../../common";
import { useSelector } from "react-redux";
import ProfileImage from "../../common/ProfileImage";
import { getInteractionicon } from "../MeetingIcon";
import LockedIcon from "app/styles/assets/images/icons/comments/locked.svg";
import AddtoGametapesNudge from "./AddtoGametapesNudge";
const Comment = ({
  comment,
  deleteComment,
  editableInit,
  editComment,
  editReply,
  deleteReply,
  onClick,
  editDisabled,
  setActiveComment,
  setCommentBeingEdited,
  commentState,
  type,
  parentId,
  addReply,
  parentSetShowAddReply,
  isDealComment,
  commentId,
  showaddToGametapeNudge,
  commentIdforGameTapeNudge,
  callId,
}) => {
  const [editable, setEditable] = useState(editableInit);
  const [showDialog, setShowDialog] = useState(false);
  const [showAddReply, setShowAddReply] = useState(false);
  const [placeAddReplyAfter, setPlaceAddReplyAfter] = useState(null);
  const userReplying = useSelector((store) => store.auth.data.user);
  const [enableAddToGametapeNudge, setEnableAddToGametapeNudge] = useState(
    showaddToGametapeNudge
  );
  function handleDelete() {
    if (type === "REPLY") {
      deleteReply(comment.id, parentId);
    } else {
      deleteComment(comment.id);
    }
    setShowDialog(false);
  }

  function handleEdit(e) {
    setActiveComment(comment.id);
    setCommentBeingEdited(true);
    setEditable(true);
  }

  async function handleEditSubmit(commentId, updatedComment, taggedUsers) {
    if (type === "REPLY") {
      if (commentId) {
        await editReply(commentId, updatedComment, taggedUsers, parentId);
      } else {
        await addReply(updatedComment, taggedUsers, parentId);
      }
    } else {
      await editComment(commentId, updatedComment, taggedUsers);
    }
    setCommentBeingEdited(false);
    setActiveComment(null);
    setEditable(false);
  }

  async function handleEditCancel() {
    setCommentBeingEdited(false);
    setActiveComment(null);
    setEditable(false);
  }

  function handleAddReply(id) {
    if (type === "COMMENT") {
      setPlaceAddReplyAfter(id);
      setShowAddReply(true);
    } else {
      parentSetShowAddReply(comment.id);
    }
  }

  return (
    <div
      className="Comment"
      style={commentState === "inactive" ? { opacity: 0.5 } : {}}
    >
      <div className="Comment__Container">
        <div className="Comment__iconContainer">
          <ProfileImage userId={comment.userId} size={25} />
        </div>
        <div className="Comment__TextContainer">
          <div className="row">
            <span className="Comment__commenter">{comment.userName}</span>
            {comment.access === "PRIVATE" && (
              <img src={LockedIcon} className="Comment__lockIcon" />
            )}
            <span className="Comment__date">
              {_formatDateToDaysRelative(comment.timeStamp)}
            </span>
          </div>
          <div
            className={
              commentState === "active"
                ? "Comment__textHighlight"
                : "Comment__text"
            }
          >
            {type === "COMMENT" &&
              isDealComment &&
              comment.interactionId !== null && (
                <span onClick={onClick} className="Comment__time">
                  {comment.interactionType && (
                    <img
                      className="Comment__timeIcon"
                      src={getInteractionicon(comment.interactionType)}
                    />
                  )}
                  {comment.interactionName}
                </span>
              )}
            {type === "COMMENT" && !isDealComment && comment.startTime !== 0 && (
              <span onClick={onClick} className="Comment__time">
                {"@" + _formatSecondsToTime(comment.startTime)}
              </span>
            )}

            {editable && (
              <CommentArea
                isNewReply
                type="UPDATE"
                {...comment}
                updateComment={handleEditSubmit}
                handleEditCancel={handleEditCancel}
              />
            )}
            {!editable && <CommentBody onClick={onClick} comment={comment} />}
            {commentId === commentIdforGameTapeNudge &&
              enableAddToGametapeNudge && (
                <>
                  <AddtoGametapesNudge
                    setEnableAddToGametapeNudge={setEnableAddToGametapeNudge}
                    comment={comment}
                  />
                </>
              )}
            <div className="Comment__footer">
              <div className="actions">
                {!editable && type !== "TUTORIAL" && (
                  <FooterButton
                    id={comment.id}
                    hoverColor="#3038B7"
                    icon={ReplyComment}
                    hoverIcon={ReplyCommentHover}
                    onClick={() => handleAddReply(null)}
                    buttonLabel="Reply"
                  />
                )}
                {!editDisabled && !editable && (
                  <FooterButton
                    id={comment.id}
                    icon={EditComment}
                    hoverIcon={EditCommentHover}
                    onClick={handleEdit}
                    buttonLabel="Edit"
                  />
                )}
                {!editDisabled && !editable && (
                  <FooterButton
                    id={comment.id}
                    hoverColor="#D63649"
                    icon={DeleteComment}
                    hoverIcon={DeleteCommentHover}
                    buttonLabel="Delete"
                    onClick={() => setShowDialog(true)}
                  />
                )}
                <ConfirmationDialog
                  title="Delete this Comment?"
                  message="Deletion is permanent and this comment can't be restored once it is deleted."
                  open={showDialog}
                  yesLabel="Delete Comment"
                  onOk={handleDelete}
                  onClose={() => setShowDialog(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingLeft: "20px" }}>
        {showAddReply && !placeAddReplyAfter && (
          <Comment
            key={comment.id}
            type="REPLY"
            parentId={comment.id}
            setCommentBeingEdited={(bool) => {
              setShowAddReply(bool);
            }}
            setActiveComment={() => { }}
            editReply={editReply}
            deleteReply={deleteReply}
            addReply={addReply}
            editableInit
            isNewReply
            comment={{
              comment: "",
              userId: userReplying.userId,
              userName: userReplying.displayName,
              startTime: comment.startTime,
            }}
          />
        )}
        {comment.replies &&
          comment.replies.map((reply, i) => {
            return (
              <>
                <Comment
                  key={"reply" + i + reply.id}
                  setCommentBeingEdited={() => { }}
                  setActiveComment={() => { }}
                  type="REPLY"
                  editReply={editReply}
                  addReply={addReply}
                  deleteReply={deleteReply}
                  parentSetShowAddReply={handleAddReply}
                  parentId={comment.id}
                  comment={reply}
                  editDisabled={reply.userId !== userReplying.userId}
                />
                {showAddReply && reply.id === placeAddReplyAfter && (
                  <Comment
                    type="REPLY"
                    parentId={comment.id}
                    setCommentBeingEdited={(bool) => {
                      setShowAddReply(bool);
                    }}
                    setActiveComment={() => { }}
                    editReply={editReply}
                    deleteReply={deleteReply}
                    addReply={addReply}
                    editableInit
                    comment={{
                      comment: "",
                      userId: userReplying.userId,
                      userName: userReplying.displayName,
                      startTime: comment.startTime,
                    }}
                  />
                )}
              </>
            );
          })}
      </div>
    </div>
  );
};

export function getTransformedComment(comment) {
  let commentString = comment?.replaceAll("\n", "<br/>");
  commentString = commentString.replace(
    /\B\@\([a-zA-Z0-9_ ]+\)/g,
    function (match, name) { return `<span class="Comment__higlightlink">${match}</span>` }
  );
  return (
    <>
      <span className={` Comment__textStyle`}>{ReactHtmlParser(xss(commentString))}</span>
    </>
  );
}

export const CommentBody = ({ comment, onClick }) => {
  return (
    <div className="Comment__body" onClick={onClick}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" style={{ color: "#2680EB" }} href={decoratedHref}>
            {decoratedText}
          </a>
        )}
        properties={{
          onClick: function onClick(e) {
            e.stopPropagation();
            e.preventDefault();
          },
        }}
      >
        <div>{getTransformedComment(comment.comment)}</div>
      </Linkify>
    </div>
  );
};

Comment.propTypes = {
  time: PropTypes.number,
  userName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showReplies: PropTypes.bool,
};

Comment.defaultProps = {
  time: 0,
  userName: "",
  showReplies: true,
  editableInit: false,
  type: "COMMENT",
  isNewReply: false,
  isDealComment: false,
};

export default Comment;
