import React, { useEffect, useState } from "react";
import { CustomDatePicker } from "app/components/common";
import { Typography } from "@material-ui/core";
import { getStartDate, getEndDate, getActivePeriod } from "app/components/dashboards/dateUtils";
import moment from "moment";
import { _formatDateToCallCardDateFormat } from "../../utils/helpers";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useRef } from "react";
import CalendarIcon from "app/styles/assets/images/icons/filter/Calendar.svg";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";
import { Button } from "app/designSystem";
import { Popper, ClickAwayListener } from '@material-ui/core';

const pastOptions = [
    { key: "week", label: "Past 7 days" },
    { key: "month", label: "Past 30 days" },
    { key: "quarter", label: "Past 90 days" },
    { key: "year", label: "Past Year" }
]

const futureOptions = [
    { key: "this_week", label: "This Week" },
    { key: "this_month", label: "This Month" },
    { key: "last_quarter", label: "Last Quarter" },
    { key: "this_quarter", label: "This Quarter" },
    { key: "this_year", label: "This Year" }
]

const DateFiltersNew = props => {
    const [showEditor, setShowEditor] = useState(false);
    const [start, setStart] = useState(props.startDate);
    const [end, setEnd] = useState(props.endDate);
    const [active, setActive] = useState(null);
    const [showRemove, setShowRemove] = useState(false);
    const [showCustom, setShowCustom] = useState(false);
    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);
    const rootContainerRef = useRef()

    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        props.removeFilterValue(props.fieldName);
    }

    useEffect(() => {
        if (!showEditor) {
            if (start !== props.startDate && end !== props.endDate) {
                props.onStartAndEndChange([start, end]);
            } else if (start !== props.startDate) {
                props.onStartDateChange(start);
            } else if (end !== props.endDate) {
                props.onEndDateChange(end);
            }
        }
    }, [showEditor])

    useEffect(() => {
        setStart(props.startDate);
    }, [props.startDate])

    useEffect(() => {
        setEnd(props.endDate);
    }, [props.endDate])

    useEffect(() => {
        setActive(getActiveTile(start, end));
    }, [start, end])

    function getActiveTile(startDate, endDate) {
        if (endDate === null && startDate === null) {
            return "allTime";
        }
        console.log("getting active tile", startDate, endDate);
        if (moment(endDate).diff(moment(new Date()), "days") === 0 && props.type === "PAST") {
            const diffDays = moment(endDate).diff(moment(startDate), "days");
            const diffMonths = moment(endDate).diff(moment(startDate), "months");
            const diffYears = moment(endDate).diff(moment(startDate), "years");
            if (diffDays == 7 || diffDays == 6) {
                return "week";
            }
            if (diffMonths == 1 && diffDays <= 31) {
                return "month";
            }
            if (diffMonths == 3 && diffDays <= 93) {
                return "quarter";
            }
            if (diffYears == 1 && diffDays <= 366) {
                return "year";
            }
        } else {
            if (getActivePeriod("this_quarter", startDate, endDate))
                return "this_quarter";
            if (getActivePeriod("last_quarter", startDate, endDate))
                return "last_quarter";
            if (getActivePeriod("this_year", startDate, endDate))
                return "this_year";
            if (getActivePeriod("this_month", startDate, endDate))
                return "this_month";
            if (getActivePeriod("this_week", startDate, endDate))
                return "this_week";
        }
        return null;
    }

    function handleTileClick(period) {
        setShowCustom(false);
        setStart(getStartDate(period));
        setEnd(getEndDate(period));
        setActive(period);
        setShowEditor(false);
    }

    function onStartDateChange(date) {
        setActive(null);
        setStart(date);
    }

    function onEndDateChange(date) {
        if (date === null) {
            setEnd(null);
            setActive(null);
            return;
        }

        const endDate = moment(date).toDate();
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        setActive(null);
        setEnd(endDate);
    }

    function changeTimezone(date, ianatz) {
        // suppose the date is 12:00 UTC
        const invdate = new Date(
            date.toLocaleString("en-US", {
                timeZone: ianatz,
            }),
        );

        console.log("date in aus timezone is", invdate);

        // then invdate will be 07:00 in Toronto
        // and the diff is 5 hours
        const diff = date.getTime() - invdate.getTime();

        console.log("diff is", diff, new Date(date.getTime() - diff));

        // so 12:00 in Toronto is 17:00 UTC
        return new Date(date.getTime() - diff);
    }

    function toggleShowEditor() {
        setShowEditor(showEditor => !showEditor);
    }

    const { classes } = props;

    return (
        <ClickAwayListener onClickAway={() => setShowEditor(false)}>
            <div className="FilterField__container" ref={rootContainerRef}>
                <div ref={ref} onClick={toggleShowEditor} className="FilterField__valueContainer FilterField__valueContainerDropdown">
                    {props.enableRemove && showRemove && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
                    {props.showIcon && <img style={{ width: "20px", height: "20px", margin: "0px 5px" }} src={CalendarIcon} />}
                    {start === null && end === null && <span className="FilterField__valueContainer__label">All Time</span>}
                    {(start !== null || end !== null) && <span className="FilterField__valueContainer__label">{_formatDateToCallCardDateFormat(start) + " - " + _formatDateToCallCardDateFormat(end)}</span>}
                </div>
                <Popper className="FilterField__popup text-base"
                    anchorEl={rootContainerRef.current}
                    onClose={() => { setShowEditor(false) }}
                    open={showEditor}
                    placement={"bottom-start"}
                    container={props.popoverContainerRef?.current}
                    modifiers={{
                        flip: { enabled: false },
                        preventOverflow: { enabled: false },
                        hide: { enabled: false }
                    }}
                >
                    <div className="DateFilter">
                        <div className="DateFilter__menuContainer">
                            {props.type === "PAST" && pastOptions.map((option, i) =>
                                <SelectItem
                                    key={i}
                                    onClick={() => handleTileClick(option.key)}
                                    active={active == option.key}
                                    label={option.label}
                                />
                            )}
                            {props.type === "FUTURE" && futureOptions.map((option, i) =>
                                <SelectItem
                                    key={i}
                                    onClick={() => handleTileClick(option.key)}
                                    active={active == option.key}
                                    label={option.label}
                                />
                            )}

                            {!props.disableAllTime && <SelectItem
                                onClick={() => handleTileClick("allTime")}
                                active={active == "allTime"}
                                label="All time"
                            />}
                            <SelectItem
                                onClick={() => setShowCustom(true)}
                                active={active == null}
                                label="Custom"
                            />
                        </div>
                        {showCustom && <div className="DateFilter__calendarContainer">
                            <CustomDatePicker
                                pickerClass="DateFilter__picker"
                                calendarContainer="DateFilter__calendarContainerLeft"
                                clearIcon={null}
                                handleDateChange={onStartDateChange}
                                maxDate={props.type === "PAST" ? new Date() : null}
                                selectedDate={
                                    start === null ? null : moment(start).toDate()
                                }
                            />
                            <Typography style={{ display: "inline" }} align="center" variant="title">
                                {" - "}
                            </Typography>
                            <CustomDatePicker
                                clearIcon={null}
                                pickerClass="DateFilter__picker"
                                maxDate={props.type === "PAST" ? new Date() : null}
                                calendarContainer="DateFilter__calendarContainerRight"
                                handleDateChange={onEndDateChange}
                                selectedDate={end === null ? null : moment(end).toDate()}
                            />
                        </div>}
                        {showCustom && <Button className="h-8 w-24 mx-auto mt-2" onClick={() => { setShowEditor(false) }}>Done</Button>}
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

DateFiltersNew.defaultProps = {
    enableRemove: true,
    showIcon: false,
    type: "PAST",
}

export default DateFiltersNew;

const SelectItem = ({ onClick, active, label }) => {
    return (
        <div className={"DateFilter__menuItem" + (active ? " DateFilter__menuItemActive" : "")} onClick={onClick}>
            <span className={"DateFilter__menuItemText" + (active ? " DateFilter__menuItemActiveText" : "")}>{label}</span>
        </div>
    );
}
