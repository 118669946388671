const defaultTheme = require("tailwindcss/defaultTheme");

const { fontFamily: { sans } } = defaultTheme
//use the same font and fall back fonts as the rest of the application does using tailwind
export default {
  overrides: {
    MuiInputBase: {
      root: {
        "font-size": ".875rem"
      },
      input: {
        "&::placeholder": {
          "font-weight": "500"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#2680EB",
    },
    secondary: {
      main: "#d5deff",
    }
  },
  typography: {
    fontFamily: [
      "MaisonNeue", ...sans
    ].join(',')
  }
};