import React from "react";
import PropTypes from "prop-types";
import { SPaper } from "app/components/common";

const Card = props => {
  return (
    <SPaper className={`Card ${props.className}`} elevation={props.elevation}>
      {props.title && (
        <div className="Card__head">
          {typeof props.title === "string" && <span className="title">{props.title}</span>}
          {typeof props.title === "object" && props.title}
          {props.headActions && <div className="Card__head-actions">{props.headActions}</div>}
        </div>
      )}
      <div
        className={`Card__body ${props.className}__body`}
        style={{ overflowY: props.overflowY, maxHeight: props.maxHeight }}
      >
        {props.children}
      </div>
    </SPaper>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.number,
  headActions: PropTypes.node,
  title: PropTypes.any,
};

Card.defaultProps = {
  children: null,
  className: "",
  headActions: null,
  elevation: 2,
  title: null,
  style: null,
  overflowY: "auto",
  maxHeight: "460px",
};

export default Card;
