import React from "react";
import { CallTable, Loading } from "app/components/common";
import { Filter } from "app/components/filter";
import { IconButton } from "@material-ui/core";
import NavigateNextIcon from "app/styles/assets/images/icons/nextPage.png";
import NavigatePreviousIcon from "app/styles/assets/images/icons/previousPage.png";
import DownloadIcon from "app/styles/assets/images/icons/excel.svg";
import ReactTooltip from "react-tooltip";

export class CallTablePageRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFilter: false };
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = this.hideFilter.bind(this);
  }

  showFilter() {
    this.setState({
      showFilter: true
    });
  }

  hideFilter() {
    this.setState({
      showFilter: false
    });
  }

  render() {
    const {
      startIndex,
      endIndex,
      totalHits,
      resetFilter,
      userNames,
      deletedUserNames,
      categories,
      competitors,
      onApplyFilter,
      filterParams,
      sort,
      classes,
      goToNextPage,
      goToPreviousPage,
      exportToExcel,
      status,
      types,
      removeFilterValue,
      managers,
      scoringTemplates
    } = this.props;
    return (
      <div>

        <Filter
          onApplyFilter={onApplyFilter}
          filterParams={filterParams}
          headerRightContent={<PaginationInfo filterParams={filterParams} startIndex={startIndex} endIndex={endIndex} exportToExcel={exportToExcel} goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} totalHits={totalHits} />}
          visible={this.state.showFilter}
          hideFilter={this.hideFilter}
          userNames={userNames}
          deletedUserNames={deletedUserNames}
          removeFilterValue={removeFilterValue}
          managers={managers}
          categories={categories}
          competitors={competitors}
          status={status}
          types={types}
          type="CALLLOG"
          scoringTemplates={scoringTemplates}
          defaultFilters={["agentNames", "managers", "duration"]}

        />
        <div>
          <div className="">
            <CallTable
              calls={this.props.calls || []}
              sort={sort}
              sortAvailable
              filterParams={filterParams}
              userNames={userNames}
              showAccountDetails
              showCrmDetails
              li
              loading={this.props.loading}
            />
          </div>
          <div className="CallTable__Footer__rightContents pb-15">
            {totalHits !== 0 && (
              <React.Fragment>
                <span style={{ marginRight: 10 }}>
                  {`Viewing ${startIndex} - ${endIndex} of ${totalHits}`}
                </span>
                <PaginationButtons
                  disablePrevious={startIndex < filterParams.size}
                  disableNext={totalHits <= endIndex}
                  onNext={goToNextPage}
                  onPrevious={goToPreviousPage}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const PaginationInfo = ({ totalHits, startIndex, endIndex, filterParams, goToNextPage, goToPreviousPage, exportToExcel }) => (
  <div className="rightContents">
    <div className="CallTable__PaginationWrapper">
      {totalHits !== 0 && (
        <React.Fragment>
          <span style={{ marginRight: 10 }}>
            {`Viewing ${startIndex} - ${endIndex} of ${totalHits}`}
          </span>
          <PaginationButtons
            disablePrevious={startIndex < filterParams.size}
            disableNext={totalHits <= endIndex}
            onNext={goToNextPage}
            onPrevious={goToPreviousPage}
          />
        </React.Fragment>
      )}
      <div style={{ marginRight: 10 }}>
        <ReactTooltip id="downloadExcel" effect="solid">
          <span>Download Call Data</span>
        </ReactTooltip>
        <img
          data-tip data-for="downloadExcel"
          onClick={exportToExcel}
          style={{
            width: "30px",
            height: "30px",
            margin: "0px 15px"
          }}
          alt=""
          src={DownloadIcon}
        />
      </div>
    </div>
  </div>
);

export const PageButton = props => {
  if (props.type !== 'next' && props.type !== 'previous') return null;
  const icon = props.type === 'next' ? NavigateNextIcon : NavigatePreviousIcon;
  const onClick = props.disabled ? () => { } : props.onClick;
  return (<div title={props.type === "next" ? "Next Page" : "Previous Page"} onClick={onClick} className={`CallTable__paginationButton ${props.disabled ? 'CallTable__paginationButtonDisabled' : ''}`}>
    <img src={icon} className="CallTable__paginationIcon" />
  </div>);
};

export const PaginationButtons = ({ disablePrevious, disableNext, onNext, onPrevious, className }) => {
  return (<div className={`CallTable__paginationContainer ${className}`}>
    <PageButton
      disabled={disablePrevious}
      type="previous"
      onClick={onPrevious}
    />
    <PageButton
      disabled={disableNext}
      type="next"
      onClick={onNext}
    />
  </div>);
};