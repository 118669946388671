import React, { useEffect, useState, useCallback } from "react";
import { withErrorHandling } from "app/components/error/errorHandlingScreen";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFilteredCalls } from "app/utils/network";
import { clone } from "lodash";
import { CallTable, Loading } from "../components/common";
import { IconButton, Chip } from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import CloseIcon from "@material-ui/icons/Close";
import { getHashFromFilterParams } from "./CallTablePage";
import { Button } from "../components/form";
import { SET_MANAGER_DASHBOARD_FILTER, SET_CALL_LOG_FILTER_AND_REFRESH } from "../actions/actionTypes";

const fieldNames = {
  "duration": "Duration",
  "talkListenRatio": "Talk to Listen Ratio",
  "time": "Time",
  "numQuestionsAsked": "Customer Questions",
  "longestMonologueDuration": "Longest Monologue Duration",
}

const sortNames = {
  "ASC": "Ascending",
  "DESC": "Descending",
}

const AgentCalls = ({ name, agentId, sortField, onClose, history, filterParams }) => {

  const [results, setResults] = useState({ calls: [] });
  const [loading, setLoading] = useState(false);
  const [sortFieldLocal, setSortFieldLocal] = useState(sortField);
  const [sortDirection, setSortDirection] = useState("DESC");
  const userNames = useSelector(store => store.screen.metaData.userNames);
  const dispatch = useDispatch();
  const goToTeamCallLogCallback = useCallback(goToTeamCallLog, [filterParams]);

  async function sort(sortFieldParam, sortDirectionParam) {
    setSortFieldLocal(sortFieldParam);
    setSortDirection(sortDirectionParam);
    setLoading(true);
    const updatedFilterParams = clone(filterParams);
    updatedFilterParams.sortField = sortFieldParam;
    updatedFilterParams.sortDirection = sortDirectionParam;
    await onApplyFilter(updatedFilterParams);
    setLoading(false);
  }

  async function onApplyFilter(filterParamsLocal = filterParams) {
    setLoading(true);
    const updatedFilterParams = clone(filterParamsLocal);
    updatedFilterParams.from = 0;
    updatedFilterParams.size = 5;
    updatedFilterParams.agentNames = [agentId];
    updatedFilterParams.sortField = sortFieldLocal;
    updatedFilterParams.sortDirection = sortDirection;
    const searchResults = await getFilteredCalls(updatedFilterParams);
    setResults(searchResults);
    setLoading(false);
  }

  async function goToTeamCallLog() {
    const filterParamsUpdated = clone(filterParams);
    filterParamsUpdated.size = 50;
    filterParamsUpdated.agentNames = [agentId];
    filterParamsUpdated.sortField = sortFieldLocal;
    filterParamsUpdated.sortDirection = sortDirection;
    console.log("updated filter params", filterParamsUpdated);
    dispatch({ type: SET_CALL_LOG_FILTER_AND_REFRESH, filterParams: filterParamsUpdated });
    history.push({
      pathname: `/calllog`,
    });

  }

  useEffect(() => {
    const updatedFilterParams = clone(filterParams);
    updatedFilterParams.sortField = sortField;
    updatedFilterParams.sortDirection = "DESC";
    switch (sortField) {
      case "longestMonologueDuration":
      case "talkListenRatio": updatedFilterParams.domReps = [agentId];
    }
    onApplyFilter(updatedFilterParams);
  }, [agentId, sortField])

  return (
    <div className="AgentCalls">
      <div className="AgentCalls__header">
        <div className="AgentCalls__header_titleDiv">
          <span className="text">{`${name}'s Top ${results.calls.length > 0 ? results.calls.length : ""} Calls`}</span>
          <Chip
            color="primary"
            style={{ marginLeft: "5px" }}
            icon={<SortIcon />}
            size="small"
            label={<span>Sorted by: <b>{fieldNames[sortFieldLocal]} ({sortNames[sortDirection]})</b></span>}
          />
        </div>
        <div className="rightDiv">
          <IconButton onClick={onClose}><CloseIcon color="primary" /></IconButton>
        </div>
      </div>
      <Loading loading={loading} />
      <CallTable
        calls={results.calls}
        sort={sort}
        sortAvailable
        filterParams={{ ...filterParams, sortField: sortFieldLocal, sortDirection }}
        userNames={userNames}
        showTable
        showRep={false}
        showLongestMonologue={sortField === "longestMonologueDuration"}
      />
      <div className="AgentCalls__footer">
        <Button theme="dark" onClick={goToTeamCallLogCallback}> View All Calls </Button>
      </div>
    </div>
  );
}

AgentCalls.propTypes = {};

AgentCalls.defaultProps = {};

export default withRouter(withErrorHandling(AgentCalls));
