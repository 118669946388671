import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Paper, Tabs, Tab, AppBar, withStyles, Grid } from '@material-ui/core';
import { getTeamDashboardData, getTeamDashboardDealInsightsData } from '../utils/network';
import { setTeamDashboardData, setTeamDashboardDealInsightsData, setScorecardsDashboardData } from '../actions/pageData';
import DateFiltersNew from "../components/filter/DateFiltersNew";
import {
  CategoryHeader
} from "../components/filter";
import SelectWithSearchNew from "../components/filter/SelectWithSearchNew";
import { DashboardFilter } from "app/components/dashboards";
import { GetSortOrder } from "app/components/dashboards/helpers";
import { setTeamDashboardTrackersData } from "app/actions/pageData";
import { setScreenType, setManagerDashboardFilter, setManagerDealInsightsFilter, setScorecardsDashboardFilter } from "app/actions/screen";
import TeamDashboardOverview from '../components/dashboards/TeamDashboardOverview';
import TeamDashboardDealInsights from '../components/dashboards/TeamDashboardDealInsights';
import TeamDashboardCompetitorInsights from '../components/dashboards/TeamDashboardCompetitorInsights';
import TeamDashboardTeamInsights from '../components/dashboards/TeamDashboardTeamInsights';
import TeamDashboardTrackers from '../components/dashboards/TeamDashboardTrackers';
import ScorecardsDashboard from '../components/dashboards/ScorecardsDashboard';
import Home from "app/styles/assets/images/sidebar/Home.svg";
import HomeActive from "app/styles/assets/images/sidebar/HomeActive.svg";
import Deals from "app/styles/assets/images/sidebar/Deals.svg";
import DealsActive from "app/styles/assets/images/sidebar/DealsActive.svg";
import Graph from "app/styles/assets/images/sidebar/Graph.svg";
import GraphActive from "app/styles/assets/images/sidebar/GraphActive.svg";
import People from "app/styles/assets/images/sidebar/People.svg";
import PeopleActive from "app/styles/assets/images/sidebar/PeopleActive.svg";
import Scorecard from "app/styles/assets/images/sidebar/scorecard.svg";
import ScorecardActive from "app/styles/assets/images/sidebar/scorecardActive.svg";
import Star from "app/styles/assets/images/sidebar/Star.svg";
import StarActive from "app/styles/assets/images/sidebar/StarActive.svg";
import { emptyFilterParams, removeFilterValue } from '../utils/filter';
import { usePrevious } from '../utils/hooks/usePrevious';
import Skeleton from 'react-loading-skeleton';
import { hasCrm } from '../reducers/persisted';
import { fetchDataSafely } from '../utils/dataUtils';
import { getSkills } from '../actions/scorecards';
import { getAllPhrasesForCustomer } from '../utils/network';
import CRMStatusPopup from 'app/components/dealView/CRMStatusPopup';
import { isCrmNotSynced } from 'app/components/settings/helpers'

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: '160px',
      width: '100%',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      height: '4px',
      backgroundColor: '#2680EB',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '14px',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
  wrapper: {
    flexDirection: 'row',
    '& > img': {
      marginRight: '10px',
      width: '20px',
      height: '20px'
    }
  },
}))((props) => <Tab disableRipple {...props} />);


const TeamDashboard = props => {
  const [graphData, setGraphData] = useState(null);
  const [activeTab, setActiveTab] = useState(getActiveTabFromHash());
  const [loading, setLoading] = useState(true);
  const prevActiveTab = usePrevious(activeTab);
  const [filterParams, setFilterParams] = useState(props.screen.managerDashboardFilter);
  const [dealInsightsFilter, setDealInsightsFilter] = useState(props.screen.managerDealInsightsFilter);
  const teamDashboardData = props && props.pageData && props.pageData.teamDashboardData && props.pageData.teamDashboardData.data;
  const teamDealInsightsData = props && props.pageData && props.pageData.teamDealInsightsData && props.pageData.teamDealInsightsData.data;
  const [showCrmUnsyncedPopup, setShowCrmUnsyncedPopup] = useState(false);

  var videoCallStats;
  var videoCallDispositions;
  var dialerCallStats;
  var dialerCallDispositions;
  var categoryCorrelation = [];
  var statsCorrelation = [];
  var competitorMentions = {};
  var userScorecardStats = {}
  var nCalls = 0;

  if (teamDashboardData) {
    competitorMentions = teamDashboardData.competitorMentions;
    nCalls = teamDashboardData.totalCalls;
    videoCallStats = teamDashboardData.videoCallStats;
    videoCallDispositions = teamDashboardData.videoCallDispositions;
    dialerCallStats = teamDashboardData.dialerCallStats;
    dialerCallDispositions = teamDashboardData.dialerCallDispositions;
    userScorecardStats = teamDashboardData.userScorecardStats
  }

  if (teamDealInsightsData) {
    categoryCorrelation = filterCorrelations(teamDealInsightsData.categoryCorrelation);
    statsCorrelation = [
      { ...teamDealInsightsData.tlRatioCorrelation, maxValue: 1 },
      { ...teamDealInsightsData.monologueCorrelation, format: 'duration' },
      { ...teamDealInsightsData.interactivityCorrelation, format: 'number' }];
    if (statsCorrelation.length === 0 || !statsCorrelation[0] || (!statsCorrelation[0].won) || statsCorrelation[0].won.count === 0 || (!statsCorrelation[0].lost) || statsCorrelation[0].lost.count === 0) statsCorrelation = [];
  }

  const { userNames, managers, categories, status, types } = props.screen.metaData;
  const trackersData = props.pageData.teamDashboardTrackersData ? props.pageData.teamDashboardTrackersData.data : {};
  const featuresGated = fetchDataSafely(props, "auth.data.user.stringsCustomer.featuresGated", {});
  const [topicsForDashboards, setTopicsForDashboards] = useState({})

  useEffect(() => {
    componentDidMount();
  }, []);

  useEffect(() => {
    checkCrmSyncStatus();
  }, []);

  useEffect(() => {
    setFilterParams(props.screen.managerDashboardFilter);
  }, [props.screen.managerDashboardFilter])

  const checkCrmSyncStatus = () => {
    if (isCrmNotSynced(props.auth))
      setShowCrmUnsyncedPopup(true)
  }

  async function componentDidMount() {
    setLoading(true);
    props.getSkills()
    setFilterParams(props.screen.managerDashboardFilter);
    if (activeTab === "deal-insights") {
      updateDashboardData(props.screen.managerDashboardFilter, false);
      await updateDealInsightsData(dealInsightsFilter, false);
    } else {
      await updateDashboardData(props.screen.managerDashboardFilter, false);
      updateDealInsightsData(dealInsightsFilter, false);
    }
    getTopicsForDashboards()
  }


  async function getTopicsForDashboards() {
    setLoading(true);
    const response = await getAllPhrasesForCustomer();
    const phrases = {};
    response.filter(phrase => phrase.text != "__EMPTY_CATEGORY_PLACEHOLDER__").map((phrase, i) => {
      const category = phrase.category.toLowerCase()
      if (phrases[category] == null) {
        if (phrase.exactMatchRequired) {

          phrases[category] = { exact: [phrase.text], approx: [] };
        } else {
          phrases[category] = { exact: [], approx: [phrase.text] };
        }
      } else if (phrase.exactMatchRequired) {
        phrases[category].exact.push(phrase.text);

      } else {
        phrases[category].approx.push(phrase.text);
      }
    });
    setTopicsForDashboards(phrases)
    setLoading(false)
  }

  useEffect(() => {
    const dashboardData = props && props.pageData && props.pageData.teamDashboardData && props.pageData.teamDashboardData.data;
    setGraphData(dashboardData && dashboardData.callsGraphData ? dashboardData.callsGraphData : null);
  }, [props.pageData.teamDashboardData]);

  function getActiveTabFromHash() {
    return window.location.hash
      ? window.location.hash.slice(1, window.location.hash.length)
      : 'overview';
  }

  function isInsignificant(won, lost) {
    var bigger = Math.max(won, lost);
    var smaller = Math.min(won, lost);
    return (bigger < 1) ? (bigger - smaller < 0.1) : (smaller > 0.9 * bigger)
  }

  function filterCorrelations(correlations) {
    if (!correlations) return [];
    return correlations.filter(correlation => {
      if (correlation.lost.count + correlation.won.count < 6) {
        return false;
      }
      if (isInsignificant(correlation.won.value, correlation.lost.value)) {
        return false;
      }
      return true;
    });
  }

  function compileTrackersData(trackerStats, totalCalls, displayNames) {
    const trackersData = {};
    for (var userId of Object.keys(trackerStats)) {
      const userTrackers = trackerStats[userId];
      const nTrackers = Object.values(userTrackers).reduce((c, a) => c + a, 0);
      if (nTrackers === 0) continue;
      for (var tracker of Object.keys(userTrackers)) {
        if (!trackersData[tracker]) trackersData[tracker] = [];
        var name = displayNames[userId] || '';
        if (name) name += " (" + (totalCalls[userId] || 0) + ")";

        trackersData[tracker].push({
          name,
          total: totalCalls[userId] || 1,
          count: userTrackers[tracker]
        });
      }
    }
    const keys = Object.keys(trackersData);
    for (let j = 0; j < keys.length; j++) {
      trackersData[keys[j]].sort(GetSortOrder("total", "desc"));
    }
    return trackersData;
  }

  function extractTotalCallsAndDisplayName(stats, totalCalls, displayNames) {
    for (var userId of Object.keys(stats)) {
      const userStats = stats[userId];
      if (!totalCalls[userId]) totalCalls[userId] = 0;
      if (userStats.stats) totalCalls[userId] += userStats.stats.callCount;
      displayNames[userId] = userStats.displayName;
    }
  }

  async function updateDashboardData(passedReqData = null, saveFilter = true) {
    if (passedReqData === null) passedReqData = filterParams;
    setLoading(true);
    const data = await getTeamDashboardData(passedReqData);
    if (data) {
      const totalCalls = {};
      const displayNames = {};
      extractTotalCallsAndDisplayName(data.videoCallStats, totalCalls, displayNames);
      extractTotalCallsAndDisplayName(data.dialerCallStats, totalCalls, displayNames);
      props.setTeamDashboardTrackersData(compileTrackersData(data.userTrackerStats, totalCalls, displayNames));
      props.setTeamDashboardData(data)
      const { userId } = props.auth.data.user;
      if (saveFilter)
        await props.setManagerDashboardFilter(passedReqData, userId);
    }
    setLoading(false);
  }

  async function updateDealInsightsData(passedReqData = null, saveFilter = true) {
    if (passedReqData === null) passedReqData = dealInsightsFilter;
    setLoading(true);
    const data = await getTeamDashboardDealInsightsData(passedReqData);
    if (data) {
      props.setTeamDashboardDealInsightsData(data);
      const { userId } = props.auth.data.user;
      if (saveFilter)
        await props.setManagerDealInsightsFilter(passedReqData, userId);
    }
    setLoading(false);
  }

  function onChange(fieldName, value) {
    onMultipleChanges([fieldName], [value]);
  }

  function onMultipleChanges(fieldNames, values) {
    const newReqData = { ...dealInsightsFilter };
    fieldNames.forEach((fieldName, i) => newReqData[fieldName] = values[i]);
    setDealInsightsFilter(newReqData);
    updateDealInsightsData(newReqData);
  }

  function getStyledTab(key, label, icon, iconActive) {
    return <StyledTab disableRipple label={<>
      {/* <img src={activeTab === key ? iconActive : icon} /> */}
      {label}</>} value={key} style={{ flexGrow: 1, maxWidth: "unset", marginRight: "3rem" }} />
  }

  async function resetFilter() {
    const filterParams = emptyFilterParams;
    updateDashboardData(filterParams);
  }

  async function handleRemoveFilterValue(fieldName) {
    const filterParamsUpdated = removeFilterValue(
      filterParams,
      fieldName
    );
    updateDashboardData(filterParamsUpdated);
  }

  function changeActiveTab(e, v) {
    window.history.replaceState({}, undefined, "#" + v);
    setActiveTab(v);
  }

  return (
    <>
      <AppBar position="static" color="inherit" elevation={1} style={{ marginTop: "-15px", position: 'fixed', width: '100%', zIndex: '10' }}>
        <StyledTabs
          value={activeTab}
          onChange={changeActiveTab}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary">
          {getStyledTab('overview', "Overview", Home, HomeActive)}
          {featuresGated.dealView && hasCrm(props.auth.data.user) && getStyledTab('deal-insights', 'Deal Insights', Deals, DealsActive)}
          {getStyledTab('competitor-insights', 'Competitor Insights', Star, StarActive)}
          {getStyledTab('team-insights', 'Team Insights', People, PeopleActive)}
          {getStyledTab('trackers', "Topics", Graph, GraphActive)}
          {featuresGated.scorecard && getStyledTab('scorecards', "Scorecards", Scorecard, ScorecardActive)}
        </StyledTabs>
      </AppBar>

      <div className="mt-12 ">
        {activeTab !== "deal-insights" && activeTab != "scorecards" &&
          <div style={{ display: 'flex', margin: '0px 0px 10px 0px' }}>
            <DashboardFilter
              removeFilterValue={handleRemoveFilterValue}
              resetFilter={resetFilter}
              onSubmit={updateDashboardData}
              filterParams={filterParams}
              otherAgent={false}
              type={"MANAGER"}
              defaultFilters={["agentNames", "managers", "duration"]}
            />
          </div>
        }
        <div className='px-8'>
          {activeTab === "deal-insights" &&
            <div style={{ display: 'flex', margin: '0px 0px 10px -8px' }}>
              <div className="Filter__fieldContainer">
                <CategoryHeader label="Reps" />
                <SelectWithSearchNew
                  key="agentNames"
                  fieldName="agentNames"
                  data={userNames}
                  value={dealInsightsFilter.agentNames}
                  label="Select Reps"
                  placeholder="Type rep name"
                  handleChange={userIds => onChange("agentNames", userIds ? userIds.map(u => u.value) : [])}
                  dontShowCloseIcon={true}
                  hasProfilePic
                />
              </div>
              <div className="Filter__fieldContainer">
                <CategoryHeader label="Team" />
                <SelectWithSearchNew
                  key="team"
                  fieldName="managers"
                  data={managers}
                  value={dealInsightsFilter.managers}
                  label="Select Team"
                  placeholder="Type manager name"
                  handleChange={managerIds => onChange("managers", managerIds ? managerIds.map(m => m.value) : [])}
                  dontShowCloseIcon={true}
                  hasProfilePic
                />
              </div>
              <div className="Filter__fieldContainer">
                <CategoryHeader label="Close Date Range" />
                <DateFiltersNew
                  key="Date"
                  showIcon
                  onStartDateChange={value =>
                    onChange("startDate", value)
                  }
                  onStartAndEndChange={values => onMultipleChanges(["startDate", "endDate"], values)}
                  onEndDateChange={value => onChange("endDate", value)}
                  startDate={dealInsightsFilter.startDate}
                  fieldName="date"
                  enableRemove={false}
                  removeFilterValue={() => props.removeFilterValue("date")}
                  endDate={dealInsightsFilter.endDate}
                  disableAllTime={true}
                />
              </div>
              {showCrmUnsyncedPopup &&
                <CRMStatusPopup
                  setShowCrmUnsyncedPopup={setShowCrmUnsyncedPopup}
                />}
            </div>
          }

          {loading && activeTab !== "scorecards" &&
            <Grid container spacing={3}>
              <Grid item xs={12} spacing={8}>
                <Skeleton height={250} width={"100%"} />
              </Grid>
            </Grid>
          }
          {!loading && activeTab === 'deal-insights' && featuresGated.dealView && hasCrm(props.auth.data.user) &&
            <TeamDashboardDealInsights
              statsCorrelation={statsCorrelation}
              categoryCorrelation={categoryCorrelation} />
          }
          {!loading && activeTab === 'competitor-insights' && <TeamDashboardCompetitorInsights mentions={competitorMentions} nCalls={nCalls} />}
          {!loading && activeTab === 'team-insights' && <TeamDashboardTeamInsights teamDashboardData={teamDashboardData} />}
          {!loading && activeTab === 'trackers' && <TeamDashboardTrackers trackersData={trackersData} topicsForDashboards={topicsForDashboards} />}
          {!loading &&
            activeTab === "overview" && <TeamDashboardOverview
              graphData={graphData}
              videoCallStats={videoCallStats}
              dialerCallStats={dialerCallStats}
              videoCallDispositions={videoCallDispositions}
              dialerCallDispositions={dialerCallDispositions} />
          }
          {
            activeTab === "scorecards" &&

            <ScorecardsDashboard setScorecardsDashboardData={props.setScorecardsDashboardData} setScorecardsDashboardFilter={props.setScorecardsDashboardFilter} />

          }
        </div>
      </div>
    </>);
}

export default connect(a => a, { setScorecardsDashboardData, setScorecardsDashboardFilter, setTeamDashboardData, setTeamDashboardDealInsightsData, setManagerDealInsightsFilter, setManagerDashboardFilter, setTeamDashboardTrackersData, setScreenType, getSkills })(TeamDashboard);