const isFalse = (value) => {
    if (value !== undefined && value !== null)
        return value === false
    else
        return false
}

export const isCrmNotSynced = (auth) => {
    const { defaultCrm, customerLevelIntegrationFlows, customerLevelIntegrations } = auth.stringsCustomer;
    switch (defaultCrm) {
        case "FRESHSALES":
            return customerLevelIntegrationFlows?.freshsalesSyncFlow && isFalse(customerLevelIntegrations?.freshsalesSyncFlow);
        case "HUBSPOT":
            return customerLevelIntegrationFlows?.hubspotSyncFlow && isFalse(customerLevelIntegrations?.hubspotSyncFlow);
        case "PIPEDRIVE":
            return customerLevelIntegrationFlows?.pipedriveSyncFlow && isFalse(customerLevelIntegrations?.pipedriveSyncFlow);
        case "SALESFORCE":
            return customerLevelIntegrationFlows?.salesforceSyncFlow && isFalse(customerLevelIntegrations?.salesforceSyncFlow);
        case "LEADSQUARED":
            return customerLevelIntegrationFlows?.leadsquaredSyncFlow && isFalse(customerLevelIntegrations?.leadsquaredSyncFlow);
        case "CLOSE":
            return customerLevelIntegrationFlows?.closeSyncFlow && isFalse(customerLevelIntegrations?.closeSyncFlow);
        default:
            return false
    }
}



