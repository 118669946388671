import React from "react";
import { ReactComponent as CloseIcon } from "../../designSystem/icons/close.svg"

const Header = props => {
  return (
    <div className="Form__header__container">
      <span className="Form__header__text">{`${props.boldTitle} `}</span>
      <span className="Form__header__boldText">{props.title}</span>
      <div className="Form__header__cancelContainer">
        <CloseIcon
          onClick={props.onCancel}
          style={{ fontSize: 20, color: "#000" }}
          color="primary"
        />
      </div>
    </div>
  );
};

export default Header;
