import { CardSkeleton } from 'app/components/common/skeleton';

export default function FolderContainerSkeleton({ count = 3, className, style }) {
    return (
        <div className="flex flex-row flex-wrap">
            {
                Array.from({ length: count }).map((_, index) => (
                    <div className="flex flex-row flex-wrap" key={"Folder_Skeleton__" + index}>
                        <CardSkeleton style={{
                            width: '25rem',
                            ...style,
                        }} className={className} />
                    </div>
                ))
            }
        </div>
    )
}