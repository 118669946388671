import React from "react";
import { CardShort } from "app/components/common";
import { Grid, TextField } from "@material-ui/core";

import { Button } from "app/designSystem"
import { ReactComponent as AddTopic } from "app/styles/assets/images/icons/addTopic.svg";

export default class AddNewCategory extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.state = {
      category: "",
    };
  }

  componentDidMount() {
    const categoryForm = document.getElementById("categoryForm");
    categoryForm.addEventListener("keyup", this.handleEnter);
  }

  componentWillUnmount() {
    const categoryForm = document.getElementById("categoryForm");
    categoryForm.removeEventListener("keyup", this.handleEnter);
  }

  handleClick() {
    this.props.onClick(this.state.category);
    this.setState({
      category: "",
    });
  }

  handleEnter(event) {
    const handleClick = this.handleClick;
    event.stopPropagation();
    event.preventDefault();
    if (event.keyCode === 13) {
      handleClick();
    }
    return true;
  }

  onChange(e) {
    this.setState({
      category: e.target.value,
    });
  }

  render() {
    return (
      <CardShort className="KeywordsTracking__Card">
        <Grid container spacing={0} direction="row" className="KeywordsTracking__container">
          <Grid item>
            <div id="categoryForm">
              <LargeInputArea value={this.state.category} onChange={this.onChange} />
            </div>
          </Grid>
          <Grid item>
            <div className="flex items-center mr-4">
              <Button
                variant="primary"
                className="!py-1"
                startIcon={<AddTopic />}>
                Add Topic
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardShort>
    );
  }
}

const LargeInputArea = props => {
  return (
    <TextField
      id="input"
      label="TOPIC NAME"
      error={props.error}
      value={props.value}
      onChange={props.onChange}
      className="KeywordsTracking__addCategory"
    />
  );
};
