import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Label } from "app/components/form";
import { Avatar, Slider } from "@material-ui/core";
import { refreshUserAction } from "app/actions/auth";
import EditIcon from "@material-ui/icons/EditRounded";
import { sendRequest, updateProfile } from "../../utils/network";
import { useRef } from "react";
import useSnackbar from "../../utils/hooks/useSnackbar";
import AvatarEditor from "react-avatar-editor";
import { useHoverListenerRef } from "../../utils/hooks/useHoverListener";
import { checkIfImage } from "../dashboards/helpers";

const ProfileImageEditor = ({ auth, refreshUserAction }) => {
  const [name, setName] = useState(auth.data.user.displayName);
  const [email, setEmail] = useState(auth.data.user.emailId);
  const [profilePic, setProfilePic] = useState(auth.data.user.imageUrlCached || auth.data.user.imageUrl);
  const [picInEdit, setPicInEdit] = useState(profilePic);
  const [showEditButton, setShowEditButton] = useState(false);
  const [scale, setScale] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isProfileImageEdit, setIsProfileImageEdit] = useState(false);
  const { setSnackbar } = useSnackbar();
  const ref = useRef(null);
  const hoverRef = useRef(null);

  useHoverListenerRef(
    hoverRef,
    () => setShowEditButton(true),
    () => setShowEditButton(false)
  );

  const lastUpdatedTime = auth.lastUpdatedTime;

  function handleProfilePicChange() {
    let inputEl = document.getElementById("changePP");
    if (inputEl.files) {
      if (checkIfImage(inputEl)) {
        setPicInEdit(inputEl.files[0]);
        setIsProfileImageEdit(true);
      } else {
        setSnackbar("Please upload files with .jpg or .png or .jpeg ", "ERROR");
      }
    }
    document.getElementById("changePP").value = null;
  }

  async function handleProfilePicSave() {
    setLoading(true);
    try {
      let image = ref.current.getImageScaledToCanvas().toDataURL();
      const imageData = new FormData();
      imageData.append("image", image);
      imageData.append("email", email);
      const response = await sendRequest("/user/change_profile_image", imageData);
      if (response == null || response.error) setSnackbar("Error while trying to update profile image. Please try again.", "ERROR");
      else {
        setSnackbar("Profile image updated succesfully!", "SUCCESS");
        refreshUserAction();
      }
    } catch (e) {
      console.log("error in changing profile pic: ", e);
      setSnackbar("Error while trying to update profile image. Please try again.", "ERROR");
    }
    setIsProfileImageEdit(false);
    setLoading(false);
  }

  function handleProfilePicCancel() {
    setIsProfileImageEdit(false);
  }

  function handleScaleChange(ev, val) {
    setScale(val);
  }

  return (
    <div className="imageContainer">
      <div style={{ marginBottom: "20px" }}>
        <Label>Profile Picture</Label>
      </div>
      {!isProfileImageEdit && (
        <div>
          <div className="ProfileDropdown__editIcon">
            <label for="changePP" className="edit">
              <EditIcon htmlColor="#fff" />
            </label>
            <input hidden type="file" id="changePP" onChange={handleProfilePicChange} accept="image/*" />
          </div>
          {profilePic && (
            <div style={{ position: "relative" }}>
              <Avatar src={profilePic + "?" + lastUpdatedTime} style={{ height: "120px", width: "120px", marginBottom: "10px" }} />
            </div>
          )}
          {!profilePic && (
            <span className="ProfileDropdown__profileIcon" style={{ height: "120px", width: "120px", marginRight: "0px", borderRadius: "100px", fontSize: "66px" }}>
              {name && name[0] ? name[0] : "P"}
            </span>
          )}
        </div>
      )}
      {isProfileImageEdit && (
        <div className="col-centered">
          <div style={{ marginBottom: "10px" }}>
            <AvatarEditor
              image={picInEdit}
              ref={ref}
              width={100}
              height={100}
              border={10}
              color={[255, 255, 255, 0.6]} // RGBA
              borderRadius={100}
              scale={1 + scale * 0.02}
              rotate={0}
            />
            <Slider value={scale} onChange={handleScaleChange} aria-labelledby="continuous-slider" />
          </div>
          <Button loading={loading} type="medium" theme="dark" onClick={handleProfilePicSave}>
            Apply
          </Button>
          <div className="Settings__profileContainerbutton Settings__profileContainernoBorder Settings__profileContainerCancel" onClick={handleProfilePicCancel}>
            Cancel
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return { ...store };
};

export default connect(mapStateToProps, { refreshUserAction })(ProfileImageEditor);
