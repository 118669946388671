import {
  USER,
  ADMIN_LOGIN
} from "app/actions/actionTypes";
import {
  MANAGER,
  OBSERVER,
  REP,
} from "../constants";
import { fetchDataSafely } from "../utils/dataUtils";

let initialState = {
  data: {
    user: {},
    isAdminLogin: false
  }
}

try {
  const user = localStorage.getItem("user");
  if (user !== null && user !== undefined && user !== "null" && user !== "undefined") {
    let parsedUser = JSON.parse(user)
    initialState = {
      data: {
        user: parsedUser || {},
      }
    };
  }
} catch (error) {
  console.log(error)
}

export default function (state = initialState, payload) {
  switch (payload.type) {
    case USER:
      if (payload.user != null) {
        return {
          ...state,
          data: {
            user: payload.user,
          },
          stringsCustomer: { ...payload.user.stringsCustomer, customerType: "SALES" },
          userId: payload.user.userId,
          settings: fetchDataSafely(payload, "user.stringsCustomer.settings"),
          isStrings: payload.user.emailId.indexOf("strings.ai") !== -1 || payload.user.emailId.indexOf("trywingman.com") !== -1,
          isAdmin: payload.user.roleInfo == null ? false : payload.user.roleInfo.admin,
          isManager: payload.user.roleInfo == null ? false : payload.user.roleInfo.role === MANAGER,
          isObserver: payload.user.roleInfo == null ? false : payload.user.roleInfo.role === OBSERVER,
          isRep: payload.user.roleInfo == null ? true : payload.user.roleInfo.role === REP,
          isRecording: payload.user.roleInfo == null ? true : payload.user.roleInfo.recording,
          isRoleSelectionDone: payload.user.roleInfo !== null && payload.user.roleInfo.role,
          lastUpdatedTime: new Date().getTime()
        };
      } else {
        return {
          ...state,
          data: {
            user: payload.user
          },
          isAdmin: false,
          isManager: false,
          isObserver: false,
        };
      }
    case ADMIN_LOGIN:
      localStorage.setItem("isAdminLogin", payload.isAdminLogin)
      return {
        ...state,
        isAdminLogin: payload.isAdminLogin
      };
    default:
      return state;
  }
}