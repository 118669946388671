import Skeleton from 'react-loading-skeleton';

export default function GameTapeVideoSkeleton() {
    return (
        <div className="flex flex-col">
            <Skeleton style={{
                height: '60vh'
            }} />
            <div className="mt-5 justify-center">
                <Skeleton height={20} width="75%" />
                <Skeleton height={20} width="50%" className="mt-4" />
            </div>
        </div>
    )
}