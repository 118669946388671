import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setSidebarExpansion } from "app/actions/screen";
import { ProfileDropdown } from "app/components/layout";
import SearchBar from "app/components/search/SearchBar";
import Notifications from "app/components/notifications/Notifications";
import { Text2XL } from "app/designSystem";
import HelpCenterDropdown from "./HelpCenterDropdown";
const HeaderSales = props => {

  return (
    <header className="flex justify-between items-center h-15 bg-white flex-shrink-0 border-b border-coolGray-200">

      <Text2XL className="ml-10">{props?.screen?.screenName}</Text2XL>


      <SearchBar />


      <div className="flex-shrink-0 flex items-center h-full">
        <HelpCenterDropdown />

        <Notifications className="ml-4" />

        <ProfileDropdown className="ml-6" />
      </div>


    </header>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

HeaderSales.propTypes = {
  setSidebarExpansion: PropTypes.func,
};

HeaderSales.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setSidebarExpansion,
  },
)(withRouter(HeaderSales));
