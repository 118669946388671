import React, {
	useEffect, useState, useCallback, useContext,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clone } from "lodash";
import { setAddCommentExpanded } from "app/actions/screen";
import OutsideClickHandler from 'react-outside-click-handler';
import { PlayerContext } from "app/containers/Call";
import AddCommentArea from "./AddCommentArea";
import UpdateCommentArea from "./UpdateCommentArea";
import { useSelector } from "react-redux";
import { getAllChannels } from "app/utils/SlackUtils";


const CommentArea = props => {
	const [comment, setComment] = useState(props.type === "ADD" ? "" : props.comment);
	const [taggedUsers, setTaggedUsers] = useState(props.type === "ADD" ? [] : props.taggedUsers || []);
	const [justTagged, setJustTagged] = useState(false);
	const [commentLinked, setCommentLinked] = useState(true);
	const [cursorPosition, setCursorPosition] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showTickOnCommentButton, setShowTickOnCommentButton] = useState(false);
	const initialAllOptions = [{ display: "All", id: "All" }];
	const [allOptions, setAllOptions] = useState(initialAllOptions);
	const [selectedSlackChannels, setSelectedSlackChannels] = useState(props.type === "ADD" ? [] : props.selectedSlackChannels || []);
	const [slackChannels, setSlackChannels] = useState([]);


	const playerProps = useContext(PlayerContext);
	const { managers, userNames } = useSelector(store => store.screen.metaData);

	useEffect(() => {
		let allOptionsLocal = initialAllOptions;
		if (userNames) allOptionsLocal = allOptionsLocal.concat(userNames.map(user => ({ id: user.label, display: user.label })));
		if (managers) allOptionsLocal = allOptionsLocal.concat(managers.map(manager => ({ id: manager.label, display: manager.label })));
		allOptionsLocal = allOptionsLocal.map((item, i) => { item.id = item.id + i; return item; })
		setAllOptions(allOptionsLocal);
	}, [userNames, managers]);

	useEffect(() => {
		getAllSlackChannels();
	}, [])

	const getAllSlackChannels = async () => {
		const channels = await getAllChannels();
		if (!channels || channels.error) return;
		const channelMap = channels.map(channel => ({ id: channel.value, display: channel.label.substring(1) }));
		setSlackChannels(channelMap);

	}

	function handleClickOutside() {
		if (comment === "") props.setAddCommentExpanded({ show: false });
	}


	async function handleAddComment(e) {
		if (comment === "" || loading) return { error: true };
		e.stopPropagation();
		setLoading(true);
		let interactionId = null;
		let interactionName = null;
		let interactionType = null;
		if (commentLinked) {
			interactionId = props.interactionId;
			interactionName = props.interactionName;
			interactionType = props.interactionType;
		}

		let commentTime = props.screen.commentTime || 0;
		if (!commentLinked) {
			commentTime = 0;
		}
		var localComment = comment.trim();
		if (localComment.endsWith("\n")) {
			localComment = localComment.substring(0, localComment.length - 1).trim();
		}
		console.log("in comment area, add comment", localComment, taggedUsers, selectedSlackChannels, commentTime, props.isPrivate)
		const res = await props.addComment(localComment, taggedUsers, selectedSlackChannels, commentTime, interactionId, interactionName, interactionType, props.isPrivate);
		setLoading(false);
		setComment("");
		setTaggedUsers([]);
		setSelectedSlackChannels([]);
		return res;
	}

	async function updateComment(e) {
		console.log("will I update a comment?", comment === "");
		if (comment === "" || loading) return { error: true };

		var updatedComment = comment.trim();

		if (updatedComment.endsWith("\n"))
			updatedComment = updatedComment.slice(0, -1);
		console.log("updating comment");
		e.stopPropagation();
		setLoading(true);
		const res = await props.updateComment(props.id, updatedComment, taggedUsers);
		setLoading(false);
		setComment("");
		setTaggedUsers([]);
		setSelectedSlackChannels([]);
		return res;
	}

	async function handleKeyDown(e) {
		if (e.keyCode === 13 && !e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();

			if (!justTagged) {
				let res;
				if (props.type === "ADD")
					res = await handleAddComment(e);
				else
					res = await updateComment(e);
				if (!(res && res.error)) setShowTickOnCommentButton(true);
				setTimeout(() => setShowTickOnCommentButton(false), 2000);
			}
		}
		setJustTagged(false);
	}

	function handleSetComment(e) {
		setComment(e.target.value);
		setJustTagged(false);
	}

	function addMention(id, display, type = "USER") {
		const updatedTaggedUsers = clone(taggedUsers);
		updatedTaggedUsers.push({ email: display, name: display, type });
		setTaggedUsers(updatedTaggedUsers);
		setJustTagged(true);
	}

	function addChannel(id, display) {
		const updatedSelectedChannel = clone(selectedSlackChannels);
		updatedSelectedChannel.push({ id: id, name: display });
		setSelectedSlackChannels(updatedSelectedChannel);
	}

	function toggleCommentLinked() {
		setCommentLinked(commentLinked => !commentLinked);
	}

	const { addCommentExpanded, commentTime } = props.screen;

	if (props.type === "ADD") {
		if (addCommentExpanded || comment !== "") {
			return (
				<OutsideClickHandler onOutsideClick={handleClickOutside}>
					<div id="CommentArea">
						<AddCommentArea
							height="180px"
							comment={comment}
							setComment={handleSetComment}
							onKeyDown={handleKeyDown}
							addMention={addMention}
							addChannel={addChannel}
							slackChannels={slackChannels}
							duration={commentTime}
							isPrivate={props.isPrivate}
							togglePrivacy={props.togglePrivacy}
							handleAddComment={handleAddComment}
							loading={loading}
							users={allOptions}
							commentLinked={commentLinked}
							toggleCommentLinked={toggleCommentLinked}
							showTickOnCommentButton={showTickOnCommentButton}
							{...props}
						/>
					</div>
				</OutsideClickHandler>
			);
		}
		return (
			<div onClick={() => props.setAddCommentExpanded({ show: true, time: playerProps.seek })} className="AddCommentPlaceholder">
				<span className="text">Write a comment</span>
			</div>
		);
	} else {
		return (
			<div id="CommentArea">
				<UpdateCommentArea
					{...props}
					comment={comment}
					setComment={handleSetComment}
					onKeyDown={handleKeyDown}
					addMention={addMention}
					updateComment={updateComment}
					loading={loading}
					users={allOptions}
					showTickOnCommentButton={showTickOnCommentButton}
				/>
			</div>
		);

	}
};


CommentArea.propTypes = {
	height: PropTypes.string,
	fullHeight: PropTypes.string,
	playTime: PropTypes.number,
};

CommentArea.defaultProps = {};

const mapStateToProps = store => {
	const { playback } = store.player;
	return { playback, ...store };
};

export default connect(
	mapStateToProps,
	{ setAddCommentExpanded },
)(CommentArea);

