import React from "react";
import { connect } from "react-redux";
import { refreshUserAction } from "app/actions/auth";
import NotificationType from "./NotificationType";
import { useSelector } from "react-redux";
import { fetchDataSafely } from "../../utils/dataUtils";
import CallAlertSlackNotification from "./slack/CallAlertSlackNotification";

const userNotificationPrefs = [
  {
    type: "Onboarding Emails",
    id: "onboardingMessages",
    copy: "Onboarding emails are sent out to help you understand and use wingman efficiently.",
  },
  {
    type: "Product Update Emails",
    id: "productUpdateMessages",
    copy: "Emails announcing new product features, how tos, case studies etc.",
  },
];

const userSlackNotificationPrefs = [
  {
    type: "Post Call Slack Alerts",
    id: "postCallSlackAlertsEnabled",
    copy: "Slack messages sent out to user with their post call workflows after they complete a call.",
    options: [
      {
        label: "after call",
        value: "AFTER_CALL"
      },
      {
        label: "at the end of the day",
        value: "DAY_END"
      }
    ]
  },
  {
    type: "Pre Call Slack Alerts",
    id: "preCallSlackAlertsEnabled",
    copy: "Slack messages sent out to user before the call.",
    options: [
      {
        label: "before the call",
        value: "BEFORE_CALL"
      },
      {
        label: "at the start of the day",
        value: "DAY_START"
      },
    ],
  },
];

const adminSlackNotificationPrefs = [
  {
    type: "Comment Messages",
    id: "commentMessages",
    copy: "Slack messages sent out to user when a comment is added to a call they were a part of",
  },
  {
    type: "Call Scored",
    id: "callScoredMessage",
    copy: "Reps are notified over slack when they are scored",
    feature: "scorecard"
  }
];

const adminNotificationPrefs = [
  {
    type: "Comment Emails",
    id: "commentMessages",
    copy: "Emails sent out to user when a comment is added to a call they were a part of",
  },
  {
    type: "User Onboarding Reminders",
    id: "userOnboardingReminders",
    copy: "Reminder emails sent to recording users to complete their integrations",
  },
  {
    type: "Manager Onboarding Status Emails",
    id: "managerOnboardingReminders",
    copy: "Emails sent to managers briefing them on the onboarding status of their reps",
  },
  {
    type: "Call Scored",
    id: "callScoredMessage",
    copy: 'Reps are notified over email when they are scored',
    feature: "scorecard"
  }
];

const NotificationSettings = ({ auth, refreshUserAction, admin, user }) => {
  const userDoc = useSelector((store) => store.auth.data.user);
  const featuresGated = useSelector((store) => store.auth.stringsCustomer.featuresGated);
  const customerWeeklyRepReport = fetchDataSafely(userDoc, "stringsCustomer.emailNotificationPreferences.weeklyRepReport", false);
  return (
    <div className="Settings__profileContainer">
      <span className="Settings__header">Notification Preferences</span>
      <div className="Settings__row">
        {user && (
          <div className="Settings__notifications">
            <span className="Settings__notifications-typeHeader">Email Notifications</span>
            {userNotificationPrefs.map((pref, i) => (
              <NotificationType key={i} id={pref.id} type={pref.type} copy={pref.copy} />
            ))}
            {(featuresGated["postCallSlackAlertsEnabled"] || featuresGated["preCallSlackAlertsEnabled"]) &&
              <div>
                <span className="Settings__notifications-typeHeader">Slack Notifications</span>
                {
                  userSlackNotificationPrefs.map((pref, i) => (
                    featuresGated[pref.id] && <CallAlertSlackNotification key={i} pref={pref} />
                  ))
                }
              </div>
            }

            {customerWeeklyRepReport && (
              <NotificationType key={userNotificationPrefs.length} id="weeklyRepReport" type="Wingman Weekly" copy="A weekly email recapping the last week of calls and meetings" />
            )}
          </div>
        )}
        {admin && (auth.isAdmin || auth.isManager) && (
          <div className="Settings__notifications">
            <span className="Settings__notifications-typeHeader">Email Notifications</span>
            {adminNotificationPrefs.map((pref, i) => (
              <NotificationType admin key={i} id={pref.id} type={pref.type} copy={pref.copy} disabled={auth.isAdmin ? false : true} feature={pref.feature} />
            ))}
          </div>
        )}
        {admin && (auth.isAdmin || auth.isManager) && (
          <div className="Settings__notifications">
            <span className="Settings__notifications-typeHeader">Slack Notifications</span>
            {adminSlackNotificationPrefs.map((pref, i) => (
              <NotificationType admin slack id={pref.id} key={i} type={pref.type} copy={pref.copy} disabled={auth.isAdmin ? false : true} feature={pref.feature} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store) => {
  return { ...store };
};

export default connect(mapStateToProps, { refreshUserAction })(NotificationSettings);
