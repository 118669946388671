import { CommentsContainer } from "app/components/call";
import React, { useState, useEffect } from "react";
import HighlightsSection, { Highlights } from "./HighlightsSectionNew";
import Search from "app/styles/assets/images/icons/callReview/Search.svg";
import SearchActive from "app/styles/assets/images/icons/callReview/SearchActiveNew.svg";
import CommentsActive from "app/styles/assets/images/icons/callReview/CommentsActiveNew.svg";
import Comments from "app/styles/assets/images/icons/callReview/Comments.svg";
import HighlightsActive from "app/styles/assets/images/icons/callReview/HighlightsActiveNew.svg";
import HighlightsIcon from "app/styles/assets/images/icons/callReview/Highlights.svg";
import { fetchDataSafely } from "../../utils/dataUtils";
import { useDispatch, useSelector } from "react-redux";
import SearchWithinCall from "./SearchWithinCall";
import { setSelectedTab } from "../../actions/call";
import { SET_MATCHING_HIGHLIGHTS } from "../../actions/actionTypes";
import { getCommentsAccessibleByUser } from "../../utils/helpers";
import { TextLarge, TextSM } from "app/designSystem"
import { CloseIcon } from "app/designSystem/icons"
import ErrorInProcessing from "app/styles/assets/images/CallProcessingError.svg"

const tabs = ["search", "comments", "highlights"];

const NotesAndComments = ({
  call,
  isCommentAreaExpanded,
  duration,
  handlePlayerClick,
  activeHighlightTab,
  setActiveHighlightTab,
  highlightsData,
  bookmarksData,
  isUsersCall,
  expandCommentArea,
  handleHighlightsClick,
  isCallEmbed }) => {

  const customerType = useSelector(store => fetchDataSafely(store.auth, "stringsCustomer.customerType", "GUEST"));
  const userPreferences = useSelector(store => store.persisted.userPreferences);
  const videoAvailable = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.version", null) >= 3;
  const videoProcessed = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.callStatus", false) === "POST_PROCESSING_DONE";
  const videoMinimized = useSelector(store => store.screen.videoMinimized);
  const comments = useSelector(store => store.call.details.callDataDocument.comments);
  const userId = useSelector((store) => store.auth.userId);
  const [totalComments, setTotalComments] = useState(0);
  const callStatus = useSelector(store => store.call?.details?.callDataDocument?.callStatus);
  const [isCallProcessed, setIsCallProcessed] = useState(false);



  const summaryData = fetchDataSafely(call, "details.summary", null);
  let speakers = ["Speaker 1", "Speaker 2"];
  const turns = useSelector(store => store.call.details.callData.conversationTurn);
  const channel = useSelector(store => store.call.details.callData.channel);
  const dispatch = useDispatch();
  if (summaryData && summaryData.speakerNames) {
    speakers = summaryData.speakerNames;
  }

  const [matchingHighlights, setMatchingHighlights] = useState({ highlightGroups: [] });
  const [activeTab, setActiveTab] = useState(getActiveTab("comments"));

  useEffect(() => {
    handleSetActiveTab(getActiveTab(activeHighlightTab));
  }, [activeHighlightTab]);

  useEffect(() => {
    countComments(comments);
  }, [comments])

  useEffect(() => {
    dispatch({ type: SET_MATCHING_HIGHLIGHTS, matchingHighlights });
  }, [matchingHighlights]);

  useEffect(() => {
    if (callStatus !== 'POST_PROCESSING_DONE' && callStatus !== 'PROCESSED') setIsCallProcessed(false);
    else setIsCallProcessed(true);
  }, [callStatus]);

  function removeSelectedHighlight(highlightName) {
    const newHighlightsData = matchingHighlights.highlightGroups.filter(highlightGroup => highlightGroup.groupName !== highlightName);
    setMatchingHighlights({ ...matchingHighlights, highlightGroups: newHighlightsData });
  }

  function getActiveTab(tab) {
    if (customerType === "GUEST") return tabs.indexOf("highlights")
    return tabs.indexOf(tab) == -1 ? 0 : tabs.indexOf(tab);
  }

  function handleSetActiveTab(tab) {
    setActiveTab(tab);
    setActiveHighlightTab(tabs[tab]);
    dispatch(setSelectedTab(tabs[tab]));
  }




  async function countComments(comments) {
    if (!comments) return;
    let totalCommentsLocal = getCommentsAccessibleByUser(comments, userId);
    let totalCommentsCount = 0;
    if (totalCommentsLocal) {
      totalCommentsLocal.map(comment => {
        let replies = 0;
        if (comment.replies) {
          replies = (comment.replies).length;
        }
        totalCommentsCount = totalCommentsCount + replies + 1;
      })
    }
    setTotalComments(totalCommentsCount);
  }

  return (
    <div className="Call__NotesAndComments" style={{ height: (fetchDataSafely(userPreferences, "showVideo", false) || !videoAvailable || !videoProcessed || isCallEmbed) ? "" : (videoMinimized ? "calc(100vh - 120px)" : "calc(80vh - 80px)") }}>
      <div className="RightPanel">
        {activeTab === 0 && <ActiveTab icon={SearchActive} label="Search" />}
        {activeTab !== 0 && <InactiveTab icon={Search} label="Search" tabIndex={0} handleSetActiveTab={handleSetActiveTab} />}

        {activeTab === 1 && <ActiveTab icon={CommentsActive} label="Comments" comments={totalComments} />}
        {activeTab !== 1 && <InactiveTab icon={Comments} label="Comments" comments={totalComments} tabIndex={1} handleSetActiveTab={handleSetActiveTab} />}

        {activeTab === 2 && <ActiveTab icon={HighlightsActive} label="Highlights" />}
        {activeTab !== 2 && <InactiveTab icon={HighlightsIcon} label="Highlights" tabIndex={2} handleSetActiveTab={handleSetActiveTab} />}


      </div>
      {activeTab === 0 && isCallProcessed && <SearchWithinCall label="Search" matchingHighlights={matchingHighlights} setMatchingHighlights={setMatchingHighlights} />}
      {isCallProcessed &&
        <>
          <div style={activeTab == 0 ? { display: "flex", height: "100%" } : { display: "none" }} >
            {customerType !== "GUEST" &&
              <div className="Call__HighlightContainer">
                <div className="flex flex-wrap">
                  {matchingHighlights?.type === "Highlights" && matchingHighlights.highlightGroups.map((matchingHighlight, index) => {
                    return (
                      <div className="flex items-center my-2" onClick={() => removeSelectedHighlight(matchingHighlight.groupName)} key={index}>
                        <TextSM className="p-2 bg-blueGray-100 text-gray-900 rounded flex items-center">
                          {matchingHighlight.groupName}
                          <CloseIcon className="ml-0.5 h-4" />
                        </TextSM>
                        {(index !== matchingHighlights.highlightGroups.length - 1) && <TextSM className="m-2">or</TextSM>}
                      </div>
                    )
                  })}
                </div>
                <div className="RightPanel__searchResultsContainer">
                  <Highlights
                    highlightsData={matchingHighlights}
                    keyword={null}
                    excludeKeywords={[]}
                    handleHighlightsClick={handleHighlightsClick}
                    speakers={speakers}
                    channel={channel}
                    showCategory={true}
                    turns={turns}
                    type="SEARCH"
                  />
                </div>
              </div>
            }
          </div>
          <div style={activeTab == 2 ? { display: "flex", height: "100%" } : { display: "none" }} >
            <HighlightsSection
              highlightsData={highlightsData}
              customerType={customerType}
              handleHighlightsClick={handleHighlightsClick}
              bookmarksData={bookmarksData}
              isUsersCall={isUsersCall}
            />
          </div>
        </>
      }
      {activeTab === 1 &&
        <CommentsContainer
          hidden={activeTab !== 1}
          call={call.details.callDataDocument}
          expandCommentArea={() => expandCommentArea(true)}
          collapseCommentArea={() => expandCommentArea(false)}
          isAddCommentAreaExpanded={isCommentAreaExpanded}
          handlePlayerClick={handlePlayerClick}
          duration={duration}
        />
      }
      {!isCallProcessed && activeTab !== 1 &&
        <div style={{ display: "flex", height: "100%", flexDirection: 'column', padding: '5% 10%', justifyContent: 'center', alignItems: 'center' }}>
          <img src={ErrorInProcessing} />
          <TextLarge className="mt-5 text-center">
            Call is being processed!
          </TextLarge>
          <TextSM className="text-center mt-2 px-10">
            Our AI models are currently processing this call. Highlight and Search will be available in a few minutes.
          </TextSM>
        </div>
      }
    </div>
  );
};

const ActiveTab = ({ icon, label, comments = null }) => (
  <div className="RightPanel__allTabs RightPanel__activeTab">
    <img
      className={`RightPanel__allTabsIcon`}
      src={icon}
    />
    <span className="RightPanel__allTabsText RightPanel__activeTabText">{label}</span>
    {label === "Comments" && (
      <span className="RightPanel__activeTabNumber">{comments}</span>
    )}
  </div>
)

const InactiveTab = ({ icon, label, tabIndex, handleSetActiveTab, comments = null }) => (
  <div className="RightPanel__allTabs RightPanel__inactiveTab" id={label + "Button_CallReview"} onClick={() => handleSetActiveTab(tabIndex)}>
    <img
      className={`RightPanel__allTabsIcon`}
      src={icon}
    />
    <span className="RightPanel__allTabsText RightPanel__inactiveTabText">{label}</span>
    {label === "Comments" && (
      <span className="RightPanel__inactiveTabNumber">{comments}</span>
    )}
  </div>
);
export default NotesAndComments;
