import { getDocFields, getDefaultCRM } from "./network";

export async function fetchCustomDealFields(){
    const response = await getDocFields('OPPORTUNITY');
    const otherOptions = Object.keys(response)
        .map(fieldId => {
            let crmField = response[fieldId];
            if (crmField.id === "hs_manual_forecast_category") {
                return { value: "forecastCategory", fieldType: crmField.fieldType, label: crmField.displayName, required: crmField.required };
            }
            return { value: "custom_" + crmField.id, fieldType: crmField.fieldType, label: crmField.displayName, required: crmField.required };
        })
        .filter(field => field.required);
    const forecastCategoryOption = await getForecastCategoryIfSalesforce();
    var fieldNameOptionsLocal = otherOptions;
    if (forecastCategoryOption)
        fieldNameOptionsLocal.push(forecastCategoryOption);
    return fieldNameOptionsLocal;    
}

export async function getForecastCategoryIfSalesforce() {
    if (getDefaultCRM() === "SALESFORCE") {
        var forecastCategoryOption = { "value": "forecastCategory", crmFieldId: ["forecastCategory", "forecastcategory"], type: "STRING", "label": "Forecast Category" };
        return forecastCategoryOption;
    }
    return null;
}
