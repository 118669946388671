import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import KeyboardIcon from "app/styles/assets/images/icons/player/keyboard.svg";
import { TextBase } from "app/designSystem"
import ReactTooltip from "react-tooltip";
import { ShiftIcon, InfoIcon, CloseIcon } from "app/designSystem/icons";

const KeyboardShortcutsWithTrigger = ({ }) => {
    const [showShortcuts, setShowShortcuts] = useState(false);

    function toggleShowShortcuts() {
        setShowShortcuts(showShortcuts => !showShortcuts);
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setShowShortcuts(false)}>
            <div className="KeyboardShortcuts__trigger">
                <img onClick={toggleShowShortcuts} src={KeyboardIcon} className="h-6" />
                {showShortcuts && <KeyboardShortcuts setShowShortcuts={setShowShortcuts} />}
            </div>
        </OutsideClickHandler>
    )
}

const KeyboardShortcut = ({ text, icon }) => {
    return (
        <div className="flex items-center justify-center bg-gray-100 rounded p-[0.375rem] min-w-[2rem]">
            {
                icon ? React.cloneElement(icon) : <TextBase textColor="text-gray-700">{text}</TextBase>
            }
        </div>
    )
}

const KeyboardShortcuts = ({ setShowShortcuts }) => {
    const os = window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";

    return (
        <div className="KeyboardShortcuts__container">
            <div className="KeyboardShortcuts__header mb-1">
                <TextBase textColor="text-gray-400">Keyboard Shortcuts</TextBase>
                {/* <img onClick={() => setShowShortcuts(false)} src={CloseIcon} className="KeyboardShortcuts__headerClose" /> */}
                <CloseIcon onClick={() => setShowShortcuts(false)} className="KeyboardShortcuts__headerClose text-gray-400 h-5" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Play/Pause</span>
                <KeyboardShortcut text="space" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Increase speed</span>
                <KeyboardShortcut text="↑" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Decrease speed</span>
                <KeyboardShortcut text="↓" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Skip forward 15 secs</span>
                <KeyboardShortcut text="→" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Skip back 15 secs</span>
                <KeyboardShortcut text="←" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Search</span>
                <KeyboardShortcut text="/" />
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Skip to previous speaker</span>
                <div className="flex items-center">
                    <KeyboardShortcut text={os === 'Windows' ? 'Ctrl' : 'Cmd'} />
                    <span textColor="text-gray-500" className="mx-1">+</span>
                    <KeyboardShortcut text="J" />
                </div>
            </div>
            <div className="KeyboardShortcuts__row">
                <span className="KeyboardShortcuts__commandText">Skip to next speaker</span>
                <KeyboardShortcut text={os === 'Windows' ? 'Ctrl' : 'Cmd'} />
                <span textColor="text-gray-500" className="mx-1">+</span>
                <KeyboardShortcut text="K" />
            </div>
            <ReactTooltip id={"magicCopyTooltip"} effect="solid" className="InfoTooltip">
                <span>Copy formatted transcript to the clipboard</span>
            </ReactTooltip>
        </div>
    );
}

export default KeyboardShortcutsWithTrigger;