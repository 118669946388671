import React, { useState, useEffect } from 'react';
import { TextBase } from 'app/designSystem'
import PropTypes from 'prop-types';

export default function TagsChipContainer({ tags, numberOfTagsToShow, textComponent }) {

    const [tagsToShow, setTagsToShow] = useState(tags);
    const [extraTagsCount, setExtraTagsCount] = useState(0);

    useEffect(() => {
        if (numberOfTagsToShow !== -1) {
            setTagsToShow(tags.slice(0, numberOfTagsToShow));
            setExtraTagsCount(tags.length - numberOfTagsToShow);
        }
    }, [numberOfTagsToShow])

    return (
        <div className="flex flex-wrap items-center overflow-hidden h-full">
            {
                tagsToShow.length ?
                    <>
                        {tagsToShow.map((tag, index) => (
                            <div key={tag + index} className="bg-purple-50 rounded py-1 px-2 mr-2 h-full">
                                {
                                    React.cloneElement(textComponent, { className: 'text-purple-600' }, tag)
                                }
                            </div>
                        ))}
                        {extraTagsCount > 0 && <div className="bg-purple-50 rounded py-1 px-2 mr-2 h-full">
                            {
                                React.cloneElement(textComponent, { className: 'text-purple-600' }, `+${extraTagsCount}`)
                            }
                        </div>}
                    </> : null
            }
        </div>
    )
}

TagsChipContainer.propTypes = {
    tags: PropTypes.array.isRequired,
    numberOfTagsToShow: PropTypes.number,
    textComponent: PropTypes.element
}

TagsChipContainer.defaultProps = {
    tags: [],
    numberOfTagsToShow: -1, // -1 === INFINITY
    textComponent: <TextBase />
}