import React from "react";
import PropTypes from "prop-types";
import { SPaper } from "app/components/common";

const CardShort = props => {
  return (
    <SPaper className={`CardShort ${props.className}`} elevation={props.elevation}>
      {props.title && (
        <div className="CardShort__head">
          <span className="title">{props.title}</span>
          {props.headActions && <div className="CardShort__head-actions">{props.headActions}</div>}
        </div>
      )}
      <div className="CardShort__body">{props.children}</div>
    </SPaper>
  );
};

CardShort.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  elevation: PropTypes.number,
  headActions: PropTypes.node,
  title: PropTypes.string,
};

CardShort.defaultProps = {
  children: null,
  className: "",
  headActions: null,
  elevation: 2,
  title: null,
};

export default CardShort;
