import React from "react";
import {
    getContactsForFilters,
    getDealStageForFilters,
    getContactsById
} from "app/utils/network";
import OutsideClickHandler from 'react-outside-click-handler';
import {
    CRMFieldFilters,
    CategoryHeader
} from "app/components/filter";
import DurationFiltersNew from "app/components/filter/DurationFiltersNew";
import { emptyFilterParams, allFields } from "app/utils/filter";
import { connect, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { clone } from "lodash";
import MoreFieldsPopup from "./MoreFieldsPopup";
import TextFilter from "./TextFilter";
import SelectWithSearchNew from "./SelectWithSearchNew";
import AsyncSelectWithSearchNew from "./AsyncSelectWithSearchNew.jsx";
import { CustomDropdown } from "../common";
import metrics from "../../utils/metrics";
import ScoringSkillFilter from "./ScoringSkillFilter";
import RangeFilterTemplate from "./RangeFilerTemplate";
import useScorecardsPermissionHelper from "app/utils/hooks/useScorecardsPermissionHelper";
import { cloneDeep } from "lodash";
const CallDispositions = [{
    value: "ALL_CALLS",
    label: "All Calls"
}, {
    value: "CONNECTED_CALLS",
    label: "Connected With Prospect"
}]

const FilterCard = props => {
    const initialFieldsSelected = [
        ...props.defaultFilters
    ].flat();
    const [filterParams, setFilterParams] = useState(JSON.parse(JSON.stringify(props.filterParams ?? {})));
    const [contacts, setContacts] = useState([]);
    const [dealStageLive, setDealStageLive] = useState([]);
    const [dealStageFrozen, setDealStageFrozen] = useState([]);
    const [contactsObj, setContactsObj] = useState({});
    const [showMoreFields, setShowMoreFields] = useState(false);
    const [fieldsSelected, setFieldsSelected] = useState(initialFieldsSelected);
    const [fieldsActive, setFieldsActive] = useState(getFieldsActive(props.filterParams));
    const [groupedUserNames, setGroupedUserNames] = useState(null);
    const [allUsersNames, setAllUsersNames] = useState(null);
    const { showOnlyRepScoresInDashboard } = useScorecardsPermissionHelper()
    const [prefillFilter, setPrefillFilter] = useState({});
    const [showOnlyRepScores, setShowOnlyRepScores] = useState(false);
    const userId = useSelector(store => store.auth.userId);
    const [updatedPrefillFilters, setUpdatedPrefillFilters] = useState(false);
    const allTagValuesByCustomer = useSelector(store => store.screen.metaData.callTagsByCustomer);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setFieldsSelected(initialFieldsSelected);
    }, [props.name])

    useEffect(() => {
        if (props.prefillFilter) {
            setPrefillFilter(props.prefillFilter)
        }
    }, [props.prefillFilter])

    useEffect(() => {
        if (props.userNames && props.deletedUserNames && props.userNames?.length > 0 && props.deletedUserNames?.length > 0) {
            console.log("userNames ", props.userNames)
            console.log("deletedUserNames ", props.deletedUserNames)
            let tempGroupedUserNames = [
                {
                    label: "Active Users",
                    options: props.userNames
                },
                {
                    label: "Deleted Users",
                    options: props.deletedUserNames
                }
            ]

            setGroupedUserNames(tempGroupedUserNames);
            setAllUsersNames([
                ...props.userNames,
                ...props.deletedUserNames
            ])
        }
    }, [props.userNames, props.deletedUserNames])

    useEffect(() => {
        if (props.prefillFilter && !updatedPrefillFilters) {
            setUpdatedPrefillFilters(true);
            const prefillFilter = props.prefillFilter;
            const filters = cloneDeep(filterParams)
            const updatedFilters = { ...filters, ...prefillFilter }
            props.onApplyFilter(updatedFilters, contactsObj);
            setFilterParams(updatedFilters)
        }
        else {
            setFilterParams(JSON.parse(JSON.stringify(props.filterParams)));
            setFieldsActive(getFieldsActive(props.filterParams));
        }
    }, [props.filterParams, fieldsSelected, props.prefillFilter]);

    function getFieldsActive(filterParamsLocal = filterParams) {
        const fieldsActiveLocal = clone(initialFieldsSelected);
        allFields.map(field => {
            if (isFieldActive(field, filterParamsLocal) && !fieldsActiveLocal.includes(field)) {
                fieldsActiveLocal.push(field);
            }
        });
        return fieldsActiveLocal;
        //setFieldsActive(fieldsActiveLocal);
    }

    function onRemoveField(field) {
        const fieldsSelectedUpdated = clone(fieldsSelected);
        const index = fieldsSelectedUpdated.indexOf(field);
        if (index > -1) {
            fieldsSelectedUpdated.splice(index, 1);
        }
        console.log("on remove field", index, fieldsSelectedUpdated);
        setFieldsSelected(fieldsSelectedUpdated);
        setShowMoreFields(false);
        props.removeFilterValue(field);
    }

    async function fetchData() {
        const contactsLocal = await getContactsForFilters("null", 10);
        const dealStageLiveLocal = await getDealStageForFilters("live");
        const dealStageFrozenLocal = await getDealStageForFilters("callTime");
        setContacts(contactsLocal);
        setDealStageFrozen(dealStageFrozenLocal);
        setDealStageLive(dealStageLiveLocal);
    }

    //select change for multi select, object is an array here
    function onSelectChange(fieldName, object) {
        const filterParamsUpdated = clone(filterParams);
        const values = object ? object.map(a => a.value) : [];
        filterParamsUpdated[fieldName] = values;
        setFilterParams(filterParamsUpdated);
        if (fieldName === "contacts") {
            setContactsObj(object);
        }
        onSubmit(filterParamsUpdated);
        metrics.logEventHeap(props.type === "CALLLOG" ? "CallLogFilter" : "DashboardFilter", { field: fieldName, value: values?.[0] });
    }

    //select change for single select, we get an object as parameter
    function onSingleSelectChange(fieldName, option) {
        const filterParamsUpdated = clone(filterParams);
        filterParamsUpdated[fieldName] = option?.value;
        setFilterParams(filterParamsUpdated);
        onSubmit(filterParamsUpdated);
        metrics.logEventHeap(props.type === "CALLLOG" ? "CallLogFilter" : "DashboardFilter", { field: fieldName, value: option });
    }

    function onChange(fieldName, value) {
        const filterParamsUpdated = clone(filterParams);
        filterParamsUpdated[fieldName] = value;
        setFilterParams(filterParamsUpdated);
        onSubmit(filterParamsUpdated);
        metrics.logEventHeap(props.type === "CALLLOG" ? "CallLogFilter" : "DashboardFilter", { field: fieldName, value: value });
    }

    function onMultipleChanges(fieldNames, values) {
        const filterParamsUpdated = clone(filterParams);
        fieldNames.map((fieldName, i) => {
            filterParamsUpdated[fieldName] = values[i];
        })
        setFilterParams(filterParamsUpdated);
        onSubmit(filterParamsUpdated);
        metrics.logEventHeap(props.type === "CALLLOG" ? "CallLogFilter" : "DashboardFilter", { field: fieldNames?.[0], value: values?.[0] });
    }

    function resetFilter() {
        setFilterParams(emptyFilterParams);
        setContactsObj({});
        setFieldsSelected(initialFieldsSelected);
        onSubmit(emptyFilterParams);
        props.resetFilter();
    }

    function onSubmit(filterParamsLocal = filterParams) {
        props.onApplyFilter(filterParamsLocal, contactsObj);
    }

    async function fetchContacts(key, callback) {
        const contactsLocal = await getContactsForFilters(key, 20);
        setContacts(contactsLocal);
        callback(contactsLocal);
    }

    function editCrmField(index, field, value) {
        console.log("in edit crm field", index, field, value);
        const filterParamsUpdated = clone(filterParams);
        filterParamsUpdated.crmFields[index] = { field, value };
        setFilterParams(filterParamsUpdated);
        onSubmit(filterParamsUpdated);
    }


    function onFieldSelect(field) {
        const fieldsSelectedUpdated = clone(fieldsSelected);
        if (!fieldsSelectedUpdated.includes(field)) {
            fieldsSelectedUpdated.push(field);
            setFieldsSelected(fieldsSelectedUpdated);
        }
        console.log("onfieldSelect update", fieldsSelectedUpdated);
        setShowMoreFields(false);
        setTimeout(() => openFieldMenu(field), 100);
    }

    function openFieldMenu(field) {
        try {
            if (document.getElementById(field)) {
                document.getElementById(field).click();
            }
        } catch (error) {
            console.log("error opening field menu:", field, error);
        }
    }

    function isFieldActive(field, filterParamsLocal = filterParams) {
        if (fieldsSelected.includes(field)) return true;
        let isActive = false;
        switch (field) {
            case "score": isActive = filterParamsLocal.score ? filterParamsLocal.score.type != null : false; break;
            case "contacts":
            case "dispositions":
            case "containsCategories":
            case "competitorMentioned":
            case "peopleTagged":
            case "commentator":
            case "notContainCategories":
            case "dealStageLive":
            case "dealStageFrozen":
            case "types":
            case "status":
            case "scoringTemplate":
            case "scoredRep":
            case "scoredBy":
            case "callTags":
            case "agentNames": isActive = filterParamsLocal[field] && filterParamsLocal[field].length > 0; break;
            case "query":
            case "meetingTitle":
            case "commentSearchTerm": isActive = (filterParamsLocal[field] && filterParamsLocal[field] !== ""); break;
            case "startTalkListenRatio":
            case "endTalkListenRatio":
            case "talkListenRatio": isActive = filterParamsLocal.endTalkListenRatio < 1 || filterParamsLocal.startTalkListenRatio > 0;
                break;
        }

        return isActive;
    }

    function toggleShowMoreFields() {
        setShowMoreFields(showMoreFields => !showMoreFields);
    }


    return (
        <div className="flex items-start bg-white border border-coolGray-200 pb-4 pt-2.5 mt-3 mb-4 px-5 rounded-lg" ref={node => (node = node)}>
            <div className="Filter__fieldsContainer">
                {[...new Set([...fieldsActive, ...fieldsSelected, ...(props.defaultFilters)].flat())].map((field, i) => {
                    return (
                        <div key={i}>

                            {field === "scoredRep" && (fieldsActive.includes("scoredRep") || fieldsSelected.includes("scoredRep")) &&
                                <div className="Filter__fieldContainer">
                                    <CategoryHeader label="Scored rep" />
                                    <SelectWithSearchNew
                                        key="scoredRep"
                                        fieldName="scoredRep"
                                        data={allUsersNames ?? props.userNames}
                                        options={allUsersNames ?? props.userNames}
                                        disabled={prefillFilter.scoredRep || props.singleUser === true}
                                        value={filterParams.scoredRep}
                                        label="Select Reps"
                                        placeholder="Type rep name"
                                        removeFilterValue={onRemoveField}
                                        handleChange={options =>
                                            onSelectChange("scoredRep", options)}
                                        hasProfilePic
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}

                                    />

                                </div>
                            }

                            {field === "agentNames" && (props.defaultFilters.includes(field) || (fieldsActive.includes("agentNames") || fieldsSelected.includes("agentNames"))) &&

                                <div className="Filter__fieldContainer">
                                    <CategoryHeader label="Reps" />
                                    <SelectWithSearchNew
                                        key="agentNames"
                                        fieldName="agentNames"
                                        data={allUsersNames ?? props.userNames} //Will help in getting the value of the selected user 
                                        options={groupedUserNames ?? props.userNames} //Will show the list
                                        disabled={props.singleUser === true}
                                        value={filterParams.agentNames}
                                        label="Select Reps"
                                        placeholder="Type rep name"
                                        removeFilterValue={onRemoveField}
                                        handleChange={options =>
                                            onSelectChange("agentNames", options)
                                        }
                                        hasProfilePic
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>
                            }

                            {field === "managers" && (props.defaultFilters.includes(field) || (fieldsActive.includes("managers") || fieldsSelected.includes("managers"))) &&

                                <div className="Filter__fieldContainer">
                                    {!props.singleUser && (
                                        <div className="Filter__fieldContainer">
                                            <CategoryHeader label="Team" />
                                            <SelectWithSearchNew
                                                key="team"
                                                fieldName="managers"
                                                data={props.managers}
                                                value={filterParams.managers}
                                                removeFilterValue={onRemoveField}
                                                label="Select Team"
                                                placeholder="Type manager name"
                                                handleChange={options =>
                                                    onSelectChange("managers", options)
                                                }
                                                hasProfilePic
                                                dontShowCloseIcon={props.defaultFilters.includes(field)}
                                            />
                                        </div>
                                    )}
                                </div>
                            }

                            {field === "duration" && (props.defaultFilters.includes(field) || (fieldsActive.includes("duration") || fieldsSelected.includes("duration"))) &&

                                <div className="Filter__fieldContainer">
                                    <CategoryHeader label="Duration" />
                                    <DurationFiltersNew
                                        onStartDurationChange={options =>
                                            onChange("startDuration", options)
                                        }
                                        onStartAndEndChange={values => onMultipleChanges(["startDuration", "endDuration"], values)}
                                        onEndDurationChange={options =>
                                            onChange("endDuration", options)
                                        }
                                        fieldName="duration"
                                        startDuration={filterParams.startDuration}
                                        removeFilterValue={onRemoveField}
                                        endDuration={filterParams.endDuration}
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>

                            }




                            {field === "scoredBy" && (fieldsActive.includes("scoredBy") || fieldsSelected.includes("scoredBy")) &&
                                <div className="Filter__fieldContainer">
                                    <CategoryHeader label="Scored By" />
                                    <SelectWithSearchNew
                                        key="scoredBy"
                                        fieldName="scoredBy"
                                        data={allUsersNames ?? props.userNames} //Will help in getting the value of the selected user 
                                        options={groupedUserNames ?? props.userNames} //Will show the list
                                        disabled={props.singleUser === true}
                                        value={filterParams.scoredBy}
                                        label="Scored By"
                                        placeholder="Type rep name"
                                        removeFilterValue={onRemoveField}
                                        handleChange={options =>
                                            onSelectChange("scoredBy", options)

                                        }
                                        hasProfilePic
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>
                            }



                            {field === "contacts" && (fieldsActive.includes("contacts") || fieldsSelected.includes("contacts")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Contacts" />
                                <AsyncSelectWithSearchNew
                                    data={contacts}
                                    loadData={fetchContacts}
                                    fieldName="contacts"
                                    removeFilterValue={onRemoveField}
                                    value={filterParams.contacts}
                                    label="Select contacts"
                                    placeholder="Type contact name"
                                    async
                                    handleChange={options =>
                                        onSelectChange("contacts", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "query" && (fieldsActive.includes("query") || fieldsSelected.includes("query")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Transcript contains" />
                                <TextFilter
                                    key="query"
                                    removeFilterValue={onRemoveField}
                                    fieldName="query"
                                    value={filterParams["query"]}
                                    placeholder="Enter phrase to search in transcript"
                                    onChange={(v) => onChange("query", v)}
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "dispositions" && (fieldsActive.includes("dispositions") || fieldsSelected.includes("dispositions")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Call Disposition" />
                                <SelectWithSearchNew
                                    key="dispositions"
                                    isMulti={false}
                                    fieldName="dispositions"
                                    data={CallDispositions}
                                    value={filterParams.dispositions}
                                    removeFilterValue={onRemoveField}
                                    label="Select Call Disposition"
                                    handleChange={options => onSingleSelectChange("dispositions", options)}
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}

                            {field === "containsCategories" && (fieldsActive.includes("containsCategories") || fieldsSelected.includes("containsCategories")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Contains Topic" />
                                <SelectWithSearchNew
                                    key="category"
                                    joinPhrase=" or "
                                    fieldName="containsCategories"
                                    data={props.categories}
                                    value={filterParams.containsCategories}
                                    removeFilterValue={onRemoveField}
                                    label="Select Topics"
                                    placeholder="Type topic name"
                                    handleChange={options =>
                                        onSelectChange("containsCategories", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "notContainCategories" && (fieldsActive.includes("notContainCategories") || fieldsSelected.includes("notContainCategories")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Doesn't Contain Topic" />
                                <SelectWithSearchNew
                                    joinPhrase=" and "
                                    key="notCategory"
                                    fieldName="notContainCategories"
                                    data={props.categories}
                                    removeFilterValue={onRemoveField}
                                    value={filterParams.notContainCategories}
                                    label="Select Topics"
                                    placeholder="Type topic name"
                                    handleChange={options =>
                                        onSelectChange("notContainCategories", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "peopleTagged" && (fieldsActive.includes("peopleTagged") || fieldsSelected.includes("peopleTagged")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="People Tagged" />
                                <SelectWithSearchNew
                                    key="people"
                                    joinPhrase=" or "
                                    fieldName="peopleTagged"
                                    data={props.userNames}
                                    value={filterParams.peopleTagged}
                                    removeFilterValue={onRemoveField}
                                    label="Select People"
                                    indexedFieldName="peopleTagged"
                                    placeholder="Type name"
                                    handleChange={options =>
                                        onSelectChange("peopleTagged", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "commentator" && (fieldsActive.includes("commentator") || fieldsSelected.includes("commentator")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Commented by" />
                                <SelectWithSearchNew
                                    key="commentator"
                                    joinPhrase=" or "
                                    fieldName="commentator"
                                    data={props.userNames}
                                    value={filterParams.commentator}
                                    removeFilterValue={onRemoveField}
                                    label="Select People"
                                    indexedFieldName="commentator"
                                    placeholder="Type name"
                                    handleChange={options =>
                                        onSelectChange("commentator", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "scoringTemplate" && (fieldsActive.includes("scoringTemplate") || fieldsSelected.includes("scoringTemplate")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Scoring Template" />
                                <SelectWithSearchNew
                                    key="scoringTemplate"
                                    joinPhrase=" or "
                                    fieldName="scoringTemplate"
                                    data={props.scoringTemplates}
                                    value={filterParams.scoringTemplate}
                                    removeFilterValue={onRemoveField}
                                    label="Choose template"
                                    indexedFieldName="sc_templateId"
                                    placeholder="Type template name"
                                    handleChange={options =>
                                        onSelectChange("scoringTemplate", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "competitorMentioned" && (fieldsActive.includes("competitorMentioned") || fieldsSelected.includes("competitorMentioned")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Competitor Mentioned" />
                                <SelectWithSearchNew
                                    key="competitor"
                                    joinPhrase=" and "
                                    fieldName="competitorMentioned"
                                    data={props.competitors}
                                    value={filterParams.competitorMentioned}
                                    removeFilterValue={onRemoveField}
                                    label="Select competitors"
                                    indexedFieldName="competitorsMentioned"
                                    placeholder="Type competitor name"
                                    handleChange={options =>
                                        onSelectChange("competitorMentioned", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "commentSearchTerm" && (fieldsActive.includes("commentSearchTerm") || fieldsSelected.includes("commentSearchTerm")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Comments contain" />
                                <TextFilter
                                    key="commentSearchTerm"
                                    fieldName="commentSearchTerm"
                                    value={filterParams.commentSearchTerm}
                                    removeFilterValue={onRemoveField}
                                    onChange={v =>
                                        onChange("commentSearchTerm", v)
                                    }
                                    placeholder="Enter phrase to search in comments"
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "meetingTitle" && (fieldsActive.includes("meetingTitle") || fieldsSelected.includes("meetingTitle")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Meeting title contains" />
                                <TextFilter
                                    key="meetingTitle"
                                    fieldName="meetingTitle"
                                    value={filterParams.meetingTitle}
                                    removeFilterValue={onRemoveField}
                                    onChange={v =>
                                        onChange("meetingTitle", v)
                                    }
                                    placeholder="Enter meeting title"
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}

                            {field === "score" && (fieldsActive.includes("score") || fieldsSelected.includes("score")) && <div key={i} className="">

                                <CategoryHeader label={" Call Score"} />
                                <ScoringSkillFilter
                                    key="score"
                                    fieldName="score"
                                    onChange={onChange}
                                    filterParams={filterParams}
                                    removeFilterValue={onRemoveField}

                                />
                            </div>
                            }
                            {(field === "talkListenRatio") && (fieldsActive.includes("talkListenRatio") || fieldsSelected.includes("talkListenRatio")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Talk/Listen Ratio" />
                                <RangeFilterTemplate
                                    fieldName="talkListenRatio"
                                    removeFilterValue={onRemoveField}
                                    onStartChange={options =>
                                        onChange("startTalkListenRatio", options)
                                    }
                                    onEndChange={options =>
                                        onChange("endTalkListenRatio", options)
                                    }
                                    onStartAndEndChange={values => onMultipleChanges(["startTalkListenRatio", "endTalkListenRatio"], values)}
                                    startRange={
                                        filterParams.startTalkListenRatio
                                    }
                                    endRange={
                                        filterParams.endTalkListenRatio
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    list={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]}
                                    roundOffFactor={100}
                                    showPercentageAfterValue={true}
                                />
                            </div>}

                            {field === "dealStageFrozen" && (fieldsActive.includes("dealStageFrozen") || fieldsSelected.includes("dealStageFrozen")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Deal Stage at Call time" />
                                <SelectWithSearchNew
                                    key="dealStageFrozen"
                                    fieldName="dealStageFrozen"
                                    removeFilterValue={onRemoveField}
                                    data={dealStageFrozen}
                                    value={filterParams.dealStageFrozen}
                                    label="Select Deal Stage"
                                    placeholder="Type Deal stage"
                                    handleChange={options =>
                                        onSelectChange("dealStageFrozen", options)
                                    }
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "dealStageLive" && (fieldsActive.includes("dealStageLive") || fieldsSelected.includes("dealStageLive")) &&
                                <div key={i} className="Filter__fieldContainer">
                                    <CategoryHeader label="Current Deal Stage" />
                                    <SelectWithSearchNew
                                        key="7"
                                        data={dealStageLive}
                                        fieldName="dealStageLive"
                                        removeFilterValue={onRemoveField}
                                        value={filterParams.dealStageLive}
                                        label="Select Current Deal Stage"
                                        placeholder="Type deal stage"
                                        handleChange={options =>
                                            onSelectChange("dealStageLive", options)
                                        }
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>}
                            {field === "types" && (fieldsActive.includes("types") || fieldsSelected.includes("types")) &&
                                <div key={i} className="Filter__fieldContainer">
                                    <CategoryHeader label="Call Type" />
                                    <SelectWithSearchNew
                                        key="callType"
                                        fieldName="types"
                                        removeFilterValue={onRemoveField}
                                        data={props.types ? props.types.filter(type => type.value !== "UNKNOWN_CALL_TYPE") : []}
                                        value={filterParams.types}
                                        label="Select Call type"
                                        placeholder="Type call type"
                                        handleChange={options => onSelectChange("types", options)}
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>}
                            {field === "callTags" && (fieldsActive.includes("callTags") || fieldsSelected.includes("callTags")) &&
                                <div key={i} className="Filter__fieldContainer">
                                    <CategoryHeader label="Call Tags" />
                                    <SelectWithSearchNew
                                        key="callTags"
                                        fieldName="callTags"
                                        removeFilterValue={onRemoveField}
                                        data={allTagValuesByCustomer?.map((item) => { return { value: item, label: item } })}
                                        value={filterParams.callTags}
                                        label="Select Call Tags"
                                        placeholder="Type call tags"
                                        handleChange={options => onSelectChange("callTags", options)}
                                        dontShowCloseIcon={props.defaultFilters.includes(field)}
                                    />
                                </div>}
                            {field === "status" && (fieldsActive.includes("status") || fieldsSelected.includes("status")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Call Status" />
                                <SelectWithSearchNew
                                    key="callStatus"
                                    fieldName="status"
                                    removeFilterValue={onRemoveField}
                                    data={props.status}
                                    value={filterParams.status}
                                    placeholder="Type call status"
                                    label="Select Call Status"
                                    handleChange={options => onSelectChange("status", options)}
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                            {field === "dialerCallDispositions" && (fieldsActive.includes("dialerCallDispositions") || fieldsSelected.includes("dialerCallDispositions")) && <div key={i} className="Filter__fieldContainer">
                                <CategoryHeader label="Call Disposition from Dialer" />
                                <SelectWithSearchNew
                                    key="dialerCallDispositions"
                                    loadDataLocally
                                    indexedFieldName="dialerCallDisposition"
                                    fieldName="dialerCallDispositions"
                                    removeFilterValue={onRemoveField}
                                    value={filterParams.dialerCallDispositions}
                                    placeholder="Type call disposition"
                                    label="Select Call Disposition"
                                    handleChange={options => onSelectChange("dialerCallDispositions", options)}
                                    dontShowCloseIcon={props.defaultFilters.includes(field)}
                                />
                            </div>}
                        </div>);
                })}
                <CRMFieldFilters
                    fieldsSelected={fieldsSelected}
                    fieldsActive={fieldsActive}
                    isFieldActive={isFieldActive}
                    filterParams={filterParams}
                    removeFilterValue={onRemoveField}
                    onSelectChange={editCrmField}
                />
                <OutsideClickHandler onOutsideClick={() => setShowMoreFields(false)}>
                    <div className="relative flex flex-col">
                        <span className="text-wingman-brand mx-3 my-5 mt-8 cursor-pointer text-base font-medium" onClick={toggleShowMoreFields}>+ More Filters</span>
                        <MoreFieldsPopup showMoreFields={showMoreFields} onSelect={onFieldSelect} />
                    </div>
                </OutsideClickHandler>
            </div>
        </div >
    );
}

const mapStateToProps = store => {
    return { ...store };
};

export default connect(
    mapStateToProps,
    {}
)(FilterCard);
