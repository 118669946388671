import React from 'react';
import cx from 'clsx';
import PropTypes from "prop-types";
import { useTabContext } from './TabsContext';

const TabPanel = (props) => {
    const {
        id,
        children,
        className,
        ...attributes
    } = props;

    const tabContext = useTabContext();

    if (tabContext === null) {
        throw new Error('TabPanel must be used within a TabsProvider');
    }

    const activeTabId = tabContext.activeTabId;

    return (
        <div
            {...attributes}
            className={cx("mt-5", className)}
            role="tabpanel"
        >
            {id == activeTabId && children}
        </div>
    );
}

export default TabPanel;

TabPanel.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

TabPanel.defaultProps = {
    children: null,
    className: "",
    id: 0,
};
