import React from "react";
import { TextBase, Button } from "app/designSystem";
import { SmileyIcon, ClosePlainCrossIcon } from "app/designSystem";
import { SET_SHOW_ADD_TO_GAMETAPE_MODAL } from "app/actions/actionTypes";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const AddtoGametapesNudge = ({ setEnableAddToGametapeNudge, comment }) => {
    const dispatch = useDispatch();
    const description = comment?.userName + " commented " + "\"" + comment?.comment + "\"";
    const commentTime = comment.startTime;
    const callDuration = useSelector(store => store.call?.details?.callDataDocument?.dashboardMetrics?.callDuration);
    const thresholdTime = 120;

    const getStartTimeForSnippet = (commentTime, thresholdTime) => {
        return commentTime - thresholdTime > 0 ? commentTime - thresholdTime : 0
    }
    const getEndTimeForSnippet = (commentTime, callDuration, thresholdTime) => {
        return commentTime + thresholdTime < callDuration ? commentTime + thresholdTime : callDuration
    }
    const startTime = getStartTimeForSnippet(commentTime, thresholdTime)
    const endTime = getEndTimeForSnippet(commentTime, callDuration, thresholdTime)

    function handleSetOpenModal(bool) {
        dispatch({
            type: SET_SHOW_ADD_TO_GAMETAPE_MODAL,
            showAddToGameTapeModal: bool,
            description: description,
            startTime: startTime,
            endTime: endTime,
        });
    }

    useEffect(() => {
        if (document.getElementById("addToTapesNudge")) {
            document.getElementById("addToTapesNudge").style.height = "140px"
        }
    }, [document.getElementById("addToTapesNudge")])

    return (
        <div className="w-full bg-green-50 pb-5 pt-3.5 px-3.5 flex flex-col items-start my-1 rounded-md h-[0px] overflow-hidden" id="addToTapesNudge" style={{ transition: "height 1s" }}>
            <div className="flex justify-between">
                <div className="flex ">

                    <SmileyIcon className="mr-4" />
                    <TextBase className="w-5/6 text-left mr-2">Based on your comment, this looks like a good example to share with your team. Would you like to add this to Game tapes?</TextBase>

                </div>
                <ClosePlainCrossIcon className="mb-1.5 w-[2.1rem] cursor-pointer" onClick={() => setEnableAddToGametapeNudge(false)} />
            </div>

            <div className="flex mt-4 ml-14">
                <Button
                    variant="secondary"
                    className={"!px-20 !py-1"}
                    onClick={() => handleSetOpenModal(true)}
                >
                    <TextBase>Add to Game tapes</TextBase>
                </Button>

            </div>
        </div>
    )
}


export default AddtoGametapesNudge;
