import {
  sendRequest
} from "app/utils/network";
import {
  clone
} from "lodash";

export async function getBattlecards(accessLevel = null, managerId = null) {
  const params = new URLSearchParams();
  if (accessLevel) params.append("accessLevel", accessLevel);
  params.append("managerId", managerId);
  const response = await sendRequest("/wingman/getBattlecards", params);
  return response;
}

export async function setWingmanDownloaded() {
  const response = await sendRequest("/wingman/setWingmanDownloaded");
  return response;
}

export async function getLinkForLatestWingmanDownloadableFile(platform) {
  const params = new URLSearchParams();
  params.append("platform", platform);
  const link = await sendRequest("/wingman/getLinkForLatestWingmanDownloadableFile", params);
  return link;
}

export async function editBattlecard(battlecard) {
  const response = await sendRequest("/wingman/editBattlecard", battlecard, null, {
    "Content-Type": "application/json",
  });
  return response;
}

export async function setUsersForCategory(category, accessInfo) {
  const response = await sendRequest("/wingman/setUsersForCategory?category=" + category, accessInfo, null, {
    "Content-Type": "application/json",
  });
  return response;
}

export async function createBattlecard(battlecard) {
  const response = await sendRequest("/wingman/createBattlecard", battlecard, null, {
      "Content-Type": "application/json",
  });
  return response;
}

export async function deleteBattlecard(battlecardId) {
  const params = new URLSearchParams();
  params.append("battlecardId", battlecardId);
  const response = await sendRequest("/wingman/deleteBattlecard", params);
  return response;
}

export async function getAllBattlecardCategories() {
  const response = await sendRequest("/wingman/getAllBattlecardCategories");
  return response;
}