const config = {
  //API_URL: "https://api.trywingman.com",
  API_URL: "http://localhost:8080",
  FRONTEND_VERSION: "3.0.3",
  GOOGLE_CONFIG: {
    clientId: "20015285592-gcia5s7hu80vrlphl7399bfjst7e7sho.apps.googleusercontent.com",
    apiKey: "AIzaSyCsjkhQm1Fzgeyt62DpEFhDd31V1MYwcCI",
    scope: "https://www.googleapis.com/auth/calendar.events.readonly",
    readWriteScope: "https://www.googleapis.com/auth/calendar.events",
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  },
  MICROSOFT_CONFIG: {
    clientID: "00c2ea3a-6c8b-43f8-b485-908c2e001868",
    authority: "https://login.microsoftonline.com/common",
    graphScopes: "user.read Calendars.Read offline_access",
    graphEndpoint: "https://graph.microsoft.com/v1.0/me",
  },

  METRICS_CONFIG: {
    apiKey: "c4ce26f755b0e83e646d43e4a9aa4d8c",
  },
  HUBSPOT_CLIENT_ID: "521898cb-0eb6-4ee3-9b1a-d380a8405e5f",
  FROALA_KEY: "Ja2A4wC2B1B1B1A4C3nDc2YRTYKg1Dc2a1JVVG1VJKKYLMPvA1E1I1A1B8D7A6E1D5F4==",
  PIPEDRIVE_CLIENT_ID: "eabc3626a17a1150",
  SALESFORCE_CLIENT_ID: "3MVG9YDQS5WtC11q1ijINW83_yCc0fTnWHpmwbxSF3Jed.PYxWPh5EDW96oybKiY5yNxwByjV.Dr.haIEHDOf",
  SALESLOFT_CLIENT_ID: "cb0f9462eef23fabd6db448a38113130ff0f617690ff7a57b6d9119c1045130b",
  ZOOM_CONFIG: {
    clientId: "_JkxtwSxSpWSNoSr2Qf0jA",
    redirectUri: "https://app.trywingman.com/onboarding",
  },
  NYLAS_CONFIG: {
    clientId: "d9fgpdg6dsfxa7h5jofq04zre",
  },
  SLACK_CONFIG: {
    clientId: "367683341572.627458967174",
    scopes: "chat:write:bot,bot,users:read.email,users:read,im:write",
  },
  RINGCENTRAL_CLIENT_ID: "XIhjcp1FS02SJUdmFrfsAg",
  RINGCENTRAL_BASE_URL: "https://platform.devtest.ringcentral.com",
  OUTREACH_CLIENT_ID: "1a7c392dde846904d55ff6aea55c0f11caa246ee09df907cd1f9ecc2316076b5",
  OUTREACH_BASE_URL: "https://api.outreach.io",
  DESKTOP_APP_S3_BUCKET: "https://strings-rta-public.s3.amazonaws.com/",
  DIALPAD_CLIENT_ID: "pe3ZSqdXcp34CsCCu2LWhDKBj",
  DIALPAD_INSTANCE_BASE: "https://sandbox.dialpad.com/",
};

if (process.env.REACT_APP_NODE_ENV === "local" || process.env.REACT_APP_NODE_ENV === undefined) {
  config.API_URL = "http://localhost:8080";
  config.ZOOM_CONFIG = {
    clientId: "FogZJOF8Qc6At0fyqhCovQ",
    redirectUri: "https://dev.trywingman.com/onboarding",
  };
}

if (process.env.REACT_APP_NODE_ENV === "eks-dev") {
  config.API_URL = "https://eks-dev-api.trywingman.com";
  config.DESKTOP_APP_S3_BUCKET = "https://strings-rta-public.s3.amazonaws.com/";
  config.RINGCENTRAL_CLIENT_ID = "s_kYXjAvQO2tSqefr7BYkA";
  config.RINGCENTRAL_BASE_URL = "https://platform.devtest.ringcentral.com";
  config.METRICS_CONFIG = {
    apiKey: "50d50eac761eff074e4944c6aa1f52de",
  };
  config.MICROSOFT_CONFIG.clientID = "6cb9f39f-ca8d-4082-a3a7-c8d787975bbd";
  config.PIPEDRIVE_CLIENT_ID = "5fcee0cd7ca43274";
  config.ZOOM_CONFIG = {
    clientId: "FogZJOF8Qc6At0fyqhCovQ",
    redirectUri: "https://dev.trywingman.com/onboarding",
  };
}

if (process.env.REACT_APP_NODE_ENV === "eks-prod" || process.env.REACT_APP_NODE_ENV === "production") {
  config.API_URL = "https://api.trywingman.com";
  config.DESKTOP_APP_S3_BUCKET = "https://strings-rta-public.s3.amazonaws.com/";
  config.RINGCENTRAL_CLIENT_ID = "QUjeBiLbQhyqcWGa6CrtmA";
  config.RINGCENTRAL_BASE_URL = "https://platform.ringcentral.com";

  config.METRICS_CONFIG = {
    apiKey: "0becda474f06eed877b78008b0901e56",
  };
  config.GOOGLE_CONFIG = {
    clientId: "20015285592-gcia5s7hu80vrlphl7399bfjst7e7sho.apps.googleusercontent.com",
    apiKey: "AIzaSyCsjkhQm1Fzgeyt62DpEFhDd31V1MYwcCI",
    scope: "https://www.googleapis.com/auth/calendar.events.readonly",
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
    readWriteScope: "https://www.googleapis.com/auth/calendar.events",
  };
  config.PIPEDRIVE_CLIENT_ID = "eabc3626a17a1150";
  config.DIALPAD_CLIENT_ID = "HpCs8mRUfyscLDyHT8h5qsxMY";
  config.DIALPAD_INSTANCE_BASE = "https://dialpad.com/";
}

console.log("url selected:", config.API_URL);

module.exports = config;
