import React from 'react';
import { CreateFolderIcon } from 'app/designSystem/icons'
import { Button } from 'app/designSystem'
import { useDispatch } from "react-redux";
import { setFolderCreateModal } from 'app/actions/gametapes'

const CreateFolderBtn = () => {
    const dispatch = useDispatch();

    const onClickHandler = () => {
        dispatch(setFolderCreateModal(true));
    }

    return (
        <Button
            variant="secondary"
            onClick={onClickHandler}
            className="h-10 ml-2"
            startIcon={<CreateFolderIcon />}
        >Create Folder</Button>
    )
}

export default CreateFolderBtn;