import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import WarningsIcon from "app/styles/assets/images/icons/dealView/WarningsIcon.svg";
import { WARNINGS } from "app/constants"
import useDataHelper from "../../utils/hooks/useDataHelper";

const AccountWarnings = ({ deal, warnings }) => {
    const { reduceWarnings } = useDataHelper();
    const [warningsLocal, setWarningsLocal] = useState(reduceWarnings(deal?.stage, warnings));

    useEffect(() => {
        setWarningsLocal(reduceWarnings(deal?.stage, warnings));
    }, [deal?.stage, warnings]);

    return (
        <div>
            {warningsLocal.length > 0 && (
                <div className="DealView__InfoPanel__SectionContainer">
                    <div className="DealView__InfoPanel__Header">
                        <div className="DealView__InfoPanel__HeaderTitle">
                            <img src={WarningsIcon} className="DealView__InfoPanel__HeaderIcon" />
                            <span className="DealView__InfoPanel__HeaderText DealView__InfoPanel__HeaderTextWarning">Warnings</span>
                        </div>
                    </div>
                    {warnings.map(warning => {
                        return (
                            <div className="DealView__InfoPanel__SectionRow">
                                <div className="DealView__InfoPanel__SectionItem">
                                    <span className="DealView__InfoPanel__SectionTextWarning"></span>
                                    <span className="DealView__InfoPanel__SectionText">{WARNINGS.find(el => el.name == warning)?.label}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )
            }
        </div >
    );
};

AccountWarnings.defaultProps = {}
export default AccountWarnings;
