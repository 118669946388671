import React from "react";
import { connect } from "react-redux";

const NotFound = () => {
  return (
    <div>
      <h1>Error 404</h1>
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

NotFound.propTypes = {};

export default connect(mapStateToProps)(NotFound);
