import React from "react";
import classNames from "classnames";
import { TextBase } from 'app/designSystem';
import { PlayIcon } from 'app/designSystem/icons';

const PlayNextDialog = ({ isOpen, callTitle = "" }) => {
    if (callTitle) {
        return (
            <div className={classNames("absolute flex justify-center bg-white bg-opacity-60 w-64 right-0 bottom-8 rounded-l p-2 px-3 flex-col backdrop-blur-md transition-all duration-500 ease-in-out", isOpen ? "opacity-100 visible" : "opacity-0")}>
                <TextBase className="flex text-wingman-brand items-center">
                    <PlayIcon className="h-5 w-5 pr-2" />
                    Playing next
                </TextBase>
                <TextBase>
                    {callTitle}
                </TextBase>
            </div>
        )
    }

    return null;
}

export default PlayNextDialog;