import React, { useState } from "react";
import play from "app/styles/assets/images/icons/player/play.svg";
import pause from "app/styles/assets/images/icons/player/pause.svg";
import dropdown from "app/styles/assets/images/icons/player/playDropdown.svg";
import { Radio } from "@material-ui/core";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { isCustomerSpeaker } from "../../reducers/call";
import { Seek15Icon, Seek15ReverseIcon } from "app/designSystem/icons"
import { SET_SELECTED_SPEAKERS } from "../../actions/actionTypes";
import { TextBase } from "app/designSystem";

const PlaybackControl = props => {
  const { controlProps } = props;
  const [showSpeakerSelection, setShowSpeakerSelection] = useState(false);
  const [selectedSpeakerCategory, setSelectedSpeakerCategory] = useState("ALL");
  const { speakers, channel } = useSelector(store => store.player.playback);
  const dispatch = useDispatch();

  function handleDropdownClick() {
    setShowSpeakerSelection(showSpeakerSelection => !showSpeakerSelection);
  }

  function handleSpeakerSelection(speakerCategory) {
    setSelectedSpeakerCategory(speakerCategory);
    let selectedSpeakersLocal = [];
    if (speakerCategory === "ALL")
      selectedSpeakersLocal = null;
    else {
      speakers.map((speaker, i) => {
        if (isCustomerSpeaker(i, channel, speaker) && speakerCategory === "CLIENT") {
          selectedSpeakersLocal.push(i + 1);
        } else if (!isCustomerSpeaker(i, channel, speaker) && speakerCategory === "REPS") {
          selectedSpeakersLocal.push(i + 1);
        }
      });
    }

    console.log("setting selected speakers to", selectedSpeakersLocal);
    dispatch({ type: SET_SELECTED_SPEAKERS, selectedSpeakers: selectedSpeakersLocal })
    setShowSpeakerSelection(false);
  }

  return (
    <div className="Player__group Player__center">
      <div className="Player__control">
        <div onClick={controlProps.onBackwardClick} className="cursor-pointer">
          <Seek15ReverseIcon className="w-4 h-4 mr-1" />
        </div>
        <div className="Player__control_playDiv">
          {controlProps.playing ? (
            <img onClick={controlProps.onPlayClick} className="Player__control_playIcon" src={pause} alt="pause" />
          ) : (
            <img onClick={controlProps.onPlayClick} className="Player__control_playIcon" src={play} alt="play" />
          )}
          {!props.fullscreenVideo && <img onClick={handleDropdownClick} className="Player__control_dropdownIcon !text-brand-blue-1" src={dropdown} alt="🔽" />}
          {showSpeakerSelection &&
            <OutsideClickHandler onOutsideClick={() => setShowSpeakerSelection(false)} >
              <div className="Player__control_dropdown">
                <div onClick={() => handleSpeakerSelection("ALL")} className="Player__control_dropdownItem">
                  <Radio
                    checked={selectedSpeakerCategory === "ALL"}
                    value="ALL"
                    name="selectedSpeakerCategory"
                    color="primary"
                  />
                  <TextBase textColor="text-wingman-brand">All Participants</TextBase>
                </div>
                <div onClick={() => handleSpeakerSelection("CLIENT")} className="Player__control_dropdownItem">
                  <Radio
                    checked={selectedSpeakerCategory === "CLIENT"}
                    value="CLIENT"
                    name="selectedSpeakerCategory"
                    color="primary"
                  />
                  <TextBase textColor="text-wingman-brand">Client Only</TextBase>
                </div>
                <div onClick={() => handleSpeakerSelection("REPS")} className="Player__control_dropdownItem">
                  <Radio
                    checked={selectedSpeakerCategory === "REPS"}
                    value="REPS"
                    name="selectedSpeakerCategory"
                    color="primary"
                  />
                  <TextBase textColor="text-wingman-brand">Reps Only</TextBase>
                </div>
              </div>
            </OutsideClickHandler>
          }
        </div>
        <div size="small" onClick={controlProps.onForwardClick} className="cursor-pointer">
          <Seek15Icon className="w-4 h-4 ml-1" />
        </div>
      </div>
    </div>
  );
};

export default PlaybackControl;
