export const closeDateThreshold = [
    {
        value: 3,
        label: '± 3 days'
    },
    {
        value: 7,
        label: '± 1 week'
    },
    {
        value: 14,
        label: '± 2 weeks'
    },
    {
        value: 28,
        label: '± 1 month'
    },
    {
        value: -1,
        label: 'N/A'
    }
]

export const alertFrequency = [
    {
        value: "DAILY",
        label: 'Every Day'
    },
    {
        value: "WEEKLY",
        label: 'Weekly'
    },
    {
        value: "BI_WEEKLY",
        label: 'Every 2 Weeks'
    }
]

export const channels = [
    {
        value: 'EMAIL',
        label: 'Email'
    },
    {
        value: 'SLACK',
        label: 'Slack'
    },
    {
        value: 'EMAIL_AND_SLACK',
        label: 'Email & Slack'
    }
]

export const daysOfWeek = [
    {
        value: '1',
        label: 'Sunday'
    },
    {
        value: '2',
        label: 'Monday'
    },
    {
        value: '3',
        label: 'Tuesday'
    },
    {
        value: '4',
        label: 'Wednesday'
    },
    {
        value: '5',
        label: 'Thursday'
    },
    {
        value: '6',
        label: 'Friday'
    },
    {
        value: '7',
        label: 'Saturday'
    },
]