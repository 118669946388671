import Skeleton from 'react-loading-skeleton';

export default function FolderInformationPanelSkeleton() {
    return (
        <div className="flex flex-col w-full sticky">
            <div className="mb-4">
                <Skeleton height={40} width={200} />
            </div>
            <div className="mb-4">
                <Skeleton height={40} />
                <Skeleton height={40} />
            </div>
            <div className="mb-4 flex">
                <Skeleton height={40} width={40} circle={true} />
                <Skeleton height={40} width={200} className="ml-4" />
            </div>
        </div>
    )
}