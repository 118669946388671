import {
    sendRequest
} from "app/utils/network";


export async function setCallAlertPreferences(timezoneOffset, dailyOffset, type, preference) {
    const params = new URLSearchParams();
    params.append("timezoneOffset", timezoneOffset);
    params.append("alertTime", dailyOffset);
    params.append("type", type);
    params.append("preference", preference);
    const response = await sendRequest("/user/set_call_alert_preferences", params);
    return response;
}