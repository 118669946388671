


import React, { useEffect, useState } from 'react';
import Select, { components } from "react-select";
import { getAllEmailTemplates } from "app/utils/network/emailTemplate";
import { formatOptionLabel as FormatOptionLabel, customStyles, customTheme } from 'app/scorecards/helper'
import { Button, PlusIcon } from 'app/designSystem'
import EmailTemplate from './EmailTemplate';

const EmailTemplateDropdown = ({ setDefaultEmailTemplateChange, getDefaultEmailTemplateId }) => {
    const [emailTemplates, setEmailTemplates] = useState([])
    const [activeTemplate, setActiveTemplate] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [showEmailTemplateEditor, setShowEmailTemplateEditor] = useState(false)

    useEffect(() => {
        fetchAllEmailTemplates()
    }, [])


    useEffect(() => {
        findSelectedTemplate()
    }, [emailTemplates])

    const findSelectedTemplate = async () => {
        const templateId = await getDefaultEmailTemplateId()
        const template = emailTemplates.find(e => e.id === templateId)
        setSelectedTemplate(template);
    }

    const fetchAllEmailTemplates = async () => {
        const response = await getAllEmailTemplates()
        if (response.success && response.result.length > 0) {
            const templates = response.result.map((template => ({ label: template.templateTitle, value: template.id, ...template })))
            setEmailTemplates(templates)
        }
        else
            setEmailTemplates([])
    }
    const handleTemplateChange = (option) => {
        setSelectedTemplate(option);
        setDefaultEmailTemplateChange(option)

    }
    const onTemplateEditClick = (templateId) => {
        let template = emailTemplates.find(e => e.id === templateId)
        setActiveTemplate(template)
        setShowEmailTemplateEditor(true)
    }

    const SelectMenuButton = (props) => {
        const addTemplateBtnClicked = () => {
            setActiveTemplate({
                templateTitle: "",
                emailSubject: "",
                emailBody: "",
                sendTo: "EVERYONE"
            })
            setShowEmailTemplateEditor(true)
        }
        return (
            <>
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>
                <div className="flex flex-col items-center justify-center p-3">
                    <hr className="w-full" />
                    <Button
                        className="w-full h-10 mt-2"
                        variant="secondary"
                        onClick={addTemplateBtnClicked}>
                        <>
                            <PlusIcon className='text-current' />
                            Add template
                        </>
                    </Button>
                </div>
            </>
        )
    }


    return (
        <>
            <Select
                className="w-full text-base text-coolGray-900 px-10"
                formatOptionLabel={
                    function (...props) {
                        return FormatOptionLabel.call(this, ...props, onTemplateEditClick)
                    }
                }
                options={emailTemplates}
                value={selectedTemplate}
                placeholder="Select template"
                onChange={handleTemplateChange}
                components={{ MenuList: SelectMenuButton, IndicatorSeparator: null }}
                theme={customTheme}
                styles={customStyles}
            />
            <EmailTemplate
                open={showEmailTemplateEditor}
                closeModal={() => setShowEmailTemplateEditor(false)}
                fetchAllEmailTemplates={fetchAllEmailTemplates}
                activeTemplate={activeTemplate} />
        </>);
}

export default EmailTemplateDropdown;

