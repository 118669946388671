import React, { useMemo } from 'react';
import { GameTapeFolder } from 'app/components/gameTapesLatest';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { getAccessOfAFolder } from './'

const FolderCards = ({ data, handleFolderActions }) => {
    const userId = useSelector(state => state.auth.userId);
    const { gametapesList, foldersList } = useSelector(state => state.gametapes);

    const subFoldersCount = useMemo(() => foldersList?.filter(folder => folder.parentFolder === data.id).length, [foldersList]);
    const access = useMemo(() => getAccessOfAFolder(data));

    const getGametapesCountForFolder = (gametapes, folder) => gametapes?.filter(gameTape => gameTape.parentFolder === folder.id).length ?? 0;

    const subGameTapesCount = useMemo(() => {
        let count = 0;
        count += getGametapesCountForFolder(gametapesList, data);

        /**
         * If folder has subfolders, then we need to get the gametapes count for each of them
         * and add it to the total count
         */

        foldersList?.filter(folder => folder.parentFolder === data.id)?.map(folder => {
            count += getGametapesCountForFolder(gametapesList, folder);
        })

        return count;
    }, [gametapesList]);

    return (
        <GameTapeFolder
            data={data}
            access={access}
            subFoldersCount={subFoldersCount}
            subGameTapesCount={subGameTapesCount}
            subscribed={data.subscribedUsers?.includes(userId)}
            actions={{
                onRemove: () => handleFolderActions("remove", data),
                onMove: () => handleFolderActions("move", data),
                onEdit: () => handleFolderActions("edit", data)
            }}
        />
    )
}

export default FolderCards;

FolderCards.propTypes = {
    data: PropTypes.object.isRequired,
    handleFolderActions: PropTypes.func.isRequired
}

FolderCards.defaultProps = {
    data: {},
    handleFolderActions: () => { }
}