import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { EditWithBoxIcon, TextBase } from 'app/designSystem'
import colors from "tailwindcss/colors"
import { getScoringTemplatesForFilter } from "app/utils/network"
import { SET_META_DATA } from 'app/actions/actionTypes';
const useScorecardHelper = (template, scoredRepId) => {
    const cachedPopularTemplates = useSelector(state => state?.scorecards?.popularTemplates)


    const scorerId = useSelector(state => state?.auth?.userId)
    const customerId = useSelector(state => state?.auth?.stringsCustomer?.customerId)
    const callId = useSelector(state => state?.call?.callId)
    const currentUserId = useSelector(state => state.auth.userId)
    const templateType = useSelector(state => state?.auth?.stringsCustomer?.scoringTemplateType)
    const dispatch = useDispatch()
    const getNewScoreCard = (template, scoredRepId) => {
        let newScorecardDocument = {
            scoringTemplateId: template.id,
            scorerId,
            customerId,
            callId,
            scoredRepId,
            scoringType: template.type,
            totalScore: null,
            questionScores: template.questions.map((question) => {//template.question has order ,label skill etc. Add scores alone to question and store in scorecard
                return {
                    ...question, score: null
                }
            }),
            remark: null,

        }

        return newScorecardDocument
    }


    const getNewTemplateObject = () => {
        return {
            customerId,
            name: "",
            type: templateType,
            questions: []
        }
    }
    const getScorecardPermissionForCurrentUser = (scorecard) => {
        // Can show for manager/admins and rep's whose  
        if (auth.isAdmin || auth.isManager)
            return "CAN_SCORE"

        else if (auth.isRep && scorecard?.scoredRepId == currentUserId)
            return "CAN_VIEW"
        else return "NO_ACCESS"
    }

    const updateMetaDataForFilters = async () => {
        let tempateOptionsForFilter = await getScoringTemplatesForFilter()
        dispatch({ type: SET_META_DATA, metaData: { scoringTemplates: tempateOptionsForFilter } });
    }
    return { getNewScoreCard, getScorecardPermissionForCurrentUser, getNewTemplateObject, updateMetaDataForFilters }
}

export default useScorecardHelper



export function formatOptionLabel({ label, value }, { context }, onTemplateEditClick) {
    const handleEditIconClick = (e, templateId) => {
        e.stopPropagation()
        e.preventDefault()
        onTemplateEditClick && onTemplateEditClick(templateId)

    }
    return (
        <div className={classNames("py-3.5 flex justify-between group", { "px-6": context == "menu" }, { "px-2": context == "value" })} >
            <TextBase textColor="text-gray-900">{label}</TextBase>
            {
                (context == "menu" && onTemplateEditClick)//only if there is a edit handler function show edit icon
                &&
                <div className="cursor-pointer opacity-0 group-hover:opacity-100" onClick={(e) => { handleEditIconClick(e, value) }} id="adoption-tracking-open-edit-template-from-dropdown">
                    <EditWithBoxIcon className="text-coolGray-500" />
                </div>


            }

        </div>
    )
}
export const customStyles = {
    option: base => {
        return {
            ...base,
            padding: 0,
            border: 0,
            margin: 0

        }
    },
    control: (base, state) => {
        //By defauly theme.primary applied as borer color 

        let style = {
            ...base,
            "minHeight": "3rem",
        }
        if (state.isFocused) {
            style["borderColor"] = "#2680EB"
            style["&:hover"] = "#2680EB"
        }
        return style
    },
    placeholder: base => {
        return {
            ...base,
            "paddingLeft": ".5rem",
        }
    },
    input: base => {
        return {
            ...base,
            "paddingLeft": ".5rem"
        }
    },
    valueContainer: base => {

        return {
            ...base,
            padding: 0,

        }
    }


};

export const customTheme = (theme) => {
    return {
        ...theme,
        colors: {
            ...theme.colors,

            primary: colors.coolGray[200],
            primary25: colors.coolGray[50],
            primary50: colors.coolGray[50]

        }
    }
}