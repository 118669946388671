import React from "react";
import { Button, Text2XL, TextBase } from "app/designSystem"

const SignUpWithProvider = (props) => {
    const { loading, data, email, ssoLoginHandler } = props;

    return (
        <div className="LoginForm" id="signUpForm">
            <Text2XL textColor="text-coolGray-800">Welcome to Wingman</Text2XL>
            <TextBase textColor="text-coolGray-500 mb-3">Let&#39;s get you started</TextBase>
            <div>
                <Button
                    id="signup"
                    className="mt-6 w-full"
                    loading={loading}
                    onClick={() => ssoLoginHandler(email)}>
                    {"Sign up with " + data?.connection_name}
                </Button>
                <div className="my-6 flex justify-center">
                    <SignUpFooter />
                </div>
            </div>
        </div>
    );
}

const SignUpFooter = () => {
    return (
        <span className="LoginForm__disclaimer text-sm">
            {"By signing up for a Wingman account you are agreeing to the "}
            <a className="text-wingman-brand" target="_blank" href="https://www.trywingman.com/privacy-policy" rel="noopener noreferrer">Privacy Policy</a>
            {" and "}
            <a className="text-wingman-brand" target="_blank" href="https://www.trywingman.com/terms-of-service" rel="noopener noreferrer">Terms of Service</a>.
        </span>
    )
}

export default SignUpWithProvider;
