
import React, { useState, useEffect, useCallback } from 'react'
import { sendRequest } from '../../utils/network';
import NoNotifications from "app/styles/assets/images/icons/NoNotifications.svg";
import CommentNotification from './CommentNotification';
import { Button, TextBase, TextLarge } from 'app/designSystem';
import { useDispatch, useSelector } from 'react-redux';
import { SET_NOTIFICATIONS } from '../../actions/actionTypes';
import ClubbedNotification from './ClubbedNotifications';
import { debounce } from "lodash";
import { withRouter } from 'react-router-dom';
import { CustomLink } from '../common';

const NotificationsBody = ({ history, hideNotificationModal, type }) => {
	const clubbedNotifications = useSelector(store => store.screen.clubbedNotifications);
	const notifications = useSelector(store => store.screen.notifications);
	const notificationsNew = useSelector(store => store.screen.notificationsNew);
	const dispatch = useDispatch();
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const limit = 10;

	useEffect(() => {
		if (!notifications || notifications.length === 0) {
			getMoreNotifications(0);
		}
	}, [])

	async function getMoreNotifications(pageParam = page) {
		console.log("hasMore", hasMore);
		if (!hasMore) return;
		const params = new URLSearchParams();
		params.append("offset", (pageParam * limit) + 1);
		params.append("limit", limit);
		const localNotifications = await sendRequest("/notifications/get_all_notifications", params);
		if (!localNotifications || localNotifications.length === 0) {
			setHasMore(false);
			return;
		}
		dispatch({ notifications: [...notifications, ...localNotifications], type: SET_NOTIFICATIONS });
		setPage(page => page + 1);
	}

	async function handleMarkAllAsRead() {
		await sendRequest("/notifications/mark_all_as_read");
		const localNotifications = notifications.map(n => { n.read = true; return n; });
		dispatch({ notifications: localNotifications, type: SET_NOTIFICATIONS });
	}

	function handleViewAll() {
		history.push("/notifications");
		hideNotificationModal();
	}

	function handleNotificationClick(id) {
		const localNotifications = notifications.map((n) => {
			if (n.id === id) {
				n.read = true;
			}
			return n;
		})
		dispatch({ notifications: localNotifications, type: SET_NOTIFICATIONS });
	}


	const handleScroll = debounce(() => {
		if (
			document.getElementById("notificationsBody").clientHeight + document.getElementById("notificationsBody").scrollTop === document.getElementById("notificationsBody").scrollHeight
		) {
			getMoreNotifications();
		}
	}, 100);

	const handleScrollCallback = useCallback(handleScroll, [page, hasMore]);

	useEffect(() => {
		if (document.getElementById("notificationsBody")) {
			console.log("adding event listener");
			document.getElementById("notificationsBody").addEventListener("scroll", handleScrollCallback);
		}
		return (() => {
			if (document.getElementById("notificationsBody")) {
				document.getElementById("notificationsBody").removeEventListener("scroll", handleScrollCallback);
			}
		});
	}, []);

	return (
		<>
			<div className="NotificationsPage__header bg-background-blue">
				<TextLarge className="font-semibold">Activity History</TextLarge>
				{notificationsNew && <Button variant={"tertiary"} onClick={handleMarkAllAsRead} className="!text-brand-blue-1"> Mark all As Read</Button>}
			</div>
			<div id="notificationsBody" className={type === "small" ? "NotificationsContainer__modalBody" : "NotificationsPage__body"} >
				{
					Object.keys(clubbedNotifications).map((callId, i) => {
						return (
							<>
								{clubbedNotifications[callId]["sharedView"].length === 1 &&
									<CommentNotification
										type={type}
										markAsRead={() => { handleNotificationClick(clubbedNotifications[callId]["sharedView"][0].id) }}
										key={i + "sharedViewSingle"}
										notification={clubbedNotifications[callId]["sharedView"][0]}
									/>
								}
								{clubbedNotifications[callId]["unread"].length === 1 &&
									<CommentNotification
										type={type}
										markAsRead={() => { handleNotificationClick(clubbedNotifications[callId]["unread"][0].id) }}
										key={i + "unreadSingle"}
										notification={clubbedNotifications[callId]["unread"][0]}
									/>
								}
								{clubbedNotifications[callId]["read"].length === 1 &&
									<CommentNotification
										type={type}
										markAsRead={() => { handleNotificationClick(clubbedNotifications[callId]["read"][0]) }}
										key={i + "readSingle"}
										notification={clubbedNotifications[callId]["read"][0]}
									/>
								}
								{clubbedNotifications[callId]["unread"].length > 1 &&
									<ClubbedNotification
										type={type}
										key={i + "unread"}
										markAsRead={handleNotificationClick}
										clubbedNotifications={clubbedNotifications[callId]["unread"]}
										callId={callId}
									/>
								}
								{clubbedNotifications[callId]["sharedView"].length > 1 &&
									<ClubbedNotification
										type={type}
										key={i + "sharedView"}
										markAsRead={handleNotificationClick}
										clubbedNotifications={clubbedNotifications[callId]["sharedView"]}
										callId={callId}
									/>
								}
								{clubbedNotifications[callId]["read"].length > 1 &&
									<ClubbedNotification
										type={type}
										key={i + "read"}
										markAsRead={handleNotificationClick}
										clubbedNotifications={clubbedNotifications[callId]["read"]}
										callId={callId}
									/>
								}
							</>
						);
					})
				}
				{notifications.length === 0 &&
					<div className="NotificationsContainer__empty">
						<img className="NotificationsContainer__emptyIcon" src={NoNotifications} />
						<span className="NotificationsContainer__emptyTitle">You have no notifications</span>
						<span className="NotificationsContainer__emptyCopy">Notifications will appear once someone tags/comments on a call.</span>
					</div>
				}
			</div>
			{notifications.length > 0 && type === "small" &&
				<div className="NotificationsContainer__modalFooter">
					<Button variant={"primary"} onClick={handleViewAll}>View All Notifications</Button>
				</div>}
		</>
	);
}
NotificationsBody.propTypes = {}
NotificationsBody.defaultProps = {
	type: "small"
}
export default withRouter(NotificationsBody);