export function magicCopy(rtfText, plainText, event) {
    event.clipboardData.setData("text/plain", plainText);
    event.clipboardData.setData("text/html", rtfText);
    return true;
}

function addWordWithSpaceIfNeeded(word, result) {
    if (word.str.charAt(0) !== '\'') result += ' ';
    result += word.str;
    return result;
}

function _partialCopy(result, turn, start, end) {
    for (const word of turn.words) {
        if (word.endMs < start * 1000) continue;
        if (word.startMs > end * 1000) return { isDone: true, result };
        result = addWordWithSpaceIfNeeded(word, result);
    }
    result += '\n'
    return { isDone: false, result };
}

export function generateClipboardText(start, end, call, turns, meetingTitle, speakerNames, format) {
    let result = getInitString(call.callId, meetingTitle, start, format);
    for (const turn of turns) {
        if (!isTurnIncludedInSelectedText(start, end, turn)) continue;

        result += getSpeakerHeading(speakerNames, turn, call.callId, format);
        const copiedData = _partialCopy(result, turn, start, end);
        result = copiedData.result;
        if (copiedData.isDone) { return result; }
    }
    return result;
}

function getInitString(callId, meetingTitle, start, format) {
    switch (format) {
        case 'rtf':
            return "<h3><a href='" + window.location.origin + '/call/' + callId + "#time=" + start + "'>" + meetingTitle + "@" + Math.floor(start / 60).toString().padStart(2, 0) + ":" + Math.floor(start % 60).toString().padStart(2, 0) + "</a></h3>\n";
        case 'plain':
            return meetingTitle + "@" + Math.floor(start / 60) + ":" + Math.floor(start % 60) + "\n";
    }
}

function getSpeakerHeading(speakerNames, turn, callId, format) {
    switch (format) {
        case 'rtf':
            return "<h3><strong>" + speakerNames[turn.speakerId - 1] + "</strong> <a href='" + window.location.origin + "/call/" + callId + "#time=" + turn.startTime + "'>" + Math.floor(turn.startTime / 60).toString().padStart(2, 0) + ":" + Math.floor(turn.startTime % 60).toString().padStart(2, 0) + "</a></h3>\n";

        case 'plain':
            return speakerNames[turn.speakerId - 1] + "[" + Math.floor(turn.startTime / 60) + ":" + Math.floor(turn.startTime % 60) + "]\n";
    }
}

function isTurnIncludedInSelectedText(start, end, turn) {
    if (turn.endTime < start) return false;
    else if (turn.startTime > end) return false;
    else return true;
}