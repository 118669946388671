import React from "react";
import { NoCallsIcon, Text2XL } from "app/designSystem";
export const EmptyTableRow = () => (
  <tr>
    <td colSpan={42} rowSpan={42}>
      <div className="flex flex-col items-center justify-start w-full">
        <NoCallsIcon className="mt-4" />
        <Text2XL className="mt-6 mb-4">No calls found!</Text2XL>
      </div>
    </td>

  </tr>
);
