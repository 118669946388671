import React, { useState } from "react";
import { connect } from "react-redux";
import EmailIcon from "app/styles/assets/images/icons/emailVerificationPending.svg";
import { sendVerificationEmail } from "../utils/network";
import { Button } from "app/designSystem";
import { WingmanLogoWithName } from "app/designSystem";
import { ProfileDropdown } from "../components/layout";

const EmailVerificationPending = props => {
  const [loading, setLoading] = useState(false);
  async function handleResend() {
    setLoading(true);
    const res = await sendVerificationEmail(props.auth.data.user.emailId);
    setLoading(false);
    return res;
  }

  return (
    <div className="EmailVerificationPending">
      <div className="header">
        <WingmanLogoWithName className="h-9" />
        <ProfileDropdown />
      </div>
      <img className="bigIcon" src={EmailIcon} alt="Email not verified" />
      <span className="bigFontBold">Verify your email address</span>
      <span className="bigFont">{`An email has been sent to ${props.auth.data.user.emailId}`}</span>
      <span className="smallFont">
        {
          "Please check your email and click on the verification link to get complete access to your Wingman account"
        }
      </span>
      <div className="bottomDiv">
        <span className="bottomFont">Didn't get any email?</span>
        <span className="bottomFontWarn">Check your junk/spam folder</span>
        <Button className="mt-2" loading={loading} onClick={handleResend} >
          {"Resend Verification Email"}
        </Button>
      </div>
      <span className="footerText">
        {"Questions? Email us at "}
        <a href="mailto:support@trywingman.com" className="link">
          {"support@trywingman.com"}
        </a>
      </span>
    </div>
  );
};

EmailVerificationPending.propTypes = {};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  {},
)(EmailVerificationPending);
