import { sendRequest } from "app/utils/network";

export async function getSharedCall(shareId) {
  const response = await sendRequest("/call/sharedCall/" + shareId);
  return response;
}

export async function getShareableCallId(callId, validOnlyForAMonth, start = null, end = null, type = "AUDIO", snippetName, notifyMe = false, emailRequired) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("validity", validOnlyForAMonth ? "MONTH" : "FOREVER");
  params.append("type", type);
  if (snippetName && snippetName.trim() != "") {
    params.append("snippetName", snippetName);
  }
  params.append("emailRequired", emailRequired);
  params.append("sendNotification", notifyMe);
  params.append("timezoneOffset", new Date().getTimezoneOffset());
  if (start) params.append("start", parseInt(start));
  if (end) params.append("end", parseInt(end));
  const response = await sendRequest("/call/shareCallNew", params);
  return response;
}

export function getShareableLink(callId) {
  return window.location.origin + "/guest/sharedCall/" + callId;
}
