import {
    sendRequest,
} from "app/utils/network";
import {
    getRedirectUrl
} from "../../components/onboarding/helpers";

export async function hubspotCallback(code, userId, access = "user") {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/hubspot/auth_callback", params);
    return response;
}

export function oneTimeHubspotSync(userId, access = "user") {
    const params = new URLSearchParams();
    params.append("userId", userId);
    params.append("access", access);
    try {
        sendRequest("/hubspot/oneTimeHubspotSync", params);
    } catch (error) {
        console.log("");
    }
}

export async function calendarCallback(code, state) {
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("state", state);
    params.append("frontendUrl", getRedirectUrl());

    const response = await sendRequest("/calendar/auth_callback", params);
    return response;
}

export async function unsyncGoogleCalendar() {
    const response = await sendRequest("/calendar/unauth_google_calendar");
    return response;
}

export async function outlookCalendarCallback(code) {
    let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
        frontendUrl = `${frontendUrl}:${window.location.port}`;
    }
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("frontendUrl", frontendUrl);
    const response = await sendRequest("/outlook/auth_callback", params);
    return response;
}

export async function unsyncOutlook() {
    const response = await sendRequest("/outlook/unsync");
    return response;
}

export async function syncSlack(code, redirectUri) {
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("redirectUri", redirectUri);
    const response = await sendRequest("/slack/auth_callback", params);
    return response;
}

export async function unsyncSlack() {
    const response = await sendRequest("/slack/unsync");
    return response;
}

export async function pipedriveCallback(code, userId) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    const response = await sendRequest("/pipedrive/auth_callback", params);
    return response;
}

export function oneTimePipedriveSync(userId) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    try {
        sendRequest("/pipedrive/oneTimePipedriveSync", params);
    } catch (error) {
        console.log("");
    }
}

export async function ringcentralCallback(code, userId, access) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/ringcentral/auth_callback", params);
    return response;
}

export function oneTimeRingcentralSync(userId) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    try {
        sendRequest("/ringcentral/init_sync", params);
    } catch (error) {
        console.log("");
    }
}

export async function salesforceCallback(code, userId, access) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/salesforce/auth_callback", params);
    return response;
}

export async function crmOAuthCallback(code, sourceCrm, apiBase = null, state = null) {
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("sourceCrm", sourceCrm);
    if (apiBase)
        params.append("apiBase", apiBase);
    if (state)
        params.append("state", state);
    const response = await sendRequest("/integration_oauth/auth_callback", params);
    return response;
}

export async function salesloftCallback(code, userId, access) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/salesloft/auth_callback", params);
    return response;
}

export async function dialpadCallback(code, userId, access) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/dialpad/auth_callback", params);
    return response;
}

export function oneTimeSalesforceSync(userId, access) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    params.append("access", access);
    try {
        sendRequest("/salesforce/oneTimeSalesforceSync", params);
    } catch (error) {
        console.log("");
    }
}

export function oneTimeSalesloftSync() {
    try {
        sendRequest("/salesloft/sync_user");
    } catch (error) {
        console.log(error);
    }
}

export function oneTimeDialpadSync() {
    try {
        sendRequest("/dialpad/sync_user");
    } catch (error) {
        console.log(error);
    }
}

export async function zoomCallback(code, userId) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    const response = await sendRequest("/zoom/auth_callback", params);
    return response;
}

export function oneTimeZoomSync(userId) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    sendRequest("/zoom/oneTimeZoomSync", params);
}

export async function outreachCallback(code, userId, access) {
    const frontendUrl = `${window.location}`;
    const params = new URLSearchParams();
    params.append("code", code);
    params.append("userId", userId);
    params.append("frontendUrl", frontendUrl);
    params.append("access", access);
    const response = await sendRequest("/outreach/auth_callback", params);
    return response;
}

export function oneTimeOutreachSync(userId) {
    const params = new URLSearchParams();
    params.append("userId", userId);
    try {
        sendRequest("/outreach/init_sync", params);
    } catch (error) {
        console.log("");
    }
}