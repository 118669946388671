import React from "react";
import PropTypes from "prop-types";
import { metrics } from "app/utils/metrics";
import { connect } from "react-redux";
import { setUserPreferences } from "app/actions/persisted";
import { fetchDataSafely } from "../../utils/dataUtils";

function withHowler(Child) {
  class CustomHowler extends React.Component {
    constructor(props) {
      super(props);
      this.play = this.play.bind(this);
      this.pause = this.pause.bind(this);
      this.rate = this.rate.bind(this);
      this.volume = this.volume.bind(this);
      this.seek = this.seek.bind(this);
      this.skipBy = this.skipBy.bind(this);
      this.setVideoState = this.setVideoState.bind(this);
      this.onseekchange = this.onseekchange.bind(this);
      this.setVideoPlayerRef = this.setVideoPlayerRef.bind(this);
      this.isVideoOn = this.isVideoOn.bind(this);
      this.getPlayerState = this.getPlayerState.bind(this);
      this.state = {
        playing: false, // Enable autoplay by default
        format: ["mp3"],
        rate: fetchDataSafely(this.props, "persisted.userPreferences.playerRate", 1),
        seek: 0,
        mute: false,
        preload: true,
        loop: false,
        volume: 1.0,
        soundId: null,
        videoState: { waiting: false },
        videoPlayerRef: null,
      };
    }

    setVideoPlayerRef(videoPlayerRef) {
      videoPlayerRef.playbackRate = this.state.rate;
      this.setState({
        videoPlayerRef,
      })
    }

    /**
     * The onseekchange called within requestAnimationFrame to update the playback position.
     */
    onseekchange() {


      const seek = this.state.videoState.currentTime();
      if (!(this.state.videoState.waiting && this.props.player.audioSource === "VIDEO")) {
        this.setState(prevState => {
          return {
            ...prevState,
            seek:
              seek == null || isNaN(seek) || seek instanceof Object
                ? prevState.seek
                : seek
          };
        });
      }
      // If the sound is still playing, continue stepping.
      if (this.state.playing) {
        requestAnimationFrame(this.onseekchange);
      }
    }

    isVideoOn() {
      return this.props.player.audioSource === "VIDEO";
    }

    play() {
      if (this.isVideoOn() && this.state.videoPlayerRef) {
        this.state.videoPlayerRef.play();
      }

      metrics.logEvent("playingCall", {
        url: this.props.player.playback.url
      });
    }

    pause(id = undefined) {
      try {
        if (this.isVideoOn() && this.state.videoPlayerRef) {
          this.state.videoPlayerRef.pause();
          return;
        }
      } catch (error) {
        console.log("Error: Couldn't pause audio", error.message);
      }
      metrics.logEvent("pausingCall", { url: this.props.src });
    }


    rate(args) {
      console.log("changing rate to ", args);
      if (this.isVideoOn()) {
        this.state.videoPlayerRef.playbackRate = args;
      }
      this.setState({
        rate: args
      });
      this.props.setUserPreferences({ playerRate: args });
    }

    volume(...args) {
      if (this.isVideoOn()) {
        this.state.videoPlayerRef.volume = args;
      }
      this.setState({
        volume: args
      });
    }

    skipBy(duration) {
      if (this.state.videoState && this.state.videoState.currentTime)
        this.seek(this.state.videoState.currentTime + duration);
      else
        this.seek(this.state.seek + duration);
    }

    seek(pos = null) {
      if (this.isVideoOn() && (pos || pos === 0)) {
        this.state.videoPlayerRef.seek(pos, "seconds");
        this.setState({
          seek: pos,
        });
        this.play();
        return pos;
      }
    }

    setVideoState(videoState) {
      this.setState({
        videoState,
      })
    }

    getPlayerState() {
      if (this.isVideoOn()) {
        return { ...this.videoState, playing: !this.state.videoState.paused, rate: this.state.rate, volume: [this.state.videoState.volume], seek: this.state.videoState.currentTime }
      }
      return this.state;
    }

    /**
     * Only render a placeholder
     */
    render() {
      const state = this.getPlayerState();
      const playerProps = {
        ...state,
        setSeek: this.seek,
        setPlay: this.play,
        skipBy: this.skipBy,
        setPause: this.pause,
        setRate: this.rate,
        setVolume: this.volume,
        setVideoState: this.setVideoState,
        setVideoPlayerRef: this.setVideoPlayerRef
      };
      return <Child playerProps={playerProps} {...this.props} />;
    }
  }

  CustomHowler.propTypes = {
    children: PropTypes.node,
    rate: PropTypes.number,
    seek: PropTypes.number,
    format: PropTypes.arrayOf(PropTypes.string),
    playing: PropTypes.bool,
    mute: PropTypes.bool,
    loop: PropTypes.bool,
    preload: PropTypes.bool,
    volume: PropTypes.number,
    onEnd: PropTypes.func,
    onPause: PropTypes.func,
    onPlay: PropTypes.func,
    onVolume: PropTypes.func,
    onStop: PropTypes.func,
    onLoad: PropTypes.func,
    onLoadError: PropTypes.func,
    onSeekChange: PropTypes.func,
    html5: PropTypes.bool,
  };

  CustomHowler.defaultProps = {
    children: null,
    playing: false, // Enable autoplay by default
    format: ["wav"],
    rate: 1,
    seek: 0,
    mute: false,
    preload: true,
    loop: false,
    volume: 1.0,
    onEnd: () => { },
    onPause: () => { },
    onPlay: () => { },
    onVolume: () => { },
    onStop: () => { },
    onLoad: () => { },
    onLoadError: () => { },
    onSeekChange: () => { },
    html5: true,
  };

  const mapStateToProps = store => {
    return { ...store };
  };

  return connect(
    mapStateToProps,
    { setUserPreferences },
  )(CustomHowler);
}

export default withHowler;
