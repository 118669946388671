import React from "react";
import PropTypes from "prop-types";
import { TextField, Grid, IconButton } from "@material-ui/core";
import { CustomLink, ThreeDotsLoader } from "app/components/common";
import { MentionsInput, Mention } from "react-mentions";
import {
  mentionInputStyleDisabledReply,
  mentionStyle,
  mentionStyleReply,
  mentionInputStyle,
  mentionInputStyleReply,
} from "app/styles/components/mentionStyles";
import ReplyIcon from "@material-ui/icons/Reply";
import { getAllUsers, addReply } from "app/utils/network";
import { connect } from "react-redux";
import { _formatDateToCallCardDateFormat } from "app/utils/helpers";
import { metrics } from "app/utils/metrics";

class RepliesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.toggleReplies = this.toggleReplies.bind(this);
    this.addReply = this.addReply.bind(this);
    this.state = {
      showReplies: true,
      replies: props.comment.replies || [],
    };
  }

  async addReply(callId, replyText, taggedUsers, commentId) {
    const { replies } = this.state;
    await addReply(callId, replyText, taggedUsers, commentId, this.props.comment.startTime);
    metrics.logEvent("replyAdded", { callId });
    const reply = {
      userName: this.props.auth.data.user.displayName,
      taggedUsers,
      comment: replyText,
    };
    replies.push(reply);
    this.setState({
      replies,
    });
  }

  toggleReplies() {
    const { showReplies } = this.state;
    this.setState({
      showReplies: !showReplies,
    });
  }

  render() {
    const { comment, callId } = this.props;
    if (this.state.replies && this.state.replies.length) {
      return (
        <div>
          <div>
            <div className="Reply__viewReplyButtonContainer">
              <span className="Reply__viewReplyButton" onClick={this.toggleReplies}>
                {this.state.showReplies
                  ? "Hide replies"
                  : `View all ${this.state.replies.length} replies`}
              </span>
            </div>
            <div style={{ display: this.state.showReplies ? "inline" : "none" }}>
              <Replies replies={this.state.replies} />
              <AddReplyContainer addReply={this.addReply} commentId={comment.id} callId={callId} />
            </div>
          </div>
        </div>
      );
    }
    return <AddReplyContainer addReply={this.addReply} commentId={comment.id} callId={callId} />;
  }
}

const Replies = props => {
  const { replies } = props;
  return replies.map((reply, id) => {
    return (
      <div key={id} className="Reply">
        <div>
          <span className="Reply__icon">
            {reply.userName && reply.userName.length > 0 && reply.userName[0]}
          </span>
          <span className="Reply__commenter">{reply.userName.split(" ")[0]}</span>
          <span className="Reply__time">{_formatDateToCallCardDateFormat(reply.timeStamp)}</span>
        </div>
        <div className="Reply__text">
          <MentionsInput
            value={reply.comment}
            onChange={null}
            markup="@(__display__)"
            displayTransform={login => `@${login}`}
            style={mentionInputStyleDisabledReply}
          >
            <Mention
              trigger="@"
              style={mentionStyleReply}
              appendSpaceOnAdd
              onAdd={null}
              data={[]}
            />
          </MentionsInput>
        </div>
      </div>
    );
  });
};

class AddReplyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reply: "",
      taggedUsers: [],
      users: [],
      justTagged: false,
      loading: false,
      showReplyBox: false,
    };
    this.addReply = this.addReply.bind(this);
    this.setReply = this.setReply.bind(this);
    this.addMention = this.addMention.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("keyup", this.handleEnter, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleEnter, false);
  }

  handleEnter(e) {
    e.preventDefault();
    if (e.keyCode === 13 && !this.state.justTagged) {
      this.addReply();
    }
    this.setState({
      justTagged: false,
    });
  }

  async componentDidMount() {
    const users = await getAllUsers();
    this.setState({
      users,
    });
  }

  async addReply() {
    let taggedUsers = this.state.taggedUsers;
    if (this.state.reply.trim() != "" && !this.state.loading) {
      await this.setState({
        loading: true,
      });
      for (let i = 0; i < taggedUsers.length; i++) {
        const tag = `@(${taggedUsers[i].name})`;
        if (this.state.reply.indexOf(tag) == -1) {
          taggedUsers.splice(i, 1);
        }
      }
      taggedUsers = taggedUsers.map(a => a.email);
      await this.props.addReply(
        this.props.callId,
        this.state.reply.trim(),
        taggedUsers,
        this.props.commentId,
      );
      await this.setState({ reply: "", loading: false, taggedUsers: [] });
    }
  }

  setReply(e) {
    const reply = e.target.value.replace("\n", "");
    this.setState({
      reply,
      justTagged: false,
    });
  }

  addMention(id, display) {
    const taggedUsers = this.state.taggedUsers;
    taggedUsers.push({ email: display, name: display });
    this.setState({
      taggedUsers,
      justTagged: true,
    });
  }

  showReplyBox = () => {
    this.setState({ showReplyBox: true });
  };

  render() {
    const { height, expanded } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          margin: "5px",
        }}
        ref={node => (this.node = node)}
      >
        <IconButton style={{ padding: "2px" }} onClick={this.showReplyBox}>
          <ReplyIcon style={{ fontSize: "14", color: "#502462" }} />
        </IconButton>
        {this.state.loading && <ThreeDotsLoader left={100} top={20} />}
        {this.state.showReplyBox && (
          <AddReplyAreaRender
            reply={this.state.reply}
            setReply={this.setReply}
            users={this.state.users}
            addMention={this.addMention}
            duration={this.props.duration}
            addReply={this.addReply}
          />
        )}
      </div>
    );
  }
}

const AddReplyAreaRender = props => {
  return (
    <Grid
      container
      alignItems="flex-start"
      justify="space-between"
      className="Reply__addReplyContainer"
    >
      <Grid item xs={12}>
        <MentionsInput
          value={props.reply}
          onChange={props.setReply}
          allowSpaceInQuery
          markup="@(__display__)"
          placeholder="Reply..."
          displayTransform={login => `@${login}`}
          style={mentionInputStyleReply}
        >
          <Mention
            trigger="@"
            style={mentionStyle}
            appendSpaceOnAdd
            onAdd={props.addMention}
            data={props.users}
          />
        </MentionsInput>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = store => {
  const { auth } = store;
  return { auth };
};

export default connect(
  mapStateToProps,
  {},
)(RepliesContainer);
