import {
	SET_CALL_LOG_FILTER,
	SET_FILTERED_CALLS,
	SET_MANAGER_DASHBOARD_FILTER
} from "./actionTypes";

export function setCallLogFilter(filterParams, userId) {
	console.log("setting localstorage calllogFilter", filterParams);
	localStorage.setItem("callLogFilter" + userId, JSON.stringify(filterParams))
	return {
		filterParams,
		type: SET_CALL_LOG_FILTER
	};
}

export function setFilteredCalls(results) {
	return {
		results,
		type: SET_FILTERED_CALLS
	};
}