import { GetSortOrder } from "../components/dashboards/helpers";
import { _getDateAtMidnight } from "./helpers";

const SMALL_ACTIVITY_THRESHOLD = 200;
const MEDIUM_ACTIVITY_THRESHOLD = 4000;

export function _getActivitiesByDate(activity, noOfDays) {
    let activityList = [];
    let tempActiveItems = [];
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - noOfDays);

    if (activity && activity.length) {
        activityList = activity.map(x => x);
        activityList.sort(GetSortOrder("date", "DESC"));

        if (noOfDays > 14 && startDate.getTime() > activityList[activityList.length - 1].date) {
            startDate = new Date(activityList[activityList.length - 1].date);
            startDate.setDate(startDate.getDate() - 1);
        }
    }
    startDate = _getDateAtMidnight(startDate);
    let iterDate = _getDateAtMidnight(new Date());

    while (iterDate.getTime() > startDate.getTime()) {
        const currentActiveItems = _getActivityForDate(activityList, iterDate);
        tempActiveItems.push(currentActiveItems);
        iterDate.setDate(iterDate.getDate() - 1);
    }

    tempActiveItems.reverse();
    return tempActiveItems;
}

export function _getActivityForDate(activityList, iterDate) {
    const currentActiveItems = { calls: [], incomingEmails: [], outgoingEmails: [], date: new Date(iterDate.getTime()) };
    if (activityList.length) {
        let activityNumber = 0;
        let activity = activityList[activityNumber];
        while (activity != undefined && activity.date >= iterDate.getTime()) {
            if (activity.date <= new Date().getTime()) {
                switch (activity.type) {
                    case "CALL":
                        currentActiveItems.calls.push(activity);
                        break;
                    case "OUTGOING_EMAIL":
                        currentActiveItems.outgoingEmails.push(activity);
                        break;
                    case "INCOMING_EMAIL":
                        currentActiveItems.incomingEmails.push(activity);
                        break;
                }
            }
            activityNumber += 1;
            activity = activityList[activityNumber];
        }
        activityList.splice(0, activityNumber);
    }
    return currentActiveItems;
}


export function _getActivityForDateDealView(activityforOneDay) {
    const dealViewActivity = { repActivity: [], customerActivity: [], date: activityforOneDay.date };
    for (let key in activityforOneDay) {
        for (let activityNum in activityforOneDay[key]) {
            let activity = activityforOneDay[key][activityNum];
            switch (key) {
                case "calls":
                    let tlRatio = activity.prospectTlRatio;
                    let repActivity = { ...activity };
                    let customerActivity = { ...activity };
                    if (tlRatio == null) {
                        repActivity.size = activity.size * 0.5;
                        customerActivity.size = activity.size * 0.5;
                    }
                    else {
                        repActivity.size = activity.size * (100 - tlRatio);
                        customerActivity.size = activity.size * tlRatio;
                    }

                    dealViewActivity.repActivity.push(repActivity);
                    dealViewActivity.customerActivity.push(customerActivity);
                    break;
                case "outgoingEmails":
                    dealViewActivity.repActivity.push(activity);
                    break;
                case "incomingEmails":
                    dealViewActivity.customerActivity.push(activity);
                    break;
            }
        }
    }
    return dealViewActivity;
}

export function _getActivityTypeScore(activity) {
    let size = _getActivitySize(activity);
    if (!size) return "None";
    if (size < SMALL_ACTIVITY_THRESHOLD) return "Low";
    if (size < MEDIUM_ACTIVITY_THRESHOLD) return "Medium";
    return "High";
}

export function _getActivitySize(activity) {
    if (!activity.length) return null;
    return activity.reduce((a, b) => { return { size: a.size + b.size } }).size
}

export function _findBarHeight(size) {
    if (size === null || size === undefined) return "0%";
    if (size === 0) return "20%";
    if (size < SMALL_ACTIVITY_THRESHOLD) return "45%";
    if (size < MEDIUM_ACTIVITY_THRESHOLD) return "66%";
    return "100%";
}

export function _hasActivityOrNot(activity) {
    if (!activity.length) return false;
    return true;
}

export function _getTooltipPlacement(divId) {
    let div = document.getElementById(divId);
    let divPosition = div ? div.getBoundingClientRect().left : 0;
    if (screen.width - divPosition < 200) return "left";
    return "right";
}