import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Button } from "app/components/form";
import { refreshUserAction } from "app/actions/auth";
import { verifyEmail } from "app/utils/network";
import EmailIcon from "app/styles/assets/images/icons/emailVerified.svg";
import { withRouter } from "react-router-dom";

const VerifyEmail = props => {
  const user = useSelector(store => store.auth.data.user);
  const [emailVerified, setEmailVerified] = useState(user != null && user.emailVerified);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    verifyToken();
  }, [])

  async function verifyToken() {
    const token = props.match.params.token;
    if (!emailVerified) {
      setLoading(true);
      const response = await verifyEmail(token);
      if (response.success) {
        setEmailVerified(true);
        props.refreshUserAction();
      } else {
        setError(response.message);
      }
      setLoading(false);
    }
  }

  function handleContinue() {
    setLoading(true);
    props.refreshUserAction();
    setTimeout(() => {
      props.history.push("/");
    }, 2000);
  }

  return (
    <div className="EmailVerificationPending">
      <img className="bigIcon" src={EmailIcon} alt="Email verified" />
      {emailVerified && <span className="bigFontBold">Congratulations!</span>}
      <span className="bigFont">
        {emailVerified
          ? "Your email has been verified."
          : error == ""
            ? "Verifying your email..."
            : "Couldn't complete email verification."}
      </span>
      <Button loading={loading} theme="dark" onClick={handleContinue} type="big">
        {"Go to My Dashboard"}
      </Button>
      <span className="footerText">
        {"Questions? Email us at "}
        <a href="mailto:support@trywingman.com" className="link">
          {"support@trywingman.com"}
        </a>
      </span>
    </div>
  );
}

const mapStateToProps = store => {
  return { ...store };
};

VerifyEmail.propTypes = {};

VerifyEmail.defaultProps = {};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(withRouter(VerifyEmail));
