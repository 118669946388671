import {
  combineReducers
} from "redux";
import call from "./call";
import player from "./player";
import auth from "./auth";
import campaign from "./campaign";
import navigate from "./navigate";
import screen from "./screen";
import gametapes from "./gametapes";
import persisted from "./persisted";
import callLog from "./callLog";
import pageData from "./pageData";
import scorecards from "./scorecards";
export default combineReducers({
  auth,
  call,
  player,
  campaign,
  navigate,
  screen,
  gametapes,
  persisted,
  callLog,
  pageData,
  scorecards
});