import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import FrontspinLogo from "app/styles/assets/images/onboarding/frontspin-logo.png";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import { sendRequest } from "../../utils/network";

const SyncFrontspin = props => {
	const [loading, setLoading] = useState(false);

	async function sync() {
		setLoading(true);
		const userId = props.auth.data.user.userId || {};
		let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
		if (window.location.port) {
			frontendUrl = `${frontendUrl}:${window.location.port}`;
		}
		const win = window.open(
			`${frontendUrl}/frontspinIntegration`,
			"Frontspin Integration",
			"width=600, height=400",
		);
		const { refreshUserAction } = props;
		const pollTimer = setInterval(async () => {
			try {
				if (win.closed) {
					clearInterval(pollTimer);
					setLoading(false);
					sendRequest("/frontspin/sync");
					refreshUserAction();
				}
			} catch (e) { }
		}, 100);
	}

	return (
		<IntegrationCard
			icon={FrontspinLogo}
			heading="Frontspin"
			subHeading="Dialer Integrations"
			bodyText="Sync Frontspin to download call recordings."
			loading={loading}
			sync={sync}
			dialer="FRONTSPIN"
			{...props}
		/>
	);
};

const mapStateToProps = store => {
	return { ...store };
};

SyncFrontspin.defaultProps = {
	access: "user",
};

SyncFrontspin.propTypes = {
	refreshUserAction: PropTypes.func.isRequired,
	access: PropTypes.string,
};

export default connect(
	mapStateToProps,
	{ refreshUserAction },
)(SyncFrontspin);
