import React, { useState, useEffect } from "react";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { IdentityProviderSyncIcon, DirectoryProviderSyncIcon } from "app/designSystem/icons";
import { TextBase, TextLarge, Button } from 'app/designSystem';
import ReactTooltip from "react-tooltip";
import { sendGetRequest, sendRequest } from "app/utils/network";
import PropTypes from "prop-types";

const SingleSignOnCard = ({ loading, heading, subHeading, body, icon, provider, handleSetupNow }) => {
    return (
        <div className="IntegrationCard rounded-lg p-2 border border-gray-200 mb-4">
            <div className="flex flex-row items-center">
                <div className="p-1 bg-gray-50 rounded flex items-center h-9">
                    {React.cloneElement(icon, { className: "h-8 w-8" })}
                </div>
                <div className="ml-2">
                    <TextLarge textColor="text-gray-900" className="font-semibold">{heading}</TextLarge>
                    <TextBase textColor="text-gray-500" className="font-semibold">{subHeading}</TextBase>
                </div>
                <div className="footer ml-auto">
                    <Button className="h-7" variant="secondary" loading={loading} onClick={handleSetupNow}>
                        {provider ? "Edit Settings" : "Setup Now"}
                    </Button>
                </div>
            </div>
            <div className="IntegrationCard__body">
                <TextBase textColor="text-gray-500">
                    {body}
                </TextBase>
            </div>
            <div className="flex">
                <div className={`rounded text-sm m-1 p-2 font-medium bg-cyan-50 !text-cyan-600 flex items-center flex-nowrap ${!provider && "hidden"}`} data-for="provider-tooltip" data-tip>
                    <span className="h-2.5 w-2.5 mr-2 bg-green-300 rounded-full" />
                    {provider}
                </div>
            </div>
            <ReactTooltip id="provider-tooltip" place="right" type="dark" effect="solid">
                <span> Active </span>
            </ReactTooltip>
        </div>
    )
}

const SingleSignOn = ({ featuresGated }) => {
    const { setSnackbar } = useSnackbar();
    const [identityProvider, setIdentityProvider] = useState(null);
    const [directoryProvider, setDirectoryProvider] = useState(false);
    const [directoryLoading, setDirectoryLoading] = useState(true);
    const [identityLoading, setIdentityLoading] = useState(true);

    useEffect(() => {
        fetchConnectionStatus();
    }, []);

    const fetchConnectionStatus = async () => {
        setDirectoryLoading(true);
        setIdentityLoading(true);
        const response = await sendGetRequest("/admin/workos/check_connection_status");
        setDirectoryLoading(false);
        setIdentityLoading(false);

        if (response.result.connection_type) {
            setIdentityProvider(response.result.connection_type);
        }

        if (response.result.directory_type) {
            setDirectoryProvider(response.result.directory_type);
        }
    }

    const handleIdentityProviderSetup = async () => {
        setIdentityLoading(true);
        const result = await sendRequest("/admin/workos/setup-customer");
        setIdentityLoading(false);

        if (result.error) {
            setSnackbar(result.message, "ERROR");
        }

        window.open(result, "_blank");
    }

    const handleDirectoryProviderSetup = async () => {
        setDirectoryLoading(true);
        const params = new URLSearchParams();
        params.append("syncDirectory", true);
        const result = await sendRequest("/admin/workos/setup-customer", params);
        setDirectoryLoading(false);

        if (result.error) {
            setSnackbar(result.message, "ERROR");
        }

        window.open(result, "_blank");
    }

    return (
        <>
            <div className="flex flex-wrap">
                {
                    featuresGated.ssoEnabled && <SingleSignOnCard loading={identityLoading} heading={"Identity Provider"} icon={<IdentityProviderSyncIcon />} body={"Setup your Identity Provider enable Single Sign On"} subHeading={"Single Sign On"} provider={identityProvider} handleSetupNow={handleIdentityProviderSetup} />
                }
                {
                    featuresGated.ssoDirectoryEnabled && <SingleSignOnCard loading={directoryLoading} heading={"Directory Provider"} icon={<DirectoryProviderSyncIcon />} body={"Setup your Directory Provider to enable Provisioning"} subHeading={"Provisioning"} provider={directoryProvider} handleSetupNow={handleDirectoryProviderSetup} />
                }
            </div>
        </>
    )
}

SingleSignOn.defaultProps = {
    featuresGated: {
        ssoEnabled: false,
        ssoDirectoryEnabled: false
    }
}

SingleSignOn.propTypes = {
    featuresGated: {
        ssoEnabled: PropTypes.bool,
        ssoDirectoryEnabled: PropTypes.bool
    }
}

export default SingleSignOn;
