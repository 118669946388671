import React, { useState } from "react";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
  root: {
    backgroundColor: "red"
  }
});

const Expansion = props => {
  const [expanded, setExpanded] = useState(props.expanded);

  function handleChange() {
    setExpanded(expanded => !expanded);
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange}
      className={"Expansion"}
      disabled={props.disabled}
    >
      <ExpansionPanelSummary
        className="Expansion__head"
        expandIcon={<ExpandMoreIcon style={{ color: "#3150D2" }} />}
      >
        <Typography className="Expansion__head-title">{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className="Expansion__content">
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Expansion.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired
};

Expansion.defaultProps = {
  children: null,
  disabled: false,
  expanded: false
};

export default withStyles(styles)(Expansion);
