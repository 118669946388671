
import React, { useEffect, useRef, useState } from "react";
import { InputBase, InputAdornment, CircularProgress, IconButton } from "@material-ui/core";
import { fetchAccountSearchResults } from "../../utils/network";
import OutsideClickHandler from "react-outside-click-handler";
import ClearIcon from "@material-ui/icons/Clear";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { metrics } from "app/utils/metrics";
import axios from 'axios';
import { getAllPhrasesForCustomer } from 'app/utils/network';
import { conversatSearchEmailData } from "../../utils/AccountViewUtils";
import AccountViewSearchSuggestions from "./AccountViewSearchSuggestions";
import { SearchV2Icon } from "app/designSystem/icons"

// Define the source for axios requests
let transcriptRequestSource = axios.CancelToken.source();
let categoryRequestSource = axios.CancelToken.source();

const AccountViewSearch = ({ loading, setLoading, callIds, emailIds, queryText, setQueryText, setSearchResult, setShowSearchResult, setTotalNumberSearchResult, getTopicMatchingForCalls }) => {
    const [searchInputIsFocused, setSearchInputIsFocused] = useState(false);
    const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
    const [allPhrases, setAllPhrases] = useState([]);

    const { getUserEmail } = useDataHelper();
    const searchInpRef = useRef();

    useEffect(() => {
        getAllPhrases();
    }, []);

    function clearQuery() {
        setQueryText("");
        setLoading(false);
        setTotalNumberSearchResult("");
        setShowSearchResult(false);
        setShowSearchSuggestions(false);
    }

    async function getAllPhrases() {
        const response = await getAllPhrasesForCustomer();
        setAllPhrases(response);
    }

    async function searchResultClickHandler(resultType) {
        setLoading(true);
        setShowSearchSuggestions(false);

        if (resultType === "transcript") {
            transcriptRequestSource?.cancel('TRANSCRIPT SEARCH QUERY CANCELLED BY USER');
            transcriptRequestSource = axios.CancelToken.source();

            metrics.logEventHeap("searchResultAccountViewSearch", { resultType: "TRANSCRIPT", queryText });

            const callsAndEmailsSearchResult = await callsAndEmailsTranscriptSearch("turns.str", queryText);

            setSearchResult({
                phrase: queryText.split(" "),
                result: getSearchResult(callsAndEmailsSearchResult),
                type: "TRANSCRIPT",
                numberOfResultsFetched: getSearchResult(callsAndEmailsSearchResult).length
            });
        } else {
            setQueryText(resultType);
            categoryRequestSource?.cancel('CATEGORY SEARCH QUERY CANCELLED BY USER');
            categoryRequestSource = axios.CancelToken.source();

            metrics.logEventHeap("searchResultAccountViewSearch", { resultType: "CATEGORY", queryText: resultType });
            const callsAndEmailsSearchResult = await callsAndEmailsCategorySearch("turns.categories", resultType);

            const callIds = callsAndEmailsSearchResult.calls.map(({ callId }) => callId);
            const matchedHighlightWordsEachCall = await getTopicMatchingForCalls(queryText, callIds);
            setSearchResult({
                phrase: getCategoryPhrasesMatched(callsAndEmailsSearchResult?.emails, queryText),
                result: getSearchResult(callsAndEmailsSearchResult),
                type: "CATEGORY",
                numberOfResultsFetched: getSearchResult(callsAndEmailsSearchResult).length,
                callHighlightPhrase: matchedHighlightWordsEachCall
            });
        }

        setLoading(false);
        setShowSearchResult(true);
    }

    function getSearchResult(callsAndEmailsSearchResult) {
        const emailListWithMatchingCategory = conversatSearchEmailData(callsAndEmailsSearchResult?.emails, getUserEmail);
        const callsListWithMatchingCategories = callsAndEmailsSearchResult?.calls;

        return [...callsListWithMatchingCategories, ...emailListWithMatchingCategory];
    }

    function getCategoryPhrasesMatched(emailResults, queryText) {
        const searchPhrases = filterPhrasesMatched(emailResults, queryText);
        const searchAnnotations = filterAnnotationsMatched(emailResults, queryText);

        return [...searchPhrases, ...searchAnnotations]
    }

    function filterPhrasesMatched(results, query) {
        const matchedKeywords = [];

        for (const result of results) {
            const { phrases_matched } = result;
            if (phrases_matched) {
                for (const { phrase, category } of phrases_matched) {
                    if (category.toLowerCase().includes(query)) {
                        matchedKeywords.push(phrase);
                    }
                }
            }
        }

        return matchedKeywords;
    }

    function filterAnnotationsMatched(results, query) {
        const matchedKeywords = [];

        let localQuery = query;

        if (query === "PRICING - AI DETECTED") {
            localQuery = "PRICING";
        } else if (query === "NEXT STEPS - AI DETECTED") {
            localQuery = "NEXTSTEPS";
        } else {
            localQuery = query;
        }

        for (const result of results) {
            const { textBody, annotations } = result;
            const filteredAnnotations = annotations.filter(({ predictionType }) => predictionType.toLowerCase().includes(localQuery.toLowerCase()));

            for (const { startIndex, endIndex } of filteredAnnotations) {
                const keyword = textBody.slice(startIndex, endIndex).trim();
                matchedKeywords.push(keyword);
            }
        }

        return matchedKeywords;
    }

    async function callsAndEmailsTranscriptSearch(fieldName, searchQuery) {
        const callsAndEmailsSearchResult = await fetchAccountSearchResults(fieldName, searchQuery, callIds, emailIds, { cancelToken: transcriptRequestSource.token })
        setTotalNumberSearchResult(callsAndEmailsSearchResult?.totalHits)
        return callsAndEmailsSearchResult;
    }

    async function callsAndEmailsCategorySearch(fieldName, searchQuery) {
        const callsAndEmailsSearchResult = await fetchAccountSearchResults(fieldName, searchQuery, callIds, emailIds, { cancelToken: categoryRequestSource.token });
        setTotalNumberSearchResult(callsAndEmailsSearchResult?.totalHits)
        return callsAndEmailsSearchResult;
    }

    async function handleChange(e) {
        const searchQuery = e.target.value;
        setQueryText(searchQuery);
        if (searchQuery?.trim() === "") {
            clearQuery();
        }

        else if (searchQuery?.trim().length < 3) {
            setShowSearchSuggestions(false);
        }

        else {
            setShowSearchSuggestions(true);
            setLoading(false);
            setShowSearchResult(false);
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setShowSearchSuggestions(false)}>
            <div className="bg-white py-4 shadow-lg px-20 transition-all duration-200 ease-in-out z-10">
                <div id="MainSearchBar" className="SearchBar">
                    <InputBase
                        inputRef={searchInpRef}
                        value={queryText}
                        className="w-full border-gray-400 h-10 border-1 rounded-lg border px-3 focus-within:!border-wingman-brand"
                        onChange={handleChange}
                        onFocus={() => setSearchInputIsFocused(true)}
                        onBlur={() => setSearchInputIsFocused(false)}
                        placeholder="Search for exact word or topic discussed in calls and emails"
                        startAdornment={
                            <InputAdornment position="start">
                                {loading && <CircularProgress size={20} color="primary" />}
                                {!loading && <SearchV2Icon className={`${searchInputIsFocused ? "text-wingman-brand" : "text-gray-400"}`} />}
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                {queryText !== "" && <IconButton size="small" onClick={clearQuery}><ClearIcon color="primary" /></IconButton>}
                            </InputAdornment>
                        }
                    />
                    {(queryText && showSearchSuggestions) &&
                        <div className="absolute w-full z-10 top-10">
                            <AccountViewSearchSuggestions isOpen={queryText && showSearchSuggestions} query={queryText} allPhrases={allPhrases} searchResultClickHandler={searchResultClickHandler} />
                        </div>
                    }
                </div>
            </div>
        </OutsideClickHandler >
    )
};

export default AccountViewSearch;
