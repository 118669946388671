import React, { useState } from "react";
import speedIcon from "app/styles/assets/images/icons/player/speed-meter.svg";

const PlaybackSpeedMenu = ({ speedProps }) => {
  const [showSpeedMenu, setShowSpeedMenu] = useState(false);
  const onChange = speedProps.onChange;
  const val = speedProps.value || 1;
  const allowedSpeeds = [2.5, 2.25, 2, 1.75, 1.5, 1.25, 1];

  function handleSpeedChange(speed) {
    onChange(speed);
    setShowSpeedMenu(false);
  }

  return (
    <div className="Player__group">
      <div onClick={() => setShowSpeedMenu(showSpeedMenu => !showSpeedMenu)} style={{ width: "80px" }} className="NewButton NewButtonTransparent">
        <img className="NewButton__startPlayerAdornment" src={speedIcon} />
        <span className="NewButton__label">{val + "x"}</span>
      </div>
      {showSpeedMenu && (
        <div className="Player__speedMenu">
          {allowedSpeeds.map(speed => {
            return (
              <div key={"Player__speedMenu__" + speed} className="Player__speedMenuRow" onClick={() => handleSpeedChange(speed)}>{speed}</div>
            )
          })}
        </div>
      )}
    </div>
  );
};

export default PlaybackSpeedMenu;
