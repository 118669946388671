import { getTimezoneOffsetString } from "app/components/dashboards/dateUtils";
import {
    sendRequest
} from "app/utils/network";

export async function getUserId(email) {
    const params = new URLSearchParams();
    params.append("email", email);
    const response = await sendRequest("/manager/getUserId", params);
    return response;
}

export async function setTimezoneOffset() {
    const timezoneOffset = getTimezoneOffsetString()
    const params = new URLSearchParams();
    params.append("timezoneOffset", timezoneOffset);
    const response = await sendRequest("/user/set_timezone_offset", params);
    return response;
}