import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import {
  Header, Input, Label, Button,
} from "app/components/form";
import { resetPassword } from "../../utils/network";
import PasswordValidator from "../auth/PasswordValidator";
import useSnackbar from "../../utils/hooks/useSnackbar";

const ResetPasswordForm = ({ open, hide }) => {
  const { setSnackbar } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [error, setError] = useState(null);

  function onFormEdit() {
    setError(null)
  }

  function validateInput() {
    if (!currentPassword || currentPassword.trim() === "") {
      setError({ type: "currentPassword", message: "Please enter your current password." })
      return false
    }
    if (!newPassword || newPassword.trim() === "" || !isValidPassword) {
      setError({ type: "newPassword", message: "Please enter a valid password." })
      return false
    }
    if (newPassword === currentPassword) {
      setError({ type: "newPassword", message: "Cannot be same as current password." })
      return false
    }
    if (!confirmNewPassword || confirmNewPassword.trim() === "" || confirmNewPassword !== newPassword) {
      setError({ type: "confirmNewPassword", message: "Passwords do not match." })
      return false
    }
    return true;
  }

  async function changePassword() {
    setLoading(true);
    if (validateInput()) {
      const response = await resetPassword(currentPassword, newPassword);
      if (response?.data?.error)
        setSnackbar(response?.data?.message, "ERROR");
      else {
        setSnackbar("Password was successfully reset.", "SUCCESS");
        hide();
      }
    }
    setLoading(false);
  }

  function handleClose() {
    hide();
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} className="Form__outerContainer">
        <div
          className="Form__container"
          onKeyUp={onFormEdit}
          style={{
            height: "auto",
            width: "657px",
          }}
        >
          <Header boldTitle="Change Password" onCancel={handleClose} />
          <div className="Form__verCon InviteUserModal__bigColumn">
            <Label>Current Password</Label>
            <Input
              type="password"
              value={currentPassword}
              warning={error?.type === "currentPassword"}
              warningMessage={error?.message}
              onChange={e => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="Form__verCon InviteUserModal__bigColumn">
            <Label>New Password</Label>
            <Input
              type="password"
              value={newPassword}
              warning={error?.type === "newPassword"}
              warningMessage={error?.message}
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>
          <div className="Form__verCon InviteUserModal__bigColumn">
            <Label>Confirm New Password</Label>
            <Input
              type="password"
              value={confirmNewPassword}
              warning={error?.type === "confirmNewPassword"}
              warningMessage={error?.message}
              onChange={e => setConfirmNewPassword(e.target.value)}
            />
          </div>
          <PasswordValidator
            password={newPassword}
            setIsValidPassword={setIsValidPassword}
          />
          <div className="Form__footerContainer">
            <div className="Form__footerContainer rightButton">
              <Button variant="outlined" onClick={handleClose}>
                {"Cancel"}
              </Button>
              <Button theme="dark" loading={loading} onClick={changePassword}>
                {"Change Password"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordForm;
