import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setScreenName } from "app/actions/screen";
import { AdhocCallJoiningForm, UploadCallRecordingForm } from "../callsDashboard";

import {
  DashboardIcon, DashboardActiveIcon,
  TeamCallsIcon, TeamCallsActiveIcon,
  MyCallsIcon, MyCallsActiveIcon,
  DealCentralIcon, DealCentralActiveIcon,
  GameTapesIcon, GameTapesActiveIcon,
  BattleCardsIcon, BattleCardsActiveIcon,
  ActivityDashboardIcon, ActivityDashboardActiveIcon,
  TextXS,
  UploadIcon,
  WingmanLogo,
  HeadphonesIcon
}
  from "app/designSystem"

import { setSidebarExpansion } from "app/actions/screen";
import { LEAD_QUALIFICATION, STACKED, GUEST } from "app/constants";
import { useHoverListener } from "../../utils/hooks/useHoverListener";

import { hasCrm } from "../../reducers/persisted";
import ReactTooltip from "react-tooltip";

import StickyToast from "app/designSystem/stickyToast";
import { ModalType } from "app/designSystem/stickyToast/ToastTypes";
import metrics from "../../utils/metrics";
import classNames from "classnames";


const tabs = {
  "dashboard": "Team Dashboard",
  "mycalls": "My Calls",
  "callsdashboard": "My Calls",
  "teamcalls": "Team Calls",
  "calllog": "Team Calls",
  "battlecards": "Battle Cards",
  "cuecards": "Battle Cards",
  "dealcentral": "Deal Central",
  "gametapes": "Game Tapes",
  "settings": "Settings",
  "activity": "Activity Dashboard"
};


class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.props.setScreenName(props.auth.isRep ? "My Calls" : "Team Calls");
    this.state = {
      activeTab: props.auth.isRep ? "myCalls" : "teamCalls",
      expanded: false,
      visible: props.screen.screenType !== STACKED,
      showCallForm: false,
      showCallUploadForm: false,
      showToast: false,
      uploadCallRecordingStatus: "IDLE",
      uploadCallRecordingProgress: 0,
      hasCrm: hasCrm(props.auth.data.user)
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);
    this.setAdhocJoiningFormVisibility = this.setAdhocJoiningFormVisibility.bind(this);
    this.goToSupportSite = this.goToSupportSite.bind(this);
    this.toggleToast = this.toggleToast.bind(this);
    this.toggleCallUploadForm = this.toggleCallUploadForm.bind(this);
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.screen.screenType !== this.props.screenType) {
      this.setState({
        visible: nextProps.screen.screenType !== STACKED,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location);
    }
  }

  setAdhocJoiningFormVisibility(visible) {
    this.setState({ showCallForm: visible })
  }

  toggleCallUploadForm() {
    if (this.state.showCallUploadForm) {
      this.toggleToast(true);
    }
    else {
      this.toggleToast(false);
    }

    this.setState({ showCallUploadForm: !this.state.showCallUploadForm })
  }

  toggleToast(visible) {
    this.setState({ showToast: visible })
  }

  onRouteChanged() {
    let pathname = window.location.pathname;
    pathname = pathname.replace(/\//g, "").toLowerCase();
    console.log("locationChanged", pathname, tabs[pathname]);
    if (tabs[pathname] || tabs[pathname] === "") {
      this.props.setScreenName(tabs[pathname]);
      this.setState({
        activeTab: pathname,
      });
    } else if (window.location.pathname === "/") {
      this.props.setScreenName(this.props.auth.isRep ? "My Calls" : "Team Calls");
      this.setState({
        activeTab: this.props.auth.isRep ? "myCalls" : "teamCalls",
      })
    } else {
      this.setState({
        activeTab: "none",
      });
    }
  }

  handleClick(screenName, tabSelected) {
    this.props.setScreenName(screenName);
    this.props.history.push(`/${tabSelected}`);
    this.setState({
      activeTab: tabSelected,
    });
  }

  goToSupportSite() {
    metrics.logEventHeap("supportSiteVisitFromSidebar");
    window.open("https://support.trywingman.com/", "_blank");
  }

  handleLogoClick() {
    this.props.setScreenName("Dashboard");
    const tabSelected = "dashboard";
    this.props.history.push(`/${tabSelected}`);
    this.setState({
      activeTab: tabSelected,
    });
  }

  render() {
    if (this.props.customerType === LEAD_QUALIFICATION) {
      return null;
    }
    if (this.props.customerType === GUEST) {
      return null;
    }
    return (
      <aside id="siderbar" className={classNames("flex-shrink-0   flex-col w-[5.625rem] items-center bg-white", { "hidden": !this.state.visible }, { "flex": this.state.visible })}>
        <div className="h-15 border-b border-coolGray-200 w-full flex items-center justify-center flex-shrink-0">
          <button>
            <WingmanLogo className="h-8 w-8" />
          </button>
        </div>
        <div className="flex flex-col items-center bg-white border-r border-coolGray-200 flex-grow pb-4 w-full">
          <SiderbarButton
            activeTab={this.state.activeTab}
            expanded={this.state.expanded}
            onClick={this.handleClick}
            image={<DashboardIcon />}
            destination="dashboard"
            activeImage={<DashboardActiveIcon />}
            label="Dashboard"
          />
          <SiderbarButton
            activeTab={this.state.activeTab}
            expanded={this.state.expanded}
            onClick={this.handleClick}
            image={<TeamCallsIcon />}
            destination="teamCalls"
            activeImage={<TeamCallsActiveIcon />}
            label="Team Calls"
          />
          {this.props.auth.isRecording && (
            <SiderbarButton
              activeTab={this.state.activeTab}
              expanded={this.state.expanded}
              onClick={this.handleClick}
              image={<MyCallsIcon />}
              destination="myCalls"
              activeImage={<MyCallsActiveIcon />}
              label="My Calls"
            />
          )}


          {(this.props.auth.data.user.stringsCustomer.featuresGated.dealView && this.state.hasCrm) && (
            <SiderbarButton
              activeTab={this.state.activeTab}
              expanded={this.state.expanded}
              onClick={this.handleClick}
              image={<DealCentralIcon />}
              destination="dealCentral"
              activeImage={<DealCentralActiveIcon />}
              label="Deal Central"
            />
          )}

          <SiderbarButton
            activeTab={this.state.activeTab}
            expanded={this.state.expanded}
            onClick={this.handleClick}
            image={<GameTapesIcon />}
            destination="gameTapes"
            activeImage={<GameTapesActiveIcon />}
            label="Game Tapes"
          />
          {this.props.auth.data.user.stringsCustomer.featuresGated.cueCards && (
            <SiderbarButton
              activeTab={this.state.activeTab}
              expanded={this.state.expanded}
              onClick={this.handleClick}
              image={<BattleCardsIcon />}
              destination="battlecards"
              activeImage={<BattleCardsActiveIcon />}
              label="Battle Cards"
            />
          )}
          <div className="w-4/6 border-coolGray-200  border-t-2 my-1">

          </div>
          {this.props.auth.data.user.stringsCustomer.featuresGated.activityDashboard && (this.props.auth.isManager || this.props.auth.isAdmin) && (
            <SiderbarButton
              activeTab={this.state.activeTab}
              expanded={this.state.expanded}
              onClick={this.handleClick}
              image={<ActivityDashboardIcon />}
              destination="activity"
              activeImage={<ActivityDashboardActiveIcon />}
              label={<>Activity <br /> Dashboard</>}
            />
          )}



          <div className="self-end flex flex-col justify-end flex-grow w-full">
            {this.props.auth.isRecording &&

              <>
                <button onClick={() => { this.setAdhocJoiningFormVisibility(true) }} className="flex flex-col items-center mb-2">
                  <div className="p-2 rounded-lg bg-wingman-brand">
                    {
                      <HeadphonesIcon />
                    }
                  </div>
                  <TextXS textColor="text-coolGray-600" className="pt-1">
                    Join my<br /> meeting
                  </TextXS>
                </button>
                <button onClick={this.toggleCallUploadForm} className="flex flex-col items-center">
                  <div className="p-2 rounded-lg bg-wingman-brand">
                    {
                      <UploadIcon />
                    }
                  </div>
                  <TextXS textColor="text-coolGray-600" className="pt-1">
                    Upload Call
                  </TextXS>
                </button>
              </>
            }


          </div>
        </div>
        {/* Dialogs and toasts ,things outside the layout ,shown only when active */}
        <UploadCallRecordingForm
          open={this.state.showCallUploadForm}
          hide={this.toggleCallUploadForm}
          userNames={this.props.screen.metaData.userNames}
          uploadCallRecordingStatus={this.state.uploadCallRecordingStatus}
          setFileUploadProgress={(progress) => this.setState({ uploadCallRecordingProgress: progress })}
          setFileUploadStatus={(status) => this.setState({ uploadCallRecordingStatus: status })}
          fileUploadProgress={this.state.uploadCallRecordingProgress}
        />
        <AdhocCallJoiningForm open={this.state.showCallForm} hide={this.setAdhocJoiningFormVisibility} />

        {this.state.showToast && (
          <>
            <StickyToast
              onClick={this.toggleCallUploadForm}
              message={'Uploading Call'}
              position={'bottom-right'}
              open={this.state.uploadCallRecordingStatus === "LOADING"}
              progress={this.state.uploadCallRecordingProgress}
              customStyle={{
                right: '100px',
              }}
              type={ModalType.LOADING}
            />

            <StickyToast
              onClick={this.toggleCallUploadForm}
              message={'Upload successful'}
              subMessage={'This call will be available after processing. Please wait for a few minutes.\n'}
              position={'bottom-right'}
              open={this.state.uploadCallRecordingStatus === "SUCCESS"}
              progress={100}
              customStyle={{
                right: '100px',
              }}
              type={ModalType.SUCCESS}
            />

            <StickyToast
              onClick={this.toggleCallUploadForm}
              message={'Upload error'}
              subMessage={'There was an error while uploading your call'}
              position={'bottom-right'}
              open={this.state.uploadCallRecordingStatus === "ERROR"}
              progress={100}
              showProgressBar={true}
              customStyle={{
                right: '100px',
              }}
              type={ModalType.ERROR}
            />
          </>
        )}

        {/* ************ */}
      </aside>
    );
  }
}





const SiderbarButton = ({ destination, label, activeTab, activeImage, image, onClick }) => {

  // const [showTooltip, setShowTooltip] = useState(false);

  // useHoverListener(
  //   `SidebarButton${label}`,
  //   () => setShowTooltip(true),
  //   () => setShowTooltip(false),
  //   500,
  // );

  function isActive() {
    if (activeTab.toLowerCase() === destination.toLowerCase()) {
      return true;
    } else if (tabs[activeTab.toLowerCase()] === label) {
      return true;
    }
    return false;
  }

  return (
    <button
      data-tip data-for={label}
      id={`SidebarButton${label}`}
      className="block"
      onClick={() => onClick(label, destination)}
    >
      {/* <ReactTooltip id={label} effect="solid" place="right">
        <span style={{ textTransform: "capitalize" }}>{label}</span>
      </ReactTooltip> */}
      <div className="flex flex-col items-center mt-1">

        <div className="p-2 pb-1 hover:bg-coolGray-50 hover:rounded-lg">
          {
            isActive() ? activeImage : image
          }
        </div>
        <TextXS textColor={(isActive() ? "text-wingman-brand" : "text-coolGray-600")} className="pb-1.5 whitespace-pre-line">
          <p>{label}</p>
        </TextXS>

      </div>
    </button>
  );
};

const mapStateToProps = store => {
  return {
    screen: store.screen,
    auth: store.auth,
  };
};

Sidebar.propTypes = {
  setScreenName: PropTypes.func.isRequired,
};

SiderbarButton.defaultProps = {
  isNew: false,
};

Sidebar.defaultProps = {};

export default connect(
  mapStateToProps,
  { setScreenName, setSidebarExpansion },
)(withRouter(Sidebar));
