import React, { useState } from "react";
import Button from "../form/Button";
import DisconnectCallModal from "app/components/call/DisconnectCallModal";
import { useSelector } from "react-redux";


export const DisconnectCall = () => {
  const callId = useSelector(store => store.call.details.callDataDocument.callId);    //used for disconnecting call
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasDisconnected, setHasDisconnected] = useState(false);


  return (
    <div>
      {!hasDisconnected && (
        <div style={{ margin: "20px 0px" }}>
          <Button bgColor="#FF1744" textColor="#FFFFFF"
            onClick={() => setDialogOpen(true)}>
            Stop Recording
          </Button>
          <DisconnectCallModal dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setHasDisconnected={setHasDisconnected} callId={callId} />
        </div>
      )}
    </div>
  );
};

DisconnectCall.propTypes = {};
export default DisconnectCall;