import React from "react";
import { Loading } from "app/components/common";
import { connect } from "react-redux";
import { setAnyUserCalls, setAnyCustomerCalls } from "app/actions/persisted";
import { anyCallsForCustomer, anyCallsForUser } from "../utils/network";
import EmptyStateTeams from "app/components/dashboards/EmptyStateTeams";
import { areAllIntegrationsDone, getFlowStatus } from "../reducers/persisted";

const withExplainer = DashboardPage => {
  class ComponentExplainer extends React.PureComponent {
    constructor(props) {
      super(props);
      const userPage = props.screen.screenName.indexOf("My") !== -1;
      this.state = {
        anyCalls: userPage ? props.persisted.anyUserCalls : props.persisted.anyCustomerCalls,
        loading: userPage ? !props.persisted.anyUserCalls : !props.persisted.anyCustomerCalls,
        userPage,
      };
    }

    async componentDidMount() {
      if (!this.state.anyCalls) {
        this.checkIfAnyCalls();
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.screen.screenName !== prevProps.screen.screenName) {
        const userPage = this.props.screen.screenName.indexOf("My") !== -1;
        const anyCalls = userPage
          ? this.props.persisted.anyUserCalls
          : this.props.persisted.anyCustomerCalls;
        this.setState({
          anyCalls,
          loading: userPage
            ? !this.props.persisted.anyUserCalls
            : !this.props.persisted.anyCustomerCalls,
          userPage,
        });
        if (!this.state.anyCalls) {
          this.checkIfAnyCalls();
        }
      }
    }

    checkIfAnyCalls = async () => {
      await this.setState({ loading: true });
      if (this.state.userPage) {
        const anyCalls = await anyCallsForUser();
        console.log("setting anyUserCalls to", anyCalls);
        this.props.setAnyUserCalls(anyCalls);
        this.setState({ anyCalls });
      } else {
        const anyCalls = await anyCallsForCustomer();
        console.log("setting anyCustomerCalls to", anyCalls);
        this.props.setAnyCustomerCalls(anyCalls);
        this.setState({ anyCalls });
      }
      await this.setState({ loading: false });
    };

    render() {
      if (this.state.loading) return <Loading loading />;
      if (!this.state.anyCalls) {
        const flowStatus = getFlowStatus(this.props.auth.data.user);
        const integrationsCompleted = areAllIntegrationsDone(flowStatus);
        return <EmptyStateTeams type="completed" integrationsCompleted={integrationsCompleted} {...this.props} />;
      }
      return <DashboardPage {...this.props} />;
    }
  }

  const mapStateToProps = store => {
    return { ...store };
  };

  const ComponentExplainerRedux = connect(
    mapStateToProps,
    { setAnyCustomerCalls, setAnyUserCalls },
  )(ComponentExplainer);

  return ComponentExplainerRedux;
};

export default withExplainer;
