import React, { useState } from "react";
import { useEffect } from "react";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useRef } from "react";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";
import { sendRequest } from "../../utils/network";
import { Button } from "app/designSystem";
import { AsyncSelectWithSearch } from ".";
import { usePrevious } from "../../utils/hooks/usePrevious";
import { Popper, ClickAwayListener } from '@material-ui/core';

const AsyncSelectWithSearchNew = ({
    data,
    value,
    disabled,
    placeholder,
    handleChange,
    menuIsOpen,
    removeFilterValue,
    fieldName,
    joinPhrase,
    loadDataLocally,
    loadData,
    indexedFieldName,
    label,
    popoverContainerRef,
    dontShowCloseIcon
}) => {
    const [showEditor, setShowEditor] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(loadDataLocally ? [] : data);
    const [valueTemp, setValueTemp] = useState(getValueTemp());
    const [showRemove, setShowRemove] = useState(false);

    const showEditorPrev = usePrevious(showEditor);

    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);
    const rootContainerRef = useRef()

    useEffect(() => {
        if (loadDataLocally) fetchData();
    }, [loadDataLocally]);

    useEffect(() => {
        setValueTemp(getValueTemp());
    }, [value])

    function getValueTemp() {
        if (!value) return [];
        const vt = value.map(v => { return { label: v, value: v } });
        console.log("values are:", vt);
        return vt;
    }

    useEffect(() => {
        if (showEditorPrev && !showEditor) {
            console.log("changing to", valueTemp, showEditor, showEditorPrev)
            handleChange(valueTemp);
        }
    }, [showEditor])

    useEffect(() => {
        if (!loadDataLocally)
            setDataLoaded(data);
    }, [data])

    async function fetchData() {
        const params = new URLSearchParams();
        params.append("fieldName", indexedFieldName);
        const values = await sendRequest("/filter/get_unique_field_values", params);
        setDataLoaded(values);
    }


    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        removeFilterValue(fieldName);
    }

    function onChange(options) {
        console.log("options:", options);
        setValueTemp(options);
    }

    function toggleShowEditor() {
        setShowEditor(showEditor => !showEditor);
    }

    return (
        <ClickAwayListener onClickAway={() => setShowEditor(false)}>
            <div className="FilterField__container" ref={rootContainerRef} >
                <div id={fieldName} ref={ref} onClick={toggleShowEditor} className="FilterField__valueContainer FilterField__valueContainerDropdown">
                    {showRemove && !dontShowCloseIcon && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
                    {(valueTemp && valueTemp.length > 0) ? <span className="FilterField__valueContainer__label">{valueTemp.map(v => { if (v) return v.label; }).join(joinPhrase)}</span> : <span className="Placeholder">{label ? label : placeholder}</span>}
                </div>
                <Popper className="FilterField__popup text-base"
                    anchorEl={rootContainerRef.current}
                    onClose={() => { setShowEditor(false) }}
                    open={showEditor}
                    placement={"bottom-start"}
                    container={popoverContainerRef?.current}
                    modifiers={{
                        flip: { enabled: false },
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: { enabled: false }
                    }
                    }>
                    <AsyncSelectWithSearch
                        data={dataLoaded}
                        loadData={loadData}
                        menuIsOpen={menuIsOpen}
                        value={valueTemp}
                        placeholder={placeholder}
                        handleChange={onChange}
                    />
                    <Button className="h-8 w-24 mx-auto" onClick={() => { setShowEditor(false) }}>Done</Button>
                </Popper>
            </div>
        </ClickAwayListener >
    );
};

AsyncSelectWithSearchNew.defaultProps = {
    menuIsOpen: true,
    async: false,
    joinPhrase: " or ",
    loadDataLocally: false,
}

export default AsyncSelectWithSearchNew;
