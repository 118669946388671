import React, { useState } from "react";
import { CloseIcon, DisconnectIcon } from "app/designSystem/icons";
import DisconnectPlugIcon from "../../styles/assets/images/icons/DisconnectPlugIcon.svg"
import { Modal } from "@material-ui/core";
import { sendRequest } from "app/utils/network";
import useSnackbar from '../../utils/hooks/useSnackbar';
import { TextBase, Button } from "app/designSystem";


export const DisconnectCallModal = ({ setHasDisconnected, setDialogOpen, dialogOpen, callId }) => {
    const [isDisconnecting, setIsDisconnecting] = useState(false);
    const { setSnackbar } = useSnackbar();

    async function disconnectUsingCallId() {
        setIsDisconnecting(true);
        const response = await sendRequest("/call/leave_call?callId=" + callId);
        if (response.success) {
            setHasDisconnected(true);
        }
        else {
            setSnackbar("Couldn't disconnect call, something went wrong.", "ERROR");
            setDialogOpen(false)
        }
    }

    return (


        <Modal
            open={dialogOpen}
            className="Disconnect__CallContainer">
            <div className="Disconnect__InfoContainer">
                {!isDisconnecting && (
                    <div >
                        <div className="Disconnect__IconContainer bg-red-600">
                            <DisconnectIcon className="Disconnect__Icon" />
                            <TextBase className="Disconnect__Info">
                                Are you sure you would like Wingman to leave the meeting and stop recording?
                            </TextBase>
                            <TextBase className="Disconnect__Note">
                                This will also disconnect the desktop app from this meeting
                            </TextBase>
                        </div>
                        <div className="Disconnect__ButtonsContainer">
                            <Button className="bg-red-600 !py-1 !px-7" variant="primary" loading={isDisconnecting}
                                onClick={disconnectUsingCallId}
                            >Stop recording</Button>
                            <Button className="bg-white !py-1.5 !px-5 mt-2" variant="secondary" onClick={() => setDialogOpen(false)}>Cancel</Button>
                        </div>
                    </div>
                )}
                {isDisconnecting && (
                    <div className="Disconnect__IntermediateContainer">
                        <div className="Disconnect__HeaderRight">
                            <CloseIcon className="Disconnect__CloseIcon" onClick={() => setDialogOpen(false)} />
                        </div>
                        <div className="Disconnect__CenterBody">
                            <img src={DisconnectPlugIcon} className="Disconnect__DisconnectPlugIcon"></img>
                            <div className="Disconnect__IntermediateInfo">Wingman will disconnect in a moment.</div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>

    );
};

DisconnectCallModal.propTypes = {};
export default DisconnectCallModal;