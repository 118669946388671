import React, { useState, useEffect } from "react";
import { TextSM } from "app/designSystem";
import classNames from "classnames";
import { useSelector } from 'react-redux';
import { isStringSubstring, isExactWordMatchingInSentence } from "app/utils/helpers"

const AccountViewSearchSuggestionsRow = ({ text, query, subHeading, handleClick }) => {
    return (
        <div className={"bg-wingman-white cursor-pointer flex flex-col justify-center py-3 pl-2 pr-8 b rounded hover:bg-gray-50"} onClick={handleClick}>
            <TextSM>
                {text} <span className="text-gray-800">{query}</span>
            </TextSM>
            {subHeading && <TextSM>
                {subHeading}
            </TextSM>}
        </div>
    );
};

const AccountViewSearchSuggestions = ({ isOpen, query, allPhrases, searchResultClickHandler }) => {
    const [availableCategoriesFromAllPhrases, setAvailableCategoriesFromAllPhrases] = useState([]);
    const [availableCategorySuggestions, setAvailableCategorySuggestions] = useState([]);

    const { categories } = useSelector(state => state.screen.metaData);

    useEffect(() => {
        setAvailableCategorySuggestions([]);

        if (allPhrases.length > 0 && query && query.trim().length > 2) {
            getMatchingCategoriesFromAllPhrases(query, allPhrases);
        }

        if (categories && categories.length > 0 && query && query.trim().length > 2) {
            getMatchingCategoriesFromAvailableCategories(query, categories);
        }

    }, [allPhrases, categories, query]);

    function getMatchingCategoriesFromAllPhrases(query, allPhrases) {
        let matchingCategories = allPhrases
            .filter(phrase => phrase.text !== "__EMPTY_CATEGORY_PLACEHOLDER__")
            .filter((phrase) => isExactWordMatchingInSentence(phrase.text, query))
            .map(phrase => phrase.category);
        setAvailableCategoriesFromAllPhrases((prevState) => Array.from(new Set([...prevState, ...matchingCategories])));
    }

    function getMatchingCategoriesFromAvailableCategories(query, categories) {
        const flattenedCategories = categories.map(({ value }) => value)
        const filteredCategories = flattenedCategories
            .filter((category) => isStringSubstring(category, query))

        setAvailableCategorySuggestions((prevState) => Array.from(new Set([...prevState, ...filteredCategories])));
    }

    if (query.trim().length === 0 && availableCategorySuggestions.length === 0) {
        return (
            <div className={classNames("w-full bg-wingman-white rounded-l transition-all duration-200 ease-in-out shadow-xl p-3 h-auto", isOpen ? "h-full opacity-100" : "h-0 opacity-0")}>
                <div className={"bg-wingman-white flex items-center py-3 pl-2 pr-8 b rounded w-full"}>
                    <TextSM className="text-center">No results found for <span className="text-gray-800">{query}</span></TextSM>
                </div>
            </div>
        );
    }

    return (
        <div className={classNames("w-full bg-wingman-white rounded-l transition-all duration-200 ease-in-out shadow-xl p-3 h-auto", isOpen ? "h-full opacity-100" : "h-0 opacity-0 hidden")}>
            {query.trim().length > 0 && <AccountViewSearchSuggestionsRow text={"Search for the exact word"} query={query} handleClick={() => searchResultClickHandler("transcript")} />}
            {availableCategorySuggestions.length > 0 &&
                availableCategorySuggestions.map((category, index) => (
                    <AccountViewSearchSuggestionsRow text={"Search for topic "} query={category} handleClick={() => searchResultClickHandler(category)} key={category + index} />
                ))
            }
            {availableCategoriesFromAllPhrases.length > 0 &&
                availableCategoriesFromAllPhrases.map((category, index) => (
                    <AccountViewSearchSuggestionsRow text={"Search for topic "} query={category} subHeading={`Found ${query} as a keyword in this topic`} handleClick={() => searchResultClickHandler(category)} key={category + index} />
                ))
            }
        </div>
    );
};

export default AccountViewSearchSuggestions;