import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import ZoomLogo from "app/styles/assets/images/onboarding/zoomLogo.svg";
import { ZOOM_CONFIG } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncZoomPhone = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync(code) {
    await crmOAuthCallback(code, 'ZOOM');
    setLoading(false);
    props.refreshUserAction();
  }
  function sync() {
    setLoading(true);
    let url = `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CONFIG.clientId}&redirect_uri=${ZOOM_CONFIG.redirectUri}`;
    openWindowAndListenForParam(url, "Zoom Phone Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={ZoomLogo}
      iconClass="zoom_icon"
      heading="ZoomPhone"
      subHeading="Dialer Integrations"
      bodyText="Sync Zoom Phone to link calls to wingman."
      sync={sync}
      loading={loading}
      dialer="ZOOM_PHONE"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncZoomPhone.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

SyncZoomPhone.defaultProps = {
  access: "user",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncZoomPhone);
