import moment from "moment";
import {
  CALL_CARD_DATE_FORMAT,
  CALL_CARD_DATETIME_FORMAT,
  DD_MMM_DATE_FORMAT,
  DATE_MONTH_FORMAT, TIME_DATE_FORMAT, MANAGER, DEAL_ACTIVITY_DATE_FORMAT, ACTIVITY_DATE_FORMAT, DEAL_COLUMNS, WARNINGS
} from "app/constants"
import { func } from "prop-types";
import { cloneDeep, escapeRegExp } from "lodash";
import { GetSortOrder } from "../components/dashboards/helpers";
import IncomingMailIcon from "app/styles/assets/images/icons/dealView/IncomingMailIcon.svg";
import OutgoingMailIcon from "app/styles/assets/images/icons/dealView/OutgoingMailIcon.svg";
import CallsIcon from "app/styles/assets/images/icons/dealView/CallsIcon.svg";
import { TextBase } from "app/designSystem"
import UserIcon from "@material-ui/icons/Person";
import useScorecardsPermissionHelper from 'app/utils/hooks/useScorecardsPermissionHelper';
import { useSelector } from "react-redux";

export const urlRegexPattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
export const emailRegexPattern = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$/;
export const lowerCaseRegexPattern = /[a-z]/;
export const upperCaseRegexPattern = /[A-Z]/;
export const digitRegexPattern = /[0-9]/;
export const alphaNumericRegexPattern = /^[a-z0-9\s]+$/i;



// ex: _roundUp(192.168, 10) => 192.2
export function _roundUp(num, precision) {
  if (!num) return 0;
  return Math.round(num * precision) / precision;
}



export function _getMinutesFromTime(seconds) {
  if (!seconds) return "0";
  let min = _roundUp((seconds / 60), 10);
  return min;
}
// ex: _formatSecondToTime(77) => 00:01:17
export function _formatSecondsToTime(seconds) {
  if (!seconds) return "00:00";
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  let sec = Math.floor((seconds % 3600) % 60);

  // hour, min, sec always have 2 digits
  hour = hour < 10 ? `0${hour}` : hour;
  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;

  if (hour === "00") return `${min}:${sec}`;
  return `${hour}:${min}:${sec}`;
}

export function copyStringToClipboard(str) {
  // Create new element
  const el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = { position: "absolute", left: "-9999px" };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
}

export function _formatTalkListenRatio(talkListenRatio, separator = "") {
  let tlRatio = _roundUp(talkListenRatio * 100, 1);
  if (tlRatio > 100) tlRatio = 100;
  return tlRatio + separator + "%";
}

// ex: _formatSecondToTime(3667) => 61:07
export function _formatSecondsToPlayingTime(seconds) {
  if (!seconds) return "00:00";
  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);

  // hour, min, sec always have 2 digits
  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;

  return `${min}:${sec}`;
}

export function _formatSecondsToMinutes(seconds, suffix = " M") {
  if (seconds === 0) return "0" + suffix;
  if (!seconds) return null;
  let min = Math.ceil(seconds / 60);
  return `${min}${suffix}`;
}

export function _formatSecondsToPrettyTime(seconds) {
  if (!seconds) return "00:00";
  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds % 60);
  sec = sec < 10 ? `0${sec}` : sec;

  return `${min}:${sec}`;
}

export function normalizeArray(array) {
  let max = -1
  let nf = []
  array.map((item) => {
    if (item > max) {
      max = item
    }
  })
  array.map((item) => {
    let value = (item) * (100) / (max)
    if (isNaN(value)) {
      value = 0
    }
    nf.push(_roundUp(value, 100))
  })
  return nf
}

export function _formatDateToCallCardDateFormat(date) {
  return moment(date).format(CALL_CARD_DATE_FORMAT)
}

export function _formatDateToDealActivityDateFormat(date) {
  return moment(date).format(DEAL_ACTIVITY_DATE_FORMAT)
}

export function _formatDateToActivityDateFormat(date) {
  return moment(date).format(ACTIVITY_DATE_FORMAT)
}

export function _trimAndEllipsize(str, length = 100) {
  if (!str || !str.slice) return str;
  return str.length > length ? (str.slice(0, length) + "...") : str;
}

export function _formatDate(date) {
  return moment(date).format(TIME_DATE_FORMAT);
}

export function _formatDateToDDMMMDateFormat(date) {
  return moment(date).format(DD_MMM_DATE_FORMAT)
}

export function _formatDateToDateMonthFormat(date) {
  return moment(date).format(DATE_MONTH_FORMAT)
}

export function _getDateAtMidnight(date) {
  date.setHours(0, 0, 0, 0);
  return date;
}

export function getHoursOrMinsOrSecs(seconds) {
  var mins = seconds / 60;
  if (mins <= 1) {
    return seconds + " secs";
  } else if (mins <= 60) {
    return mins + " mins";
  } else
    return (mins / 60) + " hours";
}

export function numToCurrencyFormatter(num, currency = "$") {
  if (!num) return "--";
  else if (num > 999 && num < 1000000) {
    return currency + (num / 1000).toFixed(0) + 'K';
  } else if (num > 1000000) {
    return currency + (num / 1000000).toFixed(1) + 'M';
  } else if (num <= 999) {
    return currency + num;
  }
  return "--";
}

export const formatOptionLabelForUserDropdowns = ({ imageUrl, label }) => (
  <div className="flex flex-row items-center" >
    <div className="flex justify-center items-center h-8 w-8 mr-3 min-w-[32px] min-h-[32px]" >
      {imageUrl ?
        <img className="h-full w-full rounded-full" src={imageUrl} /> :
        <UserIcon style={{ fontSize: "32px" }} className="bg-blue-800 p-0.5 rounded-full" color="secondary" />}
    </div>
    <TextBase textColor="text-gray-900">{label}</TextBase>
  </div >
);

export function numToCurrencyInUSDFormat(num, currency = "USD") {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  return formatter.format(num);
}

export function _formatDateToMinsBefore(date) {
  const diff = new Date().getTime() - moment(date).toDate().getTime();
  const mins = diff / 60000;
  if (_roundUp(mins, 1) < 60) return getMinsBeforeString(mins, "min");
  const hours = mins / 60;
  if (_roundUp(hours, 1) < 24) return getMinsBeforeString(hours, "hour");
  const days = hours / 24;
  if (_roundUp(days, 1) < 30) return getMinsBeforeString(days, "day");
  const months = days / 30;
  return getMinsBeforeString(months, "month");
}

export function getDiffInDays(date1, date2) {
  const diff = moment(date1).toDate().getTime() - moment(date2).toDate().getTime();
  return diff / (1000 * 3600 * 24);
}

export function _formatDateToDaysRelative(date) {
  const diff = new Date().getTime() - moment(date).toDate().getTime();
  const diffAbs = Math.abs(diff);
  const mins = diffAbs / 60000;
  if (_roundUp(mins, 1) < 60) return getTimeRelativeString(mins, "min", diff < 0);
  const hours = mins / 60;
  if (_roundUp(hours, 1) < 24) return getTimeRelativeString(hours, "hour", diff < 0);
  const days = hours / 24;
  if (_roundUp(days, 1) < 30) return getTimeRelativeString(days, "day", diff < 0);
  const months = days / 30;
  return getTimeRelativeString(months, "month", diff < 0);
}

export function getNumFromString(value) {
  if (!value) return null;
  const valueStr = value.toString();
  return valueStr.replace(/[^0-9\.]+/g, "");
}

function getTimeRelativeString(num, unit, isFuture) {
  const roundedUp = _roundUp(num, 1);
  if (isFuture)
    return "in " + roundedUp + " " + unit + (roundedUp > 1 ? "s" : "");
  else
    return roundedUp + " " + unit + (roundedUp > 1 ? "s" : "") + " ago";
}

function getMinsBeforeString(num, unit) {
  const roundedUp = _roundUp(num, 1);
  return roundedUp + " " + unit + (roundedUp > 1 ? "s" : "") + " ago";

}

export function _formatDateToCallCardDateTimeFormat(date) {
  return moment(date).format(CALL_CARD_DATETIME_FORMAT)
}

export function getShortHands(callDate) {
  let today = moment().format(CALL_CARD_DATE_FORMAT)
  let yesterday = moment().subtract(1, 'd').format(CALL_CARD_DATE_FORMAT)
  let tomorrow = moment().add(1, 'd').format(CALL_CARD_DATE_FORMAT)
  switch (callDate) {
    case today:
      return "TODAY"
    case tomorrow:
      return "TOMORROW"
    case yesterday:
      return "YESTERDAY"
    default:
      return callDate
  }
}

export function getGroupName(date, duration) {
  switch (duration) {
    case "day":
      return moment(date).format('hh A') + " - " + moment(date).add(1, "hours").format('hh A')
    case "week":
      return moment(date).format('dddd')
    case "month":
      return "Week " + Math.ceil(new Date(date).getDate() / 7) + ", " + moment(date).format('MMM')
    case "quarter":
      return "Week " + Math.ceil(new Date(date).getDate() / 7) + ", " + moment(date).format('MMM')
    case "year":
      return moment(date).format('MMM')
  }
}

export function getGroupId(date, duration) {
  switch (duration) {
    case "day":
      return new Date(date).getHours()
    case "week":
      return new Date(date).getDay()
    case "month":
      return new Date(date).getDate()
    case "quarter":
      return new Date(date).getMonth()
    case "year":
      return new Date(date).getMonth()
  }
}

export function getCommentsAccessibleByUser(comments, userId) {
  if (!comments) return [];
  return comments.filter(comment => {
    if (comment.access && comment.access === "PRIVATE") {
      let userIds = comment.taggedUsersIds || [];
      if (comment.replies) {
        comment.replies.map(reply => {
          if (reply.taggedUsersIds) {
            userIds = userIds.concat(reply.taggedUsersIds);
          }
        })
      }
      if (!userIds.includes(userId) && comment.userId !== userId)
        return false;
    }
    return true;
  });
}


export function getDateDiff(date1, date2) {
  console.log(Math.abs(new Date(date1) - new Date(date2)) / (24 * 60 * 60 * 1000));
  var diffDays = Math.abs(new Date(date1) - new Date(date2)) / (24 * 60 * 60 * 1000);
  if (diffDays === 1) return "day";
  if (diffDays === 7) return "week";
  if (diffDays >= 28 && diffDays <= 31) return "month";
  if (diffDays >= 80 && diffDays <= 95) return "month";
  if (diffDays >= 360 && diffDays <= 367) return "year";
}

export function getDateFromDuration(duration) {
  let start = moment().toDate()
  if (duration == 'day') {
    start = moment().subtract(1, 'days').toDate()
  } else if (duration == "week") {
    start = moment().subtract(7, 'days').toDate()
  } else if (duration == "month") {
    start = moment().subtract(1, 'months').toDate()
  } else if (duration == "quarter") {
    start = moment().subtract(3, 'months').toDate()
  } else if (duration == "year") {
    start = moment().subtract(1, 'years').toDate()
  }
  return start
}

export function isElementInViewport(el, parentId) {
  try {
    if (!el || !document.getElementById(parentId)) return { isInViewPort: false };
    var rect = el.getBoundingClientRect();
    var parentRect = document.getElementById(parentId).getBoundingClientRect();

    return {
      isInViewPort: rect.bottom < parentRect.bottom &&
        rect.right < parentRect.right &&
        rect.left > parentRect.left /* or $(window).width() */ &&
        rect.top > parentRect.top /* or $(window).height() */,
      relativePosition: (rect.top - parentRect.top) / (parentRect.bottom - parentRect.top),
      distanceFromEdge: parentRect.bottom - rect.bottom,
    }
  } catch (error) {
    console.log('error calculatiing is Element in viewport', error);
    return {
      isInViewPort: false,
      relativePosition: 1,
      distanceFromEdge: 20,
    }
  }
}

export function getMatchingCategoryHighlights(highlightsData, option) {
  return highlightsData.highlightGroups
    .filter(hg => {
      if (option.keyword) {
        if (hg.groupName.toLowerCase().includes(option.keyword.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    })
}

export function getMatchingCategoryHighlightsForMultiple(highlightsData, option) {
  return highlightsData.highlightGroups
    .filter(hg => {
      if (option) {
        if (hg.groupName.toLowerCase().includes(option.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    })
}

export function flattenHighlights(highlightsDataParam, excludeKeywords = null, keyword = null) {
  const highlightsLocal = [];
  const highlightsData = cloneDeep(highlightsDataParam);
  if (!highlightsData || !highlightsData.highlightGroups || highlightsData.highlightGroups.length === 0) return highlightsLocal;
  highlightsData.highlightGroups
    .filter(hg => {
      if (excludeKeywords && excludeKeywords.length > 0) {
        let keywordMatch = false;
        if (excludeKeywords.map(k => {
          if (hg.groupName.toLowerCase().includes(k)) {
            keywordMatch = true;
          }
        }));
        if (keywordMatch) {
          return false;
        }
      }
      if (keyword) {
        if (hg.groupName.toLowerCase().includes(keyword)) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    })
    .map(hg => {
      const hglts = hg.highlights.map(h => { h.groupName = hg.groupName; return h; });
      highlightsLocal.push(...hglts);
    });
  highlightsLocal.sort(GetSortOrder("startTime", "ASC"));
  return highlightsLocal;
}

export function flattenAllHighlights(highlightsDataParam) {
  const highlightsLocal = [];
  const highlightsData = cloneDeep(highlightsDataParam);

  if (!highlightsData || highlightsData.length === 0) return highlightsLocal;

  highlightsData.map(hg => {
    const hglts = hg.highlights.map(h => { h.groupName = hg.groupName; return h; });
    highlightsLocal.push(...hglts)
  });

  highlightsLocal.sort(GetSortOrder("startTime", "ASC"));
  return highlightsLocal;
}

export function filterTurns(turns, startTime, endTime) {
  turns = turns.filter(
    turn =>
    (!(
      (
        startTime > turn.startTime
        && startTime > turn.endTime
        && endTime > turn.startTime
        && endTime > turn.endTime
      )
      || (
        startTime < turn.startTime
        && startTime < turn.endTime
        && endTime < turn.startTime
        && endTime < turn.endTime
      )
    ))
  );
  return turns.map(t => {
    t.startTime = t.startTime - startTime;
    t.endTime = t.endTime - startTime;
    if (t.startTime < 0) t.startTime = 0;
    if (t.endTime > (endTime - startTime)) t.endTime = endTime - startTime;
    return t;
  });
}

export function getMatchingTranscriptHighlights(turns, option, exactMatch = false) {
  return turns.map(t => {
    let keywords = [];
    if (exactMatch) keywords.push(option.keyword.toLowerCase());
    else keywords = option.keyword.toLowerCase().split(" ");
    if (allWordsInSentence(t.turnStr, keywords, exactMatch)) {
      let firstWordMatch = 0;
      let words = cloneDeep(t.words);

      //calc words if not available
      if (!t.words || t.words.length === 0) {
        words = t.turnStr.split(" ").map(word => {
          return { str: word, startMs: t.startTime * 1000 }
        });
      }

      let startTime = t.startTime;
      if (exactMatch) {
        const stringLen = keywords[0].split(" ").length;
        const index = t.turnStr.toLowerCase().match(new RegExp("\\b" + escapeRegExp(keywords[0].toLowerCase()) + "\\b"))['index'];
        if (!index) return null;
        firstWordMatch = t.turnStr.substring(0, index).split(" ").length - 1;
        for (let i = 0; i < stringLen; i++) {
          words[firstWordMatch + i].bold = true;
        }
      }
      else {
        words = words.map((word, i) => {
          if (anyWordInSentence(word.str, keywords, exactMatch)) {
            if (firstWordMatch == 0) firstWordMatch = i;
            word.bold = true;
          }
          return word;
        })
      }
      firstWordMatch = firstWordMatch > 10 ? firstWordMatch - 10 : firstWordMatch;
      if (words[firstWordMatch]) {
        startTime = words[firstWordMatch].startMs / 1000;
        return ({
          str: t.turnStr,
          startTime: startTime,
          turnId: t.turnId,
          words: words.slice(firstWordMatch, firstWordMatch + 20),
        });
      }
    } else {
      return null;
    }
  }).filter(h => h);
}

export function getTaggedUsersNum(comment) {
  return (comment.match(/@([^)]*)/g) || []).length;
}

export function getContactName(contact) {
  if (!contact) return "";
  var name = "";
  if (contact.firstName && contact.firstName !== "null") {
    name += contact.firstName + " ";
  }
  if (contact.lastName && contact.lastName !== "null") {
    name += contact.lastName;
  }
  if (name.trim() === "" && contact.email) return contact.email;
  return name;
}

export function anyWordInSentence(sentence, words, exactMatch = false) {
  let findMatch = null;
  if (exactMatch)
    findMatch = words.find(word => sentence.toLowerCase().match(new RegExp("\\b" + escapeRegExp(word.toLowerCase()) + "\\b")));
  else
    findMatch = words.find(word => sentence.toLowerCase().includes(word.toLowerCase()));
  console.log("anyWordInSentence", sentence, words, exactMatch, findMatch);

  if (findMatch) return true;
  return false;
}

export function allWordsInSentence(sentence, words, exactMatch = false) {
  let matches = [];
  if (exactMatch)
    matches = words.map(word => sentence.toLowerCase().match(new RegExp("\\b" + escapeRegExp(word.toLowerCase()) + "\\b"))).filter(m => m);
  else
    matches = words.map(word => sentence.toLowerCase().includes(word.toLowerCase())).filter(m => m);
  console.log("allWordsInSentence", sentence, words, exactMatch, matches);
  if (matches && matches.length === words.length)
    return true
  return false;
}

export function isAttendeeOfCall(call, user) {
  let isAttendee = false;
  call.users.map((callAttendee => {
    if (user.userId === callAttendee.userId) {
      isAttendee = true;
    }
  }))
  return isAttendee;
}

export function doesUserHaveAccess(acceptedRole, user, call) {
  if (!user || !user.roleInfo || !user.roleInfo.role)
    return false;

  switch (acceptedRole) {
    case "ALL": return true;
    case "NO_ONE": return false;
    case "MANAGERS": return user.roleInfo.role === MANAGER;
    case "ADMINS": return user.roleInfo.admin;
    case "ADMINS_AND_MANAGERS": return (user.roleInfo.role === MANAGER || user.roleInfo.admin);
    case "ADMINS_AND_PARTICIPANTS": return (isAttendeeOfCall(call, user) || user.roleInfo.admin);
  }
}








export function _getActivityTypes(type = 'accountView') {
  if (type == 'dealView') return {
    customerActivity: { label: "Customer Activity" },
    repActivity: { label: "Rep Activity" }
  }

  return {
    incomingEmails: { label: "Incoming Emails", icon: IncomingMailIcon },
    calls: { label: "Calls", icon: CallsIcon },
    outgoingEmails: { label: "Outgoing Emails", icon: OutgoingMailIcon }
  }
}

export async function handleViewInCrm(crmLinks, account, deal = null) {
  if (crmLinks) {
    if (account?.dummyForDealCrmId && crmLinks["OPPORTUNITY"]) {
      window.open(crmLinks["OPPORTUNITY"].replace("__ID__", account?.dummyForDealCrmId), "_blank")
    } else if (crmLinks["OPPORTUNITY"] && deal?.crmId) {
      window.open(crmLinks["OPPORTUNITY"].replace("__ID__", deal?.crmId), "_blank")
    } else if (crmLinks["ACCOUNT"] && account?.crmId) {
      window.open(crmLinks["ACCOUNT"].replace("__ID__", account?.crmId), "_blank")
    }
  }
}

export function checkIfExternal(attendee, customerDomains) {
  if (attendee == null) {
    return false;
  }
  if (
    attendee.email != null &&
    !domainMatched(attendee.email, customerDomains)
  ) {
    return true;
  }
  return false;
}

export function checkIfExternalFromEmail(email, customerDomains) {

  if (
    email != null &&
    !domainMatched(email, customerDomains)
  ) {
    return true;
  }
  return false;
}

export function checkIfContactInLinkedDomains(email, contactDomains) {
  if (email != null && domainMatched(email, contactDomains)) return true;
  return false;
}

export function getDomainFromEmail(email) {
  if (!email) return null;
  return email.split("@").length >= 1 ? email.split("@")[1] : null;
}

export function getDisplayValue(attendee) {
  let displayValue = null;
  if (attendee.email !== null) {
    displayValue = attendee.email;
  } else if (attendee.phoneNumber !== null) {
    displayValue = attendee.phoneNumber;
  } else if (attendee.name !== null) {
    displayValue = attendee.name;
  }
  return displayValue;
}
export function getExternalGuy(attendees, customerDomains) {
  if (attendees == null) {
    return "";
  }
  for (const attendee of attendees) {
    if (
      attendee.email != null &&
      !domainMatched(attendee.email, customerDomains)
    ) {
      return attendee.email;
    }
  }
  const lastGuy = attendees[attendees.length - 1];
  if (lastGuy && lastGuy.email != null) {
    return lastGuy.email;
  } else if (lastGuy && lastGuy.phoneNumber != null) {
    return lastGuy.phoneNumber;
  }
  return "";
}

export function getExternalAttendee(attendees, customerDomains) {
  if (attendees == null) {
    return {};
  }
  for (const attendee of attendees) {
    if (
      attendee.email != null &&
      !domainMatched(attendee.email, customerDomains)
    ) {
      attendee.isExternal = true;
      return attendee;
    }
  }
  const lastGuy = attendees[attendees.length - 1];
  return lastGuy;
}

export function domainMatched(email, listOfDomains) {
  if (!email) return false;
  for (const domain of listOfDomains) {
    if (email.indexOf(domain) !== -1) {
      return true;
    }
  }
  return false;
}

export function _formatDaysToDealSettingsDays(days) {
  if (days < 7) return days + " Days";
  else return (days / 7) + (days == 7 ? " Week" : " Weeks");
}

export function _getActiveWarnings(allWarnings, enabledWarnings) {
  if (allWarnings && enabledWarnings)
    return enabledWarnings.filter(warning => allWarnings.indexOf(warning.name) != -1 && warning.enabled == true)
  return [];
}

export function _getActiveColumns(dealBoardColumns) {
  if (!dealBoardColumns?.length) return DEAL_COLUMNS;
  let dealColumnsLocal = cloneDeep(dealBoardColumns);
  if (dealColumnsLocal[0].id != 'account_name')
    dealColumnsLocal.push(DEAL_COLUMNS[0], DEAL_COLUMNS[1]);
  else {
    dealColumnsLocal[0].columnClass = "DealCard__nameHeader"
    dealColumnsLocal[1].columnClass = "DealCard__activityHeader"
  }
  dealColumnsLocal.sort(GetSortOrder("position", 'asc'));
  return dealColumnsLocal;
}

export function _getWarningSettings(dealBoardWarnings) {
  let dealWarningsLocal = WARNINGS;
  if (dealBoardWarnings != null && dealBoardWarnings.length) {
    dealBoardWarnings.forEach(warning => {
      let warningByName = dealWarningsLocal.find(el => el.name === warning.name);
      if (warningByName) {
        warningByName.settings.enabled = warning.enabled;
        warningByName.settings.threshold = warning.threshold;
        if (warning.name === "DECISION_MAKER_NOT_INVOLVED")
          warningByName.settings.decisionMakerTitleRequired = _getDecisionMakerLabelFromValue(warning.decisionMakerTitleRequired);
      }
    })
  }
  return dealWarningsLocal;
}

export function _getDecisionMakerValueFromLabel(label) {
  if (!label) return null;
  switch (label) {
    case "C-level": return "CEO";
    case "VP-level": return "VP";
    case "Director-level": return "Director";
    case "Manager-level": return "Manager";
  }
}

export function _getDecisionMakerLabelFromValue(value) {
  if (!value) return null;
  switch (value) {
    case "CEO": return "C-level";
    case "VP": return "VP-level";
    case "Director": return "Director-level";
    case "Manager": return "Manager-level";
  }
}

export function _isCustomField(fieldId) {
  let field = DEAL_COLUMNS.find(el => el.id === fieldId);
  if (["probability", "forecastCategory", "hs_manual_forecast_category", "pipeline", "custom_pipeline"].includes(fieldId)) return false;
  if (!field) return true;
  return false;
}

export function isAnythingSelected(onFail = null) {
  try {
    if (window.getSelection) {
      var selection = window.getSelection();
      if (selection.toString() !== "" && selection.toString().trim() !== "") {
        return true;
      }
    }
    return false;
  } catch (error) {
    if (onFail !== null) onFail("error checking anything selected");
    console.log("Error checking is anything is selected", error);
  }
}

export function sortByLabel(a, b) {
  return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);

}

export function validateStringsOfEmails(stringsOfEmails) {
  if (!stringsOfEmails || stringsOfEmails.length == 0) return true;

  const emails = stringsOfEmails.split(",");

  if (emails.length > 1) {
    for (const email of emails) {
      if (!isWordMatchingThePattern(email, emailRegexPattern)) {
        return false;
      }
    }

    return true;
  }

  return isWordMatchingThePattern(stringsOfEmails, emailRegexPattern);
}

export function cleanCommaSeperatedStrings(commaSeperatedString) {

  if (!commaSeperatedString) {
    return "";
  }

  const listOfStrings = commaSeperatedString.split(",");

  if (listOfStrings.length > 1) {
    return listOfStrings.map(string => string.trim()).filter(string => string !== "").join(",");
  }

  return commaSeperatedString.trim();
}

export function isWordMatchingThePattern(word, pattern) {
  return pattern.test(word);
}

export function getClientOS() {
  return window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";
}

export function isWordEmptyOrNull(word) {
  if (word === null || word.trim() === "")
    return true;
  return false;
}

export function requestFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  }
  else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  }
  else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  } else {
    throw new Error("Fullscreen API is not supported");
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    throw new Error("Fullscreen API is not supported");
  }
}

// when running on laptops in localhost ,REACT_APP_NODE_ENV is either unset or set to "local" from .env.local file (not checked into git)
export function isLocal() {
  return (!process.env.REACT_APP_NODE_ENV || process.env.REACT_APP_NODE_ENV === "local")
}
export function getSplitedSubstringOfTurn(wholeTurn = "", initalIndex, endIndex) {
  if (initalIndex !== endIndex) {
    const partOfString = wholeTurn.substring(initalIndex, endIndex);
    if (partOfString.trim() === "" || partOfString.trim() === ".") return [""];
    return getAllSentenceFromConversationTurn(partOfString);
  }
  return [""];
}

export function getAllSentenceFromConversationTurn(conversation) {
  const eachSentence = conversation.split(/[.]+/g).filter(eactText => eactText.trim().length > 0) || [];
  return eachSentence;
}

export function splitTurnWithMatchingWord(wholeTurn, matchedWordArray) {
  let indexes = [];
  for (let matchWord of matchedWordArray) {
    let i = -1;
    matchWord = ` ${matchWord} `
    while ((i = wholeTurn.indexOf(matchWord, i + 1)) != -1) {
      indexes.push({ firstIndex: i, lastIndex: i + matchWord.length });
    }
  }
  return indexes;
}


export default {
  _formatSecondsToTime,
  _roundUp,
};

export function isStringsEqual(string1, string2) {
  let string1Formatted = string1.toLowerCase().trim();
  let string2Formatted = string2.toLowerCase().trim();
  return string1Formatted === string2Formatted;
}

export function isStringSubstring(string1, string2) {
  let string1Formatted = string1.toLowerCase().trim();
  let string2Formatted = string2.toLowerCase().trim();
  return string1Formatted.includes(string2Formatted);
}

export function isExactWordMatchingInSentence(string1, string2) {
  let string1Formatted = string1.toLowerCase().trim();
  let string2Formatted = string2.toLowerCase().trim();
  return string1Formatted.split(" ").includes(string2Formatted);
}

export function capitalizeFirstLetter(string = "") {
  string = string.toLowerCase();
  const capFirstLetterString = string.charAt(0).toUpperCase() + string.slice(1);
  return capFirstLetterString;
}