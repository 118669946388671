import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextSM, TextBase, TextLarge, Button, PlusIcon } from 'app/designSystem'
import useDataHelper from '../utils/hooks/useDataHelper';
import useScorecardHelper from './helper';
import ScoringScale from './ScoringScale';
import { cloneDeep } from 'lodash';
import { sendRequest } from '../utils/network';
import useSnackbar from '../utils/hooks/useSnackbar';
import Select, { components } from "react-select";
import ScorecardSkeleton from './ScorecardSkeleton.jsx'
import CreateTemplateModal from './CreateTemplateModal';
import { formatOptionLabel as FormatOptionLabel, customStyles, customTheme } from './helper'//Temp, will be moved to Design system when select box wrapper over react-select built for new design
import { getSkills } from '../actions/scorecards';
import { fetchScoringTemplates, fetchScorecardsForcall } from 'app/utils/network'
import { metrics } from "app/utils/metrics";
import ScorecardEmptyState from './ScorecardEmptyState';
import MultipleScoring from './MultipleScoring';
import { setScorecardCount } from "app/actions/call";
import { ViewType } from 'app/constants';


export default function ScoringContainer({ close, showScoringPopover, setShowNavigationArrows, setCurrentScorecardIndex, setScorecardsCount, currentScorecardIndex, setHeadingText, viewType, setViewType, unsavedScorecard, setUnsavedScorecard }) {
    const dispatch = useDispatch()
    const currentUserId = useSelector(store => store.auth.userId);
    const scorecardCount = useSelector(store => store.call.details?.callDataDocument?.totalScorecards);
    const { getNewScoreCard, updateMetaDataForFilters } = useScorecardHelper()
    const handleTemplateCreated = (newTemplate, isEdit) => {
        //edited template , replace template object here ,created new , append to end of template list 
        //easier to reload list but not ideal experience,anyways creation flow is changing soon 
        setShowCreateTemplateModal(false)

        if (isEdit) {
            let allTemplates = cloneDeep(scoringTemplates)//mutate edited template object in list of scoring templates
            let editedTemplateIndex = allTemplates.findIndex(template => template.id === newTemplate.id)
            let editedTemplate = { ...newTemplate, value: newTemplate.id, label: newTemplate.name }
            allTemplates.splice(editedTemplateIndex, 1, editedTemplate)
            setScoringTemplates(allTemplates)
            setSelectedTemplate(editedTemplate)
        }
        else {
            const createdTemplate = { ...newTemplate, value: newTemplate.id, label: newTemplate.name }
            setScoringTemplates([...scoringTemplates, createdTemplate])
            setSelectedTemplate(createdTemplate)
        }
        updateMetaDataForFilters()
        dispatch(getSkills())
    }
    const SelectMenuButton = (props) => {
        const addTemplateBtnClicked = () => {
            setTemplateToEdit(null)
            setShowCreateTemplateModal(true)
        }
        return (
            <>
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>
                {canCreateTemplate &&
                    <div className="flex flex-col items-center justify-center p-3">
                        <hr className="w-full" />
                        <Button
                            className="w-full h-10 mt-2"
                            variant="secondary"
                            onClick={addTemplateBtnClicked}
                            id="adoption-tracking-open-add-template"

                        >
                            <>
                                <PlusIcon className='text-current' />
                                Add template
                            </>
                        </Button>

                    </div>
                }
            </>
        )
    }

    const { setSnackbar } = useSnackbar();

    const { getUserName, primaryRepId } = useDataHelper();

    const isAdmin = useSelector(state => state?.auth?.isAdmin)
    const isManager = useSelector(state => state?.auth?.isManager)
    const canCreateTemplate = isManager || isAdmin
    const callId = useSelector(state => state?.call?.callId)
    const meetingAttendees = useSelector(state => state?.call?.details?.callDataDocument?.meetingInfo?.meetingAttendees || [])
    const [repSelectOptions, setRepSelectOptions] = useState([])

    const [selectedRep, setSelectedRep] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [scoreCard, setScoreCard] = useState(null)
    const [isValidScoreCard, setIsValidScorecard] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false)
    const [scoringTemplates, setScoringTemplates] = useState([])
    const showEmptyState = (scoringTemplates.length === 0)
    const [alreadyScoredcards, setAlreadyScoredcards] = useState([])
    const [loading, setLoading] = useState(false)

    const [templateToEdit, setTemplateToEdit] = useState(null)

    //always load templates and scorecards on mount , popover destroyed and recreated on open/close
    useEffect(() => {
        if (!showCreateTemplateModal && document?.activeElement?.tagName === 'INPUT') {
            document.activeElement.blur()
        }
        //when closing create template modal dialog focus is stuck in react-select input ,since focus is stuck , clicking on select doesn't open dropdown open menu. need to click outside and click select box again
        //blur the select box to avoid this

    }, [showCreateTemplateModal])

    useEffect(() => {
        dispatch(getSkills())
        setLoading(true)
        Promise.all([fetchScoringTemplates(), fetchScorecardsForcall(callId)])
            .then(([templates, scorecards]) => {
                setScoringTemplates((templates || []).map(e => { return { value: e.id, label: e.name, ...e } }))
                setAlreadyScoredcards(scorecards || [])
                setScorecardsCount(scorecards.length)
                setHeadingText(scorecards.length > 0 ? "Call Scores" : "Score this call")
                if (showScoringPopover &&  scorecards.length === 0 ) {
                    setViewType(ViewType.WRITE_SCORECARD)//trigger creation flow if no existing scorecards
                }
            })
            .catch((e) => {
                console.error(e)
                setSnackbar("Error loading scores and templates", "ERROR");

            })
            .finally(() => {
                setLoading(false)
            })


    }, [])




    const handleSubmit = async () => {
        setSubmitLoading(true)
        let url = null;
        if (scoreCard.id) {
            metrics.logEventHeap('scorecard', { type: "EDIT" });
            url = "scorecard/edit_scorecard"
        }
        else {
            metrics.logEventHeap('scorecard', { type: "CREATE" });
            url = "scorecard/create_scorecard"

        }
        const resp = await sendRequest(url, scoreCard)
        if (!scoreCard.id)//update count if new scorecard created
        {
            dispatch(setScorecardCount(scorecardCount + 1))
        }

        if (resp.error) {
            setSnackbar("Error scoring call", "ERROR");
            setSubmitLoading(false)
        }
        else {
            setSnackbar("Score submitted", "SUCCESS");

            close()
        }

        setUnsavedScorecard(null);
        setViewType(ViewType.VIEW_SCORECARD_LIST);
    }
    const setRemark = (event) => {
        setScoreCard({ ...scoreCard, remark: event.target.value })
    }
    const clearScorecard = () => {
        if (scoreCard) {
            const newScoreCard = getNewScoreCard(selectedTemplate, selectedRep.value)
            setScoreCard(newScoreCard)
        }
    }

    // when users change the template, recreate the scorecard state with new template
    const handleTemplateChange = (template) => {
        setSelectedTemplate(template)
        const newScoreCard = getNewScoreCard(template, selectedRep.value)
        setScoreCard(newScoreCard)
    }

    const setQuestionScore = (question, questionIndex, score) => {
        let scoredQuestion = cloneDeep(question)
        scoredQuestion.score = score
        const changedScoreCard = cloneDeep(scoreCard)
        changedScoreCard.questionScores.splice(questionIndex, 1, scoredQuestion)
        setScoreCard(changedScoreCard)
    }

    // Pick all wingman rep ids from meeting attendees + mark primary rep to show as Options in ScoredRep SelectBox 
    useEffect(() => {
        //meeting info has all participants in call, filter out wingman users of current customer alone
        const filteredReps = meetingAttendees.filter(attendee => getUserName(attendee.userId)).map(
            attendee => {
                return {
                    value: attendee.userId,
                    label: getUserName(attendee.userId)
                }
            }
        )
        let primaryRepOption = filteredReps.find(rep => rep.value === primaryRepId)
        if (primaryRepOption) {
            primaryRepOption.label = primaryRepOption.label + `\t( Primary Rep )`
        }
        setRepSelectOptions(filteredReps)
    }, [meetingAttendees, primaryRepId])

    useEffect(() => {

        if (unsavedScorecard) {
            setRepAndTemplateFromScorecard(unsavedScorecard)
            setScoreCard(unsavedScorecard)
        }

    }, [repSelectOptions, scoringTemplates])

    useEffect(() => {
        //New Score card creation triggered,set primary rep as default
        if (!selectedRep && viewType === ViewType.WRITE_SCORECARD) {
            if (primaryRepId) {
                const primaryRepName = getUserName(primaryRepId)
                setSelectedRep({ value: primaryRepId, label: primaryRepName + `\t( Primary Rep )` })
            }
        }

        if (viewType === ViewType.VIEW_SCORECARD) setShowNavigationArrows(true)
        else setShowNavigationArrows(false)

    }, [viewType])

    // Switch between  multiple scorecards
    useEffect(() => {

        if (currentScorecardIndex > -1) {
            setScoreCard(alreadyScoredcards[currentScorecardIndex])
        }
    }, [currentScorecardIndex])


    function expandScorecard(scoredScorecard, index) {
        setScoreCard(scoredScorecard)
        setRepAndTemplateFromScorecard(scoredScorecard)
        setViewType(ViewType.VIEW_SCORECARD)
        setCurrentScorecardIndex(index)
        setHeadingText("Call scores")
    }
    function setRepAndTemplateFromScorecard(scoredScorecard) {

        // displaying already scored call, preselect the rep and templates        
        const scoredTemplate = scoringTemplates.find(e => e.id === scoredScorecard.scoringTemplateId)
        setSelectedTemplate(scoredTemplate)
        const scoredRep = repSelectOptions.find(e => e.value === scoredScorecard.scoredRepId)
        setSelectedRep(scoredRep)

        return ({ scoredTemplate, scoredRep })

    }
    function handleScoreAgain() {

        //rep and template are same across all scorecards in a call.
        setViewType(ViewType.WRITE_SCORECARD);
        let { scoredTemplate, scoredRep } = setRepAndTemplateFromScorecard(alreadyScoredcards[0])
        setScoreCard(getNewScoreCard(scoredTemplate, scoredRep.value));
        setHeadingText("Score this call")
    }
    function editScorecard() {
        setViewType(ViewType.WRITE_SCORECARD)
        setRepAndTemplateFromScorecard(scoreCard)
        setHeadingText("Edit call score")
        setUnsavedScorecard(scoreCard);

    }

    useEffect(() => {
        if (selectedRep && scoreCard) {
            let scoreCardLocal = cloneDeep(scoreCard);
            scoreCardLocal.scoredRepId = selectedRep.value;
            setScoreCard(scoreCardLocal)
        }
    }, [selectedRep])

    //re validate scorecard on change of each question score
    useEffect(() => {
        if (scoreCard) {
            const allQuestionsScored = scoreCard.questionScores.every(question => question.score !== null && question.score !== undefined) //not scored is  null for binary and null/0 for scaled ,both falsy
            setIsValidScorecard(allQuestionsScored)
            if (viewType === ViewType.WRITE_SCORECARD) setUnsavedScorecard(scoreCard);
        }

    }, [scoreCard])

    const onTemplateEditClick = (templateId) => {
        let template = scoringTemplates.find(e => e.id === templateId)
        setTemplateToEdit(template)
        setShowCreateTemplateModal(true)
    }

    return (
        <>
            {loading &&
                <ScorecardSkeleton />
            }
            {!loading &&
                <>
                    {
                        showEmptyState &&
                        <ScorecardEmptyState className="h-full w-full" onCtaClick={() => { setShowCreateTemplateModal(true) }} />
                    }
                    {

                        !showEmptyState &&
                        <>
                            {viewType === ViewType.VIEW_SCORECARD_LIST &&
                                <MultipleScoring scorecards={alreadyScoredcards} handleScoreAgain={handleScoreAgain} expandSelectedScorecard={expandScorecard} />
                            }
                            {(viewType === ViewType.VIEW_SCORECARD || viewType === ViewType.WRITE_SCORECARD) &&
                                <div className='flex-grow overflow-y-auto h-0 flex-col flex pb-20'>
                                    {viewType === ViewType.VIEW_SCORECARD &&
                                        <div className='px-6 mb-4'>
                                            <TextLarge textColor='text-coolGray-500' inline>Overall score  :</TextLarge>
                                            <TextLarge inline className="ml-1">{scoreCard?.totalScore}</TextLarge>
                                        </div>
                                    }
                                    <div className='px-5 flex-shrink-0'>

                                        <Select
                                            id="adoption-tracking-scorecard-rep-select"
                                            className="mb-2.5"
                                            isDisabled={viewType === ViewType.VIEW_SCORECARD || alreadyScoredcards.length > 0}
                                            formatOptionLabel={FormatOptionLabel}
                                            options={repSelectOptions}
                                            value={selectedRep}
                                            placeholder="Select Rep to score"
                                            onChange={(option) => setSelectedRep(option)}
                                            components={{ IndicatorSeparator: null }}
                                            theme={customTheme}
                                            styles={customStyles}
                                            classNamePrefix="wm-select"
                                        />
                                        <Select
                                            className="mb-2.5"
                                            isDisabled={viewType === ViewType.VIEW_SCORECARD || alreadyScoredcards.length > 0}
                                            formatOptionLabel={
                                                function (...props) {

                                                    return FormatOptionLabel.call(this, ...props, onTemplateEditClick)
                                                }

                                            }
                                            options={scoringTemplates}
                                            value={selectedTemplate}
                                            placeholder="Select scoring template"
                                            onChange={handleTemplateChange}
                                            components={{ MenuList: SelectMenuButton, IndicatorSeparator: null }}
                                            theme={customTheme}
                                            styles={customStyles}
                                        />
                                        <div className='border-t border-coolGray-200 shadow-md' />
                                    </div>
                                    <div className='flex-grow flex-shrink-0  px-5 pt-4'>
                                        {scoreCard &&
                                            <>
                                                {scoreCard.questionScores.map((question, index) => {

                                                    return <div key={'question-row-' + question.label + "-" + question.skill + "-" + index} className='mb-6' >
                                                        <div className="wm-question-skill-row flex mb-2">

                                                            <div className='w-8/12 flex'>
                                                                <TextBase className="mr-2.5">
                                                                    {index + 1}
                                                                </TextBase>
                                                                <TextBase>
                                                                    {question.label}
                                                                </TextBase>
                                                            </div>
                                                            <div className='w-1/12' />
                                                            <div className='w-3/12 flex justify-end items-start cursor-default'>
                                                                <TextSM className="px-2 py-1 rounded-lg bg-violet-50 capitalize !inline " >
                                                                    {question.skill}
                                                                </TextSM>
                                                            </div>
                                                        </div>
                                                        <ScoringScale disabled={viewType === ViewType.VIEW_SCORECARD} className="ml-4" type={scoreCard.scoringType} value={question.score} setValue={(score) => { setQuestionScore(question, index, score) }} />
                                                    </div>

                                                })
                                                }
                                                <TextBase>Remarks</TextBase>
                                                <textarea disabled={viewType === ViewType.VIEW_SCORECARD} name="remark" value={scoreCard.remark || ""} onChange={setRemark} id="remarkTextArea" rows="5" className='mt-1 rounded-lg border border-coolGray-200 w-full text-base font-normal p-2 focus:border-coolGray-500'></textarea>
                                            </>

                                        }

                                    </div>
                                    {
                                        (viewType === ViewType.VIEW_SCORECARD && (scoreCard?.scorerId === currentUserId)) &&
                                        <div className="wm-scorecard-submit-cont  px-6 pt-4  flex-shrink-0 flex justify-center ">
                                            <Button variant="secondary" className="inline-block flex-grow mr-4" onClick={editScorecard} id="adoption-tracking-edit-scorecard">
                                                Edit Scorecard
                                            </Button>

                                        </div>
                                    }
                                    {
                                        viewType === ViewType.WRITE_SCORECARD && <div className="wm-scorecard-submit-cont  px-6 pt-4  flex-shrink-0 flex ">
                                            <Button variant="secondary" className="inline-block flex-grow mr-4" onClick={clearScorecard} id="adoption-tracking-clear-scorecard">
                                                Clear all
                                            </Button>
                                            <Button variant="primary" className="inline-block flex-grow" disabled={!isValidScoreCard} onClick={handleSubmit} loading={submitLoading} id="adoption-tracking-submit-scorecard">
                                                Submit
                                            </Button>
                                        </div>
                                    }

                                </div>
                            }
                        </>
                    }

                    {
                        showCreateTemplateModal && <CreateTemplateModal open={showCreateTemplateModal} template={templateToEdit} setOpen={setShowCreateTemplateModal} onSubmit={handleTemplateCreated} />
                    }
                </>

            }


        </>
    )

}
