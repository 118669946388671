import React from "react";
import ListIcon from "@material-ui/icons/List";
import dashboard from "app/styles/assets/images/explainers/Dashboard.png";
import calls from "app/styles/assets/images/explainers/Calls.png";
import callLog from "app/styles/assets/images/explainers/CallLog1.png";
import reports from "app/styles/assets/images/explainers/Reports.png";
import TeamDashboardExplainer from "app/styles/assets/images/explainers/TeamDashboardExplainer.png";
import MyDashboardExplainer from "app/styles/assets/images/explainers/MyDashboardExplainer.png";
import MyCallsExplainer from "app/styles/assets/images/explainers/MyCallsExplainer.png";
import TeamCallLogExplainer from "app/styles/assets/images/explainers/TeamCallLogExplainer.png";
import { CustomLink } from "app/components/common";
import { connect } from "react-redux";
import { setUserData } from "app/actions/auth";
import { setOnboardingSkipped } from "app/actions/persisted";
import { withRouter } from "react-router-dom";

const Explainer = props => {
  function openSettings() {
    props.history.push("/settings#integrations");
  }

  return (
    <div className="Explainer">
      <span className="Explainer__title">Welcome to Wingman</span>
      <div className="Explainer__firstRow">
        <div className="Explainer__AboutPage">
          <div className="Explainer__imageAndTextRow">
            <img
              src={getIcon(props.screen.screenName)}
              className="Explainer__AboutPageIcon"
              alt={props.screen.screenName}
            />
            <span className="Explainer__AboutPageName">{props.screen.screenName}</span>
          </div>
          <span className="Explainer__AboutPageContent">{getContent(props.screen.screenName)}</span>
        </div>
        <div className="Explainer__GetStarted">
          <div className="Explainer__imageAndTextRow">
            <ListIcon className="Explainer__AboutPageIcon" />
            <span className="Explainer__AboutPageName">How to get started?</span>
          </div>
          <div className="Explainer__GetStartedContent">
            <p className="Explainer__GetStartedContent">





              We're downloading and processing your calls right now.
            </p>
            <p className="Explainer__GetStartedContent">





              If you have connected your calendar, Wingman note-taker will automatically join and
              record video conference meetings with your customers and will make the recordings and
              insights available for you.
            </p>
            <p className="Explainer__GetStartedContent">





              If you have not yet connected your CRM, Calendar or Dialer, click
              <CustomLink type="mini" onClick={openSettings}>





                here
</CustomLink>





              to do it.
            </p>
          </div>
        </div>
      </div>
      <div className="Explainer__secondRow">
        <img
          className="Explainer__explainerImage"
          src={getExplainerImage(props.screen.screenName)}
          alt={props.screen.screenName}
        />
      </div>
    </div>
  );
};

function getIcon(screenName) {
  switch (screenName) {
    case "Team Calls":
      return callLog;
    case "Team Dashboard":
      return dashboard;
    case "My Calls":
      return calls;
    case "My Dashboard":
      return reports;
  }
}

function getExplainerImage(screenName) {
  switch (screenName) {
    case "Team Calls":
      return TeamCallLogExplainer;
    case "Team Dashboard":
      return TeamDashboardExplainer;
    case "My Calls":
      return MyCallsExplainer;
    case "My Dashboard":
      return MyDashboardExplainer;
  }
}

function getContent(screenName) {
  switch (screenName) {
    case "Team Calls":
      return "List of all sales calls & their recordings (annotated & transcribed) made by you & your team.";
    case "Team Dashboard":
      return "See the performance of all the reps in one central place, with engagement & productivity metrics.";
    case "My Calls":
      return "My Calls: See all of your calls with customers: already done as well as upcoming scheduled, listen to the recording of any call.";
    case "My Dashboard":
      return "My Dashboard: See individual level engagement metrics & trackers/keywords. See the trends over time so that you can track your improvement.";
  }
}

const mapStateToProps = store => {
  return {};
};
export default connect(
  mapStateToProps,
  { setOnboardingSkipped, setUserData },
)(withRouter(Explainer));
