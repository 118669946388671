import React, { useState, useEffect } from "react";
import {
  Card,
  ConfirmationDialog,
  InformationDialog,
  CustomTooltip,
} from "app/components/common";
import { Grid, IconButton } from "@material-ui/core";
import { TagFilledInputArea } from "app/components/settings";
import { addPhrase, removePhrase, setAppliesToForCategory } from "app/utils/network";
import Select from "react-select";
import { Label } from "../form";
import { useSelector } from "react-redux";
import { DeleteIcon, TextBase } from "app/designSystem";
import { InfoRedIcon } from "app/designSystem";
import ReactTooltip from "react-tooltip";
export const options = {
  "ALL": { value: "ALL", label: "All" },
  "AGENT": { value: "AGENT", label: "Rep" },
  "CUSTOMER": { value: "CUSTOMER", label: "Customer" }
};


const Category = props => {
  const customer = useSelector(store => store.auth.stringsCustomer);
  let appliesTo = { value: "ALL", label: "All" };
  if (props.phrases && props.phrases.exact && props.phrases.exact.length > 0 && props.phrases.exact[0].appliesTo) {
    appliesTo = options[props.phrases.exact[0].appliesTo];
  } else if (props.phrases && props.phrases.approx && props.phrases.approx.length > 0 && props.phrases.approx[0].appliesTo) {
    appliesTo = options[props.phrases.approx[0].appliesTo];
  }

  const [state, setState] = useState({
    showDialog: false,
    style: { opacity: 1 },
    exact: props.phrases.exact,
    approx: props.phrases.approx,
    appliesTo
  });

  let optionsLocal = Object.values(options);

  useEffect(() => {
    if (props.phrases.new) {
      setState({
        ...state,
        style: { opacity: 0 },
      });
      setTimeout(async () => {
        setState({
          ...state,
          style: { opacity: 1 },
        });
      }, 100);
    }
  }, []);

  function hideDialog() {
    setState({
      ...state,
      showDialog: false,
    });
  }

  function showDialog() {
    setState({
      ...state,
      showDialog: true,
    });
  }


  async function deleteCategory() {
    setState({
      ...state,
      showDialog: false,
    });
    await props.deleteCategory(props.category);
  }

  async function handleAddPhrase(text, exactMatchRequired) {
    const phrases = text.split(",");

    for (let i = 0; i < phrases.length; i++) {
      const phrase = phrases[i];
      if (phrase.trim() === "") return null;

      const addedPhrase = await addPhrase(
        phrase.trim(),
        props.category,
        exactMatchRequired,
        state.appliesTo.value,
      );

      if (exactMatchRequired) {
        const { exact } = state;
        exact.push(addedPhrase);
        setState({
          ...state,
          exact
        });
      } else {
        const { approx } = state;
        approx.push(addedPhrase);
        setState({
          ...state,
          approx,
        });
      }
    }
  }

  async function handleRemovePhrase(phraseId, phrase, exactMatchRequired) {
    props.setLoading(true);
    await removePhrase(phrase.phraseId);
    if (exactMatchRequired) {
      const { exact } = state;
      exact.splice(exact.indexOf(phrase), 1);
      setState({
        ...state,
        exact,
      });
    } else {
      const { approx } = state;
      approx.splice(approx.indexOf(phrase), 1);
      setState({
        ...state,
        approx,
      });
    }
    props.setLoading(false);
  }

  async function handleAppliesToChange(appliesTo) {
    setState({
      ...state,
      appliesTo,
    });
    const res = await setAppliesToForCategory(props.category, appliesTo.value);
  }

  return (
    <div className="KeywordsTracking__anime" style={state.style} id={props.category + "inTopics"}>
      <Card className="KeywordsTracking__Card">
        <Grid
          container
          spacing={0}
          direction="row"
          className="KeywordsTracking__categoryNameContainer"
        >
          <Grid className="KeywordsTracking__categoryName text-brand-blue-3" item xs={7}>
            {props.category}
          </Grid>

          <Grid className="KeywordsTracking__categoryName" item xs={4}>
            <div className="KeywordsTracking__categoryName__appliesTo">
              <Label>APPLIES TO:</Label>
              <Select options={optionsLocal} value={state.appliesTo} placeholder="Select Speaker" onChange={handleAppliesToChange} isDisabled={props.disabled} />
            </div>
          </Grid>
          <Grid className="KeywordsTracking__deleteContainer" item xs={1}>
            {!props.disabled &&
              <IconButton onClick={showDialog} className="hover:text-wingman-brand">
                <DeleteIcon color="primary" className="KeywordsTracking__delete" />
              </IconButton>}
            <ConfirmationDialog
              title="Delete Category"
              message="Are you sure you want to delete the entire category?"
              open={state.showDialog}
              onOk={deleteCategory}
              onClose={hideDialog}
            />

          </Grid>
        </Grid>
        {customer.featuresGated.enableSemanticMatching &&
          <div className="KeywordsTracking__subSectionContainer">
            <div className="flex items-center ">
              <span className="KeywordsTracking__subSectionTitle inline-block ml-5 text-brand-blue-3">
                Semantically Similar Matches
              </span>
              <div className="flex items-center justify-center rounded-2xl bg-red-50 py-1 px-4 ml-2  ">
                <TextBase textColor="text-red-600">Deprecated</TextBase>
                <InfoRedIcon className="ml-1.5" data-tip data-for="semanticMatches" />
                <ReactTooltip id="semanticMatches" place="top" type="dark" effect="solid" className="flex bg-slate-800 w-[20.5rem] h-[7rem] items-center justify-around px-3 py-1">
                  <TextBase textColor="text-gray-50">{"Semantically similar matches will be removed soon and replaced with a better way to setup topics! We suggest using exact matches for any new topics that you want to track."}</TextBase>
                </ReactTooltip>
              </div>
            </div>
            <TagFilledInputArea
              id={`${props.category}2`}
              label=" Add Phrase/Keyword"
              addPhrase={phrase => handleAddPhrase(phrase, false)}
              removePhrase={handleRemovePhrase}
              tags={state.approx}
              type="approx"
              disabled={props.disabled}
            />
          </div>
        }
        <div className="KeywordsTracking__subSectionContainer">
          <CustomTooltip placement="right" title="Match exact term entered.">
            <span className="KeywordsTracking__subSectionTitle inline-block ml-5 text-brand-blue-3">
              Exact Matches
            </span>
          </CustomTooltip>
          <TagFilledInputArea
            id={`${props.category}1`}
            label=" Add Phrase/Keyword"
            addPhrase={phrase => handleAddPhrase(phrase, true)}
            removePhrase={handleRemovePhrase}
            tags={state.exact}
            type="exact"
            disabled={props.disabled}
          />
        </div>
      </Card>
    </div>
  );
}

export default Category;

const SemanticallySimilarMessage = props => {
  return (
    <div>
      <p>
        We will employ our state of the art NLP systems to find all phrases that
        mean the same thing.
      </p>
      <p>
        For example, “I don’t know this” can also be said as “I am not sure
        about this”.
      </p>
      <p>This is typically effective for longer phrases (4 or more words)</p>
    </div>
  );
};