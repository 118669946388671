export function getTriggersAsCSV(triggersObject) {
  let triggerCSV = "";
  for (let trigger of triggersObject) {
    let comma = ", ";
    if (triggerCSV === "") comma = "";
    triggerCSV += comma + trigger.keyword;
  }
  return triggerCSV;
}

export function getCSVAsTriggers(csv) {
  const triggerCSV = csv.split(",");
  return triggerCSV.map(trigger => {
    keyword: trigger.trim()
  });
}

export function groupBattlecardsIntoCategories(battlecards) {
  //let battlecardCategories = { "Pricing": [], "Competitors": [], "Product Features": [], "Objections": [] };
  let battlecardCategories = {};
  for (let battlecard of battlecards) {
    if (battlecardCategories[battlecard.category]) {
      battlecardCategories[battlecard.category].push(battlecard);
    } else {
      battlecardCategories[battlecard.category] = [battlecard]
    }
  }
  return battlecardCategories;
}

export function updateBattlecardCategoriesData(battlecard, battlecardCategories, wasEdit) {
  let index = battlecardCategories.length - 1;
  let battlecardCategoriesLocal = battlecardCategories;
  if (wasEdit) {
    const res = removeExistingCard(battlecardCategories, battlecard);
    index = res.index;
    battlecardCategoriesLocal = res.battlecardCategories;
  }
  if (battlecardCategoriesLocal[battlecard.category]) {
    battlecardCategoriesLocal[battlecard.category].splice(index, 0, battlecard);
  } else {
    battlecardCategoriesLocal[battlecard.category] = [battlecard];
  }
  return battlecardCategoriesLocal;
}

export function removeExistingCard(battlecardCategories, battlecard) {
  let index = battlecardCategories.length - 1;
  Object.keys(battlecardCategories).map(category => {
    for (let card of battlecardCategories[category]) {
      if (card.id === battlecard.id) {
        index = battlecardCategories[category].indexOf(card);
        if (index > -1) {
          battlecardCategories[category].splice(index, 1);
        }
      }
    }
  })
  return { battlecardCategories, index };
}