import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { CircularProgress } from "@material-ui/core";
import { addGameTapeToFolder, addSnippetToFolder, updateGameTapeMetadata, addNewTag, getAllFolders } from 'app/utils/network/gametapes'
import { setGameTapesAllFolderData, getGameTapesTagsData, setFolderCreateModal } from 'app/actions/gametapes'
import { Input } from 'app/designSystem/form';
import TagsInput from 'app/designSystem/form/TagsInput';
import TextArea from 'app/designSystem/form/TextArea';
import Button from 'app/designSystem/buttons/Button';
import { FolderAccordian, getFolderData } from 'app/components/gameTapesLatest/helper'
import { CreateFolderModal } from 'app/components/gameTapesLatest/modal'
import { TextBase, TextXL } from 'app/designSystem/typography/Typography'
import { CloseIcon } from 'app/designSystem/icons';
import clx from "classnames"
import metrics from 'app/utils/metrics';
import { MAX_LEVEL_OF_FOLDERS } from 'app/containers/Folder'

const CreateGameTapeForm = ({ open, closeModal, onCreate, data }) => {
    const dispatch = useDispatch();

    const { snippetStartTime, snippetEndTime, gametapeDescription } = useSelector(state => state.screen);
    const callData = useSelector(state => state.call);
    const accountName = callData?.details?.callDataDocument?.crmData?.accountDocument?.name ?? "";
    const callTitle = callData?.details?.callDataDocument?.meetingInfo?.meetingTitle ?? "";
    const callId = callData.callId;
    const callDuration = useSelector(state => state.call.details?.callData?.totalAudioLengthSecs ?? 0);
    const foldersList = useSelector(state => getFolderData(state.gametapes.foldersList));
    const tagsList = useSelector(state => state.gametapes.tagsList);

    const [isUpdating, setIsUpdating] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);

    const [formData, setFormData] = useState({
        name: "",
        description: gametapeDescription,
        tags: [],
        startTime: 0,
        duration: 0,
    });

    const [selectedFolder, setSelectedFolder] = useState({ id: "ROOT", level: 0 });
    const [loading, setLoading] = useState(false);
    const [folderListLoading, setFolderListLoading] = useState(true);

    const formRef = useRef();
    const errorMessageRef = useRef();

    const { setSnackbar } = useSnackbar();

    useEffect(() => {
        fetchFolderData();
        dispatch(getGameTapesTagsData());
    }, [])

    // When a errorMssage is set, scroll to the respective ref
    useEffect(() => {
        if (errorMessage && errorMessageRef.current) {
            errorMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [errorMessage]);

    useEffect(() => {
        if (data) {
            setFormData(data)
            setIsUpdating(true);
        }
    }, [data])

    useEffect(() => {
        if (!data) { // If not updating, then only set the form data
            if (callTitle && accountName) {
                if (callTitle.toLowerCase().includes(accountName.toLowerCase())) {
                    setFormData({ ...formData, name: callTitle })
                } else {
                    setFormData({ ...formData, name: `${accountName} <> ${callTitle}` })
                }
            } else if (callTitle) {
                setFormData({ ...formData, name: callTitle })
            } else {
                setFormData({ ...formData, name: "" })
            }
        }
    }, [callTitle, accountName])

    const onChangeHandler = (key, value) => {
        setFormData({
            ...formData,
            [key]: value
        });

        setErrorMessage(null);
    }

    const fetchFolderData = async () => {
        const data = (await getAllFolders()) ?? [];
        dispatch(setGameTapesAllFolderData(data));
        setFolderListLoading(false);
    }

    const handleValidations = () => {
        if (!formData.name) {
            setErrorMessage("Please enter a name");
            return false;
        }

        if (!isUpdating) {
            if (selectedFolder.id === "ROOT") {
                setErrorMessage("Please select a folder");
                return false;
            }
        }

        setErrorMessage(null);
        return true;
    }

    const handleSubmit = async () => {

        // Validate the form
        if (!handleValidations()) {
            return;
        }

        setLoading(true);

        // Add new tags to the tags list
        const newTags = formData.tags;

        for (let tag of newTags) {
            if (!tagsList.includes(tag)) {
                addNewTag({ tag })
            }
        }

        if (isUpdating) {
            await updateGameTapeMetadata({
                ...formData,
                gametapeId: data.id,
            })

            setSnackbar("Game Tape updated successfully", "SUCCESS");

            setLoading(false);
            onCreate?.();
        }
        else {
            if (snippetStartTime && snippetEndTime) {
                metrics.logEventHeap('createGameTape', { type: 'SNIPPET', duration: snippetEndTime - snippetStartTime });

                const result = await addSnippetToFolder({
                    folderId: selectedFolder.id,
                    callId: callId,
                    name: formData.name,
                    description: formData.description,
                    tags: formData.tags,
                    startTime: parseInt(snippetStartTime),
                    endTime: parseInt(snippetEndTime)
                })

                if (result.error) {
                    setSnackbar("There was an error adding this call to the folder", "ERROR");
                } else {
                    setSnackbar("Snippet was added to the folder", "SUCCESS");
                    closeModal();
                }

                setLoading(false);
            }
            else {
                metrics.logEventHeap('createGameTape', { type: 'CALL', duration: callDuration });

                const result = await addGameTapeToFolder({
                    folderId: selectedFolder.id,
                    callId: callId,
                    duration: parseInt(callDuration ?? 0),
                    name: formData.name,
                    description: formData.description,
                    tags: formData.tags,
                })

                if (result.error) {
                    setSnackbar("There was an error adding this call to the folder", "ERROR");
                } else {
                    setSnackbar("Call was added to the folder", "SUCCESS");
                    closeModal();
                }

                setLoading(false);
            }
        }
    }

    const handleFolderCreationModalOpen = () => {
        dispatch(setFolderCreateModal(true));
    }

    const handleFolderCreationModalClose = () => {
        dispatch(setFolderCreateModal(false));
    }


    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                className="flex justify-center items-center"
            >
                <div className={clx("!bg-white rounded-lg p-0 outline-none", isUpdating ? "w-[40rem]" : "w-[50rem]")} style={{
                    maxHeight: "calc(100vh - 6.25rem)",
                }}>
                    <div className="px-8 py-4">
                        <div className="flex justify-between w-full">
                            <TextXL>{isUpdating ? "Update" : "Create"} Game Tape</TextXL>
                            <CloseIcon className="cursor-pointer" onClick={closeModal} />
                        </div>
                    </div>
                    <hr />
                    <div className="px-8 py-4 overflow-y-auto relative" ref={formRef} style={{
                        maxHeight: "calc(100vh - 15rem)"
                    }}>
                        <div className="flex flex-col">
                            <Input
                                label="Name"
                                value={formData.name}
                                onChange={(e) => onChangeHandler("name", e.target.value)}
                                type="text"
                                placeholder="Demo name"
                                className="mb-6"
                                required
                                maxLength={100}
                            />

                            <TextArea
                                label="Description"
                                value={formData.description}
                                onChange={(e) => onChangeHandler("description", e.target.value)}
                                placeholder="Demo description"
                                className="mb-6"
                                rows={4}
                                required
                                maxLength={500}
                            />

                            <div className="mb-5">
                                <div className="mb-1 flex justify-between">
                                    <TextBase className="">Tags</TextBase>
                                    <TextBase textColor="text-gray-400"> Press enter to create a new tag</TextBase>
                                </div>
                                <TagsInput
                                    selectedTags={formData.tags}
                                    onChange={(tags) => onChangeHandler("tags", tags)}
                                    tags={tagsList}
                                />
                            </div>

                            {!isUpdating && (
                                <div className="mb-5">
                                    <TextBase className="mb-1">Select Folder</TextBase>
                                    <div className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border focus:outline-none overflow-y-auto h-56 p-2">
                                        {
                                            folderListLoading ? (
                                                <CircularProgress size={50} color="primary" />
                                            ) : <FolderAccordian folderData={foldersList} setSelectedFolder={setSelectedFolder} selectedFolder={selectedFolder} />
                                        }
                                    </div>
                                </div>
                            )}

                            <div ref={errorMessageRef}>
                                {errorMessage && <div className="flex justify-start items-center rounded mt-4 py-3 pl-5 pr-10 bg-red-50 cursor-pointer" onClick={() => { setErrorMessage("") }}>
                                    <TextBase textColor="text-red-600" className="font-normal">{errorMessage}</TextBase>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="px-8 py-4 flex items-center justify-between bg-wingman-white border-t">
                        <div>
                            {(selectedFolder?.level <= MAX_LEVEL_OF_FOLDERS - 1) && <Button variant="secondary" onClick={handleFolderCreationModalOpen} className="mr-4">New Folder</Button>}
                        </div>
                        <Button loading={loading} onClick={handleSubmit}>{isUpdating ? "Update gametape" : "Create gametape"}</Button>
                    </div>
                </div>
            </Modal >

            <CreateFolderModal
                folderId={selectedFolder.id}
                onClose={handleFolderCreationModalClose}
                onCreate={() => { handleFolderCreationModalClose(); fetchFolderData(); }}
            />
        </>
    );
}

CreateGameTapeForm.propTypes = {
    open: PropTypes.bool,
    closeModal: PropTypes.func,
    onCreate: PropTypes.func,
    isUpdating: PropTypes.bool,
    data: PropTypes.object,
    description: PropTypes.string
}

CreateGameTapeForm.defaultProps = {
    open: false,
    closeModal: () => { },
    onCreate: () => { },
    isUpdating: false,
    data: null,
    description: ""
}

export default CreateGameTapeForm;