import { GET_CALL_DETAILS, GET_CALLS, SET_ACTIVE_TIME, SET_MATCHING_HIGHLIGHTS, SET_SELECTED_SEARCH_OPTION, SET_SHARE_DETAILS, UPDATE_CALL, SET_SELECTED_HIGHLIGHT, SET_ACTIVE_TAB, UPDATE_SCORECARD_COUNT } from "app/actions/actionTypes";
import { cloneDeep } from "lodash";
import { fetchDataSafely } from "../utils/dataUtils";
import { getContactName } from "../utils/helpers";

const initialState = {
  details: {},
  activeTime: 0,
  callId: null,
  matchingHighlights: { highlightGroups: [] },
  selectedSearchOption: null,
  videoAvailable: null,
  videoProcessed: null,
  shareDetails: null,
  highlightsSection: null,
};

export default function (state = initialState, payload) {
  switch (payload.type) {
    case GET_CALL_DETAILS:
      return {
        ...state,
        details: payload.details,
        callId: payload.callId,
        videoAvailable: fetchDataSafely(payload, "details.callDataDocument.videoRecordingStatus.version", null) >= 3,
        videoProcessed: fetchDataSafely(payload, "details.callDataDocument.videoRecordingStatus.callStatus", false) === "POST_PROCESSING_DONE"
      };

    case UPDATE_CALL:
      return {
        ...state,
        details: { ...state.details, callDataDocument: { ...state.details.callDataDocument, ...payload.data } },
      }
    case GET_CALLS:
      return {
        ...state,
        calls: payload.details.data,
      };
    case SET_ACTIVE_TIME:
      return {
        ...state,
        activeTime: payload.time,
      };
    case SET_MATCHING_HIGHLIGHTS:
      return {
        ...state,
        matchingHighlights: payload.matchingHighlights,
      };
    case SET_SELECTED_SEARCH_OPTION:
      console.log("in setSelectedSearchOption payload", payload);
      return {
        ...state,
        selectedSearchOption: payload.selectedSearchOption,
      };
    case SET_SHARE_DETAILS:
      return {
        ...state,
        shareDetails: payload.shareDetails,
      }
    case SET_SELECTED_HIGHLIGHT:
      return {
        ...state,
        highlightsSection: payload.highlightsSection,
      }
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabName: payload.activeTabName,
      }
    // props.call?.details?.callDataDocument?.totalScorecards
    case UPDATE_SCORECARD_COUNT:
      return {
        ...state,
        details: {
          ...state.details,
          callDataDocument: {
            ...state.details.callDataDocument,
            totalScorecards: payload.count,
          }
        }

      }
    default:
      return state;
  }
}

export function getMediaUrl(call) {
  const videoAvailable = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.version", null) >= 3;
  const videoProcessed = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.callStatus", false) === "POST_PROCESSING_DONE";
  if (videoAvailable && videoProcessed) {
    return call?.details?.signedVideoUrl;
  } else {
    return call?.details?.signedAudioUrl;
  }
}

export function isCustomerTurn(turn, speakers, channels) {
  if (!turn) return true;
  if (channels && channels[turn.speakerId - 1]) {
    return !(channels[turn.speakerId - 1].isRep || (channels[turn.speakerId - 1].userId && channels[turn.speakerId - 1].userId !== ""));
  }
  return speakers && speakers[turn.speakerId - 1] && speakers[turn.speakerId - 1].indexOf("Customer") !== -1;
}

export function isCustomerSpeaker(speakerId, channels, speaker) {
  if (channels && channels[speakerId]) {
    return !(channels[speakerId].isRep || (channels[speakerId].userId && channels[speakerId].userId !== ""));
  }
  return speaker && speaker.indexOf("Customer") !== -1;
}

export function getRicherSpeakerData(conversationTurn, speakers, contactsList, channel, isSharedCall) {
  const speakerTimes = {};
  let sortedSpeakers = cloneDeep(speakers);
  const contacts = {};
  const tlRatios = {};
  const ids = {};
  if (!conversationTurn)
    return { sortedSpeakers, contacts, tlRatios, ids };

  conversationTurn.map(t => {
    var s = getTurnSpeaker(t, speakers);
    speakerTimes[s] = (speakerTimes[s] || 0) + t.endTime - t.startTime;
  });


  if (speakers) {
    speakers.map((speaker, i) => {
      ids[speaker] = i + 1;
      if (isSharedCall) {
        tlRatios[speaker] = getSpeakerTlRatioFromTurns(i + 1, conversationTurn);
      } else {
        tlRatios[speaker] = getSpeakerTlRatio(i, channel);
      }
      if (isCustomerSpeaker(i, channel, speaker)) {
        contactsList.map(c => {
          if (doesNameMatch(speaker, c)) {
            contacts[speaker] = c;
          }
        })
      }
    })

    sortedSpeakers = sortedSpeakers.filter(s => speakerTimes[s] && tlRatios[s] >= 0.01);
    sortedSpeakers.sort((a, b) => speakerTimes[b] - speakerTimes[a]);
  }
  return { sortedSpeakers, contacts, tlRatios, ids };
}

export function getSpeakerTlRatio(speakerId, channels) {
  const duration = channels.map(c => c.speakDuration).reduce((a, b) => a + b);
  if (channels && channels[speakerId]) {
    return (channels[speakerId].speakDuration / duration);
  }
  return 0;
}

export function getSpeakerTlRatioFromTurns(speakerId, turns) {
  const duration = turns.map(t => (t.endTime - t.startTime)).reduce((a, b) => a + b);
  const speakerTurns = turns.filter(t => t.speakerId === speakerId).map(t => (t.endTime - t.startTime))
  let speakDuration = 0;
  if (speakerTurns && speakerTurns.length > 0)
    speakDuration = speakerTurns.reduce((a, b) => a + b);

  if (!duration) return 0;
  return (speakDuration / duration);
}

export function isTurnSmallEnough(turn) {
  return (turn.endTime - turn.startTime) <= 3;
}

export function getStartTime(turns, turnId, sentenceId, offset, wordText) {
  const matchingTurn = turns.find(t => t.turnId == turnId);
  if (!matchingTurn.words || matchingTurn.words.length === 0) return matchingTurn.startTime;
  const sentences = matchingTurn.prediction.filter(pred => pred.type === "SEGMENT" && pred.metadata.predictionType === "sentence");
  let startTime = matchingTurn.words[sentences[sentenceId].startWordIdx].endMs;
  let localOffset = 0;
  matchingTurn.words.slice([sentences[sentenceId].startWordIdx]).find(word => {
    if (word.str === wordText && offset === localOffset) {
      startTime = word.endMs;
      return true;
    } else {
      localOffset = word.str.length;
      return false;
    }
  });
  return startTime / 1000;
}

export function getEndTime(turns, turnId, sentenceId, offset, wordText) {
  const matchingTurn = turns.find(t => t.turnId == turnId);
  if (!matchingTurn.words || matchingTurn.words.length === 0) return matchingTurn.endTime;
  const sentences = matchingTurn.prediction.filter(pred => pred.type === "SEGMENT" && pred.metadata.predictionType === "sentence");
  let endTime = matchingTurn.words[sentences[sentenceId].endWordIdx].endMs;
  let localOffset = 0;
  matchingTurn.words.slice(0, [sentences[sentenceId].endWordIdx]).reverse().find(word => {
    if (word.str === wordText && offset === localOffset) {
      endTime = word.endMs;
      return true;
    } else {
      localOffset = word.str.length;
      return false;
    }
  });
  return endTime / 1000;
}

export function getNextTimeToPlay(turns, currentTime, selectedSpeakers) {
  if (!selectedSpeakers) return currentTime;
  const nextTurn = turns.find(turn => {
    if (turn.startTime >= currentTime && selectedSpeakers.includes(turn.speakerId) && !isTurnSmallEnough(turn)) {
      return true;
    }
  })
  //if no next turn is found. Go to end of call and stop playing
  if (!nextTurn) return turns[turns.length - 1].endTime;
  return nextTurn.startTime;
}

export function getTurnForTime(turns, time) {
  return turns.find((turn) => {
    if (time >= turn.startTime && time <= turn.endTime) {
      return true;
    }
    return false;
  });
}

export function canThisTurnBePlayed(turns, time, selectedSpeakers) {
  const turn = getTurnForTime(turns, time);
  if (!selectedSpeakers || (turn && selectedSpeakers.includes(turn.speakerId)))
    return true;
  else
    return false;
}

export function doesNameMatch(speakerParam, contact) {
  const speaker = speakerParam.toLowerCase().replace(/ /g, '');
  const contactName = getContactName(contact).toLowerCase();
  const firstName = contactName.split(" ")[0].toLowerCase();
  const lastName = contactName.split(" ").length > 1 && contactName.split(" ")[1].toLowerCase();
  if (speaker.startsWith(firstName) && speaker.endsWith(lastName)) return true;
  return speaker === getContactName(contact).toLowerCase().replace(/ /g, '')
}

export function getTurnSpeaker(turn, speakers) {
  return speakers && speakers[turn.speakerId - 1];
}

export function getMatchingThumbnailSprite(thumbnailSprites, duration, percent) {
  const time = duration * percent;
  let matchingSprite = thumbnailSprites.find(sprite => sprite.start_time <= time && sprite.end_time >= time);
  if (!matchingSprite) {
    const index = parseInt(Math.floor((percent) / (1 / thumbnailSprites.length)));
    matchingSprite = thumbnailSprites[index];
  }
  return matchingSprite;
}

export function getMatchingThumbnailSpriteForSharedCall(thumbnailSprites, shareDetails, relativeTime) {
  const time = shareDetails.startTime + relativeTime;
  let matchingSprite = thumbnailSprites.find(sprite => sprite.start_time <= time && sprite.end_time >= time);
  return matchingSprite;
}