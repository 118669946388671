import React, {
  useRef, useEffect, useState,
} from "react";
import { Checkbox, CircularProgress } from "@material-ui/core";
import { MentionsInput, Mention } from "react-mentions";
import {
  mentionInputStyle,
} from "app/styles/components/mentionStyles";
import { getClientOS, _formatSecondsToTime } from "app/utils/helpers";
import { getInteractionicon } from "../MeetingIcon";
import LockedIcon from "app/styles/assets/images/icons/comments/locked.svg"
import UnlockedIcon from "app/styles/assets/images/icons/comments/unlocked.svg"
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import SendIcon from "app/styles/assets/images/icons/comments/send.svg";

const AddCommentArea = props => {
  const auth = useSelector(store => store.auth);
  const [duration, setDuration] = useState(props.duration);
  const ref = useRef(null);
  const returnCopy = getClientOS() === "Windows" ? "Enter" : "Return";


  useEffect(() => {
    if (ref && ref.current) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
          ref.current.autofocus = true;
        }
      }, 1000);
    }
  });

  useEffect(() => {
    if (props.comment === "") {
      setDuration(props.duration);
    }
  }, [props.duration]);

  function isValidLink() {
    if (props.isDealComment && props.interactionId !== null) return true;
    return duration !== 0;
  }

  function handlePrivacyChange() {
    props.togglePrivacy();
  }

  return (
    <div
      className="Comment__addCommentArea"
      style={{ height: props.height }}
      onClick={props.handleExpand}
    >
      <div className="Comment__expandedSection AddComment__container">
        <div className="AddComment__inputContainer">
          <MentionsInput
            className="AddComment"
            value={props.comment}
            onChange={props.setComment}
            onKeyDown={props.onKeyDown}
            allowSpaceInQuery
            placeholder="Type a comment. Use '@' to tag a person. Use '!' to tag a slack channel."
            inputRef={ref}
            displayTransform={login => `@${login}`}
            style={mentionInputStyle}
          >
            <Mention
              className="AddComment__highlight"
              trigger="@"
              markup="@(__display__)"
              appendSpaceOnAdd
              onAdd={props.addMention}
              data={props.users}
            />
            <Mention
              className="AddComment__highlight"
              trigger="!"
              markup="!(__display__)"
              appendSpaceOnAdd
              onAdd={props.addChannel}
              data={props.slackChannels}
            />
          </MentionsInput>
        </div>
        <div className="AddComment__footer">
          {isValidLink() && <div className="AddComment__linkIdDiv">
            <Checkbox size="small" color="primary" className="noPadding" checked={props.commentLinked} onChange={props.toggleCommentLinked} />
            {props.isDealComment &&
              <>
                <img src={getInteractionicon(props.interactionType)} className={"AddComment__linkIdDivIcon"} />
                <span title={props.interactionName} className="AddComment__linkIdDivText">
                  {props.interactionName}
                </span>
              </>}
            {!props.isDealComment && <span className="AddComment__linkIdDivText">
              {"@" + _formatSecondsToTime(duration)}
            </span>}
          </div>}
          <div className="AddComment__lock" onClick={handlePrivacyChange}>
            <img data-tip data-for={"PRIVATE_LOCK"} src={props.isPrivate ? LockedIcon : UnlockedIcon} className="AddComment__lockIcon" />
            <ReactTooltip delayshow={5000} id={"PRIVATE_LOCK"} type="light" effect="solid" className="WhiteTooltip">
              <span className="WhiteTooltip__mainCopy">{props.isPrivate ? "🔒 Private Comment" : "📢 Public Comment"}</span>
              <span className="WhiteTooltip__subCopy">{props.isPrivate ? "Private comments are only visible to people you tag." : "Public comments can be seen by everyone who has access to this call."}</span>
            </ReactTooltip>
          </div>

          <div className="AddComment__footerRight">
            {props.loading && <CircularProgress color="primary" size={20} />}
            {!props.loading &&
              <img
                className="AddComment__postIcon"
                onClick={props.handleAddComment}
                src={SendIcon}
              />}
          </div>
        </div>
      </div>
      <div className="AddComment__helperText">
        <b>{returnCopy}</b>&nbsp;to send &emsp;&emsp;<b>Shift + {returnCopy}</b> &nbsp;to add a new line
      </div>
    </div>
  );
};

export default AddCommentArea;