import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CircularProgress, SnackbarContent } from "@material-ui/core";

const variantIcon = {
  SUCCESS: <CheckCircleIcon className="Snackbar__icon" />,
  WARNING: <WarningIcon className="Snackbar__icon" />,
  ERROR: <ErrorIcon className="Snackbar__icon" />,
  INFO: <InfoIcon className="Snackbar__icon" />,
  LOADING: <CircularProgress size={20} color="white" className="Snackbar__icon" />,
};

function SimpleSnackbar({
  msg, open, setVisiblity, autoHideDuration, type,
}) {
  function handleClick() {
    setVisiblity(true);
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }

    setVisiblity(false);
  }

  const placement = {
    vertical: "bottom",
    horizontal: "center",
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={placement}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <SnackbarContent
          contentprops={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id" className="Snackbar__msg">{variantIcon[type]}{msg}</span>}
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
          className={"Snackbar__" + type} />
      </Snackbar>
    </div>
  );
}

SimpleSnackbar.defaultProps = {
  autoHideDuration: 5000,
};

export default SimpleSnackbar;
