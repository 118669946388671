import React, {
  useRef, useEffect, useState, useCallback,
} from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { MentionsInput, Mention } from "react-mentions";
import {
  mentionInputStyle,
} from "app/styles/components/mentionStyles";
import { connect } from "react-redux";
import { _formatSecondsToTime } from "app/utils/helpers";
import { getAllUsers } from "app/utils/network";
import { clone } from "lodash";
import { Button } from "../../form";
import FooterButton from "./FooterButton";
import SaveComment from "app/styles/assets/images/icons/comments/SaveComment.svg";
import SaveCommentHover from "app/styles/assets/images/icons/comments/SaveCommentHover.svg";
import CancelComment from "app/styles/assets/images/icons/comments/CancelComment.svg";
import CancelCommentHover from "app/styles/assets/images/icons/comments/CancelCommentHover.svg";

const UpdateCommentArea = props => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref && ref.current) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.focus();
          ref.current.autofocus = true;
        }
      }, 1000);
    }
  });


  return (
    <div
      className="Comment__UpdateCommentArea"
      onClick={props.handleExpand}
    >
      <Grid
        container
        alignItems="flex-start"
        justify="space-between"
        className="Comment__expandedSection"
      >
        <Grid item xs={12}>
          <MentionsInput
            className="UpdateComment"
            value={props.comment}
            onChange={props.setComment}
            onKeyDown={props.onKeyDown}
            allowSpaceInQuery
            placeholder="Add a comment..."
            inputRef={ref}
            displayTransform={login => `@${login}`}
            style={mentionInputStyle}
          >
            <Mention
              className="AddComment__highlight"
              trigger="@"
              markup="@(__display__)"
              appendSpaceOnAdd
              onAdd={props.addMention}
              data={props.users}
            />
          </MentionsInput>
        </Grid>
        <div className="Comment__footer">
          <div className="actions">
            <FooterButton id={props.id} icon={CancelComment} hoverIcon={CancelCommentHover} buttonLabel="Cancel" onClick={props.handleEditCancel} />
            <FooterButton id={props.id} loading={props.loading} color="#3150D2" hoverColor="#3038B7" icon={SaveComment} hoverIcon={SaveCommentHover} onClick={props.updateComment} buttonLabel={props.isNewReply ? "Post" : "Save"} />
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default UpdateCommentArea;
