import React, { useState } from "react";
import { ContactIcon } from "app/designSystem";
import ShareIconNew from "app/designSystem/icons/ShareIconNew.svg";
import { getContactName } from "../../utils/helpers";
import { TextBase } from "app/designSystem";

const ContactWithCrmLink = ({ contact, crmLinks, key }) => {
    const openCrmContactPage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(crmLinks["CONTACT"].replace("__ID__", contact?.crmId));

        if (crmLinks && crmLinks?.CONTACT && contact && contact?.crmId) {
            window.open(crmLinks["CONTACT"].replace("__ID__", contact?.crmId), "_blank");
        }
    }

    return (
        <div className="my-2 " key={key} >
            <div className="w-full flex justify-between">
                <div className="flex">
                    <ContactIcon />
                    <div className="DealView__Header__contactNameContainer text-base pl-2">
                        <span className="font-medium text-gray-900">
                            {getContactName(contact)}
                        </span>
                        <span className="text-gray-500 ">
                            {contact.email}
                        </span>
                    </div>
                </div>
                {
                    <div className="flex justify-between my-auto cursor-pointer " onClick={openCrmContactPage} >
                        <TextBase className="text-wingman-brand">
                            View in CRM
                        </TextBase>
                        <img src={ShareIconNew} width={16} height={16} />
                    </div>
                }
            </div>
        </div>

    );
};
ContactWithCrmLink.propTypes = {};
ContactWithCrmLink.defaultProps = {};
export default ContactWithCrmLink;

