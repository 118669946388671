import React, { useRef, useEffect } from 'react'
import metrics from "app/utils/metrics";
import { useIdle } from 'react-use';

export const usePageTimeTracker = (pageNames) => {
    const isIdle = useIdle(5 * 60 * 1000); //stop tracking if idle for more than 5 mins 
    const interval = useRef(null);

    useEffect(() => {
        try {
            if (isIdle && interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            } else {
                interval.current = setInterval(sendPageTimeToHeap, 30000);
            }
        } catch (error) {
            console.log("error with usePageTimeTracker", error);
        }
        return () => {
            if (interval.current) {
                clearInterval(interval.current);
                interval.current = null;
            }
        };
    }, [isIdle]);

    function sendPageTimeToHeap() {
        if (!document.hidden) {
            pageNames.map(pageName => metrics.logEventHeap(pageName + "__timeSpent", { timeSpent: 0.5 }));
        }
    }

    return null;
}