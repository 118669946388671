import React from 'react';
import TeamDashboardCallDispositions from './TeamDashboardCallDispositions';
import TeamDashboardAgentTable from "./TeamDashboardAgentTable";
import { Grid, Paper } from '@material-ui/core';
import { Line } from "react-chartjs-2";

const headingStyle = {
  display: 'flex',
  flexDirection: 'row',
  padding: '20px',
  fontSize: '18px',
  fontWeight: '600',
  alignItems: 'baseline'
};

const flatten = data => {
  return Object.keys(data).map(userId => ({
    userId,
    displayName: data[userId].displayName,
    email: data[userId].email,
    ...data[userId].stats
  }));
}

const TeamDashboardOverview = props => {
  const graphData = props.graphData;
  const videoCallStats = props.videoCallStats;
  const dialerCallStats = props.dialerCallStats;
  const videoCallDispositions = props.videoCallDispositions;
  const dialerCallDispositions = props.dialerCallDispositions;

  const data = canvas => {
    const ctx = canvas.getContext('2d');
    const totalCallsGradient = ctx.createLinearGradient(0, 0, 0, 200);
    totalCallsGradient.addColorStop(0.4, 'rgba(45, 91, 255, 0.34)');
    totalCallsGradient.addColorStop(1, 'rgba(83, 97, 255, 3.4e-05)');
    const dialerCallsGradient = ctx.createLinearGradient(0, 0, 0, 200);
    dialerCallsGradient.addColorStop(0.4, 'rgba(238, 45, 255, 0.3');
    dialerCallsGradient.addColorStop(1, 'rgba(210, 83, 255, 3e-05)');
    return {
      datasets: [
        {
          label: 'Conference calls',
          type: 'line',
          borderColor: "#4147ff",
          borderWidth: 2,
          radius: 0,
          backgroundColor: totalCallsGradient,
          data: graphData.map(d => d.videoCalls)
        }, {
          label: 'Dialer calls',
          type: 'line',
          borderColor: "#b538ff",
          borderWidth: 2,
          radius: 0,
          backgroundColor: dialerCallsGradient,
          data: graphData.map(d => d.dialerCalls)
        }
      ],
      labels: graphData.map(d => d.label)
    };
  }

  return (<>
    <Grid container spacing={3}>
      {graphData && graphData.length > 0 && <Grid item xs={12}>
        <Paper style={{ padding: '10px 20px 20px 20px' }}>
          <div style={headingStyle}>Total calls made</div>
          <Line data={data} options={{
            legend: {
              display: true,
              position: 'right'
            },
            scales: {
              xAxes: [{
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  maxTicksLimit: 10,
                  fontSize: 13,
                  fontColor: "#9EA5BD",
                }
              }],
              yAxes: [{
                stacked: true,
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 6,
                  fontSize: 13,
                  fontColor: "#9EA5BD",
                }
              }]
            },
            tooltips: {
              backgroundColor: 'white',
              displayColors: false,
              bodyFontColor: 'black',
              titleFontColor: 'black',
              axis: 'x',
              intersect: false
            }
          }} height={60} />
        </Paper>
      </Grid>}
      {videoCallStats && flatten(videoCallStats).length > 0 && <Grid item xs={12}>
        <Paper style={{ display: 'flex', flexDirection: 'column', maxHeight: '400px' }}>
          <div style={headingStyle}>
            Video calls<span style={{ color: 'grey', fontSize: '14px' }}>&nbsp;</span>
            <span style={{ marginLeft: 'auto' }}>
              <TeamDashboardCallDispositions key="video" type="video" dispositions={videoCallDispositions} />
            </span>
          </div>
          <TeamDashboardAgentTable isVideo data={flatten(videoCallStats)} />
        </Paper>
      </Grid>}
      {dialerCallStats && flatten(dialerCallStats).length > 0 && <Grid item xs={12}>
        <Paper style={{ display: 'flex', flexDirection: 'column', maxHeight: '400px' }}>
          <div style={headingStyle}>
            Dialer calls<span style={{ color: 'grey', fontSize: '14px' }}>&nbsp;</span>
            <span style={{ marginLeft: 'auto' }}>
              <TeamDashboardCallDispositions key="audio" type="dialer" dispositions={dialerCallDispositions} />
            </span>
          </div>
          <TeamDashboardAgentTable isDialer data={flatten(dialerCallStats)} />
        </Paper>
      </Grid>}
    </Grid>
  </>);
};

export default TeamDashboardOverview;