import React from "react";
import { PropTypes } from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import { SPaper } from "app/components/common";
import valueUp from "app/styles/assets/images/icons/value-up.png";
import valueDown from "app/styles/assets/images/icons/value-down.png";
import { _roundUp } from "app/utils/helpers";
import { Tabs, Tab } from "@material-ui/core";

/**
 * return color to draws charts
 * @param {string} color the color, e.g: purple, green, blue
 */
const getChartColor = color => {
  switch (color) {
    case "purple":
      return "#4A1058";
    case "blue":
      return "#00BECD";
    case "green":
      return "#54C282";
    default:
      return color;
  }
};

const tabs = ["day", "week", "month", "quarter", "year", "custom"];

class CustomChartWithTabs extends React.Component {
  constructor(props) {
    super(props);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.state = {
      activeTab: tabs.indexOf(props.activeTab),
    };
  }
  handleTabChange(e, value) {
    this.setState({
      activeTab: value,
    });
    this.props.onTabChange(getDuration(value));
  }
  render() {
    const { onTabChange } = this.props;
    return (
      <div className="Chart">
        <div className="Chart__head">
          <div className="Chart__head-avg">
            <span
              className="Chart__head-avg-number"
              style={{ color: getChartColor(this.props.color) }}
            >
              {this.props.avg}
            </span>
            <span
              className="Chart__head-avg-string"
              style={{ color: getChartColor(this.props.color) }}
            >
              Average
            </span>
          </div>
          <div>
            <div className="Chart__head-change">
              <span className="Chart__head-change-percent">{Math.abs(this.props.changeRate)}%</span>
              <img
                src={this.props.changeRate >= 0 ? valueUp : valueDown}
                className="Chart__valueIcon"
                alt="value change"
              />
            </div>
          </div>
        </div>
        {this.props.children}
        <div className="Chart__hidden">
          <div className="Chart__hidden-top-line" />
        </div>
        <div>
          <Tabs
            fullWidth
            onChange={this.handleTabChange}
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="1 day" />
            <Tab label="7 days" />
            <Tab label="1 month" />
            <Tab label="1 quarter" />
            <Tab label="1 year" />
            <Tab disabled label="Custom" />
          </Tabs>
        </div>
        <ResponsiveContainer className="Chart__body" width="100%" height={this.props.height || 300}>
          <AreaChart data={this.props.data}>
            <CartesianGrid
              strokeDasharray="0"
              vertical={this.props.verticalGrid}
              horizontal={this.props.horizontalGrid}
            />
            <XAxis
              dataKey={this.props.xDataKey}
              label={this.props.xLabel}
              interval="preserveStartEnd"
              minTickGap={this.props.xMinTickGap}
              padding={{
                left: this.props.xPadding.left || 24,
                right: this.props.xPadding.right || 24,
              }}
            />
            <YAxis
              padding={{ top: 24 }}
              width={65}
              tickCount={this.props.yTickCount}
              domain={this.props.yDomain}
              allowDecimals={this.props.yAllowDecimals}
              axisLine={this.props.yAxisLine}
              tickLine={this.props.yAxisLine}
              tickFormatter={this.props.yTickFormatter}
              label={this.props.yLabel}
            />
            {this.props.avgLine && (
              <ReferenceLine
                y={this.props.avgLine.avg || 0}
                stroke="red"
                strokeDasharray="15"
                label={{
                  position: "insideBottomRight",
                  value: this.props.avgLine.label || "Team Average",
                }}
              />
            )}
            <Tooltip content={CustomTooltip} unit={this.props.unit} />
            <Area
              dot={
                this.props.stroke
                  ? {
                      stroke: getChartColor(this.props.color),
                      strokeWidth: 2,
                      r: this.props.dotRadius,
                      fill: "#FFFFFF",
                      fillOpacity: 1,
                    }
                  : null
              }
              activeDot={
                this.props.stroke
                  ? {
                      strokeWidth: 2,
                      r: this.props.dotRadius,
                    }
                  : null
              }
              type="linear"
              dataKey={this.props.fxDataKey}
              stroke={this.props.stroke ? getChartColor(this.props.color) : null}
              strokeWidth={2}
              fill={getChartColor(this.props.color)}
              fillOpacity={0.2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

const getDuration = index => {
  return ["day", "week", "month", "quarter", "year", "custom"][index];
};

const CustomTooltip = props => {
  if (props.payload && props.payload.length > 0) {
    return (
      <SPaper>
        {_roundUp(props.payload[0].value, 100)}
        {` ${props.unit}`}
      </SPaper>
    );
  }
  return null;
};

CustomChartWithTabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  color: PropTypes.string,
  avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeRate: PropTypes.number,
  verticalGrid: PropTypes.bool,
  horizontalGrid: PropTypes.bool,
  yTickCount: PropTypes.number,
  yDomain: PropTypes.array,
  xDataKey: PropTypes.string,
  fxDataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAllowDecimals: PropTypes.bool,
  avgLine: PropTypes.object,
  stroke: PropTypes.bool,
  yAxisLine: PropTypes.bool,
  height: PropTypes.number,
  yTickFormatter: PropTypes.func,
  xPadding: PropTypes.object,
  dotRadius: PropTypes.number,
  children: PropTypes.node,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  xMinTickGap: PropTypes.number,
};

CustomChartWithTabs.defaultProps = {
  activeTab: "month",
  color: "purple",
  avg: 0,
  changeRate: 0,
  verticalGrid: false,
  horizontalGrid: false,
  yTickCount: 3,
  xDataKey: "x",
  fxDataKey: "fx",
  yDomain: [0, "auto"],
  yAllowDecimals: false,
  avgLine: null,
  stroke: true,
  yAxisLine: true,
  height: null,
  yTickFormatter: null,
  xPadding: {},
  dotRadius: 7,
  children: null,
  xLabel: "",
  yLabel: "",
  xMinTickGap: 10,
};

export default CustomChartWithTabs;
