import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Redirect } from "react-router-dom"
import { logout } from "app/actions/auth";

class Logout extends React.Component {
	constructor(props) {
		super(props)
	}
	UNSAFE_componentWillMount() {
		this.props.logout()
	}
	render() {
		return (
			<Redirect to="/login" />
		);
	}
}

const mapStateToProps = store => {
	return {};
};

export default connect(
	mapStateToProps,
	{ logout },
)(Logout);