import { useState, useEffect } from "react";
import classNames from "classnames";
import { TextBase } from 'app/designSystem/typography/Typography'
import useSnackbar from 'app/utils/hooks/useSnackbar'

const TagsAutoComplete = ({ tags, selectedTags, onClick, onEnter, onBackSpace }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");
    const [localSuggestions, setLocalSuggestions] = useState(tags);
    const { setSnackbar } = useSnackbar();

    useEffect(() => {
        if (selectedTags.length > 0) {
            // Filter out the already selected tags from the suggestions
            setLocalSuggestions(tags.filter(tag => !selectedTags.includes(tag)));
        } else {
            setLocalSuggestions(tags);
        }
    }, [tags, selectedTags]);

    const onChange = (e) => {
        const userInput = e.target.value;

        // Filter our tags that don't contain the user's input
        const unLinked = localSuggestions.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);

        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
    };

    const onClickHandler = (e) => {
        onClick?.(e.target.innerText);
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        setInput("");
    };

    const tagIsValid = (tagName) => {
        if (selectedTags.includes(tagName)) {
            setSnackbar("Tag already exists", "ERROR");
            return false;
        }
        if (tagName.length === 0) {
            setSnackbar("Tag cannot be empty", "ERROR");
            return false;
        }
        return true;
    }

    const onKeyDown = (e) => {
        // const node = suggestionsRef?.current;

        // User pressed the enter key
        if (e.key === "Enter") {
            if (!tagIsValid(input.trim())) {
                return;
            }

            onEnter?.(filteredSuggestions[activeSuggestionIndex] || input.trim());
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            setInput("");
        }
        // User pressed the up arrow
        else if (e.key === "ArrowUp") {
            if (activeSuggestionIndex === 0) {
                return;
            }

            setActiveSuggestionIndex(activeSuggestionIndex - 1);
        }
        // User pressed the down arrow
        else if (e.key === "ArrowDown") {
            if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
                return;
            }

            setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
        // User pressed the backspace key
        else if (e.key === "Backspace") {
            if (input.length === 0) {
                setActiveSuggestionIndex(0);
                setShowSuggestions(false);
                setInput("");
                onBackSpace?.();
            }
        }
    };

    const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
            <ul className="border-gray-300 shadow-lg overflow-y-auto max-h-20 flex flex-col rounded-lg p-2 border bg-white absolute w-full max-w-[12rem] z-10">
                {filteredSuggestions.map((suggestion, index) => {
                    return (
                        <TextBase tabindex={index} key={suggestion} className={classNames("p-2 rounded-md cursor-pointer text-gray-900 hover:bg-gray-50 focus:bg-gray-50", index === activeSuggestionIndex ? "bg-gray-50" : "")} onClick={onClickHandler}>
                            {suggestion}
                        </TextBase>
                    );
                })}
            </ul>
        ) : null;
    };

    return (
        <div>
            <input
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={input}
                className="w-full border-none outline-none p-0 py-2"
            />
            {showSuggestions && input && <SuggestionsListComponent />}
        </div>
    );
};

export default TagsAutoComplete;
