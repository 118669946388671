import React from "react";
import { Modal } from "@material-ui/core";
import { TextBase, TextLarge, Button } from "app/designSystem";

const DisableFullCallLinkModal = ({ open, setOpenDisableLinkModal, revokeFullCallLink }) => {


    function onClose() {
        setOpenDisableLinkModal(false)
    }

    return (
        <div>
            <Modal open={open} close={onClose} className="Form__outerContainer">
                <div
                    className="!bg-white rounded-lg flex flex-col p-0 w-[22rem] h-[13rem] outline-none"
                >
                    <TextLarge textColor="text-red-500" className="px-5 mt-6">Disable this link?</TextLarge>
                    <TextBase textColor="text-coolGray-500" className="px-5 mt-0.25 text-left">This link will be permanently disabled.</TextBase>

                    <div className="px-3 flex mt-6 items-center justify-around">
                        <TextBase className="py-0.5 px-1.5 bg-coolGray-50 text-left rounded-sm" textColor="text-coolGray-500">A new link will be automatically generated upon disabling the existing link. </TextBase>
                    </div>

                    <div className="flex flex-row px-5 items-center justify-between mt-4">
                        <Button
                            variant="secondary"
                            className=" !border-coolGray-300 !px-8 !py-1.5 mr-2 "
                            onClick={() => onClose()}
                        >
                            <TextBase>Cancel</TextBase>
                        </Button>

                        <Button
                            variant="secondary"
                            className=" !border-white !px-4 !py-1.5 bg-red-600 "
                            onClick={() => revokeFullCallLink()}
                        >
                            <TextBase textColor="text-white">Yes, Disable this link</TextBase>
                        </Button>

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default DisableFullCallLinkModal