import React from "react";
import NoCommentsIcon from "app/styles/assets/images/icons/noComments.svg";

const NoComments = () => {
  return (
    <div className="NoCommentsContainer">
      <img className="icon" src={NoCommentsIcon} alt="No Comments" />
      <span className="title">No Comments</span>
      <span className="copy">No one has commented yet.</span>
    </div>
  );
};

export default NoComments;
