import React from "react";
import { TextBase as TextBaseComponent } from "./Typography";
//single story component( only one state(set of props) needs to be documented)
//use name anme for component and named export . So  need to rename actual component name to prevent duplicate variable

export default {
  title: "Components/Typography/TextBase",
  component: TextBaseComponent,
};



export const TextBase = (args) => {
  return <TextBaseComponent {...args} />;
};
TextBase.args = {
  children: "This is some primary text in wingman",
  textColor: "text-coolGray-900",
};