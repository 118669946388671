import React, { useState, useEffect, useCallback } from "react";
import { CustomTooltip, CustomChip } from "app/components/common";
import { InputBase } from "@material-ui/core";
import { useKeyListener } from "../../../utils/hooks/useKeyListener";

const TriggerWordInput = props => {
  return (
    <div className="battlecardForm__triggerContainer">
      <span className="battlecardForm__label">When someone says:</span>
      <TagFilledInputArea
        id={`Trigger`}
        label=" Add Trigger word"
        addTag={props.onAdd}
        removeTag={props.onRemove}
        tags={props.value.map(tag => tag.keyword)}
        {...props}
      />
    </div>
  );
};

const TagFilledInputArea = ({ currentTrigger, setCurrentTrigger, removeTag, addTag, id, tags, label }) => {

  const [hideInputField, setHideInputField] = useState(false);
  const handleEnterCallback = useCallback((e) => handleEnter(e, currentTrigger), [currentTrigger]);

  useKeyListener("addTagForm" + id, handleEnterCallback);


  function onChange(e) {
    setCurrentTrigger(e.target.value);
  }

  function handleAddTag(tag) {
    addTag(tag);
    setCurrentTrigger("");
    setHideInputField(true);
    setTimeout(() => {
      setHideInputField(false);
    }, 200);
  }

  function handleEnter(event, valueLocal) {
    event.stopPropagation();
    event.preventDefault();
    if (event.keyCode === 13) {
      handleAddTag(valueLocal);
    }
  }

  return (
    <div id={`addTagForm${id}`}>
      <div className="chipContainer">
        {tags.map((tag, i) => {
          return (
            <CustomChip
              type="mini"
              className="KeywordsTracking__chip"
              key={i}
              chipProps={{
                label: tag,
                onDelete: () => {
                  removeTag(i, tag);
                },
              }}
            />
          );
        })}
        {!hideInputField && (
          <CustomTooltip placement="bottom-end" enterDelay={500} title="Press enter to add">
            <InputBase
              id="tags"
              label={label}
              className="TagFilled__textField"
              placeholder="Add trigger word"
              value={currentTrigger}
              onChange={onChange}
            />
          </CustomTooltip>
        )}
      </div>
    </div>
  );
}


export default TriggerWordInput;
