import React, { useState } from "react";
import { Button } from 'app/designSystem'
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { PlayIcon, PauseIcon, Seek15Icon, Seek15ReverseIcon, KeyboardIcon, SpeedMeterIcon, FullScreenIcon } from 'app/designSystem/icons'
import PlaybackSpeedMenu from './PlaybackSpeedMenu'
// import { setFullScreenVideo } from "app/actions/screen";

const PlayerControls = ({
    isPlaying,
    handleSeek,
    setIsPlaying,
    playbackSpeed = 1,
    handleSpeedChange = () => { },
}) => {
    // const dispatch = useDispatch();
    // const { fullscreenVideo } = useSelector(state => state.screen);

    // const toggleFullScreen = () => {
    //     dispatch(setFullScreenVideo(!fullscreenVideo));
    // }

    // useEffect(() => {
    //     try {
    //         if (fullscreenVideo) {
    //             requestFullscreen(document.getElementById("Call__transcriptVideoPlayerContainer"))
    //         }
    //         else if (
    //             (document.fullscreenElement !== null && document.exitFullscreen) ||
    //             (document.webkitFullscreenElement !== null && document.webkitExitFullscreen) ||
    //             (document.mozFullScreenElement !== null && document.mozExitFullScreen) ||
    //             (document.msFullscreenElement !== null && document.msExitFullscreen)
    //         ) {
    //             exitFullscreen();
    //         }
    //     }
    //     catch (e) {
    //         setSnackbar("Fullscreen is not supported in your device.", "ERROR");
    //         console.log(e);
    //     }
    // }, [fullscreenVideo]);

    return (
        <div className="flex flex-row justify-center items-center">
            {/* <Button variant="tertiary">
                <KeyboardIcon />
            </Button> */}
            <Button variant="tertiary" onClick={() => handleSeek({ time: 15, reverse: true })}>
                <Seek15ReverseIcon className="mr-4" />
            </Button>
            <Button
                id="tape_play_btn"
                className="!rounded-full w-12 h-12"
                onClick={() => setIsPlaying(!isPlaying)}
            >
                {isPlaying ? <PauseIcon /> : <PlayIcon className="ml-1" />}
            </Button>
            <Button variant="tertiary" onClick={() => handleSeek({ time: 15 })}>
                <Seek15Icon className="ml-4" />
            </Button>
            <div className="">
                <PlaybackSpeedMenu currentSpeed={playbackSpeed} onChange={handleSpeedChange} />
            </div>
            {/* <Button variant="tertiary" onClick={toggleFullScreen}>
                <FullScreenIcon className="ml-4" />
            </Button> */}
        </div>
    )
}

export default PlayerControls;