import React from "react";
import loadingIcon from "app/styles/assets/images/loading1.gif";
import { connect } from "react-redux";

const Loading = props => {
  const loading = props.screen.loading || props.loading;
  return (
    <div className="modal" hidden={!loading}>
      <div className="modal__content">
        <img className="loadingFullScreen" src={loadingIcon} alt="loading..." />
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    screen: store.screen,
  };
};

Loading.propTypes = {};

Loading.defaultProps = {};

export default connect(
  mapStateToProps,
  {},
)(Loading);
