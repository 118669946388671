import React from 'react'
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { ReactComponent as InfoIcon } from "app/styles/assets/images/icons/info.svg";
import { TextXS } from 'app/designSystem';

const DealField = ({ icon, value, label, setHoverView }) => {
    const [viewRef] = useHoverListenerRef(() => setHoverView && setHoverView(true), () => setHoverView && setHoverView(false), 100);

    if (!value || value.trim() === "") return null;
    return (
        <div ref={viewRef} className="DealView__Header__dealFieldDiv">
            <img src={icon} className="DealView__Header__dealFieldIcon" />
            <div className="DealView__Header__dealFieldLabelValueDiv">
                <span className="DealView__Header__dealFieldValue flex flex-row">
                    {value}
                    {setHoverView && <InfoIcon className="ml-1" />}
                </span>
                <span className="DealView__Header__dealFieldLabel">{label}</span>
            </div>
        </div>
    );
}

export const DealFieldSmall = ({ icon, value, label }) => {
    if (!value || value.trim() === "") return null;

    return (
        <div className="DealFieldDiv">
            {icon && <img src={icon} className="DealFieldIcon" />}
            <div className="DealFieldLabelValueDiv">
                <TextXS
                    textColor="text-coolGray-900"
                    className={"whitespace-nowrap font-semibold"}>
                    {value}
                </TextXS>
                <TextXS
                    textColor="text-coolGray-500"
                    className="whitespace-nowrap">
                    {label}
                </TextXS>
            </div>
        </div>
    );
}

DealField.propTypes = {}
DealField.defaultProps = {}
export default DealField;