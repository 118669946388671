import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Button } from "app/components/form";
import { CustomLink } from "app/components/common";
import { setLoading } from "app/actions/screen";
import WingmanOnboardingIcon from "app/styles/assets/images/onboarding/mobileAppOnboarding.png";

class MobileAppDownload extends Component {
    constructor(props) {
        super(props);
        this.sync = this.sync.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            os: window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac",
            modalOpen: false,
        };
    }

    handleClose() {
        this.setState({
            modalOpen: false,
        });
    }

    async sync() {
        window.open("https://www.trywingman.com/mobileapp?utmSource=webApp&utmCampaign=downloadsPage&utmMedium=downloadCTAButton", "_blank");
    }

    render() {
        const { skipHandler } = this.props;
        return (
            <div className="Sync-Background verCon">
                <div className={"DetailedOnboarding__outerContainerTransparent"}>
                    <div className="DetailedOnboarding__imageContainer">
                        <span className="DetailedOnboarding__heading">Wingman Mobile App</span>
                        <span className="DetailedOnboarding__subheading">
                            Play. Learn. Coach. On the go.
                        </span>
                        <div className="DetailedOnboarding__iconContainer">
                            <img className={"wingman_icon"} src={WingmanOnboardingIcon} alt="Logo" />
                        </div>
                    </div>
                    <div className="DetailedOnboarding__textContainer">
                        <div className="DetailedOnboarding__tipsContainer">
                            <span className="DetailedOnboarding__tip">
                                1. Review calls from anywhere 🗺:
                            </span>
                            <span className="DetailedOnboarding__tipCopy">
                                Scan call transcripts to review sales calls from anywhere. Or prep for follow up. Or learn from your peers. Do what you need to, lightning-fast.
                            </span>
                            <span className="DetailedOnboarding__tip">
                                2.  Coach effectively instantly⚡️:
                            </span>
                            <span className="DetailedOnboarding__tipCopy">
                                Find the right calls to review in seconds. Listen to a specific speaker (coming soon) and share coaching feedback via inline comments.
                            </span>
                            <span className="DetailedOnboarding__tip">
                                3.  Know what’s next in a jiffy 🗓:
                            </span>
                            <span className="DetailedOnboarding__tipCopy">
                                Swipe for upcoming meetings. Tap to know who’s involved — people, accounts and more. When you need to, toggle call recording on/off in a tap.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="DetailedOnboarding__buttonContainer">
                    <Button theme="dark" onClick={this.sync}>
                        {`Download Now`}
                    </Button>
                </div>
                <CustomLink onClick={skipHandler}>Skip</CustomLink>
            </div >
        );
    }
}

const mapStateToProps = store => {
    return { ...store };
};

MobileAppDownload.propTypes = {
    setLoading: PropTypes.func,
    skipHandler: PropTypes.func.isRequired,
};

MobileAppDownload.defaultProps = {
    setLoading: () => { },
};

export default connect(
    mapStateToProps,
    { setLoading },
)(MobileAppDownload);
