import React from "react";
import loadable from '@loadable/component'

const TeamDashboard = loadable(() => import('app/containers/index').then(m => m.TeamDashboard));

const CallsDashboard = loadable(() => import('app/containers/index').then(m => m.CallsDashboard));

const Call = loadable(() => import('app/containers/index').then(m => m.Call));

const CallTablePage = loadable(() => import('app/containers/index').then(m => m.CallTablePage));

const Search = loadable(() => import('app/containers/index').then(m => m.Search));

const NotificationsPage = loadable(() => import('app/containers/index').then(m => m.NotificationsPage));
const ActivityDashboard = loadable(() => import('app/containers/index').then(m => m.ActivityDashboard));
const AccountView = loadable(() => import('app/containers/index').then(m => m.AccountView));
const GameTapesLatest = loadable(() => import('app/containers/index').then(m => m.GameTapesLatest));
const FolderView = loadable(() => import('app/containers/index').then(m => m.FolderView));
const GameTapeView = loadable(() => import('app/containers/index').then(m => m.GameTapeView));
const DekstopLoginPage = loadable(() => import('app/containers/index').then(m => m.DekstopLoginPage));

export {
    TeamDashboard,
    CallsDashboard,
    Call,
    CallTablePage,
    Search,
    NotificationsPage,
    ActivityDashboard,
    AccountView,
    GameTapesLatest,
    FolderView,
    GameTapeView,
    DekstopLoginPage
}
