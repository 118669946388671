import { sendRequest } from '../network'


export const getAllEmailTemplates = async () => {
    return await sendRequest('/emailTemplate/get_all_templates');
}

export const addEmailTemplate = async (template) => {
    return await sendRequest('/emailTemplate/add_template', template);
}

export async function setEmailTemplateForPostCallAlert(templateId) {
    const params = new URLSearchParams();
    params.append("templateId", templateId);
    params.append("type", "POST_CALL");
    const response = await sendRequest("/user/set_default_email_template", params);
    return response;
}

export async function getEmailTemplateForPostCallAlert() {
    const params = new URLSearchParams();
    params.append("type", "POST_CALL");
    const response = await sendRequest("/user/get_default_email_template", params);
    return response;
}