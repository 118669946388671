import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { TextBase, Button } from "app/designSystem"
import { IconButton } from "@material-ui/core";
import { ReactComponent as Close } from "app/styles/assets/images/icons/Close_Icon.svg"
import { getLocalValueInInt, incrementLocalValueInInt } from "app/utils/hooks/usePersistedLocal";


const TranscriptionMessage = ({ }) => {

    const [isTranscriptMessageEnabled, setIsTranscriptMessageEnabled] = useState(true);
    const transcriptionMessageCount = getLocalValueInInt("transcriptionMessageCount")
    const transcriptionMessageCloseCount = getLocalValueInInt("transcriptionMessageCloseCount")

    useEffect(() => {
        incrementLocalValueInInt("transcriptionMessageCount", 1)
    }, [])


    function closeTranscriptMessage() {
        incrementLocalValueInInt("transcriptionMessageCloseCount", 1)
        setIsTranscriptMessageEnabled(!isTranscriptMessageEnabled);
    }

    function openTranscriptMessageDoc() {
        incrementLocalValueInInt("transcriptionMessageCloseCount", 2)
        setIsTranscriptMessageEnabled(!isTranscriptMessageEnabled);
        window.open("https://www.trywingman.com/blog-posts/how-good-is-wingman-call-transcription?utm_source=callReview&utmCampaign=inAppTranscriptionMessaging")
    }

    return (
        <div>
            {isTranscriptMessageEnabled && transcriptionMessageCount <= 5 && transcriptionMessageCloseCount < 2 &&
                < div className="Call__transcriptMessageContainer" >
                    <div className="flex flex-row items-center justify-between bg-purple-50 w-full py-2 pl-4" >
                        <TextBase className="w-2/3" textColor="text-violet-600">
                            We just benchmarked Wingman's transcription system.
                        </TextBase>
                        <div className="flex flex-row items-center">
                            <Button
                                variant="secondary"
                                className="w-30 h-10 !border-white mr-2"
                                onClick={openTranscriptMessageDoc}
                                id={"transcriptionMessageDoc"}
                            >
                                <TextBase textColor="text-coolGray-900">
                                    Learn More
                                </TextBase>
                            </Button>
                            <IconButton onClick={closeTranscriptMessage}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>}
        </div>
    )

};

export default TranscriptionMessage;