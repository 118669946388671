import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import { escapeRegExp } from "lodash";

/**
 * Pass a list of words and a text and it will return the words highlighted in the text
 */

const WordsHighlighter = ({ text, wordsList }) => {
    const makeBold = (textContent, listOfWords) => {
        const words = listOfWords.filter(Boolean).filter(word => word.length >= 3).map(word => word.toLowerCase()).map(word => escapeRegExp(word));

        if (listOfWords.length === 0 || words.length === 0) {
            return textContent;
        }

        const wordsRegex = new RegExp(`(?!\\.)(${words.join('|')})`, 'gi'); // The \\. is just to exclude the urls from being excluded, rest is just the list of words to be highlighted

        return (
            textContent.replace(wordsRegex, '<span class="font-medium text-wingman-brand bg-blue-50 not-italic px-1 rounded">$1</span>')
        )
    }

    return (
        <ul>
            <li>
                {
                    ReactHtmlParser(makeBold(text, wordsList))
                }
            </li>
        </ul>
    )
}

WordsHighlighter.propTypes = {
    text: PropTypes.string.isRequired,
    wordsList: PropTypes.arrayOf(PropTypes.string).isRequired
}

WordsHighlighter.defaultProps = {
    wordsList: [],
    text: ''
}

export default WordsHighlighter;