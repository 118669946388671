import React, { useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { refreshUserAction } from "app/actions/auth";
import { VoiceFingerprint } from "app/components/settings";
import { CustomSelect } from "../common";
import { REP, MANAGER, OBSERVER } from "../../constants";
import ResetPasswordForm from "./ResetPasswordForm";
import { sendRequest, updateProfile } from "../../utils/network";
import { useRef } from "react";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { doesUserHaveAccess } from "../../utils/helpers";
import ProfileImageEditor from "./ProfileImageEditor";
import { useEffect } from "react";
import { isWordEmptyOrNull } from "../../utils/helpers";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import Cookies from 'js-cookie'
import Input from 'app/designSystem/form/Input';
import { TextBase } from "app/designSystem/typography/PrimaryText.stories";
import { Button } from "app/designSystem";



const ProfileSettings = ({ auth, refreshUserAction }) => {
  const [name, setName] = useState(auth.data.user.displayName);
  const [role, setRole] = useState(auth.data.user.roleInfo ? auth.data.user.roleInfo.role : null);
  const [isRecording, setRecording] = useState(auth.isRecording);
  const [loading, setLoading] = useState(false);
  const [defaultCallsPrivate, setDefaultCallsPrivate] = useState(auth.data.user.defaultCallsPrivate);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [jobTitle, setJobTitle] = useState(auth.data.user.jobTitle);
  const timeoutJobTitle = useRef(null);

  // Dummy comment to trigger build , NEtlify only adds branch to AB TEST if published from git CI hook 😡
  const isStringsAiAccount = useSelector(state => {
    return state?.auth?.isStrings
  })
  const { setSnackbar } = useSnackbar();
  const trigger = <Button variant={"secondary"} className="!py-2">
    Set up Voice Fingerprint
  </Button>;
  const [isNewHeaderSidebarEnabled, setIsNewHeaderSidebarEnabled] = useState(false)


  const debouncedProfileState = useMemo(() => debounce(saveChanges, 500), []);

  async function saveChanges(localParams) {
    setLoading(true);
    setSnackbar("Your changes are being processed", "INFO", 6000);
    const res = await updateProfile({ name, role, isRecording, jobTitle, ...localParams });
    await refreshUserAction();
    setLoading(false);
    setSnackbar("Changes saved.", "SUCCESS");
    return res;
  }

  useEffect(() => {
    reset();
  }, [auth.data.user])

  const getNewHeaderSidebarStatusFromCookie = () => {
    return Cookies.get('nf_ab') === 'new_header_sidebar'
  }

  useEffect(() => {
    console.log('use effect setting state from cookie', getNewHeaderSidebarStatusFromCookie())
    setIsNewHeaderSidebarEnabled(getNewHeaderSidebarStatusFromCookie())
  }, [])

  const toggleNewHeaderSidebar = (value) => {
    setIsNewHeaderSidebarEnabled(value)

    if (value) {
      console.log("setting cookie to new_header_sidebar branch ")
      Cookies.set('nf_ab', 'new_header_sidebar', { expires: 7 })//expiry in days

    }
    else {
      Cookies.remove('nf_ab')
    }

    location.reload(true)
  }
  function reset() {
    setName(auth.data.user.displayName);
    setRole(auth.data.user.roleInfo ? auth.data.user.roleInfo.role : null);
    setRecording(auth.isRecording);
  }

  function handleJobTitleChange(e) {
    const jobTitleLocal = e.target.value;
    setJobTitle(jobTitleLocal);
    if (timeoutJobTitle.current !== null) { clearTimeout(timeoutJobTitle.current); timeoutJobTitle.current = null; }
    timeoutJobTitle.current = setTimeout(() => { saveChanges({ jobTitle: jobTitleLocal }); }, 1500);
  }

  function handleRoleChange(role) {
    let recordingLocal = isRecording;
    if (role === OBSERVER) {
      recordingLocal = false;
      setRecording(false);
    }
    if (role === REP) {
      recordingLocal = true;
      setRecording(true);
    }
    setRole(role);
    saveChanges({ role, isRecording: recordingLocal });
  }

  async function makeAllMyCallsPrivate(value) {
    const params = new URLSearchParams();
    params.append("value", value);
    await sendRequest("/user/make_all_my_calls_private", params);
    refreshUserAction();
    setSnackbar("Changes Saved", "SUCCESS");
    setDefaultCallsPrivate(value);
  }

  function hasPrivacySetterPermission() {
    const { user } = auth.data;
    const customer = auth.stringsCustomer;

    if (customer.settings.authorizedCallAccessChangers) {
      return doesUserHaveAccess(customer.settings.authorizedCallAccessChangers, user);
    }
    return false;
  }




  function handleRecordingChange(recordingParam) {
    if (recordingParam && role === OBSERVER) return;
    if (!recordingParam && role === REP) return;
    setRecording(recordingParam);
    saveChanges({ isRecording: recordingParam });
  }

  function handleNameChange(e) {
    const nameLocal = e.target.value;
    setName(nameLocal);
    if (isWordEmptyOrNull(nameLocal)) {
      debouncedProfileState.cancel();
      setSnackbar("Name cannot be empty", "ERROR");
    }
    else {
      debouncedProfileState({ name: nameLocal })
    }
  }

  return (
    <div className="Settings__profileContainer">
      <span className="Settings__header">Account Details</span>
      <div className="row horCon">
        <div className="col verCon" style={{ flex: "3 1 0" }}>
          <div className="row horCon">
            <div className="col verCon">

              <Input label="Name" value={name} onChange={handleNameChange} />
            </div>
            <div className="col verCon">
              <Input value={auth.data.user.emailId} disabled label="Email Address" />
            </div>
            <div className="col verCon">
              <TextBase >Password</TextBase>
              <Button
                loading={false}
                variant="secondary"
                onClick={() => setShowResetPasswordForm(true)}
                className="!p-3"
              >
                Change Password
              </Button>
              <ResetPasswordForm
                open={showResetPasswordForm}
                hide={() => setShowResetPasswordForm(false)}
              />
            </div>
          </div>
          <div className="row horCon">
            <div className="col verCon">
              <Input label="Job Title" value={jobTitle} onChange={handleJobTitleChange} />
            </div>
            <div className="col verCon">
              <TextBase>Role</TextBase>
              {auth.isAdmin && (
                <CustomSelect
                  menuItems={[
                    { value: REP, label: "Rep" },
                    { value: MANAGER, label: "Manager" },
                    { value: OBSERVER, label: "Observer" },
                  ]}
                  value={role}
                  onChange={e => {
                    handleRoleChange(e.target.value);
                  }}
                />
              )}
              {!auth.isAdmin && (
                <>
                  <Input data-tip data-for="cantChangeRoleInfo" value={role} disabled />
                  <ReactTooltip id="cantChangeRoleInfo" type="light" effect="solid" className="WhiteTooltip">
                    <span > Contact an admin to change your role</span>
                  </ReactTooltip>
                </>
              )}
            </div>
            <div className="col verCon left">
              <TextBase>Recording User?</TextBase>
              <Checkbox
                color="primary"
                className="noPadding"
                checked={isRecording}
                disabled={!auth.isManager}
                onChange={e => {
                  handleRecordingChange(e.target.checked);
                }}
                value="recording"
              />
            </div>
          </div>
          {auth.isRecording && (
            <div className="row horCon">
              <div className="col verCon left">
                <TextBase>Voice Fingerprint</TextBase>
                <VoiceFingerprint trigger={trigger} />
              </div>
            </div>
          )}
          {auth.stringsCustomer.featuresGated.callPrivacy && auth.isRecording && hasPrivacySetterPermission() && <div className="row horCon">
            <div className="col verCon left">
              <TextBase>Call Privacy</TextBase>
              <div className="row-centered">
                <span className="Settings__notifications-typeExplanation">
                  Make my calls private by default
                </span>
                <div style={{ marginLeft: "10px" }}>
                  <Checkbox
                    color="primary"
                    className="noPadding !text-brand-blue-1"
                    checked={defaultCallsPrivate}
                    onChange={e => {
                      makeAllMyCallsPrivate(e.target.checked);
                    }}
                    value="defaultPrivacySetting"
                  />
                </div>
              </div>
            </div>
          </div>}
        </div>
        <ProfileImageEditor auth={auth} />
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(ProfileSettings);
