import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import OutsideClickHandler from 'react-outside-click-handler'
import { Input } from 'app/designSystem/form';
import { TextBase, SearchIcon } from "app/designSystem";
import { isStringSubstring } from 'app/utils/helpers';

export default function DropdownWithSearch({ dropdownOptions, selectedOptions, onOptionClick, onOutsideClick }) {

    const [searchText, setSearchText] = useState("")
    const [searchResults, setSearchResults] = useState(dropdownOptions)

    useEffect(() => {
        let availableOptions = dropdownOptions
        if (selectedOptions) {
            availableOptions = dropdownOptions.filter(option => {
                const fieldValueArray = selectedOptions.map(field => field.value)
                if (fieldValueArray.includes(option.value)) return false
                return true
            })
        }
        const filteredData = availableOptions?.filter((data) => isStringSubstring(data.label, searchText))
        setSearchResults(filteredData)
    }, [searchText, selectedOptions])

    return (
        <OutsideClickHandler
            onOutsideClick={onOutsideClick}>
            <div
                className="absolute text-base bg-white min-w-[15rem] max-h-[15rem] overflow-y-auto z-10 p-2 rounded-lg shadow-lg border">
                <Input
                    autoFocus={true}
                    className="bg-coolGray-100 border-none"
                    inputClassname="py-2 bg-coolGray-100 font-medium"
                    placeholder="Search CRM Fields"
                    value={searchText}
                    startIcon={<SearchIcon />}
                    onChange={(e) => setSearchText(e.target.value)}
                />

                {searchResults.map((data) => (
                    <TextBase
                        key={data.value}
                        className="p-2 m-0.5 rounded cursor-pointer hover:bg-coolGray-100"
                        onClick={() => onOptionClick(data)}>
                        {data.label}
                    </TextBase>))}
            </div>
        </OutsideClickHandler>
    )
}

DropdownWithSearch.propTypes = {
    dropdownOptions: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func.isRequired
}
