import React, { useState, useEffect } from 'react'
import { getContactName, _formatTalkListenRatio, _formatSecondsToPrettyTime, _formatDateToActivityDateFormat, checkIfExternal } from '../../utils/helpers';
import { fetchDataSafely } from "../../utils/dataUtils";
import { useSelector } from 'react-redux';
import { CallAttendeePopup } from './AttendeePopup';
import { useHoverListenerRef } from '../../utils/hooks/useHoverListener';
import { getMeetingIcon } from '../call/MeetingIcon';

const CallCard = ({ call, crmData, activeInteraction }) => {
    const [contacts, setContacts] = useState([]);
    const [showContactPopup, setShowContactPopup] = useState();
    const customerDomains = useSelector(store => store.auth.stringsCustomer.authorizedDomains);
    const [contactRef] = useHoverListenerRef(() => setShowContactPopup(true), () => setShowContactPopup(false), 300);

    useEffect(() => {
        if (!call?.meetingInfo?.meetingAttendees || call?.meetingInfo?.meetingAttendees.length === 0) return;
        const contactsLocal = [];
        if (!crmData.contacts) crmData.contacts = [];
        let allContacts = [...crmData.contacts, ...fetchDataSafely(call, "crmData.contactDocumentList", [])]
        call.meetingInfo.meetingAttendees.map(attendee => {
            if (checkIfExternal(attendee, customerDomains)) {
                let match = allContacts.find(c => {
                    if (c.emails) {
                        return c.emails.includes(attendee.email);
                    }
                    return false;
                });
                if (!match) {
                    match = { email: attendee.email };
                }
                contactsLocal.push(match);
            }
        })
        setContacts(contactsLocal);
    }, [])

    return (
        <div className={`InteractionCard ${activeInteraction.callId === call.callId ? "InteractionCardActive" : ""}`}>
            <div className="InteractionCard__iconColumn">
                <img src={getMeetingIcon(call.callType)} className="InteractionCard__iconColumnIcon" />
            </div>
            <div className="InteractionCard__contentColumn">
                {contacts && contacts.length > 0 && <div className="InteractionCard__fromNameContainer">

                    <span className={"InteractionCard__fromName"}>{getContactName(contacts[0])}</span>
                    {contacts.length > 1 &&
                        <span ref={contactRef} className="InteractionCard__viewAllLinkDiv">
                            <span className="InteractionCard__contactName InteractionCard__viewAllLink">+{contacts.length - 1}</span>
                            {showContactPopup && <CallAttendeePopup type="small" contacts={contacts} />}
                        </span>
                    }
                </div>
                }
                <span className="InteractionCard__date">{_formatDateToActivityDateFormat(call.callTime)}</span>
                <span className="InteractionCard__subject line-clamp-1">{call.meetingInfo.meetingTitle}</span>
                <div className="InteractionCard__statsContainer">
                    <span className="InteractionCard__statsLabel">Call Duration:</span>
                    <span className="InteractionCard__statsValue">{_formatSecondsToPrettyTime(call?.dashboardMetrics?.callDuration ?? 0)}</span>
                    <span className="InteractionCard__statsLabel">T/L Ratio:</span>
                    <span className="InteractionCard__statsValue">{_formatTalkListenRatio(call?.dashboardMetrics?.talkListenRatio ?? 0)}</span>
                </div>

            </div>

        </div >
    );
}
CallCard.propTypes = {}
CallCard.defaultProps = {}
export default CallCard;