import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import { Grid, TextField } from "@material-ui/core";
import { setLoading } from "app/actions/screen";
import FrontspinLogo from "app/styles/assets/images/onboarding/frontspin-full.png";
import { Loading } from "app/components/common";
import { Card } from "app/components/common";
import { Button } from "../form";

const FrontspinForm = (props) => {
	const [apiKey, setApiKey] = useState("");
	const [error, setError] = useState("");

	useEffect(() => {
		const FrontspinForm = document.getElementById("FrontspinForm");
		FrontspinForm.addEventListener("keyup", submitOnEnter);
	}, []);

	function submitOnEnter(event) {
		event.preventDefault();
		if (event.keyCode === 13) {
			submitFrontspinInfo();
		}
	}

	function handleApiKeyChange(event) {
		setApiKey(event.target.value);
		setError(false);
	}

	async function submitFrontspinInfo() {
		props.setLoading(true);
		if (apiKey == "") {
			setError("Enter a apiKey");
		}
		const response = await crmOAuthCallback(apiKey, "FRONTSPIN");
		if (response.error) {
			console.log("setting error to", response.data.message);
			setError(response.data.message);
		} else {
			props.setLoading(false);
			window.close();
		}
		props.setLoading(false);
	}

	function componentWillUnmount() {
		const FrontspinForm = document.getElementById("FrontspinForm");
		FrontspinForm.removeEventListener("keyup", submitOnEnter);
	}


	return (
		<div className="Freshsales">
			<Loading />
			<div className="Freshsales__content">
				<Card className="Freshsales__content">
					<Grid>
						<div className="row-topcentered">
							<img className="header__logo" src={FrontspinLogo} alt="Logo" />
						</div>
					</Grid>
					<Grid>
						<div id="FrontspinForm">
							<table>
								<tbody>
									<tr className="row-centered">
										<td>
											<TextField
												id="apiKey"
												label="Enter API key"
												error={error}
												required
												value={apiKey}
												className="Freshsales__textField"
												onChange={handleApiKeyChange}
												margin="normal"
												type="apiKey"
											/>
										</td>
									</tr>
									<tr className="row-centered">
										<td>
											<Button theme="dark" id="syncFreshSales" onClick={submitFrontspinInfo}>
												Sync Frontspin
											</Button>
										</td>
									</tr>

									<tr className="row-centered">
										<td>
											{error && <span className="error">{error}</span>}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Grid>
				</Card>
			</div>
		</div>
	);
}

const mapStateToProps = store => {
	return { ...store };
};

FrontspinForm.propTypes = {
	setUserData: PropTypes.func.isRequired,
	setLoading: PropTypes.func,
};

FrontspinForm.defaultProps = {};

export default connect(
	mapStateToProps,
	{ setUserData, setLoading },
)(FrontspinForm);
