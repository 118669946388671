import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { refreshUserAction } from "app/actions/auth";
import { sendRequest } from "app/utils/network";
import { ReactComponent as ViewOnIcon } from "app/styles/assets/images/icons/viewOn.svg";
import { ReactComponent as ViewOffIcon } from "app/styles/assets/images/icons/viewOff.svg";
import { copyStringToClipboard } from "app/utils/helpers"
import useSnackbar from "app/utils/hooks/useSnackbar";
import { WingmanLogo } from "app/designSystem";
import { TextBase, TextLarge, Button } from 'app/designSystem';
import { CopyGrayIcon } from 'app/designSystem/icons'
import { IconButton } from "@material-ui/core";

const WingmanAPI = ({ }) => {
  const [secretToggler, setsecretToggler] = useState(true);
  const [loading, setLoading] = useState(false);
  const [apiKey, setAPIKey] = useState(null);
  const [password, setPassword] = useState(null);
  const [isAPIGenerated, setAPIGenerated] = useState(false);
  const { setSnackbar } = useSnackbar();

  async function generateAPI() {
    setLoading(true);
    const response = await sendRequest("/admin/create-api-gateway-key");
    setAPIKey(response.api_key);
    setPassword(response.password);
    setAPIGenerated(true);
    console.log(apiKey, password, response.api_key, response.password, response);
    setLoading(false);
  }

  async function regenerateSecret() {
    setLoading(true);
    const response = await sendRequest("/admin/regenerate-secret-key");
    setPassword(response.password);
    if (!secretToggler) document.getElementById('secretKey').type = "text";
    else document.getElementById('secretKey').type = "password";
    setLoading(false);
  }

  async function checkAPIExistsOrNot() {
    const response = await sendRequest("/admin/check-api-exists");
    if (response) generateAPI();
    console.log(response)
  }

  function toggleSecret() {
    if (secretToggler) {
      document.getElementById('secretKey').type = "text";
      setsecretToggler(false);
    }
    else {
      document.getElementById('secretKey').type = "password";
      setsecretToggler(true);
    }
  }

  function copyContent(el) {
    copyStringToClipboard(el);
    if (el == apiKey) {
      setSnackbar("API key copied to clipboard", "INFO");
      selectElementText(document.getElementById("apiKey"));
    }
    else {
      setSnackbar("API secret copied to clipboard", "INFO");
      selectElementText(document.getElementById("secretKey"));
    }

  }

  function selectElementText(input) {
    input.focus();
    input.select();
  }


  useEffect(() => {
    checkAPIExistsOrNot();
  }, [])
  return (
    <div className="IntegrationCard rounded-lg p-2 border border-gray-200 mb-4">
      <div className="flex flex-row items-center">
        <div className="p-1 bg-gray-50 rounded flex items-center h-9">
          <WingmanLogo className="h-8 w-8" />
        </div>
        <div className="ml-2">
          <TextLarge textColor="text-gray-900" className="font-semibold">Wingman</TextLarge>
          <TextBase textColor="text-gray-500" className="font-semibold">API</TextBase>
        </div>
        <div className="footer ml-auto">
          {isAPIGenerated &&
            <Button
              className="h-7"
              variant="secondary"
              loading={loading}
              onClick={regenerateSecret}
            >
              {"Regenerate"}
            </Button>
          }
          {!isAPIGenerated &&
            <Button
              className="h-7"
              variant="secondary"
              loading={loading}
              onClick={generateAPI}
            >
              {"Generate API Key"}
            </Button>
          }
        </div>
      </div>
      <div className="IntegrationCard__body">
        {isAPIGenerated &&
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center">
              <TextBase className="text-gray-500 mr-2">API Key</TextBase>
              <span className="flex-grow rounded border border-gray-200 mr-1">
                <input
                  type="text"
                  className="w-full text-base p-1 text-gray-600 font-medium"
                  id="apiKey"
                  value={apiKey}
                  readonly
                />
              </span>
              <IconButton>
                <CopyGrayIcon
                  className="h-4 w-4"
                  onClick={() => { copyContent(apiKey); }}
                />
              </IconButton>

            </div>
            <div className="flex flex-row items-center">
              <TextBase className="text-gray-500 mr-2 whitespace-nowrap">API Secret</TextBase>
              <span className="flex flex-row items-center flex-grow rounded border border-gray-200 mr-1" >
                <input
                  type="password"
                  className=" w-full text-base p-1 text-gray-600 font-medium "
                  id="secretKey"
                  value={password}
                  readonly
                />
                <span className="m-0.5 mr-1" onClick={toggleSecret}>{secretToggler ? <ViewOnIcon /> : <ViewOffIcon />}</span>
              </span>
              <IconButton>
                <CopyGrayIcon
                  className="h-4 w-4"
                  onClick={() => copyContent(password)}
                />
              </IconButton>
            </div>
          </div>}
        {!isAPIGenerated &&
          <TextBase textColor="text-gray-500" className="font-semibold">{"Generate key to use Wingman API"}</TextBase>
        }
      </div>
      <a className="DocumentationLink" href="https://api-doc.trywingman.com" target="blank"> <TextBase>Docs &#8599;</TextBase></a>
    </div >
  )
}

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(WingmanAPI);
