import React from "react";

export function reasonString(status, botNotJoinReason, past) {
	if (!past) return futureReasons(status, botNotJoinReason, past);

	if (["NOBODY_JOINED_CALL", "BOTJOIN_DENIED", "IGNORED_BY_USER", "NO_DATA_INCALL"].includes(status)) {
		switch (status) {
			case "NOBODY_JOINED_CALL": return "This meeting was not recorded as nobody joined.";
			case "BOTJOIN_DENIED": return "This meeting was not recorded as bot was denied entry.";
			case "IGNORED_BY_USER": return "This meeting was not recorded as requested by a user.";
			case "NO_DATA_INCALL": return "No speech detected";
		}
	}
	if (botNotJoinReason === 'IGNORED_BY_USER')
		return 'This meeting was not recorded as requested by a user';
	if (botNotJoinReason.indexOf("IGNORED_NON_MEETING") !== -1)
		return 'This meeting was not recorded as there is no meeting url';
	if (botNotJoinReason.indexOf("IGNORED_NOT_EXTERNAL_MEETING") !== -1)
		return 'This meeting was not recorded as there are no external participants';
	if (botNotJoinReason.indexOf("IGNORED_NOT_CONFIRMED") !== -1)
		return 'This meeting was not recorded as it is not confirmed';
	if (botNotJoinReason.indexOf("IGNORED_NOT_ORGANIZER") !== -1)
		return 'This meeting was not recorded as organizer is not a recording wingman user';
	if (botNotJoinReason.length > 0)
		return 'This meeting was was not recorded because: ' + botNotJoinReason;
	return 'The meeting was not recorded';
}

function futureReasons(status, botNotJoinReason, past) {
	if (botNotJoinReason === 'IGNORED_BY_USER')
		return 'This meeting will not be recorded';
	if (botNotJoinReason.indexOf("IGNORED_NON_MEETING") !== -1)
		return 'This meeting will not be recorded as there is no meeting url';
	if (botNotJoinReason.indexOf("IGNORED_NOT_EXTERNAL_MEETING") !== -1)
		return 'This meeting will not be recorded as there are no external participants';
	if (botNotJoinReason.indexOf("IGNORED_NOT_CONFIRMED") !== -1)
		return 'This meeting will not be recorded as it is not confirmed';
	if (botNotJoinReason.indexOf("IGNORED_NOT_ORGANIZER") !== -1)
		return 'This meeting will not be recorded as organizer is not a recording wingman user';
	if (botNotJoinReason.length > 0)
		return 'This meeting will not be recorded because: ' + botNotJoinReason;
	return "Wingman's bot will join and record this meeting. The transcript and insights will be available after the meeting ends.";
}

const ReasonColumn = ({ call, past }) => {
	return (
		<span className="CallTable__reasonColumn">
			{reasonString(call.callStatus, call.botNotJoinReason, past)}
		</span>
	);
};

export default ReasonColumn;
