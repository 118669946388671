import React from 'react';
import { Menu, MenuItem } from 'app/designSystem/menu'

export default function FolderAccessMenu({ handleClose, isOpen, anchorEl, folderIsPrivate, handleAccessChange }) {
    const [isPrivate, setIsPrivate] = React.useState(folderIsPrivate);

    return (
        <Menu
            list={[
                <MenuItem className="!p-3" label={isPrivate ? "Public" : "Private"} onClick={() => {
                    setIsPrivate(!isPrivate);
                    handleAccessChange(!isPrivate);
                }} />
            ]}
            open={isOpen}
            minWidth={100}
            onClose={() => handleClose()}
            menuPosition="bottom"
            anchorEl={anchorEl}
            onClick={(e) => {
                handleClose();
                e.stopPropagation();
            }}
            className="border-none !p-0"
        />
    )
}