import React from 'react'
import PropTypes from 'prop-types'
import { fetchDataSafely } from '../../../utils/dataUtils';
import { useSelector } from 'react-redux';
import TrimIcon from "app/styles/assets/images/icons/share/dragger.svg";
import { isCustomerTurn } from '../../../reducers/call';
import { _formatSecondsToPlayingTime, _formatSecondsToMinutes } from '../../../utils/helpers';
import { useEffect } from 'react';
import Waveform from './Waveform';
import ReactTooltip from "react-tooltip";
import { useState } from 'react';


const Trimmer = ({ start, end, setStart, setEnd, currentTime, seek }) => {
	const call = useSelector(store => store.call);
	const player = useSelector(store => store.player);
	const [width, setWidth] = useState(((end - start) / player.playback.duration) * 100);
	let [marginLeft, setMarginLeft] = useState((start / player.playback.duration) * 100);
	let [marginRight, setMarginRight] = useState(100 - ((end / player.playback.duration) * 100));
	let [currentPlay, setCurrentPlay] = useState(currentTime / player.playback.duration * 100);

	const waveformTurns = _calculateWaveformFromConversation(
		player.playback.conversationTurn,
		player.playback.duration,
		player.playback.speakers,
		player.playback.channel,
	);

	useEffect(() => {
		dragElement("leftTrim", handleStartChange, "left");
		dragElement("rightTrim", handleEndChange, "right");
	}, []);

	useEffect(() => {
		setWidth(((end - start) / player.playback.duration) * 100);
		setMarginLeft((start / player.playback.duration) * 100);
		setMarginRight(100 - ((end / player.playback.duration) * 100));
		setCurrentPlay((currentTime / player.playback.duration) * 100);
	}, [start, end, currentTime]);

	function handleStartChange(newStart) {
		setStart(newStart);
	}

	function handleEndChange(newEnd) {
		setEnd(newEnd);
	}

	function _calculateWaveformFromConversation(conversationTurn, callDurationSeconds, speakers, channel) {
		const waveformTurns = [];
		if (!callDurationSeconds) return waveformTurns;
		conversationTurn.forEach(turn => {
			const waveformTurn = {};
			waveformTurn.id = turn.turnId;
			waveformTurn.type = isCustomerTurn(turn, speakers, channel) ? "opportunity" : "agent";
			waveformTurn.left = (turn.startTime * 100) / callDurationSeconds;
			waveformTurn.width = ((turn.endTime - turn.startTime) * 100) / callDurationSeconds;
			if (waveformTurn.width < 0.3508) {
				waveformTurn.width = 0.3508;
			}
			if (waveformTurn.width > 100) waveformTurn.width = 100;
			waveformTurns.push(waveformTurn);
		});
		return waveformTurns;
	}

	function handleSkipPress(e) {
		e.persist();
		const skippPointRate = (e.clientX - e.target.getBoundingClientRect().x) / e.target.parentNode.clientWidth;
		const rd = Math.random() / 10000;
		const skippPointRateWithLitleRandomPos = skippPointRate + rd;
		const currentPlayingTime = player.playback.duration * skippPointRateWithLitleRandomPos;
		seek(currentPlayingTime);
	}

	function dragElement(id, handleChange, type) {

		var pos1 = 0, pos3 = 0;
		var elmnt;
		if (document.getElementById(id)) {
			elmnt = document.getElementById(id);
			document.getElementById(id).onmousedown = dragMouseDown;
		}

		function dragMouseDown(e) {
			e = e || window.event;
			e.preventDefault();
			pos3 = e.clientX;
			document.onmouseup = closeDragElement;
			document.onmousemove = elementDrag;
		}

		function elementDrag(e) {
			e = e || window.event;
			e.preventDefault();
			pos1 = pos3 - e.clientX;
			pos3 = e.clientX;
			let marginLeft = elmnt.offsetLeft - pos1;
			let newDuration;
			newDuration = ((marginLeft + 10) / elmnt.parentNode.clientWidth) * player.playback.duration;

			if (newDuration >= 0 && newDuration <= player.playback.duration) {
				if (type === "left")
					elmnt.style.left = marginLeft + "px";
				else
					elmnt.style.left = marginLeft + "px";
				handleChange(newDuration);
			}
		}

		function closeDragElement() {
			document.onmouseup = null;
			document.onmousemove = null;
		}
	}

	const howlerProps = {
		src: [player.playback.url],
		playing: false,
		volume: 1,
		rate: 1,
		seek: currentTime,
		loop: false,
	};

	const progressProps = {
		waveformTurns: waveformTurns,
		onSkipPress: handleSkipPress,
		barWidth: _calculateBarWidth(currentTime, player.playback.duration),
	};

	const playingTimeProps = {
		current: _formatSecondsToPlayingTime(currentTime),
		duration: _formatSecondsToPlayingTime(end),
	};

	function _calculateBarWidth(currentPlayingTime, callDurationSeconds) {
		let width = 0;
		if (!callDurationSeconds) return width;
		width = (currentPlayingTime * 100) / callDurationSeconds;
		return width;
	}

	return (
		<div className="Trimmer__container">
			<Waveform
				howlerProps={howlerProps}
				progressProps={progressProps}
				playingTimeProps={playingTimeProps}
			/>
			<div className="Trimmer__timelineRect">
				<span className="Trimmer__timelineRect_currentTime" style={{ left: currentPlay + "%" }}></span>
			</div>
			<div className="Trimmer__window">
				<img data-tip data-for="leftTrim" id="leftTrim" className="Trimmer__leftTrimmer" src={TrimIcon} />
				<ReactTooltip id="leftTrim" effect="solid" place="bottom">
					{_formatSecondsToPlayingTime(start)}
				</ReactTooltip>

				<div className="Trimmer__body" style={{ marginLeft: marginLeft + "%", marginRight: marginRight + "%", width: width + "%" }}>
				</div>
				<img data-tip data-for="rightTrim" id="rightTrim" className="Trimmer__rightTrimmer" src={TrimIcon} />
				<ReactTooltip id="rightTrim" effect="solid" place="bottom">
					{_formatSecondsToPlayingTime(end)}
				</ReactTooltip>

			</div>
		</div >
	);
}
Trimmer.propTypes = {}
Trimmer.defaultProps = {}
export default Trimmer;