import { _roundUp, getGroupName } from "app/utils/helpers";
import {
  ENGAGING_QUESTION_EXPLANATION,
  INTERACTIVITY_EXPLANATION,
  LONGEST_MONOLOGUE_EXPLANATION,
  TLRATIO_BAD_COLOR,
  TLRATIO_GOOD_COLOR,
  TLRATIO_OKAY_COLOR,
  TLRATIO_EXPLANATION,
  ENGAGING_QUESTION_EXPLANATION_DASHBOARD,
} from "app/constants";
import { getDuration } from "./dateUtils";
import { fetchDataSafely } from "../../utils/dataUtils";
const tailwindColors = require("tailwindcss/colors")

export function getMetricExplanation(metric, dashboard = false) {
  switch (metric.toLowerCase()) {
    case "longest monologue":
      return LONGEST_MONOLOGUE_EXPLANATION;
    case "interactivity":
      return INTERACTIVITY_EXPLANATION;
    case "talk/listen ratio":
      return TLRATIO_EXPLANATION;
    case "engaging questions":
      return dashboard ? ENGAGING_QUESTION_EXPLANATION_DASHBOARD : ENGAGING_QUESTION_EXPLANATION;
  }
}

export function getAgentsTableData(dashboardData, callCountData) {
  const agentsData = [];
  const users = dashboardData && dashboardData.userInfo ? Object.values(dashboardData.userInfo) : [];
  const userIds = dashboardData && dashboardData.userInfo ? Object.keys(dashboardData.userInfo) : [];
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    const userId = userIds[i];
    const stats = user.stats[Object.keys(user.stats)[0]];
    const domAvgTLRatio = user.dominantAgentStats[Object.keys(user.dominantAgentStats)[0]].avgTLRatio;
    const longestMonologueDuration = user.dominantAgentStats[Object.keys(user.dominantAgentStats)[0]].longestMonologueDuration;
    const callCountWithoutData = callCountData.userInfo[userId].stats[Object.keys(callCountData.userInfo[userId].stats)[0]].callCount;
    if (stats.callCount !== 0 && callCountWithoutData !== 0) {
      agentsData.push({
        displayName: user.displayName,
        userId: userId,
        ...stats,
        domAvgTLRatio,
        callCountWithoutData,
        longestMonologueDuration,
      });
    }
  }
  agentsData.sort(GetSortOrder("callCount", "desc"));
  return agentsData;
}

export function getManagerTrackersChartData(dashboardData) {
  let trackersData = {};
  const users = dashboardData && dashboardData.userInfo ? Object.values(dashboardData.userInfo) : [];
  for (let i = 0; i < users.length; i++) {
    const user = users[i];
    const stats = Object.values(user.stats)[0];
    const { change } = user;
    if (stats.callCount !== 0) {
      const { trackers } = stats;
      const keys = Object.keys(trackers);
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        let value = trackers[key];
        if (!trackersData[key]) {
          trackersData[key] = [];
        }
        trackersData[key].push({
          name: user.displayName,
          percentage: _roundUp((value / stats.callCount) * 100, 1),
          total: stats.callCount,
          count: value,
        });
      }
    }
  }
  const keys = Object.keys(trackersData);
  for (let j = 0; j < keys.length; j++) {
    trackersData[keys[j]].sort(GetSortOrder("total", "desc"));
  }
  return trackersData;
}

export function getUserTrackersChartData(dashboardData, startDate, endDate) {
  let duration = getDuration(startDate, endDate);
  let trackersData = {};
  const users = Object.values(dashboardData.userInfo);
  if (!users || users.length === 0) return trackersData;
  const user = users[0];
  const statsArray = user.stats;
  for (let period in statsArray) {
    let stats = statsArray[period];
    const { change } = user;
    if (stats.callCount !== 0) {
      const { trackers } = stats;
      const keys = Object.keys(trackers);
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        let value = trackers[key];
        if (!trackersData[key]) {
          trackersData[key] = {
            changeRate: _roundUp(change.trackers[key], 100),
            data: [],
          };
        }
        trackersData[key].data.push({
          period: period,
          periodName: getGroupName(period, duration),
          percentage: _roundUp((value / stats.callCount) * 100, 1),
          total: stats.callCount,
          count: value,
        });
      }
    }
  }
  const keys = Object.keys(trackersData);
  for (let j = 0; j < keys.length; j++) {
    trackersData[keys[j]].data.sort(GetSortOrder("period", "asc"));
  }
  return trackersData;
}

export function getUserDashboardChartData(data, startDate, endDate) {
  let duration = getDuration(startDate, endDate);
  let dashboardData = {};
  const users = Object.values(data.userInfo);
  if (!users || users.length === 0) return dashboardData;
  const user = users[0];
  const statsArray = user.stats || [];
  for (let period in statsArray) {
    let stats = statsArray[period];
    const { change } = user;
    if (stats.callCount !== 0) {
      const keys = Object.keys(stats);
      for (let j = 0; j < keys.length; j++) {
        let key = keys[j];
        if (!(key === "avgCallDuration" || key === "avgQuestions" || key === "avgTLRatio" || key === "callCount")) break;
        let value = stats[key];
        if (!dashboardData[key]) {
          dashboardData[key] = {
            changeRate: _roundUp(change[key], 100),
            data: [],
          };
        }
        dashboardData[key].data.push({
          period: period,
          periodName: getGroupName(period, duration),
          percentage: _roundUp((value / stats.callCount) * 100, 1),
          total: stats.callCount,
          count: value,
        });
      }
    }
  }
  const keys = Object.keys(dashboardData);
  for (let j = 0; j < keys.length; j++) {
    dashboardData[keys[j]].data.sort(GetSortOrder("period", "asc"));
  }
  return dashboardData;
}

export function getAverage(array, field) {
  if (array.length == 0) {
    return "NA";
  }
  let sum = 0;
  array.map((item) => {
    if (item[field] == null) {
      field = "callCount";
    }
    sum += item[field];
  });
  return _roundUp(sum / array.length, 100);
}

export function GetSortOrder(prop, direction) {
  let value = 0;
  if (prop == "name") {
    return function (a, b) {
      value = a.displayName.localeCompare(b.displayName);
      if (direction.toLowerCase() === "desc") {
        return value * -1;
      }
      return value;
    };
  }
  return function (a, b) {
    value = 0;
    if (a[prop] > b[prop]) {
      value = 1;
    } else if (a[prop] < b[prop]) {
      value = -1;
    }
    if (direction.toLowerCase() === "desc") {
      return value * -1;
    }
    return value;
  };
}

export function GetSortOrderDeep(path, direction) {
  let value = 0;
  return function (a, b) {
    value = 0;
    if (fetchDataSafely(a, path) > fetchDataSafely(b, path)) {
      value = 1;
    } else if (fetchDataSafely(a, path) < fetchDataSafely(b, path)) {
      value = -1;
    }
    if (direction.toLowerCase() === "desc") {
      return value * -1;
    }
    return value;
  };
}

export function getChartColor(index) {
  let chartColors = ["#FF4D4F", "#FF7A45", "#FFA940", "#FFEC3D", "#BAE637", "#73D13D", "#36CFC9", "#40A9FF", "#40A9FF", "#9254DE", "#F759AB"];
  return chartColors[index % chartColors.length];
}

export function getChartColorLines(index) {
  let chartColors = ["#FFB200", "#4339F2", "#02A0FC", "#FF3A29"];
  return chartColors[index % chartColors.length];
}

// need to deprecate 
export function getTLRatioColor(talkListenRatio, okayTLRatioThreshold, goodTLRatioThreshold) {
  let color = "black";
  if ((okayTLRatioThreshold && goodTLRatioThreshold) || okayTLRatioThreshold === 0 || goodTLRatioThreshold === 0) {
    color = talkListenRatio > okayTLRatioThreshold ? TLRATIO_BAD_COLOR : talkListenRatio > goodTLRatioThreshold ? TLRATIO_OKAY_COLOR : TLRATIO_GOOD_COLOR;
  }
  return color;
}

export function getTLRatioColorTWClasses(talkListenRatio, okayTLRatioThreshold, goodTLRatioThreshold) {

  let colorClasses = { textColor: "text-black", bgColor: "bg-black" };
  if ((okayTLRatioThreshold && goodTLRatioThreshold) || (okayTLRatioThreshold === 0 || goodTLRatioThreshold === 0)) {

    if (talkListenRatio > okayTLRatioThreshold) {
      colorClasses.bgColor = tailwindColors.red[600]
      colorClasses.textColor = "text-red-600"
    }

    else if (
      talkListenRatio > goodTLRatioThreshold) {
      colorClasses.bgColor = tailwindColors.orange[600]
      colorClasses.textColor = "text-orange-600"

    }


    else {
      colorClasses.bgColor = tailwindColors.green[600]
      colorClasses.textColor = "text-green-600"
    }

  }
  return colorClasses;
}
export function checkIfImage(image) {
  var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png|.jpeg)$");
  if (regex.test(image.value.toLowerCase())) {
    return true;
  } else {
    return false;
  }
}
