import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { Message } from "app/components/call";
import { SALES } from "app/constants";
import { PlayerContext } from "app/containers/Call";
import { useScrollListener } from "../../utils/hooks/useScrollListener";
import { getNextTimeToPlay, getStartTime, getEndTime, isCustomerTurn, isTurnSmallEnough, getTurnForTime, getRicherSpeakerData } from "../../reducers/call";
import { useDispatch, useSelector } from "react-redux";
import { isAnythingSelected, isElementInViewport } from "../../utils/helpers";
import { debounce } from "lodash";
import ShareIcon from "app/designSystem/icons/share.svg";
import AddCommentIcon from "app/designSystem/icons/comment.svg";
import penIcon from "app/designSystem/icons/transcriptEdit.svg"
import PlaylistIcon from "app/styles/assets/images/icons/gameTapes/gameTape.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { SET_SHOW_SHARE_CALL_MODAL, SET_TRANSCRIPT_SELECTED, SET_SHOW_ADD_TO_GAMETAPE_MODAL, SET_SELECTED_SEARCH_OPTION } from "../../actions/actionTypes";
import { TextBase, TextLarge, Text2XL, Button, CheckBox } from "app/designSystem"
import { fetchDataSafely } from "../../utils/dataUtils";
import ReactTooltip from "react-tooltip";
import { doesUserHaveAccess } from "../../utils/helpers";
import TranscriptionMessage from "./TranscriptionMessage"
import Input from 'app/designSystem/form/Input';
import { sendRequest } from "../../utils/network";
import useSnackbar from "app/utils/hooks/useSnackbar";
import VoiceFingerprintWarning from "./VoiceFingerprintWarning";
import { getClientOS } from "../../utils/helpers";
import { magicCopy, generateClipboardText } from "./transcriptUtils";
import metrics from "app/utils/metrics";

const Transcript = ({
  conversationTurnData,
  summaryData,
  _addTranscriptLabels,
  expandCommentArea,
  handleHighlightsClick,
  addRefTurn,
  setActiveNote,
  callId,
  customerType,
  autoScroll,
  setAutoScroll,
  ignoreScroll,
  hideTranscript,
  activeTurn,
  setActiveHighlightTab
}) => {

  const [lastDismissedAutoScrollTooltip, setLastDismissedAutoScrollTooltip] = useState(null);
  const channel = useSelector(store => store.call.details.callData.channel);
  const transcriptSelected = useSelector(store => store.screen.transcriptSelected);
  const playback = useSelector(store => store.player.playback);
  const call = useSelector(store => store.call);
  const selectedSpeakers = useSelector(store => store.player.playback.selectedSpeakers);
  const [selectionStartTime, setSelectionStartTime] = useState(null);
  const [selectionEndTime, setSelectionEndTime] = useState(null);
  const refTurns = useRef([]);
  const refSentences = useRef([]);
  const dispatch = useDispatch();
  const auth = useSelector((store) => store.auth);
  const [sharingPermission, setSharingPermission] = useState(hasSharingPermission());
  const [singleWordHighlighted, setSingleWordHighlighted] = useState(false);
  const [highlightedWord, setHighlightedWord] = useState("")
  const [editTranscriptModalIsOpen, setEditTranscriptModalIsOpen] = useState(false);
  const [correctWord, setCorrectWord] = useState("");
  const [loading, setLoading] = useState(false)
  const userId = useSelector(store => store.auth.userId);
  const { setSnackbar } = useSnackbar();
  const turns = useSelector(store => store.call?.details?.callData?.conversationTurn);
  const speakerNames = useSelector(store => store.call?.details?.summary?.speakerNames)
  const os = getClientOS();
  const meetingTitle = useSelector(store => store.call?.details?.callDataDocument?.meetingInfo?.meetingTitle);
  const [showEmptyCorrectWordMessage, setShowEmptyCorrectWordMessage] = useState(false);

  const richSpeakerData = useMemo(() => getRicherSpeakerData(
    playback.conversationTurn,
    playback.speakers,
    fetchDataSafely(call, "details.callDataDocument.crmData.contactDocumentList", []),
    playback.channel,
  ), [playback.conversationTurn, playback.speakers, fetchDataSafely(call, "details.callDataDocument.crmData.contactDocumentList", []), playback.channel]);

  const handleCopy = (e) => {
    if (!selectionStartTime || !selectionEndTime) return;
    const magicCopyResponse = magicCopy(generateClipboardText(selectionStartTime, selectionEndTime, call, turns, meetingTitle, speakerNames, 'rtf'), generateClipboardText(selectionStartTime, selectionEndTime, call, turns, meetingTitle, speakerNames, 'plain'), e)
    if (magicCopyResponse) setSnackbar("Transcript copied to your clipboard!", "INFO");
    metrics.logEventHeap("Magic Copy Triggered", { 'callId': callId, 'userId': auth.userId, "magicCopyStatus": magicCopyResponse })
    e.preventDefault();
  }

  let speakers = ["Speaker 1", "Speaker 2"];
  if (playback?.speakers) {
    speakers = playback.speakers;
  } else if (summaryData && summaryData.speakerNames) {
    speakers = summaryData.speakerNames;
  }

  const playerProps = useContext(PlayerContext);
  const { diffY } = useScrollListener("TranscriptContainer");

  useEffect(() => {
    document.querySelector('#TranscriptContainer').addEventListener('copy', handleCopy);

    return () => {
      document.querySelector('#TranscriptContainer').removeEventListener('copy', handleCopy);
    }
  })

  useEffect(() => {
    let bool = false;
    if (selectionStartTime && selectionEndTime) { bool = true; console.log(selectionStartTime, selectionEndTime) }
    dispatch({ type: SET_TRANSCRIPT_SELECTED, transcriptSelected: bool })
  }, [selectionStartTime, selectionEndTime])


  useEffect(() => {
    if (editTranscriptModalIsOpen) {
      window.getSelection().removeAllRanges();
      document.getElementById("Transcript__messageActions").style = "display:none;"
      document.getElementById("Transcript__correctionModal").style.display = "flex"
    }
  }, [editTranscriptModalIsOpen])

  useEffect(() => {
    if (document.getElementById("TranscriptContainer")) {
      document.addEventListener("selectionchange", debouncedMouseUp);
      document.getElementById("TranscriptContainer").addEventListener("mouseup", debouncedMouseUp);
    }
    return (() => {
      if (document.getElementById("TranscriptContainer")) {
        document.removeEventListener("selectionchange", debouncedMouseUp);
        document.getElementById("TranscriptContainer").removeEventListener("mouseup", debouncedMouseUp);
      }
    })
  }, [conversationTurnData])

  useEffect(() => {
    if (diffY < 50 || ignoreScroll) return;
    debouncedCheckAndReposition();
    const { isInViewPort } = isElementInViewport(refTurns.current[activeTurn], "TranscriptContainer");
    if (isInViewPort) return;
    setAutoScroll(false);
  }, [diffY]);

  const debouncedCheckAndReposition = debounce(checkAndReposition, 500);

  function checkAndReposition() {
    if (isAnythingSelected())
      repositionMessageActions();
  }

  function hasSharingPermission() {

    const { user } = auth.data;
    const customer = auth.stringsCustomer;
    const callDetails = call.details.callDataDocument;

    if (customer.settings.authorizedCallSharers) {
      return doesUserHaveAccess(customer.settings.authorizedCallSharers, user, callDetails);
    }

    return false;

  }

  function setSelectionTimes() {
    const { startTime, endTime } = recalculateSelectionTimes();
    if (startTime && endTime) {

      setSelectionStartTime(startTime);
      setSelectionEndTime(endTime);
      repositionMessageActions();
      return true;
    } else {
      setSelectionStartTime(null);
      setSelectionEndTime(null);
      hideMessageActions("start time and end time are null");
      return false;
    }
  }

  function repositionMessageActions(preferredXPos) {
    var selection = window.getSelection(),    // get the selection then
      range = selection.getRangeAt(0),        // the range at first selection group
      selectionRect = range.getBoundingClientRect();
    const transcriptContainer = document.getElementById("TranscriptContainer");
    const leftPosition = (preferredXPos ? (preferredXPos) : (getMessageActionY(transcriptContainer, selectionRect))) + "px";
    const topPosition = getMessageActionX(transcriptContainer, selectionRect) + "px;"
    document.getElementById("Transcript__messageActions").style = "display:flex;top:"
      + topPosition
      + "left:" + leftPosition;
    document.getElementById("Transcript__correctionModal").style = "top:"
      + topPosition
      + "left:" + leftPosition;
    console.log("showing messageActions");
  }
  function recalculateSelectionTimes() {
    var range = window.getSelection().getRangeAt(0);
    var selectedText = window.getSelection().toString();
    let startTurnId, nearestEndTurnId, startTime, endTime;
    /*  Sometimes finding endTime by comparing selectedRange nodes and storedContainerTurnRefs fails
    (because range.endContainer is a wrapper element rather than message element), in those cases ,Iterate and keep appending content from storedRefSentences and when size exceeds selectionText textSize ,stop and consider sentence/turn as end
     */
    let selectedRefSentenceContent = "";
    Object.keys(refSentences.current).map(sid => {
      if (range.startContainer.parentNode === refSentences.current[sid]) {
        startTurnId = sid;
        startTime = getStartTime(conversationTurnData, sid.split("_")[0], sid.split("_")[1], range.startOffset, selectedText.split(" ")[0]);
      }
      if (range.endContainer.parentNode === refSentences.current[sid]) {
        console.log("end sentence is", sid, "of`fset:", range.endOffset);
        endTime = getEndTime(conversationTurnData, sid.split("_")[0], sid.split("_")[1], range.endOffset, selectedText.split(" ")[selectedText.split(" ").length - 1]);
      }
      if (startTurnId && !nearestEndTurnId) {
        selectedRefSentenceContent += refSentences.current[sid].textContent
        if (selectedRefSentenceContent.length >= selectedText?.length) {
          nearestEndTurnId = sid;
        }
      }
    });
    if (!endTime) {
      endTime = getEndTime(conversationTurnData, nearestEndTurnId.split("_")[0], nearestEndTurnId.split("_")[1], range.endOffset, selectedText.split(" ")[selectedText.split(" ").length - 1]);
      console.log("Found end time by iterating through storedRefSentences", endTime);
    }
    else {
      console.log("Found end time by comparing range.endContainer and storedContainerTurnRefs", endTime);
    }
    return { startTime, endTime };
  }

  const debouncedMouseUp = debounce(handleMouseUp, 300);

  function handleMouseUp(e) {
    const clientX = e.offsetX;
    try {
      if (isAnythingSelected(hideMessageActions)) {
        setSelectionTimes();
        repositionMessageActions(clientX);
        let selectedText = window.getSelection().toString();
        let countOfwords = selectedText.match(/(\w+)/g).length
        if (countOfwords <= 4) {
          setSingleWordHighlighted(true);
          setHighlightedWord(selectedText);
        }
        else {
          setSingleWordHighlighted(false)
        }
      } else {
        hideMessageActions("nothing selected");
      }
    } catch (error) {
      console.log("Error handling mouse up event", error);
      hideMessageActions("error handling mouse up");
    }
  }

  function getMessageActionX(transcriptContainer, rect) {
    let messageActionTop = (transcriptContainer.scrollTop + rect.bottom - 70);
    if (rect.bottom < transcriptContainer.getBoundingClientRect().top || rect.top > transcriptContainer.getBoundingClientRect().bottom) {
      return messageActionTop;
    }
    if (messageActionTop < transcriptContainer.scrollTop) {
      messageActionTop = transcriptContainer.scrollTop;
    }
    if (messageActionTop > (transcriptContainer.scrollTop - 60 + transcriptContainer.clientHeight)) {
      messageActionTop = transcriptContainer.scrollTop - 60 + transcriptContainer.clientHeight;
    }
    console.log("in getMessageActionX", "rect", rect, transcriptContainer.scrollTop, transcriptContainer.getBoundingClientRect(), messageActionTop);
    return messageActionTop;
  }

  function getMessageActionY(transcriptContainer, rect) {
    console.log("in getMessageActionY", rect, transcriptContainer.getBoundingClientRect());
    let messageActionLeft = (rect.right - 20 - transcriptContainer.getBoundingClientRect().left - transcriptContainer.getBoundingClientRect().y);
    if (messageActionLeft > transcriptContainer.getBoundingClientRect().width) {
      messageActionLeft = rect.left;
    }
    return messageActionLeft;
  }

  function handleAddRefTurn(turnId, ref) {
    refTurns.current[turnId] = ref;
    addRefTurn(turnId, ref)
  }

  function handleAddSentenceRef(sentenceId, turnId, ref) {
    refSentences.current[turnId + "_" + sentenceId] = ref;
  }

  function handleHighlightsClickTranscript(turnId, startTime) {
    if (isAnythingSelected(hideMessageActions)) {
      setSelectionTimes();
      console.log("not playing as text is selected");
      return;
    }
    setAutoScroll(false);
    handleHighlightsClick(turnId, startTime);
  }

  function hideMessageActions(reason, fromEditTranscriptModal) {
    console.log("in hideMessageActions", reason)
    if (document.getElementById("Transcript__messageActions")) {
      document.getElementById("Transcript__messageActions").style = "display:none;"
    }
    if (fromEditTranscriptModal && editTranscriptModalIsOpen) {
      if (document.getElementById("Transcript__correctionModal")) {
        document.getElementById("Transcript__correctionModal").style = "display:none;"
      }
      setEditTranscriptModalIsOpen(false)
      setHighlightedWord("");
      setCorrectWord("")
      setSingleWordHighlighted(false);
    }

    setSelectionStartTime(null);
    setSelectionEndTime(null);
  }

  function handleAddComment() {
    expandCommentArea(true, selectionStartTime);
    hideMessageActions("after add comment");
  }

  function handleShare() {
    dispatch({
      type: SET_SHOW_SHARE_CALL_MODAL, showShareCallModal: true,
      startTime: selectionStartTime,
      endTime: selectionEndTime,
    })
    hideMessageActions("after share");
  }

  function handleAddToGameTape() {
    console.log("here in handleAddToGameTape");
    dispatch({
      type: SET_SHOW_ADD_TO_GAMETAPE_MODAL, showAddToGameTapeModal: true,
      startTime: selectionStartTime,
      endTime: selectionEndTime,
    })
    hideMessageActions("after add to game tape");
  }
  function handleTopicClick(badge) {
    dispatch({
      type: SET_SELECTED_SEARCH_OPTION,
      selectedSearchOption: {
        type: 'Highlights',
        keyword: badge
      }
    }
    )
    setActiveHighlightTab("Highlights")
  }

  async function handleAddToDictionary() {
    if (!correctWord) {
      setShowEmptyCorrectWordMessage(true);
      return;
    }
    else {
      setShowEmptyCorrectWordMessage(false);
    }
    setLoading(true);
    const params = new URLSearchParams();
    var incorrectWords = [];
    incorrectWords.push(highlightedWord);
    params.append("replacementWord", correctWord);
    params.append("incorrectWords", incorrectWords);
    params.append("userId", userId);
    const response = await sendRequest("/admin/add_and_edit_dictionary_words", params);
    hideMessageActions("after adding to dictionary", true)
    setLoading(false);
    setSnackbar("Correction added", "SUCCESS");
  }
  if (conversationTurnData.length > 0) {
    return (
      <div tabIndex={0} className={`Call__TranscriptScrollContainer ${hideTranscript ? "hidden" : ""}`} id="TranscriptContainer">
        <TranscriptionMessage />
        <VoiceFingerprintWarning callId={callId} />
        {
          conversationTurnData.map(turn => {
            return (
              <Message
                autoScroll={autoScroll}
                setAutoScroll={setAutoScroll}
                lastDismissed={lastDismissedAutoScrollTooltip}
                setLastDismissed={setLastDismissedAutoScrollTooltip}
                turn={turn}
                contact={richSpeakerData.contacts[speakers[turn.speakerId - 1]]}
                active={activeTurn === turn.turnId}
                seek={activeTurn === turn.turnId ? playerProps.seek : 0}
                key={turn.turnId}
                callId={callId}
                turnId={turn.turnId}
                showAddCommentButton={customerType === SALES}
                expandCommentArea={expandCommentArea}
                setActiveNote={setActiveNote}
                addRefTurn={handleAddRefTurn}
                addSentenceRef={handleAddSentenceRef}
                _addTranscriptLabels={_addTranscriptLabels}
                handleHighlightsClick={handleHighlightsClickTranscript}
                message={turn.turnStr}
                position={isCustomerTurn(turn, speakers, channel) ? "left" : "right"}
                time={turn.startTime}
                title={speakers[turn.speakerId - 1]}
                handleTopicClick={handleTopicClick}
              />
            );
          })
        }
        <OutsideClickHandler onOutsideClick={() => hideMessageActions("onOutsideClick from message actions")} >

          <div id="Transcript__messageActions" className="Transcript__messageActions">
            <MessageAction onClick={handleAddComment} icon={AddCommentIcon} label="Add a comment" />
            {sharingPermission &&
              <MessageAction onClick={handleShare} icon={ShareIcon} label="Share" />}
            <MessageAction onClick={handleAddToGameTape} icon={PlaylistIcon} label="Create game tape" />
            {singleWordHighlighted &&
              <MessageAction onClick={() => setEditTranscriptModalIsOpen(true)} icon={penIcon} label="Edit transcript" />
            }
          </div>
        </OutsideClickHandler >


        <OutsideClickHandler onOutsideClick={() => hideMessageActions("onOutsideClick from modal", true)} disabled={!editTranscriptModalIsOpen} >

          <div className="Transcript__correctionModal" id="Transcript__correctionModal" >
            <div className="flex pt-3 pb-2 px-3 justify-between items-center">
              <TextLarge textColor="text-coolGray-900" className="pt-4 pb-2 px-3">
                Edit Transcript
              </TextLarge>

            </div>
            <hr />
            <div className="flex px-3 pt-4 pb-0.5">
              <TextBase textColor="text-coolGray-500" className="mr-1">Replace</TextBase>
              <TextBase>{highlightedWord}</TextBase>
            </div>
            <Input
              placeholder="Enter the correct word"
              className="!py-0.5 !px-3"
              value={correctWord}
              onChange={(e) => setCorrectWord(e.target.value)}
              error={showEmptyCorrectWordMessage ? "Correct word cannot be empty" : null}
            />
            <TextBase textColor="text-coolGray-500" className="mt-5  px-4">This word/phase will be replaced across all upcoming calls</TextBase>
            <Button
              variant="primary"
              className={" px-3 !py-2 mt-1  mx-3 mb-5"}
              onClick={handleAddToDictionary}
              loading={loading}
            >
              <TextBase textColor="text-coolGray-50">
                Add correction
              </TextBase>
            </Button>
          </div>

        </OutsideClickHandler>




      </div >
    );
  }
  return null;
};

Transcript.defaultProps = {
  hideTranscript: false,
}


const MessageAction = ({ onClick, icon, label }) => (
  <div onClick={onClick} className="Transcript__messageActions__button">
    <img data-tip data-for={label} className="Transcript__messageActions__buttonIcon" src={icon} />
    <ReactTooltip id={label} place="top" effect="solid">
      {label}
    </ReactTooltip>
  </div>
);

export default Transcript;

