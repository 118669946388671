import React from 'react';
import { Paper } from '@material-ui/core';
import { _formatSecondsToPlayingTime, _formatTalkListenRatio, _roundUp } from '../../utils/helpers';
import ProfileImage from '../common/ProfileImage';
import { useSelector } from 'react-redux';
import { findIndex } from "lodash";
import { LONGEST_MONOLOGUE_EXPLANATION } from '../../constants';
import LabelWithExplanation from '../common/LabelWithExplanation';
import { getMetricExplanation } from './helpers';

function formatFn(v, format) {
  if (format === 'tlratio') return _formatTalkListenRatio(v, '');
  if (format === 'duration') return _formatSecondsToPlayingTime(v);
  return _roundUp(v, 10);
}

function Entry(props) {
  return <div className="TeamDashboard__LeaderboardEntry">
    <ProfileImage userId={props.entry.userId} size={30} disableTooltip />
    <div className="TeamDashboard__LeaderboardEntryName">
      {props.name}
      <div className="TeamDashboard__LeaderboardEntryCalls">{props.entry.count} calls</div>
    </div>
    <div>
      {formatFn(props.entry.value, props.format)}
    </div>
  </div>
}

function Leaderboard(props) {
  const userNames = useSelector(store => store.screen.metaData.userNames);

  function getName(userId) {
    var index = findIndex(userNames, function (o) { return o.value == userId; });
    return index === -1 ? '' : userNames[index].label;
  }

  var format = 'number';
  if (props.data.name.toLowerCase().indexOf('ratio') !== -1) format = 'tlratio';
  if (props.data.name.toLowerCase().indexOf('monologue') !== -1) format = 'duration';
  const avg = props.data && props.data.entries && props.data.entries.length > 0 ? props.data.entries.reduce((c, a) => c + a.value, 0) / props.data.entries.length : 0;
  return <Paper>
    <div className="TeamDashboard__LeaderboardHeader">
      <LabelWithExplanation
        label={props.data.name}
        labelClass="TeamDashboard__LeaderboardHeaderTitle"
        explanation={getMetricExplanation(props.data.name, true)}
      />
      <span className="TeamDashboard__TrackersAvg">
        Avg: {formatFn(avg, format)}
      </span>
    </div>
    <div className="TeamDashboard__LeaderboardContent">
      {props.data.entries.map((entry, i) => <Entry key={i} entry={entry} format={format} name={getName(entry.userId)} />)}
    </div>
  </Paper>
}

export default Leaderboard;