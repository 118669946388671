
import React from "react";
import Skeleton from 'react-loading-skeleton';
import { TableCell, TableRow } from "@material-ui/core";

export const TableSkeleton = ({ showRep, rowSize = 6 }) => {
    return (
        <>
            {[...Array(rowSize).keys()].map(i => (
                <TableRow className="CallTable__simpleRow appearDiv" key={"call-table-skeleton-row-" + i}>
                    <TableCell colSpan={1}><Skeleton width={130} height={20} /></TableCell>
                    {showRep &&
                        <TableCell colSpan={1}>
                            <div className="row-tcentered">
                                <Skeleton circle width={30} height={30} />
                                <div style={{ margin: "0px 2px" }} />
                                <Skeleton circle width={30} height={30} />
                            </div>
                        </TableCell>
                    }
                    <TableCell colSpan={3}><Skeleton width={300} height={20} /></TableCell>
                    <TableCell colSpan={2}>
                        <div className="row-tcentered" style={{ marginLeft: "20px" }}>
                            <Skeleton width={100} height={20} />
                        </div>
                    </TableCell>
                    <TableCell colSpan={1}><Skeleton width={100} height={20} /></TableCell>
                </TableRow>
            ))}
        </>
    );
}