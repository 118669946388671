import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { CallPreviewHeader } from "./CallPreviewComponent/CallPreviewHeader";
import { CallPreviewRepsSection } from "./CallPreviewComponent/CallPreviewRepsSection";

import { flattenHighlights, getContactName } from "../../utils/helpers";
import withHowler from "../common/CustomHowler";
import { getTrimmedCallDetails } from "../../actions/call";
import { connect, useSelector } from "react-redux";
import ExpandedSection from "app/styles/assets/images/icons/dealView/expandedSection.svg";
import CollapsedSection from "app/styles/assets/images/icons/dealView/collapsedSection.svg"
import Skeleton from "react-loading-skeleton";
import { Highlights } from "../call/HighlightsSectionNew";
import { fetchDataSafely } from "../../utils/dataUtils";
import { getRicherSpeakerData, isCustomerSpeaker } from "../../reducers/call";
import { GetSortOrder } from "../dashboards/helpers";
import { emptyCallScreen } from "../../containers/Call";
import CallSnippetPreview from "app/components/call/CallSnippetPreview";
import { CallPreviewCustomerSection } from "./CallPreviewComponent/CallpreviewCustomerSection";

const CallPreview = ({ loading, callDetail, callTime, callId, callType, meetingTitle, crmData, ...props }) => {
    const [callLoading, setCallLoading] = useState(false);
    const playback = useSelector(store => store.player.playback);
    const call = callDetail ? callDetail : useSelector(store => store.call?.details);
    const turns = fetchDataSafely(call, "callData.conversationTurn", []);
    const callProcessed = fetchDataSafely(call, "callDataDocument.callStatus", "NOT_READY") === "POST_PROCESSING_DONE";
    const channel = fetchDataSafely(call, "callData.channel", []);
    const highlightsData = fetchDataSafely(call, "summary.highlights", { highlightGroups: [] });
    const speakers = fetchDataSafely(call, "summary.speakerNames", ["Speaker 1", "Speaker 2"]);
    const dashboardMetrics = fetchDataSafely(call, "callDataDocument.dashboardMetrics");
    const [nextSteps, setNextSteps] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [showNextSteps, setShowNextSteps] = useState(true);
    const [showQuestions, setShowQuestions] = useState(false);
    const [clientTlRatios, setClientTlRatios] = useState([]);
    const [showCallPreview, setShowCallPreview] = useState(false);
    const [callSnippetInfo, setCallSnippetInfo] = useState({});

    const richSpeakerData = useMemo(() => getRicherSpeakerData(
        playback.conversationTurn,
        playback.speakers,
        fetchDataSafely(crmData, "contacts", []),
        playback.channel,
    ), [playback.conversationTurn, playback.speakers, fetchDataSafely(crmData, "contacts", []), playback.channel]);


    useEffect(() => {
        if (!highlightsData || !highlightsData.highlightGroups || highlightsData.highlightGroups.length === 0) {
            if (nextSteps.length > 0) setNextSteps([]);
            if (questions.length > 0) setQuestions([]);
            return;
        };
        setNextSteps(flattenHighlights(highlightsData, [], "next step"));
        setQuestions(flattenHighlights(highlightsData, [], "question"));
    }, [highlightsData]);

    useEffect(() => {
        console.log("here in clientTLRatiosLocal setting");
        const clientTlRatiosLocal = [];
        if (playback.speakers) {
            playback.speakers
                .filter((s, i) => (richSpeakerData.contacts[s] || isCustomerSpeaker(i, channel, s)))
                .filter(s => richSpeakerData.tlRatios[s] !== 0)
                .map(customer => {
                    let clientTlRatio = { tlRatio: richSpeakerData.tlRatios[customer] };
                    const contact = richSpeakerData.contacts[customer];
                    if (contact) {
                        clientTlRatio.name = getContactName(contact);
                        clientTlRatio.jobTitle = contact.jobTitle;
                    } else {
                        clientTlRatio.name = customer;
                    }
                    clientTlRatiosLocal.push(clientTlRatio);
                });
        }
        clientTlRatiosLocal.sort(GetSortOrder("tlRatio", "DESC"));
        console.log("Setting clientTlRatios to:", playback.speakers, clientTlRatiosLocal);
        setClientTlRatios(clientTlRatiosLocal);
    }, [playback.speakers, richSpeakerData]);

    useEffect(() => {
        fetchCallDetail()
    }, [callId]);

    async function fetchCallDetail() {
        setCallLoading(true);
        await props.getTrimmedCallDetails(callId);
        setCallLoading(false);
    }

    function openCallPage() {
        window.open("/call/" + callId);
    }

    function handleHighlightsClick(turnId, startTime, category) {
        setCallSnippetInfo({ startTime: startTime - 30, endTime: startTime + 30 });
        setShowCallPreview(true);
    }

    if (callLoading) {
        return (
            <PreviewLoading />
        );
    }

    return (
        <div className="CallPreview">
            <CallPreviewHeader openCallPage={openCallPage} callTime={callTime} callType={callType} meetingTitle={meetingTitle} errorMessage={call.errorMessage} />
            <div className="CallPreview__summaryContainer">
                {!call?.errorMessage &&
                    <div className="CallPreview__summaryContainer__top">
                        <div className="CallPreview__summaryContainer__rowItem CallPreview__summaryContainer__rowItemFirst">
                            <span className="CallPreview__summaryContainer__header">Rep Stats</span>
                            {dashboardMetrics &&
                                <CallPreviewRepsSection dominantRep={dashboardMetrics.dominantRep} engagingQuestions={dashboardMetrics.engagingQuestions} talkListenRatio={dashboardMetrics.talkListenRatio} />
                            }
                        </div>
                        <CallPreviewCustomerSection clientTlRatios={clientTlRatios} />
                    </div>
                }

                {showCallPreview &&
                    <CallSnippetPreview
                        isOpen={showCallPreview}
                        key={callSnippetInfo.startTime}
                        hideSnippet={() => { setCallSnippetInfo({}); setShowCallPreview(false); }}
                        callTitle={call.callDataDocument.meetingInfo.meetingTitle}
                        callId={call.callDataDocument.callId}
                        startTime={callSnippetInfo.startTime}
                        endTime={callSnippetInfo.endTime}
                        callDuration={dashboardMetrics.callDuration}
                    />
                }

                {(nextSteps.length > 0 || questions.length > 0) &&
                    <div className="CallPreview__summaryContainer__bottom">
                        <span className="CallPreview__summaryContainer__header CallPreview__summaryContainer__headerCaps">Call Summary</span>
                        {nextSteps.length > 0 &&
                            <div key="nextStep  s" className="CallPreview__summaryContainer__section">
                                <div onClick={() => setShowNextSteps(showNextSteps => !showNextSteps)} className="CallPreview__summaryContainer__sectionHeader">
                                    <span className="CallPreview__summaryContainer__sectionHeaderText">Next Steps ({nextSteps.length})</span>
                                    <img src={showNextSteps ? ExpandedSection : CollapsedSection} className="CallPreview__summaryContainer__sectionHeaderIcon" />
                                </div>
                                <div className={` ${showNextSteps ? "" : "hidden"} Call__HighlightContainer CallPreview__summaryContainer__sectionPaddedList`}>
                                    <Highlights
                                        highlightsData={highlightsData}
                                        keyword={"next step"}
                                        excludeKeywords={[]}
                                        handleHighlightsClick={handleHighlightsClick}
                                        speakers={speakers}
                                        channel={channel}
                                        turns={turns}
                                        processingPending={!callProcessed}
                                    />
                                </div>
                            </div>}

                        {questions.length > 0 &&
                            <div key="questions" className="CallPreview__summaryContainer__section">
                                <div onClick={() => setShowQuestions(showQuestions => !showQuestions)} className="CallPreview__summaryContainer__sectionHeader">
                                    <span className="CallPreview__summaryContainer__sectionHeaderText">Questions ({questions.length})</span>
                                    <img src={showQuestions ? ExpandedSection : CollapsedSection} className="CallPreview__summaryContainer__sectionHeaderIcon" />
                                </div>
                                <div className={` ${showQuestions ? "" : "hidden"} Call__HighlightContainer CallPreview__summaryContainer__sectionPaddedList`}>
                                    <Highlights
                                        highlightsData={highlightsData}
                                        keyword={"question"}
                                        excludeKeywords={[]}
                                        handleHighlightsClick={handleHighlightsClick}
                                        speakers={speakers}
                                        channel={channel}
                                        turns={turns}
                                        processingPending={!callProcessed}
                                    />
                                </div>
                            </div>
                        }
                    </div>}
                {(nextSteps.length === 0 && questions.length === 0 && call?.callDataDocument?.callId !== null) &&
                    <div className="CallPreview__emptyContainer">
                        {emptyCallScreen({ call })}
                    </div>
                }
            </div>
        </div >
    );
}


export const PreviewLoading = () => {
    return (
        <div className="CallPreview">
            <div className="CallPreview__header">
                <br />
                <Skeleton circle height={30} width={30} />
                <span className="CallPreview__subject" style={{ marginLeft: "10px" }}>
                    <Skeleton height={30} width={400} />
                </span>
            </div>
            <div className="EmailPreview__body">
                <br />
                <Skeleton height={10} width={150} />
                <br />
                <br />
                <Skeleton height={15} width={100} />
                <br />
                <br />
                <Skeleton height={15} width={350} />
                <br />
                <Skeleton height={15} width={300} />
                <br />
                <br />
                <Skeleton height={15} width={350} />
                <br />
                <Skeleton height={15} width={300} />
                <br />
                <br />
                <Skeleton height={15} width={350} />
                <br />
                <Skeleton height={15} width={300} />
                <br />
                <br />
                <Skeleton height={15} width={350} />
                <br />
                <Skeleton height={15} width={300} />
                <br />
                <br />
                <Skeleton height={15} width={100} />
                <br />
                <Skeleton height={15} width={150} />
            </div>
        </div>
    );
}

CallPreview.propTypes = {}
CallPreview.defaultProps = {}

const mapStateToProps = store => {
    return {};
};

CallPreview.defaultProps = {};

export default connect(
    mapStateToProps,
    { getTrimmedCallDetails },
)(withHowler(CallPreview));


