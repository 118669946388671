import React, { useState, useEffect } from 'react';
import moment from "moment";
import { getShortHands } from "app/utils/helpers";
import { CALL_CARD_DATE_FORMAT, MAIN } from "app/constants";
import { ReasonColumn } from "app/components/callTable";
import BlueSwitch from './Switch';
import DisconnectCallModal from 'app/components/call/DisconnectCallModal';
import noVideoIcon from 'app/styles/assets/images/icons/no_video_icon.svg';
import videoIcon from 'app/styles/assets/images/icons/video_icon.svg';
import videoDisabledIcon from 'app/styles/assets/images/icons/video_disabled.svg';
import { getUpcomingCalls, ignoreCall, joinOngoingCall } from "app/utils/network";
import { updateRecording, setUpcomingCalls } from "app/actions/pageData";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NoDataCustom } from '../components/common/NoData';
import NoCallsIcon from "app/styles/assets/images/icons/NoCalls.svg"
import Skeleton from 'react-loading-skeleton';
import { isEqual } from 'lodash';
import CalendarSyncPrompt from '../components/onboarding/CalendarSyncPrompt';
import { HeadphonesIcon, Button, TextXL, TextSM, TextLarge, TextBase, AlarmClockIcon, RecordingActiveIcon, RecordingInactiveIcon, RecordingDisabledIcon, Toggle } from "app/designSystem"
import classNames from 'classnames';
import useSnackbar from "app/utils/hooks/useSnackbar";

const DateDividedUpcomingCalls = props => {
  const [upcomingCallsLoading, setUpcomingCallsLoading] = useState(false);
  const upcomingCalls = useSelector(store => store.pageData.upcomingCalls);
  const [sections, setSections] = useState(getSections(upcomingCalls.calls));


  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    console.log("sections is:", sections);
    if (!sections || sections.length === 0) {
      setUpcomingCallsLoading(true);
    }
    var calls = await fetchPage(1);
    var sectionsLocal = getSections(calls);
    if (!isEqual(sectionsLocal, sections)) {
      setUpcomingCallsLoading(true);
      setSections(sectionsLocal);
      setTimeout(() => { setUpcomingCallsLoading(false); }, 500);
    } else {
      setSections(sectionsLocal);
      setUpcomingCallsLoading(false);
    }
  }

  async function fetchPage(page) {
    const userId = props.auth.userId;
    const upcomingCalls = await getUpcomingCalls(userId, page);
    props.setUpcomingCalls({
      hasMore: !(upcomingCalls && upcomingCalls.length < 50),
      calls: upcomingCalls,
      page: 1,
    });
    return upcomingCalls;
  }

  function getSections(calls) {
    let date = null;
    const sections = [];
    let section = { date: "", calls: [] };
    console.log("calls is:", calls);
    if (!calls || !calls.map) return sections;
    calls.map((call, i) => {
      let callDate = moment(call.callTime).format(CALL_CARD_DATE_FORMAT);
      callDate = getShortHands(callDate);
      if (callDate) callDate = callDate.toLowerCase();
      if (date === null) {
        section.date = callDate;
        section.calls = [call];
        date = callDate;
      } else if (date !== callDate) {
        sections.push(section);
        date = callDate;
        section = { date: callDate, calls: [call] };
      } else {
        section.calls.push(call);
      }
    });
    if (section.calls.length > 0) {
      sections.push(section);
    }
    return sections;
  }




  // h-0 below is need for a Flex grow container to Take up remaining space in parent and scroll
  return (
    <div className={classNames("h-full flex flex-col border-l border-tborder-coolGray-200 bg-white", props.className)}>


      {
        <> <CalendarSyncPrompt />
          <div className="flex justify-between items-center p-4 border-b border-coolGray-200">
            <TextXL> Upcoming meetings</TextXL>
            <Button startIcon={<HeadphonesIcon />} onClick={() => { props.joinMyMeeting() }}>
              <TextBase textColor='white'>Join my meeting</TextBase>
            </Button>
          </div>
        </>
      }
      {upcomingCallsLoading && <UpcomingCallsSkeletalLoading />}

      {(!upcomingCallsLoading && !sections.length) && <div className="overflow-y-auto min-w-[18.75 rem]">

        <NoDataCustom
          copy="You have no upcoming meetings."
          subcopy="Your meetings will show up here once you add meetings to calendar. You can integrate your calendar in the settings."
          icon={NoCallsIcon}
        />
      </div>}

      {Boolean(!upcomingCallsLoading && sections.length) &&
        <>

          <div className="flex-grow overflow-y-auto h-0 ">

            {sections.map((section, i) => <div key={i}>
              <TextLarge className="capitalize pt-4 pb-1 px-4">
                {`${section.date} (${section.calls.length})`}
              </TextLarge>
              {section.calls.map((call, i) => <UpcomingCall key={i} call={call} updateRecording={props.updateRecording} />)}
            </div>)}

          </div>
        </>
      }

    </div>
  );
}

const UpcomingCallsSkeletalLoading = ({ className }) => (
  <div className={classNames(className, "h-full flex flex-col border-l border-tborder-coolGray-200 bg-white pt-4 px-4")} >
    {[...Array(3).keys()].map(i => (
      <div className="DateDividedUpcomingCalls__call" key={'upcoming-call-skeleton-row-' + i}>
        <div style={{ margin: "0px 10px" }}>
          <Skeleton width={60} height={20} />
        </div>
        <div className="col-topcentered">
          <Skeleton width={200} height={15} />
          <div style={{ height: "10px" }} />
          <Skeleton width={180} height={10} />
        </div>
        <div className="col-topcentered" style={{ marginLeft: "auto", marginRight: "20px" }}>
          <Skeleton width={30} height={15} />
          <div style={{ height: "3px" }} />
          <Skeleton width={30} height={20} />
        </div>
      </div>))}
  </div>
)

const videoEle = <img src={videoIcon} className="inline align-baseline" alt="conference url present" />
const noVideoEle = <img src={noVideoIcon} className="inline align-baseline" alt="conference url missing" />
const videoDisabledEle = <img src={videoDisabledIcon} className="inline align-baseline" alt="recording disabled" />

const hasMeetingJoinUrl = call => {
  const meetingUrl = call.meetingInfo.meetingJoinUrl;
  return meetingUrl && meetingUrl.length > 0;
};

const isRecording = call => {
  if (!call) return false;
  return !call.botNotJoinReason || call.botNotJoinReason.length === 0;
};

const getVideoIcon = call => {
  if (!hasMeetingJoinUrl(call)) return <RecordingDisabledIcon />;
  return isRecording(call) ? <RecordingActiveIcon /> : <RecordingInactiveIcon />;
};


const UpcomingCall = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasDisconnected, setHasDisconnected] = useState(false);
  const { call } = props;
  const { setSnackbar, hideSnackbar } = useSnackbar();

  const meetingStartTimeMillis = call?.meetingInfo?.meetingStartTime
  const meetingEndTimeMillis = call?.meetingInfo?.meetingEndTime
  let isOngoingMeeting = false
  if (meetingStartTimeMillis && meetingEndTimeMillis) {
    const meetingStartDateTime = new Date(meetingStartTimeMillis)
    const meetingEndDateTime = new Date(meetingEndTimeMillis)
    const currentDateTime = new Date()

    if (currentDateTime >= meetingStartDateTime && currentDateTime <= meetingEndDateTime)//ongoing meeting
    {
      console.log("found an ongoing meeting", call.meetingInfo.meetingTitle)
      isOngoingMeeting = true
    }
  }
  //For an ongoing meeting, cannot disable recording.
  // Todo :Kick out wingman bot (reuse bot leave prompt design, in ongoing call page)
  const disableRecordingToggle = isRecording(call) && isOngoingMeeting;

  async function onRecordingChange(isRecordingParam) {
    if (!hasMeetingJoinUrl(call) || disableRecordingToggle) {
      setDialogOpen(true);
    }

    if (!props || !call) {
      console.error('Why is call missing', props);
      return;
    }
    const callId = call?.callId;
    if (!callId) return;
    
    if (isOngoingMeeting && isRecordingParam) {
      let resp = await joinOngoingCall(callId)
      if (resp.error) {
        setSnackbar("Failed to join call", "ERROR");
      }
      else {
        setSnackbar("Recorder will join the call shortly", "SUCCESS");
      }
    }
    props.updateRecording(callId, isRecordingParam);
    if(!isOngoingMeeting)
    await ignoreCall(callId, !isRecordingParam);
  }

  return (
    <>
      <Link to={`/call/${call.callId}`} className="block border-b border-coolGray-200 py-4 px-4">

        <div className="flex">
          <div className="flex items-center w-10/12 pr-1">
            <AlarmClockIcon />
            <TextBase textColor="text-coolGray-500" className="ml-1">{moment(call.callTime).format("hh:mm A")}</TextBase>
            {isOngoingMeeting
              && <div className='ml-4 px-2 py-1 bg-teal-100 rounded-sm text-green-700'>
                <TextSM className="uppercase" >Ongoing </TextSM>
              </div>
            }
          </div>
          <div className="w-2/12 flex justify-center items-center" onClick={(e) => { e.stopPropagation(); }}>
            {getVideoIcon(call)}
          </div>
        </div>


        <div className="flex mt-2">
          <div className="w-10/12 pr-1">
            <TextBase> {call.meetingInfo.meetingTitle}</TextBase>
            {(!isRecording(call)) &&
              <TextBase textColor="text-coolGray-500">
                <ReasonColumn call={call} past={false} />
              </TextBase>}
          </div>
          <div className="w-2/12 flex justify-center" onClick={(e) => { e.stopPropagation() }}>
            <Toggle checked={isRecording(call)} onChange={(e) => onRecordingChange(e)} />
          </div>
        </div>
      </Link>
      <DisconnectCallModal callId={call?.callId} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} setHasDisconnected={setHasDisconnected} />
    </>
  );
}


export default connect(
  state => state,
  { updateRecording, setUpcomingCalls },
)(DateDividedUpcomingCalls);