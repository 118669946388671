import React, { useState, useEffect } from 'react';
import LinearChart from './LinearChart';
import InfoIcon from "app/styles/assets/images/icons/callReview/info.svg";
import ReactTooltip from 'react-tooltip';

const colors = {
  "CALL_CONNECTED_WITH_PROSPECT": '#4CD964',
  "CALL_DID_NOT_CONNECT_WITH_PROSPECT": '#D11C1C',
};

const beautifiedNames = {
  "CALL_CONNECTED_WITH_PROSPECT": 'Calls Connected',
  "CALL_DID_NOT_CONNECT_WITH_PROSPECT": 'Calls not connected'
}

const TeamDashboardCallDispositions = props => {
  const [chartItems, setChartItems] = useState([]);

  useEffect(() => {
    setChartItems(linearChartItems());
  }, [props.dispositions]);

  function nCalls() {
    return Object.values(props.dispositions).reduce((t, c) => t + c, 0);
  }

  function linearChartItems() {
    var reducedDispositions = {};
    Object.keys(props.dispositions).map(disposition => {
      let name = disposition;
      let value = props.dispositions[disposition];
      if (!name || name === "UNKNOWN_CALL_DISPOSITION") {
        name = "CALL_CONNECTED_WITH_PROSPECT";
      }
      if (reducedDispositions[name]) {
        reducedDispositions[name] += value;
      } else {
        reducedDispositions[name] = value;
      }
    })
    return Object.keys(reducedDispositions).map((disposition, i) => {
      return ({
        name: beautifiedNames[disposition], disposition: disposition, value: reducedDispositions[disposition], color: colors[disposition]
      })
    }).sort((a, b) => (a.disposition === "CALL_CONNECTED_WITH_PROSPECT" ? -1 : 1));
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
      <span style={{ color: '#2680EB', fontSize: "20px", fontWeight: "bold" }}>{nCalls()}</span>
      <span style={{ fontSize: '12px', color: 'grey', marginLeft: "5px", fontWeight: "600" }}>Calls</span>
      <img data-tip data-for={"CallDisposition_" + props.type} src={InfoIcon} className="CrmContextContainer__callDetails__metricInfoIcon" />
      <ReactTooltip place="bottom" id={"CallDisposition_" + props.type} type="light" effect="solid" className="WhiteTooltip">
        <CallDispositionTooltip chartItems={chartItems} />
      </ReactTooltip>
      <LinearChart items={chartItems} style={{ width: '40vw', marginLeft: '10px' }} />
    </div>
  );
}


const CallDispositionTooltip = ({ chartItems }) => {
  return (
    <div className="CallDispositionTooltip">
      <span className="CallDispositionTooltip__heading">Call Disposition</span>
      {chartItems.map((item, i) => (
        <div key={i} className="CallDispositionTooltip__dispositionColumn">
          <div className="CallDispositionTooltip__valueRow">
            <div className="CallDispositionTooltip__valueCircle" style={{ backgroundColor: colors[item.disposition] }} ></div>
            <span className="CallDispositionTooltip__value">{item.value}</span>
          </div>
          <span className="CallDispositionTooltip__label">{item.name}</span>
        </div>
      ))}
    </div >
  );
}

export default TeamDashboardCallDispositions;