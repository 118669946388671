import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Input } from '../form';
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";


const TextFilter = props => {
    const [value, setValue] = useState(props.value);
    const timeout = useRef(null);
    const [showRemove, setShowRemove] = useState(false);

    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);



    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        props.removeFilterValue(props.fieldName);
    }

    function onChange(e) {
        const valueLocal = e.target.value;
        setValue(valueLocal);
        if (timeout.current !== null) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        if (timeout.current === null) {
            timeout.current = setTimeout(() => {
                props.onChange(valueLocal);
            }, 500);
        }
    }
    return (
        <div ref={ref} id={props.fieldName} className="FilterField__valueContainer" style={{ padding: "0px" }}>
            {showRemove && !props.dontShowCloseIcon && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
            <Input
                autoFocus
                value={value}
                onChange={onChange}
                placeholder={props.placeholder}
            />
        </div>
    );
}
TextFilter.propTypes = {}
TextFilter.defaultProps = {}
export default TextFilter;