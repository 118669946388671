import React from 'react';
import { ErrorBoundary } from "app/components/common";

export const withErrorHandling = (Screen) => {
  class ScreenWithErrorHandling extends React.Component {
    render() {
      return (
      	<ErrorBoundary>
      		<Screen {...this.props} />
      	</ErrorBoundary>
      );
    }
  }
    
  return ScreenWithErrorHandling;
};