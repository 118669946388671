import {
  SEEK_PLAYBACK_POSITIONS,
  GET_CALL_DETAILS,
  SET_PLAYING,
  SET_AUDIO_SOURCE,
  SET_SELECTED_SPEAKERS
} from "app/actions/actionTypes";
import { fetchDataSafely } from "../utils/dataUtils";

const initialState = {
  audioSource: "VIDEO",
  playback: {
    url: "",
    conversationTurn: [],
    duration: 0, // seconds
    seekPosition: 0, // seconds
    playing: false,
    channel: [],
    selectedSpeakers: null,
  },
};

export default function (state = initialState, payload) {
  switch (payload.type) {
    case GET_CALL_DETAILS:
      if (!payload.details.playbackUrl || payload.details.playbackUrl === "") {
        return {
          ...state,
          playback: {
            url: "",
          },
        };
      } else if (payload.details.summary === null) {
        return {
          ...state,
          playback: {
            callId: payload.details.callDataDocument.callId,
            url: payload.details.playbackUrl,
            title: "",
            callDate: payload.details.callDataDocument.callTime,
            opportunity: "",
            conversationTurn: "",
            duration: "",
            meetingInfo: payload.details.callDataDocument.meetingInfo,
            seekPosition: 0,
            speakers: "",
            callType: payload.details.callDataDocument.callType,
            channel: fetchDataSafely(payload, "details.callData.channel"),
          },
        };
      } else if (payload.details.callData === null) {
        return {
          ...state,
          playback: {
            callId: payload.details.callDataDocument.callId,
            url: payload.details.playbackUrl,
            title: payload.details.summary.title,
            callDate: payload.details.callDataDocument.callTime,
            opportunity: payload.details.summary.opportunity,
            conversationTurn: "",
            duration: payload.details.summary.callDurationSeconds,
            meetingInfo: payload.details.callDataDocument.meetingInfo,
            seekPosition: 0,
            speakers: "",
            callType: payload.details.callDataDocument.callType,
            channel: fetchDataSafely(payload, "details.callData.channel"),
          },
        };
      } else if (state.playback.url !== payload.details.playbackUrl) {
        return {
          ...state,
          playback: {
            callId: payload.details.callDataDocument.callId,
            url: payload.details.playbackUrl,
            title: payload.details.summary.title,
            callDate: payload.details.callDataDocument.callTime,
            opportunity: payload.details.summary.opportunity,
            conversationTurn: payload.details.callData.conversationTurn,
            duration: payload.details.summary.callDurationSeconds,
            meetingInfo: payload.details.callDataDocument.meetingInfo,
            seekPosition: 0,
            speakers: dedupeSpeakerNames(payload.details.summary.speakerNames),
            callType: payload.details.callDataDocument.callType,
            channel: fetchDataSafely(payload, "details.callData.channel"),
          },
        };
      } else {
        return {
          ...state,
          playback: {
            callId: payload.details.callDataDocument.callId,
            url: payload.details.playbackUrl,
            title: payload.details.summary.title,
            callDate: payload.details.callDataDocument.callTime,
            opportunity: payload.details.summary.opportunity,
            conversationTurn: payload.details.callData.conversationTurn,
            duration: payload.details.summary.callDurationSeconds,
            seekPosition: state.playback.seekPosition,
            speakers: dedupeSpeakerNames(payload.details.summary.speakerNames),
            meetingInfo: payload.details.callDataDocument.meetingInfo,
            callType: payload.details.callDataDocument.callType,
            channel: fetchDataSafely(payload, "details.callData.channel"),
          },
        };
      };
    case SET_PLAYING:
      return {
        ...state,
        playback: {
          ...state.playback,
          playing: payload.playing,
        },
      };
    case SET_SELECTED_SPEAKERS:
      return {
        ...state,
        playback: {
          ...state.playback,
          selectedSpeakers: payload.selectedSpeakers,
        },
      };
    case SEEK_PLAYBACK_POSITIONS:
      return {
        ...state,
        playback: {
          ...state.playback,
          seekPosition: payload.position,
          playing: true,
        },
      };
    case SET_AUDIO_SOURCE:
      return {
        ...state,
        audioSource: payload.audioSource
      };
    default:
      return state;
  }
}

function dedupeSpeakerNames(speakerNames) {
  const existingNames = {};
  return speakerNames.map(s => {
    if (existingNames[s]) {
      const count = existingNames[s];
      existingNames[s] = count + 1;
      return s + " " + count;
    } else {
      existingNames[s] = 1;
      return s;
    }
  })
}