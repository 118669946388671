import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import ProfileImage from '../common/ProfileImage';
import TableHeader, { TableHeaders } from '../table/TableHeader';
import { _formatSecondsToPlayingTime, _formatTalkListenRatio } from '../../utils/helpers';
import TeamDashboardTLRatio from './TeamDashboardTLRatio';

const TeamDashboardAgentTable = props => {
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState("callCount");
  const [sortDirection, setSortDirection] = useState("DESC");

  useEffect(() => {
    const stats = props.data;
    stats.sort(dirSortFn(sortField, sortDirection));
    setData(stats);
    console.log("Setting data to", stats, sortField, sortDirection)
  }, [props.data]);

  function dirSortFn(prop, direction) {
    const fn = sortFn(prop);
    return direction.toLowerCase() === "desc" ? (a, b) => -1 * fn(a, b) : fn;
  }

  function sortFn(prop) {
    return (a, b) => (a[prop] > b[prop] ? 1 : (a[prop] < b[prop] ? -1 : 0));
  }

  const headerLabelsVideo = [
    { label: "Rep name", value: "displayName", sort: true },
    { label: "Calls Made", value: "callCount", sort: true },
    { label: "Average Call Duration", value: "avgCallDuration", sort: true },
    { label: "Talk/Listen Ratio", value: "avgTLRatio", sort: true },
    { label: "Longest Monologue Duration", value: "longestMonologueDuration", sort: true }
  ];

  const headerLabelsDialer = [
    { label: "Rep name", value: "displayName", sort: true },
    { label: "Calls Made", value: "callCount", sort: true },
    { label: "Calls Connected", value: "connectedCallCount", sort: true },
    { label: "Average Call Duration", value: "avgCallDuration", sort: true },
    { label: "Talk/Listen Ratio", value: "avgTLRatio", sort: true },
  ];

  const sortData = {};
  sortData[sortField] = sortDirection;
  const headerProps = { sortData, sort: handleSort };

  function handleSort(field, direction) {
    console.log("sort data", field, direction);
    setSortDirection(direction);
    setSortField(field);
    const stats = data;
    stats.sort(dirSortFn(field, direction));
    setData(stats);
  }

  function formatDuration(duration) {
    if (!Number.isFinite(duration)) return "-";
    return _formatSecondsToPlayingTime(duration);
  }

  return (<div className="SPaper CallTable__container Manager-Dashboard__agents-performance">
    <Table stickyHeader style={{ flex: '1 1 auto', overflowY: 'auto', padding: '0px' }}>
      <TableHeaders headerLabels={props.isDialer ? headerLabelsDialer : headerLabelsVideo} headerProps={headerProps} />
      <TableBody>
        {data.map(user =>
          <TableRow className="CallTable__simpleRow" key={user.userId}>
            <TableCell>
              <div className="TeamDashboard__agentsTable__agentNameIconColumn">
                <ProfileImage userId={user.userId} size={30} disableTooltip />
                <div className="TeamDashboard__agentsTable__agentNameColumn">
                  <span className="TeamDashboard__agentsTable__name">{user.displayName}</span>
                  <span className="TeamDashboard__agentsTable__email">{user.email}</span>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <span className="TeamDashboard__agentsTable__value">
                {user.callCount}
              </span>
            </TableCell>
            {props.isDialer && <TableCell>
              <span className="TeamDashboard__agentsTable__value">
                {user.connectedCallCount}
              </span>
            </TableCell>}
            <TableCell>
              <span className="TeamDashboard__agentsTable__value">
                {formatDuration(user.avgCallDuration)}
              </span>
            </TableCell>
            <TableCell><TeamDashboardTLRatio tlRatio={user.avgTLRatio} /></TableCell>
            {props.isVideo &&
              <TableCell>
                <span className="TeamDashboard__agentsTable__value">
                  {formatDuration(user.longestMonologueDuration)}
                </span>
              </TableCell>
            }
          </TableRow>)}
      </TableBody>
    </Table>
  </div>);
};

export default connect(a => a, {})(TeamDashboardAgentTable);