import React, { useMemo } from "react";
import { PropTypes } from "prop-types";
import { useDropzone } from 'react-dropzone'

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px 20px',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#2680EB',
    borderStyle: 'dashed',
    backgroundColor: '#E5F0FD',
    color: '#6B778C',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#a21744'
};

const disabledStyle = {
    opacity: 0.5,
    backgroundColor: '#F1F1F1',
    borderColor: '#E6F1FF',
    color: '#6B778C',
    cursor: 'not-allowed'
}

const FileUploadForm = ({
    onFileDrop,
    placeholder = 'Drag and drop files here or click to select files',
    disabled = false,
    fileMapFunction = (file) => file,
    customStyles = {},
    className = '',
    ...props
}) => {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop: acceptedFiles => {
            onFileDrop(acceptedFiles.map(fileMapFunction))
        },
        disabled: disabled,
        ...props
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(disabled ? disabledStyle : {}),
        ...customStyles
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        disabled,
        customStyles
    ]);

    return (
        <div className="container" className={className}>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {placeholder}
            </div>
        </div>
    );
}

FileUploadForm.defaultProps = {
    placeholder: 'Drag and drop files here or click to select files',
    onFileDrop: () => { },
    disabled: false,
    fileMapFunction: (file) => file,
    customStyles: {},
    className: ''
}

FileUploadForm.propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onFileDrop: PropTypes.func,
    disabled: PropTypes.bool,
    fileMapFunction: PropTypes.func,
    customStyles: PropTypes.object,
    className: PropTypes.string
}

export default FileUploadForm;