import React from 'react'
import { TableCell, TableHead, TableRow, Table, TableBody, Tab } from "@material-ui/core";
import TableHeader from "app/components/table/TableHeader";
import classNames from 'classnames';
import ProfileImage from 'app/components/common/ProfileImage';
import useDataHelper from 'app/utils/hooks/useDataHelper';
import { TextBase, TextSM, UpTrendIcon, DownTrendIcon } from 'app/designSystem';
import { useState, useEffect } from 'react';
import { useSelector, connect } from 'react-redux';
import ScorecardDahsboardEmptyState from 'app/scorecards/ScorecardDashboardEmptyState';
import { getScorecardsDashboardData, sendRequest } from 'app/utils/network';
import { DashboardFilter } from '.';
import { Grid } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import { emptyFilterParams, removeFilterValue } from 'app/utils/filter';
import useScorecardsPermissionHelper from 'app/utils/hooks/useScorecardsPermissionHelper';
import ScorecardsGraphsModal from './ScorecardsGraphsModal';

import { ReactComponent as ExcelIcon } from "app/styles/assets/images/icons/excel.svg";
import { Button } from "app/designSystem";
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { downloadFile } from 'app/utils/dataUtils';
import ReactTooltip from 'react-tooltip';

import { cloneDeep } from 'lodash';



export default function ScorecardsDashboard({ setScorecardsDashboardData, setScorecardsDashboardFilter }) {
    const { getUserName } = useDataHelper()
    const [skills, setSkills] = useState([]);
    const [tableData, setTableData] = useState([])
    const [sortField, setSortField] = useState("callsScored")
    const [sortType, setSortType] = useState("DESC")
    const [isScorecardDashboardEmpty, setIsScorecardDashboardEmpty] = useState(false);
    const userId = useSelector(store => store.auth.userId)
    const [loading, setLoading] = useState(false);
    const [userScorecardStats, setUserScorecardStats] = useState({})
    const [oldScoresStats, setOldScoresStats] = useState({});
    const [averageDeltaOfScores, setAverageDeltaOfScores] = useState({})
    const handleSort = (sortField, sortType) => {
        setSortField(sortField)
        setSortType(sortType)
    }
    const screen = useSelector(store => store.screen)
    const [filterParams, setFilterParams] = useState(screen.scorecardsDashboardFilter)
    const { showOnlyRepScoresInDashboard } = useScorecardsPermissionHelper()
    const [isScorecardsGraphModalOpen, setIsScorecardsGraphModalOpen] = useState(false);
    const [showScorecardGraphFor, setShowScorecardGraphFor] = useState("");
    const [userIds, setUserIds] = useState([]);
    const { setSnackbar, hideSnackbar } = useSnackbar();

    useEffect(() => {
        getUserScorecardstats(filterParams, false);
    }, [])

    async function getUserScorecardstats(filterParamsFromProps = null, saveFilter = true) {
        if (!filterParamsFromProps) {
            filterParamsFromProps = filterParams;
        }
        if (showOnlyRepScoresInDashboard()) {
            filterParamsFromProps.scoredRep = [userId]
        }
        setFilterParams(filterParamsFromProps)
        setLoading(true);
        setFilterParams(filterParamsFromProps)
        const data = await getScorecardsDashboardData(filterParamsFromProps);

        if (data) {
            setUserScorecardStats(data?.userScoresStats ?? {})
            setScorecardsDashboardData(data?.userScoresStats ?? {})
            setOldScoresStats(data?.trendScoresStats ?? {})
            if (saveFilter) {
                setScorecardsDashboardFilter(filterParamsFromProps)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (oldScoresStats) {
            let localDeltaOfScoresAvg = cloneDeep(oldScoresStats)
            Object.keys(oldScoresStats).map((userId) => {
                if (userScorecardStats[userId]?.averageTotalScore && oldScoresStats[userId]?.averageTotalScore) {
                    localDeltaOfScoresAvg[userId].averageTotalScore = getTotalScoreAverageDiff(userId);
                }
                Object.keys(oldScoresStats[userId].skillScoreMap).map(skill => {
                    if (oldScoresStats[userId]?.skillScoreMap[skill] && userScorecardStats[userId]?.skillScoreMap[skill]) {
                        localDeltaOfScoresAvg[userId].skillScoreMap[skill] = getPercentageDiff(userId, skill)
                    }
                })
            })
            setAverageDeltaOfScores(localDeltaOfScoresAvg)
        }

    }, [oldScoresStats])


    async function resetFilter() {
        const filterParams = emptyFilterParams;
        getUserScorecardstats(filterParams);
    }

    async function handleRemoveFilterValue(fieldName) {
        const filterParamsUpdated = removeFilterValue(
            filterParams,
            fieldName
        );
        getUserScorecardstats(filterParamsUpdated);
    }


    useEffect(() => {
        if (userScorecardStats) {
            console.log('scorecard dashboard teamdashboard response', userScorecardStats);
            const tableDataAsList = []

            //convert userStatsMap to list
            Object.keys(userScorecardStats).forEach(key => {
                tableDataAsList.push({
                    ...userScorecardStats[key],
                    userId: key,
                })
            })
            setUserIds(Object.keys(userScorecardStats))


            const uniqueSkills = new Set()

            //collect all unique skills having valid Score in dashboard response

            tableDataAsList.forEach(row => {
                Object.keys((row?.skillScoreMap || {})).forEach(skill => {
                    if (row.skillScoreMap[skill] && row.skillScoreMap[skill] !== -99) {
                        uniqueSkills.add(skill)
                    }
                })
            })

            setSkills(Array.from(uniqueSkills))



            // to do ,find way to refactor, current mess is because sort field can be 
            /*  
    
            either of 'callsScored' or 'skill1
            {
                callsScored: 25,
                skillScoreMap{
                    "skill1": 5
                }
            } 
            */
            tableDataAsList.sort((a, b) => {
                if (sortType === "DESC") {
                    if (sortField === "callsScored" || sortField == "averageTotalScore") {
                        return b[sortField] - a[sortField]
                    }
                    else {
                        return b.skillScoreMap[sortField] - a.skillScoreMap[sortField]
                    }

                } else {
                    if (sortField === "callsScored" || sortField == "averageTotalScore") {
                        return a.skillScoreMap[sortField] - b.skillScoreMap[sortField]
                    }
                    else {
                        return a.skillScoreMap[sortField] - b.skillScoreMap[sortField]
                    }

                }
            }

            )
            setTableData(tableDataAsList)
        }
    }, [sortField, sortType, userScorecardStats])

    useEffect(() => {
        if (userScorecardStats) {
            if ((Object.keys(userScorecardStats)).length === 0) {
                setIsScorecardDashboardEmpty(true);
            }
            else {
                setIsScorecardDashboardEmpty(false);
            }
        }
    }, [userScorecardStats])

    function getPrefillFilterForScoredRep() {
        var showOnlyRepScores = showOnlyRepScoresInDashboard(userId);
        let filter = { "scoredRep": [userId] };
        if (!showOnlyRepScores) {
            return null;
        }

        return filter;
    }

    async function openScorecardGraphModal(userId) {
        setShowScorecardGraphFor(userId);
        setIsScorecardsGraphModalOpen(true)
    }

    function closeScorecardGraph() {
        setShowScorecardGraphFor("")
        setIsScorecardsGraphModalOpen(false)
    }
    async function downloadScoresDataAsExcel() {
        const filterParamsLocal = cloneDeep(filterParams);
        setSnackbar("Downloading report", "LOADING");
        const response = await sendRequest("/dashboard/download_scores_report", filterParamsLocal);
        downloadFile(response, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "ScoresReport.xlsx");
        hideSnackbar()
    }
    function getPercentageDiff(userId, skill) {
        const currentScore = userScorecardStats[userId]?.skillScoreMap?.[skill] ?? 0;
        const oldScore = oldScoresStats[userId]?.skillScoreMap?.[skill] ?? 0;
        const average = (((currentScore - oldScore) / oldScore) * 100).toFixed(0)
        return average;
    }

    function getTotalScoreAverageDiff(userId) {
        const oldTotalScore = oldScoresStats[userId]?.averageTotalScore ?? 0;
        const currentTotalScore = userScorecardStats[userId]?.averageTotalScore ?? 0;
        return (((currentTotalScore - oldTotalScore) / oldTotalScore) * 100).toFixed(0);

    }

    return (
        <>
            <div className=' flex flex-col relative'>
                <div className=" mt-3 h-9 absolute right-[3rem] z-1 ">
                    <ReactTooltip id="downloadExcel" effect="solid">
                        <TextBase textColor='text-coolGray-50'>Download Excel </TextBase>
                    </ReactTooltip>
                    <ExcelIcon className="h-10 w-10 mr-2 cursor-pointer" data-tip data-for="downloadExcel" onClick={downloadScoresDataAsExcel} />

                </div>
                <div style={{ display: 'flex', margin: '0px 0px 10px -2rem' }} >
                    <DashboardFilter
                        removeFilterValue={handleRemoveFilterValue}
                        resetFilter={resetFilter}
                        onSubmit={getUserScorecardstats}
                        filterParams={filterParams}
                        otherAgent={false}
                        type={"SCORECARDS"}
                        defaultFilters={["scoredRep", "scoredBy", "scoringTemplate"]}
                        prefillFilter={getPrefillFilterForScoredRep()}
                    />
                </div>
                {loading &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} spacing={8}>
                            <Skeleton height={250} width={"100%"} />
                        </Grid>
                    </Grid>
                }
                {!isScorecardDashboardEmpty && !loading &&
                    <div className='overflow-hidden rounded-2xl  bg-white border-solid border border-coolGray-200'>
                        <div className="wm-rep-score-tabl-container  overflow-x-auto">
                            <Table style={{ borderCollapse: "collapse" }} >
                                <TableHead className="bg-coolGray-50">
                                    <TableRow>
                                        <TableCell className='!pl-8'>
                                            {/*  TableHeader is not a Mui comp , it's a simple comp for title +sort icon */}
                                            <TableHeader className="whitespace-nowrap " disableSort={true}
                                                label="Rep Name"
                                            />
                                        </TableCell>
                                        <TableCell className='!px-3'>
                                            <TableHeader className="whitespace-nowrap" sortData={{ [sortField]: sortType }} sort={handleSort}
                                                label="Total Calls"
                                                field="callsScored"
                                            />
                                        </TableCell>
                                        {skills.map((skill, index) => {
                                            return (
                                                <TableCell key={skill + "_" + index} className='!px-3'>
                                                    <TableHeader className="whitespace-nowrap" sortData={{ [sortField]: sortType }} sort={handleSort}
                                                        label={skill}
                                                        field={skill}
                                                    />
                                                </TableCell>
                                            );
                                        })
                                        }
                                        <TableCell className='!px-3'>
                                            <TableHeader className="whitespace-nowrap" sortData={{ [sortField]: sortType }} sort={handleSort}
                                                label="Total Score"
                                                field="averageTotalScore"
                                            />
                                        </TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {tableData.map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover={false}
                                                    key={row.userId + 'row' + index}
                                                    className={
                                                        classNames("hover:bg-coolGray-50 cursor-pointer")
                                                    }
                                                    onClick={() => openScorecardGraphModal(row.userId)}

                                                >
                                                    <TableCell className='!py-6 !pl-8 '>
                                                        <div className='flex items-center !pr-12'>
                                                            <ProfileImage className="relative flex-shrink-0" userId={row.userId} size={40} >
                                                            </ProfileImage>
                                                            <div className='pl-3 flex-shrink-0 '>
                                                                <TextBase className="whitespace-nowrap">
                                                                    {getUserName(row.userId)}
                                                                </TextBase>
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className='!py-6 !pl-6'>
                                                        <TextBase className="flex justify-start items-center" textColor='text-coolGray-500'>
                                                            {userScorecardStats[row.userId]?.callsScored}
                                                        </TextBase>
                                                    </TableCell>
                                                    {
                                                        skills.map((skill, index) => {
                                                            return (<TableCell className='!py-6 !pl-5' key={skill + "_" + index}>
                                                                <div className='flex items-center'>
                                                                    <TextBase className="flex justify-start items-center" textColor='text-coolGray-500'>
                                                                        {userScorecardStats[row.userId]?.skillScoreMap?.[skill] || "--"}
                                                                    </TextBase>
                                                                    {userScorecardStats[row.userId]?.skillScoreMap?.[skill] && oldScoresStats[row.userId]?.skillScoreMap?.[skill] && averageDeltaOfScores[row.userId]?.skillScoreMap[skill] != 0 &&
                                                                        <>
                                                                            <TextSM textColor={averageDeltaOfScores[row.userId]?.skillScoreMap[skill] > 0 ? "text-emerald-600" : "text-red-600"} className="ml-1.5">
                                                                                {averageDeltaOfScores[row.userId]?.skillScoreMap[skill] + "%"}
                                                                            </TextSM>
                                                                            {averageDeltaOfScores[row.userId]?.skillScoreMap[skill] > 0 ? <UpTrendIcon className='ml-1' /> : <DownTrendIcon className='ml-1' />}
                                                                        </>
                                                                    }
                                                                </div>
                                                            </TableCell>

                                                            );
                                                        })
                                                    }
                                                    <TableCell className='!py-6 !pl-5'>

                                                        <div className='flex items-center' >

                                                            <TextBase className="flex justify-start items-center"  >
                                                                {userScorecardStats[row.userId]?.averageTotalScore}
                                                            </TextBase>


                                                            {oldScoresStats[row.userId] && oldScoresStats[row.userId]?.averageTotalScore && averageDeltaOfScores[row.userId]?.averageTotalScore != 0 &&
                                                                <div className={'flex items-center justify-evenly rounded-sm'}>
                                                                    <TextBase className="flex justify-start items-center ml-1.5" textColor={averageDeltaOfScores[row.userId].averageTotalScore > 0 ? "text-emerald-600" : "text-red-600"} >
                                                                        {averageDeltaOfScores[row.userId].averageTotalScore + "%"}
                                                                    </TextBase>

                                                                    {averageDeltaOfScores[row.userId].averageTotalScore > 0 ? <UpTrendIcon className='ml-1' /> : <DownTrendIcon className='ml-1' />}
                                                                </div>

                                                            }

                                                        </div>
                                                    </TableCell>
                                                </TableRow>)
                                        })
                                        }

                                    </>
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                }
                <div>
                    <ScorecardsGraphsModal
                        open={isScorecardsGraphModalOpen}
                        showScorecardGraphFor={showScorecardGraphFor}
                        userIds={userIds}
                        closeScorecardGraph={closeScorecardGraph}
                        filterParamsFromDashboard={filterParams}
                    />
                </div>
            </div>

            {isScorecardDashboardEmpty && !loading &&
                <>
                    <ScorecardDahsboardEmptyState />
                </>
            }
        </>
    )
}

