import React from "react";
import PropTypes from "prop-types";
import { flattenAllHighlights, flattenHighlights, _formatTalkListenRatio } from "../../utils/helpers";
import CommentIcon from "app/styles/assets/images/icons/player/timelineComment.svg";
import BookmarkIcon from "app/styles/assets/images/icons/player/timelineBookmark.svg";
import { ReactComponent as HighlightIcon } from "app/styles/assets/images/icons/player/timelineHighlight.svg";

const PlayerHighlightsTimeline = ({ callData, summaryData, call, highlightsData, duration }) => {
    function getLeft(time) {
        return (time * 100) / duration;
    }

    return (
        <div className="Timeline__progress-spectrum">
            {callData && callData.comments && callData.comments.map(comment => {
                if (!comment.startTime) return null;
                return (
                    <img
                        key={comment.id}
                        style={{ left: `calc(${getLeft(comment.startTime)}% - 6px)` }}
                        src={CommentIcon}
                        className="Timeline__progress-highlightsIcon"
                    />

                );
            })
            }
            {summaryData && summaryData.bookmarkedMoments && flattenHighlights(summaryData.bookmarkedMoments).map((bookmark, i) => {
                return (
                    <img
                        key={i}
                        style={{ left: `${getLeft(bookmark.startTime)}%` }}
                        src={BookmarkIcon}
                        className="Timeline__progress-highlightsIcon"
                    />
                );
            })
            }
            {
                call && call.matchingHighlights && flattenHighlights(call.matchingHighlights).map((highlight, i) => {
                    return (
                        <HighlightIcon
                            key={"highlight_" + i}
                            style={{ left: `${getLeft(highlight.startTime)}%`, color: "#ED2E7E" }}
                            className="Timeline__progress-highlightsIcon"
                        />
                    );
                })
            }
            {
                !(call?.matchingHighlights?.highlightGroups.length > 0) && highlightsData && flattenAllHighlights(highlightsData).map((highlight, i) => {
                    return (
                        <HighlightIcon
                            key={"highlight_" + i}
                            style={{ left: `${getLeft(highlight.startTime)}%`, color: "#ED2E7E" }}
                            className="Timeline__progress-highlightsIcon"
                        />
                    );
                })
            }
        </div>
    );
};

export default PlayerHighlightsTimeline;
