import React from "react";
import PropTypes from "prop-types";
import { Button } from "app/components/form";
import RecordingIcon from "@material-ui/icons/Mic";
import HearingIcon from "@material-ui/icons/Hearing";

const RecordingSelection = props => {
  return (
    <div className="RecordingSelection__outerContainer">
      <span className="RecordingSelection__title">Will you be recording?</span>
      <span className="RecordingSelection__copy">
        {"Do you also call customers and would like your calls analyzed?"}
      </span>
      <div className="RecordingSelection__buttonContainer">
        <div className="RecordingSelection__button">
          <Button  onClick={() => props.setRecording(false)}>
            <HearingIcon className="RecordingSelection__button_icon" fontSize="small" />
            <span>I will only be listening</span>
          </Button>
        </div>
        <div className="RecordingSelection__button">
          <Button  onClick={() => props.setRecording(true)}>
            <RecordingIcon className="RecordingSelection__button_icon" fontSize="small" />
            <span>I will also be recording</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

RecordingSelection.propTypes = {};

export default RecordingSelection;
