import {
  sendRequest
} from "app/utils/network";

export async function getDocTypes() {
  const response = await sendRequest("/admin/get_custom_fields_document_types");
  return response;
}

export async function getAllDocFields() {
  const response = await sendRequest("/admin/get_all_custom_fields");
  return response;
}

export async function getDocFields(type = "NONE") {
  const params = new URLSearchParams();
  params.append("docType", type);
  const response = await sendRequest("/admin/get_custom_fields", params);
  return response;
}

export async function getRequiredFields(type = "NONE") {
  const params = new URLSearchParams();
  params.append("docType", type);
  const response = await sendRequest("/admin/get_required_custom_fields", params);
  return response;
}

export async function getDefaultCRM() {
  const response = await sendRequest("/user/get_default_crm");
  return response;
}

export async function setFieldAsRequired(type, field) {
  const params = new URLSearchParams();
  params.append("docType", type);
  params.append("requiredFields", [field]);
  const response = await sendRequest("/admin/set_required_fields", params);
  return response;
}

export async function setFieldAsNotRequired(type, field) {
  const params = new URLSearchParams();
  params.append("docType", type);
  params.append("ignoredFields", [field]);
  const response = await sendRequest("/admin/set_required_fields", params);
  return response;
}