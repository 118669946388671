import React, { useState } from "react";
import Slider from "rc-slider/lib/Slider";
import volume from "app/styles/assets/images/icons/player/volume.svg";
import OutsideClickHandler from 'react-outside-click-handler';

const PlaybackVolumeControls = props => {
  const [showSlider, setShowSlider] = useState(false);
  const { volumeProps } = props;

  const toggleSlider = () => { setShowSlider(showSlider => !showSlider); }

  return (
    <OutsideClickHandler onOutsideClick={() => setShowSlider(false)}>
      <div className="Player__group">
        <div className="Player__volume">
          <img className="Player__volume-icon" src={volume} onClick={toggleSlider} alt="volume" />
          {showSlider && <div className="Player__volume-sliderContainer">
            <Slider
              vertical
              allowCross={false}
              value={volumeProps.value}
              onChange={volumeProps.onChange}
              trackStyle={[
                { backgroundColor: "#5B6DDC", borderColor: "#5B6DDC" },
                { backgroundColor: "#5B6DDC", borderColor: "#5B6DDC" },
              ]}
              handleStyle={[
                { backgroundColor: "#5B6DDC", borderColor: "#fff" },
                { backgroundColor: "#5B6DDC", borderColor: "#fff" },
              ]}
              railStyle={{ backgroundColor: "#D4D3D3" }}
            />
          </div>}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default PlaybackVolumeControls;
