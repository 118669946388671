import React from "react";
import { InputBase } from "@material-ui/core";
import WarningIcon from "app/styles/assets/images/icons/warning.png";
import { PropTypes } from "prop-types";
import classNames from "classnames";

const Input = ({
  warning,
  warningMessage,
  infoMessage,
  disabled,
  value,
  onChange,
  type,
  placeholder,
  inputRef,
  size,
  endAdornment,
  onKeyUp,
  rowsize,
  autoFocus,
  ...otherProps
}) => {
  let className = "Form__inputContainer";
  if (disabled) className = "Form__disabledInputContainer";
  if (warning) className = "Form__errorInputContainer";
  if (size == "smaller") className = "Form__smallerInputContainer";

  let customDataAttributes = {};
  if (otherProps["data-for"]) {
    customDataAttributes["data-for"] = otherProps["data-for"];
  }

  if (otherProps["data-tip"]) {
    customDataAttributes["data-tip"] = otherProps["data-tip"];
  }

  return (
    <div className="Form__tpContainer__input" {...customDataAttributes}>
      {warning && (
        <div className="Form__tpContainer__input__warningContainer">
          <span className="Form__warning">
            {warningMessage &&
              <img className="Form__warning-icon" src={WarningIcon} alt="" />}
            {warningMessage}
          </span>
        </div>
      )}
      {infoMessage && !warning && (
        <div className="Form__tpContainer__input__warningContainer">
          <span className="Form__info">{infoMessage}</span>
        </div>
      )}
      <div className={classNames(className, size === "big" ? "Form__largeInputContainer" : null)}>
        <InputBase
          type={type}
          autoFocus={autoFocus}
          multiline={size === "big"}
          rows={rowsize}
          rowsMax={100}
          endAdornment={endAdornment}
          disabled={disabled}
          placeholder={placeholder}
          className="Form__input !text-sm"
          value={value}
          inputRef={inputRef}
          onChange={onChange}
          onKeyUp={onKeyUp}
          {...otherProps}
        />
      </div>
    </div>
  );
};

Input.defaultProps = {
  warning: false,
  infoMessage: null,
  disabled: false,
  autoFocus: false,
  warningMessage: null,
  rowsize: 5,
  onChange: () => { },
  type: "text",
  placeholder: "",
  size: "small",
  inputRef: () => { },
  endAdornment: null,
};

Input.propTypes = {
  warning: PropTypes.bool,
  infoMessage: PropTypes.string,
  disabled: PropTypes.bool,
  warningMessage: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  rowsize: PropTypes.number,
  inputRef: PropTypes.func,
  endAdornment: PropTypes.node,
};

export default Input;
