import React, { useState, useRef } from 'react';
import { TextBase } from 'app/designSystem'
import cx from 'classnames';
import { MAX_LEVEL_OF_FOLDERS } from 'app/containers/Folder'

export default function AccordianItem({ folder, children, level = 0, selectedFolder, onFolderClick, disabled, className, style }) {
    const [isOpen, setIsOpen] = useState(level === 0);
    const isSelected = selectedFolder && selectedFolder.id === folder.id;
    const ref = useRef(null);

    const onTitleClick = () => {
        if (disabled) return;

        onFolderClick({ ...folder, level })

        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    const toggle = (e) => {
        setIsOpen(!isOpen)
        e.preventDefault();
    };

    return (
        <div className={className} style={{
            marginLeft: `${level * 1}rem`,
            ...style,
        }} ref={ref}>
            <div className={cx("flex cursor-pointer p-1 items-center rounded-lg select-none", isSelected && "bg-gray-100", disabled && "opacity-50")} onClick={onTitleClick} onDoubleClick={toggle}>
                <button className="p-0.5 hover:bg-coolGray-50 rounded-lg mr-2 w-4" onClick={toggle} disabled={disabled}>
                    {/* NOTE: Don't show button after max level */}
                    {(folder.hasChildren && level < MAX_LEVEL_OF_FOLDERS) ? isOpen ? '-' : '+' : ' '}
                </button>
                <TextBase>
                    {folder.name}
                </TextBase>
            </div>
            {isOpen && <div>
                {children}
            </div>}
        </div>
    );
}