import React, { useState } from 'react'
import { DealFieldSmall } from './DealField';
import AmountIcon from "app/styles/assets/images/icons/dealView/AmountIcon.svg";
import CloseDateIcon from "app/styles/assets/images/icons/dealView/CloseDateIcon.svg";
import DealStageIcon from "app/styles/assets/images/icons/dealView/DealStageIcon.svg";
import { TextXS } from 'app/designSystem';

import { _formatDateToDaysRelative } from '../../utils/helpers';
import OutsideClickHandler from 'react-outside-click-handler';
import ProfileImage from '../common/ProfileImage';
import { useSelector } from 'react-redux';
import { fetchDataSafely } from "app/utils/dataUtils";
import { GetSortOrder } from '../dashboards/helpers';
import { useEffect } from 'react';
import { Radio } from '@material-ui/core';

const DealSelector = ({ activeDeal, setActiveDeal, crmData }) => {

    const [showSelector, setShowSelector] = useState(false);
    const [hasMultipleDeals, setHasMultipleDeals] = useState(false);

    useEffect(() => {
        if (crmData && crmData.opportunities && crmData.opportunities.length > 1)
            setHasMultipleDeals(true);
    }, [crmData])

    function toggleDropdown() {
        setShowSelector(showSelector => !showSelector);
    }
    return (
        <OutsideClickHandler onOutsideClick={() => setShowSelector(false)}>
            <div onClick={toggleDropdown} className={`${hasMultipleDeals ? "DealSelectorWithDropdown" : ""} ${activeDeal ? "DealSelector" : "DealSelector DealSelectorSmall"}`}>
                {!activeDeal && <span className="DealSelector__name">All Interactions</span>}
                {activeDeal && <DealInfo activeDeal={activeDeal} />}
                {showSelector && hasMultipleDeals &&
                    <div className="DealSelector__dropdown">
                        {crmData.opportunities.sort(GetSortOrder("createdDate", "DESC")).map((opp, i) => {
                            return (
                                <div key={i} onClick={() => setActiveDeal(opp)} className="DealSelector__item">
                                    <div className="DealSelector__radio">
                                        <Radio color="primary" checked={activeDeal.id === opp.id} />
                                    </div>
                                    <DealItem activeDeal={opp} />
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        </OutsideClickHandler>
    );
}

const DealInfo = ({ activeDeal }) => {
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const currency = useSelector(store => store?.auth?.stringsCustomer?.defaultCurrency) ?? "$";

    return (
        <div style={{ width: "inherit" }}>
            <span className="DealSelector__name">{activeDeal.opportunityName}</span>
            <div className="DealSelector__fieldsContainer">
                <div className="DealSelector__column">
                    <div className="DealSelector__row">
                        <DealFieldSmall icon={CloseDateIcon} value={activeDeal && activeDeal.closeDate ? _formatDateToDaysRelative(activeDeal.closeDate) : ""} label="Close Date" />
                        <DealFieldSmall icon={DealStageIcon} value={activeDeal && activeDeal.stage ? activeDeal.stage : ""} label="Deal Stage" />
                    </div>
                    <div className="DealSelector__row">
                        <DealFieldSmall icon={AmountIcon} value={activeDeal && activeDeal.amount ? currency + activeDeal.amount : ""} label="Deal Size" />
                        {userNames && fetchDataSafely(userNames.find(o => o.value === (activeDeal?.ownerId)), "label", "") !== "" &&
                            <div className="DealFieldDiv">
                                <div className="DealView__Header__ownerNameIconDiv">
                                    <ProfileImage userId={activeDeal?.ownerId} size={24} tooltipPosition="bottom" />
                                    <div className="DealFieldLabelValueDiv" style={{ marginLeft: "5px" }}>
                                        <TextXS
                                            textColor="text-coolGray-900"
                                            className={"whitespace-nowrap font-semibold"}>
                                            {fetchDataSafely(userNames.find(o => o.value === (activeDeal?.ownerId)), "label", "")}
                                        </TextXS>
                                        <TextXS
                                            textColor="text-coolGray-500"
                                            className="whitespace-nowrap">
                                            Deal Owner
                                        </TextXS>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>);
}

const DealItem = ({ activeDeal }) => {
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const currency = useSelector(store => store?.auth?.stringsCustomer?.defaultCurrency) ?? "$";

    return (
        <div style={{ width: "inherit" }}>
            <div className="DealItem__name">{activeDeal.opportunityName}</div>
            <div className="DealSelector__fieldsContainer">
                <div className="DealSelector__column">
                    <DealFieldSmall icon={null} value={activeDeal && activeDeal.amount ? currency + activeDeal.amount : ""} label="Deal Size" />
                    <DealFieldSmall icon={null} value={activeDeal && activeDeal.stage ? activeDeal.stage : ""} label="Deal Stage" />
                    <DealFieldSmall icon={null} value={activeDeal && activeDeal.closeDate ? _formatDateToDaysRelative(activeDeal.closeDate) : ""} label="Close Date" />
                    <DealFieldSmall icon={null} value={activeDeal && activeDeal.ownerId ? fetchDataSafely(userNames.find(o => o.value === activeDeal.ownerId), "label", "") : ""} label="Deal Owner" />
                </div>
            </div>
        </div>);
}

DealSelector.propTypes = {}
DealSelector.defaultProps = {}
export default DealSelector;