import React, { useState } from "react";
import { Button, TextSM } from 'app/designSystem'
import { SpeedMeterIcon } from 'app/designSystem/icons'
import OutsideClickHandler from "react-outside-click-handler";

const PlaybackSpeedMenu = ({ onChange, currentSpeed = 1 }) => {
    const [showSpeedMenu, setShowSpeedMenu] = useState(false);
    const allowedSpeeds = [2.5, 2.25, 2.0, 1.75, 1.5, 1.25, 1.0];

    function handleSpeedChange(speed) {
        onChange(speed);
        setShowSpeedMenu(false);
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setShowSpeedMenu(false)}>
            <div className="flex items-center relative w-16 mr-1">
                <Button variant="tertiary" onClick={() => setShowSpeedMenu(showSpeedMenu => !showSpeedMenu)} className="mr-4 flex items-center">
                    <SpeedMeterIcon className="text-gray-600" />
                    <TextSM className="ml-2">{currentSpeed}{currentSpeed.toString().length === 1 ? ".0" : null}x</TextSM>
                </Button>
                {showSpeedMenu && (
                    <div className="absolute bg-white border border-1 rounded bottom-8 z-50">
                        {allowedSpeeds.map(speed => {
                            return (
                                <TextSM key={"Gametape_Player_Speed" + speed} className="flex items-center px-3 py-2 cursor-pointer hover:bg-gray-100" onClick={() => handleSpeedChange(speed)}>{speed}{speed.toString().length === 1 ? ".0" : null}</TextSM>
                            )
                        })}
                    </div>
                )}
            </div>
        </OutsideClickHandler>
    );
};

export default PlaybackSpeedMenu;
