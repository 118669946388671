import React, { useState, useEffect } from "react";
import { Grid, Paper, Modal } from '@material-ui/core';
import { Line } from "react-chartjs-2";
import { Text2XL, CloseIcon } from "app/designSystem"
import { SelectWithSearchNew, CategoryHeader, DateFiltersNew } from "app/components/filter";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import Skeleton from 'react-loading-skeleton';
import useScorecardsPermissionHelper from 'app/utils/hooks/useScorecardsPermissionHelper';
import useDataHelper from "app/utils/hooks/useDataHelper";
import { scorecardsGraphsDefaultInput } from "app/utils/filter";
import { getJSONFromFilterParams, sendRequest } from "app/utils/network";

const ScorecardsGraphsModal = (props) => {

    const [userIds, setUserIds] = useState([])
    const userNames = useSelector(store => store.screen.metaData.userNames);
    const [loading, setLoading] = useState(true);
    const [scorecardsGraphsResponse, setScorecardsGraphsResponse] = useState([]);
    const [inputForScorecardsGraphs, setInputForScoreardsGraphs] = useState(scorecardsGraphsDefaultInput);
    const skills = useSelector(store => store.screen.metaData.scoringSkills || [])
    const [skillSelected, setSkillSelected] = useState([]);
    const { showOnlyRepScoresInDashboard } = useScorecardsPermissionHelper()
    const { getUserName } = useDataHelper();
    const chartColors = ["#FFB200", "#4339F2", "#02A0FC", "#FF3A29"];

    function handleClose() {

        setUserIds([])
        setSkillSelected([])
        setScorecardsGraphsResponse([])
        props.closeScorecardGraph();
    }


    useEffect(() => {
        if (props.showScorecardGraphFor) {
            setUserIds([props.showScorecardGraphFor])
        }
        const filterParams = props.filterParamsFromDashboard
        let scorecardsGraphsInputLocal = cloneDeep(inputForScorecardsGraphs)
        if (filterParams.startDate && filterParams.endDate) {
            scorecardsGraphsInputLocal["startDate"] = filterParams.startDate;
            scorecardsGraphsInputLocal["endDate"] = filterParams.endDate;
            setInputForScoreardsGraphs(scorecardsGraphsInputLocal)
        } else {
            scorecardsGraphsInputLocal["startDate"] = null;
            scorecardsGraphsInputLocal["endDate"] = null;
            setInputForScoreardsGraphs(scorecardsGraphsInputLocal)
        }
        getDataForScorecardsGraphs(scorecardsGraphsInputLocal, [props.showScorecardGraphFor])
    }, [props.showScorecardGraphFor, props.filterParamsFromDashboard])

    async function getDataForScorecardsGraphs(input, userIds) {
        setLoading(true)
        let updatedInput = cloneDeep(input)
        updatedInput["users"] = userIds
        updatedInput["filterParams"] = getJSONFromFilterParams(props.filterParamsFromDashboard)
        const response = await sendRequest("/dashboard/scorecard_graph", updatedInput)
        setScorecardsGraphsResponse(response.scorecardsGraphsData)
        setLoading(false)
    }

    function onDateChange(fieldName, value) {
        const updatedInput = cloneDeep(inputForScorecardsGraphs);
        updatedInput[fieldName] = value;
        getDataForScorecardsGraphs(updatedInput, userIds)
        setInputForScoreardsGraphs(updatedInput);
    }

    function organiseDataForGraph(response, type, user) {
        let averageScores = [];
        let skillScores = [];
        //all the computations go here, for next round, this function will take skill name as one more prop and return it.Type will be 'skillScores' for it, and also UserId of manager or rep based on selection.Backend gives  2 data objects.Whole managers team scores and rep scores.
        response.map(data => {
            if (data?.graphsData[user]) {
                let userScoresData = data.graphsData[user]
                let skillScoreMap = userScoresData.skillScoreMap
                averageScores.push(userScoresData["averageTotalScore"])
                if (skillSelected.length > 0) {
                    skillScores.push(skillScoreMap[skillSelected[0]])
                }
            }
            else {
                averageScores.push(null)
            }
        })

        //all the responses go here
        if (type === "averageScore") {
            return averageScores;
        }
        else {
            return skillScores
        }

    }

    function onMultipleDateChanges(fieldNames, values) {
        const updatedInput = cloneDeep(inputForScorecardsGraphs);
        fieldNames.map((field, i) => {
            updatedInput[field] = values[i]
        })
        setInputForScoreardsGraphs(updatedInput);
        getDataForScorecardsGraphs(updatedInput, userIds)
    }

    function handleSkillsDropdown(skillName) {
        if (skillName === "TOTAL_SCORE") {
            setSkillSelected([]);
            return;
        }
        setSkillSelected([skillName])
    }

    function getDataSets() {
        let datasets = []
        let dataSetProto = {
            type: "line",
            label: null,
            borderWidth: 1,
            radius: 4,
            data: null,
            tension: 0.05,
            fill: false,
            borderColor: null

        }

        userIds.map((userId, i) => {
            const updatedJson = { ...dataSetProto, order: i, borderColor: chartColors[i % chartColors.length], data: organiseDataForGraph(scorecardsGraphsResponse, skillSelected.length > 0 ? skillSelected[0] : "averageScore", userId), label: getUserName(userId) }
            datasets.push(updatedJson)
        })
        return datasets;
    }

    function handleRepChange(options) {
        const ids = options ? options.map(a => a.value) : []
        setUserIds(ids)
        getDataForScorecardsGraphs(inputForScorecardsGraphs, ids)
    }

    function getSkillScores(index) {
        //Just added logic, will decide to show skill scores or not down the line.
        if (userIds.length === 1) {
            const scoresObject = scorecardsGraphsResponse[index]
            const skillScoreMap = scoresObject?.graphsData[userIds[0]]?.skillScoreMap ?? {}
            let skillScoreString = ""
            skills.map(skill => {
                if (skillScoreMap[skill]) {
                    skillScoreString = skillScoreString + skill + " score" + skillScoreMap[skill] + ","
                }
            })
            return skillScoreString;
        }
        else {
            return ""
        }
    }


    /*  function getTotalCallsScoredForThatPeriod(index) {
          if (scorecardsGraphsResponse && userIds.length === 1) {
              const scoresObject = scorecardsGraphsResponse[index]
              const callsScored = scoresObject?.graphsData[userIds[0]]?.callsScored ?? 0
              return "Calls scored " + callsScored
          } else {
              return ""
          }
      } commenting this part until we find out why the index(Helps to distinguish from which dataset tooltip is firing) prop is missing in out react graph which we use */


    const data = canvas => {
        const ctx = canvas.getContext('2d');
        const totalCallsGradient = ctx.createLinearGradient(0, 0, 0, 200);
        totalCallsGradient.addColorStop(0.4, 'rgba(45, 91, 255, 0.34)');
        totalCallsGradient.addColorStop(1, 'rgba(83, 97, 255, 3.4e-05)');
        //This gradient stuff will be useful in next iteration.Currently not used.
        return {
            labels: scorecardsGraphsResponse.map(data => data.label),
            datasets: getDataSets()
        };

    }

    return (
        <div >
            <Modal open={props.open} onClose={handleClose} className="flex items-center justify-center" >
                <div tabIndex="null" className="!bg-white rounded-lg outline-none w-[1100px] flex flex-col py-7 px-9"
                    style={{
                        minHeight: "calc(90vh - 8rem)"
                    }}  >
                    <div className="flex items-center justify-between">
                        <Text2XL>Score progress</Text2XL>
                        <CloseIcon onClick={handleClose} className="cursor-pointer" />
                    </div>

                    <div className="flex items-center justify-start mr-3 mt-6">
                        <div className="Filter__fieldContainer ml-0">
                            <CategoryHeader label="Rep" />
                            <SelectWithSearchNew
                                key="rep"
                                fieldName="rep"
                                joinPhrase=" and "
                                data={userNames} //Will help in getting the value of the selected user 
                                options={userNames} //Will show the list
                                value={userIds}
                                label="Select Reps"
                                placeholder="Type rep name"
                                hasProfilePic
                                dontShowCloseIcon={true}
                                handleChange={options => (
                                    handleRepChange(options)
                                )}
                                disabled={showOnlyRepScoresInDashboard()}
                            />
                        </div>
                        <div className=" flex flex-col items-start justify-center">
                            <CategoryHeader label="Date" />
                            <DateFiltersNew
                                key="Date"
                                showIcon
                                onStartDateChange={options => onDateChange("startDate", options)}
                                onEndDateChange={options => onDateChange("endDate", options)}
                                startDate={inputForScorecardsGraphs?.startDate}
                                endDate={inputForScorecardsGraphs?.endDate}
                                onStartAndEndChange={values => onMultipleDateChanges(["startDate", "endDate"], values)}
                                fieldName="date"
                                enableRemove={false}

                            />


                        </div>

                        <div className=" flex flex-col items-start justify-center ml-3">
                            <CategoryHeader label="Score type" />
                            <SelectWithSearchNew
                                key="scoredSkill"
                                joinPhrase=" or "
                                fieldName="scored skill"
                                data={["TOTAL_SCORE", ...skills].map(skill => ({ label: skill != "TOTAL_SCORE" ? skill : "Total score", value: skill }))}
                                label="Choose skill"
                                placeholder="Type skill name"
                                isMulti={false}
                                dontShowCloseIcon={true}
                                value={skillSelected.length > 0 ? skillSelected[0] : "TOTAL_SCORE"}
                                handleChange={options =>
                                    handleSkillsDropdown(options.value)
                                }
                            />
                        </div>


                    </div>
                    <div className="mt-4">
                        {loading &&

                            <Grid container spacing={3} className="">
                                <Grid item xs={12} spacing={8}>
                                    <Skeleton height={480} width={"100%"} />
                                </Grid>
                            </Grid>

                        }
                    </div>
                    <div className="mt-4">

                        {scorecardsGraphsResponse && !loading &&
                            <Grid item xs={12} className="p-5">

                                <Line data={data} options={{
                                    spanGaps: true,
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                maxTicksLimit: 10,
                                                fontSize: 13,
                                                fontColor: "#9EA5BD",
                                                autoSkip: false,
                                                maxRotation: 30,
                                                minRotation: 0
                                            }
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                beginAtZero: true,
                                                maxTicksLimit: 8,
                                                fontSize: 13,
                                                fontColor: "#9EA5BD",
                                                max: 6
                                            }
                                        }]
                                    },
                                    tooltips: {
                                        backgroundColor: '#1E293B',
                                        displayColors: false,
                                        bodyFontColor: 'white',
                                        titleFontColor: 'white',
                                        axis: 'x',
                                        intersect: false,
                                        yAlign: "bottom",
                                        /* callbacks: {
                                             beforeBody: function (chart) {
                                                 return getTotalCallsScoredForThatPeriod(chart[0].index)
                                             }
                                         }*/
                                    }
                                }} />



                            </Grid>
                        }

                    </div>
                </div>

            </Modal>
        </div>
    )
}

export default ScorecardsGraphsModal;