import React from 'react';
import { IntegrationSettings } from '../settings';

const CompleteIntegrations = props => {

	return (
		<div className="EmptyState__CompleteIntegrations">
			<span className="_subLine">Complete your integrations:</span>
			<div className="integrationsContainer">
				<IntegrationSettings showOnlyUnsynced {...props} />
			</div>
		</div>
	);
}

export default CompleteIntegrations;