import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { HeaderCall } from '.';
import { SkeletonHeader } from '../dealView/AccountViewHeader';
import { Player } from '../player';

const CallReviewSkeletal = ({ showCrmContext, showBackBtn }) => {
    return (
        <div className="CallReview">
            <SkeletonHeader goBack={() => { }} showBackBtn={showBackBtn} />
            <div className="Call__body">
                {showCrmContext && <div className="CrmContextContainer">
                    <div className="CrmContextContainer__body">
                        <br />
                        <Skeleton height={15} width={150} />
                        <br />
                        <br />
                        <div className={`DealView__Header__dealFieldDiv`}>
                            <Skeleton className="DealView__Header__dealFieldIcon" circle height={30} width={30} />
                            <div className="DealView__Header__dealFieldLabelValueDiv">
                                <Skeleton height={6} width={100} />
                                <Skeleton height={12} width={130} />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className={`DealView__Header__dealFieldDiv`}>
                            <Skeleton className="DealView__Header__dealFieldIcon" circle height={30} width={30} />
                            <div className="DealView__Header__dealFieldLabelValueDiv">
                                <Skeleton height={6} width={100} />
                                <Skeleton height={12} width={130} />
                            </div>
                        </div>
                        <br />
                        <br />
                        <div className={`DealView__Header__dealFieldDiv`}>
                            <Skeleton className="DealView__Header__dealFieldIcon" circle height={30} width={30} />
                            <div className="DealView__Header__dealFieldLabelValueDiv">
                                <Skeleton height={6} width={100} />
                                <Skeleton height={12} width={130} />
                            </div>
                        </div>
                    </div>
                </div>
                }
                <TranscriptVideoPlayerLoading />
                <div className="Call__NotesAndComments">
                    <EmptyComments />
                </div>
            </div>
        </div>
    );
}

export const TranscriptVideoPlayerLoading = ({ isSharedCall }) => (
    <div className={`${isSharedCall ? "Call__transcriptVideoPlayerContainerShared" : "Call__transcriptVideoPlayerContainer"} col-topcentered`}>
        <div className="Call__videoContainer">
            <Skeleton className="stretch" width={1200} height={680} />
        </div>
        <Skeleton className="Player__mainControls" height={30} width={600} />
        <br />
        <br />
        <Skeleton height={10} width={500} />
        <br />
        <Skeleton height={10} width={500} />
        <br />
        <Skeleton height={10} width={500} />
        <br />
    </div>
);


export const EmptyComments = props => (
    <div className="Comment__fullContainer">
        <br />
        <Skeleton height={40} />
        <div className="Comment__outerContainer">
            <br />
            <br />
            <br />
            <span className="CallPreview__subject">
                <Skeleton circle height={30} width={30} />
                <span style={{ marginLeft: "5px" }}>
                    <Skeleton height={20} width={200} />
                </span>
            </span>
            <div style={{ marginLeft: "40px" }}>
                <Skeleton height={10} width={200} />
                <Skeleton height={10} width={210} />
            </div>
            <br />
            <br />
            <br />
            <span className="CallPreview__subject">
                <Skeleton circle height={30} width={30} />
                <span style={{ marginLeft: "5px" }}>
                    <Skeleton height={20} width={200} />
                </span>
            </span>
            <div style={{ marginLeft: "40px" }}>
                <Skeleton height={10} width={200} />
                <Skeleton height={10} width={210} />
            </div>
        </div>
    </div>
);

export default CallReviewSkeletal;