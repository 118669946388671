import React from "react";
import PropTypes from "prop-types";
import { object } from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import volume from "app/styles/assets/images/icons/volume.png";
import { _formatSecondsToPlayingTime } from "app/utils/helpers";
import { ShareCallButtonContainer } from "app/components/player";
import { STACKED, MAIN } from "app/constants";
import { metrics } from "app/utils/metrics";
import PlaybackDetails from "./PlaybackDetails";
import PlaybackWaveform from "./PlaybackWaveform";
import PlaybackSpeedMenu from "./PlaybackSpeedMenu";
import PlaybackControl from "./PlaybackControl";
import PlaybackVolumeControls from "./PlaybackVolumeControls";
import ViewDetails from "./ViewDetails";
import { setActiveTime } from "app/actions/call";
import { CardsShown } from "./CardsShown";

const highLightsClickBufferTime = 0.5;
const styles = () => ({
  marginWithPlayer: {
    paddingLeft: "60px",
  },
  selectRoot: {
    width: "100%",
    position: "relative",
    marginTop: 3,
  },
  select: {
    width: "calc(100% - 3px)",
    cursor: "pointer",
    minWidth: 16,
    userSelect: "none",
    paddingRight: 32,
    color: "#3150D2",
    fontSize: "11.66px",
    fontWeight: "bold",
    border: 0,
    margin: 0,
    padding: "3px 0 3px",
    display: "block",
    flexGrow: 1,
    boxSizing: "content-box",
    background: "none",
    verticalAlign: "middle",
  },
});

class PlayerController extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackwardClick = this.handleBackwardClick.bind(this);
    this.handlePlayClick = this.handlePlayClick.bind(this);
    this.handleForwardClick = this.handleForwardClick.bind(this);
    this.handleSeekChange = this.handleSeekChange.bind(this);
    this.handleVolumeChange = this.handleVolumeChange.bind(this);
    this.handleStopPlaying = this.handleStopPlaying.bind(this);
    this.handleSpeedChange = this.handleSpeedChange.bind(this);
    this.handleSkipPress = this.handleSkipPress.bind(this);
    this.handlePlayError = this.handlePlayError.bind(this);
    this.handleSeekTime = this.handleSeekTime.bind(this);
    this.state = { waveformTurns: [] };
    this.showCardsShown = localStorage.getItem("showBattlecardsShown") === "SHOW";
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.playback.url !== null && nextProps.playback.url !== this.props.playback.url) {
      const waveformTurns = this._calculateWaveformFromConversation(
        nextProps.playback.conversationTurn,
        nextProps.playback.duration,
        nextProps.playback.speakers,
      );
      this.setState({
        waveformTurns,
      });
    }
  }

  // Calculate width of the running bar
  _calculateBarWidth(currentPlayingTime, callDurationSeconds) {
    let width = 0;
    if (!callDurationSeconds) return width;
    width = (currentPlayingTime * 100) / callDurationSeconds;
    return width;
  }

  // Calculate waveform width and position for display
  _calculateWaveformFromConversation(conversationTurn, callDurationSeconds, speakers) {
    const waveformTurns = [];
    if (!callDurationSeconds) return waveformTurns;
    conversationTurn.forEach(turn => {
      const waveformTurn = {};
      waveformTurn.id = turn.turnId;
      waveformTurn.type = speakers && speakers[turn.speakerId - 1] && speakers[turn.speakerId - 1].indexOf("Customer") === -1 ? "opportunity" : "agent";
      waveformTurn.left = (turn.startTime * 100) / callDurationSeconds;
      waveformTurn.width = ((turn.endTime - turn.startTime) * 100) / callDurationSeconds;
      if (waveformTurn.width < 0.3508) {
        /**
         * if start time equal the end time, ther width of waveform would be 1px
         * (0.351 percent this this case)
         */
        waveformTurn.width = 0.3508;
      }
      waveformTurns.push(waveformTurn);
    });
    return waveformTurns;
  }

  // Handle move the cursor and calculate time for player to play in Specified position
  handleSkipPress(e) {
    e.persist();

    let offsetLeft = e.target.parentNode.offsetLeft || 0;
    if (offsetLeft === 0) {
      offsetLeft = e.target.parentNode.parentNode.offsetLeft || 0;
      if (offsetLeft === 0) return;
    }
    const skippPointRate = (e.clientX - offsetLeft) / e.target.parentNode.clientWidth;
    /**
     *  Because of if press in the same position
     *  The CustomHowler can't check the pos are not equal with previous positon of seek,
     *  it make the CustomHowler ignore to seek to same previous position
     */
    const rd = Math.random() / 10000;
    const skippPointRateWithLitleRandomPos = skippPointRate + rd;
    const currentPlayingTime = this.props.playback.duration * skippPointRateWithLitleRandomPos;
    this.props.setSeek(currentPlayingTime);
    this.props.setActiveTime(currentPlayingTime);
  }

  handleSeekTime(time) {
    this.props.setSeek(time);
    this.props.setActiveTime(time);
  }

  handleVolumeChange(e) {
    this.props.setVolume(e / 100);
  }

  // Calculate the time to display in player
  handleSeekChange(time) { }

  handleStopPlaying() {
    this.props.setPause();
  }

  handleSpeedChange(e) {
    this.props.setRate(Number(e.target.value));
  }

  // skip back 15 seconds
  handleBackwardClick() {
    const currentPlayingTime = this.props.seek;
    const rd = Math.random() / 10000;
    if (currentPlayingTime < 15) {
      this.props.setSeek(rd);
      this.props.setActiveTime(rd);
    } else {
      this.props.setSeek(currentPlayingTime - 15.0 + rd);
      this.props.setActiveTime(currentPlayingTime - 15.0 + rd);
    }
  }

  // Play or Pause click
  handlePlayClick() {
    if (this.props.playing) {
      this.props.setPause();
    } else {
      this.props.setPlay();
    }
  }

  // skip forward 15 seconds
  handleForwardClick() {
    const currentPlayingTime = this.props.seek;
    if (currentPlayingTime > this.props.playback.duration - 15.0) {
      this.props.setSeek(this.props.playback.duration);
      this.props.setActiveTime(this.props.playback.duration);
    } else {
      this.props.setSeek(currentPlayingTime + 15.0);
      this.props.setActiveTime(currentPlayingTime + 15.0);
    }
  }

  handlePlayError(id, error) {
    metrics.logEvent("errorPlaying");
    this.handleStopPlaying();
  }

  render() {
    const howlerProps = {
      src: [this.props.playback.url],
      playing: this.props.playback.playing,
      volume: this.props.volume,
      rate: this.props.rate,
      seek: this.props.seek,
      onEnd: this.handleStopPlaying,
      onPlayError: this.handlePlayError,
      loop: false,
    };
    const progressProps = {
      waveformTurns: this.state.waveformTurns,
      onSkipPress: this.handleSkipPress,
      barWidth: this._calculateBarWidth(this.props.seek, this.props.playback.duration),
    };
    const playingTimeProps = {
      current: _formatSecondsToPlayingTime(this.props.seek),
      duration: _formatSecondsToPlayingTime(this.props.playback.duration),
    };
    const speedProps = {
      value: this.props.rate,
      onChange: this.handleSpeedChange,
    };
    const controlProps = {
      playing: this.props.playing,
      onBackwardClick: this.handleBackwardClick,
      onPlayClick: this.handlePlayClick,
      onForwardClick: this.handleForwardClick,
    };
    const volumeProps = {
      onChange: this.handleVolumeChange,
      value: this.props.volume * 100,
    };

    const hidden = this.props.playback.url === "";
    const isMarginLeftRequired = this.props.screenType !== STACKED;
    const { classes } = this.props;
    let screenType = "other";
    if (
      this.props.screen.screenType === STACKED &&
      this.props.screen.stackedScreenName.startsWith("Title:")
    ) {
      screenType = "call";
    } else if (
      this.props.screen.screenType === MAIN &&
      this.props.screen.screenName === "Shared Call"
    ) {
      screenType = "sharedCall";
    }

    return (
      <div
        className={`Player ${hidden ? "hidden" : ""} ${
          isMarginLeftRequired ? classes.marginWithPlayer : ""
          }`}
      >
        <PlaybackDetails {...this.props.playback} />
        <PlaybackControl classes={classes} controlProps={controlProps} />
        <PlaybackWaveform
          howlerProps={howlerProps}
          progressProps={progressProps}
          playingTimeProps={playingTimeProps}
        />
        <PlaybackSpeedMenu classes={classes} speedProps={speedProps} />
        <PlaybackVolumeControls volumeProps={volumeProps} volume={volume} />
        {screenType === "other" && <ViewDetails playback={this.props.playback} />}
        {screenType === "call" && this.props.playback.callId && (
          <ShareCallButtonContainer callId={this.props.playback.callId} />
        )}
        {this.showCardsShown &&
          <CardsShown
            onSkipPress={this.handleSeekTime}
            duration={this.props.playback.duration}
            battlecardsShown={this.props.call.details.callDataDocument && this.props.call.details.callDataDocument.battlecardsShown}
            behaviourCardsShown={this.props.call.details.callDataDocument && this.props.call.details.callDataDocument.behaviourCardsShown}
            recordingStartTime={this.props.call.details.callDataDocument && this.props.call.details.callDataDocument.recordingStartTime}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = store => {
  const { playback } = store.player;
  const { screen, call } = store;
  return { playback, screen, call };
};

PlayerController.defaultProps = {
  playback: {
    url: "",
    conversationTurn: [],
    duration: 0,
    seekPosition: 0,
  },
};

PlayerController.propTypes = {
  playback: object,
};

export default connect(
  mapStateToProps,
  { setActiveTime },
)(withStyles(styles)(PlayerController));
