import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withErrorHandling } from "app/components/error/errorHandlingScreen";
import { metrics } from "app/utils/metrics";
import { showUnrecordedInMyCallsPage } from "app/actions/pageData";
import { Grid } from "@material-ui/core";
import { CallTable } from "../components/common";
import DateDividedUpcomingCalls from "./DateDividedUpcomingCalls";
import { getCompletedCalls } from "app/utils/network";
import { setPastCalls, setUpcomingCalls } from "app/actions/pageData";
import { PaginationButtons } from "./CallTablePageRender";
import NewDataTooltip from "../components/common/NewDataTooltip";
import { anyDiffElementsInArray } from "../utils/dataUtils";
import { usePageTimeTracker } from "../utils/hooks/usePageTimeTracker";
import { TextLarge, TextBase, Toggle } from "app/designSystem"
import { AdhocCallJoiningForm } from "app/components/callsDashboard";
const pastCallPageSize = 50;

const CallsDashboard = props => {
  const defaultMyCallTab = useSelector(store => store.screen.defaultMyCallTab);
  const auth = useSelector(store => store.auth);
  const [showNewDataTooltip, setShowNewDataTooltip] = useState(false);
  const [stagedResultsPage, setStagedResultsPage] = useState(1);
  const [stagedResults, setStagedResults] = useState(null);
  const [activeTab, setActiveTab] = useState(defaultMyCallTab === "COMPLETED" ? 0 : 1);
  const [pastCallsLoading, setPastCallsLoading] = useState(false);
  const [showAdhocCallForm, setShowAdhocCallForm] = useState(false)
  usePageTimeTracker(["MyCalls"]);

  useEffect(() => {
    metrics.logEvent("openCallsDashboard");
    fetchData();
    return () => {
      metrics.logEvent("closeCallsDashboard");
      console.log("unmounting myCalls");
      if (!auth.isAdminLogin)
        metrics.logEndOfPage("myCalls");
    }
  }, []);

  async function fetchData() {
    const oldCalls = props.pageData?.pastCalls?.calls ?? [];
    if (oldCalls.length === 0) {
      setPastCallsLoading(true);
    }
    const userId = props.auth.userId;
    const page = props.pageData?.pastCalls?.page ?? 1;
    const pastCalls = await getCompletedCalls(userId, page, props.pageData.showUnrecordedInMyCallsPage);
    trySettingPastCalls(pastCalls, page);
    setPastCallsLoading(false);
  }

  useEffect(() => {
    setActiveTab(defaultMyCallTab === "COMPLETED" ? 0 : 1);
  }, [defaultMyCallTab]);

  async function handleShowUnrecordedChange(checked) {
    props.showUnrecordedInMyCallsPage(checked);
    setPastCallsLoading(true);
    await fetchPage(1, checked);
    setPastCallsLoading(false);
  }

  function handleShowNewData() {
    setPastCallsLoading(true);
    if (stagedResults)
      setPastCalls(stagedResults, stagedResultsPage);
    setTimeout(() => setPastCallsLoading(false), 500);
    setShowNewDataTooltip(false);
  }


  async function fetchPage(page, showUnrecordedInMyCallsPage = null) {
    const userId = props.auth.userId;
    if (showUnrecordedInMyCallsPage === null) showUnrecordedInMyCallsPage = props.pageData.showUnrecordedInMyCallsPage;
    const pastCalls = await getCompletedCalls(userId, page, showUnrecordedInMyCallsPage);
    setPastCalls(pastCalls, page);
  }

  function trySettingPastCalls(pastCalls, page) {
    const oldCalls = props.pageData?.pastCalls?.calls ?? [];
    if (oldCalls.length > 0
      && anyDiffElementsInArray(pastCalls.calls, oldCalls, "callId")) {
      setShowNewDataTooltip(true);
      setTimeout(() => setShowNewDataTooltip(false), 20000);
      setStagedResults(pastCalls);
      setStagedResultsPage(page);
      return;
    }

    setPastCalls(pastCalls, page);
  }

  async function setPastCalls(pastCalls, page = 1) {
    props.setPastCalls({
      hasMore: !(pastCalls && pastCalls.calls && pastCalls.calls.length < pastCallPageSize),
      calls: pastCalls?.calls,
      page,
    });
  }

  async function goToNextPage() {
    const previousPage = props.pageData.pastCalls.page + 1;
    setPastCallsLoading(true);
    await fetchPage(previousPage);
    setPastCallsLoading(false);
    scrollToTop();
  }

  async function goToPreviousPage() {
    const nextPage = props.pageData.pastCalls.page - 1;
    setPastCallsLoading(true);
    await fetchPage(nextPage);
    setPastCallsLoading(false);
    scrollToTop();
  }

  function scrollToTop() {
    try {
      document.getElementsByClassName("CallTable__container")[0].scrollTo(0, 0);
    } catch (error) {
      console.log("error scrolling", error);
    }
  }

  function firstPastCall() {
    const page = props.pageData.pastCalls.page;
    return pastCallPageSize * (page - 1) + 1;
  }

  function lastPastCall() {
    const page = props.pageData?.pastCalls?.page;
    return pastCallPageSize * (page - 1) + props.pageData?.pastCalls?.calls?.length;
  }

  function hasPastCalls() {
    return props.pageData && props.pageData?.pastCalls && props.pageData?.pastCalls?.calls?.length > 0;
  }

  const tableTopbar = () => {
    return (<div className="flex flex-shrink-0 py-4 px-5 items-center  border-coolGray-200 border-b justify-between">
      <TextLarge>  Completed calls</TextLarge>
      {showNewDataTooltip && <NewDataTooltip onClick={handleShowNewData} />}
      <div className="flex items-center">
        {hasPastCalls() &&
          <>
            <TextBase textColor="text-coolGray-500">
              Viewing {firstPastCall()} - {lastPastCall()}
            </TextBase>
            <PaginationButtons
              disablePrevious={props.pageData.pastCalls.page <= 1}
              disableNext={props.pageData.pastCalls.calls.length < pastCallPageSize}
              onPrevious={goToPreviousPage}
              onNext={goToNextPage}
              className="ml-4 mr-6"
            />
          </>
        }

        <TextBase textColor="text-coolGray-500" className="mr-2"> Show unrecorded calls</TextBase>
        <Toggle checked={props.pageData.showUnrecordedInMyCallsPage} onChange={handleShowUnrecordedChange} />
      </div>
    </div>
    )
  }
  return (
    <div className="CallsDashboard-Container flex h-full">

      <div className="CallsDashboard__sectionPaper h-full flex-shrink-0 w-2/3 mr-[-1rem] py-4">
        <CallTable
          loading={pastCallsLoading}
          showAccountDetails
          calls={props.pageData.pastCalls.calls}
          showRep={false}
          tableTopbar={tableTopbar()}
          stickyHeader
        />
      </div>


      <DateDividedUpcomingCalls className="w-1/3 ml-8 mr-1 pb-15" joinMyMeeting={() => { setShowAdhocCallForm(true) }} />
      {
        auth?.isRecording && <AdhocCallJoiningForm open={showAdhocCallForm} hide={() => setShowAdhocCallForm(false)} />
      }


    </div>
  );
}

CallsDashboard.propTypes = {};

CallsDashboard.defaultProps = {};

export default connect(
  store => store,
  { showUnrecordedInMyCallsPage, setPastCalls, setUpcomingCalls },
)(withErrorHandling(CallsDashboard));

export const Tabs = ({ onChange, value, children }) => (
  <div className="TabContainer">
    {children.map((child, i) => {
      return (
        <Tab key={i} onClick={() => onChange(i)} active={i === value}>
          {child}
        </Tab>
      );
    })}
  </div>
);

export const Tab = ({ active, onClick, children }) => (
  <div onClick={onClick} className={active ? "activeTab" : "inactiveTab"}>
    {children}
  </div>
);
