import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { TextBase, TextLarge, Button, CloseIcon } from 'app/designSystem'
import EmailEditor from './EmailEditor';
import Select from "react-select";
import { addEmailTemplate } from 'app/utils/network/emailTemplate';
import { clone } from 'lodash';
import { Input } from 'app/designSystem/form';
import ConfirmActionModal from 'app/components/gameTapesLatest/modal/ConfirmActionModal';

const EmailTemplate = ({ open, closeModal, activeTemplate, fetchAllEmailTemplates }) => {
    const sendToOptions = [
        {
            value: "EVERYONE",
            label: "All receipients",
        },
        {
            value: "CUSTOMERS",
            label: "Customers Only",
        }]
    const [templateTitle, setTemplateTitle] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [sendTo, setSendTo] = useState("EVERYONE");
    const [loading, setLoading] = useState(false);
    const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);

    useEffect(() => {
        if (!activeTemplate) return
        setTemplateTitle(activeTemplate?.templateTitle)
        setEmailSubject(activeTemplate?.emailSubject)
        setEmailBody(activeTemplate?.emailBody)
        setSendTo(sendToOptions.find((option) => option.value === activeTemplate?.sendTo))
    }, [activeTemplate])



    const onSave = async () => {
        if (hasUserEditedTemplate()) {
            setLoading(true)
            const template = clone(activeTemplate);
            const updatedTemplate = {
                ...template,
                templateTitle: templateTitle,
                emailBody: emailBody,
                sendTo: sendTo.value,
                emailSubject: emailSubject,
            }
            const response = await addEmailTemplate(updatedTemplate);
            setLoading(false)
            fetchAllEmailTemplates()
            if (response.error)
                console.log("Error")
            else
                console.log("Success")
        }
        closeModal()
    }

    const hasUserEditedTemplate = () => {
        if (templateTitle !== activeTemplate?.templateTitle)
            return true
        if (emailSubject !== activeTemplate?.emailSubject)
            return true
        if (emailBody !== activeTemplate?.emailBody)
            return true
        if (sendTo.value !== activeTemplate?.sendTo)
            return true
        return false

    }

    const handleCloseModal = () => {
        if (hasUserEditedTemplate())
            setShowDiscardConfirmation(true)
        else
            closeModal()
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={handleCloseModal} >
            <div className="flex flex-col bg-white rounded-lg w-1/2">
                <div id="edit-template-header" className="flex justify-between items-center flex-shrink-0 border-gray-200 border-b py-4">
                    <TextLarge className="px-6"> Edit email template </TextLarge>
                    <button className="px-6" onClick={handleCloseModal}><CloseIcon /></button>
                </div>
                <div id="email-template-body" className="flex flex-col">
                    <div id="email-title-button" className="flex flex-row items-center justify-between py-2 ">
                        <Input
                            label="Template Name:"
                            className="text-base w-full px-6"
                            inputClassname="!p-2 text-coolGray-900 "
                            value={templateTitle}
                            onChange={e => setTemplateTitle(e.target.value)} />
                    </div>
                    <div id="send-to" className="flex flex-row items-center border-b py-3">
                        <TextBase textColor="text-coolGray-500" className="pl-6 mr-2">To:</TextBase>
                        <Select
                            className="w-60 text-base ml-2 text-coolGray-900"
                            options={sendToOptions}
                            value={sendTo}
                            placeholder="Send to"
                            onChange={(option) => setSendTo(option)}
                        />
                    </div>
                    <div id="email-subject" className="flex flex-row border-b py-3">
                        <TextBase textColor="text-coolGray-500" className="px-6">Subject: </TextBase>
                        <input className="text-base text-coolGray-900 w-full" value={emailSubject} onChange={e => setEmailSubject(e.target.value)} />
                    </div>
                    <EmailEditor emailBody={emailBody} setEmailBody={setEmailBody} />
                    <div className="bg-coolGray-50 py-2 flex items-center justify-end border-t rounded-lg">
                        <Button variant="primary" className="h-8 w-24 mr-5" loading={loading} onClick={onSave}>Save</Button>
                    </div>
                </div>
                <ConfirmActionModal
                    open={showDiscardConfirmation}
                    onClose={() => setShowDiscardConfirmation(false)}
                    onConfirm={() => { closeModal(); setShowDiscardConfirmation(false) }}
                    title="Are you sure?"
                    message="If you exit, your changes will be permanently discarded."
                    confirmText="Discard"
                    cancelText="Cancel"
                />
            </div>
        </Modal >
    );
}

export default EmailTemplate;