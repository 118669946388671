import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Loading } from "app/components/common";
import { Route, Switch, Redirect } from "react-router-dom";
import { SignIn, SignUp, SignUpWithProvider, AdminLogin, ResetPassword, LoginCheck, LoginMobile } from "app/components/auth";
import { WingmanLogoWithName, TextXL, WingmanLogoWhite, WhitePlusIcon, ClariLogoWhite, TextBase, TextLarge, Text3XL } from "app/designSystem";
import AuthPageBackground from "app/designSystem/illustrations/authPageBackground.svg";
import ShrutiKapoorIcon from "app/styles/assets/images/shrutiKapoorIcon.jpg";
import useSnackbar from 'app/utils/hooks/useSnackbar';
import { loginByLink, sendGetRequest } from "app/utils/network";
import { setUserData } from "app/actions/auth";
import { useDispatch } from "react-redux";
import { openWindowAndListenForParam } from "app/components/onboarding/helpers";

function AuthPage(props) {
  const dispatch = useDispatch();

  const [loginState, setLoginState] = useState("LoginCheck");
  const [email, setEmail] = useState("");
  const [authKey, setAuthKey] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const { setSnackbar } = useSnackbar();

  useEffect(() => {
    const p = new URLSearchParams(window.location.search);
    const email = p.get('email');
    const authKey = p.get('authKey');
    const loginType = p.get('loginType');
    const code = p.get('code');

    if (code) {
      getAccessToken(code);
    }
    else if (email && authKey) {
      console.log("LoginCheck: email and authKey found", email, authKey);
      if (loginType === "MOBILE") {
        setEmail(email);
        setAuthKey(authKey);
        setLoginState("LoginMobile");
      } else {
        loginByMagicLink(email, authKey);
      }
    }
  }, []);

  const loginByMagicLink = async (email, authKey) => {
    setEmail(email);
    await loginInternal(loginByLink({ email, authKey }));
  }

  const loginInternal = async (loginPromise) => {
    setLoading(true);
    const response = await loginPromise;

    if (response.success) {
      const user = response;
      dispatch(setUserData(user));
    } else {
      setSnackbar(response.data.message, "ERROR");
    }

    setLoading(false);
  }

  const ssoLoginHandler = async (email) => {
    const domain = email.split("@")[1];
    setLoading(true);
    const result = await sendGetRequest(`/auth/workos/login-url?domain=${domain}`);
    setLoading(false);
    if (result?.data?.error) {
      setSnackbar(result?.data?.message, "ERROR");
    }
    else {
      const redirectUrl = result.message;
      setLoading(false);
      openWindowAndListenForParam(redirectUrl, "Single Sign-on", "code", async (code) => {
        getAccessToken(code, email);
      });
    }
  }

  const getAccessToken = async (code, email) => {
    setLoading(true);
    const accessTokenUrl = `/auth/workos/access-token?code=${code}` + (email ? `&email=${email}` : "");
    const result = await sendGetRequest(accessTokenUrl);
    setLoading(false);
    if (result?.data?.error) {
      setSnackbar("Invalid Email Address", "ERROR");
      return;
    }
    dispatch(setUserData(result));
    setLoading(false);
  }

  const PreLoginPages = {
    "LoginMobile": {
      "component": <LoginMobile email={email} authKey={authKey} />,
      "id": "LoginMobile"
    },
    "LoginCheck": {
      "component": <LoginCheck loading={loading} setLoading={setLoading} email={email} setEmail={setEmail} ssoLoginHandler={ssoLoginHandler} setData={setData} setLoginState={setLoginState} />,
      "id": "LoginCheck"
    },
    "SignIn": {
      "component": <SignIn email={email} setLoginState={setLoginState} />,
      "id": 'SignIn',
    },
    "SignUp": {
      "component": <SignUp email={email} setLoginState={setLoginState} />,
      "id": "SignUp"
    },
    "SignUpWithProvider": {
      "component": <SignUpWithProvider email={email} data={data} ssoLoginHandler={ssoLoginHandler} />,
      "id": "SignUpWithProvider"
    },
  }

  return (
    <div className="flex w-screen h-screen">
      <Loading />
      <div className="flex flex-col h-full w-1/2 overflow-hidden items-center justify-center">
        <img src={AuthPageBackground} className="w-full h-full object-cover" />
        <div className="absolute z-10 w-2/5">
          <div className="flex flex-row items-center mb-2">
            <ClariLogoWhite />
            <WhitePlusIcon />
            <WingmanLogoWhite />
          </div>
          <Text3XL textColor="text-white" className="font-bold my-4">Wingman is now a Clari product 🎉</Text3XL>
          <TextXL textColor="text-white" className=""><span className="text-3xl">“</span>We merged with Clari to make revenue collaboration and governance a reality for you. So what changes for you as Wingman customers? Nothing but you can expect a better product, faster feature updates, and more possibilities for you.<span className="text-3xl">”</span></TextXL>
          <div className="flex flex-row items-center mt-4 mb-8">
            <img src={ShrutiKapoorIcon} className="w-10 h-10 rounded-full border border-white" />
            <div className="flex flex-col ml-2">
              <TextBase textColor="text-white" className="!font-semibold">Shruti Kapoor</TextBase>
              <TextBase textColor="text-white">CEO - Wingman</TextBase>
            </div>
          </div>
          <a id="LoginPage_ButtonLinkToBlog" href="https://www.trywingman.com/blog-posts/letter-from-founders-wingman-blog" target="_blank" rel="noopener noreferrer">
            <TextLarge textColor="text-white" className="!font-semibold"> Read the full letter from our CEO &gt; </TextLarge>
          </a>
        </div>
      </div>
      <div className="flex flex-col h-full w-1/2 justify-center items-center">
        <WingmanLogoWithName className="w-44 mb-4" />
        <div>
          <Switch>
            <Route exact path={`${props.match.url}login`}
              render={
                () => PreLoginPages[loginState].component
              }
            />
            <Route exact path={`${props.match.url}resetPassword`} component={ResetPassword} />
            <Route
              exact
              path={`${props.match.url}15cf7b40-f011-43ac-be0a-83639dd67e21`}
              component={AdminLogin}
            />
            <Route render={() => <Redirect to="/login" />} />
          </Switch>
        </div>
      </div>
    </div >
  );
}


const mapStateToProps = store => {
  return { ...store };
};

AuthPage.propTypes = {};

AuthPage.defaultProps = {};

export default connect(
  mapStateToProps,
  {},
)(AuthPage);
