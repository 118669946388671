
import React, { Component, useEffect, useState } from "react";
import { TextBase } from 'app/designSystem'
import ProfileImage from "../common/ProfileImage";
import moment from "moment";
import useDataHelper from "../../utils/hooks/useDataHelper";



const LastUpdated = (props) => {

    const { getUserName } = useDataHelper();

    return (
        <div className="flex flex-row items-center " >
            <div className="flex justify-center items-center  mr-3 " >
                <ProfileImage userId={props.userId} disableTooltip={true} size={35} />
            </div>
            <div className="flex flex-col items-left">
                <TextBase textColor="text-coolGray-900">{getUserName(props.userId)}</TextBase>
                <TextBase textColor="text-coolGray-500">{moment(props.time).format("MMMM DD, YYYY")}</TextBase>
            </div>
        </div >
    )
}
export default LastUpdated;
