import { TextBase, TextXL, ScoringTemplatesEmptyIllustration, Button, LinkToPageIcon } from "app/designSystem";

import React from 'react'
import classNames from "classnames";

// Set height and width via className prop in consuming component
export default function ScorecardEmptyState({ className, onCtaClick }) {
    return (
        <div className={classNames(className, "flex flex-col justify-center items-center")}>

            <div className="flex flex-col justify-center items-center px-4">


                <ScoringTemplatesEmptyIllustration className="mb-2" />
                <TextXL>Setup scorecards for your team</TextXL>
                <TextBase textColor="text-coolGray-500" className="mt-1 mb-4 text-center ">Choose from our preset templates or create your own </TextBase>
                <Button className="!px-14" onClick={onCtaClick}>
                    Get started
                </Button>

                <div className="border-b bg-coolGray-200 w-full mt-20  shadow-md"></div>
                <div className="mt-2 px-2">
                    <TextBase textColor="text-coolGray-500" className="text-center">Follow our guide on how to leverage scorecards for better call coaching</TextBase>
                </div>
                <a className="flex items-center  mt-1" href="https://support.trywingman.com/en/articles/6021542-what-are-call-scorecards-how-do-i-use-them" target="_blank" rel="noreferrer">
                    <TextBase>Learn more</TextBase>
                    <LinkToPageIcon className="text-coolGray-900 ml-2" />
                </a>
            </div>

        </div>
    )
}
