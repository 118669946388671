import React, { useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { Button } from "app/designSystem";
import { _formatSecondsToPlayingTime } from "app/utils/helpers";
import play from "app/styles/assets/images/icons/player/play.svg";
import pause from "app/styles/assets/images/icons/player/pause.svg";
import AudioRecorder from "app/utils/AudioRecorder";
import useSnackbar from "app/utils/hooks/useSnackbar";

export const Recording = props => {

  const { setSnackbar } = useSnackbar();
  const pollTimer = useRef(null);
  const audioRecorder = useRef(null);
  const [state, setStateNative] = useState({
    recording: null,
    duration: 0,
    width: 0,
    marginLeft: -270,
    playing: null,
    loading: false
  });

  function setState(stateParam) {
    setStateNative(state => {
      return {
        ...state,
        ...stateParam
      }
    })
  }

  function onRecordStart() {
    let { duration } = state;
    let marginLeft = -270;
    let width = 0;
    pollTimer.current = setInterval(() => {
      duration += 0.1;
      width = duration * 18;
      if (duration >= 15) {
        marginLeft = -270 + (duration - 15) * 18;
      }
      if (duration > 30) {
        marginLeft = 0;
        width = 540;
      }
      setState({
        duration,
        marginLeft,
        width
      });
    }, 100);
  }

  async function stopRecording() {
    const audio = await audioRecorder.current.stop();
    if (pollTimer.current != null)
      clearInterval(pollTimer.current);
    setState({
      audio,
      recording: false
    });
  }

  function startPlaying() {
    const { audio } = state;
    audio.play();
    setState({
      playing: true
    });
  }

  function stopPlaying() {
    const { audio } = state;
    audio.pause();
    setState({
      playing: false
    });
  }

  async function startRecording() {
    try {
      audioRecorder.current = await AudioRecorder();
      audioRecorder.current.start();
      onRecordStart();
      setState({
        recording: true
      });
    } catch (error) {
      console.log("error in vp start", error);
      setSnackbar("Error starting voice recorder. Please check if you have given Microphone access.", "ERROR", 5000);

    }
    console.log("ending start recording");
  }

  function retry() {
    setState({
      recording: null,
      playing: null,
      duration: 0,
      width: 0,
      marginLeft: -270,
      audio: null
    });
  }

  async function submit() {
    let res = { error: true };
    await setState({
      loading: true
    });
    if (state.duration < 10) {
      alert(
        "We need atleast 10 seconds of recording to identify you in calls. Please record your fingerprint again."
      );
    } else {
      res = await props.onSubmit(state.audio.dataUrl);
    }
    await setState({
      loading: false
    });
    return res;
  }


  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      direction="column"
      justify="center"
    >
      <Grid style={{ position: "relative" }} item>
        <div
          className="voice__spectrum"
          style={{
            marginLeft: state.marginLeft,
            width: state.width
          }}
        />
        <div
          className="voice__line"
          style={{ marginLeft: state.marginLeft }}
        />
        <audio id="audio">
          <source id="audioSource" src="" type="audio/wav" />
        </audio>
      </Grid>
      <Grid item>
        <span className="voice__duration">
          {_formatSecondsToPlayingTime(state.duration)}
        </span>
      </Grid>
      <Grid item>
        <div className="voice">
          {state.recording === null && (
            <Button variant="secondary" className="w-32 h-10" onClick={startRecording}>Start</Button>
          )}
          {state.recording === true && (
            <Button variant="secondary" className="w-32 h-10" onClick={stopRecording}>Stop</Button>
          )}
          {state.recording === false && !state.playing && (
            <img
              onClick={startPlaying}
              alt="play"
              className="voice__play"
              src={play}
            />
          )}
          {state.playing === true && (
            <img
              onClick={stopPlaying}
              alt="pause"
              className="voice__play"
              src={pause}
            />
          )}
        </div>
      </Grid>
      {state.recording === false && (
        <div className="flex flex-row">
          <Button variant="secondary" className="w-32 h-10" onClick={retry}>
            {"Retry"}
          </Button>
          <Button
            className="w-32 h-10 ml-4"
            loading={state.loading}
            onClick={submit}
          >
            {"Upload"}
          </Button>
        </div>
      )}
    </Grid >
  );
}