import React from "react";
import { IconButton } from "@material-ui/core";
import { CustomLink } from "app/components/common";
import {
  _formatDateToCallCardDateFormat,
  _formatSecondsToPlayingTime,
  _formatTalkListenRatio,
} from "app/utils/helpers";
import DeleteIcon from "@material-ui/icons/CancelOutlined";
import { emptyFilterParams } from "app/utils/filter";
import { isEqual, has, clone, findIndex } from "lodash";
import { getContactsById, getUsersById, getDealStageForFilters } from "../../utils/network";
import { useEffect, useState } from "react";

function isFilterEmpty(filterParams, emptyFilterParams) {
  if (isEqual(filterParams, emptyFilterParams)) {
    return true;
  }
  return false;
}

const CurrentActiveFilters = (props) => {
  const [contactNames, setContactNames] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [managerNames, setManagerNames] = useState([]);
  const [dealStageNamesLive, setDealStageNamesLive] = useState([]);
  const [dealStageNamesFrozen, setDealStageNamesFrozen] = useState([]);

  useEffect(() => {
    fillNames();
  }, [props.filterParams]);

  async function getDealStageNames(dealStages, stageType) {
    if (!dealStages || dealStages.length === 0) return [];
    const dealStageMap = await getDealStageForFilters(stageType);
    const finalDealStages = dealStages.map(a => ({ id: a, value: a })).map(deal => {
      const index = findIndex(dealStageMap, o => { return o.value === deal.id; });
      if (index !== -1) return { id: dealStageMap[index].value, value: dealStageMap[index].label };
      return deal;
    });
    return finalDealStages;
  }

  async function getContactNames(contacts) {
    if (!contacts || contacts.length === 0) return [];
    const contactMap = await getContactsById(contacts);
    return contactMap.map(contact => ({ id: contact.value, value: contact.label }));
  }

  async function getUserNames(users) {
    if (!users || users.length === 0) return [];
    const userMap = await getUsersById(users);
    return userMap.map(user => ({ id: user.value, value: user.label }));
  }

  async function getManagerNames(users) {
    if (!users || users.length === 0) return [];
    const userMap = await getUsersById(users);
    return userMap.map(user => ({ id: user.value, value: `${user.label}'s Team` }));
  }

  async function fillNames() {
    const userNames = await getUserNames(props.filterParams.agentNames);
    const managerNames = await getManagerNames(props.filterParams.managers);
    const contactNames = await getContactNames(props.filterParams.contacts);
    const dealStageNamesLive = await getDealStageNames(props.filterParams.dealStageLive, "live");
    const dealStageNamesFrozen = await getDealStageNames(props.filterParams.dealStageFrozen, "frozen");
    setUserNames(userNames);
    setContactNames(contactNames);
    setManagerNames(managerNames);
    setDealStageNamesLive(dealStageNamesLive);
    setDealStageNamesFrozen(dealStageNamesFrozen);
  }

  const { filterParams, resetFilter } = props;
  if (Object.keys(removeDefaults(filterParams, emptyFilterParams)).length === 0) {
    return null;
  }

  return (
    <div className="CallTable__activeFilterContainer">
      {filterParams.agentNames.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={userNames}
          fieldName="agentNames"
          label="Reps"
        />
      )}
      {filterParams.managers.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={managerNames}
          fieldName="managers"
          label="Team"
        />
      )}
      {filterParams.contacts.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={contactNames}
          fieldName="contacts"
          label="Contacts"
        />
      )}
      {filterParams.containsCategories.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={filterParams.containsCategories.map(a => ({ id: a, value: a }))}
          fieldName="containsCategories"
          label="Has Categories"
        />
      )}
      {filterParams.competitorMentioned.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={filterParams.competitorMentioned.map(a => ({ id: a, value: a }))}
          fieldName="competitorMentioned"
          label="Has Competitor"
        />
      )}
      {false && filterParams.peopleTagged.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={filterParams.peopleTagged.map(a => ({ id: a, value: a }))}
          fieldName="peopleTagged"
          label="People Tagged"
        />
      )}
      {filterParams.query !== "" && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={[{ id: filterParams.query, value: filterParams.query }]}
          fieldName="query"
          label="Transcript contains"
        />
      )}
      {filterParams.commentSearchTerm !== "" && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={[{ id: filterParams.commentSearchTerm, value: filterParams.commentSearchTerm }]}
          fieldName="commentSearchTerm"
          label="Comments contain"
        />
      )}
      {false && filterParams.labels.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={filterParams.peopleTagged.map(a => ({ id: a, value: a }))}
          fieldName="labels"
          label="Labels"
        />
      )}
      {filterParams.notContainCategories.length > 0 && (
        <TileWithBadge
          removeFilterValue={props.removeFilterValue}
          array={filterParams.notContainCategories.map(a => ({ id: a, value: a }))}
          fieldName="notContainCategories"
          label="Not Categories"
        />
      )}
      {filterParams.endDuration > 0
        && (filterParams.endDuration < 120 || filterParams.startDuration > 0) && (
          <TileWithBadge
            fieldName="duration"
            array={[
              {
                id: `${_formatSecondsToPlayingTime(
                  filterParams.startDuration,
                )} - ${_formatSecondsToPlayingTime(filterParams.endDuration)}`,
                value: `${_formatSecondsToPlayingTime(
                  filterParams.startDuration,
                )} - ${_formatSecondsToPlayingTime(filterParams.endDuration)}`,
              },
            ]}
            label="Duration"
            removeFilterValue={props.removeFilterValue}
          />
        )}
      {filterParams.endTalkListenRatio > 0
        && filterParams.endTalkListenRatio < 100
        && filterParams.startTalkListenRatio > 0 && (
          <TileWithBadge
            fieldName="talkListenRatio"
            array={[
              {
                id: `${_formatTalkListenRatio(
                  filterParams.startTalkListenRatio,
                )} - ${_formatTalkListenRatio(filterParams.endTalkListenRatio)}`,
                value: `${_formatTalkListenRatio(
                  filterParams.startTalkListenRatio,
                )} - ${_formatTalkListenRatio(filterParams.endTalkListenRatio)}`,
              },
            ]}
            label="Talk/Listen Ratio"
            removeFilterValue={props.removeFilterValue}
          />
        )}
      {filterParams.startDate !== null && filterParams.endDate !== null && (
        <TileWithBadge
          fieldName="date"
          array={[
            {
              id: `${_formatDateToCallCardDateFormat(
                filterParams.startDate,
              )} - ${_formatDateToCallCardDateFormat(filterParams.endDate)}`,
              value: `${_formatDateToCallCardDateFormat(
                filterParams.startDate,
              )} - ${_formatDateToCallCardDateFormat(filterParams.endDate)}`,
            },
          ]}
          label="Date"
          removeFilterValue={props.removeFilterValue}
        />
      )}
      {filterParams.types && filterParams.types.length > 0 && (
        <TileWithBadge
          fieldName="types"
          array={filterParams.types.map(a => ({ id: a, value: a.replace("_", " ") }))}
          label="Call Type"
          removeFilterValue={props.removeFilterValue}
        />
      )}
      {filterParams.status && filterParams.status.length > 0 && (
        <TileWithBadge
          fieldName="status"
          array={filterParams.status.map(a => ({ id: a, value: a.replace("_", " ") }))}
          label="Call Status"
          removeFilterValue={props.removeFilterValue}
        />
      )}
      {filterParams.dealStageFrozen && filterParams.dealStageFrozen.length > 0 && (
        <TileWithBadge
          fieldName="dealStageFrozen"
          array={dealStageNamesFrozen}
          label="Deal Stage at call time"
          removeFilterValue={props.removeFilterValue}
        />
      )}
      {filterParams.dealStageLive && filterParams.dealStageLive.length > 0 && (
        <TileWithBadge
          fieldName="dealStageLive"
          array={dealStageNamesLive}
          label="Current Deal Stage"
          removeFilterValue={props.removeFilterValue}
        />
      )}
      {filterParams.crmFields
        && filterParams.crmFields.length > 0
        && filterParams.crmFields.map((crmField, i) => {
          if (!crmField.field) return null;
          return (
            <TileWithBadge
              key={i}
              fieldName={crmField.field.value}
              array={getDisplayValueByFieldType(crmField.value, crmField.field.fieldType)}
              label={crmField.field.label}
              removeFilterValue={props.removeFilterValue}
            />
          );
        })}
      <CustomLink onClick={resetFilter}>Clear All</CustomLink>
    </div>
  );
}

function getDisplayValueByFieldType(value, fieldType) {
  console.log(value, fieldType);
  switch (fieldType) {
    case "NUMERIC":
    case "INTEGER":
      if (value == null) return [];
      return [{ id: `${value[0]} - ${value[1]}`, value: `${value[0]} - ${value[1]}` }];
    case "DATE":
      return [
        {
          id: `${_formatDateToCallCardDateFormat(value[0])} - ${_formatDateToCallCardDateFormat(
            value[1],
          )}`,
          value: `${_formatDateToCallCardDateFormat(value[0])} - ${_formatDateToCallCardDateFormat(
            value[1],
          )}`,
        },
      ];
    case "STRING":
      return [{ id: value, value }];
    case "LIST":
      return value.map(a => ({ id: a, value: a }));
  }
}

// TODO: generalize this with the one in CallTablePage
const fillWithDefaults = (paramsWithoutDefaults, emptyFilterParams) => {
  const paramsWithDefaults = clone(paramsWithoutDefaults, true);
  Object.keys(emptyFilterParams).map(key => {
    if (!has(paramsWithoutDefaults, key)) {
      paramsWithDefaults[key] = emptyFilterParams[key];
    }
  });
  return paramsWithDefaults;
};

const removeDefaults = (paramsWithDefaults, emptyFilterParams) => {
  const paramsWithoutDefaults = clone(paramsWithDefaults, true);
  Object.keys(emptyFilterParams).map(key => {
    if (isEqual(emptyFilterParams[key], paramsWithoutDefaults[key])) {
      delete paramsWithoutDefaults[key];
    }
  });
  return paramsWithoutDefaults;
};

const TileWithBadge = props => {
  return (
    <div className="CallTable__activeFilter">
      {props.array.map((item, i) => {
        return (
          <div key={i} className="CallTable__activeFilterTile">
            <span className="CallTable__activeFilterField">{`${props.label}: `}</span>
            <span className="CallTable__activeFilterTile_Value">{item.value}</span>
            <IconButton
              className="CallTable__activeFilterTile_Icon"
              onClick={() => {
                props.removeFilterValue(item.id, props.fieldName);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        );
      })}
    </div>
  );
};

export default CurrentActiveFilters;
