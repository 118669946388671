import React, { useEffect, useState, useCallback } from "react";
import { Table, TableHead, TableRow, TableCell } from "@material-ui/core";
import { Button, TextBase, TextLarge, Text2XL, TextSM } from "app/designSystem"
import { AddWord, ThreeDotMenuIcon, NoWords } from "../../designSystem/icons";
import TableHeader from "../table/TableHeader";
import AddWordModal from "./AddWordModal";
import { sendRequest } from "../../utils/network";
import IncorrectWords from "./IncorrectWords";
import LastUpdated from "./LastUpdated";
import OutsideClickHandler from "react-outside-click-handler";
import Skeleton from 'react-loading-skeleton';
import classNames from "classnames";
import { ConfirmationDialog } from "app/components/common";
import { useSelector } from "react-redux";





const TranscriptCorrection = props => {

    const [addNewWordModalIsOpen, setAddNewWordModalIsOpen] = useState(false);
    const [replacementWords, setReplacementWords] = useState(["notEmpty"]);
    const [totalWordMaps, setTotalWordMaps] = useState({});
    const [editAndDeleteIsOpenedFor, setEditAndDeleteIsOpenedFor] = useState("");
    const [editModalIsOpenFor, setEditModalIsOpenFor] = useState("");
    const [allIncorrectWords, setAllIncorrectWords] = useState({});
    const [loading, setLoading] = useState(false);
    const [confirmationDialogIsOpenFor, setConfirmationDialogIsOpenFor] = useState("");
    const auth = useSelector((store) => store.auth);

    useEffect(() => {
        refreshContent();
    }, []);



    const refreshContent = useCallback(async () => {
        setLoading(true)
        setReplacementWords(["notEmpty"])
        const response = await sendRequest("/admin/get_all_the_dictionary_words")
        const correctWords = [];
        const incorrectWords = {};
        const wordsLocal = {}
        {
            response && response.map(wordMap => {
                const replacementWord = wordMap.replacement.toLowerCase()
                if (wordsLocal[replacementWord] == null) {
                    correctWords.push(wordMap.replacement)
                    wordsLocal[replacementWord] = { incorrectWords: [], displayName: "", imageUrl: "", userId: "", time: new Date() }
                }
                incorrectWords[(wordMap.word).toLowerCase()] = { replacementWord: replacementWord }
                wordsLocal[replacementWord].time = wordMap.time;
                wordsLocal[replacementWord].userId = wordMap.userId
                wordsLocal[replacementWord].incorrectWords.push(wordMap.word)
            })
        }
        setAllIncorrectWords(incorrectWords)
        setReplacementWords(correctWords.reverse());
        setTotalWordMaps(wordsLocal)
        setLoading(false)
    }, [])

    async function handleDeleteWord(word) {
        setLoading(true)
        const params = new URLSearchParams();
        params.append("replacementWord", word);
        const response = await sendRequest("/admin/delete_words_from_dictionary", params)
        setEditAndDeleteIsOpenedFor("")
        refreshContent();
    }



    return (
        <div className="overflow-auto mr-3">
            <div className="flex justify-between items-center px-2 w-full mt-8 mb-3">
                <div className="flex flex-col">
                    <Text2XL className="text-coolGray-900 mb-1">Autocorrect</Text2XL>
                    <TextSM textColor="text-coolGray-600" >Add commonly mis-transcribed words and their corrections here.<br />Tip: Start with adding corrections for proper nouns and industry specific terms.</TextSM>
                </div>
                <Button
                    variant="secondary"
                    className={classNames("!px-2 !py-1.5", { "visibility: hidden": replacementWords.length == 0 })}
                    startIcon={<AddWord />}
                    onClick={() => setAddNewWordModalIsOpen(true)}
                    disabled={auth.isAdmin ? false : true}
                    id="addWord"

                >
                    Add new word
                </Button>
            </div>
            <AddWordModal
                open={addNewWordModalIsOpen}
                close={() => {
                    setAddNewWordModalIsOpen(false)
                }}
                correctWords={replacementWords}
                allIncorrectWords={allIncorrectWords}
                refreshContent={refreshContent}
            ></AddWordModal>
            {replacementWords.length > 0 &&
                <div className="rounded-2xl  bg-white  border border-coolGray-200 overflow-hidden  mb-6 pb-11  w-full relative ">

                    <Table className="border-collapse pr-3 pb-15  " id="table" >
                        <TableHead className="bg-coolGray-50 flex items-center">
                            <TableRow >
                                <TableCell className="!pl-8 !py-4" >
                                    <TableHeader
                                        label="Incorrect words"
                                        disableSort
                                    >
                                    </TableHeader>
                                </TableCell>
                                <TableCell className="!pl-0 !py-4" >
                                    <TableHeader
                                        label="Correct word" disableSort>
                                    </TableHeader>
                                </TableCell>
                                <TableCell className="!pl-15 !py-4" >
                                    <TableHeader
                                        label="Last updated by" disableSort>
                                    </TableHeader>
                                </TableCell>
                                <TableCell className="!pl-0 !py-4" >
                                    <TableHeader
                                        label="" disableSort>
                                    </TableHeader>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <tbody>
                            {!loading && replacementWords && replacementWords.map((word, i) => {
                                return (
                                    <>
                                        <tr key={word + "-" + i} className={classNames({ "border border-solid border-coolGray-300": i != replacementWords.length - 1 })}>
                                            <td className="py-6 ">
                                                {totalWordMaps[word.toLowerCase()] &&
                                                    <IncorrectWords incorrectWords={totalWordMaps[word.toLowerCase()].incorrectWords} />
                                                }
                                            </td>
                                            <td className="py-6 pl-3">
                                                <TextLarge textColor="text-brand-blue-3">{word}</TextLarge>
                                            </td>
                                            <td className="py-6 pl-15">
                                                {totalWordMaps[word.toLowerCase()] &&
                                                    <LastUpdated userId={totalWordMaps[word.toLowerCase()].userId} time={totalWordMaps[word.toLowerCase()].time} />
                                                }
                                            </td>
                                            <td className="py-6 pr-10">
                                                {totalWordMaps[word.toLowerCase()] &&
                                                    <div className=" flex flex-col  ">
                                                        <ThreeDotMenuIcon
                                                            className={classNames({ "pointer": auth.isAdmin }, { "pointer-events-none": !auth.isAdmin })}
                                                            onClick={() => editAndDeleteIsOpenedFor == "" ? setEditAndDeleteIsOpenedFor(word) : setEditAndDeleteIsOpenedFor("")}
                                                        />

                                                        {editAndDeleteIsOpenedFor == word &&
                                                            <OutsideClickHandler onOutsideClick={() => setEditAndDeleteIsOpenedFor("")}>
                                                                <div className="z-10 flex flex-col  text-left justify-around bg-white w-[5rem] rounded-md py-2 absolute" style={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(0, 0, 0, 0.33)" }}>
                                                                    <TextBase id="editWordsForDictionary" textColor="text-coolGray-500" className=" pl-5 py-1 cursor-pointer text-left hover:bg-coolGray-100" onClick={() => setEditModalIsOpenFor(word)}>Edit</TextBase>
                                                                    <TextBase id="deleteWordsFromDictionary" textColor="text-coolGray-500" className=" pl-5 py-1 cursor-pointer text-left hover:bg-coolGray-100" onClick={() => setConfirmationDialogIsOpenFor(word)}>Delete</TextBase>
                                                                </div>

                                                            </OutsideClickHandler>
                                                        }
                                                        <OutsideClickHandler onOutsideClick={() => setConfirmationDialogIsOpenFor("")} >
                                                            <ConfirmationDialog
                                                                title="Delete Word"
                                                                message={"Are you sure you want to delete the entire incorrect words for " + confirmationDialogIsOpenFor}
                                                                open={confirmationDialogIsOpenFor == word}
                                                                onOk={() => handleDeleteWord(word.toLowerCase())}
                                                                onClose={() => setConfirmationDialogIsOpenFor("")}
                                                            />
                                                        </OutsideClickHandler>

                                                        <AddWordModal
                                                            open={editModalIsOpenFor == word}
                                                            close={() => {
                                                                setEditModalIsOpenFor("")
                                                            }}
                                                            refreshContent={refreshContent}
                                                            editWords={true}
                                                            incorrectWords={totalWordMaps[word.toLowerCase()].incorrectWords}
                                                            replacementWord={word}
                                                            correctWords={replacementWords}
                                                            allIncorrectWords={allIncorrectWords}
                                                        />
                                                    </div>
                                                }
                                            </td>

                                        </tr>

                                    </>
                                )
                            })}

                            {loading &&

                                < LoadingDictionry />

                            }



                        </tbody>

                    </Table>

                </div>}

            {replacementWords.length === 0 &&
                <div className="w-full h-full flex flex-col items-center justify-around ">
                    <NoWords className="mb-8 ml-8" />
                    <TextLarge textColor="text-coolGray-900" className="mb-3">No corrections added yet.</TextLarge>
                    <TextBase textColor="text-coolGray-500 mb-4">Add commonly mistranscribed words and their corrections here. Wingman will use these to autocorrect transcription for future calls.</TextBase>
                    <Button
                        variant="secondary"
                        className="!px-2 !py-1.5 "
                        startIcon={<AddWord />}
                        onClick={() => setAddNewWordModalIsOpen(true)}
                    >
                        Add new word
                    </Button>
                </div>

            }


        </div>

    );
}

const LoadingDictionry = () => {
    return (
        <>
            {[...Array(6).keys()].map(i => (

                <tr key={"skeletalLoading" + i}>
                    <td className="!pl-8 !py-4"><Skeleton width={200} height={40} /></td>
                    <td className=" !py-4" ><Skeleton width={320} height={40} /></td>
                    <td className="!py-4">
                        <div className="flex pl-10">
                            <Skeleton circle width={40} height={40} className="mr-1" />
                            <Skeleton width={200} height={40} ></Skeleton>
                        </div>
                    </td>
                    <td className="px-5 w-[190px]"></td>

                </tr>


            ))}
        </>
    )
}


export default TranscriptCorrection;
