import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";
import { CustomDropdown } from "app/components/common";
import { logout, setUserData } from "app/actions/auth";
import { setOnboardingSkipped } from "app/actions/persisted";
import settingsIcon from "app/styles/assets/images/icons/settings.svg";
import logoutIcon from "app/styles/assets/images/icons/logout.svg";
import { TextBase } from "app/designSystem";
import { ProfileSettingsIcon, WorkspaceSettingsIcon, LogOutIcon, ArrowDownIcon } from "app/designSystem/icons";
import classNames from "classnames";



const ProfileDropDown = props => {
  const isAdmin = props.auth.isAdmin;
  const displayName = props.auth.data.user.displayName;
  const profilePic = props.auth.data.user.imageUrlCached || props.auth.data.user.imageUrl;
  const lastUpdatedTime = props.auth.lastUpdatedTime;
  const showSingleSignOn = props.auth.data.user?.stringsCustomer?.featuresGated?.ssoEnabled || props.auth.data.user?.stringsCustomer?.featuresGated?.ssoDirectoryEnabled;
  const name = displayName;
  let trigger = <div className="flex">   <img className="profile-png" src={profilePic + "?" + lastUpdatedTime} /><ArrowDownIcon className="mt-2 mr-4" /> </div>;
  if (!profilePic) {
    trigger = <span className="ProfileDropdown__profileIcon">{(name && name[0]) ? name[0] : "P"}</span>;
  }

  const menuButtons = [
    <ProfileDropDownMenuItem
      to="/settings#profile"
      onClick={() => { }}
      label="Profile settings"
      icon={<ProfileSettingsIcon />}
    />,
    (props.auth.isAdmin || props.auth.isManager) ?
      < ProfileDropDownMenuItem
        to={showSingleSignOn ? "/settings#sso" : "/settings#adminIntegrations"}
        onClick={() => { }}
        label="Workspace settings"
        icon={<WorkspaceSettingsIcon />}
      /> : [],
    < ProfileDropDownMenuItem to="/" icon={<LogOutIcon />} onClick={props.logout} label="Logout" />,
  ].flat();

  return (
    <CustomDropdown className={classNames(props.className)} bubbleClass="flex  flex-col items-center justify-between  p-2.5 border border-solid border-coolGray-200 bg-white  rounded-lg" right={5} top={45} title={trigger} list={menuButtons} />
  );
};

export const ProfileDropDownMenuItem = props => {
  return (
    <Link to={props.to}>
      <div className={classNames("flex items-center w-[12rem] px-2 text-gray-400 pt-1.5 pb-1.5 hover:bg-gray-100 hover:!text-gray-900", props?.className)} onClick={props.onClick}>
        {props.icon ? React.cloneElement(props.icon, { className: "mr-2" }) : null}
        <TextBase textColor={"text-coolGray-500"}>{props.label}</TextBase>
      </div>
    </Link>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

ProfileDropDown.propTypes = {
  logout: PropTypes.func.isRequired,
  icon: PropTypes.element,
  label: PropTypes.string,
};

ProfileDropDown.defaultProps = {};

export default connect(
  mapStateToProps,
  { logout, setOnboardingSkipped, setUserData },
)(ProfileDropDown);
