import React, { useState } from "react";
import { _roundUp } from "app/utils/helpers";
import { InputBase } from "@material-ui/core";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useRef } from "react";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";

const NumberRangeFilters = ({
  start,
  end,
  onStartChange,
  onEndChange,
  fieldName,
  removeFilterValue,
  disableRemove,
}) => {

  const [showRemove, setShowRemove] = useState(false);

  const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);


  function onRemove(e) {
    e.preventDefault();
    e.stopPropagation();
    removeFilterValue(fieldName);
  }

  return (
    <div ref={ref} style={{ padding: "0px" }} className="FilterField__valueContainer">
      {!disableRemove && showRemove && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
      <div className="Form__inputContainer NumberRange__input">
        <InputBase
          className="!text-base"
          type="number"
          onChange={e => onStartChange(e.target.value)}
          value={start}
        />
      </div>
      <span className="NumberRange__toIcon">-</span>
      <div className="Form__inputContainer NumberRange__input">
        <InputBase
          className="!text-base"
          type="number"
          onChange={e => onEndChange(e.target.value)}
          value={end}
        />
      </div>
    </div>
  );
};

NumberRangeFilters.defaultProps = {
  suffix: "",
};

export default NumberRangeFilters;
