import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "app/actions/auth";
import { submitLeadsquaredInfo } from "app/utils/network";
import { Grid, TextField } from "@material-ui/core";
import { Loading, Card } from "app/components/common";
import { Button } from "app/components/form";
import { setLoading } from "app/actions/screen";
import leadsquaredlogo from "app/styles/assets/images/onboarding/leadsquaredLogo.png";

class LeadsquaredForm extends Component {
  constructor(props) {
    super(props);
    this.setAccessKey = this.setAccessKey.bind(this);
    this.setSecretKey = this.setSecretKey.bind(this);
    this.submitLeadsquaredInfo = this.submitLeadsquaredInfo.bind(this);
    this.submitOnEnter = this.submitOnEnter.bind(this);
    this.state = {
      accessKey: "",
      secretKey: "",
      error: "",
      accessKeyError: false,
      secretKeyError: false,
    };
    console.log("access is",this.props.match.params.access);
  }
  componentDidMount() {
    const leadsquaredForm = document.getElementById("leadsquaredForm");
    leadsquaredForm.addEventListener("keyup", this.submitOnEnter);
  }

  componentWillUnmount() {
    const leadsquaredForm = document.getElementById("leadsquaredForm");
    leadsquaredForm.removeEventListener("keyup", this.submitOnEnter);
  }

  setAccessKey(event) {
    this.setState({ accessKey: event.target.value, error: "", accessKeyError: false });
  }

  setSecretKey(event) {
    this.setState({ secretKey: event.target.value, error: "", secretKeyError: false });
  }

  submitOnEnter(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.submitLeadsquaredInfo();
    }
  }

  async submitLeadsquaredInfo() {
    this.props.setLoading(true);
    if (this.setState.accessKey == "") {
      this.setState({ error: "Enter access key", accessKeyError: true });
    } else if (this.setState.secretKey == "") {
      this.setState({ error: "Enter secret key", secretKeyError: true });
    }
    if (this.state.error !== "") return;
    const userId = this.props.match.params.userId || "";
    const access = this.props.match.params.access || "user";
    const response = await submitLeadsquaredInfo(
      userId,
      this.state.accessKey,
      this.state.secretKey,
      access
    );
    if (response.error) {
      this.setState({ error: response.message });
    } else {
      this.props.setLoading(false);
      window.close();
    }
    this.props.setLoading(false);
  }

  render() {
    return (
      <div className="Leadsquared">
        <Loading />
        <div className="Leadsquared__content">
          <Card className="Leadsquared__content">
            <Grid>
              <div className="row-topcentered">
                <img className="header__logo" src={leadsquaredlogo} alt="Logo" />
              </div>
            </Grid>
            <Grid>
              <div id="leadsquaredForm">
                <table>
                  <tbody>
                    <tr className="row-centered">
                      <td>
                        <TextField
                          id="accessKey"
                          label="Access key"
                          error={this.state.accessKeyError}
                          required
                          value={this.state.accessKey}
                          className="Leadsquared__textField"
                          onChange={this.setAccessKey}
                          margin="normal"
                          type="accessKey"
                        />
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td>
                        <TextField
                          id="secretKey"
                          label="Secret key"
                          error={this.state.secretKeyError}
                          required
                          value={this.state.secretKey}
                          className="Leadsquared__textField"
                          onChange={this.setSecretKey}
                          margin="normal"
                          type="secretKey"
                        />
                      </td>
                    </tr>{" "}
                    <tr className="row-centered">
                      <td>
                        <Button theme="dark" 
                          id="syncLeadsquared"
                          onClick={this.submitLeadsquaredInfo}
                        >
                          Sync Leadsquared
                        </Button>
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td className="Leadsquared__infoDiv">
                        <span className="Leadsquared__info">
                          You can find your leadsquared API Key at:
                        </span>
                        <span className="Leadsquared__infoLink">
                          <a
                            target="_blank"
                            href="https://run.leadsquared.com/Settings/UserAccessKey"
                          >
                            https://run.leadsquared.com/Settings/UserAccessKey
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr className="row-centered">
                      <td>
                        {this.state.error && <span className="error">{this.state.error}</span>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

LeadsquaredForm.propTypes = {
  setUserData: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
};

LeadsquaredForm.defaultProps = {};

export default connect(
  mapStateToProps,
  { setUserData, setLoading },
)(LeadsquaredForm);
