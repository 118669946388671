import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { InputBase, InputAdornment, CircularProgress, IconButton } from "@material-ui/core";
import { SearchIcon, TextBase, ClosePlainCrossIcon } from "app/designSystem";
import { searchCalls, sendRequest } from "app/utils/network";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_SEARCH_RESULTS, SET_SEARCH_RESULTS, SET_SEARCH_TERM } from "../../actions/actionTypes";
import { useKeyListener } from "../../utils/hooks/useKeyListener";
import { withRouter } from "react-router-dom";
import { debounce } from "lodash";
import SearchSuggestions from "./SearchSuggestions";
import OutsideClickHandler from "react-outside-click-handler";
import metrics from "../../utils/metrics";
import axios from 'axios'
const CancelToken = axios.CancelToken;


const SearchBar = props => {
  const searchTerm = useSelector(store => store.screen.searchTerm);
  const [searchSuggestions, setSearchSuggestions] = useState(null);
  const [callsSuggestions, setCallsSuggestions] = useState(null);
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const dispatch = useDispatch();
  const waitForTypingToStop = useRef();
  const source = useRef(CancelToken.source())
  const searchInpRef = useRef()
  const [searchSuggestionsList, setSearchSuggestionsList] = useState([])
  const [selectedSearchSuggestion, setSelectedSearchSuggestion] = useState(null)

  const openAccountPage = (id) => {
    props.history.push("/account/" + id, "_self");
  }

  const openCallReviewPage = (id) => {
    props.history.push("/call/" + id, "_self");
  }

  const handleExactTranscriptSearch = () => {
    fieldSearch("turns.str", "\"" + query + "\"");
  }
  const handleArrowKeys = (e) => {

    if (["ArrowUp", "ArrowDown", "Enter", "Escape"].includes(e.key) && searchSuggestionsList.length) {
      e.preventDefault()
      switch (e.key) {

        case "ArrowDown":

          //first time ,no selection or last item selected and DOWN pressed,select first item.
          if (!selectedSearchSuggestion || (searchSuggestionsList.indexOf(selectedSearchSuggestion) == searchSuggestionsList.length - 1)) {
            setSelectedSearchSuggestion(searchSuggestionsList[0])
          }
          else {
            let nextSelectionIndex = searchSuggestionsList.indexOf(selectedSearchSuggestion) + 1
            setSelectedSearchSuggestion(searchSuggestionsList[nextSelectionIndex])
          }
          break
        case "ArrowUp":
          //first time ,no selection or first item selected and UP pressed ,select last item.
          if (!selectedSearchSuggestion || (searchSuggestionsList.indexOf(selectedSearchSuggestion) == 0)) {
            setSelectedSearchSuggestion(searchSuggestionsList[searchSuggestionsList.length - 1])
          }


          else {
            let prevSelectionIndex = searchSuggestionsList.indexOf(selectedSearchSuggestion) - 1
            setSelectedSearchSuggestion(searchSuggestionsList[prevSelectionIndex])
          }
          break

        case "Enter":
          if (selectedSearchSuggestion) {
            if (selectedSearchSuggestion.includes('call-')) {
              openCallReviewPage(selectedSearchSuggestion.split("-").slice(1).join("-"))
            }
            else if (selectedSearchSuggestion.includes('account-')) {
              openAccountPage(selectedSearchSuggestion.split('-')[1])
            }
            else if (selectedSearchSuggestion == "titleMatch") {
              fieldSearch("title")
            }
            else if (selectedSearchSuggestion == "transcriptMatchExact") {
              handleExactTranscriptSearch()
            }
            else if (selectedSearchSuggestion == "transcriptMatch") {
              fieldSearch("turns.str")
            }

            else if (selectedSearchSuggestion == "emailMatch") {
              fieldSearch("attendees")
            }
          }
          break

        case "Escape":
          setShowSearchSuggestions(false)
          searchInpRef.current && searchInpRef.current.blur()
          break
      }


    }
  }

  useEffect(() => {

    const handleKeyup = (e) => {
      const activeElementTag = document?.activeElement.tagName
      if (e.key == '/' && searchInpRef.current && !["INPUT", "TEXTAREA"].includes(activeElementTag)) {
        searchInpRef.current.focus()
        if (query && searchSuggestions) {
          setShowSearchSuggestions(true)
        }
      }
    }
    document.addEventListener('keyup', handleKeyup)
    return () => {
      document.removeEventListener('keyup', handleKeyup)
    }
  }, [searchInpRef])

  useEffect(() => {
    if (!searchSuggestions) {
      return
    }
    let localSuggestionList = []
    if (callsSuggestions) {
      callsSuggestions.forEach((call) => {
        localSuggestionList.push('call-' + call.callId)
      })
    }

    if (searchSuggestions.accounts) {
      searchSuggestions.accounts.forEach((account) => {
        localSuggestionList.push('account-' + account.id)
      })
    }

    searchSuggestions.titleMatch && localSuggestionList.push('titleMatch')
    searchSuggestions.transcriptMatchExact && localSuggestionList.push('transcriptMatchExact')
    searchSuggestions.transcriptMatch && localSuggestionList.push('transcriptMatch')
    searchSuggestions.emailMatch && localSuggestionList.push('emailMatch')
    setSearchSuggestionsList(localSuggestionList)
    return () => {
      setSearchSuggestionsList([])
      setSelectedSearchSuggestion(null)
    }

  }, [callsSuggestions, searchSuggestions, showSearchSuggestions])

  function clearQuery() {
    console.log("clearing query:");
    setQuery("");
    setSearchSuggestions(null);
    setCallsSuggestions(null);
    setShowSearchSuggestions(false);
    dispatch({ showSearchResults: false, type: SHOW_SEARCH_RESULTS });
    dispatch({ results: { calls: [], totalHits: 0 }, type: SET_SEARCH_RESULTS });
    dispatch({ field: null, query: "", type: SET_SEARCH_TERM });
  }

  async function fieldSearch(field, queryParam = query) {
    if (queryParam && queryParam.trim() === "") return;
    setLoading(true);
    const params = new URLSearchParams();
    params.append("fieldName", field);
    params.append("query", queryParam.replaceAll('"', ''));
    const callResults = await sendRequest("/search/search_field", params);
    metrics.logEventHeap("UniversalSearch", { field: field, value: queryParam });
    if (callResults.cancelled) {
      return;
    }
    if (!callResults || !callResults.calls) {
      alert("Error occured during search. Please retry later.");
      clearQuery();
      return;
    }

    const results = {
      calls: callResults.calls,
      totalCallHits: callResults.totalHits,
      hasMoreCalls: callResults.calls.length < callResults.totalHits,
      callPage: 0,
    }
    dispatch({ showSearchResults: true, type: SHOW_SEARCH_RESULTS });
    dispatch({ results, type: SET_SEARCH_RESULTS });
    dispatch({ field, query: queryParam, type: SET_SEARCH_TERM });
    setLoading(false);
    setShowSearchSuggestions(false);
    props.history.push("/search");
  }

  async function getCallTitleFieldResults(field, queryParam = query) {
    setCallsSuggestions(null);
    if (queryParam && queryParam.trim() === "") return;
    setLoading(true);
    const params = new URLSearchParams();
    params.append("fieldName", field);
    params.append("query", queryParam.replaceAll('"', ''));
    params.append("size", 2); // Get the first 2 calls at max
    const callResults = await sendRequest("/search/search_field", params);
    if (callResults.cancelled) {
      return;
    }
    if (!callResults || !callResults.calls) {
      alert("Error occured during search. Please retry later.");
      clearQuery();
      return;
    }

    setCallsSuggestions(callResults.calls);
  }

  async function handleChange(e) {
    const searchQuery = e.target.value;
    setQuery(searchQuery);
    if (waitForTypingToStop.current !== null) {
      clearTimeout(waitForTypingToStop.current);
      waitForTypingToStop.current = null;
    }
    waitForTypingToStop.current = setTimeout(async () => {
      if (searchQuery.length >= 3) {
        setLoading(true);
        await Promise.all([
          smartSearch(searchQuery),
          getCallTitleFieldResults("title", searchQuery)
        ]);
        setLoading(false);
      }
      if (searchQuery.trim() === "") {
        clearQuery();
      }
    }, 500);

  }

  async function smartSearch(query) {
    let searchQuery = query;
    if (searchQuery.split('"').length - (1 % 2) === 1) {
      searchQuery = searchQuery.replace(/"([^"]*)$/, "$1");
    }
    setLoading(true);
    setLoadingSuggestions(true);
    const params = new URLSearchParams();
    params.append("query", searchQuery);
    source.current.cancel('cancelling as newer search made for' + searchQuery)
    source.current = CancelToken.source()
    const searchSuggestionsLocal = await sendRequest("/search/smart_search", params, null, {}, false, { cancelToken: source.current.token });
    setSearchSuggestions(searchSuggestionsLocal);
    setShowSearchSuggestions(true);
    setLoadingSuggestions(false);
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setShowSearchSuggestions(false)}>
      <div id="MainSearchBar" className="SearchBar" onClick={() => setShowSearchSuggestions(true)}>
        <InputBase
          onClick={() => setShowSearchSuggestions(true)}
          classes={{
            'input': "!text-coolGray-900 !placeholder-coolGray-600"
          }}
          inputRef={searchInpRef}
          className="h-10 px-3 py-0 rounded-lg w-[35rem] focus-within:border focus-within:border-wingman-brand group bg-coolGray-100 focus-within:bg-white"
          value={query}
          onChange={handleChange}
          onKeyDown={handleArrowKeys}
          startAdornment={
            <InputAdornment position="start">
              {loading && <CircularProgress size={20} color="primary" />}
              {!loading && <SearchIcon className="!text-wingman-brand" />}
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <div className="flex items-center">
                <TextBase textColor="coolGray-500" className="text-coolGray-500 h-6 w-6 bg-coolGray-50 flex justify-center items-center rounded group-focus-within:hidden ">/</TextBase>
                {query !== "" && <button onClick={clearQuery}><ClosePlainCrossIcon /></button>}
              </div>
            </InputAdornment>
          }
          placeholder="Search accounts, call transcripts, meeting participants, meeting titles"
        />
        {showSearchSuggestions && (searchSuggestions || loading) && <SearchSuggestions loading={loadingSuggestions} query={query} fieldSearch={fieldSearch} searchSuggestions={searchSuggestions} selectedSearchSuggestion={selectedSearchSuggestion} setSelectedSearchSuggestion={setSelectedSearchSuggestion} openAccountPage={openAccountPage} handleExactTranscriptSearch={handleExactTranscriptSearch} callsSuggestions={callsSuggestions} />}
      </div>
    </OutsideClickHandler>
  );
};

SearchBar.propTypes = {};

export default withRouter(SearchBar);

