const getAccessOfAFolder = (folder) => {
    /**
     * If a folder is private and shared, it will return 'Shared'
     * If a folder is private and not shared, it will return 'Private'
     * If a folder is public, it will return 'Public' even if it is shared
     */
    if (folder.isPrivate) {
        if (folder.sharedUsers && folder.sharedUsers.length > 0) {
            return 'Shared'
        }
        return 'Private'
    } else {
        return 'Public'
    }
}

export {
    getAccessOfAFolder
};