import React from "react";
import moment from "moment";
import { TextBase } from 'app/designSystem'
const TimeColumn = ({ call, callRowState }) => {

  return (
    <div className="TimeColumn_root">
      <TextBase className="whitespace-nowrap" textColor={callRowState == "READ" ? "text-coolGray-500" : "text-coolGray-900"} >
        {moment(call.time).format("DD MMM YYYY")}
      </TextBase>
      <TextBase textColor="text-coolGray-500">
        {moment(call.time).format("hh:mm A")}
      </TextBase>
    </div>
  );
};

export default TimeColumn;
