import React from "react";
import { getSharedCall, sendRequest } from "app/utils/network";
import { PlayerContext, UnknownError } from "app/containers/Call";
import { connect, useDispatch, useSelector } from "react-redux";
import { GUEST } from "app/constants";
import PropTypes from "prop-types";
import { setCallDetails } from "app/actions/call";
import { setScreenName } from "app/actions/screen";
import withHowler from "../common/CustomHowler";
import { useEffect, useState } from "react";
import { fetchDataSafely } from "../../utils/dataUtils";
import moment from "moment";
import { filterTurns, _formatSecondsToMinutes } from "../../utils/helpers";
import { getFutureEndDate } from "../dashboards/dateUtils";
import { ReactComponent as WarningIcon } from "app/styles/assets/images/icons/warning.svg";
import { Header } from "app/components/layout";
import { API_URL } from "app/config";
import TranscriptVideoPlayer from "./TranscriptVideoPlayer";
import { TranscriptVideoPlayerLoading } from "./CallReviewSkeletal";
import MeetingIcon from "./MeetingIcon";
import { SET_SHARE_DETAILS } from "../../actions/actionTypes";
import { getMediaUrl } from "../../reducers/call";
import { Modal } from "@material-ui/core";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { ReactComponent as Lock } from "app/styles/assets/images/icons/lock.svg";
import { validateStringsOfEmails } from "../../utils/helpers";
import { Button, Input } from "../form";
import { TextBase } from "app/designSystem"
import { useKeyListenerRef } from "../../utils/hooks/useKeyListener";


const SharedCall = (props) => {
  const customerType = GUEST;
  const user = { stringsCustomer: { authorizedDomains: ["example.com"] } };
  const [state, setState] = useState({
    loading: true,
    shareId: props.match.params.shareId,
    call: null,
    errorStr: null,
    customerType,
    isCommentAreaExpanded: false,
    activeTime: props.call.activeTime || 0,
    user,
    autoScroll: true,
    ignoreScroll: false,
    audioSource: "",
  });
  const minVersion = process.env.REACT_APP_NODE_ENV === "production" ? 3 : 1;
  const [call, setCall] = useState(null);
  const [name, setName] = useState("Rep");
  const [shareDetails, setShareDetails] = useState(null);
  const [url, setUrl] = useState("");
  const [expirationDate, setExpirationDate] = useState(getFutureEndDate("year"));
  const dispatch = useDispatch();
  const [emailRequired, setEmailRequired] = useState(false);
  const { setSnackbar } = useSnackbar();
  const [emailText, setEmailText] = useState(null)
  const [emailVerified, setEmailVerified] = useState(true);
  const title = useSelector(store => call?.details?.callDataDocument?.meetingInfo?.meetingTitle || 'Shared Call');
  const [snippetName, setSnippetName] = useState(null);
  const [tagRef] = useKeyListenerRef(onKeyEnter);

  useEffect(() => {
    props.setScreenName("Shared Call");
    getShareDetails();
    var linkTag = document.createElement("link");
    linkTag.rel = "alternate";
    linkTag.type = "application/json+oembed";
    linkTag.href = API_URL + "/call/oembed?url=" + encodeURI(window.location.href);
    console.log("calling " + API_URL + "/call/oembed?url=" + encodeURI(window.location.href));
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(linkTag);
    addMetaTag("og:title", "Wingman Shared Call");
    addMetaTag("og:description", "Call shared via Wingman");
  }, []);

  function addMetaTag(property, content) {
    var metaTag = document.createElement("meta");
    metaTag.setAttribute("property", property);
    metaTag.content = content;
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(metaTag);
  }

  async function addView() {
    const params = new URLSearchParams();
    params.append("shareId", state.shareId);
    params.append("userId", fetchDataSafely(props, "auth.data.user.userId"));
    if (emailRequired) {
      params.append("email", emailText);
    }
    sendRequest("/call/add_views", params);
  }



  async function getShareDetails() {
    const params = new URLSearchParams();
    params.append("shareId", state.shareId);
    var response = await sendRequest("/call/get_shared_call_details", params);
    var snippetDoc = response.result.shareDetailsDocument;
    if (!snippetDoc.emailRequired) {
      addView();
    } else {
      setEmailRequired(true);
    }
    if (snippetDoc.snippetName) {
      setSnippetName(snippetDoc.snippetName)
    }
    setShareDetails(snippetDoc);
    dispatch({ type: SET_SHARE_DETAILS, shareDetails: snippetDoc });
    setName(snippetDoc.userName);
    setExpirationDate(snippetDoc.expiresAt);
    if (snippetDoc.type === "VIDEO") {
      setUrl(response.result.videoUrl)

    }
    else if (snippetDoc.type === "AUDIO") {
      setUrl(response.result.audioUrl);
    }
    getCall(snippetDoc);
  }


  async function getCall(shareDetailsParam) {
    const result = await getSharedCall(state.shareId);
    if (result.error) {
      setState({
        ...state,
        loading: false,
        errorStr: result.message,
      });
      return;
    }
    let turns = fetchDataSafely(result, "callData.conversationTurn", []);
    if (shareDetailsParam && shareDetailsParam.type && (shareDetailsParam.type !== "SELF" || shareDetailsParam.type !== "TURN_SHARE")) {
      turns = filterTurns(turns, shareDetailsParam.startTime, shareDetailsParam.endTime);
    }
    result["callData"]["conversationTurn"] = turns;
    const call = { details: result };
    props.setCallDetails(result.callDataDocument.callId, result);
    setState({
      ...state,
      loading: false,
    });
    if (shareDetailsParam.type === "SELF" || shareDetailsParam.type === "TURN_SHARE") {
      setUrl(getMediaUrl(call));
    }
    setCall(call);

    if (window.location.hash !== "") {
      const searchTurn = window.location.hash.slice(1, window.location.hash.length);
      if (searchTurn.indexOf("time=") !== -1) {
        handlePlayerClick(searchTurn.split("time=")[1]);
      }
    }
  }

  function handlePlayerClick(startTime) {
    props.playerProps.setSeek(startTime);
  }

  if (state.loading) {
    return (
      <div>
        <Header customerType={GUEST} screenType={props.screen.screenType} />
        <div className="SharedCall__container">
          <TranscriptVideoPlayerLoading isSharedCall />
        </div>
      </div>
    );
  }

  if (state.errorStr !== null) {
    if (state.errorStr === "This link has expired.") {
      return (
        <div className="Call__processingContainer">
          <WarningIcon alt={"LinkExpired"} className="Call__processingIcon" />
          <span className="Call__processingCopy">
            {"This link expired on " + moment(expirationDate).format("MMMM DD, YYYY") + ". Please reach out to " + name + " for a new link."}
          </span>
        </div>
      );
    } else {
      return <UnknownError />;
    }
  }
  function handleAddView() {
    if (!emailText) {
      setEmailVerified(false);
      return;
    }
    const verified = validateStringsOfEmails(emailText);
    if (verified) {
      setEmailVerified(true)
      setEmailRequired(false);
      addView();
      setSnackbar("Email Submitted Successfuly", "SUCCESS");
    } else {
      setEmailVerified(false)
    }
  }

  function handleEmailInput(e) {
    setEmailText(e.target.value)
  }

  function onKeyEnter(event) {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === "Enter" && !event.shiftKey) {
      handleAddView();
    }
  }

  return (
    <div>
      <PlayerContext.Provider value={props.playerProps}>
        <Header customerType={GUEST} screenType={props.screen.screenType} />
        <div className="SharedCall__container">
          <div className="Call__ContextContainer__TitleContainer SharedCall__titleDiv">
            <div className="headerCall__outerDiv">
              <div className="headerCall__titleDiv">
                <div className="Player__iconContainer">
                  <MeetingIcon callType={fetchDataSafely(props, "call.details.callDataDocument.callType")} />
                </div>
                <div className="headerCall__outerDiv">
                  <div className="headerCall__titleDiv">
                    <span className="headerCall__title">{snippetName ? snippetName : title}</span>
                  </div>
                  <div>
                    <span className="Player__text-time">
                      {props.player.playback && props.player.playback.callDate ? moment(props.player.playback.callDate).format("MMMM DD, YYYY hh:mm A") : ""}
                    </span>
                    <span className="Player__text-time">
                      {props.player.playback && props.player.playback.duration ? "  |  " + _formatSecondsToMinutes(props.player.playback.duration, " MINS") : ""}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {shareDetails && shareDetails.expiresAt && (
              <div className="SharedCall__copyDiv">
                <span className="SharedCall__header__copy">
                  Expires on: <b>{moment(expirationDate).format("MMMM DD, YYYY")}</b>.
                </span>
                <span className="SharedCall__header__copy">
                  Contact <b>{name}</b> for extension.
                </span>
              </div>
            )}
          </div>
          {emailRequired && (
            <Modal open={emailRequired} className="flex justify-center items-center  " ref={tagRef}>
              <div className="bg-white shadow-md rounded-2xl p-8 outline-none" style={{ width: "600px", height: "unset" }}>
                <div className="flex flex-col justify-around items-center">
                  <div className="w-4/5 flex flex-col items-center justify-around mt-8 mb-3">
                    <Lock />
                  </div>

                  <div className="w-4/5 flex flex-col items-center justify-around mt-3">
                    <TextBase className="text-gray-400">
                      Please share your email to access the shared recording
                    </TextBase>

                  </div>

                  <div class=" w-4/5 flex flex-col items-center djustify-around relative">
                    <div className="flex justify-end w-full mb-2">
                      {!emailVerified &&
                        <p className="my-0 mt-3 mx-0 font-inter font-normal text-base flex justify-end text-right text-red-600 visible" >
                          ⚠️Please enter valid email
                        </p>}
                    </div>

                    <div style={{ width: "440px" }}>
                      <Input
                        type="email"
                        placeholder="you@yourcompany.com"
                        onChange={handleEmailInput}
                        value={emailText}
                      >
                      </Input>
                    </div>
                    <Button
                      type="big"
                      onClick={handleAddView}
                      width="440px"
                      className="mt-2"
                      theme="dark"
                    >
                      View Call Recording
                    </Button>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          <TranscriptVideoPlayer url={url} isSharedCall />
        </div>
      </PlayerContext.Provider>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    call: store.call,
    playback: store.player.playback,
    auth: store.auth,
    screen: store.screen,
  };
};

SharedCall.propTypes = {
  call: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

SharedCall.defaultProps = {};

export default connect(mapStateToProps, { setCallDetails, setScreenName })(withHowler(SharedCall));