import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import HubSpotLogo from "app/styles/assets/images/integrations/hubspot.png";
import { HUBSPOT_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { ERROR } from "../../actions/actionTypes";

const SyncHubspot = props => {
  const [loading, setLoading] = useState(false);
  const { setSnackbar } = useSnackbar();

  async function completeSync(code) {
    const userId = props.auth.data.user.userId || {};
    const res = await crmOAuthCallback(code, 'HUBSPOT');
    if (res === "NOK") {
      console.log("Code is:", code);
      setSnackbar("Error syncing. Please try again, check your permission levels or contact support@trywingman.com for help", ERROR);
      return;
    }
    setLoading(false);
    props.refreshUserAction();
  }

  async function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const url = `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&scope=timeline sales-email-read crm.objects.contacts.read crm.objects.contacts.write crm.objects.companies.write crm.schemas.contacts.read crm.objects.companies.read crm.objects.deals.read crm.objects.deals.write crm.schemas.companies.read crm.schemas.deals.read crm.objects.owners.read&redirect_uri=${location}`;
    openWindowAndListenForParam(url, "Hubspot Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={HubSpotLogo}
      heading="Hubspot"
      subHeading="CRM Integrations"
      bodyText="Sync hubspot to link all CRM info."
      loading={loading}
      sync={sync}
      crm="HUBSPOT"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncHubspot.defaultProps = {
  access: "user",
};

SyncHubspot.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  access: PropTypes.string,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncHubspot);
