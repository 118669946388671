import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton';
import CompanyIcon from "app/styles/assets/images/icons/dealView/CompanyIcon.svg";
import TranscriptIcon from "app/styles/assets/images/icons/dealView/TranscriptIcon.svg";
import AttendeeIcon from "app/styles/assets/images/icons/dealView/AttendeeIcon.svg";
import { useSelector } from 'react-redux';
import { NoDataCustom } from '../common/NoData';
import NoDataIcon from "app/styles/assets/images/icons/noSearchResults.svg"
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import metrics from "../../utils/metrics";
import MeetingTitleColumn from 'app/components/callTable/MeetingTitleColumn';

const SearchSuggestions = ({ searchSuggestions, callsSuggestions, query, fieldSearch, loading, history, selectedSearchSuggestion, setSelectedSearchSuggestion, openAccountPage, handleExactTranscriptSearch }) => {

    const [accounts, setAccounts] = useState([]);
    const [isPhrase, setIsPhrase] = useState(isQueryPhrase(query));
    const auth = useSelector(store => store.auth);

    useEffect(() => { setIsPhrase(isQueryPhrase(query)) }, [query])

    useEffect(() => {
        if (searchSuggestions && searchSuggestions.accounts) {
            setAccounts(searchSuggestions.accounts);
        }
    }, [searchSuggestions])



    function isQueryPhrase(queryParam) {
        if (!queryParam) return false;
        try {
            return queryParam.split(" ").length > 1;
        } catch (error) {
            return false;
        }
    }

    function noResults() {
        if (searchSuggestions && (searchSuggestions.titleMatch || searchSuggestions.transcriptMatch || searchSuggestions.emailMatch || callsSuggestions?.length > 0)) {
            return false;
        }
        if ((auth.isAdminLogin || auth.stringsCustomer.featuresGated.dealView) && accounts.length > 0) {
            return false;
        }
        return true;
    }

    if (query.trim() === "") return null;

    if (loading) {
        return (
            <div className="SearchSuggestions__container z-50">
                <div className="SearchSuggestions__oppsContainer">
                    <div className="SearchSuggestions__resultLoading">
                        <Skeleton circle={true} height={30} width={30} />
                        <span className="SearchSuggestions__resultTitle"><Skeleton height={20} width={200} /></span>
                    </div>
                    <div className="SearchSuggestions__resultLoading">
                        <Skeleton circle={true} height={30} width={30} />
                        <span className="SearchSuggestions__resultTitle"><Skeleton height={20} width={300} /></span>
                    </div>
                </div>
                <div className="SearchSuggestions__resultLoading">
                    <Skeleton circle={true} height={30} width={30} />
                    <span className="SearchSuggestions__resultTitle"><Skeleton height={20} width={200} /></span>
                </div>
                <div className="SearchSuggestions__resultLoading">
                    <Skeleton circle={true} height={30} width={30} />
                    <span className="SearchSuggestions__resultTitle"><Skeleton height={20} width={300} /></span>
                </div>
            </div >
        );
    }

    if (noResults())
        return (<div className="SearchSuggestions__container z-50">
            <NoDataCustom
                icon={NoDataIcon}
                copy={"Didn't find any calls/accounts matching this keyword."}
                subcopy={"Try completing the word/phrase."}
            /></div>);

    return (
        <div className="SearchSuggestions__container z-50">
            {callsSuggestions && callsSuggestions.length > 0 && <span className="SearchSuggestions__subheading mb-2">Calls found</span>}
            {callsSuggestions && callsSuggestions.length > 0 && <div className="SearchSuggestions__oppsContainer px-0">
                {callsSuggestions.map((call, index) => {
                    return (
                        <Link key={call.callId + "__" + index} className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50 px-0", { 'active': selectedSearchSuggestion == 'call-' + call.callId })} onMouseEnter={() => { setSelectedSearchSuggestion("call-" + call.callId) }} to={`/call/${call.callId}`} onClick={() => metrics.logEventHeap("UniversalSearch", { field: "call", value: query })}>
                            <MeetingTitleColumn call={call} showDate={true} showAccountDetails={true} />
                        </Link>
                    )
                })}
            </div>}

            {(auth.isAdminLogin || auth.stringsCustomer.featuresGated.dealView) && accounts.length > 0 && <span className="SearchSuggestions__subheading">Accounts found</span>}
            {(auth.isAdminLogin || auth.stringsCustomer.featuresGated.dealView) && accounts.length > 0 &&
                <div className="SearchSuggestions__oppsContainer">
                    {accounts.map(o => {
                        return (
                            <div id="SearchSuggestionsAccount" className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50", { 'active': selectedSearchSuggestion == 'account-' + o.id })} key={o.id} onClick={() => openAccountPage(o.id)} onMouseEnter={() => { setSelectedSearchSuggestion("account-" + o.id) }}>
                                <img src={CompanyIcon} className="SearchSuggestions__resultIconSmall" />
                                <div className="SearchSuggestions__resultNameDiv">
                                    <span className="SearchSuggestions__resultTitle">
                                        {o.name}
                                    </span>
                                    <span className="SearchSuggestions__resultSubtitle">
                                        In Accounts
                                    </span>
                                </div>
                            </div>);
                    })}
                </div>
            }

            {searchSuggestions && (searchSuggestions.titleMatch || searchSuggestions.transcriptMatch || searchSuggestions.emailMatch) && <span className="SearchSuggestions__subheading">Search Calls</span>}
            {searchSuggestions && <div className="SearchSuggestions__fieldsContainer">
                {searchSuggestions.titleMatch &&
                    <div id="SearchSuggestionsTitle" onClick={() => fieldSearch("title")} className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50", { 'active': selectedSearchSuggestion == 'titleMatch' })} key={"title"} onMouseEnter={() => { setSelectedSearchSuggestion("titleMatch") }}>
                        <img src={TranscriptIcon} className="SearchSuggestions__resultIcon" />
                        <div className="SearchSuggestions__resultNameDiv">
                            <span className="SearchSuggestions__resultTitle">
                                Title contains <span className="SearchSuggestions__resultTitleBold">{query}</span>
                            </span>
                        </div>
                    </div>
                }
                {searchSuggestions.transcriptMatchExact &&
                    <div id="SearchSuggestionsTranscriptExact" onClick={handleExactTranscriptSearch} className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50", { 'active': selectedSearchSuggestion == 'transcriptMatchExact' })} key={"transcriptExact"} onMouseEnter={() => { setSelectedSearchSuggestion("transcriptMatchExact") }}>
                        <img src={TranscriptIcon} className="SearchSuggestions__resultIcon" />
                        <div className="SearchSuggestions__resultNameDiv">
                            <span className="SearchSuggestions__resultTitle">
                                Transcript contains the exact {isPhrase ? "phrase" : "word"} <span className="SearchSuggestions__resultTitleBold">"{query}"</span>
                            </span>
                        </div>
                    </div>
                }
                {searchSuggestions.transcriptMatch &&
                    <div id="SearchSuggestionsTranscriptVariations" onClick={() => fieldSearch("turns.str")} className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50", { 'active': selectedSearchSuggestion == 'transcriptMatch' })} key={"transcript"} onMouseEnter={() => { setSelectedSearchSuggestion("transcriptMatch") }}>
                        <img src={TranscriptIcon} className="SearchSuggestions__resultIcon" />
                        <div className="SearchSuggestions__resultNameDiv">
                            <span className="SearchSuggestions__resultTitle">
                                Transcript contains variations of <span className="SearchSuggestions__resultTitleBold">{query}</span>
                            </span>
                        </div>
                    </div>
                }
                {searchSuggestions.emailMatch &&
                    <div id="SearchSuggestionsTranscriptAttendees" onClick={() => fieldSearch("attendees")} className={classNames("SearchSuggestions__resultContainer hover:bg-blue-50", { 'active': selectedSearchSuggestion == 'emailMatch' })} key={"attendees"} onMouseEnter={() => { setSelectedSearchSuggestion("emailMatch") }}>
                        <img src={AttendeeIcon} className="SearchSuggestions__resultIcon" />
                        <div className="SearchSuggestions__resultNameDiv">
                            <span className="SearchSuggestions__resultTitle">
                                Attendee name/email contains <span className="SearchSuggestions__resultTitleBold">{query}</span>
                            </span>
                        </div>
                    </div>}
            </div>}
        </div>
    );
}
SearchSuggestions.propTypes = {}
SearchSuggestions.defaultProps = {}
export default withRouter(SearchSuggestions);