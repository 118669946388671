import React from "react";
import cx from 'clsx';
import PropTypes from "prop-types";
import { TextBase } from "app/designSystem/typography/Typography";

const Tab = ({ leftIcon, rightIcon, label, handleClick, isSelected, isLeftMost, isRightMost, className, selectedClassName }) => {
    return (
        <li
            className={cx(
                "py-3 px-6 text-gray-400 w-full flex justify-center items-center",
                `${isSelected && selectedClassName}`,
                `${isLeftMost && "border-l-0"}`,
                `${isRightMost && "border-r-0"}`,
                className
            )}
            onClick={handleClick}
        >
            <div className="flex items-center">
                {leftIcon && (
                    React.cloneElement(leftIcon, {
                        className: cx("w-6 h-6 mr-2 grayscale", leftIcon?.props?.className),
                        style: {
                            filter: !isSelected && "invert(47%) sepia(4%) saturate(1269%) hue-rotate(182deg) brightness(92%) contrast(84%)"
                        }
                    })
                )}
                <TextBase className={`ml-2 ${!isSelected && "text-gray-500"}`}>{label}</TextBase>
                {rightIcon && (
                    React.cloneElement(rightIcon, {
                        className: cx("w-6 h-6 ml-4 grayscale", rightIcon?.props?.className),
                        style: {
                            filter: !isSelected && "invert(47%) sepia(4%) saturate(1269%) hue-rotate(182deg) brightness(92%) contrast(84%)"
                        }
                    })
                )}
            </div>
        </li>
    );
};

export default Tab;

Tab.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    leftIcon: PropTypes.object,
    rightIcon: PropTypes.object,
    label: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isLeftMost: PropTypes.bool,
    isRightMost: PropTypes.bool,
    className: PropTypes.string,
    selectedClassName: PropTypes.string
};

Tab.defaultProps = {
    id: 0,
    leftIcon: null,
    rightIcon: null,
    isSelected: false,
    isLeftMost: false,
    isRightMost: false,
    className: null,
    selectedClassName: null
};