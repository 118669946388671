import React, { useState, useRef } from 'react';
import { TextBase, TextLarge } from 'app/designSystem'
import { Link } from "react-router-dom";
import { BellRingingIcon, ThreeDotMenuIcon, GlobeIcon, UserIcon, FolderIcon, VideoIcon, TeamIcon } from 'app/designSystem/icons'
import { FolderMenu } from 'app/components/gameTapesLatest/menu'

const GameTapeFolder = ({ data, access, subscribed, actions, subFoldersCount, subGameTapesCount, className, style }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const { id, name, description } = data

    return (
        <Link to={`/gametapes/folder/${id}`} className={`flex flex-col justify-between pt-4 pb-3 px-4 m-4 mb-8 bg-gray-50 rounded-lg cursor-pointer border border-coolGray-200 transition-all hover:bg-white hover:shadow-md h-44 w-[25rem] ${className}`} style={{
            ...style
        }}>
            <div className="mb-2 flex justify-between items-start h-[35%]">
                <TextLarge className="overflow-hidden h-full line-clamp-2">{name}</TextLarge>
                {
                    subscribed && <div className="bg-purple-50 text-purple-600 rounded-full ml-2 p-1 h-9 w-9 flex justify-center items-center" style={{
                        boxShadow: 'rgb(147 51 233 / 10%) 2px 2px 15px 1px'
                    }}>
                        <BellRingingIcon stroke={"#2563EB"} style={{ height: 20, width: 20 }} />
                    </div>
                }
            </div>
            <div className="mb-2 h-[30%] overflow-hidden">
                <TextBase className="text-gray-500 flex break-words max-w-full overflow-ellipsis line-clamp-2">{description}</TextBase>
            </div>
            <div className="flex flex-row justify-between items-center h-[20%]">
                <div className="flex items-center">
                    {(access) && <div className="text-gray-500 rounded py-1 pr-2 ml-0 h-8 flex items-center">
                        {access === 'Shared' && <TeamIcon className="mr-1" />}
                        {access === 'Public' && <GlobeIcon className="mr-1 w-5 h-5" />}
                        {access === 'Private' && <UserIcon className="mr-1" />}
                        <TextBase className="font-medium text-gray-500">{access}</TextBase>
                    </div>}
                    {subGameTapesCount ? <div className="text-gray-500 rounded py-1 px-2 h-8 flex items-center">
                        <VideoIcon className="mr-2" />
                        <TextBase className="font-medium text-gray-500">{subGameTapesCount} {subGameTapesCount > 1 ? 'Tapes' : 'Tape'}</TextBase>
                    </div> : null}
                    {subFoldersCount ? <div className="text-gray-500 rounded py-1 px-2 h-8 flex items-center mr-2">
                        <FolderIcon className="mr-2" />
                        <TextBase className="font-medium text-gray-500">{subFoldersCount} {subFoldersCount > 1 ? 'Folders' : 'Folder'}</TextBase>
                    </div> : null}
                </div>
                <button className="p-0.5 hover:bg-coolGray-50 rounded-lg" onClick={(e) => {
                    setIsOpen(!isOpen);
                    e.preventDefault();
                }} ref={menuRef}>
                    <ThreeDotMenuIcon />
                </button>
            </div>
            <FolderMenu isOpen={isOpen} setIsOpen={setIsOpen} anchorEl={menuRef.current} actions={actions} />
        </Link >
    );
};

export default React.memo(GameTapeFolder);