import React from "react";
import PropTypes from "prop-types";

const Ratio = props => {
  return (
    <div className="Ratio" title={props.title}>
      <div
        className="Ratio__bar"
        style={{ width: `${props.rate}%`, backgroundColor: `${props.color}` }}
      >
        <span className="Ratio__barText">{"  " + props.title.toString().split(" ")[0]}</span>
      </div>
    </div>
  );
};

Ratio.propTypes = {
  rate: PropTypes.any,
  title: PropTypes.any,
};

Ratio.defaultProps = {
  rate: 0,
  title: "",
};

export default Ratio;
