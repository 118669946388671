import React, { useState } from "react";
import { Card, CustomIconButton } from "app/components/common";
import { Ratio } from "app/components/dashboards";
import sortIcon from "app/styles/assets/images/icons/sort.png";
import sortedIcon from "app/styles/assets/images/icons/sorted.png";
import descSortedIcon from "app/styles/assets/images/icons/descSorted.png";
import { _formatSecondsToPrettyTime, _roundUp } from "app/utils/helpers";
import { withErrorHandling } from "app/components/error/errorHandlingScreen";
import { withRouter, Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
import AgentCalls from "../../containers/AgentCalls";
import InfoIcon from "@material-ui/icons/Info";
import ReactTooltip from 'react-tooltip'

const AgentsTable = props => {
  const [selectedAgent, setSelectedAgent] = useState({});
  const [isAgentModalVisible, setIsAgentModalVisible] = useState(false);
  const [sortField, setSortField] = useState("time");

  function showAgentModal(name, agentId, field) {
    setSelectedAgent({ name, agentId });
    setSortField(field);
    setIsAgentModalVisible(true);
  }

  const callCountNF = getNormalizedValues(props.agents, "callCountWithoutData");
  const callDurationNF = getNormalizedValues(props.agents, "avgCallDuration");
  const longMonologueNF = getNormalizedValues(props.agents, "longestMonologueDuration");
  const headerProps = {
    sort: props.sort,
    sortData: props.sortData,
    classes: props.classes,
  };
  return (
    <Card title="Reps Performance">
      <table>
        <thead>
          <tr>
            <th>
              <TableHeader label="Rep Name" field="name" {...headerProps} />
            </th>
            <th>
              <TableHeader label="Call Count" field="callCount" {...headerProps} />
            </th>
            <th>
              <TableHeader label="Avg Call Duration" field="avgCallDuration" {...headerProps} />
            </th>
            <th>
              <TableHeader showTooltip message="Talk/Listen Ratio for a rep is calculated as the average across all calls excluding those where the rep was a silent participant and calls that went to voicemail." label="Talk/Listen Ratio" field="avgTLRatio" {...headerProps} />
            </th>
            <th>
              <TableHeader label="Longest Monologue Duration" field="longestMonologueDuration" {...headerProps} />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.agents.map((agent, i) => {
            const tlRatio = agent.domAvgTLRatio === "Infinity" ? agent.avgTLRatio : agent.domAvgTLRatio;
            const longestMonologueDuration = agent.longestMonologueDuration === "Infinity" || agent.longestMonologueDuration === "-Infinity" ? 0 : agent.longestMonologueDuration;
            return (
              <tr key={i}>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => showAgentModal(agent.displayName, agent.userId, "time")}
                >
                  {agent.displayName}
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => showAgentModal(agent.displayName, agent.userId, "time")}
                >
                  <Ratio
                    key={`callCount${i}`}
                    title={`${agent.callCountWithoutData} Call/s`}
                    rate={callCountNF[i]}
                  />
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => showAgentModal(agent.displayName, agent.userId, "duration")}
                >
                  <Ratio
                    key={`callDuration${i}`}
                    title={`${_formatSecondsToPrettyTime(agent.avgCallDuration)}`}
                    rate={callDurationNF[i]}
                  />
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => showAgentModal(agent.displayName, agent.userId, "talkListenRatio")}
                >
                  <Ratio
                    key={`talkListenRatio${i}`}
                    title={`${_roundUp(tlRatio * 100, 1)}%`}
                    rate={_roundUp(tlRatio * 100, 1) + 1}
                  />
                </td>
                <td
                  style={{ cursor: "pointer" }}
                  onClick={() => showAgentModal(agent.displayName, agent.userId, "longestMonologueDuration")}
                >
                  <Ratio
                    key={`longestMonologueDuration${i}`}
                    title={`${_formatSecondsToPrettyTime(longestMonologueDuration)}`}
                    rate={longestMonologueDuration === 0 ? 0 : longMonologueNF[i]}
                  />
                </td>
              </tr>
            );
          })}
          {props.agents.length == 0 && <EmptyTableRow />}
        </tbody>
      </table>
      {isAgentModalVisible && <div open={isAgentModalVisible} className="SPaper AgentCalls__modal">
        <AgentCalls {...selectedAgent} filterParams={props.filterParams} onClose={() => setIsAgentModalVisible(false)} sortField={sortField} />
      </div>}
    </Card>
  );
}

const TableHeader = ({
  classes, sort, sortData, field, label, showTooltip, message
}) => (
    <div className="Manager-Dashboard__agents-performance-header-column">
      {label}
      {showTooltip &&
        <>
          <InfoIcon data-tip data-for="TableTalkListenRatio" style={{ fontSize: 12 }} />
          <ReactTooltip id="TableTalkListenRatio" effect="solid" className="InfoTooltip">
            <span>{message}</span>
          </ReactTooltip>
        </>
      }
      <CustomIconButton
        classes={classes}
        icon={
          sortData[field] != null
            ? sortData[field] == "asc"
              ? sortedIcon
              : descSortedIcon
            : sortIcon
        }
        onClick={() => sort(field)}
      />
    </div>
  );

const EmptyTableRow = () => (
  <tr>
    <td>No Reps found.</td>
    <td />
    <td />
    <td />
    <td />
  </tr>
);

export default withRouter(withErrorHandling(AgentsTable));

export function getNormalizedValues(array, field) {
  let max = -1;
  const nf = [];
  array.map(item => {
    if (item[field] > max) {
      max = item[field];
    }
  });
  array.map(item => {
    let value = (item[field] * 90) / max;
    if (isNaN(value)) {
      value = 0;
    }
    nf.push(value);
  });
  return nf;
}
