import React, { useState } from "react";
import { CustomTooltip, CustomChip } from "app/components/common";
import { CircularProgress, TextField } from "@material-ui/core";
import { copyStringToClipboard } from "../../utils/helpers";
import { useKeyListenerRef } from "../../utils/hooks/useKeyListener";
import useSnackbar from "../../utils/hooks/useSnackbar";

const TagFilledInputArea = (props) => {
  const [values, setValues] = useState([]);
  const [hideInputField, setHideInputField] = useState(false);
  const [loading, setLoading] = useState(false);

  const [tagRef] = useKeyListenerRef(onKeyEnter);
  const { setSnackbar } = useSnackbar();

  function onChange(e) {
    setValues(e.target.value.split("\n"));
  }

  async function addPhrase(phrase) {
    setLoading(true);
    await props.addPhrase(phrase);
    setValues([]);
    setHideInputField(true);
    setTimeout(() => {
      setHideInputField(false);
    }, 200);
    setLoading(false);
  }

  function onKeyEnter(event) {
    event.stopPropagation();
    event.preventDefault();

    if (event.keyCode === 13 && !event.shiftKey) {
      if (event.ctrlKey || event.altKey) {
        let tempValues = values;
        tempValues.push("");
        setValues(tempValues);
      }
      else values.map(value => {
        if (value) addPhrase(value);
      });
    }
  }

  function handleClick(value) {
    copyStringToClipboard(value);
    setSnackbar("Phrase Copied!", "SUCCESS", 300);
  }

  const { removePhrase, type } = props;

  return (
    <div style={{ overflow: "auto" }}>
      <div ref={tagRef} className="KeywordsTracking__chipContainer">
        {props.tags.map((phrase, i) => {
          if (phrase.text !== "__EMPTY_CATEGORY_PLACEHOLDER__") {
            return (
              <CustomTooltip key={i} placement="top" enterDelay={100} title="Click to copy">
                <div style={{ display: "inline-block" }}>
                  <CustomChip
                    className="KeywordsTracking__chip"
                    chipProps={{
                      label: phrase.text,
                      onClick: () => handleClick(phrase.text),
                      onDelete: () => {
                        removePhrase(phrase.phraseId, phrase, type == "exact");
                      },
                      disabled: props.disabled
                    }}
                  />
                </div>
              </CustomTooltip>
            );
          }
          return null;
        })}
        <div style={{ display: "flex", alignItems: "center" }}>
          {!hideInputField && (
            <CustomTooltip placement="bottom-end" enterDelay={500} title="Press enter to add">
              <TextField
                id={`phrases${props.id}`}
                label={props.label}
                value={values.join("\n")}
                onChange={onChange}
                multiline
                className="KeywordsTracking__addPhrase"
                disabled={props.disabled}
              />
            </CustomTooltip>
          )}
          {loading && <CircularProgress size={20} />}
        </div>
      </div>
    </div>
  );
}

export default TagFilledInputArea;
