import { debounce } from "lodash";
import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";

export const useHoverListener = (id, hoverCallback, unhoverCallback, interval = 0) => {
  const [hovering, setHovering] = useState(false);
  const timeout = useRef(null);

  function onHover(e) {
    if (timeout.current === null) {
      timeout.current = setTimeout(() => {
        setHovering(true);
        hoverCallback(e);
      }, interval);
    }
  }

  function onUnhover(e) {
    if (timeout.current !== null) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setHovering(false);
    unhoverCallback(e);
  }

  useEffect(() => {
    if (document.getElementById(id)) {
      document.getElementById(id).addEventListener("mouseenter", onHover);
      document.getElementById(id).addEventListener("mouseleave", onUnhover);
    }
    return () => {
      if (document.getElementById(id)) {
        document.getElementById(id).removeEventListener("mouseenter", onHover);
        document.getElementById(id).removeEventListener("mouseleave", onUnhover);
      }
    };
  }, [id]);

  return hovering;
}

export const useHoverListenerRef = (hoverCallback, unhoverCallback, interval = 0) => {
  const ref = useRef(null);
  const timeout = useRef(null);

  function onHover(e) {
    if (timeout.current === null) {
      timeout.current = setTimeout(() => {
        hoverCallback(e);
      }, interval);
    }
  }

  function onUnhover(e) {
    if (timeout.current !== null) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    unhoverCallback(e);
  }

  const setRef = useCallback(node => {
    if (ref.current) {
      ref.current.removeEventListener("mouseenter", onHover);
      ref.current.removeEventListener("mouseleave", onUnhover);
    }

    if (node) {
      node.addEventListener("mouseenter", onHover);
      node.addEventListener("mouseleave", onUnhover);

    }

    // Save a reference to the node
    ref.current = node
  }, [])
  return [setRef];
}

export const useHoverAndMoveListenerRef = (ref, hoverCallback, unhoverCallback, mousemoveCallback, interval = 0) => {
  const [hovering, setHovering] = useState(false);
  const timeout = useRef(null);
  const onMouseMoveDebounce = debounce(onMouseMove, 100);

  function onHover(e) {
    if (timeout.current === null) {
      timeout.current = setTimeout(() => {
        setHovering(true);
        hoverCallback(e);
      }, interval);
    }
  }

  function onMouseMove(e) {
    console.log("on mouse move", e.clientX);
    mousemoveCallback(e);
  }

  function onUnhover(e) {
    if (timeout.current !== null) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    setHovering(false);
    unhoverCallback(e);
  }

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mousemove", onMouseMoveDebounce);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mousemove", onMouseMoveDebounce);
      }
    };
  }, [ref.current]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("mouseenter", onHover);
      ref.current.addEventListener("mouseleave", onUnhover);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", onHover);
        ref.current.removeEventListener("mouseleave", onUnhover);
      }
    };
  }, [ref.current]);

  return hovering;
}