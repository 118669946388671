import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
/**
 * Component to use for most user interactions
 */
export default function Button({ className, variant, children, loading, startIcon, endIcon, onClick, disabled, ...props }) {
  return (
    <button onClick={(e) => { onClick && !disabled && onClick(e) }} disabled={disabled}
      className={classNames(
        //   common for all variants
        "px-2.5 py-3   text-base  items-center justify-center flex rounded disabled:opacity-30 disabled:cursor-not-allowed",
        {
          "bg-brand-blue-1 text-white font-medium hover:bg-brand-blue-2 active:bg-brand-blue-3 ":
            variant == "primary",
        },
        {
          "bg-white border-secondary border-medium-gray  text-coolGray-500 font-medium hover:text-brand-blue-2 hover:border-brand-blue-2  active:text-brand-blue-3 active:border-brand-blue-3":
            variant == "secondary",
        },
        {
          "bg-transparent text-gray-700 font-medium hover:text-brand-blue-1 active:text-brand-blue-3 ":
            variant == "tertiary",
        },
        className
      )}
      {...props}
    >
      {loading && <CircularProgress color="white" size={15} className="mr-5" />}
      {startIcon &&
        <div className="ml-1 mr-2">
          {startIcon}
        </div>}
      {children}
      {endIcon &&
        <div className="ml-2 mr-1">
          {endIcon}
        </div>}
    </button>
  );
}
Button.propTypes = {
  /**
   * Based on button use cases ,use the appropriate type
   * */
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  /**
     * Icon appears before label
     * */
  startIcon: PropTypes.element,
  /**
     * Icon appears after label
     * */
  endIcon: PropTypes.element
};

Button.defaultProps = {
  variant: "primary"
}

