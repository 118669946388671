import { Popper } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ScoringContainer from './ScoringContainer.jsx'
import { TextLarge, ClosePlainCrossIcon } from 'app/designSystem'
import { SmallArrowRightIcon, SmallArrowLeftIcon, DisabledLeftArrowIcon, DisabledRightArrowIcon } from "app/designSystem/icons";
import { ViewType } from 'app/constants.js';


export default function ScoringPopover({ showScoringPopover, setShowScoringPopover, popoverAnchor, viewType, setViewType }) {
    const [showNavigationArrows, setShowNavigationArrows] = useState(false);
    const [currentScorecardIndex, setCurrentScorecardIndex] = useState(-1);
    const [scorecardsCount, setScorecardsCount] = useState(null);
    const [headingText, setHeadingText] = useState("");
    const [unsavedScorecard, setUnsavedScorecard] = useState(null)

    useEffect(() => {
        if (unsavedScorecard) setViewType(ViewType.WRITE_SCORECARD)
    }, [unsavedScorecard])

    function closeScoringPopover() { setUnsavedScorecard(null); setViewType(ViewType.VIEW_SCORECARD_LIST); setShowScoringPopover(false); }

    return (
        showScoringPopover &&
        <Popper className="border-coolGray-200 w-[30%] border-l bg-white scoring-popover z-20 shadow-drawer " style={{ height: "calc(100vh - 70px)" }}
            anchorEl={popoverAnchor}
            onClose={() => { setShowScoringPopover(false) }}
            open={showScoringPopover}
            placement={"bottom-end"}
            modifiers={{
                offset: { offset: "-1 -2 " },
                flip: { enabled: false },
                preventOverflow: {
                    enabled: false,
                },
                hide: { enabled: false }
            }
            }>

            <div className='flex flex-col h-full'>
                <div className='flex-shrink-0'>
                    <div className='flex justify-between mb-4 items-center px-5 pt-5 pb-4 shadow-sm'>
                        <div className='flex'>
                            <TextLarge className="mr-1 ml-4" >{headingText}</TextLarge>
                            {showNavigationArrows &&
                                <>
                                    {currentScorecardIndex == 0 ? <DisabledLeftArrowIcon /> : <SmallArrowLeftIcon onClick={() => currentScorecardIndex > 0 ? setCurrentScorecardIndex(currentScorecardIndex - 1) : null} className="cursor-pointer " />}
                                    {currentScorecardIndex == scorecardsCount - 1 ? <DisabledRightArrowIcon className=' ml-[-0.6rem]' /> : <SmallArrowRightIcon onClick={() => { currentScorecardIndex < scorecardsCount - 1 ? setCurrentScorecardIndex(currentScorecardIndex + 1) : null }} className="cursor-pointer ml-[-0.6rem]" />}

                                </>
                            }
                        </div>
                        <div className='flex justify-end'>
                            {(viewType === ViewType.WRITE_SCORECARD) &&
                                <button className='flex items-start  justify-center mr-2 rounded-full h-6 w-6 hover:bg-coolGray-100 text-wingman-brand' onClick={() => { setShowScoringPopover(false); }}> — </button>
                            }
                            <button className='flex items-center justify-center mr-2 rounded-full hover:bg-coolGray-100'
                                onClick={() => { closeScoringPopover() }} >
                                <ClosePlainCrossIcon />
                            </button>
                        </div>
                    </div>

                </div>
                <ScoringContainer close={() => { setShowScoringPopover(false); }} showScoringPopover={showScoringPopover} setShowNavigationArrows={setShowNavigationArrows} setCurrentScorecardIndex={setCurrentScorecardIndex} setScorecardsCount={setScorecardsCount} currentScorecardIndex={currentScorecardIndex} setHeadingText={setHeadingText} viewType={viewType} setViewType={setViewType} unsavedScorecard={unsavedScorecard} setUnsavedScorecard={setUnsavedScorecard} />
            </div>
        </Popper>)
}