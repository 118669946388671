import React, { useEffect, useState } from "react";
import {
  getBattlecards,
  getLinkForLatestWingmanDownloadableFile,
  getAllBattlecardCategories,
  getAllManagersForFilters,
  deleteBattlecard,
} from "app/utils/network";
import { BattlecardFormModal, BattleCard } from "app/components/BattleCard";
import {
  groupBattlecardsIntoCategories,
  updateBattlecardCategoriesData,
  removeExistingCard,
} from "app/components/BattleCard/helper";
import { setLoading, setMetaData } from "app/actions/screen";
import { setBattlecards } from "app/actions/pageData";
import { connect } from "react-redux";
import { DESKTOP_APP_S3_BUCKET } from "app/config";
import { Label } from "app/components/form";
import { Button } from "app/designSystem"
import Select from "react-select";
import BattleCardCategory from "app/components/wingman/BattleCardCategory";
import { PERSONAL, GLOBAL } from "../../constants";
import xss from 'xss';
import { usePageTimeTracker } from "../../utils/hooks/usePageTimeTracker";
import noDataImage from "app/styles/assets/images/icons/noData.svg";
import { Text2XL } from "app/designSystem/typography/Typography";

const MY_PERSONAL_CARDS = "My Personal Cards";
const GLOBAL_CARDS = "Global Cards";
const ALL_CARDS = "All Cards";

const BattleCards = props => {
  const [battlecard, setBattlecard] = useState(null);
  const [teamFilter, setTeamFilter] = useState({ label: "All Cards", value: null });
  const [showCreateBattleCardForm, setShowCreateBattleCardForm] = useState(false);
  const os = window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";
  const initialOptions = [{ label: "All Cards", value: null }, { value: props.auth.data.user.userId, label: MY_PERSONAL_CARDS }, { value: GLOBAL, label: GLOBAL_CARDS }];
  const [options, setOptions] = useState(initialOptions);
  usePageTimeTracker(["CueCardsSetup"]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    let newOptions = props.screen.metaData.managers;
    newOptions = initialOptions.concat(newOptions);
    setOptions(newOptions);
  }, [props.screen.metaData.managers]);

  async function loadData() {
    await fetchBattlecards();
    await loadManagers();
  }

  async function loadManagers() {
    const managers = await getAllManagersForFilters();
    props.setMetaData({ managers });
  }

  async function fetchBattlecards(teamFilterLocal = null) {
    if (props.pageData.battlecards.cards.length === 0) {
      props.setLoading(true);
    }
    const battlecards = await getBattlecardsByFilter(teamFilterLocal || teamFilter);
    let allCategories = await getAllBattlecardCategories();
    allCategories = [...new Set([...allCategories, ...props.screen.metaData.battlecardCategories])];
    props.setMetaData({ battlecardCategories: allCategories });
    const safeBattlecards = battlecards.map(card => {
      card.htmlContent = xss(card.htmlContent);
      return card;
    });
    const battlecardCategories = groupBattlecardsIntoCategories(safeBattlecards);
    props.setBattlecards(battlecardCategories);
    props.setLoading(false);
  }

  async function getBattlecardsByFilter(option) {
    let battlecards;

    if (option.value === GLOBAL) {
      battlecards = await getBattlecards(GLOBAL);
    } else if (option.label === MY_PERSONAL_CARDS) {
      battlecards = await getBattlecards(PERSONAL);
    } else if (option.label?.indexOf("Team") !== -1) {
      battlecards = await getBattlecards("TEAM", option.value);
    } else if (option.label === ALL_CARDS) {
      battlecards = await getBattlecards();
    }

    return battlecards;
  }

  function openNewBattleCardForm() {
    setShowCreateBattleCardForm(true);
    setBattlecard(null);
  }

  function hideNewBattleCardForm() {
    setShowCreateBattleCardForm(false);
  }

  function openEditBattleCardForm(editBattlecard) {
    setShowCreateBattleCardForm(true);
    setBattlecard(editBattlecard);
  }

  async function removeBattlecard(card) {
    props.setLoading(true);
    await deleteBattlecard(card.id);
    const { battlecardCategories } = removeExistingCard(props.pageData.battlecards.cards, card);
    props.setBattlecards(battlecardCategories);
    props.setLoading(false);
  }

  function addOrUpdateBattlecard(updateBattlecard, wasEdit) {
    updateBattlecard.htmlContent = xss(updateBattlecard.htmlContent);
    props.setLoading(true);
    const battlecardCategories = updateBattlecardCategoriesData(
      updateBattlecard,
      props.pageData.battlecards.cards,
      wasEdit,
    );
    props.setBattlecards(battlecardCategories);
    props.setLoading(false);
  }

  async function downloadWingman() {
    props.setLoading(true);
    const link = await getLinkForLatestWingmanDownloadableFile(window.navigator.platform);
    window.open(DESKTOP_APP_S3_BUCKET + link, "_self");
    props.setLoading(false);
  }

  async function handleTeamFilterChange(option) {
    props.setLoading(true);
    setTeamFilter(option);
    await fetchBattlecards(option);
    props.setLoading(false);
  }

  return (
    <div>
      <div className="wingman__header px-5">
        <div className="wingman__floatingContainer">
          <div className="teamFilter">
            <Label>SHOW ONLY:</Label>
            <div className="select">
              <Select options={options} value={teamFilter} placeholder="Select Team" onChange={handleTeamFilterChange} />
            </div>
          </div>
          <Button
            className="h-10"
            onClick={openNewBattleCardForm}
          >
            {"Add Battle Card"}
          </Button>
        </div>
      </div>
      {props.pageData.battlecards.cards.length !== 0 && (
        <BattleCardsContainer
          openEditBattleCardForm={openEditBattleCardForm}
          removeBattlecard={removeBattlecard}
          battlecardCategories={props.pageData.battlecards.cards}
        />
      )}
      {/* No battlecards component */}
      {(props.pageData.battlecards.cards.length === 0 || Object.keys(props.pageData?.battlecards?.cards)?.length == 0) && (
        <div className="flex justify-center items-center flex-col mb-10">
          <br />
          <img src={noDataImage} className="PrivateCall__Graphic" />
          <br />
          <Text2XL>No Battle Cards</Text2XL>
          <p>
            You can add a battle card by clicking the button above.
          </p>
        </div>
      )}
      {props.pageData.battlecards.cards.length !== 0 && (
        <Button className="mx-auto h-10 mb-2" variant="secondary" onClick={downloadWingman}>
          {`Download Wingman for ${os}`}
        </Button>
      )}
      <BattlecardFormModal
        key={battlecard === null ? "create" : battlecard.id + battlecard.category}
        open={showCreateBattleCardForm}
        battlecard={battlecard}
        addOrUpdateBattlecard={addOrUpdateBattlecard}
        removeBattlecard={removeBattlecard}
        hide={hideNewBattleCardForm}
        allCategories={props.screen.metaData.battlecardCategories}
      />
    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { setLoading, setMetaData, setBattlecards },
)(BattleCards);

const BattleCardsContainer = props => {
  return (
    <div>
      {Object.keys(props.battlecardCategories).map((battlecardCategory, i) => {
        return (
          <BattleCardCategory
            openEditBattleCardForm={props.openEditBattleCardForm}
            key={i}
            removeBattlecard={props.removeBattlecard}
            categoryName={battlecardCategory}
            cards={props.battlecardCategories[battlecardCategory]}
          />
        );
      })}
    </div>
  );
};

export const BattleCardGrid = props => {
  return (
    <div className="wingman__battlecardGrid">
      {props.battlecards.length === 0 && (
        <div className="wingman__battlecardGrid-sampleCardDiv">
          <BattleCard
            key={"Empty_one"}
            data={{
              "triggers": [{
                keyword: "When Rep/Customer says:  'Word or Phrase'"
              }], "listContent": ["Say this", "Say that"]
            }}
            editCard={() => { }}
          />
          <div className="wingman__battlecardGrid-copyDiv">
            <span className="wingman__battlecardGrid-copy">{"---> "}<b>Triggers:</b> A word or a phrase that when said during a call shows the card to the rep.</span>
            <span className="wingman__battlecardGrid-copy" style={{ marginTop: "30px" }}>{"---> "}<b>Talking Points:</b> Points that your rep can use to effectively handle the trigger.</span>
          </div>
        </div>
      )
      }
      {
        props.battlecards.map((battlecard, i) => {
          return (
            <BattleCard
              key={i}
              removeBattlecard={() => props.removeBattlecard(battlecard)}
              data={battlecard}
              editCard={() => props.openEditBattleCardForm(battlecard)}
            />
          );
        })
      }
    </div >
  );
};
