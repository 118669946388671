import React, { useState, useEffect } from 'react'
import NotificationsIcon from "app/styles/assets/images/icons/notifications.svg";
import NewNotificationsIcon from "app/styles/assets/images/icons/newNotifications.svg";
import { useSelector, useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { withRouter } from 'react-router-dom';
import { usePrevious } from '../../utils/hooks/usePrevious';
import NotificationsBody from './NotificationsBody';
import { fetchDataSafely } from '../../utils/dataUtils';
import { SET_ONE_TIME_OPEN } from '../../actions/actionTypes';
import classNames from 'classnames';

const Notifications = ({ className }) => {
	const showNotificationsOneTime = useSelector(store => fetchDataSafely(store, "persisted.oneTimeOpen.notificationsCenter", false));
	const [showNotificationModal, setShowNotificationModal] = useState(false);
	const notificationsNew = useSelector(store => store.screen.notificationsNew);
	const prevNotificationsNew = usePrevious(notificationsNew);
	const [shakeNotificationBell, setShakeNotificationBell] = useState(false);
	const dispatch = useDispatch();

	const hideNotificationModal = () => setShowNotificationModal(false);

	useEffect(() => {
		if (!prevNotificationsNew && notificationsNew) {
			if (!showNotificationsOneTime) {
				setShowNotificationModal(true);
				dispatch({ type: SET_ONE_TIME_OPEN, oneTimeOpen: { notificationsCenter: true } });
			}
			setShakeNotificationBell(true);
			setTimeout(() => { setShakeNotificationBell(false) }, 3000);
		}
	}, [notificationsNew]);

	return (
		<div className={classNames("NotificationsContainer", className)}>
			<OutsideClickHandler onOutsideClick={() => { setShowNotificationModal(false) }}>
				<div style={{ cursor: "pointer" }}>
					<img className={`${shakeNotificationBell ? "NotificationsIconShake" : ""} NotificationsContainer__icon`} onClick={() => { setShowNotificationModal(showNotificationModal => !showNotificationModal) }} src={notificationsNew ? NewNotificationsIcon : NotificationsIcon} />
				</div>

				{showNotificationModal && <div className="NotificationsContainer__modal">
					<NotificationsBody hideNotificationModal={hideNotificationModal} />
				</div>
				}
			</OutsideClickHandler>
		</div >
	);
}
Notifications.propTypes = {}
Notifications.defaultProps = {}
export default Notifications;