import {
    SET_GAMETAPES_FOLDER_DATA,
    SET_GAMETAPES_GAMETAPES_DATA,
    SET_GAMETAPES_ALL_FOLDER_DATA,
    SET_GAMETAPES_ALL_GAMETAPES_DATA,
    SET_GAMETAPES_FOLDER_CHILDREN_DATA,
    SET_GAMETAPES_GAMETAPES_CHILDREN_DATA,
    SET_GAMETAPES_TAGS_DATA,
    SET_CREATE_FOLDER_MODAL_OPEN
} from "app/actions/actionTypes"

const initialState = {
    foldersList: [],
    gametapesList: [],
    cachedFoldersData: {},
    cachedGametapesData: {},
    cachedFoldersChildrenData: {},
    cachedGametapesChildrenData: {},
    tagsList: [],
    modal: {
        isCreateFolderModalOpen: false,
    }
}

export default function (state = initialState, payload) {
    switch (payload.type) {
        case SET_GAMETAPES_FOLDER_DATA:
            return {
                ...state,
                cachedFoldersData: {
                    ...state.cachedFoldersData,
                    [payload.folderId]: payload.data
                }
            };
        case SET_GAMETAPES_GAMETAPES_DATA:
            return {
                ...state,
                cachedGametapesData: {
                    ...state.cachedGametapesData,
                    [payload.gametapeId]: payload.data
                }
            };
        case SET_GAMETAPES_ALL_FOLDER_DATA:
            return {
                ...state,
                foldersList: payload.folders,
            };
        case SET_GAMETAPES_ALL_GAMETAPES_DATA:
            return {
                ...state,
                gametapesList: payload.gametapes,
            };
        case SET_GAMETAPES_FOLDER_CHILDREN_DATA:
            return {
                ...state,
                cachedFoldersChildrenData: {
                    ...state.cachedFoldersChildrenData,
                    [payload.folderId]: payload.data
                }
            };
        case SET_GAMETAPES_GAMETAPES_CHILDREN_DATA:
            return {
                ...state,
                cachedGametapesChildrenData: {
                    ...state.cachedGametapesChildrenData,
                    [payload.folderId]: payload.data
                }
            };
        case SET_GAMETAPES_TAGS_DATA:
            return {
                ...state,
                tagsList: payload.tags,
            };
        case SET_CREATE_FOLDER_MODAL_OPEN:
            return {
                ...state,
                modal: {
                    ...state.modal,
                    isCreateFolderModalOpen: payload.isOpen
                }
            };
        default:
            return state;
    }
}