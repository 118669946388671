import { useEffect, useState } from "react";
import { Input, Button } from "../form";
import { addPhrase, getAllPhrasesForCustomer } from '../../utils/network';
import ThumbsUpIcon from "app/styles/assets/images/icons/thumbsup.svg";
import ErrorIcon from "app/styles/assets/images/icons/erroricon.svg";
import CloseIcon from "app/styles/assets/images/icons/closeCircle.svg";
import { options } from "../settings/Category";

const TeamDashboardAddCompetitorPopup = ({ onCancel }) => {
    const [success, setSuccess] = useState(0)
    return (
        <div className="flex flex-col px-8 py-6 mt-2 justify-center rounded-lg !bg-white shadow-lg border border-gray-100">
            {success === 0 && <AddCompetitor setSuccess={setSuccess} />}
            {success === 1 && <SuccessMessage />}
            {success === -1 && <ErrorMessage setSuccess={setSuccess} />}
            <img
                className="absolute top-0.5 -right-1.5 w-6 cursor-pointer"
                onClick={onCancel}
                src={CloseIcon}
            />
        </div >
    )
}

export default TeamDashboardAddCompetitorPopup;

const AddCompetitor = ({ setSuccess }) => {

    const [loading, setLoading] = useState(false)
    const [inputText, setInputText] = useState("")
    const [error, setError] = useState(null)
    const [category, setCategory] = useState(options["ALL"].value)

    async function setAppliesToCategory() {
        const phrases = await getAllPhrasesForCustomer()
        const competitors = phrases.filter(phrase =>
            phrase.category === "Competitors" &&
            phrase.text !== "__EMPTY_CATEGORY_PLACEHOLDER__"
        );
        if (competitors.length > 0 && competitors[0].appliesTo)
            setCategory(competitors[0].appliesTo)
    }

    useEffect(() => {
        setAppliesToCategory()
    }, [])


    async function addCompetitor() {
        if (inputText.trim().length > 0) {
            setLoading(true)
            const response = await addPhrase(inputText, "Competitors", true, category)
            response.error ? setSuccess(-1) : setSuccess(1)
        }
        else
            setError("Competitor name can't be blank")
        setInputText("")
        setLoading(false)
    }

    return (
        <>
            <label className="pl-1 my-3 font-medium">Add Competitor</label>
            <Input
                placeholder="Enter competitor name"
                value={inputText}
                onChange={e => {
                    setError(null);
                    setInputText(e.target.value)
                }}
            />
            {error && <span className="Error__tag">{error}</span>}
            <Button
                className="ml-auto py-6"
                type="small"
                theme="dark"
                onClick={addCompetitor}
                loading={loading}
            >
                Submit
            </Button>
        </>)
}

const Message = ({ icon, title, subtitle, setSuccess, buttonText, isErrorMessage }) => {
    return (
        <div className="flex flex-col items-center">
            <img className="w-12 py-2" src={icon} />
            <label className="pb-3 pl-1 text-lg font-medium">{title}</label>
            <p className="pb-3 pl-1 text-sm text-center text-gray-600">
                {subtitle}
            </p>
            {isErrorMessage && <Button
                className="py-1"
                type="small"
                theme="dark"
                onClick={() => { setSuccess(0) }}
            >
                {buttonText}
            </Button>}
        </div>
    )
}

const SuccessMessage = () => {
    return (
        <Message
            icon={ThumbsUpIcon}
            title="Competitor Added"
            subtitle="This competitor will start getting tracked in all your future calls."
        />
    )
}

const ErrorMessage = ({ setSuccess }) => {
    return (
        <Message
            icon={ErrorIcon}
            title="Competitior was not added"
            subtitle="There was an error due to a network issue. Contact Wingman if issue persists."
            setSuccess={setSuccess}
            buttonText="Try Again"
            isErrorMessage
        />
    )
}