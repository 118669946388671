import React, { useState } from "react";
import { Radio } from "@material-ui/core";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { Button } from "app/designSystem";
import { Input } from 'app/designSystem/form/';
import { connect, useSelector } from "react-redux";
import { refreshUserAction } from "app/actions/auth";
import { sendRequest } from "../../utils/network";
import LabelWithExplanation from "../common/LabelWithExplanation";
import SelectWithSearchNew from "../filter/SelectWithSearchNew";
import { CONSENT_PAGES_EXPLANATION } from "app/constants";
import CompanyLogoEditor from "./CompanyLogoEditor";


const RecorderComplianceSettings = ({ disabled }) => {
  const auth = useSelector((store) => store.auth);
  const customer = useSelector((store) => store.auth.stringsCustomer);
  const { setSnackbar } = useSnackbar();
  const [announcementEnabledType, setAnnouncementEnabledType] = useState(customer?.settings?.announcementEnabledType ?? "NONE");
  const [announcementEnabled, setAnnouncementEnabled] = useState(announcementEnabledType == "NONE" ? false : true);
  const [announcementFrequency, setAnnouncementFrequency] = useState(customer?.settings?.announcementFrequency ?? "EVERY");
  const [audioText, setAudioText] = useState(customer?.settings?.customAudioText ?? "This call is getting recorded by Wingman for quality and training purposes.");
  const [editingAudioText, setEditingAudioText] = useState(false);
  const [enableRecordingConsentPage, setEnableRecordingConsentPage] = useState(customer?.featuresGated?.recordingConsentPage ?? false);
  const [consentPageEnabledTeams, setConsentPageEnabledTeams] = useState(customer?.consentPageConfig?.managerIds?.length ? customer?.consentPageConfig?.managerIds : ["All teams"]);
  const [consentPageEnabled, setConsentPageEnabled] = useState(customer?.consentPageConfig?.enabled ?? false);
  const { managers } = useSelector((store) => store.screen.metaData);
  const [recordingDisclaimer, setRecordingDisclaimer] = useState(
    customer?.consentPageConfig?.recordingDisclaimer ??
    "This meeting will be recorded by Wingman for note-taking and training. Just let the host know if you’d like a copy of the recording."
  );
  const [savingRecordingDisclaimer, setSavingRecordingDisclaimer] = useState(false);
  const [editingRecordingDisclaimer, setEditingRecordingDisclaimer] = useState(false);
  const [savingAudioText, setSavingAudioText] = useState(false);
  const integrationFlows = customer?.integrationFlows;

  async function handleAnnouncementEnabledTypeChange(type) {
    setAnnouncementEnabledType(type);
    if (type === "NONE") {
      setAnnouncementEnabled(false);
    } else {
      setAnnouncementEnabled(true);
    }

    const params = new URLSearchParams();
    params.append("announcementEnabled", type);
    await sendRequest("/admin/change_announcement_enabled_type", params);
    refreshUserAction();
    setSnackbar("Changes Saved", "SUCCESS");
  }

  async function handleAnnouncementFrequencyChange(freq) {
    setAnnouncementFrequency(freq);
    const params = new URLSearchParams();
    params.append("announcementFrequency", freq);
    await sendRequest("/admin/save_announcement_frequency", params);
    refreshUserAction();
    setSnackbar("Changes Saved", "SUCCESS");
  }

  function updateAudiotext(event) {
    const newvalue = event.target.value;
    setAudioText(newvalue);
  }

  function handleAudioTextButtonClick() {
    if (!editingAudioText) {
      setEditingAudioText(true);
    } else {
      setEditingAudioText(false);
      handleTextSave();
    }
  }

  async function handleTextSave() {
    const params = new URLSearchParams();
    setSavingAudioText(true);
    params.append("audioText", audioText);
    await sendRequest("/admin/audio_Text", params);
    refreshUserAction();
    setSavingAudioText(false);
    setSnackbar("Changes Saved", "SUCCESS");
  }

  async function handleConsentPageEnabled(temp) {
    const params = new URLSearchParams();
    params.append("consentPageEnabled", !consentPageEnabled);
    if (temp) {
      setConsentPageEnabled(true);
    } else {
      setConsentPageEnabled(false);
    }
    await sendRequest("/admin/set_consent_page_enabled", params);
    refreshUserAction();
    setSnackbar("Changes Saved", "SUCCESS");
  }

  async function onChangeTeam(teamIds) {
    if (teamIds) {
      let managerIds = teamIds.map((teamId) => {
        return teamId.value;
      });
      if (managerIds.includes("All teams")) {
        setConsentPageEnabledTeams(["All teams"]);

        managerIds = [];
      } else {
        setConsentPageEnabledTeams(managerIds);
      }

      const params = new URLSearchParams();
      params.append("consentPageEnabledList", managerIds);

      await sendRequest("/admin/set_manager_ids_for_consent_page", params);
      refreshUserAction();
      setSnackbar("Changes Saved", "SUCCESS");
    }
  }

  function handleRecordingDisclaimer(event) {
    const info = event.target.value;
    setRecordingDisclaimer(info);
  }

  function handleRecordingDisclaimerButtonClick() {
    if (!editingRecordingDisclaimer) {
      setEditingRecordingDisclaimer(true);
    } else {
      setEditingRecordingDisclaimer(false);
      handleRecordingDisclaimerSave();
    }
  }

  async function handleRecordingDisclaimerSave() {
    const params = new URLSearchParams();
    setSavingRecordingDisclaimer(true);
    params.append("recordingDisclaimer", recordingDisclaimer);
    await sendRequest("/admin/save_recording_disclaimer", params);
    setSavingRecordingDisclaimer(false);
    setSnackbar("Changes Saved", "SUCCESS");
  }

  return (
    <div>
      {customer.featuresGated?.recordingConsentAudio && (
        <div style={{ marginTop: "16px" }}>
          <span className="Settings__label">Recording Consent via Audio</span>
          <div style={{ marginLeft: "20px" }}>
            <span className="Settings__label">Play recording consent announcement for:</span>
            <div className="noMargin row horCon">
              <Radio
                color="primary"
                checked={announcementEnabledType == "INTERNAL"}
                onChange={() => {
                  handleAnnouncementEnabledTypeChange("INTERNAL");
                }}
                disabled={disabled}
              />
              <span className="Settings__notifications-type"> Meetings organized by a recording user</span>
            </div>
            <div className="noMargin row horCon">
              <Radio
                color="primary"
                checked={announcementEnabledType == "ALL"}
                onChange={() => {
                  handleAnnouncementEnabledTypeChange("ALL");
                }}
                disabled={disabled}
              />
              <span className="Settings__notifications-type"> Any meeting that is being recorded</span>
            </div>
            <div className="noMargin row horCon">
              <Radio
                color="primary"
                checked={announcementEnabledType == "NONE"}
                onChange={() => {
                  handleAnnouncementEnabledTypeChange("NONE");
                }}
                disabled={disabled}
              />
              <span className="Settings__notifications-type"> None </span>
            </div>
          </div>

          {announcementEnabled && (
            <div style={{ marginLeft: "20px" }}>
              <span className="Settings__label">When should we play the recording consent announcement?</span>

              <div className="noMargin row horCon">
                <Radio color="primary" checked={announcementFrequency == "EVERY"} onChange={() => handleAnnouncementFrequencyChange("EVERY")} disabled={disabled} />
                <span className="Settings__notifications-type">Every-time an external participant joins </span>
              </div>
              <div className="noMargin row horCon">
                <Radio color="primary" checked={announcementFrequency == "FIRST"} onChange={() => handleAnnouncementFrequencyChange("FIRST")} disabled={disabled} />
                <span className="Settings__notifications-type">Only when the first external participant joins </span>
              </div>

              <span className="Settings__label" style={{ marginTop: "15px" }}>
                Customize announcement:{" "}
              </span>
              <div style={{ width: "60%", margin: "5px 0px 10px 0px" }}>
                <Input
                  placeholder="Type the audio announcement to be played."
                  type="text"
                  value={audioText}
                  onChange={updateAudiotext}
                  id="audioText"
                  disabled={!editingAudioText || !announcementEnabled}
                  size={"big"}
                  disabled={disabled}
                />
              </div>
              <Button type="small" theme="light" onClick={() => handleAudioTextButtonClick()} disabled={!announcementEnabled} loading={savingAudioText} disabled={disabled}>
                {editingAudioText ? "Save" : "Edit"}
              </Button>
            </div>
          )}
        </div>
      )}

      {integrationFlows.googleCalendarAuthFlow &&
        <>
          <hr className="mt-4" />
          {enableRecordingConsentPage && (
            <div style={{ marginTop: "16px" }}>
              <div className="flex flex-col items-start">
                <LabelWithExplanation
                  labelClass="Settings__label"
                  label="Recording Consent via Consent page"
                  tooltipHeading="How does this work?"
                  explanation={CONSENT_PAGES_EXPLANATION}
                />
                <span className="-mt-1 Settings__notifications-typeExplanation text-coolGray-500" >{"Wingman’s consent page ensures all your call recordings are compliant. "}
                  <a className="underline" target="_blank" href="https://support.trywingman.com/en/articles/5882254?utmSource=inAppResyncNudge&utmCampaign=mandatoryConsentPageEnabled&utmMedium=webApp">See how it works.</a>
                </span>
              </div>
              <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                <span className="Settings__label">Enable recording consent via Consent Page</span>

                <div className="noMargin row horCon">
                  <Radio color="primary" checked={consentPageEnabled} onChange={() => handleConsentPageEnabled(true)} disabled={disabled} />
                  <span className="Settings__notifications-type">Yes </span>
                </div>
                <div className="noMargin row horCon">
                  <Radio color="primary" checked={!consentPageEnabled} onChange={() => handleConsentPageEnabled(false)} disabled={disabled} />
                  <span className="Settings__notifications-type">No </span>
                </div>
              </div>
            </div>
          )}

          {consentPageEnabled && enableRecordingConsentPage && (
            <div className="flex-row flex items-center">
              <span className="Settings__label" style={{ marginLeft: "20px" }}>
                Enable recording consent for
              </span>
              <div className="Filter__fieldContainer" style={{ width: "50%", marginLeft: "10px" }}>
                <span className="max-w-1/2">
                  <SelectWithSearchNew
                    key="team"
                    fieldName="agentIds"
                    data={[{ value: "All teams", label: "All Teams" }, ...managers]}
                    value={consentPageEnabledTeams}
                    label="Select Team"
                    placeholder="Type manager name"
                    handleChange={(teamIds) => onChangeTeam(teamIds)}
                    dontShowCloseIcon={true}
                    position="right"
                    size="small"
                    disabled={disabled}
                  />
                </span>
              </div>
            </div>
          )}

          {consentPageEnabled && enableRecordingConsentPage && (
            <div className="ml-5">
              <span className="Settings__label">Customise Consent Page </span>
              <div style={{ marginLeft: "20px" }}>
                <span className="Settings__label">Recording Disclaimer</span>
                <div style={{ width: "60%", margin: "5px 0px 10px 0px" }}>
                  <Input type="text" value={recordingDisclaimer} onChange={handleRecordingDisclaimer} id="audioText" disabled={!editingRecordingDisclaimer} rowsize={3} />
                </div>
                <Button className="h-8" variant="secondary" onClick={() => handleRecordingDisclaimerButtonClick()} loading={savingRecordingDisclaimer} disabled={disabled}>
                  {editingRecordingDisclaimer ? "Save" : "Edit"}
                </Button>
                <div style={{ alignItems: "center" }}>
                  <span className="Settings__label" style={{ marginTop: "15px", display: "block" }}>
                    Upload company logo here
                  </span>
                  <CompanyLogoEditor auth={auth} disabled={disabled} />
                </div>
              </div>
            </div>
          )}
        </>}
    </div>
  );
};

RecorderComplianceSettings.propTypes = {};
RecorderComplianceSettings.defaultProps = {};
export default connect((props) => props, { refreshUserAction })(RecorderComplianceSettings);
