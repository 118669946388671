import Select, { components } from "react-select";
import { Input } from "app/designSystem/form";
import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { customStyles, customTheme, formatOptionLabel } from "./helper"; //Temp, will be moved to Design system when select box wrapper over react-select built for new design
import { TextSM, TextBase, Button, CloseIcon, DragHandlerIcon } from "app/designSystem";

const ScorecardQuestion = ({ question, index, updateQuestion, addNewSkill, deleteQuestion, skillOptions, showDeleteQuestionIcon }) => {
    const [isMenuFocused, setIsMenuFocused] = useState(false)
    const blurHandler = function (e) {
        if (!e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.contains(e.relatedTarget)) {
            setIsMenuFocused(false);
        }
    }
    return (
        <div className='flex mb-6 items-center' >
            <DragHandlerIcon className='mr-2 mt-3 h-8 w-8' />
            <Input
                label={`Question ${index + 1} `}
                value={question.label}
                onChange={(e) => { updateQuestion({ ...question, label: e.target.value }) }}
                type="text"
                placeholder="e.g.  Did the rep schedule the next steps ?"
                className="w-7/12  flex-shrink-0"
                error={question.invalidLabel ? "Question cannot be empty " : ""}
            />
            <div className='w-5/12 ml-6 flex'>
                <div className='flex-shrink-0 flex-grow'>
                    <TextBase className="mb-1" textColor='text-coolGray-500'>Skill</TextBase>
                    <Select
                        currentQuestionIndex={index}
                        id={question.label}
                        options={skillOptions}
                        value={{ label: question.skill, value: question.skill }}
                        formatOptionLabel={formatOptionLabel}
                        placeholder="Select or create a skill"
                        onChange={(option) => { updateQuestion({ ...question, skill: option.value }) }}
                        components={{ MenuList: MenuListHandler }}
                        styles={customStyles}
                        theme={customTheme}
                        onBlur={blurHandler}
                        menuPlacement={"auto"}
                        menuIsOpen={isMenuFocused}
                        onFocus={() => { setIsMenuFocused(true) }}
                        blurInputOnSelect={true}
                        setIsMenuFocused={setIsMenuFocused}
                        addNewSkill={(val) => { addNewSkill(val); setIsMenuFocused(false); }}
                    />
                    {
                        question.invalidSkill && <TextSM textColor="text-red-600" className="ml-1 mt-1">Skill is required</TextSM>
                    }
                </div>
                {
                    showDeleteQuestionIcon &&
                    <div className='flex justify-end ml-2   flex-shrink-0 items-start'>

                        <button className="p-1 mt-8 hover:bg-coolGray-50 rounded-lg" onClick={deleteQuestion} ><CloseIcon />
                        </button>

                    </div>

                }
            </div>

        </div>)
}

const MenuListHandler = ({ ...props }) => {
    return (
        <>
            <TextSM className="ml-2 pr-6 py-2">Select a skill from dropdown</TextSM>
            <components.MenuList  {...props}> {props.children} </components.MenuList>
            <CustomAddSkill
                addNewSkill={(val) => props.selectProps.addNewSkill(val)}
                blurHandler={props.selectProps.onBlur}
            />
        </>
    )
}

const CustomAddSkill = ({ addNewSkill, blurHandler }) => {

    const [inputValue, setInputValue] = useState("");
    onkeyup = (e => {
        if (e.key === "Enter") {
            if (inputValue.trim() !== "") {
                addNewSkill(inputValue);
                setInputValue("");
            }
        }
    })

    return (
        <div className='flex row center px-2 mb-2 py-2.5 mx-2 rounded bg-blueGray-50'
            onKeyDown={(e) => { e.stopPropagation() }}
            onMouseDown={(e) => { e.stopPropagation() }}
            onBlur={blurHandler}
        >
            <Input
                id="AddSkill"
                className="flex-shrink mr-3.5 flex-grow"
                placeholder="Create a new skill"
                type="text"
                value={inputValue}
                onChange={(e) => { setInputValue(e.currentTarget.value); }}

            />
            <Button variant='primary' 
                onClick={() => {
                    if (inputValue.trim() !== "") {
                        addNewSkill(inputValue);
                        setInputValue("");
                    }
                }}>Add</Button>
        </div>
    )
}

export default ScorecardQuestion;