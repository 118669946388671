import React, { useMemo } from 'react';
import { _formatDateToDealActivityDateFormat } from 'app/utils/helpers';

const ActivityDates = ({ date }) => {

    const shownDate = useMemo(() => {
        let shownDate;
        let today = new Date();

        today.setHours(0, 0, 0, 0);

        if (today.getTime() == date.getTime()) {
            shownDate = ("Today")
        }
        else if ((today.getDate() - date.getDate()) % 7 == 0) {
            shownDate = _formatDateToDealActivityDateFormat(date)
        }

        return shownDate;
    }, [date]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {shownDate !== undefined && (
                <div className={"DealCard__dateMark" + (shownDate == "Today" ? " DealCard__dateMarkToday" : "")}>|</div>
            )}
            <div className={"DealCard__timelineDates" + (shownDate == "Today" ? " DealCard__timelineDatesToday" : "")}>
                {shownDate}
            </div>
        </div >
    );
}


ActivityDates.propTypes = {}
ActivityDates.defaultProps = {}
export default React.memo(ActivityDates);