import Skeleton from 'react-loading-skeleton';

export default function GameTapeInformationPanelSkeleton() {
    return (
        <div className="flex flex-col">
            <div className="mb-4">
                <Skeleton height={40} width={150} />
            </div>
            <div className="mb-4">
                <Skeleton height={80} />
            </div>
            <div className="mb-0">
                <Skeleton height={40} width={200} />
            </div>
        </div>
    )
}