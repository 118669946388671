import React from 'react';
import { useState, useEffect } from 'react';
import { _getActivitySize, _findBarHeight, _hasActivityOrNot } from '../../utils/ActivityHelper';
import IncomingMailIcon from "app/styles/assets/images/icons/dealView/incomingMailsBarIcon.svg";
import OutgoingMailIcon from "app/styles/assets/images/icons/dealView/outgoingMailsBarIcon.svg";
import CallsIcon from "app/styles/assets/images/icons/dealView/callsBarIcon.svg";

const ActivityBar = ({ propId, activityForOneDay, barSize }) => {
    const [activityCheck, setActivityCheck] = useState({});

    useEffect(() => {
        setActivityCheck(getActivityCheck());
    }, [activityForOneDay]);

    function getActivityCheck() {
        return ({
            calls: _hasActivityOrNot(activityForOneDay.calls),
            incomingEmails: _hasActivityOrNot(activityForOneDay.incomingEmails),
            outgoingEmails: _hasActivityOrNot(activityForOneDay.outgoingEmails)
        })
    }

    return (
        <div id={propId} style={{ display: "flex" }}>
            <div className={"DealCard__activityBar" + (barSize === "Big" ? " DealCard__activityBar__Big" : "")}>
                <div className="DealCard__activityBar__segment">
                    {activityCheck.incomingEmails && <img className="DealCard__activityBar__icon" src={IncomingMailIcon} />}
                </div>
                <div className="DealCard__activityBar__segment">
                    {activityCheck.calls && <img className="DealCard__activityBar__icon" src={CallsIcon} />}
                </div>
                <div className="DealCard__activityBar__segment">
                    {activityCheck.outgoingEmails && <img className="DealCard__activityBar__icon" src={OutgoingMailIcon} />}
                </div>
            </div>
        </div >
    );
}


ActivityBar.propTypes = {}
ActivityBar.defaultProps = {
    barSize: ""
}
export default React.memo(ActivityBar);