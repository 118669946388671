import React from "react";
import { getLinkForLatestWingmanDownloadableFile } from "app/utils/network";
import { DESKTOP_APP_S3_BUCKET } from "app/config";
import { Button } from "app/designSystem";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { getClientOS } from "app/utils/helpers";

const Downloads = () => {
	const os = getClientOS();
	const { setSnackbar, hideSnackbar } = useSnackbar();

	async function downloadWingman() {
		setSnackbar("Downloading...", "LOADING");
		const link = await getLinkForLatestWingmanDownloadableFile(window.navigator.platform);
		window.open(DESKTOP_APP_S3_BUCKET + link, "_self");
		hideSnackbar();
	}

	function openWingmanMobileAppPage() {
		window.open("https://www.trywingman.com/mobileapp?utmSource=webApp&utmCampaign=downloadsPage&utmMedium=downloadCTAButton", "_blank");
	}

	return (
		<div className="Settings__crmContainer">
			<span className="Settings__header">Downloads</span>
			<span className="Settings__label">Desktop App</span>
			<span className="Settings__explanation text-coolGray-500">Wingman desktop app is built for receiving real time coaching prompts like Cue Cards, Monologue Alerts etc.</span>
			<div style={{ display: "flex", margin: "10px 0px" }}>
				<Button className="h-10" onClick={downloadWingman}>
					{`Download Wingman Desktop App for ${os}`}
				</Button>
			</div>
			<span className="Settings__label">Mobile App</span>
			<span className="Settings__explanation text-coolGray-500">Access your sales call library, call summaries, coaching and upcoming meetings on the go with Wingman mobile on iPhone and Android.</span>
			<div style={{ display: "flex", margin: "10px 0px" }}>
				<Button className="h-10" onClick={openWingmanMobileAppPage}>
					{`Download Wingman Mobile App`}
				</Button>
			</div>
		</div>
	);
};

export default Downloads;
