import React from "react";
import PropTypes from "prop-types";
import noData from "app/styles/assets/images/icons/noData.svg";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { Button } from "../form";
import { useHistory } from "react-router-dom";
const NoSuchCallFound = ({ details }) => {
  const { getUserName } = useDataHelper();
  const history = useHistory();

  function goBack() {
    history.goBack();
  }

  return (
    <div className="PrivateCall">
      <img src={noData} className="PrivateCall__Graphic" />
      <span className="PrivateCall__header">No Such Call Found.</span>
      <Button theme="dark" onClick={goBack}>
        Go back
      </Button>
    </div>
  );
};
NoSuchCallFound.propTypes = {};
NoSuchCallFound.defaultProps = {};
export default NoSuchCallFound;
