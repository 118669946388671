import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { pipedriveCallback, oneTimePipedriveSync } from "app/utils/network";
import PipedriveLogo from "app/styles/assets/images/onboarding/PipedriveLogo.png";
import { API_URL, PIPEDRIVE_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncPipedrive = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync() {
    setLoading(false);
    props.refreshUserAction();
  }

  function sync() {
    setLoading(true);
    let location = "https://app.trywingman.com/pipedriveInstallation";
    if (API_URL.includes("dev")) {
      location = "https://dev.trywingman.com/pipedriveInstallation";
    }
    const url = `https://oauth.pipedrive.com/oauth/authorize?client_id=${PIPEDRIVE_CLIENT_ID}&redirect_uri=${location}`;
    openWindowAndListenForParam(url, "Pipe drive Integration", "integrations", completeSync);
  }

  return (
    <IntegrationCard
      icon={PipedriveLogo}
      heading="Pipedrive"
      bodyText="Sync pipedrive to link all CRM info."
      subHeading="CRM Integrations"
      sync={sync}
      loading={loading}
      crm="PIPEDRIVE"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncPipedrive.defaultProps = {};

SyncPipedrive.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncPipedrive);
