import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { oneTimeRingcentralSync, crmOAuthCallback } from "app/utils/network";
import RingcentralLogo from "app/styles/assets/images/onboarding/RingcentralLogo.png";
import { RINGCENTRAL_BASE_URL, RINGCENTRAL_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncRingcentral = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync(code) {
    const userId = props.auth.data.user.userId || {};
    await crmOAuthCallback(code, 'RINGCENTRAL');
    setLoading(false);
    oneTimeRingcentralSync(userId);
    props.refreshUserAction();
  }

  function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const url = `${RINGCENTRAL_BASE_URL}/restapi/oauth/authorize?client_id=${RINGCENTRAL_CLIENT_ID}&response_type=code&redirect_uri=${location}`;
    openWindowAndListenForParam(url, "Ringcentral Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={RingcentralLogo}
      heading="Ringcentral"
      subHeading="Dialer Integrations"
      bodyText="Sync Ringcentral to download call recordings."
      sync={sync}
      loading={loading}
      dialer="RINGCENTRAL"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncRingcentral.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

SyncRingcentral.defaultProps = {
  access: "user",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncRingcentral);
