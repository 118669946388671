import React from "react";
import { Player as VideoPlayer, ControlBar, LoadingSpinner, BigPlayButton, Shortcut } from 'video-react';

const VideoPlayerWrapper = React.forwardRef(({ src, poster }, ref) => (

    <VideoPlayer
        ref={ref}
        height="100%"
        width="100%"
        fluid={false}
        aspectRatio="16:9"
        poster={poster}
        autoPlay
    >
        <source src={src} />
        <ControlBar disableCompletely />
        <LoadingSpinner />
        <BigPlayButton className="hidden" position="center" />
        <Shortcut clickable={true} dblclickable={false} />
    </VideoPlayer>
));

VideoPlayerWrapper.displayName = 'VideoPlayerWrapper';

export default VideoPlayerWrapper;