import React, { useState, useRef, useEffect } from 'react'
import { InputBase } from "@material-ui/core";
import { SearchIcon, CloseIcon } from "app/designSystem";

const MoreFiltersSearch = ({ filterFields, setFilterFields }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const ref = useRef(null);

    //to get the focus in the input box
    useEffect(() => {
        if (ref.current) {
            ref.current.focus();
            ref.current.autofocus = true;
        }
    })

    function getFieldsBySearch(value) {
        let copyFilterFields = { ...filterFields }
        // for multiple words in the input separated by whitespace
        const words = value.trim().split(" ")
        for (let category in copyFilterFields) {
            copyFilterFields[category] = copyFilterFields[category].filter(field => {
                let count = 0;
                for (const word of words) {
                    if (field.label.toLowerCase().includes(word.toLowerCase()))
                        count++;
                }
                return count == words.length
            })
        }
        return copyFilterFields
    }

    function handleSearchTermChange(e) {
        let value = e.target.value || "";
        setSearchTerm(value);
        setFilterFields(getFieldsBySearch(value))
    }

    function clearQuery() {
        setSearchTerm("")
        setFilterFields(filterFields)
    }

    return (
        <div className="w-11/12 pl-2 m-2 bg-white border border-gray-300 rounded-md">
            <div className="flex items-center py-0.5">
                <SearchIcon
                    className="h-5 w-5 mx-2 mb-0.5 text-wingman-brand"
                />
                <InputBase
                    id="SearchFilter"
                    className="flex-grow ml-1.5 !text-base !text-coolGray-900 !placeholder-coolGray-600"
                    inputRef={ref}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search Filter"
                    autoComplete='off' //autocomplete suggestions block the filters displayed
                />
                <CloseIcon
                    className="h-5 w-5 mx-2 mb-0.5 cursor-pointer"
                    onClick={clearQuery}
                />
            </div>
        </div>
    )
}

export default MoreFiltersSearch