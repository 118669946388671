import React from "react";
import { PropTypes } from "prop-types";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import { SPaper } from "app/components/common";
import valueUp from "app/styles/assets/images/icons/value-up.png";
import valueDown from "app/styles/assets/images/icons/value-down.png";
import { _roundUp } from "app/utils/helpers";

/**
 * return color to draws charts
 * @param {string} color the color, e.g: purple, green, blue
 */
const getChartColor = color => {
  switch (color) {
    case "purple":
      return "#4A1058";
    case "blue":
      return "#00BECD";
    case "green":
      return "#54C282";
    default:
      return color;
  }
};


const CustomChart = props => { 
  return (
    <div className="Chart">
      <div className="Chart__head">
        <div className="Chart__head-avg">
          <span
            className="Chart__head-avg-number"
            style={{ color: getChartColor(props.color) }}
          >
            {props.avg}
          </span>
          <span
            className="Chart__head-avg-string"
            style={{ color: getChartColor(props.color) }}
          >
            Average
          </span>
        </div>
        {props.changeRate!==Infinity && <div>
          <div className="Chart__head-change">
            <span className="Chart__head-change-percent">
              {Math.abs(props.changeRate)}%
            </span>
            <img
              src={props.changeRate >= 0 ? valueUp : valueDown}
              className="Chart__valueIcon"
              alt="value change"
            />
          </div>
        </div>
      }
      </div>
      {props.children}
      <div className="Chart__hidden">
        <div className="Chart__hidden-top-line" />
      </div>
      <ResponsiveContainer
        className="Chart__body"
        width="100%"
        height={props.height || 190}
      >
        <AreaChart data={props.data} >
          <CartesianGrid
            strokeDasharray="0"
            vertical={props.verticalGrid}
            horizontal={props.horizontalGrid}
          />
          <XAxis
            dataKey={props.xDataKey}
            label={props.xLabel}
            interval={"preserveStartEnd"}
            minTickGap={props.xMinTickGap}
            padding={{
              left: props.xPadding.left || 24,
              right: props.xPadding.right || 24,
            }}
          />
          <YAxis
            padding={{ top: 24 }}
            width={65}
            tickCount={props.yTickCount}
            domain={props.yDomain}
            allowDecimals={props.yAllowDecimals}
            axisLine={props.yAxisLine}
            tickLine={props.yAxisLine}
            tickFormatter={props.yTickFormatter}
            label={props.yLabel}
          />
          {props.data.length==0 &&
            <div className="row-centered">
                <h4>No Data Available</h4>
            </div>
          }
          {props.avgLine && (
            <ReferenceLine
              y={props.avgLine.avg || 0}
              stroke="red"
              strokeDasharray="15"
              label={{
                position: "insideBottomRight",
                value: props.avgLine.label || "Team Average",
              }}
            />
          )}
          <Tooltip content={CustomTooltip} unit={props.unit} />
          <Area
            dot={
              props.stroke
                ? {
                    stroke: getChartColor(props.color),
                    strokeWidth: 2,
                    r: props.dotRadius,
                    fill: "#FFFFFF",
                    fillOpacity: 1,
                  }
                : null
            }
            activeDot={
              props.stroke
                ? {
                    strokeWidth: 2,
                    r: props.dotRadius,
                  }
                : null
            }
            type="linear"
            dataKey={props.fxDataKey}
            stroke={props.stroke ? getChartColor(props.color) : null}
            strokeWidth={2}
            fill={getChartColor(props.color)}
            fillOpacity={0.2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};


const CustomTooltip = props => {
  if(props.payload && props.payload.length > 0){
    return (
      <SPaper>{_roundUp(props.payload[0].value,100)}{' '+props.unit}</SPaper>
    )
  }else{
    return null
  }
}

CustomChart.propTypes = {
  color: PropTypes.string,
  avg: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changeRate: PropTypes.number,
  verticalGrid: PropTypes.bool,
  horizontalGrid: PropTypes.bool,
  yTickCount: PropTypes.number,
  yDomain: PropTypes.array,
  xDataKey: PropTypes.string,
  fxDataKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  yAllowDecimals: PropTypes.bool,
  avgLine: PropTypes.object,
  stroke: PropTypes.bool,
  yAxisLine: PropTypes.bool,
  height: PropTypes.number,
  yTickFormatter: PropTypes.func,
  xPadding: PropTypes.object,
  dotRadius: PropTypes.number,
  children: PropTypes.node,
  xLabel: PropTypes.string,
  yLabel: PropTypes.string,
  xMinTickGap: PropTypes.number,
};

CustomChart.defaultProps = {
  color: "purple",
  avg: 0,
  changeRate: 0,
  verticalGrid: false,
  horizontalGrid: false,
  yTickCount: 3,
  xDataKey: "x",
  fxDataKey: "fx",
  yDomain: [0, "auto"],
  yAllowDecimals: false,
  avgLine: null,
  stroke: true,
  yAxisLine: true,
  height: null,
  yTickFormatter: null,
  xPadding: {},
  dotRadius: 7,
  children: null,
  xLabel: '',
  yLabel: '',
  xMinTickGap: 10,
};

export default CustomChart;
