import React from 'react'
import { sendRequest } from '../../utils/network';
import { _formatDateToMinsBefore } from '../../utils/helpers';
import { TimeColumn } from '../callTable';
import CommentIcon from "app/styles/assets/images/icons/comment.svg";
import CommentNotification from './CommentNotification';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';
import CallViewedIcon from "app/styles/assets/images/icons/notifications/CallViewed.svg";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { TextSM, TextBase } from 'app/designSystem';

const ClubbedNotification = ({ clubbedNotifications, callId, type, last, markAsRead }) => {

	const [expandClubbedNotifications, setExpandClubbedNotifications] = useState(false);
	const history = useHistory();

	function handleClick() {
		clubbedNotifications.map(notification => {
			const params = new URLSearchParams();
			params.append("id", notification.id);
			sendRequest("/notifications/mark_as_read", params);
			markAsRead(notification.id);
		});
		if (clubbedNotifications && clubbedNotifications.length > 0 && clubbedNotifications[0].notificationType === "ACCOUNT_COMMENT") {
			history.push("/account/" + callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications");
		} else if (clubbedNotifications && clubbedNotifications.length > 0 && clubbedNotifications[0].notificationType === "GAMETAPE") {
			history.push("/gametapes/folder/" + callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications");
		} else {
			history.push("/call/" + callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications");
		}
	}

	function getMostRecentComment() {
		return clubbedNotifications[0];
	}

	function handleToggle() {
		setExpandClubbedNotifications(expandClubbedNotifications => !expandClubbedNotifications);
	}

	function getClubbedTitle() {
		if (clubbedNotifications && clubbedNotifications.length > 0) {
			switch (clubbedNotifications[0].notificationType) {
				case "COMMENT":
					return clubbedNotifications.length + " comments posted"
				case "GAMETAPE":
					return clubbedNotifications.length + " gametapes added"
				default:
					return clubbedNotifications.length + " views on your shared call"
			}
		}
		return "NO";
	}

	function getClubbedIcon() {
		if (clubbedNotifications && clubbedNotifications.length > 0 && clubbedNotifications[0].notificationType === "COMMENT")
			return CommentIcon;
		return CallViewedIcon;

	}

	function getType() {
		if (clubbedNotifications && clubbedNotifications.length > 0)
			return clubbedNotifications[0].notificationType;
	}

	if (clubbedNotifications.length === 0) return null;

	return (
		<div className={`CommentNotification ${type === "large" ? "CommentNotificationLarge" : ""} ${getMostRecentComment().read ? "" : " CommentNotification__unread"}`}>
			{type === "large" && <TimeColumn call={{ time: getMostRecentComment().createdDate }} />}
			<div className={`CommentNotification__iconContainer  ${last ? "" : "CommentNotification__iconShowLine"}`}>
				<img className={`CommentNotification__icon`} style={{ height: type === "large" ? "50px" : "35px" }} src={getClubbedIcon()} />
			</div>
			<div className="CommentNotification__main">
				<div onClick={handleClick} className="CommentNotification__header">
					<div className="CommentNotification__titleContainer">
						<TextBase textColor="text-coolGray-900">{getClubbedTitle()}</TextBase>
						<div className='flex mt-1 items-center'>
							<TextSM className=" mr-1" textColor='text-coolGray-400'>{"on "}</TextSM>
							<TextBase textColor='text-brand-blue-3'>{getMostRecentComment().callTitle}</TextBase>
						</div>
					</div>
					{type !== "large" && <TextSM textColor='text-coolGray-400' className="ml-auto ">{_formatDateToMinsBefore(getMostRecentComment().createdDate)}</TextSM>}
				</div>
				{type === "large" &&
					<ExpansionPanel className="ClubbedNotification__commentsExpansion" expanded={expandClubbedNotifications} onChange={handleToggle}>
						<ExpansionPanelSummary className="ClubbedNotification__commentsExpansionSummary" expandIcon={<ExpandMoreIcon />}>
							View {getType() === "COMMENT" ? "Comments" : "All"}
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className="ClubbedNotification__commentsExpansionDetails">
							{clubbedNotifications.map((notification, i) => {
								return <CommentNotification clubbedView type="large" notification={notification} markAsRead={() => { markAsRead(notification.id) }} />
							})}
						</ExpansionPanelDetails>
					</ExpansionPanel>
				}
			</div>
		</div>
	);
}
ClubbedNotification.propTypes = {}
ClubbedNotification.defaultProps = {
	type: "small",
}
export default ClubbedNotification;