import React, { useState } from "react";
import { getTriggersAsCSV } from "./helper";
import MenuIcon from "@material-ui/icons/MoreVert";
import { CustomDropdown, ConfirmationDialog } from "../common";
import { ProfileDropDownMenuItem } from "../layout/ProfileDropdown";
import ReactTooltip from "react-tooltip";
import FooterButton from "../call/comments/FooterButton";
import EditComment from "app/styles/assets/images/icons/comments/EditComment.svg";
import EditCommentHover from "app/styles/assets/images/icons/comments/EditCommentHover.svg";
import DeleteComment from "app/styles/assets/images/icons/comments/DeleteComment.svg";
import DeleteCommentHover from "app/styles/assets/images/icons/comments/DeleteCommentHover.svg";
import moment from "moment";
import { useSelector } from "react-redux";

const CardHeader = ({ data, editCard, removeBattlecard }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { triggers } = data;

  const userNames = useSelector(store => store.screen.metaData.userNames);

  function handleDeleteConfirmation() {
    removeBattlecard();
    setShowDeleteConfirmation(false);
  }

  return (
    <div className={`${data.publishStatus === "DRAFT" ? "BattleCard__header-draft" : "BattleCard__header-published"} BattleCard__header`}>
      {data.publishStatus === "DRAFT" && <span className="BattleCard__header-draft-tag">&nbsp;</span>}
      <div className="BattleCard__header-title">
        <span className="BattleCard__header-copy">When someone says: </span>
        <span className="BattleCard__header-title-text">{triggers.slice(0, 2).map(t => "'" + t.keyword + "'").join(", ")}
          {triggers.length >= 3 &&
            <>
              <span className="BattleCard__header-copy" data-tip data-for={data.id}> + {triggers.length - 2} more</span>
              <ReactTooltip id={data.id} effect="solid" className="InfoTooltip">
                <span style={{ textTransform: "capitalize" }}>{triggers.slice(2).map(t => "'" + t.keyword + "'").join(", ")}</span>
              </ReactTooltip>
            </>
          }
        </span>
        {(data.lastUpdated || userNames.find(user => user.value === data.lastUpdatedBy)) && <span className="BattleCard__header-info BattleCard__header-hover">Last edited {data.lastUpdated ? "on " + moment(data.lastUpdated).format("MMM DD, YYYY hh:mm A") : ""} {userNames.find(user => user.value === data.lastUpdatedBy) ? "by " + userNames.find(user => user.value === data.lastUpdatedBy).label : ""}</span>}
      </div>
      <div className="BattleCard__header_menu">
        <CustomDropdown bubbleClass="ProfileDropdown" right={0} title={<MenuIcon />} list={
          [
            <FooterButton
              id={data.id + "edit"}
              onClick={editCard}
              buttonLabel="Edit"
              icon={EditComment}
              hoverIcon={EditCommentHover}
              className="BattleCard__menuButton"
            />
            ,
            <FooterButton
              id={data.id + "delete"}
              onClick={() => setShowDeleteConfirmation(true)}
              buttonLabel="Delete"
              hoverColor="#D63649"
              icon={DeleteComment}
              className="BattleCard__menuButton"
              hoverIcon={DeleteCommentHover}
            />
          ]} />
      </div>
      <ConfirmationDialog
        open={showDeleteConfirmation}
        title="Delete Battle Card"
        message="Are you sure you want to delete this Battle Card?"
        yesLabel="Delete Card"
        noLabel="Cancel"
        onOk={handleDeleteConfirmation}
        onClose={() => { setShowDeleteConfirmation(false) }} />
      <div />
    </div>
  );
}

export default CardHeader;
