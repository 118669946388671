import React, { useState } from "react";
import {
  Button,
  TextSM,
  Text2XL,
  WingmanLogoWithName,
} from "app/designSystem";
import { useDispatch, useSelector } from "react-redux";
import { sendGetRequest } from "app/utils/network";
import { useEffect } from "react";
import { logout } from "app/actions/auth";
import { Loading } from "app/components/common";
import { DEEP_LINK_PROTOCOL_URL } from "app/constants";

function DekstopLoginPage() {
  const email = useSelector((store) => store.auth.data.user.emailId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function fetchAuthKey() {
    setLoading(true);
    const response = await sendGetRequest("/auth/getToken");
    window.location.href = `${DEEP_LINK_PROTOCOL_URL}login?&authKey=${response?.message}&email=${email}`;
    setLoading(false);
  };

  useEffect(() => {
    fetchAuthKey();
  }, []);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center w-screen h-screen bg-white overflow-hidden">
      <div className="absolute top-4 left-4 ">
        <WingmanLogoWithName className="w-44" />
      </div>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <div className="text-center">
            <Text2XL className="!font-normal ">Login as</Text2XL>
            <Text2XL>{email}</Text2XL>
          </div>
          <Button onClick={fetchAuthKey} className="mt-6 w-80 !rounded-md">
            Open the Dekstop app
          </Button>
          <div className="text-center mt-6">
            <TextSM>Not {email}?</TextSM>
            <TextSM
              className="text-wingman-brand cursor-pointer"
              onClick={() => dispatch(logout())}
            >
              Login with another account
            </TextSM>
          </div>
        </>
      )}
    </div>
  );
}

export default DekstopLoginPage;
