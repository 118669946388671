import React, { useEffect } from "react";
import ShareCallForm from "app/components/player/ShareCallForm";
import { ReactComponent as ShareIcon } from "app/designSystem/icons/ShareIcon.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_SHOW_SHARE_CALL_MODAL } from "../../actions/actionTypes";
import call from "../../reducers/call";
import ReactTooltip from "react-tooltip"
import { Button } from "app/designSystem";
const tooltipid = "SHARE_CALL_ICON"

const ShareCallButtonContainer = ({ disabled }) => {
  const screen = useSelector(store => store.screen);
  const { videoAvailable, videoProcessed } = useSelector(store => store.call);
  const conversationTurn = useSelector(store => store?.call?.details?.callData?.conversationTurn ?? []);
  const [openModal, setOpenModal] = useState(screen.showShareCallModal);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenModal(screen.showShareCallModal);
  }, [screen.showShareCallModal]);


  function handleSetOpenModal(bool) {
    dispatch({ type: SET_SHOW_SHARE_CALL_MODAL, showShareCallModal: bool });
    setOpenModal(bool);
  }

  if (conversationTurn.length == 0) return null;

  return (
    <>
      <Button variant="secondary" startIcon={<ShareIcon />} className={`mx-1 ${disabled ? "NewButton__disabled" : ''}`} onClick={!disabled ? () => handleSetOpenModal(true) : () => { }} disabled={disabled}>
        Share
      </Button>
      {openModal &&
        <ShareCallForm videoAvailable={videoAvailable} videoProcessed={videoProcessed} open={openModal} closeModal={() => handleSetOpenModal(false)} />}
      {disabled &&
        <ReactTooltip id={tooltipid} type="light" place="bottom" effect="solid" className="WhiteTooltip">
          <span className="WhiteTooltip__mainCopy">Share Call</span>
          <span className="WhiteTooltip__subCopy">You don't have the permissions required to share this call.</span>
        </ReactTooltip>}
    </>


  );
}

export default ShareCallButtonContainer;
