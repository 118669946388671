import React from "react";
import { Button } from "app/designSystem";
import { CloseIcon } from "app/designSystem";
import classNames from "classnames";
import PropTypes from "prop-types";


export const NotificationNudge = ({ Svg, children }) => {

    return (
        <div className="bg-orange-50 mt-6 p-2  flex flex-row items-center text-sm">
            <div className="p-1 rounded-full bg-orange-100">
                <Svg />
            </div>
            <div className="ml-2">
                {children}
            </div>
        </div>

    );
};


export const NotificationWithButton = ({ onClose, handleButtonClickAction, variant, loading, children, buttonClassName, buttonText }) => {
    return (
        <div
            className={classNames(
                "w-full px-3 py-2",
                {
                    "bg-green-50": variant === "green",
                },
                {
                    "bg-orange-50": variant === "orange",
                },
            )}
            style={{ transition: "height 1s" }}
        >
            <div className="relative">
                <CloseIcon
                    className="absolute right-0 top-0 cursor-pointer text-slate-500"
                    onClick={onClose}
                />
                {children}
            </div>

            <div className="flex mt-4">
                <Button
                    variant="secondary"
                    className={buttonClassName}
                    onClick={handleButtonClickAction}
                    loading={loading}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

NotificationWithButton.propTypes = {
    loading: PropTypes.bool,
    variant: PropTypes.string
};

NotificationWithButton.defaultProps = {
    loading: false,
    variant: "orange"
};
