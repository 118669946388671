import React from "react";
import RepIcon from "app/styles/assets/images/onboarding/rep.svg";
import ManagerIcon from "app/styles/assets/images/onboarding/manager.svg";
import ObserverIcon from "app/styles/assets/images/onboarding/observer.svg";

const RoleCard = props => {
  return (
    <div
      className={props.selected ? "RoleCard__activeContainer" : "RoleCard__outerContainer"}
      onClick={props.onSelect}
    >
      <div className="RoleCard__header">
        <img className="RoleCard__icon" src={roles[props.type].icon} alt={props.type} />
      </div>
      <div className="RoleCard__body">
        <span className="RoleCard__body_role">{props.type}</span>
        <span className="RoleCard__body_explain">{roles[props.type].explanation}</span>
      </div>
    </div>
  );
};

const roles = {
  REP: {
    explanation: "I call customers and would like my calls recorded, transcribed and analyzed.",
    icon: RepIcon,
  },
  MANAGER: { explanation: "I manage a team of reps.", icon: ManagerIcon },
  OBSERVER: {
    explanation:
      "I am neither, but would just like to review some calls. Observer accounts are free!",
    icon: ObserverIcon,
  },
};

RoleCard.propTypes = {};

export default RoleCard;
