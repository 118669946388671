import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux';
import { findIndex } from "lodash";
import { Portal } from 'react-portal';
import ReactTooltip from "react-tooltip";
import UserIcon from "@material-ui/icons/Person";

const ProfileImage = ({ userId, size, overlap, className, email, disableTooltip, caller, tooltipPosition ,style}) => {
	const profileImages = useSelector(store => store.screen.metaData.profileImages);
	const userNames = useSelector(store => store.screen.metaData.userNames);
	const loggedInUserId = useSelector(store => store.auth.data.user.userId);
	const lastUpdatedTime = useSelector(store => store.auth.lastUpdatedTime);

	function getName(userId) {
		var index = findIndex(userNames, function (o) { return o.value == userId; });
		if (index === -1) return email;
		var name = userNames[index].label;
		return name;
	}

	if (profileImages && profileImages[userId])
		return (
			<div className={className} style={style}>
				<img data-tip data-for={"profileImage" + caller + userId} src={profileImages[userId] + (userId === loggedInUserId ? "?" + lastUpdatedTime : "")} style={{ height: size + "px", width: size + "px", borderRadius: (size / 2) + "px", border: (caller == "attendeeIcon" ? "solid 2px #ffffff" : "") }} />
				{!disableTooltip && 
				<Portal>
					<ReactTooltip id={"profileImage" + caller + userId} effect="solid" place={tooltipPosition}>
						<span>{getName(userId)}</span>
					</ReactTooltip>
				</Portal>
				}
			</div>
		)
	else {
		if (getInitials(userId) !== null)
			return (
				<div className={className} style={style}>
					<div data-tip data-for={"profileImage" + userId} className={`ProfileImage__Avatar ${overlap ? 'ProfileImage__overlap' : ''}`} style={{ fontSize: (size * 0.4) + "px", height: size + "px", width: size + "px", borderRadius: (size / 2) + "px", border: (caller == "attendeeIcon" ? "solid 2px #ffffff" : "") }}>
						{getInitials(userId)}
					</div>
					{!disableTooltip && getName(userId) !== "" && 
					<Portal>
						<ReactTooltip id={"profileImage" + userId} effect="solid" place={tooltipPosition}>
							<span>{getName(userId)}</span>
						</ReactTooltip>
					</Portal>}
					
				</div>
			);
		else
			return (
				<div className={className} style={style}>
					<div data-tip data-for={"profileImage" + userId} className={`ProfileImage__Avatar ${overlap ? 'ProfileImage__overlap' : ''}`} style={{ fontSize: (size * 0.4) + "px", height: size + "px", width: size + "px", borderRadius: (size / 2) + "px", border: (caller == "attendeeIcon" ? "solid 2px #ffffff" : "") }}>
						<UserIcon style={{ "fontSize": size - 5 + "px" }} color="secondary" />
					</div>
					{!disableTooltip && getName(userId) !== "" && 
					<Portal>
					<ReactTooltip id={"profileImage" + userId} effect="solid" place={tooltipPosition}>
						<span>{getName(userId)}</span>
					</ReactTooltip>
					</Portal>}
					
				</div>
			);
	}
}

ProfileImage.propTypes = {}
ProfileImage.defaultProps = {
	size: 20,
	className: "",
	email: "",
	caller: "",
	tooltipPosition: "top"
}
export default ProfileImage

export function getInitials(userId, userNames) {
	var index = findIndex(userNames, function (o) { return o.value == userId; });
	if (index === -1) return null;
	var name = userNames[index].label;
	return name.split(" ").map(word => word[0]).join("");
}


