import React from "react";
import longestMonologueIcon from "app/styles/assets/images/icons/longestMonologue.svg";
import { _formatSecondsToPlayingTime } from "app/utils/helpers";

const LongestMonologueColumn = props => {
	const durationText = _formatSecondsToPlayingTime(props.duration);
	return (
		<div className="CallTable__durationContainer">
			<img className="CallTable__timeIcon" src={longestMonologueIcon} alt="icon" />
			<span className="CallTable__durationContainer_text">{durationText}</span>
		</div>
	);
};

export default LongestMonologueColumn;
