import React, { useRef, useState } from "react";
import { Modal } from "@material-ui/core";
import { joinCall } from "../../utils/network";
import { useEffect } from "react";
import { cleanCommaSeperatedStrings, validateStringsOfEmails } from '../../utils/helpers'
import ReactTooltip from "react-tooltip";
import { Button } from "app/designSystem"
import { Input } from "app/designSystem/form"
import { TextLarge } from 'app/designSystem/typography/Typography';
import { JoinCallIllustration } from "app/designSystem/illustrations";
import { CloseIcon } from 'app/designSystem/icons';

const AdhocCallJoiningForm = ({ open, hide }) => {
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showSuccessfulJoinMessage, setShowSuccessfulJoinMessage] = useState(false);

  const inputRef = useRef(null);

  function isUrl(text) {
    return /http[s]?:\/\//.test(text);
  }

  function setShowTooltipUrlCopied(showTooltipUrlCopied) {
    if (inputRef && inputRef.current) {
      if (showTooltipUrlCopied) {
        ReactTooltip.show(inputRef.current);
      } else {
        ReactTooltip.hide(inputRef.current);
      }
    }
  }

  async function readUrlFromClipboard() {
    const clipboardContent = await navigator.clipboard
      .readText()
      .catch(console.log);
    if (clipboardContent === undefined) return;
    if (isUrl(clipboardContent)) {
      setUrl(clipboardContent);
      setShowTooltipUrlCopied(true);
      setTimeout(() => setShowTooltipUrlCopied(false), 2000);
    } else {
      console.log("Clipboard content is not url", clipboardContent);
      setUrl("");
    }
  }

  useEffect(() => {
    if (open) readUrlFromClipboard();
  }, [open]);

  async function joinAdhocCall() {
    let filteredEmail = cleanCommaSeperatedStrings(email);
    setEmail(filteredEmail);

    if (url === "") {
      setError("Url can't be empty");
      return;
    }
    if (filteredEmail != "" && !validateStringsOfEmails(filteredEmail)) {
      setError("Please enter valid email id");
      return;
    }
    setError("");
    setLoading(true);
    const response = await joinCall(url, filteredEmail, title);
    if (response && response.error) {
      setError(response.message);
      setLoading(false);
    } else {
      resetForm();
      setShowSuccessfulJoinMessage(true);
    }
    return response;
  }

  function resetForm() {
    setError("");
    setLoading(false);
    setUrl("");
    setEmail("");
    setTitle("");
    setShowSuccessfulJoinMessage(false);
  }

  function handleClose() {
    resetForm();
    hide();
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose} className="Form__outerContainer">
        <div
          className="Form__container rounded-lg p-0"
          style={{
            maxHeight: "calc(100vh - 6.25rem)",
            width: "657px",
            outline: "none"
          }}
        >
          {showSuccessfulJoinMessage && (
            <div className="px-8 py-4">
              <div className="flex justify-end w-full">
                <button onClick={handleClose}>
                  <CloseIcon />
                </button>
              </div>
              <SucessfullyJoinedMessage />
            </div>
          )}
          {!showSuccessfulJoinMessage && (
            <>
              <div className="px-8 py-4">
                <div className="flex justify-between w-full">
                  <TextLarge> Join My Meeting </TextLarge>
                  <button onClick={handleClose}>
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <hr />
              <div className="px-8 py-4">
                <div
                  ref={inputRef}
                  data-tip
                  data-for={"adhocMeetingUrl"}
                  className="Form__verCon"
                >
                  <Input
                    label="Meeting Url"
                    value={url}
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                    type="text"
                    className="mb-4"
                    placeholder="https://zoom.us/j/123456789"
                  />
                  <ReactTooltip
                    /*random  event id used to disable tooltip*/
                    event={"sjd"}
                    place="right"
                    id={"adhocMeetingUrl"}
                    effect="solid"
                  >
                    Meeting Url Copied!
                  </ReactTooltip>
                </div>
                <div className="Form__verCon">
                  <Input
                    type="text"
                    label="Meeting attendees"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="mb-4"
                    placeholder="rep@mydomain.com,rep@micro.com"
                  />
                </div>
                <div className="Form__verCon">
                  <Input
                    type="text"
                    label="Meeting Title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    placeholder="Demo meeting"
                  />
                </div>
                {error !== "" && (
                  <span className="Form__errorMessage">
                    {error}
                    <br />
                    {error !== "Please enter valid email id" &&
                      "Contact us on intercom or email if the problem continues."}
                  </span>
                )}
                <div className="Form__footerContainer">
                  <div className="Form__footerContainer rightButton">
                    <Button
                      variant="secondary"
                      textColor="#686868"
                      onClick={handleClose}
                      className="mr-3 w-28"
                    >
                      {"Cancel"}
                    </Button>
                    <Button
                      loading={loading}
                      onClick={joinAdhocCall}
                      className="w-44"
                    >
                      {"Join Meeting"}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

const SucessfullyJoinedMessage = () => (
  <div className="AdhocCall__successContainer">
    <JoinCallIllustration className="h-56 mb-4" />
    <TextLarge textColor="text-wingman-brand">
      Wingman is trying to join your call.
      <br /> Please wait for a couple of minutes.
    </TextLarge>
  </div>
);

export default AdhocCallJoiningForm;
