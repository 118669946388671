import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHoverListener } from 'app/utils/hooks/useHoverListener';
import { CircularProgress } from '@material-ui/core';

const FooterButton = ({ id, icon, hoverIcon, color, hoverColor, buttonLabel, onClick, className, loading }) => {
  const [hovering, setHovering] = useState(false);

  useHoverListener(
    "Comment_" + id + "_" + buttonLabel,
    () => setHovering(true),
    () => setHovering(false)
  );

  return (
    <div id={"Comment_" + id + "_" + buttonLabel} onClick={onClick} className={`${className} row-tcentered`}>
      {!loading && <img src={hovering ? hoverIcon : icon} className="icon" alt={""} />}
      {loading && <CircularProgress color="primary" size={10} />}
      <span className="label" style={hovering ? { color: hoverColor } : { color }}>{buttonLabel}</span>

    </div>
  );
};

FooterButton.propTypes = {
  id: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  hoverIcon: PropTypes.object.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClick: PropTypes.func,
};

FooterButton.defaultProps = {
  color: "#8798AD",
  hoverColor: "#2E384D",
  buttonLabel: "click",
  className: "Comment__footerButton",
  onClick: () => { },
  loading: false,
}

export default FooterButton;