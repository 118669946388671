import React from "react";
import { CustomLink } from "app/components/common";
import { PropTypes } from "prop-types";
import { Button } from "app/components/form";

const SyncFlowContents = props => (
  <React.Fragment>
    <div
      className={
        props.transparent
          ? "SyncFlowContents__outerContainerTransparent"
          : "SyncFlowContents__outerContainer"
      }
    >
      <div className="SyncFlowContents__iconContainer">
        <img className={props.iconClass} src={props.icon} alt="Logo" />
      </div>
      <div className="SyncFlowContents__textContainer">
        <span className="SyncFlowContents__heading">{props.heading}</span>
        <span className="SyncFlowContents__subheading">{props.subheading}</span>
        <div className="SyncFlowContents__buttonContainer">
          {!props.synced && (
            <Button  theme="dark" onClick={props.clickHandler}>
              {props.buttonLabel}
            </Button>
          )}
          {props.synced && (
            <CustomLink onClick={props.unsync}>{props.unsyncButtonLabel}</CustomLink>
          )}
          {props.error && (
            <div className="errorDiv">
              <span className="errorLabel">Error</span>
              <span className="errorMessage">{props.errorMessage}</span>
              <span className="errorInst">
                {"Incase the problem persists, please use the chat widget to contact us."}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  </React.Fragment>
);

SyncFlowContents.defaultProps = {
  buttonLabel: "Sync",
  unsyncButtonLabel: "Unsync",
  transparent: false,
  synced: false,
  error: false,
  errorMessage: "",
};

SyncFlowContents.propTypes = {
  heading: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  unsyncButtonLabel: PropTypes.string,
  synced: PropTypes.bool,
  transparent: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default SyncFlowContents;
