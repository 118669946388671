import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchDataSafely } from '../../utils/dataUtils';
import { sendRequest } from '../../utils/network';
import { refreshUserAction } from "app/actions/auth";
import { connect } from "react-redux";


const NotificationType = ({ type, id, copy, admin, slack, refreshUserAction, disabled, feature }) => {
	const userDoc = useSelector(store => store.auth.data.user);
	const [checked, setChecked] = useState(false);
	const auth = useSelector((store) => store.auth);
	//Pls add the new feature name to this array and use checkFeaturesGated function to get the value for new features which need notification settings.
	const gatedFeatures = ["scorecard"]

	function checkFeaturesGated(feature) {
		if (feature === "scorecard") {
			return auth.stringsCustomer?.featuresGated?.scorecard || false;
		}
	}
	function handlePreferenceChange(e) {
		const value = e.target.checked;
		setChecked(value);
		const params = new URLSearchParams();
		params.append("notificationType", id);
		params.append("channel", slack ? "SLACK" : "EMAIL");
		params.append("enable", value);
		if (admin) {
			sendRequest("/admin/set_notification_preference", params)
		} else {
			sendRequest("/user/set_notification_preference", params)
		}
		refreshUserAction();
	}

	useEffect(() => {
		if (admin) {
			if (slack) {
				setChecked(fetchDataSafely(userDoc, "stringsCustomer.slackNotificationPreferences", {})[id] || false);
			} else {
				setChecked(fetchDataSafely(userDoc, "stringsCustomer.emailNotificationPreferences", {})[id] || false);
			}
		} else {
			if (slack) {
				setChecked(fetchDataSafely(userDoc, "slackNotificationPreferences", {})[id] || false);
			} else {
				console.log("in here---", slack, id, fetchDataSafely(userDoc, "emailNotificationPreferences", {})[id]);
				setChecked(fetchDataSafely(userDoc, "emailNotificationPreferences", {})[id] || false);
			}
		}
	}, [])

	return (
		<>
			{((feature && gatedFeatures.includes(feature)) ? checkFeaturesGated(feature) : true) &&
				<div className="Settings__notifications-typeContainer">
					<Checkbox checked={checked} onChange={handlePreferenceChange} disabled={disabled} color="primary" />
					<div className="Settings__notifications-typeContent">
						<span className="Settings__notifications-type">{type}</span>
						<span className="Settings__notifications-typeExplanation text-coolGray-500">{copy}</span>
					</div>
				</div>
			}
		</>
	);
}
NotificationType.propTypes = {}
NotificationType.defaultProps = {
	admin: false,
	slack: false,
}

const mapStateToProps = store => {
	return { ...store };
};

export default connect(
	mapStateToProps,
	{ refreshUserAction },
)(NotificationType);