import React from "react";
import { PropTypes } from "prop-types";

const ThreeDotsLoader = props => {
  return (
  	<div className="spinnerContainer">
	    <div className="spinner" style={{left:props.left, top:props.top}}>
	      <div className="bounce1"></div>
	      <div className="bounce2"></div>
	      <div className="bounce3"></div>
	    </div>
	</div>
  );
};

PropTypes.defaultProps = {
	left : 0,
	top : 0
}

export default ThreeDotsLoader;
