import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { formatOptionLabelForUserDropdowns } from "../../utils/helpers";

const SelectWithSearch = ({
  data,
  value,
  disabled,
  placeholder,
  handleChange,
  menuIsOpen,
  isMulti,
  toUpperCase,
  hasProfilePic,
  ...props
}) => {
  const dataLowercase = transformToLowercase(data)
  const valueLowercase = transformToLowercase(value)

  function transformToLowercase(array) {
    if (array && !array.map)
      array = [array];

    return array && array.map((item) => {
      if (item.label)
        return { ...item, label: toUpperCase ? item.label.toUpperCase() : item.label.toLowerCase() }

      else
        return { ...item, label: "" }
    })
  }


  if (menuIsOpen) {
    return (
      <Select
        value={valueLowercase}
        formatOptionLabel={hasProfilePic ? formatOptionLabelForUserDropdowns : null}
        isMulti={isMulti}
        isDisabled={disabled}
        styles={colourStyles}
        components={makeAnimated()}
        onChange={handleChange}
        placeholder={placeholder}
        options={dataLowercase}
        menuIsOpen={menuIsOpen}
        autoFocus={menuIsOpen}
        className="basic-multi-select"
        classNamePrefix="select"
        {...props}
      />
    );
  }
  return (
    <Select
      value={value}
      formatOptionLabel={hasProfilePic ? formatOptionLabelForUserDropdowns : null}
      isMulti={isMulti}
      isDisabled={disabled}
      styles={colourStyles}
      components={makeAnimated()}
      onChange={handleChange}
      placeholder={placeholder}
      options={data}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};

SelectWithSearch.defaultProps = {
  isMulti: true
}

export default SelectWithSearch;

export const colourStyles = {
  container: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      width: 250,
      zIndex: 6,
    };
  },
  control: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: "white",
      border: "1px solid #E1E5E9",
      borderRadius: "4px",
    };
  },
  menu: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      position: "relative",
      boxShadow: "0px none",
      border: "1px solid #f0f1f2",
      borderRadius: "4px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "#e5f0fd"
          : isFocused
            ? "#e5f0fd"
            : null,
      color: isDisabled ? "#ccc" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
      textTransform: "capitalize",
      zIndex: 100,
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      textTransform: "capitalize",
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#e5f0fd"
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
    textTransform: "capitalize",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#2680EB",
    ":hover": {
      backgroundColor: "#2680EB",
      color: "white"
    }
  })
};
