import React from 'react'
import { sendRequest } from '../../utils/network';
import { useHistory, withRouter } from "react-router-dom";
import { _formatDateToMinsBefore } from '../../utils/helpers';
import ProfileImage from '../common/ProfileImage';
import TimeColumn from '../callTable/TimeColumn';
import CommentIcon from "app/styles/assets/images/icons/comment.svg";
import CallViewedIcon from "app/styles/assets/images/icons/notifications/CallViewed.svg";
import { TextBase, TextSM } from 'app/designSystem';


const CommentNotification = ({ notification, last, markAsRead, type, clubbedView }) => {

	const history = useHistory();

	function makeTagsBlue(comment) {
		return comment.split("@(").map(part => {
			if (part.indexOf(")") !== -1) {
				const tagEndIndex = part.indexOf(")");
				return <span><span style={{ color: "#117DCE" }}>{part.slice(0, tagEndIndex)}</span>{part.slice(tagEndIndex + 1, part.length)}</span>
			} else {
				return part;
			}
		})
	}

	function handleClick() {
		const params = new URLSearchParams();
		params.append("id", notification.id);
		sendRequest("/notifications/mark_as_read", params);
		if (notification.notificationType === "ACCOUNT_COMMENT") {
			history.push("/account/" + notification.callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications");
		} else if (notification.notificationType === "GAMETAPE") {
			history.push("/gametapes/folder/" + notification.callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications");
		} else {
			history.push("/call/" + notification.callId + "?utm_source=notificationsCenter&utm_medium=notifications&utm_campaign=commentNotifications#time=" + (notification.comment && notification.comment.startTime ? notification.comment.startTime : 0));
		}
		markAsRead();
	}

	return (
		<div onClick={handleClick} className={`CommentNotification ${type === "large" ? "CommentNotificationLarge" : ""} ${notification.read ? "" : " CommentNotification__unread"}`}>
			{type === "large" && <TimeColumn call={{ time: notification.createdDate }} />}
			<div className={`CommentNotification__iconContainer  ${last ? "" : "CommentNotification__iconShowLine"}`}>
				<img className={`CommentNotification__icon`} style={{ height: type === "large" ? "50px" : "35px" }} src={notification.notificationType === "COMMENT" ? CommentIcon : CallViewedIcon} />
			</div>
			<div className="CommentNotification__main">
				<div className="flex justify-between">
					{false && <div className="CommentNotification__commentorImage">
						<ProfileImage userId={notification.comment.userId} size={type === "large" ? 35 : 20} />
					</div>}
					<div className="CommentNotification__titleContainer w-11/12 ">
						<TextBase textColor="text-coolGray-900" >{notification.subject}</TextBase>
						{!clubbedView &&
							<div className='flex mt-1 items-center'>
								<TextSM className=" mr-1" textColor='text-coolGray-400'>{"on "}</TextSM>
								<TextBase textColor='text-brand-blue-3'>{notification.callTitle}</TextBase>
							</div>
						}
					</div>
					{type !== "large" && <TextSM textColor='text-coolGray-400' className="ml-auto ">{_formatDateToMinsBefore(notification.createdDate)}</TextSM>}
				</div>
				{notification.comment && notification.comment.comment && <div className="CommentNotification__body">
					<span className="CommentNotification__bodyText">{makeTagsBlue(notification.comment.comment)}</span>
				</div>}
			</div>
		</div>
	);
}
CommentNotification.propTypes = {}
CommentNotification.defaultProps = {
	type: "small",
	clubbedView: false,
}
export default CommentNotification;