import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { object } from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { _formatSecondsToPlayingTime } from "app/utils/helpers";
import { metrics } from "app/utils/metrics";
import PlaybackWaveform from "./PlaybackWaveform";
import PlaybackSpeedMenu from "app/components/gameTapesLatest/player/PlaybackSpeedMenu";
import PlaybackControl from "./PlaybackControl";
import { setActiveTime } from "app/actions/call";
import { setFullScreenVideo } from "app/actions/screen";
import { CardsShown } from "./CardsShown";
import { canThisTurnBePlayed, getRicherSpeakerData, getTurnSpeaker, isCustomerTurn } from "../../reducers/call";
import PlayerSpeakerTimeline from "./PlayerSpeakerTimeline";
import ToggleShowVideo from "./ToggleShowVideo";
import { fetchDataSafely } from "../../utils/dataUtils";
import { SET_SELECTED_SPEAKERS } from "../../actions/actionTypes";
import KeyboardShortcutsWithTrigger from "./KeyboardShortcuts";
import { FullScreenIcon, FullScreenCollapseIcon } from "app/designSystem/icons";

const PlayerController = props => {

  const videoAvailable = fetchDataSafely(props.call, "details.callDataDocument.videoRecordingStatus.version", null) >= 3;
  const dispatch = useDispatch();
  const [timelineWidth, setTimelineWidth] = useState("100%");
  const speakerTimelineRef = useRef(null);
  const jobTitles = useSelector(store => store.screen?.metaData?.jobTitles);
  const speakerNames = useSelector(store => store?.call?.details?.summary?.speakerNames ?? []);
  const channels = useSelector(store => store?.call.details.callData.channel);
  const os = window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";
  const handleKeyEventsCallback = useCallback(handleKeyEvents, [props.rate, props.playing, props.screen.transcriptSelected, props.seek]);

  const resizeObserver = useRef(null)

  // Waveforms has max-width set which equals speakertimeline width , on resizing the window , speaker timeline width changes,so keep observing it for width changes and re sync waveform width 
  useEffect(() => {

    if (speakerTimelineRef?.current) {
      resizeObserver.current = new ResizeObserver(() => {
        setTimelineWidth(speakerTimelineRef.current.clientWidth);
      })
      resizeObserver.current.observe(speakerTimelineRef.current);
    }
    return (() => {
      if (resizeObserver?.current) {
        resizeObserver?.current.disconnect()
      }
    }
    );

  }, [speakerTimelineRef.current])

  useEffect(() => {
    if (speakerTimelineRef.current) {
      console.log("setting timeline to", speakerTimelineRef.current.clientWidth + "px !important");
      setTimelineWidth(speakerTimelineRef.current.clientWidth);
    }
  }, [speakerTimelineRef.current])

  const waveformTurns = useMemo(() => _calculateWaveformFromConversation(
    props.playback.conversationTurn,
    props.playback.duration,
    props.playback.speakers,
    props.playback.channel,
  ), [props.playback.conversationTurn, props.playback.duration, props.playback.speakers, props.playback.channel]);

  const speakerData = useMemo(() => getRicherSpeakerData(
    props.playback.conversationTurn,
    props.playback.speakers,
    fetchDataSafely(props, "call.details.callDataDocument.crmData.contactDocumentList", []),
    props.playback.channel,
    props.isSharedCall
  ), [props.playback.conversationTurn, props.playback.speakers, fetchDataSafely(props, "call.details.callDataDocument.crmData.contactDocumentList", []), props.playback.channel, props.isSharedCall]);

  const showCardsShown = localStorage.getItem("showBattlecardsShown") === "SHOW";

  useEffect(() => {
    return () => {
      console.log("unmounting player setting to true");
      if (props.setPause)
        props.setPause();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEventsCallback);
    return () => {
      document.removeEventListener("keydown", handleKeyEventsCallback);
    }
  }, [handleKeyEventsCallback]);

  function handleKeyEvents(e) {
    let activeElement = null;
    try {
      activeElement = document.activeElement.tagName;
    } catch (error) {

    }

    if (activeElement === "TEXTAREA" || activeElement === "INPUT" || e.ctrlKey || e.shiftKey) return;
    let isCtrlKey = os === 'Windows' ? e.ctrlKey : e.metaKey;
    switch (e.keyCode) {
      case 32:
        e.preventDefault();
        e.stopImmediatePropagation();
        if (props.playing) {
          props.setPause();
        } else {
          props.setPlay();
        }
        break;
      case 37:
        e.preventDefault();
        e.stopImmediatePropagation();
        handleBackwardClick();
        break;
      case 39:
        console.log("here in handle forward click", e.ctrlKey);
        e.preventDefault();
        e.stopImmediatePropagation();
        handleForwardClick();
        break;
      case 38:
        if (props.screen.transcriptSelected) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        let newRate1 = parseFloat(props.rate) + 0.25;
        if (!newRate1 || newRate1 > 2.5) {
          newRate1 = 2.5;
        }
        handleSpeedChange(newRate1);
        break;
      case 40:
        if (props.screen.transcriptSelected) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        let newRate = parseFloat(props.rate) - 0.25;
        if (!newRate || newRate < 0.5) {
          newRate = 0.5;
        }
        handleSpeedChange(newRate);
        break;
      case 75:
        if (props.screen.transcriptSelected || !isCtrlKey) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        handlePlayNextSpeaker();
        break;
      case 74:
        if (props.screen.transcriptSelected || !isCtrlKey) return;
        e.preventDefault();
        e.stopImmediatePropagation();
        handlePlayPreviousSpeaker();
        break;
    }
  }

  function handlePlayNextSpeaker() {
    let conversationTurn = props.playback.conversationTurn;
    let currentTurn;
    for (let i in conversationTurn) {
      if (conversationTurn[i].startTime > props.seek) {
        currentTurn = conversationTurn[i - 1];
      }
      if (currentTurn && conversationTurn[i].speakerId !== currentTurn?.speakerId) {
        props.setSeek(conversationTurn[i].startTime);
        props.setActiveTime(conversationTurn[i].startTime);
        return;
      }
    }
  }

  function handlePlayPreviousSpeaker() {
    let conversationTurn = props.playback.conversationTurn;
    let currentTurn;
    for (let i = conversationTurn.length - 1; i >= 0; i--) {
      if (currentTurn && conversationTurn[i].speakerId !== currentTurn?.speakerId) {
        props.setSeek(conversationTurn[i].startTime);
        props.setActiveTime(conversationTurn[i].startTime);
        return;
      }
      if (conversationTurn[i].startTime <= props.seek) {
        currentTurn = conversationTurn[i];
      }
    }
  }

  // Calculate width of the running bar
  function _calculateBarWidth(currentPlayingTime, callDurationSeconds) {
    let width = 0;
    if (!callDurationSeconds) return width;
    width = Math.min((currentPlayingTime * 100) / callDurationSeconds, 100);
    return width;
  }

  // Calculate waveform width and position for display
  function _calculateWaveformFromConversation(conversationTurn, callDurationSeconds, speakers, channel) {
    const waveformTurns = [];
    if (!callDurationSeconds || !conversationTurn) return waveformTurns;
    conversationTurn.forEach(turn => {
      const waveformTurn = {};
      waveformTurn.id = turn.turnId;
      waveformTurn.speaker = getTurnSpeaker(turn, speakers);
      waveformTurn.type = isCustomerTurn(turn, speakers, channel) ? "opportunity" : "agent";
      waveformTurn.left = (turn.startTime * 100) / callDurationSeconds;
      waveformTurn.width = ((turn.endTime - turn.startTime) * 100) / callDurationSeconds;
      if (waveformTurn.width < 0.3508) {
        /**
         * if start time equal the end time, ther width of waveform would be 1px
         * (0.351 percent this this case)
         */
        waveformTurn.width = 0.3508;
      }
      if (waveformTurn.width > 100) waveformTurn.width = 100;
      waveformTurns.push(waveformTurn);
    });

    const smoothenedWaveforms = [];
    const last = waveformTurns.reduce((prev, curr) => {
      if (prev.speaker !== curr.speaker || ((curr.left - (prev.left + prev.width)) > 5)) {
        smoothenedWaveforms.push(prev);
        return curr;
      } else {
        prev.width += curr.width;
        return prev;
      }
    })
    smoothenedWaveforms.push(last);
    return smoothenedWaveforms;
  }

  // Handle move the cursor and calculate time for player to play in Specified position
  function handleSkipPress(skippPointRate) {
    const rd = Math.random() / 10000;
    const skippPointRateWithLitleRandomPos = skippPointRate + rd;
    const currentPlayingTime = props.playback.duration * skippPointRateWithLitleRandomPos;
    clearSpeakerSelection(currentPlayingTime);
    props.setSeek(currentPlayingTime);
    props.setActiveTime(currentPlayingTime);
  }

  function clearSpeakerSelection(time) {
    if (!canThisTurnBePlayed(props.playback.conversationTurn, time, props.playback.selectedSpeakers)) {
      dispatch({ type: SET_SELECTED_SPEAKERS, selectedSpeakers: null })
    }
  }

  function handleSeekTime(time) {
    props.setSeek(time);
    props.setActiveTime(time);
  }

  function handleVolumeChange(e) {
    props.setVolume(e / 100);
  }

  // Calculate the time to display in player

  function handleStopPlaying() {
    console.log("pausing audio on end");
    props.setPause();
  }

  function handleSpeedChange(speed) {
    console.log("inside handleSpeedChange", speed);
    props.setRate(speed);
  }

  // skip back 15 seconds
  function handleBackwardClick() {
    props.skipBy(-15.0);
  }

  // Play or Pause click
  function handlePlayClick() {
    if (props.playing) {
      props.setPause();
    } else {
      props.setPlay();
    }
  }

  // skip forward 15 seconds
  function handleForwardClick() {
    props.skipBy(15.0);
  }

  function handlePlayError() {
    metrics.logEvent("errorPlaying");
    handleStopPlaying();
  }

  function toggleFullscreen() {
    props.setFullScreenVideo(!props.screen.fullscreenVideo);
  }

  function getJobTitleForRep(speakerName) {
    let index = speakerNames.indexOf(speakerName);
    if (index != -1 && jobTitles) {
      let userId = channels[index].userId;
      return jobTitles[userId];
    }
    return null;
  }

  const howlerProps = {
    src: [props.playback.url],
    playing: props.playback.playing,
    volume: props.volume,
    rate: props.rate,
    seek: props.seek,
    setPlay: props.setPlay,
    setPause: props.setPause,
    duration: props.playback.duration,
    onEnd: handleStopPlaying,
    onPlayError: handlePlayError,
    loop: false,
  };
  const progressProps = {
    playing: props.playing,
    waveformTurns: waveformTurns,
    onSkipPress: handleSkipPress,
    isSharedCall: props.isSharedCall,
    barWidth: _calculateBarWidth(props.seek, props.playback.duration),
  };
  const playingTimeProps = {
    current: _formatSecondsToPlayingTime(props.seek),
    duration: _formatSecondsToPlayingTime(props.playback.duration),
  };
  const speedProps = {
    value: props.rate,
    onChange: handleSpeedChange,
  };
  const controlProps = {
    playing: props.playing,
    onBackwardClick: handleBackwardClick,
    onPlayClick: handlePlayClick,
    onForwardClick: handleForwardClick,
  };


  return (
    <div
      className={`Player ${props.screen.fullscreenVideo ? "PlayerFullScreen !p-0 !rounded-none" : ""}`}
    >
      <div className={`Player__row ${props.screen.fullscreenVideo ? "Player__mainControlsFullscreen !rounded-none" : "Player__mainControls"}`}>
        <div className="flex items-center w-2/5 ml-4">
          <KeyboardShortcutsWithTrigger />
          <PlaybackSpeedMenu onChange={speedProps.onChange} currentSpeed={speedProps.value} />
        </div>
        <div className="row-centered w-1/5">
          <PlaybackControl fullscreenVideo={props.screen.fullscreenVideo} controlProps={controlProps} />
        </div>
        {props.screen.fullscreenVideo && <PlaybackWaveform
          howlerProps={howlerProps}
          fullscreenVideo={props.screen.fullscreenVideo}
          progressProps={progressProps}
          playingTimeProps={playingTimeProps}
        />}
        <div className="flex items-center justify-start w-2/5">
          {!props.isSharedCall && !props.screen.fullscreenVideo && videoAvailable && <ToggleShowVideo showVideo={props.showVideo} setShowVideo={props.setShowVideo} />}
          <div className="w-1/4">
            {props.showVideo && props.videoAvailable && <button className="flex justify-center" onClick={toggleFullscreen}>{props.screen.fullscreenVideo ? <FullScreenCollapseIcon className="" /> : <FullScreenIcon className="" />}</button>}
          </div>
        </div>
      </div>

      {!props.screen.fullscreenVideo && <div className="Player__row" style={{ maxWidth: timelineWidth }}>
        <PlaybackWaveform
          fullscreenVideo={props.screen.fullscreenVideo}
          howlerProps={howlerProps}
          progressProps={progressProps}
          playingTimeProps={playingTimeProps}
        />
      </div>}
      {!props.screen.fullscreenVideo && <div id="Player__speakerTimelines" ref={speakerTimelineRef} className="Player__speakerTimelines">
        {speakerData && speakerData.sortedSpeakers && speakerData.sortedSpeakers.map((speaker, i) => {
          return <PlayerSpeakerTimeline
            key={i}
            isSharedCall={props.isSharedCall}
            speaker={speaker}
            id={speakerData.ids[speaker]}
            contact={speakerData.contacts[speaker]}
            tlRatio={speakerData.tlRatios[speaker]}
            selectedSpeakers={props.playback.selectedSpeakers}
            howlerProps={howlerProps}
            progressProps={progressProps}
            playingTimeProps={playingTimeProps}
            accountName={props?.call?.details?.callDataDocument?.crmData?.accountDocument?.name}
            jobTitle={getJobTitleForRep(speaker)}
          />
        })}
      </div>}
      {showCardsShown &&
        <CardsShown
          onSkipPress={handleSeekTime}
          duration={props.playback.duration}
          battlecardsShown={props.call.details.callDataDocument && props.call.details.callDataDocument.battlecardsShown}
          behaviourCardsShown={props.call.details.callDataDocument && props.call.details.callDataDocument.behaviourCardsShown}
          recordingStartTime={props.call.details.callDataDocument && props.call.details.callDataDocument.recordingStartTime}
        />
      }
    </div>
  );
}

const mapStateToProps = store => {
  const { playback } = store.player;
  const { screen, call } = store;
  return { playback, screen, call };
};

PlayerController.defaultProps = {
  isSharedCall: false,
  playback: {
    url: "",
    conversationTurn: [],
    duration: 0,
    showViewButton: true,
    seekPosition: 0,
  },
};

PlayerController.propTypes = {
  playback: object,
};

export default connect(
  mapStateToProps,
  { setActiveTime, setFullScreenVideo },
)(PlayerController);
