import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@material-ui/core'
import { TableHeaders } from '../components/table/TableHeader'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStartDate, getEndDate } from '../components/dashboards/dateUtils'
import { sendRequest } from '../utils/network'
import { SPaper, NoData } from 'app/components/common'
import { CustomDropdown } from "app/components/common";
import { Label } from "app/components/form";
import Select from "react-select";
import { Ratio } from '../components/dashboards'
import { getNormalizedValues } from '../components/dashboards/AgentsTable'
import { GetSortOrder } from '../components/dashboards/helpers'
import { LOADING } from '../actions/actionTypes'
import { NoDataCustom } from '../components/common/NoData'
import NoDataIcon from "app/styles/assets/images/icons/noData.svg"

const ActivityDashboard = ({ }) => {

	const [usageData, setUsageData] = useState(null);
	const [total, setTotal] = useState(null);
	const [timePeriod, setTimePeriod] = useState("month");
	const [sortField, setSortField] = useState("CALL_VIEW");
	const [sortDirection, setSortDirection] = useState("DESC");
	const auth = useSelector(store => store.auth);
	const screen = useSelector(store => store.screen);
	const [team, setTeam] = useState({ value: "ALL", label: "All Users" });
	const initialOptions = [{ value: auth.data.user.userId, label: "My Team" }, { value: "ALL", label: "All Users" }];
	const [options, setOptions] = useState(initialOptions);

	const customStyles = {
		menuPortal: provided => ({ ...provided, zIndex: 20 }),
		menu: provided => ({ ...provided, zIndex: 20 })
	}

	const styleForTimespan = {
		control: base => ({ ...base, border: "none", borderRadius: 0, borderBottom: "2px dashed #2680EB !important", boxShadow: "none", minHeight: "0", height: "35px" }),
		indicatorSeparator: base => ({ ...base, display: "none" }),
		singleValue: base => ({ ...base, color: "#2680EB", fontWeight: 600, fontSize: "22px" }),
		valueContainer: base => ({ ...base, padding: 0 }),
		dropdownIndicator: base => ({ ...base, color: "#848484 !important" })
	}

	const dispatch = useDispatch();

	const headerLabels = [
		{ label: "Name", value: "name", sort: false },
		{ label: "Call Views", value: "CALL_VIEW", sort: true },
		{ label: "Comments", value: "COMMENT", sort: true },
	];

	const timeValues = [
		{ label: "Past 7 days", value: "week" },
		{ label: "Past 30 days", value: "month" },
		{ label: "Past 90 days", value: "quarter" },
		{ label: "Past year", value: "year" },
	]

	const sortData = {};
	sortData[sortField] = sortDirection;
	const headerProps = { sortData, sort: handleSort };


	useEffect(() => {
		fetchUsage();
	}, [])

	useEffect(() => {
		let newOptions = screen.metaData.managers;
		newOptions = initialOptions.concat(newOptions);
		setOptions(newOptions);
	}, [screen.metaData.managers]);



	function handleSort(field, direction) {
		console.log("sort data", field, direction);
		setSortDirection(direction);
		setSortField(field);
		const stats = usageData;
		usageData.sort(GetSortOrder(field, direction));
		setUsageData(stats);
	}

	async function fetchUsage(timePeriodParam = timePeriod, teamParam = team) {
		dispatch({ type: LOADING, loading: true })
		const params = new URLSearchParams();
		params.append("managerId", teamParam.value);
		params.append("start", getStartDate(timePeriodParam));
		params.append("end", getEndDate());
		const stats = await sendRequest("/usage/report_customer_usage_stats", params);
		stats.sort(GetSortOrder(sortField, sortDirection));
		let foundTotal = false;
		const filteredStats = stats.filter(stat => {
			if (stat.name === "Total") {
				setTotal(stat);
				foundTotal = true;
				return false;
			}
			return stat.CALL_VIEW;
		});
		if (!foundTotal) setTotal(null);
		setUsageData(filteredStats);
		dispatch({ type: LOADING, loading: false })
	}

	function handleTimeChange(timePeriodParam) {
		setTimePeriod(timePeriodParam.value);
		fetchUsage(timePeriodParam.value);
	}

	function getTimeLabel(timePeriodParam) {
		return timeValues.filter(t => t.value === timePeriodParam)[0].label;
	}

	function handleUsersChange(teamParam) {
		setTeam(teamParam);
		fetchUsage(timePeriod, teamParam)
	}

	return (
		<div className="ActivityDashboard px-8">
			<div className="ActivityDashboard__header">
				<span className="ActivityDashboard__headerText">Usage stats for &nbsp;</span>
				<Select options={timeValues} onChange={handleTimeChange} className="w-44" isSearchable={false} defaultValue={{ label: "Past 30 days", value: "month" }} styles={styleForTimespan} />
				<div className="ActivityDashboard__headerSelectUsers">
					<Label>Select Team:</Label>
					<Select options={options} value={team} placeholder="Select Team" onChange={handleUsersChange} menuPortalTarget={document.body}
						menuPosition={'fixed'}
						styles={customStyles} />
				</div>
			</div>
			<SPaper className="ActivityDashboard__table">
				<div >
					<Table>
						<TableHeaders
							headerLabels={headerLabels}
							headerProps={headerProps}
						/>
						<tbody>
							{usageData != null && usageData.map((key, i) => {
								const callViewsNF = getNormalizedValues(usageData, "CALL_VIEW");
								const commentsNF = getNormalizedValues(usageData, "COMMENT");
								return <tr key={i}>
									<td className="p-4">
										<div className="RoleManagement__table__userName">
											{key.name}
										</div>
									</td>
									<td>
										<Ratio
											key={`ratioCallViews${i}`}
											title={key["CALL_VIEW"]}
											rate={callViewsNF[i]}
										/>
									</td>
									<td>
										<Ratio
											key={`ratioComments${i}`}
											title={key["COMMENT"]}
											rate={commentsNF[i]}
										/>
									</td>
								</tr>
							})}
							{total !== null &&
								<tr className="ActivityDashboard__totalRow" key={"LAST"}>
									<td className="ActivityDashboard__totalColumn p-4">
										<div className="RoleManagement__table__userName">
											{"Total"}
										</div>
									</td>
									<td className="ActivityDashboard__totalColumn">
										<div className="RoleManagement__table__userName">{total["CALL_VIEW"]}
										</div>
									</td>
									<td className="ActivityDashboard__totalColumn">
										<div className="RoleManagement__table__userName">{total["COMMENT"]}
										</div>
									</td>
								</tr>
							}
						</tbody>
					</Table>
				</div>
				{!usageData || usageData.length === 0 && (
					<NoDataCustom icon={NoDataIcon} copy={"No Usage Found"} />
				)
				}
			</SPaper>
		</div>

	);
}
ActivityDashboard.propTypes = {}
ActivityDashboard.defaultProps = {}
export default ActivityDashboard
