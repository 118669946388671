import {
  logEvent
} from "app/utils/network"

var Metrics = function () { }

Metrics.prototype.logEndOfPage = function (pageName) {
  try {
    console.log("trying to logEndOfPage: " + pageName);
    if (window.heap && window.heap.track && window.location.hostname.indexOf("app.trywingman.com") !== -1) {
      console.log("calling heap track: " + pageName + "PageExited");
      window.heap.track(pageName + 'PageExited', {});
    }
  } catch (error) {
    console.log("error in calling heap track", error);
  }
}

Metrics.prototype.logEventHeap = function (eventName, properties = {}) {
  try {
    console.log("trying to logEventHeap: ", eventName, JSON.stringify(properties));
    if (window.heap && window.heap.track && window.location.hostname.indexOf("app.trywingman.com") !== -1) {
      console.log("calling heap track: ", eventName);
      window.heap.track(eventName, properties);
    }
  } catch (error) {
    console.log("error in calling heap track", error);
  }
}

Metrics.prototype.logEvent = function (event, eventProperties) {
  const isAdminLogin = localStorage.getItem("isAdminLogin");
  let user = localStorage.getItem("user");
  if (isAdminLogin == "false" && user != "undefined" && user != undefined && user != null) {
    user = JSON.parse(user)
    let userProperties = {
      "authorizedDomains": user?.stringsCustomer?.authorizedDomains,
      "email": user?.emailId
    }
    logEvent(event, eventProperties, userProperties);
  }
};

Metrics.prototype.logAnonymousEvent = function (event, eventProperties) {
  logEvent(event, eventProperties);
};


export var metrics = new Metrics();

export default metrics;