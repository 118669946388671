import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NotificationWithButton, TextBase } from "app/designSystem";
import { pushCallToCrm } from "app/utils/network";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { capitalizeFirstLetter } from "app/utils/helpers";
import { SHOW_CRM_WARNING_FOR_CALL_STATUS } from "app/constants";

const PushCallToCrm = ({ call }) => {
    const [showCrmPushNotification, setShowCrmPushNotification] = useState(true);
    const [pushCallCrmLoading, setPushCallCrmLoading] = useState(false);
    const { setSnackbar } = useSnackbar();
    const defaultCrm =
        useSelector((store) => store?.auth?.stringsCustomer?.defaultCrm) || "";

    const addCallToCrm = async () => {
        setPushCallCrmLoading(true);
        let response = await pushCallToCrm(call?.callId);
        if (response.error) {
            setSnackbar("Failed to push call, Please try again.", "ERROR");
        } else {
            setShowCrmPushNotification(false);
            setSnackbar("Call pushed successfully", "SUCCESS");
        }
        setPushCallCrmLoading(false);
    };
    console.log("SHOW_CRM_WARNING_FOR_CALL_STATUS.includes(call?.callStatus)", SHOW_CRM_WARNING_FOR_CALL_STATUS.includes(call?.callStatus));
    const showCrmOption = (call) => {
        return (
            !call?.eventPushedToCrm &&
            call?.crmInfo?.accountId &&
            SHOW_CRM_WARNING_FOR_CALL_STATUS.includes(call?.callStatus) &&
            call?.crmInfo?.contacts?.length > 0
        );
    };

    return (
        showCrmPushNotification &&
        showCrmOption(call) && (
            <NotificationWithButton
                variant="orange"
                onClose={() => setShowCrmPushNotification(false)}
                handleButtonClickAction={addCallToCrm}
                loading={pushCallCrmLoading}
                buttonClassName={"w-full !py-1 !text-gray-900"}
                buttonText={`Push to ${capitalizeFirstLetter(defaultCrm)}`}
            >
                {" "}
                <TextBase className="text-orange-500 w-11/12">
                    Call not pushed to {capitalizeFirstLetter(defaultCrm)}
                </TextBase>
                <TextBase className="!text-gray-500 m-1">
                    Would you like to push this call manually?
                </TextBase>
            </NotificationWithButton>
        )
    );
};

export default PushCallToCrm;
