import React from "react";
import { useSelector } from "react-redux";
import { fetchScorecardsForcall } from "../network";
import { useState } from "react";
const useScorecardsPermissionHelper = () => {

    const auth = useSelector(store => store.auth)
    const isAdmin = auth.isAdmin;
    const isManager = auth.isManager;
    const isRep = auth.isRep;
    const userId = auth.userId
    const authorizedCallScorers = auth.settings.authorizedCallScorers;
    const authorizedScoreViewers = auth.settings.authorizedScoreViewers;

    function showScoreCardButton(scoredRepId) {

        if (authorizedCallScorers === "NO_ONE") {
            return false;
        }

        if (scoredRepId) {  ///scoredRepId present indicates It's a scored call ,Checking VIEWER permissions

            if (authorizedScoreViewers === "ALL") {
                return true;
            }

            if (authorizedScoreViewers === "MANAGERS_AND_SCORED_REP") {

                if (isManager) {
                    return true;
                }
                if (isRep && scoredRepId === userId) {
                    return true;
                }
            }

            if (authorizedScoreViewers == "MANAGERS_AND_ADMINS_SCORED_REP") {
                if (isAdmin || isManager) {
                    return true;
                }

                if (isRep && scoredRepId === userId) {
                    return true;
                }

            }

        }

        if (!scoredRepId) { //Else it's an unscored call, checking scorer permissions
            return showScoreAgainButton()
        }


        return false;

    }

    function showScoreAgainButton() {

        if (authorizedCallScorers === "ALL") {
            return true;
        }
        if (authorizedCallScorers === "MANAGERS") {
            if (isManager) {
                return true;
            }
        }
        if (authorizedCallScorers === "MANAGERS_AND_ADMINS") {
            if (isAdmin || isManager) {
                return true;
            }
        }
        return false;

    }

    function showOnlyRepScoresInDashboard() {

        if (authorizedScoreViewers === "ALL") {
            return false;
        }
        else if (authorizedScoreViewers === "MANAGERS_AND_SCORED_REP") {

            if (isManager) {
                return false;
            }

            if (isRep) {
                return true;
            }
        }
        else if (authorizedScoreViewers === "MANAGERS_AND_ADMINS_SCORED_REP") {
            if (isManager || isAdmin) {
                return false;
            }

            if (isRep) {
                return true;
            }
        }

        return true;

    }

    //We can club the above 2 function, but i wish to keep it this way...such that readibility will be good.
    return { showScoreCardButton, showScoreAgainButton, showOnlyRepScoresInDashboard }

};

export default useScorecardsPermissionHelper;