import React from 'react'
import { TextBase, Button, } from 'app/designSystem'
import { Mention, MentionsInput } from 'react-mentions';
import { mentionInputStylesforEmailEditor } from "app/styles/components/mentionStyles";

const EmailEditor = ({ emailBody, setEmailBody }) => {
    const inputTagOptions = [
        { id: "callTitle", display: "Call Title" },
        { id: "repNextSteps", display: "Rep Next Steps" },
        { id: "customerNextSteps", display: "Customer Next Steps" },
        { id: "callRecordingLink", display: "Call Recording Link" },
        { id: "myName", display: "My Name" },
    ]
    return (
        <div className="flex flex-col items-center h-full">
            <div className="bg-coolGray-50 flex flex-row justify-between items-center py-1 w-full">
                <TextBase textColor="text-coolGray-400 px-6 py-2    ">{"Type “/” for topics & other commands"}</TextBase>
                {/* <Button variant="secondary" className="mr-5 h-8">Preview email</Button> */}
            </div>
            <div className="min-h-[15rem] max-h-[20rem] w-full px-6 py-4 overflow-y-auto">
                <MentionsInput
                    placeholder="Enter email content"
                    value={emailBody || ""}
                    displayTransform={input => `@${input}`}
                    allowSpaceInQuery
                    onChange={e => setEmailBody(e.target.value)}
                    style={mentionInputStylesforEmailEditor}>
                    <Mention
                        trigger="/"
                        markup="{{__id__}}"
                        appendSpaceOnAdd
                        displayTransform={input => `{{${input}}}`}
                        data={inputTagOptions}
                    />
                </MentionsInput>
            </div>
        </div >);
}

export default EmailEditor;