import React from 'react'
import CallCard from 'app/components/dealView/CallCardNew';
import DealEventTag from 'app/components/dealView/DealEventTag';
import EmailCard, { EmailCardLoading } from 'app/components/dealView/EmailCard';
import { _formatDateToCallCardDateFormat } from 'app/utils/helpers';
import DealSelector from './DealSelector';
import { useEffect } from 'react';
import { fetchDataSafely } from '../../utils/dataUtils';
import chroma from "chroma-js";
import { useCallback } from 'react';
import { dealStageColors } from '../../containers/AccountView';
import metrics from '../../utils/metrics';
import classNames from "classnames";
import { VariableSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

const InteractionsRow = ({ index, style, data }) => {
    const { allEvents, activeInteraction, activeDeal, crmData, addRef, setActiveInteraction } = data;
    const event = allEvents[index];

    if (event.type === "CALL")
        return (
            <div ref={ref => addRef(ref, event.id)} key={"CALL_CARD" + "__" + event.id + index} onClick={() => setActiveInteraction(event.call)} style={style}>
                <CallCard key={event.id} activeInteraction={activeInteraction} call={event.call} crmData={crmData} />
            </div>
        );

    if (event.type === "EMAIL") return (
        <div ref={ref => addRef(ref, event.id)} key={"EMAIL_CARD" + "__" + event.id + index} onClick={() => setActiveInteraction(event.email)} style={style}>
            <EmailCard key={event.id} activeInteraction={activeInteraction} email={event.email} crmData={crmData} />
        </div>
    );

    if (event.type === "STAGE_CHANGED") {
        let color;

        if (event.stage.includes("Won")) color = chroma(dealStageColors[0].color);
        else if (event.stage.includes("Lost")) color = chroma(dealStageColors[1].color);
        else color = chroma(dealStageColors[(index % 4) + 2].color);
        return (
            <div ref={ref => addRef(ref, event.id)} key={"DEAL_STAGE" + event.stage + "__" + index} className="DealView__stageChangeDivOuter" style={{ backgroundColor: color.alpha(0.2).css(), ...style }}>
                <div className="DealView__stageChangeDiv" style={{ backgroundColor: color }}>
                    <span className="DealView__stageChangeText">{event.stage}</span>
                    <span className="DealView__stageChangeDate">{" on " + _formatDateToCallCardDateFormat(event.date)}</span>
                </div>
            </div>);
    }
    if (event.type === "OPPORTUNITY_CREATED")
        return (
            <div key={"OPPORTUNITY_CREATED" + event.id} ref={ref => addRef(ref, event.id)} style={style}>
                <DealEventTag type="CREATED" event={event} activeDeal={activeDeal} />
            </div>
        );
    if (event.type === "OPPORTUNITY_CLOSED")
        return (
            <div key="OPPORTUNITY_CLOSED" ref={ref => addRef(ref, event.id)} style={style}>
                <DealEventTag type="CLOSED" event={event} activeDeal={activeDeal} />
            </div>
        );
}

const Interactions = ({ loading, setInteractionRef, scrollToCard, crmData, allEvents, activeInteraction, addRef, setActiveInteraction, activeDeal, setActiveDeal, disableInteractions }) => {
    const handleKeyEventsCallBack = useCallback(handleKeyEvents, [allEvents, activeInteraction]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyEventsCallBack);
        return () => {
            document.removeEventListener("keydown", handleKeyEventsCallBack);
        }
    }, [allEvents, activeInteraction])

    useEffect(() => {
        let foundMatchingDeal = false;
        if (crmData.opportunities && window.location.hash !== "") {
            const searchTurn = window.location.hash.slice(1, window.location.hash.length);
            if (searchTurn.indexOf("deal=") !== -1) {
                const activeDealId = searchTurn.split("deal=")[1];
                foundMatchingDeal = crmData.opportunities.find(opp => {
                    if (opp.id === activeDealId) {
                        setActiveDeal(opp);
                        return true;
                    }
                    return false;
                })
            }
        }
        if (!foundMatchingDeal && crmData.opportunities && crmData.opportunities.length > 0) {
            setActiveDeal(crmData.opportunities[0]);
        }
    }, [crmData.opportunities])

    function getRowSize(index) {
        const event = allEvents[index];
        if (event.type === "CALL") return 94;
        if (event.type === "EMAIL") return 117;
        if (event.type === "OPPORTUNITY_CREATED" || event.type === "OPPORTUNITY_CLOSED") return 35;

        return 20;
    }

    function handleKeyEvents(e) {
        let activeElement = null;
        try {
            activeElement = document.activeElement.tagName;
        } catch (error) {
            console.log(error);
        }
        if (activeElement === "TEXTAREA" || activeElement === "INPUT") return;
        switch (e.keyCode) {
            case 40:
                let prevVal = null;
                const nextEvent = allEvents.find(event => {
                    if (event.type !== "CALL" && event.type !== "EMAIL") return false;
                    if (prevVal === "JACKPOT") {
                        return true;
                    }
                    if (activeInteraction.id === event.id || activeInteraction.callId === event.id) {
                        prevVal = "JACKPOT";
                    }
                })
                if (nextEvent) {
                    scrollToCard(nextEvent.id);
                    setActiveInteraction(nextEvent.call || nextEvent.email);
                    metrics.logEventHeap("goToNextInteractionUsingKeys");
                }
                break;
            case 38:
                let prevEvent = null;
                allEvents.find(event => {
                    if (event.type !== "CALL" && event.type !== "EMAIL") return false;
                    if (activeInteraction.id === event.id || activeInteraction.callId === event.id) {
                        return true;
                    }
                    prevEvent = event;
                })
                if (prevEvent) {
                    scrollToCard(prevEvent.id);
                    setActiveInteraction(prevEvent.call || prevEvent.email);
                    metrics.logEventHeap("goToPrevInteractionUsingKeys")
                }
                break;
        }
    }


    if (loading) {
        return (
            <div>
                <EmailCardLoading />
                <EmailCardLoading />
                <EmailCardLoading />
            </div>
        );
    }

    return (
        <div>
            <DealSelector setActiveDeal={setActiveDeal} activeDeal={activeDeal} crmData={crmData} />
            <div className={classNames(disableInteractions ? "blur-sm pointer-events-none" : "")}>
                <div className="DealView__HeadingBig">All Communications</div>
                <div className={`DealView__InteractionsScroll ${activeDeal ? "" : "DealView__InteractionsScrollBig"}`} ref={ref => setInteractionRef(ref)} id="DealView__Interactions">
                    <AutoSizer>
                        {({ height, width }) => (
                            <VariableSizeList itemCount={allEvents.length} height={height} width={width} itemSize={getRowSize} itemData={{ allEvents, activeInteraction, activeDeal, crmData, setActiveInteraction, addRef }}>
                                {InteractionsRow}
                            </VariableSizeList>
                        )}
                    </AutoSizer>
                </div>
            </div>
        </div>
    );
}
Interactions.propTypes = {}
Interactions.defaultProps = {}
export default Interactions;