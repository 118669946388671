import React from "react";
import { Link } from "react-router-dom";
import { TableCell } from "@material-ui/core";
import classNames from "classnames";

export const TableColumn = class TD extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    console.log("error:", error);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <TableCell colSpan={this.props.span}>NA</TableCell>;
    }
    return (
      <TableCell className={classNames(this.props.className, "!py-0 !px-0 !border-b !border-solid !border-coolGray-200")} colSpan={this.props.span} >
        <Link to={this.props.path} className={classNames(this.props.contentStyles, "!py-6 !px-0 block")}>
          {this.props.children}
        </Link>
      </TableCell>
    );
  }
};
