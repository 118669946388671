import React, { useContext } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setSidebarExpansion } from "app/actions/screen";
import { WingmanLogoWithName } from "app/designSystem"
import { Button } from "app/components/form";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { fetchDataSafely } from "../../utils/dataUtils";
import MeetingIcon, { getMeetingIcon } from "../call/MeetingIcon";
import { PlayerContext } from "../../containers/Call";

const HeaderGuest = props => {
  const [canRedirect, setCanRedirect] = useState(false);
  const playerProps = useContext(PlayerContext);

  function isLoggedIn() {
    const userData = props.auth.data.user || {};
    if (isEmpty(userData)) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (isLoggedIn() && props.call) {
      var callCustomer = fetchDataSafely(props.call, "details.callDataDocument.stringsCustomer.customerId");
      if (callCustomer === props.auth.data.user.stringsCustomer.customerId) {
        setCanRedirect(true);
      }
    }
  }, [props.call, props.auth.data.user])

  function redirectToMainCallScreen() {
    const frontendUrl = `${window.location.origin}/call/${props.call.details.callDataDocument.callId}#time=${playerProps.seek}`;
    window.location.replace(frontendUrl + window.location.hash);
    return;
  }

  return (
    <div className="headerGuest">
      <div className="headerProduct__left" style={{ marginLeft: 20 }}>
        <div
          onClick={() => {
            window.open("https://trywingman.com");
          }}
          className="headerProduct__copy"
        >
          <WingmanLogoWithName className="h-8" />
        </div>
      </div>
      <div className="headerProduct__right" style={{ marginRight: 20 }}>
        <div
          onClick={() => {
            if (canRedirect) {
              return;
            } else {
              window.open("https://trywingman.com");
            }
          }}
          className="headerProduct__copy"
        >
          {!canRedirect && <span className="headerProduct__copyDull">Learn more about how Wingman can help your sales team</span>}
          {canRedirect &&
            <Button onClick={redirectToMainCallScreen} theme="dark" size="medium">
              <div className="ButtonWithImage">
                <div className="ButtonWithImage__imageDiv">
                  <img className={"ButtonWithImage__image"} src={getMeetingIcon(props.call.details.callDataDocument.callType)} />
                </div>
                <span>View recording</span>
              </div>
            </Button>}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

HeaderGuest.propTypes = {
  setSidebarExpansion: PropTypes.func,
};

HeaderGuest.defaultProps = {};

export default connect(
  mapStateToProps,
  {
    setSidebarExpansion,
  },
)(withRouter(HeaderGuest));
