import {
  USER,
  GET_ONBOARDING_SKIPPED,
  SET_ONBOARDING_STATUS,
  SET_SEARCH_TERM,
  SET_ANY_USER_CALLS,
  SET_ANY_CUSTOMER_CALLS,
  SET_USER_PREFERENCES,
  SET_LAST_RELOAD,
  SET_ONE_TIME_OPEN,
} from "app/actions/actionTypes";

const initialState = {
  searchTerm: "",
  onboardingSkipped: false,
  anyUserCalls: false,
  anyCustomerCalls: false,
  userPreferences: { playerRate: 1, showVideo: false },
  lastReload: "1.0.0",
  notifications: [],
  notificationsNew: false,
  clubbedNotifications: {},
  oneTimeOpen: { notificationsCenter: false }
}

export default function (state = initialState, payload) {
  switch (payload.type) {
    case USER:
      if (payload.user != null) {
        return {
          ...state,
          onboardingFlowStatus: getFlowStatus(payload.user),
        };
      } else {
        return {
          ...state,
          onboardingFlowStatus: {},
        };
      }
    case GET_ONBOARDING_SKIPPED:
      return {
        ...state,
        onboardingSkipped: payload.details,
      };
    case SET_ONE_TIME_OPEN:
      return {
        ...state,
        oneTimeOpen: { ...state.oneTimeOpen, ...payload.oneTimeOpen },
      };
    case SET_ONBOARDING_STATUS:
      return {
        ...state,
        onboardingFlowStatus: payload.flowStatus,
      };
    case SET_ANY_USER_CALLS:
      return {
        ...state,
        anyUserCalls: payload.anyCalls,
      };
    case SET_ANY_CUSTOMER_CALLS:
      return {
        ...state,
        anyCustomerCalls: payload.anyCalls,
      };
    case SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: { ...state.userPreferences, ...payload.preferences, }
      }
    case SET_LAST_RELOAD:
      return {
        ...state,
        lastReload: payload.version,
      }


    default:
      return state;
  }
}

export function isOnboardingDone(userData, flowStatus) {
  if (!(userData && userData.roleInfo && userData.roleInfo.role)) return false;
  if (!userData.roleInfo.recording) return true;
  for (var flow in flowStatus) {
    if (exceptionsList.indexOf(flow) === -1 && !flowStatus[flow]) {
      return false;
    }
  }
  return true;
}

export function getFlowStatus(userData) {
  let flowStatus = {}
  if (userData && userData.stringsCustomer && userData.stringsCustomer.integrationFlows) {
    let integrationFlows = userData.stringsCustomer.integrationFlows
    for (var integrationFlow in integrationFlows) {
      if (integrationFlows[integrationFlow] && userData.integrationStatus) {
        flowStatus[integrationFlow] = userData.integrationStatus[integrationFlow]
      }
    }
  }

  if (userData.stringsCustomer && userData.stringsCustomer.customerLevelIntegrationFlows) {
    let integrationFlows = userData.stringsCustomer.customerLevelIntegrationFlows
    let integrations = userData.stringsCustomer.customerLevelIntegrations
    for (var integrationFlow in integrationFlows) {
      if (integrations && integrationFlows[integrationFlow] && integrations[integrationFlow] !== undefined) {
        flowStatus[integrationFlow] = integrations[integrationFlow];
      }
      if (integrationFlows[integrationFlow]) {
        flowStatus.customerLevelIntegrationsPresent = true;
      }
      //console.log("flowstatus for ", integrationFlow, integrationFlows[integrationFlow], integrations[integrationFlow]);
    }
  }
  console.log("flowstatus", flowStatus);
  flowStatus.mobileAppDownloadFlow = false;
  return flowStatus
}

export function areAllIntegrationsDone(flowStatus) {
  if (!flowStatus) return true;

  for (const integration of integrationsList) {
    if (flowStatus[integration] === false) {
      return false;
    }
  }
  return true;
}

export function hasCrmSynced(flowStatus) {
  for (const integration of crmList) {
    if (flowStatus[integration]) {
      return true;
    }
  }
  return false;
}

export function getCrmSynced(flowStatus) {
  for (const integration of crmList) {
    if (flowStatus[integration]) {
      return integration;
    }
  }
  return null;
}

export function hasCrm(user) {
  try {
    for (const integration of crmList) {
      if (user.stringsCustomer.integrationFlows[integration]) {
        return true;
      }
    }
    for (const integration of crmList) {
      if (user.stringsCustomer.customerLevelIntegrationFlows[integration]) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return true;
  }
}


export const exceptionsList = [
  "hubspotDialerFlow",
  "hubspotCrmFlowDisabled",
  "customerLevelIntegrationsPresent",
  "slackAuthFlow",
  "extensionDownloadFlow",
  "gongSyncFlow",//No setup Screen for these flows, but flow is marked as done when customers imported from gong or chorus,adding to list to avoid UI break
  "chorusSyncFlow"
];

export const integrationsList = [
  "googleCalendarAuthFlow",
  "outlookCalendarAuthFlow",
  "salesforceSyncFlow",
  "hubspotSyncFlow",
  "freshsalesSyncFlow",
  "frontspinSyncFlow",
  "aircallSyncFlow",
  "frontspinSyncFlow",
  "pipedriveSyncFlow",
  "leadsquaredSyncFlow",
  "ringcentralSyncFlow",
  "outreachSyncFlow",
  "salesloftSyncFlow",
  "dialpadSyncFlow",
  "zoomSyncFlow",
  "nylasSyncFlow",
  "closeSyncFlow",
];

export const crmList = [
  "salesforceSyncFlow",
  "hubspotSyncFlow",
  "freshsalesSyncFlow",
  "pipedriveSyncFlow",
  "leadsquaredSyncFlow",
  "closeSyncFlow",
]