export const getModalPosition = (position) => {
    const modalPositionStyle = {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
    }

    if (position === "top") {
        modalPositionStyle.top = "1em"
    } else if (position === "bottom") {
        modalPositionStyle.bottom = "1em"
    } else if (position === "left") {
        modalPositionStyle.left = "1em"
    } else if (position === "right") {
        modalPositionStyle.right = "1em"
    } else if (position === "top-left") {
        modalPositionStyle.top = "1em"
        modalPositionStyle.left = "1em"
    } else if (position === "top-right") {
        modalPositionStyle.top = "1em"
        modalPositionStyle.right = "1em"
    } else if (position === "bottom-left") {
        modalPositionStyle.bottom = "1em"
        modalPositionStyle.left = "1em"
    } else if (position === "bottom-right") {
        modalPositionStyle.bottom = "1em"
        modalPositionStyle.right = "1em"
    } else {
        modalPositionStyle.top = "1em"
    }

    return modalPositionStyle
}