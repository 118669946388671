import React from "react";
import CardHeader from "./CardHeader";
import CardBody from './CardBody';

const BattleCard = props => {
  const { editCard } = props;
  return (
    <div className="BattleCard__container" style={{ marginLeft: props.leftMargin }}>
      <CardHeader editCard={editCard} removeBattlecard={props.removeBattlecard} data={props.data} />
      <CardBody data={props.data} />
    </div>
  );
};

export default BattleCard;
