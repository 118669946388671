import React, { useState, useEffect } from 'react';
import { Text2XL } from 'app/designSystem'
import { GameTapeCard } from 'app/components/gameTapesLatest'
import { useSelector } from 'react-redux'
import { FolderCards } from './helper';

export default function SearchView({ searchText }) {
    const { gametapesList, foldersList } = useSelector(state => state.gametapes);

    const [gameTapesMatchingByName, setGameTapesMatchingByName] = useState([])
    const [gameTapesMatchingByTags, setGameTapesMatchingByTags] = useState([])
    const [foldersMatchingByName, setFoldersMatchingByName] = useState([])

    useEffect(() => {
        if (gametapesList && gametapesList.length) {
            const matchingGameTapesByName = gametapesList.filter(gameTape => gameTape.name?.toLowerCase().includes(searchText.toLowerCase()))
            const matchingGameTapesByTags = gametapesList.filter(gameTape => gameTape.tags && gameTape.tags.length > 0)?.filter(gameTape => gameTape.tags?.filter(tag => tag?.toLowerCase().includes(searchText.toLowerCase())).length > 0)

            setGameTapesMatchingByName(matchingGameTapesByName)
            setGameTapesMatchingByTags(matchingGameTapesByTags)
        }
        if (foldersList && foldersList.length) {
            const matchingFolders = foldersList.filter(folder => folder.name?.toLowerCase().includes(searchText.toLowerCase()))
            setFoldersMatchingByName(matchingFolders)
        }
    }, [searchText])

    return (
        <div className="flex flex-row flex-wrap">
            {
                (gameTapesMatchingByName?.length || gameTapesMatchingByTags.length || foldersMatchingByName?.length) ?
                    <div>
                        {
                            gameTapesMatchingByName.length ?
                                <div className="flex flex-col">
                                    <Text2XL className="px-4 py-2">By name</Text2XL>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            gameTapesMatchingByName.map((gameTape) => <GameTapeCard key={gameTape.id} data={gameTape} />)
                                        }
                                    </div>
                                </div>
                                : null
                        }
                        {
                            gameTapesMatchingByTags.length ?
                                <div className="flex flex-col">
                                    <Text2XL className="px-4 py-2">By tags</Text2XL>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            gameTapesMatchingByTags.map((gameTape) => <GameTapeCard key={gameTape.id} data={gameTape} />)
                                        }
                                    </div>
                                </div>
                                : null
                        }
                        {
                            foldersMatchingByName.length ?
                                <div className="flex flex-col">
                                    <Text2XL className="px-4 py-2">By folder</Text2XL>
                                    <div className="flex flex-row flex-wrap">
                                        {
                                            foldersMatchingByName.map((folder) => <FolderCards key={folder.id} data={folder} />)
                                        }
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    : (
                        <div className="flex items-center justify-center w-full flex-col h-44"><Text2XL>No gametape or folder found</Text2XL></div>
                    )
            }
        </div>
    )
}