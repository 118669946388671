import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { outlookCalendarCallback, oneTimeOutlookFetch, unsyncOutlook } from "app/utils/network";
import outlookLogo from "app/styles/assets/images/onboarding/OutlookLogo.png";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import { MICROSOFT_CONFIG } from "app/config";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncOutlook = props => {
  const [loading, setLoading] = useState(false);

  async function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const url = `${"https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code"
      + "&client_id="}${MICROSOFT_CONFIG.clientID}&redirect_uri=${encodeURIComponent(
        location,
      )}&scope=${encodeURIComponent(MICROSOFT_CONFIG.graphScopes)}&response_mode=fragment`;
    openWindowAndListenForParam(url, "Outlook Integration", "code", completeSync);
  }

  async function completeSync(code) {
    await outlookCalendarCallback(code);
    setLoading(false);
    props.refreshUserAction();
    oneTimeOutlookFetch();
  }

  async function unsync() {
    props.setLoading(true);
    await unsyncOutlook();
    props.refreshUserAction();
    props.setLoading(false);
  }

  return (
    <IntegrationCard
      icon={outlookLogo}
      heading="Outlook Calendar"
      subHeading="Calendar Integrations"
      bodyText="Sync your outlook calendar to get meetings analysed."
      sync={sync}
      unsync={unsync}
      loading={loading}
      synced={props.synced}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncOutlook.defaultProps = {};

SyncOutlook.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncOutlook);
