import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import agentIcon from "app/styles/assets/images/icons/profile_rep.svg";
import customerIcon from "app/styles/assets/images/icons/profile_customer.svg";
import { CustomTooltip } from "app/components/common";
import { withRouter } from "react-router-dom";
import { getUserId } from "app/utils/network";

const PeopleTag = props => {

  const [isRep, setIsRep] = useState(props.userId ? true : false);
  const [repId, setRepId] = useState(null);

  useEffect(() => { setRepOrNot() }, [props.isExternal]);

  async function setRepOrNot() {
    if (!props.isExternal) {
      setIsRep(true);
      return;
    }
    const result = await getUserId(props.contactName);
    if (result.error)
      setIsRep(false)
    else {
      setIsRep(true);
      setRepId(result);
    }
  }

  if (props.contactName === null || props.contactName.trim() === "") {
    return null;
  }

  return (
    <div className="PeopleTag">
      <div className="PeopleTag__container">
        <img className="PeopleTag__icon" src={isRep ? agentIcon : customerIcon} alt="icon" />
        <CustomTooltip placement="bottom" title={props.contactName}>
          <span className="PeopleTag__text">
            {" "}
            {props.contactName}
          </span>
        </CustomTooltip>
      </div>
    </div>
  );
};

PeopleTag.propTypes = {
  contactName: PropTypes.string.isRequired,
};

PeopleTag.defaultProps = {
  contactName: "Contact Person",
};

export default withRouter(PeopleTag);
