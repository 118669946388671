import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import { Input } from "app/components/form";
import { TextBase, TextLarge, TextSM, Button } from "app/designSystem"
import { ReactComponent as CancelIcon } from "app/styles/assets/images/icons/plainClose.svg";
import TagFilledInputArea from "./TagFilledInputAreaForModal";
import { sendRequest } from "app/utils/network";
import { useEffect } from "react";
import useSnackbar from "app/utils/hooks/useSnackbar";


const AddWordModal = (props) => {
    const [correctWord, setCorrectWord] = useState(props.replacementWord ? props.replacementWord : "");
    const [incorrectWordsLocal, setIncorrectWordsLocal] = useState(props.incorrectWords ?? []);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const userId = useSelector(store => store.auth.userId);
    const [errorMessageForIncorrectWords, setErrorMessageForIncorrectWords] = useState(null);
    const [wordsEdited, setWordsEdited] = useState(false);
    const { setSnackbar } = useSnackbar();
    const [newIncorrectWord, setNewIncorrectWord] = useState("");
    const [replacementWord, setReplacementWord] = useState(props.replacementWord ? props.replacementWord : "");
    useEffect(() => {
        if (props.replacementWord) {
            setCorrectWord(props.replacementWord)
        }
    }, [props.replacementWord])


    function handleClose() {
        if (!props.editWords) {
            setCorrectWord("")
        }
        if (!wordsEdited && props.editWords) {
            setCorrectWord(props.replacementWord)
            setIncorrectWordsLocal(props.incorrectWords);
        }
        else {
            setIncorrectWordsLocal([]);
        }
        setErrorMessage("")
        setErrorMessageForIncorrectWords("")
        setWordsEdited(false);
        setNewIncorrectWord("");
        props.close();
    }

    function handleIncorrectWordsChanges(changes) {
        setIncorrectWordsLocal(changes);
    }


    async function addNewWord() {

        if (!checkForErrors()) {
            setLoading(true);
            const params = new URLSearchParams();
            params.append("replacementWord", correctWord)
            params.append("incorrectWords", incorrectWordsLocal)
            params.append("userId", userId)
            await sendRequest("/admin/add_and_edit_dictionary_words", params)
            setLoading(false)
            handleClose();
            setSnackbar("Your corrections will automatically be applied to future calls", "SUCCESS");
            props.refreshContent();
        }
    }

    async function handleChangesToIncorrectWords() {
        if (!checkForErrors()) {
            setLoading(true)
            const params = new URLSearchParams();
            params.append("replacementWord", correctWord)
            params.append("incorrectWords", incorrectWordsLocal.length == 0 ? props.incorrectWords : incorrectWordsLocal)
            params.append("userId", userId)
            params.append("editOldWords", true)
            params.append("oldReplacementWord", props.replacementWord)
            await sendRequest("/admin/add_and_edit_dictionary_words", params)
            setLoading(false)
            handleClose();
            setWordsEdited(true);
            setSnackbar("Your corrections will automatically be applied to future calls", "SUCCESS");
            props.refreshContent();
        }


    }

    function checkForErrors() {
        let errorFound = false;
        var updatedIncorrectWords = incorrectWordsLocal;
        if (correctWord.trim() == "") {
            setErrorMessage("Please add correct keyword")
            errorFound = true;
        }
        else if (checkForCorrectWordDuplicates()) {
            setErrorMessage("A correction has already been added for " + correctWord)
            errorFound = true;
        }
        else if ((updatedIncorrectWords.map((word) => word.toLowerCase())).includes(correctWord.trim().toLowerCase())) {
            setErrorMessage("Correct and incorrect words cannot be the same")
            errorFound = true;
        }
        else {
            setErrorMessage("")
        }

        if (updatedIncorrectWords.length == 0 && newIncorrectWord != "") {
            const allIncorrectWords = props.allIncorrectWords;
            if (allIncorrectWords[newIncorrectWord.toLowerCase()]) {
                setErrorMessageForIncorrectWords("A correction has already been added for " + newIncorrectWord)
                errorFound = true;
            }
            else if (correctWord.trim().toLowerCase() == newIncorrectWord.toLowerCase()) {
                setErrorMessageForIncorrectWords("Correct and incorrect words cannot be the same")
                errorFound = true;
            }
            else {
                updatedIncorrectWords = updatedIncorrectWords.push(newIncorrectWord)
                setIncorrectWordsLocal(updatedIncorrectWords)
            }
        }
        else if (updatedIncorrectWords.length == 0) {
            setErrorMessageForIncorrectWords("Incorrect words cannot be empty")
            errorFound = true;
        }
        else {
            setErrorMessageForIncorrectWords(null);
        }
        return errorFound;

    }

    function checkForCorrectWordDuplicates() {
        var duplicateFound = false;
        const correctWords = props.correctWords.map((word) =>
            word.toLowerCase()
        );
        if (correctWord.toLowerCase() != replacementWord.toLowerCase() && correctWords.includes(correctWord.toLowerCase())) {
            duplicateFound = true;
        }
        return duplicateFound;

    }
    function handleErrorMessagesForIncorrectWords(message) {
        setErrorMessageForIncorrectWords(message);
    }

    function handleRecentlyAddedIncorrectWord(word) {
        setNewIncorrectWord(word.trim());
    }

    return (
        <div>
            <Modal open={props.open} onClose={handleClose} className="Form__outerContainer ">
                <div
                    className="bg-white rounded-lg overflow-auto w-[38rem] p-0 outline-none"
                    style={{
                        maxHeight: "calc(100vh - 6.25rem)",
                    }}
                >

                    <div className="px-8 py-5 flex justify-between items-center">
                        <TextLarge>{props.editWords ? "Edit corrections" : "Add New Correction"}</TextLarge>
                        <button onClick={handleClose}>
                            <CancelIcon />
                        </button>
                    </div>
                    <hr />


                    <div className=" w-full flex flex-col px-8 mt-4">
                        <div className="flex justify-between items-center">
                            <TextBase textColor="text-coolGray-500 my-1 mr-1">Incorrect words</TextBase>
                            {!errorMessageForIncorrectWords &&
                                <TextSM textColor="text-coolGray-400 my-1">Please enter to add</TextSM>}
                            {errorMessageForIncorrectWords &&
                                <TextBase textColor="text-rose-500 my-1">{errorMessageForIncorrectWords}</TextBase>
                            }
                        </div>
                        <TagFilledInputArea handleRecentlyAddedIncorrectWord={handleRecentlyAddedIncorrectWord} handleChangesToMatches={handleIncorrectWordsChanges} incorrectWords={props.incorrectWords} handleErrorMessagesForIncorrectWords={handleErrorMessagesForIncorrectWords} allIncorrectWords={props.allIncorrectWords} forDictionary={true} correctWord={correctWord} ></TagFilledInputArea>
                    </div>

                    <div className=" w-full flex flex-col px-8 mt-4">
                        <div className="flex items-center justify-between">
                            <TextBase textColor="text-coolGray-500 my-1">Replace with</TextBase>
                            <TextBase textColor="text-rose-500 my-1">{errorMessage}</TextBase>
                        </div>
                        <Input
                            value={correctWord}
                            onChange={(e) => {
                                setCorrectWord(e.target.value);
                            }}
                            placeholder={"replacement word"}

                        />
                    </div>



                    <div className="flex items-center px-8 mt-8 justify-end pb-5">
                        <Button variant="secondary" onClick={handleClose} className={"mr-2 !px-4 !py-2"}>
                                Cancel
                        </Button>
                        <Button
                            loading={loading}
                            variant={"primary"}
                            className={"!px-4 !py-2"}
                            onClick={props.editWords ? handleChangesToIncorrectWords : addNewWord}
                            id="addWordModal"
                        >
                                Submit
                        </Button>

                    </div>

                </div>

            </Modal>
        </div>
    )
}
export default AddWordModal;