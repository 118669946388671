import {
  LOADING,
  EXPAND_SIDEBAR,
  SCREEN_NAME,
  SCREEN_TYPE,
  STACKED_SCREEN_NAME,
  SET_FIRST_URL,
  SET_MANAGER_DASHBOARD_FILTER,
  SET_USER_DASHBOARD_FILTER,
  SET_ACTIVE_FILTER,
  SET_AGENT_DASHBOARD_FILTER,
  SET_META_DATA,
  SET_SETTINGS_TAB,
  SET_SEARCH_TERM,
  SET_SEARCH_RESULTS,
  SHOW_SEARCH_RESULTS,
  SET_VIDEO_MINIMIZE,
  USER,
  HIDE_SNACKBAR,
  SET_SNACKBAR,
  SET_ADD_COMMENT_EXPANDED,
  SET_SHOW_SHARE_CALL_MODAL,
  SET_ADD_NOTE_EXPANDED,
  SET_DEFAULT_MY_CALL_TAB,
  SET_FULLSCREEN_VIDEO,
  SET_NOTIFICATIONS,
  SET_CALL_LOG_FILTER_ID,
  SET_DASHBOARD_FILTER_ID,
  SET_SCORECARDS_DASHBOARD_FILTER_ID,
  SET_DEALS_FILTER,
  HIDE_VP_WARNING,
  HIDE_TRIAL_END_DIALOGUE,
  SET_TRANSCRIPT_SELECTED,
  SET_SHOW_ADD_TO_GAMETAPE_MODAL,
  SET_MANAGER_DEAL_INSIGHTS_FILTER,
  SET_DEAL_BOARD,
  SET_ACTIVE_ROLLUP,
  SET_ACTIVE_DEALBOARD,
  SET_SCORECARDS_DASHBOARD_FILTER
} from "app/actions/actionTypes";
import {
  DEAL_COLUMNS,
  MAIN, WARNINGS,
} from "app/constants";
import {
  getInitialFilters, emptyFilterParams, getInitialDealFilters, initialDealFilters
} from "../utils/filter";
import { sortByLabel } from "../utils/helpers";

export const defaultRollup = {
  id: "DEALS_IN_PIPELINE",
  name: "Deals in Pipeline",
  filterFields: [{ fieldName: "stage", values: ["OPEN"] }],
  version: 1.0,
};


const initialState = {
  loading: false,
  sidebarExpanded: false,
  screenName: "",
  screenType: MAIN,
  settingsTab: "profile",
  stackedScreenName: "",
  firstUrl: "/",
  managerDashboardFilter: getSavedFilterParams(),
  scorecardsDashboardFilter: getSavedFilterParams("scorecardDashboard"),
  managerDealInsightsFilter: getSavedFilterParams("dealInsights"),
  userDashboardFilter: getInitialFilters("userDashboard"),
  agentDashboardFilter: getInitialFilters("agentDashboard"),
  activeFilter: "managerDashboardFilter",
  dealsFilter: getInitialDealFilters(),
  addCommentExpanded: false,
  showShareCallModal: false,
  transcriptSelected: false,
  shareStartTime: null,
  shareEndTime: null,
  showAddToGameTapeModal: false,
  snippetStartTime: null,
  snippetEndTime: null,
  commentTime: false,
  addNoteExpaned: false,
  searchTerm: "",
  fieldName: null,
  showSearchResults: false,
  showSnackbar: false,
  fullscreenVideo: false,
  videoMinimized: false,
  snackbarMessage: "",
  snackbarHideDuration: 3000,
  defaultMyCallTab: "COMPLETED",
  showVpWarning: true,
  showTrialEndDialogue: true,
  searchResults: {
    calls: [],
    hasMoreCalls: false,
    totalCallHits: 0,
    callPage: 0,
  },
  notifications: [],
  notificationsNew: false,
  clubbedNotifications: {},
  dealBoard: {
    boardTitle: "",
    admins: [],
    viewers: [],
    opportunityWarningSettings: [],
    columns: [],
    dealAlertSettings: []
  },
  activeRollup: defaultRollup,
  activeDealboard: null,
  metaData: {
    userNames: [],
    deletedUserNames: [],
    managers: [],
    types: [],
    status: [],
    categories: [],
    labels: [],
    battlecardCategories: [],
    profileImages: [],
    savedFilters: [],
    dealEditAccessList: [],
    scoringTemplates: []
    //battlecardCategories: ["Pricing", "Competitors", "Product Fetaures", "Objections"],
  },
  callLogFilterId: getInitialFilterId("callLogFilterId"),
  dashboardFilterId: getInitialFilterId("dashboardFilterId"),
  scorecardsDashboardFilterId: getInitialFilterId("scorecardsDashboardFilterId")
};

function getInitialFilterId(filterName) {
  try {
    const user = localStorage.getItem("user");
    if (user !== null && user !== undefined && user !== "null" && user !== "undefined") {
      let parsedUser = JSON.parse(user);
      var id = localStorage.getItem(filterName + parsedUser.userId);
      if (id) return id;
    }
  } catch (error) {
    console.log(error)
  }
  return null;
}

function getSavedFilterParams(prefix = "managerDashboard") {
  try {
    const user = localStorage.getItem("user");
    if (user !== null && user !== undefined && user !== "null" && user !== "undefined") {
      let parsedUser = JSON.parse(user);
      const filterParams = localStorage.getItem(`${prefix}${parsedUser.userId}`);
      if (filterParams && filterParams !== "undefined" && JSON.parse(filterParams) && JSON.parse(filterParams).version === emptyFilterParams.version) {
        return JSON.parse(filterParams);
      }
    }
  } catch (error) {
    console.log(error)
  }
  return getInitialFilters(prefix);
}

export default function (state = initialState, payload) {
  switch (payload.type) {
    case LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case EXPAND_SIDEBAR:
      return {
        ...state,
        sidebarExpanded: payload.expanded,
      };
    case SET_TRANSCRIPT_SELECTED:
      return {
        ...state,
        transcriptSelected: payload.transcriptSelected,
      };
    case SCREEN_NAME:
      return {
        ...state,
        screenName: payload.screenName,
        showSearchResults: payload.screenName === "Search Results",
      };
    case STACKED_SCREEN_NAME:
      return {
        ...state,
        stackedScreenName: payload.stackedScreenName,
      };
    case SCREEN_TYPE:
      return {
        ...state,
        screenType: payload.screenType,
      };
    case SET_FIRST_URL:
      return {
        ...state,
        firstUrl: payload.firstUrl,
      };
    case SET_MANAGER_DASHBOARD_FILTER:
      return {
        ...state,
        managerDashboardFilter: payload.filter,
        activeFilter: "managerDashboardFilter",
      };
    case SET_SCORECARDS_DASHBOARD_FILTER:
      return {
        ...state,
        scorecardsDashboardFilter: payload.filter,
        activeFilter: "scorecardsDashboardFilter",
      }
    case SET_MANAGER_DEAL_INSIGHTS_FILTER:
      return {
        ...state,
        managerDealInsightsFilter: payload.filter,
      };
    case HIDE_VP_WARNING:
      return {
        ...state,
        showVpWarning: false,
      };
    case HIDE_TRIAL_END_DIALOGUE:
      return {
        ...state,
        showTrialEndDialogue: false,
      };
    case USER:
      if (payload.user != null) {
        const filterParams = localStorage.getItem(`managerDashboard${payload.user.userId}`);
        if (filterParams && filterParams !== "undefined" && JSON.parse(filterParams) && JSON.parse(filterParams).version === emptyFilterParams.version) {
          return {
            ...state,
            managerDashboardFilter: JSON.parse(filterParams)
          };
        }
      }
    case SET_AGENT_DASHBOARD_FILTER:
      return {
        ...state,
        agentDashboardFilter: payload.filter,
        activeFilter: "agentDashboardFilter",
      };
    case SET_USER_DASHBOARD_FILTER:
      return {
        ...state,
        userDashboardFilter: payload.filter,
        activeFilter: "userDashboardFilter",
      };
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        activeFilter: payload.filter,
      };
    case SET_META_DATA:
      if (payload.metaData?.userNames)
        payload.metaData.userNames = payload.metaData.userNames.sort(sortByLabel)
      if (payload.metaData?.managers)
        payload.metaData.managers = payload.metaData.managers.sort(sortByLabel)
      return {
        ...state,
        metaData: {
          ...state.metaData,
          ...payload.metaData,
        },
      };
    case SET_SETTINGS_TAB:
      return {
        ...state,
        settingsTab: payload.tab,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload.query,
        fieldName: payload.field,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: payload.results,
      };
    case SHOW_SEARCH_RESULTS:
      return {
        ...state,
        showSearchResults: payload.showSearchResults,
      };
    case HIDE_SNACKBAR:
      return {
        ...state,
        showSnackbar: false,
        snackbarMessage: null,
      };
    case SET_SNACKBAR:
      return {
        ...state,
        showSnackbar: true,
        snackbarType: payload.snackbarType,
        snackbarMessage: payload.snackbarMessage,
        snackbarHideDuration: payload.snackbarHideDuration,
      };
    case SET_ADD_COMMENT_EXPANDED:
      return {
        ...state,
        addCommentExpanded: payload.show,
        commentTime: payload.time,
      };
    case SET_ADD_NOTE_EXPANDED:
      return {
        ...state,
        addNoteExpaned: payload.boolean,
      };
    case SET_DEFAULT_MY_CALL_TAB:
      return {
        ...state,
        defaultMyCallTab: payload.tab,
      }
    case SET_FULLSCREEN_VIDEO:
      return {
        ...state,
        fullscreenVideo: payload.fullscreenVideo,
      }
    case SET_NOTIFICATIONS:
      let anyNew = false;
      if (payload.notifications && payload.notifications.filter)
        anyNew = payload.notifications.filter((n) => { return !n.read }).length > 0;
      return {
        ...state,
        notifications: payload.notifications,
        notificationsNew: anyNew,
        clubbedNotifications: clubNotifications(payload.notifications),
      }
    case SET_CALL_LOG_FILTER_ID:
      return {
        ...state,
        callLogFilterId: payload.filterId,
      }
    case SET_DASHBOARD_FILTER_ID:
      return {
        ...state,
        dashboardFilterId: payload.filterId,
      }
    case SET_SCORECARDS_DASHBOARD_FILTER_ID:
      return {
        ...state,
        scorecardsDashboardFilterId: payload.filterId
      }
    case SET_DEALS_FILTER:
      return {
        ...state,
        dealsFilter: payload.dealsFilter,
      }
    case SET_DEAL_BOARD:
      return {
        ...state,
        dealBoard: { ...state.dealBoard, ...payload.dealBoard }
      }
    case SET_ACTIVE_ROLLUP:
      return {
        ...state,
        activeRollup: payload.activeRollup
      }
    case SET_ACTIVE_DEALBOARD:
      return {
        ...state,
        activeDealboard: payload.activeDealboard
      }
    case SET_SHOW_SHARE_CALL_MODAL:
      return {
        ...state,
        showShareCallModal: payload.showShareCallModal,
        shareStartTime: payload.startTime,
        shareEndTime: payload.endTime,
      }
    case SET_SHOW_ADD_TO_GAMETAPE_MODAL:
      return {
        ...state,
        showAddToGameTapeModal: payload.showAddToGameTapeModal,
        snippetStartTime: payload.startTime,
        snippetEndTime: payload.endTime,
        gametapeDescription: payload.description,
      }
    case SET_VIDEO_MINIMIZE:
      return {
        ...state,
        videoMinimized: payload.videoMinimized,
      }
    default:
      return state;
  }
}

function clubNotifications(notifications) {
  const clubbedNotifications = {};
  if (!notifications || !notifications.map) return clubbedNotifications;
  notifications.map((n) => {
    const callId = n.callId;
    if (!clubbedNotifications[callId]) {
      clubbedNotifications[callId] = { read: [], sharedView: [], unread: [], all: [] };
    }
    if (n.notificationType === "COMMENT") {
      clubbedNotifications[callId]["all"].push(n);
      if (n.read)
        clubbedNotifications[callId]["read"].push(n);
      else
        clubbedNotifications[callId]["unread"].push(n);
    } else {
      clubbedNotifications[callId]["sharedView"].push(n);
    }
  });
  return clubbedNotifications;
}