import React, { useEffect, useState } from "react";
import { ReactComponent as Eye } from "app/styles/assets/images/icons/Eye.svg";
import { ReactComponent as Clock } from "app/styles/assets/images/icons/Clock.svg";
import { ReactComponent as LinkBroken } from "app/styles/assets/images/icons/LinkBroken.svg";
import { ReactComponent as Extend } from "app/styles/assets/images/icons/Extend.svg"
import { ReactComponent as Calender } from "app/styles/assets/images/icons/Calender.svg"
import { sendRequest } from "../../utils/network";
import { TextBase, Button } from "app/designSystem"
import { getShareableLink } from "app/utils/network";
import useSnackbar from "../../utils/hooks/useSnackbar";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { updateNotificationReceiversForSnippet } from "app/utils/network"
const ShareCallDetails = ({ document, isFullCall, meetTitle }) => {
    const [dateText, setDateText] = useState("");
    const [linkExpiryStatus, setLinkExpiryStatus] = useState(false)
    const [loaderForSnippetExtension, setLoaderForSnippetExtension] = useState(false)
    const [loaderforSnippetRevoking, setLoaderForSnippetRevoking] = useState(false)
    const [currentSnippetDocument, setCurrentSnippetDocument] = useState(document)
    const { setSnackbar } = useSnackbar();
    const userId = useSelector(store => store.auth.userId)

    useEffect(() => {
        getLinkExpiryStatus(document);
    }, document);

    function getViewedDetails(timestamp) {
        var viewedDate = new Date(timestamp).getDate();
        var today = String(new Date().getDate()).padStart(2, "0");
        viewedDate = String(new Date(timestamp).getDate()).padStart(2, "0");
        if (today == viewedDate) {
            var hour = new Date(timestamp).getHours();
            var minute = new Date(timestamp).getMinutes();
            var ap = "AM";
            if (hour > 11) {
                ap = "PM";
            }
            if (hour > 12) {
                hour = hour - 12;
            }
            if (hour == 0) {
                hour = 12;
            }
            if (hour < 10) {
                hour = "0" + hour;
            }
            if (minute < 10) {
                minute = "0" + minute;
            }
            var timeString = hour + ":" + minute + " " + ap;
            return timeString;
        } else {
            var month = new Date(timestamp).toLocaleString("en-us", { month: "short" });
            var date = new Date(timestamp).getDate().toString();
            return date + " " + month;
        }
    }
    function getLinkExpiryStatus(document) {
        if (document.expiresAt) {
            var expiry = new Date(document.expiresAt);
            var today = new Date();
            if (expiry <= today) {
                setLinkExpiryStatus(true)
            }
        }
        date(document.expiresAt)
    }

    async function copyLinkToClipBoard(document) {
        var response = getShareableLink(document.shareId)
        navigator.clipboard.writeText(response)
        setSnackbar("Link Copied to clipboard", "SUCCESS");
        const doc = await updateNotificationReceiversForSnippet(document, userId)
        if (doc) {
            setCurrentSnippetDocument(doc);
        }
    }



    function date(timeStamp) {
        var month = new Date(timeStamp).toLocaleString("en-us", { month: "short" });
        var dateNum = new Date(timeStamp).getDate().toString();
        var year = new Date(timeStamp).getFullYear().toString();
        setDateText(dateNum + " " + month + " " + year);
    }

    function secondsToTimeConverter(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    }
    async function handleLinkExtensions(bool, document) {
        const params = new URLSearchParams();
        var expiry = new Date(document.expiresAt)
        params.append("shareId", document.shareId);
        if (!bool) {
            setLoaderForSnippetRevoking(true)
            var response = await sendRequest("/call/revoke_snippet_validity", params)
            setLinkExpiryStatus(true)
            setLoaderForSnippetRevoking(false)
            setSnackbar("Link revoked successfuly.", "ERROR")
        }
        else {
            setLoaderForSnippetExtension(true)
            params.append("timeStamp", expiry)
            var response = await sendRequest("/call/extend_snippet_validity", params)
            setLoaderForSnippetExtension(false)
            setSnackbar("Link extended by a month successfully.", "SUCCESS")
        }
        date(response.expiresAt)
        setCurrentSnippetDocument(response)
    }

    return (
        <div className="flex flex-col items-center justify-start">
            {(isFullCall || !currentSnippetDocument.expiresAt) &&
                <div className="w-full">
                    <div className="bg-coolGray-50 py-4 pl-8 w-full flex items-center  ">
                        <TextBase className="w-2/4 items-center justify-start" textColor="text-coolGray-800">
                            {isFullCall ? meetTitle : (currentSnippetDocument.snippetName ? currentSnippetDocument.snippetName : meetTitle)}
                        </TextBase>
                        {!currentSnippetDocument.expiresAt && !isFullCall &&
                            <Button
                                variant="secondary"
                                className="flex flex-row items-center justify-center !bg-red-100 p-2 rounded-xl cursor-pointer !border-coolGray-50 ml-40 "
                                onClick={() => handleLinkExtensions(false, currentSnippetDocument)}
                                startIcon={!loaderforSnippetRevoking ? <LinkBroken /> : null}
                                loading={loaderforSnippetRevoking}
                                id="revokeSnippet"
                            >
                                <TextBase textColor="text-red-600 mr-1 ">
                                    Disable
                                </TextBase>

                            </Button>
                        }
                    </div>
                    <div className="flex flex-row items-center justify-start w-full  px-8 py-5">
                        <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                            <Eye className="mr-2" />
                            <TextBase textColor={"text-coolGray-500"} className="mr-1">
                                {currentSnippetDocument.views ? currentSnippetDocument.views.length : 0}
                            </TextBase>
                            <TextBase textColor={"text-coolGray-500"}>
                                {(currentSnippetDocument.views ? currentSnippetDocument.views.length : 0) == 1 ? "View" : "Views"}
                            </TextBase>
                        </div>
                        <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                            <Clock className="mr-2" />
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.startTime) + " -"}
                            </TextBase>
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.endTime)}
                            </TextBase>
                        </div>
                        {isFullCall &&
                            <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl">
                                <TextBase className="mr-1" textColor="text-coolGray-500">
                                    Entire Call
                                </TextBase>
                            </div>}
                        <Button
                            className="flex flex-row items-center justify-start !bg-green-100 py-2 px-3 rounded-xl ml-4"
                            onClick={() => copyLinkToClipBoard(currentSnippetDocument)}
                            id={isFullCall ? "copyFullCallLinkFromDetails" : "copyNoExpirySnippetLinkFromDetails"}
                        >
                            <TextBase className="mr-1" textColor={"text-green-600"}>
                                Copy Link
                            </TextBase>
                        </Button>
                    </div>

                </div>
            }
            {!isFullCall && currentSnippetDocument.expiresAt && linkExpiryStatus &&
                <div className="w-full">
                    <div className="bg-red-100 py-4 pl-8 w-full flex flex-row ">
                        <TextBase textColor="text-coolGray-900 " className="items-center justify-start w-3/5 flex">
                            {currentSnippetDocument.snippetName ? currentSnippetDocument.snippetName : meetTitle}
                        </TextBase>
                        <div className="flex items-center flex-row  ">
                            <LinkBroken className="mr-1" />
                            <TextBase textColor="text-red-600">
                                Link expired on {dateText}
                            </TextBase>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start w-full py-5 pl-8">
                        <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                            <Eye className="mr-2" />
                            <TextBase textColor={"text-coolGray-500"} className="mr-1">
                                {currentSnippetDocument.views ? currentSnippetDocument.views.length : 0}
                            </TextBase>
                            <TextBase textColor={"text-coolGray-500"}>
                                {(currentSnippetDocument.views ? currentSnippetDocument.views.length : 0) == 1 ? "View" : "Views"}
                            </TextBase>
                        </div>
                        <div className="flex flex-row items-center justify-start bg-coolGray-50 p-2 rounded-xl mr-3">
                            <Clock className="mr-2" />
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.startTime) + " -"}
                            </TextBase>
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.endTime)}
                            </TextBase>
                        </div>

                    </div>

                </div>
            }
            {!isFullCall && currentSnippetDocument.expiresAt && !linkExpiryStatus &&
                <div className="w-full">
                    <div className="bg-green-50 py-2 pl-8 w-full flex flex-row ">
                        <TextBase textColor="text-coolGray-900 " className="items-center justify-start mt-3 w-2/4">
                            {currentSnippetDocument.snippetName ? currentSnippetDocument.snippetName : meetTitle}
                        </TextBase>
                        <div className="flex items-center flex-row  ">

                            <Button
                                variant="secondary"
                                className="!bg-green-50 mr-5 cursor-pointer !border-green-50 w-[180px] !px-0 !py-0"
                                onClick={() => handleLinkExtensions(true, currentSnippetDocument)}
                                startIcon={!loaderForSnippetExtension ? <Extend /> : null}
                                loading={loaderForSnippetExtension}
                                id="extendSnippet"
                            >

                                <TextBase textColor="text-green-600 mr-1 " data-tip data-for={"extendAccess"}>
                                    Extend access
                                </TextBase>
                                <ReactTooltip id={"extendAccess"} effect="solid" place="bottom" >
                                    {"Access will be extended by a month"}
                                </ReactTooltip>

                            </Button>

                            <Button
                                variant="secondary"
                                className="flex flex-row items-center justify-start !bg-red-100 p-2 rounded-xl cursor-pointer !border-green-50"
                                onClick={() => handleLinkExtensions(false, currentSnippetDocument)}
                                startIcon={!loaderforSnippetRevoking ? <LinkBroken /> : null}
                                loading={loaderforSnippetRevoking}
                                id="revokeSnippet"
                            >
                                <TextBase textColor="text-red-600 mr-1">
                                    Disable
                                </TextBase>

                            </Button>

                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-start w-full py-5 pl-8">
                        <div className="flex flex-row items-center justify-start bg-gray-50 p-2 rounded-xl mr-3">
                            <Eye className="mr-2" />
                            <TextBase textColor={"text-coolGray-500"} className="mr-1">
                                {currentSnippetDocument.views ? currentSnippetDocument.views.length : 0}
                            </TextBase>
                            <TextBase textColor={"text-coolGray-500"}>
                                {(currentSnippetDocument.views ? currentSnippetDocument.views.length : 0) >= 1 ? "View/s" : "Views"}
                            </TextBase>
                        </div>
                        <div className="flex flex-row items-center justify-start bg-gray-50 p-2 rounded-xl mr-3">
                            <Clock className="mr-2" />
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.startTime) + " -"}
                            </TextBase>
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                {secondsToTimeConverter(currentSnippetDocument.endTime)}
                            </TextBase>
                        </div>
                        <div className="flex flex-row items-center justify-start bg-gray-50 p-2 rounded-xl">
                            <Calender className="mr-2" />
                            <TextBase className="mr-1" textColor={"text-coolGray-500"}>
                                Expires on {dateText}
                            </TextBase>
                        </div>
                        <Button
                            className="flex flex-row items-center justify-start !bg-green-100 py-2 px-3 rounded-xl ml-4"
                            onClick={() => copyLinkToClipBoard(currentSnippetDocument)}
                            id="copyAvailableSnippetLinkFromDetails"
                        >
                            <TextBase className="mr-1" textColor={"text-green-600"}>
                                Copy Link
                            </TextBase>
                        </Button>


                    </div>
                </div>
            }
            <div className="w-full mt-2 pb-10 pl-8">
                <TextBase className="w-full mb-3" textColor={"text-coolGray-500"}>
                    Viewed by
                </TextBase>
                {currentSnippetDocument &&
                    <div className="w-full pr-10 ">
                        {currentSnippetDocument.views &&
                            ((currentSnippetDocument.views)).map((view, index) => {
                                var bg = "";
                                if (index % 2 == 0) {
                                    var bg = "bg-coolGray-50";
                                }
                                return (
                                    <div>
                                        {(view.email || view.location != "null, null, null") &&
                                            <div className={"flex flex-row items-center justify-between px-2  rounded  w-full mb-3" + " " + bg}>
                                                {view.email &&
                                                    <TextBase textColor="text-coolGray-500" className="my-1">
                                                        {view.email}
                                                    </TextBase>
                                                }
                                                {!view.email && view.location != "null, null, null" &&
                                                    <TextBase textColor="text-coolGray-500" className="my-1">
                                                        {"Someone at " + view.location + " has viewed."}
                                                    </TextBase>
                                                }

                                                <TextBase textColor="text-coolGray-500" className="my-1">
                                                    {getViewedDetails(view.viewTime)}
                                                </TextBase>

                                            </div>
                                        }
                                    </div>
                                );
                            })}
                        {!currentSnippetDocument.views &&
                            <TextBase textColor="text-coolGray-900" className="bg-coolGray-50 px-2 py-1 rounded ">
                                No views for this shared link yet.
                            </TextBase>
                        }
                    </div>
                }

            </div>
        </div>
    )

}

export default ShareCallDetails
