import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import SlackIcon from "app/styles/assets/images/icons/slack.svg";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import { SLACK_CONFIG } from "app/config";
import { syncSlack, unsyncSlack } from "app/utils/network";

const SyncSlack = ({ auth, refreshUserAction, lastSyncDate }) => {
	const [slackLoading, setSlackLoading] = useState(false);
	const isSlackSynced = auth.stringsCustomer.customerLevelIntegrations
		&& auth.stringsCustomer.customerLevelIntegrations.slackAuthFlow;

	function handleSyncSlack() {
		setSlackLoading(true);
		const url = `https://slack.com/oauth/authorize?redirect_uri=${getRedirectUrl(true)}&client_id=${SLACK_CONFIG.clientId
			}&scope=${SLACK_CONFIG.scopes}`;
		openWindowAndListenForParam(url, "Slack Integration", "code", completeSync);
	}

	async function handleUnsyncSlack() {
		setSlackLoading(true);
		await unsyncSlack();
		await refreshUserAction();
		setSlackLoading(false);
	}

	async function completeSync(code) {
		if (!code || code === "") return;
		await syncSlack(code, getRedirectUrl(true));
		await refreshUserAction();
		setSlackLoading(false);
	}


	return (
		<IntegrationCard
			key="slack"
			heading="Slack"
			subHeading="Notifications"
			bodyText="We will send Wingman notifications via slack."
			sync={handleSyncSlack}
			synced={isSlackSynced}
			unsync={handleUnsyncSlack}
			icon={SlackIcon}
			loading={slackLoading}
			lastSyncDate={lastSyncDate}
		/>
	);
};

const mapStateToProps = store => {
	return { ...store };
};

SyncSlack.defaultProps = {
	access: "user",
};

SyncSlack.propTypes = {
	refreshUserAction: PropTypes.func.isRequired,
	access: PropTypes.string,
};

export default connect(
	mapStateToProps,
	{ refreshUserAction },
)(SyncSlack);
