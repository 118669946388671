import React from 'react';

const LinearChart = ({ items, style, itemStyle }) => {
  var tot = items.reduce((p, c) => p + c.value, 0);
  return (<span style={{ display: 'inline-block', ...style }}>
    {items.map((item, i) => <span key={i} className="TeamDashboard__LinearChartItem"
      style={{ display: 'inline-block', width: `${item.value / tot * 100}%`, backgroundColor: item.color, ...itemStyle }}
    />)}
  </span>);
}

export default LinearChart;