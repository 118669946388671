import React, { useState } from "react";
import { useEffect } from "react";
import SelectWithSearch from "./SelectWithSearch";
import { isEqual } from "lodash"
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useRef } from "react";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";
import { sendRequest } from "../../utils/network";
import { Button } from "app/designSystem";
import { Popper, ClickAwayListener } from '@material-ui/core';

const SelectWithSearchNew = ({
    data,
    value,
    disabled,
    placeholder,
    handleChange,
    menuIsOpen,
    removeFilterValue,
    fieldName,
    joinPhrase,
    loadDataLocally,
    indexedFieldName,
    label,
    dontShowCloseIcon,
    position,
    isMulti,
    autoClose,
    isDealField,
    size,
    isInCallUploadForm, //TODO: Remove after the new dropdown design implementation
    popoverContainerRef,
    toUpperCase,
    hasProfilePic,
    ...props
}) => {
    const [showEditor, setShowEditor] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(loadDataLocally ? [] : data);
    const [valueTemp, setValueTemp] = useState(findMatchingObject(dataLoaded, value));
    const [showRemove, setShowRemove] = useState(false);


    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);
    const rootContainerRef = useRef()

    useEffect(() => {
        if (loadDataLocally) fetchData();
    }, [loadDataLocally]);

    useEffect(() => {
        setValueTemp(findMatchingObject(dataLoaded, value));
    }, [dataLoaded, value]);

    useEffect(() => {
        if (!showEditor) {
            if (!isEqual(findMatchingObject(dataLoaded, value), valueTemp)) {
                handleChange(valueTemp);
            }
        }
    }, [showEditor])

    useEffect(() => {
        if (!loadDataLocally)
            setDataLoaded(data);
    }, [data])

    async function fetchData() {
        const params = new URLSearchParams();
        params.append("fieldName", indexedFieldName);
        if (isDealField) {
            const values = await sendRequest("/filter/get_unique_field_values_for_opps", params);
            setDataLoaded(values);
        } else {
            const values = await sendRequest("/filter/get_unique_field_values", params);
            setDataLoaded(values);
        }
    }

    function findMatchingObject(results, values) {
        const matchesFound = [];
        if (values && results) {
            if (isMulti) {
                for (let j = 0; j < values.length; j++) {
                    for (let i = 0; i < results.length; i++) {
                        if (results[i].value === values[j]) {
                            matchesFound.push(results[i]);
                            break;
                        }
                    }
                }
            }
            else {
                results.map(result => {
                    if (result.value === values) matchesFound.push(result);
                })
            }
        }
        return matchesFound;
    }

    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        removeFilterValue(fieldName);
    }

    function onChange(options) {
        console.log("options:", options);
        // if isMulti is true, options is an array
        // and if isMulti is false, options in an object
        if (!isMulti && options && Array.isArray(valueTemp)) {
            const isFound = valueTemp.some(e => e.value === options.value);
            if (isFound) options = [];
        }
        setValueTemp(options);
        if (autoClose) setShowEditor(false);
    }

    function toggleShowEditor() {
        setShowEditor(showEditor => !showEditor);
    }
    return (
        <ClickAwayListener onClickAway={() => setShowEditor(false)}>
            <div className={"FilterField__container " + (disabled ? "bg-gray-100" : "")}
                style={
                    size === 'small' ? { height: '26px' } : {},
                    isInCallUploadForm ? { height: '50px' } : {}
                } ref={rootContainerRef}>
                <div id={fieldName} ref={!dontShowCloseIcon ? ref : null} onClick={toggleShowEditor} className={"FilterField__valueContainer" + (size == "small" ? " FilterField__valueContainerDropdownSmall" : " FilterField__valueContainerDropdown ")}>
                    {showRemove && !dontShowCloseIcon && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
                    {(valueTemp && valueTemp.length > 0) &&
                        <span
                            style={{ textTransform: "capitalize" }}
                            className={"FilterField__valueContainer__label" + (size == "small" ? " FilterField__valueContainer__labelSmall" : "")}>
                            {valueTemp.map(v => {
                                if (v.label)
                                    return toUpperCase ? v.label.toUpperCase() : v.label.toLowerCase()
                                else
                                    return ""
                            }).join(joinPhrase)}
                        </span>}
                    {!(valueTemp && valueTemp.length > 0) &&
                        <span className={`${isInCallUploadForm ? "text-gray-400" : "Placeholder"}`}>{label ? label : placeholder}</span>}
                </div>
                {!disabled && <Popper
                    className="FilterField__popup text-base"
                    anchorEl={rootContainerRef.current}
                    onClose={() => { setShowEditor(false) }}
                    open={showEditor}
                    placement={position == "right" ? "bottom-end" : "bottom-start"}
                    container={popoverContainerRef?.current}
                    modifiers={{
                        flip: { enabled: false },
                        preventOverflow: {
                            enabled: false,
                        },
                        hide: { enabled: false }
                    }
                    }>
                    <SelectWithSearch
                        menuIsOpen={menuIsOpen}
                        data={dataLoaded}
                        value={valueTemp}
                        disabled={disabled}
                        placeholder={placeholder}
                        handleChange={onChange}
                        isMulti={isMulti}
                        toUpperCase={toUpperCase}
                        hasProfilePic={hasProfilePic}
                        {...props}
                    />
                    {!autoClose && <Button className="h-8 w-24 mx-auto" onClick={() => { setShowEditor(false) }}>Done</Button>}
                </Popper>}
            </div>
        </ClickAwayListener >
    );
};

SelectWithSearchNew.defaultProps = {
    menuIsOpen: true,
    async: false,
    joinPhrase: " or ",
    loadDataLocally: false,
    position: 'left',
    isMulti: true,
    autoClose: false,
    size: "large",
    isDealField: false,
    isInCallUploadForm: false
}

export default SelectWithSearchNew;
