import React, { useState } from "react";
import { CallPreviewHeader } from "./CallPreviewComponent/CallPreviewHeader";
import { _formatSecondsToTime } from "../../utils/helpers";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { TextSM } from "app/designSystem"
import WordsHighlighter from "app/components/common/WordsHighlighter";
import CallSnippetPreview from "app/components/call/CallSnippetPreview";
import { PlayIcon } from "app/designSystem/icons";
import { clone } from "lodash";
import { getSplitedSubstringOfTurn, splitTurnWithMatchingWord } from "../../utils/helpers";
import { ACCOUNT_VIEW_SEARCH_FIELDS } from "app/constants";
import { metrics } from "app/utils/metrics";

export const CallPreviewSearchResult = ({ matchedWords, callResult, searchedQuery, searchType }) => {
    const [showCallPreview, setShowCallPreview] = useState(false);
    const [callSnippetInfo, setCallSnippetInfo] = useState({});
    const { time, duration, callId, callType, title, matchedTurns } = callResult;
    const [transcriptShowOnScreen, setTranscriptShowOnScreen] = useState(matchedTurns?.slice(0, 3));

    const { getUserName } = useDataHelper();

    const getMoreSentenceForHighlight = (wholeTurn = "", highlightText = "") => {
        highlightText = highlightText.replace("<em>", "");
        highlightText = highlightText.replace("</em>", "");
        let cloneHighlightText = clone(highlightText);
        if (highlightText.split(' ').length >= 30) return highlightText; // if highlight text is too long, just return it

        const firstIndexOfHighlight = wholeTurn.indexOf(highlightText);
        const lastIndexOfHighlight = firstIndexOfHighlight + highlightText.length - 1;
        const splitedconversationBeforeHighlight = getSplitedSubstringOfTurn(wholeTurn, 0, firstIndexOfHighlight);
        const splitedconversationAfterHighlight = getSplitedSubstringOfTurn(wholeTurn, lastIndexOfHighlight + 1, wholeTurn.length);

        const startOfSentence = splitedconversationBeforeHighlight[splitedconversationBeforeHighlight.length - 1] ?? ""
        const endOfSentence = splitedconversationAfterHighlight[0] ?? ".";
        return startOfSentence + (startOfSentence ? ". " : "") + cloneHighlightText + endOfSentence;
    }

    const getWholeSentenceFromWords = (wholeTurn = "", matchedWordArray) => {
        let indexes = splitTurnWithMatchingWord(wholeTurn, matchedWordArray);

        let matchedSentencesArray = [];
        for (let indexObj of indexes) {
            let sentenceLastIndex = wholeTurn?.slice(indexObj?.lastIndex, wholeTurn?.length).indexOf(".") === -1 ? wholeTurn.length
                : wholeTurn.length - wholeTurn?.slice(indexObj?.lastIndex, wholeTurn?.length).split("").reverse().join("").lastIndexOf(".");
            let sentenceFirstIndex = wholeTurn?.slice(0, indexObj?.firstIndex).lastIndexOf(".") === -1 ? -1 : wholeTurn?.slice(0, indexObj?.firstIndex).lastIndexOf(".");
            let matchedSentence = wholeTurn?.substring(sentenceFirstIndex + 1, sentenceLastIndex);
            matchedSentencesArray.push(matchedSentence);
        }
        return matchedSentencesArray;
    }


    function openCallPage() {
        let turnIdNeeded = searchType === "TRANSCRIPT" ? matchedTurns[0]?.id : "";
        metrics.logEventHeap("openCallPageAccountViewSearch", { search_type: searchType, search_query: searchedQuery });
        window.open("/call/" + callId + "#" + turnIdNeeded + "&query=" + encodeURIComponent(searchedQuery) + "&type=" + ACCOUNT_VIEW_SEARCH_FIELDS[searchType].type);
    }

    function handleTimeClick(startTime) {
        metrics.logEventHeap("playCallAccountViewSearch", { search_type: searchType, search_query: searchedQuery });
        setCallSnippetInfo({ startTime: startTime - 10, endTime: startTime + 30 });
        setShowCallPreview(true);
    }

    const toggleShowQuotedText = () => transcriptShowOnScreen?.length === 3 ? setTranscriptShowOnScreen(matchedTurns) : setTranscriptShowOnScreen(matchedTurns.slice(0, 3));

    return (
        <div className="CallPreview">
            <CallPreviewHeader openCallPage={openCallPage} callTime={time} callType={callType} meetingTitle={title} />
            <div className="CallPreview__summaryContainer">
                <div className="CallPreview__summaryContainer__bottom">
                    <span className="CallPreview__summaryContainer__header CallPreview__summaryContainer__headerCaps">{searchType === "TRANSCRIPT" ? "Transcript" : "Category"} Match</span>
                    <div className="Call__HighlightContainer CallPreview__summaryContainer__sectionPaddedList">
                        {transcriptShowOnScreen?.map((turn, index) => {
                            return (
                                <React.Fragment key={"Transcript_Result_for_Call_Result" + index}>
                                    <div className="flex">
                                        <div onClick={() => handleTimeClick(turn?.startTime)} className="flex items-center rounded mr-2 bg-blueGray-50 p-1 text-gray-400 hover:text-wingman-brand w-[3.5rem] justify-center cursor-pointer">
                                            <TextSM className="text-current">
                                                {_formatSecondsToTime(turn.startTime)}
                                            </TextSM>
                                            <div>
                                                <PlayIcon className="h-3 w-3 ml-1" />
                                            </div>
                                        </div>
                                        <div className="Highlight__header">
                                            {
                                                turn?.speakerUserId ? <span className={"Highlight__speaker"}> {turn?.speakerName || getUserName(turn?.speakerUserId)} </span> : <span className={"Highlight__speaker Highlight__speakerCustomer"}> {turn?.speakerName || "Customer"} </span>
                                            }
                                        </div>
                                    </div>

                                    <div className="ml-16">
                                        {turn?.highlights?.length > 0 ?
                                            turn?.highlights?.map((highlightText, index) => {
                                                return (
                                                    <div className="Highlight" key={"Highlight_for_Transcript_Result_for_Call_Result" + highlightText + index}>
                                                        <span className="Highlight__text">
                                                            <WordsHighlighter wordsList={matchedWords} text={getMoreSentenceForHighlight(turn?.str, highlightText)} />
                                                        </span>
                                                    </div>
                                                );
                                            })
                                            : (
                                                <div className="Highlight">
                                                    <span className="Highlight__text">
                                                        <WordsHighlighter wordsList={getWholeSentenceFromWords(turn?.str, matchedWords)} text={turn?.str} />
                                                    </span>
                                                </div>
                                            )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        {matchedTurns?.length > 3 && (
                            <div className="EmailPreview__showQuotedTextDiv">
                                <span onClick={toggleShowQuotedText} className="EmailPreview__showQuotedText">{(transcriptShowOnScreen.length > 3 ? "Hide" : "Show") + " Entire Matched Transcript"}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {
                showCallPreview && <CallSnippetPreview
                    isOpen={showCallPreview}
                    callId={callId}
                    callTitle={title}
                    startTime={callSnippetInfo.startTime}
                    endTime={callSnippetInfo.endTime}
                    callDuration={duration}
                    hideSnippet={() => setShowCallPreview(false)}
                />
            }
        </div >
    );
};

CallPreviewSearchResult.propTypes = {}
CallPreviewSearchResult.defaultProps = {}
