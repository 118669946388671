import React, { useEffect } from 'react'
import { daysOfWeek } from 'app/components/dealView/DealsAlertsUtils';
import { timeOptionsForSlack } from 'app/components/dashboards/dateUtils';
import SelectWithSearchNew from 'app/components/filter/SelectWithSearchNew';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { setTimezoneOffset } from 'app/utils/network/users';

const NotificationTimeSelector = ({ 
    filterAlert, 
    setFilterAlert, 
    popoverContainerRef 
}) => {

    const alertFrequencyOptions = [
        {
            value: "HOURLY",
            label: 'Every Hour'
        },
        {
            value: "DAILY",
            label: 'Every Day'
        },
        {
            value: "WEEKLY",
            label: 'Weekly'
        },
        {
            value: "BI_WEEKLY",
            label: 'Every 2 Weeks'
        }
    ]

    useEffect(() => {
        setTimezoneOffset()
    }, [])

    function onChange(field, value) {
        let filterAlertLocal = cloneDeep(filterAlert);
        // ignore alerDay if alertFrequency is DAILY
        if (field === "alertFrequency" && value === "DAILY")
            filterAlertLocal["alertDay"] = 0;
        if (field === "slackChannelId")
            filterAlertLocal[field] = (value === "DM" ? null : value)
        else
            filterAlertLocal[field] = value;
        if (field !== "isEnabled")
            filterAlertLocal.isEnabled = true;
        setFilterAlert(filterAlertLocal);
    }
    return (
        <div className="flex items-center pl-7">
            <span className="ml-1">
                <SelectWithSearchNew
                    key="alertFrequency"
                    fieldName="alertFrequency"
                    data={alertFrequencyOptions}
                    value={filterAlert.alertFrequency}
                    label="Select Frequency"
                    handleChange={alertFrequency => onChange("alertFrequency", alertFrequency.value)}
                    dontShowCloseIcon={true}
                    isMulti={false}
                    autoClose={true}
                    popoverContainerRef={popoverContainerRef}
                />
            </span>
            {(filterAlert.alertFrequency === "WEEKLY" ||
                filterAlert.alertFrequency === "BI_WEEKLY") &&
                <span className="max-w-1/2 ml-1">
                    <SelectWithSearchNew
                        key="alertDay"
                        fieldName="alertDay"
                        data={daysOfWeek}
                        value={filterAlert.alertDay}
                        label="Select Day"
                        handleChange={alertDay => onChange("alertDay", alertDay.value)}
                        dontShowCloseIcon={true}
                        position="right"
                        isMulti={false}
                        autoClose={true}
                        size="small"
                        popoverContainerRef={popoverContainerRef}
                    />
                </span>}
            {filterAlert.alertFrequency !== "HOURLY" &&
                <span className="max-w-1/2 ml-1">
                    <SelectWithSearchNew
                        key="alertTime"
                        fieldName="alertTime"
                        data={timeOptionsForSlack}
                        value={filterAlert.alertTime}
                        label="Select Time"
                        handleChange={alertTime => onChange("alertTime", alertTime.value)}
                        dontShowCloseIcon={true}
                        position="right"
                        isMulti={false}
                        autoClose={true}
                        size="small"
                        toUpperCase
                        popoverContainerRef={popoverContainerRef}
                    />
                </span>}
        </div>
    )
}


export default NotificationTimeSelector;

NotificationTimeSelector.propTypes = {
    filterAlert: PropTypes.object.isRequired,
    setFilterAlert: PropTypes.func.isRequired
}