import {
  GET_ONBOARDING_SKIPPED,
  SET_ONBOARDING_STATUS,
  SET_ANY_USER_CALLS,
  SET_ANY_CUSTOMER_CALLS,
  SET_USER_PREFERENCES,
  SET_LAST_RELOAD,
} from "./actionTypes";

export function setOnboardingSkipped(details) {
  console.log("setting onboarding skipped to", details);
  return {
    details,
    type: GET_ONBOARDING_SKIPPED
  };
}

export function setOnboardingFlowStatus(flowStatus) {
  console.log("setting onboarding status to", JSON.stringify(flowStatus));
  return {
    flowStatus,
    type: SET_ONBOARDING_STATUS
  };
}

export function setAnyUserCalls(anyCalls) {
  return {
    anyCalls,
    type: SET_ANY_USER_CALLS
  };
}

export function setAnyCustomerCalls(anyCalls) {
  return {
    anyCalls,
    type: SET_ANY_CUSTOMER_CALLS
  };
}

export function setLastReload(version) {
  return {
    version,
    type: SET_LAST_RELOAD,
  };
}

export function setUserPreferences(preferences) {
  return {
    preferences,
    type: SET_USER_PREFERENCES,
  }
}