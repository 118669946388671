export const getMenuPosition = (anchorEl, menuRef, position) => {
    const {
        height: heightOfAnchor,
        width: widthOfAnchor,
        top: topOfAnchor,
        left: leftOfAnchor
    } = anchorEl.getBoundingClientRect();

    const {
        offsetWidth: menuWidth,
        offsetHeight: menuHeight
    } = menuRef.current;

    let top, left;

    if (position === "bottom") {
        top = topOfAnchor + heightOfAnchor;
        left = leftOfAnchor - menuWidth / 2 + widthOfAnchor / 2;
    } else if (position === "bottom-left") {
        top = topOfAnchor + heightOfAnchor;
        left = leftOfAnchor;
    } else if (position === "bottom-right") {
        top = topOfAnchor + heightOfAnchor;
        left = leftOfAnchor - (menuWidth - widthOfAnchor);
    } else if (position === "top") {
        top = topOfAnchor - menuHeight;
        left = leftOfAnchor - menuWidth / 2 + widthOfAnchor / 2;
    } else if (position === "top-left") {
        top = topOfAnchor - menuHeight;
        left = leftOfAnchor;
    } else if (position === "top-right") {
        top = topOfAnchor - menuHeight;
        left = leftOfAnchor - (menuWidth - widthOfAnchor);
    } else if (position === "left") {
        top = topOfAnchor - menuHeight / 2 + heightOfAnchor / 2;
        left = leftOfAnchor - menuWidth;
    } else if (position === "left-top") {
        top = topOfAnchor;
        left = leftOfAnchor - menuWidth;
    } else if (position === "left-bottom") {
        top = topOfAnchor - heightOfAnchor;
        left = leftOfAnchor - menuWidth;
    } else if (position === "right") {
        top = topOfAnchor - menuHeight / 2 + heightOfAnchor / 2;
        left = leftOfAnchor + widthOfAnchor;
    } else if (position === "right-top") {
        top = topOfAnchor;
        left = leftOfAnchor + widthOfAnchor;
    } else if (position === "right-bottom") {
        top = topOfAnchor - heightOfAnchor;
        left = leftOfAnchor + widthOfAnchor;
    }

    return {
        top,
        left
    };
};