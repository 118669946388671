import React, { useState, useEffect } from "react";
import { DocumentFields } from "app/components/settings";
import { getDocTypes } from "../../utils/network";
import { useSelector } from "react-redux";

const CrmSettings = () => {
  const [docTypes, setDocTypes] = useState([]);
  const auth = useSelector(store => store.auth)

  async function fetchResults() {
    const response = await getDocTypes();
    setDocTypes(response);
  }

  useEffect(() => {
    fetchResults();
  }, []);

  return (
    <div className="Settings__crmContainer">
      <span className="Settings__header">Import CRM Fields</span>
      <span className="Settings__headerExplanation">Choose CRM fields to be imported into Wingman. These will be linked to calls and enable users to filter calls based on their values.</span>
      {docTypes.map(docType => {
        return <DocumentFields key={docType} type={docType} disabled={auth.isAdmin ? false : true} />;
      })}
    </div>
  );
};

export default CrmSettings;
