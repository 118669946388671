import React from "react";
import { CommentCountIcon, TextBase } from "app/designSystem";
import classNames from "classnames";

export default function CommentCount({ count, className }) {
  return (
    <div className={classNames("relative py-2 px-3 bg-brand-blue-4 rounded-[2.5rem] flex items-center", className)}>
      <CommentCountIcon className="w-6 h-6" />
      <TextBase className="text-wingman-brand">
        {count}
      </TextBase>
    </div>
  );
}
