import React from 'react';
import { useState, useEffect } from 'react';
import { _getActivitySize, _findBarHeight } from '../../utils/ActivityHelper';


const ActivityBarDealView = ({ propId, activityForOneDay, barSize }) => {
    const [activityHeight, setActivityHeight] = useState({});

    useEffect(() => {
        setActivityHeight(getActivityHeight());
    }, [activityForOneDay]);

    function getActivityHeight() {
        return ({
            repActivity: _findBarHeight(_getActivitySize(activityForOneDay.repActivity)),
            customerActivity: _findBarHeight(_getActivitySize(activityForOneDay.customerActivity))
        })
    }

    return (
        <div id={propId} style={{ display: "flex" }}>
            <div className={"DealCard__ActivityBarDealView" + (barSize == "Big" ? " DealCard__ActivityBarDealView__Big" : "")}>
                <div className="DealCard__ActivityBarDealView__segmentUp">
                    <div className="DealCard__ActivityBarDealView__customerActivity" style={{ height: activityHeight.customerActivity }}></div>
                </div>
                <div className="DealCard__ActivityBarDealView__segmentDown">
                    <div className="DealCard__ActivityBarDealView__repActivity" style={{ height: activityHeight.repActivity }}></div>
                </div>
            </div>
        </div >
    );
}


ActivityBarDealView.propTypes = {}
ActivityBarDealView.defaultProps = {
    barSize: ""
}
export default ActivityBarDealView;