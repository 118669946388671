import { sendRequest, sendGetRequest } from 'app/utils/network';

export const creatFolder = async ({ name, description = '', folderId = 'ROOT', isPrivate = false, subscribedUsers = [] }) => {
    const params = new URLSearchParams();
    params.append('name', name.trim());
    params.append('description', description?.trim());
    params.append('parentFolder', folderId);
    params.append('isPrivate', isPrivate);
    params.append('subscribedUsers', subscribedUsers);

    const result = await sendRequest('/folder/add_folder', params);
    return result;

}

export const updateFolderMetadata = async ({ name, description = '', folderId = 'ROOT', isPrivate = false, subscribedUsers = [] }) => {
    const params = new URLSearchParams();
    params.append('folderId', folderId);
    params.append('name', name.trim());
    params.append('description', description?.trim());
    params.append('isPrivate', isPrivate);
    params.append('subscribedUsers', subscribedUsers);

    const result = await sendRequest('/folder/update_folder_metadata', params);
    return result;

}

export const getAllFolders = async () => {
    const response = await sendGetRequest('/folder/get_all_folders');
    return response.result;
}

export const getAllGametapes = async () => {
    const response = await sendGetRequest('/gametape/get_all_gametapes');
    return response.result;
}

export const getAllTags = async () => {
    return await sendGetRequest('/gametape/get_all_tags');
}

export const getFolderInformation = async ({ folderId }) => {
    const response = await sendGetRequest(`/folder/get_folder_information?parentFolder=${folderId}`);
    return response.result;
}

export const getFolderChildren = async ({ folderId = 'ROOT' }) => {
    const response = await sendGetRequest(`/folder/get_folder_children?parentFolder=${folderId}`);
    return response.result;
}

export const getFolderPath = async ({ folderId }) => {
    const response = await sendGetRequest(`/folder/get_folder_path?folderId=${folderId}`);
    return response.result;
}

export const subscribeFolder = async ({ folderId, subscribe }) => {
    const params = new URLSearchParams();
    params.append("parentFolder", folderId);
    params.append("subscribe", subscribe);

    const result = await sendRequest('/folder/subscribe_folder', params);
    return result;
}

export const subscribeAnotherUser = async ({ folderId, subscribe, userId }) => {
    const params = new URLSearchParams();
    params.append("parentFolder", folderId);
    params.append("subscribe", subscribe);
    params.append("userId", userId);

    const result = await sendRequest('/folder/subscribe_another_user', params);
    return result;
}

export const getGameTapes = async ({ folderId }) => {
    const response = await sendGetRequest(`/gametape/get_gametapes?parentFolder=${folderId}`);
    return response.result;
}

export const getGameTapeInformation = async ({ gametapeId }) => {
    const response = await sendGetRequest(`/gametape/get_gametape_information?gametapeId=${gametapeId}`);
    return response.result;
}

export const addGameTapeToFolder = async ({ folderId, callId, name, description = "", tags = [], duration }) => {
    const params = new URLSearchParams();
    params.append("callId", callId);
    params.append("folderId", folderId);
    params.append("name", name.trim());
    params.append("description", description?.trim());
    params.append("tags", tags);
    params.append("duration", duration);

    const result = await sendRequest('/gametape/add_gametape', params);
    return result;
}

export const addSnippetToFolder = async ({ folderId, callId, name, description = "", tags = [], startTime, endTime }) => {
    const params = new URLSearchParams();
    params.append("callId", callId);
    params.append("folderId", folderId);
    params.append("name", name.trim());
    params.append("description", description?.trim());
    params.append("tags", tags);
    params.append("startTime", startTime);
    params.append("endTime", endTime);

    const result = await sendRequest('/gametape/add_snippet', params);
    return result;
}

export const addNewTag = async ({ tag }) => {
    const params = new URLSearchParams();
    params.append("tag", tag);

    const result = await sendRequest('/gametape/add_tag', params);
    return result;
}

export const updateGameTapeMetadata = async ({ gametapeId, name, description = "", tags = [] }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("name", name.trim());
    params.append("description", description?.trim());
    params.append("tags", tags);

    const result = await sendRequest('/gametape/update_gametape_metadata', params);
    return result;
}

export const removeGameTape = async ({ folderId, gametapeId }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("folderId", folderId);

    const result = await sendRequest('/gametape/remove_gametape', params);
    return result;
}

export const removeFolder = async ({ folderId }) => {
    const params = new URLSearchParams();
    params.append("folderId", folderId);

    const result = await sendRequest('/folder/remove_folder', params);
    return result;
}

export const updateOrder = async ({ folderId, orderList }) => {
    const params = new URLSearchParams();
    params.append("parentFolder", folderId);
    params.append("orderList", orderList);

    const result = await sendRequest('/folder/update_order', params);
    return result;
}


export const moveFolder = async ({ folderId, parentFolderId = 'ROOT' }) => {
    const params = new URLSearchParams();
    params.append("folderId", folderId);
    params.append("newParentId", parentFolderId);

    const result = await sendRequest('/folder/move_folder', params);
    return result;
}

export const moveGameTape = async ({ gametapeId, parentFolderId }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("newParentId", parentFolderId);

    const result = await sendRequest('/gametape/move_gametape', params);
    return result;
}

export const copyGameTape = async ({ gametapeId, parentFolderId }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("newParentId", parentFolderId);

    const result = await sendRequest('/gametape/copy_gametape', params);
    return result;
}

export const addStats = async ({ gametapeId }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);

    const result = await sendRequest('/gametape_statistics/add_stats', params);
    return result;
}

export const updateProgress = async ({ gametapeId, progress, duration }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("progress", progress);
    params.append("duration", duration);

    const result = await sendRequest('/gametape_statistics/update_progress', params);
    return result;
}

export const updateLike = async ({ gametapeId, liked }) => {
    const params = new URLSearchParams();
    params.append("gametapeId", gametapeId);
    params.append("liked", liked);

    const result = await sendRequest('/gametape_statistics/update_like', params);
    return result;
}

export const folderIdMapping = async () => {
    return await sendRequest('/gametape/get_all_folder_id_mapping');
}

export const getTopViews = async (gametapesList) => {
    const params = new URLSearchParams();
    params.append("gametapesList", gametapesList);

    const result = await sendRequest('/gametape_statistics/get_top_views', params);
    return result;
}

export const addSharedUsers = async ({ folderId, sharedUsers }) => {
    const params = new URLSearchParams();
    params.append("parentFolder", folderId);
    params.append("newSharedUsersList", sharedUsers);

    const result = await sendRequest('/folder/add_shared_users', params);
    return result;
}

export const removeSharedUsers = async ({ folderId, userId }) => {
    const params = new URLSearchParams();
    params.append("parentFolder", folderId);
    params.append("userId", userId);

    const result = await sendRequest('/folder/remove_shared_users', params);
    return result;
}

export const getSignedUrls = async (callId) => {
    const params = new URLSearchParams();
    params.append("callId", callId);

    const response = await sendRequest("/call/get_gametape_signed_urls", params);
    return response;
}