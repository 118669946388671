import "./fonts/MaisonNeue-Book.woff";
import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import {
  BrowserRouter as Router
} from "react-router-dom";
import {
  MuiThemeProvider,
  createTheme
} from "@material-ui/core/styles";
import {
  Provider
} from "react-redux";
import {
  PersistGate
} from "redux-persist/integration/react";
import {
  persistStore,
  persistReducer
} from "redux-persist";
import {
  applyMiddleware,
  compose,
  createStore
} from "redux";

import theme from "app/theme";
import rootReducer from "app/reducers";
import App from "app/containers/App";

import "./custom.scss";
import "./tailwindBuilt.css";


// Material-ui settings
const muiTheme = createTheme({
  ...theme,
  typography: {
    fontFamily: [
      "MaisonNeue, sans-serif"
    ]
  }
});

const persistConfig = {
  key: "stringsai",
  storage,
  whitelist: ["persisted", "auth", "navigate"],
};

// Redux Settings
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistReducer(persistConfig, rootReducer),
  composeEnhancers(applyMiddleware(thunk)),
);

class AppProvider extends React.Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    return (
      <Provider store={store} >
        <PersistGate loading={null} persistor={persistStore(store)} >
          <App />
        </PersistGate>
      </Provider >
    );
  }
}

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme} >
    <Router>
      <AppProvider />
    </Router>
  </MuiThemeProvider >,
  document.getElementById("root"),
);