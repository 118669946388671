import { NAVIGATE } from "app/actions/actionTypes";

const initialState = {
  pages: {},
};

export default function(state = initialState, payload) {
  
  switch (payload.type) {
    case NAVIGATE:
      state.pages[payload.details.page] = payload.details.data
      return {
        ...state,
        pages: state.pages,
      };
    default:
      return state;
  }
}
