import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataSafely } from 'app/utils/dataUtils';
import { Transcript } from "app/components/call";
import { PlayerContext } from "app/containers/Call";
import { Player } from "app/components/player";
import { Player as VideoPlayer, ControlBar, LoadingSpinner, BigPlayButton, Shortcut, Sour } from 'video-react';
import { CircularProgress } from '@material-ui/core';
import { getCall } from '../../utils/network';
import { SET_FULLSCREEN_VIDEO, SET_USER_PREFERENCES, SET_VIDEO_MINIMIZE } from '../../actions/actionTypes';
import 'video-react/dist/video-react.css';
import MinimizeIcon from "app/styles/assets/images/icons/callReview/minimize.svg";
import ExpandIcon from "app/styles/assets/images/icons/callReview/expand.svg";
import useSnackbar from '../../utils/hooks/useSnackbar';
import { requestFullscreen, exitFullscreen } from 'app/utils/helpers';
import { getMediaUrl, getNextTimeToPlay, getTurnForTime, isTurnSmallEnough } from '../../reducers/call';
import { Text2XL } from 'app/designSystem';
//import HLSSource from '../../utils/HLSSource';

const TranscriptVideoPlayer = ({
  expandCommentArea,
  handleHighlightsClick,
  _addTranscriptLabels,
  addRefTurn,
  setActiveNote,
  autoScroll,
  ignoreScroll,
  setAutoScroll,
  searchTranscript,
  showTranscript,
  isCallEmbed,
  ...props
}) => {
  const call = useSelector(store => store.call);
  const userPreferences = useSelector(store => store.persisted.userPreferences);
  const [videoState, setVideoState] = useState(null);
  const fullscreenVideo = useSelector(store => store.screen.fullscreenVideo);
  const playerProps = useContext(PlayerContext);
  const minVersion = process.env.REACT_APP_NODE_ENV === "production" ? 3 : 1;
  const videoAvailable = fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.version", null) >= minVersion;
  const [videoProcessed, setVideoProcessed] = useState(fetchDataSafely(call, "details.callDataDocument.videoRecordingStatus.callStatus", false) === "POST_PROCESSING_DONE");
  const [showVideo, setShowVideo] = useState(props.isSharedCall || (videoProcessed && fetchDataSafely(userPreferences, "showVideo", false)));
  const [minimizeVideo, setMinimizeVideo] = useState(false);
  const audioUrl = useSelector(store => store.player.playback.url);
  const selectedSpeakers = useSelector(store => store.player.playback.selectedSpeakers);
  const [activeTurn, setActiveTurn] = useState(0);
  const [url, setUrl] = useState(audioUrl);
  const dispatch = useDispatch();
  let videoPlayerRef = null;
  const timer = useRef(null);
  const { setSnackbar } = useSnackbar();
  const customerType = useSelector(store => fetchDataSafely(store.auth, "stringsCustomer.customerType", "GUEST"));
  const summaryData = fetchDataSafely(call, "details.summary", null);
  const conversationTurnData = fetchDataSafely(call, "details.callData.conversationTurn", []);
  let poster = "https://image.freepik.com/free-vector/colourful-music-sound-waves-background_23-2148424538.jpg";
  if (videoAvailable && (videoProcessed || !props.isSharedCall)) {
    poster = null;
  }

  useEffect(() => {
    const seek = playerProps.seek;
    const turn = getTurnForTime(conversationTurnData, seek);
    if (turn && seek >= turn.startTime && seek <= turn.endTime) {
      if (!selectedSpeakers || selectedSpeakers.includes(turn.speakerId) || isTurnSmallEnough(turn)) {
        setActiveTurn(turn.turnId);
      }
      else {
        console.log("this speaker is not selected. Skipping to next selected turn");
        const nextTime = getNextTimeToPlay(conversationTurnData, seek, selectedSpeakers);
        playerProps.setSeek(nextTime);
      }
    }
  }, [playerProps.seek, conversationTurnData, selectedSpeakers]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange webkitfullscreenchange mozfullscreenchange MSFullscreenChange", handleFullScreenChange);
    }
  }, []);

  useEffect(() => {
    try {
      if (fullscreenVideo) {
        requestFullscreen(document.getElementById("Call__transcriptVideoPlayerContainer"))
      }
      else if (
        (document.fullscreenElement !== null && document.exitFullscreen) ||
        (document.webkitFullscreenElement !== null && document.webkitExitFullscreen) ||
        (document.mozFullScreenElement !== null && document.mozExitFullScreen) ||
        (document.msFullscreenElement !== null && document.msExitFullscreen)
      ) {
        exitFullscreen();
      }
    }
    catch (e) {
      setSnackbar("Fullscreen is not supported in your device.", "ERROR");
      console.log(e);
    }
  }, [fullscreenVideo]);

  function handleFullScreenChange(e) {
    if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
      console.log("entering fullscreen");
    }
    else {
      dispatch({ fullscreenVideo: false, type: SET_FULLSCREEN_VIDEO });
    }
  }

  useEffect(() => {
    if (videoPlayerRef !== null) {
      var url = getMediaUrl(call);
      setUrl(url);
      const preVideoState = videoState;
      videoPlayerRef.load();
      if (preVideoState) {
        setVideoState(preVideoState);
        videoPlayerRef.seek(preVideoState.currentTime);
        videoPlayerRef.playbackRate = preVideoState.playbackRate;
        if (playerProps.playing) videoPlayerRef.play();
      }
    }
  }, [videoPlayerRef, videoAvailable])

  useEffect(() => {
    dispatch({ videoMinimized: minimizeVideo, type: SET_VIDEO_MINIMIZE });
  }, [minimizeVideo])

  useEffect(() => {
    if (props.isSharedCall) return;
    if (videoAvailable && !videoProcessed) {
      if (timer.current === null) {
        timer.current = setInterval(async () => {
          var callDataDocument = await getCall(call.details.callDataDocument.callId);
          setVideoProcessed(fetchDataSafely(callDataDocument, "videoRecordingStatus.callStatus", false) === "POST_PROCESSING_DONE");
        }, 60 * 1000);
      }
    } else {
      if (timer.current !== null) {
        clearInterval(timer);
        timer.current = null;
      }
    }
    return () => {
      if (timer.current != null) {
        clearInterval(timer);
        timer.current = null;
      }
    }
  }, [videoAvailable, videoProcessed])

  useEffect(() => {
    if (videoPlayerRef !== null) {
      playerProps.setVideoPlayerRef(videoPlayerRef);
      videoPlayerRef.subscribeToStateChange(handleVideoStateChange);
    }
  }, [videoPlayerRef]);

  function handleVideoStateChange(videoState) {
    if (videoState) {
      playerProps.setVideoState(videoState);
      setVideoState(videoState)
    }
  }

  function handleSetShowVideo(bool) {
    if (bool) {
      setMinimizeVideo(false);
    }
    setAutoScroll(true);
    dispatch({ type: SET_USER_PREFERENCES, preferences: { showVideo: bool } });
    setShowVideo(bool);
  }

  function handleVideoMaximize() {
    if (document.getElementById("VideoContainer")) {
      document.getElementById("VideoContainer").classList.add("VideoMaximize");
      setTimeout(() => {
        setMinimizeVideo(false);
        console.log("do noting");
      }, 200);
    } else
      console.log("do noting");
    //setMinimizeVideo(false);
  }

  return (
    <div id="Call__transcriptVideoPlayerContainer" className={`${props.isSharedCall ? "Call__transcriptVideoPlayerContainerShared" : "Call__transcriptVideoPlayerContainer"}`}>
      <div className={`${((!videoProcessed && !props.isSharedCall) || minimizeVideo) ? "hidden" : (showVideo ? "Call__videoContainer" : "Call__videoContainerCorner")} ${fullscreenVideo ? "Call__videoContainerFullscreen" : ""} ${isCallEmbed ? 'embed' : ''}`}>
        {!minimizeVideo && !showVideo && <img onClick={() => setMinimizeVideo(true)} className="Call__videoContainer__minimize" src={MinimizeIcon} />}
        <VideoPlayer
          ref={videoPlayer => videoPlayerRef = videoPlayer}
          height="100%"
          width="100%"
          poster={poster}
          fluid={false}
          aspectRatio="16:9"
        >
          <source src={(props.isSharedCall && props.url) ? props.url : url} />

          <ControlBar disableCompletely />
          <LoadingSpinner />
          <BigPlayButton className="hidden" position="center" />
          <Shortcut clickable={true} dblclickable={false} />
        </VideoPlayer>
      </div>
      {minimizeVideo &&
        <div id="VideoContainer" onClick={handleVideoMaximize} className="Call__videoContainer__minimizedVideo">
          <img src={ExpandIcon} className="Call__videoContainer__expand" />
          <span className="Call__videoContainer__expandText">Expand video</span>
        </div>
      }

      {showVideo && videoAvailable && !videoProcessed && !props.isSharedCall &&
        <div style={{ color: "white", display: "flex", flexDirection: "column", padding: "20px", fontSize: "12px", textAlign: "center", alignItems: "center", justifyContent: "center", backgroundColor: "black", height: "100%", width: "100%" }}>
          <CircularProgress size={25} color="secondary" />
          <span style={{ margin: "10px 0px" }}>
            Video is being processed.<br /> Please wait for a few mins and try again.
          </span>
        </div>
      }

      {!props.isSharedCall &&
        <Transcript
          setActiveHighlightTab={props.setActiveHighlightTab}
          callId={call.details.callDataDocument.callId}
          highlightsData={fetchDataSafely(call, "callDetails.callData.highlightsData")}
          customerType={customerType}
          conversationTurnData={conversationTurnData}
          summaryData={summaryData}
          expandCommentArea={expandCommentArea}
          handleHighlightsClick={handleHighlightsClick}
          _addTranscriptLabels={_addTranscriptLabels}
          addRefTurn={addRefTurn}
          setActiveNote={setActiveNote}
          autoScroll={autoScroll}
          setAutoScroll={setAutoScroll}
          ignoreScroll={ignoreScroll}
          searchTranscript={searchTranscript}
          hideTranscript={showVideo}
          activeTurn={activeTurn}
        />}

      <Player
        key={fetchDataSafely(call, "details.callDataDocument.callId", "noCall")}
        showVideo={showVideo}
        setShowVideo={handleSetShowVideo}
        isSharedCall={props.isSharedCall}
        videoAvailable={videoAvailable}
        videoProcessed={videoProcessed}
        {...playerProps}
      />
    </div >
  );
}
TranscriptVideoPlayer.propTypes = {}
TranscriptVideoPlayer.defaultProps = {
  showTranscript: true,
  url: null,
  isSharedCall: false,
}
export default TranscriptVideoPlayer