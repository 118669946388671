import React, { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, IconButton, CircularProgress, Checkbox, Input, Backdrop } from "@material-ui/core";
import { getShareableLink } from "app/utils/network";
import { fetchDataSafely } from "app/utils/dataUtils";
import { ReactComponent as CloseIcon } from "app/styles/assets/images/icons/closeCircle.svg";
import { ReactComponent as AttachmentLink } from "app/styles/assets/images/icons/AttachmentLink.svg";
import { ReactComponent as ShareLine } from "app/styles/assets/images/icons/ShareLine.svg";
import { SmallArrowLeftIcon } from "app/designSystem";
import { ReactComponent as Users } from "app/styles/assets/images/icons/Users.svg";
import { useEffect } from "react";
import { _formatSecondsToMinutes } from "../../utils/helpers";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { getShareableCallId } from "../../utils/network/callSharing";
import { sendRequest } from "../../utils/network";
import ShareCallFormTrimmer from "./ShareCallFormTrimmer";
import { TextBase, TextXL, Button, CheckBox, TextLarge } from "app/designSystem"
import classNames from "classnames";
import ShareCallManagement from "./ShareCallManagement";
import { TrashBinIcon, ActiveTrashBinIcon, TickInsideCircleIcon } from "app/designSystem";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import DisableFullCallLinkModal from "./DisableFullCallLinkModal";
import ReactTooltip from "react-tooltip";
import { updateNotificationReceiversForSnippet } from "app/utils/network"

const ShareCallForm = ({ open, closeModal, videoAvailable, videoProcessed }) => {
  const [start, setStart] = useState(0);
  const call = useSelector((store) => store.call);
  const player = useSelector((store) => store.player);
  const [end, setEnd] = useState(fetchDataSafely(player, "playback.duration", 7200));
  const [fullCallLink, setFullCallLink] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [validOnlyForAMonth, setValidOnlyForAMonth] = useState(false);
  const [notifyMe, setNotifyMe] = useState(true);
  const { setSnackbar, hideSnackbar } = useSnackbar();
  const [sharedSnippets, setSharedSnippets] = useState();
  const [snippetName, setSnippetName] = useState("");
  const [headerText, setHeaderText] = useState("Share Call");
  const [emailRequired, setEmailRequired] = useState(false);
  const [meetTitle, setMeetTitle] = useState("");
  const [settingsForCompleteCall, setSettingsForCompleteCall] = useState(false);
  const [fullCallDocument, setFullCallDocument] = useState();
  const [backEnabled, setBackEnabled] = useState(false)
  const [loader, setLoader] = useState(false)
  const [currentMode, setCurrentMode] = useState("ENTIRE_CALL")
  const screen = useSelector((store) => store.screen);
  const [deleteHover, setDeleteHover] = useState(false);
  const [openDisableLinkModal, setOpenDisableLinkModal] = useState(false);
  const [showLoaderForFullCallLinkGeneration, setShowLoaderForFullCallLinkGeneration] = useState(false);
  const [showFullCallLinkGenerationMessage, setShowFullCallLinkGenerationMessage] = useState(false);
  const [deleteHoverRef] = useHoverListenerRef(() => setDeleteHover(true), () => setDeleteHover(false))
  const timeout = useRef(null);
  const userId = useSelector(store => store.auth.userId)


  useEffect(() => {
    getAllTheShareDetailsDocuments()
  }, []);

  useEffect(() => {
    if (screen.shareStartTime && screen.shareEndTime) {
      setCurrentMode("TRIM_CALL")
    }
  }, [screen.shareStartTime, screen.shareEndTime])


  async function generateLink(startParam = start, endParam = end) {
    setWaiting(true);
    setLoader(true)
    let response = await getShareableCallId(call.callId, validOnlyForAMonth, startParam, endParam, "AUDIO", snippetName, notifyMe, emailRequired);
    setFullCallDocument(response);
    if (response?.error) {
      setSnackbar("Couldn't generate the call link, please retry", "ERROR");
      setFullCallLink("");
    } else {
      let link = getShareableLink(response.shareId);
      setFullCallLink(link);
      setNotifyMe(response.sendViewNotification)
      setEmailRequired(response.emailRequired);
    }
    setWaiting(false);
    setLoader(false)
  }

  function handleBackInsideShareCall() {
    setBackEnabled(true)
  }

  async function copyToClipboard() {
    let link = fullCallLink;
    navigator.clipboard.writeText(link);
    setSnackbar("Link Copied", "SUCCESS");
    const response = await updateNotificationReceiversForSnippet(fullCallDocument, userId)
    if (response) {
      setFullCallDocument(response)
    }
  }

  function handleClose(force = false) {
    setHeaderText("Share Call");
    setCurrentMode("ENTIRE_CALL")
    if (!force && (waiting)) {
      return;
    }
    closeModal();
  }

  function getLinkExpiryStatus(document) {
    var linkExpired = false;
    if (document.expiresAt) {
      var expiry = new Date(document.expiresAt);
      var today = new Date();
      if (expiry <= today) {
        linkExpired = true;
      }
    }
    if (linkExpired) {
      return true;
    }
    else {
      return false
    }
  }

  async function getAllTheShareDetailsDocuments() {
    setLoader(true)
    var hasFullCallShareLink = false;
    const params = new URLSearchParams();
    params.append("callId", call.callId);
    const documents = await sendRequest("/call/get_shared_call_documents", params);
    var fullDocIndex = -1;
    var shareId;
    if (documents) {
      documents.forEach((document, index) => {
        if (document.type == "SELF" && !getLinkExpiryStatus(document) && !document.snippetName) {
          setFullCallDocument(document);
          fullDocIndex = index;
          hasFullCallShareLink = true;
          shareId = document.shareId;
          setMeetTitle(document.meetTitle);
          setNotifyMe(document.sendViewNotification)
          setEmailRequired(document.emailRequired);
        }
      });
    }

    if (fullDocIndex != -1) {
      documents.splice(fullDocIndex, 1)
    }
    setSharedSnippets(documents.reverse());

    if (hasFullCallShareLink) {
      setFullCallLink(getShareableLink(shareId));
      setLoader(false)
    } else {
      generateLink(start, end);
    }
    setMeetTitle(call?.details?.callDataDocument?.meetingInfo.meetingTitle ?? "Cannot process meet title right now.");

    if (fullCallLink) {
      setShowLoaderForFullCallLinkGeneration(false)
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = setTimeout(() => setShowFullCallLinkGenerationMessage(false), 3000);
    }

  }

  function handleBack() {
    setBackEnabled(false)
    getAllTheShareDetailsDocuments()
  }

  async function updateFullCallDocument(NotifyMe, EmailRequired) {
    const params = new URLSearchParams();
    params.append("callId", fullCallDocument.callId)
    params.append("shareId", fullCallDocument.shareId)
    params.append("emailRequired", EmailRequired)
    params.append("notifyMe", NotifyMe)
    var response = await sendRequest("/call/update_full_call_document", params)
    setFullCallDocument(response)
    setSnackbar("Changes Saved", "SUCCESS")
  }

  function handleNotifyCheckBox(bool) {
    setNotifyMe(bool)
    updateFullCallDocument(bool, emailRequired)

  }

  function handleEmailRequiredCheckBox(bool) {
    setEmailRequired(bool)
    updateFullCallDocument(notifyMe, bool)
  }

  function handleModeChange(mode) {
    setHeaderText(mode);
    if (mode == "Trim Call") {
      setCurrentMode("TRIM_CALL")
    }
    if (mode == "Shared links") {
      setCurrentMode("SNIPPET_DETAILS")
    }
  }

  async function revokeFullCallLink() {
    setOpenDisableLinkModal(false)
    setShowLoaderForFullCallLinkGeneration(true)
    setShowFullCallLinkGenerationMessage(true)
    const params = new URLSearchParams();
    params.append("shareId", fullCallDocument.shareId);
    await sendRequest("/call/revoke_snippet_validity", params)
    await getAllTheShareDetailsDocuments()
  }

  return (
    <Modal open={open} onClose={handleClose} className=" items-center flex justify-center my-10 overflow-scroll">
      <>
        {currentMode != "TRIM_CALL" &&
          <div className={classNames("ShareCallForm__Screen p-0 overflow-auto", "w-[680px] min-h-[300px]")} style={{ height: currentMode == "TRIM_CALL" ? "60vh" : "unset" }}>
            <div className="flex items-center justify-around px-7 py-5">
              {!backEnabled && currentMode != "TRIM_CALL" &&
                <TextXL textColor="text-coolGray-900 ">
                  {headerText}
                </TextXL>
              }
              {backEnabled && currentMode != "TRIM_CALL" &&
                <div className="flex flex-row items-center" onClick={handleBack}>
                  <SmallArrowLeftIcon className="mr-0.5" />
                  <TextXL textColor="text-coolGray-900" className="cursor-pointer">
                    Back
                  </TextXL>
                </div>
              }
              <CloseIcon onClick={() => handleClose(true)} className="h-10 w-10 rounded-2xl flex ml-auto cursor-pointer" />
            </div>
            <div className="w-full border-t border-solid border-coolGray-200"></div>
            {currentMode == "ENTIRE_CALL" && (
              <div >
                <div className="flex flex-col justify-around items-center">
                  <div className=" flex flex-col justify-center items-center  mt-4 mb-1 w-11/12   ">
                    <TextBase className="  inline-block w-full whitespace-nowrap overflow-hidden overflow-ellipsis" textcolor="text-coolGray-800">
                      {meetTitle}
                    </TextBase>
                  </div>

                  <div className="flex flex-row  items-center w-11/12">
                    <div className="flex flex-row  items-center w-full relative mr-4 ">
                      <div className="bg-white border border-solid border-coolGray-200 box-border border-r-0  py-1  px-1 overflow-hidden inline-block  whitespace-nowrap overflow-ellipsis rounded h-10  items-center w-4/5">
                        <TextBase className="inline-block w-full whitespace-nowrap overflow-hidden overflow-ellipsis pt-1 pb-1" textColor="text-coolGray-500">
                          {fullCallLink}
                        </TextBase>
                      </div>
                      <Button
                        variant="secondary"
                        className="w-39 position: relative right-1.5 h-10 !border-coolGray-200 mr-1"
                        onClick={copyToClipboard}
                        loading={loader}
                        startIcon={loader ? null : <AttachmentLink />}
                        id="copyFullCallLink"
                      >
                        {loader ? null : <TextBase className="py-5 " textColor="text-brand-blue-1">
                          Copy
                        </TextBase>}
                      </Button>
                      <div ref={deleteHoverRef} data-tip data-for={"deleteCallLink"} className="cursor-pointer ">
                        {deleteHover ? <ActiveTrashBinIcon onClick={() => setOpenDisableLinkModal(true)} /> : <TrashBinIcon />}
                        <ReactTooltip id={"deleteCallLink"} type="light" place="bottom" effect="solid" className="WhiteTooltip">
                          <span className="WhiteTooltip__mainCopy">{"Disable link"}</span>
                          <span className="WhiteTooltip__subCopy">{"On disabling the link, a new link will be automatically generated."}</span>

                        </ReactTooltip>

                      </div>
                      <DisableFullCallLinkModal
                        open={openDisableLinkModal}
                        setOpenDisableLinkModal={setOpenDisableLinkModal}
                        revokeFullCallLink={revokeFullCallLink}

                      />

                    </div>

                  </div>

                  <div className="flex flex-col  p-0.5  w-11/12 items-start mt-5" >
                    <div className="flex flex-row" >
                      <CheckBox checked={emailRequired} onChange={() => handleEmailRequiredCheckBox(!emailRequired)} disabled={loader} ></CheckBox>
                      <TextBase className=" mt-0.5 ml-2 text-left" textColor="text-coolGray-500">
                        Ask viewer’s email for access to the call recording
                      </TextBase>
                    </div>
                  </div>


                  <div className="flex flex-col  p-0.5  w-11/12 items-start mt-1" >
                    <div className="flex flex-row" >
                      <CheckBox checked={notifyMe} onChange={() => handleNotifyCheckBox(!notifyMe)} disabled={loader} ></CheckBox>
                      <TextBase className="mt-0.5 ml-2 text-left" textColor="text-coolGray-500 ">
                        Receive an alert when your call recording is viewed
                      </TextBase>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center w-11/12 mt-5 mb-5">
                    <div className="w-2/5 border-t border-solid border-coolGray-200"></div>
                    <TextBase textColor="text-coolGray-500">
                      OR
                    </TextBase>
                    <div className="w-2/5 border-t border-solid border-coolGray-200"></div>
                  </div>

                  <Button
                    variant="secondary"
                    className="w-11/12 !border-coolGray-200"
                    onClick={() => handleModeChange("Trim Call")}
                    disabled={loader}
                    startIcon={<ShareLine />}
                    children={<TextBase textColor="text-coolGray-500">
                      Trim and Share
                    </TextBase>}
                    id="trimCall"
                  >
                  </Button>
                  <div className="w-full border-t border-solid border-cooGray-200 mt-8"></div>
                </div>
                {!showFullCallLinkGenerationMessage &&
                  <Button
                    variant="tertiary"
                    className="my-2 !border-white "
                    disabled={loader}
                    startIcon={<Users className="ml-5  " />}
                    children={<TextBase textColor="text-brand-blue-1">
                      Shared Links
                    </TextBase>}
                    onClick={() => handleModeChange("Shared links")}
                    id="shareLinksManagement"
                  >
                  </Button>
                }
                {showFullCallLinkGenerationMessage &&
                  <div className={classNames("flex items-center justify-around w-full text-center p-3 overflow-hidden", { "bg-green-50": showLoaderForFullCallLinkGeneration }, { "bg-green-400": !showLoaderForFullCallLinkGeneration })} id="generationMessage" style={{ transition: "height 1s" }}>
                    {showLoaderForFullCallLinkGeneration &&
                      <div className="flex">
                        <CircularProgress color="green" size={14} className="mr-2 mt-1" />
                        <TextBase className="text-green-500 mb-1">Creating a new sharable link</TextBase>
                      </div>
                    }

                    {!showLoaderForFullCallLinkGeneration &&
                      <div className="flex" id="mani">
                        <TickInsideCircleIcon className="mr-1" />
                        <TextLarge textColor="text-white">Link created</TextLarge>
                      </div>
                    }


                  </div>
                }

              </div>
            )}


            {currentMode == "SNIPPET_DETAILS" && (
              <div>
                <ShareCallManagement documents={sharedSnippets} fullCallLink={fullCallLink} close={handleClose} fullCallDocument={fullCallDocument} meetTitle={meetTitle} backEnabled={backEnabled} backFunction={handleBackInsideShareCall} />
              </div>
            )}




          </div>
        }
        {currentMode == "TRIM_CALL" &&

          <ShareCallFormTrimmer open={open} closeModal={closeModal} videoAvailable={videoAvailable} videoProcessed={videoProcessed} close={handleClose} />

        }
      </>

    </Modal>
  );
};

export default ShareCallForm;