import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setOnboardingSkipped, setOnboardingFlowStatus } from "app/actions/persisted";
import { Redirect } from "react-router-dom";

import { VoiceFingerprinting, WingmanDownload, RoleSelection } from "app/components/onboarding";
import { CustomLink } from "app/components/common";
import { IntegrationSettings } from "../components/settings";
import { isOnboardingDone, integrationsList, areAllIntegrationsDone } from "../reducers/persisted";
import { fetchDataSafely } from "../utils/dataUtils";
import CompletingIntegration from "../components/onboarding/CompletingIntegration";
import MobileAppDownload from "../components/onboarding/MobileAppDownload";

const Onboarding = props => {

  useEffect(() => {
    checkIfOnboardingDone();
  }, [props.auth.data.user]);


  function checkIfOnboardingDone() {
    if (isOnboardingDone(props.auth.data.user, props.persisted.onboardingFlowStatus)) {
      props.setOnboardingSkipped(true);
    }
  }

  function skipHandler(integrationFlow) {
    const flowStatus = props.persisted.onboardingFlowStatus;
    flowStatus[integrationFlow] = true;
    props.setOnboardingFlowStatus(flowStatus);
    checkIfOnboardingDone();
  }

  function skipIntegrations() {
    const flowStatus = props.persisted.onboardingFlowStatus;
    for (const integration of integrationsList) {
      flowStatus[integration] = true;
    }
    props.setOnboardingFlowStatus(flowStatus);
    checkIfOnboardingDone();
  }

  function isOnboardingSkipped() {
    const { onboardingSkipped } = props.persisted;
    return onboardingSkipped;
  }


  console.log("in onboarding jsx: onboarding Done", isOnboardingDone(props.auth.data.user, props.persisted.onboardingFlowStatus));
  console.log("in objsx : onboarding skipped", isOnboardingSkipped());
  const flowStatus = props.persisted.onboardingFlowStatus;
  console.log("user is---", fetchDataSafely(props, "auth.data.user.userId"));

  if (
    window.location.search.indexOf("code") !== -1
    || window.location.hash.indexOf("code") !== -1
  ) {
    return (
      <CompletingIntegration loading={true} />
    );
  }

  if (isOnboardingDone(props.auth.data.user, props.persisted.onboardingFlowStatus) && isOnboardingSkipped()) {
    return <Redirect to={props.screen.firstUrl === "/onboarding" ? "/" : props.screen.firstUrl} />;
  }

  if (fetchDataSafely(props, "auth.data.user.userId") && (!props.auth.data.user.roleInfo || !props.auth.data.user.roleInfo.role)) {
    return <RoleSelection />;
  }

  if (!areAllIntegrationsDone(flowStatus)) {
    return (
      <div className="Sync-Background verCon">
        <IntegrationSettings admin user />
        <CustomLink onClick={skipIntegrations}>Skip</CustomLink>
      </div>
    );
  }

  if (fetchDataSafely(props, "auth.data.user.userId") && flowStatus && !flowStatus.wingmanDownloadFlow) {
    return <WingmanDownload skipHandler={() => skipHandler("wingmanDownloadFlow")} />;
  }

  if (fetchDataSafely(props, "auth.data.user.userId") && flowStatus && !flowStatus.mobileAppDownloadFlow) {
    return <MobileAppDownload skipHandler={() => skipHandler("mobileAppDownloadFlow")} />;
  }


  if (fetchDataSafely(props, "auth.data.user.userId") && flowStatus && !flowStatus.voiceFingerPrintingFlow) {
    return (
      <VoiceFingerprinting skipHandler={() => skipHandler("voiceFingerPrintingFlow")} />
    );
  }


  console.log("here in skipping to /", props.screen.firstUrl);
  return <Redirect to="/" />;
}

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { setOnboardingSkipped, setOnboardingFlowStatus },
)(Onboarding);

