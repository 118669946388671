import React, { useState } from "react";
import Range from "rc-slider/lib/Range";
import "rc-slider/assets/index.css";
import { _roundUp, getHoursOrMinsOrSecs } from "app/utils/helpers";
import OutsideClickHandler from "react-outside-click-handler";
import { useEffect } from "react";
import { CustomDropdown } from "../common";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useRef } from "react";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";

const DurationFiltersNew = ({ startDuration, endDuration, removeFilterValue, fieldName, onStartDurationChange, onEndDurationChange, dontShowCloseIcon }) => {
    const [start, setStart] = useState(startDuration);
    const [end, setEnd] = useState(endDuration);
    const [showRemove, setShowRemove] = useState(false);

    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);

    useEffect(() => {
        setStart(startDuration);
    }, [startDuration])

    useEffect(() => {
        setEnd(endDuration);
    }, [endDuration])

    function handleStartChange(time) {
        setStart(time);
        onStartDurationChange(time);
    }

    function handleEndChange(time) {
        setEnd(time);
        onEndDurationChange(time);
    }


    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        removeFilterValue(fieldName);
    }

    return (
        <div className="FilterField__container">
            <div ref={ref} className="FilterField__valueContainer">
                {showRemove && !dontShowCloseIcon && <img onClick={onRemove} className="FilterField__removeIcon" src={closeIcon} />}
                <div className="RangeInput">
                    <div className="RangeInput__row text-base">
                        <CustomDropdown
                            bubbleClass={"RangeInput__dropdown"}
                            top={30}
                            left={10}
                            title={<span className="RangeInput__dropdown__trigger">{getHoursOrMinsOrSecs(start)}</span>}
                            list={[0, 1, 10, 30, 60, 120, 300, 600, 900, 1800].map(time => <div key={time} onClick={() => handleStartChange(time)} className="RangeInput__dropdown__item">{getHoursOrMinsOrSecs(time)} </div>)} />
                        {" - "}
                        <CustomDropdown
                            bubbleClass={"RangeInput__dropdown"}
                            top={30}
                            left={10}
                            title={<span className="RangeInput__dropdown__trigger">{getHoursOrMinsOrSecs(end)}</span>}
                            list={[36000, 7200, 3600, 1800, 600, 300, 120, 60].map(time => <div key={time} onClick={() => handleEndChange(time)} className="RangeInput__dropdown__item">{getHoursOrMinsOrSecs(time)} </div>)} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DurationFiltersNew;
