import React, { useEffect } from "react";
import { useState } from "react";
import closeIcon from "app/styles/assets/images/icons/closeCircle.svg";
import { SelectWithSearchNew } from ".";
import RangeFilterTemplate from "./RangeFilerTemplate";
import { useHoverListenerRef } from "app/utils/hooks/useHoverListener";
import { useSelector } from "react-redux";


const ScoringSkillFilter = ({ onChange, filterParams, removeFilterValue, fieldName }) => {

    const [showRemove, setShowRemove] = useState(false);
    const [ref] = useHoverListenerRef(() => setShowRemove(true), () => setShowRemove(false), 200);
    const skills = useSelector(store => store.screen.metaData.scoringSkills || [])

    function onRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        removeFilterValue(fieldName);
    }
    const jsonObject = {
        type: filterParams.score ? filterParams.score.type : null,
        scoredSkill: filterParams.score ? filterParams.score.scoredSkill : [],
        startRange: filterParams.score ? filterParams.score.startRange : 0,
        endRange: filterParams.score ? filterParams.score.endRange : 5
    }

    function handleSkillsDropdown(value) {


        if (value === "TOTAL_SCORE") {
            jsonObject.scoredSkill = [];
            jsonObject.type = "TOTAL_SCORE"
        }
        else {
            jsonObject.scoredSkill = [value]
            jsonObject.type = "SKILL"
        }
        onChange("score", jsonObject)

    }

    function handleRangeDropDown(type, value) {
        if (type === "startRange") {
            jsonObject.startRange = value;
        }
        if (type === "endRange") {
            jsonObject.endRange = value;
        }
        if (!jsonObject.type) {
            jsonObject.type = "TOTAL_SCORE"
        }
        onChange("score", jsonObject)
    }


    return (
        <div className="border rounded p-0.25 ">
            <div className="FilterField__valueContainer " ref={ref}>
                {showRemove && <img onClick={(e) => onRemove(e)} className="FilterField__removeIcon" src={closeIcon} />}
                <div className="flex  justify-between ">
                    <div className="mr-5 ">
                        <SelectWithSearchNew
                            key="scoredSkill"
                            joinPhrase=" or "
                            fieldName="scored skill"
                            data={["TOTAL_SCORE", ...skills].map(skill => ({ label: skill != "TOTAL_SCORE" ? skill : "Total score", value: skill }))}
                            value={filterParams.score ? (filterParams.score.scoredSkill.length === 0 ? filterParams.score.type : filterParams.score.scoredSkill[0]) : null}
                            label="Choose skill"
                            placeholder="Type skill name"
                            handleChange={options =>
                                handleSkillsDropdown(options.value)
                            }
                            isMulti={false}
                            dontShowCloseIcon={true}

                        />
                    </div>
                    <RangeFilterTemplate
                        fieldName="scoreRange"
                        onStartChange={options =>
                            handleRangeDropDown("startRange", options)
                        }
                        onEndChange={options =>
                            handleRangeDropDown("endRange", options)
                        }
                        onStartAndEndChange={null}
                        startRange={
                            filterParams.score ? filterParams.score.startRange : 0
                        }
                        endRange={
                            filterParams.score ? filterParams.score.endRange : 5
                        }
                        list={[0, 1, 2, 3, 4, 5]}
                        roundOffFactor={1}
                        showPercentageAfterValue={false}
                        dontShowCloseIcon={true}
                    />
                </div>

            </div>
        </div>

    )
}

export default ScoringSkillFilter;
