import React from 'react'
import PropTypes from 'prop-types'
const DealEventTag = ({ event, type, activeDeal }) => {

    return (
        <div className="DealEventTag">
            <span className="DealEventTag__name">{event.opportunity.opportunityName + (type === "CREATED" ? " created." : " closed.")}</span>
        </div>
    );
}
DealEventTag.propTypes = {}
DealEventTag.defaultProps = {}
export default DealEventTag;