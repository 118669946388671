import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import clx from "classnames";
import { getMenuPosition } from './helper'

const PREFIX = "menu__";

const Menu = ({
    list,
    open,
    minWidth,
    onClose,
    className,
    menuPosition = "bottom",
    parentContainerRef,
    anchorEl,
    onClick
}) => {
    const [visible, setVisiblitly] = useState(null);
    const menuRef = useRef(null);
    const [position, setPostion] = useState({
        top: 0,
        left: 0
    });

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick, false);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick, false);
        }
    }, [])

    useEffect(() => {
        if (visible && anchorEl) {
            const { top, left } = getMenuPosition(anchorEl, menuRef, menuPosition)
            setPostion({ top, left })
        }
    }, [anchorEl, visible]);

    useEffect(() => {
        if (open) {
            setVisiblitly(true);
        }
        else {
            setVisiblitly(false);
        }
    }, [open])

    const handleOutsideClick = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
            handleClose(e);
        }
    }

    const handleClose = (e) => {
        setVisiblitly(false);
        onClose(e);
    }

    if (!visible) {
        return null;
    }

    return (
        ReactDOM.createPortal(
            <div
                className="cursor-pointer absolute z-2"
                style={{
                    opacity: 1,
                    visibility: "visible",
                    minWidth,
                    zIndex: 9999,
                    ...position
                }}
                ref={menuRef}
                onClick={onClick}
            >
                <div className={clx("bg-white rounded-lg shadow-lg px-2 py-2.5 border border-gray-200", className)}>
                    {list?.map((item, i) => {
                        return <div key={PREFIX + i}>{item}</div>;
                    })}
                </div>
            </div>, parentContainerRef || document.body)
    );
}

Menu.propTypes = {
    list: PropTypes.array.isRequired,
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    minWidth: PropTypes.number,
    menuPosition: PropTypes.oneOf(["top", "bottom", "left", "right", "top-left", "top-right", "bottom-left", "bottom-right", "left-top", "left-rght", "right-top", "right-bottom"]),
    anchorEl: PropTypes.object,
    parentContainerRef: PropTypes.object
};

Menu.defaultProps = {
    list: [],
    className: "",
    open: false,
    onClose: () => { },
    minWidth: "",
    menuPosition: "bottom",
    anchorEl: null,
    parentContainerRef: null
};

export default Menu;
