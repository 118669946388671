import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { oneTimeFreshsalesSync } from "app/utils/network";
import FreshsalesLogo from "app/styles/assets/images/integrations/freshsales.png";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncFreshsales = props => {
  const [loading, setLoading] = useState(false);

  async function sync() {
    setLoading(true);
    const userId = props.auth.data.user.userId || {};
    let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      frontendUrl = `${frontendUrl}:${window.location.port}`;
    }
    const win = window.open(
      `${frontendUrl}/freshsalesIntegration/${userId}/${props.access}`,
      "Fresh Sales Integration",
      "width=600, height=400",
    );
    const { refreshUserAction } = props;
    const pollTimer = setInterval(async () => {
      try {
        if (win.closed) {
          clearInterval(pollTimer);
          setLoading(false);
          refreshUserAction();
        }
      } catch (e) { }
    }, 100);
  }

  return (
    <IntegrationCard
      icon={FreshsalesLogo}
      heading="FreshSales"
      subHeading="CRM Integrations"
      bodyText="Sync freshsales to link all CRM info."
      loading={loading}
      sync={sync}
      crm="FRESHSALES"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncFreshsales.defaultProps = {
  access: "user",
};

SyncFreshsales.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  access: PropTypes.string,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncFreshsales);
