import {
  clone, isEqual
} from "lodash";

export function fetchDataSafely(data, path, defaultValue = null) {
  const dataPath = path.split(".");
  let currDir = clone(data);
  const valuePath = dataPath.map(dir => {
    if (currDir && currDir[dir]) {
      const value = currDir[dir];
      currDir = currDir[dir];
      return value;
    }
    currDir = {};
    return null;
  });

  if (valuePath[valuePath.length - 1]) {
    return valuePath[valuePath.length - 1];
  }
  return defaultValue;
}

export function anyNewElementsInArray(newArr, oldArr, dateField) {
  var latestDataDate = oldArr[0][dateField];
  if (!oldArr || !newArr) return true;
  if (oldArr.length === 0) return true;
  return newArr.find(item => !oldArr.includes(item) && new Date(item[dateField]).getTime() > new Date(latestDataDate).getTime());
}

export function anyDiffElementsInArray(newArr, oldArr, idField) {
  if (!oldArr || !newArr) return true;
  if (oldArr.length === 0) return true;
  const oldIds = oldArr.map(el => el[idField]);
  const newIds = newArr.map(el => el[idField]);
  return !isEqual(newIds, oldIds);
}

export function downloadFile(contents, type, fileName) {
  const binaryString = window.atob(contents);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  const blob = new Blob([bytes], {
    type,
  });
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  document.body.removeChild(link);
}