import React, { useState } from "react";
import Button from "./Button";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function IconButton({ icon, onClick, iconClassName, hoverColor, activeColor, isActive, ...props }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <Button onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props}>
            {
                React.cloneElement(icon, {
                    className: classNames(
                        iconClassName
                    ),
                    style: {
                        fill: isActive ? activeColor : "#fff",
                        color: (isActive || isHovered) ? hoverColor : "currentColor",
                    },
                })
            }
        </Button>
    );
}

IconButton.propTypes = {
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func,
    iconClassName: PropTypes.string,
    hoverColor: PropTypes.string,
    activeColor: PropTypes.string,
    isActive: PropTypes.bool,
};

IconButton.defaultProps = {
    onClick: () => { },
    iconClassName: "",
    hoverColor: "#000000",
    activeColor: "#FFFFFF",
    isActive: false,
};