import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { calendarCallback, unsyncGoogleCalendar } from "app/utils/network";
import googleCalendar from "app/styles/assets/images/onboarding/Google.png";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import { openWindowAndListenForMultipleURLParams, getRedirectUrl } from "./helpers";
import { GOOGLE_CONFIG } from "app/config";
import GoogleIcon from "app/styles/assets/images/icons/g-logo.png"
import { sendGetRequest } from "app/utils/network";

const SyncCalendars = props => {
  const [loading, setLoading] = useState(false);
  const { stringsCustomer, data } = useSelector(store => store.auth);

  async function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const scope = GOOGLE_CONFIG.readWriteScope;
    const response = await sendGetRequest("/auth/getToken");
    if (response?.data?.error) {
      setLoading(false);
      return
    }
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CONFIG.clientId}&scope=${scope}&redirect_uri=${location}&access_type=offline&response_type=code&prompt=consent&state=${response.message}`;
    console.log("url for google calendar is", url);
    openWindowAndListenForMultipleURLParams(url, "Google Calendar Integration", ["code", "state"], completeSync);
  }


  async function completeSync(code, state) {
    await calendarCallback(code, state);
    setLoading(false);
    await props.refreshUserAction();
  }

  async function unsync() {
    props.setLoading(true);
    await unsyncGoogleCalendar();
    props.refreshUserAction();
    props.setLoading(false);
  }

  const { synced } = props;
  return (
    <IntegrationCard
      key="googleCalendar"
      heading="Google Calendar"
      subHeading="Calendar Integrations"
      bodyText="Sync your google calendar to get meetings analysed."
      sync={sync}
      synced={synced}
      unsync={unsync}
      icon={googleCalendar}
      customButton={<GoogleSignInDummy />}
      customButtonEnabled={["googletester@stringsai.com", "tester@stringsai.com"].includes(data.user.emailId)}
      loading={loading}
      lastSyncDate={props.lastSyncDate}
    />
  );
}

export const GoogleSignInDummy = props => {
  return (
    <a href="#">
      <button type="button" style={{ fontSize: "14px", boxShadow: "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px", padding: "0px 24px 0px 24px", display: "flex", flexDirection: "row", alignItems: "center", border: "1px solid #4285f4", color: "rgba(0, 0, 0, 0.54)", border: "none", minWidth: "110px", height: "40px", borderRadius: "3%" }}>
        <img src={GoogleIcon} style={{ height: "18px", width: "18px", background: "white", marginRight: "12px" }} alt="" />
        <b>Sign in with Google</b>
      </button>
    </a>
  );
}

const mapStateToProps = store => {
  return { ...store };
};

SyncCalendars.defaultProps = {};

SyncCalendars.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncCalendars);
