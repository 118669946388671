import PropTypes from "prop-types";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { NotesAndComments } from "../components/call";
import { isUsersCall } from "../components/call/helpers";
import { setAddCommentExpanded } from "app/actions/screen";
import { ErrorMessage } from "../components/common/ErrorBoundary";
import { fetchDataSafely } from "../utils/dataUtils";
import TranscriptVideoPlayer from "../components/call/TranscriptVideoPlayer";
import { CrmContext } from "app/components/call/CrmContext";
import withHowler from "../components/common/CustomHowler";
import { HeaderCall } from "app/components/call";
import { reasonString } from "app/components/callTable/ReasonColumn";
import { getCallDetails, getTrimmedCallDetails, setActiveTime, setSelectedSearchOption } from "app/actions/call";
import { setScreenType, setStackedScreenName } from "app/actions/screen";
import { setSelectedSpeakers } from "app/actions/player";
import { MAIN, STACKED } from "app/constants";
import processingIcon from "app/styles/assets/images/processing.svg";
import scheduledIcon from "app/styles/assets/images/scheduled.svg";
import noDataIcon from "app/styles/assets/images/noData.svg";
import PrivateCallGraphic from "app/styles/assets/images/icons/callReview/privateCall.svg";
import { metrics } from "app/utils/metrics";
import { refreshUser } from "app/utils/network";
import { isEmpty } from "lodash";
import { canThisTurnBePlayed } from "../reducers/call";
import CallReviewSkeletal from "../components/call/CallReviewSkeletal";
import PrivateCallAccessDenied from "../components/call/PrivateCallAccessDenied";
import NoSuchCallFound from "../components/call/NoSuchCallFound";
import { fetchScorecardsForcall } from "app/utils/network";
import DisconnectCall from "../components/call/DisconnectCall";
import { usePageTimeTracker } from "app/utils/hooks/usePageTimeTracker";

export const PlayerContext = React.createContext({});

class Call extends React.Component {
  constructor(props) {
    super(props);

    this.handleHighlightsClick = this.handleHighlightsClick.bind(this);
    this.expandCommentArea = this.expandCommentArea.bind(this);
    this.addRefTurn = this.addRefTurn.bind(this);
    this.handlePlayerClick = this.handlePlayerClick.bind(this);
    this.scrollTranscript = this.scrollTranscript.bind(this);
    this.scrollAndHighlightBubble = this.scrollAndHighlightBubble.bind(this);
    this.getTurnId = this.getTurnId.bind(this);
    this.setActiveNote = this.setActiveNote.bind(this);
    this.setActiveHighlightTab = this.setActiveHighlightTab.bind(this);
    this.setAutoScroll = this.setAutoScroll.bind(this);
    this._addTranscriptLabels = this._addTranscriptLabels.bind(this);
    this.handleLoading = this.handleLoading.bind(this);
    let customerType = null;
    const { user } = this.props.auth.data;
    if (user && user.stringsCustomer) {
      customerType = user.stringsCustomer.customerType;
    }
    this.state = {
      loading: true,
      errorStr: null,
      activeNote: null,
      customerType,
      isCommentAreaExpanded: false,
      activeTime: props.call.activeTime,
      activeHighlightTab: "comments",
      duration: 0,
      autoScroll: true,
      ignoreScroll: false,
      isCallEmbed: false
    };
    this.refTurns = [];
  }

  async componentDidMount() {
    console.log("mounting call.jsx");
    const isCallEmbed = (this.props?.location?.pathname || "").includes("callembed")
    if (isCallEmbed) {
      this.setActiveHighlightTab('highlights');
    }
    this.setState({ isCallEmbed });

    try {
      this.props.setStackedScreenName("Meeting Details");
      this.props.setScreenType(STACKED);
      let callId = this.props.match.params.callId || "";
      callId = callId.split("#")[0];
      await this.props.getTrimmedCallDetails(callId);
      if (this.props.call.details === "error" || this.props.call.details.error) {
        this.setState({
          loading: false,
          errorStr: fetchDataSafely(this.props.call.details, "errorMessage")
            ? fetchDataSafely(this.props.call.details, "errorMessage")
            : "Error loading call details. Please try again later.",
        });
      } else {
        this.setMeetingTitleAsScreenName();
        this.setState({
          loading: false,
        });
      }
      this.props.getCallDetails(callId, this.props.auth.isAdminLogin);

      if (window.location.hash !== "") {
        const paramsString = window.location.hash.slice(1, window.location.hash.length);
        this.handleUrlParams(paramsString);
      }
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
        errorStr: "Error loading call details. Please try again later.",
      });
    }

    metrics.logEvent("openCall");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.activeTime !== nextProps.call.activeTime) {
      const turnId = this.getTurnId(nextProps.call.activeTime);
      this.scrollTranscript(turnId);
      this.setState({
        activeTime: nextProps.call.activeTime,
      });
    }
  }

  componentWillUnmount() {
    this.props.setScreenType(MAIN);
    this.props.setSelectedSearchOption(null);
    if (!this.props.auth.isAdminLogin) metrics.logEndOfPage("callReview");
    metrics.logEvent("closeCall");
  }

  handleUrlParams(paramsString) {
    const urlParams = new URLSearchParams(paramsString);
    if (urlParams.has("turnId")) {
      const mappedTurn = urlParams.get("turnId");
      this.scrollTranscript(mappedTurn, true);
    } else if (urlParams.has("time")) {
      let time = 0;
      if (urlParams.get("time").length > 1) time = urlParams.get("time");
      this.handlePlayerClick(time);
      this.props.playerProps.setPlay();
    } else {
      var turnId = paramsString.split("&")[0];
      let mappedTurn = this.props.call.details.summary.translatedTurnIdMap ? this.props.call.details.summary.translatedTurnIdMap[turnId] : turnId;
      if (!mappedTurn) mappedTurn = paramsString;
      this.scrollTranscript(mappedTurn, true);
    }
    if (urlParams.has("query")) {
      let type = "Transcript";
      if (urlParams.has("type")) type = urlParams.get("type");
      this.props.setSelectedSearchOption({ type, keyword: decodeURIComponent(urlParams.get("query").replaceAll('"', "")) });
      this.setActiveHighlightTab("search");
    }
  }

  setAutoScroll(bool) {
    this.setState({ autoScroll: bool, ignoreScroll: bool });
    setTimeout(
      function () {
        this.setState({ ignoreScroll: false });
      }.bind(this),
      1000
    );
  }

  setMeetingTitleAsScreenName() {
    const meetingTitle = fetchDataSafely(this.props, "call.details.callDataDocument.meetingInfo.meetingTitle");
    if (meetingTitle) {
      this.props.setStackedScreenName(`Title: ${meetingTitle}`);
    }
  }

  getTurnId(startTime) {
    const conversationTurnData = this.props.call.details.callData ? this.props.call.details.callData.conversationTurn || [] : [];
    if (conversationTurnData !== []) {
      for (let i = 0; i < conversationTurnData.length; i++) {
        const turn = conversationTurnData[i];
        if (startTime < turn.startTime) {
          return turn.turnId === 1 ? 1 : turn.turnId - 1;
        }
      }
    }
    return conversationTurnData.length;
  }

  scrollTranscript(turnId, seekPlayer = false) {
    const conversationTurnData = this.props.call.details.callData ? this.props.call.details.callData.conversationTurn || [] : [];
    for (let i = 0; i < conversationTurnData.length; i++) {
      const turn = conversationTurnData[i];
      if (turn.turnId.toString() === turnId.toString()) {
        this.scrollAndHighlightBubble(this.refTurns[turnId]);
        if (seekPlayer) {
          this.props.playerProps.setSeek(turn.startTime);
        }
        return;
      }
    }
  }

  scrollAndHighlightBubble(ref) {
    if (ref && ref.scrollIntoView) {
      ref.scrollIntoView({ block: "center" });
      this.setAutoScroll(true);
    }
  }

  addRefTurn(turnId, ref) {
    this.refTurns[turnId] = ref;
  }

  expandCommentArea(boolean, startTime = null, e = null) {
    if (e != null) {
      e.stopPropagation();
    }
    let duration = startTime;
    if (boolean === true) {
      if (duration === null) {
        duration = this.props.call.activeTime;
      }
      this.props.setActiveTime(duration);
      this.setState({
        duration,
        activeHighlightTab: "comments",
      });
    }
    this.props.setAddCommentExpanded({ show: boolean, time: duration });
  }

  setActiveHighlightTab(tab) {
    this.setState({
      activeHighlightTab: tab,
    });
  }

  _addTranscriptLabels(conversationTurnId) {
    const highlightsData = fetchDataSafely(this.props, "call.details.summary.highlights");
    if (isEmpty(highlightsData)) return [];
    const label = [];
    highlightsData.highlightGroups.forEach((hg) => {
      hg.highlights.forEach((h) => {
        if (h.turnId === conversationTurnId) {
          label.push(hg.turnLabel);
        }
      });
    });
    return label;
  }

  clearSpeakerSelection(time) {
    if (!canThisTurnBePlayed(this.props.playback.conversationTurn, time, this.props.playback.selectedSpeakers)) {
      this.props.setSelectedSpeakers(null);
    }
  }

  handlePlayerClick(startTime) {
    this.clearSpeakerSelection(startTime);
    this.props.setActiveTime(startTime);
    const turnId = this.getTurnId(startTime);
    this.props.playerProps.setSeek(startTime);
    this.scrollAndHighlightBubble(this.refTurns[turnId]);
  }

  handleHighlightsClick(turnId, startTime, category = null) {
    console.log("handleHighlightsClick:", "turnId", turnId, "startTime", startTime);
    if (category != null) {
      metrics.logEvent("clickedOnAnnotation", { category });
    }
    this.clearSpeakerSelection(startTime);
    this.props.setActiveTime(startTime);
    this.scrollAndHighlightBubble(this.refTurns[turnId]);
    this.props.playerProps.setSeek(startTime);
  }

  setActiveNote(note) {
    this.setState({
      activeNote: note,
      activeHighlightTab: "notes",
    });
  }

  handleLoading(loading) {
    this.setState({ loading: loading });
  }

  render() {





    if (this.state.loading) {
      return <CallReviewSkeletal showCrmContext={!this.state.isCallEmbed} showBackBtn={!this.state.isCallEmbed} />;
    }
    if (this.state.errorStr !== null) {
      if (this.state.errorStr === "PRIVATE_CALL") {
        return <PrivateCallAccessDenied details={this.props.call.details} />;
      }
      if (this.state.errorStr === "NO_SUCH_CALL_FOUND") {
        return <NoSuchCallFound />;
      }
      return <ErrorMessage errorMessage={this.state.errorStr} showSupportLink={false} title="Oops! We can't load this call." />;
    }

    return (
      <PlayerContext.Provider value={this.props.playerProps}>
        <HeaderCall handleLoading={this.handleLoading} />
        <CallRender
          isCallEmbed={this.state.isCallEmbed}
          call={this.props.call}
          activeNote={this.state.activeNote}
          setActiveNote={this.setActiveNote}
          customerType={this.state.customerType}
          user={this.props.auth.data.user}
          isCommentAreaExpanded={this.state.isCommentAreaExpanded}
          expandCommentArea={this.expandCommentArea}
          duration={this.state.duration}
          _addTranscriptLabels={this._addTranscriptLabels}
          handleHighlightsClick={this.handleHighlightsClick}
          handlePlayerClick={this.handlePlayerClick}
          addRefTurn={this.addRefTurn}
          activeHighlightTab={this.state.activeHighlightTab}
          setActiveHighlightTab={this.setActiveHighlightTab}
          autoScroll={this.state.autoScroll}
          setAutoScroll={this.setAutoScroll}
          ignoreScroll={this.state.ignoreScroll}
        />
      </PlayerContext.Provider>
    );
  }
}

const CallRender = (props) => {


  const auth = useSelector((store) => store.auth);
  const summaryData = props.call.details.summary || null;
  const turnRefs = useRef({});

  const conversationTurnData = useSelector((store) => fetchDataSafely(store, "call.details.callData.conversationTurn", []));
  usePageTimeTracker(["CallReview"]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyEvents);
    return () => {
      document.removeEventListener("keydown", handleKeyEvents);
    };
  }, []);

  function handleKeyEvents(e) {
    let activeElement = null;
    try {
      activeElement = document.activeElement.tagName;
    } catch (error) { }
    console.log("in handle key events for call", "keycode", e.keyCode, "el:", activeElement);
    if (activeElement === "TEXTAREA" || activeElement === "INPUT") return;
    switch (e.keyCode) {
      case 191:
        e.preventDefault();
        e.stopImmediatePropagation();
        props.setActiveHighlightTab("search");
        if (document.getElementById("SearchWithinCall")) {
          const inputEl = document.getElementById("SearchWithinCall");
          inputEl.focus();
          inputEl.autofocus = true;
        }
        break;
    }
  }

  function handleAddRefTurn(turnId, ref) {
    turnRefs.current[turnId] = ref;
    props.addRefTurn(turnId, ref);
  }

  if (props.call.details.callDataDocument && props.call.details.callDataDocument.leadQualificationInfo) {
  }
  const highlightsData = summaryData ? summaryData.highlights : null;
  const bookmarksData = summaryData ? summaryData.bookmarkedMoments : null;

  const usersCall = useMemo(() => isUsersCall(props.call.details.callDataDocument, props.user.userId), [props.call.details.callDataDocument, props.user.userId]);

  const emptyCallScreenElement = emptyCallScreen(props);
  const isCallCompleted = emptyCallScreenElement ? false : true;

  return (
    <div className="Call">
      <div className="Call__body">
        {(props.customerType !== "GUEST" && !props.isCallEmbed) && <CrmContext isCallCompleted={isCallCompleted} />}
        {!isCallCompleted && <div className="Call__transcriptVideoPlayerContainer">{emptyCallScreenElement}</div>}
        {isCallCompleted && (
          <TranscriptVideoPlayer
            expandCommentArea={props.expandCommentArea}
            handleHighlightsClick={props.handleHighlightsClick}
            setActiveHighlightTab={props.setActiveHighlightTab}
            _addTranscriptLabels={props._addTranscriptLabels}
            addRefTurn={handleAddRefTurn}
            setActiveNote={props.setActiveNote}
            autoScroll={props.autoScroll}
            setAutoScroll={props.setAutoScroll}
            ignoreScroll={props.ignoreScroll}
            isCallEmbed={props.isCallEmbed}
          />
        )}
        {props.customerType !== "GUEST" && (
          <NotesAndComments
            expandCommentArea={props.expandCommentArea}
            handleHighlightsClick={props.handleHighlightsClick}
            _addTranscriptLabels={props._addTranscriptLabels}
            addRefTurn={handleAddRefTurn}
            setActiveNote={props.setActiveNote}
            autoScroll={props.autoScroll}
            setAutoScroll={props.setAutoScroll}
            isCallCompleted={isCallCompleted}
            ignoreScroll={props.ignoreScroll}
            isCommentAreaExpanded={props.isCommentAreaExpanded}
            activeNote={props.activeNote}
            copyNotes={props.copyNotes}
            handlePlayerClick={props.handlePlayerClick}
            call={props.call}
            duration={props.duration}
            activeHighlightTab={props.activeHighlightTab}
            expandCommentArea={props.expandCommentArea}
            setActiveHighlightTab={props.setActiveHighlightTab}
            highlightsData={highlightsData}
            customerType={props.customerType}
            handleHighlightsClick={props.handleHighlightsClick}
            bookmarksData={bookmarksData}
            isUsersCall={usersCall}
            notesEnabled={auth.data.user.stringsCustomer.featuresGated.crmNotes}
            isCallEmbed={props.isCallEmbed}

          />
        )}
      </div>
    </div >
  );
};

export const emptyCallScreen = (props) => {
  const highlightsData = props?.call?.details?.summary?.highlights ?? null;
  const conversationTurnData = props?.call?.details?.callData?.conversationTurn ?? [];
  const hasBotNotJoinReason = fetchDataSafely(props, "call.details.callDataDocument.botNotJoinReason", []).length > 1;
  const callStatus = props?.call?.details?.callDataDocument?.callStatus;
  const callErrorStatus = props?.call?.details?.errorMessage;

  if (conversationTurnData.length > 0 && !(callStatus === 'INPROGRESS' || callStatus === 'COMPLETED')) { return null; }

  let processing = false;
  if (
    (callStatus === "WAITING_IN_QUEUE" || callStatus === "PROCESSING" || callStatus === "COMPLETED" || callStatus === "INPROGRESS" || callStatus === "INITIATED") &&
    (!conversationTurnData || conversationTurnData.length === 0)
  ) {
    processing = true;
  }

  let scheduled = false;
  if (callStatus === "SCHEDULED") {
    scheduled = true;
  }

  let noData = false;
  if (
    callStatus === "NO_DATA_INCALL" ||
    callStatus === "NOBODY_JOINED_CALL" ||
    ((!highlightsData || highlightsData.length === 0 || !highlightsData.highlightGroups || highlightsData.highlightGroups.length === 0) &&
      (!conversationTurnData || conversationTurnData.length === 0))
  ) {
    noData = true;
  }

  let callIgnored = false;
  if (callStatus === "IGNORED_BY_USER") {
    callIgnored = true;
  }

  if (hasBotNotJoinReason && noData) {
    const cdd = props.call.details.callDataDocument;
    const reason = reasonString(cdd.callStatus, cdd.botNotJoinReason, cdd.callTime < new Date());
    return EmptyCallPage(noDataIcon, reason, "", "bot not join reason");
  }

  if (callErrorStatus === "PRIVATE_CALL") return <PrivateCall />;
  if (callStatus === "INITIATED") return <Joining />;
  if (callStatus === "INPROGRESS") return <Recording />;
  if (callStatus === "COMPLETED") return <Processing />;
  if ((callStatus === "CALL_DID_NOT_HAPPEN" && props.call.details.callDataDocument.mediaRecorderStatus === "BOT_DENIED") || callStatus === "BOTJOIN_DENIED")
    return <UnableToJoin />;
  if (callStatus === "CALL_DID_NOT_HAPPEN") {
    if (props?.call?.details?.callDataDocument?.mediaRecorderStatus === "NOBODY_JOINED_CALL") return <CallDidNotHappen />;
    if (props?.call?.details?.callDataDocument?.mediaRecorderStatus === "BOT_DENIED") return <UnableToJoin />;
    return <NoData />;
  }
  if (callStatus === "UNKNOWN_CALL_STATUS") return <UnknownError />;
  if (callStatus === "ERROR_IN_PROCESSING") return <ProcessingError />;
  if (callStatus === "ERROR_IN_RECORDING" || callStatus === "FAILED_TO_DOWNLOAD") return <UnableToRecord />;
  if (scheduled) return <Scheduled />;
  if (processing) return <Processing />;
  if (callIgnored) return <CallIgnored />;
  if (noData) return <NoData />;
  return null;
};

export const EmptyCallPage = (icon, text, subtext = "", alt) => {
  return (
    <div className="Call__processingContainer">
      <img src={icon} alt={alt} className="Call__processingIcon" />
      <span className="Call__processingCopy">{text}</span>
      <span className="Call__processingSubCopy">{subtext}</span>
      {alt === "Recording" && <DisconnectCall />}
    </div>
  );
};

export const UnknownError = () =>
  EmptyCallPage(noDataIcon, "An unknown error occurred with this call.", "Please reach out to support@trywingman.com if the issue persists.", "UnknownError");

const Joining = () => EmptyCallPage(processingIcon, "Wingman is joining this call.", "Please wait for a couple of minutes", "Joining");

const PrivateCall = () => EmptyCallPage(PrivateCallGraphic, "This call have been marked as private.");

const Recording = () =>
  EmptyCallPage(processingIcon, "Wingman is currently recording this call.", "The recording for this call will be available minutes after the call ends", "Recording");

const ProcessingError = () =>
  EmptyCallPage(noDataIcon, "There was an error processing this call recording.", "Please reach out to support@trywingman.com if the issue persists.", "Error");

const UnableToRecord = () =>
  EmptyCallPage(noDataIcon, "Wingman was unable to record this call.", "Please reach out to support@trywingman.com if the issue persists.", "UnableToRecord");

const UnableToJoin = () => EmptyCallPage(noDataIcon, "Wingman was unable to join and record this call.", "the bot was not permitted to join the meeting.", "UnableToJoin");

const Processing = () =>
  EmptyCallPage(processingIcon, "This call is currently being processed.", "The recording for this call will be available in a couple of minutes", "Processing");

const Scheduled = () =>
  EmptyCallPage(scheduledIcon, "Wingman's bot will join and record this meeting.", "The transcript and insights will be available after the meeting ends.", "Scheduled");

const NoData = () => EmptyCallPage(noDataIcon, "We did not detect any speech in this call.", "", "No Data in Call");

const CallIgnored = () => EmptyCallPage(noDataIcon, "This call was not recorded as requested by the user.", "", "Ignored by user");

const CallDidNotHappen = () =>
  EmptyCallPage(noDataIcon, "Wingman did not detect any speech in the call.", "Perhaps this meeting did not happen or started late?", "Call did not happen");

const mapStateToProps = (store) => {
  return {
    call: store.call,
    playback: store.player.playback,
    auth: store.auth,
  };
};

Call.propTypes = {
  call: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getCallDetails: PropTypes.func.isRequired,
  getTrimmedCallDetails: PropTypes.func.isRequired,
  setStackedScreenName: PropTypes.func.isRequired,
  setScreenType: PropTypes.func.isRequired,
};

Call.defaultProps = {};

export default connect(mapStateToProps, {
  getCallDetails,
  getTrimmedCallDetails,
  setScreenType,
  setStackedScreenName,
  setActiveTime,
  setAddCommentExpanded,
  setSelectedSpeakers,
  setSelectedSearchOption,
})(withHowler(Call));

export { CallRender };
