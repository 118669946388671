import React, { useEffect, useState, useRef } from "react";
import HubSpotLogo from "app/styles/assets/images/integrations/hubspot.png";
import SalesforceLogo from "app/styles/assets/images/onboarding/salesforce.png";
import PipedriveLogo from "app/styles/assets/images/onboarding/PipedriveLogo.png";
import LeadsquaredLogo from "app/styles/assets/images/onboarding/leadsquaredLogo.png";
import FreshsalesLogo from "app/styles/assets/images/integrations/freshsales.png";
import crmInfoEmptyState from "app/styles/assets/images/icons/crmInfoEmptyState.svg";
import { sendRequest } from "../../utils/network";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { hasCrmSynced, getFlowStatus } from "app/reducers/persisted";
import DealField from "../dealView/DealField";
import CompanyIcon from "app/styles/assets/images/icons/dealView/CompanyIcon.svg";
import PinkInfoIcon from "app/styles/assets/images/icons/callReview/pinkyInfo.svg";
import Briefcase from "app/designSystem/icons/briefcase.svg";
import Dollar from "app/designSystem/icons/dollar.svg";
import DealStageIcon from "app/styles/assets/images/icons/dealView/DealStageIcon.svg";
import WarningTriangle from "app/styles/assets/images/icons/warning.svg";
import ContactIcon from "app/designSystem/icons/userGroup.svg";
import Calendar from "app/designSystem/icons/calender.svg";
import { useHoverListenerRef } from "../../utils/hooks/useHoverListener";
import { handleViewInCrm, _formatSecondsToPrettyTime, _formatTalkListenRatio, domainMatched, getNumFromString } from "../../utils/helpers";
import ProfileImage from "../common/ProfileImage";
import CrmLink from "app/styles/assets/images/icons/callReview/CRMLinkArrow.svg";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { ENGAGING_QUESTION_EXPLANATION, INTERACTIVITY_EXPLANATION, LONGEST_MONOLOGUE_EXPLANATION, PRIMARY_REP_EXPLANATION, TLRATIO_EXPLANATION } from "../../constants";
import ReactTooltip from "react-tooltip";
import { Link, useHistory } from "react-router-dom";
import AttendeeIcons from "./AttendeeIcons";
import { UPDATE_CALL } from "../../actions/actionTypes";
import LabelWithExplanation from "../common/LabelWithExplanation";
import ContactsPopup, { AttendeePopup } from "../dealView/AttendeePopup";
import { getDealDataFromES, getDealWarningSettings } from 'app/utils/network/dealBoard';
import { _getActiveWarnings } from 'app/utils/helpers';
import WarningsPopup from 'app/components/dealView/WarningsPopup';
import PushCallToCrm from "app/components/call/PushCallToCrmSection";
import FooterButton from "./comments/FooterButton";
import { Button } from 'app/designSystem/';
import AddIcon from 'app/designSystem/icons/AddIcon.svg';
import AddIconHover from 'app/designSystem/icons/AddIconHover.svg';
import { assignTags, removeTag } from "app/utils/network/call";
import TagsContainer from "app/designSystem/form/TagsInput/TagsContainer";
import useSnackbar from "app/utils/hooks/useSnackbar";
import { Input } from "app/designSystem/form";

export const CrmContext = ({ isCallCompleted }) => {

	const [defaultCrmIcon, setDefaultCrmIcon] = useState(null);
	const [crmLinks, setCrmLinks] = useState(null);
	const [showAllContacts, setShowAllContacts] = useState(false);
	const [showAllAttendees, setShowAllAttendees] = useState(false);
	const [attendeePopupMargin, setAttendeePopupMargin] = useState(null);
	const call = useSelector(store => store.call.details.callDataDocument);
	const auth = useSelector(store => store.auth);
	const [contacts, setContacts] = useState([]);
	const authorizedDomains = useSelector(store => store.auth.stringsCustomer.authorizedDomains);
	const [crmData, setCrmData] = useState(call.crmData);
	const [crmHeight, setCrmHeight] = useState(null);
	const crmMargin = 12;
	const [crmLinkHover, setCrmLinkHover] = useState(false);
	const [contactNamesRef] = useHoverListenerRef(() => setShowAllContacts(true), () => setShowAllContacts(false), 500);
	const [attendeeIconsRef] = useHoverListenerRef(() => setShowAllAttendees(true), () => setShowAllAttendees(false), 500);
	const attendees = call?.meetingInfo?.meetingAttendees ?? [];
	const finishedFetchingLatestData = useRef(false);
	const dispatch = useDispatch();
	const { getUserName, reduceWarnings } = useDataHelper();
	const history = useHistory();
	const [activeWarnings, setActiveWarnings] = useState(null);
	const [showWarnings, setShowWarnings] = useState(false);
	const [canAddTag, setCanAddTag] = useState(false);
	const [inputTags, setInputTags] = useState("");
	const [submitTagsLoading, setSubmitTagsLoading] = useState(false);
	const [displayTags, setDisplayTags] = useState(call?.callTags?.map((tag) => { return { label: tag.charAt(0).toUpperCase() + tag.slice(1), value: tag } }));
	const { setSnackbar } = useSnackbar();

	useEffect(() => {
		fetchDefaultCrm();
		fetchCrmLinks();
		fetchLatestCrmData();
		setCrmButtonHeight();
		findAttendeePopupMargin();
	}, []);

	useEffect(() => {
		filterContacts();
		fetchDealWarnings();
	}, [crmData]);

	function findAttendeePopupMargin() {
		let attendees = call?.meetingInfo?.meetingAttendees ?? [];
		if (attendees) {
			if (attendees.length == 1) setAttendeePopupMargin("60px");
			else setAttendeePopupMargin(((Math.min(attendees.length, 6) - 1) * 15 + 60) + "px");
		}
	}

	function setCrmButtonHeight() {
		let el = document.getElementById("crmLinkCompany");
		if (el) {
			let height = el.offsetHeight;
			setCrmHeight(height + crmMargin);
		}

	}

	function handleOpenDealView() {
		let link = "/account/" + crmData.accountDocument.id;
		if (crmData.opportunityDocument && crmData.opportunityDocument.id) {
			link += "#dealId=" + crmData.opportunityDocument.id;
		}
		history.push(link);
	}

	const toggleAddTag = () => {
		setCanAddTag(!canAddTag);
	}

	const onTagsChange = (tags) => {
		setInputTags(tags.trim());
	}

	const handleEnterOnTags = (e) => {
		if (e.key === 'Enter') tagsSubmit();
	}

	const tagsSubmit = async () => {
		if (!submitTagsLoading) {
			if (inputTags.length < 1) {
				setSnackbar("Tags cannot be empty", "INFO");
				return;
			}
			if (displayTags?.filter(item => item.label === inputTags.toLowerCase().charAt(0).toUpperCase() + inputTags.toLowerCase().slice(1)).length > 0) {
				setSnackbar("Tag already present", "ERROR");
				return;
			}
			setSubmitTagsLoading(true);
			const result = await assignTags(call.callId, inputTags.toLowerCase());
			if (result) {
				const temp = displayTags;
				temp.push({ label: inputTags.toLowerCase().charAt(0).toUpperCase() + inputTags.toLowerCase().slice(1), value: inputTags });
				setDisplayTags(temp);
				setInputTags("");
			} else setSnackbar("Some problem occured, please retry later!", "ERROR");
			setSubmitTagsLoading(false);
		}
	}

	const handleTagRemove = async (tag) => {
		const result = await removeTag(call.callId, tag);
		if (result) setDisplayTags(displayTags?.filter(item => item.label !== tag.label));
		else setSnackbar("Some problem occured, please retry later!", "ERROR");
	}

	function filterContacts() {
		if (crmData.contactDocumentList) {
			let externalContactsAndLeads = crmData.contactDocumentList.filter(contact => {
				if (contact.emails && contact.emails.length > 0 && domainMatched(contact.emails[0], authorizedDomains)) return false;
				return true;
			})

			externalContactsAndLeads = externalContactsAndLeads.filter((ele, i, arr) => arr.map(ele => ele["crmId"]).indexOf(ele["crmId"]) === i);
			let externalContacts = externalContactsAndLeads.filter(c => !c.lead);
			let externalLeads = externalContactsAndLeads.filter(c => c.lead);
			setContacts(externalContacts);
		}
	}

	async function fetchDefaultCrm() {
		const response = await sendRequest("/user/get_default_crm");
		switch (response) {
			case "SALESFORCE": setDefaultCrmIcon(SalesforceLogo); break;
			case "HUBSPOT": setDefaultCrmIcon(HubSpotLogo); break;
			case "FRESHSALES": setDefaultCrmIcon(FreshsalesLogo); break;
			case "PIPEDRIVE": setDefaultCrmIcon(PipedriveLogo); break;
			case "LEADSQUARED": setDefaultCrmIcon(LeadsquaredLogo); break;
		}
	}

	async function fetchCrmLinks() {
		const response = await sendRequest("/user/get_crm_links");
		setCrmLinks(response);
	}

	async function fetchLatestCrmData() {
		const params = new URLSearchParams();
		params.append("callId", call.callId);
		const response = await sendRequest("/call/get_latest_crm_data", params);
		setCrmData(response);
		dispatch({ type: UPDATE_CALL, data: { crmData: response } });
		console.log("latest crm data", response);
		finishedFetchingLatestData.current = true;
	}

	function isEmptyCrmData(crmData) {
		if ((!contacts || contacts.length === 0) && !crmData.accountDocument && !crmData.opportunityDocument) {
			return true;
		}
		return false;
	}

	function getContactName(contact) {
		if (!contact.firstName || contact.firstName === "null") {
			if (!contact.lastName || contact.lastName === "null") {
				return contact.emails && contact.emails.length > 0 ? contact.emails[0] :
					contact.phones && contact.phones.length > 0 ? contact.phones[0] : "Nameless";
			}
			return contact.lastName;
		}
		if (!contact.lastName || contact.lastName === "null") {
			return contact.firstName;
		}
		return contact.firstName + " " + contact.lastName;
	}

	const fetchDealWarnings = async () => {
		const accountId = crmData?.opportunityDocument?.accountId
		const dealId = crmData?.opportunityDocument?.id
		if (accountId && dealId) {
			const dealData = await getDealDataFromES(accountId, dealId)
			const { stage, warnings } = dealData
			const reducedWarnings = reduceWarnings(stage, warnings)
			const dealWarningSettings = await getDealWarningSettings("Company Board")
			setActiveWarnings(_getActiveWarnings(reducedWarnings, dealWarningSettings))
		}
	}

	return (
		<div className="CrmContextContainer">
			<div className="CrmContextContainer__header">
				<span className="CrmContextContainer__header-title">Attendees:</span>
			</div>
			<div className="CrmContextContainer__body">
				<div className="CrmContextContainer__callDetails__metric" ref={attendeeIconsRef}>
					<AttendeeIcons attendees={attendees.map(u => u.userId)} imageSize={30} emailIds={attendees.map(u => u.email)} />
					{showAllAttendees && <AttendeePopup attendees={attendees.map(u => u.userId)} emailIds={attendees.map(u => u.email)} names={attendees.map(u => u.name)} imageSize={25} margin={attendeePopupMargin} />}
				</div>
			</div>

			<PushCallToCrm call={call} />

			{hasCrmSynced && <div className="CrmContextContainer__header">
				{defaultCrmIcon !== null && <img src={defaultCrmIcon} className="CrmContextContainer__header-crmLogo" />}
				<span className="CrmContextContainer__header-title">CRM Info:</span>
			</div>}
			<div className="CrmContextContainer__body">
				{hasCrmSynced && !isEmptyCrmData(crmData) &&
					<>
						{crmData && crmData.accountDocument &&
							<div className="DealCard__companyDiv" id="crmLinkButton"
								style={{ marginBottom: "5px", height: crmHeight + "px" }}
								onClick={() => { handleViewInCrm(crmLinks, crmData.accountDocument) }}
								onMouseEnter={() => setCrmLinkHover(true)}
								onMouseLeave={() => setCrmLinkHover(false)}>
								<div className="DealCard__companyRow" id="crmLinkCompany" style={{ marginTop: (crmLinkHover ? -((crmHeight - crmMargin) * 2 + crmMargin) + "px" : "0px") }}>
									<img src={CompanyIcon} className="DealCard__companyIcon" />
									<span className="DealCard__companyName">{crmData.accountDocument && crmData.accountDocument.name}</span>
								</div>
								<div className="DealCard__companyRow" style={{ paddingTop: crmHeight + "px" }}>
									<img src={CrmLink} className="DealCard__companyArrow" />
									<span className="DealCard__companyName">Go to CRM</span>
								</div>
							</div>
						}
						{crmData && crmData.opportunityDocument &&
							<>
								<DealField icon={Briefcase} value={crmData.opportunityDocument.opportunityName} label="Deal Name" />

								{crmData && crmData.opportunityDocument && crmData.opportunityDocument.amount &&
									<DealField icon={Dollar} value={getNumFromString(crmData.opportunityDocument.amount)} label="Deal Size" />
								}
								{crmData && crmData.opportunityDocument && crmData.opportunityDocument.closeDate &&
									<DealField icon={Calendar} value={moment(crmData.opportunityDocument.closeDate).format("MMMM DD, YYYY")} label="Close Date" />
								}

								<DealField icon={DealStageIcon} value={crmData.opportunityDocument.stage} label="Deal Stage" />

								{activeWarnings && activeWarnings.length > 0 &&
									<div className="relative">
										<DealField icon={WarningTriangle} value={activeWarnings.length + " warnings"} label="Deal Alerts" setHoverView={setShowWarnings} />
										{showWarnings &&
											<div className="fixed w-max rounded shadow max-h-half z-1000 bg-white left-32">
												{activeWarnings && <WarningsPopup warnings={activeWarnings} />}
											</div>
										}
									</div>}
							</>
						}
						{
							<div ref={contactNamesRef} className="relative">
								<div className="CrmContextContainer__contactContainer">
									<div className="CrmContextContainer__contactAllNameContainer">
										<img className="CrmContextContainer__contactIcon" src={ContactIcon} />
										<div className="CrmContextContainer__contactNameContainer">
											<span className="CrmContextContainer__contactName">CRM contacts</span>
											<span className="CrmContextContainer__contactJob">{contacts.length} Contacts</span>
										</div>
										{<div className=" relative CrmContextContainer__contactViewAllContainer">
											{showAllContacts && <ContactsPopup crmLinks={crmLinks} contacts={contacts} />}
										</div>}
									</div>
								</div>
							</div>
						}
						{(auth.isAdminLogin || auth.stringsCustomer.featuresGated.dealView) && crmData && crmData.accountDocument &&
							<div style={{ margin: "10px 0px", display: "flex", alignItems: "center" }}>
								<Link to={"/account/" + crmData.accountDocument.id + ((crmData.opportunityDocument && crmData.opportunityDocument.id) ? "#dealId=" + crmData.opportunityDocument.id : "")}>
									<Button variant="secondary" className=" NewButton NewButton__label w-40">Review {crmData && crmData.opportunityDocument ? "Deal" : "Account"}</Button>
								</Link>
							</div>}

					</>
				}
				{hasCrmSynced && isEmptyCrmData(crmData) && finishedFetchingLatestData.current &&
					<div style={{ display: "flex", flexDirection: "column", alignItems: "center", height: "120px", justifyContent: "space-between" }}>
						<img src={crmInfoEmptyState} style={{ height: "60px" }} />
						<span style={{ fontSize: "11px", color: "#060730", textAlign: "center", fontWeight: "bold" }}>Wingman was unable to find a relevant contact in your CRM.</span>
						<span style={{ fontSize: "10px", color: "#8798AD", textAlign: "center" }}></span>
					</div>
				}
			</div>
			{isCallCompleted && <div className="CrmContextContainer__header">
				<span className="CrmContextContainer__header-title">Call Details:</span>
			</div>}
			{isCallCompleted &&
				<div className="CrmContextContainer__body">

					{call.dashboardMetrics && call.dashboardMetrics.dominantRep && <div className="CrmContextContainer__callDetails__metric">
						<div className="CrmContextContainer__domRep">
							<span className="CrmContextContainer__domRepText">Primary Rep</span>
							<img data-tip data-for={"PrimaryRep_metric"} src={PinkInfoIcon} className="CrmContextContainer__callDetails__metricInfoIcon" />
							<ReactTooltip place="right" id={"PrimaryRep_metric"} type="light" effect="solid" className="WhiteTooltip">
								<span className="WhiteTooltip__mainCopy">Who is the primary rep?</span>
								<span className="WhiteTooltip__subCopy">{PRIMARY_REP_EXPLANATION}</span>
							</ReactTooltip>
						</div>
						<div className="ProfileImageAndName">
							<ProfileImage className="ProfileImageAndName__icon" disableTooltip userId={call.dashboardMetrics.dominantRep} size={18} />
							<span className="CrmContextContainer__callDetails__metricValue">{getUserName(call.dashboardMetrics.dominantRep)}</span>
						</div>
					</div>}
					{call.dashboardMetrics && call.dashboardMetrics.talkListenRatio > 0 &&
						<CallDetailsMetric
							label="Talk/Listen Ratio"
							value={_formatTalkListenRatio(call.dashboardMetrics.talkListenRatio, "")}
							explanation={TLRATIO_EXPLANATION}
						/>
					}
					{call.dashboardMetrics && call.dashboardMetrics.longestMonologueDuration > 0 &&
						<CallDetailsMetric
							label="Longest Monologue"
							value={_formatSecondsToPrettyTime(call.dashboardMetrics.longestMonologueDuration)}
							explanation={LONGEST_MONOLOGUE_EXPLANATION}
						/>
					}
					{call.dashboardMetrics && call.dashboardMetrics.interactivityLevel > 0 &&
						<CallDetailsMetric
							label="Interactivity"
							value={call.dashboardMetrics.interactivityLevel}
							explanation={INTERACTIVITY_EXPLANATION}
						/>
					}
					{call.dashboardMetrics && <CallDetailsMetric label="Engaging Questions" value={call.dashboardMetrics.engagingQuestions} explanation={ENGAGING_QUESTION_EXPLANATION} />}
				</div>
			}
			<div className="CrmContextContainer__header justify-between">
				<span className="CrmContextContainer__header-title">Tags:</span>
				<FooterButton onClick={toggleAddTag} icon={AddIcon} hoverIcon={AddIconHover} buttonLabel="" className="w-5 flex-end" id="addCallTag"></FooterButton>
			</div>
			<div className="CrmContextContainer__body">
				{canAddTag &&
					<div className="CrmContextContainer__callTags__inputContainer flex flex-row justify-center items-center">
						<Input disabled={submitTagsLoading} placeholder="Press Enter to add a tag" value={inputTags} onChange={(e) => { onTagsChange(e.target.value) }} onKeyDown={handleEnterOnTags} inputClassname="!p-1 !text-sm" className="w-full -mt-1 mb-2" />
					</div>
				}
				<div className="CrmContextContainer__callTags__displayTagsContainer flex flex-wrap w-full">
					<TagsContainer tags={displayTags} onTagRemove={(tag) => handleTagRemove(tag)} />
				</div>
			</div>
		</div >);
}


export const CallDetailsMetric = ({ label, value, explanation, labelClass }) => {
	if (!value) return null;
	return (
		<div className="CrmContextContainer__callDetails__metric">
			<div className="CrmContextContainer__callDetails__metricLabelContainer">
				<LabelWithExplanation label={label} labelClass={`${labelClass ? labelClass : "CrmContextContainer__callDetails__metricLabel"}`} explanation={explanation} />
			</div>
			<span className="CrmContextContainer__callDetails__metricValue">{value}</span>
		</div>
	);
};