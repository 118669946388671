import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-date-picker";

const styles = theme => ({
  calendarContainer: {
    zIndex: 2,
  },
});

const CustomDatePicker = props => {
  const {
    classes, selectedDate, calendarContainer, clearIcon, pickerClass,
  } = props;
  return (
    <DatePicker
      calendarIcon={null}
      clearIcon={clearIcon}
      calendarClassName={calendarContainer == null ? classes.calendarContainer : calendarContainer}
      value={props.selectedDate}
      className="Calendar__picker"
      onChange={props.handleDateChange}
      maxDate={props.maxDate}
    />
  );
};

CustomDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
};

CustomDatePicker.defaultProps = {
  label: "",
  selectedDate: new Date(),
  handleDateChange: () => { },
};

export default withStyles(styles)(CustomDatePicker);
