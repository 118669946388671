import React, { Component } from "react";
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { FROALA_KEY } from "app/config";

const CardBody = ({ data }) => {
  if (data.htmlContent) {
    return (
      <div className="BattleCard__body RichTextEditor">
        <FroalaEditorView config={{ key: FROALA_KEY }} model={data.htmlContent}>
          {data.htmlContent}
        </FroalaEditorView>
      </div>
    );
  }
  if (data.listContent) {
    return (
      <div className="BattleCard__body">
        {data.listContent.map((point, i) => {
          return <BulletPoint key={i} point={point} even={i % 2} />;
        })}
      </div>
    );
  }
  return (
    <div className="BattleCard__body">
      <BulletPoint point={data.blobContent} even={false} />
    </div>
  );
}

const BulletPoint = props => (
  <div className={props.even ? "BulletPoint__even" : "BulletPoint__odd"}>
    <span className="BulletPoint__text">{props.point}</span>
  </div>
);

export default CardBody;
