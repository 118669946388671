import React from 'react';
import PropTypes from 'prop-types';
import { TextBase, TextSM } from 'app/designSystem';
import classNames from 'classnames';

const Input = React.forwardRef(({ label, value, onChange, error, type, className, inputClassname, startIcon, ...props }, ref) => (
    <div className={classNames(startIcon && "flex items-center border border-gray-300 rounded-md bg-white", className)} data-testid="input-container">
        {label && (
            <TextBase className="mb-1 !text-coolGray-500" data-testid="input-label">
                {label}
            </TextBase>
        )}
        {
            startIcon && (
                <div className="flex items-center ml-2">
                    {startIcon}
                </div>
            )
        }
        <input
            className={classNames("focus:ring-brand-blue-1 focus:border-brand-blue-1 block w-full sm:text-sm border-gray-300 rounded-md border p-3 focus:outline-none", startIcon && "border-none", inputClassname)}
            value={value}
            onChange={onChange}
            type={type}
            {...props}
            ref={ref}
            data-testid="input-input"
        />
        {error && <TextSM textColor="text-red-600" data-testid="input-error" className="ml-1 mt-1" >{error}</TextSM>}
    </div>
))

export default Input;

Input.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    onChange: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
};

Input.defaultProps = {
    error: '',
    type: 'text',
    className: '',
};
