import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { RootFolderView, SubFolderView } from 'app/components/gameTapesLatest'
import { ConfirmActionModal, CreateFolderModal, ClipboardActionsModal } from 'app/components/gameTapesLatest/modal'
import { getFolderChildren, removeFolder } from 'app/utils/network/gametapes'
import { HeaderSkeleton, FolderContainerSkeleton } from 'app/components/gameTapesLatest/skeleton'
import useSnackbar from "app/utils/hooks/useSnackbar";
import { setGameTapesFolderChildrenData, getGameTapesAllFolderData, getGameTapesAllGameTapesData, setFolderCreateModal } from 'app/actions/gametapes'

const FoldersContainer = ({ folderId = 'ROOT' }) => {
    const dispatch = useDispatch();

    const { setSnackbar } = useSnackbar();
    const { cachedFoldersChildrenData } = useSelector(state => state.gametapes);

    const [selectedFolder, setSelectedFolder] = useState(null);
    const [moveFolderModalOpen, setMoveFolderModalOpen] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const folderOptions = [{ label: 'Private', value: 'Private' }, { label: 'Shared', value: 'Shared' }, { label: 'Public', value: 'Public' }, { label: 'All Folders', value: null }];

    useEffect(() => {
        dispatch(getGameTapesAllFolderData());
        dispatch(getGameTapesAllGameTapesData());
    }, [])

    useEffect(() => {
        if (folderId !== null) {
            browseFolder(folderId);
        }
    }, [folderId])

    const browseFolder = async (folderId) => {
        try {
            if (cachedFoldersChildrenData[folderId]) {
                setLoading(false);
            }
            else {
                setLoading(true);
            }

            const result = await getFolderChildren({ folderId });
            if (result) {
                dispatch(setGameTapesFolderChildrenData(folderId, result));
            }
            setLoading(false);
        }
        catch (err) {
            console.log(err);
        }
    }

    const removeFolderHandler = async (folderIdToRemove) => {
        setLoading(true);

        try {
            await removeFolder({ folderId: folderIdToRemove })
            dispatch(setGameTapesFolderChildrenData(folderId, cachedFoldersChildrenData[folderId]?.filter(e => e.id !== folderIdToRemove)));
            dispatch(getGameTapesAllFolderData());
            setSnackbar("Folder removed successfully", "SUCCESS");
        } catch (err) {
            console.log(err);
            setSnackbar("Error removing folder", "ERROR");
        } finally {
            setLoading(false);
        }
    }

    const handleFolderActions = (action, data) => {
        if (action === "remove") {
            setConfirmationModalOpen(true);
        } else if (action === "move") {
            setMoveFolderModalOpen(true);
        } else if (action === "edit") {
            dispatch(setFolderCreateModal(true));
        }

        setSelectedFolder(data);
    }

    const moveFolderHandler = async (folderIdToMove) => {
        try {
            dispatch(setGameTapesFolderChildrenData(folderId, cachedFoldersChildrenData[folderId]?.filter(e => e.id !== folderIdToMove)));
            dispatch(getGameTapesAllFolderData());
            setSnackbar("Folder moved successfully", "SUCCESS");
        } catch (err) {
            console.log(err);
            setSnackbar("Error removing folder", "ERROR");
        }
    }

    const handleModalClose = () => {
        dispatch(setFolderCreateModal(false));
        setConfirmationModalOpen(false);
        setMoveFolderModalOpen(false);
        setSelectedFolder(null);
    }

    if (loading) {
        return (
            <div className="flex flex-col mb-16">
                <HeaderSkeleton />
                <FolderContainerSkeleton style={{
                    height: "12rem"
                }} />
            </div>
        )
    }

    return (
        <div className="mb-16">
            {
                folderId === 'ROOT' ? ( // Root folder view (Parent folder)
                    <RootFolderView folderOptions={folderOptions} foldersData={cachedFoldersChildrenData[folderId]} handleFolderActions={handleFolderActions} />
                ) : (
                    <SubFolderView foldersData={cachedFoldersChildrenData[folderId]} handleFolderActions={handleFolderActions} />
                )
            }

            <CreateFolderModal
                folderId={folderId}
                onClose={handleModalClose}
                onCreate={() => { browseFolder(folderId); handleModalClose(); }}
                data={selectedFolder}
            />

            {
                moveFolderModalOpen && <ClipboardActionsModal
                    action={"Move"}
                    type={"Folder"}
                    open={moveFolderModalOpen}
                    folderId={selectedFolder.id}
                    onClose={handleModalClose}
                    onCreate={() => { browseFolder(folderId); moveFolderHandler(selectedFolder.id); handleModalClose(); }} />
            }

            {
                confirmationModalOpen && <ConfirmActionModal
                    open={confirmationModalOpen}
                    onClose={() => setConfirmationModalOpen(false)}
                    onConfirm={() => { removeFolderHandler(selectedFolder.id); handleModalClose(); }}
                    title={"Remove Folder"}
                    message={"Are you sure you want to remove this folder? \n All tapes and subfolders will be removed."}
                />
            }
        </div>
    )
}

export default FoldersContainer;