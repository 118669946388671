import React, { useState, useRef, useEffect } from 'react'
import { getAllPhrasesForCustomer } from '../../utils/network';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import ClearSearch from "app/styles/assets/images/icons/callReview/ClearSearch.svg";
import { getMatchingCategoryHighlights, getMatchingCategoryHighlightsForMultiple, getMatchingTranscriptHighlights } from "../../utils/helpers";
import { fetchDataSafely } from '../../utils/dataUtils';
import { useSelector } from 'react-redux';
import { TextBase } from 'app/designSystem';
import { InputBase, InputAdornment, CircularProgress, IconButton } from "@material-ui/core";
import classNames from 'classnames';

const SearchWithinCall = ({ matchingHighlights, setMatchingHighlights }) => {

    const [searching, setSearching] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchOptions, setSearchOptions] = useState(null);
    const [allPhrases, setAllPhrases] = useState([]);
    const call = useSelector(store => store.call);
    const [showInput, setShowInput] = useState(false);
    const [selectedSearchOption, setSelectedSearchOption] = useState(call.selectedSearchOption);
    const typingDone = useRef(null);
    const ref = useRef(null);

    const highlightsData = fetchDataSafely(call, "details.summary.highlights");
    const summaryData = fetchDataSafely(call, "details.summary", null);
    let speakers = ["Speaker 1", "Speaker 2"];
    const turns = useSelector(store => store.call.details.callData.conversationTurn);
    if (summaryData && summaryData.speakerNames) {
        speakers = summaryData.speakerNames;
    }

    useEffect(() => {
        if (showInput && ref.current) {
            ref.current.focus();
            ref.current.autofocus = true;
        }
    }, [showInput])

    useEffect(() => {
        setSelectedSearchOption(call.selectedSearchOption);
        if (call.selectedSearchOption)
            handleSearchOptionSelection(call.selectedSearchOption);
    }, [call.selectedSearchOption])

    useEffect(() => {
        getAllPhrases();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.focus();
                ref.current.autofocus = true;
            }
        }, 1000);
    }, [ref]);

    useEffect(() => {
        if (matchingHighlights.highlightGroups?.length === 0) {
            setSearchTerm("");
            setShowInput(true);
        }
    }, [matchingHighlights])

    async function getAllPhrases() {
        const response = await getAllPhrasesForCustomer();
        console.log("phrases:", response);
        setAllPhrases(response);
    }

    function handleSearchOptionSelection(option) {
        setSelectedSearchOption(option);
        setShowInput(false);
        setSearchOptions(null);
        setSearching(true);
        if (option.type === "Highlights") {
            if (option && option.keyword.trim() !== "") {
                if (option.keyword.includes(",")) {
                    const keywords = option.keyword.split(",")

                    let filteredHighlights = []

                    for (let keyword of keywords) {
                        const matchingHighlights = getMatchingCategoryHighlightsForMultiple(highlightsData, keyword);
                        filteredHighlights = filteredHighlights.concat(matchingHighlights);
                    }

                    setMatchingHighlights({ type: option.type, highlightGroups: filteredHighlights });
                } else {
                    const matchingHighlightsLocal = getMatchingCategoryHighlights(highlightsData, option);

                    // if only the type is same merge the highlights
                    if (matchingHighlights.type === option.type) {
                        const filteredHighlights = Array.from(new Set([
                            ...matchingHighlights.highlightGroups,
                            ...matchingHighlightsLocal
                        ]))
                        setMatchingHighlights({ type: option.type, highlightGroups: filteredHighlights });
                    } else {
                        setMatchingHighlights({ type: option.type, highlightGroups: matchingHighlightsLocal });
                    }
                }
            } else {
                setMatchingHighlights({ type: null, highlightGroups: [] });
            }
        } else {
            const matchingTurnHighlights = getMatchingTranscriptHighlights(turns, option, option.type === "TranscriptExact" ? true : false);
            setMatchingHighlights({ type: option.type, highlightGroups: [{ groupName: "Transcript Matches", highlights: matchingTurnHighlights }] })
        }
        setSearching(false);
    }

    function clearQuery(e) {
        e.target.value = "";
        handleSearchTermChange(e);
        setMatchingHighlights({ highlightGroups: [] });
        setSelectedSearchOption(null);
    }

    function handleSearchTermChange(e) {
        let value = e.target.value || "";
        let exactSearch = false;
        setSearchTerm(value);
        if (value.length == 0 || value.trim() === "") {
            setMatchingHighlights({ highlightGroups: [] });
            setSelectedSearchOption(null);
        }

        if (value.length < 3) return;

        if (value[0] == "\"" && value[value.length - 1] == "\"") {
            value = value.replaceAll("\"", "");
            exactSearch = true;
        }
        setSearching(true);

        if (typingDone.current !== null) {
            clearTimeout(typingDone.current);
            typingDone.current = null;
        }

        typingDone.current = setTimeout(() => getSearchOptions(value, exactSearch), 1000);
    }

    function getSearchOptions(value, exactSearch) {
        var searchOptionsLocal = [];
        var existingCategories = highlightsData.highlightGroups.map(hg => hg.groupName);
        var matchingTurn = [];
        if (exactSearch) {
            matchingTurn = turns.find(t => t.turnStr.toLowerCase().replace(/[^a-z ]/g, "").split(" ").indexOf(value.toLowerCase()) != -1);
        }
        else matchingTurn = turns.find(t => t.turnStr.toLowerCase().includes(value.toLowerCase()));

        var matchingCategories = [];
        allPhrases.map(p => {
            if (p.text !== "__EMPTY_CATEGORY_PLACEHOLDER__" && existingCategories.includes(p.category) && (p.text.toLowerCase().split(" ").includes(value.toLowerCase()) || p.category.toLowerCase().includes(value.toLowerCase()))) {
                if (!matchingCategories.includes(p.category)) matchingCategories.push(p.category);
            }
        });
        existingCategories.map(c => {
            if (c.toLowerCase().includes(value.toLowerCase())) {
                if (!matchingCategories.includes(c)) matchingCategories.push(c);
            }
        })
        if (matchingTurn) {
            if (exactSearch) searchOptionsLocal.push({ type: "TranscriptExact", keyword: value });
            else searchOptionsLocal.push({ type: "Transcript", keyword: value });
        }
        matchingCategories.map(category => searchOptionsLocal.push({ type: "Highlights", keyword: category, supportText: `Found ${value} as a keyword in this topic` }));
        setSearchOptions(searchOptionsLocal);
        setSearching(false);
    }

    function beautifyType(type) {
        switch (type) {
            case "TranscriptExact": return "Transcript";
            default: return type;
        }
    }
    return (
        <div className={`RightPanel__searchTab ${searchOptions && searchTerm !== "" ? "RightPanel__searchTab__expanded" : ""}`}>
            <div className="RightPanel__searchBar bg-white">
                {(selectedSearchOption === null || showInput) && <InputBase
                    id="SearchWithinCall"
                    className="RightPanel__searchTabInput flex flex-row items-center"
                    inputRef={ref}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search"
                    autoComplete='off'
                    startAdornment={
                        <InputAdornment position="start">
                            {<SearchIcon color="primary" />}
                        </InputAdornment>
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {searchTerm.length > 0 && <IconButton size="small" className="red" onClick={clearQuery} ><ClearIcon color="primary" /></IconButton>}
                        </InputAdornment>}
                />}

                {matchingHighlights.type === "Highlights" && !showInput && (
                    <div onClick={() => { setSearchTerm(""); setShowInput(true); }} className="RightPanel__searchTabInput">
                        {
                            matchingHighlights.highlightGroups?.length > 0 ?
                                <TextBase className="RightPanel__searchResultsText">Multiple highlight search</TextBase>
                                : null
                        }
                        {
                            matchingHighlights.highlightGroups?.length > 0 && <img onClick={clearQuery} className="Call__TranscriptSearchContainer__searchBar-icon RightPanel__searchTabInput__endAdornment" src={ClearSearch} />
                        }
                    </div>
                )}

                {matchingHighlights.type !== "Highlights" && selectedSearchOption && !showInput && (
                    <div onClick={() => setShowInput(true)} className="RightPanel__searchTabInput">
                        <span className="RightPanel__searchResultsText"><b>{selectedSearchOption.keyword}</b> in {beautifyType(selectedSearchOption.type)}</span>
                        <img onClick={clearQuery} className="Call__TranscriptSearchContainer__searchBar-icon RightPanel__searchTabInput__endAdornment" src={ClearSearch} />
                    </div>
                )}

                {searchOptions && searchTerm !== "" &&
                    <div className="absolute z-10 left-0 top-full w-full bg-white rounded-lg shadow-lg p-4">
                        {searchOptions.map((option, i) => {
                            return (
                                <div onClick={() => handleSearchOptionSelection(option)} key={option + i} className="RightPanel__searchResultsRow">
                                    <div className={classNames(option.type === "Highlights" ? "bg-gray-50" : "")}>
                                        <TextBase className="text-ellipsis overflow-hidden py-1 whitespace-nowrap font-medium text-gray-500 "><b className="text-gray-900">{option.keyword}</b> in {option.type.replace("Exact", "")}</TextBase>
                                        {
                                            option.type === "Highlights" &&
                                            <TextBase className="text-ellipsis overflow-hidden py-1 whitespace-nowrap font-medium text-gray-700">{option.supportText}</TextBase>
                                        }
                                    </div>
                                </div>
                            )
                        })}

                        {
                            searchOptions.length === 0 &&
                            <div key={"nores"} className="RightPanel__searchResultsRow">
                                <span className="RightPanel__searchResultsText">No matches found.</span>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

SearchWithinCall.propTypes = {}
SearchWithinCall.defaultProps = {}
export default SearchWithinCall;