import {
  API_URL
} from "app/config";
import { escapeRegExp } from "lodash";

export function gup(url, name) {
  const nameStripped = name.replace(/[[]/, "[").replace(/[]]/, "]");
  const regexS = `[#?&]${nameStripped}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  if (results == null) return "";
  console.log(results[1], decodeURIComponent(results[1]));
  return decodeURIComponent(results[1]);
}

export function openWindowAndListenForParam(url, title, param = "code", callback) {
  const win = window.open(url, title, "width=700, height=600, left=100");
  let code;
  const pollTimer = setInterval(async () => {
    try {
      if (win.closed) {
        clearInterval(pollTimer);
        await callback(null);
      }
      const url = win.document.URL || win.location.href;
      console.log(`url -- ${url}`)
      if (url && url.indexOf(param) !== -1) {
        clearInterval(pollTimer);
        const redirectUrl = url;
        code = gup(redirectUrl, param);
        win.close();
        await callback(code);
      } else {
        console.log("window document url and location href is null.");
      }
    } catch (e) {
      console.log(e);
    }
  }, 100);
}

export function openWindowAndListenForMultipleURLParams(url, title, params = ["code", "state"], callback) {
  const win = window.open(url, title, "width=700, height=600, left=100");
  const pollTimer = setInterval(async () => {
    try {
      if (win.closed) {
        clearInterval(pollTimer);
        await callback(null);
      }
      const url = win.document.URL || win.location.href;
      console.log(`url -- ${url}`)
      if (url && url.indexOf(params[0]) !== -1) {
        clearInterval(pollTimer);
        const redirectUrl = url;
        const args = params.map(param => gup(redirectUrl, param));
        win.close();
        await callback(...args);
      } else {
        console.log("window document url and location href is null.");
      }
    } catch (e) {
      console.log(e);
    }
  }, 100);
}

export function getRedirectUrl(withFullPath = true) {
  console.log("in get redirect url. API URL is:", API_URL);
  if (API_URL.includes("dev") || API_URL.includes("localhost")) {
    let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      frontendUrl = `${frontendUrl}:${window.location.port}`;
    }
    return withFullPath ? frontendUrl + "/onboarding" : frontendUrl;
  }
  const frontendUrl = "https://app.strings.ai";
  return withFullPath ? frontendUrl + "/onboarding" : frontendUrl
}