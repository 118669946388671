import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hideSnackbar, setSnackbar } from "app/actions/screen";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Chip } from "@material-ui/core";
import { Button } from "app/designSystem";
import {
  getDocFields,
  setFieldAsRequired,
  setFieldAsNotRequired
} from "../../utils/network";

const DocumentFields = ({ type, setSnackbar, hideSnackbar, disabled }) => {
  const [fields, setFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [field, setField] = useState(null);
  const [loading, setLoading] = useState(false);
  const fieldsFetcher = useCallback(fetchFields, [type]);

  useEffect(() => {
    fieldsFetcher();
  }, []);

  async function fetchFields() {
    const fieldObjects = await getDocFields(type);
    setFields(getFieldsFromFieldsObject(fieldObjects, false));
    setRequiredFields(getFieldsFromFieldsObject(fieldObjects, true));
  }

  async function addRequiredField() {
    if (!field) return { error: true };
    setLoading(true);
    const res = await setFieldAsRequired(type, field.value);
    setSnackbar("Syncing of CRM fields could take several hours for older calls.", "INFO");
    setField("");
    fieldsFetcher();
    setLoading(false);
    return res;
  }

  async function removeRequiredField(deletedField) {
    await setFieldAsNotRequired(type, deletedField.value);
    fieldsFetcher();
  }

  function handleChange(selectedField) {
    setField(selectedField);
  }

  return (
    <div className="documentSection">
      <span className="label">{type}</span>
      <div className="inputContainer">
        <Select
          value={field}
          isSearchable
          className="Select"
          classNamePrefix="Select"
          components={makeAnimated()}
          onChange={handleChange}
          placeholder="Select Field"
          options={fields}
          isDisabled={disabled}
        />
        <Button className="w-32" loading={loading} onClick={addRequiredField} disabled={disabled}>
          {"Add Field"}
        </Button>
      </div>
      <div className="tagsContainer">
        {requiredFields.map((requiredField, i) => (
          <Chip
            key={i}
            label={requiredField.label}
            className="tag"
            onDelete={() => removeRequiredField(requiredField)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export function getFieldsFromFieldsObject(fieldObjects, required) {
  const arr = Object.keys(fieldObjects)
    .filter(fieldName => fieldObjects[fieldName].required === required)
    .map(fieldName => {
      return { value: fieldName, label: fieldObjects[fieldName].displayName };
    });
  return arr;
}

const mapStateToProps = store => {
  return null;
};

export default connect(
  mapStateToProps,
  { hideSnackbar, setSnackbar },
)(DocumentFields);

DocumentFields.propTypes = {
  type: PropTypes.string.isRequired
};