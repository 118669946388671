import React, { useState } from "react";
import PropTypes from "prop-types";
import ContactIcon from "app/styles/assets/images/icons/dealView/ContactIcon.svg";
import { checkIfExternalFromEmail, getContactName } from "../../utils/helpers";
import ProfileImage from "../common/ProfileImage";
import { useContext } from "react";
import { UserNameContext } from "../../containers/AccountView";
import ContactWithCrmLink from "app/components/dealView/ContactWithCrmLink";
import { useSelector } from "react-redux";
import useDataHelper from "../../utils/hooks/useDataHelper";
import { useHoverListenerRef } from "../../utils/hooks/useHoverListener";
import { TextBase } from "app/designSystem";

const ContactsPopup = ({ contacts, crmLinks }) => {
    return (
        <div className="DealView__AttendeePopup !w-96 p-4">
            <span className="font-medium">CRM Contacts</span>
            <div className="overflow-y-auto pr-3 ">
                {contacts.map((contact) => {
                    return (
                        <ContactWithCrmLink key={contact?.id} contact={contact} crmLinks={crmLinks} />
                    );
                })}
            </div>
        </div>
    );
};
ContactsPopup.propTypes = {};
ContactsPopup.defaultProps = {};
export default ContactsPopup;

export const EmailAttendeePopup = ({ reps, contacts, type }) => {
    const parentClass =
        type === "small"
            ? "DealView__AttendeePopupSmall"
            : "DealView__AttendeePopup";

    const { getRepName } = useContext(UserNameContext);
    return (
        <div className="DealView__AttendeePopup DealView__EmailAttendeePopup">
            <span className={`${parentClass}__headingSmall`}>To:</span>
            {reps &&
                reps.map((rep, i) => {
                    return (
                        <div key={i} className={`${parentClass}__contactContainer`}>
                            <ProfileImage
                                className="EmailPreview__repIcon"
                                size={type === "small" ? 20 : 30}
                                email={rep.userEmail}
                                userId={rep.userId}
                            />
                            <div className={`${parentClass}__contactNameContainer`}>
                                <span className={`${parentClass}__contactName`}>
                                    {getRepName(rep.userId, rep.userEmail)}
                                </span>
                                <span className={`${parentClass}__contactJob`}>
                                    {rep.userEmail}
                                </span>
                            </div>
                        </div>
                    );
                })}
            {contacts &&
                contacts.map((contact, i) => {
                    return (
                        <div key={i} className={`${parentClass}__contactContainer`}>
                            <img
                                className={`${parentClass}__contactIcon`}
                                src={ContactIcon}
                            />
                            <div className={`${parentClass}__contactNameContainer`}>
                                <span className={`${parentClass}__contactName`}>
                                    {getContactName(contact)}
                                </span>
                                <span className={`${parentClass}__contactJob`}>
                                    {contact.jobTitle}
                                </span>
                                <span className={`${parentClass}__contactJob`}>
                                    {contact.email}
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export const CallAttendeePopup = ({ reps, contacts, type }) => {
    const { getRepName } = useContext(UserNameContext);
    const parentClass =
        type === "small"
            ? "DealView__AttendeePopupSmall"
            : "DealView__AttendeePopup";
    return (
        <div className="DealView__AttendeePopup DealView__EmailAttendeePopup">
            <span className={`${parentClass}__heading`}>Participants</span>
            {reps && <span className={`${parentClass}__headingSmall`}>Reps:</span>}
            <div className={`${parentClass}__repsContainer`}>
                {reps &&
                    reps.map((rep, i) => {
                        return (
                            <ProfileImage
                                key={i}
                                className="EmailPreview__repIcon"
                                email={rep.userEmail}
                                size={type === "small" ? 20 : 30}
                                userId={rep.userId}
                            />
                        );
                    })}
            </div>
            {contacts && (
                <span className={`${parentClass}__headingSmall`}>Contacts:</span>
            )}
            {contacts &&
                contacts.map((contact, i) => {
                    return (
                        <div key={i} className={`${parentClass}__contactContainer`}>
                            <img
                                className={`${parentClass}__contactIcon`}
                                src={ContactIcon}
                            />
                            <div className={`${parentClass}__contactNameContainer`}>
                                <span className={`${parentClass}__contactName`}>
                                    {getContactName(contact)}
                                </span>
                                <span className={`${parentClass}__contactJob`}>
                                    {contact.jobTitle}
                                </span>
                                <span className={`${parentClass}__contactJob`}>
                                    {contact.email}
                                </span>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};

export const AttendeePopup = ({
    attendees,
    emailIds,
    names,
    imageSize,
    margin,
}) => {
    const { getUserName } = useDataHelper();
    const authorizedDomains = useSelector(
        (store) => store.auth.stringsCustomer.authorizedDomains
    );

    return (
        <div
            className="CrmContextContainer__callDetails__tooltip"
            style={{ left: margin }}
        >
            {attendees.map((attendee, i) => {
                return (
                    <div className="CrmContextContainer__callDetails__tooltipRow">
                        <div className="CrmContextContainer__callDetails__tooltipSection">
                            {!checkIfExternalFromEmail(emailIds[i], authorizedDomains) && (
                                <ProfileImage
                                    key={attendee}
                                    size={imageSize}
                                    userId={attendee}
                                    disableTooltip={true}
                                    className="CrmContextContainer__callDetails__tooltipProfile"
                                />
                            )}
                            {checkIfExternalFromEmail(emailIds[i], authorizedDomains) && (
                                <img
                                    src={ContactIcon}
                                    style={{
                                        height: imageSize + "px",
                                        width: imageSize + "px",
                                        borderRadius: imageSize / 2 + "px",
                                        marginRight: "15px",
                                    }}
                                />
                            )}
                        </div>
                        <div className="CrmContextContainer__callDetails__tooltipSection">
                            <div className="CrmContextContainer__callDetails__tooltipName">
                                {getUserName(attendee) || names[i] || "---"}
                            </div>
                            <div className="CrmContextContainer__callDetails__tooltipEmail">
                                {emailIds[i]}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
