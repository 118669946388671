import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const TooltipText = ({ tip, visible, horizontal }) => {
  const className = horizontal ? "TooltipTextHorizontal" : "TooltipText";
  return (
    <div className={classNames(className, visible ? "" : "hidden")}>
      <span className="text">{tip}</span>
    </div>
  );
};

TooltipText.propTypes = {
  tip: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
  horizontal: PropTypes.bool,
};

TooltipText.defaultProps = {
  showTooltip: false,
  horizontal: false,
};

export default TooltipText;
