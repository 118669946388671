import React, { useState } from "react";
import { sendRequest } from "app/utils/network";
import { Button } from "app/designSystem";
import { Input } from 'app/designSystem/form/';
import { useSelector, connect } from "react-redux";
import { useRef } from "react";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { fetchDataSafely } from "../../utils/dataUtils";
import { refreshUserAction } from "app/actions/auth";
import { Checkbox, Radio } from "@material-ui/core";
import { CustomDropdown } from "../common";
import EditReminderEmailModal from "./EditReminderEmailModal";
import { ReactComponent as EditPenIcon } from "app/styles/assets/images/icons/comments/EditPenIcon.svg";
import RecorderComplianceSettings from "./RecorderComplianceSettings";

const RecorderSettings = ({ refreshUserAction }) => {
  const auth = useSelector((store) => store.auth);
  const customer = useSelector((store) => store.auth.stringsCustomer);
  const [name, setName] = useState(fetchDataSafely(customer, "processConfig.botName") || "wingman note-taker");
  const [sendEmailBeforeTime, setSendEmailBeforeTime] = useState(fetchDataSafely(customer, "callNotificationSecondsInAdvance") / 60 || null);
  const [showReminderEmailModal, setShowReminderEmailModal] = useState(false);
  const timeout = useRef(null);
  const timeValues = [5, 10, 15];
  const { setSnackbar } = useSnackbar();
  const [botJoinType, setBotJoinType] = useState(fetchDataSafely(customer, "processConfig.botJoinType") || "ALL_EXTERNAL_MEETINGS");
  const [enableAllMeetingsButton, setEnableAllMeetingsButton] = useState(customer?.featuresGated?.enableInternalMeetingRecording ?? false);

  async function handleNameChange(e) {
    const nameLocal = e.target.value;
    setName(nameLocal);
    if (timeout.current !== null) {
      clearTimeout(timeout.current);
      timeout.current = null;
    }
    timeout.current = setTimeout(() => changeNameInBackend(nameLocal), 500);
  }

  async function handleBotJoinType(type) {
    const params = new URLSearchParams();
    setBotJoinType(type);
    params.append("botJoinType", type);
    await sendRequest("/admin/set_bot_join_type", params);
    refreshUserAction();
    setSnackbar("Changes Saved. Recording settings for your teams upcoming calls will be updated in 5 mins.", "SUCCESS");
  }

  function handleSendInviteEmailChange(e) {
    const inviteEmailSending = e.target.checked;
    if (inviteEmailSending) setSendEmailBeforeTime(5);
    else setSendEmailBeforeTime(null);
    setEmailMinChangeInBackend(inviteEmailSending ? 5 : null);
  }

  function handleSendInviteEmailMinChange(min) {
    setSendEmailBeforeTime(min);
    setEmailMinChangeInBackend(min);
  }

  async function setEmailMinChangeInBackend(min) {
    const params = new URLSearchParams();
    params.append("enable", min ? true : false);
    if (min) params.append("timeBeforeMeeting", min * 60);
    await sendRequest("/admin/change_recorder_email_settings", params);
    refreshUserAction();
  }

  async function changeNameInBackend(nameLocal) {
    const params = new URLSearchParams();
    params.append("botName", nameLocal);
    await sendRequest("/admin/rename_recorder_bot", params);
    refreshUserAction();
    setSnackbar("Changes Saved", "SUCCESS");
  }



  return (
    <div className="Settings__profileContainer">
      <span className="Settings__header">Recorder Settings</span>
      {customer?.featuresGated?.botRenaming && <span className="Settings__label">Wingman Bot Name</span>}
      {customer?.featuresGated?.botRenaming && (
        <div className="w-2/6 mt-1 mx-0 mb-4">
          <Input value={name} onChange={handleNameChange} disabled={auth.isAdmin ? false : true} />
        </div>
      )}
      <span className="Settings__label">Which video conference meetings should Wingman join?</span>
      <div className="noMargin row horCon">
        <Radio color="primary" checked={botJoinType === "ALL_EXTERNAL_MEETINGS"} onChange={() => handleBotJoinType("ALL_EXTERNAL_MEETINGS")} disabled={!auth.isAdmin} />
        <span className="Settings__notifications-type">All meetings with external participants</span>
      </div>
      <div className="noMargin row horCon">
        <Radio
          color="primary"
          checked={botJoinType == "EXTERNAL_MEETINGS_HOSTED_BY_RECORDING_USER"}
          onChange={() => handleBotJoinType("EXTERNAL_MEETINGS_HOSTED_BY_RECORDING_USER")}
          disabled={auth.isAdmin ? false : true}
        />
        <span className="Settings__notifications-type">
          {"Meetings with external participants "} <b>hosted</b> {"by recording users from " + customer.name}
        </span>
      </div>
      {enableAllMeetingsButton && (
        <div className="noMargin row horCon">
          <Radio color="primary" checked={botJoinType == "ALL_MEETINGS"} onChange={() => handleBotJoinType("ALL_MEETINGS")} disabled={auth.isAdmin ? false : true} />
          <span className="Settings__notifications-type">{"All Meetings"} </span>
        </div>
      )}
      <hr className="mt-4" />
      <span className="Settings__header" style={{ marginTop: "20px" }}>
        Recorder Compliance
      </span>
      <span className="Settings__label">Recording Consent via Email</span>
      <div>
        <div className="loMargin row horCon">
          <Checkbox checked={sendEmailBeforeTime !== null} onChange={handleSendInviteEmailChange} disabled={auth.isAdmin ? false : true} color="primary" />
          <div className="Settings__notifications-typeContent" style={{ marginRight: "10px" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span className="Settings__notifications-type">{"Send a reminder email "}</span>
              <CustomDropdown
                bubbleClass={"RecorderDropdown"}
                bottom={25}
                title={<span className="Settings__notifications-type RecorderDropdown__trigger">{sendEmailBeforeTime ? sendEmailBeforeTime + " mins" : " 5 mins"}</span>}
                list={timeValues.map((time) => (
                  <div key={time} onClick={() => handleSendInviteEmailMinChange(time)} className="Settings__notifications-type RecorderDropdown__item">
                    {time} mins
                  </div>
                ))}
                disabled={auth.isAdmin ? false : true}
              />
              <span className="Settings__notifications-type">{" before meeting"} </span>
            </div>
            <span className="Settings__notifications-typeExplanation text-coolGray-500">
              {"The reminder email will also notify the external guests that the meeting will be recorded by Wingman."}
            </span>
          </div>
        </div>
        <Button
          className="ml-10 mt-2 h-8 hover:wingman-brand"
          variant="secondary"
          onClick={() => setShowReminderEmailModal(true)} disabled={!auth.isAdmin}>
          <EditPenIcon className="mr-1" />
          Edit Email
        </Button>
      </div>
      <hr className="mt-4" />
      <RecorderComplianceSettings disabled={auth.isAdmin ? false : true} />
      <EditReminderEmailModal open={showReminderEmailModal} closeModal={() => setShowReminderEmailModal(false)} />
    </div>
  );
};

const mapStateToProps = (store) => {
  return { ...store };
};

export default connect(mapStateToProps, { refreshUserAction })(RecorderSettings);
