import React, { useState } from 'react'
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import CompanyIcon from "app/styles/assets/images/icons/dealView/CompanyIcon.svg";
import ConversationIcon from "app/styles/assets/images/icons/dealView/ConversationIcon.svg";
import { _formatDateToDaysRelative, handleViewInCrm } from '../../utils/helpers';
import DealField from './DealField';
import { Button } from '../form';
import { withRouter } from 'react-router-dom';
import { sendRequest } from '../../utils/network';
import { useEffect } from 'react';
import CrmIcon from '../common/CrmIcon';
import { useLastLocation } from 'react-router-last-location';
import Skeleton from 'react-loading-skeleton';

const AccountViewHeader = ({ crmData, history, interactions, loading, activeDeal }) => {
    const { account } = crmData;
    const [crmLinks, setCrmLinks] = useState(null);
    const lastLocation = useLastLocation();


    useEffect(() => {
        fetchCrmLinks();
    }, []);

    function goBack() {
        console.log("last location is", lastLocation);
        if (lastLocation) {
            history.goBack();
        } else {
            history.push("/dealCentral");
        }
    }

    async function fetchCrmLinks() {
        const response = await sendRequest("/user/get_crm_links");
        setCrmLinks(response);
    }

    if (loading) return (
        <SkeletonHeader goBack={goBack} />
    );

    return (
        <div className="DealView__Header">
            <div className="DealView__Header__metaContainer">
                <div className="DealView__Header__nameContainer">
                    <div onClick={goBack} className="BackButton">
                        <BackIcon color="primary" />
                    </div>
                    <div className="DealView__Header__companyDiv">
                        <img src={CompanyIcon} className="DealView__Header__companyIcon" />
                        <span className="DealView__Header__companyName">{account?.name ?? activeDeal?.opportunityName}</span>
                    </div>
                </div>
            </div>
            <div className="DealView__Header__fieldsContainer">
                <DealField icon={ConversationIcon} value={interactions.length > 0 ? _formatDateToDaysRelative(interactions[0].date) : null} label="Last Contacted" />
            </div>

            <div className="DealView__Header__rightContainer">
                <Button
                    variant="secondary"
                    onClick={() => { handleViewInCrm(crmLinks, account, activeDeal) }}>
                    <CrmIcon height="20px" width="20px" />
                    <span className="ml-1">View in CRM</span>
                </Button>
            </div>
        </div >
    );
}
AccountViewHeader.propTypes = {}
AccountViewHeader.defaultProps = {}
export default withRouter(AccountViewHeader);

export const SkeletonHeader = ({ goBack, showBackBtn = true }) => (
    <div className="DealView__Header">
        <div className="DealView__Header__companyDiv">
            {showBackBtn &&
                <div onClick={goBack} className="BackButton">
                    <BackIcon color="primary" />
                </div>
            }
            <div className="DealView__Header__companyDiv">
                <Skeleton circle={true} height={40} width={40} />
                <span className="DealView__Header__companyName"><Skeleton height={25} width={200} />
                </span>
            </div>
        </div>
    </div>
);