import React, { useState } from 'react';
import { Grid, Modal, Paper } from '@material-ui/core';
import TeamDashboardMultiLineComparison from './TeamDashboardMultiLineComparison';
import { Doughnut } from 'react-chartjs-2';
import { getChartColor } from './helpers';
import { NoDataCustom } from '../common/NoData';
import { emptyFilterParams } from '../../utils/filter';
import { clone } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CALL_LOG_FILTER_AND_REFRESH } from '../../actions/actionTypes';
import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from "../form";
import TeamDashboardAddCompetitorPopup from './TeamDashboardAddCompetitorPopup'
import NoDataIcon from "app/styles/assets/images/icons/noData.svg";
import ShareIcon from "app/styles/assets/images/icons/shareIcon.svg";
import { Link } from "react-router-dom";

const TeamDashboardCompetitorInsights = ({ nCalls, mentions }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(store => store.screen.loading);
  const existingFilterParams = useSelector(store => store.screen.managerDashboardFilter);
  const competitors = mentions !== null ? Object.keys(mentions).map(name => ({ name, value: mentions[name], maxVal: nCalls })).sort((a, b) => (a.value > b.value) ? -1 : (a.value === b.value ? 0 : 1)) : null;
  const seed = 0;
  const auth = useSelector(store => store.auth);
  const [showAddCompetitorPopup, setShowAddCompetitorPopup] = useState(false)

  function makeChartJsData(stats) {
    if (!stats) return null;
    const labels = competitors.map(c => c.name);
    const data = competitors.map(c => c.value);
    const backgroundColor = labels.map((k, i) => getChartColor(i));
    return {
      datasets: [{ type: 'pie', data, backgroundColor }],
      labels
    };
  }

  function openCallLogWithCompetitorFilter(competitor) {
    const filterParamsUpdated = clone(existingFilterParams);
    filterParamsUpdated.competitorMentioned = [competitor];
    dispatch({ type: SET_CALL_LOG_FILTER_AND_REFRESH, filterParams: filterParamsUpdated });
    history.push({
      pathname: `/calllog`,
    });
  }

  function toggleShowAddCompetitorPopup() {
    setShowAddCompetitorPopup(showAddCompetitorPopup => !showAddCompetitorPopup);
  }

  function hideAddCompetitorPopup() {
    setShowAddCompetitorPopup(false)
  }

  if (!loading && (competitors === null || competitors.length === 0))
    return (
      auth.isAdmin ?
        <NoCompetitorsSectionForAdmin
          icon={NoDataIcon}
          heading="No competitor found"
          subheading="Add a few competitors to unlock these insights"
        /> :
        <NoDataCustom
          copy="No competitor found"
          subcopy="Ask your admin to add competitors to unlock these insights."
        />

    )
  if (loading) return null;
  return (
    <div className="SPaper relative" style={{ width: "70%" }}>
      <div className="row-start ">
        <div style={{ padding: '15px 10px', width: "50%" }}>
          <div className="TeamDashboard__tabHeading" style={{ marginLeft: "10px" }}>Competitor Mentions</div>
          <div className="TeamDashboard__CompetitorLegend-chart">
            <Doughnut width={200} data={makeChartJsData(mentions)} options={{ legend: false }} />
          </div>
        </div>
        <div style={{ marginTop: '100px', marginLeft: "-10px", width: "70%" }}>
          <div className="TeamDashboard__CompetitorLegend-heading">Legend</div>
          <div className="TeamDashboard__CompetitorLegend">
            {competitors.map((c, i) => {
              return (
                <div key={i} onClick={() => openCallLogWithCompetitorFilter(c.name)} className="TeamDashboard__CompetitorLegend-item">
                  <span className="TeamDashboard__CompetitorLegend-color" style={{ backgroundColor: getChartColor(i) }} />
                  <div>
                    <p className="TeamDashboard__CompetitorLegend-name">{c.name}</p>
                    <p className="TeamDashboard__CompetitorLegend-value">{c.value} calls</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {auth.isAdmin &&
        <OutsideClickHandler onOutsideClick={hideAddCompetitorPopup}>
          <div className="absolute top-4 right-4">
            <Button
              type="small"
              theme="light"
              onClick={toggleShowAddCompetitorPopup}
            >
              + Add Competitor
            </Button>
            {showAddCompetitorPopup &&
              <div
                className="absolute w-px-100"
                style={{ width: "25vw" }}
              >
                <TeamDashboardAddCompetitorPopup onCancel={hideAddCompetitorPopup} />
              </div>}
          </div>
        </OutsideClickHandler>}
    </div>
  );
}

export default TeamDashboardCompetitorInsights;

const NoCompetitorsSectionForAdmin = ({
  icon,
  heading,
  subheading
}) => {
  const [showModal, setshowModal] = useState(false)

  function handleClose() {
    setshowModal(false)
  }

  return (
    <div className="flex flex-col h-4/5 w-full items-center justify-center">
      <img className="w-72 my-6" src={icon} alt="Logo" />
      <span className="text-xl font-semibold">{heading}</span>
      <span className="mt-1 mb-10 text-center text-gray-400 text-sm font-normal">{subheading}</span>
      <Button
        type="medium"
        theme="dark"
        width="25rem"
        onClick={() => setshowModal(true)}
      >
        Add Competitor
      </Button>
      <Link to={"/settings#keywords"}>
        <div className="flex flex-row items-center mt-4">
          <span className="px-2 text-base font-medium text-blue-700" > Manage Topics </span>
          <img className="w-5" src={ShareIcon} alt="Logo" />
        </div>
      </Link>
      <Modal open={showModal} onClose={handleClose}>
        <div className=" w-1/4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <TeamDashboardAddCompetitorPopup
            onCancel={handleClose}
          />
        </div>
      </Modal>
    </div>
  )
}
