<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#2680EB"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.9961 27.3778L19.5739 37.8395C18.8812 38.2554 17.9923 38.0145 17.5883 37.3013C17.4589 37.0728 17.3906 36.8129 17.3906 36.5483V15.625C17.3906 14.7995 18.0406 14.1302 18.8424 14.1302C19.0994 14.1302 19.3519 14.2005 19.5739 14.3338L36.9961 24.7954C37.6887 25.2114 37.9227 26.1266 37.5188 26.8397C37.3926 27.0625 37.2125 27.2479 36.9961 27.3778Z" fill="white"/>
</svg>
import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { _formatSecondsToPlayingTime } from "../../utils/helpers";
import ThumbnailViewer from "./ThumbnailViewer";
import { useSelector } from "react-redux";
import { flattenHighlights, _formatTalkListenRatio } from "../../utils/helpers";
import CommentIcon from "app/styles/assets/images/icons/player/timelineComment.svg";
import BookmarkIcon from "app/styles/assets/images/icons/player/timelineBookmark.svg";
import HighlightIcon from "app/styles/assets/images/icons/player/timelineHighlight.svg";
import { fetchDataSafely } from "../../utils/dataUtils";
import PlayerHighlightsTimeline from "./PlayerHighlightsTimeline";

const PlaybackWaveform = props => {
  const timeline = useRef(null);
  const [lineheight, setLineheight] = useState(0);
  const [hightlightDataPoints, setHighlightDataPoints] = useState([])
  const call = useSelector(store => store.call);
  const selectedHighlightSection = useSelector(store => store.call.highlightsSection);
  const activeTabName = useSelector(store => store.call.activeTabName);
  const callData = fetchDataSafely(call, "details.callDataDocument");
  const summaryData = fetchDataSafely(call, "details.summary");
  const highlightsData = fetchDataSafely(call, "details.summary.highlights");

  useEffect(() => {
    if (!props.fullscreenVideo) {
      setTimeout(() => {
        if (document.getElementById("Player__speakerTimelines"))
          setLineheight(document.getElementById("Player__speakerTimelines").clientHeight + 13);
      }, 500);
    }
  }, [props.progressProps.playing, props.fullscreenVideo])

  useEffect(() => {
    if (!selectedHighlightSection || activeTabName !== "highlights") {
      setHighlightDataPoints([])
      return;
    }

    if (selectedHighlightSection === "Others") {
      setHighlightDataPoints([])
    } else {
      const tempHightlightsData = highlightsData.highlightGroups.filter(({ groupName }) => groupName.toLowerCase().includes(selectedHighlightSection.toLowerCase()));
      setHighlightDataPoints(tempHightlightsData)
    }
  }, [activeTabName, selectedHighlightSection, highlightsData])

  function handleClick(e) {
    e.persist();
    const skippPointRate = (e.clientX - timeline.current.getBoundingClientRect().x) / timeline.current.clientWidth;
    props.progressProps.onSkipPress(skippPointRate);
  }

  return (
    <div className="Timeline Timeline__firstRow">
      <div ref={timeline} className="Timeline__progressMain">
        <ThumbnailViewer isSharedCall={props.progressProps.isSharedCall} progressBarWithBookmark showPointerLine duration={props.howlerProps.duration} handleClick={handleClick} />
        <div className="Timeline__progress-background" />
        {!props.progressProps.isSharedCall && !props.fullscreenVideo && <PlayerHighlightsTimeline
          call={call}
          highlightsData={hightlightDataPoints}
          duration={props.howlerProps.duration}
          callData={callData}
          summaryData={summaryData}
        />}
        <div
          className="Timeline__progress-cont"
          role="presentation"
        >
          <div
            style={{ width: `${props.progressProps.barWidth}%` }}
            className="Timeline__progress-cont-bar"
          />
        </div>
        <div
          style={{ left: `${props.progressProps.barWidth}%` }}
          className="Timeline__progress-handle"
        />
        {props.progressProps.playing && !props.fullscreenVideo && < div
          style={{ height: `${lineheight}px`, left: `${props.progressProps.barWidth}%` }}
          className="Timeline__progress-line"
        />}
      </div>
      <div className="Timeline__playing-time">
        <span className="Timeline__playing-time-text">{props.playingTimeProps.current}</span>
        <span className="Timeline__playing-time-text" style={{ "marginLeft": "auto" }}>{props.playingTimeProps.duration}</span>
      </div>
    </div>
  );
};

export default PlaybackWaveform;
