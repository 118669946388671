import React, { useEffect } from "react";
import { ReactComponent as PlaylistIcon } from "app/styles/assets/images/icons/gameTapes/gameTape.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateGameTapeForm } from "../gameTapesLatest/modal"
import { SET_SHOW_ADD_TO_GAMETAPE_MODAL } from "../../actions/actionTypes";
import { Button } from "app/designSystem";

const AddToGameTapeButton = ({ }) => {
  const { videoAvailable, videoProcessed } = useSelector(store => store.call);
  const screen = useSelector(store => store.screen);
  const [openModal, setOpenModal] = useState(screen.showAddToGameTapeModal);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("setting setOpenModal to", screen.showAddToGameTapeModal);
    setOpenModal(screen.showAddToGameTapeModal);
  }, [screen.showAddToGameTapeModal]);

  function handleSetOpenModal(bool) {
    dispatch({ type: SET_SHOW_ADD_TO_GAMETAPE_MODAL, showAddToGameTapeModal: bool });
    setOpenModal(bool);
  }

  return (
    <>
      <Button startIcon={<PlaylistIcon />} className="mx-1" variant="secondary" onClick={() => handleSetOpenModal(true)}>
        Create game tape
      </Button>
      {openModal && <CreateGameTapeForm videoAvailable={videoAvailable} videoProcessed={videoProcessed} open={openModal} closeModal={() => handleSetOpenModal(false)} />}
    </>
  );
}

export default AddToGameTapeButton;
