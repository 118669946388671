import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setStackedScreenName, setScreenType, setLoading } from "app/actions/screen";
import {
  getContactById,
  getCallsForEmail,
  getContactByEmail,
  refreshUser,
} from "app/utils/network";
import { STACKED, MAIN } from "app/constants";
import { SPaper, PeopleTag, NoData } from "app/components/common";
import { CallCardWithStats } from "app/components/opportunities";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: null,
      calls: [],
      okayTLRatioThreshold: null,
      goodTLRatioThreshold: null,
    };
  }
  async componentDidMount() {
    this.props.setStackedScreenName("Contact Calls");
    this.props.setScreenType(STACKED);
    const response = await refreshUser();
    this.setState({
      okayTLRatioThreshold: response.user.stringsCustomer.settings.okayTLRatioThreshold,
      goodTLRatioThreshold: response.user.stringsCustomer.settings.goodTLRatioThreshold,
    });
  }
  async UNSAFE_componentWillMount() {
    this.props.setLoading(true);
    this.props.setStackedScreenName("Contact Details");
    await this.loadCalls(this.props.match.params.contactInfo);
    this.props.setLoading(false);
  }
  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.contactInfo !== nextProps.match.params.contactInfo) {
      this.props.setLoading(true);
      await this.loadCalls(nextProps.match.params.contactInfo);
      this.props.setLoading(false);
    }
  }
  componentWillUnmount() {
    this.props.setScreenType(MAIN);
  }
  async loadCalls(contactEmail) {
    let contact = null;
    if (contactEmail.indexOf("@") != -1) {
      contact = await getContactByEmail(contactEmail);
    }
    this.setState({
      contact,
    });
    const calls = await getCallsForEmail(contactEmail);
    this.setState({
      calls,
    });
  }
  render() {
    const { contact } = this.state;
    if (this.state.calls.length === 0) {
      return (
        <NoData
          title="No calls found"
          style={{ height: "calc(100vh - 60px)", margin: "-1em -2em" }}
        />
      );
    }
    return (
      <div>
        {this.state.calls.length > 0 && (
          <div style={{ marginTop: "30px" }} className="Manager-Dashboard__head-time">
            Past Calls
          </div>
        )}
        {this.state.calls.map((call, i) => {
          return (
            <CallCardWithStats
              okayTLRatioThreshold={this.state.okayTLRatioThreshold}
              goodTLRatioThreshold={this.state.goodTLRatioThreshold}
              call={call}
              key={i}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

Contact.propTypes = {
  classes: PropTypes.object,
  setStackedScreenName: PropTypes.func.isRequired,
  setScreenType: PropTypes.func.isRequired,
};

Contact.defaultProps = {};

export default connect(
  mapStateToProps,
  { setScreenType, setStackedScreenName, setLoading },
)(Contact);
