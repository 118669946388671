import React from 'react';
import { _formatTalkListenRatio } from '../../utils/helpers';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getTLRatioColor } from "../dashboards/helpers";
import { connect } from "react-redux";

const TeamDashboardTLRatio = props => {
  const {
    okayTLRatioThreshold,
    goodTLRatioThreshold
  } = props.auth.stringsCustomer.settings;

  const pathColor = getTLRatioColor(
    props.tlRatio,
    okayTLRatioThreshold,
    goodTLRatioThreshold
  );

  function formatPercent(percent) {
    if (!Number.isFinite(percent)) return "-";
    return _formatTalkListenRatio(percent, '');
  }

  return (
    <div style={{ height: '35px', width: '35px', marginLeft: "15px" }}>
      <CircularProgressbar
        value={props.tlRatio}
        maxValue={1}
        strokeWidth={12}
        text={formatPercent(props.tlRatio)}
        styles={buildStyles({
          pathTransitionDuration: 0.5,
          pathColor
        })} />
    </div>
  );
}

export default connect(a => a, {})(TeamDashboardTLRatio);