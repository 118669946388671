import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import SalesforceLogo from "app/styles/assets/images/onboarding/salesforce.png";
import { SALESFORCE_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncSalesforce = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync(code) {
    const res = await crmOAuthCallback(code, 'SALESFORCE');
    setLoading(false);
    props.refreshUserAction();
  }
  function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const sandboxExperimentFeatureGateEnabled = props.auth.stringsCustomer?.featuresGated?.experiments?.includes("sandbox_salesforce") ?? false;
    let url = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SALESFORCE_CLIENT_ID}&redirect_uri=${location}`;
    if (sandboxExperimentFeatureGateEnabled) {
      url = `https://test.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${SALESFORCE_CLIENT_ID}&redirect_uri=${location}`;
    }
    openWindowAndListenForParam(url, "Salesforce Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={SalesforceLogo}
      iconClass="salesforce_icon"
      heading="Salesforce"
      subHeading="CRM Integrations"
      bodyText="Sync salesforce to to link all CRM info."
      sync={sync}
      loading={loading}
      crm="SALESFORCE"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncSalesforce.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

SyncSalesforce.defaultProps = {
  access: "user",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncSalesforce);
