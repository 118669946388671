import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip';
import InfoIcon from "app/designSystem/icons/info.svg";
const LabelWithExplanation = ({ label, explanation, labelClass, place, tooltipHeading }) => {
    return (
        <div className="LabelWithExplanation">
            <span className={`${labelClass ? labelClass : "LabelWithExplanation__label"}`}>{label}</span>
            <img data-tip data-for={label + "_metric"} src={InfoIcon} className="LabelWithExplanation__infoIcon" />
            <ReactTooltip place={place} id={label + "_metric"} type="light" effect="solid" className="WhiteTooltip">
                {tooltipHeading &&
                    <span className="WhiteTooltip__mainCopy">{tooltipHeading}</span>}
                {!tooltipHeading &&
                    <span className="WhiteTooltip__mainCopy">{`What ${label.endsWith("s") ? "are" : "is"} ${label}?`}</span>}
                <span className="WhiteTooltip__subCopy">{explanation} </span>
            </ReactTooltip>
        </div>
    );
}
LabelWithExplanation.propTypes = {}
LabelWithExplanation.defaultProps = {
    place: "right"
}
export default LabelWithExplanation;