import React, { } from "react";
import { TextBase, TextLarge, Button } from "app/designSystem";
import ProfileImage from "app/components/common/ProfileImage";
import useDataHelper from "app/utils/hooks/useDataHelper";
import moment from "moment"
import useScorecardsPermissionHelper from "app/utils/hooks/useScorecardsPermissionHelper";


const MultipleScoring = ({ scorecards, expandSelectedScorecard, handleScoreAgain }) => {

    const { getUserName } = useDataHelper()
    const { showScoreAgainButton } = useScorecardsPermissionHelper();

    function getscoredDate(date) {
        if (moment().diff(date, "days") > 0) {
            let days = moment().diff(date, "days")
            return days === 1 ? days + " day ago" : days + " days ago"
        }
        else if (moment().diff(date, "hours") > 0) {
            let hours = moment().diff(date, "hours")
            return hours === 1 ? hours + " hour ago" : hours + " hours ago"
        }
        else if (moment().diff(date, "minutes") > 0) {
            let minutes = moment().diff(date, "minutes")
            return minutes === 1 ? minutes + " minute ago" : minutes + " minutes ago"
        }

        return "Just Now";

    }

    return (
        <div className="flex flex-col px-8 overflow-y-auto ">

            <div className="flex flex-col ">
                <TextBase textColor="text-coolGray-500" className="mb-1">Rep scored:</TextBase>
                <div className="flex items-center mt-1 ">
                    <ProfileImage userId={scorecards[0].scoredRepId} disableTooltip={true} size={20} />
                    <TextBase className="ml-1.5">{getUserName(scorecards[0].scoredRepId)}</TextBase>
                </div>

            </div>

            <div className="border border-coolGray-200 mt-5 mb-5"></div>

            <div className="flex flex-col my-3">

                {scorecards && scorecards.map((card, i) => {

                    return (

                        <div className="bg-coolGray-50 border border-coolGray-200  my-3 rounded-lg cursor-pointer  transition-all  hover:shadow-md  flex flex-col min-h-30 py-4" onClick={() => expandSelectedScorecard(card, i)} key={'scored-card-' + i}>

                            <div className="flex flex-row px-5 mt-1 justify-between">

                                <div className="flex flex-col ">
                                    <TextBase textColor="text-coolGray-500"> Score </TextBase>

                                    <TextLarge textColor="text-coolGray-900">{card.totalScore + " / 5"}</TextLarge>

                                </div>

                                <div className="flex flex-row items-center mr-3">

                                    <ProfileImage userId={card.scorerId} disableTooltip={true} size={40} />

                                    <div className=" ml-2 flex-flex-col">

                                        <TextBase className="inline-block  overflow-hidden  w-[5rem] whitespace-nowrap overflow-ellipsis text-ellipsis mb-0" >{getUserName(card.scorerId)}</TextBase>

                                        <TextBase textColor="text-coolGray-500" className="mt-[-0.25rem]">{getscoredDate(card.scoredDate)}</TextBase>
                                    </div>

                                </div>


                            </div>

                            {card.remark &&
                                <div className="flex flex-col px-5 mt-5 pb-4  mr-3">
                                    <TextBase textColor="text-coolGray-500" className="mb-1">Remarks:</TextBase>
                                    <TextBase textColor="text-coolGray-900 flex line-clamp-3 mt-[-0.25rem]">{card.remark}</TextBase>
                                </div>
                            }
                        </div>




                    );


                })}

            </div>
            {showScoreAgainButton() &&
                <div className="flex items-center my-3">
                    <Button
                        variant={"secondary"}
                        className=" !border-coolGray-300 hover:shadow-md w-full"
                        onClick={() => handleScoreAgain()}
                    >
                        <TextBase textColor="text-coolGray-500">Score this call</TextBase>
                    </Button>
                </div>
            }

        </div>
    );
}


export default MultipleScoring