import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from "prop-types";
import { uniqueId } from 'lodash'
/**
 * Component to use simple user actions which don't require an additional save step.(in that case use checkbox)
 */
export default function Toggle({ className, checked, disabled, onChange }) {
    // Use a hidden input tag for accessibilty reasons. 3 Layers
    //1 Static ,off state BG with border
    //2 Enabled toggle Background 
    //A round button .
    //Enabled background slides left to right when toggling 

    // Input Tag to Toggle Div mapping done via label-><Inp/> tag idFor Prop . all instances of Toggle rendered on screen need unique ID to avoid conflicts
    const [toggleInpId] = useState(uniqueId('toggle'))
    return (
        <div className={classNames('wm-toggle relative w-10 h-5 inline-block ', className)}>
            <input type="checkbox" checked={checked} className="wm-toggle-checkbox hidden" name="toggle" id={toggleInpId} onChange={e => onChange(e.target.checked)} disabled={disabled} />
            <label htmlFor={toggleInpId} className={classNames("block h-full overflow-hidden rounded-xl  relative", { 'cursor-pointer': !disabled }, { 'cursor-not-allowed': disabled })}>
                <div className="wm-toggle-off-state rounded-xl border border-solid border-coolGray-200 bg-blue-50 h-full" />
                <div className={classNames("wm-toggle-on-state  w-10 h-5 bg-wingman-brand absolute top-0 left-0 ease-linear transition-all duration-200", { '-ml-10': !checked }, { 'ml-0': checked })} />
                <div className={classNames("wm-toggle-switch absolute w-4 h-4 rounded-full  shadow  top-0 m-0.5 ease-linear transition-all duration-200 bg-white", { 'right-5': !checked }, { 'right-0': checked })} />
            </label>
        </div>
    )
}


Toggle.propTypes = {
    /**
     * Switch state
     * */
    checked: PropTypes.bool,
    /**
     * Callback for  toggle state changes
     * */
    onChange: PropTypes.func,
    /**
     * Callback for  toggle state changes
     * */
    disabled: PropTypes.bool

}
Toggle.defaultProps = {
    checked: false,
    disabled: false
}