import React, { useState } from "react";
import {
  IconButton, MenuItem,
} from "@material-ui/core";
import { CustomDropdown } from "app/components/common";
import AddIcon from "@material-ui/icons/AddBoxRounded";
import { useKeyListener } from "../../../utils/hooks/useKeyListener";
import Select, { components } from 'react-select';
import { Input } from "app/designSystem/form";
import OutsideClickHandler from "react-outside-click-handler";

const CategoryDropDown = props => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(props?.value || "");

  function handleCategorySelection(category) {
    console.log(category);
    props.onChange(category.value);
    setSelectedCategory(category.value);
    setDropdownVisible(false);
  }

  function handleCategoryAddition(category) {
    props.addCategory(category.value);
    handleCategorySelection(category);
  }

  const customStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    }),
    indicatorsContainer: base => ({
      ...base,
      height: 30,
    }),
    option: styles => ({
      minHeight: 35, ...styles
    })
  };

  const SelectMenuButton = (props) => {
    return (
      <>
        <components.MenuList {...props}>
          {props.children}
        </components.MenuList >
        <InputMenuItem addCategory={handleCategoryAddition} />
      </>
    )
  }

  const blurHandler = function (e) {
    console.log(e.currentTarget.contains(e.relatedTarget), e.currentTarget, e.relatedTarget);
    if (!e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode.contains(e.relatedTarget)) {
      setDropdownVisible(false);
    }
  }



  const menuOptions = props.categories.map(category => {
    return { value: category, label: category }
  });

  return (
    <div className="battlecardForm__category__container">
      <div className="battlecardForm__label">Category:</div>
      <OutsideClickHandler onOutsideClick={() => { setDropdownVisible(false) }}>
        <Select
          className="z-20 select_root_class -mt-1"
          options={menuOptions}
          value={{ label: selectedCategory, value: selectedCategory }}
          placeholder="Select category"
          onChange={handleCategorySelection}
          components={{ MenuList: SelectMenuButton }}
          styles={customStyles}
          maxMenuHeight={450}
          onBlur={blurHandler}
          menuIsOpen={dropdownVisible}
          onFocus={() => { setDropdownVisible(true) }}
          blurInputOnSelect={true}
        />
      </OutsideClickHandler>
    </div>
  );
};

const InputMenuItem = props => {
  const [category, setCategory] = useState("");

  function onChange(e) {
    console.log(e);
    setCategory(e.target.value);
  }

  useKeyListener("AddCategory", event => {
    if (event.keyCode !== 13) {
      return;
    }
    addCategory();
  });

  function addCategory() {
    console.log("here")
    if (category.trim() === "") {
      return;
    }
    props.addCategory({ value: category, label: category });
    setCategory("");
  }

  return (
    <div id="AddCategory" onMouseDown={e => e.stopPropagation()} onKeyDown={e => e.stopPropagation()} className="battlecardForm__category__newCategory z-20 -mt-2 pb-1">
      <Input
        className="battlecardForm__input z-30 ml-4"
        value={category}
        onChange={onChange}
        placeholder="Add category"
        inputClassname="px-2 py-1"
      />
      <IconButton onClick={addCategory}>
        <AddIcon color="primary" />
      </IconButton>
    </div>
  );
};

CategoryDropDown.propTypes = {};

export default CategoryDropDown;
