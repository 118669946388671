import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IncomingEmailIcon from "app/styles/assets/images/icons/dealView/IncomingEmailIcon.svg";
import OutgoingEmailIcon from "app/styles/assets/images/icons/dealView/OutgoingEmailIcon.svg";
import ContactIcon from "app/styles/assets/images/icons/dealView/ContactIcon.svg";
import { _formatDateToCallCardDateFormat, getContactName, checkIfExternal, _formatDateToCallCardDateTimeFormat } from '../../utils/helpers';
import Linkify from "react-linkify";
import ProfileImage from '../common/ProfileImage';
import { useSelector } from 'react-redux';
import { fetchDataSafely } from "../../utils/dataUtils";
import { EmailAttendeePopup } from './AttendeePopup';
import { useRef } from 'react';
import { useHoverListenerRef } from '../../utils/hooks/useHoverListener';
import { useContext } from 'react';
import { UserNameContext } from '../../containers/AccountView';
import { sendRequest } from '../../utils/network';
import { PreviewLoading } from './CallPreview';

const EmailPreview = ({ email, crmData }) => {
    const [emailContent, setEmailContent] = useState(email.textBody);
    const [showQuotedText, setShowQuotedText] = useState(false);
    const [emailFullBody, setEmailFullBody] = useState(null);
    const [hasTextOverflow, setHasTextOverflow] = useState(false);
    const [contacts, setContacts] = useState([{ email: "" }]);
    const [reps, setReps] = useState([{ userId: "", email: "" }]);
    const [fromName, setFromName] = useState(email.fromAddress);
    const [fromId, setFromId] = useState(null);
    const [showToPopup, setShowToPopup] = useState(false);
    const customerDomains = useSelector(store => store.auth.stringsCustomer.authorizedDomains);
    const { getRepName } = useContext(UserNameContext);
    const [ref] = useHoverListenerRef(() => setShowToPopup(true), () => setShowToPopup(false), 300);
    useEffect(() => {
        let allAttendees = email.toAddresses;
        allAttendees = allAttendees.map(a => { return ({ email: a }) })
        const contactsLocal = [];
        const repsLocal = [];
        if (!crmData.contacts) crmData.contacts = [];
        let allContacts = [...crmData.contacts, ...fetchDataSafely(email, "crmData.contactDocumentList", [])]
        allAttendees.map(attendee => {
            if (checkIfExternal(attendee, customerDomains)) {
                let match = allContacts.find(c => {
                    if (c.emails) {
                        return c.emails.includes(attendee.email);
                    }
                    return false;
                });
                if (!match) {
                    match = { email: attendee.email };
                }
                contactsLocal.push(match);
            }
            else {
                let match = email.users.find(user => user.userEmail === attendee.email);
                if (!match) {
                    match = { userId: "", userEmail: attendee.email };
                }
                repsLocal.push(match);
            }
        })

        if (email.incoming) {
            let match = allContacts.find(c => {
                if (c.emails) {
                    return c.emails.includes(email.fromAddress);
                }
                return false;
            });
            setFromName(match ? getContactName(match) : email.fromAddress);
        } else {
            let match = email.users.find(user => user.userEmail === email.fromAddress);
            setFromId(match ? match.userId : null);
            setFromName(match ? getRepName(match, email.fromAddress) : email.fromAddress);
        }
        setReps(repsLocal);
        setContacts(contactsLocal);
    }, [])

    useEffect(() => {
        getEmailBody();
    }, [email])

    async function getEmailBody() {
        const params = new URLSearchParams();
        params.append("emailId", email.id);
        const { emailBody, emailMain } = await sendRequest("/account/get_email_by_id", params);
        setEmailFullBody(emailBody);
        const emailContentLocal = extractMainThreadContent(emailMain);
        setEmailContent(emailContentLocal);
        setHasTextOverflow(checkIfTextOverflow(emailBody, emailContentLocal));
    }

    function checkIfTextOverflow(textBody, textContent) {
        if (removeSpecialCharacters(cleanContent(textBody)) === removeSpecialCharacters(cleanContent(textContent))) return false;
        return true;
    }

    function cleanContent(emailBody) {
        return emailBody?.replace(/ *\<[^>]*\> */g, "").replace(/ *\[[^>]*\] */g, "");
    }

    function extractMainThreadContent(email) {
        // break the email body when it finds \nFrom or \nOn pattern
        return email.split(/\nFrom|\nOn/)[0]?.trim();
    }

    function removeSpecialCharacters(emailBody) {
        return emailBody?.replace(/[^a-zA-Z]/g, "");
    }

    const toggleShowQuotedText = () => setShowQuotedText(showQuotedText => !showQuotedText);

    return (
        <div>
            {!emailFullBody && <PreviewLoading />}
            {emailFullBody &&
                <div className="EmailPreview">
                    <div className={"EmailPreview__header EmailPreview__header" + (email.incoming ? "Orange" : "Purple")}>
                        <div className="EmailPreview__subjectContainer">
                            <img className="EmailPreview__mailIcon" src={email.incoming ? IncomingEmailIcon : OutgoingEmailIcon} />
                            <span className={"EmailPreview__subject EmailPreview__subject" + (email.incoming ? "Orange" : "Purple")}>{email.subject}</span>
                        </div>
                        <span className="EmailPreview__date">{_formatDateToCallCardDateTimeFormat(email.messageDate)}</span>
                    </div>
                    <div className="EmailPreview__namesContainer">
                        <div>
                            {email.incoming && <img src={ContactIcon} className="EmailPreview__fromIcon" />}
                            {!email.incoming && <ProfileImage className="EmailPreview__repIcon" size={30} userId={fromId} />}
                        </div>
                        <div>
                            <div className="EmailPreview__names">
                                <span className="EmailPreview__fromName">{fromName}</span>
                                <span className="EmailPreview__toLabel">to</span>
                                <span className="EmailPreview__fromName">{!email.incoming ? getContactName(contacts[0]) : reps[0]?.userEmail}</span>
                                <div ref={ref} style={{ display: "flex", position: "relative" }}>
                                    {email.toAddresses.length > 1 && <span className="EmailPreview__morePeople">+{email.toAddresses.length - 1} Others</span>}
                                    {showToPopup && <EmailAttendeePopup reps={reps} contacts={contacts} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Linkify
                        properties={{
                            style: {
                                color: "blue",
                                fontWeight: "bold",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                                border: "0px",
                                boxShadow: "0px",
                                "&:hover": { border: "0px", backgroundColor: "red" },
                                "&:focus": { border: "0px", backgroundColor: "yellow" },
                                "&:active": { border: "0px", backgroundColor: "blue" }
                            },
                            onClick: function onClick(e) {
                                e.preventDefault();
                                window.open(e.target.getAttribute("href"));
                            }
                        }}
                    >

                        <span className="EmailPreview__body">
                            {cleanContent(showQuotedText ? emailFullBody : emailContent)}
                        </span>

                    </Linkify>
                    {hasTextOverflow && (
                        <div className="EmailPreview__showQuotedTextDiv">
                            <span onClick={toggleShowQuotedText} className="EmailPreview__showQuotedText">{(showQuotedText ? "Hide" : "Show") + " Entire Conversation"}</span>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}


EmailPreview.propTypes = {}
EmailPreview.defaultProps = {}
export default EmailPreview;