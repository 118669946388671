import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "app/components/form";
import { Avatar } from "@material-ui/core";
import { refreshUserAction } from "app/actions/auth";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/EditRounded";
import { sendRequest } from "../../utils/network";
import { useRef } from "react";
import useSnackbar from "../../utils/hooks/useSnackbar";
import AvatarEditor from "react-avatar-editor";
import { useHoverListenerRef } from "../../utils/hooks/useHoverListener";
import { unset } from "lodash";
import { checkIfImage } from "../dashboards/helpers";
import classNames from "classnames";

const CompanyLogoEditor = ({ auth, refreshUserAction, disabled }) => {
  const customer = useSelector((store) => store.auth.stringsCustomer);
  const [companyName, setCompanyName] = useState(customer?.authorizedDomains.length ? customer?.authorizedDomains[0] : null);
  const [email, setEmail] = useState(auth.data.user.emailId);
  const [companyLogo, setCompanyLogo] = useState(customer?.consentPageConfig?.companyLogoUrl);
  const [logoInEdit, setLogoInEdit] = useState(companyLogo);
  const [showEditButton, setShowEditButton] = useState(false);
  const [loading, setLoading] = useState("");
  const [isLogoEdit, setIsLogoEdit] = useState(false);
  const [logoWidth, setLogoWidth] = useState(customer?.consentPageConfig?.logoWidth != 0 ? customer?.consentPageConfig?.logoWidth : 200);
  const [logoHeight, setLogoHeight] = useState(customer?.consentPageConfig?.logoHeight != 0 ? customer?.consentPageConfig?.logoHeight : 120);
  const { setSnackbar } = useSnackbar();
  const minHeight = 30;
  const minWidth = 50;
  const maxHeight = 120;
  const ref = useRef(null);
  const hoverRef = useRef(null);

  useHoverListenerRef(
    hoverRef,
    () => setShowEditButton(true),
    () => setShowEditButton(false)
  );

  const lastUpdatedTime = auth.lastUpdatedTime;

  function handleLogoChange(e) {
    let inputEl = document.getElementById("changeLogo");
    if (inputEl.files) {
      if (checkIfImage(inputEl)) {
        var reader = new FileReader();
        reader.readAsDataURL(inputEl.files[0]);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          console.log("logoeditior", e.target);
          image.onload = function () {
            var height = this.height;
            var width = this.width;

            if (width < minWidth || height < minHeight) {
              setSnackbar("Please upload logo of min width 50px and height of 30px ", "ERROR");
            } else {
              setUpdatedDemensions(height, width);
              setLogoInEdit(inputEl.files[0]);
              setIsLogoEdit(true);
            }
          };
        };
      } else {
        setSnackbar("Please upload files with .jpg or .png or .jpeg ", "ERROR");
      }
    }

    return;
  }
  function setUpdatedDemensions(srcHeight, srcWidth) {
    var ratio = 1;
    if (srcHeight > maxHeight) {
      ratio = maxHeight / srcHeight;
    }

    setLogoHeight(Math.round(srcHeight * ratio));
    setLogoWidth(Math.round(srcWidth * ratio));
  }

  async function handleCompanyLogoSave() {
    setLoading("logo");
    try {
      let image = ref.current.getImageScaledToCanvas().toDataURL();
      const imageData = new FormData();
      imageData.append("image", image);
      imageData.append("email", email);
      imageData.append("logoWidth", logoWidth);
      imageData.append("logoHeight", logoHeight);
      const response = await sendRequest("/admin/change_company_logo", imageData);
      if (response == null || response.error) setSnackbar("Error while trying to update logo. Please try again.", "ERROR");
      else {
        setSnackbar("Logo updated succesfully!", "SUCCESS");
        setCompanyLogo(response);
        refreshUserAction();
      }
    } catch (e) {
      console.log("error in changing logo: ", e);
      setSnackbar("Error while trying to update logo. Please try again.", "ERROR");
    }
    setIsLogoEdit(false);
    setLoading("");
  }

  function handleLogoCancel() {
    setIsLogoEdit(false);
    setLogoWidth(customer?.consentPageConfig?.logoWidth != 0 ? customer?.consentPageConfig?.logoWidth : 200);
    setLogoHeight(customer?.consentPageConfig?.logoHeight != 0 ? customer?.consentPageConfig?.logoHeight : 120);
  }

  return (
    <div className="imageContainer__companyLogo">
      <div style={{ width: logoWidth }} className="CompanyLogo__header"></div>
      {!isLogoEdit && (
        <div style={{ marginTop: "15px" }}>
          <div className="ProfileDropdown__editIcon">
            <label for="changeLogo" className="edit" style={{ left: logoWidth - 25, top: -15, right: unset }}>
              <EditIcon htmlColor="#fff" className={classNames({ "cursor-default": disabled })} />
            </label>
            <input hidden type="file" id="changeLogo" onChange={handleLogoChange} onClick={(e) => (e.target.value = "")} accept="image/*" disabled={disabled} />
          </div>

          {companyLogo && (
            <div style={{ position: "relative" }}>
              <Avatar
                src={companyLogo + "?" + lastUpdatedTime}
                style={{
                  height: logoHeight.toString() + "px",
                  width: logoWidth.toString() + "px",
                  marginBottom: "10px",
                  borderRadius: "0px",
                }}
              />
            </div>
          )}

          {!companyLogo && (
            <span
              className="ProfileDropdown__profileIcon"
              style={{
                height: logoHeight.toString() + "px",
                width: logoWidth.toString() + "px",
                marginRight: "0px",
                borderRadius: "100px",
                fontSize: "66px",
                borderRadius: "0px",
              }}
            >
              {companyName && companyName[0] ? companyName[0] : "L"}
            </span>
          )}
        </div>
      )}
      {isLogoEdit && (
        <div className="col-centered" style={{ alignItems: "unset" }}>
          <div style={{ marginBottom: "10px", width: logoWidth }}>
            <AvatarEditor
              image={logoInEdit}
              ref={ref}
              height={logoHeight}
              width={logoWidth}
              border={10}
              color={[255, 255, 255, 0.6]} // RGBA
              borderRadius={0}
              scale={1}
              rotate={0}
            />
          </div>
          <div className="col-centered" style={{ width: logoWidth }}>
            <Button loading={loading == "logo"} type="medium" theme="dark" onClick={handleCompanyLogoSave}>
              Apply
            </Button>

            <div className="Settings__profileContainerbutton Settings__profileContainernoBorder Settings__profileContainerCancel" onClick={handleLogoCancel}>
              Cancel
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (store) => {
  return { ...store };
};

export default connect(mapStateToProps, { refreshUserAction })(CompanyLogoEditor);
