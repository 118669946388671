import {
    SET_SCORING_SKILLS,
    SET_POPULAR_TEMPLATES
} from "app/actions/actionTypes"

const initialState = {
    skills: [],
    popularTemplates: []
}

export default function (state = initialState, payload) {
    switch (payload.type) {
        case SET_SCORING_SKILLS:
            return {
                ...state,
                skills: payload.skills,
            }

        case SET_POPULAR_TEMPLATES:
            return {
                ...state,
                popularTemplates: payload.popularTemplates,
            }
        default:
            return state;
    }
}