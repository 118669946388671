import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import { refreshUserAction } from "app/actions/auth";
import { setLoading } from "app/actions/screen";
import { Input, Label } from "../form";
import { DESKTOP_APP_S3_BUCKET } from "app/config";
import { setUserMonologueThreshold, setCustomerMonologueThreshold, getLinkForLatestWingmanDownloadableFile } from "../../utils/network";
import { Button } from "app/components/form";

const WingmanSettings = ({ refreshUserAction, auth, setLoading, admin, user }) => {
  const [monologueThreshold, setMonologueThreshold] = useState(
    auth.data.user.monologueThreshold || auth.stringsCustomer.defaultMonologueThreshold || 90,
  );
  const [teamMonologueThreshold, setTeamMonologueThreshold] = useState(
    auth.stringsCustomer.defaultMonologueThreshold || 90,
  );

  const os = window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac";

  async function updateUserMonologue(e) {
    setMonologueThreshold(e.target.value);
    await setUserMonologueThreshold(e.target.value);
    refreshUserAction();
  }

  async function updateCustomerMonologue(e) {
    setTeamMonologueThreshold(e.target.value);
    await setCustomerMonologueThreshold(e.target.value);
    refreshUserAction();
  }

  async function downloadWingman() {
    setLoading(true);
    const link = await getLinkForLatestWingmanDownloadableFile(window.navigator.platform);
    window.open(DESKTOP_APP_S3_BUCKET + link, "_self");
    setLoading(false);
  }

  return (
    <div>
      <div className="Settings__profileContainer">
        <span className="Settings__header">{admin ? "Real Time Coaching (Admin)" : "Real Time Coaching (Personal)"}</span>
        <span className="Settings__headerExplanation">Note: You need to download the <a onClick={downloadWingman}>Wingman Desktop App</a> to get real time coaching prompts like Cuecards, Monologue Alerts etc.</span>
        <div className="row horCon">
          <div className="Settings__notifications-typeContent">
            <span className="Settings__notifications-type">Monologue Threshold</span>
            <span className="Settings__notifications-typeExplanation">Monologue threshold defines the threshold in seconds after which {admin ? " all reps " : " you "} will be sent a monologue alert on the Wingman Desktop App.</span>
            <div style={{ width: "30%", margin: "5px 0px" }}>
              <Input
                type="number"
                value={admin ? teamMonologueThreshold : monologueThreshold}
                endAdornment={<InputAdornment position="end">Seconds</InputAdornment>}
                onChange={admin ? updateCustomerMonologue : updateUserMonologue}
              />
            </div>
          </div>
        </div>
      </div>
      {false && <div style={{ display: "flex", justifyContent: "center" }}>
        <Button theme="dark" onClick={downloadWingman}>
          {`Download Wingman for ${os}`}
        </Button>
      </div>}
    </div>
  );
};

WingmanSettings.propTypes = {};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  { refreshUserAction, setLoading },
)(WingmanSettings);
