import React, { useState, useRef } from 'react';
import { _formatSecondsToMinutes } from 'app/utils/helpers';
import { Link } from "react-router-dom";
import { TextBase, TextLarge } from 'app/designSystem'
import { TagsChipContainer } from 'app/designSystem/common'
import { ThreeDotMenuIcon, PlayIconBlurredIcon } from 'app/designSystem/icons'
import { GameTapeMenu } from 'app/components/gameTapesLatest/menu'
import useDataHelper from "app/utils/hooks/useDataHelper";
import moment from 'moment';

const GameTapeCard = ({ data, stats, actions }) => {
    const { getUserName } = useDataHelper();
    const [isHovered, setIsHovered] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const { id, name, description, tags = [], duration, userId, thumbnail, createdDate } = data;

    return (
        <Link to={`/gametapes/video/${id}`} className="flex flex-col rounded-lg m-4 mb-8 border border-gray-200 select-none w-[25rem]" id="gametape_card" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div role="thumbnail" className="relative">
                <img src={thumbnail || "https://via.placeholder.com/600x300?text=%20"} alt="thumbnail" className="w-full h-48 object-cover rounded-t-lg" />
                <div className="absolute" style={{
                    top: "50%",
                    left: "50%",
                    transform: isHovered ? "translate(-50%, -50%)" : "translate(-50%, -20%)",
                    opacity: isHovered ? 1 : 0,
                    visibility: isHovered ? "visible" : "hidden",
                    transition: "all 0.3s ease-in-out"
                }}>
                    <PlayIconBlurredIcon className="text-white" />
                </div>
            </div>
            <div className="p-4 h-48">
                <div className="mb-2 -mt-2" role="progressbar">
                    <div className="relative">
                        <div className="bg-gray-100 rounded-lg h-1.5 w-full" />
                        <div className="bg-wingman-brand rounded-lg h-1.5 w-full absolute top-0" style={{
                            width: `${Math.min(((stats?.progress ?? 0) / duration) * 100, 100)}%`
                        }} />
                    </div>
                </div>
                <div className="flex flex-col justify-between h-full">
                    <div className="h-[85%] flex-col">
                        <div className="mb-0 flex items-start justify-between h-[35%]">
                            <TextLarge className="text-gray-900 w-full h-full line-clamp-2">{name}</TextLarge>
                            <div className="py-1 px-2 w-24 align-right">
                                <TextBase className="text-gray-500">{_formatSecondsToMinutes(duration, " mins")}</TextBase>
                            </div>
                        </div>
                        <div className="my-2 overflow-hidden h-[30%]">
                            <TextBase className="text-gray-500 line-clamp-2">{description}</TextBase>
                        </div>
                        <div className="h-[20%]">
                            <TagsChipContainer tags={tags} numberOfTagsToShow={2} />
                        </div>
                    </div>
                    <div className="flex items-center justify-between h-[20%]">
                        <div className="flex items-center">
                            <TextBase className="text-gray-500 flex items-center justify-center whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[12rem]">Added by: {userId ? getUserName(userId) : "Wingman"}</TextBase>
                            <div className="h-1 w-1 bg-gray-600 rounded-full mx-1.5"></div>
                            <TextBase className="text-gray-500 flex items-center justify-center">{moment(createdDate).fromNow()}</TextBase>
                        </div>

                        {actions && <button className="p-0.5 hover:bg-coolGray-50 rounded-lg" onClick={(e) => {
                            setIsOpen(!isOpen);
                            e.preventDefault();
                        }} ref={menuRef}>
                            <ThreeDotMenuIcon />
                        </button>}
                    </div>
                </div>
            </div>
            {actions && <GameTapeMenu isOpen={isOpen} setIsOpen={setIsOpen} anchorEl={menuRef.current} actions={actions} />}
        </Link>
    );
};

export default React.memo(GameTapeCard);