import { sendRequest } from '../network'


export const getCrmGroups = async () => {
    return await sendRequest('/crmGroup/get_crm_groups');
}

export const updateCrmGroup = async (crmGroup) => {
    return await sendRequest('/crmGroup/update_crm_group', crmGroup);
}

export const deleteCrmGroup = async (crmGroup) => {
    return await sendRequest('/crmGroup/delete_crm_group', crmGroup);
}
