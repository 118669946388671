import React, { useState, useEffect } from 'react';
import { calendarCallback, oneTimeOutlookFetch, outlookCalendarCallback, sendRequest } from "app/utils/network";
import { useSelector } from "react-redux";
import { ReactComponent as CalendarIcon } from "app/styles/assets/images/icons/calendar_generic.svg";
import { getRedirectUrl, openWindowAndListenForMultipleURLParams, openWindowAndListenForParam } from 'app/components/onboarding/helpers';
import { GOOGLE_CONFIG, MICROSOFT_CONFIG } from 'app/config';
import { CircularProgress } from '@material-ui/core';
import { TextBase } from 'app/designSystem';
import useReduxUpdater from 'app/utils/hooks/useReduxUpdater';
import { sendGetRequest } from 'app/utils/network';

const CalendarSyncPrompt = props => {
    const auth = useSelector(store => store.auth);
    let { integrationStatus } = auth?.data?.user;
    let { integrationFlows } = auth?.stringsCustomer;
    const [syncing, setSyncing] = useState(false);
    const { refreshUserAction } = useReduxUpdater();
    const [resyncRequired, setResyncRequired] = useState(false);

    const isGoogleCalendarSyncPending = (!integrationStatus.googleCalendarAuthFlow && integrationFlows.googleCalendarAuthFlow);
    const isOutlookCalendarSyncPending = (!integrationStatus.outlookCalendarAuthFlow && integrationFlows.outlookCalendarAuthFlow);


    useEffect(() => {
        checkIfResyncRequired();
    }, []);

    async function checkIfResyncRequired() {
        const resyncRequiredLocal = await sendRequest("/user/is_calendar_resync_needed");
        setResyncRequired(resyncRequiredLocal);
    }

    async function syncGoogleCalendar() {
        setSyncing(true);
        const location = getRedirectUrl();
        const scope = GOOGLE_CONFIG.readWriteScope;
        const response = await sendGetRequest("/auth/getToken");
        if (response?.data?.error) {
            setSyncing(false);
            return
        }
        const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_CONFIG.clientId}&scope=${scope}&redirect_uri=${location}&access_type=offline&response_type=code&prompt=consent&state=${response.message}`;
        console.log("url for google calendar is", url);
        openWindowAndListenForMultipleURLParams(url, "Google Calendar Integration", ["code", "state"], completeGoogleSync);
    }

    async function completeGoogleSync(code, state) {
        await calendarCallback(code, state);
        await refreshUserAction();
        await checkIfResyncRequired();
        setSyncing(false);
    }


    async function syncOutlookCalendar() {
        setSyncing(true);
        const location = getRedirectUrl();
        const url = `${"https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code"
            + "&client_id="}${MICROSOFT_CONFIG.clientID}&redirect_uri=${encodeURIComponent(
                location,
            )}&scope=${encodeURIComponent(MICROSOFT_CONFIG.graphScopes)}&response_mode=fragment`;
        openWindowAndListenForParam(url, "Outlook Integration", "code", completeOutlookSync);
    }

    async function completeOutlookSync(code) {
        await outlookCalendarCallback(code);
        refreshUserAction();
        oneTimeOutlookFetch();
        setSyncing(false);
    }

    //don't show the prompt when two calendars are involved.
    if (integrationFlows.outlookCalendarAuthFlow && integrationFlows.googleCalendarAuthFlow) return null;
    if (!(isGoogleCalendarSyncPending || isOutlookCalendarSyncPending || resyncRequired)) return null;
    if (props.promptSync && (isGoogleCalendarSyncPending || isOutlookCalendarSyncPending)) {
        return (
            <div className="flex items-center w-full bg-blue-100 px-4 py-3">
                <CalendarIcon className="w-5 h-5 mr-3" />
                <TextBase inline textColor="text-blue-700">Your calendar is not synced yet</TextBase>
                <span onClick={isGoogleCalendarSyncPending ? syncGoogleCalendar : syncOutlookCalendar} className="font-semibold text-blue-700 cursor-pointer ml-auto">
                    {syncing &&
                        <CircularProgress
                            size={16}
                        />}
                    {!syncing && "Sync now"}
                </span>
            </div>
        );
    }
    else if (props.promptResync && resyncRequired) {
        return (
            <div className="flex absolute bg-blue-100 items-center w-1/2 left-1/4 h-15 bottom-0 px-8 py-3 rounded-md">
                <CalendarIcon className="w-5 h-5 mr-3" stroke="red" />
                <TextBase inline textColor="text-blue-700">Your pre-meeting consent page has been enabled. <a className="underline" target="_blank" href="https://support.trywingman.com/en/articles/5882254?utmSource=inAppResyncNudge&utmCampaign=mandatoryConsentPageEnabled&utmMedium=webApp">See how this affects you.</a></TextBase>
                <div onClick={syncGoogleCalendar} className="cursor-pointer ml-auto">
                    <TextBase inline textColor="text-blue-700" className="font-bold">
                        {syncing && <CircularProgress size={16} />}
                        {!syncing && "Resync Calendar"}
                    </TextBase>
                </div>
            </div>
        );
    }
    return null;

}

CalendarSyncPrompt.defaultProps = {
    promptResync: false,
    promptSync: true,
};

export default CalendarSyncPrompt;