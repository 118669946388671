import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "app/components/form";

export default class InformationDialog extends React.Component {
  constructor(props) {
    super();
  }

  handleOk = () => {
    this.props.onClose();
  };

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>{this.props.message}</DialogContent>
        <DialogActions>
          <Button  theme="dark" onClick={this.handleOk}>
            {"Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InformationDialog.propTypes = {
  onClose: PropTypes.func,
};
