import React from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { colourStyles } from "app/components/filter/SelectWithSearch";
import { useState, useEffect } from "react";
import { useRef } from "react";

const AsyncSelectWithSearch = props => {

  const [value, setValue] = useState([]);
  const [data, setData] = useState(props.data);

  const currentTimeout = useRef();

  useEffect(() => {
    setValue(props.value);
    const val = props.value || [];
    setData([...props.data, ...val]);
  }, [props.value, props.data])

  function handleChange(options) {
    props.handleChange(options);
  }

  function handleLoadOptions(inputValue, callback) {
    if (!inputValue || inputValue.trim() === "") return;
    if (currentTimeout.current !== null) clearTimeout(currentTimeout.current);
    currentTimeout.current = setTimeout(() => {
      props.loadData(inputValue, callback);
    }, 300);
  }

  return (
    <AsyncSelect
      value={value}
      options={data}
      isMulti
      styles={colourStyles}
      autoFocus={props.menuIsOpen}
      components={makeAnimated()}
      menuIsOpen={props.menuIsOpen}
      onChange={handleChange}
      loadOptions={handleLoadOptions}
      placeholder={props.placeholder}
      defaultOptions={data}
      className="basic-multi-select"
      classNamePrefix="select"
      dontShowCloseIcon={props.dontShowCloseIcon}
    />
  );
}

export default AsyncSelectWithSearch;