import React from 'react';
import { Link } from 'react-router-dom';
import { TextBase } from 'app/designSystem'

const FolderPaths = ({ folderPath = [] }) => {
    return (
        <div className="flex px-2.5 mb-10">
            {folderPath.length ? folderPath.map((folder, index) => (
                <div key={folder.id} className="flex items-center">
                    <Link className="p-1.5 rounded hover:bg-gray-50 cursor-pointer" to={index == 0 ? `/gametapes` : `/gametapes/folder/${folder.id}`}>
                        <TextBase className="text-gray-600">{folder.name}</TextBase>
                    </Link>
                    {(index !== folderPath.length - 1) && <span className="px-2 text-gray-600">/</span>}
                </div>
            )) : null}
        </div>
    );
}

export default React.memo(FolderPaths);