import React, { useState, useEffect } from "react";
import {
  NumberRangeFilters,
  CategoryHeader
} from "app/components/filter";
import { getAllDocFields, sendRequest } from "../../utils/network";
import DateFiltersNew from "./DateFiltersNew";
import SelectWithSearchNew from "./SelectWithSearchNew";
import { fetchCustomDealFields } from "../../utils/CustomDealFilterHelper"
import { useSelector } from "react-redux";

const fieldsWithIdsMapped = ["pipeline", "custom_pipeline"];

const CRMFieldFilters = ({
  filterParams,
  onSelectChange,
  fieldsSelected,
  removeFilterValue,
  isDealField,
}) => {
  const [crmFields, setCrmFields] = useState([]);
  const [fieldsActive, setFieldsActive] = useState([]);

  useEffect(() => {
    fetchFields();
  }, []);

  useEffect(() => {
    getFieldsActive(filterParams);
  }, [filterParams, crmFields]);

  function getFieldsActive(filterParamsLocal = filterParams) {
    const fieldsActiveLocal = [];
    if (!filterParams.crmFields) {
      setFieldsActive(fieldsActiveLocal);
      return;
    }
    Object.keys(filterParams.crmFields).map(field => {
      if (isFieldActive(field, filterParamsLocal) && !fieldsActiveLocal.includes(field)) {
        fieldsActiveLocal.push(field);
      }
    });
    setFieldsActive(fieldsActiveLocal);
  }

  async function fetchFields() {
    const fieldObjects = await getAllDocFields();
    const fields = getFieldsFromFieldsObject(fieldObjects, true);
    console.log("active crm fields", fields);
    const dealFields = await fetchCustomDealFields();
    isDealField ? setCrmFields(dealFields) : setCrmFields(fields);
  }

  function setValues(values, index, crmField) {
    onSelectChange(index, crmField, values);
  }

  async function setValue(value, indexOfValue, index, crmField, selectedValue) {
    let localValue = selectedValue;
    if (!localValue) {
      localValue = [];
    }
    localValue[indexOfValue] = value;
    await onSelectChange(index, crmField, localValue);
  }

  function getValue(fieldName) {
    return filterParams["crmFields"][fieldName] ? filterParams["crmFields"][fieldName]["value"] : null;
  }

  function isFieldActive(fieldName) {
    if (fieldsSelected && fieldsSelected.includes(fieldName)) return true;
    const value = getValue(fieldName);
    const crmField = crmFields.find(field => field.value === fieldName);
    if (!crmField) return false;

    switch (crmField.fieldType) {
      case "NUMERIC": return value && (value[0] > 0 || value[1] < 100);
      case "INTEGER": return value && (value[0] > 0 || value[1] < 10);
      case "DATE": return value && (value[0] || value[1]);
      case "LIST": return value && value.length > 0;
      case "STRING": return value && value[0] !== "";
    }
    return false;
  }

  if (!crmFields || crmFields.length === 0) return null;

  return (
    <>
      {[...new Set([...fieldsActive, ...fieldsSelected])].map(fieldName => {
        var crmField = crmFields.find(field => field.value === fieldName);
        if (!crmField) return null;
        const selectedValue = getValue(fieldName);
        const i = crmField.value;
        return (
          <div key={i} className="Filter__fieldContainer">
            <CategoryHeader label={crmField.label} />
            {crmField && crmField.fieldType === "NUMERIC" && !fieldsWithIdsMapped.includes(crmField.value) && (
              <NumberRangeFilters
                key={`CRMField${fieldName}`}
                fieldName={crmField.value}
                start={selectedValue && selectedValue[0] ? selectedValue[0] : 0}
                end={selectedValue && selectedValue[1] ? selectedValue[1] : 100}
                removeFilterValue={removeFilterValue}
                onStartChange={v => setValue(v, 0, i, crmField, selectedValue)}
                onEndChange={v => setValue(v, 1, i, crmField, selectedValue)}

              />
            )}
            {crmField && crmField.fieldType === "INTEGER" && !fieldsWithIdsMapped.includes(crmField.value) && (
              <NumberRangeFilters
                key={`CRMField${fieldName}`}
                removeFilterValue={removeFilterValue}
                fieldName={crmField.value}
                start={selectedValue && selectedValue[0] ? selectedValue[0] : 0}
                end={selectedValue && selectedValue[1] ? selectedValue[1] : 10}
                onStartChange={v => setValue(v, 0, i, crmField, selectedValue)}
                onEndChange={v => setValue(v, 1, i, crmField, selectedValue)}
              />
            )}
            {crmField && crmField.fieldType === "DATE" && (
              <DateFiltersNew
                key={`CRMField${fieldName}`}
                startDate={
                  selectedValue && selectedValue[0]
                    ? selectedValue[0]
                    : null
                }
                endDate={
                  selectedValue && selectedValue[1] ? selectedValue[1] : null
                }
                fieldName={crmField.value}
                removeFilterValue={removeFilterValue}
                onStartAndEndChange={v => setValues(v, i, crmField)}
                onStartDateChange={v => setValue(v, 0, i, crmField, selectedValue)}
                onEndDateChange={v => setValue(v, 1, i, crmField, selectedValue)}

              />
            )}
            {((crmField && crmField.fieldType === "STRING")
              || (crmField && crmField.fieldType === "LIST")
              || fieldsWithIdsMapped.includes(crmField.value)) && (
                <CrmFieldsSelect
                  key={`CRMField${fieldName}`}
                  crmField={crmField}
                  removeFilterValue={removeFilterValue}
                  value={selectedValue || []}
                  onChange={(values) =>
                    setValues(values, i, crmField)
                  }
                  isDealField={isDealField}
                />
              )}
          </div>
        );
      })}
    </>
  );
};

const CrmFieldsSelect = ({ crmField, onChange, value, removeFilterValue, isDealField }) => {
  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const pipelines = useSelector(store => store.screen.metaData.pipelines);

  useEffect(() => {
    fetchData();
  }, [pipelines]);

  useEffect(() => {
    console.log("data changed to:", data);
    if (data && data?.filter)
      setValues(data.filter(v => value.includes(v.value)));
  }, [value, data]);

  function handleChange(options) {
    if (!options) {
      onChange([]);
      return;
    }
    onChange(options.map(o => o.value));
  }

  async function fetchData() {
    const params = new URLSearchParams();
    console.log(crmField);
    params.append("fieldName", crmField.value);
    let values;
    if (isDealField) {
      if (crmField.value === "custom_pipeline" || crmField.value === "pipeline")
        values = pipelines;
      else
        values = await sendRequest("/filter/get_unique_field_values_for_opps", params);
    }
    else
      values = await sendRequest("/filter/get_unique_field_values", params);

    console.log("unique field values", values);
    setData(values);
  }
  return <SelectWithSearchNew
    data={data}
    fieldName={crmField.value}
    removeFilterValue={removeFilterValue}
    value={value}
    placeholder="Select Value"
    handleChange={handleChange}
  />
}

export function getFieldsFromFieldsObject(docObjects, required) {
  let arr = [];
  Object.keys(docObjects).map(docType => {
    const fieldObjects = docObjects[docType];
    arr = arr.concat(
      Object.keys(fieldObjects)
        .filter(fieldName => fieldObjects[fieldName].required === required)
        .map(fieldName => {
          return {
            value: `l${docType[0].toLowerCase()}_${fieldName}`,
            label: `${camelize(docType)}- ${fieldObjects[fieldName].displayName
              }`,
            fieldType: fieldObjects[fieldName].fieldType
          };
        })
    );
  });
  return arr;
}

export function flattenDocObjects(docObjects, required) {
  Object.keys(docObjects).map(docType => {
    const fieldObjects = docObjects[docType];
    docObjects[docType] =
      Object.keys(fieldObjects)
        .filter(fieldName => fieldObjects[fieldName]?.required === required)
        .map(fieldName => {
          return {
            value: `l${docType[0].toLowerCase()}_${fieldName}`,
            label: `${fieldObjects[fieldName]?.displayName
              }`,
            fieldType: fieldObjects[fieldName]?.fieldType
          };
        })
  });
  return docObjects;
}

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    })
    .replace(/\s+/g, "");
}


CRMFieldFilters.propTypes = {};
export default CRMFieldFilters;
