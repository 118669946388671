import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import SalesloftLogo from "app/styles/assets/images/onboarding/salesloft.webp";
import { SALESLOFT_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncSalesloft = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync(code) {
    const res = await crmOAuthCallback(code, 'SALESLOFT');
    setLoading(false);
    props.refreshUserAction();
  }
  function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const url = ` https://accounts.salesloft.com/oauth/authorize?client_id=${SALESLOFT_CLIENT_ID}&redirect_uri=${location}&response_type=code`;
    openWindowAndListenForParam(url, "Salesloft Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={SalesloftLogo}
      iconClass="salesforce_icon"
      heading="Salesloft"
      subHeading="Dialer Integrations"
      bodyText="Sync salesloft to link all salesloft calls."
      sync={sync}
      loading={loading}
      dialer="SALESLOFT"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncSalesloft.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
};

SyncSalesloft.defaultProps = {
  access: "admin",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncSalesloft);
