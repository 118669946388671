import React from "react";
import { Battlecard } from "./Battlecard";
import { BehaviourCard } from "./BehaviourCard";

export const CardsShown = ({
 battlecardsShown, duration, recordingStartTime, onSkipPress, behaviourCardsShown
}) => {
  if (!battlecardsShown && !behaviourCardsShown) return null;
  return (
    <div className="Timeline__progress__CardsShown">
      {battlecardsShown && battlecardsShown.map((battlecard, i) => {
        if (!battlecard.timeShown) return null;
        const battlecardTime = (battlecard.timeShown - recordingStartTime) / 1000;
        if (battlecardTime > duration || battlecardTime < 0) return null;
        const leftMargin = (battlecardTime * 100) / duration;
        return <Battlecard key={i} onSkipPress={onSkipPress} battlecardTime={battlecardTime} battlecard={battlecard} leftMargin={leftMargin} />;
      })}
      {behaviourCardsShown && behaviourCardsShown.map((card, i) => {
        if (!card.frontendReceiveTime) return null;
        const cardTime = (card.frontendReceiveTime - recordingStartTime) / 1000;
        if (cardTime > duration || cardTime < 0) return null;
        const leftMargin = (cardTime * 100) / duration;
        return <BehaviourCard key={i} type={card.behaviourType} onSkipPress={onSkipPress} behaviourCardTime={cardTime} behaviourCard={card} leftMargin={leftMargin} />;
      })}
    </div>
  );
};
