import axios from "axios";
import {
  API_URL
} from "app/config";
import {
  connect
} from "react-redux";

export const request = (opts = {}) => {
  let defaultOptions = {
    headers: {
      ...opts,
    }
  }
  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  let axiosApi = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    ...defaultOptions,
  });

  axiosApi.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
      console.log("unauthorized, logging out ...");
      let frontendUrl = `${window.location.protocol}//${window.location.hostname}`;
      if (window.location.port) {
        frontendUrl = `${frontendUrl}:${window.location.port}`;
      }
      const lastForceLogoutTime = localStorage.getItem("lastForceLogoutTime");
      if (lastForceLogoutTime && new Date().getTime() - lastForceLogoutTime < 30000) {
        console.log("ignore force logout as I just did it.");
        return Promise.reject(error.response);;
      }
      localStorage.setItem("lastForceLogoutTime", new Date().getTime());

      if (window.location.pathname === "/login") {
        console.log("dont go from login to logout");
      } else {
        console.log("forcing logout" + window.location.pathname);
        window.location.href = frontendUrl + "/logout";
      }
    }
    return Promise.reject(error.response);
  });

  return axiosApi;
};

export default request;