import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { PageButton } from "../../containers/CallTablePageRender";

const styles = {
  paginationHeaderContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  paginationButtonsContainer: {
    marginRight: 10,
    width: "62px",
    display: "flex",
  },
  paginationImageContainer: {
    border: "1px solid #C6CBD4",
    height: "32px",
    padding: "7px",
    width: "32px",
    cursor: "pointer",
  },
  paginationIcon: {
    height: "16px",
    width: "16px",
  },
};

const ResultsAction = ({
  startIndex,
  endIndex,
  totalHits,
  classes,
  page,
  goToNextPage,
  goToPreviousPage,
}) => {
  if (totalHits === 0) {
    return null;
  }
  return (
    <div className={classes.paginationHeaderContainer}>
      <span style={{ marginRight: 10 }}>
        Viewing {`${startIndex} - ${endIndex <= totalHits ? endIndex : totalHits}`} of {totalHits}
      </span>
      <div className={classes.paginationButtonsContainer}>
        <PageButton
          disabled={page === 0}
          paginationImageContainer={classes.paginationImageContainer}
          paginationIcon={classes.paginationIcon}
          type="previous"
          onClick={goToPreviousPage}
        />
        <PageButton
          disabled={totalHits <= endIndex}
          paginationImageContainer={classes.paginationImageContainer}
          paginationIcon={classes.paginationIcon}
          type="next"
          onClick={goToNextPage}
        />
      </div>
    </div>
  );
};

ResultsAction.propTypes = {};

export default withStyles(styles)(ResultsAction);
