import React, { useEffect, useState } from "react";
import { Avatar } from "@material-ui/core";
import ProfileImage from "../common/ProfileImage";
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";

function applyOverlap(element) {
  return withStyles(theme => ({
    root: {
      border: "solid white 2px",
      margin: "-7px",
      height: "18px",
      width: "18px",
      fontSize: "9px",
      backgroundColor: "#26324f !important"
    }
  }))(element);
}

const AvatarOverlapped = applyOverlap(Avatar);

const AgentColumn = ({ call, agentMaps, size, className, overlap }) => {

  const [usersToShow, setUsersToShow] = useState(call.users ? call.users.slice(0, 3) : []);
  const [hiddenUsersNames, setHiddenUsersNames] = useState("");

  useEffect(() => {
    if (call.users && call.users.length > 3) {
      setUsersToShow(call.users.slice(0, 3));
      setHiddenUsersNames(call.users
        .slice(3)
        .map(a => agentMaps[a])
        .join(", "));
    }
  }, [call.users, agentMaps]);

  const sizePx = size || 30;

  return (
    <div className={classNames("CallTable__agentContainer",className)}>
     
      {usersToShow.map((user, j) => {
        let profileImgStackingStyle={
          right:`${j*1}rem`
        }
        return <ProfileImage className="relative" style={profileImgStackingStyle} key={j} userId={user} size={sizePx} overlap={overlap} />
      })}

      {call.users && call.users.length > 3 && !overlap && (               
        <div style={{right:`${usersToShow.length}rem`}} className="relative">
        <Avatar key="new" title={hiddenUsersNames} className="!text-white !text-sm !bg-coolGray-800 !w-10 !h-10 relative" >
          {`+${call.users.length - 3}`}
        </Avatar>
        </div>
      )}
      {call.users && call.users.length > 3 && overlap && ( //no consumer of AgentColumn, passes this prop. Todo:Remove
        <AvatarOverlapped key="new" title={hiddenUsersNames}>
          {`+${call.users.length - 3}`}
        </AvatarOverlapped>
      )}
    </div>
  );
};

export default AgentColumn;
