import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const JumpThroughList = ({ list, jumpTo }) => {
  const [refs, setRefs] = useState({});

  useEffect(() => {
    if (jumpTo) {
      if (refs[jumpTo]) {
        refs[jumpTo].scrollIntoView({ block: "center" });
      }
    }
  }, [jumpTo]);

  function addRef(id, ref) {
    refs[id] = ref;
  }

  return (
    <>
      {list.map(item => {
        return (
          <div key={item.id} ref={ref => addRef(item.id, ref)}>
            {item.render}
          </div>
        );
      })}
    </>
  );
};

JumpThroughList.propTypes = {
  list: PropTypes.array.isRequired,
  jumpTo: PropTypes.string,
};

JumpThroughList.defaultProps = {
  jumpTo: null,
};

export default JumpThroughList;
