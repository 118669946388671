import React from "react";
import { Route, Switch } from "react-router-dom";
import { object } from "prop-types";
import { connect } from "react-redux";
import { Loading, NotFound } from "app/components/common";
import { SharedCall } from "app/components/call";
import { STACKED, GUEST } from "app/constants";

class GuestDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerType: GUEST,
      openDrawer: false,
    };
  }
  render() {
    return (
      <div className={`Dashboard ${this.state.openDrawer ? "" : "is-toggled"}`}>
        <Loading />
        <div
          className={`dashboard__content 
          ${"dashboard__contentFull"}
          ${this.props.screen.screenType === STACKED ? "dashboard__stacked" : ""}`}
        >
          <div className="dashboard__content-inner-stacked">
            <Switch>
              <Route
                exact
                path={`${this.props.match.url}/sharedCall/:shareId`}
                render={props => <SharedCall {...this.props} {...props} />}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = store => {
  return { ...store };
};

GuestDashboard.defaultProps = {
  location: {},
  match: {},
};

GuestDashboard.propTypes = {
  location: object,
  match: object,
};

export default connect(mapStateToProps)(GuestDashboard);
