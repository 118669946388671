import React from 'react';
import { Menu, MenuItem } from 'app/designSystem/menu'
import { MoveIcon, DeleteIcon, EditIcon } from 'app/designSystem/icons'

export default function FolderMenu({ setIsOpen, isOpen, anchorEl, actions }) {
    return (
        <Menu
            list={[
                <MenuItem icon={<EditIcon />} label="Edit" onClick={actions.onEdit} />,
                <MenuItem icon={<MoveIcon />} label="Move" onClick={actions.onMove} />,
                <MenuItem icon={<DeleteIcon />} label="Delete" onClick={actions.onRemove} />
            ]}
            open={isOpen}
            minWidth={200}
            onClose={() => setIsOpen(false)}
            menuPosition="bottom-left"
            anchorEl={anchorEl}
            onClick={(e) => {
                setIsOpen(false);
                e.stopPropagation();
            }}
        />
    )
}