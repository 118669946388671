import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import { SPaper } from "app/components/common";
import classNames from "classnames";

const styles = {
  menu: {
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: 100,
    transition: "opacity 500ms",
    opacity: 1,
  },
};

class CustomDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.toggleVisiblity = this.toggleVisiblity.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = {
      visible: props.visible || false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.visible !== nextProps.visible) {
      this.setState({
        visible: nextProps.visible,
      });
    }
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("mousedown", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }

  handleSelect(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.onVisiblityChange !== null) {
      this.props.onVisiblityChange(false);
    } else {
      this.setState({
        visible: false
      })
    }
  }

  handleOutsideClick(e) {
    if (this.node && !this.node.contains(e.target)) {
      if (this.props.onVisiblityChange !== null) {
        this.props.onVisiblityChange(false);
      } else {
        this.setState({
          visible: false,
        });
      }
    }
  }

  toggleVisiblity(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.disabled) {
      return;
    }

    const { visible } = this.state;
    if (this.props.onVisiblityChange !== null) {
      this.props.onVisiblityChange(!visible);
    } else {
      this.setState({
        visible: !visible,
      });
    }
  }

  render() {
    const props = this.props;
    const { classes } = this.props;
    return (
      <div style={{ position: "relative", "cursor": "pointer" }} ref={node => (this.node = node)} className={classNames(props.className)}>
        <div onClick={this.toggleVisiblity}>{props.title}</div>
        <div
          className={classes.menu}
          style={{
            opacity: this.state.visible ? 1 : 0,
            visibility: this.state.visible ? "visible" : "hidden",
            right: props.right,
            left: props.left,
            margin: props.margin,
            top: props.top,
            bottom: props.bottom,
          }}
        >
          <SPaper className={classNames(this.props.bubbleClass, "!p-2.5")} elevation={2} >
            {props.list.map((item, i) => {
              return <div key={i} onClick={this.handleSelect}>{item}</div>;
            })}
          </SPaper>
        </div>
      </div>
    );
  }
}

CustomDropdown.propTypes = {
  title: PropTypes.node,
  list: PropTypes.array,
  className: PropTypes.string,
};

CustomDropdown.defaultProps = {
  title: null,
  list: [],
  className: "",
  onVisiblityChange: null,
  visible: false,
  disabled: false,
};

export default withStyles(styles)(CustomDropdown);
