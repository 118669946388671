
import React from "react";
import { connect } from "react-redux";
import { getManualAnnotationQuestion, submitManualAnnotationDecision } from "app/utils/network";
import { withStyles } from "@material-ui/core";

class ManualAnnotation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callId: "",
      turnId: 0,
      id: "",
      left: "",
      right: "",
      type: "",
      userId: this.props.auth.data.user.userId || "",
    };
  }
  async UNSAFE_componentWillMount() {
    await this.loadData();
  }
  async loadData() {
    const response = await getManualAnnotationQuestion(this.state.userId);
    this.setState({
      callId: response.callId,
      turnId: response.turnId,
      id: response.id,
      left: response.left,
      right: response.right,
      type: response.type,
    });
  }

  async submit(decision) {
    const response = await submitManualAnnotationDecision(this.state.id, this.state.userId, decision);
    await this.loadData();
  }

  render() {
    const {classes} = this.props
    return (
    <div style={{display:"flex",height:"100%"}}>
      <div style={{margin:"auto",textAlign:"center",width:"1000px"}}>
        <div style={{fontSize:"40px"}}>Is the top statement relevant to the bottom statement in the context of : <span>{this.state.type}</span>?</div>
        <div style={{fontSize:"30px",margin:"10px",minHeight:"100px",display:"flex",alignItems:"center",justifyContent:"center"}}>{this.state.type}: "{this.state.left}"</div>
        <div style={{fontSize:"30px",margin:"10px",minHeight:"100px",display:"flex",alignItems:"center",justifyContent:"center"}}>Match: "{this.state.right}"</div>
        <button className={classes.button + " " + classes.gray} onClick={() => window.open("/call/" + this.state.callId + "#" + this.state.turnId).focus()}>Open call</button>
        <div>
          <button className={classes.button + " " + classes.green} onClick={() => this.submit("YES")}>Yes</button>
          <button className={classes.button + " " + classes.red} onClick={() => this.submit("NO")}>No</button>
        </div>
      </div>
    </div>
    );
  }
}

const styles = {
  button: {
    border: 'none',
    color: 'white',
    fontSize: '20px',
    padding: '15px 32px',
    margin: '10px',
    borderRadius: '4px',
  },
  green: { backgroundColor: '#4CAF50' },
  red: { backgroundColor: '#F44336' },
  blue: { backgroundColor: '#008CBA' },
  gray: { backgroundColor: '#555555' },
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(mapStateToProps)(withStyles(styles)(ManualAnnotation));