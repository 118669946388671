import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from "prop-types";
import { uniqueId } from 'lodash'
import { CheckMark } from "app/designSystem";



export default function CheckBox({ className, checked, disabled, onChange }) {
    const [checkBoxInpId] = useState(uniqueId('checkBox'))


    return (
        <div className="inline">
            <input type="checkbox" checked={checked} className="wm-checkbox hidden" name="checkBox" id={checkBoxInpId} onChange={e => onChange(e.target.checked)} disabled={disabled} />
            <label htmlFor={checkBoxInpId} className={classNames(" h-full overflow-hidden   relative", { 'cursor-pointer': !disabled }, { 'cursor-not-allowed': disabled })}>
                <div className={classNames("wm-checkbox inline-block border border-solid border-gray-200 rounded w-6 h-6", { 'bg-brand-blue-1': checked }, { 'bg-blue-50': !checked })}>
                    {checked &&
                        <CheckMark className="w-full h-full" />
                    }

                </div>
            </label>
        </div>
    )
}



CheckBox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool

}
CheckBox.defaultProps = {
    checked: false,
    disabled: false
} 
