import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { crmOAuthCallback } from "app/utils/network";
import OutreachLogo from "app/styles/assets/images/integrations/outreach.png";
import { OUTREACH_BASE_URL, OUTREACH_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";

const SyncOutreach = props => {
  const [loading, setLoading] = useState(false);

  async function completeSync(code) {
    const res = await crmOAuthCallback(code, 'OUTREACH');
    setLoading(false);
    props.refreshUserAction();
  }
  function sync() {
    setLoading(true);
    const location = getRedirectUrl();
    const scope = "prospects.read calls.read users.read callDispositions.read";
    const url = `${OUTREACH_BASE_URL}/oauth/authorize?client_id=${OUTREACH_CLIENT_ID}&response_type=code&redirect_uri=${location}&scope=${scope}`;
    openWindowAndListenForParam(url, "Outreach Integration", "code", completeSync);
  }

  return (
    <IntegrationCard
      icon={OutreachLogo}
      heading="Outreach"
      subHeading="Dialer Integrations"
      bodyText="Sync outreach to download call recordings."
      sync={sync}
      loading={loading}
      dialer="OUTREACH"
      {...props}
    />
  );
};

const mapStateToProps = store => {
  return { ...store };
};

SyncOutreach.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  access: PropTypes.string,
};

SyncOutreach.defaultProps = {
  access: "user",
};

export default connect(
  mapStateToProps,
  { refreshUserAction },
)(SyncOutreach);
