import React, { useState } from "react";
import { Comment, CommentArea, NoComments } from "app/components/call/comments";
import { addComment, deleteComment, editComment, sendRequest, editReply, addReply, deleteReply } from "app/utils/network";
import { metrics } from "app/utils/metrics";
import { clone, findIndex } from "lodash";
import JumpThroughList from "../common/JumpThroughList";
import { GetSortOrder } from "../dashboards/helpers";
import { useSelector, useDispatch } from "react-redux";
import { isAnythingSelected, getCommentsAccessibleByUser } from "../../utils/helpers";
import { UPDATE_CALL } from "../../actions/actionTypes";
import { goodComments, badComments } from "./GoodComments";

const CommentsContainer = props => {
  const dispatch = useDispatch();
  const auth = useSelector(store => store.auth);
  const [comments, setComments] = useState(getComments);
  const [isPrivate, setIsPrivate] = useState(false);
  const [jumpTo, setJumpTo] = useState(null);
  const [activeComment, setActiveComment] = useState(null);
  const [isCommentBeingEdited, setCommentBeingEdited] = useState(false);
  const screen = useSelector(store => store.screen);
  const [showAddToGametapeNudge, setShowAddToGametapeNudge] = useState(false);
  const [commentIdforGameTapeNudge, setCommentIdForGametapeNudge] = useState(null);

  function togglePrivacy() {
    setIsPrivate(isPrivate => !isPrivate);
  }

  function getComments() {
    if (props.call && props.call.comments) {
      const initalComments = getCommentsAccessibleByUser(props.call.comments, auth.userId);
      initalComments.sort(GetSortOrder("startTime", "ASC"));
      return initalComments;
    }
    return [];
  }

  async function handleDeleteComment(commentId) {
    const updatedComments = clone(comments);
    const deleteCommentIndex = findIndex(updatedComments, o => { return o.id === commentId; });// find comment index
    updatedComments.splice(deleteCommentIndex, 1);
    dispatch({ type: UPDATE_CALL, data: { comments: updatedComments } }); //updating redux state
    setComments(updatedComments);
    metrics.logEvent("commentDeleted", { callId: props.call.callId });
    const res = await deleteComment(commentId, props.call.callId);

    return res;
  }

  async function handleDeleteReply(replyId, commentId) {
    console.log("deleting reply", replyId, commentId);
    const updatedComments = clone(comments);
    const commentIndex = findIndex(updatedComments, o => { return o.id === commentId });
    const commentToUpdate = updatedComments[commentIndex];
    const deleteReplyIndex = findIndex(commentToUpdate.replies, o => { return o.id === replyId; });// find comment index
    commentToUpdate.replies.splice(deleteReplyIndex, 1);
    updatedComments[commentIndex] = commentToUpdate;
    setComments(updatedComments);
    dispatch({ type: UPDATE_CALL, data: { comments: updatedComments } }); //updating redux state
    const res = await deleteReply(props.call.callId, commentId, replyId);
    return res;
  }

  async function handleEditComment(commentId, commentText, taggedUsers) {
    const updatedComments = clone(comments);
    const editCommentIndex = findIndex(updatedComments, o => { return o.id === commentId; });// find comment index
    const oldComment = updatedComments[editCommentIndex];
    updatedComments.splice(editCommentIndex, 1,
      {
        id: commentId,
        userName: auth.data.user.displayName,
        userId: auth.data.user.userId,
        taggedUsers,
        comment: commentText,
        startTime: oldComment.startTime,
        replies: oldComment.replies,
        access: oldComment.access,
      });
    dispatch({ type: UPDATE_CALL, data: { comments: updatedComments } }); //updating redux state
    setComments(updatedComments);
    metrics.logEvent("commentUpdated", { callId: props.call.callId });
    const res = await editComment(props.call.callId, commentId, commentText, oldComment.startTime, taggedUsers);
    return res;
  }

  async function handleEditReply(replyId, commentText, taggedUsers, commentId) {
    console.log("editing reply", replyId, commentText, taggedUsers, commentId);
    const updatedComments = clone(comments);
    const commentIndex = findIndex(updatedComments, o => { return o.id === commentId });
    const commentToUpdate = updatedComments[commentIndex];
    const editReplyIndex = findIndex(commentToUpdate.replies, o => { return o.id === replyId; });// find comment index
    const oldReply = commentToUpdate.replies[editReplyIndex];
    commentToUpdate.replies.splice(editReplyIndex, 1,
      {
        id: commentId,
        userName: auth.data.user.displayName,
        userId: auth.data.user.userId,
        taggedUsers,
        comment: commentText,
        startTime: oldReply.startTime,
      });
    updatedComments[commentIndex] = commentToUpdate;
    setComments(updatedComments);
    metrics.logEvent("replyUpdated", { callId: props.call.callId });
    const res = await editReply(props.call.callId, commentText, taggedUsers, commentId, oldReply.startTime, replyId);
    return res;
  }

  async function handleAddReply(commentText, taggedUsersParam, commentId) {
    console.log("inserting reply", commentText, taggedUsersParam, commentId);
    const updatedComments = clone(comments);
    const commentIndex = findIndex(updatedComments, o => { return o.id === commentId });
    const commentToUpdate = updatedComments[commentIndex];
    const taggedUsers = cleanUpTaggedUsersForServer(taggedUsersParam, commentText);
    if (!commentToUpdate.replies) commentToUpdate.replies = [];

    const { callId } = props.call;
    metrics.logEvent("commentUpdated", { callId });
    const res = await addReply(props.call.callId, commentText, taggedUsers, commentId, commentToUpdate.startTime);

    //for tracking 
    let isPrivate = commentToUpdate?.access == "PRIVATE"
    metrics.logEventHeap("addComment", { type: "REPLY", callId, isPrivate, commentId })


    commentToUpdate.replies.push(
      {
        id: res,
        userName: auth.data.user.displayName,
        userId: auth.data.user.userId,
        taggedUsers,
        comment: commentText,
        startTime: commentToUpdate.startTime,
      });
    updatedComments[commentIndex] = commentToUpdate;
    setComments(updatedComments);
    dispatch({ type: UPDATE_CALL, data: { comments: updatedComments } }); //updating redux state
    return res;
  }

  function cleanUpTaggedUsersForServer(taggedUsersParam, commentText) {
    const taggedUsers = clone(taggedUsersParam);
    if (!taggedUsers) return null;
    for (let i = 0; i < taggedUsers.length; i++) {
      const tag = `@(${taggedUsers[i].name})`;
      if (commentText.indexOf(tag) === -1) {
        taggedUsers.splice(i, 1);
      }
    }
    return taggedUsers.map(a => a.email);
  }

  function checkForGoodComment(comment) {
    let commentsArray = comment.split(" ").map(word => word.toLowerCase());
    let goodCommentFound = "";
    let badCommentFound = ""
    goodCommentFound = (goodComments.find(word => commentsArray.includes(word)))
    badCommentFound = (badComments.find(word => commentsArray.includes(word)))
    return !badCommentFound && goodCommentFound;
  }

  function cleanUpSlackChannelForServer(selectedSlackChannels, commentText) {
    const slackChannels = clone(selectedSlackChannels);
    if (!slackChannels) return null;
    for (let i = 0; i < slackChannels.length; i++) {
      const tag = `!(${slackChannels[i].name})`;
      if (commentText.indexOf(tag) === -1) {
        slackChannels.splice(i, 1);
      }
    }
    const slackChannelsArray = slackChannels.map(a => a.id);
    const slackChannelSet = [...new Set(slackChannelsArray)]; //to avoid duplicate alerts
    return slackChannelSet
  }

  async function handleAddComment(commentText, taggedUsersParam, selectedSlackChannels, startTime, interactionId = null, interactionName = null, interactionType = null, isPrivate = false) {

    const { callId } = props.call;

    const taggedUsers = cleanUpTaggedUsersForServer(taggedUsersParam, commentText);
    const slackChannels = cleanUpSlackChannelForServer(selectedSlackChannels, commentText);
    console.log("inside handle add comment", taggedUsers, slackChannels, commentText, startTime, isPrivate);
    const res = await addComment(callId, commentText, taggedUsers, slackChannels, startTime, isPrivate);
    metrics.logEvent("commentAdded", { callId });
    metrics.logEventHeap('addComment', { callId, type: "NEW", isPrivate })
    const updatedComments = clone(comments);
    const comment = {
      id: res,
      userName: auth.data.user.displayName,
      userId: auth.data.user.userId,
      taggedUsers,
      slackChannels,
      comment: commentText,
      startTime,
      access: isPrivate ? "PRIVATE" : "PUBLIC",
    };
    if (checkForGoodComment(commentText)) {
      setShowAddToGametapeNudge(true)
      setCommentIdForGametapeNudge(comment.id)
    }
    updatedComments.push(comment);
    updatedComments.sort(GetSortOrder("startTime", "ASC"));
    dispatch({ type: UPDATE_CALL, data: { comments: updatedComments } }); //updating redux state
    setComments(updatedComments);
    setJumpTo(res);
  }

  function handleCommentClick(startTime) {
    if (!isAnythingSelected()) props.handlePlayerClick(startTime);
  }

  const className = props.hidden ? "hidden" : "";

  const commentsRender = comments.map((comment, i) => {
    let commentState = "normal";
    if (isCommentBeingEdited) {
      commentState = "inactive";
      if (activeComment === comment.id) commentState = "active";
    }

    return {
      render: (
        <Comment
          key={comment.id + i}
          deleteComment={handleDeleteComment}
          editComment={handleEditComment}
          deleteReply={handleDeleteReply}
          addReply={handleAddReply}
          editReply={handleEditReply}
          onClick={() => handleCommentClick(comment.startTime)}
          comment={comment}
          callId={props.call.callId}
          commentState={commentState}
          setActiveComment={setActiveComment}
          setCommentBeingEdited={setCommentBeingEdited}
          editDisabled={comment.userId !== auth.data.user.userId}
          commentId={comment.id}
          showaddToGametapeNudge={showAddToGametapeNudge}
          commentIdforGameTapeNudge={commentIdforGameTapeNudge}
        />
      ),
      id: comment.id,
    };
  });

  const tutorialComment = {
    userName: "Wingman",
    comment: `Hi @(${auth.data.user.displayName})! 
    You can use this section to add comments, provide feedback or get a teammate’s attention. Use ‘@’ to tag a teammate, use '!' to tag a slack channel.`,
  };

  return (
    <div className={`${className} Comment__fullContainer`}>
      <CommentArea
        isPrivate={isPrivate}
        setIsPrivate={setIsPrivate}
        togglePrivacy={togglePrivacy}
        key={props.duration}
        type="ADD"
        addComment={handleAddComment}
      />
      <div className="Comment__outerContainer" style={screen.addCommentExpanded ? { "height": "calc(100vh - 285px)" } : { "height": "calc(100vh - 200px)" }}>
        <JumpThroughList list={commentsRender} jumpTo={jumpTo} />
        {comments.length === 0 && (
          <>
            <NoComments />
            <Comment
              comment={tutorialComment}
              callId={props.call.callId}
              showReplies={false}
              editDisabled
              type="TUTORIAL"
            />
          </>
        )}
      </div>
    </div>
  );
};

CommentsContainer.defaultProps = {
  isAddCommentAreaExpanded: false,
};

export default CommentsContainer;

