import { GET_CAMPAIGNS, GET_CLIENTS, GET_PUBLISHERS } from "app/actions/actionTypes";

const initialState = {
  details: {},
};

export default function(state = initialState, payload) {
  switch (payload.type) {
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: payload.details.campaigns,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: payload.details,
      };
    case GET_PUBLISHERS:
      return {
        ...state,
        publishers: payload.details.publishers,
      };
    default:
      return state;
  }
}
