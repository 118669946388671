import React from "react";
import { useState } from "react";
import { Player as VideoPlayer, ControlBar, LoadingSpinner, BigPlayButton, Shortcut } from "video-react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, IconButton, CircularProgress, Checkbox } from "@material-ui/core";
import { Input as MuiInput } from "@material-ui/core";
import { getShareableLink } from "app/utils/network";
import { fetchDataSafely } from "app/utils/dataUtils";
import Trimmer from "./Share/Trimmer";
import { ReactComponent as CloseIcon } from "app/styles/assets/images/icons/closeCircle.svg";
import { ReactComponent as CopyIcon } from "app/styles/assets/images/icons/share/copy.svg";
import { ReactComponent as LinkIcon } from "app/styles/assets/images/icons/share/link.svg";
import TrimmingAnimation from "app/styles/assets/animations/trimmingAnimation.json";
import { useEffect } from "react";
import { _formatSecondsToMinutes } from "../../utils/helpers";
import moment from "moment";
import useSnackbar from "../../utils/hooks/useSnackbar";
import Lottie from "react-lottie";
import { getMediaUrl } from "../../reducers/call";
import { getShareableCallId } from "../../utils/network/callSharing";
import { TextBase, TextLarge, Text2XL, CheckBox, Button } from "app/designSystem"
import { Input } from "../form";
import { sendRequest } from "app/utils/network";
import ReactTooltip from "react-tooltip";
import LabelWithExplanation from "../common/LabelWithExplanation";
import { getSignedUrlsForSnippet } from "app/utils/network/call";
const ShareCallFormTrimmer = ({ open, closeModal, videoAvailable, videoProcessed, close }) => {
    const screen = useSelector((store) => store.screen);
    const [start, setStart] = useState(0);
    const [videoState, setVideoState] = useState(null);
    const videoPlayerRef = useRef(null);
    const call = useSelector((store) => store.call);
    const player = useSelector((store) => store.player);
    const [end, setEnd] = useState(fetchDataSafely(player, "playback.duration", 7200));
    const showEndSeekTimer = useRef(null);
    const [link, setLink] = useState("");
    const [shareId, setShareId] = useState(null);
    const [waiting, setWaiting] = useState(false);
    const [validOnlyForAMonth, setValidOnlyForAMonth] = useState(false);
    const [notifyMe, setNotifyMe] = useState(true);
    const { setSnackbar, hideSnackbar } = useSnackbar();
    const [audioSource, setAudioSource] = useState();
    const [snippetName, setSnippetName] = useState(null);
    const [emailRequired, setEmailRequired] = useState(false);
    const [showTrimSettings, setShowTrimSettings] = useState(false);
    const [checkTosendSettingsApi, setCheckToSendSettingsApi] = useState(false);

    useEffect(() => {
        if (screen.shareStartTime && screen.shareEndTime) {
            generateLink(screen.shareStartTime, screen.shareEndTime)
            setShowTrimSettings(true)
        }
    }, [screen.shareStartTime, screen.shareEndTime]);

    useEffect(() => {
        setAudioSource(getMediaUrl(call));
    }, [call]);

    useEffect(() => {
        handleSeek(start);
    }, [start]);

    useEffect(() => {
        console.log("some change..", videoPlayerRef.current, open);
        if (videoPlayerRef.current !== null) {
            videoPlayerRef.current.subscribeToStateChange((vs) => {
                setVideoState(vs);
            });
        }
    }, [videoPlayerRef.current, open]);

    useEffect(() => {
        if (showEndSeekTimer.current !== null) {
            clearTimeout(showEndSeekTimer);
            showEndSeekTimer.current = null;
        }
        handleSeek(end);
        if (showEndSeekTimer.current === null) {
            showEndSeekTimer.current = setTimeout(() => handleSeek(start), 3000);
        }
    }, [end]);

    async function generateLink(startParam = start, endParam = end) {
        setWaiting(true);
        let response = await getShareableCallId(call.callId, validOnlyForAMonth, startParam, endParam, "AUDIO", snippetName, notifyMe, emailRequired);

        if (response?.error) {
            setSnackbar("Couldn't generate the call link, please retry", "ERROR");
            setLink("");
            setShareId(null);
        } else {
            let urls = await getSignedUrlsForSnippet(response.shareId)
            var snippetDoc = urls.result.shareDetailsDocument;
            if (snippetDoc.type === "VIDEO") {
                setAudioSource(urls.result.videoUrl)
            }
            else {
                setAudioSource(urls.result.audioUrl)
            }
            let link = getShareableLink(response.shareId);
            console.log("link is ", link);
            if (startParam === 0 && endParam === player.playback.duration) {
                setShareId(call.callId);
            } else {
                setShareId(response.shareId);
            }
            setLink(link);
            setCheckToSendSettingsApi(true);

        }
        setWaiting(false);
    }

    useEffect(() => {
        if (!showTrimSettings && checkTosendSettingsApi) {
            setCheckToSendSettingsApi(false);
            setTimeout(function () {
                handleShareSettings(shareId)
            }, 1000)
        }

    }, [checkTosendSettingsApi])



    function copyToClipboard(e) {
        document.getElementById("input").select();
        document.execCommand("copy");
        e.target.focus();
        setSnackbar("Link Copied", "SUCCESS");
    }

    function handleSeek(time) {
        if (videoPlayerRef.current !== null) {
            videoPlayerRef.current.seek(time, "seconds");
        }
    }

    function handleClose(force = false) {
        if (!force && (waiting || link !== "")) {
            return;
        }

        setStart(0);
        { videoPlayerRef.current && videoPlayerRef.current.seek(0, "seconds") }
        setLink("");
        setShareId("");
        setEnd(fetchDataSafely(player, "playback.duration", 7200));
        close(true);
    }

    function handleSnippetName(e) {
        setSnippetName(e.target.value)
    }

    async function handleShareSettings(id) {
        if (id) {
            const params = new URLSearchParams();
            params.append("shareId", id)
            params.append("validity", validOnlyForAMonth ? "MONTH" : "FOREVER");
            params.append("emailRequired", emailRequired);
            params.append("notifyMe", notifyMe);
            params.append("snippetName", snippetName)
            await sendRequest("/call/update_sharing_settings_for_snippet", params)
        }

    }

    return (
        <div className="ShareCallForm__Screen fixed ">
            <div className="flex items-center w-full mt-3 mb-3">
                <span className="ShareCallForm__header__text">{showTrimSettings ? "Share Settings" : "Trim Call"}</span>
                <CloseIcon onClick={() => handleClose(true)} className="ShareCallForm__header__close" />
            </div>
            <div className=" border-t border-solid border-coolGray-200 mx-[-20px] mb-2 "></div>
            {!showTrimSettings &&
                <div className={`ShareCallForm__body`}>
                    {link !== "" && <span className="ShareCallForm__footer__text">Your call is ready to share!</span>}
                    {!waiting && !showTrimSettings && (
                        <VideoPlayer
                            ref={(videoPlayer) => (videoPlayerRef.current = videoPlayer)}
                            height="100%"
                            width="100%"
                            fluid={false}
                            poster={videoAvailable && videoProcessed ? null : "https://image.freepik.com/free-vector/colourful-music-sound-waves-background_23-2148424538.jpg"}
                            aspectRatio="16:9"
                        >
                            <source src={audioSource} />
                            <ControlBar disableCompletely />
                            <LoadingSpinner />
                            <BigPlayButton position="center" />
                            <Shortcut clickable={true} dblclickable={false} />

                        </VideoPlayer>
                    )}
                    {waiting && !showTrimSettings && (
                        <>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: TrimmingAnimation,
                                }}
                                height={500}
                                width={500}
                            />
                            <span className="ShareCallForm__trimCopy">Trimming your call...</span>
                        </>
                    )}
                </div>
            }
            {!waiting && !showTrimSettings && link === "" && (
                <>
                    <span className="ShareCallForm__footer__text">
                        Trim Video
                    </span>
                    <Trimmer seek={handleSeek} currentTime={videoState ? videoState.currentTime : 0} start={start} setStart={setStart} end={end} setEnd={setEnd} />
                    <div className="ShareCallForm__footer">
                        <div style={{ display: "flex", flexDirection: "column", marginRight: "auto" }}>
                            {false && <span className="ShareCallForm__footer__text">{fetchDataSafely(call, "details.callDataDocument.meetingInfo.meetingTitle")}</span>}
                            {false && (
                                <div>
                                    <span className="ShareCallForm__footer__subtext Player__text-time">
                                        {player.playback && player.playback.callDate ? moment(player.playback.callDate).format("MMMM DD, hh:mm A") : ""}
                                    </span>
                                    <span className="ShareCallForm__footer__subtext Player__text-time">
                                        {player.playback && player.playback.duration ? "  |  " + _formatSecondsToMinutes(player.playback.duration, " MINS") : ""}
                                    </span>
                                </div>
                            )}

                        </div>
                        <Button variant={"primary"} className={"!px-6 !py-2"} onClick={(e) => { setShowTrimSettings(true), generateLink(start, end) }}>
                            <TextBase textColor="text-coolGray-50">
                                Next
                            </TextBase>
                        </Button>
                    </div>
                </>
            )}
            {showTrimSettings &&
                <div className="flex flex-col items-start">

                    <div className=" w-full flex flex-col px-8 mt-4">
                        <LabelWithExplanation
                            labelClass="Settings__label"
                            label="Snippet title"
                            tooltipHeading=""
                            explanation={"Use this field to set the name for the shared link (anyone accessing the link will see this name). Defaults to the call title."}
                        />
                        <Input
                            value={snippetName}
                            onChange={(e) => setSnippetName(e.target.value)}
                            placeholder={"eg: Call with acme"}
                        />
                    </div>

                    <div className="flex flex-col items-start mt-10  w-full">
                        <TextBase textColor="text-coolGray-500 px-8" className="mb-3">
                            Additional Settings
                        </TextBase>
                        <div className="flex flex-row mb-3 px-8" >
                            <CheckBox checked={validOnlyForAMonth} onChange={(e) => { setValidOnlyForAMonth(!validOnlyForAMonth) }} />
                            <TextBase className="mt-0.5 ml-2 text-left" textColor="text-coolGray-900 ">
                                Set this shared link to expire in 30 days
                            </TextBase>
                        </div>
                        <div className="flex flex-row mb-3 px-8"  >
                            <CheckBox checked={emailRequired} onChange={(e) => setEmailRequired(!emailRequired)} />
                            <TextBase className="mt-0.5 ml-2 text-left" textColor="text-coolGray-900 ">
                                Ask viewer’s email for access to the call recording
                            </TextBase>
                        </div>
                        <div className="flex flex-row mb-3 px-8" >
                            <CheckBox checked={notifyMe} onChange={() => setNotifyMe(!notifyMe)} />
                            <TextBase className="mt-0.5 ml-2 text-left" textColor="text-coolGray-900 ">
                                Receive an alert when your call recording is viewed
                            </TextBase>
                        </div>

                        <div className="flex items-center px-8 mt-5 pt-3 justify-end pb-3 bg-coolGray-100 absolute bottom-0 left-0 w-full">
                            <div className="flex items-center">
                                <TextLarge onClick={() => handleClose(true)} className="mr-10 cursor-pointer">Cancel</TextLarge>
                                <Button
                                    variant={"primary"}
                                    className={"!px-6 !py-2"}
                                    onClick={() => { setShowTrimSettings(false), handleShareSettings(shareId) }}
                                >
                                    <TextBase textColor="text-coolGray-50">
                                        Next
                                    </TextBase>
                                </Button>
                            </div>

                        </div>
                    </div>

                </div>

            }
            {link !== "" && !showTrimSettings && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="ShareCallForm__footer__text">
                        <LinkIcon style={{ margin: "0px 5px" }} />
                        Here’s your call snippet
                    </span>
                    <div className="ShareCallForm__linkContainer">
                        <MuiInput id="input" inputProps={{ className: "ShareCallForm__footer__link" }} value={link} className="ShareCallForm__footer__link" />
                        <IconButton size="medium" onClick={copyToClipboard}>
                            <CopyIcon />
                        </IconButton>
                    </div>
                </div>
            )}
        </div>

    );
};

export default ShareCallFormTrimmer;
