import React, { useState, useEffect } from "react";
import MeetingIcon from "../call/MeetingIcon";
import CompanyIcon from "app/styles/assets/images/icons/dealView/CompanyIcon.svg";
import { _formatDateToCallCardDateFormat } from "../../utils/helpers";
import { fetchDataSafely } from "../../utils/dataUtils";
import { TextBase } from "../../designSystem";
import { useSelector } from "react-redux";
import classNames from "classnames";

const MeetingTitleColumn = ({ call, showAccountDetails, showDate, hoverUnderline, callRowState }) => {
  const [details, setDetails] = useState(getLocalDetails());
  const currency = useSelector(store => store?.auth?.stringsCustomer?.defaultCurrency) ?? "$";

  useEffect(() => {
    setDetails(getLocalDetails());
  }, [call])

  function getLocalDetails() {
    const localDetails = [];
    if (call && call.accountName && call.accountName.trim() !== "")
      localDetails.push(call.accountName);
    if (call && call.currentDealStage && call.currentDealStage.trim() !== "" && !["LEAD", "CONTACT", "ACCOUNT", "DEAL"].includes(call.currentDealStage))
      localDetails.push(call.currentDealStage);
    if (call && call.dealAmount && call.dealAmount.trim() !== "")
      localDetails.push(currency + call.dealAmount);
    return localDetails;
  }


  let meetingNameColor = "text-coolGray-900"
  if (callRowState == "READ") {
    meetingNameColor = "text-coolGray-500"
  }
  return (
    <div className="flex flex-row items-center">
      <MeetingIcon callType={call.callType} className="mr-2 flex-shrink-0" />
      <div className="">

        <TextBase className={classNames({ 'hover:underline': hoverUnderline })} textColor={meetingNameColor}>{call.title}</TextBase>
        {showAccountDetails &&
          <div className="flex flex-row items-center flex-wrap">
            {showDate &&
              <TextBase textColor="text-coolGray-500" className="capitalize" >
                {_formatDateToCallCardDateFormat(fetchDataSafely(call, "time", null))}
              </TextBase>}
            {details.map((deets, i) => {
              return (
                <React.Fragment key={i}>
                  {(i !== 0 || showDate) && <div className="bg-coolGray-300 w-1.5 h-1.5 rounded-full mx-1.5" />}
                  <TextBase textColor="text-coolGray-500" >{deets}</TextBase>
                </React.Fragment>
              )
            })}
          </div>
        }
      </div>
    </div>
  );
};

MeetingTitleColumn.defaultProps = {
  showAccountDetails: false,
  showDate: false,
  hoverUnderline: false
}

export default MeetingTitleColumn;
