import React, { } from "react";
import { useSelector } from "react-redux";
import { checkIfExternalFromEmail } from "../../utils/helpers";
import ProfileImage, { getInitials } from "../common/ProfileImage";
import ContactIcon from "app/styles/assets/images/icons/dealView/ContactIcon.svg";

const AttendeeIcons = ({ attendees, imageSize, emailIds }) => {
    const authorizedDomains = useSelector(store => store.auth.stringsCustomer.authorizedDomains);

    return (
        <div>
            {attendees &&
                <div className="CrmContextContainer__callDetails__attendees">
                    {attendees.slice(0, 5).map((attendee, i) => {
                        return (
                            <div key={attendee + i} style={{ borderRadius: (imageSize / 2) + "px", marginLeft: (i == 0 ? 0 : (-imageSize / 2) + "px") }}>
                                {!checkIfExternalFromEmail(emailIds[i], authorizedDomains) && <ProfileImage key={attendee} size={imageSize} userId={attendee} disableTooltip={true} caller="attendeeIcon" />}
                                {checkIfExternalFromEmail(emailIds[i], authorizedDomains) && <div data-tip data-for={"profileImageattendeeIcon" + attendee}>
                                    <img style={{ "width": imageSize + "px", "height": imageSize + "px", borderRadius: (imageSize / 2) + "px", border: "solid 2px #ffffff" }} src={ContactIcon} />
                                </div>}

                            </div>
                        )
                    })}
                    {attendees.length > 5 &&
                        <div>
                            <div className="CrmContextContainer__callDetails__attendeesMore"
                                style={{ height: imageSize + "px", width: imageSize + "px", borderRadius: (imageSize / 2) + "px", marginLeft: (-imageSize / 2) + "px" }}>
                                +{attendees.length - 5}
                            </div>

                        </div>
                    }
                </div>
            }
        </div>
    );
}
AttendeeIcons.propTypes = {}
AttendeeIcons.defaultProps = {}
export default AttendeeIcons;