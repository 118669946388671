import React, { useEffect, useState } from 'react'
import { getAllDocFields } from "../../utils/network";
import { flattenDocObjects } from './CRMFieldFilters';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { fetchCustomDealFields } from "../../utils/CustomDealFilterHelper"
import MoreFiltersSearch from './MoreFiltersSearch';
import classNames from "classnames";

const MoreFieldsPopup = ({ onSelect, showMoreFields, isDealFilter }) => {
    const ref = useRef(null);
    const auth = useSelector(store => store.auth);

    useEffect(() => {
        if (ref && ref.current !== null) {
            if (ref.current.getBoundingClientRect().right > document.documentElement.clientWidth) {
                ref.current.style.left = (document.documentElement.clientWidth - ref.current.getBoundingClientRect().right) + "px";
            } else {
                ref.current.style.left = "20px";
            }
            if (ref.current.getBoundingClientRect().bottom > document.documentElement.clientHeight) {
                ref.current.style.maxHeight = (document.documentElement.clientHeight - ref.current.getBoundingClientRect().top - 10) + "px";
            } else {
                ref.current.style.maxHeight = "calc(100vh - 200px)";
            }
        }

    }, [showMoreFields]);

    const callFilters = {
        "General": [
            { value: "types", label: "Call Type" },
            { value: "contacts", label: "Contacts" },
            { value: "dispositions", label: "Call Disposition" },
            { value: "notContainCategories", label: "Doesn't Contain Topic" },
            { value: "containsCategories", label: "Contains Topic" },
            { value: "competitorMentioned", label: "Competitor Mentioned" },
            { value: "talkListenRatio", label: "Talk to Listen Ratio" },
            { value: "status", label: "Call Status" },
            { value: "agentNames", label: "Agent Name" },
            { value: "managers", label: "Managers" },
            { value: "duration", label: "Duration" },
            { value: "callTags", label: "Call Tags" }
        ],
        "Coaching": [
            { value: "score", label: "Call Score" },
            { value: "peopleTagged", label: "People Tagged " },
            { value: "commentSearchTerm", label: "Comments" },
            { value: "commentator", label: "Commented by" },
            { value: "scoringTemplate", label: "Scoring Template" },
            { value: "scoredRep", label: "Scored Rep" },
            { value: "scoredBy", label: "Scored By" }
        ],
        "Search": [
            { value: "query", label: "Transcript" },
            { value: "meetingTitle", label: "Meeting Title" },
        ],
        "CRM": [
            { value: "dealStageLive", label: "Current Deal Stage" },
            { value: "dealStageFrozen", label: "Deal Stage At Call Time" },
        ],
        "Dialer": [
            { value: "dialerCallDispositions", label: "Call Disposition From Dialer" },
        ]
    }

    const dealFilters = {
        "General": [
            { value: "dealAmount", label: "Amount" },
            { value: "dealNames", label: "Deal Name" },
            { value: "competitors", label: "Competitor Mentioned" },
            { value: "warnings", label: "Warnings" },
        ]
    }

    const [filterFields, setFilterFields] = useState(isDealFilter ? dealFilters : callFilters);
    const [allFilterFields, setAllFilterFields] = useState(isDealFilter ? dealFilters : callFilters);

    async function fetchFields() {
        const fieldObjects = await getAllDocFields();
        const fields = flattenDocObjects(fieldObjects, true);
        console.log("docs", fields);
        Object.keys(fields).map(crmEntity => {
            fields[crmEntity].map(field => {
                if (!filterFields[crmEntity]) { filterFields[crmEntity] = []; }
                filterFields[crmEntity].push(field);
            });
        });
        setFilterFields(filterFields);
        setAllFilterFields(filterFields)
    }
    async function setDealFields() {
        const otherDealFields = {};
        otherDealFields["CRM"] = await fetchCustomDealFields()
        setFilterFields({ ...filterFields, ...otherDealFields })
        setAllFilterFields({ ...filterFields, ...otherDealFields })
    }

    useEffect(() => {
        isDealFilter ? setDealFields() : fetchFields();
    }, []);

    return (
        <div ref={ref} className={
            classNames(
                showMoreFields ? "" : "hidden",
                "absolute bg-white border border-gray-200 rounded p-3 shadow z-10 w-[600px] flex flex-col top-14 left-5 ")}>
            <MoreFiltersSearch
                setFilterFields={setFilterFields}
                filterFields={allFilterFields}
            />
            <div className="overflow-y-scroll flex-wrap">
                {Object.keys(filterFields).map(category => {
                    return (
                        filterFields[category].length > 0 &&
                        <div key={category} className="MoreFieldsPopup__categoryContainer">
                            <span className="MoreFieldsPopup__category">{category}</span>
                            <div className="MoreFieldsPopup__fieldsContainer">
                                {filterFields[category].map(field => {
                                    return <span key={field.value}
                                        className="text-base cursor-pointer text-gray-700 ml-1 mr-2 mb-0.5 p-2 font-medium hover:font-semibold min-w-[200px] hover:text-wingman-brand hover:bg-brand-blue-4 hover:rounded-md"
                                        onClick={() => onSelect(field.value)}>{field.label}</span>
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
MoreFieldsPopup.propTypes = {}
MoreFieldsPopup.defaultProps = {}
export default MoreFieldsPopup;