import React from "react";
import PropTypes from "prop-types";
import { IconButton, withStyles } from "@material-ui/core";
import classnames from "classnames";

const styles = () => ({
  root: {},
  button: {},
  mini: {
    padding: "2px",
    margin: "2px",
    minWidth: "0px",
    maxHeight: "10px",
  },
});

const CustomIconButton = props => {
  const { classes, iconButtonProps, onClick } = props;
  return (
    <IconButton
      className={props.type === "mini" ? classes.mini : classnames(classes.button, classes.root)}
      onClick={onClick}
      {...iconButtonProps}
    >
      <div className="CustomIconButton">
        <img src={props.icon} alt={props.label} />
        {props.label && <span>{props.label}</span>}
      </div>
    </IconButton>
  );
};

CustomIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string.isRequired,
  iconButtonProps: PropTypes.object,
};

CustomIconButton.defaultProps = {
  label: "",
  iconButtonProps: {},
};

export default withStyles(styles)(CustomIconButton);
