import React, { useState } from 'react';
import moment from 'moment';
import { TextBase, TextLarge, TextSM, Button, IconButton } from 'app/designSystem';
import { EyeIcon, ThumbsUpIcon } from 'app/designSystem/icons';
import { TagsChipContainer } from 'app/designSystem/common'
import { useSelector } from 'react-redux';

const GameTapeInformationPanel = ({ gametapeId, handleLike }) => {
    const { cachedGametapesData } = useSelector(state => state.gametapes);
    const { gametape, stats } = cachedGametapesData[gametapeId];
    const { name, description, addedAt, createdDate, callId, userId, startTime, tags } = gametape;
    const { likesCount, viewsCount } = stats;

    const [liked, setLiked] = useState(stats.liked);
    const [likeCounter, setLikeCounter] = React.useState(likesCount);

    const handleLikeClick = (liked) => {
        setLiked(!liked);
        handleLike(!liked);
        setLikeCounter(likeCounter + (liked ? -1 : 1));
    };

    return (
        <div className="flex-0 bg-wingman-white rounded-lg px-3 py-4">
            <div>
                <TextLarge>{name}</TextLarge>
            </div>
            <div>
                <TextBase className="text-gray-500">{moment(addedAt ?? createdDate).format('DD MMM, YYYY')}</TextBase>
            </div>
            <div className="flex my-2 flex-wrap">
                <TagsChipContainer tags={tags} textComponent={<TextSM />} />
            </div>
            <div className="mt-2 max-h-40 overflow-y-auto">
                <TextBase className="text-gray-500">{description}</TextBase>
            </div>
            <div className="flex mt-4">
                <div className="flex items-center mr-6">
                    <IconButton variant="tertiary" className="!text-gray-500 !p-0" icon={<ThumbsUpIcon />} iconClassName="text-gray-500" hoverColor="#2680EB" activeColor="#C7D2FE" isActive={liked} onClick={() => handleLikeClick(liked)} />
                    <TextBase className="text-gray-500 ml-1">{likeCounter} {likeCounter > 1 ? "Likes" : "Like"}</TextBase>
                </div>
                <div className="flex items-center mr-2">
                    <EyeIcon className="text-gray-500" />
                    <TextBase className="text-gray-500 ml-2">{viewsCount} {viewsCount > 1 ? "Views" : "View"}</TextBase>
                </div>
            </div>
            {(callId && userId) && <div>
                <a href={`/call/${callId}#time=${startTime}`} target="_blank" rel="noopener noreferrer">
                    <Button id="view_in_call_review_btn" variant="secondary" className="mt-4 w-full py-2">View in call review</Button>
                </a>
            </div>}
        </div>
    );
};

export default GameTapeInformationPanel;