import React from 'react';
import request from "app/utils/request";
import moment from "moment";
import {
  func,
} from "prop-types";
import {
  clone,
} from "lodash";
import {
  getStartDate,
  getEndDate,
} from "../components/dashboards/dateUtils";

import axios from "axios";
import useSnackbar from "./hooks/useSnackbar";

export const useNetworkRequest = (_) => {
  const { setSnackbar } = useSnackbar();

  async function fetch(url, params = null, data = null, headers = null) {
    const response = await sendRequest(url, params, data, headers);
    if (response.error) {
      setSnackbar(response.data.message, "ERROR");
    } else {
      return response;
    }
  }

  async function fetchFromFunc(func) {
    const response = await func();
    if (response.error) {
      setSnackbar(response.data.message, "ERROR");
    }
  }
  return { fetch, fetchFromFunc };
}

export async function sendRequest(url, params = null, data = null, headers = null, logResult = false, config = {}) {
  try {
    const start = new Date();
    const response = await request().post(url, params ?? data, {
      headers,
      ...config
    });

    if (logResult || localStorage.getItem("logResult") === "true") console.log("end", response.data, url, new Date() - start);
    return response.data;
  } catch (error) {

    if (axios.isCancel(error)) {//if request cancelled break out of normal error handling flow
      console.log("Request has been cancelled", error)
      return
    }
    console.log("error is", error, url);
    if (!error || !error?.data) {
      if (error?.message) {
        error.data = { message: error.message };
      } else {
        error = {
          data: {
            message: "An error occurred while talking to our servers. Please try again or contact support."
          }
        };
        error.message = "An error occurred while talking to our servers. Please try again or contact support.";
      }
      error.error = true;
      error.data.error = true;
      console.log("in first catch", error);
      return error;
    }
    else {
      error.data.error = true;
      error.error = true;
      if (!error?.data?.message) {
        error.message = "An error occurred while talking to our servers. Please try again or contact support.";
        error.data.message = "An error occurred while talking to our servers. Please try again or contact support.";
      } else if (!error?.message) {
        error.message = error.data.message;
      }
      console.log("in second catch", error);
      return error;
    }
  }
}

export async function sendGetRequest(url, headers = null, logResult = false, config = {}) {
  try {
    const start = new Date();
    const response = await request().get(url, {
      headers,
      ...config
    });

    if (logResult || localStorage.getItem("logResult") === "true") console.log("end", response.data, url, new Date() - start);
    return response.data;
  } catch (error) {

    if (axios.isCancel(error)) {//if request cancelled break out of normal error handling flow
      console.log("Request has been cancelled", error)
      return
    }
    console.log("error is", error, url);
    if (!error || !error?.data) {
      if (error?.message) {
        error.data = { message: error.message };
      } else {
        error = {
          data: {
            message: "An error occurred while talking to our servers. Please try again or contact support."
          }
        };
        error.message = "An error occurred while talking to our servers. Please try again or contact support.";
      }
      error.error = true;
      error.data.error = true;
      console.log("in first catch", error);
      return error;
    }
    else {
      console.log("in second catch", error);
      return error;
    }
  }
}

const CancelToken = axios.CancelToken;
let call;

export async function sendCancellableRequest(url, params = null, data = null, headers = null) {

  if (call) {
    console.log("cancelling req for params", params.toString());
    call.cancel("Operation cancelled becauce one more request was made");
  }
  try {
    call = CancelToken.source();
    const start = new Date();
    request().post(url, params, { cancelToken: call.token, ...headers }).then((response) => {
      console.log("end", response.data, url, params.toString(), new Date() - start);
      return response.data;
    }).catch((error) => {
      console.log("axios error", axios.isCancel(error));
      return { cancelled: true };
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function getAllCampaigns() {
  const response = await sendRequest("/campaign/getAllCampaigns");
  return response;
}

export async function getAllPublishers() {
  const response = await sendRequest("/campaign/getAllPublishers");
  return response;
}

export async function getAllClients() {
  const response = await sendRequest("/campaign/getAllClients");
  return response;
}

export async function getCampaignsForClient(clientId) {
  const params = new URLSearchParams();
  params.append("clientId", clientId);
  const response = await sendRequest("/campaign/getCampaignsForClient", params);
  return response;
}

export async function getPublishersForCampaign(clientId, campaignId) {
  const params = new URLSearchParams();
  params.append("clientId", clientId);
  params.append("campaignId", campaignId);
  const response = await sendRequest(
    "/campaign/getPublishersForCampaign",
    params,
  );
  return response;
}

export async function updateNotificationReceiversForSnippet(document, userId) {
  const list = document.usersToNotifyOnShareCallView || [];
  if (document.userId === userId || list.includes(userId)) {
    return null;
  }
  const params = new URLSearchParams();
  params.append("shareId", document.shareId)
  params.append("userId", userId);
  const response = await sendRequest("/call/updateNotificationReceiversList", params)
  return response
}

export async function getCallsForPublisher(clientId, campaignId, publisherId) {
  const params = new URLSearchParams();
  params.append("clientId", clientId);
  params.append("campaignId", campaignId);
  params.append("publisherId", publisherId);
  const response = await sendRequest("/campaign/getCallsForPublisher", params);
  return response;
}

export async function createCampaign(campaign) {
  const params = new URLSearchParams();
  params.append("campaignId", campaign.campaignId);
  params.append("campaignName", campaign.campaignName);
  params.append("startDate", new Date());
  params.append("endDate", new Date());
  const response = await sendRequest("/campaign/createCampaign", params);
  return response;
}


export async function createClient(client) {
  const params = new URLSearchParams();
  params.append("clientId", client.clientId);
  params.append("clientName", client.clientName);
  const response = await sendRequest("/campaign/createClient", params);
  return response;
}

export async function createPublisher(publisher) {
  const params = new URLSearchParams();
  params.append("publisherId", publisher.publisherId);
  params.append("publisherName", publisher.publisherName);
  const response = await sendRequest("/campaign/createPublisher", params);
  return response;
}

export async function addCallScriptQuestion(campaignId, question) {
  const params = new URLSearchParams();
  params.append("campaignId", campaignId);
  params.append("question", question);
  const response = await sendRequest("/campaign/addCallScriptQuestion", params);
  return response;
}

export async function deleteCallScriptQuestion(campaignId, question) {
  const params = new URLSearchParams();
  params.append("campaignId", campaignId);
  params.append("question", question);
  const response = await sendRequest(
    "/campaign/deleteCallScriptQuestion",
    params,
  );
  return response;
}

export async function refreshUser(email = null) {
  const params = new URLSearchParams();
  const response = await sendRequest("/admin/refreshUser", params);
  return response;
}

export async function googleLogin(name, email, imageUrl, idToken) {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("name", name);
  params.append("imageUrl", imageUrl);
  params.append("idToken", idToken);
  const response = await sendRequest("/auth/secureGoogleLoginNew", params);
  return response;
}

export async function googleSignup(name, email, imageUrl, idToken) {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("name", name);
  params.append("imageUrl", imageUrl);
  params.append("idToken", idToken);
  const response = await sendRequest("/auth/secureGoogleSignup", params);
  return response;
}

export async function updateProfile({ name, role, isRecording, jobTitle }) {
  const params = new URLSearchParams();
  params.append("role", role);
  params.append("name", name);
  params.append("isRecording", isRecording);
  params.append("jobTitle", jobTitle);
  const response = await sendRequest("/user/update_profile", params);
  return response;
}

export async function setUserMonologueThreshold(threshold) {
  const params = new URLSearchParams();
  params.append("threshold", threshold);
  const response = await sendRequest("/user/set_monologue_threshold", params);
  return response;
}

export async function setCustomerMonologueThreshold(threshold) {
  const params = new URLSearchParams();
  params.append("threshold", threshold);
  const response = await sendRequest("/admin/set_monologue_threshold", params);
  return response;
}


export async function resetPassword(password, newPassword) {
  const params = new URLSearchParams();
  params.append("password", password);
  params.append("newPassword", newPassword);
  const response = await sendRequest("/user/reset_password", params);
  return response;
}

export async function resetPasswordWithKey(email, password, authKey) {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("password", password);
  params.append("authKey", authKey);
  const response = await sendRequest("/auth/resetPassword", params);
  return response;
}

export async function requestResetPassword(email) {
  const params = new URLSearchParams();
  params.append("email", email);
  const response = await sendRequest("/auth/requestResetPassword", params);
  return response;
}

export async function requestLinkToLogin(email) {
  const params = new URLSearchParams();
  params.append("email", email);
  const response = await sendRequest("/auth/requestLoginByLink", params);
  return response;
}

export async function getVoiceFingerprintUrl() {
  const response = await sendRequest("/fingerprint/get_voice_fingerprint");
  return response;
}

export async function inviteUser({
  email,
  role,
  manager,
}, recordingUser) {
  let requestedRole = role.value;
  if (role.value.includes("MANAGER")) {
    requestedRole = "MANAGER"
  }
  const params = new URLSearchParams();
  params.append("email", email.trim());
  params.append("role", requestedRole);
  if (manager.value) {
    params.append("manager", manager.value)
  }
  params.append("isRecording", recordingUser)
  const response = await sendRequest("/admin/inviteUser", params);
  return response;
}

export async function getUpcomingCalls(userId, page) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("page", page);
  params.append("fetchOngoingCalls", true)
  const response = await sendRequest("/calendar/getUpcomingCalls", params);
  return response;
}

export async function getCompletedCalls(userId, page, showIgnored = false) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("page", page);
  params.append("showIgnored", showIgnored);
  const response = await sendRequest("/calendar/getPastCallsNew", params);
  return response;
}

export async function checkEmailId(email) {
  const params = new URLSearchParams();
  params.append("email", email);
  const response = await sendRequest("/auth/checkEmailId", params);
  return response;
}

export async function login(payload) {
  const params = new URLSearchParams();
  params.append("email", payload.email);
  params.append("password", payload.password);
  const response = await sendRequest("/auth/login", params);
  return response;
}

export async function loginByLink(payload) {
  const params = new URLSearchParams();
  params.append("email", payload.email);
  params.append("authKey", payload.authKey);
  const response = await sendRequest("/auth/loginByLink", params);
  return response;
}

export async function sendVerificationEmail(emailId) {
  const params = new URLSearchParams();
  params.append("emailId", emailId);
  const response = await sendRequest("/auth/sendVerificationEmail", params);
  return response;
}

export async function adminLogin(payload) {
  const params = new URLSearchParams();
  params.append("email", payload.email);
  params.append("password", payload.password);
  params.append("desiredUser", payload.desiredUser);
  params.append("isGoogleVerified", payload.googleVerified);
  params.append("idToken", payload.idToken);
  const response = await sendRequest("/auth/adminLogin", params);
  return response;
}

export async function signUp(email, password, name) {
  const params = new URLSearchParams();
  params.append("email", email);
  params.append("password", password);
  params.append("displayName", name);
  const response = await sendRequest("/auth/signup", params);
  return response;
}


export async function getRecentlyContactedOpportunities() {
  const response = await sendRequest(
    "/opportunity/getRecentlyContactedOpportunities",
  );
  return response;
}

export async function getOpportunityById(opportunityId) {
  const params = new URLSearchParams();
  params.append("opportunityId", opportunityId);
  const response = await sendRequest("/opportunity/getOpportunityById", params);
  return response;
}

export async function getOpportunityByContactEmail(emailId) {
  const params = new URLSearchParams();
  params.append("emailId", emailId);
  const response = await sendRequest(
    "/opportunity/getOpportunityByContactEmail",
    params,
  );
  return response;
}

export async function getCallsForOpportunity(opportunityId) {
  const params = new URLSearchParams();
  params.append("opportunityId", opportunityId);
  const response = await sendRequest(
    "/opportunity/getCallsForOpportunity",
    params,
  );
  return response;
}

export async function getCallsForAccount(accountId) {
  const params = new URLSearchParams();
  params.append("accountId", accountId);
  const response = await sendRequest(
    "/opportunity/getCallsForAccount",
    params,
  );
  return response;
}

export async function getContactById(contactId) {
  const params = new URLSearchParams();
  params.append("contactId", contactId);
  const response = await sendRequest("/opportunity/getContactById", params);
  return response;
}

export async function getContactByEmail(emailId) {
  const params = new URLSearchParams();
  params.append("emailId", emailId);
  const response = await sendRequest("/opportunity/getContactByEmail", params);
  return response;
}

export async function getCallsForContactId(contactId) {
  const params = new URLSearchParams();
  params.append("contactId", contactId);
  const response = await sendRequest("/opportunity/getCallsForContact", params);
  return response;
}

export async function getCallsForEmail(emailId) {
  const params = new URLSearchParams();
  params.append("emailId", emailId);
  const response = await sendRequest("/opportunity/getCallsForEmail", params);
  return response;
}

export async function getAllUsersByCustomerId(customerId) {
  const params = new URLSearchParams();
  params.append("customerId", customerId);
  const response = await sendRequest("/admin/getAllUsersByCustomerId", params);
  return response;
}

export async function setAdmin(userId, value) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("value", value);
  const response = await sendRequest("/admin/setAdmin", params);
  return response;
}

export async function setRoleByAdmin(userId, role, isRecording = null) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("role", role);
  params.append("isRecording", isRecording);
  const response = await sendRequest("/admin/setRole", params);
  return response;
}

export async function setRoleByUser(role, isRecording = null) {
  const params = new URLSearchParams();
  params.append("role", role);
  params.append("isRecording", isRecording);
  const response = await sendRequest("/user/setRole", params);
  return response;
}

export async function setRecordingUser(userId, value) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("value", value);
  const response = await sendRequest("/admin/setRecordingUser", params);
  return response;
}

export async function assignManager(agentId, managerId, managerName) {
  const params = new URLSearchParams();
  params.append("agentId", agentId);
  params.append("managerId", managerId);
  params.append("managerName", managerName);
  const response = await sendRequest("/admin/assignManager", params);
  return response;
}

export async function verifyEmail(token) {
  const params = new URLSearchParams();
  params.append("verificationToken", token);
  try {
    const response = await sendRequest("/auth/verifyEmail", params);
    return response;
  } catch (error) {
    return {
      error: true,
      message: "The link has expired.",
    };
  }
}

export async function submitFreshsalesInfo(userId, apiKey, domain, access) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("apiKey", apiKey);
  params.append("freshSalesAppUrl", domain);
  params.append("access", access);
  const response = await sendRequest(
    "/freshsales/submitFreshsalesInfo",
    params,
  );
  return response;
}

export async function submitLeadsquaredInfo(userId, accessKey, secretKey, access) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("accessKey", accessKey);
  params.append("secretKey", secretKey);
  params.append("access", access);
  const response = await sendRequest(
    "/leadsquared/save_apikeys",
    params,
  );
  return response;
}

export async function addPhrase(
  phrase,
  category,
  exactMatchRequired,
  appliesTo,
) {
  const params = new URLSearchParams();
  params.append("phrase", phrase);
  params.append("category", category);
  params.append("exactMatchRequired", exactMatchRequired);
  params.append("appliesTo", appliesTo);
  const response = await sendRequest("/customer/addPhrase", params);
  return response;
}

export async function addCategory(
  category,
  exactMatches,
  similarMatches,
  appliesTo,
) {
  const params = new URLSearchParams();
  params.append("category", category);
  params.append("exactMatches", exactMatches);
  params.append("similarMatches", similarMatches);
  params.append("appliesTo", appliesTo);
  const response = await sendRequest("/customer/addCategory", params);
  return response;
}

export async function removePhrase(phraseId) {
  const params = new URLSearchParams();
  params.append("phraseId", phraseId);
  const response = await sendRequest("/customer/removePhrase", params);
  return response;
}

export async function getAllPhrasesForCustomer() {
  const params = new URLSearchParams();
  const response = await sendRequest(
    "/customer/getAllPhrasesForCustomer",
    params,
  );
  return response;
}

export async function deleteCategory(category) {
  const params = new URLSearchParams();
  params.append("category", category);
  const response = await sendRequest("/customer/deleteCategory", params);
  return response;
}

export async function setAppliesToForCategory(category, appliesTo) {
  const params = new URLSearchParams();
  params.append("category", category);
  params.append("appliesTo", appliesTo);
  const response = await sendRequest("/customer/setAppliesToForCategory", params);
  return response;
}

export async function getCall(callId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  const response = await sendRequest(
    "/call/getCall",
    params,
  );
  return response;
}

export async function oneTimeFreshsalesSync(userId) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  const response = await sendRequest(
    "/freshsales/oneTimeFreshsalesSync",
    params,
  );
  return response;
}

export async function oneTimeOutlookFetch() {
  const response = await sendRequest(
    "/outlook/one_time_fetch",
  );
  return response;
}

export async function unsyncIntegration(field, access = "user") {
  const params = new URLSearchParams();
  params.append("field", field);
  params.append("access", access);
  const response = await sendRequest(
    "/user/unsync_integration", params,
  );
  return response;
}

export async function deleteUser(userId) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  const response = await sendRequest(
    "/admin/deleteUser", params,
  );
  return response;
}

export async function oneTimeLeadsquaredSync(userId, access) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  params.append("access", access);
  const response = await sendRequest("/leadsquared/sync", params);
  return response;
}

export async function submitVoiceFingerPrint(userId, audioBlob) {
  const response = await request().post("/fingerprint/add?userId=" + userId, {
    audioBlob,
  });
  return response.data;
}

export async function getOldTeamDashboardData(filterParams) {
  const response = await sendRequest("/dashboard/team", getJSONFromFilterParams(filterParams));
  return response;
}

export async function getTeamDashboardData(filterParams) {
  const response = await sendRequest("/dashboard/team-dashboard", getJSONFromFilterParams(filterParams));
  return response;
}

export async function getScorecardsDashboardData(filterParams) {
  const response = await sendRequest("/dashboard/scorecards_dashboard", getJSONFromFilterParams(filterParams));
  return response;
}

export async function getTeamDashboardDealInsightsData(filterParams) {
  const response = await sendRequest("/dashboard/team_dashboard_deal_insights", getJSONFromFilterParams(filterParams));
  return response;
}

export async function anyCallsForCustomer() {
  const response = await sendRequest("/call/anyCallsForCustomer");
  return response;
}

export async function anyCallsForUser() {
  const response = await sendRequest("/call/anyCallsForUser");
  return response;
}

export async function getUserDashboardData(filterParams) {
  const response = await sendRequest("/dashboard/me", getJSONFromFilterParams(filterParams));
  return response;
}

export async function getCallCountData(filterParams) {
  const response = await sendRequest("/dashboard/callCount", getJSONFromFilterParams(filterParams));
  return response;
}


export async function search(query, from = 0, size = 25) {
  const params = new URLSearchParams();
  params.append("query", query);
  params.append("from", from);
  params.append("size", size);
  const response = await sendRequest("/search/for", params);
  return response;
}

export async function deleteComment(commentId, callId) {
  const params = new URLSearchParams();
  params.append("commentId", commentId);
  params.append("callId", callId);
  const response = await sendRequest("/comments/deleteComment", params);
  return response;
}

export async function editComment(callId, commentId, commentText, startTime, taggedUsers) {
  const params = new URLSearchParams();
  params.append("commentId", commentId);
  params.append("callId", callId);
  params.append("commentText", commentText);
  params.append("taggedUsers", taggedUsers);
  params.append("startTime", startTime);
  const response = await sendRequest("/comments/editComment", params);
  return response;
}

export async function addComment(callId, commentText, taggedUsers, slackChannels, startTime, isPrivate = false) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("commentText", commentText);
  params.append("taggedUsers", taggedUsers);
  params.append("slackChannels", slackChannels);
  params.append("startTime", startTime);
  params.append("access", isPrivate ? "PRIVATE" : "PUBLIC");
  const response = await sendRequest("/comments/addComment", params);
  return response;
}

export async function deleteReply(callId, commentId, replyId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("commentId", commentId);
  params.append("replyId", replyId);
  const response = await sendRequest("/comments/deleteReply", params);
  return response;
}

export async function addReply(callId, replyText, taggedUsers, replyToId, startTime) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("replyText", replyText);
  params.append("taggedUsers", taggedUsers);
  params.append("replyToId", replyToId);
  params.append("startTime", startTime);
  const response = await sendRequest("/comments/addReply", params);
  return response;
}

export async function editReply(callId, replyText, taggedUsers, replyToId, startTime, replyId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("replyText", replyText);
  params.append("taggedUsers", taggedUsers);
  params.append("replyToId", replyToId);
  params.append("startTime", startTime);
  params.append("replyId", replyId);
  const response = await sendRequest("/comments/editReply", params);
  return response;
}

export async function getProfileImages() {
  return await sendRequest("/user/get_profile_pics");
}

export async function getRepJobTitles() {
  const response = await sendRequest("/user/get_rep_job_titles");
  return response;
}

export async function getAllPipelines() {
  const response = await sendRequest("/filter/get_all_pipelines_from_db");
  return response;
}

//filter required all list options to be in a simple {value:id,label:"option label"} format, reusing the same feature endpoints rather than adding new endpoints just for transforming  response format
export async function getScoringTemplatesForFilter() {
  try {
    const resp = await fetchScoringTemplates()
    return (resp || []).map(template => ({ "value": template.id, "label": template.name }))

  }
  catch (e) {
    console.error("error fetching scoring templates for filter metadata,returning empty list", e);
    return [];//avoid breaking usemetadata fetcher
  }
}
export const fetchScoringTemplates = async () => {
  const response = await sendRequest('scorecard/get_all_scoring_templates');
  if (response.error) {
    throw error
  }
  return response.result
}

export const fetchScorecardsForcall = async (callId) => {
  const params = new URLSearchParams()
  params.append("callId", callId)
  const response = await sendRequest('scorecard/get_call_scorecards', params)
  if (response.error) {
    throw error
  }
  return response.result
}
export async function getAllUsers() {
  const response = await sendRequest("/comments/getAllUsers");
  return response;
}

export async function getAllUserNamesForFilters() {
  const response = await sendRequest("/filter/getAllUserNamesForFilters");
  if (response.error) {
    return null;
  }
  return response;
}

export async function getAllDeletedUserNamesForFilters() {
  const response = await sendRequest("/filter/getAllDeletedUserNamesForFilters");
  if (response.error) {
    return null;
  }
  return response;
}

export async function getAllPeopleTagged() {
  const response = await sendRequest("/filter/getAllPeopleTagged");
  return response;
}

export async function getAllLabels() {
  const response = await sendRequest("/filter/getAllLabels");
  return response;
}

export async function getAllManagersForFilters() {
  const response = await sendRequest("/filter/getAllManagersForFilters");
  return response;
}

export async function getAllCallStatus() {
  const response = await sendRequest("/filter/getAllCallStatus");
  return response;
}

export async function getAllCallTypes() {
  const response = await sendRequest("/filter/getAllCallTypes");
  return response;
}

export async function getAllCategoriesForFilters() {
  const response = await sendRequest("/filter/getAllCategoriesForFilters");
  return response;
}

export async function getAllCompetitorsForFilters() {
  const response = await sendRequest("/filter/get_unique_field_values?fieldName=competitorsMentioned");
  return response;
}

export async function getAllContacts() {
  const response = await sendRequest("/filter/getAllContacts");
  return response;
}

export async function getContactsById(contactIds) {
  const params = new URLSearchParams();
  params.append("contactIds", contactIds);
  const response = await sendRequest("/filter/getContactsById", params);
  return response;
}

export async function getUsersById(userIds) {
  const params = new URLSearchParams();
  params.append("userIds", userIds);
  const response = await sendRequest("/filter/getUsersById", params);
  return response;
}

export async function getContactsForFilters(key = "null", size = 20) {
  const params = new URLSearchParams();
  params.append("key", key);
  params.append("size", size);
  const response = await sendRequest(
    "/filter/getAllContactsForFilters",
    params,
  );
  return response;
}

export async function getDealNamesForFilters(key = "null", size = 20) {
  const params = new URLSearchParams();
  params.append("key", key);
  params.append("size", size);
  const response = await sendRequest(
    "/filter/getAllDealNamesForFilters",
    params,
  );
  return response;
}

export async function getAllTagsForCustomer() {
  const response = await sendRequest("/filter/getAllCustomerTags");
  return response;
}

export async function getDealStageForFilters(stageType) {
  const params = new URLSearchParams();
  params.append("stageType", stageType);
  const response = await sendRequest(
    "/filter/getAllDealStagesForFilters",
    params,
  );
  return response;
}

export async function ignoreCall(callId, ignoreCall) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("ignoreCall", ignoreCall);
  const response = await sendRequest(
    "/call/ignore_call",
    params,
  );
  return response;
}
export async function joinOngoingCall(callId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  const response = await sendRequest(
    "/call/join_ongoing_call",
    params,
  );
  return response;
}


export async function deleteCall(callId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  const response = await sendRequest(
    "/call/delete_call",
    params,
  );
  return response;
}

export async function editMeetingTitle(callId, title) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("title", title);
  const response = await sendRequest(
    "/call/edit_meeting_title",
    params,
  );
  return response;
}


export async function getReviewAnnotationQuestion(userId) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  const response = await sendRequest("/experimental/get_unreviewed", params);
  return response;
}

export async function joinCall(url, attendeeEmail, title) {
  const params = new URLSearchParams();
  params.append("url", url);
  if (attendeeEmail !== "") params.append("attendeeEmail", attendeeEmail);
  if (title !== "") params.append("meetingTitle", title);
  const response = await sendRequest("/call/join_adhoc_call", params);
  return response;
}

export async function submitReviewAnnotationDecision(
  callId,
  turnId,
  reviewFor,
  userId,
  decision,
) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  params.append("turnId", turnId);
  params.append("reviewFor", reviewFor);
  params.append("userId", userId);
  params.append("status", decision);
  const response = await sendRequest("/experimental/save_review", params);
  return response;
}

export async function getManualAnnotationQuestion(userId) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  const response = await sendRequest(
    "/experimental/get_unreviewed_paraphrase",
    params,
  );
  return response;
}

export async function submitManualAnnotationDecision(id, userId, decision) {
  const params = new URLSearchParams();
  params.append("id", id);
  params.append("userId", userId);
  params.append("status", decision);
  const response = await sendRequest(
    "/experimental/save_paraphrase_review",
    params,
  );
  return response;
}

export async function getReviewsCount(userId) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  const response = await sendRequest("/experimental/get_reviews_count", params);
  return response;
}

export async function getTopCalls(userId, startDate, endDate) {
  const params = new URLSearchParams();
  params.append("userId", userId);
  if (startDate !== null) {
    params.append("startDate", moment(startDate).toDate());
  } else {
    params.append("startDate", startDate);
  }
  if (endDate !== null) {
    params.append("endDate", moment(endDate).toDate());
  } else {
    params.append("endDate", endDate);
  }
  const response = await sendRequest("/manager/getTopCalls", params);
  return response;
}

export async function getFilteredCalls(filterParams) {
  const response = await sendRequest("/search/filter", getJSONFromFilterParams(filterParams), null, {
    "Content-Type": "application/json",
  });
  return response;
}

export async function exportCallLogToExcel(filterParams) {
  const response = await sendRequest("/filter/export", getJSONFromFilterParams(filterParams), null, {
    "Content-Type": "application/json",
    Accept: "application/octet-stream",
    responseType: "arraybuffer",
  });
  return response;
}

export function getJSONFromFilterParams(filterParams) {

  const params = clone(filterParams);

  params.crmFields = getBackendCrmFields(params.crmFields);
  if (filterParams.startDate != null) {
    params.startDate = moment(params.startDate).toDate();
  }
  if (filterParams.endDate != null) {
    params.endDate = moment(params.endDate).toDate();
  }
  if (params.query)
    params.query = params.query.trim();
  params.startDuration = params.startDuration === null ? 0 : params.startDuration;
  params.endDuration = params.endDuration === null ? 0 : params.endDuration;
  params.startTalkListenRatio = params.startTalkListenRatio === null ? 0 : params.startTalkListenRatio;
  params.endTalkListenRatio = params.endTalkListenRatio === null ? 0 : params.endTalkListenRatio;
  return params;
}

export function getBackendCrmFields(crmFields) {
  try {
    console.log("getBackendCrmFields", crmFields);
    if (!crmFields) return [];
    const arr = Object.keys(crmFields).filter(crmField => {
      const value = crmFields[crmField].value;
      switch (crmFields[crmField].field.fieldType) {
        case "NUMERIC": return value && (value[0] > 0 || value[1] < 100);
        case "INTEGER": return value && (value[0] > 0 || value[1] < 10);
        case "DATE": return value && (value[0] || value[1]);
        case "LIST": return value && value.length > 0;
        case "STRING": return value && value[0] !== "";
      }
      return false;
    }).map(crmField => {
      return ({
        fieldName: crmFields[crmField].field.value,
        fieldType: crmFields[crmField].field.fieldType,
        values: crmFields[crmField].value,
      });
    });
    return arr;
  } catch (error) {
    console.log("error getting backend crm fields", error);
    return crmFields;
  }
}

export function getFrontendCrmFields(crmFields) {
  if (!crmFields) return [];
  const map = {};
  crmFields.map(crmField => {
    console.log("frontend field", crmField);
    map[crmField.fieldName] = { field: { value: crmField.fieldName, fieldType: crmField.fieldType }, value: crmField.values }
  });
  return map;
}

export async function logEvent(event, eventProperties, userProperties = null) {
  const params = new URLSearchParams();
  params.append("event", event);
  if (eventProperties) {
    params.append("eventProperties", JSON.stringify(eventProperties));
  }
  if (userProperties) {
    params.append("userProperties", JSON.stringify(userProperties));
  }
  const response = await sendRequest("/metrics/log_event", params);
  return response;
}

export async function getUsersWithoutVfp(callId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  return await sendRequest("/call/get_call_users_without_vfp", params);
}

export async function fetchAccountSearchResults(fieldName, searchQuery, callIds, emailIds, cancelToken, from = 0) {
  const searchPamas = new URLSearchParams();
  searchPamas.append("calls", callIds);
  searchPamas.append("emails", emailIds);
  searchPamas.append("query", searchQuery);
  searchPamas.append("fieldName", fieldName);
  searchPamas.append("from", from);
  const callsAndEmailsSearchResult = await sendRequest("/search/account_view_search", searchPamas, null, null, false, cancelToken);
  return callsAndEmailsSearchResult;
}

export async function pushCallToCrm(callId) {
  const params = new URLSearchParams();
  params.append("callId", callId);
  const response = await sendRequest("/call/push_call_to_crm", params);
  return response;
}

export {
  searchCalls,
  searchOpportunities,
}
  from "./network/search";

export {
  getSharedCall,
  getShareableLink,
}
  from "./network/callSharing";

export {
  getUserId,
}
  from "./network/users";

export {
  getBattlecards,
  createBattlecard,
  editBattlecard,
  getAllBattlecardCategories,
  setWingmanDownloaded,
  getLinkForLatestWingmanDownloadableFile,
  deleteBattlecard,
}
  from "./network/wingman";

export {
  hubspotCallback,
  oneTimeHubspotSync,
  pipedriveCallback,
  oneTimePipedriveSync,
  ringcentralCallback,
  oneTimeRingcentralSync,
  salesforceCallback,
  crmOAuthCallback,
  oneTimeSalesforceSync,
  salesloftCallback,
  oneTimeSalesloftSync,
  dialpadCallback,
  oneTimeDialpadSync,
  zoomCallback,
  oneTimeZoomSync,
  outreachCallback,
  oneTimeOutreachSync,
  calendarCallback,
  outlookCalendarCallback,
  unsyncOutlook,
  unsyncGoogleCalendar,
  syncSlack,
  unsyncSlack,
}
  from "./network/integrations";

export {
  getDocTypes,
  getAllDocFields,
  getDocFields,
  getRequiredFields,
  getDefaultCRM,
  setFieldAsRequired,
  setFieldAsNotRequired,
}
  from "./network/crmSettings";
