import React, {
  useMemo, useEffect, useState, useCallback
} from "react";
import { connect } from "react-redux";
import { Label } from "app/components/form";
import { SPaper } from "app/components/common";
import Select from "react-select";
import { BattleCardGrid } from "app/components/settings/BattleCards";
import { setUsersForCategory } from "../../utils/network/wingman";
import { getAllManagersForFilters } from "../../utils/network";
import { setMetaData } from "../../actions/screen";
import { GLOBAL, TEAM, PERSONAL } from "../../constants";

const BattleCardCategory = ({
  cards, auth, screen, setMetaData, categoryName, openEditBattleCardForm, removeBattlecard
}) => {
  const getTeamCallBack = useCallback(getTeam, [cards]);
  const [team, setTeam] = useState(null);
  const initialOptions = [{ value: auth.data.user.userId, label: "Only Me" }, { value: GLOBAL, label: "All Users" }];
  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    setTeam(getTeamCallBack());
  }, [cards]);

  useEffect(() => {
    let newOptions = screen.metaData.managers;
    newOptions = initialOptions.concat(newOptions);
    setOptions(newOptions);
  }, [screen.metaData.managers]);

  useEffect(() => {
    let newOptions = screen.metaData.managers;
    newOptions = initialOptions.concat(newOptions);
    setOptions(newOptions);
  }, []);

  function getTeam() {
    if (cards && cards.length > 0 && cards[0].accessInfo) {
      const {
        accessLevel, managerId, userId, managerName, userName,
      } = cards[0].accessInfo;
      if (accessLevel === "PERSONAL" && userId) {
        if (userId === auth.data.user.userId) { return { value: userId, label: "Only Me" }; }
        return { value: userId, label: `${userName}'s Personal Cards` };
      }
      if (accessLevel === "TEAM" && managerId) {
        return { value: managerId, label: managerName };
      }

      if (accessLevel === "GLOBAL") {
        return { value: "GLOBAL", label: "All Users" };
      }
    }
    return null;
  }

  async function handleUsersChange(option) {
    setTeam(option);
    if (option.value === GLOBAL) {
      await setUsersForCategory(categoryName, { accessLevel: GLOBAL });
    } else if (option.label === "Only Me") {
      await setUsersForCategory(categoryName, { accessLevel: PERSONAL, userId: auth.data.user.userId, userName: auth.data.user.displayName });
    } else if (option.label.indexOf("Team") !== -1) {
      await setUsersForCategory(categoryName, { accessLevel: TEAM, managerId: option.value, managerName: option.label });
    }
  }

  return (
    <SPaper className="">
      <div className="wingman__categoryHeader">
        <span className="wingman__categoryName">{categoryName}</span>
        <div className="SelectUsers">
          <Label>APPLIES TO:</Label>
          <Select options={options} value={team} placeholder="Select Team" onChange={handleUsersChange} />
        </div>
      </div>
      <BattleCardGrid removeBattlecard={removeBattlecard} openEditBattleCardForm={openEditBattleCardForm} battlecards={cards} auth={auth} />
      <div className="wingman__rulerContainer"><hr /></div>
    </SPaper>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

export default connect(
  mapStateToProps,
  {},
)(BattleCardCategory);
