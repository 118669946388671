import React from 'react';
import { connect } from "react-redux";
import { setLoading } from "app/actions/screen";
import { refreshUserAction } from "app/actions/auth";
import { Loading } from "app/components/common";
import { unsyncIntegration } from "../../utils/network";
import { getFlowStatus } from "../../reducers/persisted";
import { clone } from "lodash";
import {
  SyncCalendars,
  SyncOutlook,
  SyncSalesforce,
  SyncHubspot,
  SyncFreshsales,
  SyncPipedrive,
  SyncLeadsquared,
  SyncRingcentral,
  SyncOutreach,
  SyncAircall,
  SyncFrontspin,
  SyncClose,
  SyncSalesloft,
  SyncDialpad,
  SyncZoomPhone,
  SyncNylas,
  WingmanAPI
} from "../onboarding";
import SyncSlack from "../onboarding/SyncSlack";

const IntegrationSettings = props => {
  let { integrationFlows, customerLevelIntegrationFlows, customerLevelIntegrations, featuresGated } = props.auth.data.user.stringsCustomer;
  let { isAdmin } = props.auth;

  const flowStatus = getFlowStatus(props.auth.data.user);
  const customerLevelIntegration = props.auth.stringsCustomer.customerLevelIntegrations
  const ssoFeatureEnabled = featuresGated?.ssoEnabled ?? false;

  let userFlows = clone(integrationFlows);
  let customerFlows = clone(customerLevelIntegrationFlows)
  if (props.showOnlyUnsynced) {
    Object.keys(flowStatus).forEach(key => {
      userFlows[key] = !flowStatus[key];
      customerFlows[key] = !flowStatus[key]
    });
    console.log("show Flows" + userFlows + customerFlows);
  }

  async function unsync(field, access = "user") {
    props.setLoading(true);
    await unsyncIntegration(field, access);
    await props.refreshUserAction();
    props.setLoading(false);
  }

  if (!customerFlows && props.admin) return null;
  if (!userFlows && props.user) return null;

  return (
    <div className="Settings__integrationsContainer">
      <span className="Settings__header">Integrations</span>
      <Loading />
      {props.user && (
        <div className="m-2 flex flex-row flex-wrap">
          {userFlows.googleCalendarAuthFlow && (
            <SyncCalendars
              setLoading={props.setLoading}
              synced={flowStatus.googleCalendarAuthFlow}
            />
          )}
          {userFlows.outlookCalendarAuthFlow && (
            <SyncOutlook
              setLoading={props.setLoading}
              synced={flowStatus.outlookCalendarAuthFlow}
            />
          )}
          {userFlows && userFlows.nylasSyncFlow && (
            <SyncNylas
              synced={flowStatus.nylasSyncFlow}
              unsync={() => unsync("nylas")}
            />
          )}
        </div>
      )}
      {isAdmin && props.admin && (
        <div className="Settings__integrations__row">
          {customerFlows?.hubspotSyncFlow && (
            <SyncHubspot
              access="admin"
              synced={flowStatus.hubspotSyncFlow}
              unsync={() => unsync("hubspot", "admin")}
              lastSyncDate={customerLevelIntegration?.hubspotSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.leadsquaredSyncFlow && (
            <SyncLeadsquared
              access="admin"
              synced={flowStatus.leadsquaredSyncFlow}
              unsync={() => unsync("leadsquared", "admin")}
              lastSyncDate={customerLevelIntegration?.leadsquaredSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.freshsalesSyncFlow && (
            <SyncFreshsales
              access="admin"
              synced={flowStatus.freshsalesSyncFlow}
              unsync={() => unsync("freshsales", "admin")}
              lastSyncDate={customerLevelIntegration?.freshsalesSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.salesforceSyncFlow && (
            <SyncSalesforce
              access="admin"
              synced={flowStatus.salesforceSyncFlow}
              unsync={() => unsync("salesforce", "admin")}
              lastSyncDate={customerLevelIntegration?.salesforceSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.zoomSyncFlow && (
            <SyncZoomPhone
              access="admin"
              synced={flowStatus.zoomSyncFlow}
              unsync={() => unsync("zoom", "admin")}
              lastSyncDate={customerLevelIntegration?.zoomSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.outreachSyncFlow && (
            <SyncOutreach
              access="admin"
              synced={flowStatus.outreachSyncFlow}
              unsync={() => unsync("outreach", "admin")}
              lastSyncDate={customerLevelIntegration?.outreachSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.aircallSyncFlow && (
            <SyncAircall
              access="admin"
              synced={flowStatus.aircallSyncFlow}
              unsync={() => unsync("aircall", "admin")}
              lastSyncDate={customerLevelIntegration?.aircallSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.frontspinSyncFlow && (
            <SyncFrontspin
              access="admin"
              synced={flowStatus.frontspinSyncFlow}
              unsync={() => unsync("frontspin", "admin")}
              lastSyncDate={customerLevelIntegration?.frontspinSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.closeSyncFlow && (
            <SyncClose
              access="admin"
              synced={flowStatus.closeSyncFlow}
              unsync={() => unsync("close", "admin")}
            />
          )}
          {customerFlows?.pipedriveSyncFlow && (
            <SyncPipedrive
              access="admin"
              synced={flowStatus.pipedriveSyncFlow}
              unsync={() => unsync("pipedrive", "admin")}
              lastSyncDate={customerLevelIntegration?.pipedriveSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.slackAuthFlow && (
            <SyncSlack
              access="admin"
              synced={flowStatus.slackAuthFlow}
              unsync={() => { }}
              lastSyncDate={customerLevelIntegration?.slackAuthFlowTimeStamp}
            />
          )}

          {customerFlows?.salesloftSyncFlow && (
            <SyncSalesloft
              access="admin"
              synced={flowStatus.salesloftSyncFlow}
              unsync={() => unsync("salesloft", "admin")}
              lastSyncDate={customerLevelIntegration?.salesloftSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.dialpadSyncFlow && (
            <SyncDialpad
              access="admin"
              synced={flowStatus.dialpadSyncFlow}
              unsync={() => unsync("dialpad", "admin")}
              lastSyncDate={customerLevelIntegration?.dialpadSyncFlowTimeStamp}
            />
          )}
          {customerFlows?.ringcentralSyncFlow && (
            <SyncRingcentral
              access="admin"
              synced={flowStatus.ringcentralSyncFlow}
              unsync={() => unsync("ringcentral", "admin")}
              lastSyncDate={customerLevelIntegration?.ringcentralSyncFlowTimeStamp}
            />
          )}
          {featuresGated.wingmanAPI && (
            <WingmanAPI />
          )}

        </div>
      )}

    </div>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

IntegrationSettings.propTypes = {};

IntegrationSettings.defaultProps = {
  showOnlyUnsynced: false,
};

export default connect(
  mapStateToProps,
  { setLoading, refreshUserAction },
)(IntegrationSettings);
