import React, { useState, useEffect } from 'react';
import { _formatSecondsToMinutes } from 'app/utils/helpers';
import { TextBase, TextLarge, Text2XL, Button } from 'app/designSystem'
import { Modal } from "@material-ui/core";
import clx from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandlerIcon, CloseIcon, SaveIcon } from 'app/designSystem/icons';
import useDataHelper from "app/utils/hooks/useDataHelper";
import metrics from 'app/utils/metrics';
import { useSelector } from 'react-redux';

const GameTapeRow = React.memo(({ data }) => {
    const { getUserName } = useDataHelper();
    const { thumbnail, name, description, duration, userId } = data;

    return (
        <>
            <div className="flex items-center w-8/12">
                <DragHandlerIcon className="mr-10 h-10 w-10" />
                <div className="h-full w-[18rem]">
                    <img src={thumbnail} alt={name} className="rounded-2xl w-full h-full object-cover" />
                </div>
                <div className="flex flex-col mx-5 w-full">
                    <TextLarge className="font-medium overflow-hidden h-12 mb-2">{name}</TextLarge>
                    <TextBase className="text-gray-500 font-medium h-16 overflow-hidden">{description}</TextBase>
                </div>
            </div>
            <div className="mr-0 my-auto w-1/12">
                {/* {
                    tags?.map((tag, index) => (
                        <div key={tag + index} className="bg-purple-50 rounded py-1 px-2 mr-2 mb-2 flex">
                            <TextBase className="text-purple-600 font-medium">{tag}</TextBase>
                        </div>
                    ))
                } */}
            </div>
            <div className="mr-4 my-auto w-1/12">
                <TextBase className="text-gray-500 font-medium">{_formatSecondsToMinutes(duration, " mins")}</TextBase>
            </div>
            <div className="my-auto w-2/12">
                <TextBase className="text-gray-500">Added by: {userId ? getUserName(userId) : "Wingman"}</TextBase>
            </div>
        </>
    )
})

const GameTapeReorderModal = ({ folderId, updateOrderOfTapes, modalOpen, setOpen }) => {
    const [filesList, updateFilesList] = useState();
    const { cachedGametapesChildrenData } = useSelector(state => state.gametapes);

    useEffect(() => {
        updateFilesList(cachedGametapesChildrenData[folderId]);
    }, [folderId, cachedGametapesChildrenData])

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(filesList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateFilesList(items);
    }

    const handleSubmit = async () => {
        metrics.logEventHeap("reorderTapes");
        updateOrderOfTapes({ filesList });
        setOpen(false);
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleSubmit}
            className="flex justify-center items-center"
        >
            <div className="flex flex-col bg-white rounded-xl" style={{
                maxHeight: "calc(100vh - 6.25rem)",
                width: "calc(100vw - 10rem)",
            }}>
                <div className="flex justify-between items-center py-6 px-8">
                    <Text2XL>Reorder Gametapes</Text2XL>
                    <button className="ml-auto p-2" onClick={() => setOpen(false)}><CloseIcon /></button>
                </div>
                <hr className="mb-10" />

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided) => (
                            <ul className="overflow-auto characters px-8" {...provided.droppableProps} ref={provided.innerRef}>
                                {filesList.map(({ gametape }, index) => {
                                    return (
                                        <Draggable key={gametape.id} draggableId={gametape.id} index={index}>
                                            {(provided) => (
                                                <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={clx("flex m-auto justify-between py-4 px-10 rounded-2xl max-h-36", index % 2 === 0 ? "bg-gray-50" : "bg-white")}>
                                                    <GameTapeRow data={gametape} />
                                                </li>
                                            )}
                                        </Draggable>
                                    )
                                }
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="flex justify-end py-4 pr-10 border-t bg-wingman-white">
                    <Button onClick={handleSubmit} startIcon={<SaveIcon />}>Save gametapes</Button>
                </div>
            </div >
        </Modal >
    )
}

export default GameTapeReorderModal;