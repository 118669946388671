import React, { useEffect, useRef, useState } from 'react'
import useSnackbar from "app/utils/hooks/useSnackbar";
import { Modal } from '@material-ui/core';
import { TextBase, TextLarge, Button, CloseIcon, TextSM, Toggle } from 'app/designSystem'
import { Input } from 'app/designSystem/form';
import NotificationMediumSelector from './NotificationMediumSelector';
import NotificationTimeSelector from './NotificationTimeSelector';
import SlackChannelSelector from './SlackChannelSelector';
import { saveFilter } from 'app/utils/network/filters';
import PropTypes from 'prop-types';

const SaveAsModal = ({ open, selectedFilter, filterParams, onSaveFilter, allFilters, hideModal, data }) => {
    const [loading, setLoading] = useState(false);
    const { setSnackbar } = useSnackbar();
    const [filterAlert, setFilterAlert] = useState(selectedFilter);
    const [error, setError] = useState(null);
    const modalContainerRef = useRef();

    useEffect(() => {
        setFilterAlert({
            ...selectedFilter,
            name: (data.type === "SAVE_AS") ? "" : selectedFilter.name,
            alertEnabled: selectedFilter.alertEnabled ?? true,
            medium: selectedFilter.medium ?? "EMAIL_AND_SLACK",
            alertFrequency: selectedFilter.alertFrequency ?? "DAILY",
            alertTime: selectedFilter.alertTime ?? "PT18H",
            slackChannelId: selectedFilter.slackChannelId ?? "DM"
        })
        setError(null)
    }, [selectedFilter, data])


    const doesFilterNameExist = (allFiltersList) => {
        return allFiltersList.some(filter => filter.name === filterAlert.name)
    }

    const isInputValid = (allFiltersList) => {
        if (!filterAlert?.name) {
            setError({
                type: "filterName",
                message: "Filter name cannot be empty"
            })
            return false
        }
        if (doesFilterNameExist(allFiltersList)) {
            setError({
                type: "filterName",
                message: "Filter name already exists"
            })
            return false
        }
        return true
    }

    const onSave = async (filterAlert, saveOnlyAlert) => {
        setLoading(true);
        const response = await saveFilter(filterAlert, filterParams, saveOnlyAlert)
        setLoading(false);
        if (response.error)
            setSnackbar("There was an error saving the filter", 'ERROR');
        else
            setSnackbar("Filter was saved", 'SUCCESS');
        onSaveFilter({ ...filterAlert, filterParams, id: response });
        hideModal()
    }

    const handleSave = async () => {
        if (data.type === "SAVE_AS") {
            if (!isInputValid(allFilters)) return;
            onSave({ ...filterAlert, id: null }, false)
        }
        if (data.type === "UPDATE") {
            const allFiltersList = allFilters.filter(filter => filter.id !== filterAlert.id)
            if (data.type === "UPDATE" && !isInputValid(allFiltersList)) return;
            onSave(filterAlert, false)
        }
        if (data.type === "ALERT") {
            onSave(filterAlert, true)
        }
    }

    const handleOnChange = (field, value) => {
        if (field === "name") setError(null)
        setFilterAlert({ ...filterAlert, [field]: value })
    }

    return (
        <Modal
            className="flex justify-center items-center"
            open={open}
            onClose={hideModal} >
            <div
                ref={modalContainerRef}
                className="flex flex-col bg-white rounded-lg w-5/12 shadow-md overflow-x-scroll">
                <div id="save-filter-header" className="flex justify-between items-center flex-shrink-0 border-gray-200 border-b py-4">
                    <TextLarge className="px-6">{data?.headerText}</TextLarge>
                    <button className="px-6" onClick={hideModal}><CloseIcon /></button>
                </div>
                <div id="save-filter-body" className="flex flex-col">
                    {data.type !== "ALERT" &&
                        <>
                            <Input
                                label="Filter Name:"
                                className="text-base w-full px-6 mt-8"
                                inputClassname="!p-2 text-coolGray-900 "
                                value={filterAlert.name}
                                error={error?.type === "filterName" ? error?.message : null}
                                onChange={(e) => handleOnChange("name", e.target.value)} />

                            <TextSM className="px-6 py-1">Personalise your filter names to make them easily accessible</TextSM>
                        </>}
                    <TextLarge textColor="text-coolGray-900" className="px-6 mt-5 mb-1">Filter Alerts</TextLarge>
                    <div className="flex px-6 mb-2">
                        <TextBase textColor="text-coolGray-500" className="">Send an alert when a call matching this filter is recorded.</TextBase>
                        <Toggle
                            className="ml-auto"
                            checked={filterAlert.alertEnabled}
                            onChange={() => handleOnChange("alertEnabled", !filterAlert.alertEnabled)}
                        />
                    </div>
                    {filterAlert.alertEnabled === true &&
                        <>
                            <div className="flex items-center px-6 py-2">
                                <TextBase textColor="text-coolGray-600" className="mr-auto">Notify me:</TextBase>
                                <NotificationTimeSelector
                                    filterAlert={filterAlert}
                                    setFilterAlert={setFilterAlert}
                                    popoverContainerRef={modalContainerRef}
                                />
                            </div>
                            <div className="flex items-center px-6 py-2">
                                <TextBase textColor="text-coolGray-600" className="mr-auto">Notify through:</TextBase>
                                <NotificationMediumSelector
                                    selectedOption={filterAlert.medium}
                                    setSelectedOption={(value) => handleOnChange("medium", value)}
                                    popoverContainerRef={modalContainerRef}
                                />
                            </div>
                            {filterAlert.medium?.includes("SLACK") &&
                                <div className="flex items-center px-6 py-2">
                                    <TextBase textColor="text-coolGray-600" className="mr-auto">Send slack alerts to</TextBase>
                                    <SlackChannelSelector
                                        selectedChannel={filterAlert.slackChannelId}
                                        setSelectedChannel={(value) => handleOnChange("slackChannelId", value)}
                                        popoverContainerRef={modalContainerRef}
                                    />
                                </div>}
                        </>}
                </div>
                <div className="bg-coolGray-50 py-2 flex items-center justify-end border-t mt-8 rounded-b-lg">
                    <Button variant="primary" className="h-8 w-28 mr-5 my-0.5" loading={loading} onClick={handleSave}>{data?.buttonText}</Button>
                </div>
            </div>
        </Modal >
    );
}

export default SaveAsModal;

SaveAsModal.propTypes = {
    open: PropTypes.bool,
    allFilters: PropTypes.array,
    hideModal: PropTypes.func.isRequired,
    onSaveFilter: PropTypes.func.isRequired,
    filterParams: PropTypes.object,
    selectedFilter: PropTypes.object,
    data: PropTypes.object
}

