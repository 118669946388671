import React from "react";
import PropTypes from "prop-types";
import { SPaper, PeopleTag, CustomChip } from "app/components/common";
import { Grid, withStyles } from "@material-ui/core";
import waitIcon from "app/styles/assets/images/icons/cd.png";
import createIcon from "app/styles/assets/images/icons/cb.png";
import tlRatioIcon from "app/styles/assets/images/icons/lr.png";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  _formatSecondsToTime,
  _formatTalkListenRatio
} from "app/utils/helpers";
import { getTLRatioColor } from "../dashboards/helpers";

const styles = {
  card: {
    padding: "30px"
  },
  icon: {
    height: "67px",
    width: "67px",
    margin: "5px"
  },
  title: {
    height: "12px",
    color: "#333333",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "19px",
    textAlign: "center",
    margin: "10px"
  },
  iconDiv: {
    height: "150px"
  },
  iconDivInner: {
    height: "150px"
  },
  sections: {
    margin: "15px 0px"
  },
  tlrText: {
    margin: "3px",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "24px",
    textAlign: "center"
  },
  cdText: {
    margin: "3px",
    color: "#0e0e0e",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "24px",
    textAlign: "center"
  },
  container: {
    width: "700px",
    margin: "10px"
  },
  outerContainer: {
    marginLeft: "10px"
  }
};

const CallCardWithStats = props => {
  const { call, classes } = props;
  const { meetingInfo } = call;
  let color = "black";
  if (meetingInfo !== null) {
    if (call.dashboardMetrics) {
      color = getTLRatioColor(
        call.dashboardMetrics.talkListenRatio,
        props.okayTLRatioThreshold,
        props.goodTLRatioThreshold
      );
    }
    const meetingCreator = meetingInfo.meetingCreator || "";
    return (
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="center"
        justify="flex-start"
        className={classes.outerContainer}
      >
        <Grid xs={2} item>
          <CustomChip
            chipProps={{
              label: moment(call.callTime).format("DD MMMM | hh:mm A")
            }}
          />
        </Grid>
        <Grid item>
          <Link to={`/call/${call.callId}`}>
            <SPaper className={classes.container}>
              <span className={classes.title}>{meetingInfo.meetingTitle}</span>
              <Grid
                container
                spacing={3}
                direction="row"
                className={classes.iconDiv}
              >
                <Grid item xs={4}>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    className={classes.iconDivInner}
                    justify="center"
                    direction="column"
                  >
                    <img className={classes.icon} src={createIcon} alt="icon" />
                    <span className={classes.title}>CREATED BY</span>
                    <PeopleTag contactName={meetingCreator} />
                  </Grid>
                </Grid>
                {call.dashboardMetrics && (
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      className={classes.iconDivInner}
                      justify="center"
                      direction="column"
                    >
                      <img className={classes.icon} src={waitIcon} alt="icon" />
                      <span className={classes.title}>CALL DURATION</span>
                      <span className={classes.cdText}>
                        {_formatSecondsToTime(
                          call?.dashboardMetrics?.callDuration ?? 0
                        )}
                      </span>
                    </Grid>
                  </Grid>
                )}
                {call.dashboardMetrics && (
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={3}
                      alignItems="center"
                      className={classes.iconDivInner}
                      justify="center"
                      direction="column"
                    >
                      <img
                        className={classes.icon}
                        src={tlRatioIcon}
                        alt="icon"
                      />
                      <span className={classes.title}>TALK/LISTEN RATIO</span>
                      <span className={classes.tlrText} style={{ color }}>
                        {_formatTalkListenRatio(
                          call.dashboardMetrics.talkListenRatio
                        )}
                      </span>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </SPaper>
          </Link>
        </Grid>
      </Grid>
    );
  }
  return null;
};

CallCardWithStats.propTypes = {
  call: PropTypes.object
};

CallCardWithStats.defaultProps = {};

export default withStyles(styles)(CallCardWithStats);
