import React, { useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { refreshUserAction } from "app/actions/auth";
import { hubspotCallback, oneTimeHubspotSync, sendRequest } from "app/utils/network";
import HubSpotLogo from "app/styles/assets/images/integrations/hubspot.png";
import { HUBSPOT_CLIENT_ID } from "app/config";
import { openWindowAndListenForParam, getRedirectUrl } from "app/components/onboarding/helpers";
import IntegrationCard from "app/components/onboarding/IntegrationCard";
import useSnackbar from "../../utils/hooks/useSnackbar";
import { ERROR } from "../../actions/actionTypes";
import { WingmanLogoWithName } from "app/designSystem"

const AddHubspotScopes = props => {
    const [loading, setLoading] = useState(false);
    const [synced, setSynced] = useState(false);
    const { setSnackbar } = useSnackbar();

    async function completeSync(code) {
        if (!code) {
            setLoading(false);
            return;
        }
        const userId = props.auth.data.user.userId || {};
        const params = new URLSearchParams();
        params.append("code", code);
        params.append("userId", userId);
        const res = await sendRequest("/hubspot/updateScopes", params);
        if (res === "NOK") {
            console.log("Code is:", code);
            setSnackbar("Error syncing. Please try again, check your permission levels or contact support@trtwingman.com for help", ERROR);
            setLoading(false);
            return;
        }
        setSynced(true);
        setLoading(false);
        props.refreshUserAction();
        window.open("/", "_self");
    }

    async function sync() {
        setLoading(true);
        const location = getRedirectUrl();
        const url = `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_CLIENT_ID}&scope=contacts timeline sales-email-read&redirect_uri=${location}`;
        openWindowAndListenForParam(url, "Hubspot Integration", "code", completeSync);
    }

    return (
        <div style={{ flexDirection: "column", display: "flex", height: "100vh", width: "600px", margin: "auto", justifyContent: "center", alignItems: "center" }}>
            <WingmanLogoWithName className="h-15 mb-7" />
            <span style={{ textAlign: "center", marginBottom: "10px", lineHeight: "40px", fontSize: "23px", color: "#304156", fontWeight: "bold" }}>Hi, We need your help to enable the all new deal view!</span>
            <span style={{ textAlign: "center", marginBottom: "30px", lineHeight: "22px", fontSize: "15px", color: "#304156", fontWeight: "500" }}>The new deal view feature enables seeing all the information about a deal in one place. To enable this, Wingman needs additional permission to read your emails from Hubspot.</span>
            <IntegrationCard
                icon={HubSpotLogo}
                heading="Hubspot"
                subHeading="CRM Integrations"
                bodyText="Sync hubspot to link all CRM info."
                loading={loading}
                sync={sync}
                synced={synced}
                {...props}
            />
            <span style={{ textAlign: "center", marginBottom: "30px", fontSize: "15px", color: "#304156", fontWeight: "600" }}>Click on sync to allow Wingman to read emails</span>
        </div >
    );
};

const mapStateToProps = store => {
    return { ...store };
};

AddHubspotScopes.defaultProps = {
    access: "user",
};

AddHubspotScopes.propTypes = {
    refreshUserAction: PropTypes.func.isRequired,
    access: PropTypes.string,
};

export default connect(
    mapStateToProps,
    { refreshUserAction },
)(AddHubspotScopes);
