import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { Modal } from "@material-ui/core";
import { Button } from "app/components/form";
import { CustomLink } from "app/components/common";
import { refreshUserAction } from "app/actions/auth";
import { setLoading } from "app/actions/screen";
import { setWingmanDownloaded, getLinkForLatestWingmanDownloadableFile } from "app/utils/network";
import WingmanOnboardingIcon from "app/styles/assets/images/onboarding/downloadWingman.png";
import { DESKTOP_APP_S3_BUCKET } from "app/config";

class WingmanDownload extends Component {
  constructor(props) {
    super(props);
    this.sync = this.sync.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openSupportPage = this.openSupportPage.bind(this);
    this.state = {
      os: window.navigator.platform.indexOf("Win") !== -1 ? "Windows" : "Mac",
      modalOpen: false,
    };
  }

  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  async sync() {
    this.props.setLoading(true);
    const link = await getLinkForLatestWingmanDownloadableFile(window.navigator.platform);
    window.open(DESKTOP_APP_S3_BUCKET + link, "_self");
    await setWingmanDownloaded();
    this.setState({
      modalOpen: true,
      link: DESKTOP_APP_S3_BUCKET + link,
    });
    this.props.setLoading(false);
  }

  openSupportPage() {
    window.open("http://support.trywingman.com/en/articles/4465734-how-to-manage-wingman-s-real-time-cues-during-screen-sharing", "_blank");
  }

  render() {
    const { skipHandler } = this.props;
    return (
      <div className="Sync-Background verCon">
        <div className={"DetailedOnboarding__outerContainerTransparent"}>
          <div className="DetailedOnboarding__imageContainer">
            <span className="DetailedOnboarding__heading">Wingman Desktop App</span>
            <span className="DetailedOnboarding__subheading">
              Supercharge your sales calls with live cues and coaching alerts.
              </span>
            <div className="DetailedOnboarding__iconContainer">
              <img className={"wingman_icon"} src={WingmanOnboardingIcon} alt="Logo" />
            </div>
          </div>
          <div className="DetailedOnboarding__textContainer">
            <div className="DetailedOnboarding__tipsContainer">
              <span className="DetailedOnboarding__tip">
                1. No droning on with monologue alerts⏰:
              </span>
              <span className="DetailedOnboarding__tipCopy">
                All talk and no listen makes your prospects go to sleep. Get gentle nudges to pause and ask a question when you've been talking for too long.
              </span>
              <span className="DetailedOnboarding__tip">
                2.  Pitch perfect with cue cards🎴:
              </span>
              <span className="DetailedOnboarding__tipCopy">
                Get pop-up cards with just the right thing to pitch at that moment, in the moment with cue cards. Contextual and discreet.
               </span>
              <span className="DetailedOnboarding__tip">
                3.  Bookmark a moment with one click🔖:
              </span>
              <span className="DetailedOnboarding__tipCopy">
                You make memories, we'll save them. Hit the bookmark button to save important moments during calls and easily go back to them later.
            </span>
              <span onClick={this.openSupportPage} className="DetailedOnboarding__supportLink">How do live cues appear while screen sharing?❓🤔</span>
            </div>
          </div>
          <DownloadModal
            open={this.state.modalOpen}
            handleClose={this.handleClose}
            link={this.state.link}
            skipHandler={skipHandler}
          />
        </div>
        <div className="DetailedOnboarding__buttonContainer">
          <Button theme="dark" onClick={this.sync}>
            {`Download Now`}
          </Button>
        </div>
        <CustomLink onClick={skipHandler}>Skip</CustomLink>
      </div >
    );
  }
}

export const AutomaticWingmanDownload = props => {
  async function download() {
    const link = await getLinkForLatestWingmanDownloadableFile(window.navigator.platform);
    window.open(DESKTOP_APP_S3_BUCKET + link, "_blank");
    props.history.goBack();
  }
  useEffect(() => download(), []);

  return null;
};

const DownloadModal = props => {
  return (
    <Modal open={props.open} onClose={props.handleClose} className="wingman__downloadModal">
      <div className="wingman__downloadModal-inner">
        <span className="wingman__downloadModal-bigText">
          Your download should start automatically.
        </span>
        <span className="wingman__downloadModal-text">
          <br />
          If it doesn't click on this <a href={props.link}>Link</a> to download Wingman.
        </span>
        <Button theme="dark" onClick={props.skipHandler}>Next</Button>
      </div>
    </Modal>
  );
};

const mapStateToProps = store => {
  return { ...store };
};

WingmanDownload.propTypes = {
  refreshUserAction: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  skipHandler: PropTypes.func.isRequired,
};

WingmanDownload.defaultProps = {
  setLoading: () => { },
};

export default connect(
  mapStateToProps,
  { refreshUserAction, setLoading },
)(WingmanDownload);
