import React, { useState, useEffect } from 'react'
import { sendRequest } from '../../utils/network';
import { useDispatch, useSelector } from 'react-redux';
import { SET_NOTIFICATIONS, SCREEN_NAME } from '../../actions/actionTypes';
import { Button } from '../form';
import NotificationsBody from './NotificationsBody';

const NotificationsPage = () => {
	const dispatch = useDispatch();
	const notifications = useSelector(store => store.screen.notifications);
	const notificationsNew = useSelector(store => store.screen.notificationsNew);

	useEffect(() => {
		dispatch({ screenName: "Notifications Center", type: SCREEN_NAME })
		getAllNotifications();
	}, [])

	async function getAllNotifications() {
		const localNotifications = await sendRequest("/notifications/get_all_notifications");
		dispatch({ notifications: localNotifications, type: SET_NOTIFICATIONS });
	}

	async function handleMarkAllAsRead() {
		await sendRequest("/notifications/mark_all_as_read");
		const localNotifications = notifications.map(n => { n.read = true; return n; });
		dispatch({ notifications: localNotifications, type: SET_NOTIFICATIONS });
	}


	return (
		<div className="NotificationsPage__container">
			<NotificationsBody type="large" hideNotificationModal={() => { }} />
		</div >
	);
}
NotificationsPage.propTypes = {}
NotificationsPage.defaultProps = {}
export default NotificationsPage;