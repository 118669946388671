import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextBase, Button, TextLarge, TextSM } from "app/designSystem"
import { IconButton } from "@material-ui/core";
import { ReactComponent as Close } from "app/styles/assets/images/icons/Close_Icon.svg"
import { getLocalValueInInt, incrementLocalValueInInt } from "app/utils/hooks/usePersistedLocal";
import { getUsersWithoutVfp } from "app/utils/network";


const VoiceFingerprintWarning = ({ callId }) => {

    const [isWarningEnabled, setIsWarningEnabled] = useState(false);
    const isRecorderCall = useSelector(store => store.call?.details?.callData?.recorderCapturedDiarization);
    const numChannels = useSelector(store => store.call?.details?.callData?.metadata?.numChannels);
    const [usersWithoutVFP, setUsersWithoutVFP] = useState('');

    useEffect(() => {
        if (isRecorderCall || (numChannels && numChannels > 1)) setIsWarningEnabled(false);
        else if (usersWithoutVFP.length < 1) setIsWarningEnabled(false);
        else setIsWarningEnabled(true);
    }, [isRecorderCall, numChannels, usersWithoutVFP])

    useEffect(() => {
        if (callId) getUserStringWithoutVfp(callId);
    }, [callId])

    async function getUserStringWithoutVfp(callId) {
        const response = await getUsersWithoutVfp(callId);
        if (response.error) setIsWarningEnabled(false);
        else {
            let userListWithoutVFP = response.map(name => name.split(' ')[0]);
            setUsersWithoutVFP(userListWithoutVFP.join(' and '));
        }
    }

    function closeWarning() {
        setIsWarningEnabled(!isWarningEnabled);
    }

    return (
        <div>
            {isWarningEnabled &&
                < div className="Call__transcriptMessageContainer" >
                    <div className="flex flex-row items-center justify-between bg-orange-50 w-full py-2 pl-4" >
                        <div className="flex flex-col items-center justify-between w-auto">
                            <TextBase className="w-full" textColor="text-orange-800">
                                Speaker identification for this call may not be accurate because {usersWithoutVFP} and {usersWithoutVFP} haven't submitted their voice fingerprint.
                            </TextBase>
                            <TextSM className="w-full font-light" textColor="text-orange-500">
                                Voice fingerprints help Wingman assign the different parts of the call to the right speakers.
                            </TextSM>
                        </div>
                        <div className="flex flex-row items-center">
                            <IconButton onClick={closeWarning}>
                                <Close />
                            </IconButton>
                        </div>
                    </div>
                </div>}
        </div>
    )

};

export default VoiceFingerprintWarning;