import React from 'react';
import PropTypes from 'prop-types';
import clx from 'classnames';
import { TextBase } from '../typography/Typography';

const TextArea = React.forwardRef(({ label, error, className, textAreaClassName, ...props }, ref) => {
    return (
        <div className={className}>
            {label && (
                <TextBase className="mb-1 text-gray-500">
                    {label}
                </TextBase>
            )}
            <textarea
                className={clx("focus:ring-brand-blue-1 focus:border-brand-blue-1 block w-full sm:text-sm border-gray-300 rounded-md border p-3 focus:outline-none", textAreaClassName)}
                ref={ref}
                {...props}
            />
            {error && <span className="input-error">{error}</span>}
        </div>
    );
});

export default TextArea;

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
    label: PropTypes.string,
    textAreaClassName: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
};

TextArea.defaultProps = {
    error: '',
    className: '',
    textAreaClassName: '',
};
