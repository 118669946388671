import {
    SET_GAMETAPES_FOLDER_DATA,
    SET_GAMETAPES_GAMETAPES_DATA,
    SET_GAMETAPES_ALL_GAMETAPES_DATA,
    SET_GAMETAPES_ALL_FOLDER_DATA,
    SET_GAMETAPES_FOLDER_CHILDREN_DATA,
    SET_GAMETAPES_GAMETAPES_CHILDREN_DATA,
    SET_GAMETAPES_TAGS_DATA,
    SET_CREATE_FOLDER_MODAL_OPEN
} from "./actionTypes"
import { getAllFolders, getAllGametapes, getAllTags } from "app/utils/network/gametapes"

export function setGameTapesAllFolderData(data) {
    return {
        type: SET_GAMETAPES_ALL_FOLDER_DATA,
        folders: data
    }
}

export function setGameTapesAllGameTapesData(data) {
    return {
        type: SET_GAMETAPES_ALL_GAMETAPES_DATA,
        gametapes: data
    }
}

export function setGameTapesFolderChildrenData(folderId = 'ROOT', data = []) {
    return {
        type: SET_GAMETAPES_FOLDER_CHILDREN_DATA,
        folderId,
        data
    }
}

export function setFolderCreateModal(isOpen) {
    return {
        type: SET_CREATE_FOLDER_MODAL_OPEN,
        isOpen
    }
}

export function setGameTapesFolderData(folderId = 'ROOT', data = []) {
    return {
        type: SET_GAMETAPES_FOLDER_DATA,
        folderId,
        data
    }
}

export function setGameTapesGameTapesChildrenData(folderId = 'ROOT', data = []) {
    return {
        type: SET_GAMETAPES_GAMETAPES_CHILDREN_DATA,
        folderId,
        data
    }
}

export function setGameTapesGameTapesData(gametapeId, data = []) {
    return {
        type: SET_GAMETAPES_GAMETAPES_DATA,
        gametapeId,
        data
    }
}

export function setGameTapesTagsData(data) {
    return {
        type: SET_GAMETAPES_TAGS_DATA,
        tags: data
    }
}

export function getGameTapesAllFolderData() {
    return dispatch => {
        getAllFolders().then((data) => {
            dispatch(setGameTapesAllFolderData(data))
        })
    };
}

export function getGameTapesTagsData() {
    return dispatch => {
        getAllTags().then((data) => {
            dispatch(setGameTapesTagsData(data))
        })
    };
}

export function getGameTapesAllGameTapesData() {
    return dispatch => {
        getAllGametapes().then((data) => {
            dispatch(setGameTapesAllGameTapesData(data))
        })
    };
}
