import React from 'react';
import Skeleton from 'react-loading-skeleton';


export default function ScorecardsSettingsSkeleton() {
    return (



        Array.from({ length: 5 }).fill('scorecardSkeleton').map((i) => {

            return (

                <div className="flex flex-col justify-start pt-4 pb-3 px-4 m-4 ml-0 mb-8 bg-gray-50 rounded-lg  border border-coolGray-200 transition-all hover:bg-white hover:shadow-md h-30 w-[20rem]">

                    <Skeleton width={200} height={25} />
                    <div className="mt-5">
                        <Skeleton width={120} height={20} />
                    </div>
                    <div className="mt-2">
                        <Skeleton width={120} height={20} />
                    </div>

                </div>
            )
        })




    );
}