import Skeleton from 'react-loading-skeleton';

const CardSkeleton = ({ className = "", style = {} }) => {
    return (
        <div className={`flex flex-col justify-between p-4 m-4 bg-gray-50 rounded-lg border border-coolGray-200 transition-all hover:bg-white hover:shadow-md h-60 w-96 ${className}`} style={{
            ...style,
        }}>
            <div>
                <div className="mb-2">
                    <Skeleton width={200} height={20} />
                </div>
                <div className="flex mb-2">
                    <Skeleton width={100} height={20} />
                </div>
                <div className="mb-2">
                    <Skeleton width={200} height={20} />
                </div>
            </div>
            <div>
                <div className="flex flex-row justify-between">
                    <Skeleton width={100} height={20} />
                    <Skeleton width={100} height={20} />
                </div>
            </div>
        </div>
    )
}

export default CardSkeleton;